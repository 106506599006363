import React, { FC, useMemo } from 'react'
import { Text, TextProps } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { createTextPropsForParams } from '../../../helpers/textVariantHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import {
  HeadingParams,
  HeadingVariant,
  TextField,
} from '../../../types/layoutService'

export interface SitecoreTextProps extends Omit<TextProps, 'css'> {
  field: TextField
  defaultVariant: HeadingVariant
  params?: HeadingParams
}

const SitecoreText: FC<SitecoreTextProps> = ({
  field,
  defaultVariant,
  params,
  ...rest
}) => {
  const fieldValue = getTextFieldValue(field)
  const { pageEditing } = useSitecoreContext()

  const textProps = useMemo(
    () => createTextPropsForParams(params, defaultVariant),
    [params, defaultVariant]
  )

  if (!pageEditing && !fieldValue.length) {
    return null
  }

  return (
    <Text {...textProps} {...rest}>
      {fieldValue}
    </Text>
  )
}

export default SitecoreText
