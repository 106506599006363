import { AxiosRequestConfig } from 'axios'
import { HEADER_AUTHORIZATION } from '../constants/httpConstants'
import { UserProps } from '../types/userProps'
import { getBearerToken } from './userHelper'

export const createRequestConfig = (
  user?: UserProps,
  customHeaders?: any,
  customParams?: any
): AxiosRequestConfig => {
  const headers = { ...customHeaders }
  const params = customParams ? { ...customParams } : undefined

  const accessToken = getBearerToken(user)
  if (accessToken) {
    headers[HEADER_AUTHORIZATION] = accessToken
  }

  return { headers, ...(params ? { params } : {}) }
}
