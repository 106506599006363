import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import {
  fetchNewsArticles,
  NewsArticlesRequestOptions,
} from '../../../../services/rest/sitecore/newsArticles'
import { NewsArticle, PaginatedResponse } from '../../../../types/layoutService'
import useAxiosClient from '../core/useAxiosClient'

export const useNewsArticleService = (
  options: NewsArticlesRequestOptions
): [boolean, PaginatedResponse<NewsArticle> | undefined] => {
  const client = useAxiosClient(AxiosClientType.SitecoreApi)
  const appInsights = useAppInsightsContext()

  const [newsArticles, setNewsArticles] = useState<
    PaginatedResponse<NewsArticle> | undefined
  >(undefined)
  const [fetching, setFetching] = useState(true)

  const getNewsArticles = useCallback(
    async (cancelToken: CancelToken) => {
      setFetching(true)

      try {
        const { data } = await fetchNewsArticles(client, options, cancelToken)

        if (data) {
          setNewsArticles(data)
        }
      } catch (error) {
        const err = error as AxiosError<PaginatedResponse<NewsArticle>>

        appInsights.trackException({
          exception: new Error(
            `Unable to fetch news articles: ${err.message} (${err.code})`
          ),
          severityLevel: SeverityLevel.Error,
          properties: options,
        })
      } finally {
        setFetching(false)
      }
    },

    [appInsights, options]
  )

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()

    getNewsArticles(token)

    return () => {
      cancel()
    }
  }, [getNewsArticles, options])

  return [fetching, newsArticles]
}
