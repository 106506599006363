import React from 'react'
import { useSelector } from 'react-redux'
import PartMostVisitedSlider, {
  PartMostVisitedSliderDatasource,
  PartMostVisitedSliderParams,
} from '../PartMostVisitedSlider/PartMostVisitedSlider'
import { RootState } from '../../../reducers'
import { Datasource, Params, WithDataProps } from '../../../types/layoutService'
import { RecommendedPartSliderProps } from '../RecommendedPartSlider/RecommendedPartSlider'

const ShoppingBasketPartMostVisitedSlider = (
  props: Datasource<PartMostVisitedSliderDatasource> &
    RecommendedPartSliderProps &
    Params<PartMostVisitedSliderParams> &
    WithDataProps
) => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)

  if (order?.items?.length) return null

  return <PartMostVisitedSlider {...props} />
}

export default ShoppingBasketPartMostVisitedSlider
