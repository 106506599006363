import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import { SliderTuple } from '../../../hooks/slider/useSlider'
import Button from '../../atoms/Button/Button'

interface ContentSliderHeaderProps {
  title?: string
  slider: SliderTuple
}

const ContentSliderHeader: FC<ContentSliderHeaderProps> = ({ title, slider }) => {
  const [, decrementPageNumber, incrementPageNumber, { isFirstPage, isLastPage }] =
    slider
  const canNavigate = !isFirstPage || !isLastPage

  return (
    <Flex
      pb={[4, 4, 5]}
      sx={{
        justifyContent: 'space-between',
      }}
    >
      {title && (
        <Text as="h2" variant="heading2" color="text">
          {title}
        </Text>
      )}

      {canNavigate && (
        <Flex>
          <Button
            variant="outline"
            round
            icon={ICONS.ARROW_LEFT}
            disabled={!!isFirstPage}
            onClick={decrementPageNumber}
          />

          <Button
            variant="outline"
            round
            icon={ICONS.ARROW_RIGHT}
            disabled={!!isLastPage}
            onClick={incrementPageNumber}
            ml={2}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default ContentSliderHeader
