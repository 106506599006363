import { ErrorComponentProps } from '@sitecore-jss/sitecore-jss-react-forms'
import React, { FC } from 'react'
import SitecoreFormError from './SitecoreFormError'

const SitecoreFormErrorList: FC<ErrorComponentProps> = ({ formErrors }) => {
  if (formErrors?.length < 1) return null

  return (
    <>
      {formErrors.map((message) => (
        <SitecoreFormError message={message} />
      ))}
    </>
  )
}

export default SitecoreFormErrorList
