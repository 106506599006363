import React, { FC } from 'react'
import { Text } from 'theme-ui'
import TableCell from '../../molecules/Table/TableCell'
import TableBody from '../../molecules/Table/TableBody'
import TableRow from '../../molecules/Table/TableRow'
import Table from '../../molecules/Table/Table'
import { DealerPartCrossReference } from '../../../hooks/services/graphql/useDealerPartListService'

interface DealerPartCrossReferencesTableProps {
  crossReferences: DealerPartCrossReference[]
}

export const DealerPartCrossReferencesTable: FC<
  DealerPartCrossReferencesTableProps
> = ({ crossReferences }) => {
  let lastVendorName = ''

  if (!crossReferences?.length) return null

  return (
    <Table columnSizes={[1, 1, 1]}>
      <TableBody>
        {crossReferences.map(
          ({ vendorName, crossReferencePartNumber, brandName }, i) => {
            const hideVendorNameOnMobile =
              lastVendorName === vendorName ? ['none', 'none', 'block'] : []

            lastVendorName = vendorName

            return (
              <TableRow key={i.toString()}>
                <TableCell>
                  <Text
                    as="strong"
                    variant="bodyContent"
                    color="gray.3"
                    sx={{
                      display: hideVendorNameOnMobile,
                      fontWeight: 'bold',
                    }}
                  >
                    {vendorName}
                  </Text>
                </TableCell>

                <TableCell>
                  <Text
                    as="span"
                    variant="bodyContent"
                    sx={{
                      textAlign: ['right', 'right', 'left'],
                    }}
                  >
                    {brandName}
                  </Text>
                </TableCell>

                <TableCell>
                  <Text
                    as="span"
                    variant="bodyContent"
                    sx={{
                      textAlign: ['right', 'right', 'left'],
                    }}
                  >
                    {crossReferencePartNumber}
                  </Text>
                </TableCell>
              </TableRow>
            )
          }
        )}
      </TableBody>
    </Table>
  )
}

export default DealerPartCrossReferencesTable
