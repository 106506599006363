import React, { FC } from 'react'
import Link from '../../atoms/Link/Link'
import { FooterItem } from './Footer'

interface FooterNavigationLinkProps {
  link: FooterItem
}

const FooterNavigationLink: FC<FooterNavigationLinkProps> = ({ link }) => {
  if (!link?.url) return null

  return (
    <Link
      sx={{
        display: 'block',
        fontWeight: 'normal',
        textDecoration: 'none',
        fontSize: 1,
        color: 'textLight',
        opacity: 0.8,
        outline: 'none',
        lineHeight: 'menuList',
        ':hover, :focus, :active': {
          opacity: 1,
        },
      }}
      href={link.url}
      target={link?.target || '_SELF'}
    >
      {link?.title}
    </Link>
  )
}

export default FooterNavigationLink
