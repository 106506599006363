import { Action } from 'redux'
import { SHOPPING_BASKET_WIZARD_ADD_OR_REPLACE_ORDER_ITEM } from '../../../constants/shoppingBasketConstants'
import { CommerceOrderItem } from '../../../types/commerceApi'

export interface AddOrReplaceOrderItemAction
  extends Action<typeof SHOPPING_BASKET_WIZARD_ADD_OR_REPLACE_ORDER_ITEM> {
  payload: CommerceOrderItem
}

export const addOrReplaceOrderItem = (payload: CommerceOrderItem) => ({
  type: SHOPPING_BASKET_WIZARD_ADD_OR_REPLACE_ORDER_ITEM,
  payload,
})
