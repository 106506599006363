import React, { FC } from 'react'
import ContentSlider from '../ContentSlider/ContentSlider'
import ContentCard from '../../molecules/ContentCard/ContentCard'
import withData from '../../../enhancers/withData'
import EmptyGridBox from '../EmptyGridBox/EmptyGridBox'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import { Image } from '../../../types/dafResponseProps'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'

interface KnowledgeBaseDatasource {
  title: TextField
}

interface Slide {
  description: TextField
  link: LinkField
  image: Image
}

export interface KnowledgeBaseProps {
  knowledgeBaseItems: Slide[]
}

const KnowledgeBase: FC<
  Datasource<KnowledgeBaseDatasource> & KnowledgeBaseProps
> = ({
  datasource: { title },
  rendering: { componentName, uid },
  knowledgeBaseItems,
}) => {
  const { pageEditing } = useSitecoreContext()

  if (!knowledgeBaseItems?.length && pageEditing) {
    return <EmptyGridBox componentName={componentName} />
  }

  if (!knowledgeBaseItems?.length) return null

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid}>
      <ContentSlider title={getTextFieldValue(title)} slidesPerPage={6}>
        {knowledgeBaseItems.map((item, i) => (
          <ContentCard key={i.toString()} {...item} />
        ))}
      </ContentSlider>
    </AnchorMenuTarget>
  )
}

export default withData(KnowledgeBase)
