import React, { FC } from 'react'
import { Box, Flex, FlexProps, Text } from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Dealer } from '../../../types/dealerServiceTypes'
import DealerLocatorDealerDayOpeningHours from './DealerLocatorDealerDayOpeningHours'

interface DealerLocatorDealerWeekOpeningHoursProps extends Omit<FlexProps, 'css'> {
  dealer: Dealer
}

const DealerLocatorDealerWeekOpeningHours: FC<
  DealerLocatorDealerWeekOpeningHoursProps
> = ({ dealer, sx, ...props }) => {
  const { localizationContext } = useSitecoreContext()

  return (
    <Box sx={sx} {...props} data-t-id="CompanyOpeningSchedule">
      {[...Array(7)].map((_, i) => (
        <Flex key={i.toString()}>
          <Box sx={{ verticalAlign: 'top', width: '100px' }}>
            <Text
              as="span"
              variant="bodySmall"
              mr={3}
              sx={{
                lineHeight: 'menuList',
              }}
            >
              {localizationContext?.daysOfTheWeek?.[i]}
            </Text>
          </Box>

          <Box sx={{ verticalAlign: 'top', width: '100%' }}>
            <DealerLocatorDealerDayOpeningHours
              key={i.toString()}
              dayNumber={i + 1}
              dealer={dealer}
            />
          </Box>
        </Flex>
      ))}
    </Box>
  )
}

export default DealerLocatorDealerWeekOpeningHours
