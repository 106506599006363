import React, { ReactNode } from 'react'
import { Box, Text } from 'theme-ui'

interface FormGroupProps {
  title?: string
  children: ReactNode
}

export const FormGroup = ({ title, children }: FormGroupProps) => (
  <Box
    as="fieldset"
    sx={{
      border: 'none',
      padding: 0,
      marginBottom: [5, 5, 7],
      '> :last-child': {
        marginBottom: 0,
      },
    }}
  >
    {title && (
      <Text
        variant="heading3"
        as="legend"
        sx={{
          marginBottom: 4,
        }}
      >
        {title}
      </Text>
    )}

    {children}
  </Box>
)

export default FormGroup
