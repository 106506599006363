import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import toPascalCase from '../../../helpers/pascalCaseHelper'

type FormFiledTextTypes = 'error' | 'success' | 'info'

export interface FormFieldTextProps extends Omit<BoxProps, 'css'> {
  type: FormFiledTextTypes
}

const FormFieldText: FC<FormFieldTextProps> = ({ type, children, sx, ...rest }) => (
  <Box
    as="div"
    data-t-id={typeof children === 'string' ? toPascalCase(children) : undefined}
    sx={{
      fontSize: 0,
      color: type,
      lineHeight: 'smallText',
      mt: 1,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export default FormFieldText
