import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { CATEGORY_FILTER_ID } from '../constants/searchConstants'
import { SearchContext } from './SearchContextProvider'
import { ActiveSearchResultFilters } from '../types/filterProps'
import usePartSearchStatisticsService from '../hooks/services/rest/ecommerce/usePartSearchStatisticsService'
import { SearchStatistics } from '../services/rest/ecommerce/partSearch'
import {
  PROMOTION_API_TYPE_PROMOTIONS,
  PROMOTION_API_VALUE_DEAL,
} from '../constants/promotionConstants'

export interface PartSearchContextProps {
  fetching: boolean
  statistics?: SearchStatistics
  isPromotion?: boolean
}

export const PartSearchContext = createContext<PartSearchContextProps>({
  fetching: false,
  statistics: undefined,
})

interface PartSearchContextProviderProps {
  children: ReactNode
  isPromotion?: boolean
}

const PartSearchContextProvider = ({
  children,
  isPromotion = false,
}: PartSearchContextProviderProps) => {
  const {
    searchString,
    category: contextCategory,
    subcategory: contextSubcategory,
  } = useContext(SearchContext)

  const categoryFilterObject = useMemo((): ActiveSearchResultFilters => {
    const categoryId = contextSubcategory?.id || contextCategory?.id

    return categoryId
      ? {
          [CATEGORY_FILTER_ID]: [categoryId],
        }
      : isPromotion
      ? { [PROMOTION_API_TYPE_PROMOTIONS]: [PROMOTION_API_VALUE_DEAL] }
      : {}
  }, [contextCategory, contextSubcategory, isPromotion])

  const [fetchingSearchParts, statistics] = usePartSearchStatisticsService(
    searchString,
    categoryFilterObject
  )

  return (
    <PartSearchContext.Provider
      value={{
        fetching: fetchingSearchParts,
        statistics,
        isPromotion,
      }}
    >
      {children}
    </PartSearchContext.Provider>
  )
}
export default PartSearchContextProvider
