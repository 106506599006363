import React from 'react'
import Button, { ButtonVariants } from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import i18next from 'i18next'

interface MyFleetAddVehicleButtonProps {
  handleAddVehicleClick: () => void
  variant?: ButtonVariants
}

const MyFleetAddVehicleButton = ({
  handleAddVehicleClick,
  variant = 'outline',
}: MyFleetAddVehicleButtonProps) => (
  <Button
    data-t-id="add-vehicle-button"
    variant={variant}
    sx={{
      width: ['100%', null, 'auto'],
      '> div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }}
    onClick={handleAddVehicleClick}
    icon={ICONS.PLUS}
  >
    {i18next.t('addVehicleLabels.addVehicleButtonLabel')}
  </Button>
)

export default MyFleetAddVehicleButton
