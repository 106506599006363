import React, { FC, useContext, useMemo } from 'react'
import { Text } from 'theme-ui'
import Spinner from '../../atoms/Spinner/Spinner'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import PartQuantumPriceTable from './PartQuantumPriceTable'
import {
  getPartQuantumPriceTableColumns,
  getPartQuantumPriceTableRows,
} from '../../../helpers/partQuantumPriceTableHelper'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'
import PartQuantumPriceMobileTable from './PartQuantumPriceMobileTable'
import { TextField } from '../../../types/layoutService'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'

export interface PartQuantumPriceProps {
  title: TextField
  id: string
}

const PartQuantumPriceResponsive: FC<PartQuantumPriceProps> = ({ title, id }) => {
  const { priceInformation, fetching } = useContext(PartSalesInformationContext)
  const { dateFormatter } = useContext(LocalizationContext)
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)
  const currencyFormatter = useCurrencyFormatter(priceInformation?.price)

  const columns = useMemo(() => getPartQuantumPriceTableColumns(), [])
  const rows = useMemo(
    () =>
      priceInformation
        ? getPartQuantumPriceTableRows(
            currencyFormatter,
            dateFormatter,
            priceInformation?.price,
            true
          )
        : [],
    [currencyFormatter, dateFormatter, priceInformation]
  )

  if (fetching) {
    return <Spinner size={3} />
  }

  if (!rows.length) return null

  if (!breakpointM) {
    return (
      <PartMobileModalLink
        title={getTextFieldValue(title)}
        table={<PartQuantumPriceMobileTable columns={columns} rows={rows} />}
      />
    )
  }

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={id} mb={7}>
      {title && (
        <Text as="h2" variant="heading2" mb={4}>
          {getTextFieldValue(title)}
        </Text>
      )}

      <PartQuantumPriceTable rows={rows} columns={columns} />
    </AnchorMenuTarget>
  )
}

export default PartQuantumPriceResponsive
