import React, { useMemo } from 'react'
import { getCountryOptions } from '../../../helpers/requestAccountFormHelper'
import { FormInputSelectOptionProps } from '../FormControls/FormInputSelect'
import { SelectField, SelectFieldProps } from './SelectField'

export interface SelectCountryFieldProps extends Omit<SelectFieldProps, 'options'> {}

export const SelectCountryField = (props: SelectCountryFieldProps) => {
  const countryOptions = useMemo<FormInputSelectOptionProps[]>(
    () => getCountryOptions(),
    []
  )

  return <SelectField {...props} options={countryOptions} />
}

export default SelectCountryField
