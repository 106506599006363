import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Box, Text } from 'theme-ui'
import { resolveLocalizedUrl, resolveUrl } from '../../../helpers/linkFieldHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  BackgroundParams,
  Datasource,
  LinkField,
  Params,
  TextField,
} from '../../../types/layoutService'
import SearchBarForm from '../../molecules/SearchBar/SearchBarForm'
import SearchBarButton from '../../molecules/SearchBar/SearchBarButton'
import SearchBarInput from '../../molecules/SearchBar/SearchBarInput'
import withData from '../../../enhancers/withData'
import useDafVehicleReferenceService from '../../../hooks/services/rest/ecommerce/useDafVehicleReferenceService'
import { VehicleReference } from '../../../services/rest/ecommerce/dafVehicleReferences'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import {
  URL_QUERY_STRING_PARAM,
  URL_VEHICLE_SEARCH_QUERY_PARAM,
} from '../../../constants/urlConstants'
import { navigateClient, runOnWindow } from '../../../helpers/dom'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

interface VehicleSearchBlockDatasource {
  title: TextField
  description?: TextField
  vehicleSearchPage: LinkField
  placeholder: TextField
}

const getReferenceOptionLabel = (reference: VehicleReference) =>
  Object.values(reference)
    .map((value) => value)
    .filter((value) => value)
    .join(' | ')

const VehicleSearchBlock: FC<
  Datasource<VehicleSearchBlockDatasource> & Params<BackgroundParams>
> = ({
  datasource: { title, description, placeholder, vehicleSearchPage },
  params: { backgroundColor = 'DafGray' },
}) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const [isFetching, dafVehicleReferences] = useDafVehicleReferenceService()
  const [query, setQuery] = useState('')

  const trimmedSearchString = useMemo(() => query.trim(), [query])

  const bg = backgroundColor !== 'DafGray' ? 'gray.0' : 'gray.4'
  const color = backgroundColor !== 'DafGray' ? 'text' : 'textLight'
  const secondaryColor = backgroundColor !== 'DafGray' ? 'gray.2' : 'text'

  const submit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_FUNCTION,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.VEHICLE_SEARCH,
        search_query: trimmedSearchString,
        page_type: '',
      })

      navigateClient(
        `${resolveLocalizedUrl(
          vehicleSearchPage,
          cultureCode
        )}?${URL_VEHICLE_SEARCH_QUERY_PARAM}=${trimmedSearchString}`
      )
    },
    [cultureCode, trimmedSearchString, vehicleSearchPage]
  )

  useEffect(() => {
    const locationHref = runOnWindow((w) => w.location.href)

    if (locationHref?.includes(URL_QUERY_STRING_PARAM)) {
      const searchValue = getUrlQueryValue(URL_QUERY_STRING_PARAM)

      if (searchValue) {
        setQuery(searchValue)
      }
    }
  }, [])

  return (
    <Box sx={{ bg, padding: [4, 4, 4, 5] }}>
      <Text
        as="h3"
        variant="heading3"
        sx={{
          marginBottom: !description ? '3' : undefined,
          color,
        }}
      >
        {getTextFieldValue(title)}
      </Text>

      {description && (
        <Text
          as="p"
          variant="bodySmall"
          sx={{
            marginBottom: '3',
            lineHeight: 'menuList',
            color: secondaryColor,
          }}
        >
          {getTextFieldValue(description)}
        </Text>
      )}

      <SearchBarForm
        action={resolveUrl(vehicleSearchPage)}
        sx={{
          width: ['100%', '100%', '100%', '50%'],
        }}
        onSubmit={submit}
      >
        <SearchBarInput
          name={URL_VEHICLE_SEARCH_QUERY_PARAM}
          list="chassisNumbers"
          placeholder={getTextFieldValue(placeholder)}
          value={query}
          onChange={(ev) => setQuery(ev.currentTarget.value)}
        />

        <datalist id="chassisNumbers">
          {!isFetching &&
            dafVehicleReferences?.length &&
            dafVehicleReferences.map((reference) => (
              <option value={reference.chassisNumber} key={reference.chassisNumber}>
                {getReferenceOptionLabel(reference)}
              </option>
            ))}
        </datalist>

        <SearchBarButton />
      </SearchBarForm>
    </Box>
  )
}

export default withData(VehicleSearchBlock)
