import React, { FC, useMemo } from 'react'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import {
  ApiVariant,
  Datasource,
  Flag,
  NumberField,
  Params,
  SliderVariant,
  TextField,
} from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import { RecommendedPartSliderProps } from '../RecommendedPartSlider/RecommendedPartSlider'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartSliderSkeleton from '../../atoms/Skeleton/PartSliderSkeleton'
import PartCardListResponsive from '../CardList/PartCardListResponsive'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import usePageEvents from '../../../hooks/usePageEvents'
import usePartListService from '../../../hooks/services/graphql/usePartListService'
import { Part } from '../../../types/dafResponseProps'

export interface PartMostVisitedSliderDatasource {
  title: TextField
  minimumNumberOfVisits: NumberField
  maximumNumberOfParts: NumberField
}

export interface PartMostVisitedSliderParams {
  variant: SliderVariant
  apiVariant: ApiVariant
  topSpacing: Flag
  bottomSpacing: Flag
}

type PartMostVisitedSliderType = FC<
  Datasource<PartMostVisitedSliderDatasource> &
    RecommendedPartSliderProps &
    Params<PartMostVisitedSliderParams>
>

const PartMostVisitedSlider: PartMostVisitedSliderType = ({
  params: { variant, topSpacing, bottomSpacing },
  datasource,
  partPage,
}) => {
  const { title, minimumNumberOfVisits, maximumNumberOfParts } = datasource

  const [readPageEvents] = usePageEvents()

  const partNumbers = useMemo<string[]>(
    () =>
      readPageEvents({
        category: 'PART_DETAIL_PAGE_VISIT',
        minCount: minimumNumberOfVisits.numberValue,
        limit: maximumNumberOfParts.numberValue,
      }),
    [readPageEvents]
  )

  const [fetchingPartList, partList] = usePartListService(partNumbers)

  const partsInOrderOfPartVisit = useMemo(
    () =>
      partNumbers
        .map((partNumber) =>
          partList?.find((part) => part.partNumber === partNumber)
        )
        .filter((part): part is Part => !!part),
    [partNumbers, partList]
  )

  if (!partsInOrderOfPartVisit?.length) return null

  return (
    <PartCardListResponsive
      title={getTextFieldValue(title)}
      variant={variant}
      partList={partsInOrderOfPartVisit}
      partPage={partPage}
      fetching={fetchingPartList}
      topSpacing={topSpacing === '1'}
      bottomSpacing={bottomSpacing === '1'}
      dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_MOST_VISITED_SLIDER}
    />
  )
}

export default withClientSideRender(
  withData(PartMostVisitedSlider, {
    pageEditingComponent: PartSliderSkeleton as PartMostVisitedSliderType,
    showPageEditingComponentWhenPreviewing: true,
  })
)
