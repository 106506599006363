import React, { useContext } from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import OrderStickySummaryWayOfTransport from '../OrderStickySummary/OrderStickySummaryWayOfTransport'
import OrderStickySummaryTotalPrice from '../OrderStickySummary/OrderStickySummaryTotalPrice'
import OrderStickySummaryNavigationButtons from '../OrderStickySummary/OrderStickySummaryNavigationButtons'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import OrderStickySummaryLoyaltyPoints from '../OrderStickySummary/OrderStickySummaryLoyaltyPoints'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'

const OrderSummary = () => {
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { orderSummary } = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel
  )
  const [expanded, setExpanded] = React.useState(false)

  return (
    <Box
      sx={{
        backgroundColor: 'gray.0',
        padding: [3, 3, 4],
      }}
    >
      <ClickableWrapper
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: [null, null, null, null, 'none'],
          position: 'absolute',
          insetBlockEnd: '100%',
          insetInlineEnd: 3,
          backgroundColor: 'gray.0',
          borderStartStartRadius: 'default',
          borderStartEndRadius: 'default',
          color: 'primary',
          paddingInline: 2,
        }}
      >
        <Box variant="visuallyHidden">Toggle details</Box>
        <IconWrapper icon={ICONS.ARROW_UP} size={4} rotate={expanded ? 180 : 0} />
      </ClickableWrapper>

      <Box
        sx={{
          display: [expanded ? 'block' : 'none', null, null, null, 'block'],
        }}
      >
        <Text as="h2" variant="heading2" sx={{ marginBlockEnd: 4 }}>
          {i18next.t('commonLabels.overview')}
        </Text>
      </Box>

      <Text
        as="dl"
        variant="bodySmall"
        sx={{
          '.dlGroup': {
            display: 'flex',
            justifyContent: 'space-between',
            marginBlockEnd: 2,
          },
          dd: {
            fontWeight: 'bold',
            textAlign: 'end',
          },
        }}
      >
        <Box
          sx={{
            display: [expanded ? 'block' : 'none', null, null, null, 'block'],
            '> :last-of-type': {
              borderBlockEnd: '1px solid',
              borderColor: 'gray.1',
              marginBlockEnd: 3,
              paddingBlockEnd: 2,
            },
          }}
        >
          {order?.customerOrderNumber?.value && (
            <div className="dlGroup">
              <dt>
                {order.isR2CEnabled && !isImpersonated ? (
                  <>{i18next.t('r2cLabels.r2cJobsheetNumber')}</>
                ) : (
                  <>{i18next.t('shoppingLabels.orderNumber')}</>
                )}
              </dt>
              <dd>{order?.customerOrderNumber?.value}</dd>
            </div>
          )}

          {order?.customerOrderReference?.value && (
            <div className="dlGroup">
              <dt>{i18next.t('shoppingLabels.orderReference')}</dt>
              <dd>{order?.customerOrderReference?.value}</dd>
            </div>
          )}

          {orderSummary?.customerLoyalty && (
            <div>
              <Box
                sx={{
                  display: [null, 'none'],
                }}
              >
                <Box as="div" className="dlGroup">
                  <OrderStickySummaryLoyaltyPoints />
                </Box>
              </Box>
            </div>
          )}

          {!!order?.wayOfTransportOptions?.length && (
            <Box
              sx={{
                marginBlockStart: 4,
                display: ['none', null, null, null, 'block'],
              }}
            >
              <div className="dlGroup">
                <OrderStickySummaryWayOfTransport />
              </div>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
            paddingBlockEnd: [2, 3],
            marginBlockEnd: [3, 4],
          }}
        >
          {!!order?.wayOfTransportOptions?.length && (
            <Box
              sx={{
                display: [null, null, null, null, 'none'],
              }}
            >
              <div className="dlGroup">
                <OrderStickySummaryWayOfTransport />
              </div>
            </Box>
          )}

          <div className="dlGroup">
            <OrderStickySummaryTotalPrice />
          </div>

          {orderSummary?.customerLoyalty &&
            orderSummary.customerLoyalty.loyaltyPoints !== null && (
              <Box
                sx={{
                  display: ['none', null, null, null, 'block'],
                }}
              >
                <div className="dlGroup">
                  <OrderStickySummaryLoyaltyPoints />
                </div>
              </Box>
            )}
        </Box>
      </Text>

      <OrderStickySummaryNavigationButtons />
    </Box>
  )
}

export default OrderSummary
