import React, { FC, useCallback, useContext, useMemo } from 'react'
import i18next from 'i18next'
import { DEFAULT_PART_QUANTITY } from '../../../constants/partSalesInformationConstants'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import { loggedIn } from '../../../helpers/userHelper'
import usePartsListService from '../../../hooks/services/graphql/usePartListService'
import PartListSalesInformationProvider from '../../../providers/PartListSalesInformationProvider'
import { UserContext } from '../../../providers/UserProvider'
import { CommerceBomMaster, CommerceSpecification } from '../../../types/commerceApi'
import { LinkField } from '../../../types/layoutService'
import Spinner from '../../atoms/Spinner/Spinner'
import PartBillOfMaterialMaster from './PartBillOfMaterialMaster'
import { Part } from '../../../types/dafResponseProps'
import { PartNumberQuantityInput } from '../../../hooks/services/graphql/usePartSalesInformationService'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { measurePartClick } from '../../../helpers/analyticsHelper'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import PartLine from '../../molecules/PartLine/PartLine'
import PartLineTitle from '../../molecules/PartLine/PartLineTitle'

interface PartBillOfMaterialMastersTableProps {
  bomMasters?: CommerceBomMaster[]
  partsPageUri: LinkField
  componentOrigin?: string
}

const getPartNumbers = (bomMasters?: CommerceBomMaster[]): string[] =>
  bomMasters
    ?.map((billOfMaterialPart) => billOfMaterialPart.partNumber)
    .filter((partNumber): partNumber is string => !!partNumber) || []

const combineParts = (
  bomMasters: CommerceBomMaster[],
  parts: Part[]
): {
  bomMaster: CommerceBomMaster
  bomMasterSpecification: CommerceSpecification | undefined
  englishDescription?: string
}[] =>
  bomMasters.map((bomMaster) => {
    const correspondingPart = parts.find(
      (part) => part.partNumber === bomMaster.partNumber
    )

    return {
      bomMaster,
      bomMasterSpecification:
        correspondingPart?.specification as CommerceSpecification, // Temporary cast as part service returns other type
      englishDescription: correspondingPart?.englishDescription,
    }
  })

export const gridTemplate = {
  columnSizes: ['1fr', '1fr', '1fr 2fr 1fr'], // css prop: grid-template-columns
  columnSizesLoggedIn: ['1fr', '1fr', '1fr 2fr 1fr 1fr 2fr'], // css prop: grid-template-columns
}

const PartBillOfMaterialMastersTable: FC<PartBillOfMaterialMastersTableProps> = ({
  bomMasters,
  partsPageUri,
  componentOrigin,
}) => {
  const { user } = useContext(UserContext)
  const { categories } = useContext(CategoriesContext)

  const partNumbers = useMemo(() => getPartNumbers(bomMasters), [bomMasters])

  const [fetching, parts] = usePartsListService(partNumbers)

  const enrichedBomMasters = useMemo(
    () =>
      (!fetching &&
        bomMasters &&
        !!bomMasters.length &&
        !!parts &&
        combineParts(bomMasters, parts)) ||
      [],
    [bomMasters, fetching, parts]
  )

  const partNumberQuantityInputs = useMemo<PartNumberQuantityInput[]>(
    () =>
      enrichedBomMasters.map(({ bomMaster }) => ({
        partNumber: bomMaster.partNumber,
        quantity: DEFAULT_PART_QUANTITY,
      })),
    [enrichedBomMasters]
  )

  const measureClick = useCallback(
    (part: Part, index: number) =>
      measurePartClick(
        part.partNumber,
        part.englishDescription,
        part.brand?.description,
        part.category?.mainCategoryId,
        part.category?.subCategoryId,
        index,
        categories,
        componentOrigin || DATA_LAYER.COMPONENT_ORIGIN.PART_BOM_MASTER
      ),
    [categories, componentOrigin]
  )

  if (fetching) return <Spinner size={3} />

  if (!enrichedBomMasters?.length) return null

  return (
    <PartListSalesInformationProvider
      partNumbersQuantity={partNumberQuantityInputs}
      includePrice
      includeStock
    >
      <PartLine
        gridTemplate={gridTemplate}
        py={3}
        sx={{
          display: ['none', 'none', 'grid'],
        }}
      >
        <PartLineTitle />

        <PartLineTitle>{i18next.t('commonLabels.product')}</PartLineTitle>

        <PartLineTitle>{i18next.t('commonLabels.brand')}</PartLineTitle>

        {loggedIn(user) && (
          <PartLineTitle>{i18next.t('stockLabels.availability')}</PartLineTitle>
        )}

        {loggedIn(user) && (
          <PartLineTitle>{i18next.t('partSalesLabels.price')}</PartLineTitle>
        )}
      </PartLine>

      {enrichedBomMasters.map((enrichedBomMaster, i) => (
        <PartBillOfMaterialMaster
          {...enrichedBomMaster}
          partsPageUri={partsPageUri}
          key={i.toString()}
          onClick={() => measureClick(enrichedBomMaster, i)}
          gridTemplate={gridTemplate}
        />
      ))}
    </PartListSalesInformationProvider>
  )
}

export default withClientSideRender(PartBillOfMaterialMastersTable)
