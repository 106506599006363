import Cookies from 'universal-cookie'
import { ActiveUserState } from '../types/layoutService'

export const decodeActiveUserStateCookie = (
  base64EncodedCookie?: string
): ActiveUserState | undefined => {
  if (!base64EncodedCookie) return undefined

  try {
    const activeUserStateJson = atob(base64EncodedCookie)
    return JSON.parse(activeUserStateJson)
  } catch {
    return undefined
  }
}

export const encodeActiveUserStateCookie = (
  activeUserState: ActiveUserState
): string => {
  const activeUserStateJson = JSON.stringify(activeUserState)
  const base64EncodedCookie = btoa(activeUserStateJson)
  return base64EncodedCookie
}

export const getCookie = (cookieName: string) => {
  const cookies = new Cookies()

  return cookies.get(cookieName)
}
