import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  LinkField,
  TextField as TextFieldProps,
} from '../../../types/layoutService'
import RequestAccountForm from './RequestAccountForm'
import { LayoutProps } from '../../../types/layoutProps'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

export interface RequestAccountDatasource {
  title: TextFieldProps
  selectDealerNotice: TextFieldProps
  consentText: TextFieldProps
  errorText: TextFieldProps
  submitLabel: TextFieldProps
  thankYouPage: LinkField
  privacyStatementText: TextFieldProps
  addressInformationTitle: TextFieldProps
  contactInformationTitle: TextFieldProps
  context: LayoutProps
}

export interface RequestAccountFormData {
  consent?: boolean
  customerCompanyAddress?: string
  customerCompanyCity?: string
  customerCompanyCountryCode?: string
  customerCompanyName?: string
  vatNumber?: string
  chamberOfCommerceNumber?: string
  customerCompanyPostalCode?: string
  dealerCompanyLocationCode?: string
  personEmail?: string
  personFirstName?: string
  personLastName?: string
  personPhoneNumber?: string
  selectedDealerCompany?: string
}

const RequestAccount = ({ datasource }: Datasource<RequestAccountDatasource>) => {
  const { reCaptcha } = useSitecoreContext()

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptcha?.siteKey ?? ''}>
      <RequestAccountForm datasource={datasource} />
    </GoogleReCaptchaProvider>
  )
}

export default withData(RequestAccount, {
  showMessageWhenPageEditing: true,
})
