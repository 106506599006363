import React, { FC, useEffect, useRef } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'
import { Dealer } from '../../../types/dealerServiceTypes'
import DealerLocatorDealerAddress from './DealerLocatorDealerAddress'

interface DealerLocatorDealerInfoProps extends Omit<BoxProps, 'css'> {
  dealer: Dealer
  excludeDealerName?: boolean
  setColumnSpace?: (space: number) => void
}

const DealerLocatorDealerInfo: FC<DealerLocatorDealerInfoProps> = ({
  dealer,
  setColumnSpace,
  excludeDealerName = false,
  sx,
  ...props
}) => {
  const dealerNameRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!setColumnSpace) return

    if (dealerNameRef?.current) {
      setColumnSpace(dealerNameRef.current.getBoundingClientRect().height)
    }
  }, [setColumnSpace])

  return (
    <Box sx={sx} {...props} data-t-id="CompanyAddress">
      {!excludeDealerName && (
        <Text
          as="h4"
          ref={dealerNameRef}
          variant="heading3"
          sx={{
            color: 'text',
            lineHeight: 'menuList',
          }}
        >
          {dealer.name}
        </Text>
      )}

      {dealer.address && <DealerLocatorDealerAddress address={dealer.address} />}
    </Box>
  )
}

export default DealerLocatorDealerInfo
