import React, { useContext, useState } from 'react'
import CardFrame from '../CardFrame'
import CardImage from '../CardImage'
import CardTitle from '../CardTitle'
import AddToBasketQuantitySpinner from '../../AddToBasket/AddToBasketQuantitySpinner/AddToBasketQuantitySpinner'
import Button from '../../../atoms/Button/Button'
import { Box, Flex, Text } from 'theme-ui'
import { ICONS } from '../../../../constants/iconConstants'
import i18next from 'i18next'
import { formatNumber } from '../../../../helpers/numberFormatHelper'
import { getFormattedPriceString } from '../../../../helpers/priceHelper'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { RewardsContext } from '../../../../providers/RewardsProvider'
import RewardCardCreditVoucherCard from './RewardCardCreditVoucherCard'
import {
  REWARD_TYPE_CREDIT_MERCHANDISE,
  REWARD_TYPE_PRODUCT_MERCHANDISE,
} from '../../../../constants/loyaltyConstants'

const PART_CARD_VARIANT_MINIMAL = 'minimal'
const PART_CARD_VARIANT_FULL = 'full'

export interface RewardProps {
  rewardId: number
  type:
    | typeof REWARD_TYPE_CREDIT_MERCHANDISE
    | typeof REWARD_TYPE_PRODUCT_MERCHANDISE
  creditValue?: {
    currencyCode?: string
    value?: number
  }
  productName?: string
  productDetailUrl?: string
  productImageUrl?: string
  costPriceInPoints: number
  isAvailableForMe: boolean
  variant?: typeof PART_CARD_VARIANT_MINIMAL | typeof PART_CARD_VARIANT_FULL
}

const RewardCard = ({
  rewardId,
  type,
  creditValue,
  productName,
  productDetailUrl,
  productImageUrl,
  costPriceInPoints,
  isAvailableForMe,
  variant = PART_CARD_VARIANT_FULL,
}: RewardProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const [quantity, setQuantity] = useState<number>(1)

  const {
    setRewardSidePanelOpen,
    setRewardSidePanelReward,
    rewardsData,
    setRewardSidePanelQuantity,
  } = useContext(RewardsContext)

  const handleClickRedeem = () => {
    setRewardSidePanelOpen(true)
    setRewardSidePanelReward(
      rewardsData?.rewards.find((reward) => reward.rewardId === rewardId)
    )
    setRewardSidePanelQuantity(quantity)
  }

  const handleChangeQuantity = (quantity: number) => {
    setQuantity(quantity)
  }

  return (
    <CardFrame hasLink={isAvailableForMe} variant={variant}>
      <Box
        sx={{
          opacity: isAvailableForMe ? 1 : 0.4,
        }}
      >
        {type === REWARD_TYPE_CREDIT_MERCHANDISE ? (
          <>
            <RewardCardCreditVoucherCard
              variant={variant}
              creditValue={creditValue}
            />

            <CardTitle
              title={`${getFormattedPriceString(
                cultureCode,
                creditValue?.currencyCode,
                creditValue?.value
              )} ${i18next.t('shoppingLabels.customerLoyalty.credit')}`}
            />
          </>
        ) : (
          <>
            <CardImage src={productImageUrl} title={productName} />
            <CardTitle title={productName} />
          </>
        )}

        <Text as="p" variant="bodySmall" color="gray.2">
          {i18next.t('shoppingLabels.customerLoyalty.merchandiseStoreVoucher')}
        </Text>

        <Text
          as="p"
          variant="bodySmall"
          color="gray.3"
          sx={{
            marginBlock: 16,
          }}
        >
          <b>{formatNumber(costPriceInPoints, cultureCode)}</b>&nbsp;
          {i18next.t('shoppingLabels.customerLoyalty.points')}
        </Text>
      </Box>

      <Flex
        sx={{
          alignItems: 'start',
          justifyContent: 'space-between',
          marginBlock: 16,
          gap: 3,
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            zIndex: 2,
          }}
        >
          <AddToBasketQuantitySpinner
            stepSize={1}
            initialQuantity={1}
            maxQuantity={10}
            onChange={handleChangeQuantity}
            variant="small"
            disabled={!isAvailableForMe}
            debounceInput={false}
          />
        </Box>

        <Box
          sx={{
            zIndex: 1,
            inlineSize: variant === PART_CARD_VARIANT_MINIMAL ? null : '100%',
          }}
        >
          <Button
            variant="primary"
            icon={isAvailableForMe ? ICONS.REDEEM : ICONS.LOCK}
            iconSize={4}
            disabled={!isAvailableForMe}
            hideLabel={variant === PART_CARD_VARIANT_MINIMAL}
            onClick={handleClickRedeem}
            sx={{
              width: '100%',
              '::after': {
                content: '""',
                position: 'absolute',
                inset: 0,
                zIndex: 1,
              },
            }}
          >
            {i18next.t('shoppingLabels.customerLoyalty.redeem')}
          </Button>
        </Box>
      </Flex>

      {productDetailUrl && (
        <Button
          as="a"
          href={productDetailUrl}
          target="_blank"
          icon={ICONS.EXIT}
          iconSize={4}
          iconPosition="end"
          variant="infinite"
          color="primary"
          sx={{
            position: 'relative',
            zIndex: 2,
          }}
        >
          {i18next.t('shoppingLabels.customerLoyalty.productDetails')}
        </Button>
      )}
    </CardFrame>
  )
}

export default RewardCard
