import React from 'react'
import { BoxProps, Image } from 'theme-ui'

interface AvatarProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css' | 'content'>> {
  image: string
  alt: string
}

const Avatar = ({ image, sx, alt }: AvatarProps) => (
  <Image
    alt={alt}
    src={image}
    sx={{
      position: 'relative',
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      ...sx,
    }}
  />
)

export default Avatar
