import React from 'react'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import useFooterLegalLinkListService from '../../../hooks/services/graphql/useFooterLegalLinkListService'
import Container from '../../atoms/Container/Container'
import FooterLegalLink from './FooterLegalLink'

const FooterLegalLinks = () => {
  const [fetching, footerLegalLinkList] = useFooterLegalLinkListService()

  if (fetching || !footerLegalLinkList?.length) return null

  return (
    <Container mb={[4, 4, 5]}>
      {footerLegalLinkList.map((link, i) => (
        <FooterLegalLink key={i.toString()} {...link} />
      ))}
    </Container>
  )
}

export default withClientSideRender(FooterLegalLinks)
