import React, { FC, useCallback, useContext } from 'react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { ICONS } from '../../../constants/iconConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { resolveLocalizedUrl } from '../../../helpers/linkFieldHelper'
import { loggedIn } from '../../../helpers/userHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { UserContext } from '../../../providers/UserProvider'
import { LinkField } from '../../../types/layoutService'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Link, { LinkProps } from '../../atoms/Link/Link'

interface SearchBarUploadButtonProps extends LinkProps {
  linkField: LinkField
}

const SearchBarUploadButton: FC<SearchBarUploadButtonProps> = ({
  linkField,
  sx,
  ...linkProps
}) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const { user } = useContext(UserContext)

  const clickAction = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SCAN_BUTTON,
      scan_option: DATA_LAYER.CUSTOM_DIMENSION.SCAN_OPTION.SCAN,
      search_query: '',
      page_type: '',
    })
  }, [])

  if (!loggedIn(user) || !linkField?.url) return null

  return (
    <Link
      onClick={clickAction}
      href={resolveLocalizedUrl(linkField, cultureCode)}
      data-t-id="scan-upload-button"
      sx={{
        display: 'block',
        outline: 'none',
        color: 'text',
        ':hover, :focus, :active': {
          color: 'primary',
        },
        ...sx,
      }}
      {...linkProps}
    >
      <IconWrapper icon={ICONS.CAMERA} size={5} />
    </Link>
  )
}

export default SearchBarUploadButton
