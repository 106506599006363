import { resolveEnvironment } from './environment'
import {
  ENVIRONMENT_DEVELOPMENT,
  ENVIRONMENT_LOCAL,
  ENVIRONMENT_TEST,
} from '../constants/environmentConstants'

export const shouldUseLocalCommerceApiBaseUrl = (): boolean => {
  const environment = resolveEnvironment()
  const isDevelopOrTest =
    environment?.name === ENVIRONMENT_LOCAL ||
    environment?.name === ENVIRONMENT_DEVELOPMENT ||
    environment?.name === ENVIRONMENT_TEST
  const url = new URL(window.location.href)
  const debugParam = url.searchParams.get('debug')

  return isDevelopOrTest && debugParam === 'true'
}
