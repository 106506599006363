import { useTheme } from '@emotion/react'
import React, { FC, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { PERSISTED_SEARCH_RESULTS_VIEW_TYPE } from '../../../../constants/searchConstants'
import { URL_SEARCH_VIEW_TYPE } from '../../../../constants/urlConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import { SearchContext } from '../../../../providers/SearchContextProvider'
import { DEFAULT_VIEW_TYPES } from './SearchControlsViewTypeSwitcher'
import ClickableWrapper, {
  ClickableWrapperProps,
} from '../../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import {
  getCategoryById,
  getSubCategoryById,
} from '../../../../helpers/categoryHelper'

interface SearchControlsViewTypeSwitcherButtonProps extends ClickableWrapperProps {
  icon: string
  buttonViewType: string
  active: boolean
}

const SearchControlsViewTypeSwitcherButton: FC<
  SearchControlsViewTypeSwitcherButtonProps
> = ({ icon, buttonViewType, active, ...props }) => {
  const { categories } = useContext(CategoriesContext)
  const { transitions } = useTheme()
  const history = useHistory()
  const { category, subcategory } = useContext(SearchContext)

  const updateViewType = useCallback(() => {
    const params = new URLSearchParams(history.location.search)
    const mainCategory = getCategoryById(category?.id, categories)
    const subCategory = getSubCategoryById(subcategory?.id, mainCategory)

    if (DEFAULT_VIEW_TYPES.includes(buttonViewType)) {
      sessionStorage.removeItem(PERSISTED_SEARCH_RESULTS_VIEW_TYPE)
      params.delete(URL_SEARCH_VIEW_TYPE)
    } else {
      sessionStorage.setItem(PERSISTED_SEARCH_RESULTS_VIEW_TYPE, buttonViewType)
      params.set(URL_SEARCH_VIEW_TYPE, buttonViewType)
    }

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CHANGE_VIEW_MODE,
      main_category: mainCategory?.englishDescription || '',
      sub_category: subCategory?.englishDescription || '',
      view_name: buttonViewType,
    })

    history.push(`?${params.toString()}`)
  }, [history, category, categories, subcategory, buttonViewType])

  return (
    <ClickableWrapper
      onClick={() => updateViewType()}
      sx={{
        color: active ? 'primary' : 'gray.1',
        transition: `ease all ${transitions[1]}`,
        ':hover': {
          color: 'primary',
        },
      }}
      {...props}
    >
      <IconWrapper icon={icon} />
    </ClickableWrapper>
  )
}

export default SearchControlsViewTypeSwitcherButton
