import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { CATEGORY_FILTER_ID, VIEW_TYPE_TABLE } from '../constants/searchConstants'
import useSitecoreContext from '../hooks/useSitecoreContext'
import { SearchContext } from './SearchContextProvider'
import { ActiveSearchResultFilters, FilterGroup } from '../types/filterProps'
import useFulfilledPartSearchService, {
  PartFulFilled,
} from '../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { PaginationInfo } from '../types/layoutService'
import { URL_FILTER_PARAMETER, URL_PAGE_PARAM } from '../constants/urlConstants'
import { CommerceCategory } from '../types/commerceApi'
import { CategoriesContext } from './CategoriesProvider'
import {
  getSearchResultViewType,
  getSearchViewTypeFromUrl,
} from '../helpers/partContextHelper'
import { mapFilterStringToActiveSearchResultFilterObject } from '../helpers/filterHelper'
import { getFlattenedCategoryTree } from '../helpers/categoryHelper'
import { getPartsPerPageByViewType } from '../helpers/searchResultsHelper'
import usePrevious from '../hooks/usePrevious'
import useClassInformationListService, {
  Class,
} from '../hooks/services/graphql/useClassInformationListService'
import {
  PROMOTION_API_TYPE_PROMOTIONS,
  PROMOTION_API_VALUE_DEAL,
} from '../constants/promotionConstants'

export interface PartSearchResultContextProps {
  fetching: boolean
  parts?: PartFulFilled[]
  pagination?: PaginationInfo
  availableFilters?: FilterGroup[]
  availableCategories?: string[]
  availableClassInformation?: Class[]
  activeFilters: ActiveSearchResultFilters | undefined
  viewType?: CommerceCategory['viewType']
  initialViewType?: CommerceCategory['viewType']
  totalCount?: number
}

export const PartSearchResultContext = createContext<PartSearchResultContextProps>({
  fetching: false,
  parts: undefined,
  pagination: undefined,
  availableFilters: undefined,
  availableCategories: undefined,
  availableClassInformation: undefined,
  activeFilters: undefined,
  viewType: undefined,
  initialViewType: undefined,
  totalCount: undefined,
})

interface PartSearchResultContextProviderProps {
  children: ReactNode
  isPromotion?: boolean
}

const PartSearchResultContextProvider = ({
  children,
  isPromotion,
}: PartSearchResultContextProviderProps) => {
  const { search } = useLocation()
  const {
    searchString,
    viewType: contextViewType,
    category,
    subcategory,
  } = useContext(SearchContext)
  const { paginationSettings } = useSitecoreContext()
  const { categories } = useContext(CategoriesContext)

  const [fetching, setFetching] = useState(true)
  const [pagination, setPagination] = useState<PaginationInfo | undefined>(undefined)

  const urlQueryString = useMemo(() => new URLSearchParams(search), [search])
  const urlPageNumber = useMemo(
    () => urlQueryString.get(URL_PAGE_PARAM),
    [urlQueryString]
  )
  const urlFilterString = useMemo(
    () => urlQueryString.get(URL_FILTER_PARAMETER) || undefined,
    [urlQueryString]
  )

  const [pageIndex, urlViewType] = useMemo(() => {
    const newUrlViewType = getSearchViewTypeFromUrl(urlQueryString)
    const newPageIndex = urlPageNumber ? parseInt(urlPageNumber, 10) - 1 : 0

    return [newPageIndex, newUrlViewType]
  }, [urlPageNumber, urlQueryString])

  const [urlFilterObject, filterCategory] = useMemo(() => {
    const newUrlFilterObject =
      mapFilterStringToActiveSearchResultFilterObject(urlFilterString) || {}
    const newFilterCategory = newUrlFilterObject?.[CATEGORY_FILTER_ID]

    return [newUrlFilterObject, newFilterCategory]
  }, [urlFilterString])

  const previousUrlViewType = usePrevious(urlViewType)

  const [viewType, initialViewType, partsPerPage, activeFilters] = useMemo(() => {
    const overrideCategoryId = filterCategory?.[0] || subcategory?.id || category?.id
    const overrideCategory =
      overrideCategoryId && categories && !!categories?.length
        ? getFlattenedCategoryTree(overrideCategoryId, categories)?.pop()
        : undefined
    const categoryViewType = getSearchResultViewType(overrideCategory, searchString)

    let newViewType = urlViewType || contextViewType || categoryViewType

    if (urlViewType === undefined && previousUrlViewType === contextViewType) {
      newViewType = categoryViewType
    }

    const newPartsPerPage = getPartsPerPageByViewType(
      paginationSettings,
      newViewType
    )

    const newActiveFilters = overrideCategory
      ? { ...urlFilterObject, [CATEGORY_FILTER_ID]: [overrideCategory.id] }
      : urlFilterObject

    if (
      isPromotion &&
      !Object.hasOwn(newActiveFilters, PROMOTION_API_TYPE_PROMOTIONS)
    ) {
      // Add 'Promotions' and 'Deal' to activeFilters
      newActiveFilters[PROMOTION_API_TYPE_PROMOTIONS] = [PROMOTION_API_VALUE_DEAL]
    }

    return [newViewType, categoryViewType, newPartsPerPage, newActiveFilters]
    // contextViewType & searchString are constant and available on page load
  }, [filterCategory, categories, urlFilterObject, urlViewType])

  const [fetchingParts, parts, availableFilters, availableCategories, totalCount] =
    useFulfilledPartSearchService(
      searchString,
      activeFilters,
      pageIndex,
      partsPerPage
    )

  const classCodeList = useMemo(
    () =>
      !fetchingParts && parts && viewType === VIEW_TYPE_TABLE
        ? [...new Set(parts.map(({ searchPart }) => searchPart?.classCode))]
        : [],
    [fetchingParts, parts, viewType]
  )
  const [fetchingClassInformation, availableClassInformation] =
    useClassInformationListService(classCodeList)

  useEffect(() => {
    if (totalCount && partsPerPage) {
      const totalPageCount = Math.ceil(totalCount / partsPerPage)

      setPagination({
        totalCount: totalPageCount,
        previousIndex: pageIndex && pageIndex > 0 ? pageIndex - 1 : null,
        currentIndex: pageIndex,
        nextIndex: pageIndex < totalPageCount - 1 ? pageIndex + 1 : null,
      })
    }
  }, [pageIndex, partsPerPage, totalCount])

  useEffect(() => {
    if (!fetchingParts && !fetchingClassInformation) {
      setFetching(false)
    } else {
      setFetching(true)
    }
  }, [fetchingParts, fetchingClassInformation])

  return (
    <PartSearchResultContext.Provider
      value={{
        fetching,
        parts,
        pagination,
        activeFilters,
        availableFilters,
        availableCategories,
        availableClassInformation,
        viewType,
        initialViewType,
        totalCount,
      }}
    >
      {children}
    </PartSearchResultContext.Provider>
  )
}
export default PartSearchResultContextProvider
