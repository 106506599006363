import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { FooterLegalLink as FooterLegalLinkProps } from '../../../hooks/services/graphql/useFooterLegalLinkListService'
import Link from '../../atoms/Link/Link'
import FooterLegalLinkMessage from './FooterLegalLinkMessage'

const FooterLegalLink: FC<FooterLegalLinkProps> = (props) => {
  const { transitions } = useTheme()
  const { url, description, message } = props

  if (!url && !message) return null

  if (!url && message) return <FooterLegalLinkMessage {...props} />

  return (
    <Link
      href={url}
      target={url?.startsWith('/') ? '' : '_BLANK'}
      sx={{
        display: 'inline-block',
        fontSize: 0,
        color: 'textLight',
        fontWeight: 'normal',
        textDecoration: 'none',
        outline: 'none',
        lineHeight: 'body',
        marginBottom: [1, 1, 0],
        transitionDuration: transitions[1],
        transitionProperty: 'opacity',
        opacity: 0.64,
        ':hover, :focus, :active': {
          opacity: 1,
        },
        '::after': {
          position: 'relative',
          content: '"|"',
          display: 'inline',
          fontSize: '1',
          color: 'textLight',
          fontWeight: 'normal',
          mx: 2,
        },
        '&:last-child': {
          '::after': { display: 'none' },
        },
      }}
    >
      {description}
    </Link>
  )
}

export default FooterLegalLink
