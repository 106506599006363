import React, { FC, ReactNode } from 'react'
import List from '../../../atoms/List/List'
import { Bullet } from '../../../../types/dafResponseProps'
import Modal, { ModalProps } from '../Modal'
import ModalBodyContent from '../ModalBodyContent'

export interface ProductPropertiesModalProps extends ModalProps {
  title: string
  bullets?: Bullet[]
  table?: ReactNode
}

const ProductPropertiesModal: FC<ProductPropertiesModalProps> = ({
  title,
  bullets,
  table,
  onClose,
  isActive,
}) => (
  <Modal isActive={isActive} onClose={onClose} title={title}>
    <ModalBodyContent
      sx={{
        '.table-row:first-of-type': {
          borderTop: 'none',
        },
      }}
    >
      {bullets && !!bullets.length && (
        <List
          items={bullets}
          pl={3}
          mb={5}
          sx={{
            li: {
              color: 'gray.2',
              '::before': {
                color: 'primary',
              },
            },
          }}
        />
      )}

      {table && table}
    </ModalBodyContent>
  </Modal>
)
export default ProductPropertiesModal
