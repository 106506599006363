import React, { FC, useCallback, useContext } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { EVENT_CATEGORIES } from '../../../constants/analyticsConstants'
import { TextField } from '../../../types/layoutService'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import AddToBasket from './AddToBasket'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'

interface DealerPartAddToBasketProps extends Omit<BoxProps, 'css' | 'label'> {
  partNumber?: string
  partDescription?: string
  brandName?: string
  label?: TextField
  askQuotationLabel?: TextField
  variant?: 'small'
  dataLayerComponentOrigin: string
}

const DealerPartAddToBasket: FC<DealerPartAddToBasketProps> = ({
  partNumber,
  partDescription,
  brandName,
  label,
  askQuotationLabel,
  variant,
  dataLayerComponentOrigin,
  ...boxProps
}) => {
  const { priceInformation } = useContext(PartSalesInformationContext)

  const onOrder = useCallback(
    (quantity: number) => {
      pushToDataLayer({
        event: DATA_LAYER.EVENT_NAME.ADD_TO_CART,
        ecommerce: {
          currency: priceInformation?.price?.currencyCode,
          value: priceInformation?.price?.netTotalPrice,
          items: [
            {
              item_id: partNumber,
              item_name: partDescription,
              item_brand: brandName,
              item_category: EVENT_CATEGORIES.UNCATEGORIZED,
              item_list_name: dataLayerComponentOrigin,
              doa_product: true, // 'dealer own assortment'
              quantity,
              price: priceInformation?.price?.netUnitPrice,
            },
          ],
        },
      })
    },
    [
      priceInformation,
      partNumber,
      partDescription,
      brandName,
      dataLayerComponentOrigin,
    ]
  )

  return (
    <Box {...boxProps}>
      <AddToBasket
        partNumber={partNumber}
        variant={variant}
        orderLabel={label}
        askQuotationLabel={askQuotationLabel}
        onOrder={onOrder}
      />
    </Box>
  )
}

export default DealerPartAddToBasket
