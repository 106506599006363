import { useTheme } from '@emotion/react'
import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import { SliderVariant } from '../../../types/layoutService'
import CardAction from './CardAction'
import {
  PART_CARD_VARIANT_DEFAULT,
  PART_CARD_VARIANT_MINIMAL,
} from './PartCard/PartCard'

interface CardFrameProps {
  variant?: SliderVariant
  children: ReactNode
  hasLink?: boolean
}

const renderChildren = (
  children: ReactNode,
  { setInFocus }: { setInFocus: (state: boolean) => void }
) =>
  Children.map(children, (child) => {
    if (!isValidElement(child)) return child

    if (child.type === CardAction) {
      return cloneElement(child as ReactElement, { setInFocus })
    }

    return child
  })

const CardFrame = ({
  variant = PART_CARD_VARIANT_DEFAULT,
  children,
  hasLink = false,
}: CardFrameProps) => {
  const { transitions } = useTheme()
  const [inFocus, setInFocus] = useState(false)

  return (
    <Box
      sx={{
        display: 'block',
        height: '100%',
        padding: variant === PART_CARD_VARIANT_MINIMAL ? 3 : 4,
        borderStyle: 'solid',
        borderColor: inFocus ? 'primary' : 'gray.1',
        borderWidth: '1px',
        borderRadius: 'default',
        transition: 'ease all',
        transitionDuration: transitions[1],
        position: 'relative',
        zIndex: 0,
        ':hover, :focus-within': {
          borderColor: hasLink ? 'primary' : null,
        },
      }}
    >
      {renderChildren(children, { setInFocus })}
    </Box>
  )
}

export default CardFrame
