import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'

interface VehicleGridProps {
  children: ReactNode
}

const VehicleGrid = ({ children }: VehicleGridProps) => (
  <Box
    sx={{
      display: 'grid',
      gridAutoRows: '1fr',
      gridTemplateColumns: [
        'repeat(1, 1fr)',
        'repeat(1, 1fr)',
        'repeat(2, 1fr)',
        'repeat(3, 1fr)',
      ],
      gap: 3,
    }}
  >
    {children}
  </Box>
)

export default VehicleGrid
