import i18next from 'i18next'
import { adaptCommerceTechnicalDrawingToImage } from '../adapters/commerceApiAdapters'
import {
  CommerceSpecification,
  CommerceBillOfMaterialPart,
  CommerceBomMaster,
  CommerceBillOfMaterial,
  CommerceAttributesOfAPart,
  CommerceBomMasterWithoutPart,
} from '../types/commerceApi'
import { Image } from '../types/dafResponseProps'
import { combineImagesSources, filterPlaceholderImages } from './carouselHelper'
import { formatNumber } from './numberFormatHelper'

export const sortCommerceImages = (images?: Image[]) =>
  images?.sort((a, b) => (a?.priority || 0) - (b?.priority || 0))

export const sortCommerceAttributesOfAPart = (
  partAttributes?: CommerceAttributesOfAPart[]
) => partAttributes?.sort((a, b) => a.priority - b.priority)

export const sortCommerceSpecification = (
  specification?: CommerceSpecification
) => ({
  ...specification,
  partAttributes: sortCommerceAttributesOfAPart(specification?.partAttributes),
})

export const sortCommerceBomParts = (bomParts: CommerceBillOfMaterialPart[]) =>
  bomParts
    .reduce<CommerceBillOfMaterialPart[]>(
      (a, b) => [
        ...a,
        {
          ...b,
          images: sortCommerceImages(b?.images),
          specification: sortCommerceSpecification(b?.specification),
        },
      ],
      []
    )
    .sort((a, b) => a.priority - b.priority)

export const sortCommerceBomMasters = (bomParts: CommerceBomMaster[]) =>
  bomParts.reduce<CommerceBomMaster[]>(
    (a, b) => [
      ...a,
      {
        ...b,
        images: sortCommerceImages(b?.images),
      },
    ],
    []
  )

export const sortBillOfMaterialByPriority = (
  billOfMaterial?: CommerceBillOfMaterial
) =>
  billOfMaterial
    ? {
        ...(billOfMaterial?.bomParts
          ? { bomParts: sortCommerceBomParts(billOfMaterial.bomParts) }
          : {}),
        ...(billOfMaterial?.bomMasters
          ? { bomMasters: sortCommerceBomMasters(billOfMaterial.bomMasters) }
          : {}),
      }
    : undefined

export const resolvePrimaryImage = ({
  images = [],
  technicalDrawings = [],
}: CommerceBomMasterWithoutPart): Image | undefined => {
  const combinedImages = combineImagesSources(
    technicalDrawings.map(adaptCommerceTechnicalDrawingToImage),
    images
  )

  return filterPlaceholderImages(combinedImages)[0]
}

export const getCommercePartUri = (
  mainCategory?: string,
  subCategory?: string,
  partNumber?: string
): string => {
  const uriParts = []

  if (mainCategory) {
    uriParts.push(mainCategory)

    if (subCategory) {
      uriParts.push(subCategory)

      if (partNumber) {
        uriParts.push(partNumber)
      }
    }
  }

  return uriParts.join('/')
}

export const loyaltyPoints = (points?: number, cultureCode?: string) => {
  if (points === undefined) return '...'

  return `${points > 0 ? '+ ' : ''} ${formatNumber(points, cultureCode)} ${i18next.t(
    'shoppingLabels.customerLoyalty.points'
  )}`
}
