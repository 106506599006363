import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface SendEndMaintenancePlanRequest {}

export interface SendEndMaintenancePlanPayloadProps {
  vin: string | undefined
}

const sendEndMaintenancePlanPayload = (
  queryParams: DafDefaultQueryParams,
  payload: SendEndMaintenancePlanPayloadProps,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<SendEndMaintenancePlanRequest>>> =>
  // api expects an array of vins, sending only one
  client.post('/vehicles/end-maintenanceplan', [payload.vin], {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default sendEndMaintenancePlanPayload
