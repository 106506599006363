import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import {
  getModalBackgroundColor,
  getModalDividerColor,
  getModalDividerOpacity,
} from '../../../helpers/modalHelper'
import { SubThemeName } from '../../../providers/ThemeProvider'
import { getDividerSx } from '../../atoms/Divider/Divider'
import { ModalVariant } from './Modal'
import ModalCloseButton from './ModalCloseButton'
import ModalTitle from './ModalTitle'

interface ModalHeaderProps {
  theme: SubThemeName
  title?: string
  variant: ModalVariant
  onClose: () => void
}

const ModalHeader: FC<ModalHeaderProps> = ({ theme, title, onClose, variant }) => {
  const themeSettings = useTheme()

  const dividerColor = getModalDividerColor(theme, themeSettings)
  const dividerOpacity = getModalDividerOpacity(theme, themeSettings)
  const backgroundColor = getModalBackgroundColor(theme, themeSettings)

  return (
    <Flex
      sx={{
        padding:
          variant === 'minimal' ? 0 : [2, 2, 2, variant === 'fullscreen' ? 3 : 0],
        paddingBottom: variant === 'minimal' ? 3 : [2, 2, 2, 3],
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 2,
        backgroundColor:
          variant === 'minimal'
            ? 'transparent'
            : [
                backgroundColor,
                backgroundColor,
                backgroundColor,
                variant === 'fullscreen' ? backgroundColor : 'transparent',
              ],
        width: '100%',
        ...getDividerSx('bottom', dividerColor, dividerOpacity),
        borderBottomWidth: [
          '1px',
          '1px',
          '1px',
          variant === 'fullscreen' ? '1px' : '0px',
        ],
      }}
    >
      <ModalTitle title={title} />

      <ModalCloseButton onClick={onClose} variant={variant} theme={theme} />
    </Flex>
  )
}

export default ModalHeader
