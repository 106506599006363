import { Action } from 'redux'
import {
  SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL,
  SHOPPING_BASKET_WIZARD_SET_ORDER_ITEM_REFERENCE,
} from '../../../constants/shoppingBasketConstants'
import { CommerceOrderItem } from '../../../types/commerceApi'

export interface SetOrderItemReference
  extends Action<typeof SHOPPING_BASKET_WIZARD_SET_ORDER_ITEM_REFERENCE> {
  payload: CommerceOrderItem
}

export interface SetEditPartListLineSidePanelOpen
  extends Action<typeof SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL> {
  payload: {
    active: boolean
    partNumber: string
  }
}

export const setOrderItemReference = (payload: CommerceOrderItem) => ({
  type: SHOPPING_BASKET_WIZARD_SET_ORDER_ITEM_REFERENCE,
  payload,
})

export const setEditPartListLineSidePanel = (
  active: boolean,
  partNumber?: string
) => ({
  type: SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL,
  payload: { active, partNumber },
})
