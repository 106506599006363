import React, { FC, useContext } from 'react'
import { Flex } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { TextField } from '../../../types/layoutService'
import Button, { ButtonProps } from '../../atoms/Button/Button'
import Spinner from '../../atoms/Spinner/Spinner'

interface AddToBasketConfirmButtonNormalProps extends Omit<ButtonProps, 'variant'> {
  disabled?: boolean
  posting?: boolean
  buttonLabel?: string
  askQuotationLabel?: TextField
  orderLabel?: TextField
}

const AddToBasketConfirmButtonNormal: FC<AddToBasketConfirmButtonNormalProps> = ({
  disabled,
  posting,
  orderLabel,
  askQuotationLabel,
  ...buttonProps
}) => {
  const { activeSupplier } = useContext(ActiveStoreProviderContext)

  const buttonLabel = activeSupplier?.userIsGuest ? askQuotationLabel : orderLabel

  if (!buttonLabel) return null

  return (
    <Button
      type="submit"
      disabled={disabled}
      variant="primary"
      sx={{
        width: '100%',
      }}
      {...buttonProps}
    >
      {!posting && getTextFieldValue(buttonLabel)}

      {posting && (
        <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size={2} color="currentColor" />
        </Flex>
      )}
    </Button>
  )
}

export default AddToBasketConfirmButtonNormal
