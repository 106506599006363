import React from 'react'
import {
  URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM,
} from '../../../constants/urlConstants'
import withData from '../../../enhancers/withData'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import { ShoppingBasketProvider } from '../../../providers/ShoppingBasketProvider'
import ShoppingBasketConsumer from './ShoppingBasketConsumer'

export interface ShoppingBasketWizardDatasource {
  headingStepOverview: TextField
  headingStepDetails: TextField
  headingStepQuotationDetails: TextField
  emptyOverviewMessage: TextField
  activateAccountLink?: LinkField
  visitShopLink?: LinkField
  continueShoppingLink?: LinkField
  redeemPointsLink?: LinkField
  invalidOrderStatus: TextField
  noRecipients: TextField
  powerUserR2C: TextField
  powerUserRoyalMail: TextField
  requestQuoteNotAllowed: TextField
  royalMailExternalDirectoryIdEmpty: TextField
  russianWholeSaleOrderingNotAllowed: TextField
  unAuthorized: TextField
  wholeSaleOrderWayOfTransportEmpty: TextField
  loyaltyPointsLimitReaching: TextField
  loyaltyPointsLimitReached: TextField
  loyaltyPointsNoLimit: TextField
  orderNumberIsMandatoryButEmpty: TextField
  referenceNumberIsMandatoryButEmpty: TextField
  jobsheetReferenceRequiredToConfirmOrder: TextField
  r2CTasksNotFilled: TextField
  continueOrderButtonLabel: TextField
  continueQuotationButtonLabel: TextField
  editJobsheetButtonLabel: TextField
  noItemsInBasket: TextField
}

export interface ShoppingBasketWizardProps {
  partPage: LinkField
  dealerPartPage: LinkField
  orderConfirmedPage: LinkField
  quotationSentPage: LinkField
}

export type ShoppingBasketWizardSteps =
  | typeof URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM
  | typeof URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM
  | typeof URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM

const ShoppingBasketWizard = ({
  datasource,
  rendering,
  partPage,
  dealerPartPage,
  orderConfirmedPage,
  quotationSentPage,
}: Datasource<ShoppingBasketWizardDatasource> & ShoppingBasketWizardProps) => {
  return (
    <ShoppingBasketProvider
      datasource={datasource}
      rendering={rendering}
      partPage={partPage}
      dealerPartPage={dealerPartPage}
      orderConfirmedPage={orderConfirmedPage}
      quotationSentPage={quotationSentPage}
    >
      <ShoppingBasketConsumer />
    </ShoppingBasketProvider>
  )
}

export default withData(ShoppingBasketWizard, { checkDatasource: false })
