import React, { ReactNode } from 'react'
import { Flex } from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

interface ButtonGroupWrapperProps {
  children: ReactNode
}

const ButtonGroupWrapper = ({ children }: ButtonGroupWrapperProps) => {
  const { pageEditing } = useSitecoreContext()

  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        minHeight: pageEditing ? '40px' : 0,
        marginBottom: -2,
        'a, button': {
          marginRight: 2,
          marginBottom: 2,
          '&:last-child': {
            marginRight: 0,
          },
        },
      }}
    >
      {children}
    </Flex>
  )
}

export default ButtonGroupWrapper
