import React, { forwardRef, PropsWithChildren } from 'react'
import { Box, BoxProps } from 'theme-ui'

interface ListBaseProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {}

const ListBase = forwardRef<HTMLElement, PropsWithChildren<ListBaseProps>>(
  ({ children, sx, ...boxProps }, ref) => (
    <Box as="ul" ref={ref} sx={{ listStyle: 'none', pl: 0, ...sx }} {...boxProps}>
      {children}
    </Box>
  )
)

export default ListBase
