import React, { FC } from 'react'
import ClickableMenuItemWrapper from '../ClickableMenuItemWrapper/ClickableMenuItemWrapper'
import { NavigationItem as NavigationItemProps } from '../../../types/layoutService'
import { BaseItem } from './BaseItem'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { kebabCase } from '../../../helpers/kebabCaseHelper'

export const LinkItem: FC<NavigationItemProps> = ({
  title,
  url = '',
  target = '',
}) => {
  const { site } = useSitecoreContext()
  return (
    <ClickableMenuItemWrapper
      as="a"
      target={target}
      href={url}
      data-t-id={`${site.name}-${kebabCase(title)}`}
      sx={{
        display: 'inline-block',
        position: 'relative',
      }}
    >
      <BaseItem title={title} />
    </ClickableMenuItemWrapper>
  )
}
