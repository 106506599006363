import React, { Children, ReactNode, useEffect, useRef, useState } from 'react'
import { Box } from 'theme-ui'
import i18next from 'i18next'
import { createPortal } from 'react-dom'
import Button, { ButtonVariants } from '../../atoms/Button/Button'
import { ICONS } from '../../../constants/iconConstants'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { COLORS } from '../../../constants/themeConstants'
import useBreakpoints from '../../../hooks/useBreakpoints'

interface ActionButtonWithListProps {
  variant?: ButtonVariants
  children: ReactNode
  dataTId?: string
}

const ActionButtonList = ({
  variant = 'outline',
  children,
  dataTId,
}: ActionButtonWithListProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [breakpointS] = useBreakpoints('s')
  const [portalStyleRight, setPortalStyleRight] = useState<{
    top: string
    right: string
  }>({
    top: '0px',
    right: '0px',
  })
  const [portalStyleLeft, setPortalStyleLeft] = useState<{
    top: string
    left: string
  }>({
    top: '0px',
    left: '0px',
  })
  const wrapperRef = useRef<HTMLElement>(null)

  useOutsideClick(wrapperRef, () => setIsActive(false), !isActive)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsActive(!isActive)
    const rect = event.currentTarget.getBoundingClientRect()
    const doc = document.documentElement
    setPortalStyleRight({
      top: `${window.scrollY - doc.clientTop + rect.bottom}px`,
      right: `${
        window.innerWidth - (window.scrollX - doc.clientLeft + rect.right)
      }px`,
    })
    setPortalStyleLeft({
      top: `${window.scrollY - doc.clientTop + rect.bottom}px`,
      left: '16px',
    })
  }

  useEffect(() => {
    const repositionPortal = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect()
        const doc = document.documentElement
        setPortalStyleRight({
          top: `${window.scrollY - doc.clientTop + rect.bottom}px`,
          right: `${
            window.innerWidth - (window.scrollX - doc.clientLeft + rect.right)
          }px`,
        })
        setPortalStyleLeft({
          top: `${window.scrollY - doc.clientTop + rect.bottom}px`,
          left: '16px',
        })
      }
    }

    window.addEventListener('scroll', repositionPortal)
    window.addEventListener('resize', repositionPortal)

    return () => {
      window.removeEventListener('scroll', repositionPortal)
      window.removeEventListener('resize', repositionPortal)
    }
  }, [wrapperRef, isActive])

  const childrenArray = Children.toArray(children)
  const allChildrenFalse = childrenArray.every((child) => !child)

  return (
    <Box
      sx={{ position: 'relative', display: !allChildrenFalse ? 'block' : 'none' }}
    >
      <Box ref={wrapperRef}>
        <Button
          data-t-id={dataTId}
          sx={{ width: '100%' }}
          variant={variant}
          icon={isActive ? ICONS.ARROW_UP : ICONS.ARROW_DOWN}
          iconPosition="end"
          iconSize={4}
          onClick={handleButtonClick}
        >
          {i18next.t('myFleet.actionsButton')}
        </Button>
      </Box>
      {createPortal(
        <Box
          sx={{
            paddingTop: 3,
            position: 'absolute',
            zIndex: 5,
            display: isActive ? 'block' : 'none',
            opacity: isActive ? 1 : 0,
            minWidth: '260px',
            maxInlineSize: '300px',
            ...(breakpointS ? portalStyleRight : portalStyleLeft),
          }}
        >
          <Box
            sx={{
              border: '1px solid',
              borderColor: COLORS.MEDIUM_GRAY,
              background: COLORS.WHITE,
              paddingX: 3,
              '& > *': {
                borderBottom: '1px solid',
                borderColor: COLORS.MEDIUM_GRAY,
                '&:last-child': {
                  borderBottom: 'none',
                },
              },
            }}
          >
            {children}
          </Box>
        </Box>,
        document.body
      )}
    </Box>
  )
}

export default ActionButtonList
