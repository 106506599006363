import React, { FC } from 'react'
import { Category } from '../../../../../../hooks/services/graphql/useCategoryListService'
import PartSearchControlsSubcategoryListColumnItem from './PartSearchControlsSubcategoryListColumnItem'

interface PartSearchControlsSubcategoryListColumnProps {
  categoriesTree: Category[][]
  callback?: () => void
}

const PartSearchControlsSubcategoryListColumn: FC<
  PartSearchControlsSubcategoryListColumnProps
> = ({ categoriesTree, callback }: PartSearchControlsSubcategoryListColumnProps) => (
  <>
    {categoriesTree.map((categoryTree) => (
      <PartSearchControlsSubcategoryListColumnItem
        key={categoryTree.map(({ id }) => id).join('-')}
        categoryTree={categoryTree}
        callback={callback}
      />
    ))}
  </>
)

export default PartSearchControlsSubcategoryListColumn
