import { Action } from 'redux'
import { ToastProps } from '../types/toastTypes'

interface AddToastAction extends Action<typeof ADD_TOAST> {
  payload: ToastProps
}

interface RemoveToastAction extends Action<typeof REMOVE_TOAST> {
  payload: string
}

interface ResetToastsAction extends Action<typeof RESET_TOASTS> {}

export type ToastActions = AddToastAction | RemoveToastAction | ResetToastsAction

export const ADD_TOAST = 'notification/ADD_TOAST'
export const REMOVE_TOAST = 'notification/REMOVE_TOAST'
export const RESET_TOASTS = 'notification/RESET_TOASTS'

export const addToast = ({ id, message }: ToastProps): ToastActions => ({
  type: ADD_TOAST,
  payload: {
    id,
    message,
  },
})

export const removeToast = (id: string): RemoveToastAction => ({
  payload: id,
  type: REMOVE_TOAST,
})

export const resetToasts = (): ResetToastsAction => ({
  type: RESET_TOASTS,
})
