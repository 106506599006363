import i18next from 'i18next'
import React, { FC, useCallback } from 'react'
import { Box, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { ICONS } from '../../../../constants/iconConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import Link from '../../../atoms/Link/Link'

interface QuickOrderByFileProps {
  fileUploadUrl?: string
}

const QuickOrderByFile: FC<QuickOrderByFileProps> = ({ fileUploadUrl }) => {
  const trackEvent = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.UPLOAD_FILE,
      status: DATA_LAYER.CUSTOM_DIMENSION.STATUS.INTENTION,
    })
  }, [])

  if (!fileUploadUrl) return null

  return (
    <Link
      href={fileUploadUrl}
      data-t-id="shopping-basket-quick-order-by-file"
      onClick={trackEvent}
    >
      <Text
        variant="bodySmall"
        sx={{
          height: '42px',
          paddingLeft: 3,
          paddingRight: 1,
          fontSize: 1,
          color: 'gray.2',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: 'default',
          borderColor: 'gray.1',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
      >
        {i18next.t('shoppingLabels.uploadFile')}

        <Box color="primary">
          <IconWrapper icon={ICONS.UPLOAD} size={5} />
        </Box>
      </Text>
    </Link>
  )
}

export default QuickOrderByFile
