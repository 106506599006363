import React, { FC } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import { FooterLegalLinkItem } from './Footer'
import Container from '../../atoms/Container/Container'
import StaticFooterLegalLink from './StaticFooterLegalLink'

const StaticFooterLegalLinks: FC<FooterLegalLinkItem> = ({ items }) => {
  if (items && items?.results?.length > 0) {
    return (
      <Container mb={[4, 4, 5]}>
        <Text
          sx={{
            display: 'inline-block',
            fontSize: 0,
            color: 'textLight',
            fontWeight: 'normal',
            textDecoration: 'none',
            outline: 'none',
            lineHeight: 'body',
            marginBottom: [1, 1, 0],
            transitionProperty: 'opacity',
            opacity: 0.64,
            marginRight: 1,
            '::after': {
              position: 'relative',
              content: '"|"',
              display: 'inline',
              fontSize: '1',
              color: 'textLight',
              fontWeight: 'normal',
              mx: 2,
            },
          }}
        >
          {i18next.t('footer.copyrights')}
        </Text>
        {items?.results.map((link, i) => (
          <StaticFooterLegalLink key={i.toString()} {...link} />
        ))}
      </Container>
    )
  }
  return <></>
}

export default withClientSideRender(StaticFooterLegalLinks)
