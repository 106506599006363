import React, { forwardRef } from 'react'
import FormAsyncInputSpinner from './FormAsyncInputSpinner'
import FormInputTextarea, { FormInputTextareaProps } from './FormInputTextarea'

export interface FormInputTextareaAsyncProps extends FormInputTextareaProps {
  posting?: boolean
}

const FormInputTextareaAsync = forwardRef<
  HTMLInputElement,
  FormInputTextareaAsyncProps
>(({ posting, children, disabled, ...rest }, ref) => (
  <FormAsyncInputSpinner posting={posting}>
    <FormInputTextarea ref={ref} disabled={disabled || posting} {...rest}>
      {children}
    </FormInputTextarea>
  </FormAsyncInputSpinner>
))

export default FormInputTextareaAsync
