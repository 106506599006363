import React from 'react'
import { Box } from 'theme-ui'

interface Props {
  children: React.ReactNode
}

const SidePanelPaddedBox = ({ children }: Props) => (
  <Box
    sx={{
      paddingX: [3, 3, 6],
      paddingY: [3, 3, 5],
    }}
  >
    {children}
  </Box>
)

export default SidePanelPaddedBox
