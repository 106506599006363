import React from 'react'
import { Box, Text } from 'theme-ui'
import { alphaColor } from '../../../helpers/themeHelper'

interface StatePillProps {
  state: string
  color: string
  dataTId?: string
}
const StatePill = ({ state, color, dataTId }: StatePillProps) => (
  <Box
    sx={{
      paddingX: 2,
      borderRadius: 4,
      height: '24px',
      width: 'max-content',
      backgroundColor: alphaColor(color, 0.2),
      color,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Text
      as="span"
      sx={{
        fontSize: 1,
      }}
      data-t-id={dataTId}
    >
      {state}
    </Text>
  </Box>
)

export default StatePill
