import React, { ReactNode } from 'react'
import { Dealer } from '../../../../types/dealerServiceTypes'
import SidePanel from '../SidePanel'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import DealerInformationOverview from './DealerInformationOverview'

interface DealerInformationSidePanelProps {
  dealer: Dealer
  onClose: () => void
  title: string
  active?: boolean
  children: ReactNode
}

const DealerInformationSidePanel = ({
  active,
  dealer,
  onClose,
  title,
  children,
}: DealerInformationSidePanelProps) => {
  const [breakpointM] = useBreakpoints('m')

  return (
    <SidePanel
      active={active}
      onClose={onClose}
      title={breakpointM ? title : dealer.name || ''}
    >
      <DealerInformationOverview dealer={dealer} staticDealerFilters={[]}>
        {children}
      </DealerInformationOverview>
    </SidePanel>
  )
}

export default DealerInformationSidePanel
