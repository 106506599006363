export const USER_ROLES: Record<string, string> = {
  commerceShoppingBasket: 'commerce\\Shopping Basket',
  commerceHeadquarters: 'commerce\\Headquarters',
  commerceBasic: 'commerce\\Basic',
}

export const DEFAULT_CULTURE_CODE = 'en-gb'
export const DEFAULT_CURRENCY_CODE = 'EUR'
export const DEFAULT_COUNTRY_CODE = 'gb'

export const GREETING_TYPE_USER_FULL_NAME = 'userFullName'
export const GREETING_TYPE_USER_COMPANY_NAME = 'companyName'

export const COMPANY_TYPE_CODE_PREPACK = '03'
export const COMPANY_TYPE_CODE_DEALER = '11'
export const COMPANY_TYPE_CODE_REGIONAL_OFFICE = '21'
export const COMPANY_TYPE_CODE_HEADQUARTERS = '22'
export const COMPANY_TYPE_CODE_PACCAR_INC = '31'

export const USER_ROLES_VEHICLE_OVERVIEW_ACCESS = 'OD60'
export const USER_ROLES_PARTS_ACCESS = 'OD61'
export const USER_ROLES_VEHICLE_MANAGEMENT = 'OD62'
export const USER_ROLES_CUSTOMER_REQUEST_MAINTENANCE_APPOINTMENT = 'DL51'
export const USER_ROLES_MULTISUPPORT_REPAIR_AND_MAINTENANCE = 'OD57'
export const USER_ROLES_SITECORE_SHOPPING_BASKET = 'OD58'
export const USER_ROLES_DEALER_ORDER_QUOTATION_PRICE = 'DL52'
export const USER_ROLES_EARN_LOYALTY_POINTS = 'OD63'
export const USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION = 'DL54'
