import React, { FC, useMemo } from 'react'
import { Flex, Text } from 'theme-ui'
import { Class } from '../../../../../hooks/services/graphql/useClassInformationListService'
import { LinkField, TextField } from '../../../../../types/layoutService'
import Spinner from '../../../../atoms/Spinner/Spinner'
import { PartFulFilled } from '../../../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { groupPartsByClassCode } from '../../../../../helpers/searchTableViewHelper'
import PartSearchTable from './PartSearchTable'

interface PartSearchTableViewProps {
  parts?: PartFulFilled[]
  fetching?: boolean
  availableClassInformation?: Class[]
  partPage: LinkField
  supersessionText: TextField
}

const PartSearchTableView: FC<PartSearchTableViewProps> = ({
  parts,
  fetching,
  availableClassInformation,
  partPage,
  supersessionText,
}) => {
  const hasResults = useMemo(() => !!parts?.length, [parts])
  const groupedParts = useMemo(() => groupPartsByClassCode(parts), [parts])

  if (fetching) {
    return (
      <Flex sx={{ justifyContent: 'center', py: 5 }}>
        <Spinner />
      </Flex>
    )
  }

  if (!hasResults || !groupedParts || !availableClassInformation) return null

  return (
    <>
      {availableClassInformation.map((classInformation) => (
        <div id={classInformation.code} key={classInformation.code}>
          <Text variant="heading3" as="h3" mb={[3, 3, 4]}>
            {classInformation.description}
          </Text>

          <PartSearchTable
            classInformation={classInformation}
            parts={groupedParts[classInformation.code]}
            partPage={partPage}
            supersessionText={supersessionText}
          />
        </div>
      ))}
    </>
  )
}
export default PartSearchTableView
