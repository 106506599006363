import i18next from 'i18next'
import React from 'react'
import { Box, Text } from 'theme-ui'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import ProgressBar from '../../organisms/ProgressBar/ProgressBar'
import StatePill from '../../atoms/StatePill/StatePill'
import { COLORS } from '../../../constants/themeConstants'
import {
  SERVICEPLAN_CONTRACT_STATE_CANCELED,
  SERVICEPLAN_CONTRACT_STATE_CLOSED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRING,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
  VEHICLE_DETAIL_END_TYPE_DATE,
  VEHICLE_DETAIL_KM_ABBREVIATION,
} from '../../../constants/dafCompanyVehicleConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

interface ServicePlanStatusProps {
  vehicle: VehicleDetailResponse
}

const ServicePlanStatus = ({ vehicle }: ServicePlanStatusProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const GetStatusChipValue = () => {
    switch (vehicle?.rmContract?.servicePlan?.status?.contractState) {
      case SERVICEPLAN_CONTRACT_STATE_EXPIRED:
        return i18next.t('myFleetFilters.expired')
      case SERVICEPLAN_CONTRACT_STATE_ON_HOLD:
        return i18next.t('myFleetFilters.contractOnHold')
      case SERVICEPLAN_CONTRACT_STATE_CANCELED:
        return i18next.t('myFleetFilters.canceled')
      case SERVICEPLAN_CONTRACT_STATE_EXPIRING:
        return i18next.t('myFleetFilters.expiring')
      case SERVICEPLAN_CONTRACT_STATE_CLOSED:
        return i18next.t('myFleetFilters.closed')
      default:
        return null
    }
  }

  const getDurationColor = () => {
    switch (vehicle?.rmContract?.servicePlan?.status?.contractState) {
      case SERVICEPLAN_CONTRACT_STATE_CANCELED:
      case SERVICEPLAN_CONTRACT_STATE_EXPIRED:
      case SERVICEPLAN_CONTRACT_STATE_CLOSED:
        return COLORS.ALERT
      default:
        return COLORS.BLACK
    }
  }

  const calcuLateDurationPercentage = () => {
    if (
      vehicle?.rmContract?.servicePlan?.details?.durationInMonthsEndDate === null ||
      vehicle?.rmContract?.servicePlan?.status?.currentMonth === null
    )
      return undefined
    if (
      vehicle?.rmContract?.servicePlan?.details?.durationInMonthsCancelDate !==
        null &&
      vehicle?.rmContract?.servicePlan?.details?.durationInMonthsCancelDate !==
        undefined
    )
      return `${
        (vehicle?.rmContract?.servicePlan?.status?.currentMonth /
          vehicle?.rmContract?.servicePlan?.details?.durationInMonthsCancelDate) *
        100
      }%`

    const percentage =
      (vehicle?.rmContract?.servicePlan?.status?.currentMonth /
        vehicle?.rmContract?.servicePlan?.details?.durationInMonthsEndDate) *
      100

    return percentage > 100 ? '100%' : `${percentage}%`
  }

  const calculateMileagePercentage = () => {
    if (
      vehicle?.mileage?.value === null ||
      vehicle?.rmContract?.servicePlan?.details?.endKilometers === null ||
      vehicle?.rmContract?.servicePlan?.details?.startKilometers === null
    )
      return undefined

    const calculatedMaxValue =
      vehicle?.rmContract?.servicePlan?.details?.endKilometers -
      vehicle?.rmContract?.servicePlan?.details?.startKilometers

    const calculatedValue =
      vehicle?.mileage?.value -
      vehicle?.rmContract?.servicePlan?.details?.startKilometers

    const percentage = (calculatedValue / calculatedMaxValue) * 100

    return percentage > 100 ? '100%' : `${percentage}%`
  }

  const getDurationBarValue = () => {
    if (
      vehicle?.rmContract?.servicePlan?.status?.currentMonth === null ||
      vehicle?.rmContract?.servicePlan?.details?.durationInMonthsEndDate === null
    )
      return undefined
    if (
      vehicle?.rmContract?.servicePlan?.details?.durationInMonthsCancelDate !==
        null &&
      vehicle?.rmContract?.servicePlan?.details?.durationInMonthsCancelDate !==
        undefined
    ) {
      return (
        <Text
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Text
            sx={{
              display: 'flex',
            }}
          >
            {`${vehicle?.rmContract?.servicePlan?.status?.currentMonth} / 
          ${vehicle?.rmContract?.servicePlan?.details?.durationInMonthsCancelDate}`}
            <Text
              sx={{
                textDecoration: 'line-through',
                whiteSpace: 'pre-line',
                marginX: 1,
              }}
            >
              {`(${vehicle?.rmContract?.servicePlan?.details?.durationInMonthsEndDate})`}
            </Text>
            {i18next.t('servicePlan.months')}
          </Text>
        </Text>
      )
    }
    return (
      <Text>
        {`${vehicle?.rmContract?.servicePlan?.status?.currentMonth} / ${
          vehicle?.rmContract?.servicePlan?.details?.durationInMonthsEndDate
        } ${i18next.t('servicePlan.months')}`}
      </Text>
    )
  }

  const getMileageValue = () => {
    let calculatedEndKilometers =
      vehicle?.rmContract?.servicePlan?.details?.endKilometers ?? 0

    let calculatedMileage = vehicle?.mileage?.value

    if (
      vehicle?.mileage?.value === null ||
      vehicle?.rmContract?.servicePlan?.details?.endKilometers === null
    ) {
      return undefined
    }
    if (
      vehicle?.rmContract?.servicePlan?.details?.startKilometers &&
      vehicle?.rmContract?.servicePlan?.details?.startKilometers > 0
    ) {
      calculatedEndKilometers -=
        vehicle?.rmContract?.servicePlan?.details?.startKilometers
      calculatedMileage -= vehicle?.rmContract?.servicePlan?.details?.startKilometers
    }
    const formattedCalculatedMileage =
      Intl.NumberFormat(cultureCode).format(calculatedMileage)

    return (
      <Text>
        {`${formattedCalculatedMileage} /  
        ${new Intl.NumberFormat(cultureCode).format(
          calculatedEndKilometers
        )} ${VEHICLE_DETAIL_KM_ABBREVIATION}`}
      </Text>
    )
  }

  const mileageValue = getMileageValue()
  const durationBarValue = getDurationBarValue()
  const statusChipValue = GetStatusChipValue()?.toLowerCase()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignContent: 'center',
        }}
      >
        <Text variant="heading2">{i18next.t('servicePlan.statusTitle')}</Text>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: ['flex-end'],
          }}
        >
          {statusChipValue && (
            <StatePill
              state={statusChipValue}
              color={
                vehicle?.rmContract?.servicePlan?.status?.contractState ===
                SERVICEPLAN_CONTRACT_STATE_EXPIRING
                  ? COLORS.PROMOTION
                  : COLORS.ALERT
              }
            />
          )}
        </Box>
      </Box>

      <ProgressBar
        label={i18next.t('servicePlan.duration')}
        color={getDurationColor()}
        value={durationBarValue}
        percentage={calcuLateDurationPercentage()}
      />

      {mileageValue &&
        vehicle?.mileage?.value &&
        vehicle.rmContract?.servicePlan?.details?.endType !==
          VEHICLE_DETAIL_END_TYPE_DATE && (
          <ProgressBar
            label={i18next.t('servicePlan.mileage')}
            color={
              vehicle?.rmContract?.servicePlan?.details?.endKilometers !== null &&
              vehicle?.mileage?.value >
                vehicle?.rmContract?.servicePlan?.details?.endKilometers
                ? COLORS.ALERT
                : COLORS.BLACK
            }
            value={mileageValue}
            percentage={calculateMileagePercentage()}
            description={
              vehicle?.rmContract?.servicePlan?.details?.endKilometers !== null &&
              vehicle?.mileage?.value >
                vehicle?.rmContract?.servicePlan?.details?.endKilometers
                ? i18next.t('servicePlan.kilometersReached')
                : undefined
            }
          />
        )}
    </>
  )
}

export default ServicePlanStatus
