import React, { FC, Fragment, ReactNode } from 'react'
import { PartFulFilled } from '../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'

interface SearchResultPageProps {
  parts: PartFulFilled[]
  children?: ((part: PartFulFilled, index: number) => ReactNode) | ReactNode
}

const SearchResultsPage: FC<SearchResultPageProps> = ({ parts, children }) => {
  if (!parts.length) return null

  if (typeof children === 'function') {
    return (
      <>
        {parts.map((part, i) => (
          <Fragment key={i.toString()}>{children(part, i)}</Fragment>
        ))}
      </>
    )
  }

  return <>{children}</>
}

export default SearchResultsPage
