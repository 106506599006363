import React, { ComponentType } from 'react'
import { getDisplayName } from '../helpers/getDisplayName'
import SearchContextProvider from '../providers/SearchContextProvider'

function withSearchContext<Props>(
  WrappedComponent: ComponentType<Props>
): ComponentType<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WithSearchContext = (props: any) => (
    <SearchContextProvider
      assemblyPage={props.assemblyPage}
      partPage={props.partPage}
    >
      <WrappedComponent {...props} />
    </SearchContextProvider>
  )

  WithSearchContext.displayName = `withSearchContext(${getDisplayName(
    WrappedComponent
  )}`

  return WithSearchContext
}

export default withSearchContext
