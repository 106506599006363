import React, { useContext, useEffect, useState } from 'react'
import { Box } from 'theme-ui'
import i18next from 'i18next'
import MyFleetListFilterGroupHeading from './MyFleetListFilterGroupHeading'
import { Filter } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import MyFleetListFilterOption from './MyFleetListFilterOption'
import { MyFleetListContext } from '../../../../providers/MyFleetListContextProvider'

interface MyFleetListFilterGroupProps {
  filterOptions?: Filter
}

const MyFleetListFilterGroup = ({ filterOptions }: MyFleetListFilterGroupProps) => {
  const [active, setActive] = useState(false)
  const { activeFilters } = useContext(MyFleetListContext)
  const [numberOfFiltersSelected, setNumberOfFiltersSelected] = useState(0)

  useEffect(() => {
    if (activeFilters[0] && filterOptions && activeFilters[0][filterOptions.id]) {
      setNumberOfFiltersSelected(activeFilters[0][filterOptions.id].length)
    } else {
      setNumberOfFiltersSelected(0)
    }
  }, [activeFilters, filterOptions])

  useEffect(() => {
    if (numberOfFiltersSelected !== 0) {
      setActive(true)
    }
  }, [numberOfFiltersSelected])

  if (!filterOptions) return null

  return (
    <>
      <Box
        sx={{
          mb: 4,
          '&:last-of-type': {
            mb: 0,
          },
          '*': { cursor: 'pointer' },
        }}
      >
        <MyFleetListFilterGroupHeading
          onClick={() => {
            setActive(!active)
          }}
          active={active}
          selectionCount={numberOfFiltersSelected}
        >
          {i18next.t(`myFleetFilters.${filterOptions.id}`)}
        </MyFleetListFilterGroupHeading>

        <Box
          sx={{
            pt: active ? 2 : 0,
            mb: active ? -2 : 0,
            overflow: 'hidden',
          }}
        >
          {active &&
            filterOptions.options.map((filterOption) => (
              <MyFleetListFilterOption
                key={filterOption.id}
                filterOption={filterOption}
                contract={filterOptions.contract}
              />
            ))}
        </Box>
      </Box>
    </>
  )
}

export default MyFleetListFilterGroup
