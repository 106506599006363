import React, { FC, useCallback, useContext, useState } from 'react'
import useCommerceApiPostService from '../../../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveUserStateContext } from '../../../../../../providers/ActiveUserStateProvider'
import {
  deleteOrderRecipients,
  DeleteRecipientsRequestProps,
} from '../../../../../../services/rest/ecommerce/order/order-recipients'
import {
  CommerceMessage,
  CommerceRecipient,
  CommerceResponse,
} from '../../../../../../types/commerceApi'
import FormInputTextListItem from '../../../../../atoms/FormControls/FormInputTextList/FormInputTextListItem'
import { MutationType } from './OrderRecipients'

type EmailType = 'From' | 'To' | 'CC' | 'BCC'
enum FieldType {
  From = 1,
  To = 2,
  CC = 3,
  BCC = 4,
}

interface OrderRecipientProps {
  recipient: CommerceRecipient
  disabled?: boolean
  readOnly?: boolean
  onChange: () => void
  dataTId?: string
  fieldType?: EmailType
  onChangeEnd: (
    mutationValue?: CommerceRecipient,
    mutationType?: MutationType
  ) => void
}

const OrderRecipient: FC<OrderRecipientProps> = ({
  recipient,
  disabled,
  readOnly,
  onChange,
  onChangeEnd,
  dataTId,
  fieldType,
}) => {
  const { orderNumber } = useContext(ActiveUserStateContext)
  const [error, setError] = useState<string | null>(null)

  const [, response, executeDeleteOrderRecipient] = useCommerceApiPostService<
    DeleteRecipientsRequestProps,
    CommerceResponse<null>
  >(deleteOrderRecipients)

  const onDeleteMessage = useCallback(
    (commerceMessage: CommerceMessage) => {
      if (commerceMessage.severity === 'Success') {
        return onChangeEnd(recipient, 'delete')
      }

      if (commerceMessage.severity === 'Error' && commerceMessage.message) {
        setError(commerceMessage.message)
      }

      return onChangeEnd()
    },
    [onChangeEnd, recipient]
  )

  useCommerceApiResponse<null>({
    response,
    onResult: () => undefined,
    onMessage: onDeleteMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (result) => result as null,
    onStatus: {
      500: (data) => setError(data?.message || null),
    },
  })

  const onDelete = useCallback(() => {
    setError(null)

    executeDeleteOrderRecipient({
      orderNumber,
      field: FieldType[fieldType || 'To'],
      recipient: recipient.emailAddress,
    })

    return onChange?.()
  }, [
    executeDeleteOrderRecipient,
    orderNumber,
    recipient.emailAddress,
    fieldType,
    onChange,
  ])

  return (
    <FormInputTextListItem
      dataTId={dataTId}
      deletable={recipient.deleteAllowed}
      onDelete={onDelete}
      error={error}
      disabled={disabled}
      readOnly={readOnly}
    >
      {recipient.emailAddress}
    </FormInputTextListItem>
  )
}

export default OrderRecipient
