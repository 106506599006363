import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { ICONS } from '../../../constants/iconConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import ClickableMenuItemWrapper from '../../atoms/ClickableMenuItemWrapper/ClickableMenuItemWrapper'
import { ClickableWrapperProps } from '../../atoms/ClickableWrapper/ClickableWrapper'
import HotjarSuppress from '../../atoms/HotjarSuppress/HotjarSuppress'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'

export interface MyAccountAnchorProps extends Omit<ClickableWrapperProps, 'title'> {
  title: string
  label: string
  suppress?: boolean
}

const MyAccountAnchor: FC<MyAccountAnchorProps> = ({
  title,
  label,
  suppress,
  onClick: callback,
  ...props
}) => (
  <ClickableMenuItemWrapper
    onClick={(event) => {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.ACCOUNT,
        action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.OPEN,
      })

      if (typeof callback === 'function') callback(event)
    }}
    title={title}
    sx={{
      position: 'relative',
      display: 'block',
      py: [3, 3, 3, 4],
      pl: 1,
      pr: [1, 1, 1, 3],
    }}
    {...props}
  >
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', maxHeight: '24px' }}>
      <IconWrapper icon={ICONS.PROFILE} />

      <Text
        as="span"
        variant="buttonText"
        sx={{
          display: ['none', 'block'],
        }}
      >
        {suppress && (
          <HotjarSuppress>
            <>{label}</>
          </HotjarSuppress>
        )}

        {!suppress && label}
      </Text>
    </Flex>
  </ClickableMenuItemWrapper>
)

export default MyAccountAnchor
