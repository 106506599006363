import React, { useCallback } from 'react'
import { Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { useHistory } from 'react-router-dom'
import {
  URL_FILTER_PARAMETER,
  URL_SEARCH,
  URL_SORT,
} from '../../../../constants/urlConstants'
import Button from '../../../atoms/Button/Button'
import TableBody from '../../../molecules/Table/TableBody'
import Table from '../../../molecules/Table/Table'
import MyFleetListTableRow from './MyFleetListTableRow'
import {
  Filter,
  Vehicle,
} from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import MyFleetListTableHead from './MyFleetListTableHead'
import { LinkField } from '../../../../types/layoutService'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'

interface MyFleetListTableProps {
  vehicleData: Vehicle[]
  unfilteredVehicleData: Vehicle[]
  filterGroups?: Filter[]
  vehicleDetailLink?: LinkField
  requestQuoteMenuOptionTitle: string | undefined
  requestMaintenanceAppointmentOptionTitle: string | undefined
  deleteVehicleOptionTitle: string | undefined
}

const MyFleetListTable = ({
  vehicleData,
  unfilteredVehicleData,
  filterGroups,
  vehicleDetailLink,
  requestQuoteMenuOptionTitle,
  requestMaintenanceAppointmentOptionTitle,
  deleteVehicleOptionTitle,
}: MyFleetListTableProps) => {
  const {
    site: { name },
  } = useSitecoreContext()
  const history = useHistory()
  const handleClick = useCallback(() => {
    const params = new URLSearchParams(history.location.search)
    params.delete(URL_SORT)
    params.delete(URL_SEARCH)
    params.delete(URL_FILTER_PARAMETER)

    history.push({
      search: params.toString(),
    })
  }, [history])

  const columnSizes =
    name === MULTISUPPORT_SITE_CONTEXT
      ? [0.1, 0.4, 0.4, 0.4, 0.2, 0.3, 1, 0.6, 0.6, 1]
      : [0.1, 0.2, 0.5, 0.2, 0.1, 0.4, 0, 0.4, 0, 0, 0.2]
  return (
    <>
      <Table columnSizes={columnSizes}>
        <MyFleetListTableHead />
        <TableBody>
          {vehicleData.map((vehicle: Vehicle) => (
            <MyFleetListTableRow
              key={vehicle.chassisNumber}
              vehicle={vehicle}
              filterGroups={filterGroups}
              vehicleDetailLink={vehicleDetailLink}
              requestQuoteMenuOptionTitle={requestQuoteMenuOptionTitle}
              requestMaintenanceAppointmentOptionTitle={
                requestMaintenanceAppointmentOptionTitle
              }
              deleteVehicleOptionTitle={deleteVehicleOptionTitle}
            />
          ))}
        </TableBody>
      </Table>
      {!vehicleData.length && unfilteredVehicleData.length > 0 && (
        <Flex
          sx={{
            marginTop: 8,
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Text variant="heading2">{i18next.t('commonLabels.noResultsFound')}</Text>
          <Text variant="bodyContent" color="gray.2" sx={{ marginTop: 3 }}>
            {i18next.t('filteringLabels.noResultForCombinationOfFilters')}
          </Text>
          <Flex sx={{ justifyContent: 'center', marginTop: 4 }}>
            <Button variant="primary" onClick={() => handleClick()}>
              {i18next.t('filteringLabels.resetFilters')}
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
}
export default MyFleetListTable
