import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import i18next from 'i18next'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS, VectorIcon } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'
import Link from '../../atoms/Link/Link'
import { alphaColor } from '../../../helpers/themeHelper'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { Datasource, NavigationItemWithIcon } from '../../../types/layoutService'
import withData from '../../../enhancers/withData'

interface MyAccountNavigationFields {
  fields: MyAccountNavigationData
}

interface MyAccountNavigationData {
  data: MyAccountNavigationItems
}

interface MyAccountNavigationItems {
  navigationItems: { items: NavigationItemWithIcon[] }
}

interface IconAndLabelProps {
  icon?: string
  label?: string
}

const IconAndLabel = ({ icon, label }: IconAndLabelProps) => (
  <Flex
    sx={{
      gap: 1,
    }}
  >
    <IconWrapper
      icon={ICONS?.[(icon?.toUpperCase() || 'PROFILE') as VectorIcon]}
      color={COLORS.DAF_BLUE}
    />
    <Box
      sx={{
        paddingBlockStart: 8,
      }}
    >
      {label}
    </Box>
  </Flex>
)

const LinkItem = (item: NavigationItemWithIcon) => (
  <Link
    href={item?.url}
    sx={{
      display: 'block',
      padding: 2,
      borderRadius: 4,
      color: COLORS.BLACK,
      '&[aria-current], &:hover, &:focus': {
        backgroundColor: alphaColor(COLORS.DAF_BLUE, 0.08),
        color: COLORS.DAF_BLUE,
      },
    }}
    aria-current={item?.isActive ? 'page' : undefined}
  >
    <Flex
      sx={{
        gap: 1,
      }}
    >
      <IconWrapper
        icon={ICONS?.[item?.icon?.toUpperCase() as VectorIcon] ?? 'PROFILE'}
        color={COLORS.DAF_BLUE}
      />
      <Box
        sx={{
          paddingBlockStart: 2,
        }}
      >
        {item?.title}
      </Box>
    </Flex>
  </Link>
)

const MyAccountNavigation = ({
  fields: {
    data: {
      navigationItems: { items },
    },
  },
}: Datasource & MyAccountNavigationFields) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const lastItemIndex = items?.length - 1
  const lastItem = items[lastItemIndex]
  const filteredItems = items.slice(0, lastItemIndex)
  const activeMenuItem = items.find((item) => item.isActive)

  return (
    <>
      <Box
        as="nav"
        aria-labelledby="accountNavigation"
        sx={{
          background: COLORS.WHITE,
          border: '1px solid',
          borderColor: COLORS.MEDIUM_GRAY,
          borderRadius: 4,
          padding: [0, 0, 3],
          marginBlockEnd: [3, null, 0],
        }}
      >
        <Box variant="visuallyHidden">
          {i18next.t('myAccountOverview.myAccountMenuTitle')}
        </Box>
        <ClickableWrapper
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
          aria-expanded={isExpanded}
          color={COLORS.DAF_BLUE}
          sx={{
            display: ['block', 'block', 'none'],
            inlineSize: '100%',
            padding: 2,
            marginBlockEnd: 2,
          }}
        >
          <Flex
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <IconAndLabel
              icon={activeMenuItem?.icon}
              label={activeMenuItem?.title}
            />
            <Box
              padding={1}
              sx={{
                padding: 1,
                transform: isExpanded ? 'rotate(180deg)' : null,
              }}
            >
              <IconWrapper icon={ICONS.ARROW_DOWN} size={3} />
            </Box>
          </Flex>
        </ClickableWrapper>

        <Box
          sx={{
            display: isExpanded ? 'block' : ['none', 'none', 'block'],
            paddingBlockEnd: 1,
          }}
        >
          <Box
            as="ul"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              listStyle: 'none',
              padding: 0,
              paddingBlock: 1,
              marginBlock: 1,
              borderBlock: '1px solid',
              borderBlockStart: [null, 'none'],
              borderColor: COLORS.MEDIUM_GRAY,
            }}
          >
            {filteredItems.map((item) => (
              <li key={item.url}>
                <LinkItem {...item} />
              </li>
            ))}
          </Box>

          {lastItem && <LinkItem {...lastItem} />}
        </Box>
      </Box>
    </>
  )
}

export default withData(MyAccountNavigation, {
  checkDatasource: false,
  showMessageWhenPageEditing: true,
})
