import React, { FC } from 'react'
import { Box } from 'theme-ui'
import { CommerceOrderItem } from '../../../types/commerceApi'
import PartLineBasicInformation from './PartLineBasicInformation'
import PartLineSuggestionInformation from './PartLineSuggestionInformation'
import PartLineAnchor from './PartLineAnchor'

interface PartLineInformationProps {
  part?: CommerceOrderItem
  href: string
  onClick: () => void
}

const PartLineInformation: FC<PartLineInformationProps> = ({
  part,
  href,
  onClick,
}) => {
  if (!part?.suggestionInformation) {
    return (
      <Box>
        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineBasicInformation
            description={part?.description}
            partNumber={part?.partNumber}
            displayPartNumber={part?.displayPartNumber}
            supplierPartNumber={part?.supplierPartNumber}
          />
        </PartLineAnchor>
      </Box>
    )
  }

  return (
    <Box>
      <PartLineSuggestionInformation part={part} />

      <PartLineAnchor href={href} onClick={onClick}>
        <PartLineBasicInformation
          description={part.description}
          partNumber={part.partNumber}
          displayPartNumber={part?.displayPartNumber}
          supplierPartNumber={part?.supplierPartNumber}
        />
      </PartLineAnchor>
    </Box>
  )
}

export default PartLineInformation
