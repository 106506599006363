import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

interface AvailableOption {
  title: string
  optionKey: string
  priority: number
}

export interface ServicesResult {
  availableOptions: AvailableOption[]
  description: string
  descriptionKey: string
  packageAbbreviation: string
  priority: number
  title: string
}

interface CompanyVehicleData {
  result: ServicesResult[]
  messages: null
}

const getVehicleRMServicePlanActions = (
  chassisNumber: string | undefined,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CompanyVehicleData>> =>
  client.get(`daf-vehicle-rmserviceplan-actions/${chassisNumber}`, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default getVehicleRMServicePlanActions
