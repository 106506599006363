import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Text } from 'theme-ui'
import { DEALER_LOCATOR_USE_CLIENT_LOCATION } from '../../../constants/cookieConstants'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import {
  URL_FILTER_PARAMETER,
  URL_PAGE_PARAM,
  URL_QUERY_STRING_PARAM,
} from '../../../constants/urlConstants'
import withData from '../../../enhancers/withData'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { navigatorApiPermissions, runOnWindow } from '../../../helpers/dom'
import {
  mapDealerFiltersToSearchDealerRequestFilters,
  mapFilterStringToActiveSearchDealerFilterObject,
} from '../../../helpers/filterHelper'
import { getGeolocationCoordinates } from '../../../helpers/geoLocationHelper'
import {
  getMultiListFieldItems,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import {
  getUrlQueryValue,
  getUrlWithCurrentSearchParams,
} from '../../../helpers/urlHelper'
import { useDealerSearchService } from '../../../hooks/services/rest/dealer/useDealerSearchService'
import {
  Dealer,
  SearchDealerRequest,
  SearchDealerRequestFilter,
  SearchDealerRequestGeoPoint,
} from '../../../types/dealerServiceTypes'
import {
  Datasource,
  DealerFilter,
  Flag,
  LinkField,
  MultilistField,
  Params,
  StaticDealerFilter,
  TextField,
} from '../../../types/layoutService'
import DealerLocatorCurrentLocation from './DealerLocatorCurrentLocation'
import SpinnerWithLabel from '../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import DealerLocatorNoResults from './DealerLocatorNoResults'
import DealerLocatorSearchBar from './DealerLocatorSearchBar'
import DealerLocatorControls from './DealerLocatorControls'
import DealerInformationDealerLocatorSidePanel from '../SidePanel/DealerInformation/DealerInformationDealerLocatorSidePanel'
import Paginated from '../Paginated/Paginated'
import DealerLocatorTable from './DealerLocatorTable'
import { getDividerSx } from '../../atoms/Divider/Divider'

export enum DealerLocatorAction {
  RequestAccount = 'RequestAccount',
  SelectDealer = 'SelectDealer',
}

interface DealerLocatorDatasource {
  availableFilters: MultilistField<DealerFilter>
  staticFilters: MultilistField<StaticDealerFilter>
  filtersLabel: TextField
  loadingLabel: TextField
  loadingLocationLabel: TextField
  requestAccountLink: LinkField
  selectDealerLink: LinkField
  searchPlaceholderLabel: TextField
  useCurrentLocationLabel: TextField
  currentLocationActiveLabel: TextField
  currentLocationDisabled: TextField
  noSearchResultsLabel: TextField
  noSearchLocationOrQuerySuppliedLabel: TextField
  sidePanelTitle: TextField
}

interface DealerLocatorParams {
  action: DealerLocatorAction
  excludeDealersWithoutRequestAccountSupport: Flag
  excludeDealerWithoutLoyaltyParticipation: Flag
}

const DealerLocator: FC<
  Datasource<DealerLocatorDatasource> & Params<DealerLocatorParams>
> = ({
  datasource: {
    availableFilters,
    staticFilters,
    searchPlaceholderLabel,
    useCurrentLocationLabel,
    currentLocationActiveLabel,
    currentLocationDisabled,
    loadingLabel,
    loadingLocationLabel,
    requestAccountLink,
    selectDealerLink,
    filtersLabel,
    noSearchResultsLabel,
    noSearchLocationOrQuerySuppliedLabel,
    sidePanelTitle,
  },
  params: {
    action,
    excludeDealersWithoutRequestAccountSupport:
      forceExcludeDealersWithoutRequestAccountSupport,
    excludeDealerWithoutLoyaltyParticipation:
      forceExcludeDealerWithoutLoyaltyParticipation,
  },
}) => {
  const appInsights = useAppInsightsContext()

  const history = useHistory()

  const [locationUsageBlocked, setLocationUsageBlocked] = useState<
    boolean | undefined
  >(undefined)
  const [determiningLocation, setDeterminingLocation] = useState(true)
  const [clientLocation, setClientLocation] = useState<
    undefined | SearchDealerRequestGeoPoint
  >(undefined)
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
  const [pageIndex, setPageIndex] = useState(0)
  const [dealers, setDealers] = useState<Dealer[] | undefined>(undefined)

  const [useClientLocation, setUseClientLocation] = useState<boolean>(
    runOnWindow((w) =>
      w.localStorage.getItem(DEALER_LOCATOR_USE_CLIENT_LOCATION)
    ) === 'true'
  )

  const initialFilters = useMemo<SearchDealerRequestFilter[]>(
    () =>
      mapDealerFiltersToSearchDealerRequestFilters(
        getMultiListFieldItems(availableFilters)
      ),
    [availableFilters]
  )
  const [selectedFilters, queryString] = useMemo(
    () => [
      getUrlQueryValue(URL_FILTER_PARAMETER, history.location.search),
      getUrlQueryValue(URL_QUERY_STRING_PARAM, history.location.search) || '',
    ],
    [history.location.search]
  )

  const [activeFilterExcludeDealerWithoutLoyaltyParticipation, activeFilters] =
    useMemo(
      () => [
        selectedFilters?.includes('ExcludeDealerWithoutLoyaltyParticipation'),
        mapFilterStringToActiveSearchDealerFilterObject(
          selectedFilters?.replace(
            // TODO: Generalize in future - static filters should be made dynamic
            'ExcludeDealerWithoutLoyaltyParticipation:1',
            ''
          )
        ) || [],
      ],
      [selectedFilters]
    )

  const excludeDealerWithoutLoyaltyParticipation =
    forceExcludeDealerWithoutLoyaltyParticipation === '1' ||
    activeFilterExcludeDealerWithoutLoyaltyParticipation

  const excludeDealersWithoutRequestAccountSupport =
    forceExcludeDealersWithoutRequestAccountSupport === '1'

  const shouldFetch = useMemo(
    () =>
      !!searchQuery?.length ||
      (!locationUsageBlocked && !determiningLocation && !!clientLocation),
    [clientLocation, determiningLocation, locationUsageBlocked, searchQuery]
  )

  const dealerServiceOptions = useMemo<Partial<SearchDealerRequest> | undefined>(
    () =>
      shouldFetch
        ? {
            text: searchQuery,
            initialFilters,
            filters: activeFilters,
            pageIndex,
            geoPoint: clientLocation,
            excludeDealersWithoutRequestAccountSupport,
            excludeDealerWithoutLoyaltyParticipation,
          }
        : undefined,
    [
      shouldFetch,
      searchQuery,
      initialFilters,
      activeFilters,
      pageIndex,
      clientLocation,
      excludeDealersWithoutRequestAccountSupport,
      excludeDealerWithoutLoyaltyParticipation,
    ]
  )

  const [dealerServiceResponse, fetchingDealers] =
    useDealerSearchService(dealerServiceOptions)

  const determineLocation = useCallback(() => {
    setDealers(undefined)

    getGeolocationCoordinates()
      .then((coords) => {
        setClientLocation({
          latitude: coords.latitude,
          longitude: coords.longitude,
        })
      })
      .catch((err) => {
        appInsights.trackException({
          exception: new Error(`Unable to determine current location: ${err}`),
          severityLevel: SeverityLevel.Error,
        })
      })
      .finally(() => setDeterminingLocation(false))
  }, [appInsights])

  useEffect(() => {
    if (shouldFetch) {
      if (clientLocation) {
        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
          action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.CURRENT_LOCATION,
          location: Object.entries(clientLocation)
            .map(([key, val]) => `${key}: ${val}`)
            .join(', '),
        })
      } else {
        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
          action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.SEARCH,
          search_query: searchQuery,
        })
      }
    }
  }, [clientLocation, searchQuery, shouldFetch])

  useEffect(() => {
    if (dealerServiceResponse?.results) {
      setDealers(dealerServiceResponse.results)
    }
  }, [dealerServiceResponse])

  useEffect(() => {
    if (queryString.length) {
      setClientLocation(undefined)
      setUseClientLocation(false)
    } else {
      setDeterminingLocation(true)
      setUseClientLocation(true)
    }

    setSearchQuery(queryString)
  }, [determineLocation, queryString])

  useEffect(() => {
    const urlPageNumber = getUrlQueryValue(URL_PAGE_PARAM, history.location.search)

    if (urlPageNumber) {
      setPageIndex(
        parseInt(urlPageNumber, 10) - 1 // convert page number to page index
      )
    } else if (pageIndex !== 0) {
      setPageIndex(0)
    }
  }, [history.location.search, pageIndex])

  useEffect(() => {
    if (typeof searchQuery === 'undefined') return // First we need to be sure that there is no search string supplied in url. If so don't start detecting client location

    if (!searchQuery && !clientLocation && useClientLocation) {
      determineLocation()
    } else {
      setDeterminingLocation(false)
    }
  }, [clientLocation, determineLocation, searchQuery, useClientLocation])

  useEffect(() => {
    navigatorApiPermissions('geolocation')
      .then((permission) => {
        setLocationUsageBlocked(permission.state === 'denied')

        if (permission.state !== 'granted') {
          setUseClientLocation(false)
          window.localStorage.setItem(DEALER_LOCATOR_USE_CLIENT_LOCATION, 'false')
          determineLocation() // call to show location icon in browser search bar so client easily can change setting
        }

        // eslint-disable-next-line no-param-reassign
        permission.onchange = () => {
          if (permission.state === 'denied') {
            setLocationUsageBlocked(true)
            setUseClientLocation(false)

            setClientLocation(undefined)
            setDealers(undefined)
            window.localStorage.setItem(DEALER_LOCATOR_USE_CLIENT_LOCATION, 'false')
          }

          if (permission.state === 'granted') {
            setLocationUsageBlocked(false)
            setSearchQuery('')
            setUseClientLocation(true)
            setDeterminingLocation(true)

            const targetUrl = getUrlWithCurrentSearchParams()

            targetUrl.searchParams.delete(URL_PAGE_PARAM)
            targetUrl.searchParams.delete(URL_QUERY_STRING_PARAM)

            history.push({
              pathname: targetUrl.pathname,
              search: targetUrl.searchParams.toString(),
            })

            window.localStorage.setItem(DEALER_LOCATOR_USE_CLIENT_LOCATION, 'true')
          }
        }
      })
      .catch((err) => {
        setLocationUsageBlocked(true)
        setUseClientLocation(false)

        console.error(err)
      })
  }, [determineLocation, history])

  return (
    <>
      <DealerLocatorSearchBar
        placeholderLabel={searchPlaceholderLabel}
        useClientLocation={useClientLocation}
        currentLocationActiveLabel={getTextFieldValue(currentLocationActiveLabel)}
        resetCurrentLocationUsage={() => {
          setClientLocation(undefined)
          setDeterminingLocation(false)
          setUseClientLocation(false)
        }}
      />

      <DealerLocatorCurrentLocation
        locationUsageBlocked={locationUsageBlocked}
        setClientLocation={(coords?: GeolocationCoordinates) => {
          setClientLocation(
            coords && {
              latitude: coords.latitude,
              longitude: coords.longitude,
            }
          )
        }}
        setDeterminingLocation={(state) => setDeterminingLocation(state)}
        setUseClientLocation={(state) => setUseClientLocation(state)}
        useClientLocation={useClientLocation}
        currentLocationDisabled={currentLocationDisabled}
        label={useCurrentLocationLabel}
      />

      {!searchQuery?.length &&
      !fetchingDealers &&
      !dealers?.length &&
      !clientLocation &&
      !determiningLocation ? (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            paddingY: 3,
          }}
        >
          <Text>{getTextFieldValue(noSearchLocationOrQuerySuppliedLabel)}</Text>
        </Flex>
      ) : (
        <>
          {!dealers?.length && (determiningLocation || fetchingDealers) ? (
            <SpinnerWithLabel sx={{ width: '100%', paddingY: 3 }}>
              {determiningLocation && getTextFieldValue(loadingLocationLabel)}

              {!determiningLocation &&
                fetchingDealers &&
                getTextFieldValue(loadingLabel)}
            </SpinnerWithLabel>
          ) : (
            <DealerLocatorControls
              filters={getMultiListFieldItems(availableFilters)}
              staticFilters={
                forceExcludeDealerWithoutLoyaltyParticipation
                  ? []
                  : getMultiListFieldItems(staticFilters)
              }
              filtersLabel={filtersLabel}
              resultsCount={dealerServiceResponse?.totalCount || 0}
            />
          )}

          {dealerServiceResponse && dealers && !!dealers.length && (
            <>
              <Paginated
                fetching={fetchingDealers}
                fetchingLabel={getTextFieldValue(loadingLabel)}
                onNavigate={(newPageIndex) => {
                  setPageIndex(newPageIndex)
                }}
                sx={{ ...getDividerSx('top') }}
                {...dealerServiceResponse.pagination}
              >
                <DealerLocatorTable
                  action={action}
                  requestAccountLink={requestAccountLink}
                  selectDealerLink={selectDealerLink}
                  dealers={dealers}
                  staticDealerFilters={getMultiListFieldItems(staticFilters)}
                  useClientLocation={useClientLocation}
                />
              </Paginated>

              <DealerInformationDealerLocatorSidePanel
                dealers={dealers}
                staticDealerFilters={getMultiListFieldItems(staticFilters)}
                action={action}
                sidePanelTitle={sidePanelTitle}
                requestAccountLink={requestAccountLink}
                selectDealerLink={selectDealerLink}
              />
            </>
          )}

          {!fetchingDealers &&
            !determiningLocation &&
            dealerServiceResponse &&
            dealerServiceResponse.results?.length === 0 && (
              <DealerLocatorNoResults label={noSearchResultsLabel} />
            )}
        </>
      )}
    </>
  )
}

export default withData(DealerLocator, {
  showMessageWhenPageEditing: true,
})
