import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'theme-ui'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Toast from '../../molecules/Toast/Toast'
import { RootState } from '../../../reducers'
import { removeToast } from '../../../actions/toastActions'

const ToastWrapper = () => {
  const { toasts } = useSelector((state: RootState) => state.toasts)
  const dispatch = useDispatch()
  const toastsRefs = useRef(new Map())

  useEffect(() => {
    // auto remove toast after 5 seconds
    const timer = setTimeout(() => {
      if (toasts && toasts?.length > 0) {
        if (toasts) {
          dispatch(removeToast(toasts[0]?.id))
        }
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [toasts, dispatch])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        position: 'fixed',
        right: 3,
        top: 3,
        gap: 3,
        zIndex: '99999',
      }}
    >
      <TransitionGroup>
        {toasts?.map((toast) => {
          const toastRef = toastsRefs.current.get(toast.id) || React.createRef()
          toastsRefs.current.set(toast.id, toastRef)
          return (
            <CSSTransition
              nodeRef={toastRef}
              timeout={300}
              classNames="toast"
              unmountOnExit
              key={toast.id}
            >
              <div ref={toastRef}>
                <Toast key={toast.id} id={toast.id} message={toast.message} />
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </Flex>
  )
}

export default ToastWrapper
