import React, { FC } from 'react'
import i18next from 'i18next'
import { Dealer } from '../../../types/dealerServiceTypes'
import { LinkField, StaticDealerFilter } from '../../../types/layoutService'
import DealerLocatorTableRow from './DealerLocatorTableRow'
import { DealerLocatorAction } from './DealerLocator'
import PartLine from '../../molecules/PartLine/PartLine'
import PartLineTitle from '../../molecules/PartLine/PartLineTitle'

interface DealerLocatorTableProps {
  action: DealerLocatorAction
  requestAccountLink: LinkField
  selectDealerLink: LinkField
  dealers: Dealer[]
  staticDealerFilters: StaticDealerFilter[]
  useClientLocation?: boolean
}

export const gridTemplate = {
  columnSizes: ['1fr', '1fr', '3fr 2fr 2fr 4fr 2fr'], // css prop: grid-template-columns
}

export const gridTemplateWidthDistance = {
  columnSizes: ['1fr', '1fr', '3fr 2fr 2fr 2fr 2fr 2fr'], // css prop: grid-template-columns
}

const DealerLocatorTable: FC<DealerLocatorTableProps> = ({
  action,
  requestAccountLink,
  selectDealerLink,
  dealers,
  staticDealerFilters,
  useClientLocation,
}) => {
  const computedGridTemplate = useClientLocation
    ? gridTemplateWidthDistance
    : gridTemplate

  if (!dealers?.length) return null

  return (
    <>
      <PartLine
        gridTemplate={computedGridTemplate}
        py={3}
        sx={{
          display: ['none', 'none', 'grid'],
        }}
      >
        <PartLineTitle>{i18next.t('dealerLabels.location')}</PartLineTitle>

        <PartLineTitle>{i18next.t('dealerLabels.contactDetails')}</PartLineTitle>

        <PartLineTitle>{i18next.t('dealerLabels.todaysOpeningHours')}</PartLineTitle>

        <PartLineTitle>{i18next.t('dealerLabels.services')}</PartLineTitle>

        {useClientLocation && (
          <PartLineTitle>{i18next.t('dealerLabels.distance')}</PartLineTitle>
        )}
      </PartLine>

      {dealers?.map((dealer) => (
        <DealerLocatorTableRow
          key={dealer.code}
          gridTemplate={computedGridTemplate}
          action={action}
          requestAccountLink={requestAccountLink}
          selectDealerLink={selectDealerLink}
          staticDealerFilters={staticDealerFilters}
          dealer={dealer}
          useClientLocation={useClientLocation}
        />
      ))}
    </>
  )
}

export default DealerLocatorTable
