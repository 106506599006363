import React from 'react'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import {
  URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM,
} from '../../../../constants/urlConstants'
import TitleBar from '../../../molecules/TitleBar/TitleBar'
import { getEditableTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { Box } from 'theme-ui'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'

const OrderHeading = () => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { step, datasource } = useShoppingBasket()

  let title = datasource?.headingStepOverview

  if (step === URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM) {
    title = datasource?.headingStepQuotationDetails
  } else if (step === URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM) {
    title = datasource?.headingStepDetails
  }

  return (
    <Box
      sx={{
        marginBlockStart: [2, null, 3, 7],
      }}
    >
      <TitleBar
        title={`${getEditableTextFieldValue(title)} ${
          order ? `(${order?.number})` : ''
        }`}
      />
    </Box>
  )
}

export default OrderHeading
