import React, { forwardRef } from 'react'
import FormAsyncInputSpinner from './FormAsyncInputSpinner'
import FormInputText, { FormInputTextProps } from './FormInputText'

export interface FormInputTextAsyncProps extends FormInputTextProps {
  posting?: boolean
}

const FormInputTextAsync = forwardRef<HTMLInputElement, FormInputTextAsyncProps>(
  ({ posting, children, disabled, ...rest }, ref) => (
    <FormAsyncInputSpinner posting={posting}>
      <FormInputText ref={ref} disabled={disabled || posting} {...rest}>
        {children}
      </FormInputText>
    </FormAsyncInputSpinner>
  )
)

export default FormInputTextAsync
