import {
  CLOSE_R2C_JOBSHEET_REFERENCE_SIDEPANEL,
  CLOSE_R2C_TASK_SIDEPANEL,
  OPEN_R2C_JOBSHEET_REFERENCE_SIDEPANEL,
  OPEN_R2C_TASK_SIDEPANEL,
} from '../constants/r2cConstants'
import { R2CActions } from '../actions/r2cActions'

export interface R2CReducerState {
  showR2CTaskSidepanel: {
    showSidepanel: boolean
    partNumber: string
  }
  showR2CJobsheetSidepanel: {
    showSidepanel: boolean
    partNumber: string
  }
}

const r2cReducer = (
  state: R2CReducerState = {
    showR2CTaskSidepanel: {
      showSidepanel: false,
      partNumber: '',
    },
    showR2CJobsheetSidepanel: {
      showSidepanel: false,
      partNumber: '',
    },
  },
  action: R2CActions
): R2CReducerState => {
  switch (action.type) {
    case OPEN_R2C_TASK_SIDEPANEL:
      return {
        ...state,
        showR2CTaskSidepanel: {
          showSidepanel: true,
          partNumber: action.payload.partNumber,
        },
      }
    case CLOSE_R2C_TASK_SIDEPANEL:
      return {
        ...state,
        showR2CTaskSidepanel: {
          showSidepanel: false,
          partNumber: '',
        },
      }
    case OPEN_R2C_JOBSHEET_REFERENCE_SIDEPANEL:
      return {
        ...state,
        showR2CJobsheetSidepanel: {
          showSidepanel: true,
          partNumber: action.payload.partNumber,
        },
      }
    case CLOSE_R2C_JOBSHEET_REFERENCE_SIDEPANEL:
      return {
        ...state,
        showR2CJobsheetSidepanel: {
          showSidepanel: false,
          partNumber: '',
        },
      }
    default:
      return state
  }
}

export default r2cReducer
