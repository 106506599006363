import { AxiosResponse } from 'axios'

export const saveFile = (
  response: AxiosResponse<BlobPart>,
  filename: string,
  filetype: string | 'octet/stream'
) => {
  const documentObject = new Blob([response.data], {
    type: filetype,
  })

  saveFileFromBlob(documentObject, filename)
}

export const saveFileFromBlob = (documentObject: Blob, filename: string) => {
  const url = window.URL.createObjectURL(documentObject)

  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = filename
  a.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }, 0)
}

export const getFileExtension = (fileName: string) => {
  let ext = ''
  let pos = 0
  if (fileName) {
    pos = fileName.lastIndexOf('.') // get last position of `.`
    if (fileName === '' || pos < 1) {
      pos = fileName.lastIndexOf(' ') // get last position of ` `
      if (fileName === '' || pos < 1) {
        return ext
      }
      ext = fileName.slice(pos + 1)
    }
    ext = fileName.slice(pos + 1)
  }
  return ext
}

export const getFilenameFromHeaders = (
  headers: { [id: string]: string },
  defaultFilename: string
) => {
  let fileName = defaultFilename
  const disposition = headers['content-disposition']
  if (disposition && disposition.indexOf('inline') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches !== null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '')
    }
  }
  return fileName
}

export const getFiletypeFromHeaders = (
  headers: { [id: string]: string },
  defaultFiletype: string
) => {
  let fileType = defaultFiletype
  const contentType = headers['content-type']
  if (contentType) {
    fileType = contentType
  }
  return fileType
}
