import React, { useContext } from 'react'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import i18next from 'i18next'
import { navigateClient } from '../../../../helpers/dom'
import { hasRole } from '../../../../helpers/userHelper'
import { USER_ROLES_DEALER_ORDER_QUOTATION_PRICE } from '../../../../constants/userConstants'
import { QUOTATION_SENT, QUOTE } from '../../../../constants/orderConstants'
import { UserContext } from '../../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import useCommerceApiPostService from '../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import {
  OrderSendQuotationProps,
  sendQuotation,
} from '../../../../services/rest/ecommerce/order/order-send-quotation'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { registerModalNotification } from '../../../../actions/notificationActions'
import {
  URL_SEARCH_TYPE,
  URL_SHOPPING_BASKET_WIZARD_COMPANY_NAME_FOR_CONFIRMATION_PAGE,
  URL_SHOPPING_BASKET_WIZARD_ORDER_NUMBER_FOR_CONFIRMATION_PAGE,
} from '../../../../constants/urlConstants'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'

const SendQuotationButton = () => {
  const { quotationSentPage } = useShoppingBasket()
  const { orderNumber } = useContext(ActiveUserStateContext)
  const dispatch = useDispatch()
  const { user } = useContext(UserContext)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const order = useSelector((state: RootState) => state?.shoppingBasketWizard?.order)

  const sendQuoteConfirmed = () => {
    navigateClient(
      `${quotationSentPage.url}?${URL_SHOPPING_BASKET_WIZARD_ORDER_NUMBER_FOR_CONFIRMATION_PAGE}=${orderNumber}&${URL_SHOPPING_BASKET_WIZARD_COMPANY_NAME_FOR_CONFIRMATION_PAGE}=${order?.customerCompanyName}&${URL_SEARCH_TYPE}=${QUOTATION_SENT}`
    )
  }

  const [posting, response, post] = useCommerceApiPostService<
    OrderSendQuotationProps,
    CommerceResponse<CommerceOrder>
  >(sendQuotation)

  useCommerceApiResponse<CommerceOrder>({
    response,
    onResult: sendQuoteConfirmed,
    onMessage: (message) => {
      if (message.severity === 'Error' && !!message?.message?.length) {
        dispatch(registerModalNotification('Error', message.message))
      }
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrder,
  })

  const handleClickButton = () => {
    post({ orderNumber: order?.number })
  }

  if (
    !hasRole(user, USER_ROLES_DEALER_ORDER_QUOTATION_PRICE) ||
    !isImpersonated ||
    order?.type === QUOTE
  ) {
    return null
  }

  return (
    <Button
      disabled={posting}
      icon={ICONS.ENVELOPE}
      iconPosition="end"
      sx={{
        width: '100%',
      }}
      onClick={handleClickButton}
    >
      {i18next.t('shoppingLabels.sendQuotation')}
    </Button>
  )
}

export default SendQuotationButton
