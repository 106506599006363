import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import { NavigationItem } from '../../../types/layoutService'
import { SiteThemeLogoImage } from '../../../types/sitecoreContextProps'
import Navigation from '../../molecules/Navigation/Navigation'
import MainNavigationLogo from './MainNavigationLogo'

interface MainNavigationLeftProps {
  navigation: { items: NavigationItem[] }
  logoImage?: SiteThemeLogoImage
}

const MainNavigationLeft: FC<MainNavigationLeftProps> = ({
  logoImage,
  navigation,
}) => (
  <Flex
    data-t-id="main-navigation-left"
    sx={{
      alignItems: 'center',
      flexBasis: ['auto', 'auto', 'auto', '33.33%'],
      flexGrow: [1, 1, 0],
      order: 1,
    }}
  >
    <MainNavigationLogo logoImage={logoImage} />

    <Navigation sx={{ display: ['none', 'none', 'flex'] }} {...navigation} />
  </Flex>
)

export default MainNavigationLeft
