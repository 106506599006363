import React, { FC, useContext } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import { parseDate } from '../../../helpers/dateHelper'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { TextField } from '../../../types/layoutService'
import TableCell from '../../molecules/Table/TableCell'
import TableBody from '../../molecules/Table/TableBody'
import TableRow from '../../molecules/Table/TableRow'
import Table from '../../molecules/Table/Table'
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter'
import { AvailableCoupon } from '../../../hooks/services/graphql/usePartSalesInformationService'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'

interface PartMaxCouponTableProps {
  messages: TextField
  coupon: AvailableCoupon[]
}

const PartMaxCouponTable: FC<PartMaxCouponTableProps> = ({ coupon, messages }) => {
  const { priceInformation } = useContext(PartSalesInformationContext)
  const { dateFormatter } = useContext(LocalizationContext)

  const currencyFormatter = useCurrencyFormatter(priceInformation?.price)

  // always take first coupon as discussed with Nicole de Bie
  const { couponNumber, discountValue, endDate, numberOfCoupons } = coupon[0]

  const couponMessages = messages.value.split(/\r?\n/)
  const endDateFormatted = dateFormatter(parseDate(endDate))

  return (
    <Table columnSizes={[1, 1]}>
      <TableBody>
        {couponNumber && (
          <TableRow>
            <TableCell>
              <Text
                as="strong"
                variant="bodyContent"
                sx={{
                  fontWeight: 'bold',
                }}
                color="gray.3"
              >
                {i18next.t('partSalesLabels.number')}
              </Text>
            </TableCell>

            <TableCell>
              <Text
                as="span"
                variant="bodyContent"
                sx={{
                  textAlign: ['right', 'right', 'left'],
                }}
              >
                {couponNumber}
              </Text>
            </TableCell>
          </TableRow>
        )}

        {discountValue && (
          <TableRow>
            <TableCell>
              <Text
                as="strong"
                variant="bodyContent"
                color="gray.3"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {i18next.t('partSalesLabels.discount')}
              </Text>
            </TableCell>

            <TableCell>
              <Text
                as="span"
                variant="bodyContent"
                sx={{
                  textAlign: ['right', 'right', 'left'],
                }}
              >
                {currencyFormatter(discountValue)}
              </Text>
            </TableCell>
          </TableRow>
        )}

        {endDate && (
          <TableRow>
            <TableCell>
              <Text
                as="strong"
                variant="bodyContent"
                color="gray.3"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {i18next.t('partSalesLabels.endDate')}
              </Text>
            </TableCell>

            <TableCell>
              <Text
                as="span"
                variant="bodyContent"
                sx={{
                  textAlign: ['right', 'right', 'left'],
                }}
              >
                {endDateFormatted}
              </Text>
            </TableCell>
          </TableRow>
        )}

        {numberOfCoupons && (
          <TableRow>
            <TableCell>
              <Text
                as="strong"
                variant="bodyContent"
                color="gray.3"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {i18next.t('partSalesLabels.numberAvailable')}
              </Text>
            </TableCell>

            <TableCell>
              <Text
                variant="bodyContent"
                sx={{
                  textAlign: ['right', 'right', 'left'],
                }}
              >
                {numberOfCoupons}
              </Text>
            </TableCell>
          </TableRow>
        )}

        {!!couponMessages?.length &&
          couponMessages.map((message) => (
            <TableRow key={message}>
              <TableCell>
                <Text
                  variant="bodyContent"
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  {message}
                </Text>
              </TableCell>

              <TableCell />
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
export default PartMaxCouponTable
