import React, { createContext, ReactNode } from 'react'
import useSitecoreContext from '../hooks/useSitecoreContext'

export const WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY = 'assembly'
export const WILDCARD_PAGE_CONTEXT_TYPE_DEALER_PART = 'dealerPart'
export const WILDCARD_PAGE_CONTEXT_TYPE_NEWS = 'news'
export const WILDCARD_PAGE_CONTEXT_TYPE_PART = 'part'
export const WILDCARD_PAGE_CONTEXT_TYPE_VEHICLE = 'vehicle'

export const WildcardPageContext = createContext<Record<string, unknown>>({})

interface WildcardPageContextProviderProps {
  children: ReactNode
}

const WildcardPageContextProvider = ({
  children,
}: WildcardPageContextProviderProps) => {
  const { wildcardPageContext } = useSitecoreContext() ?? { wildcardPageContext: {} }

  return (
    <WildcardPageContext.Provider value={wildcardPageContext}>
      {children}
    </WildcardPageContext.Provider>
  )
}

export default WildcardPageContextProvider
