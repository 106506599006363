import React, { FC } from 'react'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import withData from '../../../enhancers/withData'
import { Datasource } from '../../../types/layoutService'
import CookieConsentBase, {
  CookieConsentBaseProps,
} from '../CookieConsentBase/CookieConsentBase'

const CookieConsent: FC<Datasource<CookieConsentBaseProps>> = ({ datasource }) => (
  <CookieConsentBase
    sx={{
      borderTop: '1px solid',
      borderColor: 'gray.1',
      mb: [4, 4, 7],
      pt: [4, 4, 7],
    }}
    {...datasource}
  />
)

export default withClientSideRender(withData(CookieConsent))
