import { AxiosInstance, AxiosResponse } from 'axios'
import { HEADER_VERIFICATION_TOKEN } from '../../../constants/httpConstants'

export interface RequestAccountRequestData {
  customerCompany: {
    name: string
    vatNumber: string
    chamberOfCommerceNumber: string
    city: string
    postalCode: string
    address: string
    countryCode: string
  }
  person: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }
  dealerCompany: {
    name: string
    addressLatitude: string
    addressLongitude: string
    locationCode: string
  }
  returnInformation: {
    partDetailUrl: string
  }
}

export interface RequestAccountResponseError {
  name: string
  rule: string
  args: string[]
}

export interface RequestAccountResponseData {
  isSuccessful: boolean
  message?: string | null
  errors: RequestAccountResponseError[]
}

export const requestAccount = (
  client: AxiosInstance,
  data: RequestAccountRequestData,
  verificationToken: string
): Promise<AxiosResponse<RequestAccountResponseData>> =>
  client.post('/accounts', data, {
    headers: {
      [HEADER_VERIFICATION_TOKEN]: verificationToken,
    },
  })
