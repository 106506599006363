import React from 'react'
import { Box, Flex } from 'theme-ui'
import { ToastProps } from '../../../types/toastTypes'
import { removeToast } from '../../../actions/toastActions'
import { COLORS } from '../../../constants/themeConstants'

const Toast = ({ id, message }: ToastProps) => (
  <Flex
    sx={{
      width: '350px',
      background: COLORS.BLACK,
      color: COLORS.WHITE,
      padding: 3,
      borderRadius: 4,
    }}
    onClick={() => removeToast(id)}
  >
    <Box>{message}</Box>
  </Flex>
)

export default Toast
