import i18next from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  COMMERCE_SITE_CONTEXT,
  MULTISUPPORT_SITE_CONTEXT,
} from '../../../../constants/environmentConstants'
import ActionButtonListButton from '../../../molecules/ActionButtonList/ActionButtonListButton'
import {
  DAF_SEARCH_EMBEDDED_URL,
  IS_DEALER_ADMIN_QUERY_PARAM,
  URL_WILDCARD_PLACEHOLDER,
} from '../../../../constants/urlConstants'
import {
  toggleDeleteVehiclePanel,
  toggleEditServicePlanPanel,
  toggleEditVehiclePanel,
  toggleEndMaintenancePlanPanel,
  toggleMaintenanceAppointmentPanel,
  toggleMaintenanceInputPanel,
  toggleMileageSettlementInputPanel,
  toggleRequestQuotePanel,
} from '../../../../actions/dafCompanyVehicleActions'
import { VEHICLE_DETAIL_MAINTENANCE_TAB } from '../../../../constants/dafCompanyVehicleConstants'
import ActionButtonList from '../../../molecules/ActionButtonList/ActionButtonList'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { LinkField } from '../../../../types/layoutService'
import { addToast } from '../../../../actions/toastActions'
import {
  getVehicleLabelForOverview,
  orderPartsFormMaintenanceLink,
} from '../../../../helpers/vehicleHelper'
import { getQueryParam } from '../../../../helpers/urlHelper'

interface MyFleetListActionButtonListProps {
  vehicle: Vehicle
  vehicleDetailLink?: LinkField
  requestQuoteMenuOptionTitle: string | undefined
  requestMaintenanceAppointmentOptionTitle: string | undefined
  deleteVehicleMenuOptionTitle: string | undefined
}

const MyFleetListActionButtonList = ({
  vehicle,
  vehicleDetailLink,
  requestQuoteMenuOptionTitle,
  requestMaintenanceAppointmentOptionTitle,
  deleteVehicleMenuOptionTitle,
}: MyFleetListActionButtonListProps) => {
  const dispatch = useDispatch()
  const id = useMemo(() => uuidv4(), [])

  const {
    site,
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const [isDealerAdmin, setIsDealerAdmin] = useState(false)

  useEffect(() => {
    const isDealerAdmin = getQueryParam(IS_DEALER_ADMIN_QUERY_PARAM)
    if (isDealerAdmin === 'true') setIsDealerAdmin(true)
  }, [location])

  return (
    <ActionButtonList dataTId={`myfleet-actions-${vehicle.vin}`}>
      {(site.name === MULTISUPPORT_SITE_CONTEXT || isDealerAdmin) &&
        vehicle.vehicleActions?.requestQuote?.showOption &&
        requestQuoteMenuOptionTitle && (
          <ActionButtonListButton
            label={requestQuoteMenuOptionTitle}
            action={() =>
              dispatch(
                toggleRequestQuotePanel({
                  isActive: true,
                  chassisNumber: vehicle.chassisNumber,
                })
              )
            }
          />
        )}

      {site.name === COMMERCE_SITE_CONTEXT &&
        vehicle.vehicleActions?.orderParts?.showOption && (
          <ActionButtonListButton
            label={i18next.t('myFleet.orderPartsForMaintenance')}
            as="a"
            href={orderPartsFormMaintenanceLink(
              cultureCode,
              vehicle.chassisNumber,
              vehicle.servicePlanner.service.upcomingJobs
            )}
          />
        )}

      {site.name === COMMERCE_SITE_CONTEXT &&
        vehicle.vehicleActions?.searchParts?.showOption && (
          <ActionButtonListButton
            label={i18next.t('myFleet.searchPartsButtonLabel')}
            as="a"
            href={`${DAF_SEARCH_EMBEDDED_URL}${
              vehicle.chassisNumber || vehicle.vin || vehicle.registrationNumber
            }`}
          />
        )}

      {site.name !== MULTISUPPORT_SITE_CONTEXT &&
        vehicle.vehicleActions?.requestMaintenancePlan.showOption && (
          <ActionButtonListButton
            label={i18next.t('panels.requestMaintenancePlan')}
            action={() => {
              if (
                vehicle.hasDafConnect &&
                !vehicle.rmContract &&
                !vehicle.firstRegistrationDate
              ) {
                return dispatch(
                  addToast({
                    id,
                    message: i18next.t(
                      'panels.requestMaintenancePlanUpdateRegistrationDateToast',
                      {
                        registrationNumber: getVehicleLabelForOverview(vehicle),
                      }
                    ),
                  })
                )
              }

              return dispatch(
                toggleMaintenanceInputPanel({
                  isActive: true,
                  chassisNumber: vehicle.chassisNumber,
                })
              )
            }}
            data-t-id="myfleet-actionbutton-requestmaintenance"
          />
        )}

      {vehicle.vehicleActions?.editServicePlan.showOption && (
        <ActionButtonListButton
          label={i18next.t('formLabels.editServicePlan')}
          action={() =>
            dispatch(
              toggleEditServicePlanPanel({
                isActive: true,
                chassisNumber: vehicle.chassisNumber,
              })
            )
          }
        />
      )}
      {(site.name === MULTISUPPORT_SITE_CONTEXT || isDealerAdmin) &&
        vehicle.vehicleActions?.inputMileage.showOption && (
          <ActionButtonListButton
            label={i18next.t('formLabels.inputMileageActionButtonLabel')}
            action={() =>
              dispatch(
                toggleMileageSettlementInputPanel({
                  isActive: true,
                  chassisNumber: vehicle.chassisNumber,
                })
              )
            }
          />
        )}
      {(site.name === MULTISUPPORT_SITE_CONTEXT || isDealerAdmin) &&
        vehicle.vehicleActions?.maintenance.showOption && (
          <ActionButtonListButton
            label={i18next.t('myFleet.maintenanceTitle')}
            as="a"
            href={`${vehicleDetailLink?.url.replace(
              URL_WILDCARD_PLACEHOLDER,
              vehicle.chassisNumber
            )}?activeTab=${VEHICLE_DETAIL_MAINTENANCE_TAB}`}
          />
        )}

      {vehicle.vehicleActions?.adjustMaintenancePlan.showOption && (
        <ActionButtonListButton
          label={i18next.t('maintenance.adjustMaintenancePlan')}
          action={() =>
            dispatch(
              toggleMaintenanceInputPanel({
                isActive: true,
                chassisNumber: vehicle.chassisNumber,
                isAdjustMaintenance: true,
              })
            )
          }
        />
      )}

      {vehicle.vehicleActions?.endMaintenancePlan.showOption && (
        <ActionButtonListButton
          label={i18next.t('maintenance.endMaintenancePlan')}
          action={() =>
            dispatch(
              toggleEndMaintenancePlanPanel({
                isActive: true,
                chassisNumber: vehicle.chassisNumber,
              })
            )
          }
        />
      )}

      {vehicle.vehicleActions?.requestMaintenanceAppointment.showOption && (
        <ActionButtonListButton
          label={requestMaintenanceAppointmentOptionTitle || ''}
          action={() =>
            dispatch(
              toggleMaintenanceAppointmentPanel({
                isActive: true,
                chassisNumber: vehicle.chassisNumber,
              })
            )
          }
        />
      )}

      {vehicle.vehicleActions?.editVehicle.showOption && (
        <ActionButtonListButton
          label={i18next.t('editVehicleLabels.panelTitle')}
          action={() =>
            dispatch(
              toggleEditVehiclePanel({
                isActive: true,
                chassisNumber: vehicle.chassisNumber,
              })
            )
          }
        />
      )}

      {vehicle.vehicleActions?.delete?.showOption &&
        deleteVehicleMenuOptionTitle && (
          <ActionButtonListButton
            label={deleteVehicleMenuOptionTitle}
            action={() =>
              dispatch(
                toggleDeleteVehiclePanel({
                  isActive: true,
                  chassisNumber: vehicle.chassisNumber,
                })
              )
            }
          />
        )}
    </ActionButtonList>
  )
}

export default MyFleetListActionButtonList
