import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC } from 'react'
import withSubTheme from '../../../enhancers/withTheme'
import { ColumnLayoutParams } from '../../../types/columnLayoutProps'
import { DatasourceBase, Params } from '../../../types/layoutService'
import ColumnLayout from '../../atoms/ColumnLayout/ColumnLayout'

const FourColumnLayout: FC<DatasourceBase & Params<ColumnLayoutParams>> = ({
  params,
  rendering,
}) => (
  <ColumnLayout params={params}>
    <Placeholder name="jss-grid-first-column" rendering={rendering} />

    <Placeholder name="jss-grid-second-column" rendering={rendering} />

    <Placeholder name="jss-grid-third-column" rendering={rendering} />

    <Placeholder name="jss-grid-fourth-column" rendering={rendering} />
  </ColumnLayout>
)

export default withSubTheme(FourColumnLayout)
