import { useTheme } from '@emotion/react'
import {
  Children,
  cloneElement,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { v4 as uuidv4 } from 'uuid'

interface AnimateProps {
  from: ThemeUIStyleObject
  enter: ThemeUIStyleObject
  leave?: ThemeUIStyleObject
  active?: boolean
  onAnimationStart?: () => void
  onAnimationEnd?: () => void
  children: ReactElement
}

const keyframe = (
  name: string,
  start: ThemeUIStyleObject,
  end: ThemeUIStyleObject
) => ({
  [`@keyframes ${name}`]: {
    '0%': start,
    '100%': end,
  },
})

const Animate = ({
  from,
  enter,
  leave,
  active,
  onAnimationStart,
  onAnimationEnd,
  children,
}: AnimateProps) => {
  const { transitions } = useTheme()
  const [shouldRender, setRender] = useState(active)
  const id = useMemo(() => uuidv4(), [])

  const onlyChild = Children.only(children)
  const startName = `start-${id}`
  const endName = `end-${id}`

  useEffect(() => {
    if (active) {
      setRender(true)
    }
  }, [active])

  const unMount = () => {
    setRender(false)
  }

  if (!shouldRender) return null

  return cloneElement(onlyChild, {
    sx: {
      ...children.props.sx,
      ...keyframe(startName, from, enter),
      ...keyframe(endName, enter, leave || from), // use from as fallback,
      animation: `${active ? startName : endName} ${transitions[2]} forwards`,
      ...(active ? enter : {}),
    },
    onAnimationEnd: () => {
      if (onAnimationEnd) onAnimationEnd()
      if (!active) unMount()
    },
    onAnimationStart,
  })
}

export default Animate
