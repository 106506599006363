import React, { FC } from 'react'
import Image, { ImageProps } from '../../atoms/Image/Image'

interface PartLineImageProps extends ImageProps {}

const PartLineImage: FC<PartLineImageProps> = ({ src, sx, ...imageProps }) => {
  if (!src) return <></>

  return (
    <Image
      src={src}
      sx={{
        width: '100%',
        maxWidth: 64,
        maxHeight: 64,
        objectFit: 'contain',
        ...sx,
      }}
      {...imageProps}
    />
  )
}

export default PartLineImage
