import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ENVIRONMENT_LOCAL } from 'src/constants/environmentConstants'
import { resolveEnvironment } from 'src/helpers/environment'

const appInsightsFactory = (environmentName) => {
  const env = resolveEnvironment(environmentName)

  if (!env) {
    throw new Error('Unable to resolve environment.')
  }

  const instrumentationKey = env.vars.appInsightsInstrumentationKey

  if (!instrumentationKey) {
    throw new Error('Unable to create AppInsights: missing instrumentation key.')
  }

  const reactPlugin = new ReactPlugin()

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: window.history },
      },
      enableAutoRouteTracking: false,
      disableAjaxTracking: true,
      disableFetchTracking: false,
      loggingLevelConsole: env.name === ENVIRONMENT_LOCAL ? 2 : 0,
    },
  })

  appInsights.loadAppInsights()

  return { appInsights, reactPlugin }
}

export default appInsightsFactory
