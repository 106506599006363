import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { getDividerSx } from '../../../atoms/Divider/Divider'
import ClickableWrapper, {
  ClickableWrapperProps,
} from '../../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'

interface ModalAnchorWithIconProps extends ClickableWrapperProps {
  label: string
  icon: string
  onClick: () => void
}

const ModalAnchorWithIcon: FC<ModalAnchorWithIconProps> = ({
  label,
  icon,
  onClick,
  sx,
  ...clickableWrapperProps
}) => (
  <ClickableWrapper
    onClick={onClick}
    sx={{
      display: 'flex',
      alignItems: 'center',
      py: 3,
      width: '100%',
      color: 'text',
      ...getDividerSx('top', 'gray.1'),
      ...sx,
    }}
    {...clickableWrapperProps}
  >
    <IconWrapper icon={icon} />

    <Text as="h2" variant="bodyCompact" sx={{ px: 2, flexGrow: 1 }}>
      {label}
    </Text>

    <IconWrapper icon={ICONS.ARROW_RIGHT} />
  </ClickableWrapper>
)

export default ModalAnchorWithIcon
