import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import DealerPartAddToBasket from '../AddToBasket/DealerPartAddToBasket'
import DealerPartPrice from '../PartPrice/DealerPartPrice'

interface DealerPartSalesInformationProps {
  partNumber?: string
  partDescription?: string
  brandName?: string
  dataLayerComponentOrigin?: string
}

const DealerPartSalesInformation: FC<DealerPartSalesInformationProps> = ({
  partNumber,
  partDescription,
  brandName,
  dataLayerComponentOrigin = '',
}) => {
  if (!partNumber) return null

  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
      }}
    >
      <DealerPartPrice partNumber={partNumber} variant="small" />

      <DealerPartAddToBasket
        partNumber={partNumber}
        partDescription={partDescription}
        brandName={brandName}
        variant="small"
        dataLayerComponentOrigin={dataLayerComponentOrigin}
      />
    </Flex>
  )
}

export default DealerPartSalesInformation
