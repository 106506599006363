import React, { FC, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { Box } from 'theme-ui'
import { useSelector } from 'react-redux'
import Navigation from '../../molecules/Navigation/Navigation'
import Overlay from '../../molecules/Overlay/Overlay'
import PartsNavigationPanel from '../PartsNavigation/Panel/PartsNavigationPanel'
import { scrollTo } from '../../../helpers/dom'
import { NavigationItem } from '../../../types/layoutService'
import PartsNavigationHeader from '../PartsNavigation/PartsNavigationHeader'
import Animate from '../../atoms/Animate/Animate'
import { RootState } from '../../../reducers'

interface MainNavigationMobileNavigationPanelProps {
  navigation: { items: NavigationItem[] }
}

const MainNavigationMobileNavigationPanel: FC<
  MainNavigationMobileNavigationPanelProps
> = ({ navigation }) => {
  const mobileNavigationOpen = useSelector(
    (state: RootState) => state.navigation.mobileNavigationOpen
  )

  const {
    mainNavigation: { textColor },
  } = useTheme()

  useEffect(() => {
    scrollTo({ top: 0, left: 0 })
  }, [])

  return (
    <Overlay
      isActive={mobileNavigationOpen}
      sx={{
        display: ['block', 'block', 'none'],
      }}
    >
      <Animate
        from={{
          left: '-100vw',
          top: 0,
        }}
        enter={{
          left: 0,
          top: 0,
        }}
        active={mobileNavigationOpen}
      >
        <Box sx={{ position: 'relative', height: '100vh', width: '100vw' }}>
          <PartsNavigationHeader />

          <PartsNavigationPanel>
            <Navigation {...navigation} sx={{ px: 3, py: 4, color: textColor }} />
          </PartsNavigationPanel>
        </Box>
      </Animate>
    </Overlay>
  )
}

export default MainNavigationMobileNavigationPanel
