import React, { FC } from 'react'
import {
  StringInputViewModel,
  ValueFormField,
} from '@sitecore-jss/sitecore-jss-forms'
import {
  FieldChangeCallback,
  ValueFieldProps,
} from '@sitecore-jss/sitecore-jss-react-forms'
import { Box } from 'theme-ui'
import FormInputLabel from '../../../atoms/FormControls/FormInputLabel'
import FormInputText from '../../../atoms/FormControls/FormInputText'
import FormFieldText from '../../../atoms/FormControls/FormFieldText'

const handleOnChange = (
  targetField: ValueFormField,
  fieldValue: string,
  callback: FieldChangeCallback
) => {
  let valid = true
  const errorMessages = []

  if (targetField.model.required && !fieldValue) {
    valid = false
    errorMessages.push(`${targetField.model.title} is required`)
  }

  callback(targetField.valueField.name, fieldValue, valid, errorMessages)
}

export const Email: FC<ValueFieldProps<StringInputViewModel>> = (props) => {
  const { field, value, onChange, errors, tracker } = props

  return (
    <Box sx={{ marginBottom: 4 }}>
      <FormInputLabel required={field.model.required}>
        {field.model.title}
      </FormInputLabel>

      <FormInputText
        type="email"
        id={field.valueField.id}
        name={field.valueField.name}
        value={value}
        maxLength={field.model.maxLength}
        placeholder={field.model.placeholderText}
        onChange={(e) => handleOnChange(field, e.target.value, onChange)}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
      />

      {errors
        ? errors.map((error) => <FormFieldText type="error">{error}</FormFieldText>)
        : null}
    </Box>
  )
}
