import React, { FC } from 'react'
import ClickableWrapper, { ClickableWrapperProps } from './ClickableWrapper'

interface ConditionalClickableWrapperProps extends ClickableWrapperProps {
  condition: boolean
}

export const ConditionalClickableWrapper: FC<ConditionalClickableWrapperProps> = ({
  condition,
  children,
  ...wrapperProps
}) => {
  if (condition) {
    return <ClickableWrapper {...wrapperProps}>{children}</ClickableWrapper>
  }

  return <>{children}</>
}
