import React, { FC } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Text } from 'theme-ui'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { ICON_SIZE } from '../../../../constants/partsNavigationConstants'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { Category } from '../../../../hooks/services/graphql/useCategoryListService'

interface PartsNavigationListProps {
  categories: Category[]
  activeCategory: Category | undefined
  clickAction: (itemId: string, englishDescription?: string) => void
}

const PartsNavigationList: FC<PartsNavigationListProps> = ({
  categories,
  activeCategory,
  clickAction,
  ...props
}) => {
  const { mainNavigation, fontSizes, lineHeights } = useTheme()

  return (
    <Box
      as="menu"
      sx={{
        minHeight: 0,
        py: 3,
        pl: 5,
        pr: 3,
      }}
      {...props}
    >
      {categories.map(({ id, description, englishDescription }) => (
        <ClickableWrapper
          key={id}
          data-t-id={`category-${id}`}
          onClick={() => clickAction(id, englishDescription)}
          sx={{
            display: 'flex',
            padding: [3, 3, 3, 2],
            paddingY: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            outline: 'none',
            ':hover, :active, :focus': {
              '> span': {
                color:
                  activeCategory && activeCategory.id === id
                    ? mainNavigation.textColor
                    : mainNavigation.textActiveColor,
              },
            },
            svg: {
              display: ['none', 'none', 'none', 'inline-block'],
              color:
                activeCategory && activeCategory.id === id
                  ? mainNavigation.textColor
                  : 'transparent',
            },
          }}
        >
          <Text
            as="span"
            variant="bodySmall"
            sx={{
              width: [
                '100%',
                '100%',
                '100%',
                `calc(100% - ${fontSizes[ICON_SIZE]}px)`,
              ],
              pr: [0, 0, 0, 3],
              color: mainNavigation.textColor,
              lineHeight: lineHeights.menuList,
              fontWeight:
                activeCategory && activeCategory.id === id ? 'bold' : 'normal',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {description}
          </Text>

          <IconWrapper size={ICON_SIZE} icon={ICONS.ARROW_RIGHT} />
        </ClickableWrapper>
      ))}
    </Box>
  )
}

export default PartsNavigationList
