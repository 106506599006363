// stripped down version of: https://github.com/ifeanyiisitor/react-use-scroll-direction
// we only need scroll y direction recognition and document wide scroll tracking, no target elements required

import { useEffect, useState } from 'react'
import { isBrowser } from '../helpers/dom'

type Listener = () => void

const getScrollTop = () =>
  window.scrollY ||
  window.pageYOffset ||
  document.body.scrollTop ||
  (document.documentElement && document.documentElement.scrollTop) ||
  0

const addScrollListener = (listener: Listener) =>
  document.addEventListener('scroll', listener)

const removeScrollListener = (listener: Listener) =>
  document.removeEventListener('scroll', listener)

type ScrollDirection = 'UP' | 'DOWN' | null

const useScrollDirection = (): ScrollDirection => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null)

  useEffect(() => {
    if (isBrowser()) {
      let scrollTimeout: number
      let lastScrollTop = getScrollTop()

      const handleScroll = () => {
        // Reset scroll direction when scrolling stops
        window.clearTimeout(scrollTimeout)
        scrollTimeout = window.setTimeout(() => {
          setScrollDirection(null)
        }, 66)

        // Set vertical direction while scrolling
        const scrollTop = getScrollTop()
        if (scrollTop > lastScrollTop) {
          setScrollDirection('DOWN')
        } else if (scrollTop < lastScrollTop) {
          setScrollDirection('UP')
        }
        lastScrollTop = scrollTop
      }

      addScrollListener(handleScroll)

      return () => removeScrollListener(handleScroll)
    }

    return undefined
  }, [])

  return scrollDirection
}

export default useScrollDirection
