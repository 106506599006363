import React, { FC, useEffect, useState } from 'react'
import { Text, TextProps } from 'theme-ui'

interface BasicInformationAttributeProps extends Omit<TextProps, 'css'> {
  label: string
  value?: string | number
}

const BasicInformationAttribute: FC<BasicInformationAttributeProps> = ({
  label,
  value,
  ...props
}) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient || !value) return null

  return (
    <Text
      variant="bodyCompact"
      sx={{ display: 'block', marginBottom: 3 }}
      {...props}
    >
      {label}

      <Text as="span" color="gray.2" ml={2}>
        {value}
      </Text>
    </Text>
  )
}

export default BasicInformationAttribute
