import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { CommerceResponse } from '../../../types/commerceApi'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'

export interface SendRmMileageSettlementInputnRequest {}

export interface SendRmMileageSettlementInputPayloadProps {
  mileageSettlementUserSource: number
  mileage: number
}

const sendRmMileageSettlementInputPayload = (
  chassisNumber: string | null | undefined,
  payload: SendRmMileageSettlementInputPayloadProps,
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<SendRmMileageSettlementInputnRequest>>> =>
  client.post(`/daf-vehicle-rm-mileage-settlement-input/${chassisNumber}`, payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default sendRmMileageSettlementInputPayload
