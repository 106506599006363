import { useMemo } from 'react'
import { FilterFunc } from '../types/closureTypes'
import { DialogRenderedItem, DialogSelector } from '../types/dialogTypes'
import useSitecoreContext from './useSitecoreContext'

export interface UseDialogOptions {
  limit?: number
  filter?: FilterFunc<DialogRenderedItem>
}

export const useDialogs = (
  selector: DialogSelector,
  options?: UseDialogOptions
): DialogRenderedItem[] => {
  const { dialogs } = useSitecoreContext()

  return useMemo<DialogRenderedItem[]>(() => {
    let items = (dialogs?.renderedItems ?? {})[selector] ?? []

    // Limit the amount of items to n if specified
    if (options?.limit && options.limit > 0) {
      items = items.slice(0, options.limit)
    }

    // Filter the items when a filter function is provided
    if (options?.filter) {
      items = items.filter(options.filter)
    }

    // Sort by priority (DESC) to ensure correct order
    return items.sort((a, b) => b.priority - a.priority)
  }, [])
}
