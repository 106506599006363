import React, { FC, useContext, useMemo } from 'react'
import { DEFAULT_PART_QUANTITY } from '../../../../constants/partSalesInformationConstants'
import {
  VIEW_TYPE_DEFAULT,
  VIEW_TYPE_GRID,
  VIEW_TYPE_LINE,
} from '../../../../constants/searchConstants'
import DealerPartSalesInformationProvider from '../../../../providers/DealerPartSalesInformationProvider'
import { DealerPartSearchContext } from '../../../../providers/DealerPartSearchContextProvider'
import { LinkField, TextField } from '../../../../types/layoutService'
import Container from '../../../atoms/Container/Container'
import SearchControls from '../SearchControls/SearchControls'
import DealerPartSearchGridView from './Grid/DealerPartSearchGridView'
import DealerPartSearchLineView from './Line/DealerPartSearchLineView'

interface DealerPartSearchTabPanelProps {
  viewLabel: TextField
  dealerPartPage: LinkField
}

const DealerPartSearchTabPanel: FC<DealerPartSearchTabPanelProps> = ({
  viewLabel,
  dealerPartPage,
}) => {
  const { dealerParts, totalCount, viewType } = useContext(DealerPartSearchContext)

  const partNumbersQuantity = useMemo(
    () =>
      (dealerParts &&
        dealerParts.map(({ dealerPart }) => ({
          partNumber: dealerPart?.partNumber,
          quantity: DEFAULT_PART_QUANTITY,
        }))) ||
      [],
    [dealerParts]
  )

  return (
    <Container>
      <SearchControls
        viewLabel={viewLabel}
        count={totalCount}
        viewType={viewType}
        defaultViewType={VIEW_TYPE_DEFAULT}
        sx={{ mb: 3, mt: -3 }}
      />

      <DealerPartSalesInformationProvider partNumbersQuantity={partNumbersQuantity}>
        {viewType === VIEW_TYPE_LINE && (
          <DealerPartSearchLineView
            dealerParts={dealerParts}
            dealerPartPage={dealerPartPage}
          />
        )}

        {viewType === VIEW_TYPE_GRID && (
          <DealerPartSearchGridView
            dealerParts={dealerParts}
            dealerPartPage={dealerPartPage}
          />
        )}
      </DealerPartSalesInformationProvider>
    </Container>
  )
}
export default DealerPartSearchTabPanel
