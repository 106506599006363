import React, { useContext } from 'react'
import { Box, Flex } from 'theme-ui'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { useHistory } from 'react-router-dom'
import MyFleetListFilterChip from './MyFleetListFilterChip'
import {
  toggleAddVehiclePanel,
  toggleMyFleetFilterPanel,
} from '../../../../actions/dafCompanyVehicleActions'
import MyFleetListTextInputFilter from './MyFleetListTextInputFilter'
import { MyFleetListContext } from '../../../../providers/MyFleetListContextProvider'
import {
  getFilterStringByActiveSearchResultFilterObject,
  toggleFilter,
} from '../../../../helpers/filterHelper'
import { ActiveSearchResultFilters } from '../../../../types/filterProps'
import {
  MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE,
  MY_FLEET_LIST_FILTER_OPTION_MAINTENANCE,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
  SERVICEPLAN_CONTRACT_STATE_EXPIRED,
} from '../../../../constants/dafCompanyVehicleConstants'
import { Filter } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'
import { hasRole } from '../../../../helpers/userHelper'
import { USER_ROLES_VEHICLE_MANAGEMENT } from '../../../../constants/userConstants'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import MyFleetAddVehicleButton from '../MyFleetAddVehicleButton/MyFleetAddVehicleButton'

interface MyFleetListFiltersWrapperProps {
  filterGroups?: Filter[]
}

type ActiveFilterObject = {
  [key: string]: string[]
}

const MyFleetListFiltersWrapper = ({
  filterGroups,
}: MyFleetListFiltersWrapperProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    user,
    site: { name },
  } = useSitecoreContext()
  const [breakpointM] = useBreakpoints('m')

  const showExpiredFilterButton = filterGroups?.some((item) =>
    item.options.some(
      (option) => option.value === SERVICEPLAN_CONTRACT_STATE_EXPIRED
    )
  )

  const showOverdueFilterButton = !!filterGroups?.some((item) =>
    item.options.some(
      (option) => option.value === MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE
    )
  )
  const { activeFilters } = useContext(MyFleetListContext)
  const maintenanceOverdueFiltersCount = activeFilters[0].maintenance?.filter(
    (filter: string) => filter === MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE
  ).length

  const expiredServicePlansFiltersCount = activeFilters[0].contractState?.filter(
    (filter: string) => filter === SERVICEPLAN_CONTRACT_STATE_EXPIRED
  ).length

  const totalSelectedFilters: number = Object.values(
    activeFilters[0] as ActiveFilterObject
  ).reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (sum, currentArray: any) => sum + currentArray.length,
    0
  )

  const openSideBar = () => {
    dispatch(toggleMyFleetFilterPanel(true))
  }

  const toggleFiltersForMyFleetList = (filterObject: ActiveSearchResultFilters) => {
    const params = new URLSearchParams(history.location.search)
    history.push(
      `?${getFilterStringByActiveSearchResultFilterObject(
        filterObject,
        params,
        false,
        false
      )}`
    )
  }

  const toggleOverdueMaintenanceFilters = () => {
    const overdueFilterObject = toggleFilter(
      activeFilters[0] || {},
      MY_FLEET_LIST_FILTER_OPTION_MAINTENANCE,
      MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE
    )

    toggleFiltersForMyFleetList(overdueFilterObject)
  }

  const toggleExpiredServicePlanFilters = () => {
    const expiredFilterObject = toggleFilter(
      activeFilters[0] || {},
      MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE,
      SERVICEPLAN_CONTRACT_STATE_EXPIRED
    )

    toggleFiltersForMyFleetList(expiredFilterObject)
  }

  return (
    <Box>
      <Flex
        sx={{
          gap: 3,
          flexDirection: ['column-reverse', 'column-reverse', 'row'],
          paddingX: 0,
        }}
      >
        <Flex sx={{ gap: 3 }}>
          <Box sx={{ flex: 1 }}>
            <Button
              data-t-id="multisupport-sort-and-filter"
              variant={totalSelectedFilters >= 1 ? 'primary' : 'outline'}
              sx={{
                width: '100%',
                flexGrow: 1,
                '> div': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              onClick={() => openSideBar()}
              icon={ICONS.SORT_AND_FILTER}
            >
              {`${i18next.t('myFleetListSortAndFilterLabels.sortAndFilterLabel')}${
                totalSelectedFilters > 0 ? ` (${totalSelectedFilters})` : ''
              }`}
            </Button>
          </Box>
          {hasRole(user, USER_ROLES_VEHICLE_MANAGEMENT) && !breakpointM && (
            <Box sx={{ flex: 1, width: '100%' }}>
              <MyFleetAddVehicleButton
                handleAddVehicleClick={() => dispatch(toggleAddVehiclePanel(true))}
              />
            </Box>
          )}
        </Flex>
        {name === MULTISUPPORT_SITE_CONTEXT && showOverdueFilterButton && (
          <Box sx={{ display: ['none', 'none', 'block'] }}>
            <MyFleetListFilterChip
              label={i18next.t(
                'myFleetListSortAndFilterLabels.overdueMaintenanceButtonLabel'
              )}
              selectionCount={maintenanceOverdueFiltersCount}
              showSelectionCount={false}
              onClick={() => toggleOverdueMaintenanceFilters()}
            />
          </Box>
        )}
        {name === MULTISUPPORT_SITE_CONTEXT && showExpiredFilterButton && (
          <Box sx={{ display: ['none', 'none', 'block'] }}>
            <MyFleetListFilterChip
              label={i18next.t(
                'myFleetListSortAndFilterLabels.expiredServicePlansButtonLabel'
              )}
              selectionCount={expiredServicePlansFiltersCount}
              showSelectionCount={false}
              onClick={() => toggleExpiredServicePlanFilters()}
            />
          </Box>
        )}
        <Box
          sx={{
            marginLeft: ['unset', 'unset', 'auto'],
            marginBottom: [2, 2, 0],
          }}
        >
          <MyFleetListTextInputFilter />
        </Box>
        {hasRole(user, USER_ROLES_VEHICLE_MANAGEMENT) && breakpointM && (
          <Box>
            <MyFleetAddVehicleButton
              handleAddVehicleClick={() => dispatch(toggleAddVehiclePanel(true))}
            />
          </Box>
        )}
      </Flex>
    </Box>
  )
}
export default MyFleetListFiltersWrapper
