import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'

interface CardBrandNameProps {
  name?: string
}

const CardBrandName: FC<CardBrandNameProps> = ({ name }) => (
  <Flex
    sx={{
      flexShrink: 1,
      alignItems: 'center',
    }}
  >
    <Text
      as="span"
      variant="bodySmall"
      sx={{
        lineHeight: 'menuList',
        display: 'inline-block',
        color: 'gray.2',
      }}
    >
      {name}
    </Text>
  </Flex>
)

export default CardBrandName
