import i18next from 'i18next'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex, Text } from 'theme-ui'
import {
  createOrderMutation,
  markOrderMutationFinished,
  setEditPartListLineSidePanel,
  setOrderItemReference,
} from '../../../../actions/shoppingBasket/wizard'
import { EVENTS } from '../../../../constants/analyticsConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import useCommerceApiPostService from '../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import {
  OrderItemReferenceRequestProps,
  OrderItemReferenceResponse,
  postOrderItemReference,
} from '../../../../services/rest/ecommerce/order/order-item-reference'
import {
  CommerceMessage,
  CommerceOrderItem,
  CommerceResponse,
} from '../../../../types/commerceApi'
import Button from '../../../atoms/Button/Button'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import FormFieldText from '../../../atoms/FormControls/FormFieldText'
import SidePanelFooter from '../SidePanelFooter'
import { RootState } from '../../../../reducers'
import { UserContext } from '../../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import {
  COMPANY_TYPE_CODE_PREPACK,
  COMPANY_TYPE_CODE_REGIONAL_OFFICE,
  USER_ROLES_DEALER_ORDER_QUOTATION_PRICE,
} from '../../../../constants/userConstants'
import Image from '../../../atoms/Image/Image'
import {
  getCurrencySymbol,
  getFormattedPriceString,
} from '../../../../helpers/priceHelper'
import { hasRole, userCultureCode } from '../../../../helpers/userHelper'
import { QUOTE } from '../../../../constants/orderConstants'
import { Form } from 'react-final-form'
import { TextAreaField, TextField } from '../../../atoms/FormFields'
import { COLORS } from '../../../../constants/themeConstants'
import { ValidationErrors } from 'final-form'
import { getDividerSx } from '../../../atoms/Divider/Divider'
import { PartListSalesInformationContext } from '../../../../providers/PartListSalesInformationProvider'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import { refetchShoppingBasketSidepanelSummary } from '../../../../actions/shoppingBasket/sidePanelActions'

type FormData = {
  vehicleReference: string
  lineReference: string
  newNetPrice: number | undefined
}

const MAX_ORDER_ITEM_REFERENCE_LENGTH = 250
const MAX_MAINFRAME_ORDER_ITEM_REFERENCE_LENGTH = 40

const ShoppingBasketEditItemSidePanel = () => {
  const { orderNumber } = useContext(ActiveUserStateContext)
  const dispatch = useDispatch()
  const { user } = useContext(UserContext)
  const { editItemSidePanel } = useSelector(
    (state: RootState) => state.shoppingBasketWizard
  )
  const { activeSupplier, isImpersonated } = useContext(ActiveStoreProviderContext)
  const { priceInformationList } = useContext(PartListSalesInformationContext)
  const order = useSelector((state: RootState) => state?.shoppingBasketWizard?.order)
  const [priceMessage, setPriceMessage] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [openNextPart, setOpenNextPart] = useState(false)

  const getCurrentPart = (partNumber: string) => {
    return order?.items?.find((item) => item.partNumber === partNumber)
  }

  const [part, setPart] = useState<CommerceOrderItem | undefined>(undefined)

  useEffect(() => {
    setPart(getCurrentPart(editItemSidePanel.partNumber || ''))
  }, [editItemSidePanel.partNumber])

  const canSetOrderQuotation = () => {
    return (
      hasRole(user, USER_ROLES_DEALER_ORDER_QUOTATION_PRICE) &&
      isImpersonated &&
      order?.type !== QUOTE
    )
  }

  const showMainFrameMaxLengthMessage = useMemo(
    () =>
      user?.profile?.customProperties?.companyType === COMPANY_TYPE_CODE_PREPACK ||
      activeSupplier?.companyTypeCode === COMPANY_TYPE_CODE_REGIONAL_OFFICE,
    [activeSupplier, user]
  )

  const [posting, response, postToShoppingBasket] = useCommerceApiPostService<
    OrderItemReferenceRequestProps,
    CommerceResponse<OrderItemReferenceResponse>
  >(postOrderItemReference)

  const displayNextPartInfo = () => {
    if (!order?.items?.length) return

    const currentPartIndex = order?.items.findIndex(
      (p) => p.partNumber === part?.partNumber
    )
    const nextPartIndex =
      currentPartIndex === order?.items.length - 1 ? 0 : currentPartIndex + 1

    const nextPart = order.items[nextPartIndex]

    dispatch(setEditPartListLineSidePanel(true, nextPart.partNumber))

    setOpenNextPart(false)
  }

  const onResult = (
    customerOrderItemReference: OrderItemReferenceResponse | null
  ) => {
    if (!customerOrderItemReference || !customerOrderItemReference.length)
      return undefined

    dispatch(setOrderItemReference(customerOrderItemReference[0]))

    dispatch(markOrderMutationFinished())

    dispatch(refetchShoppingBasketSidepanelSummary(true))

    if (openNextPart) return displayNextPartInfo()

    dispatch(setEditPartListLineSidePanel(false))
  }

  const onMessage = useCallback((commerceMessage: CommerceMessage) => {
    if (commerceMessage.message && commerceMessage.severity === 'Error') {
      setError(commerceMessage.message)
    }
  }, [])

  useCommerceApiResponse<OrderItemReferenceResponse>({
    response,
    onResult,
    onMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as OrderItemReferenceResponse,
  })

  const measureInteraction = useCallback(
    (value: string, action?: string) => {
      const status =
        part?.lineReference && part?.lineReference !== '' ? 'edit' : 'add'

      pushToDataLayer({
        event: EVENTS.ADD_NOTE,
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.ADD_NOTE,
        status: action || status,
        value,
      })
    },

    [part]
  )

  const partPrice = priceInformationList?.find(
    (listItem) => listItem.partNumber === part?.partNumber
  )?.price?.netUnitPrice

  const validate = (values: FormData) => {
    const errors: ValidationErrors = {}
    setPriceMessage('')

    if (
      part &&
      values.vehicleReference &&
      values.vehicleReference.length > part.vehicleReference?.maxLength
    ) {
      errors.vehicleReference = i18next.t('formValidation.maxLength', {
        0: MAX_ORDER_ITEM_REFERENCE_LENGTH,
      })
    }

    if (values.newNetPrice && isNaN(+values.newNetPrice)) {
      errors.newNetPrice = i18next.t('commonFormLabels.number')
    } else if (
      part?.price?.netUnitPrice &&
      values.newNetPrice &&
      partPrice &&
      values.newNetPrice > partPrice
    ) {
      setPriceMessage(i18next.t('shoppingLabels.quotationPriceNotApplied'))
    }

    if (
      values.lineReference &&
      values.lineReference.length > MAX_ORDER_ITEM_REFERENCE_LENGTH
    ) {
      errors.lineReference = i18next.t('formValidation.maxLength', {
        0: MAX_ORDER_ITEM_REFERENCE_LENGTH,
      })
    }

    return errors
  }

  const submitOrderItemReference = (values: FormData) => {
    setError(null)
    dispatch(createOrderMutation())
    postToShoppingBasket({
      orderNumber,
      items: [
        {
          partNumber: part?.partNumber,
          lineReference: values.lineReference,
          vehicleReference: values.vehicleReference,
          quotationUnitPrice: values.newNetPrice,
          externalItemReference: part?.externalOrderItemReference,
          updateQuotationUnitPrice: canSetOrderQuotation(),
        },
      ],
    })
    measureInteraction(values.lineReference)
    measureInteraction(values.vehicleReference)

    values.newNetPrice = undefined
    values.lineReference = ''
    values.vehicleReference = ''
  }

  return (
    <SidePanel
      active={editItemSidePanel.active}
      onClose={() => {
        dispatch(setEditPartListLineSidePanel(false))
      }}
      title={i18next.t('shoppingLabels.additionalInformation')}
      position="right"
    >
      <SidePanelBody>
        <Flex sx={{ gap: 3 }}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <span data-t-id="shopping-basket-order-item-reference-partdescription">
              <Text
                as="h4"
                sx={{ margin: 0, fontWeight: 'bold' }}
                color={COLORS.DARK}
              >
                {part?.description}
              </Text>
            </span>
            <span data-t-id="shopping-basket-order-item-reference-partnumber">
              <Text>{part?.partNumber}</Text>
            </span>
          </Box>
          <Box>
            <Image
              src={part?.images?.[0]?.thumbnailImageUrl}
              alt={part?.description}
              height="64px"
            />
          </Box>
        </Flex>

        <Form
          validate={validate}
          onSubmit={submitOrderItemReference}
          initialValues={{
            vehicleReference: part?.vehicleReference.value,
            lineReference: part?.lineReference,
            newNetPrice: part?.price?.quotationUnitPrice?.toFixed(2),
          }}
          render={({ handleSubmit, values }) => (
            <form id="itemAdditionalInformation" onSubmit={handleSubmit}>
              {canSetOrderQuotation() && (
                <>
                  <Text
                    as="h4"
                    variant="heading4"
                    sx={{ margin: 0 }}
                    color={COLORS.DARK}
                  >
                    {i18next.t('shoppingLabels.netPrice')}
                  </Text>
                  <Text
                    sx={{
                      marginBlockStart: 0,
                      marginBlockEnd: 24,
                    }}
                  >
                    {getFormattedPriceString(
                      userCultureCode(),
                      part?.price?.currencyCode,
                      priceInformationList?.find(
                        (listItem) => listItem.partNumber === part?.partNumber
                      )?.price?.netUnitPrice
                    )}
                  </Text>

                  <TextField
                    name="newNetPrice"
                    label={i18next.t('shoppingLabels.newNetPrice')}
                    placeholder={i18next.t('shoppingLabels.typeYourPrice')}
                    prefix={getCurrencySymbol(
                      userCultureCode(),
                      part?.price?.currencyCode
                    )}
                    onFocus={() =>
                      measureInteraction(
                        part?.price?.quotationUnitPrice?.toString() || '',
                        'intention'
                      )
                    }
                    disabled={!order?.isEditAllowed}
                  />
                  <FormFieldText type="info">{priceMessage}</FormFieldText>

                  <FormFieldText type="error">{error}</FormFieldText>

                  <Box
                    sx={{
                      ...getDividerSx('bottom'),
                      marginBlock: 32,
                    }}
                  />
                </>
              )}

              <TextField
                data-t-id="shopping-basket-input-order-vehicle-reference"
                name="vehicleReference"
                label={i18next.t('shoppingLabels.vehicleReference')}
                placeholder={`${i18next.t(
                  'commonLabels.forExample'
                )} XLRTE47MS0E843658`}
                disabled={posting || !order?.isEditAllowed}
                onFocus={() =>
                  measureInteraction(
                    part?.vehicleReference?.value || '',
                    'intention'
                  )
                }
              />

              <TextAreaField
                name="lineReference"
                label={i18next.t('shoppingLabels.remarks')}
                data-t-id="shopping-basket-input-line-reference"
                disabled={posting || !order?.isEditAllowed}
                onFocus={() =>
                  measureInteraction(part?.lineReference || '', 'intention')
                }
              />

              {showMainFrameMaxLengthMessage &&
                values.lineReference?.length >
                  MAX_MAINFRAME_ORDER_ITEM_REFERENCE_LENGTH && (
                  <>
                    <h4 data-t-id="shopping-basket-order-item-mainframe-length-title">
                      {i18next.t('shoppingLabels.mainframeMaxReferenceLength', {
                        maxCount: MAX_MAINFRAME_ORDER_ITEM_REFERENCE_LENGTH,
                      })}
                    </h4>
                    <Box
                      data-t-id="shopping-basket-order-item-mainframe-length-description"
                      as="p"
                      sx={{
                        wordBreak: 'break-all',
                      }}
                    >
                      &apos;
                      {values.lineReference?.substring(
                        0,
                        MAX_MAINFRAME_ORDER_ITEM_REFERENCE_LENGTH
                      )}
                      ...&apos;
                    </Box>
                  </>
                )}
            </form>
          )}
        />
      </SidePanelBody>

      <SidePanelFooter
        sx={{ display: 'grid', gap: '16px', button: { width: '100%' } }}
      >
        <Button
          data-t-id="shopping-basket-save-order-item-reference"
          variant="primary"
          disabled={posting || !order?.isEditAllowed}
          type="submit"
          form="itemAdditionalInformation"
        >
          {i18next.t('commonLabels.save')}
        </Button>

        {order?.items?.length !== 1 && (
          <Button
            data-t-id="shopping-basket-save-and-next-order-item-reference"
            variant="outline"
            disabled={posting || !order?.isEditAllowed}
            type="submit"
            form="itemAdditionalInformation"
            onClick={() => {
              setOpenNextPart(true)
            }}
          >
            {i18next.t('commonLabels.saveAndNext')}
          </Button>
        )}
      </SidePanelFooter>
    </SidePanel>
  )
}

export default ShoppingBasketEditItemSidePanel
