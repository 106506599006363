import React, { ReactNode, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Flex } from 'theme-ui'
import { RootState } from '../../../reducers'
import Overlay from '../../molecules/Overlay/Overlay'
import useOutsideClick from '../../../hooks/useOutsideClick'
import Animate from '../../atoms/Animate/Animate'

interface PartsNavigationOverlayProps {
  onClose: () => void
  children: ReactNode
}

const PartsNavigationOverlay = ({
  onClose,
  children,
}: PartsNavigationOverlayProps) => {
  const { partsNavigationOpen } = useSelector((state: RootState) => state.navigation)
  const contentRef = useRef<HTMLElement | null>(null)

  useOutsideClick(contentRef, onClose, !partsNavigationOpen)

  return (
    <Overlay isActive={partsNavigationOpen}>
      <Animate
        from={{
          translate: '-360px',
        }}
        enter={{
          translate: 0,
        }}
        active={partsNavigationOpen}
      >
        <Flex
          ref={contentRef}
          sx={{
            position: 'relative',
            flexDirection: 'column',
            height: '100%',
            width: ['100vw', '100vw', '100vw', 'unset'],
          }}
        >
          {children}
        </Flex>
      </Animate>
    </Overlay>
  )
}

export default PartsNavigationOverlay
