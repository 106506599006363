export const EVENTS = {
  SELECT_COMPANY: 'selectCompany',
  SELECT_SUPPLIER: 'selectSupplier',
  CLOSE_FILTER: 'closeFilter',
  SUBMIT_FILTERS: 'submitFilters',
  REMOVE_ALL_FILTERS: 'removeAllFilters',
  VIEW_FILTER_IN_PAGE: 'viewFilterInPage',
  ADD_FILTER_IN_PAGE: 'addFilterInPage',
  REMOVE_FILTER_IN_PAGE: 'removeFilterInPage',
  NAVIGATE_MENU_CLOSE: 'navigateMenuClose',
  NAVIGATE_IN_PAGE: 'navigateInPage',
  CLICK: 'click',
  SELECT_ITEM: 'select_item',
  ADD_NOTE: 'add note',
  LINK_CLICK: 'linkClick',
  SOCIAL_SHARE: 'social share',
  REQUEST_ACCOUNT: 'requestAccount',
  ADD_TO_QUOTATION: 'addToQuotation',
  FIND_DEALER: 'find dealer',
  FIND_DEALER_REQUEST_FORM_SELECT: 'find dealer request form select',
  BEGIN_CHECKOUT: 'begin_checkout',
}

export const EVENT_CATEGORIES = {
  HEADER_SELECT_COMPANY: 'header - company selection',
  HEADER_SELECT_SUPPLIER: 'header - supplier selection',
  CLOSE_FILTER: 'close filter',
  SUBMIT_FILTER: 'submit filter',
  REMOVE_FILTER: 'remove filter',
  ADD_FILTER_IN_PAGE: 'add filter - in page',
  REMOVE_FILTER_IN_PAGE: 'remove filter - in page',
  NAVIGATE_MENU_CLOSE: 'navigate - menu - close',
  LINK_CLICK: 'linkClick',
  QUICK_NAVIGATION: 'quickNavigation',
  SOCIAL: 'social',
  ECOMMERCE: 'ecommerce',
  ADD_NOTE: 'add note',
  REQUEST_ACCOUNT: 'request account',
  ADD_TO_QUOTATION: 'addToQuotation',
  CAROUSEL: 'carousel',
  JUMP_TO_TABLE_IN_PAGE: 'jump to table - in page',
  FIND_DEALER: 'find dealer',
  UNCATEGORIZED: 'uncategorized',
}
