import React, { FC } from 'react'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { BannerParams, Datasource, Params } from '../../../types/layoutService'
import Banner, { DEFAULT_BANNER_VARIANT } from '../../atoms/Banner/Banner'
import BannerCallToAction from '../../atoms/Banner/BannerCallToAction'
import { BannerGradient } from '../../atoms/Banner/BannerGradient'
import BannerImage from '../../atoms/Banner/BannerImage'
import BannerLogo from '../../atoms/Banner/BannerLogo'
import BannerVideo from '../../atoms/Banner/BannerVideo'
import { HeroBannerDatasource } from '../HeroBanner/HeroBanner'

const HeroBannerWithCallToAction: FC<
  Datasource<HeroBannerDatasource> & Params<BannerParams>
> = ({
  datasource: { title, button, image, video, logo, subtitle },
  params: {
    bottomSpacing,
    variant = DEFAULT_BANNER_VARIANT,
    gradientType,
    logoPosition,
    hasNegativeOffset,
  },
  rendering,
}) => (
  <Banner bottomSpacing={bottomSpacing}>
    <Box sx={{ position: 'relative' }}>
      <BannerImage imageField={image} variant={variant} />

      <BannerVideo video={video} />

      <BannerGradient gradientType={gradientType} visible={!!image?.src} />

      <BannerLogo
        logoImageField={logo?.targetItem?.image}
        logoPosition={logoPosition}
      />
    </Box>

    <BannerCallToAction
      title={getTextFieldValue(title)}
      subtitle={subtitle?.value}
      button={button}
      variant={variant}
      hasNegativeOffset={hasNegativeOffset}
      rendering={rendering}
    />
  </Banner>
)

export default withData(HeroBannerWithCallToAction)
