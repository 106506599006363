import React, { FC } from 'react'
import { Box } from 'theme-ui'
import { DealerPartFulfilled } from '../../../../../hooks/services/rest/ecommerce/useDealerPartSearchService'
import { LinkField } from '../../../../../types/layoutService'
import { getDividerSx } from '../../../../atoms/Divider/Divider'
import { partSearchGridTemplate } from '../../PartSearch/Line/PartSearchLineView'
import SearchResultsLineView from '../../SearchResultsLineViewColumnTitles'
import DealerPartSearchLine from './DealerPartSearchLine'

interface DealerPartSearchLineViewProps {
  dealerParts?: DealerPartFulfilled[]
  dealerPartPage: LinkField
}

const DealerPartSearchLineView: FC<DealerPartSearchLineViewProps> = ({
  dealerParts,
  dealerPartPage,
}) => {
  if (!dealerParts?.length) return null

  return (
    <Box sx={{ ...getDividerSx('top') }}>
      <SearchResultsLineView gridTemplate={partSearchGridTemplate} />

      {dealerParts.map((dealerPart, i) => (
        <DealerPartSearchLine
          key={dealerPart.searchDealerPart.partNumber}
          dealerPartPage={dealerPartPage}
          gridTemplate={partSearchGridTemplate}
          index={i}
          {...dealerPart}
        />
      ))}
    </Box>
  )
}

export default DealerPartSearchLineView
