import React, { forwardRef, useContext } from 'react'
import { Box } from 'theme-ui'
import DigitalMagazineBackground from './DigitalMagazineBackground'
import { ImageWithFocalPointField, VideoField } from '../../../types/layoutService'
import Container from '../../atoms/Container/Container'
import { loggedIn } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'

export interface DigitalMagazineSlideProps {
  children: React.ReactNode
  image?: ImageWithFocalPointField
  video?: VideoField
  ref?: React.RefObject<HTMLDivElement>
  id: string
  isCover?: boolean // cover has different height because of sticky menu
}
const DigitalMagazineSlide = forwardRef(
  ({ children, image, video, id, isCover }: DigitalMagazineSlideProps, ref) => {
    const { user } = useContext(UserContext)
    const menuHeight = loggedIn(user) ? '114px' : '72px'
    const coverHeight =
      isCover && loggedIn(user) ? 'calc(100vh - 114px)' : 'calc(100vh - 72px)'
    return (
      <Box
        as="section"
        id={id}
        ref={ref}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: 'calc(100vh - 72px)',
          scrollMarginTop: menuHeight,
          scrollSnapAlign: 'start',
          '&:first-of-type': {
            height: coverHeight,
          },
        }}
      >
        <DigitalMagazineBackground image={image} video={video} />

        <Container
          sx={{
            zIndex: 1,
            position: 'absolute',
            height: coverHeight,
          }}
        >
          {children}
        </Container>
      </Box>
    )
  }
)

export default DigitalMagazineSlide
