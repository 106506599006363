import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import { FONT_AWESOME_ICONS } from '../../../constants/iconConstants'
import { NotificationSeverity } from '../../../types/notificationTypes'
import { ThemeProps } from '../../../types/themeProps'
import IconWrapper from '../IconWrapper/IconWrapper'

interface NotificationLineProps {
  severity: NotificationSeverity
  message: string
}

const iconColor = (type: NotificationSeverity, themeSettings: ThemeProps) =>
  ({
    Error: 'error',
    Warning: themeSettings.colors.promotion[0],
    Info: 'primary',
    Success: 'success',
  }[type])

const NotificationLine: FC<NotificationLineProps> = ({ severity, message }) => {
  const themeSettings = useTheme()

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <IconWrapper
        icon={FONT_AWESOME_ICONS.EXCLAMATION_TRIANGLE}
        size={3}
        color={iconColor(severity, themeSettings)}
      />

      <Text
        as="p"
        variant="bodyCompact"
        sx={{
          ml: 3,
          color: 'gray.2',
        }}
      >
        {message}
      </Text>
    </Flex>
  )
}

export default NotificationLine
