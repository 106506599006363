import React, { FC } from 'react'
import { useTheme } from '@emotion/react'
import ClickableWrapper, {
  ClickableWrapperProps,
} from '../ClickableWrapper/ClickableWrapper'

interface ClickableMenuItemWrapperProps extends ClickableWrapperProps {
  active?: boolean
}

const ClickableMenuItemWrapper: FC<ClickableMenuItemWrapperProps> = ({
  children,
  active = false,
  sx,
  ...rest
}) => {
  const { transitions } = useTheme()

  return (
    <ClickableWrapper
      sx={{
        position: 'relative',
        outline: 'none',
        color: active ? 'var(--active-parent-link-color)' : 'inherit',
        '::after': {
          display: ['none', 'none', 'none', 'block'],
          zIndex: 1,
          position: 'absolute',
          content: '""',
          width: '100%',
          bottom: '-1px',
          left: 0,
          borderBottom: '2px solid',
          borderBottomColor: active
            ? 'var(--active-parent-link-color)'
            : 'transparent',
          transition: `ease all ${transitions[1]}`,
        },

        ':hover, :focus, :active': {
          color: 'inherit',
          '::after': {
            borderBottomColor: 'currentColor',
            width: '100%',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </ClickableWrapper>
  )
}

export default ClickableMenuItemWrapper
