import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Text } from 'theme-ui'
import { Label } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import Checkbox from '../../../atoms/inputs/Checkbox/Checkbox'
import {
  getFilterStringByActiveSearchResultFilterObject,
  toggleFilter,
} from '../../../../helpers/filterHelper'
import { displayValueOrConvertBooleanToHumanReadableString } from '../../../../constants/partAttributeHelper'
import { URL_PAGE_PARAM } from '../../../../constants/urlConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { PartSearchResultContext } from '../../../../providers/PartSearchResultContextProvider'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import Radio from '../../../atoms/inputs/Radio/Radio'
import {
  FILTER_GROUP_TYPE_CHECKBOX,
  FILTER_GROUP_TYPE_RADIOBUTTON,
} from '../../../../constants/searchConstants'

export interface FilterOptionProps {
  id: string
  description: string
  value: string
  groupDescription: string
  disabled?: boolean
  onChange: () => void
  type: typeof FILTER_GROUP_TYPE_CHECKBOX | typeof FILTER_GROUP_TYPE_RADIOBUTTON
}

const PartFilterOption: FC<FilterOptionProps> = ({
  id,
  description,
  value,
  groupDescription,
  disabled,
  onChange,
  type,
}) => {
  const history = useHistory()
  const { activeFilters } = useContext(PartSearchResultContext)

  const checked = useMemo(
    () => {
      if (
        type === FILTER_GROUP_TYPE_RADIOBUTTON &&
        value === '' &&
        !activeFilters?.[id]
      ) {
        return true
      }

      return activeFilters?.[id]?.includes(value) ?? false
    },
    // id & value are available on mount

    [activeFilters]
  )
  const checkboxId = `${id}-${value}`

  const handleCheckClick = useCallback(() => {
    if (type === FILTER_GROUP_TYPE_RADIOBUTTON) {
      if (activeFilters) {
        delete activeFilters[id]
      }
    }

    const newFilterObject = toggleFilter(activeFilters || {}, id, value)
    const params = new URLSearchParams(history.location.search)

    if (checked) {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FILTER,
        filter_action: DATA_LAYER.CUSTOM_DIMENSION.FILTER_ACTION.REMOVE_OVERLAY,
        filter_type: '',
        filter_name: groupDescription,
        filter_value: description,
      })
    } else {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FILTER,
        filter_action: DATA_LAYER.CUSTOM_DIMENSION.FILTER_ACTION.ADD,
        filter_type: '',
        filter_name: groupDescription,
        filter_value: description,
      })
    }

    params.delete(URL_PAGE_PARAM)

    history.push(
      `?${getFilterStringByActiveSearchResultFilterObject(
        newFilterObject,
        params,
        false,
        false
      )}`
    )

    onChange()
    // groupDescription, description, id & value are available on mount
  }, [activeFilters, checked, history])

  return (
    <Label
      htmlFor={checkboxId}
      sx={{
        mb: 2,
        display: 'flex',
        cursor: 'pointer',
      }}
    >
      <Text as="span" variant="bodySmall" sx={{ color: 'gray.2', flex: 1, pr: 3 }}>
        {displayValueOrConvertBooleanToHumanReadableString(description)}
      </Text>

      {type === FILTER_GROUP_TYPE_RADIOBUTTON ? (
        <Radio
          key={checkboxId}
          id={checkboxId}
          name={id}
          value={value}
          onChange={handleCheckClick}
          checked={checked}
          disabled={disabled}
        />
      ) : (
        <Checkbox
          key={checkboxId}
          id={checkboxId}
          value={value}
          onChange={handleCheckClick}
          checked={checked}
          disabled={disabled}
        />
      )}
    </Label>
  )
}

export default PartFilterOption
