import React, { useContext } from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import axios, { AxiosResponseHeaders } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { FormSpy } from 'react-final-form'
import ClickableWrapper from '../../../../atoms/ClickableWrapper/ClickableWrapper'
import { COLORS } from '../../../../../constants/themeConstants'
import SidePanelClickableSection from '../../SidePanelClickableSection/SidePanelClickableSection'
import {
  getMileageValue,
  validateAndGetDate,
} from '../../../../../helpers/vehicleHelper'
import { VehicleDetailResponse } from '../../../../../services/rest/ecommerce/dafVehicleDetail'
import useSitecoreContext from '../../../../../hooks/useSitecoreContext'
import useAxiosClient from '../../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../../providers/UserProvider'
import fetchDafVehicleRmDocuments, {
  DafVehicleRmDocumentsResponse,
} from '../../../../../services/rest/ecommerce/dafVehicleRmDocuments'
import fetchDafVehicleUrlRmDocument from '../../../../../services/rest/ecommerce/dafVehicleUrlRmDocument'
import {
  getFileExtension,
  getFilenameFromHeaders,
  getFiletypeFromHeaders,
  saveFile,
} from '../../../../../helpers/fileHelper'
import { SelectField, TextAreaField } from '../../../../atoms/FormFields'
import { InputDateField } from '../../../../atoms/FormFields/InputDateField'
import { ValidationRules } from '../../../../../helpers/formFieldHelper'
import { ActiveStoreProviderContext } from '../../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../../types/userProps'

interface EarlyTerminationFormProps {
  vehicle: VehicleDetailResponse | undefined
}

const EarlyTerminationForm = ({ vehicle }: EarlyTerminationFormProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const chassisNumber =
    vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber ?? ''
  const terminationOptions = [
    {
      value: '1',
      text: i18next.t('earlyTerminationFormLabels.vehicleTotalLossLabel'),
    },
    { value: '2', text: i18next.t('earlyTerminationFormLabels.vehicleSoldLabel') },
    { value: '3', text: i18next.t('earlyTerminationFormLabels.vehicleStolenLabel') },
    {
      value: '4',
      text: i18next.t(
        'earlyTerminationFormLabels.vehicleReturnedToLeaseCompanyLabel'
      ),
    },
    {
      value: '5',
      text: i18next.t('earlyTerminationFormLabels.vehicleReplacedLabel'),
    },
    {
      value: '6',
      text: i18next.t('earlyTerminationFormLabels.vehicleReachedEndMileageLabel'),
    },
    { value: '7', text: i18next.t('earlyTerminationFormLabels.otherLabel') },
  ]

  const getDafVehicleRmDocuments = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafVehicleRmDocuments(
      dafVehicleDetailQueryParams,
      chassisNumber,
      client,
      token,
      user
    )
    return response.data
  }

  const { data: dafVehicleRmDocuments } = useQuery({
    queryKey: ['getDafVehicleRmDocuments'],
    queryFn: getDafVehicleRmDocuments,
    enabled: !!vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber,
  })

  const findContractDocument = (vehicleDocuments: DafVehicleRmDocumentsResponse) =>
    vehicleDocuments?.documents?.find(
      (document) => document.url.documentType === 'Contract'
    )

  const vehicleContractDocument =
    dafVehicleRmDocuments && dafVehicleRmDocuments.result
      ? findContractDocument(dafVehicleRmDocuments.result)
      : undefined

  const getDafVehicleUrlRmDocument = async () => {
    const { token } = axios.CancelToken.source()

    if (vehicleContractDocument && vehicleContractDocument.url) {
      return fetchDafVehicleUrlRmDocument(
        vehicleContractDocument.url,
        client,
        token,
        user
      )
    }
    return undefined
  }

  const { data: dafVehicleUrlRmDocument } = useQuery({
    queryKey: ['getDafVehicleUrlRmDocument'],
    queryFn: getDafVehicleUrlRmDocument,
    enabled: !!vehicleContractDocument,
  })
  const handleDocumentClick = () => {
    if (dafVehicleUrlRmDocument) {
      if (dafVehicleUrlRmDocument && dafVehicleUrlRmDocument.status === 200) {
        const headers = dafVehicleUrlRmDocument.headers as AxiosResponseHeaders
        let fileName = 'service plan agreement'
        fileName += `.${getFileExtension(getFilenameFromHeaders(headers, fileName))}`
        saveFile(
          dafVehicleUrlRmDocument,
          fileName,
          getFiletypeFromHeaders(headers, 'octet/stream')
        )
      }
    }
  }
  return (
    <>
      <SidePanelClickableSection label={i18next.t('formLabels.currentServicePlan')}>
        <Box>
          <Box sx={{ marginBottom: 2 }}>
            <Text
              variant="bodySmall"
              color={COLORS.BLACK}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {i18next.t('formLabels.plan')}
            </Text>
            <Text variant="bodySmall" color={COLORS.GRAY}>
              {vehicle?.rmContract?.servicePlan?.packageName}
            </Text>
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <Text
              variant="bodySmall"
              color={COLORS.BLACK}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {i18next.t('formLabels.endDate')}
            </Text>
            <Text variant="bodySmall" color={COLORS.GRAY}>
              {validateAndGetDate(
                vehicle?.rmContract?.servicePlan?.details?.endDate
              )}
            </Text>
          </Box>
          {vehicle?.rmContract?.servicePlan?.details?.yearlyKilometers && (
            <Box>
              <Text
                variant="bodySmall"
                color={COLORS.BLACK}
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {i18next.t('formLabels.mileage')}
              </Text>
              <Text variant="bodySmall" color={COLORS.GRAY}>
                {getMileageValue(
                  vehicle?.rmContract?.servicePlan?.details?.yearlyKilometers,
                  cultureCode
                )}
              </Text>
            </Box>
          )}
          {vehicleContractDocument && (
            <Box sx={{ marginTop: 2 }}>
              <ClickableWrapper
                sx={{ fontSize: 2 }}
                onClick={() => handleDocumentClick()}
              >
                <Text
                  variant="bodySmall"
                  color={COLORS.DAF_BLUE}
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {i18next.t('formLabels.downloadServicePlanAgreement')}
                </Text>
              </ClickableWrapper>
            </Box>
          )}
        </Box>
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={i18next.t('earlyTerminationFormLabels.terminationDatePanelTitle')}
      >
        <Box>
          <InputDateField
            name="terminationDate"
            validationRules={[
              ValidationRules.ShouldBeTodayOrInTheFuture,
              ValidationRules.Required,
            ]}
            label={i18next.t('earlyTerminationFormLabels.terminationDateLabel')}
          />
        </Box>
        <Box>
          <SelectField
            name="earlyTerminationReason"
            placeholder={i18next.t(
              'earlyTerminationFormLabels.earlyTerminationPlaceholderLabel'
            )}
            label={i18next.t(
              'earlyTerminationFormLabels.earlyTerminationReasonLabel'
            )}
            options={terminationOptions}
          />
        </Box>
        <FormSpy>
          {(props) =>
            props.values.earlyTerminationReason === '7' ? (
              <>
                <Box>
                  <TextAreaField
                    name="specificReason"
                    label={i18next.t(
                      'earlyTerminationFormLabels.specificReasonLabel'
                    )}
                    placeholder={i18next.t(
                      'earlyTerminationFormLabels.specificReasonPlaceholderLabel'
                    )}
                  />
                </Box>
              </>
            ) : null
          }
        </FormSpy>
        <Box>
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: 0,
            }}
            color={COLORS.GRAY}
          >
            {i18next.t('earlyTerminationFormLabels.formNote')}
          </Text>
        </Box>
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={`${i18next.t('formLabels.addRemarks')} (${i18next.t(
          'formLabels.optional'
        )})`}
      >
        <Box>
          <TextAreaField
            required={false}
            name="remarks"
            label={i18next.t('formLabels.addRemarks')}
            placeholder={i18next.t('formLabels.typeYourRemark')}
          />
        </Box>
      </SidePanelClickableSection>
    </>
  )
}

export default EarlyTerminationForm
