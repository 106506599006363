import {
  createDefaultFieldFactory,
  FieldTypes,
} from '@sitecore-jss/sitecore-jss-react-forms'
import {
  Button,
  Checkbox,
  DropdownList,
  Email,
  MultipleLineText,
  Section,
  SingleLineText,
  Telephone,
  TextField,
} from './FieldTemplates'

const FieldFactory = createDefaultFieldFactory()

FieldFactory.setComponent(FieldTypes.Button, Button)
FieldFactory.setComponent(FieldTypes.Checkbox, Checkbox)
FieldFactory.setComponent(FieldTypes.DropdownList, DropdownList)
FieldFactory.setComponent(FieldTypes.Email, Email)
FieldFactory.setComponent(FieldTypes.MultipleLineText, MultipleLineText)
FieldFactory.setComponent(FieldTypes.Section, Section)
FieldFactory.setComponent(FieldTypes.SingleLineText, SingleLineText)
FieldFactory.setComponent(FieldTypes.Telephone, Telephone)
FieldFactory.setComponent(FieldTypes.TextField, TextField)

// Clear out unsupported fields
FieldFactory.setComponent(FieldTypes.CheckboxList, () => null)
FieldFactory.setComponent(FieldTypes.DateField, () => null)
FieldFactory.setComponent(FieldTypes.FileUpload, () => null)
FieldFactory.setComponent(FieldTypes.ListBox, () => null)
FieldFactory.setComponent(FieldTypes.Password, () => null)
FieldFactory.setComponent(FieldTypes.RadioButtonList, () => null)

export default FieldFactory
