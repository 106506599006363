import React, { useContext, useMemo } from 'react'
import i18next from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import { Box, Flex, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import Button from '../../../atoms/Button/Button'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'
import SidePanelFooter from '../SidePanelFooter'
import { toggleDeleteVehiclePanel } from '../../../../actions/dafCompanyVehicleActions'
import deleteCompanyVehicle from '../../../../services/rest/ecommerce/updateVehicle'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { UserContext } from '../../../../providers/UserProvider'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { addToast } from '../../../../actions/toastActions'
import toCamelCase from '../../../../helpers/camelCaseHelper'
import { getVehicleLabel } from '../../../../helpers/vehicleHelper'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import { RootState } from '../../../../reducers'
import { DeleteVehicleSidepanelDatasource } from '../../../../types/VehicleDetailGenericComponentDataProps'
import { getFormattedSidepanelVehicleIntroduction } from '../../../../helpers/sidepanelHelper'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import SpinnerWithLabel from '../../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import { COLORS } from '../../../../constants/themeConstants'
import { ICONS } from '../../../../constants/iconConstants'
import { resolveEnvironment } from '../../../../helpers/environment'
import {
  ACTIVE_CONTRACT,
  CONNECTED_VEHICLE,
  CONNECTED_VEHICLE_AND_ACTIVE_CONTRACT,
} from '../../../../constants/dafCompanyVehicleConstants'

interface DeleteVehiclePanelProps {
  datasource: DeleteVehicleSidepanelDatasource
  vehicle?: VehicleDetailResponse
  onFormSuccess?: () => void
  isPending?: boolean
}

interface SpecificErrorToastsProps {
  [key: string]: string
}

const DeleteVehiclePanel = ({
  datasource,
  vehicle,
  onFormSuccess,
  isPending,
}: DeleteVehiclePanelProps) => {
  const dispatch = useDispatch()
  const { user } = useContext(UserContext)
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { token } = axios.CancelToken.source()
  const id = useMemo(() => uuidv4(), [])
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const env = resolveEnvironment()
  const { deleteVehiclePanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const handleConfirmButton = () => {
    deleteVehicleAction()
  }
  const specificErrorToasts: SpecificErrorToastsProps = {
    toastGeneralError: getTextFieldValue(datasource.generalErrorToast),
    deleteVehicleLatestContractForVehicle: getTextFieldValue(
      datasource.latestContractForVehicleErrorToast
    ),
    deleteVehicleHasActiveMaintenancePlan: getTextFieldValue(
      datasource.hasActiveMaintenancePlanErrorToast
    ),
    deleteVehicleIncorrectVinNumber: getTextFieldValue(
      datasource.incorrectVINNumberErrorToast
    ),
  }

  const getNotAllowedReasonMessage = (reason: string) => {
    switch (reason) {
      case CONNECTED_VEHICLE:
        return getTextFieldValue(datasource.connectedVehicle)
      case ACTIVE_CONTRACT:
        return getTextFieldValue(datasource.activeContract)
      case CONNECTED_VEHICLE_AND_ACTIVE_CONTRACT:
        return getTextFieldValue(datasource.connectedVehicleAndActiveContract)
      default:
        return ''
    }
  }

  const { isPending: isDeleting, mutate: deleteVehicleAction } = useMutation({
    mutationFn: () =>
      deleteCompanyVehicle(
        vehicle?.vehicleDetail.vehicleAttributes.vin,
        dafVehicleDetailQueryParams,
        client,
        token,
        user
      ),

    onSuccess: (response) => {
      dispatch(toggleDeleteVehiclePanel({ isActive: false }))

      const responseHasErrorMessages = !!response.data?.messages?.length
      let toastMessageKey = getTextFieldValue(datasource?.successToast)

      if (responseHasErrorMessages) {
        const responseErrorCode = toCamelCase(response?.data.messages?.[0].code)
        const errorToastKey =
          responseErrorCode === undefined ? 'toastGeneralError' : responseErrorCode
        toastMessageKey = specificErrorToasts[errorToastKey]
      }

      dispatch(
        addToast({
          id,
          message: i18next.t(toastMessageKey, {
            registrationNumber: getVehicleLabel(vehicle?.vehicleDetail),
          }),
        })
      )

      if (onFormSuccess && !responseHasErrorMessages) onFormSuccess()
    },

    onError: () => {
      dispatch(toggleDeleteVehiclePanel({ isActive: false }))
      dispatch(
        addToast({
          id,
          message: i18next.t('errorMessages.pleaseTryAgainLater'),
        })
      )
    },
  })
  return (
    <SidePanel
      position="right"
      active={deleteVehiclePanelState}
      onClose={() => {
        dispatch(toggleDeleteVehiclePanel({ isActive: false }))
      }}
      disableOutsideClick
      title={getTextFieldValue(datasource.panelTitle)}
    >
      <SidePanelBody>
        {isPending && (
          <Flex
            sx={{
              width: '100%',
              height: '100%',
              paddingY: 5,
            }}
          >
            <SpinnerWithLabel
              label={i18next.t('commonLabels.loading')}
              sx={{
                width: 'max-content',
                position: 'sticky',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          </Flex>
        )}

        {!isPending && (
          <>
            {vehicle?.vehicleActions?.delete?.allowed ? (
              getFormattedSidepanelVehicleIntroduction(datasource, vehicle)
            ) : (
              <Text
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {i18next.t(getTextFieldValue(datasource.cannotDeleteVehicle), {
                  registrationNumber: `${getVehicleLabel(vehicle?.vehicleDetail)}`,
                })}
              </Text>
            )}

            {vehicle?.vehicleActions?.delete?.notAllowedReason && (
              <>
                <Box sx={{ marginTop: 4 }}>
                  <Text>
                    {getNotAllowedReasonMessage(
                      vehicle?.vehicleActions?.delete?.notAllowedReason
                    )}
                  </Text>
                </Box>
                {vehicle?.vehicleActions?.delete?.notAllowedReason ===
                  CONNECTED_VEHICLE && (
                  <Button
                    as="a"
                    href={env?.vars?.connectPortalUrl}
                    color={COLORS.DAF_BLUE}
                    target="noopener"
                    variant="infinite"
                    icon={ICONS.EXIT}
                    iconPosition="end"
                    iconSize={4}
                    sx={{
                      marginTop: 4,
                      paddingLeft: 0,
                    }}
                  >
                    {i18next.t('commonLabels.visitPaccarConnectPortal')}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </SidePanelBody>
      <SidePanelFooter>
        {vehicle?.vehicleActions?.delete?.allowed ? (
          <Button
            variant="primary"
            type="submit"
            disabled={isDeleting || !vehicle}
            sx={{ width: '100%' }}
            onClick={() => handleConfirmButton()}
          >
            {i18next.t('commonFormLabels.confirm')}
          </Button>
        ) : (
          <Button
            variant="outline"
            type="submit"
            sx={{ width: '100%' }}
            onClick={() => dispatch(toggleDeleteVehiclePanel({ isActive: false }))}
          >
            {i18next.t('commonLabels.close')}
          </Button>
        )}
      </SidePanelFooter>
    </SidePanel>
  )
}

export default DeleteVehiclePanel
