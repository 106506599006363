import { Environment } from '../types/environmentProps'
import {
  ENVIRONMENT_CERT,
  ENVIRONMENT_DEVELOPMENT,
  ENVIRONMENT_LOCAL,
  ENVIRONMENT_PROD,
  ENVIRONMENT_TEST,
  ENVIRONMENTS,
} from '../constants/environmentConstants'
import { getWindow } from './dom'

export const resolveHost = (): string | undefined => {
  if (!getWindow()) return undefined
  return window.location.host
}

export const resolveEnvironment = (name?: string): Environment | undefined => {
  if (name) {
    return ENVIRONMENTS.find((env) => env.name === name)
  }

  const host = resolveHost()
  if (!host) return undefined

  return ENVIRONMENTS.find((environment) =>
    environment.patterns.some((pattern) => pattern.test(host))
  )
}

export const isEnvironment = (...names: string[]): boolean => {
  const env = resolveEnvironment()
  return !!env && names.some((name) => name === env.name)
}

export const isDevelopment = (): boolean =>
  isEnvironment(
    ENVIRONMENT_LOCAL,
    ENVIRONMENT_DEVELOPMENT,
    ENVIRONMENT_TEST,
    ENVIRONMENT_CERT
  )

export const isProduction = (): boolean => isEnvironment(ENVIRONMENT_PROD)
