import { useEffect, useState } from 'react'
import { getWindow } from '../helpers/dom'
import { getApplicationHeaderHeight } from '../helpers/layoutHelper'
import useWindowSize from './useWindowSize'

export default () => {
  if (!getWindow()) return 0

  const [height, setHeight] = useState(getApplicationHeaderHeight())
  const windowSize = useWindowSize()

  useEffect(() => {
    setHeight(getApplicationHeaderHeight())
  }, [windowSize.height])

  return height
}
