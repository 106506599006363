import { GraphQLClient } from 'graphql-request'
import { useContext } from 'react'
import { isBrowser } from '../../../../helpers/dom'
import { GraphQLClientContext } from '../../../../providers/GraphQLClientProvider'

const useGraphQLClient = (): GraphQLClient => {
  const client = useContext(GraphQLClientContext)

  if (!isBrowser()) {
    // Return undefined when running in SSR context.
    // This prevents allocating objects on the global heap that were not freed correctly by the garbage collector.
    // We can do this safely because the result of this hook is only consumed client side in useEffect callbacks,
    // which do not run on the server.
    return undefined as unknown as GraphQLClient
  }

  if (!client) {
    throw new Error(
      'GraphQL client not available please render a <GraphQLClientProvider /> in your AppRoot.'
    )
  }

  return client
}

export default useGraphQLClient
