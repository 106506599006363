import React, { FC, useCallback } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import { OnNavigate } from '../../atoms/PaginationPageNumber/PaginationPageNumber'
import Pagination from '../Pagination/Pagination'
import SpinnerWithLabel from '../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import { URL_PAGE_PARAM } from '../../../constants/urlConstants'
import { scrollTo } from '../../../helpers/dom'

interface PaginatedProps extends Omit<BoxProps, 'css'> {
  fetching?: boolean
  fetchingLabel: string
  onNavigate?: OnNavigate
  totalCount?: number
  previousIndex?: number | null
  currentIndex?: number
  nextIndex?: number | null
}

const Paginated: FC<PaginatedProps> = ({
  onNavigate,
  fetching,
  fetchingLabel,
  totalCount,
  previousIndex,
  currentIndex,
  nextIndex,
  children,
  sx,
  ...boxProps
}) => {
  const history = useHistory()

  const addPageNumberToUrl = useCallback(
    (pageIndex: number) => {
      const params = new URLSearchParams(history.location.search)
      const pageNumber = pageIndex + 1

      params.set(URL_PAGE_PARAM, pageNumber.toString())

      history.push(`?${params.toString()}`)
    },
    [history]
  )

  return (
    <>
      <Box
        sx={{
          display: 'block',
          position: 'relative',
          minHeight: '56px',
          marginBottom: [4, 4, 4, 5],
          ...sx,
        }}
        {...boxProps}
      >
        {fetching && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <SpinnerWithLabel
              label={fetchingLabel}
              sx={{
                width: 'max-content',
                position: 'sticky',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          </Box>
        )}

        <Box sx={{ opacity: fetching ? '0.35' : '1', overflow: 'hidden' }}>
          {children}
        </Box>
      </Box>

      {totalCount && totalCount > 0 ? (
        <Pagination
          onNavigate={(newPageIndex) => {
            addPageNumberToUrl(newPageIndex)
            if (onNavigate) onNavigate(newPageIndex)
            // scroll page to top to see new results
            scrollTo({ top: 0 })
          }}
          totalCount={totalCount}
          previousIndex={previousIndex}
          currentIndex={currentIndex}
          nextIndex={nextIndex}
        />
      ) : null}
    </>
  )
}

export default Paginated
