import {
  NotificationActions,
  NotificationInformation,
  REGISTER_MODAL_NOTIFICATION,
  RESET_MODAL_NOTIFICATIONS,
} from '../actions/notificationActions'

export interface NotificationReducerState {
  modalNotifications?: NotificationInformation[]
}

const DEFAULT_STATE = {
  modalNotifications: undefined,
}

const notificationReducer = (
  state: NotificationReducerState = DEFAULT_STATE,
  action: NotificationActions
): NotificationReducerState => {
  switch (action.type) {
    case REGISTER_MODAL_NOTIFICATION:
      return {
        ...state,
        modalNotifications: [action.payload, ...(state?.modalNotifications || [])],
      }

    case RESET_MODAL_NOTIFICATIONS:
      return {
        ...state,
        modalNotifications: undefined,
      }

    default:
      return state
  }
}

export default notificationReducer
