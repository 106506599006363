import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Box } from 'theme-ui'
import useIntersectionObserver from '../../../hooks/useIntersectionObserver'
import SitecoreImage, { SitecoreImageProps } from './SitecoreImage'
import { runOnWindow } from '../../../helpers/dom'

interface SitecoreImageParallaxProps extends SitecoreImageProps {
  offset?: number
}

const percentageInScreen = (element: HTMLElement) => {
  const viewportHeight = window.innerHeight
  const distance = window.scrollY + viewportHeight - element.offsetTop
  const percentage = Math.round(
    distance / ((viewportHeight + element.offsetHeight) / 100)
  )

  return Math.min(100, Math.max(0, percentage))
}

const scaleValueToRange = (
  minValue: number,
  maxValue: number,
  value: number,
  rangeMin: number,
  rangeMax: number
) => ((value - minValue) / (maxValue - minValue)) * (rangeMax - rangeMin) + rangeMin

const SitecoreImageParallax: FC<SitecoreImageParallaxProps> = ({
  offset = 200,
  sx,
  ...sitecoreImageProps
}) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const intersecting = useIntersectionObserver<HTMLDivElement>(elementRef)

  const animate = useCallback(() => {
    if (!elementRef.current || !imageRef.current) return

    const newPosition = scaleValueToRange(
      0,
      100,
      percentageInScreen(elementRef.current),
      -Math.abs(offset),
      0
    )

    imageRef.current.style.top = `${newPosition}px`
  }, [offset])

  useEffect(() => {
    animate()

    if (!intersecting) return undefined

    runOnWindow((w) => w.addEventListener('scroll', animate))

    return () => {
      runOnWindow((w) => {
        w.removeEventListener('scroll', animate)
      })
    }
  }, [intersecting, animate])

  return (
    <Box
      ref={elementRef}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        display: 'block',
      }}
    >
      <SitecoreImage
        sx={{
          opacity: 0,
          ...sx,
        }}
        {...sitecoreImageProps}
      />

      <SitecoreImage
        ref={imageRef}
        sx={{
          position: 'absolute',
          top: 0,
          height: `calc(100% + ${offset}px) !important`,
          width: 'auto !important',
          maxWidth: 'unset',
          left: '50%',
          transform: 'translateX(-50%)',
          ...sx,
        }}
        {...sitecoreImageProps}
      />
    </Box>
  )
}

export default SitecoreImageParallax
