import { AxiosResponse } from 'axios'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceResponse } from '../../../../types/commerceApi'

export type OrderCustomerOrderReference = string

export interface OrderCustomerOrderReferenceRequestProps {
  orderNumber?: string
  customerOrderReference?: string
}

export const postOrderCustomerOrderReference: RequestFunction<
  OrderCustomerOrderReferenceRequestProps,
  CommerceResponse<OrderCustomerOrderReference>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<OrderCustomerOrderReference>>> =>
  client.post(
    `/order-customer-order-reference/${props.orderNumber || ''}`,
    {
      itemReference: props.customerOrderReference || '',
    },
    {
      ...createRequestConfig(
        context.user,
        (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
        {
          customerCompanyId: context.customerCompanyId,
          supplierCompanyId: context.supplierCompanyId,
          isImpersonated: !!context.isImpersonated,
        }
      ),
      cancelToken: config.cancelToken,
    }
  )
