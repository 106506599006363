import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box, Flex } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { getMultiListFieldItems } from '../../../helpers/layoutServiceHelper'
import {
  Datasource,
  ImageWithFocalPointField,
  LinkField,
  MultilistField,
  TextField,
} from '../../../types/layoutService'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'
import EditFrame from '../../molecules/ExperienceEditor/ExperienceEditorEditFrame'
import Grid from '../../molecules/Grid/Grid'
import CallToActionWithImageBase from '../CallToActionWithImageBase/CallToActionWithImageBase'

const EDIT_FRAME_FIELD_NAMES = ['Button', 'Image', 'Title', 'Text', 'Wrap Link']

export interface CallToActionWithImageProps {
  title: TextField
  text?: TextField
  button?: LinkField
  image?: ImageWithFocalPointField
}

export interface CallToActionWithImageEditFrameProps
  extends CallToActionWithImageProps {
  id: string
  version: number
  name: string
}

interface CallToActionGridWithImageDatasource {
  image: ImageWithFocalPointField
  callToActionItems: MultilistField<CallToActionWithImageEditFrameProps>
}

const CallToActionGridWithImage: FC<
  Datasource<CallToActionGridWithImageDatasource>
> = ({ datasource: { image, callToActionItems: rawCallToActionItems = {} } }) => {
  const { space } = useTheme()
  const callToActionItems = getMultiListFieldItems(rawCallToActionItems)

  return (
    <Flex
      sx={{
        flexDirection: ['column-reverse', 'column-reverse', 'row'],
      }}
      mb={[3, 3, 7]}
    >
      <Flex
        sx={{
          flex: 1,
        }}
        pr={[0, 0, space[4] / 2]}
      >
        <Grid
          sx={{
            flex: 1,
          }}
          columns={2}
        >
          {callToActionItems.map((item, i) => (
            <EditFrame
              key={item.id}
              itemId={item.id}
              itemVersion={item.version}
              title={item.name}
              fieldNames={EDIT_FRAME_FIELD_NAMES}
            >
              <CallToActionWithImageBase key={i.toString()} {...item} />
            </EditFrame>
          ))}
        </Grid>
      </Flex>

      <Flex
        sx={{
          flex: 1,
        }}
        pl={[0, 0, space[4] / 2]}
        pb={[3, 3, 0]}
      >
        <Box
          as="figure"
          sx={{
            width: '100%',
          }}
        >
          <SitecoreImage imageField={image} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default withData(CallToActionGridWithImage)
