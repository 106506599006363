import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  ImageWithFocalPointField,
  TextField,
} from '../../../types/layoutService'
import Container from '../../atoms/Container/Container'
import { COLORS } from '../../../constants/themeConstants'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { alphaColor } from '../../../helpers/themeHelper'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'

export interface LogoItem {
  image?: ImageWithFocalPointField
}

export interface HightlightedContentBlockDatasource {
  title: TextField
  subtitle?: TextField
  image?: ImageWithFocalPointField
  title1?: TextField
  text1?: TextField
  title2?: TextField
  text2?: TextField
  title3?: TextField
  text3?: TextField
}

const HightlightedContentBlock = ({
  datasource: {
    title,
    subtitle,
    image,
    title1,
    text1,
    title2,
    text2,
    title3,
    text3,
  },
}: Datasource<HightlightedContentBlockDatasource>) => {
  const { space } = useTheme()
  const hightlightedContainer = (
    highligtedTitle: TextField,
    highligtedText: TextField
  ) => (
    <Flex
      sx={{
        gap: 3,
        background: alphaColor(COLORS.WHITE, 0.1),
        flexDirection: 'column',
        padding: [4, 4, 5],
      }}
    >
      <Flex
        sx={{
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          background: COLORS.DAF_BLUE,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconWrapper icon={ICONS.CHECK} color={COLORS.WHITE} />
      </Flex>
      <Box>
        <Text
          as="h3"
          color={COLORS.WHITE}
          sx={{
            fontSize: [2, 2, 4],
            fontWeight: ['bold', 'bold', 'normal'],
          }}
        >
          {highligtedTitle?.value}
        </Text>
        <Text
          as="p"
          sx={{ marginBlockStart: 16, fontSize: 2, fontWeight: 'normal' }}
          color={COLORS.WHITE}
        >
          {highligtedText?.value}
        </Text>
      </Box>
    </Flex>
  )
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        backgroundColor: COLORS.DARK,
      }}
    >
      <Box
        sx={{
          gridArea: '1 / 1',
        }}
      >
        <SitecoreImage
          imageField={image}
          sx={{
            height: 'auto',
            maskImage:
              'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
          }}
        />
      </Box>
      <Box
        sx={{
          gridArea: '1 / 1',
          zIndex: 1,
          display: 'flex',
        }}
      >
        <Container>
          <Flex
            sx={{
              flexDirection: 'column',
              height: '100%',
              paddingBlock: space[8],
              gap: space[6],
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: [null, null, null, '2fr 3fr'],
              }}
            >
              <Flex sx={{ gap: 3, flexDirection: 'column' }}>
                <Text
                  as="p"
                  color={COLORS.WHITE}
                  sx={{
                    fontSize: 2,
                  }}
                >
                  {subtitle?.value}
                </Text>
                <Text
                  as="h2"
                  color={COLORS.WHITE}
                  sx={{
                    fontSize: [4, 4, 5],
                    fontWeight: 'normal',
                  }}
                >
                  {title?.value}
                </Text>
              </Flex>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gap: [2, 2, 4],
                gridTemplateColumns: [null, null, null, '1fr 1fr 1fr'],
                marginBlockStart: 'auto',
              }}
            >
              {title1 && text1 && <Box>{hightlightedContainer(title1, text1)}</Box>}
              {title2 && text2 && <Box>{hightlightedContainer(title2, text2)}</Box>}
              {title3 && text3 && <Box>{hightlightedContainer(title3, text3)}</Box>}
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default withData(HightlightedContentBlock)
