import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface SendMarkMaintenanceAsPerformedRequest {}

export interface SendMarkMaintenanceAsPerformedPayloadProps {
  vin: string
  serviceJobs: Record<string, unknown>[]
  eventType: string
}

const sendMarkMaintenanceAsPerformedPayload = (
  queryParams: DafDefaultQueryParams,
  payload: SendMarkMaintenanceAsPerformedPayloadProps,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<SendMarkMaintenanceAsPerformedRequest>>> =>
  client.post('/vehicles/service-jobs', payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default sendMarkMaintenanceAsPerformedPayload
