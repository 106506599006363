import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { TextField } from '../../../types/layoutService'
import Container from '../../atoms/Container/Container'

interface FooterLegalLinksProps {
  brandTitle: TextField
}

const FooterLegalLinks: FC<FooterLegalLinksProps> = ({ brandTitle }) => (
  <Container
    sx={{
      display: 'flex',
      py: 5,
      flexDirection: 'column',
    }}
  >
    <Text
      as="blockquote"
      variant="heading3"
      sx={{
        fontWeight: 'normal',
        color: 'textLight',
      }}
    >
      {getTextFieldValue(brandTitle)}
    </Text>
  </Container>
)

export default FooterLegalLinks
