import React, { FC, useContext } from 'react'
import { LinkField } from '../../../../../types/layoutService'
import SearchResultsPage from '../../SearchResultsPage'
import { PartFulFilled } from '../../../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import CardGrid from '../../../../molecules/Card/CardGrid'
import GridCardContainer from '../../../../molecules/GridCardContainer/GridCardContainer'
import PartCard from '../../../../molecules/Card/PartCard/PartCard'
import { adaptPartFullFilledToPartCardProps } from '../../../../molecules/Card/PartCard/partCardAdapters'
import { measurePartClick } from '../../../../../helpers/analyticsHelper'
import { CategoriesContext } from '../../../../../providers/CategoriesProvider'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'

interface PartSearchGridViewProps {
  parts?: PartFulFilled[]
  partPage: LinkField
}

const PartSearchGridView: FC<PartSearchGridViewProps> = ({ parts, partPage }) => {
  const { categories } = useContext(CategoriesContext)

  if (!parts?.length) return null

  return (
    <CardGrid>
      <SearchResultsPage parts={parts}>
        {(part: PartFulFilled, index: number) => (
          <GridCardContainer>
            <PartCard
              partPage={partPage}
              dataLayerComponentOrigin={
                DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_GRID
              }
              onClick={() => {
                measurePartClick(
                  part.part?.partNumber,
                  part.part?.englishDescription,
                  part.part?.brand?.description,
                  part.part?.category?.mainCategoryId,
                  part.part?.category?.subCategoryId,
                  index,
                  categories,
                  DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_GRID
                )
              }}
              {...adaptPartFullFilledToPartCardProps(part)}
            />
          </GridCardContainer>
        )}
      </SearchResultsPage>
    </CardGrid>
  )
}

export default PartSearchGridView
