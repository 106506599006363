import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { getTotalSearchResultsString } from '../../../helpers/searchResultsHelper'
import { alphaColor } from '../../../helpers/themeHelper'
import Tab, { TabProps } from '../../molecules/Tab/Tab'

interface SearchResultsTabProps extends TabProps {
  count?: number
}

const SearchResultsTab: FC<SearchResultsTabProps> = ({
  count,
  children,
  active,
  ...tabProps
}) => {
  const { mainNavigation } = useTheme()
  if (!count || count === 0) return null

  return (
    <Tab
      sx={{
        color: active ? 'text' : mainNavigation.textActiveColor,
        backgroundColor: active
          ? 'background'
          : alphaColor(mainNavigation.textColor, 0.08),
        ':hover': {
          backgroundColor: active
            ? 'background'
            : alphaColor(mainNavigation.textColor, 0.14),
        },
      }}
      active={active}
      {...tabProps}
    >
      {`${children} (${getTotalSearchResultsString(count)})`}
    </Tab>
  )
}

export default SearchResultsTab
