import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTheme } from '@emotion/react'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import { resolveUrl } from '../../../helpers/linkFieldHelper'
import { URL_QUERY_STRING_PARAM } from '../../../constants/urlConstants'
import AutoSuggestions from './AutoSuggestions/AutoSuggestions'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import { navigateClient, runOnWindow } from '../../../helpers/dom'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import SearchBarForm from './SearchBarForm'
import SearchBarInput from './SearchBarInput'
import SearchBarUploadButton from './SearchBarUploadButton'
import Animate from '../../atoms/Animate/Animate'
import SearchBarControls from './SearchBarControls'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import {
  WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY,
  WILDCARD_PAGE_CONTEXT_TYPE_PART,
} from '../../../providers/WildcardPageContextProvider'
import { AssemblyContext, PartContext } from '../../../types/sitecoreContextProps'

interface SearchBarDatasource {
  buttonText: TextField
  inputPlaceholder: TextField
  scanUploadPage: LinkField
}

interface SearchBarProps {
  partsPageUri: LinkField
  searchResultsPage: LinkField
}

const SearchBar: FC<Datasource<SearchBarDatasource> & SearchBarProps> = ({
  datasource,
  partsPageUri,
  searchResultsPage,
}) => {
  const { inputPlaceholder, scanUploadPage } = datasource
  const { mainNavigation } = useTheme()
  const { category: partContextCategory, subcategory: partContextSubcategory } =
    useWildcardPageContext<PartContext>(WILDCARD_PAGE_CONTEXT_TYPE_PART)
  const {
    category: assemblyContextCategory,
    subcategory: assemblyContextSubcategory,
  } = useWildcardPageContext<AssemblyContext>(WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY)
  const category = partContextCategory || assemblyContextCategory || undefined
  const subcategory =
    partContextSubcategory || assemblyContextSubcategory || undefined
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchString, setSearchString] = useState('')
  const trimmedSearchString = useMemo(() => searchString.trim(), [searchString])
  const [isSearchInputFocused, setSearchInputFocused] = useState(false)

  const submit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()

      const baseUrl = new URL(window.location.href)
      const searchUrl = new URL(resolveUrl(searchResultsPage) || '/', baseUrl)

      searchUrl.searchParams.set(URL_QUERY_STRING_PARAM, trimmedSearchString)

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_FUNCTION,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.SEARCH_BAR,
        search_query: trimmedSearchString,
        page_type: '',
      })

      navigateClient(searchUrl.toString())
    },
    [searchResultsPage, trimmedSearchString]
  )

  const focusOnSearchInput = useCallback(() => {
    setSearchInputFocused(true)
  }, [])

  useEffect(() => {
    const locationHref = runOnWindow((w) => w.location.href)

    if (locationHref?.includes(searchResultsPage?.url) || subcategory || category) {
      const searchValue = getUrlQueryValue(URL_QUERY_STRING_PARAM)

      if (searchValue) {
        setSearchString(searchValue)
      }
    }
  }, [category, searchResultsPage, subcategory])

  return (
    <>
      {searchResultsPage !== null && (
        <SearchBarForm
          action={resolveUrl(searchResultsPage)}
          onSubmit={submit}
          sx={{ zIndex: 5 }}
        >
          <SearchBarInput
            ref={inputRef}
            data-t-id="part-search-input"
            name={URL_QUERY_STRING_PARAM}
            value={searchString}
            placeholder={getTextFieldValue(inputPlaceholder)}
            autoComplete="off"
            onChange={(event) => setSearchString(event?.target.value)}
            onFocus={focusOnSearchInput}
            minLength={3}
            hasUploadButton
            sx={{
              backgroundColor: mainNavigation.searchBarBackground,
            }}
          />

          <AutoSuggestions
            isSearchInputFocused={isSearchInputFocused}
            searchString={trimmedSearchString}
            searchResultsPage={searchResultsPage}
            partsPageUri={partsPageUri}
          />

          <SearchBarControls
            currentInput={searchString}
            inputRef={inputRef}
            onReset={() => setSearchString('')}
            submitButtonTestId="part-search-submit"
          >
            {!searchString && <SearchBarUploadButton linkField={scanUploadPage} />}
          </SearchBarControls>
        </SearchBarForm>
      )}
      <Animate
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        active={isSearchInputFocused}
      >
        <ClickableWrapper
          onClick={() => setSearchInputFocused(false)}
          sx={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'overlay',
            position: 'fixed',
            top: 0,
            left: 0,
            outline: 'none',
            zIndex: 2,
            cursor: 'default',
          }}
        />
      </Animate>
    </>
  )
}

const SearchBarPageEditingComponent: FC<Datasource<SearchBarDatasource>> = ({
  datasource,
}) => {
  const { inputPlaceholder, scanUploadPage } = datasource
  const { mainNavigation } = useTheme()

  return (
    <SearchBarForm sx={{ zIndex: 5 }}>
      <SearchBarInput
        data-t-id="part-search-input"
        name={URL_QUERY_STRING_PARAM}
        placeholder={getTextFieldValue(inputPlaceholder)}
        autoComplete="off"
        minLength={3}
        hasUploadButton
        sx={{
          backgroundColor: mainNavigation.searchBarBackground,
        }}
      />
      <SearchBarControls>
        <SearchBarUploadButton linkField={scanUploadPage} />
      </SearchBarControls>
    </SearchBarForm>
  )
}

export default withData(SearchBar, {
  pageEditingComponent: SearchBarPageEditingComponent,
  showPageEditingComponentWhenPreviewing: true,
})
