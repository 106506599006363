import React, { FC, useEffect, useMemo, useRef } from 'react'
import { Box, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { useDispatch } from 'react-redux'
import withData from '../../../enhancers/withData'
import { Datasource } from '../../../types/layoutService'
import DigitalMagazineSlide from '../../molecules/DigitalMagazine/DigitalMagazineSlide'
import { kebabCase } from '../../../helpers/kebabCaseHelper'
import Button from '../../atoms/Button/Button'
import DigitalMagazineLinkItems from '../../molecules/DigitalMagazine/DigitalMagazineLinkItems'
import Link from '../../atoms/Link/Link'
import { pushToDigitalMagazineMenu } from '../../../actions/digitalMagazine/digitalMagazineActions'
import BannerLogo from '../../atoms/Banner/BannerLogo'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import renderHTML from '../../../helpers/renderHTMLHelper'
import { getEditableTextFieldValue } from '../../../helpers/layoutServiceHelper'

// eslint-disable-next-line
const MagazineProductHighlightDual: FC<Datasource> = ({ datasource }: any) => {
  const dispatch = useDispatch()
  const { space } = useTheme()
  const controls = useAnimation()
  const linkControls = useAnimation()
  const ref = useRef(null)
  const linkRef = useRef(null)
  const isInView = useInView(ref, { margin: '0px 0px -50% 0px', once: true })
  const linksInView = useInView(linkRef, { margin: '10% 0px 0px 0px', once: true })
  const productLinks = [
    {
      ...datasource.product1Link,
      ...datasource.product1Image,
    },
    {
      ...datasource.product2Link,
      ...datasource.product2Image,
    },
  ]

  const titleVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: 'easeOut' },
    },
    hidden: { opacity: 0, y: 50 },
  }

  const logoVariants = {
    visible: {
      opacity: 1,
      transition: { delay: 1, duration: 0.7, ease: 'easeOut' },
    },
    hidden: { opacity: 0 },
  }

  const linkVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: 'easeOut' },
    },
    hidden: { marginTop: 'auto', opacity: 0, y: 50 },
  }

  useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [controls, isInView])

  useEffect(() => {
    if (linksInView) {
      linkControls.start('visible')
    }
  }, [linkControls, linksInView])

  const slideMenuData = useMemo(
    () => ({
      menuTitle: datasource.menutitle.value,
      menuId: kebabCase(datasource.menutitle.value),
    }),
    [datasource.menutitle.value]
  )

  useEffect(() => {
    dispatch(pushToDigitalMagazineMenu(slideMenuData))
  }, [dispatch, slideMenuData])

  return (
    <DigitalMagazineSlide
      image={datasource.backgroundImage}
      video={datasource.backgroundVideo}
      id={kebabCase(datasource.menutitle.value)}
      ref={ref}
    >
      <Box
        sx={{
          position: 'relative',
          paddingY: [
            `${space[7]}px`,
            `${space[7]}px`,
            `${space[8]}px`,
            `${space[8]}px`,
            `${space[8]}px`,
          ],
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: ['90%', '90%', '90%', '60%', '60%'],
          }}
        >
          {datasource.title.value && (
            <motion.div initial="hidden" variants={titleVariants} animate={controls}>
              <Text
                sx={{
                  fontSize: [4],
                  fontWeight: 'bold',
                  color: 'white',
                  textTransform: 'uppercase',
                }}
              >
                {datasource.subject.value}
              </Text>

              <Text
                as="h2"
                sx={{
                  marginTop: `${space[3]}px`,
                  fontSize: [5, 5, 6, 6, 7],
                  fontWeight: 'light',
                  color: 'white',
                }}
              >
                {datasource.title.value}
              </Text>

              <Text
                sx={{
                  lineHeight: 'heading2',
                  fontWeight: 'normal',
                }}
                color="white"
                mt={3}
              >
                {renderHTML(getEditableTextFieldValue(datasource.text))}
              </Text>

              {datasource.primaryButton && (
                <Button
                  variant="outline"
                  sx={{
                    marginTop: 3,
                  }}
                >
                  <Link
                    href={datasource.primaryButton.url}
                    onClick={() =>
                      pushToDataLayer({
                        [DATA_LAYER.EVENT_KEYS.EVENT]:
                          DATA_LAYER.EVENT.CALL_TO_ACTION,
                        call_to_action_name: datasource.primaryButton?.text,
                        call_to_action_type:
                          DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
                        destination_url: datasource.primaryButton?.url,
                      })
                    }
                  >
                    {datasource.primaryButton.text}
                  </Link>
                </Button>
              )}
            </motion.div>
          )}
        </Box>

        {datasource.logo && (
          <motion.div initial="hidden" variants={logoVariants} animate={controls}>
            <BannerLogo
              sx={{
                top: 0,
                zIndex: 0,
                display: ['none', 'none', 'none', 'block', 'block'],
                marginTop: [`${space[8]}px`, `${space[9]}px`, `${space[9]}px`],
              }}
              logoImageField={datasource.logo?.targetItem?.image}
              logoPosition="topRight"
            />
          </motion.div>
        )}

        <motion.div
          ref={linkRef}
          initial="hidden"
          variants={linkVariants}
          animate={linkControls}
        >
          <DigitalMagazineLinkItems items={productLinks} />
        </motion.div>
      </Box>
    </DigitalMagazineSlide>
  )
}

export default withData(MagazineProductHighlightDual)
