import React, { FC, ReactNode } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Flex, FlexProps } from 'theme-ui'
import PartsNavigationPanelBody from './PartsNavigationPanelBody'

interface PartsNavigationPanelProps
  extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {
  children?: ReactNode
}

const PartsNavigationPanel: FC<PartsNavigationPanelProps> = ({
  children,
  sx,
  ...manipulatedProps
}) => {
  const { mainNavigation } = useTheme()

  return (
    <Box
      as="aside"
      sx={{
        position: ['absolute', 'absolute', 'absolute', 'relative'],
        backgroundColor: mainNavigation.background,
        overflowX: 'hidden',
        height: ['calc(100% - 56px)', null, null, '100%'],
        '::before': {
          content: '""',
          display: ['none', 'none', 'none', 'block'],
          width: 1,
          height: 'calc(100% - 1px)',
          position: 'absolute',
          top: '1px',
          left: 0,
          bottom: 0,
          backgroundColor: mainNavigation.dividerColor,
          opacity: mainNavigation.dividerOpacity,
        },
        ...sx,
      }}
      {...manipulatedProps}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          width: ['100vw', '100vw', '100vw', '360px'],
        }}
      >
        <PartsNavigationPanelBody>{children}</PartsNavigationPanelBody>
      </Flex>
    </Box>
  )
}

export default PartsNavigationPanel
