import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { useTheme } from '@emotion/react'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import Tile from '../../atoms/Tile/Tile'
import { SelectField, TextField } from '../../atoms/FormFields'
import FormInputLabel from '../../atoms/FormControls/FormInputLabel'
import { COLORS } from '../../../constants/themeConstants'
import Button from '../../atoms/Button/Button'
import Spinner from '../../atoms/Spinner/Spinner'
import DefinitionList from '../../atoms/DefinitionList/DefinitionList'
import { MyAccountPageContext } from '../../../providers/MyAccountPageProvider'
import { UserContext } from '../../../providers/UserProvider'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'

import { DafDefaultQueryParams } from '../../../types/userProps'
import userLanguage, {
  UserLanguageProps,
} from '../../../services/rest/user/language'

interface FormValues {
  firstAndLastName: string
  email: string
  language: string
}

interface LanguageItem {
  name: string
  url: string
  isActive?: boolean
  displayName: string
  regionName: string
  cultureCode: string
}

interface UserSettingsOverviewProps {
  languageItems: LanguageItem[]
  labels: {
    firstAndLastNameLabel: string
    emailLabel: string
    passwordLabel: string
    passwordText: string
    languageLabel: string
  }
}

const UserSettingsOverview = ({
  languageItems,
  labels,
}: UserSettingsOverviewProps) => {
  const { accountUserDetailsData, isLoading } = useContext(MyAccountPageContext)
  const { space } = useTheme()
  const [editUserProfile, setEditUserProfile] = useState(false)
  const { user } = useContext(UserContext)
  const activeLanguageItem = languageItems.find(
    ({ cultureCode }) => accountUserDetailsData?.user?.language === cultureCode
  )
  const commerceClient = useAxiosClient(AxiosClientType.CommerceApi)
  const { token } = axios.CancelToken.source()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const replaceLanguageInUrl = (newLang: string) => {
    const url = new URL(window.location.href)
    const pathSegments = url.pathname.split('/')

    // Assuming the language code is always the second segment
    if (pathSegments.length > 1) {
      pathSegments[1] = newLang
    }

    url.pathname = pathSegments.join('/')
    return url.toString()
  }

  const definitions = [
    {
      term: labels.firstAndLastNameLabel,
      detail: `${accountUserDetailsData?.user?.firstName} ${accountUserDetailsData?.user?.lastName}`,
    },
    { term: labels.emailLabel, detail: accountUserDetailsData?.user?.email },
    { term: labels.passwordLabel, detail: '***********' },
    {
      term: labels.languageLabel,
      detail: activeLanguageItem?.displayName,
    },
  ]

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }
  const changeUserLanguage = async (payload: UserLanguageProps) => {
    const response = await userLanguage(
      payload,
      commerceClient,
      dafVehicleDetailQueryParams,
      token,
      user
    )

    return response.data
  }

  const { isPending: isMutating, mutate } = useMutation({
    mutationFn: (payload: UserLanguageProps) => changeUserLanguage(payload),

    onSuccess: (responseData) => {
      if (!responseData?.messages?.length) {
        window.location.href = replaceLanguageInUrl(
          responseData?.result?.cultureCode
        )
      }
    },
  })

  const onSubmit = ({ language }: FormValues) => {
    mutate({ cultureCode: language })
    setEditUserProfile(false)
  }
  return (
    <Box sx={{ marginBlockStart: space[4], position: 'relative' }}>
      <Tile>
        {editUserProfile ? (
          <Form
            onSubmit={onSubmit}
            initialValues={{
              firstAndLastName: user?.profile?.fullName,
              email: user?.profile?.email,
              language: activeLanguageItem?.cultureCode,
            }}
            render={({ handleSubmit }) => (
              <>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '2fr 1fr'],
                    gap: 3,
                    alignItems: 'center',
                  }}
                >
                  <form onSubmit={handleSubmit} id="user-profile-settings">
                    <TextField
                      name="firstAndLastName"
                      disabled
                      label={labels.firstAndLastNameLabel}
                    />

                    <TextField name="email" disabled label={labels.emailLabel} />

                    <Flex sx={{ flexDirection: 'column', marginBlockEnd: space[4] }}>
                      <FormInputLabel>{labels.passwordLabel}</FormInputLabel>
                      <Text
                        color={COLORS.DARK}
                        sx={{
                          fontSize: 1,
                        }}
                      >
                        {labels.passwordText}
                      </Text>
                    </Flex>

                    <SelectField
                      name="language"
                      label={labels.languageLabel}
                      options={languageItems?.map(
                        ({ cultureCode, displayName }) => ({
                          value: cultureCode,
                          text: displayName,
                        })
                      )}
                    />
                    <Flex sx={{ gap: 2, marginTop: space[3] }}>
                      <Button
                        variant="outline"
                        type="button"
                        onClick={() => setEditUserProfile(false)}
                      >
                        {i18next.t('commonFormLabels.cancel')}
                      </Button>
                      <Button variant="primary" type="submit">
                        {i18next.t('commonLabels.save')}
                      </Button>
                    </Flex>
                  </form>
                </Box>
              </>
            )}
          />
        ) : (
          <>
            {isMutating || (isLoading && <Spinner />)}
            {!isMutating && accountUserDetailsData && (
              <DefinitionList
                definitions={definitions}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 2fr'],
                  gap: 3,
                  alignItems: 'center',
                }}
              />
            )}
            <Box sx={{ position: 'absolute', right: space[3], top: space[3] }}>
              <Button
                variant="infinite"
                color={COLORS.DAF_BLUE}
                onClick={() => setEditUserProfile(true)}
              >
                {i18next.t('commonFormLabels.edit')}
              </Button>
            </Box>
          </>
        )}
      </Tile>
    </Box>
  )
}

export default UserSettingsOverview
