import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../../types/userProps'
import { createRequestConfig } from '../../../../helpers/axiosHelper'

interface CreditValue {
  value: number
  currencyCode: string
}

export interface Voucher {
  type: string
  creditValue: CreditValue
  productName: string
  productDetailUrl: string
  productImageUrl: string
  costPriceInPoints: number
  issuedDate: string
  expiresDate: string
  uniqueCode: string
  status: string
}

interface VouchersStatisticsProps {
  totalCount: number
}

interface VouchersProps {
  statistics: VouchersStatisticsProps
  vouchers: Voucher[]
}

interface Message {
  message: string
  severity: string
  code: string
  args: string[]
  argsObj: Record<string, any>
}

interface Paging {
  pageNumber: number
  partsPerPage: number
}

interface Sorting {
  sortField: string
  sortOrder: string
}

interface RequestBody {
  cultureCode: string
  paging: Paging
  sorting?: Sorting
}

export interface VouchersData {
  result: VouchersProps
  messages: Message[]
}

const getVouchers = (
  requestBody: RequestBody,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<VouchersData>> =>
  client.post('customer/loyalty/vouchers', requestBody, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default getVouchers
