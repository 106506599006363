import i18next from 'i18next'
import React, { ReactNode, useContext, useMemo } from 'react'
import { Flex } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { AssemblySearchContext } from '../../../providers/AssemblySearchContextProvider'
import { DealerPartSearchContext } from '../../../providers/DealerPartSearchContextProvider'
import { PartSearchContext } from '../../../providers/PartSearchContextProvider'
import { RenderingProps, TextField } from '../../../types/layoutService'
import SpinnerWithLabel from '../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import SearchResultsTitleBar from './SearchResultsTitleBar'
import SearchResultsNoResults from './SearchResultsNoResults'
import SearchResultsBackToTopWrapper from './BackToTopButton/SearchResultsBackToTopWrapper'

interface SearchResultsLoadedResultsProps {
  noSearchResultsDescription?: TextField
  loadingLabel: TextField
  rendering: RenderingProps
  children: ReactNode
}

const SearchResultsLoadedResults = ({
  noSearchResultsDescription,
  loadingLabel,
  rendering,
  children,
}: SearchResultsLoadedResultsProps) => {
  const { fetching: fetchingParts, statistics: partStatistics } =
    useContext(PartSearchContext)
  const { fetching: fetchingAssemblies, totalCount: assemblyTotalCount } =
    useContext(AssemblySearchContext)
  const { fetching: fetchingDealerParts, totalCount: dealerPartTotalCount } =
    useContext(DealerPartSearchContext)

  const fetching = useMemo(
    () => fetchingAssemblies && fetchingParts && fetchingDealerParts,
    [fetchingAssemblies, fetchingDealerParts, fetchingParts]
  )

  const hasNoResults =
    !fetching &&
    (assemblyTotalCount === 0 || assemblyTotalCount === undefined) &&
    partStatistics?.totalCount === 0 &&
    !dealerPartTotalCount

  if (fetching) {
    return (
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          paddingY: 5,
        }}
      >
        <SpinnerWithLabel
          label={getTextFieldValue(loadingLabel)}
          sx={{
            width: 'max-content',
            position: 'sticky',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      </Flex>
    )
  }

  return (
    <>
      <SearchResultsTitleBar
        noSearchResultsDescription={noSearchResultsDescription}
      />

      {hasNoResults ? (
        <SearchResultsNoResults rendering={rendering} />
      ) : (
        <SearchResultsBackToTopWrapper
          backToTopLabel={i18next.t('paginationLabels.backToTop')}
        >
          {children}
        </SearchResultsBackToTopWrapper>
      )}
    </>
  )
}

export default SearchResultsLoadedResults
