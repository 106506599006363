import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  DealerFilter,
  StaticDealerFilter,
  TextField,
} from '../../../types/layoutService'
import SearchControlsContainer from '../SearchResults/SearchControls/SearchControlsContainer'
import DealerLocatorFilterChipList from './DealerLocatorFilterChipList'
import SearchControlsStatistics from '../SearchResults/SearchControls/SearchControlsStatistics'
import SearchControlsStatisticsContainer from '../SearchResults/SearchControls/SearchControlsStatisticsContainer'

interface DealerLocatorControlsProps {
  filters: DealerFilter[]
  staticFilters?: StaticDealerFilter[]
  filtersLabel: TextField
  resultsCount: number
}

const DealerLocatorControls: FC<DealerLocatorControlsProps> = ({
  filters,
  staticFilters,
  filtersLabel,
  resultsCount,
}) => (
  <>
    <Text as="h4" variant="heading4" mb={1}>
      {getTextFieldValue(filtersLabel)}
    </Text>

    <SearchControlsContainer>
      <DealerLocatorFilterChipList filters={filters} staticFilters={staticFilters} />

      <SearchControlsStatisticsContainer>
        <SearchControlsStatistics
          count={resultsCount}
          data-t-id="dealer-locator-statistics"
        />
      </SearchControlsStatisticsContainer>
    </SearchControlsContainer>
  </>
)

export default DealerLocatorControls
