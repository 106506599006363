import { isValidElement, ReactNode } from 'react'
import FormInputSelect from '../components/atoms/FormControls/FormInputSelect'
import FormErrorText from '../components/atoms/FormControls/FormFieldText'
import FormInputLabel from '../components/atoms/FormControls/FormInputLabel'
import FormInputText from '../components/atoms/FormControls/FormInputText'
import FormInputCheckbox from '../components/atoms/FormControls/FormInputCheckbox'
import FormInputTextarea from '../components/atoms/FormControls/FormInputTextarea'
import FormInputTextAsync from '../components/atoms/FormControls/FormInputTextAsync'
import FormInputTextareaAsync from '../components/atoms/FormControls/FormInputTextareaAsync'
import FormInputTextList from '../components/atoms/FormControls/FormInputTextList/FormInputTextList'

export const isErrorText = (child: ReactNode) =>
  isValidElement(child) && child.type === FormErrorText

export const isInput = (child: ReactNode) =>
  isValidElement(child) &&
  (child.type === FormInputText ||
    child.type === FormInputTextAsync ||
    child.type === FormInputTextarea ||
    child.type === FormInputTextareaAsync ||
    child.type === FormInputSelect ||
    child.type === FormInputCheckbox ||
    child.type === FormInputTextList)

export const isLabel = (child: ReactNode) =>
  isValidElement(child) && child.type === FormInputLabel
