import { TextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text'
import React, { FC, useCallback, useMemo } from 'react'
import { Text } from 'theme-ui'
import { DEALER_DEPARTMENT_CODE_GENERAL } from '../../../constants/dealerLocatorConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { Dealer } from '../../../types/dealerServiceTypes'
import Link from '../../atoms/Link/Link'

interface DealerLocatorDealerContactProps extends Omit<TextProps, 'css'> {
  dealer: Dealer
  dataLayerArguments: Record<string, unknown>
  sx?: Record<string, unknown>
}

const DealerLocatorDealerContact: FC<DealerLocatorDealerContactProps> = ({
  dealer,
  sx = {},
  dataLayerArguments,
  ...props
}) => {
  const contactDetails = useMemo(
    () =>
      dealer.communications?.find(
        ({ departmentCode }) => departmentCode === DEALER_DEPARTMENT_CODE_GENERAL
      ),
    [dealer.communications]
  )

  const dispatchAnalyticEvent = useCallback(() => {
    pushToDataLayer(dataLayerArguments)
  }, [dataLayerArguments])

  return (
    <Text
      variant="bodySmall"
      sx={{
        display: 'flex',
        lineHeight: 'menuList',
        flexDirection: 'column',
        paddingTop: 0,
        a: {
          color: 'inherit',
          width: 'fit-content',
          ':hover': { color: 'primary' },
        },
        ...sx,
      }}
      {...props}
    >
      {contactDetails?.phone && (
        <Link
          onClick={dispatchAnalyticEvent}
          href={`tel:${contactDetails.phone}`}
          data-t-id="CompanyPhone"
        >
          {contactDetails.phone}
        </Link>
      )}

      {contactDetails?.email && (
        <Link
          onClick={dispatchAnalyticEvent}
          href={`mailto:${contactDetails.email}`}
          data-t-id="CompanyEmail"
        >
          {contactDetails.email}
        </Link>
      )}

      {dealer?.website && (
        <Link
          onClick={dispatchAnalyticEvent}
          href={`https://${dealer.website}`}
          target="_BLANK"
          data-t-id="CompanyWebsite"
        >
          {dealer.website}
        </Link>
      )}
    </Text>
  )
}

export default DealerLocatorDealerContact
