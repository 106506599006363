import { CommerceSimpleImage, CommerceTechnicalDrawing } from '../types/commerceApi'
import { Image } from '../types/dafResponseProps'

export const adaptCommerceSimpleImageToImage = (
  simpleImage?: CommerceSimpleImage
): Image => ({
  imageUrl: simpleImage?.imageUrl ?? '',
  thumbnailImageUrl: simpleImage?.thumbnailImageUrl ?? '',
  priority: 0,
})

export const adaptCommerceTechnicalDrawingToImage = (
  technicalDrawing?: CommerceTechnicalDrawing
): Image => ({
  imageUrl: technicalDrawing?.imageUrl ?? '',
  priority: technicalDrawing?.priority ?? 0,
  thumbnailImageUrl: technicalDrawing?.thumbnailImageUrl ?? '',
})
