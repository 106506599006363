import React, { FC, ReactNode } from 'react'
import { Flex } from 'theme-ui'

export interface ModalContainerProps {
  onSideClick?: () => void
  children: ReactNode
}

const ModalContainer: FC<ModalContainerProps> = ({ children }) => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      paddingTop: [0, 0, 0, 5],
      paddingBottom: [0, 0, 0, 5],
    }}
  >
    {children}
  </Flex>
)

export default ModalContainer
