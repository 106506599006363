import React, { FC, useContext, useMemo } from 'react'
import { Text } from 'theme-ui'
import Spinner from '../../atoms/Spinner/Spinner'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import PartMaxCouponTable from './PartMaxCouponTable'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'
import { TextField } from '../../../types/layoutService'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'

interface PartMaxCouponProps {
  messages: TextField
  title: TextField
  id: string
}

const PartMaxCouponResponsive: FC<PartMaxCouponProps> = ({
  messages,
  title,
  id,
}) => {
  const { priceInformation, fetching } = useContext(PartSalesInformationContext)

  const [breakpointM] = useBreakpoints(BREAKPOINT_M)

  const availableCoupon = useMemo(
    () => priceInformation?.price?.couponInformation?.maxProgram?.availableCoupon,
    [priceInformation]
  )

  const couponAvailable = !!availableCoupon?.length

  const tableMaxCoupon = couponAvailable && (
    <PartMaxCouponTable messages={messages} coupon={availableCoupon} />
  )

  if (fetching) {
    return <Spinner size={3} />
  }

  if (!couponAvailable) return null

  if (!breakpointM) {
    return (
      <PartMobileModalLink title={getTextFieldValue(title)} table={tableMaxCoupon} />
    )
  }

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} mb={7} id={id}>
      {title && (
        <Text as="h2" variant="heading2" mb={4}>
          {getTextFieldValue(title)}
        </Text>
      )}

      {tableMaxCoupon}
    </AnchorMenuTarget>
  )
}

export default PartMaxCouponResponsive
