import useResizeObserver from '@react-hook/resize-observer'
import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import { isBrowser } from '../../../helpers/dom'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useScrollDirection from '../../../hooks/useScrollDirection'
import defaultTheme from '../../../themes/defaultTheme'
import { SEARCH_BAR_HEIGHT } from '../../molecules/SearchBar/SearchBarInput'

const MOBILE_SEARCH_BAR_SCREEN_REAL_ESTATE =
  SEARCH_BAR_HEIGHT + defaultTheme.space[3] * 2

interface HeaderProps {
  children: ReactNode
}

const Header = ({ children }: HeaderProps) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const [breakpointS] = useBreakpoints('s')

  const [headerHeight, setHeaderHeight] = useState(0)
  const [stickinessGap, setStickinessGap] = useState(0)

  const scrollDirection = useScrollDirection()

  const getDefaultStickinessGap = useCallback(() => {
    const mainNavigation = headerRef.current?.lastChild as HTMLDivElement

    if (headerHeight && mainNavigation) {
      return headerHeight - mainNavigation.clientHeight
    }

    return 0
  }, [headerHeight])

  const stickyBehaviorDefault = useCallback(() => {
    if (headerHeight) setStickinessGap(getDefaultStickinessGap())
  }, [getDefaultStickinessGap, headerHeight])

  const stickyBehaviorMobile = useCallback(() => {
    if (headerHeight && scrollDirection) {
      if (scrollDirection === 'DOWN') {
        setStickinessGap(headerHeight - MOBILE_SEARCH_BAR_SCREEN_REAL_ESTATE)
      } else {
        setStickinessGap(getDefaultStickinessGap())
      }
    }
  }, [getDefaultStickinessGap, headerHeight, scrollDirection])

  useResizeObserver(headerRef, (entry) => setHeaderHeight(entry.contentRect.height))

  useLayoutEffect(() => {
    if (!isBrowser()) return

    if (!breakpointS) stickyBehaviorMobile()
    else stickyBehaviorDefault()
  }, [breakpointS, stickyBehaviorDefault, stickyBehaviorMobile])

  return (
    <Box
      ref={headerRef}
      as="header"
      sx={{
        position: 'sticky',
        top: -stickinessGap,
        transition: 'ease top 200ms',
        zIndex: 3,
      }}
    >
      {children}
    </Box>
  )
}

export default Header
