import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import withData from '../../../enhancers/withData'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import {
  Datasource,
  ImageWithFocalPointField,
  TextField,
} from '../../../types/layoutService'
import RichText from '../../atoms/RichText/RichText'
import TestimonialAuthor from './TestimonialAuthor'

interface TestimonialDatasource {
  title: TextField
  text: TextField
  authorName: TextField
  authorImage: ImageWithFocalPointField
  authorJobTitle: TextField
}

const Testimonial: FC<Datasource<TestimonialDatasource>> = ({
  datasource: { title, text, authorName, authorImage, authorJobTitle },
}) => (
  <>
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'column',
        paddingLeft: [0, 0, '51px'],
        marginBottom: [4, 4, 0],
        '::before': {
          content: '"\\201C"',
          fontWeight: '400',
          fontStyle: 'normal',
          fontSize: ['60px', '60px', '80px'],
          lineHeight: '72px',
          color: 'primary',
          position: ['relative', 'relative', 'absolute'],
          marginBottom: ['-26px', '-26px', 0],
          left: 0,
        },
      }}
    >
      <Text
        variant="heading1"
        sx={{
          color: 'gray.3',
          fontSize: ['24px', '24px', '32px'],
        }}
      >
        {getTextFieldValue(title)}
      </Text>

      {getEditableTextFieldValue(text) && (
        <Text variant="bodyContent" sx={{ marginTop: 2, p: { color: 'gray.3' } }}>
          <RichText textField={text} />
        </Text>
      )}

      <TestimonialAuthor
        name={getTextFieldValue(authorName)}
        image={authorImage}
        jobTitle={getTextFieldValue(authorJobTitle)}
      />
    </Flex>
  </>
)

export default withData(Testimonial, { checkDatasource: true })
