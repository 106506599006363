import React, { FC, useContext, useEffect } from 'react'
import { FlexProps } from 'theme-ui'
import PartPrice from './PartPrice'
import { PriceVariant } from '../../../types/salesProps'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'
import { PriceInformation } from '../../../hooks/services/graphql/usePartSalesInformationService'

interface PartPriceAsyncProps extends Omit<FlexProps, 'css'> {
  partNumber?: string
  variant?: PriceVariant
  fetchedPrice?: (price: PriceInformation) => void
}

const PartPriceAsync: FC<PartPriceAsyncProps> = ({
  variant,
  fetchedPrice,
  sx,
  ...flexProps
}) => {
  const { priceInformation, fetching } = useContext(PartSalesInformationContext)

  useEffect(() => {
    if (!fetching && fetchedPrice && priceInformation) {
      fetchedPrice(priceInformation)
    }
  }, [fetching, fetchedPrice, priceInformation])

  return (
    <PartPrice
      price={priceInformation?.price}
      variant={variant}
      sx={sx}
      fetching={fetching}
      {...flexProps}
    />
  )
}

export default PartPriceAsync
