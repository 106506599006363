import React from 'react'
import { getDisplayName } from '../helpers/getDisplayName'
import useHasMounted from '../hooks/useHasMounted'

function withClientSideRender<Props>(
  WrappedComponent: React.ComponentType<Props>
): React.ComponentType<Props> {
  const WithClientSideRender = (props: Props) => {
    const hasMounted = useHasMounted()
    if (!hasMounted) {
      return null
    }

    // @ts-ignore
    return <WrappedComponent {...props} />
  }

  WithClientSideRender.displayName = `withClientSideRender(${getDisplayName(
    WrappedComponent
  )})`

  return WithClientSideRender
}

export default withClientSideRender
