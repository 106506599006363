import React, { FC, FocusEventHandler, MouseEventHandler } from 'react'
import { Field } from 'react-final-form'
import FormControl from '../FormControls/FormControl'
import FormFieldText from '../FormControls/FormFieldText'
import FormInputLabel from '../FormControls/FormInputLabel'
import { BaseFieldProps } from './BaseField'
import FormInputTextarea from '../FormControls/FormInputTextarea'

export interface TextAreaFieldProps extends BaseFieldProps {
  label?: string
  placeholder?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onClick?: MouseEventHandler<HTMLInputElement>
  value?: string
}

export const TextAreaField: FC<TextAreaFieldProps> = ({
  disabled,
  required,
  name,
  label,
  placeholder,
  validate,
  onBlur,
  onFocus,
  onClick,
}) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <FormControl
        id={name}
        error={meta.touched && (meta.error ?? meta.submitError)}
        disabled={disabled ?? meta.submitting}
      >
        <FormInputLabel required={required}>{label}</FormInputLabel>

        <FormInputTextarea
          {...input}
          onBlur={(e) => {
            if (typeof onBlur === 'function') onBlur(e)
            input.onBlur(e)
          }}
          required={required}
          onFocus={(e) => {
            if (typeof onFocus === 'function') onFocus(e)
            input.onFocus(e)
          }}
          onClick={(e) => {
            if (typeof onClick === 'function') onClick(e)
          }}
          placeholder={placeholder}
        />

        <FormFieldText type="error">{meta.error ?? meta.submitError}</FormFieldText>
      </FormControl>
    )}
  </Field>
)
