import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react'
import React, { createElement, FC } from 'react'
import componentFactory from '../../temp/componentFactory'
import { DialogPropsBase, DialogRenderedItem } from '../../types/dialogTypes'

const renderDialogItem = (dialog: DialogRenderedItem) => {
  const { hash, id: key, isEditingOrPreviewing, rendering } = dialog

  return createElement<ComponentRendering & DialogPropsBase>(
    componentFactory(dialog.rendering.componentName),
    {
      ...rendering,
      isEditingOrPreviewing,
      hash,
      key,
    }
  )
}

interface DialogItemRendererProps {
  dialogs: DialogRenderedItem[]
}

const DialogItemRenderer: FC<DialogItemRendererProps> = ({ dialogs }) => {
  if (!dialogs.length) return null

  return <>{dialogs.map((dialog) => renderDialogItem(dialog))}</>
}

export default DialogItemRenderer
