import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  NotificationInformation,
  resetModalNotifications,
} from '../../../../actions/notificationActions'
import { RootState } from '../../../../reducers'
import NotificationLine from '../../../atoms/NotificationLine/NotificationLine'
import Modal from '../Modal'
import ModalBodyContent from '../ModalBodyContent'

interface NotificationModalProps {}

const NotificationModal: FC<NotificationModalProps> = () => {
  const dispatch = useDispatch()
  const { modalNotifications } = useSelector(
    (state: RootState) => state.notifications
  )

  const [isActive, setActive] = useState(false)
  const [notifications, setNotifications] = useState<
    NotificationInformation[] | undefined
  >(undefined)

  const closeNotificationModal = useCallback(() => {
    setActive(false)
    dispatch(resetModalNotifications())
  }, [dispatch])

  useEffect(() => {
    if (modalNotifications?.length) {
      setActive(true)
      setNotifications(modalNotifications)
    }
  }, [modalNotifications])

  return (
    <Modal isActive={isActive} onClose={closeNotificationModal} variant="minimal">
      <ModalBodyContent>
        {!!notifications?.length &&
          notifications.map(({ severity, message }, i) => (
            <NotificationLine
              key={i.toString()}
              severity={severity}
              message={message}
            />
          ))}
      </ModalBodyContent>
    </Modal>
  )
}

export default NotificationModal
