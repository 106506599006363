import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getCompanyById } from '../helpers/userProfileHelper'
import { useDealerService } from '../hooks/services/rest/dealer/useDealerService'
import useLazyCompanyDetailsService, {
  CompanyDetails,
  CompanyDetailsQueryData,
} from '../hooks/services/graphql/useLazyCompanyDetailsService'
import { Dealer } from '../types/dealerServiceTypes'
import { Company, Supplier } from '../types/userProps'
import { ActiveUserStateContext } from './ActiveUserStateProvider'
import { UserContext } from './UserProvider'
import { COMPANY_TYPE_CODE_DEALER } from '../constants/userConstants'

interface ActiveStoreProviderProps {
  children: ReactNode
}

export interface ActiveStoreProviderContextProps {
  activeCompany?: Company
  activeSupplier?: Supplier
  isImpersonated: boolean
  impersonatedCompany?: CompanyDetails
  impersonatedSupplier?: CompanyDetails
  actingCompanyId?: number
  actingSupplierId?: number
  activeSupplierInformation?: Dealer
}

export const ActiveStoreProviderContext =
  createContext<ActiveStoreProviderContextProps>({
    activeCompany: undefined,
    activeSupplier: undefined,
    isImpersonated: false,
    impersonatedCompany: undefined,
    impersonatedSupplier: undefined,
    actingCompanyId: undefined,
    actingSupplierId: undefined,
    activeSupplierInformation: undefined,
  })

const ActiveStoreProvider = ({ children }: ActiveStoreProviderProps) => {
  const { activeUserState } = useContext(ActiveUserStateContext)
  const { userProfile } = useContext(UserContext)

  const [activeCompany, setActiveCompany] = useState<Company | undefined>(undefined)
  const [activeSupplier, setActiveSupplier] = useState<Supplier | undefined>(
    undefined
  )
  const isImpersonated = useMemo<boolean>(
    () => activeUserState?.isImpersonated || false,
    [activeUserState]
  )
  const [impersonatedCompany, setImpersonatedCompany] = useState<
    CompanyDetails | undefined
  >(undefined)
  const [impersonatedSupplier, setImpersonatedSupplier] = useState<
    CompanyDetails | undefined
  >(undefined)
  const [activeSupplierInformation] = useDealerService(
    useMemo(
      () =>
        activeSupplier?.companyTypeCode === COMPANY_TYPE_CODE_DEALER
          ? activeSupplier?.locationCode
          : undefined,
      [activeSupplier]
    )
  )

  const activeCompanyId = useMemo(() => {
    if (isImpersonated) {
      // when impersonating set active company id to users default company
      return userProfile?.defaultCustomerCompanyId
    }

    // use selected company id from cookie - use user default company as fallback
    return (
      activeUserState?.selectedCustomerCompanyId ||
      userProfile?.defaultCustomerCompanyId
    )
  }, [isImpersonated, activeUserState, userProfile])

  const activeSupplierId = useMemo(
    () =>
      getCompanyById(
        activeCompany?.suppliers,
        activeUserState?.selectedSupplierCompanyId ||
          userProfile?.defaultSupplierCompanyId
      )?.companyId,
    [activeCompany, activeUserState, userProfile]
  )

  const actingCompanyId = useMemo(() => {
    if (isImpersonated) {
      // when impersonating set active company id to users default company
      return activeUserState?.selectedCustomerCompanyId
    }
    return activeCompany?.companyId
  }, [isImpersonated, activeCompany, activeUserState])

  const actingSupplierId = useMemo(() => {
    if (isImpersonated) {
      // when impersonating set active company id to users default company
      return activeUserState?.selectedSupplierCompanyId
    }

    return getCompanyById(activeCompany?.suppliers, activeSupplier?.companyId)
      ?.companyId
  }, [activeSupplier, isImpersonated, activeCompany, activeUserState])

  const userCompanies = useMemo(() => userProfile?.companies, [userProfile])

  const onImpersonatedCompanyDetailsData = useCallback(
    (data?: CompanyDetailsQueryData) => {
      if (data?.company) {
        setImpersonatedCompany(data.company)
      }
      if (data?.additionalCompany) {
        setImpersonatedSupplier(data.additionalCompany)
      }
    },
    []
  )

  const [fetchImpersonatedCompanyDetails] = useLazyCompanyDetailsService(
    onImpersonatedCompanyDetailsData
  )

  // when impersonating fetch impersonated company details
  useEffect(() => {
    const abortController = new AbortController()

    if (
      activeUserState?.isImpersonated &&
      activeUserState?.selectedCustomerCompanyId &&
      activeUserState?.selectedSupplierCompanyId
    ) {
      fetchImpersonatedCompanyDetails(
        {
          companyId: activeUserState.selectedCustomerCompanyId,
          additionalCompanyId: activeUserState.selectedSupplierCompanyId,
          isImpersonated: true,
          withAdditionalCompany: true,
        },
        abortController.signal
      )
    }

    return () => {
      abortController.abort()
    }
  }, [activeUserState, fetchImpersonatedCompanyDetails])

  // set active company
  useEffect(() => {
    setActiveSupplier(undefined) // remove supplier as company changed, suppliers are company specific
    setActiveCompany(getCompanyById(userCompanies, activeCompanyId))
  }, [activeCompanyId, userCompanies])

  // set active supplier
  useEffect(() => {
    if (activeCompany) {
      const supplier =
        getCompanyById(activeCompany?.suppliers, activeSupplierId) ||
        activeCompany?.suppliers?.[0]

      setActiveSupplier(supplier)
    }
  }, [activeCompany, activeSupplierId])

  return (
    <ActiveStoreProviderContext.Provider
      value={{
        activeCompany,
        activeSupplier,
        isImpersonated,
        impersonatedCompany,
        impersonatedSupplier,
        actingCompanyId,
        actingSupplierId,
        activeSupplierInformation,
      }}
    >
      {children}
    </ActiveStoreProviderContext.Provider>
  )
}

export default ActiveStoreProvider
