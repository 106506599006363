import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box } from 'theme-ui'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'

interface QuantityDecrementButtonProps {
  disabled?: boolean
  onClick: () => void
}

const QuantityDecrementButton: FC<QuantityDecrementButtonProps> = ({
  disabled,
  onClick,
}) => {
  const { transitions } = useTheme()

  return (
    <ClickableWrapper
      data-t-id="quantity-decrement"
      data-is-draggable="false"
      disabled={disabled}
      onClick={onClick}
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        outline: 'none',
        paddingLeft: 3,
        paddingRight: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '10px',
          height: '2px',
          bg: disabled ? 'gray.1' : 'gray.3',
          transition: `ease all ${transitions[1]}`,
        }}
      />
    </ClickableWrapper>
  )
}

export default QuantityDecrementButton
