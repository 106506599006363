import React, { FC } from 'react'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { Category } from '../../../../hooks/services/graphql/useCategoryListService'
import PartsNavigationPanelList from './PartsNavigationPanelList'

interface PartsNavigationPanelListSubcategoryProps {
  categories?: Category[]
  activeCategory?: Category | undefined
  parentCategory?: Category
  setSubCategoryId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const PartsNavigationPanelListSubcategory: FC<
  PartsNavigationPanelListSubcategoryProps
> = ({ categories, parentCategory, activeCategory, setSubCategoryId }) => {
  if (!categories || !categories.length) return null

  return (
    <PartsNavigationPanelList
      data-t-id="parts-navigation-subcategories"
      categories={categories}
      activeCategory={activeCategory}
      clickAction={(itemId, englishDescription) => {
        setSubCategoryId(itemId)

        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
          navigate_type: DATA_LAYER.CUSTOM_DIMENSION.NAVIGATION_TYPE.MEGA_MENU,
          main_category: parentCategory?.englishDescription || '',
          sub_category: englishDescription,
          filter_name: '',
          filter_value: '',
          page_type: '',
        })
      }}
    />
  )
}

export default PartsNavigationPanelListSubcategory
