import i18next from 'i18next'
import React, { FC, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refetchShoppingBasketSidepanelSummary } from '../../../actions/shoppingBasket/sidePanelActions'
import {
  addOrReplaceOrderItem,
  createOrderMutation,
  markOrderMutationFinished,
  removeOrderItems,
} from '../../../actions/shoppingBasket/wizard'
import { tooltipRegister } from '../../../actions/tooltipActions'
import { ICONS } from '../../../constants/iconConstants'
import {
  TOOLTIP_BASKET,
  TOOLTIP_PART_QUANTITY_MUTATION,
} from '../../../constants/tooltipConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getEnglishCategoryName } from '../../../helpers/categoryHelper'
import useCommerceApiPostService from '../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveUserStateContext } from '../../../providers/ActiveUserStateProvider'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import {
  deleteOrderItems,
  DeleteOrderItemsRequestProps,
} from '../../../services/rest/ecommerce/order/order-items'
import {
  CommerceMessage,
  CommerceOrderItem,
  CommerceResponse,
} from '../../../types/commerceApi'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Spinner from '../../atoms/Spinner/Spinner'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { registerModalNotification } from '../../../actions/notificationActions'
import { RootState } from '../../../reducers'

interface PartListLineRemoveProps {
  part: CommerceOrderItem
  index: number
}

const PartListLineRemove: FC<PartListLineRemoveProps> = ({ part, index }) => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { orderNumber } = useContext(ActiveUserStateContext)
  const { categories } = useContext(CategoriesContext)
  const dispatch = useDispatch()

  const [deleting, response, executeDeleteOrderItems] = useCommerceApiPostService<
    DeleteOrderItemsRequestProps,
    CommerceResponse<CommerceOrderItem[]>
  >(deleteOrderItems)

  const handleClick = useCallback(() => {
    dispatch(createOrderMutation())
    executeDeleteOrderItems({ orderNumber, items: [part.partNumber] })

    pushToDataLayer({
      event: DATA_LAYER.EVENT_NAME.REMOVE_FROM_CART,
      ecommerce: {
        currency: part.price?.currencyCode,
        value: part.price?.netTotalPrice,
        items: [
          {
            item_id: part.partNumber,
            item_name: part.englishDescription,
            item_brand: part.brand?.description,
            item_category: getEnglishCategoryName(part.category, categories),
            item_list_name: DATA_LAYER.EVENT_NAME.SHOPPING_BASKET,
            doa_product: false, // 'dealer own assortment'
            quantity: part.quantity,
            index,
            price: part.price?.netUnitPrice,
          },
        ],
      },
    })
  }, [categories, dispatch, executeDeleteOrderItems, orderNumber, part, index])

  const onRemoveSuccess = useCallback(
    (commerceMessage: CommerceMessage) => {
      if (commerceMessage.severity === 'Error' && commerceMessage.message) {
        dispatch(registerModalNotification('Error', commerceMessage.message))
      }

      if (commerceMessage.severity === 'Success') {
        if (!part.suggestionInformation) {
          dispatch(removeOrderItems([part.partNumber]))
        }
        dispatch(refetchShoppingBasketSidepanelSummary(true))
        dispatch(markOrderMutationFinished())

        dispatch(
          tooltipRegister({
            name: TOOLTIP_BASKET,
            data: {
              icon: ICONS.CHECK,
              label: i18next.t('shoppingLabels.removeItem', {
                partNumber: part.partNumber,
              }),
            },
          })
        )
      }
    },
    [dispatch, part]
  )

  const onResult = useCallback(
    (commerceResponse: CommerceOrderItem[] | null) => {
      if (commerceResponse?.length && commerceResponse[0]?.suggestionInformation) {
        dispatch(addOrReplaceOrderItem(commerceResponse[0]))
        dispatch(
          tooltipRegister({
            name: `${TOOLTIP_PART_QUANTITY_MUTATION}-${commerceResponse[0].partNumber}`,
            data: {
              icon: ICONS.EXCLAMATION_TRIANGLE,
              label: i18next.t('shoppingLabels.acceptanceDrop', {
                percentage: 0,
              }),
            },
          })
        )
      }
    },
    [dispatch]
  )

  useCommerceApiResponse<CommerceOrderItem[]>({
    response,
    onResult,
    onMessage: onRemoveSuccess,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (result) => result as CommerceOrderItem[],
  })

  if (deleting) return <Spinner size={2} />

  return (
    <ClickableWrapper
      onClick={handleClick}
      data-t-id="shopping-basket-remove-order-item"
      disabled={!order?.isEditAllowed}
    >
      <IconWrapper
        icon={ICONS.TRASH}
        size={4}
        color={order?.isEditAllowed ? 'primary' : 'primaryDisabled'}
      />
    </ClickableWrapper>
  )
}

export default PartListLineRemove
