import React, { FC } from 'react'
import { PartFulFilled } from '../../../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { LinkField, TextField } from '../../../../../types/layoutService'
import SearchResultsPage from '../../SearchResultsPage'
import PartSearchLine from './PartSearchLine'
import SearchResultsLineViewColumnTitles from '../../SearchResultsLineViewColumnTitles'

interface PartSearchLineViewProps {
  parts?: PartFulFilled[]
  partPage: LinkField
  supersessionText: TextField
}

export const partSearchGridTemplate = {
  columnSizes: [
    '1fr',
    '1fr',
    '1fr 2fr 4fr 1fr',
    '1fr 2fr 4fr 1fr',
    '1fr 2fr 4fr 1fr',
    '1fr 2fr 4fr 4fr',
  ], // css prop: grid-template-columns
  columnSizesLoggedIn: ['1fr', '1fr', '1fr 2fr 4fr 1fr 2fr 3fr'], // css prop: grid-template-columns
}

const PartSearchLineView: FC<PartSearchLineViewProps> = ({
  parts,
  partPage,
  supersessionText,
}) => {
  if (!parts?.length) return null

  return (
    <>
      <SearchResultsLineViewColumnTitles gridTemplate={partSearchGridTemplate} />

      <SearchResultsPage parts={parts}>
        {(part: PartFulFilled, index: number) => (
          <PartSearchLine
            {...part}
            index={index}
            partPage={partPage}
            supersessionText={supersessionText}
            gridTemplate={partSearchGridTemplate}
          />
        )}
      </SearchResultsPage>
    </>
  )
}

export default PartSearchLineView
