import React, { ReactNode } from 'react'
import Button from '../../atoms/Button/Button'

interface DealerLocatorFilterChipProps {
  active: boolean
  onClick: () => void
  children: ReactNode
}

const DealerLocatorFilterChip = ({
  active,
  onClick,
  children,
}: DealerLocatorFilterChipProps) => (
  <Button
    variant={active ? 'primary' : 'outline'}
    onClick={onClick}
    sx={{
      mr: 2,
      mb: 2,
      display: 'inline-block',
      ':last-child': { mr: 0 },
    }}
  >
    {children}
  </Button>
)

export default DealerLocatorFilterChip
