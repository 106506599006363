import React from 'react'
import Tab, { TabProps } from '../../molecules/Tab/Tab'
import { COLORS } from '../../../constants/themeConstants'
import { alphaColor } from '../../../helpers/themeHelper'

interface VehicleDetailTabProps extends TabProps {
  value: string
}

const VehicleDetailTab = ({ value, active, ...tabProps }: VehicleDetailTabProps) => (
  <Tab
    sx={{
      backgroundColor: active ? COLORS.WHITE : COLORS.MEDIUM_GRAY,
      color: active ? COLORS.BLACK : COLORS.DAF_DARK_BLUE,
      ':hover': {
        background: active ? COLORS.WHITE : alphaColor(COLORS.BLACK, 0.14),
      },
    }}
    {...tabProps}
  >
    {value}
  </Tab>
)

export default VehicleDetailTab
