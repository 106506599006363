import React, { ReactNode } from 'react'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import trpTheme from '../themes/trp/trpTheme'
import dafTheme from '../themes/daf/dafTheme'
import dafGrayTheme from '../themes/daf/subThemes/dafGrayTheme'
import trpBlueTheme from '../themes/trp/subThemes/trpBlueTheme'
import GlobalStyle from '../themes/GlobalStyle'
import { ThemeName } from '../types/sitecoreContextProps'

export type ComponentBackgroundColor = 'None' | 'Gray' | 'DafGray' | 'Primary'
export type SubThemeName = 'default' | 'dafGray' | 'trpBlue'

interface ThemeProviderProps {
  themeName?: ThemeName
  subThemeName?: SubThemeName
  isRoot?: boolean
  children: ReactNode
}

const getTheme = (themeName: ThemeName, subThemeName: SubThemeName = 'default') => {
  switch (subThemeName) {
    case 'dafGray': {
      return dafGrayTheme
    }
    case 'trpBlue': {
      return trpBlueTheme
    }
    default: {
      return themeName === 'trp' ? trpTheme : dafTheme
    }
  }
}

const ThemeProvider = ({
  themeName = 'daf',
  subThemeName,
  isRoot,
  children,
}: ThemeProviderProps) => (
  <EmotionThemeProvider theme={getTheme(themeName, subThemeName)}>
    {isRoot && <GlobalStyle />}

    {children}
  </EmotionThemeProvider>
)

export default ThemeProvider
