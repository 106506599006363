import React, { FC } from 'react'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, ImageWithFocalPointField } from '../../../types/layoutService'
import SitecoreImageParallax from '../../atoms/SitecoreImage/SitecoreImageParallax'

export interface DoubleImageBlockDatasource {
  imageLeft: ImageWithFocalPointField
  imageRight: ImageWithFocalPointField
}

const DoubleImageBlock: FC<Datasource<DoubleImageBlockDatasource>> = ({
  datasource: { imageLeft, imageRight },
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
        gap: [3, 3, 4],
        img: { width: '100%', height: 'auto', objectFit: 'contain' },
      }}
    >
      <div>
        <SitecoreImageParallax imageField={imageLeft} />
      </div>

      <div>
        <SitecoreImageParallax imageField={imageRight} />
      </div>
    </Box>
  )
}

export default withData(DoubleImageBlock, { checkDatasource: true })
