import React, { FC, useCallback, useContext } from 'react'
import { Box } from 'theme-ui'
import MyAccountAnchor from './MyAccountAnchor'
import { UserContext } from '../../../providers/UserProvider'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

export type ButtonTypes = 'link' | 'post'

interface MyAccountLoginButtonProps {
  title: string
  label: string
  loginUri: string
  buttonType?: ButtonTypes
  textColor?: string
}

const MyAccountLoginButton: FC<MyAccountLoginButtonProps> = ({
  title,
  label,
  loginUri,
  buttonType,
  textColor,
}) => {
  const { userProfile } = useContext(UserContext)
  const { crossSiteUrl } = useSitecoreContext()
  const onClick = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.LOGIN_ACCOUNT,
    })
  }, [])
  if (userProfile) return null
  return (
    <>
      {buttonType === 'link' && (
        <a href={loginUri}>
          <Box sx={{ color: textColor, textDecoration: 'none' }}>
            <MyAccountAnchor
              title={title}
              label={label}
              onClick={onClick}
              data-t-id="menu-button-my-account-login"
            />
          </Box>
        </a>
      )}
      {(buttonType === 'post' || !buttonType) && (
        <form method="POST" action={`${crossSiteUrl ?? ''}${loginUri}`}>
          <MyAccountAnchor
            title={title}
            label={label}
            onClick={onClick}
            data-t-id="menu-button-my-account-login"
            type="submit"
          />
        </form>
      )}
    </>
  )
}
export default MyAccountLoginButton
