import React, { FC } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ConditionalLink } from '../../atoms/Link/Link'
import withData from '../../../enhancers/withData'
import MobileBreadcrumb from '../MobileBreadcrumb/MobileBreadcrumb'
import { Datasource, Flag, Params, TextField } from '../../../types/layoutService'
import BackToSearch, {
  persistedSearchContextHelper,
} from '../BackToSearch/BackToSearch'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { runOnWindow } from '../../../helpers/dom'
import ColumnLayout from '../../atoms/ColumnLayout/ColumnLayout'
import { ColumnLayoutParams } from '../../../types/columnLayoutProps'
import withSubTheme from '../../../enhancers/withTheme'
import { Field } from '@sitecore-jss/sitecore-jss/layout'

interface BreadcrumbProps {
  breadcrumb: BreadcrumbItems
}

interface BreadcrumbParams extends ColumnLayoutParams {
  hideOnMobile?: Flag
  targetPage?: Flag
  startPage?: Flag
}

interface BreadcrumbDatasource {
  mobileBackText: TextField
}

interface BreadcrumbItems {
  items: ItemProps[]
}

export interface ItemProps {
  url: string
  title: string
}

const Breadcrumb: FC<
  Datasource<BreadcrumbDatasource> & Params<BreadcrumbParams> & BreadcrumbProps
> = ({
  breadcrumb: { items = [] },
  datasource,
  params: { hideOnMobile, startPage, targetPage, ...columnLayoutParams },
}) => {
  const { route } = useSitecoreContext()
  const { mobileBackText } = datasource || {}
  const hideBreadcrumb = (route?.fields?.hideBreadcrumb as Field | undefined)?.value

  if (hideBreadcrumb) {
    runOnWindow(() => {
      persistedSearchContextHelper().clear()
    })

    return null
  }

  return (
    <ColumnLayout
      params={{
        ...columnLayoutParams,
        topDivider: '0',
      }}
    >
      <Flex sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <BackToSearch startPage={startPage} targetPage={targetPage} />

        <MobileBreadcrumb
          mobileBackText={mobileBackText}
          hideOnMobile={hideOnMobile}
          items={items}
        />

        <Box
          as="ol"
          itemType="https://schema.org/BreadcrumbList"
          sx={{
            display: ['none', 'none', 'none', 'flex'],
            flexDirection: 'row',
            alignItems: 'center',
            py: 5,
            px: 0,
            mx: -1,
          }}
        >
          {!!items?.length &&
            items.map((item, i) => (
              <Text
                as="li"
                variant="smallText"
                key={i.toString()}
                itemProp="itemListElement"
                itemType="https://schema.org/ListItem"
                sx={{
                  display: 'flex',
                  listStyleType: 'none',
                  alignItems: 'center',
                  color: 'gray.2',
                }}
              >
                <ConditionalLink
                  dataTId={`breadcrumb-${item.title}`}
                  condition={i + 1 !== items.length}
                  href={item.url}
                  itemProp="item"
                  sx={{
                    fontSize: '0',
                    textDecoration: 'none',
                    outline: 'none',
                    px: 1,
                    color: 'inherit',
                  }}
                >
                  <span itemProp="name">{item.title}</span>
                </ConditionalLink>

                {items.length !== i + 1 && (
                  <IconWrapper icon={ICONS.ARROW_RIGHT} size={1} />
                )}

                <meta itemProp="position" content={(i + 1).toString()} />
              </Text>
            ))}
        </Box>
      </Flex>
    </ColumnLayout>
  )
}
export default withData(withSubTheme(Breadcrumb), { checkDatasource: false })
