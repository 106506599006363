import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import { joinStringsWithDelimiter } from '../../../helpers/stringHelper'
import { Address } from '../../../types/dealerServiceTypes'

interface DealerLocatorDealerAddressProps {
  address: Address
}

const DealerLocatorDealerAddress: FC<DealerLocatorDealerAddressProps> = ({
  address,
}) => {
  const addressSegments = useMemo(
    () => [
      joinStringsWithDelimiter(
        [address?.addressLine1, address?.addressLine2, address?.addressLine3],
        ', '
      ),
      joinStringsWithDelimiter([address?.postalCode, address?.city]),
      address?.country || '',
    ],
    [address]
  )

  return (
    <Text
      as="ul"
      variant="bodySmall"
      sx={{
        listStyle: 'none',
        padding: 0,
        lineHeight: 'menuList',
      }}
    >
      {!!addressSegments[0].length && <li>{addressSegments[0]}</li>}

      {!!addressSegments[1].length && <li>{addressSegments[1]}</li>}

      {!!addressSegments[2].length && <li>{addressSegments[2]}</li>}
    </Text>
  )
}

export default DealerLocatorDealerAddress
