export const DATA_LAYER = {
  EVENT_KEYS: {
    EVENT: 'event',
    EVENT_NAME: 'eventName',
  },
  EVENT: {
    SEARCH: 'search',
    SEARCH_SUGGEST: 'searchSuggest',
    SCAN_BUTTON: 'scan_button',
    NAVIGATE_MENU: 'navigateMenu',
    NAVIGATE_MENU_ALL: 'navigateMenuAll',
    ADD_FILTER_MENU: 'addFilterMenu',
    NAVIGATE_IN_PAGE: 'navigateInPage',
    MODAL_CLOSE: 'modal close',
    MODAL_INTERACTION: 'modal interaction',
    REQUEST_ACCOUNT: 'requestAccount',
    VIEW_ITEM: 'view_item',
    SELECT_ITEM: 'select_item',
    ADD_TO_QUOTATION: 'addToQuotation',
    CHANGE_QUANTITY: 'change quantity',
    LOGIN: 'login',
    SELECT_COMPANY: 'selectCompany',
    SELECT_SUPPLIER: 'selectSupplier',
    CLICK: 'click',
    FORM_SUBMISSION: 'formSubmission',
    CHANGE_VIEW_MODE: 'changeViewMode',
    INITIAL_VIEW_MODE: 'initial view mode',
    BACK_TO_TOP: 'backToTop',
    CHANGE_SHOW_MODE: 'changeShowMode',
    TABLE_SORT_ASCEND: 'tableSortAscend',
    TABLE_SORT_DESCEND: 'tableSortDescend',
    TABLE_SORT_RESET: 'tableSortReset',
    CONTACT: 'contact',
    FIND_DEALER: 'find dealer',
    SHOPPING_CART: 'shoppingCart',
    ACCOUNT: 'account',
    VIDEO_VIEW: 'videoView',
    VIEW_FILTER_IN_PAGE: 'viewFilterInPage',
    ADD_FILTER_IN_PAGE: 'addFilterInPage',
    REMOVE_FILTER_IN_PAGE: 'removeFilterInPage',
    REMOVE_ALL_FILTERS: 'removeAllFilters',
    SUBMIT_FILTERS: 'submitFilters',
    FIND_DEALER_REQUEST_FORM_SELECT: 'find dealer request form select',
    QUICK_NAVIGATION: 'quickNavigation',
    PRODUCT_DETAIL: 'productDetail',
    UPLOAD_FILE: 'upload file',
    ADD_PART_NUMBER: 'add partnumber',
    STOCK_AVAILABILITY: 'stock_availability',
    ADD_NOTE: 'add note',
    CHANGE_SHIPPING_INFO: 'change shipping info',
    MAGAZINE_NAVIGATION: 'magazine_navigation',
    CALL_TO_ACTION: 'call_to_action',
  },
  EVENT_NAME: {
    SEARCH_FUNCTION: 'search_function',
    SEARCH_SUGGEST: 'search_suggest',
    SCAN_BUTTON: 'scan_button',
    NAVIGATE: 'navigate',
    MODAL: 'modal',
    REQUEST_ACCOUNT: 'request_account',
    ADD_TO_CART: 'add_to_cart',
    ADD_TO_QUOTATION: 'add_to_quotation',
    CHANGE_QUANTITY: 'change_quantity',
    LOGIN_ACCOUNT: 'login_account',
    HEADER_DROPDOWN: 'header_dropdown',
    CALL_TO_ACTION: 'call_to_action',
    FORMS: 'forms',
    CHANGE_VIEW_MODE: 'change_view_mode',
    INITIAL_VIEW_MODE: 'initial_view_mode',
    BACK_TO_TOP: 'back_to_top',
    CHANGE_SHOW_MODE: 'change_show_mode',
    TABLE_SORT: 'table_sort',
    CONTACT: 'contact',
    SHOPPING_BASKET: 'shopping_basket',
    ACCOUNT: 'account',
    PRODUCT_SUPERSESSION: 'product_supersession',
    VIDEO: 'video',
    FILTER: 'filter',
    DEALER_FINDER: 'dealer_finder',
    UPLOAD_FILE: 'upload_file',
    ADD_PART_NUMBER: 'add_partnumber',
    ADD_NOTE: 'add_note',
    STOCK_AVAILABILITY: 'stock_availability',
    CHANGE_SHIPPING_INFO: 'change_shipping_info',
    ADD_SHIPPING_INFO: 'add_shipping_info',
    REMOVE_FROM_CART: 'remove_from_cart',
    REMOVE_ALL_FROM_CART: 'remove_all_from_cart',
    PURCHASE_REGISTERED: 'purchase_registered',
    MAGAZINE_NAVIGATION: 'magazine_navigation',
    VIEW_CART: 'view_cart',
    VIEW_ITEM_LIST: 'view_item_list',
  },
  EVENT_CATEGORY: {
    SEARCH_FUNCTION: 'searchFunction',
    SEARCH_SUGGEST: 'searchSuggest',
    SCAN_BUTTON: 'scan_button',
    NAVIGATE_MENU: 'navigate - menu',
    NAVIGATE_MENU_ALL: 'navigate - menu - all',
    ADD_FILTER_IN_MENU: 'add filter - in menu',
    NAVIGATE_IN_PAGE: 'navigate - in page',
    MODAL_CLOSE: 'modal close',
    MODAL_INTERACTION: 'modal interaction',
    REQUEST_ACCOUNT: 'request account',
    UNCATEGORIZED: 'uncategorized',
    ADD_TO_QUOTATION: 'addToQuotation',
    ECOMMERCE: 'ecommerce',
    LOGIN: 'login',
    HEADER_COMPANY_SELECTION: 'header - company selection',
    HEADER_SUPPLIER_SELECTION: 'header - supplier selection',
    CTA: 'cta',
    FORM_SUBMISSION: 'form submission',
    FORM_ENGAGEMENT: 'form engagement',
    CHANGE_VIEW_MODE: 'change view mode',
    INITIAL_VIEW_MODE: 'initial view mode',
    CHANGE_SHOW_MODE: 'change show mode',
    CLICK: 'click',
    TABLE_SORT_ASCEND: 'table sort - ascend',
    TABLE_SORT_DESCEND: 'table sort - descend',
    TABLE_SORT_RESET: 'table sort - reset',
    CONTACT: 'contact',
    FIND_DEALER: 'find dealer',
    SHOPPING_CART_OVERVIEW: 'shoppingCartOverview',
    ACCOUNT_OVERVIEW: 'accountOverview',
    VIDEO: 'video',
    VIEW_FILTER: 'view filter',
    REMOVE_FILTER_IN_PAGE: 'remove filter in page',
    REMOVE_FILTER: 'remove filter',
    ADD_FILTER_IN_PAGE: 'add filter in page',
    SUBMIT_FILTER: 'submit filter',
    QUICK_NAVIGATION: 'quickNavigation',
    UPLOAD_FILE: 'upload file',
    ADD_PART_NUMBER: 'add partnumber',
    STOCK_AVAILABILITY: 'stockAvailability',
    ADD_NOTE: 'add note',
    CHANGE_SHIPPING_INFO: 'change shipping info',
  },
  EVENT_ACTION: {
    SEARCH_BAR: 'Search Bar',
    CHANGE_QUANTITY: 'change quantity',
    SELECT: 'select',
    CLOSE: 'close',
    DROPDOWN: 'dropdown',
    BACK_TO_TOP: 'back to top',
    SEARCH: 'search',
    CURRENT_LOCATION: 'current location',
    FILTER: 'filter',
    CONTACT_DETAILS: 'contact details',
    CONTACT_DETAILS_POP_IN: 'contact details - pop-in',
    FIND_DEALER: 'find dealer',
    FIND_DEALER_POP_IN: 'find dealer - pop-in',
    SHOW_DEALER_DETAILS: 'show dealer details',
    OPEN: 'open',
    START: 'start',
    SELECT_DEALER: 'select dealer',
    OTHER_DEALER: 'other dealer',
    DETAIL: 'detail',
    STATUS: 'status',
    CLICK: 'click',
  },
  EVENT_LABEL: {
    CLICK: 'click',
    INTENTION: 'intention',
  },
  CUSTOM_DIMENSION: {
    SEARCH_TYPE: {
      SEARCH_BAR: 'search bar',
      VEHICLE_SEARCH: 'vehicle search',
      DEALER_FINDER: 'dealerfinder',
      SEARCH_SCAN_BUTTON: 'search scan button',
    },
    SCAN_OPTION: {
      SCAN: 'scan',
      UPLOAD_IMAGE: 'upload image',
      SCAN_HISTORY: 'scan history',
    },
    NAVIGATION_TYPE: {
      MEGA_MENU: 'mega menu',
      IN_PAGE: 'in page',
      JUMP_TO_TABLE_IN_PAGE: 'jump to table in page',
    },
    MAIN_CATEGORY: {
      ALL: 'all',
    },
    SUB_CATEGORY: {
      ALL: 'all',
    },
    MODAL_ACTION: {
      NEXT: 'next',
      PREVIOUS: 'previous',
      DONE: 'done',
      CLOSE: 'close',
    },
    QUANTITY_CHANGE: {
      ADD: '+',
      REMOVE: '-',
      INPUT: 'typebox',
    },
    ACTION: {
      SELECT: 'select',
      CLOSE: 'close',
      DROPDOWN: 'dropdown',
      FORM_ENGAGEMENT: 'form engagement',
      SUCCESSFUL: 'successful',
      FAILED_SUBMIT: 'failed submit',
      SEARCH: 'search',
      CURRENT_LOCATION: 'current location',
      FILTER: 'filter',
      CLICK_CONTACT_DETAILS: 'click contact details',
      CLICK_CONTACT_DETAILS_POP_IN: 'click contact details - pop-in',
      REQUEST_ACCOUNT: 'request account',
      REQUEST_ACCOUNT_POP_IN: 'request account - pop-in',
      CLICK_SHOW_DEALER_DETAILS: 'click show dealer details',
      OPEN: 'open',
      SHOW_PRODUCT_SUPER_SESSION_INFORMATION:
        'show product supersession information',
      START: 'start',
      SELECT_DEALER: 'select dealer',
      SELECT_DIFFERENT_DEALER: 'select different dealer',
    },
    CALL_TO_ACTION_TYPE: {
      BUTTON: 'button',
      ARTICLE: 'article',
      HERO_HEADER: 'hero header',
    },
    CONTACT_METHOD: {
      MOBILE_PHONE: 'mobile phone',
    },
    FILTER_ACTION: {
      VIEW: 'view',
      ADD: 'add',
      REMOVE_OVERLAY: 'remove overlay',
      ACTIVATE: 'activate',
    },
    FILTER_TYPE: {
      TOP_THREE: 'top 3',
      MORE_FILTERS: 'more filters',
    },
    STATUS: {
      INTENTION: 'intention',
      SUCCESS: 'success',
      FAIL: 'fail',
      ADD: 'add',
      EDIT: 'edit',
    },
  },
  COMPONENT_ORIGIN: {
    SEARCH_RESULT_GRID: 'search result grid',
    SEARCH_RESULT_LINE: 'search result line',
    SEARCH_RESULT_TABLE: 'search result table',
    SEARCH_RESULT_MOBILE: 'search result mobile',
    PRODUCT_DETAIL: 'product detail',
    ASSEMBLY_BOM: 'assembly - BoM',
    PART_BOM_MASTER: 'part - BoM master',
    PART_BOM_PART: 'part - BoM part',
    RECOMMENDED_PART_SLIDER: 'part - recommended parts slider',
    PART_SPARE_PARTS: 'part - spare parts slider',
    PART_CROSS_SELL_PARTS: 'part - cross sell parts slider',
    PART_ALTERNATIVE_PARTS: 'part - alternative parts slider',
    PART_SLIDER: 'parts slider',
    PART_PROMOTIONS_SLIDER: 'part promotions slider',
    PART_MOST_VISITED_SLIDER: 'most visited parts slider',
    DEALER_FINDER: 'dealer finder',
    PART_BASIC_INFORMATION: 'part basic information',
    SHOPPING_BASKET: 'shopping basket',
  },
}
