import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  ShoppingBasketWizardDatasource,
  ShoppingBasketWizardProps,
  ShoppingBasketWizardSteps,
} from '../components/organisms/ShoppingBasketWizard/ShoppingBasketWizard'
import { LinkField, RenderingProps } from '../types/layoutService'
import {
  URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM,
} from '../constants/urlConstants'
import { CommerceOrder, CommerceResponse } from '../types/commerceApi'
import useCommerceApiFetchService from '../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { getOrder, OrderRequestProps } from '../services/rest/ecommerce/order/orders'
import { ActiveUserStateContext } from './ActiveUserStateProvider'
import useCommerceApiResponse from '../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../reducers'
import {
  refetchShoppingBasketOrder,
  setOrder as setOrderAction,
} from '../actions/shoppingBasket/wizard'
import { useHistory, useLocation } from 'react-router-dom'
import { getUrlQueryValue } from '../helpers/urlHelper'

interface ShoppingBasketProviderProps extends ShoppingBasketWizardProps {
  children: React.ReactNode
  datasource: ShoppingBasketWizardDatasource
  rendering: RenderingProps
}

interface ShoppingBasketContextProps {
  initialFetch: boolean
  datasource: ShoppingBasketWizardDatasource
  rendering?: RenderingProps
  partPage: LinkField
  dealerPartPage: LinkField
  orderConfirmedPage: LinkField
  quotationSentPage: LinkField
  step: ShoppingBasketWizardSteps
  setStep: (newStep: ShoppingBasketWizardSteps) => void
}

// Create the context with a function that takes the default props
function createShoppingBasketContext() {
  const context = createContext<ShoppingBasketContextProps | undefined>(undefined)

  function useShoppingBasket() {
    const contextValue = useContext(context)
    if (contextValue === undefined) {
      throw new Error('useTheme must be used within a ThemeProvider')
    }
    return contextValue
  }

  return { Provider: context.Provider, useShoppingBasket }
}

const { Provider, useShoppingBasket } = createShoppingBasketContext()

const ShoppingBasketProvider = ({
  children,
  datasource,
  rendering,
  partPage,
  dealerPartPage,
  orderConfirmedPage,
  quotationSentPage,
}: ShoppingBasketProviderProps) => {
  const [initialFetch, setInitialFetch] = useState(true)
  const { refetchOrder } = useSelector(
    (state: RootState) => state.shoppingBasketWizard
  )
  const dispatch = useDispatch()
  const { orderNumber } = useContext(ActiveUserStateContext)
  const [, response, fetchOrder] = useCommerceApiFetchService<
    OrderRequestProps,
    CommerceResponse<CommerceOrder>
  >(getOrder)

  const onResult = (newOrder: CommerceOrder | null) => {
    setInitialFetch(false)
    if (newOrder) {
      dispatch(setOrderAction(newOrder))
    }
  }

  useCommerceApiResponse<CommerceOrder>({
    response,
    onResult,
    onMessage: (message) => {
      if (message.code === 'orderNotFound') setInitialFetch(false)
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrder,
  })

  useEffect(() => {
    fetchOrder({ orderNumber })
  }, [fetchOrder, orderNumber])

  useEffect(() => {
    if (refetchOrder) {
      fetchOrder({ orderNumber })
      dispatch(refetchShoppingBasketOrder(false))
    }
  }, [fetchOrder, orderNumber, refetchOrder])

  const { search } = useLocation()
  const history = useHistory()

  const getUrl = useCallback(() => {
    const urlValue = getUrlQueryValue(URL_SHOPPING_BASKET_WIZARD_STEP_PARAM, search)

    if (urlValue === URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM) {
      return URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM
    }

    if (urlValue === URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM) {
      return URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM
    }

    return URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM
  }, [search])

  const [step, setStepInternal] = useState<ShoppingBasketWizardSteps>(() => getUrl())

  const updateQueryParam = useCallback(
    (newStep: ShoppingBasketWizardSteps) => {
      if (getUrl() !== newStep) {
        const params = new URLSearchParams(search)
        params.set(URL_SHOPPING_BASKET_WIZARD_STEP_PARAM, newStep)
        history.push({ search: params.toString() })
      }
    },
    [search, history]
  )

  const setStep = useCallback(
    (newStep: ShoppingBasketWizardSteps) => {
      setStepInternal(newStep)
      updateQueryParam(newStep)
    },
    [updateQueryParam]
  )

  useEffect(() => {
    const urlStep = getUrl()
    if (urlStep !== step) {
      setStepInternal(urlStep)
    }
  }, [search, getUrl, step])

  useEffect(() => {
    updateQueryParam(step)
  }, [step])

  return (
    <Provider
      value={{
        initialFetch,
        datasource,
        partPage,
        dealerPartPage,
        orderConfirmedPage,
        quotationSentPage,
        step,
        setStep,
        rendering,
      }}
    >
      {children}
    </Provider>
  )
}

export { ShoppingBasketProvider, useShoppingBasket }
