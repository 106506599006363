import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Box, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { COLORS } from '../../../../constants/themeConstants'
import { ICONS } from '../../../../constants/iconConstants'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'

interface SidePanelClickableSectionProps {
  children: ReactNode
  label: string
  labelId?: string
  initialActive?: boolean
}

const SidePanelClickableSection = ({
  children,
  label,
  labelId,
  initialActive = false,
  ...rest
}: SidePanelClickableSectionProps) => {
  const detailsRef = useRef<HTMLDetailsElement>(null)
  const { space } = useTheme()
  const [active, setActive] = useState(initialActive)

  useEffect(() => {
    detailsRef.current?.addEventListener('toggle', () => {
      if (detailsRef.current) {
        setActive(detailsRef.current.open)
      }
    })
  }, [initialActive])

  return (
    <Box
      sx={{
        borderBlockStart: '1px solid',
        borderColor: COLORS.MEDIUM_GRAY,
      }}
    >
      <details ref={detailsRef} open={active} {...rest}>
        <Box
          as="summary"
          id={labelId}
          sx={{
            cursor: 'pointer',
            paddingBlock: space[3],
            paddingInline: [space[3], null, space[6]],
            listStyle: 'none',
            '::-webkit-details-marker': {
              display: 'none',
            },
            display: 'flex',
            justifyContent: 'space-between',
            gap: space[3],
            color: COLORS.DAF_BLUE,
            ':hover, :focus, :active': {
              color: COLORS.DAF_DARK_BLUE,
            },
          }}
        >
          <Text variant="heading4">{label}</Text>
          <IconWrapper
            icon={ICONS.ARROW_DOWN}
            size={4}
            style={{
              rotate: active && '180deg',
            }}
          />
        </Box>
        <Box
          sx={{
            paddingBlock: space[3],
            paddingInline: [space[3], null, space[6]],
            borderBlockStart: '1px solid',
            borderColor: COLORS.MEDIUM_GRAY,
          }}
        >
          {children}
        </Box>
      </details>
    </Box>
  )
}

export default SidePanelClickableSection
