import React, { Children, ReactNode } from 'react'
import { Box, Flex } from 'theme-ui'

interface ContentColumnLayoutProps {
  children: ReactNode
}

const ContentColumnLayout = ({ children }: ContentColumnLayoutProps) => {
  const columnCount = Children.count(children)

  return (
    <Flex
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        backgroundColor: 'background',
      }}
    >
      {Children.map(children, (child) => (
        <Box
          sx={{
            width: ['100%', '100%', '50%', '50%', `${100 / columnCount}%`],
            px: [4, 4, 4, 5],
            py: 5,
            borderColor: 'gray.1',
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: [
              1,
              1,
              columnCount > 2 ? 1 : 0,
              columnCount > 2 ? 1 : 0,
              0,
            ],
            borderRightWidth: '1px',
            ':last-child': {
              borderRightWidth: columnCount === 3 ? 1 : 0,
              borderBottomWidth: 0,
            },
            [`:nth-last-of-type(-n+${columnCount - 2})`]: {
              borderBottomWidth: 0,
            },
          }}
        >
          {child}
        </Box>
      ))}
    </Flex>
  )
}

export default ContentColumnLayout
