import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'

interface VehicleGridTileProps {
  children: ReactNode
  sx?: Record<string, unknown>
}

const VehicleGridTile = ({ children, sx }: VehicleGridTileProps) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: COLORS.MEDIUM_GRAY,
      borderRadius: 8,
      padding: [3, 3, 5],
      ...sx,
    }}
  >
    {children}
  </Box>
)

export default VehicleGridTile
