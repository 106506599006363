import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Flex, FlexProps } from 'theme-ui'

interface GridCardContainerProps extends Omit<FlexProps, 'css'> {
  bordered?: boolean
}

const GridCardContainer: FC<GridCardContainerProps> = ({
  bordered = false,
  children,
  sx,
}) => {
  const { space } = useTheme()

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        py: bordered ? [3, 3, 5] : `${space[4] / 2}px`,
        width: ['100%', '50%', `calc(100% / ${3})`],
        px: `${space[4] / 2}px`,
        ...(bordered && {
          borderBottomColor: 'gray.1',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }),
        ...sx,
      }}
    >
      {children}
    </Flex>
  )
}

export default GridCardContainer
