import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import ExperienceEditorMessage from './ExperienceEditorMessage'

export interface ExperienceEditorComponentFrameProps extends Omit<BoxProps, 'css'> {
  title?: string
  text?: string
}

export const ExperienceEditorComponentFrame: FC<
  ExperienceEditorComponentFrameProps
> = ({ text, children }) => (
  <Box
    sx={{
      border: '2px solid LightSkyBlue',
    }}
  >
    <ExperienceEditorMessage text={text} />

    {children}
  </Box>
)

export default ExperienceEditorComponentFrame
