import React from 'react'
import {
  Box,
  Button as ThemeUIButton,
  ButtonProps as ThemeUIButtonProps,
  Text,
} from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import ButtonIcon from './ButtonIcon'

export type ButtonVariants = 'primary' | 'outline' | 'ghost' | 'infinite'

export interface ButtonProps extends Omit<ThemeUIButtonProps, 'css'> {
  variant?: ButtonVariants
  hideLabel?: boolean
  round?: boolean
  icon?: string
  iconPosition?: 'start' | 'end'
  iconSize?: number
  target?: string
}

const Button = ({
  variant = 'primary',
  hideLabel = false,
  round = false,
  icon,
  iconPosition = 'start',
  iconSize = 4,
  children,
  target,
  sx,
  ...rest
}: ButtonProps) => {
  const { pageEditing } = useSitecoreContext()
  const restProps = {
    ...rest,
    target,
  }
  return (
    <ThemeUIButton
      variant={variant}
      sx={{
        paddingX: round || variant === 'infinite' || hideLabel ? 0 : '20px',
        paddingY: 0,
        minHeight: round || variant !== 'infinite' ? 40 : null,
        maxHeight: round ? 40 : undefined,
        minWidth: 40,
        width: 'max-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
        cursor: 'pointer',
        '> svg': {
          display: 'block',
          position: round ? 'absolute' : undefined,
        },
        ':disabled': {
          cursor: 'initial',
          opacity: 0.3,
          pointerEvents: 'none',
          'svg, svg path': {
            fill: 'currentColor !important', // overrule all default styles and force to match icon color with text color
          },
        },
        ...sx,
      }}
      {...restProps}
      {...(pageEditing ? { onClick: (e) => e.preventDefault() } : {})}
      rel={target === '_blank' ? 'noopener' : undefined}
    >
      {icon && iconPosition === 'start' && (
        <ButtonIcon icon={icon} size={iconSize} removeMargin={hideLabel} />
      )}

      {children && (
        <Box variant={hideLabel ? 'visuallyHidden' : undefined}>
          <Text variant="bodySmall">{children}</Text>
        </Box>
      )}
      {icon && iconPosition === 'end' && (
        <ButtonIcon icon={icon} size={iconSize} removeMargin={hideLabel} />
      )}
    </ThemeUIButton>
  )
}

export default Button
