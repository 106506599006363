import React, { FC } from 'react'
import { Flex, FlexProps } from 'theme-ui'
import PartAddToBasket from '../AddToBasket/PartAddToBasket'
import PartPromotionsAsync from '../PartPromotions/PartPromotionsAsync'
import PartPriceAsync from '../PartPrice/PartPriceAsync'

interface PartSalesInformationProps extends Omit<FlexProps, 'css'> {
  partNumber?: string
  partEnglishDescription?: string
  brandName?: string
  categoryId?: string
  subcategoryId?: string
  dataLayerComponentOrigin?: string
  packageUnitQuantity?: number
  initialQuantity?: number
}

const PartSalesInformation: FC<PartSalesInformationProps> = ({
  partNumber,
  partEnglishDescription,
  brandName,
  categoryId,
  subcategoryId,
  dataLayerComponentOrigin = '',
  packageUnitQuantity,
  initialQuantity,
  ...flexProps
}) => {
  if (!partNumber) return null

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
      {...flexProps}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <div>
          <PartPriceAsync partNumber={partNumber} variant="small" />
        </div>

        <PartAddToBasket
          partNumber={partNumber}
          partEnglishDescription={partEnglishDescription}
          brandName={brandName}
          categoryId={categoryId}
          subcategoryId={subcategoryId}
          variant="small"
          dataLayerComponentOrigin={dataLayerComponentOrigin}
          stepSize={packageUnitQuantity}
          initialQuantity={initialQuantity}
        />
      </Flex>

      <PartPromotionsAsync partNumber={partNumber} mt={2} />
    </Flex>
  )
}

export default PartSalesInformation
