import React, { ReactNode } from 'react'
import { Box, Flex } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import { SliderTuple } from '../../../hooks/slider/useSlider'
import Button from '../../atoms/Button/Button'

interface CarouselControlsProps {
  slider: SliderTuple
  children: ReactNode
}

const CarouselControls = ({ slider, children }: CarouselControlsProps) => {
  const [, decrementPageNumber, incrementPageNumber, { isFirstPage, isLastPage }] =
    slider

  return (
    <Flex sx={{ width: '100%', height: '100%' }}>
      <Flex
        sx={{ flexShrink: 1, flexDirection: 'column', justifyContent: 'center' }}
      >
        <Button
          data-t-id="carousel-previous-item"
          variant="outline"
          round
          icon={ICONS.ARROW_LEFT}
          disabled={isFirstPage}
          onClick={decrementPageNumber}
        />
      </Flex>

      <Box
        sx={{
          flexBasis: 'fit-content',
          flexGrow: 1,
          paddingX: 4,
          '> div': {
            height: '100%',
          },
        }}
      >
        {children}
      </Box>

      <Flex
        sx={{ flexShrink: 1, flexDirection: 'column', justifyContent: 'center' }}
      >
        <Button
          data-t-id="carousel-next-item"
          variant="outline"
          round
          icon={ICONS.ARROW_RIGHT}
          disabled={isLastPage}
          onClick={incrementPageNumber}
        />
      </Flex>
    </Flex>
  )
}

export default CarouselControls
