import { ShoppingBasketWizardState } from '.'

export default (state: ShoppingBasketWizardState, payload: string) => {
  if (!state?.order) return state

  return {
    ...state,
    order: {
      ...state.order,
      customerOrderReference: {
        ...state.order.customerOrderReference,
        value: payload,
      },
    },
  }
}
