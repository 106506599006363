import React, { useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import i18next from 'i18next'
import WithData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import { COLORS } from '../../../constants/themeConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { MyAccountPageProvider } from '../../../providers/MyAccountPageProvider'
import FinanceProfileOverview from './FinanceProfileOverview'
import RequestMyAccountChangesSidepanel from '../SidePanel/RequestMyAccountChangesSidepanel/RequestMyAccountChangesSidepanel'
import Button from '../../atoms/Button/Button'

interface FinancialProfileDataSource {
  title: TextField
  description: TextField
  emailLabel: TextField
  invoiceMethodLabel: TextField
  paymentTypeLabel: TextField
  collectionDayLabel: TextField
  datasource: {
    targetItem: RequestChangesSidepanelDatasource
  }
}

interface RequestChangesSidepanelDatasource {
  title: TextField
  footer: TextField
  messageInputLabel: TextField
  messageInputPlaceholder: TextField
  thankYouMessage: TextField
}

const FinancialProfile = ({
  datasource: {
    title,
    description,
    emailLabel,
    invoiceMethodLabel,
    paymentTypeLabel,
    collectionDayLabel,
    datasource: { targetItem },
  },
}: Datasource<FinancialProfileDataSource>) => {
  const { space } = useTheme()
  const [sidePanelActive, setSidePanelActive] = useState(false)

  return (
    <MyAccountPageProvider>
      <Flex sx={{ flexDirection: 'column' }}>
        {title && (
          <Text
            as="h1"
            variant="heading1"
            sx={{
              color: COLORS.BLACK,
              fontSize: 5,
              lineHeight: 'heading',
            }}
          >
            {getTextFieldValue(title)}
          </Text>
        )}
        {description && (
          <Box sx={{ width: ['100%', '66%'] }}>
            <Text
              variant="heading2"
              sx={{
                marginBlock: space[3],
                color: COLORS.GRAY,
                lineHeight: 'body',
                fontSize: 2,
              }}
            >
              {getTextFieldValue(description)}
            </Text>
          </Box>
        )}
        <Box>
          <FinanceProfileOverview
            labels={{
              emailLabel: getTextFieldValue(emailLabel),
              invoiceMethodLabel: getTextFieldValue(invoiceMethodLabel),
              paymentTypeLabel: getTextFieldValue(paymentTypeLabel),
              collectionDayLabel: getTextFieldValue(collectionDayLabel),
            }}
          />
        </Box>

        <Button
          sx={{ marginBlockStart: space[4] }}
          data-t-id="company-profile-request-changes-sidepanel"
          variant="primary"
          onClick={() => {
            setSidePanelActive(true)
          }}
        >
          {i18next.t('myAccountOverview.requestAChange')}
        </Button>
      </Flex>
      <RequestMyAccountChangesSidepanel
        active={sidePanelActive}
        labels={targetItem}
        onSidepanelClose={() => {
          setSidePanelActive(false)
        }}
      />
    </MyAccountPageProvider>
  )
}
export default WithData(FinancialProfile)
