import React, { useContext } from 'react'
import { MyAccountPageContext } from '../../../providers/MyAccountPageProvider'
import Tile from '../../atoms/Tile/Tile'
import DefinitionList from '../../atoms/DefinitionList/DefinitionList'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'
import Spinner from '../../atoms/Spinner/Spinner'
import { FINANCE } from '../../../constants/accountUserDetailsConstants'

interface FinanceProfileOverviewProps {
  labels: {
    emailLabel: string
    invoiceMethodLabel: string
    paymentTypeLabel: string
    collectionDayLabel: string
  }
}

const FinanceProfileOverview = ({
  labels: { emailLabel, invoiceMethodLabel, paymentTypeLabel, collectionDayLabel },
}: FinanceProfileOverviewProps) => {
  const { accountUserDetailsData, isLoading } = useContext(MyAccountPageContext)

  const financeEmail = accountUserDetailsData?.company?.email?.find(
    (email) => email.type === FINANCE
  )?.address

  const definitions = [
    {
      term: emailLabel,
      detail: showDashWhenEmptyValue(financeEmail).toString(),
    },
    {
      term: invoiceMethodLabel,
      detail: showDashWhenEmptyValue(
        accountUserDetailsData?.finance?.invoiceMethod
      ).toString(),
    },
    {
      term: paymentTypeLabel,
      detail: showDashWhenEmptyValue(
        accountUserDetailsData?.finance?.paymentMethod
      ).toString(),
    },
    {
      term: collectionDayLabel,
      detail: showDashWhenEmptyValue(
        accountUserDetailsData?.finance?.paymentTermsDay
      ).toString(),
    },
  ]
  return (
    <>
      <Tile>
        {isLoading && <Spinner />}
        {!isLoading && accountUserDetailsData && (
          <DefinitionList
            definitions={definitions}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 2fr'],
              gap: 3,
              alignItems: 'center',
            }}
          />
        )}
      </Tile>
    </>
  )
}

export default FinanceProfileOverview
