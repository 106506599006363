import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'

const ModalBodyContent: FC<Omit<BoxProps, 'css'>> = ({
  children,
  sx,
  ...boxProps
}) => (
  <Box
    sx={{
      p: [3, 3, 3, 5],
      flexGrow: 1,
      backgroundColor: 'inherit',
      ...sx,
    }}
    {...boxProps}
  >
    {children}
  </Box>
)

export default ModalBodyContent
