import i18next from 'i18next'
import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ShoppingBasketWayOfTransportSidePanel from '../../SidePanel/ShoppingBasket/ShoppingBasketWayOfTransportSidePanel'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import {
  URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_PARAM,
} from '../../../../constants/urlConstants'
import { RootState } from '../../../../reducers'

interface OrderStickySummaryWayOfTransportProps {}

const OrderStickySummaryWayOfTransport: FC<
  OrderStickySummaryWayOfTransportProps
> = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const [sitePanelOpen, setSidePanelOpen] = useState(false)

  const selectedOption = order?.wayOfTransportOptions?.find(
    ({ isSelectedOption }) => isSelectedOption
  )

  const openSidePanel = useCallback(() => {
    const params = new URLSearchParams(search)

    params.set(
      URL_SHOPPING_BASKET_WIZARD_STEP_PARAM,
      URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM
    )

    history.push(`?${params.toString()}`)

    setSidePanelOpen(true)
  }, [history, search])

  return (
    <>
      <dt>
        {i18next.t('shoppingLabels.deliveryLabels.priceBasedOnTransport')}{' '}
        <ClickableWrapper
          onClick={openSidePanel}
          sx={{
            color: 'primary',
          }}
        >
          Edit
        </ClickableWrapper>
      </dt>
      <dd>{`${selectedOption?.method} (${selectedOption?.deliveryTerm})`}</dd>
      <ShoppingBasketWayOfTransportSidePanel
        active={sitePanelOpen}
        onClose={() => setSidePanelOpen(false)}
      />
    </>
  )
}

export default OrderStickySummaryWayOfTransport
