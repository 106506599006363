import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import AppRoot from './AppRoot'
import { defaultLanguage } from './temp/config'
import { initializeI18nClientSide } from './lib/localization'
import rootReducer from '../src/reducers'
import graphQLClientFactory from './lib/graphQLClientFactory'
import { ENVIRONMENT_CERT, ENVIRONMENT_PROD } from './constants/environmentConstants'
import { isEnvironment } from './helpers/environment'
import axiosClientFactory from './lib/axiosClientFactory'
import appInsightsFactory from './lib/appInsightsFactory'

function getInitialJssState() {
  const jsonSourceElement = document.getElementById('__JSS_STATE__')
  return jsonSourceElement && jsonSourceElement.innerHTML
    ? JSON.parse(jsonSourceElement.innerHTML)
    : null
}

let initLanguage = defaultLanguage

/**
 * Sitecore JavaScript Services
 */
const initialJssState = getInitialJssState()

/**
 * GraphQL
 */
const graphQLClient = graphQLClientFactory()

/**
 * Axios
 */
const axiosClientMap = axiosClientFactory()

/**
 * Redux
 */

const reduxStore = createStore(
  rootReducer,
  window.__GLOBAL_REDUX_STATE__,
  isEnvironment(ENVIRONMENT_CERT, ENVIRONMENT_PROD)
    ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk))
)

delete window.__GLOBAL_REDUX_STATE__

/**
 * Microsoft Application Insights
 */
const { reactPlugin: appInsightsReactPlugin } = appInsightsFactory()

initializeI18nClientSide(initLanguage).then(() => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root')

  const helmetContext = {}

  if (initialJssState) {
    hydrateRoot(
      rootElement,
      <AppRoot
        appInsightsReactPlugin={appInsightsReactPlugin}
        axiosClientMap={axiosClientMap}
        graphQLClient={graphQLClient}
        helmetContext={helmetContext}
        path={window.location.pathname}
        reduxStore={reduxStore}
        Router={BrowserRouter}
        ssrState={initialJssState}
      />
    )
  } else {
    createRoot(rootElement).render(
      <AppRoot
        appInsightsReactPlugin={appInsightsReactPlugin}
        axiosClientMap={axiosClientMap}
        graphQLClient={graphQLClient}
        helmetContext={helmetContext}
        path={window.location.pathname}
        reduxStore={reduxStore}
        Router={BrowserRouter}
      />
    )
  }
})
