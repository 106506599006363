import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Text } from 'theme-ui'
import { getFilterStringByActiveSearchResultFilterObject } from '../../../../helpers/filterHelper'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { TextField } from '../../../../types/layoutService'
import Button from '../../../atoms/Button/Button'

interface PartSearchInvalidFilterProps {
  noResultsLabel: TextField
  invalidFilterCombinationLabel: TextField
  removeFiltersLabel: TextField
}

const PartSearchInvalidFilter: FC<PartSearchInvalidFilterProps> = ({
  noResultsLabel,
  invalidFilterCombinationLabel,
  removeFiltersLabel,
}) => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    history.push(`?${getFilterStringByActiveSearchResultFilterObject({})}`)
  }, [history])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        py: 7,
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Text
        data-t-id="no-part-results-invalid-filter-combination"
        variant="heading2"
        sx={{ maxWidth: '260px', mb: 3 }}
      >
        {getTextFieldValue(noResultsLabel)}
      </Text>

      <Text variant="bodyCompact" sx={{ maxWidth: '260px', color: 'gray.2', mb: 4 }}>
        {getTextFieldValue(invalidFilterCombinationLabel)}
      </Text>

      <Button variant="primary" onClick={handleClick}>
        {getTextFieldValue(removeFiltersLabel)}
      </Button>
    </Flex>
  )
}

export default PartSearchInvalidFilter
