import React, { FC } from 'react'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Datasource } from '../../../types/layoutService'
import ContentCard from '../../molecules/ContentCard/ContentCard'
import ContentSlider from '../ContentSlider/ContentSlider'
import { GenericContentSliderDatasource } from './GenericContentSlider'

const GenericContentSliderSkeleton: FC<
  Datasource<GenericContentSliderDatasource>
> = ({ datasource: { title } }) => (
  <ContentSlider title={getTextFieldValue(title)} slidesPerPage={6}>
    {[...Array(4)].map((_, i) => (
      <ContentCard.Skeleton key={i.toString()} />
    ))}
  </ContentSlider>
)

export default GenericContentSliderSkeleton
