import i18next from 'i18next'
import React from 'react'
import ActionsDoughnutChartWrapper from '../../ChartWrappers/ActionsDoughnutChartWrapper'
import { DoughnutProps } from '../../../../types/DoughnutChartTypes'
import {
  MY_FLEET_LIST_FILTER_OPTION_MAINTENANCE,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
} from '../../../../constants/dafCompanyVehicleConstants'
import { COLORS } from '../../../../constants/themeConstants'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'

interface MaintenanceStateChartProps {
  numberOfVehicles: number
  filteredActiveRMContracts: Vehicle[] | undefined
}
const MaintenanceStateChart = ({
  numberOfVehicles,
  filteredActiveRMContracts,
}: MaintenanceStateChartProps) => {
  const countDueInOneMonth = () =>
    filteredActiveRMContracts
      ? filteredActiveRMContracts?.reduce(
          (count, contract) =>
            contract?.applicableFilters?.maintenance?.includes(
              MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH
            )
              ? count + 1
              : count,
          0
        )
      : 0

  const countDueInOneToTwoMonths = () =>
    filteredActiveRMContracts
      ? filteredActiveRMContracts?.reduce(
          (count, contract) =>
            contract?.applicableFilters?.maintenance?.includes(
              MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS
            )
              ? count + 1
              : count,
          0
        )
      : 0
  const countOverdue = () =>
    filteredActiveRMContracts
      ? filteredActiveRMContracts?.reduce(
          (count, contract) =>
            contract?.applicableFilters?.maintenance?.includes(
              MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE
            )
              ? count + 1
              : count,
          0
        )
      : 0

  const maintenanceStateData: DoughnutProps = {
    total: countDueInOneMonth() + countDueInOneToTwoMonths() + countOverdue(),
    items: [
      {
        id: 'maintenanceDueinOneToTwoMonths',
        filterCategory: MY_FLEET_LIST_FILTER_OPTION_MAINTENANCE,
        filterValue: MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS,
        itemTotal: countDueInOneToTwoMonths(),
        color: COLORS.ORANGE,
      },
      {
        id: 'maintenanceDueinOneMonth',
        filterCategory: MY_FLEET_LIST_FILTER_OPTION_MAINTENANCE,
        filterValue: MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH,
        // || 1 is needed to render the pie
        itemTotal:
          countDueInOneMonth() + countDueInOneToTwoMonths() + countOverdue() === 0
            ? 1
            : countDueInOneMonth(),
        color: COLORS.PROMOTION,
      },
      {
        id: 'maintenanceOverdue',
        filterCategory: MY_FLEET_LIST_FILTER_OPTION_MAINTENANCE,
        filterValue: MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
        itemTotal: countOverdue(),
        color: COLORS.ALERT,
      },
    ],
    legenda: [
      {
        id: 'maintenanceDueinOneToTwoMonths',
        name: i18next.t('actionsStatisticsLabels.dueInOneToTwoMonthsLabel'),
        itemTotal: countDueInOneToTwoMonths(),
        color: COLORS.ORANGE,
      },
      {
        id: 'maintenanceDueinOneMonth',
        name: i18next.t('actionsStatisticsLabels.dueInOneMonthLabel'),
        itemTotal: countDueInOneMonth(),
        color: COLORS.PROMOTION,
      },
      {
        id: 'maintenanceOverdue',
        name: i18next.t('actionsStatisticsLabels.overdueLabel'),
        itemTotal: countOverdue(),
        color: COLORS.ALERT,
      },
    ],
  }
  return (
    <ActionsDoughnutChartWrapper
      chartTitle={i18next.t('actionsStatisticsLabels.maintenanceStateTitle')}
      chartData={maintenanceStateData}
      numberOfVehicles={numberOfVehicles}
    />
  )
}

export default MaintenanceStateChart
