import { useTheme } from '@emotion/react'
import React, { FC, FormEvent, useCallback, useMemo, useRef, useState } from 'react'
import { Text } from 'theme-ui'
import { URL_QUERY_STRING_PARAM } from '../../../constants/urlConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { resolveUrl } from '../../../helpers/linkFieldHelper'
import { LinkField, TextField } from '../../../types/layoutService'
import SearchBarForm from '../SearchBar/SearchBarForm'
import SearchBarInput from '../SearchBar/SearchBarInput'
import SearchBarControls from '../SearchBar/SearchBarControls'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { navigateClient } from '../../../helpers/dom'

interface FindNearestDealerFormProps {
  title: TextField
  dealerLocatorPage: LinkField
  inputPlaceholder: TextField
}

const FindNearestDealerForm: FC<FindNearestDealerFormProps> = ({
  title,
  inputPlaceholder,
  dealerLocatorPage,
}) => {
  const { breakpoints } = useTheme()

  const inputRef = useRef<HTMLInputElement>(null)
  const [searchString, setSearchString] = useState('')

  const trimmedSearchString = useMemo(() => searchString.trim(), [searchString])

  const submit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_FUNCTION,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.DEALER_FINDER,
        search_query: trimmedSearchString,
        page_type: '',
      })

      navigateClient(
        `${resolveUrl(
          dealerLocatorPage
        )}?${URL_QUERY_STRING_PARAM}=${trimmedSearchString}`
      )
    },
    [dealerLocatorPage, trimmedSearchString]
  )

  return (
    <>
      <Text as="h3" variant="heading3" mb={3}>
        {getTextFieldValue(title)}
      </Text>

      <SearchBarForm
        action={resolveUrl(dealerLocatorPage)}
        onSubmit={submit}
        sx={{
          maxWidth: breakpoints[1],
          width: '100%',
        }}
      >
        <SearchBarInput
          ref={inputRef}
          name={URL_QUERY_STRING_PARAM}
          placeholder={getTextFieldValue(inputPlaceholder)}
          autoComplete="off"
          value={searchString}
          onChange={(event) => setSearchString(event?.target.value)}
        />

        <SearchBarControls
          inputRef={inputRef}
          currentInput={searchString}
          onReset={() => setSearchString('')}
          submitButtonTestId="submit-button-nearest-dealer-form"
        />
      </SearchBarForm>
    </>
  )
}

export default FindNearestDealerForm
