import React, { FC } from 'react'
import Link from '../../atoms/Link/Link'
import { SiteThemeLogoImage } from '../../../types/sitecoreContextProps'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { TRP_THEME } from '../../../constants/themeConstants'
import { Box } from 'theme-ui'

export interface MainNavigationLogoProps {
  logoImage?: SiteThemeLogoImage
}

const MainNavigationLogo: FC<MainNavigationLogoProps> = ({ logoImage }) => {
  const {
    site: { theme },
  } = useSitecoreContext()

  if (!logoImage) return null

  return (
    <Link
      href="/"
      sx={{
        display: 'inline-block',
        mr: 4,
      }}
    >
      <Box
        sx={{
          img: {
            display: 'block',
            height:
              theme?.name === TRP_THEME
                ? ['30px', null, '40px']
                : ['18px', null, '24px'],
            width: 'auto',
          },
        }}
      >
        <img
          src={logoImage.src}
          alt={logoImage.alt || logoImage.title}
          height={18}
          width={66}
        />
      </Box>
    </Link>
  )
}
export default MainNavigationLogo
