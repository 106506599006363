import React, { FC } from 'react'
import { Box, Flex } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, ImageWithFocalPointField } from '../../../types/layoutService'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'

interface BasicImageDatasource {
  image: ImageWithFocalPointField
}

const BasicImage: FC<Datasource<BasicImageDatasource>> = ({
  datasource: { image },
}) => (
  <Flex sx={{ flexWrap: 'wrap', marginBottom: [4, 4, 7] }}>
    {!!image?.src && (
      <Box
        sx={{
          position: 'relative',
          minHeight: '350px',
          width: '100%',
        }}
      >
        <Box
          as="figure"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <SitecoreImage imageField={image} />
        </Box>
      </Box>
    )}
  </Flex>
)

export default withData(BasicImage)
