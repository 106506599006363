import React, { FC } from 'react'
import { Flex, FlexProps } from 'theme-ui'
import OriginalPrice from '../../atoms/OriginalPrice/OriginalPrice'
import Price from '../../atoms/Price/Price'
import Spinner from '../../atoms/Spinner/Spinner'
import { PricingProps } from '../../../types/salesProps'
import useDealerPartSalesInformation from '../../../hooks/useDealerPartSalesInformation'
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter'

interface DealerPartPriceProps extends Omit<FlexProps, 'css'> {
  partNumber?: string
}

const DealerPartPrice: FC<PricingProps & DealerPartPriceProps> = ({
  partNumber,
  variant,
  sx,
  ...flexProps
}) => {
  const [priceInformation, fetching] = useDealerPartSalesInformation(partNumber)

  const currencyFormatter = useCurrencyFormatter(priceInformation?.price)

  return (
    <Flex sx={{ flexDirection: 'column', ...sx }} {...flexProps}>
      {fetching && <Spinner size={3} />}

      {!fetching && priceInformation?.price && (
        <>
          <Price
            variant={variant}
            price={currencyFormatter(priceInformation.price?.netUnitPrice)}
          />

          {priceInformation.price?.netUnitPrice &&
            priceInformation.price?.retailUnitPrice &&
            priceInformation.price.netUnitPrice !==
              priceInformation.price.retailUnitPrice && (
              <OriginalPrice
                variant={variant}
                price={currencyFormatter(priceInformation.price?.retailUnitPrice)}
              />
            )}
        </>
      )}
    </Flex>
  )
}

export default DealerPartPrice
