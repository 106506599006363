import React, { FC, MouseEvent, useCallback, useState } from 'react'
import { Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { MATCH_TYPE_SUPERSESSION } from '../../../constants/searchConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { MatchMetaDataProps } from '../../../types/dafResponseProps'
import { TextField } from '../../../types/layoutService'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import InformationIcon from '../../atoms/InformationIcon/InformationIcon'
import SidePanel from '../../organisms/SidePanel/SidePanel'
import SidePanelBody from '../../organisms/SidePanel/SidePanelBody'

interface SupersessionProps {
  partDescription: string
  partNumber?: string
  matchMetaData?: MatchMetaDataProps
  sidePanelText?: TextField
}

const Supersession: FC<SupersessionProps> = ({
  partDescription,
  partNumber,
  matchMetaData,
  sidePanelText,
}) => {
  const [sidePanel, setSidePanel] = useState(false)
  const [breakpointM] = useBreakpoints('m')

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()

      setSidePanel(true)

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.PRODUCT_SUPERSESSION,
        action:
          DATA_LAYER.CUSTOM_DIMENSION.ACTION.SHOW_PRODUCT_SUPER_SESSION_INFORMATION,
        category_name: '',
        sub_category_name: '',
        part_number: partNumber,
      })
    },
    [partNumber]
  )

  if (matchMetaData?.type !== MATCH_TYPE_SUPERSESSION) return null

  if (!sidePanelText) return null

  if (!breakpointM) return null

  return (
    <>
      <ClickableWrapper
        onClick={onClick}
        sx={{
          display: 'block',
          pl: 2,
        }}
      >
        <InformationIcon />
      </ClickableWrapper>

      <SidePanel
        active={sidePanel}
        onClose={() => setSidePanel(false)}
        title={partDescription}
      >
        <SidePanelBody>
          <Text variant="bodyContent" color="gray.2">
            {`${getTextFieldValue(sidePanelText)} ${matchMetaData.basedOn} `}
            &rarr;
            {` ${partNumber}`}
          </Text>
        </SidePanelBody>
      </SidePanel>
    </>
  )
}

export default Supersession
