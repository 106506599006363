import { Box } from 'theme-ui'
import { Field } from 'react-final-form'
import React, { ChangeEvent } from 'react'
import Radio from '../inputs/Radio/Radio'
import FormInputLabel from '../FormControls/FormInputLabel'

interface InputRadioListProps {
  legend: string
  name: string
  options: { value: string; label: string }[]
  checked?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const InputRadioList = ({
  legend,
  name,
  options,
  checked = '',
  onChange,
}: InputRadioListProps) => (
  <Box as="fieldset" sx={{ border: 0, padding: 0, marginBlockEnd: 8 }}>
    <FormInputLabel as="legend">{legend}</FormInputLabel>

    {options.map((option) => (
      <Field
        name={name}
        type="radio"
        key={option.value}
        value={option.value}
        initialValue={checked}
      >
        {({ input }) => (
          <Radio
            {...input}
            label={option.label}
            onChange={(e) => {
              if (onChange) onChange(e)
              input.onChange(e)
            }}
          />
        )}
      </Field>
    ))}
  </Box>
)

export default InputRadioList
