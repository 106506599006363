import React, { useCallback, useContext, useEffect, useState } from 'react'
import i18next from 'i18next'
import { Flex } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import PartList from './PartList/PartList'
import { URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM } from '../../../../constants/urlConstants'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { COLORS } from '../../../../constants/themeConstants'
import { RootState } from '../../../../reducers'
import useCommerceApiPostService from '../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import {
  deleteOrderItems,
  DeleteOrderItemsRequestProps,
} from '../../../../services/rest/ecommerce/order/order-items'
import {
  CommerceMessage,
  CommerceOrderItem,
  CommerceResponse,
} from '../../../../types/commerceApi'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import {
  addOrReplaceOrderItem,
  createOrderMutation,
  markOrderMutationFinished,
  removeOrderItems,
} from '../../../../actions/shoppingBasket/wizard'
import { refetchShoppingBasketSidepanelSummary } from '../../../../actions/shoppingBasket/sidePanelActions'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import Spinner from '../../../atoms/Spinner/Spinner'
import { registerModalNotification } from '../../../../actions/notificationActions'
import { getTotalOrderAmount } from '../../../../helpers/priceHelper'
import { getEnglishCategoryName } from '../../../../helpers/categoryHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'

const OrderOverview = () => {
  const { step, datasource } = useShoppingBasket()
  const [deleting, response, executeDeleteOrderItems] = useCommerceApiPostService<
    DeleteOrderItemsRequestProps,
    CommerceResponse<CommerceOrderItem[]>
  >(deleteOrderItems)

  const onContinueShippingClick = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
      call_to_action_name: i18next.t('shoppingLabels.continueShopping'),
      call_to_action_type: DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
      target_url: '/',
    })
  }, [])

  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { orderNumber } = useContext(ActiveUserStateContext)
  const { categories } = useContext(CategoriesContext)
  const [isLoading, setIsLoading] = useState(false)
  const hasRemovableItemsInBasket = order?.items?.some((item) => item.quantity > 0)

  const dispatch = useDispatch()

  const handleRemoveAllProducts = () => {
    dispatch(createOrderMutation())
    dispatch(refetchShoppingBasketSidepanelSummary(true))

    pushToDataLayer({
      event: DATA_LAYER.EVENT_NAME.REMOVE_ALL_FROM_CART,
    })
    pushToDataLayer({
      event: DATA_LAYER.EVENT_NAME.REMOVE_FROM_CART,
      ecommerce: {
        currency: getTotalOrderAmount(order).currency,
        value: getTotalOrderAmount(order).value,
        items: order?.items?.map((item) => ({
          item_id: item.partNumber,
          item_name: item.englishDescription,
          item_brand: item.brand?.description,
          item_category: getEnglishCategoryName(item.category, categories),
          item_list_name: DATA_LAYER.EVENT_NAME.SHOPPING_BASKET,
          doa_product: item.type === 'DealerOwnAssortment',
          quantity: item.quantity,
          price: item.price?.netUnitPrice,
        })),
      },
    })

    executeDeleteOrderItems({
      orderNumber,
      items: order?.items
        ?.filter((item) => item.quantity > 0)
        .map((item) => item.partNumber),
    })
  }

  const onResult = useCallback(
    (commerceResponse: CommerceOrderItem[] | null) => {
      // Get messages where severity is 'Success' and result parts from the response
      // to identify missing parts in the response
      const messageParts =
        response?.data?.messages
          ?.filter((message) => message.severity === 'Success')
          ?.map((message) => message.args?.[0]) ?? []
      const resultParts =
        response?.data?.result?.map((result) => result.partNumber) ?? []

      // Logic for identifying missing parts in the response
      // if a part is missing in the response it is not an MDI part
      // and should be removed from the order with dispatch
      let missingParts = []

      if (resultParts.length > 0) {
        missingParts = messageParts.filter(
          (part) => part && !resultParts.includes(part)
        )
      } else {
        missingParts = messageParts
      }

      // Ensure part is defined before dispatching removeOrderItems
      missingParts.forEach((part) => {
        if (typeof part === 'string') {
          dispatch(removeOrderItems([part]))
        }
      })

      // Process commerceResponse items with suggestionInformation
      // and add or replace them in the order so they will nog be
      // removed when the order is refreshed
      if (commerceResponse === null) return

      commerceResponse.forEach((item) => {
        if (item?.suggestionInformation) {
          dispatch(addOrReplaceOrderItem(item))
        }
      })
    },
    [dispatch, response]
  )

  const onRemoveSuccess = useCallback(
    (commerceMessage: CommerceMessage) => {
      if (commerceMessage.severity === 'Error' && commerceMessage.message) {
        dispatch(registerModalNotification('Error', commerceMessage.message))
      }

      dispatch(refetchShoppingBasketSidepanelSummary(true))
      dispatch(markOrderMutationFinished())
    },
    [dispatch]
  )

  useCommerceApiResponse<CommerceOrderItem[]>({
    response,
    onResult,
    onMessage: onRemoveSuccess,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (result) => result as CommerceOrderItem[],
  })

  useEffect(() => {
    setIsLoading(deleting)
  }, [deleting])

  if (step !== URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM) return null

  return (
    <div>
      {isLoading ? <Spinner size={4} /> : <PartList />}
      <Flex
        sx={{
          justifyContent: 'space-between',
          marginBlock: 4,
        }}
      >
        <div>
          <Button
            as="a"
            href={datasource.continueShoppingLink?.url}
            variant="outline"
            icon={ICONS.ARROW_LEFT}
            iconSize={4}
            data-t-id="shopping-basket-continue-shopping"
            onClick={onContinueShippingClick}
            sx={{
              display: ['none', null, null, null, 'flex'],
            }}
          >
            {datasource.continueShoppingLink?.text}
          </Button>
        </div>

        {hasRemovableItemsInBasket && (
          <Button
            color={COLORS.DAF_BLUE}
            variant="infinite"
            icon={ICONS.TRASH}
            iconPosition="end"
            iconSize={4}
            data-t-id="shopping-basket-remove-all-products"
            onClick={handleRemoveAllProducts}
            disabled={!order?.isEditAllowed}
          >
            {i18next.t('shoppingLabels.removeAllProducts')}
          </Button>
        )}
      </Flex>
    </div>
  )
}

export default OrderOverview
