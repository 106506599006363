import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import { SkeletonComponent } from '../../../types/layoutProps'
import Skeleton from '../../atoms/Skeleton/Skeleton'

interface CardTitleProps {
  title?: string
}

const CardTitle: FC<CardTitleProps> & SkeletonComponent = ({ title }) => {
  if (!title) return null

  return (
    <Text
      as="h4"
      variant="heading4"
      sx={{
        mt: 2,
        color: 'text',
        fontWeight: 'bold',
      }}
    >
      {title}
    </Text>
  )
}

CardTitle.Skeleton = () => (
  <Skeleton>
    <Box
      sx={{
        mt: 2,
        width: '100%',
        height: '14px',
      }}
    />
  </Skeleton>
)

export default CardTitle
