import React, { Fragment } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'

interface DefinitionsListProps extends Omit<BoxProps, 'css'> {
  definitions: DefinitionListProps[]
}

interface DefinitionListProps {
  term: string
  detail: string | undefined
}

const DefinitionList = ({ definitions, sx }: DefinitionsListProps) => (
  <Box as="dl" sx={{ ...sx }}>
    {definitions.map(({ term, detail }) => (
      <Fragment key={term}>
        <Text as="dt" color={COLORS.DARK} sx={{ fontWeight: 'bold', fontSize: 1 }}>
          {term}
        </Text>
        <Text as="dd" color={COLORS.GRAY} sx={{ fontSize: 1 }}>
          {showDashWhenEmptyValue(detail)}
        </Text>
      </Fragment>
    ))}
  </Box>
)

export default DefinitionList
