import { SortDirection } from '../components/organisms/SearchResults/PartSearch/Table/PartSearchTable'
import {
  CLASS_PROPERTY_TYPE_BOOLEAN,
  CLASS_PROPERTY_TYPE_BOOLEAN_TRUE,
  CLASS_PROPERTY_TYPE_DECIMAL,
  CLASS_PROPERTY_TYPE_LIST,
  CLASS_PROPERTY_TYPE_NUMBER,
  CLASS_PROPERTY_TYPE_STRING,
  COLUMN_ID_BRAND,
  COLUMN_ID_PART_NUMBER,
  COLUMN_ID_SUPPLIER_PART_NUMBER,
  DEFAULT_PROPERTIES_TO_DISPLAY_COUNT,
  DEFAULT_SORT_DIRECTION,
  SORT_DIRECTION_ASCENDING,
  SORT_DIRECTION_DESCENDING,
} from '../constants/searchConstants'
import { PartFulFilled } from '../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { Property } from '../hooks/services/graphql/useClassInformationListService'
import { Attribute, Part } from '../types/dafResponseProps'

export const getDisplayProperties = (properties: Property[], limit?: number) =>
  (properties &&
    !!properties.length &&
    [...properties] // copy array before sorting
      .sort((a, b) => a.priority - b.priority)
      .slice(0, limit || DEFAULT_PROPERTIES_TO_DISPLAY_COUNT)) ||
  []

export const getNextSortDirection = (currentSortDirection: SortDirection) => {
  if (currentSortDirection === DEFAULT_SORT_DIRECTION) {
    return SORT_DIRECTION_ASCENDING
  }
  if (currentSortDirection === SORT_DIRECTION_ASCENDING) {
    return SORT_DIRECTION_DESCENDING
  }
  return DEFAULT_SORT_DIRECTION
}

const getPartAttributes = (
  part: Part,
  attributeId: string
): Attribute[] | undefined =>
  part.specification?.partAttributes?.filter(
    ({ id }) => id.toString() === attributeId
  )

const getPartAttribute = (
  part?: Part,
  attributeId?: string
): Attribute | undefined =>
  (part && attributeId && getPartAttributes(part, attributeId)?.[0]) || undefined

const parsePartStringAttribute = (attribute?: Attribute): string =>
  attribute?.value || ''

const parsePartBooleanAttribute = (attribute?: Attribute): boolean =>
  attribute?.value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE

const parsePartNumberAttribute = (attribute?: Attribute): number =>
  Number(attribute?.value.replace(',', '')) || -1

const compareString = (
  stringA: string,
  stringB: string,
  orderMultiplier: number
) => {
  if (stringA === '' || stringA === null) return 1
  if (stringB === '' || stringB === null) return -1

  return (
    stringA.localeCompare(
      stringB,
      undefined,
      { numeric: true, sensitivity: 'base' } // set numeric & sensitivity for natural sorting (accept numbers & case insensitive)
    ) * orderMultiplier
  )
}

const propertySorter = (
  type: Property['type'],
  parts: PartFulFilled[],
  sortColumnId: string,
  orderMultiplier: number
): PartFulFilled[] => {
  switch (type) {
    case CLASS_PROPERTY_TYPE_STRING:
    case CLASS_PROPERTY_TYPE_LIST:
      return parts.sort((a, b) => {
        const stringA = parsePartStringAttribute(
          getPartAttribute(a?.part, sortColumnId)
        )
        const stringB = parsePartStringAttribute(
          getPartAttribute(b?.part, sortColumnId)
        )

        return compareString(stringA, stringB, orderMultiplier)
      })

    case CLASS_PROPERTY_TYPE_NUMBER:
    case CLASS_PROPERTY_TYPE_DECIMAL:
      return parts.sort((a, b) => {
        const attributeA = getPartAttribute(a?.part, sortColumnId)
        const attributeB = getPartAttribute(b?.part, sortColumnId)

        if (!attributeA?.value) return 1
        if (!attributeB?.value) return -1

        const numberA = parsePartNumberAttribute(attributeA)
        const numberB = parsePartNumberAttribute(attributeB)

        return (numberA - numberB) * orderMultiplier
      })

    case CLASS_PROPERTY_TYPE_BOOLEAN:
      return parts.sort((a, b) => {
        const attributeA = getPartAttribute(a?.part, sortColumnId)
        const attributeB = getPartAttribute(b?.part, sortColumnId)

        if (!attributeA?.value) return 1
        if (!attributeB?.value) return -1

        const booleanA = parsePartBooleanAttribute(attributeA)
        const booleanB = parsePartBooleanAttribute(attributeB)

        return (Number(booleanA) - Number(booleanB)) * orderMultiplier
      })

    default:
      return parts
  }
}

export const partSorter = (
  properties: Property[],
  parts: PartFulFilled[],
  columnId?: string,
  sortDirection?: SortDirection
) => {
  const orderMultiplier = sortDirection === SORT_DIRECTION_ASCENDING ? 1 : -1
  if (!columnId) return parts

  switch (columnId) {
    case COLUMN_ID_PART_NUMBER:
      return parts.sort((a, b) =>
        compareString(
          a.searchPart.partNumber,
          b.searchPart.partNumber,
          orderMultiplier
        )
      )

    case COLUMN_ID_SUPPLIER_PART_NUMBER:
      return parts.sort((a, b) =>
        compareString(
          a.part?.supplierPartNumber || '',
          b.part?.supplierPartNumber || '',
          orderMultiplier
        )
      )

    case COLUMN_ID_BRAND:
      // brand sorter
      return parts.sort((a, b) =>
        compareString(
          a.part?.brand?.description || '',
          b.part?.brand?.description || '',
          orderMultiplier
        )
      )

    default: {
      const type = properties.find(
        (property) => property.id.toString() === columnId
      )?.type

      return type ? propertySorter(type, parts, columnId, orderMultiplier) : parts
    }
  }
}

export const groupPartsByClassCode = (
  parts?: PartFulFilled[]
): Record<string, PartFulFilled[]> => {
  if (parts) {
    return parts.reduce<Record<string, PartFulFilled[]>>((prev, part) => {
      const classCode = part.searchPart.classCode

      return {
        ...prev,
        [classCode]: [...(prev?.[classCode] || []), part],
      }
    }, {})
  }
  return {}
}
