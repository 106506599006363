import { AxiosResponse } from 'axios'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceRecipient, CommerceResponse } from '../../../../types/commerceApi'

export type OrderRecipientEmailType = 'To' | 'CC'

export interface OrderRecipientsRequestProps {
  orderNumber?: string
  recipient?: string
}

export interface DeleteRecipientsRequestProps {
  orderNumber?: string
  field: number
  recipient?: string
}

export const postOrderRecipients: RequestFunction<
  OrderRecipientsRequestProps,
  CommerceResponse<CommerceRecipient>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceRecipient>>> =>
  client.post(
    `/order-recipients/${props.orderNumber || ''}`,
    { recipient: props.recipient || '' },
    {
      ...createRequestConfig(
        context.user,
        (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
        {
          customerCompanyId: context.customerCompanyId,
          supplierCompanyId: context.supplierCompanyId,
          isImpersonated: !!context.isImpersonated,
        }
      ),
      cancelToken: config.cancelToken,
    }
  )

export const deleteOrderRecipients: RequestFunction<
  DeleteRecipientsRequestProps,
  CommerceResponse<null>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<null>>> =>
  client.delete(`order-recipients/${props.orderNumber || ''}`, {
    data: { recipient: props.recipient || '', field: props.field },
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
      }
    ),
    cancelToken: config.cancelToken,
  })
