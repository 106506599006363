import React from 'react'
import { Datasource, TextField } from '../../../types/layoutService'
import { RewardsProvider } from '../../../providers/RewardsProvider'
import RewardsOverview from '../RewardsOverview/RewardsOverview'
import i18next from 'i18next'
import SearchResultsBackToTopWrapper from '../SearchResults/BackToTopButton/SearchResultsBackToTopWrapper'
import withData from '../../../enhancers/withData'
import RewardsRedeemRewardSidepanel, {
  RewardsSidepanelDatasource,
} from '../../molecules/RewardsRedeemRewardSidepanel/RewardsRedeemRewardSidepanel'
import { LoyaltyProvider } from '../../../providers/LoyaltyProvider'

export interface RewardsOverviewDatasourceProps {
  filterTitle: TextField
  availableForMeLabel: TextField
  creditMerchandiseVoucherLabel: TextField
  productMerchandiseVoucherLabel: TextField
  noResultsTitle: TextField
  noResultsMessage: TextField
  redeemSidepanel: {
    targetItem: RewardsSidepanelDatasource
  }
}

const RewardsOverviewWrapper = ({
  datasource,
}: Datasource<RewardsOverviewDatasourceProps>) => {
  return (
    <RewardsProvider>
      <SearchResultsBackToTopWrapper
        backToTopLabel={i18next.t('paginationLabels.backToTop')}
      >
        <RewardsOverview datasource={datasource} />
      </SearchResultsBackToTopWrapper>
      <LoyaltyProvider>
        <RewardsRedeemRewardSidepanel
          datasource={datasource.redeemSidepanel.targetItem}
        />
      </LoyaltyProvider>
    </RewardsProvider>
  )
}
export default withData(RewardsOverviewWrapper)
