import React, { Children, cloneElement, FC, ReactElement, ReactNode } from 'react'

interface HotjarSuppressProps {
  children: ReactNode
  condition?: boolean
}

const HotjarSuppress: FC<HotjarSuppressProps> = ({ children, condition = true }) => {
  const elements = Children.toArray(children)

  if (!elements.length || !condition) return <>{children}</>

  if (elements.length === 1) {
    return cloneElement(elements[0] as ReactElement, { 'data-hj-suppress': true })
  }

  return (
    <>
      {elements.map((child) =>
        cloneElement(child as ReactElement, { 'data-hj-suppress': true })
      )}
    </>
  )
}

export default HotjarSuppress
