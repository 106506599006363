import React, { FC } from 'react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import withData from '../../../enhancers/withData'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import {
  getMultiListFieldItems,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import { resolveUrl } from '../../../helpers/linkFieldHelper'
import { Datasource, MultilistField } from '../../../types/layoutService'
import Link from '../../atoms/Link/Link'
import CallToActionContent from '../../molecules/CallToActionContent/CallToActionContent'
import CallToActionImage from '../../molecules/CallToActionImage/CallToActionImage'
import { CallToActionWithImageProps } from '../CallToActionGridWithImage/CallToActionGridWithImage'
import { Box } from 'theme-ui'

interface ArticleHighlightsProps {
  callToActionItems?: MultilistField<CallToActionWithImageProps>
}

const ArticleHighlights: FC<Datasource<ArticleHighlightsProps>> = ({
  datasource: { callToActionItems: rawCallToActionItems },
}) => {
  const callToActionItems = getMultiListFieldItems(rawCallToActionItems)
  if (!callToActionItems?.length) return null

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(min(300px,100%),1fr))',
        gap: 4,
      }}
    >
      {callToActionItems.slice(0, 4).map(({ title, text, button, image }, i) => (
        <Link
          key={i.toString()}
          href={resolveUrl(button)}
          target={button?.target}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            color: 'inherit',
          }}
          onClick={() => {
            pushToDataLayer({
              [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
              call_to_action_name: getTextFieldValue(title),
              call_to_action_type:
                DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.ARTICLE,
              target_url: button?.url || '',
            })
          }}
        >
          <CallToActionImage imageField={image} />

          <CallToActionContent
            {...{ title, text, button }}
            alignButtonToBottom
            wrapLink={{ boolValue: true, value: true }}
          />
        </Link>
      ))}
    </Box>
  )
}

export default withData(ArticleHighlights)
