import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Form, FormSpy } from 'react-final-form'
import { FormApi } from 'final-form'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import SidePanel from '../SidePanel'
import SidePanelFooter from '../SidePanelFooter'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import Spinner from '../../../atoms/Spinner/Spinner'
import EditServicePlanFormSubmitButton from '../../../molecules/Forms/EditServicePlanForm/EditServicePlanFormSubmitButton'
import { toggleEditServicePlanPanel } from '../../../../actions/dafCompanyVehicleActions'
import {
  EDIT_SERVICE_PLAN_ADD_SERVICE_PARTNERS,
  EDIT_SERVICE_PLAN_ADD_SERVICES,
  EDIT_SERVICE_PLAN_EARLY_TERMINATION,
  EDIT_SERVICE_PLAN_EXTEND_SERVICES,
} from '../../../../constants/multiSupportFormConstants'
import SidePanelClickableSection from '../SidePanelClickableSection/SidePanelClickableSection'
import { COLORS } from '../../../../constants/themeConstants'
import sendEditRmServicePlanActions, {
  SendEditRmServicePlanActionsPayload,
} from '../../../../services/rest/ecommerce/sendEditRmServicePlanActions'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { UserContext } from '../../../../providers/UserProvider'
import Button from '../../../atoms/Button/Button'
import renderHTML from '../../../../helpers/renderHTMLHelper'
import ExtendServicePlanForm from './forms/ExtendServicePlanForm'
import AddServicePartnersForm from './forms/AddServicePartnersForm'
import { Dealer } from '../../../../types/dealerServiceTypes'
import {
  SERVICEPLAN_CONTRACT_STATE_ACTIVE,
  SERVICEPLAN_CONTRACT_STATE_EXPIRING,
} from '../../../../constants/dafCompanyVehicleConstants'
import EarlyTerminationForm from './forms/EarlyTerminationForm'
import { RootState } from '../../../../reducers'
import { getVehicleLabel } from '../../../../helpers/vehicleHelper'
import AddServicesForm from './forms/AddServicesForm'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { ServicesContext } from '../../../../providers/ServicesProvider'
import SidePanelMain from '../SidePanelMain'
import SidePanelPaddedBox from '../SidePanelPaddedBox'
import InputRadioList from '../../../atoms/FormFields/InputRadioList'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'

interface EditServicePlanFormPanelProps {
  vehicle: VehicleDetailResponse | undefined
  messageOnSuccess?: string
}

type FormData = {
  type?: string
  extendDuration?: string
  selectedServicePartners?: string
  remarks?: string
  addServicePartnersRemarks?: string
  terminationDate?: string
  specificReason?: string
  earlyTerminationReason?: string
  [EDIT_SERVICE_PLAN_ADD_SERVICES]?: string[]
  addService?: string
}

function EditServicePlanFormPanel({
  vehicle,
  messageOnSuccess,
}: EditServicePlanFormPanelProps) {
  const { servicesData } = useContext(ServicesContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }
  const dispatch = useDispatch()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { token } = axios.CancelToken.source()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPristine, setIsPristine] = useState(true)
  const [formSuccessfullySend, setFormSuccessfullySend] = useState(false)
  const formApiRef = useRef<FormApi<FormData> | null>(null)
  const [radioValue, setRadioValue] = useState(
    EDIT_SERVICE_PLAN_ADD_SERVICE_PARTNERS
  )

  const { editServicePlanFormPanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const [hasFormErrors, setHasFormErrors] = useState(false)
  const [selectedServicePartners, setSelectedServicePartners] = useState<Dealer[]>(
    []
  )
  const { featureToggles } = useSitecoreContext()
  const postEditRmServicePlanActions = async (
    chassisNumber: string | undefined,
    payload: SendEditRmServicePlanActionsPayload
  ) => {
    const response = await sendEditRmServicePlanActions(
      dafVehicleDetailQueryParams,
      chassisNumber,
      payload,
      client,
      token,
      user
    )

    return response.data
  }

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (payload: SendEditRmServicePlanActionsPayload) =>
      postEditRmServicePlanActions(
        vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber,
        payload
      ),

    onSuccess: () => {
      setFormSuccessfullySend(true)
    },
  })

  const shouldShowAddServicePartnersForm = useCallback(
    () =>
      typeof featureToggles?.addServicePartners === 'undefined' ||
      (!!featureToggles?.addServicePartners &&
        ((vehicle?.rmContract?.mostRecentContractForVehicle === true &&
          vehicle?.rmContract?.servicePlan?.status.contractState ===
            SERVICEPLAN_CONTRACT_STATE_EXPIRING) ||
          vehicle?.rmContract?.servicePlan?.status.contractState ===
            SERVICEPLAN_CONTRACT_STATE_ACTIVE)),
    [vehicle, featureToggles]
  )

  const shouldShowAddServicesOption = () =>
    shouldShowAddServicePartnersForm() &&
    servicesData?.some(
      (service) =>
        service.packageAbbreviation ===
        vehicle?.rmContract?.servicePlan.coverageSummary.contractPackageAbbreviation
    )

  const shouldShowEarlyTerminationForm = useCallback(
    () =>
      (vehicle?.rmContract?.mostRecentContractForVehicle === true &&
        vehicle?.rmContract?.servicePlan?.status.contractState ===
          SERVICEPLAN_CONTRACT_STATE_EXPIRING) ||
      vehicle?.rmContract?.servicePlan?.status.contractState ===
        SERVICEPLAN_CONTRACT_STATE_ACTIVE,
    [vehicle]
  )

  useEffect(() => {
    if (!shouldShowAddServicePartnersForm()) {
      setRadioValue(EDIT_SERVICE_PLAN_EXTEND_SERVICES)
    }
  }, [shouldShowAddServicePartnersForm])

  const defaultPayload = {
    type: null,
    endDate: null,
    mileage: null,
    extendDuration: null,
    remarks: null,
    earlyTerminationReason: null,
    specificReason: null,
    terminationDate: null,
    selectedAdditionalServices: null,
    additionalServicePartners: null,
  }
  const onSubmit = async (values: FormData | undefined) => {
    if (values) {
      if (radioValue === EDIT_SERVICE_PLAN_EXTEND_SERVICES) {
        const payload = {
          ...defaultPayload,
          type: 2,
          extendDuration: values.extendDuration ? +values.extendDuration : null,
          remarks: values.remarks ?? null,
        }
        mutate(payload)
      }

      if (radioValue === EDIT_SERVICE_PLAN_ADD_SERVICE_PARTNERS) {
        const payload = {
          ...defaultPayload,
          type: 1,
          additionalServicePartners: selectedServicePartners?.map(
            (partner) => partner.code
          ),
          remarks: values.addServicePartnersRemarks ?? null,
        }
        mutate(payload)
      }

      if (radioValue === EDIT_SERVICE_PLAN_ADD_SERVICES) {
        const payload = {
          ...defaultPayload,
          type: 3,
          selectedAdditionalServices: values[EDIT_SERVICE_PLAN_ADD_SERVICES] || null,
          remarks: values.remarks ?? null,
        }
        mutate(payload)
      }

      if (radioValue === EDIT_SERVICE_PLAN_EARLY_TERMINATION) {
        const payload = {
          ...defaultPayload,
          type: 4,
          earlyTerminationReason: values.earlyTerminationReason ?? null,
          specificReason: values.specificReason ?? null,
          terminationDate: values.terminationDate ?? null,
          remarks: values.remarks ?? null,
        }
        mutate(payload)
      }
    }
  }

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    handleClearForm()
    setRadioValue(event.target.value)
  }

  const handleSelectServicePartner = (partner: Dealer) => {
    if (
      selectedServicePartners?.some(
        (selectedServicePartner) => selectedServicePartner.code === partner.code
      )
    ) {
      // already added
      return
    }
    const updated = selectedServicePartners.concat(partner)
    setSelectedServicePartners(updated)
  }

  const handleRemoveServicePartner = (partner: Dealer) => {
    const updatedPartners = selectedServicePartners.filter(
      (selectedServicePartner) => selectedServicePartner.code !== partner.code
    )
    setSelectedServicePartners(updatedPartners)
  }

  const handleClearForm = () => {
    if (formApiRef.current) {
      formApiRef.current.reset()
    }
    setFormSuccessfullySend(false)

    setSelectedServicePartners([])
  }

  const handleCloseForm = () => {
    dispatch(toggleEditServicePlanPanel({ isActive: false }))
    handleClearForm()
  }

  return (
    <SidePanel
      position="right"
      active={editServicePlanFormPanelState}
      onClose={() => handleCloseForm()}
      disableOutsideClick
      title={i18next.t('formLabels.editServicePlan')}
    >
      <SidePanelMain>
        {!isLoading && vehicle && !formSuccessfullySend && (
          <>
            <SidePanelPaddedBox>
              {`${i18next.t('formLabels.editServicePlan')} ${i18next.t(
                'formLabels.forVehicle'
              )} `}
              <Text
                as="span"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {getVehicleLabel(vehicle.vehicleDetail)}
              </Text>
            </SidePanelPaddedBox>
            <SidePanelClickableSection
              initialActive
              label={i18next.t('formLabels.whatEditRequestDoYouWant')}
            >
              <Form
                onSubmit={() => undefined}
                render={() => (
                  <InputRadioList
                    legend=""
                    name="editServicePlan"
                    checked={radioValue}
                    options={[
                      ...(shouldShowAddServicePartnersForm()
                        ? [
                            {
                              label: i18next.t('formLabels.addServicePartners'),
                              value: EDIT_SERVICE_PLAN_ADD_SERVICE_PARTNERS,
                            },
                          ]
                        : []),
                      ...(shouldShowAddServicesOption()
                        ? [
                            {
                              label: i18next.t('formLabels.addServices'),
                              value: EDIT_SERVICE_PLAN_ADD_SERVICES,
                            },
                          ]
                        : []),
                      {
                        label: i18next.t('formLabels.extendServicePlan'),
                        value: EDIT_SERVICE_PLAN_EXTEND_SERVICES,
                      },
                      ...(shouldShowEarlyTerminationForm()
                        ? [
                            {
                              label: i18next.t(
                                'earlyTerminationFormLabels.earlyTerminationRadioLabel'
                              ),
                              value: EDIT_SERVICE_PLAN_EARLY_TERMINATION,
                            },
                          ]
                        : []),
                    ]}
                    onChange={changeHandler}
                  />
                )}
              />
            </SidePanelClickableSection>
            <Form
              onSubmit={onSubmit}
              validate={(values: FormData) => {
                const errors: FormData = {}
                if (
                  !values.extendDuration &&
                  radioValue === EDIT_SERVICE_PLAN_EXTEND_SERVICES
                ) {
                  errors.extendDuration = i18next.t('commonFormLabels.required')
                }

                if (
                  selectedServicePartners?.length === 0 &&
                  radioValue === EDIT_SERVICE_PLAN_ADD_SERVICE_PARTNERS
                ) {
                  errors.selectedServicePartners = i18next.t(
                    'commonFormLabels.required'
                  )
                }
                if (
                  radioValue === EDIT_SERVICE_PLAN_EARLY_TERMINATION &&
                  !values.terminationDate
                ) {
                  errors.terminationDate = i18next.t('commonFormLabels.required')
                }

                if (
                  radioValue === EDIT_SERVICE_PLAN_EARLY_TERMINATION &&
                  !(
                    dayjs(values.terminationDate).startOf('day') >=
                    dayjs().startOf('day')
                  )
                ) {
                  errors.terminationDate = i18next.t(
                    'commonFormLabels.dateShouldNotBeInThePastMessage'
                  )
                }

                if (
                  radioValue === EDIT_SERVICE_PLAN_EARLY_TERMINATION &&
                  !values.earlyTerminationReason
                ) {
                  errors.earlyTerminationReason = i18next.t(
                    'commonFormLabels.required'
                  )
                }
                // check if "Other" is selected
                if (
                  radioValue === EDIT_SERVICE_PLAN_EARLY_TERMINATION &&
                  values.earlyTerminationReason === '7' &&
                  !values.specificReason
                ) {
                  errors.specificReason = i18next.t('commonFormLabels.required')
                }

                if (
                  radioValue === EDIT_SERVICE_PLAN_ADD_SERVICES &&
                  !values[EDIT_SERVICE_PLAN_ADD_SERVICES]?.length
                ) {
                  errors.addService = i18next.t('commonFormLabels.oneOptionRequired')
                }
                setTimeout(() => {
                  // is needed to bypass an error. Still don't know why
                  setHasFormErrors(Object.keys(errors).length > 0)
                }, 0)

                return errors
              }}
              render={({ handleSubmit, form }) => {
                formApiRef.current = form
                return (
                  <form onSubmit={handleSubmit}>
                    <FormSpy
                      onChange={({ pristine, submitting }) => {
                        setIsSubmitting(submitting)
                        setIsPristine(pristine)
                      }}
                    />
                    {radioValue === EDIT_SERVICE_PLAN_ADD_SERVICE_PARTNERS && (
                      <>
                        <AddServicePartnersForm
                          vehicle={vehicle}
                          onSelectedPartners={handleSelectServicePartner}
                          onRemoveSelectedPartners={handleRemoveServicePartner}
                          partners={selectedServicePartners}
                        />
                      </>
                    )}
                    {radioValue === EDIT_SERVICE_PLAN_ADD_SERVICES && (
                      <AddServicesForm vehicle={vehicle} />
                    )}
                    {radioValue === EDIT_SERVICE_PLAN_EXTEND_SERVICES && (
                      <ExtendServicePlanForm vehicle={vehicle} />
                    )}
                    {radioValue === EDIT_SERVICE_PLAN_EARLY_TERMINATION && (
                      <EarlyTerminationForm vehicle={vehicle} />
                    )}
                  </form>
                )
              }}
            />
          </>
        )}
        {(!vehicle || isLoading) && (
          <Box sx={{ marginTop: 4 }}>
            <Spinner size={4} />
          </Box>
        )}
        {formSuccessfullySend && messageOnSuccess && (
          <SidePanelPaddedBox>{renderHTML(messageOnSuccess)}</SidePanelPaddedBox>
        )}
      </SidePanelMain>
      <SidePanelFooter sx={{ flexDirection: 'column' }}>
        {isSubmitting && (
          <>
            <Box sx={{ width: '100%', textAlign: 'center', flexDirection: 'row' }}>
              <Spinner size={4} />
              <Text variant="actionsLabel">
                {i18next.t('formLabels.sendingYourRequest')}
              </Text>
            </Box>
          </>
        )}
        {!(isSubmitting || isLoading) && !formSuccessfullySend && (
          <>
            <Box sx={{ width: '100%' }}>
              <EditServicePlanFormSubmitButton
                label={i18next.t('formLabels.sendRequest')}
                disabled={
                  isSubmitting ||
                  !vehicle ||
                  hasFormErrors ||
                  !vehicle.vehicleActions.editServicePlan.confirmEnabled
                }
                onSubmit={() => onSubmit(formApiRef.current?.getState().values)}
              />
            </Box>
            <Box sx={{ textAlign: 'center', marginTop: 3 }}>
              {isPristine && (
                <Text
                  sx={{
                    fontSize: 0,
                    fontWeight: 'bold',
                  }}
                  color={COLORS.GRAY}
                >
                  {i18next.t('formLabels.addRemarkFooterText')}
                </Text>
              )}

              {!isPristine && radioValue !== EDIT_SERVICE_PLAN_EARLY_TERMINATION && (
                <Text
                  sx={{
                    fontSize: 0,
                    fontWeight: 'bold',
                  }}
                  color={COLORS.GRAY}
                >
                  {i18next.t('formLabels.nonBindingQuoteFooterText')}
                </Text>
              )}

              {!isPristine && radioValue === EDIT_SERVICE_PLAN_EARLY_TERMINATION && (
                <Text
                  sx={{
                    fontSize: 0,
                    fontWeight: 'bold',
                  }}
                  color={COLORS.GRAY}
                >
                  {i18next.t(
                    'earlyTerminationFormLabels.earlyTerminationFooterText'
                  )}
                </Text>
              )}
            </Box>
          </>
        )}
        {!(isSubmitting || isLoading) && formSuccessfullySend && (
          <Button onClick={() => handleCloseForm()} sx={{ width: '100%' }}>
            {i18next.t('commonLabels.close')}
          </Button>
        )}
      </SidePanelFooter>
    </SidePanel>
  )
}

export default EditServicePlanFormPanel
