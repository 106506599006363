import React, { FC } from 'react'
import { MenuItem } from '../../../hooks/services/graphql/useMenuItemListService'
import { SubNavigationItem } from '../../atoms/NavigationItems'
import MenuList from '../MenuList/MenuList'

interface SubNavigationMenuProps {
  menuItems?: MenuItem[]
  fetching: boolean
}

const SubNavigationMenu: FC<SubNavigationMenuProps> = ({ fetching, menuItems }) => (
  <MenuList>
    {!fetching &&
      !!menuItems?.length &&
      menuItems.map(({ description, id, url }) => (
        <SubNavigationItem key={id} href={url}>
          {description}
        </SubNavigationItem>
      ))}
  </MenuList>
)

export default SubNavigationMenu
