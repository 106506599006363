import React, { FC } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'

export interface ExperienceEditorMessageProps extends Omit<BoxProps, 'css'> {
  title?: string
  text?: string
}

export const ExperienceEditorMessage: FC<ExperienceEditorMessageProps> = ({
  title,
  text,
  sx,
  ...boxProps
}) => (
  <Box
    sx={{
      background: 'LightSkyBlue',
      padding: 3,
      color: 'white',
      width: '100%',
      ...sx,
    }}
    {...boxProps}
  >
    {title && (
      <Text as="h2" variant="heading3" paddingBottom={text ? 2 : 0}>
        {title}
      </Text>
    )}

    {text && (
      <Text
        as="p"
        variant="bodySmall"
        sx={{
          maxWidth: '500px',
        }}
      >
        {text}
      </Text>
    )}
  </Box>
)

export default ExperienceEditorMessage
