import React, { createContext, ReactNode, useMemo } from 'react'
import withClientSideRender from '../enhancers/withClientSideRender'
import useDealerPartSalesInformationService, {
  DealerPartPriceInformation,
} from '../hooks/services/graphql/useDealerPartSalesInformationService'
import {
  PartNumberQuantityInput,
  StockInformation,
} from '../hooks/services/graphql/usePartSalesInformationService'

export interface DealerPartSalesInformationContextProps {
  fetching: boolean
  priceInformations?: DealerPartPriceInformation[]
  stockInformation?: StockInformation[]
}

export const DealerPartSalesInformationContext =
  createContext<DealerPartSalesInformationContextProps>({
    fetching: false,
    priceInformations: undefined,
    stockInformation: undefined,
  })

export interface DealerPartSalesInformationProps {
  partNumbersQuantity?: PartNumberQuantityInput[]
  children: ReactNode
}

const getUniquePartNumbersQuantity = (
  partNumbersQuantity?: PartNumberQuantityInput[]
) =>
  partNumbersQuantity?.filter(
    (v, i, a) =>
      a.findIndex((t) => t.partNumber && t.partNumber === v.partNumber) === i
  )

const DealerPartSalesInformationProvider = ({
  children,
  partNumbersQuantity,
}: DealerPartSalesInformationProps) => {
  const uniquePartNumbersQuantity = useMemo(
    () => getUniquePartNumbersQuantity(partNumbersQuantity),
    [partNumbersQuantity]
  )

  const [fetching, dealerPartSalesInformation] =
    useDealerPartSalesInformationService(uniquePartNumbersQuantity)

  return (
    <DealerPartSalesInformationContext.Provider
      value={{
        fetching,
        priceInformations: dealerPartSalesInformation.priceInformations,
        stockInformation: dealerPartSalesInformation.stockInformationList,
      }}
    >
      {children}
    </DealerPartSalesInformationContext.Provider>
  )
}

export default withClientSideRender(DealerPartSalesInformationProvider)
