import React, { FC, useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'
import ModalBodyContent from '../Modal/ModalBodyContent'
import { Slide } from '../SlideshowHandout/SlideshowHandout'
import RichText from '../../atoms/RichText/RichText'

interface SlideshowSlideProps extends Slide {
  isActive?: boolean
  imageHeights?: Array<string>
}

const SlideshowSlide: FC<SlideshowSlideProps> = ({
  body,
  title,
  image,
  isActive,
  imageHeights,
}) => {
  const withImage = !!image?.src
  const withText =
    !!getTextFieldValue(title).length || !!getTextFieldValue(body).length

  const variant = useMemo(() => {
    if (withImage && withText) return 'imageText'
    if (!withImage && withText) return 'text'
    return 'image'
  }, [withImage, withText])

  if (!isActive) return null

  return (
    <Box
      sx={{
        height:
          variant === 'imageText' ? ['100%', '100%', '100%', '635px'] : undefined,
        overflowY: 'auto',
      }}
    >
      {withImage && (
        <Box
          sx={{
            width: '100%',
            height: imageHeights ?? ['210px', '290px', '360px'],
            top: 0,
            zIndex: -1,
            backgroundColor: 'gray.0',
          }}
        >
          <SitecoreImage imageField={image} />
        </Box>
      )}

      {withText && (
        <ModalBodyContent
          sx={{
            pt: [3, 3, 3, 5],
          }}
        >
          <Text as="h2" variant="heading2">
            {getTextFieldValue(title)}
          </Text>

          <RichText textField={body} />
        </ModalBodyContent>
      )}
    </Box>
  )
}

export default SlideshowSlide
