import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Box, Flex } from 'theme-ui'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import i18next from 'i18next'
import useDebounce from '../../../hooks/useDebounce'
import SearchBarInput from '../SearchBar/SearchBarInput'
import { COLORS } from '../../../constants/themeConstants'
import SearchBarControls from '../SearchBar/SearchBarControls'
import { fetchDealers } from '../../../services/rest/dealer/dealerSearch'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { Dealer, SearchDealerRequestFilter } from '../../../types/dealerServiceTypes'
import { PaginatedResponse } from '../../../types/layoutService'

interface SearchServicePartnersProps {
  id: string
  onDataFetch: (
    servicePartnersData: PaginatedResponse<Dealer>,
    searchString: string
  ) => void
  onReset?: () => void
  disabled?: boolean
  filters?: SearchDealerRequestFilter[]
}
const SearchServicePartnersInput = forwardRef(
  (
    { id, onDataFetch, onReset, disabled, filters }: SearchServicePartnersProps,
    ref
  ) => {
    const dealerClient = useAxiosClient(AxiosClientType.DealerApi)
    const inputRef = useRef<HTMLInputElement>(null)
    const [searchString, setSearchString] = useState('')

    useDebounce(searchString, 300)

    useImperativeHandle(ref, () => ({
      resetTextInputFilter,
    }))

    const getServicePartners = async () => {
      const { token } = axios.CancelToken.source()
      const response = await fetchDealers(
        dealerClient,
        {
          text: searchString,
          pageSize: 20,
          filters: filters || [],
        },
        token
      )
      return response.data
    }

    const { refetch: refectchServiceParners, data: servicePartnersData } = useQuery({
      queryKey: ['SearchServicePartners'],
      queryFn: () => getServicePartners(),
      enabled: searchString.length > 2,
    })

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchString(event?.target.value)
    }

    const resetTextInputFilter = () => {
      setSearchString('')
      refectchServiceParners()
      if (onReset) onReset()
    }

    useEffect(() => {
      if (searchString.length < 3) return
      refectchServiceParners()
    }, [searchString, refectchServiceParners])

    useEffect(() => {
      if (servicePartnersData) {
        onDataFetch(servicePartnersData, searchString)
      }
    }, [servicePartnersData, searchString, onDataFetch])

    return (
      <Flex sx={{ flexDirection: 'column' }}>
        <Box sx={{ position: 'relative' }}>
          <SearchBarInput
            required={false}
            id={id}
            disabled={disabled}
            data-t-id="add-service-parners-input"
            ref={inputRef}
            name="search"
            placeholder={i18next.t('formLabels.searchByCityOrPostalCode')}
            autoComplete="off"
            minLength={3}
            value={searchString}
            onChange={changeHandler}
            sx={{
              opacity: disabled ? 0.5 : 1,
              backgroundColor: COLORS.WHITE,
              border: '1px solid',
              borderColor: COLORS.DARK_GRAY,
            }}
          />

          <SearchBarControls
            disabled={disabled}
            submitButtonTestId="add-service-parners-search-button"
            currentInput={searchString}
            inputRef={inputRef}
            onReset={() => resetTextInputFilter()}
          />
        </Box>
      </Flex>
    )
  }
)

export default SearchServicePartnersInput
