import React, { FC, useCallback, useContext, useMemo } from 'react'
import i18next from 'i18next'
import PartListSalesInformationProvider from '../../../providers/PartListSalesInformationProvider'
import { loggedIn } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import { LinkField } from '../../../types/layoutService'
import { DEFAULT_PART_QUANTITY } from '../../../constants/partSalesInformationConstants'
import {
  PART_BOM_TABLE_MASTERS_VARIANT,
  PART_BOM_TABLE_PARTS_VARIANT,
} from '../../../constants/partBillOfMaterialConstants'
import { CommerceBillOfMaterialPart } from '../../../types/commerceApi'
import PartBillOfMaterialPart from './PartBillOfMaterialPart'
import { PartNumberQuantityInput } from '../../../hooks/services/graphql/usePartSalesInformationService'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import { measurePartClick } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartLine from '../../molecules/PartLine/PartLine'
import PartLineTitle from '../../molecules/PartLine/PartLineTitle'

export type PartBomTableVariantType =
  | typeof PART_BOM_TABLE_PARTS_VARIANT
  | typeof PART_BOM_TABLE_MASTERS_VARIANT

export interface PartBillOfMaterialTableProps {
  bomParts?: CommerceBillOfMaterialPart[]
  partsPageUri: LinkField
  variant?: PartBomTableVariantType
}

export const gridTemplate = {
  columnSizes: ['1fr', '1fr', '1fr 2fr 1fr 1fr'], // css prop: grid-template-columns
  columnSizesLoggedIn: ['1fr', '1fr', '1fr 2fr 1fr 1fr 1fr 2fr'], // css prop: grid-template-columns
}

export const gridTemplateWithReferenceColumn = {
  columnSizes: ['1fr', '1fr', '1fr 1fr 2fr 1fr 1fr'], // css prop: grid-template-columns
  columnSizesLoggedIn: ['1fr', '1fr', '1fr 1fr 2fr 1fr 1fr 1fr 2fr'], // css prop: grid-template-columns
}

const PartBillOfMaterialTable: FC<PartBillOfMaterialTableProps> = ({
  bomParts,
  partsPageUri,
}) => {
  const { user } = useContext(UserContext)
  const { categories } = useContext(CategoriesContext)

  const partNumberQuantityInputs = useMemo<PartNumberQuantityInput[]>(
    () =>
      bomParts?.map(({ partNumber }) => ({
        partNumber,
        quantity: DEFAULT_PART_QUANTITY,
      })) || [],
    [bomParts]
  )

  const showDrawingReferenceColumn = useMemo(() => {
    if (bomParts && !!bomParts.length) {
      return bomParts.some(({ drawingReference }) => drawingReference)
    }

    return false
  }, [bomParts])

  const computedGridTemplate = useMemo(
    () =>
      showDrawingReferenceColumn ? gridTemplateWithReferenceColumn : gridTemplate,
    [showDrawingReferenceColumn]
  )

  const measureClick = useCallback(
    (part: CommerceBillOfMaterialPart, index: number) =>
      measurePartClick(
        part.partNumber,
        part.englishDescription,
        part.brand?.description,
        part.category?.mainCategoryId,
        part.category?.subCategoryId,
        index,
        categories,
        DATA_LAYER.COMPONENT_ORIGIN.PART_BOM_PART
      ),
    [categories]
  )

  if (!bomParts?.length) return null

  return (
    <PartListSalesInformationProvider
      partNumbersQuantity={partNumberQuantityInputs}
      includePrice
      includeStock
    >
      <PartLine
        gridTemplate={computedGridTemplate}
        sx={{
          display: ['none', 'none', 'grid'],
        }}
        py={3}
      >
        {showDrawingReferenceColumn && (
          <PartLineTitle>
            {i18next.t('commonLabels.numberAbbreviation')}
          </PartLineTitle>
        )}

        <PartLineTitle />

        <PartLineTitle>{i18next.t('commonLabels.product')}</PartLineTitle>

        <PartLineTitle>{i18next.t('commonLabels.quantity')}</PartLineTitle>

        <PartLineTitle>{i18next.t('commonLabels.brand')}</PartLineTitle>

        {loggedIn(user) && (
          <PartLineTitle>{i18next.t('stockLabels.availability')}</PartLineTitle>
        )}

        {loggedIn(user) && (
          <PartLineTitle>{i18next.t('partSalesLabels.price')}</PartLineTitle>
        )}
      </PartLine>

      {bomParts.map((part, i) => (
        <PartBillOfMaterialPart
          key={i.toString()}
          bomPart={part}
          showDrawingReferenceColumn={showDrawingReferenceColumn}
          englishDescription={part.englishDescription}
          partsPageUri={partsPageUri}
          gridTemplate={computedGridTemplate}
          onClick={() => measureClick(part, i)}
        />
      ))}
    </PartListSalesInformationProvider>
  )
}

export default PartBillOfMaterialTable
