import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Text } from 'theme-ui'
import { Form } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Trans } from 'react-i18next'
import Button from '../../../atoms/Button/Button'
import SidePanel from '../SidePanel'
import { toggleMarkMaintenanceAsPerformedPanel } from '../../../../actions/dafCompanyVehicleActions'
import SidePanelBody from '../SidePanelBody'
import { RootState } from '../../../../reducers'
import { UpcomingJob } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { CheckboxField } from '../../../atoms/FormFields'
import { InputMileageTextField } from '../../../atoms/FormFields/InputMileageTextField'
import SidePanelFooter from '../SidePanelFooter'
import { reverseFormatNumber } from '../../../../helpers/numberFormatHelper'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../providers/UserProvider'
import postDafVehicleUpcomingJobs, {
  DafVehicleUpcomingJobsRequest,
} from '../../../../services/rest/ecommerce/vehicleUpcomingJobs'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import sendMarkMaintenanceAsPerformedPayload, {
  SendMarkMaintenanceAsPerformedPayloadProps,
} from '../../../../services/rest/ecommerce/sendMarkMaintenanceAsPerformed'
import { InputDateField } from '../../../atoms/FormFields/InputDateField'
import { ValidationRules } from '../../../../helpers/formFieldHelper'
import { getVehicleLabel } from '../../../../helpers/vehicleHelper'
import { JOB_EXECUTED_EVENT } from '../../../../constants/dmscConstants'
import { VehicleReducerState } from '../../../../reducers/dafCompanyVehicleReducer'

interface MarkMaintenanceAsPerformedPanelProps {
  onFormSuccess?: () => void
}

type FormData = {
  date: string
  mileageInput: string
  confirm: boolean
}

const MarkMaintenanceAsPerformedPanel = ({
  onFormSuccess,
}: MarkMaintenanceAsPerformedPanelProps) => {
  const dispatch = useDispatch()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { token } = axios.CancelToken.source()
  const { vehicleData, selectedJobs } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const { markMaintenanceAsPerformedPanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const [formSuccessfullySend, setFormSuccessfullySend] = useState(false)

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const postUpcomingJobStatus = (request: DafVehicleUpcomingJobsRequest) =>
    postDafVehicleUpcomingJobs(
      request,
      dafVehicleDetailQueryParams,
      client,
      token,
      user
    ).then((response) => response.data)

  const { isPending: isLoading, mutateAsync } = useMutation({
    mutationFn: postUpcomingJobStatus,
    onSuccess: () => {
      if (
        vehicleData &&
        vehicleData?.vehicleDetail?.nonMultiSupportServices.length > 0
      )
        handleFormSuccessfullySend()
    },
  })

  const postMarkMaintenanceAsPerformedInput = async (
    payload: SendMarkMaintenanceAsPerformedPayloadProps
  ) => {
    const response = await sendMarkMaintenanceAsPerformedPayload(
      dafVehicleDetailQueryParams,
      payload,
      client,
      token,
      user
    )

    return response.data
  }

  const { isPending: isLoadingRecalculate, mutate } = useMutation({
    mutationFn: (payload: SendMarkMaintenanceAsPerformedPayloadProps) =>
      postMarkMaintenanceAsPerformedInput(payload),

    onSuccess: () => {
      handleFormSuccessfullySend()
    },
  })

  const handleFormSuccessfullySend = () => {
    setFormSuccessfullySend(true)
    if (onFormSuccess) onFormSuccess()
  }

  const onSubmit = async (values: FormData) => {
    if (!selectedJobs) return

    const res = selectedJobs.map((job: UpcomingJob) =>
      mutateAsync({
        dueDate: job.dueDate,
        jobCode: job.code,
        markedAsComplete: true,
        chassisNumber: vehicleData?.vehicleDetail.vehicleAttributes.chassisNumber,
      })
    )

    await Promise.all(res)

    let mileageInputValue: number | null = null

    if (!vehicleData?.rmContract) {
      mileageInputValue = reverseFormatNumber(
        values?.mileageInput as unknown as string,
        cultureCode
      )
    }

    const payload: SendMarkMaintenanceAsPerformedPayloadProps = {
      vin: vehicleData?.vehicleDetail.vehicleAttributes.vin || '',
      eventType: JOB_EXECUTED_EVENT,
      serviceJobs: selectedJobs.map((job: UpcomingJob) => ({
        code: job.code,
        executedDistance: mileageInputValue,
        executedDate: values.date,
        dueDate: job.dueDate,
      })),
    }

    mutate(payload)
  }

  const validate = (values: FormData) => {
    const errors: ValidationErrors = {}
    if (!values.date) errors.date = i18next.t('commonFormLabels.required')

    if (!values.confirm) errors.confirm = i18next.t('commonFormLabels.required')
    if (!values.date) errors.date = i18next.t('commonFormLabels.required')

    if (values.date && new Date(values.date) > new Date()) {
      errors.date = i18next.t('commonFormLabels.isFutureDateMessage')
    }

    if (!vehicleData?.rmContract) {
      if (!values.mileageInput)
        errors.mileageInput = i18next.t('commonFormLabels.required')

      if (
        values.mileageInput &&
        reverseFormatNumber(values.mileageInput as unknown as string, cultureCode) <
          1000
      ) {
        errors.mileageInput = i18next.t('commonFormLabels.invalidMileage')
      }
    }

    return errors
  }
  return (
    <SidePanel
      position="right"
      active={markMaintenanceAsPerformedPanelState}
      onClose={() => {
        dispatch(toggleMarkMaintenanceAsPerformedPanel({ isActive: false }))
      }}
      title={i18next.t('maintenance.reportAsPerformed')}
    >
      {formSuccessfullySend ? (
        <>
          <SidePanelBody>
            <Text variant="bodySmall">
              <p>{i18next.t('maintenance.reportAsPerformedDone')}</p>
            </Text>
          </SidePanelBody>
          <SidePanelFooter>
            <Button
              sx={{ width: '100%' }}
              variant="primary"
              onClick={() => {
                dispatch(toggleMarkMaintenanceAsPerformedPanel({ isActive: false }))
              }}
            >
              {i18next.t('commonLabels.close')}
            </Button>
          </SidePanelFooter>
        </>
      ) : (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, hasValidationErrors }) => (
            <>
              <SidePanelBody>
                <form onSubmit={handleSubmit} id="markMaintenanceAsPerformedForm">
                  <Text variant="bodySmall">
                    <Trans
                      i18nKey={
                        !vehicleData?.rmContract
                          ? 'maintenance.reportAsPerformedIntro'
                          : 'maintenance.reportAsPerformedIntroNoMileage'
                      }
                      values={{
                        registrationNumber: `<strong>${getVehicleLabel(
                          vehicleData?.vehicleDetail
                        )}</strong>`,
                      }}
                    />

                    <ul>
                      {selectedJobs?.map((upcomingJob: UpcomingJob) => (
                        <li key={upcomingJob.code}>
                          {upcomingJob.code}
                          <span> – </span>
                          {upcomingJob.description}
                        </li>
                      ))}
                    </ul>
                  </Text>

                  <InputDateField
                    name="date"
                    validationRules={[
                      ValidationRules.Required,
                      ValidationRules.ShouldBeInThePast,
                    ]}
                    label={i18next.t('commonLabels.date')}
                  />

                  {!vehicleData?.rmContract && (
                    <InputMileageTextField label="Mileage" name="mileageInput" />
                  )}

                  <CheckboxField
                    name="confirm"
                    label={i18next.t('commonLabels.iConfirmCorrect')}
                  />
                </form>
              </SidePanelBody>
              <SidePanelFooter>
                <Button
                  sx={{ width: '100%' }}
                  variant="primary"
                  type="submit"
                  // button is outside <form>, but this makes it part of the form again
                  form="markMaintenanceAsPerformedForm"
                  disabled={
                    hasValidationErrors ||
                    isLoading ||
                    isLoadingRecalculate ||
                    !vehicleData?.vehicleActions.reportAsPerformed.confirmEnabled
                  }
                >
                  {i18next.t('commonFormLabels.confirm')}
                </Button>
              </SidePanelFooter>
            </>
          )}
        />
      )}
    </SidePanel>
  )
}

export default MarkMaintenanceAsPerformedPanel
