import { Action } from 'redux'
import { SHOPPING_BASKET_WIZARD_SET_ORDER_REMARKS } from '../../../constants/shoppingBasketConstants'

export interface SetOrderRemarks
  extends Action<typeof SHOPPING_BASKET_WIZARD_SET_ORDER_REMARKS> {
  payload: string
}

export const setOrderRemarks = (payload: string) => ({
  type: SHOPPING_BASKET_WIZARD_SET_ORDER_REMARKS,
  payload,
})
