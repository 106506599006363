import { Box, Link, Text } from 'theme-ui'
import React, { useCallback, useState } from 'react'
import i18next from 'i18next'
import {
  ContractLine,
  VehicleDetailResponse,
} from '../../../services/rest/ecommerce/dafVehicleDetail'
import { COLORS } from '../../../constants/themeConstants'
import { Bullet } from '../../../types/dafResponseProps'
import List from '../../atoms/List/List'
import SidePanel from '../../organisms/SidePanel/SidePanel'
import SidePanelBody from '../../organisms/SidePanel/SidePanelBody'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'

interface ServicePlansCoverageSummaryBulletsProps {
  vehicle: VehicleDetailResponse
}

const ServicePlansCoverageSummaryBullets = ({
  vehicle,
}: ServicePlansCoverageSummaryBulletsProps) => {
  const [sidePanel, setSidePanel] = useState(false)
  const onClick = useCallback(() => {
    setSidePanel(!sidePanel)
  }, [sidePanel])

  const convertContractLinesToBullets = (contractLines: ContractLine[]) => {
    const bullets: Bullet[] = []
    if (!contractLines) return bullets
    const contractLinesFiltered = contractLines
      .map((contractLine) => contractLine.contractLine)
      .sort()

    contractLinesFiltered.forEach((value) => {
      const bullet: Bullet = {
        description: value,
      }
      bullets.push(bullet)
    })
    return bullets
  }

  return (
    <>
      {vehicle?.rmContract?.servicePlan?.coverageSummary?.contractLines && (
        <Box>
          <Text variant="heading4" sx={{ marginTop: 5 }}>
            {i18next.t('servicePlan.services')}
          </Text>

          <List
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: ['column', 'row', 'column', 'column'],
              paddingLeft: 3,
              marginTop: 1,
              li: {
                width: ['100%', '50%', '100%', '100%'],
                display: 'flex',
                fontSize: 1,
                color: 'gray.2',
                paddingRight: 3,
              },
            }}
            items={convertContractLinesToBullets(
              vehicle?.rmContract?.servicePlan?.coverageSummary?.contractLines
            ).slice(0, 6)}
          />
          {vehicle?.rmContract?.servicePlan?.coverageSummary?.contractLines.length >
            6 && (
            <ClickableWrapper onClick={onClick}>
              <Link
                sx={{
                  fontSize: 1,
                  lineHeight: 2,
                  float: 'left',
                  color: COLORS.DAF_BLUE,
                  fontWeight: 500,
                }}
              >
                {i18next.t('servicePlan.more')}
              </Link>
            </ClickableWrapper>
          )}
        </Box>
      )}

      <SidePanel
        active={sidePanel}
        onClose={() => setSidePanel(false)}
        title={i18next.t('servicePlan.services')}
      >
        <SidePanelBody>
          <Text variant="bodyContent" color="gray.2">
            <List
              sx={{
                width: '100%',
                paddingLeft: 3,
                marginTop: 1,
                li: {
                  fontSize: 1,
                  color: 'gray.2',
                },
              }}
              items={convertContractLinesToBullets(
                vehicle?.rmContract?.servicePlan?.coverageSummary?.contractLines
              )}
            />
          </Text>
        </SidePanelBody>
      </SidePanel>
    </>
  )
}

export default ServicePlansCoverageSummaryBullets
