import React, { FC } from 'react'
import { Flex, FlexProps, Text } from 'theme-ui'
import { getStockChipStyle, getStockIcon } from '../../../helpers/stockHelper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'

interface PartStockInformationChipProps extends Omit<FlexProps, 'css'> {
  stockState: string
}

const PartStockInformationChip: FC<PartStockInformationChipProps> = ({
  stockState,
  children,
}) => (
  <Flex
    sx={{
      width: 'fit-content',
      minInlineSize: '40px',
      border: 'none',
      textDecoration: 'none',
      lineHeight: 'menuList',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 1,
      textAlign: 'left',
      paddingY: 1,
      paddingInline: '6px',
      borderRadius: '20px',
      transition: 'ease all 200ms',
      overflow: 'hidden',
      wordBreak: 'break-all',
      ...getStockChipStyle(stockState || ''),
    }}
  >
    <Flex
      sx={{
        margin: '-6px',
      }}
    >
      <IconWrapper icon={getStockIcon(stockState)} size={4} />
    </Flex>

    {children && (
      <Text as="span" variant="smallText" sx={{ lineHeight: '12px' }}>
        {children}
      </Text>
    )}
  </Flex>
)

export default PartStockInformationChip
