export const alphaColor = (hexColorString: string, opacity?: number | string) => {
  const hexColor = hexColorString.replace('#', '')

  if (hexColor.length !== 6) return ''

  const aRgbHex = hexColor.match(/.{1,2}/g)

  if (!aRgbHex) return ''

  return `rgba(${[
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
    opacity || 1,
  ].join(',')})`
}
