import React, { FC, ReactNode } from 'react'
import { Link } from 'theme-ui'

export interface MenuListLinkProps {
  href: string
  children: ReactNode
}

export const SubNavigationItem: FC<MenuListLinkProps> = ({ children, href }) => (
  <Link
    variant="bodySmall"
    href={href}
    sx={{
      display: 'block',
      px: [0, 0, 5],
      py: 2,
      width: '100%',
      color: 'gray.3',
      outline: 'none',
      whiteSpace: 'nowrap',
      lineHeight: ['heading2', 'heading2', 'menuList'],
      fontSize: [2, 2, 1],
      textDecoration: 'none',
      ':hover, :focus, :active': {
        color: 'primary',
      },
    }}
  >
    {children}
  </Link>
)
