import React, { FC, useContext, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { useTheme } from '@emotion/react'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import MyAccountAnchor from './MyAccountAnchor'
import MyAccountMenu from './MyAccountMenu'
import { UserContext } from '../../../providers/UserProvider'
import { AuthenticationInfoProps } from '../../../types/userProps'
import {
  Datasource,
  Flag,
  LinkField,
  Params,
  TextField,
} from '../../../types/layoutService'
import SidePanel from '../SidePanel/SidePanel'
import SidePanelBody from '../SidePanel/SidePanelBody'
import SidePanelFooter from '../SidePanel/SidePanelFooter'
import Button from '../../atoms/Button/Button'
import MyAccountLoginButton from './MyAccountLogin'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { loggedIn } from '../../../helpers/userHelper'
import { COLORS } from '../../../constants/themeConstants'
import { AccountOverviewMenuProps } from '../../../types/accountOverviewTypes'

interface MyAccountDatasource {
  title: TextField
  loginLabel: TextField
  logoutLabel: TextField
  hybridLoginPageCommerce: LinkField
  hybridLoginPageMultisupport: LinkField
  accountOverviewMenuCommerce: AccountOverviewMenuProps
  accountOverviewMenuMultisupport: AccountOverviewMenuProps
}

interface MyAccountProps {
  authenticationInfo: AuthenticationInfoProps
}

interface MyAccounParams {
  transparentBackground: Flag
}

const MyAccount: FC<
  Datasource<MyAccountDatasource> & MyAccountProps & Params<MyAccounParams>
> = ({
  params: { transparentBackground },
  datasource: {
    title,
    loginLabel,
    logoutLabel,
    hybridLoginPageCommerce,
    hybridLoginPageMultisupport,
    accountOverviewMenuCommerce,
    accountOverviewMenuMultisupport,
  },
  authenticationInfo,
}) => {
  const { site, featureToggles, classicUrl, absoluteUrl } = useSitecoreContext()
  const showAccountMenuItems = !(site?.name === 'multisupport')
  const { user, userProfile } = useContext(UserContext)
  const [myAccountOpen, setMyAccountOpen] = useState(false)
  const isHybridLogin = featureToggles?.hybridLogin ?? false
  const { mainNavigation } = useTheme()

  const textColor = transparentBackground ? COLORS.WHITE : mainNavigation.textColor

  const getHybridLoginUri = (url: string) => {
    const isAbsoluteUri =
      url.indexOf('http://') === 0 || url.indexOf('https://') === 0

    return isAbsoluteUri
      ? `${classicUrl}/auth0/Prelogin?returnUrl=${url}`
      : `${classicUrl}/auth0/Prelogin?returnUrl=${absoluteUrl}${url}`
  }

  if (!userProfile && !loggedIn(user)) {
    return (
      <>
        <>
          {isHybridLogin && hybridLoginPageCommerce.url && (
            <MyAccountLoginButton
              title={getTextFieldValue(title)}
              label={getTextFieldValue(loginLabel)}
              loginUri={
                isHybridLogin &&
                hybridLoginPageMultisupport.url &&
                !showAccountMenuItems
                  ? getHybridLoginUri(hybridLoginPageMultisupport.url)
                  : getHybridLoginUri(hybridLoginPageCommerce.url)
              }
              buttonType="link"
              textColor={textColor}
            />
          )}
        </>
        <>
          {!isHybridLogin && (
            <Box>
              <MyAccountLoginButton
                title={getTextFieldValue(title)}
                label={getTextFieldValue(loginLabel)}
                loginUri={authenticationInfo?.loginUri}
              />
            </Box>
          )}
        </>
      </>
    )
  }

  return (
    <>
      <Flex sx={{ position: 'relative' }}>
        <MyAccountAnchor
          title={getTextFieldValue(title)}
          label={
            userProfile?.initials ||
            user?.profile.fullName ||
            getTextFieldValue(loginLabel)
          }
          suppress={!!userProfile}
          onClick={() => setMyAccountOpen(true)}
          data-t-id="menu-button-my-account"
        />
      </Flex>

      <SidePanel
        active={myAccountOpen}
        onClose={() => setMyAccountOpen(false)}
        title={getTextFieldValue(title)}
        position="right"
      >
        <SidePanelBody>
          <MyAccountMenu
            accountOverviewMenuItemsCommerce={accountOverviewMenuCommerce}
            accountOverviewMenuItemsMultisupport={accountOverviewMenuMultisupport}
          />
        </SidePanelBody>

        <SidePanelFooter>
          {authenticationInfo?.logoutUri && (
            <Button
              as="a"
              variant="outline"
              href={authenticationInfo.logoutUri}
              sx={{ width: '100%' }}
            >
              {getTextFieldValue(logoutLabel)}
            </Button>
          )}
        </SidePanelFooter>
      </SidePanel>
    </>
  )
}

export default withData(MyAccount)
