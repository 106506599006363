import React, { FC } from 'react'
import { Box, FlexProps } from 'theme-ui'

interface TableBodyProps extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {}

const TableBody: FC<TableBodyProps> = ({ children, sx, ...manipulatedProps }) => (
  <Box
    className="table-body"
    sx={{
      display: 'table-row-group',
      width: '100%',
      '> *:last-child': {
        borderBottom: '0',
      },
      ...sx,
    }}
    {...manipulatedProps}
  >
    {children}
  </Box>
)

export default TableBody
