import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { BannerParams, Datasource, Params } from '../../../types/layoutService'
import Banner, { DEFAULT_BANNER_VARIANT } from '../../atoms/Banner/Banner'
import BannerAboveTitle from '../../atoms/Banner/BannerAboveTitle'
import { BannerGradient } from '../../atoms/Banner/BannerGradient'
import BannerImage from '../../atoms/Banner/BannerImage'
import BannerLogo from '../../atoms/Banner/BannerLogo'
import BannerVideo from '../../atoms/Banner/BannerVideo'
import { HeroBannerDatasource } from '../HeroBanner/HeroBanner'

const HeroBannerWithTitleAbove: FC<
  Datasource<HeroBannerDatasource> & Params<BannerParams>
> = ({
  datasource: { title, image, video, logo },
  params: {
    bottomSpacing,
    variant = DEFAULT_BANNER_VARIANT,
    gradientType,
    logoPosition,
  },
}) => (
  <>
    <BannerAboveTitle title={getTextFieldValue(title)} />

    <Banner bottomSpacing={bottomSpacing}>
      <BannerImage imageField={image} variant={variant} />

      <BannerVideo video={video} />

      <BannerGradient gradientType={gradientType} visible={!!image?.src} />

      <BannerLogo
        logoImageField={logo?.targetItem?.image}
        logoPosition={logoPosition}
      />
    </Banner>
  </>
)

export default withData(HeroBannerWithTitleAbove)
