import React, { useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'
import Table from '../Table/Table'
import { Dealer } from '../../../types/dealerServiceTypes'
import { joinStringsWithDelimiter } from '../../../helpers/stringHelper'
import ServiceDetailMainServiceDealerItem from './ServiceDetailMainServiceDealerItem'
import Spinner from '../../atoms/Spinner/Spinner'

interface ServiceDetailMainServiceDealerProps {
  serviceDealer: Dealer | undefined
  isFetching: boolean
}

const ServiceDetailMainServiceDealer = ({
  serviceDealer,
  isFetching,
}: ServiceDetailMainServiceDealerProps) => {
  const addressSegments = useMemo(
    () => [
      joinStringsWithDelimiter(
        [
          serviceDealer?.address?.addressLine1,
          serviceDealer?.address?.addressLine2,
          serviceDealer?.address?.addressLine3,
        ],
        ', '
      ),
      joinStringsWithDelimiter([
        serviceDealer?.address?.postalCode,
        serviceDealer?.address?.city,
      ]),
    ],
    [serviceDealer]
  )

  return (
    <>
      {!serviceDealer && !isFetching && (
        <Box>
          <Text variant="bodySmall" color={COLORS.GRAY}>
            -
          </Text>
        </Box>
      )}

      {!serviceDealer && isFetching && (
        <Box
          sx={{
            paddingY: 5,
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner />
        </Box>
      )}

      {serviceDealer && !isFetching && (
        <>
          <Table columnSizes={[0.1, 0.9]}>
            <ServiceDetailMainServiceDealerItem
              icon={ICONS.TRUCK}
              value={serviceDealer?.name}
            />
            <ServiceDetailMainServiceDealerItem
              icon={ICONS.MARKER}
              value={`${
                addressSegments && !!addressSegments[0].length && addressSegments[0]
              }, ${
                addressSegments && !!addressSegments[1].length && addressSegments[1]
              }`}
            />
            <ServiceDetailMainServiceDealerItem
              icon={ICONS.PHONE}
              value={serviceDealer?.communications?.[0]?.phone}
            />
            <ServiceDetailMainServiceDealerItem
              icon={ICONS.ENVELOPE}
              value={serviceDealer?.communications?.[0]?.email}
            />
          </Table>
        </>
      )}
    </>
  )
}

export default ServiceDetailMainServiceDealer
