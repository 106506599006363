import i18next from 'i18next'
import React, {
  ClipboardEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createOrderMutation,
  markOrderMutationFinished,
  refetchShoppingBasketOrder,
  setCustomerOrderReference,
} from '../../../../../actions/shoppingBasket/wizard'
import useCommerceApiPostService from '../../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveUserStateContext } from '../../../../../providers/ActiveUserStateProvider'
import { RootState } from '../../../../../reducers'
import {
  OrderCustomerOrderReference,
  OrderCustomerOrderReferenceRequestProps,
  postOrderCustomerOrderReference,
} from '../../../../../services/rest/ecommerce/order/order-customer-order-reference'
import { CommerceResponse } from '../../../../../types/commerceApi'
import FormControl from '../../../../atoms/FormControls/FormControl'
import FormFieldText from '../../../../atoms/FormControls/FormFieldText'
import FormInputLabel from '../../../../atoms/FormControls/FormInputLabel'
import FormInputTextAsync from '../../../../atoms/FormControls/FormInputTextAsync'
import { QUOTE } from '../../../../../constants/orderConstants'

const OrderFieldCustomerOrderReference: FC = () => {
  const dispatch = useDispatch()

  const { orderNumber } = useContext(ActiveUserStateContext)
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const [error, setError] = useState<string | null>(null)
  const [showError, setShowError] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const defaultValue = useMemo(
    () => order?.customerOrderReference?.value || '',
    [order]
  )

  const [posting, response, post] = useCommerceApiPostService<
    OrderCustomerOrderReferenceRequestProps,
    CommerceResponse<OrderCustomerOrderReference>
  >(postOrderCustomerOrderReference)

  useCommerceApiResponse<OrderCustomerOrderReference>({
    response,
    onResult: (e: OrderCustomerOrderReference | null) => {
      const value = e || ''

      dispatch(setCustomerOrderReference(value))
      dispatch(markOrderMutationFinished())
      dispatch(refetchShoppingBasketOrder(true))

      if (inputRef.current) inputRef.current.value = value
    },
    onMessage: () => undefined,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as OrderCustomerOrderReference,
  })

  const validateInput = useCallback(
    (input: string) => {
      if (order?.customerOrderReference?.isMandatory && !input) {
        setError(i18next.t('formValidation.required'))
        return false
      }

      if (
        order?.customerOrderReference?.maxLength &&
        input.length > order.customerOrderReference.maxLength
      ) {
        setError(
          i18next.t('formValidation.maxLength', {
            0: order?.customerOrderReference?.maxLength,
          })
        )

        return false
      }

      setError(null)
      setShowError(false)

      return true
    },
    [order]
  )

  const submitInput = useCallback(
    (inputValue: string) => {
      if (inputValue === order?.customerOrderReference?.value) return

      post({
        orderNumber,
        customerOrderReference: inputValue,
      })
    },
    [order, post, orderNumber]
  )

  const eventHandlers = useMemo(
    () => ({
      onBlur: (e: FocusEvent<HTMLInputElement>) => {
        if (!validateInput(e.target.value)) {
          setShowError(true)
        }

        submitInput(e.target.value)
      },
      onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => {
        if (!validateInput(e.currentTarget.value)) {
          setShowError(true)
        }

        if (e.key === 'Enter') submitInput(e.currentTarget.value)
      },
      onPaste: (e: ClipboardEvent<HTMLInputElement>) => {
        validateInput(e.currentTarget.value)
        setShowError(true)
      },
    }),
    [submitInput, validateInput]
  )

  useEffect(() => {
    if (error) {
      dispatch(createOrderMutation())
    } else {
      dispatch(markOrderMutationFinished())
    }
  }, [dispatch, error])

  return (
    <FormControl
      error={showError}
      sx={{
        marginBottom: 0,
      }}
    >
      <FormInputLabel
        required={
          order?.customerOrderReference?.isMandatory || !order?.isEditAllowed
        }
      >
        {order?.type === QUOTE
          ? i18next.t('shoppingLabels.quoteReference')
          : i18next.t('shoppingLabels.orderReference')}
      </FormInputLabel>

      <FormInputTextAsync
        data-t-id="order-customer-order-reference"
        ref={inputRef}
        defaultValue={defaultValue}
        posting={posting}
        readOnly={!order?.isEditAllowed}
        {...eventHandlers}
      />

      {showError && <FormFieldText type="error">{error}</FormFieldText>}
    </FormControl>
  )
}

export default OrderFieldCustomerOrderReference
