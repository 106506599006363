import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Text } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import i18next from 'i18next'
import { AxiosError } from 'axios'
import {
  requestAccount,
  RequestAccountResponseData,
} from '../../../services/rest/sitecore/account'
import RichText from '../../atoms/RichText/RichText'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  HiddenField,
  SelectCountryField,
  SubmitButton,
  SubmitError,
  TextField,
} from '../../atoms/FormFields'
import {
  mapFormDataToRequestData,
  mapResponseErrorsToFormErrors,
} from '../../../helpers/requestAccountFormHelper'
import useReturnUrl from '../../../hooks/useReturnUrl'
import { required } from '../../../helpers/formFieldHelper'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import { URL_DEALER_CODE_PARAM } from '../../../constants/urlConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import FormGroup from '../../atoms/FormControls/FormGroup'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { navigateClient } from '../../../helpers/dom'
import { RequestAccountDatasource } from './RequestAccount'

export interface RequestAccountFormData {
  consent?: boolean
  customerCompanyAddress?: string
  customerCompanyCity?: string
  customerCompanyCountryCode?: string
  customerCompanyName?: string
  vatNumber?: string
  chamberOfCommerceNumber?: string
  customerCompanyPostalCode?: string
  dealerCompanyLocationCode?: string
  personEmail?: string
  personFirstName?: string
  personLastName?: string
  personPhoneNumber?: string
  selectedDealerCompany?: string
}

interface RequestAccountFormProps {
  datasource: RequestAccountDatasource
}

const RequestAccountForm = ({ datasource }: RequestAccountFormProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const client = useAxiosClient(AxiosClientType.SitecoreApi)
  const returnUrl = useReturnUrl()

  const history = useHistory()
  const dealerLocationCode = useMemo(
    () => getUrlQueryValue(URL_DEALER_CODE_PARAM, history.location.search),
    [history.location.search]
  )

  const i18nFormErrorFormatter = useCallback(
    (ruleName: string, args: string[]) =>
      i18next.t(`formValidation.${ruleName}`, { args }),
    []
  )

  const requiredValidation = useCallback(
    (value: string) => required(i18nFormErrorFormatter)(value),
    [i18nFormErrorFormatter]
  )

  const genericFormError = useCallback(
    (customError?: string | null) => ({
      [FORM_ERROR]: customError || datasource.errorText,
    }),
    [datasource]
  )

  const trackEvent = useCallback((label: string) => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FORMS,
      action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.FORM_ENGAGEMENT,
      title: 'requestAccount',
      engaged_field: label,
    })
  }, [])

  const trackSubmitEvent = useCallback((label: string) => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FORMS,
      action: label,
      title: 'requestAccount',
    })
  }, [])

  const onSubmit = useCallback(
    async (formData: RequestAccountFormData) => {
      if (!returnUrl || !executeRecaptcha) return undefined

      try {
        const token = await executeRecaptcha('requestAccount')

        const { data } = await requestAccount(
          client,
          mapFormDataToRequestData(formData, returnUrl),
          token
        )

        if (data.isSuccessful) {
          trackSubmitEvent(DATA_LAYER.CUSTOM_DIMENSION.ACTION.SUCCESSFUL)

          navigateClient(
            new URL(datasource.thankYouPage.url, window.location.href).toString()
          )
        } else {
          trackSubmitEvent(DATA_LAYER.CUSTOM_DIMENSION.ACTION.FAILED_SUBMIT)

          return genericFormError(data?.message)
        }
      } catch (error) {
        const err = error as AxiosError
        const data = err.response?.data as RequestAccountResponseData

        trackSubmitEvent(DATA_LAYER.CUSTOM_DIMENSION.ACTION.FAILED_SUBMIT)

        // Return mapped validation errors when there are any
        if (data?.errors?.length) {
          return mapResponseErrorsToFormErrors(data.errors, i18nFormErrorFormatter)
        }

        if (!data.isSuccessful && data?.message) {
          return genericFormError(data.message)
        }

        return genericFormError()
      }

      return undefined
    },
    [
      datasource.thankYouPage.url,
      trackSubmitEvent,
      i18nFormErrorFormatter,
      executeRecaptcha,
      genericFormError,
      returnUrl,
    ]
  )

  return (
    <>
      <Text
        as="h2"
        variant="heading2"
        sx={{
          color: 'gray.3',
          marginBottom: 4,
          opacity: !dealerLocationCode ? 0.4 : undefined,
        }}
      >
        {getTextFieldValue(datasource.title)}
      </Text>

      {!dealerLocationCode && (
        <Text as="p" sx={{ opacity: 0.4 }}>
          {getTextFieldValue(datasource.selectDealerNotice)}
        </Text>
      )}

      {dealerLocationCode && (
        <Form
          initialValues={{
            dealerCompanyLocationCode: dealerLocationCode,
          }}
          subscription={{ submitting: true, pristine: true }}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <TextField
                  validate={requiredValidation}
                  name="customerCompanyName"
                  label={i18next.t('formFields.companyName')}
                  onClick={() => trackEvent('customerCompanyName')}
                />

                <TextField
                  required={false}
                  name="vatNumber"
                  label={i18next.t('formFields.vatNumber')}
                  onClick={() => trackEvent('vatNumber')}
                />

                <TextField
                  required={false}
                  name="chamberOfCommerceNumber"
                  label={i18next.t('formFields.chamberOfCommerceNumber')}
                  onClick={() => trackEvent('chamberOfCommerceNumber')}
                />
              </FormGroup>

              <FormGroup
                title={getTextFieldValue(datasource.addressInformationTitle)}
              >
                <TextField
                  validate={requiredValidation}
                  name="customerCompanyAddress"
                  label={i18next.t('formFields.address')}
                  onClick={() => trackEvent('customerCompanyAddress')}
                />

                <TextField
                  validate={requiredValidation}
                  name="customerCompanyPostalCode"
                  label={i18next.t('formFields.postalCode')}
                  onClick={() => trackEvent('customerCompanyPostalCode')}
                />

                <TextField
                  validate={requiredValidation}
                  name="customerCompanyCity"
                  label={i18next.t('formFields.city')}
                  onClick={() => trackEvent('customerCompanyCity')}
                />

                <SelectCountryField
                  validate={requiredValidation}
                  name="customerCompanyCountryCode"
                  label={i18next.t('formFields.country')}
                  placeholder={i18next.t('formFields.selectCountryPlaceholder')}
                  onChange={() => trackEvent('customerCompanyCountryCode')}
                />

                <HiddenField
                  validate={requiredValidation}
                  name="dealerCompanyLocationCode"
                />
              </FormGroup>

              <FormGroup
                title={getTextFieldValue(datasource.contactInformationTitle)}
              >
                <TextField
                  validate={requiredValidation}
                  name="personFirstName"
                  label={i18next.t('formFields.firstName')}
                  onClick={() => trackEvent('personFirstName')}
                />

                <TextField
                  validate={requiredValidation}
                  name="personLastName"
                  label={i18next.t('formFields.lastName')}
                  onClick={() => trackEvent('personLastName')}
                />

                <TextField
                  validate={requiredValidation}
                  name="personEmail"
                  label={i18next.t('formFields.email')}
                  onClick={() => trackEvent('personEmail')}
                />

                <TextField
                  validate={requiredValidation}
                  name="personPhoneNumber"
                  label={i18next.t('formFields.phoneNumber')}
                  onClick={() => trackEvent('personPhoneNumber')}
                />
              </FormGroup>

              <Text as="p" color="gray.2" variant="bodySmall" marginBottom="4">
                {getTextFieldValue(datasource.consentText)}
              </Text>

              <SubmitError />

              <SubmitButton>
                {getTextFieldValue(datasource.submitLabel)}
              </SubmitButton>

              <Text variant="bodySmall" color="gray.2" marginBottom={4}>
                <RichText textField={datasource.privacyStatementText} />
              </Text>
            </form>
          )}
        />
      )}
    </>
  )
}

export default RequestAccountForm
