import React, { FC } from 'react'
import { Box, Flex } from 'theme-ui'
import Skeleton from '../../atoms/Skeleton/Skeleton'

const ContentCardSkeleton: FC = () => (
  <Box sx={{ display: 'block', userDrag: 'none', userSelect: 'none' }}>
    <Flex
      pr={[2, 2, 4]}
      sx={{
        flexDirection: 'column',
      }}
    >
      <Skeleton>
        <Box
          sx={{
            paddingTop: '56.25%', // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
            position: 'relative',
            mb: [2, 2, 3],
          }}
        />
      </Skeleton>

      <Skeleton>
        <Box sx={{ height: '14px', width: '100%' }} />
      </Skeleton>
    </Flex>
  </Box>
)

export default ContentCardSkeleton
