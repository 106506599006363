import React, { Children, cloneElement, FC, ReactElement, ReactNode } from 'react'

interface HotjarWhitelistProps {
  children: ReactNode
  condition?: boolean
}

const HotjarWhitelist: FC<HotjarWhitelistProps> = ({
  children,
  condition = true,
}) => {
  const elements = Children.toArray(children)

  if (!elements.length || !condition) return <>{children}</>

  if (elements.length === 1) {
    return cloneElement(elements[0] as ReactElement, { 'data-hj-whitelist': true })
  }

  return (
    <>
      {elements.map((child) =>
        cloneElement(child as ReactElement, { 'data-hj-whitelist': true })
      )}
    </>
  )
}

export default HotjarWhitelist
