import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { ApiVariant } from '../../../types/layoutService'
import { PROMOTION_API_TYPE_DEALS } from '../../../constants/promotionConstants'

const fetchPromotions = (
  apiVariant: ApiVariant,
  client: AxiosInstance,
  cancelToken: CancelToken,
  customerCompanyId: number,
  supplierCompanyId: number,
  user?: UserProps,
  isImpersonated?: boolean
): Promise<AxiosResponse<string[]>> =>
  client.get(
    apiVariant === PROMOTION_API_TYPE_DEALS ? '/ActiveDeals/' : '/ActivePromotions/',
    {
      params: {
        customerCompanyId,
        supplierCompanyId,
        isImpersonated: !!isImpersonated,
      },
      ...createRequestConfig(user),
      cancelToken,
    }
  )

export default fetchPromotions
