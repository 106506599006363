import { getCommercePartUri } from '../../../../helpers/commerceApiHelpers'
import { DealerPartFulfilled } from '../../../../hooks/services/rest/ecommerce/useDealerPartSearchService'
import { PartFulFilled } from '../../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { CommerceBrand, CommerceCrossSellPart } from '../../../../types/commerceApi'
import { Image, Part } from '../../../../types/dafResponseProps'

const resolveBrandImageUrl = (brand?: CommerceBrand) =>
  brand?.image?.thumbnailImageUrl || brand?.image?.imageUrl

const resolveImageUrl = (images?: Image[]) =>
  images?.[0].imageUrl || images?.[0].thumbnailImageUrl

export const adaptPartFullFilledToPartCardProps = ({
  part,
  searchPart,
  uri,
}: PartFulFilled) => ({
  partDescription: part?.description,
  partNumber: searchPart.partNumber,
  displayPartNumber: part?.displayPartNumber,
  imageUrl: resolveImageUrl(part?.images),
  matchMetaData: searchPart.matchMetaData,
  brandImageUrl: resolveBrandImageUrl(part?.brand),
  brandName: part?.brand?.description,
  categoryId: part?.category?.mainCategoryId,
  subcategoryId: part?.category?.subCategoryId,
  packageUnitQuantity: part?.specification?.packaging?.packageUnitQuantity,
  uri,
})

export const adaptDealerPartFullFilledToPartCardProps = ({
  dealerPart,
  searchDealerPart,
  uri,
}: DealerPartFulfilled) => ({
  partDescription: dealerPart?.partName,
  partNumber: searchDealerPart.partNumber,
  imageUrl: dealerPart?.partImages?.[0]?.imageUrl,
  matchMetaData: searchDealerPart.matchMetaData,
  brandName: dealerPart?.brandName,
  uri,
})

export const adaptPartToPartCardProps = (part: Part) => ({
  partDescription: part.description,
  partEnglishDescription: part.englishDescription,
  partNumber: part.partNumber || '',
  displayPartNumber: part?.displayPartNumber,
  imageUrl: resolveImageUrl(part?.images),
  brandImageUrl: resolveBrandImageUrl(part?.brand),
  brandName: part?.brand?.description,
  categoryId: part?.category?.mainCategoryId,
  subcategoryId: part?.category?.subCategoryId,
  packageUnitQuantity: part?.specification?.packaging?.packageUnitQuantity,
  uri: getCommercePartUri(
    part.category?.mainCategoryId,
    part.category?.subCategoryId,
    part.partNumber
  ),
})

export const adaptCrossSellPartToPartCardProps = (
  crossSellPart: CommerceCrossSellPart
) => ({
  partDescription: crossSellPart.description,
  partNumber: crossSellPart.partNumber,
  displayPartNumber: crossSellPart?.displayPartNumber,
  imageUrl: resolveImageUrl(crossSellPart?.images),
  brandImageUrl: resolveBrandImageUrl(crossSellPart?.brand),
  brandName: crossSellPart?.brand?.description,
  categoryId: crossSellPart?.category?.mainCategoryId,
  subcategoryId: crossSellPart?.category?.subCategoryId,
  packageUnitQuantity: crossSellPart?.specification?.packaging?.packageUnitQuantity,
  uri: getCommercePartUri(
    crossSellPart.category?.mainCategoryId,
    crossSellPart.category?.subCategoryId,
    crossSellPart.partNumber
  ),
})
