export const getPageTitle = (
  siteTitle = '',
  pageTitle = '',
  separator = '|'
): string =>
  [
    decodeURIComponent(siteTitle?.trim() || ''),
    decodeURIComponent(pageTitle?.trim() || ''),
  ]
    .filter((s) => !!s)
    .join(` ${decodeURIComponent(separator?.trim() || '')} `)
