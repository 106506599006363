import React, { FC } from 'react'
import { Box } from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { ImageWithFocalPointField } from '../../../types/layoutService'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'

export interface CallToActionImageProps {
  imageField?: ImageWithFocalPointField
}

const CallToActionImage: FC<CallToActionImageProps> = ({ imageField }) => {
  const { pageEditing } = useSitecoreContext()

  return (
    <Box
      sx={{
        paddingTop: '56.25%', // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625
        mb: [3, 3, 4],
        width: '100%',
        height: 'auto',
        position: 'relative',
        ...(pageEditing && !imageField?.src
          ? { backgroundColor: 'black', opacity: 0.16 }
          : {}),
      }}
    >
      <Box
        as="picture"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}
      >
        <SitecoreImage imageField={imageField} />
      </Box>
    </Box>
  )
}

export default CallToActionImage
