import { Input, InputProps } from 'theme-ui'
import React, { forwardRef, PropsWithChildren } from 'react'
import { getBackgroundColor, getTextColor } from '../../../helpers/formInputHelper'
import { COLORS } from '../../../constants/themeConstants'
import { alphaColor } from '../../../helpers/themeHelper'

export interface FormInputProps extends Omit<InputProps, 'css'> {
  disabled?: boolean
  required?: boolean
  error?: boolean
  id?: string
  name?: string
  placeholder?: string
  type?: string
}

export interface FormInputBaseProps extends FormInputProps {}

const FormInputBase = forwardRef<
  HTMLInputElement,
  PropsWithChildren<FormInputBaseProps>
>(({ disabled = false, readOnly = false, error = false, sx, ...rest }, ref) => {
  return (
    <Input
      ref={ref}
      disabled={disabled}
      readOnly={readOnly}
      sx={{
        borderWidth: 1,
        borderColor: error ? 'error' : 'darkGray',
        borderStyle: 'solid',
        borderRadius: 'default',
        transition: 'ease all 200ms',
        width: '100%',
        backgroundColor: getBackgroundColor(disabled || readOnly),
        color: getTextColor(error, disabled),
        font: 'inherit',
        fontSize: 1,
        lineHeight: 'buttonText',
        outline: 0,
        paddingX: 3,
        paddingY: 2,
        '::placeholder': {
          color: alphaColor(COLORS.GRAY, 0.4),
        },
        MozAppearance: 'textfield',
        '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        ':focus': {
          borderColor: error ? 'error' : 'dark',
        },
        ...sx,
      }}
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="off"
      {...rest}
    />
  )
})

export default FormInputBase
