import React, { FC } from 'react'
import ExperienceEditorMessage from '../../molecules/ExperienceEditor/ExperienceEditorMessage'

interface EmptyGridBoxProps {
  componentName: string
}

const EmptyGridBox: FC<EmptyGridBoxProps> = ({ componentName }) => (
  <ExperienceEditorMessage
    title={`Unable to load "${componentName}", no data found.`}
    text="This block is only visible in page editing mode."
    marginBottom={[2, 2, 5]}
  />
)

export default EmptyGridBox
