import { alphaColor } from '../helpers/themeHelper'

export const DAF_THEME = 'daf'
export const TRP_THEME = 'trp'

export const COLORS = {
  DAF_DARK_BLUE: '#004482',
  DAF_BLUE: '#00529C',
  TRP_BLUE: '#003087',
  TRP_DARK_BLUE: '#002871',
  ORANGE: '#FFBF19',
  ORANGE_DARK: '#E19500',
  PROMOTION: '#FF7A19',
  PROMOTION_DARK: '#D66615',
  ALERT: '#D50017',
  ALERT_LIGHT: '#F8D6DA',
  ALERT_DARK: '#AA0012',
  CONFIRMATION: '#028700',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#F3F3F3',
  MEDIUM_GRAY: '#E5E5E5',
  DARK_GRAY: '#949494',
  GRAY: '#5C5C5C',
  MEDIUM_DARK: '#353C45',
  DARK: '#242424',
  BLACK: '#000000',
  GREEN: '#3AAA38',
  GREEN_LIGHT: '#EFF8EF',
  CREDIT_DARK_BLUE: '#001F3C',
}

export const SCROLL_BAR_WIDTH = '10px'
export const SCROLL_TRACK_COLOR = alphaColor(COLORS.BLACK, 0.08)
export const SCROLL_THUMB_COLOR = alphaColor(COLORS.BLACK, 0.16)
export const SCROLL_THUMB_HOVER_COLOR = alphaColor(COLORS.BLACK, 0.32)

export const RTL_READING_LANGUAGES = ['ar-eg']

export const BREAKPOINT_XS = 'xs'
export const BREAKPOINT_S = 's'
export const BREAKPOINT_M = 'm'
export const BREAKPOINT_L = 'l'
export const BREAKPOINT_XL = 'xl'
export const BREAKPOINT_XXL = 'xxl'

export const BREAKPOINT_XS_VALUE = 0
export const BREAKPOINT_S_VALUE = 640
export const BREAKPOINT_M_VALUE = 832
export const BREAKPOINT_L_VALUE = 1024
export const BREAKPOINT_XL_VALUE = 1280
export const BREAKPOINT_XXL_VALUE = 1680

export const BREAKPOINTS = [
  { label: BREAKPOINT_XS, value: BREAKPOINT_XS_VALUE },
  { label: BREAKPOINT_S, value: BREAKPOINT_S_VALUE },
  { label: BREAKPOINT_M, value: BREAKPOINT_M_VALUE },
  { label: BREAKPOINT_L, value: BREAKPOINT_L_VALUE },
  { label: BREAKPOINT_XL, value: BREAKPOINT_XL_VALUE },
  { label: BREAKPOINT_XXL, value: BREAKPOINT_XXL_VALUE },
]
