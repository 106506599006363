import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  ImageWithFocalPointField,
  LinkField,
  TextField,
} from '../../../types/layoutService'
import { COLORS } from '../../../constants/themeConstants'
import Container from '../../atoms/Container/Container'
import SitecoreButton from '../SitecoreButton/SitecoreButton'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import { alphaColor } from '../../../helpers/themeHelper'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'

export interface HeroBannerWithUSPDatasource {
  title: TextField
  subtitle?: TextField
  buttonLeft?: LinkField
  buttonRight?: LinkField
  image?: ImageWithFocalPointField
  uSP1?: TextField
  uSP2?: TextField
  uSP3?: TextField
  uSP4?: TextField
}

const HeroBannerWithUSP = ({
  datasource: {
    title,
    subtitle,
    image,
    buttonLeft,
    buttonRight,
    uSP1,
    uSP2,
    uSP3,
    uSP4,
  },
}: Datasource<HeroBannerWithUSPDatasource>) => {
  // const { space } = useTheme()

  const HeroBannerUSPItem = (usp: TextField) => (
    <Flex sx={{ alignItems: 'center', gap: 3 }}>
      <Flex
        sx={{
          inlineSize: '20px',
          blockSize: '20px',
          minBlockSize: '20px',
          minInlineSize: '20px',
          borderRadius: '50%',
          background: COLORS.WHITE,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconWrapper icon={ICONS.CHECK} size={3} />
      </Flex>
      <Text
        color={COLORS.WHITE}
        sx={{
          fontSize: 1,
        }}
      >
        {usp.value}
      </Text>
    </Flex>
  )

  return (
    <Box
      sx={{
        background: `linear-gradient(to left, rgb(0 0 0  / 0), rgb(0 0 0 / 12.5%), rgb(0 0 0 / 50%)), ${COLORS.DAF_BLUE}`,
      }}
    >
      <Container>
        <Box
          sx={{
            paddingBlock: [4, 4, 7],
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: [null, null, '4fr 6fr'],
              gap: 3,
              alignItems: 'center',
            }}
          >
            <Box>
              <Text
                as="h1"
                color={COLORS.WHITE}
                sx={{
                  fontWeight: 'normal',
                  fontSize: [5, 5, 6],
                }}
              >
                {title.value}
              </Text>
              <Text
                sx={{
                  marginBlockStart: 2,
                  fontWeight: 'normal',
                  fontSize: [2, 2, 3],
                }}
                as="p"
                color={COLORS.WHITE}
              >
                {subtitle?.value}
              </Text>
              <Flex sx={{ gap: 2, marginBlockStart: 4 }}>
                <Box>
                  <SitecoreButton variant="outline" linkField={buttonLeft} />
                </Box>
                <Box>
                  <SitecoreButton
                    variant="ghost"
                    sx={{ color: 'white', borderColor: 'white' }}
                    linkField={buttonRight}
                  />
                </Box>
              </Flex>
            </Box>
            <Box sx={{ display: ['none', 'none', 'block'] }}>
              <SitecoreImage
                imageField={image}
                sizes="630px"
                sx={{
                  maxWidth: '630px',
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{ borderTop: '1px solid', borderColor: alphaColor(COLORS.WHITE, 0.3) }}
      >
        <Container>
          <Box
            sx={{
              gap: 3,
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit,minmax(min(200px,100%),1fr))',
              marginBlockStart: 'auto',
              paddingBlock: 3,
            }}
          >
            {uSP1 && <Box>{HeroBannerUSPItem(uSP1)}</Box>}
            {uSP2 && <Box>{HeroBannerUSPItem(uSP2)}</Box>}
            {uSP3 && <Box>{HeroBannerUSPItem(uSP3)}</Box>}
            {uSP4 && <Box>{HeroBannerUSPItem(uSP4)}</Box>}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default withData(HeroBannerWithUSP)
