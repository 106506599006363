import { useContext, useMemo } from 'react'
import { HEADER_AUTHORIZATION } from '../../../../constants/httpConstants'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CULTURE_CODE,
} from '../../../../constants/userConstants'
import { getBearerToken } from '../../../../helpers/userHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import {
  BaseGraphServiceHeaders,
  BaseGraphServiceVariables,
} from '../../../../types/graphServiceTypes'
import { UserProps } from '../../../../types/userProps'
import useSitecoreContext from '../../../useSitecoreContext'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createHeaders = (user?: UserProps, customHeaders?: any): any => {
  const headers = { ...customHeaders }

  const accessToken = getBearerToken(user)
  if (accessToken) {
    headers[HEADER_AUTHORIZATION] = accessToken
  }

  return headers
}

export interface UseBaseGraphQLServiceOptions {
  requireAuthentication?: boolean
}

export const useBaseGraphQLServiceHeaders = (user?: UserProps) =>
  useMemo(() => createHeaders(user), [user])

const useBaseGraphQLService = (
  options?: UseBaseGraphQLServiceOptions
): [BaseGraphServiceHeaders, BaseGraphServiceVariables | undefined] => {
  const { user } = useContext(UserContext)

  const { languageContext } = useSitecoreContext()

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const variables = useMemo(() => {
    const countryCode = languageContext?.countryCode ?? DEFAULT_COUNTRY_CODE
    const cultureCode = languageContext?.cultureCode ?? DEFAULT_CULTURE_CODE

    // Anonymous users (trp.eu)
    if (!user && !options?.requireAuthentication) {
      return { cultureCode, countryCode }
    }

    // Authenticated users (parts.daf.com)
    if (user && actingCompanyId && actingSupplierId) {
      return {
        countryCode,
        cultureCode,
        customerCompanyId: actingCompanyId,
        supplierCompanyId: actingSupplierId,
        isImpersonated,
      }
    }

    return undefined
  }, [actingCompanyId, actingSupplierId, isImpersonated, languageContext, user])

  const headers = useBaseGraphQLServiceHeaders(user)

  return [headers, variables]
}

export default useBaseGraphQLService
