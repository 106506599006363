import React, { FC, useEffect, useMemo } from 'react'
import { Text } from 'theme-ui'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'
import i18next from 'i18next'
import { ICONS } from '../../../constants/iconConstants'
import { Flag } from '../../../types/layoutService'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Link from '../../atoms/Link/Link'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import { PartContext } from '../../../types/sitecoreContextProps'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'

interface BackToSearchProps {
  targetPage?: Flag
  startPage?: Flag
}

interface PersistedSearchContext {
  location: Location
  partContext: PartContext
}

const SESSION_STORAGE_PERSISTED_SEARCH_CONTEXT_KEY = 'persistedSearchContext'

export const persistedSearchContextHelper = (
  startPage = false,
  targetPage = false
) => {
  const persistedSearchContext = {
    set: (location: Location, partContext: PartContext) => {
      const previousContext =
        startPage && targetPage ? persistedSearchContext.get() : [] // only persist previous context when on a sub-category page

      if (
        previousContext.length < 2 // if we don't have the persisted search context of a sub-category page
      ) {
        const newContext = [...previousContext, { location, partContext }]

        sessionStorage.setItem(
          SESSION_STORAGE_PERSISTED_SEARCH_CONTEXT_KEY,
          JSON.stringify(newContext)
        )
      } else if (
        previousContext.length === 2 &&
        previousContext?.[0]?.partContext?.category !== partContext?.category
      ) {
        const newContext = [{ location, partContext }]

        sessionStorage.setItem(
          SESSION_STORAGE_PERSISTED_SEARCH_CONTEXT_KEY,
          JSON.stringify(newContext)
        )
      }
    },
    get: (): PersistedSearchContext[] => {
      const persistedContext: PersistedSearchContext[] = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_PERSISTED_SEARCH_CONTEXT_KEY) || '[]'
      )

      return persistedContext
    },
    clear: () => {
      sessionStorage.removeItem(SESSION_STORAGE_PERSISTED_SEARCH_CONTEXT_KEY)
    },
    getLink: (partContext: PartContext) => {
      const context = persistedSearchContext.get().pop()

      if (
        !(
          startPage &&
          targetPage &&
          partContext.category !== context?.partContext.category &&
          partContext.subcategory &&
          context?.partContext?.subcategory
        ) &&
        context
      )
        return `${context.location.pathname}${context.location.search}`

      return undefined
    },
  }

  return persistedSearchContext
}

const BackToSearch: FC<BackToSearchProps> = ({
  startPage: rawStartPage,
  targetPage: rawTargetPage,
}) => {
  const location = useLocation()
  const partContext = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  const startPage = rawStartPage === '1'
  const targetPage = rawTargetPage === '1'

  const persistedSearchContext = persistedSearchContextHelper(startPage, targetPage)

  const link = useMemo(
    () => persistedSearchContext.getLink(partContext),
    [partContext, persistedSearchContext]
  )

  useEffect(() => {
    if (startPage) {
      persistedSearchContext.set(location, partContext)
    }

    if (
      !startPage &&
      !targetPage // clear on any other page
    ) {
      persistedSearchContext.clear()
    }
  }, [location, partContext, persistedSearchContext, startPage, targetPage])

  if (!persistedSearchContext || !targetPage) return null

  if (!link) return null

  return (
    <Link
      href={link}
      data-t-id="breadcrumb-previous"
      onClick={persistedSearchContext.clear}
      sx={{
        display: ['none', 'none', 'none', 'flex'],
        alignItems: 'center',
        color: 'primary',
        textDecoration: 'none',
        svg: { marginLeft: '-4px' },
      }}
    >
      <IconWrapper icon={ICONS.ARROW_LEFT} size={1} />

      <Text
        as="span"
        variant="smallText"
        sx={{
          pl: 1,
          pr: 2,
          mr: 2,
          borderRightColor: 'gray.1',
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
        }}
      >
        {i18next.t('paginationLabels.previous')}
      </Text>
    </Link>
  )
}

export default withClientSideRender(BackToSearch)
