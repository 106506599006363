import React, { ReactNode } from 'react'
import { Box, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import Divider from '../../atoms/Divider/Divider'

interface MenuListActionProps {
  href: string
  currentlySelected?: boolean
  children: ReactNode
}

const MenuListAction = ({
  href,
  children,
  currentlySelected = false,
}: MenuListActionProps) => {
  const { mainNavigation, colors } = useTheme()

  return (
    <Box as="li" sx={{ listStyle: 'none' }}>
      <ClickableWrapper
        as="a"
        href={href}
        sx={{
          display: 'block',
          position: 'relative',
          bg: 'background',
          px: [3, 3, 5],
          py: [3, 3, 2],
          width: '100%',
          justifyContent: 'start',
          color: currentlySelected ? colors.primary : 'gray.3',
          outline: 'none',
          ':hover, :active, :focus': {
            color: [
              mainNavigation.textColor,
              mainNavigation.textColor,
              `${colors.primary} !important`,
            ],
          },
          '::before, ::after': {
            position: 'absolute',
            content: '""',
            display: ['block', 'block', 'none'],
            top: '50%',
          },
          '::before': {
            right: 0,
            borderRadius: '50%',
            border: '1px solid',
            borderColor: mainNavigation.dividerColor,
            transform: 'translateY(-50%)',
            bg: currentlySelected ? mainNavigation.dividerColor : 'transparent',
            width: 20,
            height: 20,
          },
          '::after': {
            right: '7px',
            borderRight: '2px solid',
            borderBottom: '2px solid',
            borderColor: currentlySelected
              ? mainNavigation.background
              : 'transparent',
            transform: 'translateY(calc(-50% - 1px)) rotate(45deg)',
            width: 5,
            height: 8,
          },
        }}
      >
        <Text
          variant="bodySmall"
          sx={{ whiteSpace: 'nowrap', lineHeight: 'menuList' }}
        >
          {children}
        </Text>
      </ClickableWrapper>

      <Divider
        sx={{
          display: ['block', 'block', 'none'],
        }}
        color={mainNavigation.dividerColor}
        opacity={mainNavigation.dividerOpacity}
      />
    </Box>
  )
}

export default MenuListAction
