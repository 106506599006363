import React, { FC } from 'react'
import { BoxProps, Text } from 'theme-ui'
import { Bullet } from '../../../types/dafResponseProps'
import ListBase from '../ListBase/ListBase'

interface ListProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  items: Bullet[]
}

const List: FC<ListProps> = (props) => {
  const { items } = props

  return (
    <ListBase {...props}>
      {items &&
        items.map(({ description }, i) => (
          <Text
            as="li"
            variant="bodyContent"
            key={i.toString()}
            sx={{
              listStyle: 'none',
              '&::before': {
                content: '"\u2022"',
                color: 'text',
                fontWeight: 'bold',
                display: 'inline-block',
                width: '1em',
                marginLeft: '-1em',
              },
            }}
          >
            {description}
          </Text>
        ))}
    </ListBase>
  )
}

export default List
