import React, { forwardRef, PropsWithChildren } from 'react'
import { Image as RebassImage, ImageProps as RebassImageProps } from 'theme-ui'

export interface ImageProps
  extends Pick<
    RebassImageProps,
    Exclude<keyof RebassImageProps, 'css' | 'onClick'>
  > {
  src?: string
  variant?: string
  borderRadius?: number
}

const Image = forwardRef<HTMLImageElement, PropsWithChildren<ImageProps>>(
  ({ borderRadius, sx, ...manipulatedProps }, ref) => (
    <RebassImage
      ref={ref}
      {...manipulatedProps}
      sx={{
        borderRadius,
        objectFit: 'cover',
        ...sx,
      }}
    />
  )
)

export default Image
