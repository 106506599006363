import React, { createContext, ReactNode } from 'react'
import useMenuItemListService, {
  MenuItem,
} from '../hooks/services/graphql/useMenuItemListService'

export interface MenuProviderContextProps {
  fetching: boolean
  menu?: MenuItem[]
}

export const MenuProviderContext = createContext<MenuProviderContextProps>({
  fetching: false,
  menu: undefined,
})

interface MenuProviderProps {
  children: ReactNode
}

const MenuProvider = ({ children }: MenuProviderProps) => {
  const [fetching, menu] = useMenuItemListService()

  return (
    <MenuProviderContext.Provider
      value={{
        fetching,
        menu,
      }}
    >
      {children}
    </MenuProviderContext.Provider>
  )
}

export default MenuProvider
