import {
  CLOSE_R2C_JOBSHEET_REFERENCE_SIDEPANEL,
  CLOSE_R2C_TASK_SIDEPANEL,
  OPEN_R2C_JOBSHEET_REFERENCE_SIDEPANEL,
  OPEN_R2C_TASK_SIDEPANEL,
} from '../constants/r2cConstants'

export interface OpenR2CTaskSidepanel {
  type: typeof OPEN_R2C_TASK_SIDEPANEL
  payload: {
    showSidepanel: boolean
    partNumber: string
  }
}
export interface CloseR2CTaskSidepanel {
  type: typeof CLOSE_R2C_TASK_SIDEPANEL
  payload: boolean
}

export interface OpenR2CJobsheetSidepanel {
  type: typeof OPEN_R2C_JOBSHEET_REFERENCE_SIDEPANEL
  payload: {
    showSidepanel: boolean
    partNumber: string
  }
}
export interface CloseR2CJobsheetSidepanel {
  type: typeof CLOSE_R2C_JOBSHEET_REFERENCE_SIDEPANEL
  payload: boolean
}

export type R2CActions =
  | OpenR2CTaskSidepanel
  | CloseR2CTaskSidepanel
  | OpenR2CJobsheetSidepanel
  | CloseR2CJobsheetSidepanel

export const toggleR2CTaskSidepanel = (
  showSidepanel: boolean,
  partNumber?: string
) => ({
  type: showSidepanel ? OPEN_R2C_TASK_SIDEPANEL : CLOSE_R2C_TASK_SIDEPANEL,
  payload: showSidepanel ? { showSidepanel, partNumber } : { showSidepanel },
})

export const toggleR2CJobsheetSidepanel = (
  showSidepanel: boolean,
  partNumber?: string
) => ({
  type: showSidepanel
    ? OPEN_R2C_JOBSHEET_REFERENCE_SIDEPANEL
    : CLOSE_R2C_JOBSHEET_REFERENCE_SIDEPANEL,
  payload: showSidepanel ? { showSidepanel, partNumber } : { showSidepanel },
})
