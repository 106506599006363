import React, { ReactNode } from 'react'
import { Box, Flex } from 'theme-ui'

interface PartSearchFilterChipContainerProps {
  children: ReactNode
}

const PartSearchFilterChipContainer = ({
  children,
}: PartSearchFilterChipContainerProps) => (
  <Box
    sx={{
      flexGrow: 1,
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      WebkitOverflowScrolling: 'touch',
    }}
  >
    <Flex
      sx={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', mb: -2 }}
    >
      {children}
    </Flex>
  </Box>
)

export default PartSearchFilterChipContainer
