import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box } from 'theme-ui'
import Divider from '../../atoms/Divider/Divider'
import withData from '../../../enhancers/withData'
import FooterLegalLinks from './FooterLegalLinks'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import FooterCaption from './FooterCaption'
import FooterColumns from './FooterColumns'
import StaticFooterLegalLinks from './StaticFooterLegalLinks'

interface FooterDatasource {
  brandTitle: TextField
  socialChannelsTitle: TextField
  socialChannels: LinkField<SocialChannels>
  footerLegalLinks: LinkField<FooterLegalLinkItem>
}

export interface FooterLegalLinkItem {
  items: {
    results: FooterLegalLinkTargetItem[]
  }
}

interface FooterLegalLinkTargetItem {
  linkItem: FooterItem
}

interface FooterProps {
  navigationItems: FooterItems
}

export interface SocialChannels {
  linkedIn: TextField
  facebook: TextField
  x: TextField
  youtube: TextField
  instagram: TextField
  flickr: TextField
}

export interface FooterItems {
  items: FooterItem[]
}

export interface FooterItem {
  title: string
  url: string
  target: string
  children: FooterItem[]
}

const Footer: FC<Datasource<FooterDatasource> & FooterProps> = ({
  datasource,
  navigationItems,
}) => {
  const { footer } = useTheme()
  const { brandTitle, socialChannelsTitle, socialChannels, footerLegalLinks } =
    datasource

  return (
    <Box as="footer" sx={{ position: 'relative' }} bg={footer.background}>
      <Divider color="textLight" sx={{ opacity: 0.16 }} />
      <FooterColumns
        navigationItems={navigationItems}
        socialChannelsTitle={socialChannelsTitle}
        socialChannels={socialChannels}
      />
      {footerLegalLinks?.targetItem?.items &&
        footerLegalLinks?.targetItem?.items?.results?.length > 0 && (
          <StaticFooterLegalLinks items={footerLegalLinks.targetItem.items} />
        )}
      {!footerLegalLinks?.targetItem && <FooterLegalLinks />}
      <Divider color="textLight" sx={{ opacity: 0.16 }} mt={[3, 3, 0]} />
      <FooterCaption brandTitle={brandTitle} />
    </Box>
  )
}

export default withData(Footer)
