import { Action } from 'redux'
import { NotificationSeverity } from '../types/notificationTypes'

export interface NotificationInformation {
  severity: NotificationSeverity
  message: string
}

interface RegisterModalNotificationAction
  extends Action<typeof REGISTER_MODAL_NOTIFICATION> {
  payload: NotificationInformation
}

interface ResetModalNotificationsAction
  extends Action<typeof RESET_MODAL_NOTIFICATIONS> {}

export type NotificationActions =
  | RegisterModalNotificationAction
  | ResetModalNotificationsAction

export const REGISTER_MODAL_NOTIFICATION = 'notification/REGISTER_MODAL_NOTIFICATION'
export const RESET_MODAL_NOTIFICATIONS = 'notification/RESET_MODAL_NOTIFICATIONS'

export const registerModalNotification = (
  severity: NotificationSeverity,
  message: string
): RegisterModalNotificationAction => ({
  type: REGISTER_MODAL_NOTIFICATION,
  payload: {
    severity,
    message,
  },
})

export const resetModalNotifications = (): ResetModalNotificationsAction => ({
  type: RESET_MODAL_NOTIFICATIONS,
})
