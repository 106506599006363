import {
  CUSTOM_URL_CHARACTER_REPLACE_MAP,
  URL_FILTER_DELIMITER,
  URL_FILTER_NAME_VALUE_DELIMITER,
  URL_FILTER_PARAMETER,
  URL_FILTER_VALUE_DELIMITER,
  URL_QUERY_STRING_PARAM,
} from '../constants/urlConstants'
import { runOnWindow } from './dom'
import {
  CATEGORY_FILTER_ID,
  FILTER_MASTER_GROUP_APPLICATION,
} from '../constants/searchConstants'
import {
  ActiveSearchResultFilters,
  FilterGroup,
  FilterOption,
} from '../types/filterProps'
import { SearchDealerRequestFilter } from '../types/dealerServiceTypes'
import { DealerFilter } from '../types/layoutService'
import { Filter } from '../services/rest/ecommerce/dafCompanyVehicles'

export const toggleFilter = (
  currentFilters: ActiveSearchResultFilters,
  filterId: string,
  filterValue: string
) => {
  const newFilterObject = { ...currentFilters }
  const currentFilterGroup = newFilterObject?.[filterId]

  if (!currentFilterGroup) {
    if (filterValue) {
      // create filter group and add filter
      newFilterObject[filterId] = [filterValue]
    }
  } else if (!newFilterObject[filterId].includes(filterValue)) {
    // add new value to existing group if not already exists
    newFilterObject[filterId] = [...currentFilterGroup, filterValue]
  } else {
    // remove from group if already exists
    newFilterObject[filterId] = newFilterObject[filterId].filter(
      (value: string | number) => value !== filterValue
    )
    // remove group if no filters selected in group
    if (!newFilterObject[filterId].length) {
      delete newFilterObject[filterId]
    }
  }
  return newFilterObject
}

export const mapFilterStringToActiveSearchResultFilterObject = (
  filterString?: string
): ActiveSearchResultFilters => mapFilterStringToActiveFilterObject(filterString)

export const mapFilterStringToActiveSearchDealerFilterObject = (
  filterString?: string
): SearchDealerRequestFilter[] =>
  mapFilterStringToActiveFilterObject(filterString, [])

export const mapFilterStringToActiveFilterObject = <Type>(
  filterString?: string,
  returnType: Record<string, unknown> | unknown[] = {}
): Type => {
  if (!filterString) return returnType as Type

  const filters = filterString.split(URL_FILTER_DELIMITER)

  let filterObject = returnType

  filters.forEach((filter) => {
    const filterParts = filter
      .split(URL_FILTER_NAME_VALUE_DELIMITER)
      .filter((segment) => !!segment)

    // skip if there are no filter values supplied
    if (!!filterParts.length && filterParts.length > 1) {
      const id = filterParts[0]
      const values =
        filterParts?.[1]
          .split(URL_FILTER_VALUE_DELIMITER)
          // remove empty values
          .filter((segment) => !!segment)
          .map((value) => customCharacterDecode(value)) ||
        // return empty if no values found
        returnType

      if (Array.isArray(filterObject)) {
        filterObject = [...filterObject, { id, values }]
      } else {
        filterObject = {
          ...filterObject,
          [id]: values,
        }
      }
    }
  })

  return filterObject as Type
}

export const mapDealerFiltersToSearchDealerRequestFilters = (
  dealerFilters: DealerFilter[]
): SearchDealerRequestFilter[] => {
  const searchDealerRequestFilters: SearchDealerRequestFilter[] = []

  dealerFilters.forEach(({ filterType, filterValue }) => {
    const searchDealerRequestFilter: SearchDealerRequestFilter | undefined =
      searchDealerRequestFilters.find(({ id }) => id === filterType.value)

    if (searchDealerRequestFilter) {
      searchDealerRequestFilter.values.push(filterValue.value)
    } else {
      searchDealerRequestFilters.push({
        id: filterType.value,
        values: [filterValue.value],
      })
    }
  })

  return searchDealerRequestFilters
}

/*
  Converts a filter object into a url friendly string
  Returns updated url query string with the new filters and existing query parameters
  Example input: { BrandIds: [ '123', '321', '132' ], ApplicationIds: [ 1, 2, 3 ] }
  Example output: 'filter=BrandIds:123,321,132;ApplicationIds:1,2,3
*/
export const getFilterStringByActiveSearchResultFilterObject = (
  filters: ActiveSearchResultFilters,
  searchParams?: URLSearchParams,
  ignoreQueryString?: boolean,
  excludeCategoryFilter = true
): string => {
  const params =
    searchParams || new URLSearchParams(runOnWindow((w) => w.location.search))
  const filterString = Object.keys(filters)
    .filter((filterName) => {
      if (!excludeCategoryFilter) return true

      return filterName !== CATEGORY_FILTER_ID
    })
    .map((filterName) => {
      const values = filters[filterName]
      if (!values.length) return undefined

      return `${filterName}${URL_FILTER_NAME_VALUE_DELIMITER}${values
        .map((value) => customCharacterEncode(value))
        .join()}`
    })
    .filter((filter) => !!filter)
    .join(URL_FILTER_DELIMITER)

  if (filterString) {
    params.set(URL_FILTER_PARAMETER, filterString)
  } else {
    params.delete(URL_FILTER_PARAMETER)
  }

  if (ignoreQueryString) {
    params.delete(URL_QUERY_STRING_PARAM)
  }

  return params.toString()
}

export const customCharacterEncode = (string: string) => {
  const regex = new RegExp(
    `\\${Object.keys(CUSTOM_URL_CHARACTER_REPLACE_MAP).join('|\\')}`,
    'g'
  )
  return string.replace(
    regex,
    (matched) => CUSTOM_URL_CHARACTER_REPLACE_MAP[matched]
  )
}

export const customCharacterDecode = (string: string) => {
  const regex = new RegExp(
    Object.keys(CUSTOM_URL_CHARACTER_REPLACE_MAP)
      .map((key) => CUSTOM_URL_CHARACTER_REPLACE_MAP[key])
      .join('|'),
    'g'
  )
  return string.replace(
    regex,
    (matched) =>
      Object.keys(CUSTOM_URL_CHARACTER_REPLACE_MAP).find(
        (key) => CUSTOM_URL_CHARACTER_REPLACE_MAP[key] === matched
      ) || matched
  )
}

export const getFilterFromFilterService = (
  name: string,
  filters: FilterGroup[]
): FilterGroup | undefined => filters.find(({ id }) => id === name)

export const filterItemsFromFilterService = (
  idsToExclude: string[],
  filters: FilterGroup[]
): FilterGroup[] =>
  filters?.length ? filters.filter(({ id }) => !idsToExclude.includes(id)) : []

export const orderFiltersByPriority = (filters: FilterGroup[]): FilterGroup[] =>
  (filters.length &&
    filters
      .reduce<FilterGroup[]>(
        (a, b) => [
          ...a,
          {
            ...b,
            filterOptions: b.filterOptions.sort((c, d) => c.priority - d.priority),
          },
        ],
        []
      )
      .sort((a, b) => a.priority - b.priority)) ||
  []

export const separateApplicationFiltersFromFilterGroups = (
  searchResultsFilters: FilterGroup[] | undefined
) =>
  searchResultsFilters?.reduce<[FilterGroup[], FilterGroup[]]>(
    (returnArray, filter) => {
      returnArray[FILTER_MASTER_GROUP_APPLICATION.includes(filter.id) ? 0 : 1].push(
        filter
      )

      return returnArray
    },
    [[], []]
  ) || [undefined, undefined]

export const getApplicationFilterGroupPriority = (
  applicationFilterGroups: FilterGroup[] | undefined
) =>
  applicationFilterGroups
    ? Math.min(...applicationFilterGroups.map(({ priority }) => priority))
    : undefined

export const stripInvalidFilterValues = (
  knownFilterOptions?: FilterOption[],
  appliedFilterOptionValues?: string[]
): string[] => {
  if (!knownFilterOptions || !appliedFilterOptionValues) return []

  const knownFilterOptionValues = knownFilterOptions.map(({ value }) => value)

  return appliedFilterOptionValues.filter((appliedValue) =>
    knownFilterOptionValues.includes(appliedValue)
  )
}

export const getFilterOrPersistedFilterOptions = (
  lastModifiedFilterGroup: FilterGroup | undefined,
  filterId: string,
  filterOptions: FilterOption[]
) => {
  if (lastModifiedFilterGroup) {
    if (lastModifiedFilterGroup.id === filterId) {
      return lastModifiedFilterGroup.filterOptions
    }
  }

  return filterOptions
}

export const getExistingFilterValues = (
  filterValues: string[],
  filters: Filter[] | undefined
) => {
  if (filters === undefined) return filterValues
  const existingFilterValues: string[] = []
  filterValues.forEach((filterValue) => {
    filters.forEach((filterGroup) => {
      const filterOption = filterGroup.options.find(
        (option) => option.value === filterValue
      )
      if (filterOption !== undefined) existingFilterValues.push(filterOption.value)
    })
  })
  return existingFilterValues
}
