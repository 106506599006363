import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import { PartContext } from '../../../types/sitecoreContextProps'
import PartMaxCouponResponsive from './PartMaxCouponResponsive'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'

interface PartMaxCouponDatasource {
  messages: TextField
  title: TextField
}

const PartMaxCoupon: FC<Datasource<PartMaxCouponDatasource>> = ({
  datasource,
  rendering: { uid },
}) => {
  const { part } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  return (
    <PartSalesInformationProvider partNumber={part?.partNumber}>
      <PartMaxCouponResponsive {...datasource} id={uid} />
    </PartSalesInformationProvider>
  )
}

export default withData(PartMaxCoupon, {
  checkDatasource: true,
  showMessageWhenPageEditing: true,
})
