import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'theme-ui'
import {
  CATEGORY_FILTER_ID,
  VIEW_TYPE_DEFAULT,
  VIEW_TYPE_TABLE,
} from '../../../../../constants/searchConstants'
import { getTextFieldValue } from '../../../../../helpers/layoutServiceHelper'
import usePrevious from '../../../../../hooks/usePrevious'
import { PartSearchContext } from '../../../../../providers/PartSearchContextProvider'
import { PartSearchResultContext } from '../../../../../providers/PartSearchResultContextProvider'
import { ReferenceField, TextField } from '../../../../../types/layoutService'
import Container from '../../../../atoms/Container/Container'
import SpinnerWithLabel from '../../../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import { PartFilterSidePanelDatasource } from '../../../SidePanel/PartFilterSidePanel/PartFilterSidePanel'
import SearchControls from '../../SearchControls/SearchControls'
import SearchSubcategoriesList from './SubcategoryList/PartSearchControlsSubcategoryList'
import PartSearchControlsTableAnchors from './TableAnchorList/PartSearchTableAnchors'
import PartSearchFilterChipList from './FilterChipList/PartSearchFilterChipList'

interface PartSearchControlsProps {
  filtersLabel: TextField
  categoriesLabel: TextField
  tableViewAnchorLabel: TextField
  viewLabel: TextField
  filterOverlay: ReferenceField<PartFilterSidePanelDatasource>
  loadingLabel: TextField
  moreFiltersLabel: TextField
  children: ReactNode
}

const PartSearchControls = ({
  filtersLabel,
  categoriesLabel,
  tableViewAnchorLabel,
  viewLabel,
  filterOverlay,
  children,
  loadingLabel,
  moreFiltersLabel,
}: PartSearchControlsProps) => {
  const { statistics } = useContext(PartSearchContext)
  const {
    activeFilters,
    availableFilters,
    availableCategories,
    parts,
    totalCount,
    viewType,
    initialViewType,
    fetching,
  } = useContext(PartSearchResultContext)

  const defaultViewType = useMemo(
    () =>
      initialViewType && initialViewType === VIEW_TYPE_TABLE
        ? VIEW_TYPE_TABLE
        : VIEW_TYPE_DEFAULT,
    [initialViewType]
  )

  const activeCategoryFilter = useMemo(
    () => activeFilters?.[CATEGORY_FILTER_ID]?.[0],
    [activeFilters]
  )
  const previousCategoryFilter = usePrevious(activeCategoryFilter)

  const [categoryFilterChanged, setCategoryFilterChanged] = useState(false)

  const displayFiltersChips = useMemo(() => {
    const minimumAmountOfSelectedFilters = activeCategoryFilter ? 2 : 1
    const withActiveFilters =
      activeFilters &&
      Object.keys(activeFilters).length >= minimumAmountOfSelectedFilters
    const withoutActiveFilters =
      activeFilters &&
      Object.keys(activeFilters).length === minimumAmountOfSelectedFilters - 1

    return (
      (withActiveFilters || withoutActiveFilters) &&
      availableFilters &&
      availableFilters?.length >= 1
    )
  }, [activeCategoryFilter, activeFilters, availableFilters])

  const displaySearchControls = useMemo(
    () =>
      statistics?.totalCount &&
      statistics.totalCount > 0 &&
      !!parts?.length &&
      parts.length > 0,
    [statistics, parts]
  )

  useEffect(() => {
    if (activeCategoryFilter !== previousCategoryFilter) {
      setCategoryFilterChanged(true)
    }
  }, [activeCategoryFilter, previousCategoryFilter])

  useEffect(() => {
    if (!fetching) setCategoryFilterChanged(false)
  }, [fetching])

  if (categoryFilterChanged) {
    return (
      <Container>
        <SpinnerWithLabel
          label={getTextFieldValue(loadingLabel)}
          sx={{
            width: 'max-content',
            position: 'sticky',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      </Container>
    )
  }

  return (
    <>
      {!!displaySearchControls && (
        <Container>
          <SearchSubcategoriesList
            categoriesLabel={categoriesLabel}
            availableCategories={availableCategories}
          />

          <PartSearchControlsTableAnchors label={tableViewAnchorLabel} />

          {displayFiltersChips && (
            <Text as="h4" variant="heading4" mb={1}>
              {getTextFieldValue(filtersLabel)}
            </Text>
          )}

          <SearchControls
            viewLabel={viewLabel}
            count={totalCount}
            defaultViewType={defaultViewType}
            viewType={viewType}
            sx={{
              mb: displayFiltersChips ? [3, 3, 5] : undefined,
            }}
          >
            {displayFiltersChips && (
              <PartSearchFilterChipList
                filterOverlay={filterOverlay}
                moreFiltersLabel={moreFiltersLabel}
              />
            )}
          </SearchControls>
        </Container>
      )}

      <Container>{children}</Container>
    </>
  )
}

export default PartSearchControls
