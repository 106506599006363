import i18next from 'i18next'
import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'

interface DealerLocatorDealerDistanceProps extends Omit<TextProps, 'css' | 'label'> {
  distance?: number | null
}

const DealerLocatorDealerDistance: FC<DealerLocatorDealerDistanceProps> = ({
  distance,
  sx,
  ...props
}) => {
  if (typeof distance !== 'number') return null

  return (
    <Text
      variant="bodySmall"
      sx={{
        lineHeight: 'menuList',
        ...sx,
      }}
      {...props}
    >
      {`${i18next.t('dealerLabels.distance')}: `}

      {`${distance} km`}
    </Text>
  )
}

export default DealerLocatorDealerDistance
