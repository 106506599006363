import React, { createContext } from 'react'
import useCategoryListService, {
  Category,
} from '../hooks/services/graphql/useCategoryListService'

export interface CategoriesContextProps {
  categories?: Category[]
}

export interface CategoriesProviderProps {
  children?: React.ReactNode
}

export const CategoriesContext = createContext<CategoriesContextProps>({
  categories: undefined,
})

const CategoriesProvider = ({ children }: CategoriesProviderProps) => {
  const [, categories] = useCategoryListService()

  return (
    <CategoriesContext.Provider
      value={{
        categories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  )
}
export default CategoriesProvider
