import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { CATEGORY_FILTER_ID } from '../../../../constants/searchConstants'
import { URL_PAGE_PARAM } from '../../../../constants/urlConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { getFilterStringByActiveSearchResultFilterObject } from '../../../../helpers/filterHelper'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { PartSearchResultContext } from '../../../../providers/PartSearchResultContextProvider'
import { ActiveSearchResultFilters } from '../../../../types/filterProps'
import { TextField } from '../../../../types/layoutService'
import Button from '../../../atoms/Button/Button'

interface PartFilterResetButtonProps {
  label: TextField
}

const PartFilterResetButton = ({ label }: PartFilterResetButtonProps) => {
  const history = useHistory()
  const { activeFilters } = useContext(PartSearchResultContext)
  const [buttonState, setButtonState] = useState(true)

  const handleClick = useCallback(() => {
    const params = new URLSearchParams(history.location.search)
    const newFilterObject: ActiveSearchResultFilters = activeFilters?.[
      CATEGORY_FILTER_ID
    ]
      ? {
          [CATEGORY_FILTER_ID]: activeFilters[CATEGORY_FILTER_ID],
        }
      : {}

    params.delete(URL_PAGE_PARAM)

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FILTER,
      filter_action: DATA_LAYER.CUSTOM_DIMENSION.FILTER_ACTION.REMOVE_OVERLAY,
      filter_type: '',
    })

    history.push(
      `?${getFilterStringByActiveSearchResultFilterObject(
        newFilterObject,
        params,
        undefined,
        false
      )}`
    )
  }, [activeFilters, history])

  useEffect(() => {
    const minimumAmountOfSelectedFilters = activeFilters?.[CATEGORY_FILTER_ID]?.[0]
      ? 1
      : 0

    if (Object.keys(activeFilters || {}).length > minimumAmountOfSelectedFilters) {
      setButtonState(false)
    } else {
      setButtonState(true)
    }
  }, [activeFilters, setButtonState])

  return (
    <Button
      data-t-id="=parts-filter-panel-reset-button"
      disabled={buttonState}
      variant="outline"
      sx={{ flexGrow: 1, mr: 2 }}
      onClick={() => handleClick()}
    >
      {getTextFieldValue(label)}
    </Button>
  )
}

export default PartFilterResetButton
