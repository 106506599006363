import React, { FC, useContext, useMemo } from 'react'
import { Box } from 'theme-ui'
import { FilterOption } from '../../../../types/filterProps'
import PartFilterGroupHeading from './PartFilterGroupHeading'
import PartFilterOption from './PartFilterOption'
import { PartSearchResultContext } from '../../../../providers/PartSearchResultContextProvider'
import { stripInvalidFilterValues } from '../../../../helpers/filterHelper'
import {
  FILTER_GROUP_TYPE_CHECKBOX,
  FILTER_GROUP_TYPE_RADIOBUTTON,
} from '../../../../constants/searchConstants'

interface PartFilterGroupProps {
  description: string
  englishDescription: string
  id: string
  priority: number
  totalCount?: number
  filterOptions?: FilterOption[]
  active: boolean
  toggleFilterGroupId: () => void
  onChange: () => void
  type: typeof FILTER_GROUP_TYPE_CHECKBOX | typeof FILTER_GROUP_TYPE_RADIOBUTTON
}

const PartFilterGroup: FC<PartFilterGroupProps> = ({
  description,
  englishDescription,
  id,
  filterOptions,
  active,
  toggleFilterGroupId,
  onChange,
  type,
}) => {
  const { fetching, activeFilters } = useContext(PartSearchResultContext)

  const selectedFilterCount = useMemo(
    () => stripInvalidFilterValues(filterOptions, activeFilters?.[id])?.length || 0,

    [activeFilters]
  )

  if (!filterOptions?.length) return null

  return (
    <Box
      data-t-id={`filter-group-${id}`}
      sx={{
        mb: 4,
        '&:last-of-type': {
          mb: 0,
        },
        '*': { cursor: fetching && !active ? 'wait' : undefined },
      }}
    >
      <PartFilterGroupHeading
        onClick={toggleFilterGroupId}
        active={active}
        selectionCount={selectedFilterCount}
        disabled={fetching && !active}
        type={type}
      >
        {description}
      </PartFilterGroupHeading>

      <Box
        sx={{
          pt: active ? 2 : 0,
          mb: active ? -2 : 0,
          overflow: 'hidden',
        }}
      >
        {active &&
          filterOptions.map((filterOption) => (
            <PartFilterOption
              key={filterOption.value}
              id={id}
              groupDescription={englishDescription}
              onChange={onChange}
              type={type}
              {...filterOption}
            />
          ))}
      </Box>
    </Box>
  )
}

export default PartFilterGroup
