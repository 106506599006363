import React, { FC, useCallback, useContext } from 'react'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { getTotalSearchResultsString } from '../../../../helpers/searchResultsHelper'
import { PartSearchResultContext } from '../../../../providers/PartSearchResultContextProvider'
import { TextField } from '../../../../types/layoutService'
import Button from '../../../atoms/Button/Button'
import Spinner, { VARIANT } from '../../../atoms/Spinner/Spinner'

interface SidePanelProps {
  label: TextField
  onClose: () => void
}

const SidePanel: FC<SidePanelProps> = ({ label, onClose }) => {
  const { fetching, totalCount } = useContext(PartSearchResultContext)

  const totalSearchResults = getTotalSearchResultsString(totalCount!)

  const handleClick = useCallback(() => {
    onClose()

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FILTER,
      filter_action: DATA_LAYER.CUSTOM_DIMENSION.FILTER_ACTION.ACTIVATE,
      filter_type: '',
      filter_name: '',
      filter_value: '',
    })
  }, [onClose])

  return (
    <Button
      data-t-id="parts-filter-panel-confirm-button"
      variant="primary"
      sx={{
        flexGrow: 1,
        '> div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      onClick={handleClick}
      disabled={fetching}
    >
      {`${getTextFieldValue(label)}${
        (typeof totalSearchResults === 'string' || totalSearchResults > 0) &&
        !fetching
          ? ` ${totalSearchResults}`
          : ''
      }`}

      {fetching && (
        <Spinner
          size={2}
          variant={VARIANT.LIGHT}
          ml={2}
          sx={{
            display: 'inline-block',
          }}
        />
      )}
    </Button>
  )
}

export default SidePanel
