import React, { FC, useCallback } from 'react'
import Button from '../../../atoms/Button/Button'

interface EditServicePlanFormSubmitButtonProps {
  label: string
  onSubmit: () => void
  disabled?: boolean
}

const EditServicePlanFormSubmitButton: FC<EditServicePlanFormSubmitButtonProps> = ({
  label,
  onSubmit,
  disabled,
  ...rest
}) => {
  const handleClick = useCallback(() => {
    onSubmit()
  }, [onSubmit])

  return (
    <Button
      disabled={disabled}
      variant="primary"
      sx={{
        flexGrow: 1,
        width: '100%',
        '> div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      onClick={handleClick}
      {...rest}
    >
      {label}
    </Button>
  )
}

export default EditServicePlanFormSubmitButton
