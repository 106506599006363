import React from 'react'
import { Datasource, LinkField } from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import { ICONS } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'
import Button from '../../atoms/Button/Button'

interface DafPortalConnectDatasource {
  link: LinkField
}

const DafPortalConnect = ({
  datasource,
}: Datasource<DafPortalConnectDatasource>) => {
  if (!datasource.link) return null

  return (
    <Button
      variant="infinite"
      color={COLORS.WHITE}
      as="a"
      href={datasource.link.url}
      target={datasource.link.target}
      icon={ICONS.EXIT}
      iconPosition="end"
    >
      {datasource.link.text}
    </Button>
  )
}

export default withData(DafPortalConnect)
