import React, { FC, useMemo } from 'react'
import { Box, BoxProps, Flex } from 'theme-ui'
import {
  LOYALTY_DEAL_IMAGE_PATH,
  NEXT_DEAL_ONLINE_IMAGE_PATH,
} from '../../../constants/staticImageConstants'
import { Price } from '../../../hooks/services/graphql/usePartSalesInformationService'
import Image from '../../atoms/Image/Image'

export interface PromotionStickerProps extends Omit<BoxProps, 'css'> {
  variant: 'xs' | 's' | 'm' | 'l' | 'xl'
  position?: 'absolute' | 'relative'
  price?: Price
}

const stickerVariants = {
  xs: {
    width: '67%',
    maxWidth: '32px',
  },
  s: {
    width: '75%',
    maxWidth: '48px',
  },
  m: {
    width: '32%',
    maxWidth: '64px',
  },
  l: {
    width: '28%',
    maxWidth: '86px',
  },
  xl: {
    width: '20%',
    maxWidth: '86px',
  },
}

const allowedOnlineOnlyVariants = ['m', 'l', 'xl']

export const PromotionSticker: FC<PromotionStickerProps> = ({
  variant = 'm',
  position = 'absolute',
  price,
  children,
  sx,
  ...boxProps
}) => {
  const isNextPromotion = price?.promotionInformation?.program === 'NextPromotion'
  const isOnlineOnly = price?.promotionInformation?.applicability === 'Online'
  const shouldRenderOnlineOnlySticker = useMemo(
    () => allowedOnlineOnlyVariants.includes(variant),
    [variant]
  )

  if (!isNextPromotion) return <>{children}</>

  return (
    <Box sx={{ position: 'relative', zIndex: 0, ...sx }} {...boxProps}>
      <Flex
        sx={{
          position,
          left: 0,
          top: 0,
          zIndex: '1',
          flexDirection: 'column',
          ...stickerVariants[variant],
        }}
      >
        <Image src={LOYALTY_DEAL_IMAGE_PATH} data-t-id="Loyalty-promotion-sticker" />

        {isOnlineOnly && shouldRenderOnlineOnlySticker && (
          <Image
            data-t-id="Online-only-promotion-sticker"
            width="calc(100% - 10px)"
            marginTop={variant === 'm' ? '2px' : '4px'}
            src={NEXT_DEAL_ONLINE_IMAGE_PATH}
          />
        )}
      </Flex>

      {children}
    </Box>
  )
}

export default PromotionSticker
