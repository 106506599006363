import React, { FC } from 'react'
import { Text } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import Container from '../../atoms/Container/Container'
import { getDividerSx } from '../../atoms/Divider/Divider'
import SocialShareButton from '../../molecules/SocialShareButton/SocialShareButton'

interface SocialShareButtonsDatasource {
  shareLabelText: TextField
}

const SocialShareButtons: FC<Datasource<SocialShareButtonsDatasource>> = ({
  datasource: { shareLabelText },
}) => (
  <Container
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      py: 5,
      mb: [4, 4, 7],
      ...getDividerSx('top'),
      ...getDividerSx('bottom'),
    }}
  >
    <Text
      color="gray.2"
      py="1"
      px="2"
      sx={{
        fontSize: 2,
      }}
    >
      {shareLabelText.value}
    </Text>

    <SocialShareButton variant="FACEBOOK" />

    <SocialShareButton variant="LINKEDIN" />
  </Container>
)

export default withData(SocialShareButtons)
