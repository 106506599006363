import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface JobSheetsReferenceData {
  messages: JobSheetsMessagesProps[]
  result: JobSheetsReferenceProps[]
}

export interface JobSheetsMessagesProps {
  code: string
  message: string
  severity: string
}
export interface JobSheetsReferenceProps {
  id: string
  reference: string
  status: string
}

export interface JobSheetsTasksData {
  messages: JobSheetsMessagesProps[]
  result: JobsheetTasksProps
}
export interface JobsheetTasksProps {
  tasks: Task[]
  id: string
  reference: string
  status: string
}

export interface Task {
  id: string
  description: string
  status: string
  reasonForWork: string
}

export const getJobSheetReferences = (
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  reference: string,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<JobSheetsReferenceData>> =>
  client.get(`/r2c/references/${reference}/jobsheets`, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export const getJobSheetTasks = (
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  jobSheetID: string,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<JobSheetsTasksData>> =>
  client.get(`/r2c/jobsheets/${jobSheetID}/tasks`, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })
