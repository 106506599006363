import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Datasource, TextField } from '../../../types/layoutService'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import DealerPartCrossReferencesTable from './DealerPartCrossReferencesTable'
import { DealerPart } from '../../../hooks/services/graphql/useDealerPartListService'

interface DealerPartCrossReferencesDatasource {
  title: TextField
}

interface DealerPartCrossReferencesProps {
  dealerPart?: DealerPart
}

const DealerPartCrossReferences: FC<
  Datasource<DealerPartCrossReferencesDatasource> & DealerPartCrossReferencesProps
> = ({ dealerPart, datasource: { title }, rendering: { uid } }) => {
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)
  const { partCrossReferences } = dealerPart ?? {}

  const Table = useMemo(
    () =>
      partCrossReferences &&
      !!partCrossReferences.length && (
        <DealerPartCrossReferencesTable crossReferences={partCrossReferences} />
      ),
    [partCrossReferences]
  )

  if (!Table) return null

  if (!breakpointM) {
    return <PartMobileModalLink title={getTextFieldValue(title)} table={Table} />
  }

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid} mb={7}>
      {title && (
        <Text as="h2" variant="heading2" mb={4}>
          {getTextFieldValue(title)}
        </Text>
      )}

      {Table && Table}
    </AnchorMenuTarget>
  )
}

export default withData(DealerPartCrossReferences, {
  showMessageWhenPageEditing: true,
})
