import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { NewsArticle, PaginatedResponse } from '../../../types/layoutService'

export interface NewsArticlesRequestOptions {
  item: string
  pageIndex: number
  pageSize?: number
}

const DEFAULT_NEWS_PAGE_SIZE = 12
const DEFAULT_NEWS_SERVICE_OPTIONS: NewsArticlesRequestOptions = {
  item: '',
  pageIndex: 0,
  pageSize: DEFAULT_NEWS_PAGE_SIZE,
}

export const fetchNewsArticles = (
  client: AxiosInstance,
  receivedOptions: NewsArticlesRequestOptions,
  cancelToken: CancelToken
): Promise<AxiosResponse<PaginatedResponse<NewsArticle>>> =>
  client.get('news-articles', {
    params: { ...DEFAULT_NEWS_SERVICE_OPTIONS, ...receivedOptions },
    cancelToken,
  })
