import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import parse from 'html-react-parser'
import HotjarSuppress from '../../atoms/HotjarSuppress/HotjarSuppress'

interface PersonalizedGreetingBannerBaseProps {
  headingPrefix: string
  suffix?: string
  body?: string
}

const PersonalizedGreetingBannerBase: FC<PersonalizedGreetingBannerBaseProps> = ({
  headingPrefix,
  suffix,
  body,
}) => {
  const { breakpoints } = useTheme()

  return (
    <Box p={5} bg="gray.0" mb={[4, 4, 7]}>
      <Text as="h2" variant="heading1" mb={2} color="gray.3">
        {headingPrefix}

        <HotjarSuppress>
          <Text as="span">{suffix}</Text>
        </HotjarSuppress>
      </Text>

      {!!body && (
        <Text
          variant="bodySmall"
          sx={{
            lineHeight: 'bodyContent',
            color: 'gray.2',
            fontSize: 2,
            maxWidth: breakpoints[0],
            p: {
              margin: 0,
            },
            a: {
              color: 'inherit',
              textDecoration: 'underline',
            },
          }}
        >
          {parse(body)}
        </Text>
      )}
    </Box>
  )
}

export default PersonalizedGreetingBannerBase
