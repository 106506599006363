import React, { useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { useHistory } from 'react-router-dom'
import { DafCompanyVehiclesData } from '../../../services/rest/ecommerce/dafCompanyVehicles'
import { COLORS } from '../../../constants/themeConstants'
import {
  MY_FLEET_LIST_URL,
  VEHICLE_SERVICES_NO_SERVICES,
  VEHICLE_SERVICES_NON_MULTISUPPORT,
} from '../../../constants/dafCompanyVehicleConstants'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import ContractServicesIcon from '../../atoms/ContractServicesIcon/ContractServicesIcon'
import {
  getFilterStringByActiveSearchResultFilterObject,
  toggleFilter,
} from '../../../helpers/filterHelper'

interface PlansAndOtherServicesProps {
  isFetching: boolean
  dafCompanyVehicles?: null | DafCompanyVehiclesData
}

interface PlanOrServiceTile {
  onClick?: () => void
  abbreviation: string
  genericName: string
  count: number
}

const PlansAndOtherServices = ({
  isFetching,
  dafCompanyVehicles,
}: PlansAndOtherServicesProps) => {
  const history = useHistory()

  const setMyFleetFilter = (filter: string) => {
    const newFilterObject = toggleFilter({}, 'applicableServices', filter)
    const params = new URLSearchParams(history.location.search)

    history.push(
      `${MY_FLEET_LIST_URL}?${getFilterStringByActiveSearchResultFilterObject(
        newFilterObject,
        params,
        false,
        false
      )}`
    )
  }

  const getNonMultiSupportServicesMPText = () => {
    const nonMultiSupportServices = dafCompanyVehicles?.result.vehicles?.find(
      (item) =>
        item.nonMultiSupportServices &&
        item.nonMultiSupportServices.some(
          (service) => service.type === VEHICLE_SERVICES_NON_MULTISUPPORT
        )
    )

    if (!nonMultiSupportServices) return null

    const nonMultiSupportServicesMPText =
      nonMultiSupportServices.nonMultiSupportServices.find(
        (serviceItem) => serviceItem.type === VEHICLE_SERVICES_NON_MULTISUPPORT
      )
    return {
      type: nonMultiSupportServicesMPText?.type,
      description: nonMultiSupportServicesMPText?.type,
    }
  }

  const contractPackageCount = useMemo(() => {
    const counts: {
      [key: string]: {
        abbreviation: string
        genericName: string
        priority: number
        count: number
      }
    } = {}

    let noServicesCount = 0

    const nonMultiSupportServicesCount =
      dafCompanyVehicles?.result.vehicles?.filter(
        (result) => result.nonMultiSupportServices?.length
      ).length ?? 0

    if (dafCompanyVehicles?.result.vehicles) {
      dafCompanyVehicles.result.vehicles.forEach((item) => {
        const abbreviation = item.rmContract?.contractPackageAbbreviation
        const genericName = item.rmContract?.contractGenericPackageName
        const priority = item.serviceCoveragePriority || Number.MAX_SAFE_INTEGER

        if (abbreviation && genericName) {
          if (!counts[abbreviation]) {
            counts[abbreviation] = { abbreviation, genericName, priority, count: 1 }
          } else {
            counts[abbreviation].count += 1
          }
        }

        if (item.noServices) noServicesCount += 1
      })
    }

    const plansAndOthersArray = Object.values(counts)
    plansAndOthersArray.sort((a, b) => a.priority - b.priority)

    return { plansAndOthersArray, nonMultiSupportServicesCount, noServicesCount }
  }, [dafCompanyVehicles])

  const renderPlanOrServiceTile = ({
    onClick,
    abbreviation,
    genericName,
    count,
  }: PlanOrServiceTile) => (
    <Box
      as="li"
      key={abbreviation}
      sx={{
        gridRow: 'span 3',
        display: 'grid',
        gridTemplateRows: 'subgrid',
        gap: 0,
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          margin: 'auto',
        }}
      >
        <ClickableWrapper onClick={onClick}>
          <ContractServicesIcon
            iconSize="large"
            contractPackageAbbreviation={abbreviation}
          />
        </ClickableWrapper>
      </Box>
      <Text as="p" variant="smallText" color={COLORS.GRAY} sx={{ marginTop: 1 }}>
        {genericName}
      </Text>
      <Text as="span" variant="heading1">
        {count}
      </Text>
    </Box>
  )

  return (
    <>
      <Box
        data-t-id="Homepage-Plans&ServicesWidget"
        sx={{
          backgroundColor: 'white',
          position: 'relative',
          borderRadius: 8,
          border: '1px solid',
          borderColor: COLORS.MEDIUM_GRAY,
          padding: [3, 3, 3, 5],
        }}
      >
        <Box>
          <Text as="h3" variant="heading2" mb={1}>
            {i18next.t('plansAndOtherServices.plansAndOtherServicesLabel')}
          </Text>
          <Text as="p" variant="bodySmall">
            {i18next.t('plansAndOtherServices.plansAndOtherServicesDescription')}
          </Text>
        </Box>
        {!isFetching && dafCompanyVehicles && contractPackageCount && (
          <Box
            as="ul"
            sx={{
              padding: 0,
              marginTop: 4,
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(min(72px, 100%), 1fr))',
              gap: 3,
            }}
          >
            {Object.values(contractPackageCount.plansAndOthersArray).map((item) =>
              renderPlanOrServiceTile({
                onClick: () => setMyFleetFilter(item.genericName),
                abbreviation: item.abbreviation,
                genericName: item.genericName,
                count: item.count,
              })
            )}
            {contractPackageCount?.nonMultiSupportServicesCount > 0 &&
              renderPlanOrServiceTile({
                onClick: () => setMyFleetFilter(VEHICLE_SERVICES_NON_MULTISUPPORT),
                abbreviation: getNonMultiSupportServicesMPText()?.type ?? '',
                genericName: i18next.t('myFleetFilters.mP'),
                count: contractPackageCount.nonMultiSupportServicesCount,
              })}
            {contractPackageCount.noServicesCount > 0 &&
              renderPlanOrServiceTile({
                onClick: () => setMyFleetFilter(VEHICLE_SERVICES_NO_SERVICES),
                abbreviation: VEHICLE_SERVICES_NO_SERVICES,
                genericName: i18next.t('myFleetFilters.noServices'),
                count: contractPackageCount.noServicesCount,
              })}
            {!dafCompanyVehicles.result.vehicles.length &&
              renderPlanOrServiceTile({
                onClick: () => setMyFleetFilter(''),
                abbreviation: VEHICLE_SERVICES_NO_SERVICES,
                genericName: i18next.t('myFleetFilters.noServices'),
                count: contractPackageCount.noServicesCount,
              })}
          </Box>
        )}
      </Box>
    </>
  )
}

export default PlansAndOtherServices
