import React, { FC, useContext, useMemo } from 'react'
import { CommerceBomMaster, CommerceSpecification } from '../../../types/commerceApi'
import { LinkField } from '../../../types/layoutService'
import { loggedIn } from '../../../helpers/userHelper'
import PartSalesInformation from '../../molecules/SalesInformation/PartSalesInformation'
import { UserContext } from '../../../providers/UserProvider'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartLineBasicInformation from '../../molecules/PartLine/PartLineBasicInformation'
import PartLine, { PartLineGridTemplate } from '../../molecules/PartLine/PartLine'
import PartLineImage from '../../molecules/PartLine/PartLineImage'
import PartLineProductImage from '../../molecules/PartLine/PartLineProductImage'
import { getCommercePartUri } from '../../../helpers/commerceApiHelpers'
import PartLineStockInformation from '../../molecules/PartLine/PartLineStockInformation'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'
import PartLineAnchor from '../../molecules/PartLine/PartLineAnchor'

interface PartBillOfMaterialMasterProps {
  bomMaster: CommerceBomMaster
  bomMasterSpecification: CommerceSpecification | undefined
  partsPageUri: LinkField
  englishDescription?: string
  onClick: () => void
  gridTemplate?: PartLineGridTemplate
}

const PartBillOfMaterialMaster: FC<PartBillOfMaterialMasterProps> = ({
  bomMaster,
  bomMasterSpecification,
  partsPageUri,
  englishDescription,
  gridTemplate,
  onClick,
}) => {
  const { user } = useContext(UserContext)

  const href = useMemo(
    () =>
      bomMaster.partNumber
        ? `${partsPageUri.url}/${getCommercePartUri(
            bomMaster.category?.mainCategoryId,
            bomMaster.category?.subCategoryId,
            bomMaster.partNumber
          )}`
        : undefined,
    [bomMaster.category, bomMaster.partNumber, partsPageUri.url]
  )

  if (!bomMaster) return null

  return (
    <PartSalesInformationProvider partNumber={bomMaster.partNumber}>
      <PartLine gridTemplate={gridTemplate}>
        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineProductImage
            partNumber={bomMaster.partNumber || ''}
            src={bomMaster?.images?.[0].thumbnailImageUrl}
            alt={bomMaster?.description}
          />
        </PartLineAnchor>

        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineBasicInformation
            description={bomMaster.description}
            partNumber={bomMaster.partNumber || ''}
            displayPartNumber={bomMaster.displayPartNumber || bomMaster.partNumber}
          />
        </PartLineAnchor>

        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineImage
            src={bomMaster?.brand?.image?.thumbnailImageUrl}
            alt={bomMaster?.brand?.description}
          />
        </PartLineAnchor>

        {loggedIn(user) && (
          <PartLineStockInformation
            partNumber={bomMaster.partNumber}
            displayPartNumber={bomMaster.displayPartNumber}
            componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_BOM_MASTER}
            categoryId={bomMaster?.category?.mainCategoryId}
            subcategoryId={bomMaster?.category?.subCategoryId}
          />
        )}

        {loggedIn(user) && (
          <PartSalesInformation
            partNumber={bomMaster.partNumber}
            partEnglishDescription={englishDescription}
            brandName={bomMaster?.brand?.description}
            categoryId={bomMaster?.category?.mainCategoryId}
            subcategoryId={bomMaster?.category?.subCategoryId}
            dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_BOM_MASTER}
            packageUnitQuantity={
              bomMasterSpecification?.packaging?.packageUnitQuantity
            }
          />
        )}
      </PartLine>
    </PartSalesInformationProvider>
  )
}

export default PartBillOfMaterialMaster
