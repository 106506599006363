import {
  BREAKPOINT_L,
  BREAKPOINT_L_VALUE,
  BREAKPOINT_M,
  BREAKPOINT_M_VALUE,
  BREAKPOINT_S,
  BREAKPOINT_S_VALUE,
  BREAKPOINT_XL,
  BREAKPOINT_XL_VALUE,
  BREAKPOINT_XS,
  BREAKPOINT_XS_VALUE,
  BREAKPOINT_XXL,
  BREAKPOINT_XXL_VALUE,
} from '../constants/themeConstants'
import useMediaQuery from './useMediaQuery'

export type Breakpoints = Array<
  | typeof BREAKPOINT_XS
  | typeof BREAKPOINT_S
  | typeof BREAKPOINT_M
  | typeof BREAKPOINT_L
  | typeof BREAKPOINT_XL
  | typeof BREAKPOINT_XXL
>

const useBreakpoints = (...selectedBreakpoints: Breakpoints): boolean[] => {
  const mediaQueryMatches = {
    [BREAKPOINT_XS]: useMediaQuery(
      BREAKPOINT_XS_VALUE,
      selectedBreakpoints.includes(BREAKPOINT_XS)
    ),
    [BREAKPOINT_S]: useMediaQuery(
      BREAKPOINT_S_VALUE,
      selectedBreakpoints.includes(BREAKPOINT_S)
    ),
    [BREAKPOINT_M]: useMediaQuery(
      BREAKPOINT_M_VALUE,
      selectedBreakpoints.includes(BREAKPOINT_M)
    ),
    [BREAKPOINT_L]: useMediaQuery(
      BREAKPOINT_L_VALUE,
      selectedBreakpoints.includes(BREAKPOINT_L)
    ),
    [BREAKPOINT_XL]: useMediaQuery(
      BREAKPOINT_XL_VALUE,
      selectedBreakpoints.includes(BREAKPOINT_XL)
    ),
    [BREAKPOINT_XXL]: useMediaQuery(
      BREAKPOINT_XXL_VALUE,
      selectedBreakpoints.includes(BREAKPOINT_XXL)
    ),
  }

  return selectedBreakpoints.map((breakpoint) => mediaQueryMatches[breakpoint])
}

export default useBreakpoints
