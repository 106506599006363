import React, { FC, useCallback, useMemo } from 'react'
import { Box } from 'theme-ui'
import { Form } from '@sitecore-jss/sitecore-jss-react-forms'
import {
  FormFetcher,
  FormResult,
  SitecoreForm as SitecoreFormModel,
} from '@sitecore-jss/sitecore-jss-forms'
import { JssFormData } from '@sitecore-jss/sitecore-jss-forms/types/JssFormData'
import { sitecoreApiHost, sitecoreApiKey } from '../../../temp/config'
import SitecoreFormErrorList from './SitecoreFormErrorList'
import FieldFactory from './FieldFactory'
import './SitecoreForm.css'

export interface SitecoreFormProps {
  fields: SitecoreFormModel
}

const createFormFetcher =
  (pageItemId: string, options?: RequestInit): FormFetcher =>
  async (formData: JssFormData, endpoint: string) => {
    const url = new URL(endpoint)
    // Append context item ID to prevent submission exceptions
    url.searchParams.append('sc_itemid', pageItemId)

    const result = await fetch(url.toString(), {
      body: formData.toMultipartFormData(),
      method: 'post',
      credentials: 'include',
      ...options,
    })

    const json = await result.json()
    return json as FormResult
  }

const SitecoreForm: FC<SitecoreFormProps> = ({ fields }) => {
  const formFetcher = useMemo(
    () => createFormFetcher(fields.pageItemId.value),
    [fields.pageItemId.value]
  )

  const onRedirect = useCallback((url: string) => {
    window.location.href = url
  }, [])

  return (
    <Box mb={[4, 4, 7]} className="sitecore-form">
      <Form
        form={fields}
        sitecoreApiHost={sitecoreApiHost}
        sitecoreApiKey={sitecoreApiKey}
        fieldFactory={FieldFactory}
        errorComponent={SitecoreFormErrorList}
        onRedirect={onRedirect}
        formFetcher={formFetcher}
      />
    </Box>
  )
}

export default SitecoreForm
