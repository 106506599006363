import React, { FC, ReactElement } from 'react'
import { Flex, FlexProps } from 'theme-ui'
import GridItem from '../../atoms/GridItem/GridItem'
import GridRow from '../../atoms/GridRow/GridRow'

interface GridProps extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {
  columns: number
  children: ReactElement[]
}

const Grid: FC<GridProps> = ({ columns, children, sx, ...gridProps }) => {
  if (!children.length) return null

  const rows = Math.ceil(children.length / columns)
  let gridRows: Array<ReactElement[]> = []

  for (let row = 0; row < rows; row += 1) {
    const first = row * columns
    const last = first + columns

    gridRows = [...gridRows, [...children.slice(first, last)]]
  }

  if (!gridRows?.length) return null

  return (
    <Flex sx={{ flexDirection: 'column', ...sx }} {...gridProps}>
      {gridRows.map((row, i) => (
        <GridRow key={i.toString()}>
          {row?.length &&
            row.map((item, _ii) => <GridItem key={_ii.toString()}>{item}</GridItem>)}
        </GridRow>
      ))}
    </Flex>
  )
}

export default Grid
