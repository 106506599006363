import React, { FC, useCallback } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import { LinkField } from '../../../../../types/layoutService'
import PartLineBasicInformation from '../../../../molecules/PartLine/PartLineBasicInformation'
import { SearchDealerPart } from '../../../../../services/rest/ecommerce/dealerPartSearch'
import PartLineImage from '../../../../molecules/PartLine/PartLineImage'
import PartLine, {
  PartLineGridTemplate,
} from '../../../../molecules/PartLine/PartLine'
import DealerPartSalesInformation from '../../../../molecules/SalesInformation/DealerPartSalesInformation'
import { measureDealerPartClick } from '../../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import PartLineBrand from '../../../../molecules/PartLine/PartLineBrand'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import { DealerPart } from '../../../../../hooks/services/graphql/useDealerPartListService'
import PartLineAnchor from '../../../../molecules/PartLine/PartLineAnchor'

interface DealerPartSearchLineProps {
  searchDealerPart: SearchDealerPart
  dealerPart?: DealerPart
  uri?: string
  dealerPartPage: LinkField
  index: number
  gridTemplate?: PartLineGridTemplate
}

const DealerPartSearchLine: FC<DealerPartSearchLineProps> = ({
  searchDealerPart,
  dealerPart,
  uri,
  dealerPartPage,
  gridTemplate,
  index,
}) => {
  const href = `${dealerPartPage.url}/${uri}`

  const onClick = useCallback(
    () =>
      measureDealerPartClick(
        searchDealerPart.partNumber,
        dealerPart?.partName,
        dealerPart?.brandName,
        index,
        DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
      ),
    [dealerPart, index, searchDealerPart.partNumber]
  )

  return (
    <PartLine
      gridTemplate={gridTemplate}
      data-t-id={`dealer-part-search-line-part-number-${searchDealerPart.partNumber}`}
    >
      <PartLineAnchor href={href} onClick={onClick}>
        <PartLineImage src={dealerPart?.partImages?.[0]?.thumbnailImageUrl} />
      </PartLineAnchor>
      <PartLineAnchor href={href} onClick={onClick}>
        <PartLineBasicInformation
          description={dealerPart?.partName}
          partNumber={searchDealerPart.partNumber}
          matchMetaData={searchDealerPart.matchMetaData}
          supplierPartNumber={dealerPart?.manufacturerPartNumber}
        />
      </PartLineAnchor>
      <PartLineAnchor
        href={href}
        onClick={onClick}
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Text
          as="span"
          variant="bodySmall"
          sx={{
            mr: 2,
            lineHeight: 'heading3',
            color: 'gray.3',
            display: ['block', 'block', 'none'],
          }}
        >
          {i18next.t('commonLabels.specifications')}
        </Text>

        <Text
          as="span"
          variant="bodySmall"
          sx={{
            lineHeight: 'menuList',
            display: 'inline-block',
            color: 'gray.2',
            width: ['auto', 'auto', '100%'],
          }}
        >
          {dealerPart?.partProperties}
        </Text>
      </PartLineAnchor>
      <PartLineAnchor
        href={href}
        onClick={onClick}
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          mb: -3,
        }}
      >
        <Text
          as="span"
          variant="bodySmall"
          sx={{
            mr: 2,
            lineHeight: 'heading3',
            color: 'gray.3',
            display: ['block', 'block', 'none'],
          }}
        >
          {i18next.t('commonLabels.brand')}
        </Text>

        <PartLineBrand
          brand={dealerPart ? { description: dealerPart?.brandName } : undefined}
        />
      </PartLineAnchor>

      <PartLineStockInformation
        partNumber={dealerPart?.partNumber}
        componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE}
        isDealer
      />

      <DealerPartSalesInformation partNumber={dealerPart?.partNumber} />
    </PartLine>
  )
}

export default DealerPartSearchLine
