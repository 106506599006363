import React, { useContext } from 'react'
import { Box, Text } from 'theme-ui'
import { MyAccountPageContext } from '../../../providers/MyAccountPageProvider'
import Tile from '../../atoms/Tile/Tile'
import DefinitionList from '../../atoms/DefinitionList/DefinitionList'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'
import Spinner from '../../atoms/Spinner/Spinner'
import { COLORS } from '../../../constants/themeConstants'
import {
  COMPANY,
  MAIN_ADDRESS_TYPE,
  RM,
} from '../../../constants/accountUserDetailsConstants'
import { getDisplayAddress } from '../../../helpers/dealerHelper'

interface CompanyProfileOverviewProps {
  labels: {
    companyName: string
    addressLabel: string
    phoneNumberLabel: string
    emailLabel: string
    vATNumberLabel: string
    chamberOfCommerce: string
    dAFMultiSupportTitle: string
    repairAndMaintenanceEmailLabel: string
  }
}

const CompanyProfileOverview = ({
  labels: {
    companyName,
    addressLabel,
    phoneNumberLabel,
    emailLabel,
    vATNumberLabel,
    chamberOfCommerce,
    dAFMultiSupportTitle,
    repairAndMaintenanceEmailLabel,
  },
}: CompanyProfileOverviewProps) => {
  const { accountUserDetailsData, isLoading } = useContext(MyAccountPageContext)
  const companyEmail = accountUserDetailsData?.company?.email?.find(
    (email) => email.type === COMPANY
  )?.address
  const rmEmail = accountUserDetailsData?.company?.email?.find(
    (email) => email.type === RM
  )?.address

  const mainAddress = accountUserDetailsData?.company?.addresses?.find(
    (address) => address?.addressType === MAIN_ADDRESS_TYPE
  )

  const definitions = [
    {
      term: companyName,
      detail: showDashWhenEmptyValue(
        accountUserDetailsData?.company.name
      ).toString(),
    },
    {
      term: addressLabel,
      detail: showDashWhenEmptyValue(getDisplayAddress(mainAddress)).toString(),
    },
    {
      term: phoneNumberLabel,
      detail: showDashWhenEmptyValue(
        accountUserDetailsData?.company?.telephone
      ).toString(),
    },
    {
      term: emailLabel,
      detail: showDashWhenEmptyValue(companyEmail).toString(),
    },
    {
      term: vATNumberLabel,
      detail: showDashWhenEmptyValue(
        accountUserDetailsData?.company?.vatNumber
      ).toString(),
    },
    {
      term: chamberOfCommerce,
      detail: showDashWhenEmptyValue(
        accountUserDetailsData?.company?.cocNumber
      ).toString(),
    },
  ]
  return (
    <>
      <Tile>
        {isLoading && <Spinner />}
        {!isLoading && accountUserDetailsData && (
          <DefinitionList
            definitions={definitions}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 2fr'],
              gap: 3,
              alignItems: 'center',
            }}
          />
        )}
      </Tile>
      <Box sx={{ marginBlockStart: 4 }}>
        {dAFMultiSupportTitle && (
          <>
            <Text
              as="h1"
              variant="heading1"
              sx={{
                color: COLORS.BLACK,
                fontSize: 5,
                lineHeight: 'heading',
                marginBlockEnd: 3,
              }}
            >
              {dAFMultiSupportTitle}
            </Text>

            <Tile>
              {isLoading && <Spinner />}
              {!isLoading && accountUserDetailsData && (
                <DefinitionList
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 2fr'],
                    gap: 3,
                    alignItems: 'center',
                  }}
                  definitions={[
                    {
                      term: repairAndMaintenanceEmailLabel,
                      detail: showDashWhenEmptyValue(rmEmail).toString(),
                    },
                  ]}
                />
              )}
            </Tile>
          </>
        )}
      </Box>
    </>
  )
}

export default CompanyProfileOverview
