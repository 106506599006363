import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'

interface DafVehicleUpcomingJobsResponse {}

const postAddCompanyVehicle = (
  props: string[],
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<DafVehicleUpcomingJobsResponse>>> =>
  client.post('/add-company-vehicle', props, {
    cancelToken,
    params: queryParams,
    ...createRequestConfig(user),
  })

export default postAddCompanyVehicle
