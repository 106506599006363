import React, { FC } from 'react'
import MyFleetListSidePanelFooterConfirmButton from './MyFleetListSidePanelFooterConfirmButton'
import SidePanelFooter from '../../../SidePanel/SidePanelFooter'
import MyFleetListSidePanelFooterResetButton from './MyFleetListSidePanelFooterResetButton'
import { TextField } from '../../../../../types/layoutService'

interface PartFilterSidePanelFooterProps {
  removeFiltersLabel: TextField
  confirmFiltersLabel: TextField
  numberOfResults: number
  onClose: () => void
}

const MyFleetListSidePanelFooter: FC<PartFilterSidePanelFooterProps> = ({
  removeFiltersLabel,
  confirmFiltersLabel,
  numberOfResults,
  onClose,
}) => (
  <SidePanelFooter>
    <MyFleetListSidePanelFooterResetButton label={removeFiltersLabel} />

    <MyFleetListSidePanelFooterConfirmButton
      onClose={onClose}
      numberOfResults={numberOfResults}
      label={confirmFiltersLabel}
    />
  </SidePanelFooter>
)

export default MyFleetListSidePanelFooter
