import { useEffect, useState, useRef, RefObject } from 'react'
import { runOnWindow } from '../helpers/dom'
import useSitecoreContext from './useSitecoreContext'

const useIntersectionObserver = <T extends HTMLElement>(
  ref: RefObject<T>
): boolean => {
  const { pageEditing } = useSitecoreContext()
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    runOnWindow(() => {
      observerRef.current = new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      )
    })
  }, [])

  useEffect(() => {
    if (!observerRef?.current || !ref?.current || pageEditing) return undefined

    observerRef.current.observe(ref.current)

    return () => {
      if (observerRef?.current) observerRef.current.disconnect()
    }
  }, [ref, pageEditing])

  return isIntersecting
}

export default useIntersectionObserver
