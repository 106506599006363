import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  URL_FILTER_PARAMETER,
  URL_SEARCH,
  URL_SORT,
} from '../constants/urlConstants'
import { ActiveSearchResultFilters } from '../types/filterProps'
import { CATEGORY_FILTER_ID } from '../constants/searchConstants'
import { mapFilterStringToActiveSearchResultFilterObject } from '../helpers/filterHelper'

export interface MyFleetListContextProps {
  sort: string | null
  search: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeFilters: any
}

export const MyFleetListContext = createContext<MyFleetListContextProps>({
  sort: null,
  search: null,
  activeFilters: [],
})

interface MyFleetListContextProviderProps {
  children: ReactNode
}

const MyFleetListContextProvider = ({
  children,
}: MyFleetListContextProviderProps) => {
  const { search } = useLocation()
  const [sortValue, setSortValue] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [filterValue, setFilterValue] = useState<ActiveSearchResultFilters[] | null>(
    null
  )

  const urlQueryString = useMemo(() => new URLSearchParams(search), [search])

  const urlSortString: string | null = useMemo(
    () => urlQueryString.get(URL_SORT) || null,
    [urlQueryString]
  )

  const urlSearchString: string | null = useMemo(
    () => urlQueryString.get(URL_SEARCH) || null,
    [urlQueryString]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const urlFilterString: any = useMemo(
    () => urlQueryString.get(URL_FILTER_PARAMETER) || null,
    [urlQueryString]
  )

  const [urlFilterObject, filterCategory] = useMemo(() => {
    const newUrlFilterObject =
      mapFilterStringToActiveSearchResultFilterObject(urlFilterString) || {}
    const newFilterCategory = newUrlFilterObject?.[CATEGORY_FILTER_ID]

    return [newUrlFilterObject, newFilterCategory]
  }, [urlFilterString])

  const newFilterobj = useMemo(() => {
    const overrideCategoryId = filterCategory?.[0]

    const newActiveFilters = overrideCategoryId
      ? { ...urlFilterObject, [CATEGORY_FILTER_ID]: [overrideCategoryId] }
      : urlFilterObject

    return [newActiveFilters]
    // contextViewType & searchString are constant and available on page load
  }, [filterCategory, urlFilterObject])

  useEffect(() => {
    setSortValue((prevSortValue) => {
      // Update the sort value only if it has changed
      if (prevSortValue !== urlSortString) {
        return urlSortString
      }
      return prevSortValue
    })

    setSearchValue((prevSearchValue) => {
      // Update the search value only if it has changed
      if (prevSearchValue !== urlSearchString) {
        return urlSearchString
      }
      return prevSearchValue
    })

    setFilterValue(newFilterobj)
  }, [urlSortString, urlSearchString, urlFilterString, newFilterobj])

  return (
    <MyFleetListContext.Provider
      value={{
        sort: sortValue,
        search: searchValue,
        activeFilters: filterValue,
      }}
    >
      {children}
    </MyFleetListContext.Provider>
  )
}
export default MyFleetListContextProvider
