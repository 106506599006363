import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'
import { AxiosResponse } from 'axios'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'

export interface OrderPunchoutProps {
  orderNumber?: string
}

export const orderPunchout: RequestFunction<
  OrderPunchoutProps,
  CommerceResponse<CommerceOrder>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrder>>> =>
  client.put(`/order-punchout/${props.orderNumber || ''}`, null, {
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
      }
    ),
    cancelToken: config.cancelToken,
  })

export interface OrderPunchoutXmlProps {
  orderNumber?: string
}

export const orderGetPunchoutXml: RequestFunction<
  OrderPunchoutXmlProps,
  CommerceResponse<string>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<string>>> =>
  client.get(`/orders-punchout-cxml/${props.orderNumber}`, {
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
      }
    ),
    cancelToken: config.cancelToken,
  })

export interface OrderPunchoutOciProps {
  orderNumber?: string
}

export interface OciResponse {
  orderLines: [
    {
      description: string
      quantity: string
      unit: string
      price: string
      currency: string
      productNumber: string
      customValue1: string
      sacCode: string
      autoConfirmPunchOut: string
    }
  ]
}

export const orderGetPunchoutOci: RequestFunction<
  OrderPunchoutOciProps,
  CommerceResponse<OciResponse>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<OciResponse>>> =>
  client.get(`/orders-punchout-oci/${props.orderNumber}`, {
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
      }
    ),
    cancelToken: config.cancelToken,
  })
