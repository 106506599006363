import i18next from 'i18next'
import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import { STOCK_LOCATION_ID_CONSIGNMENT } from '../../../../constants/stockConstants'
import { StockLocation } from '../../../../hooks/services/graphql/usePartSalesInformationService'
import { getDividerSx } from '../../../atoms/Divider/Divider'

interface ConsignmentStockInformationProps {
  partNumber: string
  displayPartNumber?: string
  stockLocation?: StockLocation
}

const ConsignmentStockInformation: FC<ConsignmentStockInformationProps> = ({
  partNumber,
  displayPartNumber,
  stockLocation,
}) => {
  if (
    stockLocation?.locationId !== STOCK_LOCATION_ID_CONSIGNMENT ||
    !stockLocation?.consignmentStockInformation
  )
    return null

  return (
    <Box
      sx={{
        color: 'text',
        marginBottom: 4,
        ...getDividerSx('bottom'),
      }}
    >
      <Text variant="heading3" paddingBottom="3">
        {`${i18next.t('partLabels.partNumber')}: ${displayPartNumber || partNumber}`}
      </Text>

      <Text
        variant="bodyContent"
        sx={{
          color: 'gray.2',
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 3,
          ...getDividerSx('top'),
        }}
      >
        <span data-t-id="Consignment-BINLocation">
          {stockLocation.consignmentStockInformation.binLocation}
        </span>

        <span data-t-id="Consignment-StockAmount">
          {stockLocation.consignmentStockInformation.registeredLevel}
        </span>
      </Text>
    </Box>
  )
}

export default ConsignmentStockInformation
