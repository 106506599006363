import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { useIsFetching } from '@tanstack/react-query'
import { RootState } from '../../../reducers'
import {
  MILEAGE_INPUT_STATE_OPEN,
  SERVICEPLAN_CONTRACT_STATE_ACTIVE,
  SERVICEPLAN_CONTRACT_STATE_EXPIRING,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
  VEHICLE_DETAIL_FINANCE_TAB,
  VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB,
} from '../../../constants/dafCompanyVehicleConstants'
import { getQueryParam } from '../../../helpers/urlHelper'
import ActionButtonList from '../../molecules/ActionButtonList/ActionButtonList'
import ActionButtonListButton from '../../molecules/ActionButtonList/ActionButtonListButton'
import {
  toggleEditServicePlanPanel,
  toggleMileageSettlementInputPanel,
} from '../../../actions/dafCompanyVehicleActions'
import VehicleGrid from '../../molecules/VehicleGrid/VehicleGrid'
import VehicleGridTile from '../../molecules/VehicleGrid/VehicleGridTile'
import MileageSettlementHistoryTile from './MileageSettlementHistoryTile'
import MileageSettlementStatusTile from './MileageSettlementStatusTile'
import Divider from '../../atoms/Divider/Divider'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'
import MainServicePlanDetailTile from './MainServicePlanDetailTile'
import MileageSettlementDetailsTile from './MileageSettlementDetailsTile'
import MainServicePlanPaymentTile from './MainServicePlanPaymentTile'
import NoResults from '../../molecules/NoResults/NoResults'
import Button from '../../atoms/Button/Button'
import MainServicePlanStatusTile from './MainServicePlanStatusTile'
import { USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION } from '../../../constants/userConstants'
import { hasRole } from '../../../helpers/userHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const VehicleDetailFinance = () => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const currentActiveTab = getQueryParam(VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB)
  const dispatch = useDispatch()
  const { vehicleData } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const isFetchingVehicleData = useIsFetching({ queryKey: ['dafVehicleData'] })
  const { user } = useSitecoreContext()

  const handleEditServicePlanClick = (chassisNumber: string) => {
    dispatch(
      toggleEditServicePlanPanel({
        isActive: true,
        chassisNumber,
      })
    )
  }

  const handleMileageSettlementInputClick = (chassisNumber: string) => {
    dispatch(
      toggleMileageSettlementInputPanel({
        isActive: true,
        chassisNumber,
      })
    )
  }

  useEffect(() => {
    setIsActive(currentActiveTab === VEHICLE_DETAIL_FINANCE_TAB)
  }, [isActive, currentActiveTab])

  const shouldShowMileageSettlementTile =
    vehicleData?.rmContract?.finance?.mileageSettlementDetails
      ?.mileageSettlementInputState === MILEAGE_INPUT_STATE_OPEN ||
    vehicleData?.rmContract?.servicePlan.status.contractState ===
      SERVICEPLAN_CONTRACT_STATE_ACTIVE ||
    vehicleData?.rmContract?.servicePlan.status.contractState ===
      SERVICEPLAN_CONTRACT_STATE_EXPIRING ||
    vehicleData?.rmContract?.servicePlan.status.contractState ===
      SERVICEPLAN_CONTRACT_STATE_ON_HOLD

  return (
    <>
      <Box sx={{ display: !isActive ? 'none' : '' }}>
        {vehicleData?.rmContract?.servicePlan && (
          <>
            <Box>
              <Flex
                sx={{
                  marginBottom: 4,
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: ['column', 'column', 'row'],
                }}
              >
                <Box
                  sx={{ width: ['100%', '100%', '50%'], marginBottom: [3, 3, 0] }}
                >
                  <Text
                    sx={{
                      fontSize: [4, 4, 2],
                      fontWeight: 'bold',
                    }}
                  >
                    {`${i18next.t(
                      'servicePlan.packageNameLabel'
                    )}: ${showDashWhenEmptyValue(
                      vehicleData?.rmContract?.servicePlan?.packageName
                    )}`}
                  </Text>
                </Box>
                <Box sx={{ alignSelf: 'flex-start', marginLeft: [0, 0, 'auto'] }}>
                  <ActionButtonList variant="primary">
                    {vehicleData.vehicleActions.editServicePlan.showOption && (
                      <ActionButtonListButton
                        label={i18next.t('formLabels.editServicePlan')}
                        action={() =>
                          handleEditServicePlanClick(
                            vehicleData?.vehicleDetail.vehicleAttributes
                              .chassisNumber
                          )
                        }
                      />
                    )}
                    {vehicleData.vehicleActions.inputMileage.showOption && (
                      <ActionButtonListButton
                        label={i18next.t('formLabels.inputMileageActionButtonLabel')}
                        action={() =>
                          handleMileageSettlementInputClick(
                            vehicleData?.vehicleDetail.vehicleAttributes
                              .chassisNumber
                          )
                        }
                      />
                    )}
                  </ActionButtonList>
                </Box>
              </Flex>
              {!vehicleData.rmContract?.finance?.companyIsOnlyVehicleOperator && (
                <VehicleGrid>
                  <VehicleGridTile>
                    <MainServicePlanPaymentTile />
                  </VehicleGridTile>
                  <VehicleGridTile sx={{ display: 'flex', flexDirection: 'column' }}>
                    <MainServicePlanDetailTile />
                  </VehicleGridTile>
                  <MainServicePlanStatusTile />
                </VehicleGrid>
              )}
            </Box>
            <Divider sx={{ marginY: [6, 6, 8] }} />
            <Box>
              <Flex sx={{ marginBottom: 4, alignItems: 'center', width: '100%' }}>
                <Box>
                  <Text
                    sx={{
                      fontSize: [4, 4, 2],
                      fontWeight: 'bold',
                    }}
                  >
                    {i18next.t('finance.mileageSettlementAndDeviationIndication')}
                  </Text>
                </Box>
              </Flex>
              <VehicleGrid>
                <VehicleGridTile>
                  <MileageSettlementHistoryTile />
                </VehicleGridTile>
                <VehicleGridTile sx={{ display: 'flex', flexDirection: 'column' }}>
                  <MileageSettlementDetailsTile />
                </VehicleGridTile>
                {shouldShowMileageSettlementTile && (
                  <VehicleGridTile sx={{ display: 'flex', flexDirection: 'column' }}>
                    <MileageSettlementStatusTile />
                  </VehicleGridTile>
                )}
              </VehicleGrid>
            </Box>
          </>
        )}

        {!isFetchingVehicleData && !vehicleData?.rmContract?.servicePlan ? (
          <NoResults
            title={i18next.t('servicePlan.noServicePlanTitle')}
            description={i18next.t('servicePlan.noServicePlanDescription')}
          >
            {!hasRole(
              user,
              USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
            ) && (
              <Button variant="primary">
                {i18next.t('servicePlan.requestNewQuoteButtonLabel')}
              </Button>
            )}
          </NoResults>
        ) : null}
      </Box>
    </>
  )
}

export default VehicleDetailFinance
