import React, { FC } from 'react'
import { Box } from 'theme-ui'
import { SkeletonComponent } from '../../../types/layoutProps'
import Image from '../../atoms/Image/Image'
import Skeleton from '../../atoms/Skeleton/Skeleton'

interface CardImageProps {
  src?: string
  title?: string
}

const CardImage: FC<CardImageProps> & SkeletonComponent = ({ src, title }) => (
  <Box
    as="picture"
    sx={{
      display: 'block',
      margin: 'auto',
      aspectRatio: '16 / 9',
    }}
  >
    {src && (
      <Image
        src={src}
        alt={title}
        sx={{
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        }}
      />
    )}
  </Box>
)

CardImage.Skeleton = () => (
  <Skeleton>
    <Box
      sx={{
        paddingTop: '56.25%', // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}
    />
  </Skeleton>
)

export default CardImage
