import React, { FC } from 'react'
import { Box } from 'theme-ui'
import { Category } from '../../../../hooks/services/graphql/useCategoryListService'
import PartSearchControlsSubcategoryListModalItem from '../../SearchResults/PartSearch/SearchControls/SubcategoryList/PartSearchControlsSubcategoryListModalItem'
import Modal, { ModalProps } from '../Modal'
import ModalBodyContent from '../ModalBodyContent'

export interface SearchCategoryListModalProps extends ModalProps {
  title: string
  categoriesTree: Category[][]
  callback?: () => void
}

const SearchCategoryListModal: FC<SearchCategoryListModalProps> = ({
  title,
  categoriesTree,
  isActive,
  onClose,
  callback,
}) => (
  <Modal isActive={isActive} onClose={onClose} title={title}>
    <ModalBodyContent>
      {categoriesTree && !!categoriesTree.length && (
        <Box as="ul" sx={{ listStyle: 'none', p: 0 }}>
          {categoriesTree.map((categoryTree, i) => (
            <PartSearchControlsSubcategoryListModalItem
              key={i.toString()}
              categoryTree={categoryTree}
              callback={callback}
            />
          ))}
        </Box>
      )}
    </ModalBodyContent>
  </Modal>
)

export default SearchCategoryListModal
