import React, { FC, ReactNode, useState } from 'react'
import { ICONS } from '../../../constants/iconConstants'
import ProductPropertiesModal from '../Modal/ModalVariants/ProductPropertiesModal'
import { Bullet } from '../../../types/dafResponseProps'
import ModalAnchorWithIcon from '../Modal/ModalAnchors/ModalAnchorWithIcon'

interface PartMobileModalLinkProps {
  title: string
  bullets?: Bullet[]
  table: ReactNode
}

const PartMobileModalLink: FC<PartMobileModalLinkProps> = ({
  title,
  bullets = undefined,
  table,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ModalAnchorWithIcon
        label={title}
        onClick={() => setModalOpen(true)}
        icon={ICONS.BASKET}
      />

      <ProductPropertiesModal
        isActive={modalOpen}
        onClose={() => setModalOpen(false)}
        title={title}
        bullets={bullets}
        table={table}
      />
    </>
  )
}

export default PartMobileModalLink
