import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from 'axios'
import { UserProps } from '../../../../types/userProps'
import useAxiosClient from '../core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import useSitecoreContext from '../../../useSitecoreContext'

export type RequestFunction<RequestProps, ResponseType> = (
  client: AxiosInstance,
  props: RequestProps,
  config: RequestConfig,
  context: RequestContext
) => Promise<AxiosResponse<ResponseType>>

interface RequestConfig {
  cancelToken: CancelToken
}

export interface RequestContext {
  customerCompanyId: number
  supplierCompanyId: number
  user?: UserProps
  isImpersonated?: boolean
  xSessionId?: string
  cultureCode?: string
}

const useCommerceApiFetchService = <RequestProps, ResponseType>(
  requestFunction: RequestFunction<RequestProps, ResponseType>
): [
  boolean,
  AxiosResponse<ResponseType> | undefined | null,
  (requestProps: RequestProps) => Promise<void>
] => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [fetching, setFetching] = useState(false)
  const [responseData, setResponseData] = useState<
    AxiosResponse<ResponseType> | undefined | null
  >(undefined)
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined)

  const executor = useCallback(
    async (requestProps: RequestProps) => {
      if (!actingCompanyId || !actingSupplierId) return

      const source = axios.CancelToken.source()

      setCancelTokenSource(source)

      setFetching(true)

      try {
        const response = await requestFunction(
          client,
          requestProps,
          { cancelToken: source.token },
          {
            customerCompanyId: actingCompanyId,
            supplierCompanyId: actingSupplierId,
            user,
            isImpersonated,
            cultureCode,
          }
        )

        if (response) {
          setResponseData(response)
        }
      } catch (error) {
        const err = error as AxiosError<ResponseType>
        if (!err.code || err.code === 'ERR_CANCELED') return

        setResponseData(err.response)

        appInsights.trackException({
          exception: new Error(
            `Unable to execute ${requestFunction.name}: ${err.message} (${err.code})`
          ),
          severityLevel: SeverityLevel.Error,
          properties: {
            actingCompanyId,
            actingSupplierId,
            user,
            isImpersonated,
            ...requestProps,
          },
        })
      } finally {
        setFetching(false)
      }
    },

    [
      requestFunction,
      actingCompanyId,
      actingSupplierId,
      user,
      isImpersonated,
      appInsights,
    ]
  )

  useEffect(
    () => () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
      }
    },
    [cancelTokenSource]
  )

  return [fetching, responseData, executor]
}

export default useCommerceApiFetchService
