import { AxiosResponse } from 'axios'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceOrderItem, CommerceResponse } from '../../../../types/commerceApi'

export interface OrderItemsRequestItem {
  partNumber: string
  quantity: number
}

export interface OrderItemsRequestProps {
  orderNumber: string
  items?: OrderItemsRequestItem[]
}

export interface DeleteOrderItemsRequestProps {
  orderNumber?: string
  items?: string[]
}

export interface DeleteOrderItemRequestProps {}

export const postOrderItems: RequestFunction<
  OrderItemsRequestProps,
  CommerceResponse<CommerceOrderItem[]>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrderItem[]>>> =>
  client.post(
    `order-items/${props.orderNumber}`,
    {
      items: props.items ?? [],
    },
    {
      ...createRequestConfig(
        context.user,
        (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
        {
          customerCompanyId: context.customerCompanyId,
          supplierCompanyId: context.supplierCompanyId,
          isImpersonated: !!context.isImpersonated,
        }
      ),
      cancelToken: config.cancelToken,
    }
  )

export const deleteOrderItems: RequestFunction<
  DeleteOrderItemsRequestProps,
  CommerceResponse<CommerceOrderItem[]>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrderItem[]>>> =>
  client.delete(`order-items/${props.orderNumber || 'latest'}`, {
    data: props.items,
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
      }
    ),
    cancelToken: config.cancelToken,
  })
