import React, { ReactNode } from 'react'
import { Box, Text } from 'theme-ui'
import Skeleton from '../../atoms/Skeleton/Skeleton'

interface CardExcerptTextProps {
  children: ReactNode
}

const CardExcerptText = ({ children }: CardExcerptTextProps) => (
  <Text
    as="p"
    variant="bodySmall"
    sx={{ lineHeight: 'menuList', color: 'gray.2', mb: 3 }}
  >
    {children}
  </Text>
)

const CardExcerptTextSkeleton = () => (
  <Skeleton>
    <Box sx={{ mt: 1, mb: 3, height: '14px' }} />
  </Skeleton>
)

CardExcerptText.Skeleton = CardExcerptTextSkeleton

export default CardExcerptText
