import React, { FC } from 'react'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Box } from 'theme-ui'
import { ButtonProps } from '@theme-ui/components'

export interface ClickableWrapperProps extends Omit<ButtonProps, 'css'> {
  target?: string
}

const ClickableWrapper: FC<ClickableWrapperProps> = ({
  target,
  children,
  sx,
  as = 'button',
  type,
  disabled,
  ...manipulatedProps
}) => {
  const { pageEditing } = useSitecoreContext()

  const ButtonOrAnchor = as

  return (
    <Box
      sx={{
        '> *': {
          appearance: 'none',
          border: 'none',
          backgroundColor: 'transparent',
          textAlign: 'start',
          padding: 0,
          textDecoration: 'inherit',
          cursor: disabled || pageEditing ? 'initial' : 'pointer',
          ...sx,
        },
        display: 'contents',
      }}
    >
      <ButtonOrAnchor
        {...manipulatedProps}
        type={type || (!as || as === 'button' ? 'button' : undefined)}
        target={as === 'a' ? target : undefined}
        {...(pageEditing
          ? { disabled: true, onClick: (e: MouseEvent) => e.preventDefault() }
          : { disabled })}
      >
        {children}
      </ButtonOrAnchor>
    </Box>
  )
}

export default ClickableWrapper
