import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import i18next from 'i18next'
import { URL_QUERY_STRING_PARAM } from '../../../../constants/urlConstants'
import { resolveUrl } from '../../../../helpers/linkFieldHelper'
import Divider from '../../../atoms/Divider/Divider'
import { getFlattenedCategoryTree } from '../../../../helpers/categoryHelper'
import AutoSuggestionItem from './AutoSuggestionItem'
import AutoSuggestionGroup from './AutoSuggestionGroup'
import { LinkField } from '../../../../types/layoutService'
import useAutoSuggestionService from '../../../../hooks/services/rest/ecommerce/useAutoSuggestionService'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import Animate from '../../../atoms/Animate/Animate'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { Category } from '../../../../hooks/services/graphql/useCategoryListService'

export interface AutoSuggestionsProps {
  isSearchInputFocused: boolean
  searchString: string
  partsPageUri: LinkField
  searchResultsPage?: LinkField
}

const constructItemUrl = (searchResultsPage?: LinkField) => (query: string) => {
  const baseUrl = new URL(window.location.href)
  const searchUrl = new URL(resolveUrl(searchResultsPage) || '/', baseUrl)
  searchUrl.searchParams.set(URL_QUERY_STRING_PARAM, query)
  return searchUrl.toString()
}

const constructCategoryItemUrl =
  (partsPageUri: LinkField, categories?: Category[]) =>
  (query: string, categoryId: string) => {
    const flattenedCategoryTree = getFlattenedCategoryTree(categoryId, categories)
    const categoryPath = flattenedCategoryTree
      .map((category) => category.id)
      .join('/')
    const baseUrl = new URL(window.location.href)
    const searchUrl = new URL(resolveUrl(partsPageUri) || '/', baseUrl)

    return `${searchUrl.toString()}/${categoryPath}?${URL_QUERY_STRING_PARAM}=${query}`
  }

const AutoSuggestions: FC<AutoSuggestionsProps> = ({
  isSearchInputFocused,
  searchString,
  partsPageUri,
  searchResultsPage,
}) => {
  const autoSuggestions = useAutoSuggestionService(searchString)
  const { categories } = useContext(CategoriesContext)
  const [autoSuggestionGroups, setAutoSuggestionGroups] = useState<
    string[] | undefined
  >(undefined)

  const getItemUrl = useMemo(
    () => constructItemUrl(searchResultsPage),
    [searchResultsPage]
  )

  const getCategoryItemUrl = useMemo(
    () => constructCategoryItemUrl(partsPageUri, categories),
    [categories, partsPageUri]
  )

  const onSuggestionClick = useCallback(
    (suggestionGroup: string, suggestion: string) => {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_SUGGEST,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.SEARCH_BAR,
        search_query: searchString,
        page_type: '',
        suggestion_category: suggestionGroup, // TODO: should always be english
        suggestion_click: suggestion,
      })
    },
    [searchString]
  )

  const ProductSuggestionGroup = useMemo(
    () => (
      <AutoSuggestionGroup label={i18next.t('commonLabels.products')}>
        {autoSuggestions?.flatSuggestionTexts &&
          autoSuggestions.flatSuggestionTexts.map(
            ({ query, suggestionText }, index) => (
              <AutoSuggestionItem
                key={index.toString()}
                href={getItemUrl(query)}
                onClick={() =>
                  onSuggestionClick(
                    i18next.t('commonLabels.products'),
                    suggestionText
                  )
                }
                searchString={searchString}
                suggestionText={suggestionText}
              />
            )
          )}
      </AutoSuggestionGroup>
    ),
    [autoSuggestions, getItemUrl, searchString, onSuggestionClick]
  )

  const CollectixSuggestionGroup = useMemo(
    () => (
      <AutoSuggestionGroup label={i18next.t('partLabels.crossReferences')}>
        {autoSuggestions?.collectixSuggestions &&
          autoSuggestions.collectixSuggestions.map(
            ({ query, suggestionText }, index) => (
              <AutoSuggestionItem
                key={index.toString()}
                href={getItemUrl(query)}
                onClick={() =>
                  onSuggestionClick(
                    i18next.t('partLabels.crossReferences'),
                    suggestionText
                  )
                }
                searchString={searchString}
                suggestionText={suggestionText}
              />
            )
          )}
      </AutoSuggestionGroup>
    ),
    [autoSuggestions, getItemUrl, searchString, onSuggestionClick]
  )
  const CategorySuggestionGroup = useMemo(
    () => (
      <AutoSuggestionGroup label={i18next.t('commonLabels.categories')}>
        {autoSuggestions?.suggestionsPerCatalogCategory &&
          autoSuggestions.suggestionsPerCatalogCategory.map(
            ({ query, categoryId, suggestionText }, index) => (
              <AutoSuggestionItem
                key={index.toString()}
                href={getCategoryItemUrl(query, categoryId)}
                onClick={() =>
                  onSuggestionClick(
                    i18next.t('commonLabels.categories'),
                    suggestionText
                  )
                }
                searchString={searchString}
                suggestionText={suggestionText}
              />
            )
          )}
      </AutoSuggestionGroup>
    ),
    [autoSuggestions, getCategoryItemUrl, searchString, onSuggestionClick]
  )

  useEffect(() => {
    const groups = []

    if (autoSuggestions?.flatSuggestionTexts?.length) {
      groups.push('ProductSuggestionGroup')
    }

    if (autoSuggestions?.collectixSuggestions?.length) {
      groups.push('CollectixSuggestionGroup')
    }

    if (autoSuggestions?.suggestionsPerCatalogCategory?.length) {
      groups.push('CategorySuggestionGroup')
    }

    setAutoSuggestionGroups(groups)
  }, [autoSuggestions])

  if (!autoSuggestionGroups?.length) return null

  return (
    <Animate
      from={{ maxHeight: '0' }}
      enter={{ maxHeight: '80vh' }}
      active={isSearchInputFocused}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 5,
          borderRadius: '4px',
          mt: 2,
          width: '100%',
          backgroundColor: 'background',
          overflowY: 'auto',
          p: 4,
          overscrollBehavior: 'contain',
        }}
      >
        {autoSuggestionGroups.map((groupName, i) => (
          <Fragment key={i.toString()}>
            {groupName === 'ProductSuggestionGroup' && ProductSuggestionGroup}

            {groupName === 'CollectixSuggestionGroup' && CollectixSuggestionGroup}

            {groupName === 'CategorySuggestionGroup' && CategorySuggestionGroup}

            {i < autoSuggestionGroups.length - 1 && <Divider my={3} />}
          </Fragment>
        ))}
      </Box>
    </Animate>
  )
}

export default AutoSuggestions
