import React, { FC, FocusEventHandler, MouseEventHandler } from 'react'
import { Field } from 'react-final-form'
import { Box, Text } from 'theme-ui'
import FormControl from '../FormControls/FormControl'
import FormFieldText from '../FormControls/FormFieldText'
import FormInputLabel from '../FormControls/FormInputLabel'
import FormInputText from '../FormControls/FormInputText'
import { BaseFieldProps } from './BaseField'
import { COLORS } from '../../../constants/themeConstants'

export interface TextFieldProps extends BaseFieldProps {
  label?: string
  placeholder?: string
  prefix?: string
  suffix?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onClick?: MouseEventHandler<HTMLInputElement>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  type?: string
  value?: string | number | null | undefined
  max?: string
  min?: string
  parse?: (string: string) => number
  format?: (string: number) => string
}

export const TextField: FC<TextFieldProps> = ({
  disabled,
  required,
  name,
  label,
  placeholder,
  prefix,
  suffix,
  validate,
  onBlur,
  onFocus,
  onClick,
  onChange,
  value,
  max,
  min,
  parse,
  format,
  type = 'text',
  ...rest
}) => (
  <Field name={name} validate={validate} parse={parse} format={format}>
    {({ input, meta }) => {
      const hasError = meta.touched && (meta.error ?? meta.submitError)
      return (
        <FormControl
          id={name}
          error={hasError}
          disabled={disabled ?? meta.submitting}
        >
          <FormInputLabel required={required} htmlFor={name}>
            {label}
          </FormInputLabel>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {prefix && (
              <Text
                sx={{ marginRight: 3, fontSize: 1 }}
                color={hasError ? COLORS.ALERT : COLORS.BLACK}
              >
                {prefix}
              </Text>
            )}
            <FormInputText
              error={hasError}
              type={type}
              disabled={disabled ?? meta.submitting}
              id={name}
              name={name}
              onBlur={(e) => {
                if (typeof onBlur === 'function') onBlur(e)
                input.onBlur(e)
              }}
              onChange={(e) => {
                if (onChange) onChange(e)
                input.onChange(e)
              }}
              required={required}
              onFocus={(e) => {
                if (typeof onFocus === 'function') onFocus(e)
                input.onFocus(e)
              }}
              value={value ?? input.value}
              onClick={(e) => {
                if (typeof onClick === 'function') onClick(e)
              }}
              placeholder={placeholder}
              max={max || undefined}
              min={min || undefined}
              {...rest}
            />
            {suffix && (
              <Text
                sx={{ marginLeft: 3, fontSize: 1 }}
                color={hasError ? COLORS.ALERT : COLORS.BLACK}
              >
                {suffix}
              </Text>
            )}
          </Box>
          <FormFieldText type="error">
            {meta.error ?? meta.submitError}
          </FormFieldText>
        </FormControl>
      )
    }}
  </Field>
)
