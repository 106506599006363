import React, { FC, ReactNode } from 'react'
import { Flex, Text } from 'theme-ui'

interface AutoSuggestionGroupProps {
  label: string
  children: ReactNode | ReactNode[]
}

const AutoSuggestionGroup: FC<AutoSuggestionGroupProps> = ({ label, children }) => (
  <Flex sx={{ flexDirection: 'column' }}>
    <Text
      as="span"
      variant="smallText"
      sx={{ mb: 2, color: 'gray.2', textAlign: 'right' }}
    >
      {label}
    </Text>

    {children}
  </Flex>
)

export default AutoSuggestionGroup
