import React, { FC } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'
import { FONT_AWESOME_ICONS } from '../../../constants/iconConstants'
import HotjarSuppress from '../../atoms/HotjarSuppress/HotjarSuppress'
import DealerContactInformationLine from './DealerContactInformationLine'

export type DealerContactInformationVariant = 'minimal' | 'full'

interface DealerContactInformationBaseProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  name?: string
  address?: string
  phone?: string
  email?: string
  variant?: DealerContactInformationVariant
}

const DealerContactInformationBase: FC<DealerContactInformationBaseProps> = ({
  name,
  address,
  phone,
  email,
  variant = 'full',
  ...boxProps
}) => (
  <Box {...boxProps}>
    {name && (
      <HotjarSuppress>
        <Text as="h3" variant="heading3" mb={2}>
          {name}
        </Text>
      </HotjarSuppress>
    )}

    <Box as="ul" sx={{ m: 0, p: 0, fontSize: variant === 'full' ? 2 : 1 }}>
      <DealerContactInformationLine
        icon={variant === 'full' ? FONT_AWESOME_ICONS.LOCATION_ARROW : undefined}
        label={address}
      />

      <DealerContactInformationLine
        href={`tel:${phone}`}
        icon={variant === 'full' ? FONT_AWESOME_ICONS.PHONE_ALT : undefined}
        label={phone}
      />

      <DealerContactInformationLine
        href={`mailto:${email}`}
        icon={variant === 'full' ? FONT_AWESOME_ICONS.ENVELOPE : undefined}
        label={email}
      />
    </Box>
  </Box>
)

export default DealerContactInformationBase
