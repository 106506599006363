import React, { FC } from 'react'
import { LinkProps } from 'theme-ui'
import Link from '../../atoms/Link/Link'

interface PartLineAnchorProps extends Omit<LinkProps, 'css'> {
  href?: string
  onClick?: () => void
}

const PartLineAnchor: FC<PartLineAnchorProps> = ({
  href,
  onClick,
  children,
  sx,
}) => (
  <Link
    href={href}
    onClick={onClick}
    sx={{
      border: 'none',
      background: 'none',
      textDecoration: 'none',
      textAlign: 'left',
      color: 'inherit',
      cursor: 'pointer',
      userSelect: 'text',
      ...sx,
    }}
  >
    {children}
  </Link>
)

export default PartLineAnchor
