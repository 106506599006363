import { AxiosInstance } from 'axios'
import { useContext } from 'react'
import { isBrowser } from '../../../../helpers/dom'
import {
  AxiosClientContext,
  AxiosClientType,
} from '../../../../providers/AxiosClientProvider'

const useAxiosClient = (type: AxiosClientType): AxiosInstance => {
  const { clientMap } = useContext(AxiosClientContext)

  if (!isBrowser()) {
    // Return undefined when running in SSR context.
    // This prevents allocating objects on the global heap that were not freed correctly by the garbage collector.
    // We can do this safely because the result of this hook is only consumed client side in useEffect callbacks,
    // which do not run on the server.
    return undefined as unknown as AxiosInstance
  }

  if (!clientMap) {
    throw new Error(
      'Axios not available, please render a <AxiosProvider /> in your AppRoot.'
    )
  }

  const client = clientMap[type]

  if (!client) {
    throw new Error(`Client of type ${type} not available.`)
  }

  return client
}

export default useAxiosClient
