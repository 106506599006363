import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import DoughnutChart from '../../atoms/Charts/DoughnutChart'
import { COLORS } from '../../../constants/themeConstants'
import { DoughnutLegenda, DoughnutProps } from '../../../types/DoughnutChartTypes'

interface ActionsDoughnutChartWrapperProps {
  chartTitle: string
  chartData: DoughnutProps
  numberOfVehicles: number
}

const ActionsDoughnutChartWrapper = ({
  chartTitle,
  chartData,
  numberOfVehicles,
}: ActionsDoughnutChartWrapperProps) => {
  // if only one section is visible, the pie chart does not need padding
  // to show a 100% round circle
  const countNumberOfVisibleSections = chartData?.legenda?.reduce(
    (total, obj) => (obj.itemTotal !== 0 ? total + 1 : total),
    0
  )
  return (
    <Flex
      sx={{
        flexDirection: ['row', null, 'column'],
        alignItems: 'center',
        gap: 4,
      }}
    >
      <Box sx={{ minWidth: ['140px', '140px', '100%'] }}>
        <DoughnutChart
          chartData={chartData}
          numberOfVehicles={numberOfVehicles}
          numberOfVisibleSections={countNumberOfVisibleSections ?? 1}
        />
      </Box>
      {chartData?.legenda && (
        <Box
          sx={{
            inlineSize: [null, '100%'],
          }}
        >
          <Text
            sx={{
              fontSize: 1,
              fontWeight: 'bold',
            }}
          >
            {chartTitle}
          </Text>
          {chartData.legenda.map((entry: DoughnutLegenda) => (
            <Flex
              key={entry.name}
              sx={{
                marginBlockStart: 2,
                gap: 2,
                '&::before': {
                  content: '""',
                  backgroundColor: entry.color,
                  display: 'block',
                  inlineSize: 8,
                  blockSize: 8,
                  borderRadius: '50%',
                  marginBlockStart: 1,
                },
              }}
            >
              <Text
                variant="smallText"
                color={COLORS.GRAY}
                sx={{
                  flex: 1,
                }}
              >
                {entry.name}
              </Text>
              <Text
                data-t-id={`ActionWidget-Amount${entry.id}`}
                sx={{
                  fontWeight: 'bold',
                  fontSize: 1,
                  fontVariantNumeric: 'tabular-nums',
                }}
              >
                {entry.itemTotal}
              </Text>
            </Flex>
          ))}
          <Box />
        </Box>
      )}
    </Flex>
  )
}
export default ActionsDoughnutChartWrapper
