import React, { FC, useMemo, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import {
  CheckboxField,
  Datasource,
  ImageWithFocalPointField,
  TextField,
} from '../../../types/layoutService'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import withData from '../../../enhancers/withData'
import YouTubeVideoPoster from './YouTubeVideoPoster'
import { buildEmbedUrl } from '../../../helpers/youTubeVideohelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { DEFAULT_CULTURE_CODE } from '../../../constants/userConstants'

interface YouTubeVideoDatasource {
  title: TextField
  videoId: TextField
  posterImage: ImageWithFocalPointField
  closedCaptions?: CheckboxField
}

const YouTubeVideo: FC<Datasource<YouTubeVideoDatasource>> = ({
  datasource: { title, videoId, posterImage, closedCaptions },
}) => {
  const { languageContext } = useSitecoreContext()
  const [startVideo, setStartVideo] = useState(false)

  const embedUrl = useMemo<string>(
    () =>
      buildEmbedUrl({
        videoId: getTextFieldValue(videoId),
        language: languageContext?.cultureCode ?? DEFAULT_CULTURE_CODE,
        autoplay: true,
        controls: true,
        modestBranding: true,
        closedCaptions: closedCaptions?.boolValue,
      }),
    [videoId, closedCaptions, languageContext]
  )

  return (
    <Flex
      sx={{
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        mb: [4, 4, 7],
      }}
    >
      {!startVideo && (
        <YouTubeVideoPoster
          title={title}
          imageField={posterImage}
          onClick={() => setStartVideo(true)}
        />
      )}

      <Box sx={{ width: '100%', maxWidth: '970px' }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            maxHeight: '545px', // 970 * 0.5621 = 545.237
            paddingTop: '56.25%', // aspect ratio
          }}
        >
          {startVideo && (
            <Box
              as="iframe"
              sx={{
                iframe: {
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                },
              }}
            >
              <iframe
                src={embedUrl}
                title={getTextFieldValue(title)}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </Box>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default withData(YouTubeVideo)
