import React, { ComponentType, ElementType } from 'react'
import ExperienceEditorMessage from '../components/molecules/ExperienceEditor/ExperienceEditorMessage'
import { getDisplayName } from '../helpers/getDisplayName'
import useSitecoreContext from '../hooks/useSitecoreContext'
import { Datasource, DatasourceBase, WithDataProps } from '../types/layoutService'
import { COLORS } from '../constants/themeConstants'

export interface WithDataOptions<Props> {
  checkDatasource?: boolean
  showMessageWhenPageEditing?: boolean
  showHeaderMessageWhenPageEditting?: boolean
  pageEditingComponent?: ComponentType<Props & Datasource>
  showPageEditingComponentWhenPreviewing?: boolean
}

function withData<Props extends DatasourceBase>(
  WrappedComponent: ComponentType<Props>,
  receivedOptions?: WithDataOptions<Props>
): ComponentType<Props & WithDataProps> {
  const displayName = getDisplayName(WrappedComponent)
  const options: WithDataOptions<Props> = {
    checkDatasource: true,
    showMessageWhenPageEditing: false,
    ...receivedOptions,
  }

  const WithData = (props: Props & WithDataProps) => {
    const { pageEditing, pagePreviewing } = useSitecoreContext()

    const {
      fields: { data = {} } = {},
      rendering: { dataSource, componentName } = {},
    } = props

    if (options.checkDatasource && !data.datasource) {
      return pageEditing ? (
        <ExperienceEditorMessage
          title={componentName ?? 'Component'}
          text="Component is missing datasource."
        />
      ) : null
    }

    if (
      options.pageEditingComponent &&
      (pageEditing ||
        (pagePreviewing && options.showPageEditingComponentWhenPreviewing))
    ) {
      const PageEditingComponent = options.pageEditingComponent as ElementType
      return <PageEditingComponent {...data} {...props} datasourceId={dataSource} />
    }

    if (pageEditing && options.showMessageWhenPageEditing) {
      return (
        <>
          <ExperienceEditorMessage
            title={componentName ?? 'Component'}
            text="Component is hidden when editing."
          />
        </>
      )
    }

    if (pageEditing && options.showHeaderMessageWhenPageEditting) {
      return (
        <>
          <ExperienceEditorMessage
            title={`${
              componentName ?? 'Component'
            } - Component is hidden when editing.`}
            sx={{ padding: 2, background: COLORS.GRAY, height: '100%' }}
          />
        </>
      )
    }

    return <WrappedComponent {...data} {...props} datasourceId={dataSource} />
  }

  WithData.displayName = `withData(${displayName})`

  return WithData
}

export default withData
