import React, { useState } from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { alphaColor } from '../../../../helpers/themeHelper'
import { COLORS } from '../../../../constants/themeConstants'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import WebshopPointsCampaignButton from '../../ShoppingBasketWizard/OrderPage/WebshopPointsCampaign/WebshopPointsCampaignButton'
import Button from '../../../atoms/Button/Button'
import WebshopPointsCampaignInformation from '../../ShoppingBasketWizard/OrderPage/WebshopPointsCampaign/WebshopPointsCampaignInformation'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'

const WebshopPointsCampaignSidePanel = () => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { datasource } = useShoppingBasket()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const [open, setOpen] = useState(false)
  if (!order?.webshopPointsCampaign) return null

  return (
    <>
      <ClickableWrapper
        onClick={() => setOpen(true)}
        sx={{
          display: 'block',
          inlineSize: '100%',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            backgroundColor: alphaColor(COLORS.DAF_BLUE, 0.04),
            borderRadius: 'default',
            paddingInline: [3, null, 4],
            paddingBlock: [2, null, 4],
            overflow: 'hidden',
            '> span': {
              fontWeight: 'bold',
              fontSize: 2,
              lineHeight: 'menuList',
            },
          }}
        >
          <Box
            sx={{
              svg: {
                position: 'absolute',
                opacity: 0.32,
                bottom: '-50px',
                right: 0,
                height: '156px',
                width: '156px',
                color: 'primary',
              },
            }}
          >
            <IconWrapper icon={ICONS.PRESENT} size="fill" color="currentColor" />
          </Box>

          <Text
            variant="heading2"
            sx={{
              color: 'primary',
              fontSize: [1, 1, 4],
              fontWeight: 'normal',
            }}
          >
            {i18next.t('shoppingLabels.webshopPointsCampaign.merchandisePoints')}

            <IconWrapper size={4} icon={ICONS.ARROW_RIGHT} color="currentColor" />
          </Text>

          <span>
            {i18next.t('shoppingLabels.webshopPointsCampaign.points', {
              pointCount: new Intl.NumberFormat(cultureCode).format(
                Number(order?.webshopPointsCampaign.pointsBalance)
              ),
            })}
          </span>
        </Box>
      </ClickableWrapper>

      <SidePanel
        position="right"
        active={open}
        onClose={() => setOpen(false)}
        title={i18next.t('shoppingLabels.webshopPointsCampaign.merchandisePoints')}
      >
        <SidePanelBody>
          <WebshopPointsCampaignInformation
            webshopPointsCampaign={order?.webshopPointsCampaign}
          />

          <div>
            <WebshopPointsCampaignButton
              webshopPointsCampaign={order?.webshopPointsCampaign}
              activateAccountLink={datasource.activateAccountLink}
              continueShoppingLink={datasource.continueShoppingLink}
              redeemPointsLink={datasource.redeemPointsLink}
            />

            {datasource.visitShopLink && (
              <Button
                as="a"
                href={datasource.visitShopLink.url}
                target="_blank"
                variant="outline"
                sx={{
                  width: '100%',
                }}
              >
                {datasource.visitShopLink?.text}
              </Button>
            )}
          </div>
        </SidePanelBody>
      </SidePanel>
    </>
  )
}

export default WebshopPointsCampaignSidePanel
