import { Action } from 'redux'
import { SHOPPING_BASKET_WIZARD_REFETCH_ORDER } from '../../../constants/shoppingBasketConstants'

export interface RefetchOrderAction
  extends Action<typeof SHOPPING_BASKET_WIZARD_REFETCH_ORDER> {
  payload: boolean
}
export const refetchShoppingBasketOrder = (payload: boolean) => ({
  type: SHOPPING_BASKET_WIZARD_REFETCH_ORDER,
  payload,
})
