import useResizeObserver from '@react-hook/resize-observer'
import React, {
  FC,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { Box, BoxProps } from 'theme-ui'
import {
  pushToAnchorMenu,
  removeFromAnchorMenu,
} from '../../../actions/anchorMenuActions'

interface AnchorMenuTargetProps extends Omit<BoxProps, 'css'> {
  title: string
  id: string
}

const useSize = (target: RefObject<HTMLDivElement>) => {
  const [size, setSize] = useState<DOMRect>()

  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect())
    }
  }, [target])

  useResizeObserver(target, (entry) => {
    setSize(entry.target.getBoundingClientRect())
  })

  return size
}

const AnchorMenuTarget: FC<AnchorMenuTargetProps> = ({
  title,
  id,
  children,
  ...boxProps
}) => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const elementDomRect = useSize(ref)
  const isVisible = useMemo(
    () =>
      !!(elementDomRect && elementDomRect.width > 0 && elementDomRect.height > 0),
    [elementDomRect]
  )

  const data = useMemo(
    () => ({
      componentTitle: title,
      componentId: id,
    }),
    [id, title]
  )

  const pushDataToAnchorMenu = useCallback(() => {
    dispatch(pushToAnchorMenu(data))
  }, [dispatch, data])

  const removeDataFromAnchorMenu = useCallback(() => {
    dispatch(removeFromAnchorMenu(data))
  }, [dispatch, data])

  useLayoutEffect(() => {
    if (isVisible) {
      pushDataToAnchorMenu()
    } else {
      removeDataFromAnchorMenu()
    }

    return () => removeDataFromAnchorMenu()
  }, [pushDataToAnchorMenu, removeDataFromAnchorMenu, isVisible])

  return (
    <Box ref={ref} id={id} {...boxProps}>
      {children}
    </Box>
  )
}

export default AnchorMenuTarget
