import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import TableCell from '../../molecules/Table/TableCell'
import Table from '../../molecules/Table/Table'
import TableBody from '../../molecules/Table/TableBody'
import TableHead from '../../molecules/Table/TableHead'
import TableRow from '../../molecules/Table/TableRow'
import TableColumnTitle from '../../molecules/Table/TableColumnTitle'

export interface PartQuantumPriceTableProps {
  columns: PartQuantumPriceTableColumnProps[]
  rows: PartQuantumPriceTableRowProps[]
}

export interface PartQuantumPriceTableColumnProps {
  id: string
  label?: string
}

export interface PartQuantumPriceTableRowProps {
  label: string
  quantity?: string
  stockPrice?: string
  rushPrice?: string
  endDate?: string
  priceType?: string
  reference?: string
}

const PartQuantumPriceTable: FC<PartQuantumPriceTableProps> = ({
  rows,
  columns,
}: PartQuantumPriceTableProps) => {
  const columnSizes = useMemo(
    () => (columns?.length ? columns.map((_, i) => (i === 0 ? 2 : 1)) : []),
    [columns]
  )

  if (!columns?.length || !rows?.length) return null

  return (
    <Table columnSizes={columnSizes}>
      <TableHead>
        {columns.map((column, i) => (
          <TableColumnTitle
            key={i.toString()}
            sx={{
              display: 'table-cell',
            }}
          >
            {column.label ? column.label : null}
          </TableColumnTitle>
        ))}
      </TableHead>

      <TableBody>
        {rows.map((row, i) => (
          <TableRow key={i.toString()}>
            <TableCell>
              {row.label && (
                <Text
                  as="strong"
                  variant="bodyContent"
                  color="gray.3"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {row.label}
                </Text>
              )}
            </TableCell>

            <TableCell>
              {row.quantity && (
                <Text as="span" variant="bodyContent">
                  {row.quantity}
                </Text>
              )}
            </TableCell>

            <TableCell>
              {row.stockPrice && (
                <Text as="span" variant="bodyContent">
                  {row.stockPrice}
                </Text>
              )}
            </TableCell>

            <TableCell>
              {row.rushPrice && (
                <Text as="span" variant="bodyContent">
                  {row.rushPrice}
                </Text>
              )}
            </TableCell>

            <TableCell>
              {row.endDate && (
                <Text as="span" variant="bodyContent">
                  {row.endDate}
                </Text>
              )}
            </TableCell>

            <TableCell>
              {row.priceType && (
                <Text as="span" variant="bodyContent">
                  {row.priceType}
                </Text>
              )}
            </TableCell>

            <TableCell>
              {row.reference && (
                <Text as="span" variant="bodyContent">
                  {row.reference}
                </Text>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default PartQuantumPriceTable
