import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import { PartContext } from '../../../types/sitecoreContextProps'
import PartQuantumPriceResponsive from './PartQuantumPriceResponsive'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'

export interface PartQuantumPriceDatasource {
  title: TextField
}

const PartQuantumPrice: FC<Datasource<PartQuantumPriceDatasource>> = ({
  datasource,
  rendering: { uid },
}) => {
  const { title } = datasource
  const { part } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  return (
    <PartSalesInformationProvider partNumber={part?.partNumber}>
      <PartQuantumPriceResponsive title={title} id={uid} />
    </PartSalesInformationProvider>
  )
}

export default withData(PartQuantumPrice, { showMessageWhenPageEditing: true })
