import React, { ChangeEvent, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex, Text } from 'theme-ui'
import axios from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form } from 'react-final-form'
import { v4 as uuidv4 } from 'uuid'
import i18next from 'i18next'
import SidePanel from '../SidePanel'
import { toggleAddVehiclePanel } from '../../../../actions/dafCompanyVehicleActions'
import SidePanelBody from '../SidePanelBody'
import {
  validateCompanyVehicle,
  ValidateCompanyVehicleResult,
} from '../../../../services/rest/ecommerce/validateCompanyVehicle'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import useDebounce from '../../../../hooks/useDebounce'
import Input from '../../../atoms/inputs/Input/Input'
import { COLORS } from '../../../../constants/themeConstants'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import FormFieldText from '../../../atoms/FormControls/FormFieldText'
import VehicleInformationTile from '../../../atoms/VehicleInformationTile/VehicleInformationTile'
import SidePanelFooter from '../SidePanelFooter'
import postAddCompanyVehicle from '../../../../services/rest/ecommerce/addCompanyVehicle'
import { addToast } from '../../../../actions/toastActions'
import toCamelCase from '../../../../helpers/camelCaseHelper'
import { RootState } from '../../../../reducers'
import { resolveEnvironment } from '../../../../helpers/environment'

interface AddVehicleSidePanelProps {
  onFormSuccess?: () => void
}

const AddVehicleSidePanel = ({ onFormSuccess }: AddVehicleSidePanelProps) => {
  const commerceClient = useAxiosClient(AxiosClientType.CommerceApi)
  const dispatch = useDispatch()
  const env = resolveEnvironment()
  const { token } = axios.CancelToken.source()
  const { user } = useSitecoreContext()
  const id = useMemo(() => uuidv4(), [])
  const [vehicleListToBeAdded, setVehicleListToBeAdded] = useState<
    ValidateCompanyVehicleResult[]
  >([])
  const [numberOfVehiclesAdded, setNumberOfVehiclesAdded] = useState<number>(0)
  const [searchString, setSearchString] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const dafAddVehicleQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const { addVehiclePanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  useDebounce(searchString, 300)

  const getValidateCompanyVehicle = async () => {
    const response = await validateCompanyVehicle(
      commerceClient,
      dafAddVehicleQueryParams,
      searchString,
      token,
      user
    )
    return response.data
  }

  const { refetch } = useQuery({
    queryKey: ['ValidateCompanyVehicle'],
    queryFn: () => getValidateCompanyVehicle(),
    enabled: false,
  })

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) setErrorMessage(undefined)
    setSearchString(event?.target.value)
  }

  const handleFormSubmit = () => {
    refetch().then((data) => {
      const response = data?.data?.result
      const message = data?.data?.messages[0]?.code
      if (response) addVehicleToList(response)
      if (message) setErrorMessage(message)
    })
  }

  const addCompanyVehicle = async (payload: string[]) => {
    const response = await postAddCompanyVehicle(
      payload,
      dafAddVehicleQueryParams,
      commerceClient,
      token,
      user
    )

    return response.data
  }

  const { mutate } = useMutation({
    mutationFn: (payload: string[]) => addCompanyVehicle(payload),

    onError: () => {
      dispatch(
        addToast({
          id,
          message: i18next.t('commonLabels.noResultsFoundPleaseTryAgain'),
        })
      )
    },

    onSuccess: () => {
      dispatch(
        addToast({
          id,
          message: `${numberOfVehiclesAdded} ${i18next.t(
            'addVehicleLabels.addVehicleSuccessMessage'
          )}`,
        })
      )
      if (onFormSuccess) onFormSuccess()
    },
  })

  const addVehicleToList = (vehicle: ValidateCompanyVehicleResult) => {
    setSearchString('')
    setVehicleListToBeAdded((currentList) => {
      const vehicleExists = currentList.some(
        (currentVehicle) => currentVehicle.vin === vehicle.vin
      )
      if (vehicleExists) {
        return currentList
      }
      return [vehicle, ...currentList]
    })
  }

  const handleClose = () => {
    setSearchString('')
    setVehicleListToBeAdded([])
    setErrorMessage(undefined)
    dispatch(toggleAddVehiclePanel(false))
  }

  const removeVehicleFromList = (vin: string) => {
    setVehicleListToBeAdded(
      vehicleListToBeAdded.filter((vehicle) => vehicle.vin !== vin)
    )
  }

  const submitVehicles = () => {
    const vinArray = vehicleListToBeAdded.map((vehicle) => vehicle.vin)
    setNumberOfVehiclesAdded(vinArray.length)
    mutate(vinArray)
    handleClose()
  }

  return (
    <SidePanel
      disableOutsideClick
      position="right"
      active={addVehiclePanelState}
      onClose={handleClose}
      title={i18next.t('addVehicleLabels.addVehiclePanelTitle')}
    >
      <SidePanelBody>
        <Flex sx={{ gap: 2, flexDirection: 'column' }}>
          <Text
            variant="bodySmall"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {i18next.t('addVehicleLabels.addVehicleInputLabel')}
          </Text>
          <Flex sx={{ flexDirection: 'column' }}>
            <Box sx={{ position: 'relative' }}>
              <Form
                onSubmit={() => handleFormSubmit()}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Input
                      data-t-id="search-vin-input"
                      name="search-vin-input"
                      placeholder={i18next.t(
                        'addVehicleLabels.addVehiclePlaceholderText'
                      )}
                      autoComplete="off"
                      value={searchString}
                      onChange={changeHandler}
                      sx={{
                        backgroundColor: COLORS.WHITE,
                        border: '1px solid',
                        borderColor: errorMessage
                          ? COLORS.ALERT
                          : COLORS.MEDIUM_GRAY,
                        padding: 3,
                        fontSize: 1,
                        paddingInlineEnd: '60px',
                      }}
                    />
                    <Button
                      variant="primary"
                      icon={ICONS.ADD_TRUCK}
                      type="submit"
                      sx={{
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        right: 0,
                        borderRadius: 3,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        px: 2,
                      }}
                    />
                  </form>
                )}
              />
            </Box>
            {errorMessage && (
              <Box sx={{ marginTop: 2 }}>
                <FormFieldText type="error">
                  {i18next.t(`addVehicleLabels.${toCamelCase(errorMessage)}`)}
                </FormFieldText>

                {errorMessage === 'AddVehicleConnected' && (
                  <Button
                    as="a"
                    href={env?.vars?.connectPortalUrl}
                    color={COLORS.ALERT}
                    target="noopener"
                    variant="infinite"
                    icon={ICONS.EXIT}
                    iconPosition="end"
                    iconSize={4}
                    sx={{
                      marginTop: 4,
                      paddingLeft: 0,
                    }}
                  >
                    {i18next.t('commonLabels.visitPaccarConnectPortal')}
                  </Button>
                )}
              </Box>
            )}
          </Flex>
          <Box />
        </Flex>
        {vehicleListToBeAdded.length > 0 && (
          <Flex sx={{ flexDirection: 'column', gap: 2, marginTop: 4 }}>
            <Text
              variant="bodySmall"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {i18next.t('addVehicleLabels.addVehicleListTitle')}
            </Text>
            {vehicleListToBeAdded.map((vehicle) => (
              <VehicleInformationTile
                key={vehicle.vin}
                vehicle={vehicle}
                onRemove={(vin) => removeVehicleFromList(vin)}
              />
            ))}
          </Flex>
        )}
      </SidePanelBody>
      <SidePanelFooter>
        <Button
          disabled={vehicleListToBeAdded.length === 0}
          sx={{ width: '100%' }}
          variant="primary"
          onClick={() => {
            submitVehicles()
          }}
        >
          {i18next.t('commonFormLabels.confirm')}
        </Button>
      </SidePanelFooter>
    </SidePanel>
  )
}

export default AddVehicleSidePanel
