import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface Vehicle {
  chassisNumber: string
  applicableFilters: any
  vin: string
  vehicleType: string
  registrationNumber: string
  firstRegistrationDate: string
  vehicleActionStatus: number
  urlVehicleImageModelYearSeries: string
  vehicleAgeRange: string
  maintenance: string[]
  vehicleSeries: string
  serviceCoveragePriority?: number
  make: string
  rmContract: RmContract
  servicePlanner: ServicePlanner
  noServices: boolean
  nonMultiSupportServices: NonMultiSupportServices[]
  mileage: Mileage
  hasDafConnect: boolean
  customerVehicleId: string
  buildDate: Date
  menuOptions?: MenuOptions
  vehicleActions?: VehicleActions
}

interface NonMultiSupportServices {
  type: string
  description: string
}

export interface RmContract {
  contractPackageAbbreviation?: string
  contractGenericPackageName?: string
  contractState?: string
  invoiceState?: string
  mainServiceDealer?: MainServiceDealer
  mostRecentContractForVehicle: boolean
  mileageSettlementInputState: string
}

export interface MainServiceDealer {
  name: string
  locationCode: string
}

export interface Filter {
  id: string
  priority: number
  contract: string
  options: Option[]
}

export interface Option {
  id: string
  value: string
  priority: number
}

export interface ServicePlanner {
  service: Service
}

export interface Mileage {
  value: number
  date: string
  type: string
}

export interface Service {
  recalls: Recall[]
  upcomingJobs: UpcomingJob[]
}

export interface Recall {
  id: number
  type: string
  description: string
}

export interface UpcomingJob {
  priority: number
  id: number
  code: string
  dueDate: string
  dueIn: string
  dueInKms: number
  markedAsComplete: boolean
  description: string
  rmiJobCode: string[]
}

export interface DafCompanyVehicles {
  vehicles: Vehicle[]
  filters: Filter[] | undefined
}

interface DafCompanyVehiclesReference {
  messages: null
  result: DafCompanyVehicles
}

interface MenuOptions {
  delete: boolean
  requestQuote: boolean
}

export interface VehicleActions {
  delete: VehicleAction
  requestQuote: VehicleAction
  orderParts: VehicleAction
  searchParts: VehicleAction
  requestMaintenancePlan: VehicleAction
  adjustMaintenancePlan: VehicleAction
  endMaintenancePlan: VehicleAction
  editVehicle: VehicleAction
  maintenance: VehicleAction
  editServicePlan: VehicleAction
  rmDocuments: VehicleAction
  reportAsPerformed: VehicleAction
  inputMileage: VehicleAction
  requestMaintenanceAppointment: VehicleAction
}

interface VehicleAction {
  showOption: boolean
  allowed: boolean
  notAllowedReason: string | null
  confirmEnabled: boolean
}

export type DafCompanyVehiclesData = DafCompanyVehiclesReference

const fetchDafCompanyVehicles = (
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<DafCompanyVehiclesData>> =>
  client.get('/daf-company-vehicles/', {
    params: queryParams,
    ...createRequestConfig(user),
    cancelToken,
  })

export default fetchDafCompanyVehicles
