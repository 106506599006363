import React, { FC, useContext } from 'react'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'
import PromotionSticker, { PromotionStickerProps } from './PromotionSticker'

export const PromotionStickerAsync: FC<Omit<PromotionStickerProps, 'price'>> = ({
  children,
  ...promotionStickerProps
}) => {
  const { priceInformation } = useContext(PartSalesInformationContext)

  if (!priceInformation?.price) return <>{children}</>

  return (
    <PromotionSticker price={priceInformation.price} {...promotionStickerProps}>
      {children}
    </PromotionSticker>
  )
}

export default PromotionStickerAsync
