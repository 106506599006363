import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import RichText from '../../atoms/RichText/RichText'
import SitecoreButton from '../SitecoreButton/SitecoreButton'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { getFullTextDate } from '../../../helpers/dateHelper'
import {
  ButtonParams,
  CheckboxField,
  DateField,
  HeadingParams,
  LinkField,
  TextField,
} from '../../../types/layoutService'
import { DEFAULT_CULTURE_CODE } from '../../../constants/userConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

export interface CallToActionContentProps {
  title: TextField
  text?: TextField
  date?: DateField
  button?: LinkField
  wrapLink?: CheckboxField
  alignButtonToBottom?: boolean
  params?: HeadingParams & ButtonParams
  skipTagEvent?: boolean
}

const CallToActionContent: FC<CallToActionContentProps> = ({
  title,
  text,
  button,
  date,
  wrapLink,
  alignButtonToBottom,
  params,
  skipTagEvent,
}) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  return (
    <>
      <Flex sx={{ flexDirection: 'column', flexGrow: alignButtonToBottom ? 1 : 0 }}>
        {date && date?.dateValue > 0 && (
          <Text as="span" color="gray.2" mb="2" sx={{ fontSize: 1 }}>
            {getFullTextDate(date?.dateValue, cultureCode ?? DEFAULT_CULTURE_CODE)}
          </Text>
        )}

        <Text
          as="p"
          pb={1}
          color="text"
          sx={{
            fontSize: [2, 2, 4],
            lineHeight: ['heading3', 'heading3', 'heading2'],
            fontWeight: 'bold',
          }}
        >
          {title?.value}
        </Text>

        {text && !!getEditableTextFieldValue(text) ? (
          <Text
            sx={{
              lineHeight: 'heading2',
            }}
            color="gray.2"
            pb={3}
          >
            <RichText textField={text} />
          </Text>
        ) : null}
      </Flex>

      {!!button && (
        <SitecoreButton
          {...(wrapLink?.boolValue ? { as: 'span' } : {})}
          params={params}
          linkField={button}
          onClick={
            skipTagEvent
              ? undefined
              : () => {
                  pushToDataLayer({
                    [DATA_LAYER.EVENT_KEYS.EVENT]:
                      DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
                    call_to_action_name: getTextFieldValue(title),
                    call_to_action_type:
                      DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
                    target_url: button.url,
                  })
                }
          }
        />
      )}
    </>
  )
}

export default CallToActionContent
