import dayjs from 'dayjs'
import { DEFAULT_CULTURE_CODE } from '../constants/userConstants'

const DEFAULT_DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

/* eslint-disable no-restricted-globals */
export const getFullTextDate = (
  timestamp: number,
  language: string,
  options?: Intl.DateTimeFormatOptions
) =>
  new Date(timestamp).toLocaleDateString(
    language,
    options ?? DEFAULT_DATE_TIME_FORMAT_OPTIONS
  )

export const getCurrentDate = (addYears?: number): Date => {
  const currentDate = new Date()

  if (!!addYears && addYears > 0) {
    currentDate.setFullYear(currentDate.getFullYear() + addYears)
  }

  return currentDate
}

export const parseDate = (dateString?: string): Date | undefined => {
  if (!dateString) return undefined

  const timestamp = Date.parse(dateString)
  if (isNaN(timestamp)) return undefined

  return new Date(timestamp)
}

export const dayDiff = (d1: Date, d2: Date) => {
  const oneDay = 1000 * 60 * 60 * 24
  if (d1.getMonth() === 11 && d1.getDate() > 25) d2.setFullYear(d2.getFullYear() + 1)

  const result = Math.round(d2.getTime() - d1.getTime()) / oneDay
  return Math.round(result)
}

export const calculateDueInDays = (dateString?: string) => {
  const parsedDate = parseDate(dateString)
  if (parsedDate) return dayDiff(new Date(), parsedDate)

  return 0
}

export const getMonthName = (
  month: number | null | undefined,
  cultureCode: string | undefined
) => {
  if (!month || month < 0 || month > 12) return '-'
  return new Date(`2020-${month}-1`).toLocaleString(
    !cultureCode ? DEFAULT_CULTURE_CODE : cultureCode,
    {
      month: 'long',
    }
  )
}

export const getTime = (date?: string) => {
  if (date === undefined || date === null) {
    return 0
  }
  return new Date(date).getTime()
}

export const validateAndGetDate = (date: string | undefined | null) =>
  dayjs(date).isValid() && date ? dayjs(date).format('DD-MM-YYYY') : '-'
