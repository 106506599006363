import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import withClientSideRender from '../enhancers/withClientSideRender'
import useBaseGraphQLService from '../hooks/services/graphql/core/useBaseGraphQLService'
import usePartSalesInformationService, {
  PartNumberQuantityInput,
  PartSalesInformation,
  PriceInformation,
  StockInformation,
} from '../hooks/services/graphql/usePartSalesInformationService'

export interface PartListSalesInformationContextProps {
  fetching: boolean
  priceInformationList?: PriceInformation[]
  stockInformationList?: StockInformation[]
}

export const PartListSalesInformationContext =
  createContext<PartListSalesInformationContextProps>({
    fetching: false,
    priceInformationList: undefined,
    stockInformationList: undefined,
  })

export interface PartListSalesInformationProviderProps {
  includePrice?: boolean
  includeStock?: boolean
  partNumbersQuantity?: PartNumberQuantityInput[]
  children: ReactNode
}

const getUniquePartNumbersQuantity = (
  partNumbersQuantity?: PartNumberQuantityInput[]
) =>
  partNumbersQuantity?.filter(
    (v, i, a) =>
      a.findIndex((t) => t.partNumber && t.partNumber === v.partNumber) === i
  )

const PartListSalesInformationProvider = ({
  children,
  includePrice,
  includeStock,
  partNumbersQuantity,
}: PartListSalesInformationProviderProps) => {
  const [, baseVariables] = useBaseGraphQLService({
    requireAuthentication: true,
  })

  const uniquePartNumbersQuantity = useMemo(
    () => getUniquePartNumbersQuantity(partNumbersQuantity),
    [partNumbersQuantity]
  )

  const [salesInformation, setSalesInformation] = useState<
    PartSalesInformation | undefined
  >(undefined)

  const [fetchPartsSalesInformation, fetching] = usePartSalesInformationService(
    includePrice,
    includeStock,
    (data) => setSalesInformation(data)
  )

  useEffect(() => {
    if (baseVariables) fetchPartsSalesInformation(uniquePartNumbersQuantity)
  }, [baseVariables, uniquePartNumbersQuantity])

  return (
    <PartListSalesInformationContext.Provider
      value={{
        fetching,
        ...salesInformation,
      }}
    >
      {children}
    </PartListSalesInformationContext.Provider>
  )
}

export default withClientSideRender(PartListSalesInformationProvider)
