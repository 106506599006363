import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import Table from '../../../molecules/Table/Table'
import TableRow from '../../../molecules/Table/TableRow'
import TableCell from '../../../molecules/Table/TableCell'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { COLORS } from '../../../../constants/themeConstants'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import {
  getVehicleLabelForOverview,
  showDashWhenEmptyValue,
  validateAndGetDate,
} from '../../../../helpers/vehicleHelper'

interface MyFleetVehicleDetailsProps {
  vehicle: Vehicle
}
const MyFleetListVehicleDetails = ({ vehicle }: MyFleetVehicleDetailsProps) => {
  const [breakpointS] = useBreakpoints('s')
  return (
    <>
      <Flex>
        <Box>
          <Text
            as="span"
            variant="bodyContent"
            color="black"
            sx={{
              display: 'block',
              width: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
            }}
          >
            {getVehicleLabelForOverview(vehicle)}
          </Text>
        </Box>
        {!breakpointS && (
          <Box sx={{ marginTop: 1, marginLeft: 1 }}>
            <IconWrapper
              icon={ICONS.CONNECTED}
              size={3}
              color={vehicle?.hasDafConnect ? COLORS.BLACK : COLORS.MEDIUM_GRAY}
            />
          </Box>
        )}
      </Flex>
      <Table columnSizes={[0.3, 0.7]}>
        <TableRow
          sx={{
            borderBottom: 'none',
          }}
        >
          <TableCell
            sx={{
              paddingY: 0,
            }}
          >
            <Text
              as="span"
              variant="bodySmall"
              color="gray.3"
              sx={{ fontWeight: 'bold' }}
            >
              {`${i18next.t('myFleet.typeTitle')}:`}
            </Text>
          </TableCell>
          <TableCell
            sx={{
              paddingY: 0,
            }}
          >
            <Text as="span" variant="bodySmall">
              {showDashWhenEmptyValue(vehicle?.vehicleType)}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            borderBottom: 'none',
          }}
        >
          <TableCell
            sx={{
              paddingY: 0,
            }}
          >
            <Text
              as="span"
              variant="bodySmall"
              color="gray.3"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {`${i18next.t('myFleet.vinTitle')}:`}
            </Text>
          </TableCell>
          <TableCell
            sx={{
              paddingY: 0,
            }}
          >
            <Text as="span" variant="bodySmall">
              {showDashWhenEmptyValue(vehicle?.vin)}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            borderBottom: 'none',
          }}
        >
          <TableCell
            sx={{
              paddingY: 0,
            }}
          >
            <Text
              as="span"
              variant="bodySmall"
              color="gray.3"
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
              }}
            >
              {`${i18next.t('myFleet.firstRegistrationTitleAbbr')}:`}
            </Text>
          </TableCell>
          <TableCell
            sx={{
              paddingY: 0,
            }}
          >
            <Text as="span" variant="bodySmall">
              {validateAndGetDate(vehicle.firstRegistrationDate)}
            </Text>
          </TableCell>
        </TableRow>
      </Table>
    </>
  )
}

export default MyFleetListVehicleDetails
