import React, { ReactNode } from 'react'
import { Flex, Text } from 'theme-ui'

interface NoResultsProps {
  title: string
  description: string
  children?: ReactNode
}

const NoResults = ({ title, description, children }: NoResultsProps) => (
  <Flex
    sx={{
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    }}
  >
    <Text variant="heading2">{title}</Text>
    <Text variant="bodyContent" color="gray.2" sx={{ marginTop: 2 }}>
      {description}
    </Text>
    <Flex sx={{ justifyContent: 'center', marginTop: 4 }}>{children}</Flex>
  </Flex>
)

export default NoResults
