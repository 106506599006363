import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'

interface MenuListProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {}

const MenuList: FC<MenuListProps> = ({ children, sx, ...boxProps }) => (
  <Box sx={{ minWidth: '240px', ...sx }} {...boxProps}>
    {children}
  </Box>
)

export default MenuList
