import React, { FC, useState } from 'react'
import { getTextFieldValue } from '../../../../../../helpers/layoutServiceHelper'
import { Class } from '../../../../../../hooks/services/graphql/useClassInformationListService'
import { TextField } from '../../../../../../types/layoutService'
import ModalAnchor from '../../../../Modal/ModalAnchors/ModalAnchor'
import SearchResultTableAnchorsModal from '../../../../Modal/ModalVariants/SearchResultTableAnchorsModal'

interface PartSearchTableAnchorsModalAnchorProps {
  title: TextField
  availableClassInformation: Class[]
}

const PartSearchTableAnchorsModalAnchor: FC<
  PartSearchTableAnchorsModalAnchorProps
> = ({ title, availableClassInformation }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ModalAnchor
        label={getTextFieldValue(title)}
        onClick={() => setModalOpen(true)}
      />

      <SearchResultTableAnchorsModal
        isActive={modalOpen}
        onClose={() => setModalOpen(false)}
        title={getTextFieldValue(title)}
        availableClassInformation={availableClassInformation}
      />
    </>
  )
}

export default PartSearchTableAnchorsModalAnchor
