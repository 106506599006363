import React, { FC } from 'react'
import { Box, Flex } from 'theme-ui'
import { DefaultDialogRenderer } from '../core/DialogRenderer'

const SlideshowModalLayout: FC = () => (
  <Flex
    sx={{
      flexDirection: 'column',
      minHeight: '100vh',
    }}
    // minHeight="100vh"
    backgroundColor="overlay"
  >
    <Box as="main" sx={{ flexGrow: 1 }}>
      <DefaultDialogRenderer selector="modals" limit={1} />
    </Box>
  </Flex>
)

export default SlideshowModalLayout
