import React, { FC, ReactNode, useMemo } from 'react'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

const WEB_EDIT_EDIT_FIELDS_COMMAND_ID = '{2F7D34E2-EB93-47ED-8177-B1F429A9FCD7}'

const DEFAULT_EDIT_FRAME_TITLE = 'Edit'

export interface ExperienceEditorEditFrameProps {
  allowDelete?: boolean
  itemId?: string
  itemVersion?: number
  title?: string
  fieldNames?: string[]
  children: ReactNode
}

export interface ChromeExperienceEditorEditFrameProps
  extends Required<ExperienceEditorEditFrameProps> {
  language: string
}

export interface ChromeCommandData {
  click?: string
  header?: string
  icon?: string
  disabledIcon?: string
  isDivider: boolean
  tooltip?: string
  type?: string
}

export interface ChromeData {
  commands?: ChromeCommandData[]
  contextItemUri?: string
  custom?: Record<string, string>
  displayName?: string
  expandedDisplayName?: string
}

export interface ChromeDataOptions {
  allowDelete: boolean
  itemId: string
  itemVersion: number
  language: string
  title: string
  fieldNames: string[]
}

const buildContextItemUri = (
  database: string,
  itemId: string,
  itemVersion: number,
  language: string
): string => `sitecore://${database}/${itemId}?lang=${language}&ver=${itemVersion}`

const buildFieldEditorCommand = (
  itemId: string,
  fieldNames: string[]
): ChromeCommandData => {
  const pipeSeparatedFieldNames = fieldNames.join('|')

  return {
    isDivider: false,
    click: `javascript:Sitecore.PageModes.PageEditor.postRequest('webedit:fieldeditor(command=${WEB_EDIT_EDIT_FIELDS_COMMAND_ID},fields=${pipeSeparatedFieldNames},id=${itemId})',null,false)`,
    disabledIcon: '/temp/pencil_disabled16x16.png',
    header: 'Edit Fields',
    icon: '/temp/iconcache/office/16x16/pencil.png',
    tooltip: 'Edit Fields',
    type: 'click',
  }
}

const buildDeleteCommand = (itemId: string): ChromeCommandData => ({
  isDivider: false,
  click: `javascript:Sitecore.PageModes.PageEditor.postRequest('webedit:delete(id=${itemId})',null,false)`,
  disabledIcon: '/temp/delete_disabled16x16.png',
  header: 'Delete',
  icon: '/temp/iconcache/office/16x16/delete.png',
  tooltip: 'Delete',
  type: 'click',
})

const buildChromeData = ({
  allowDelete,
  itemId,
  itemVersion,
  language,
  title,
  fieldNames,
}: ChromeDataOptions): ChromeData => {
  const commands: ChromeCommandData[] = [buildFieldEditorCommand(itemId, fieldNames)]

  if (allowDelete) {
    commands.push(buildDeleteCommand(itemId))
  }

  return {
    expandedDisplayName: title,
    commands,
    contextItemUri: buildContextItemUri('master', itemId, itemVersion, language),
    displayName: title,
  }
}

const ChromeExperienceEditorEditFrame: FC<ChromeExperienceEditorEditFrameProps> = ({
  allowDelete,
  itemId,
  itemVersion,
  language,
  title,
  fieldNames,
  children,
}) => {
  const chromeData = useMemo(
    () =>
      JSON.stringify(
        buildChromeData({
          allowDelete,
          itemId,
          itemVersion,
          language,
          title,
          fieldNames,
        }),
        null,
        2
      ),
    [allowDelete, itemId, itemVersion, language, title, fieldNames]
  )

  return (
    <div className="scLooseFrameZone scEnabledChrome">
      <span className="scChromeData">{chromeData}</span>
      {children}
    </div>
  )
}

const ExperienceEditorEditFrame = ({
  allowDelete,
  itemId,
  itemVersion,
  title,
  fieldNames,
  children,
}: ExperienceEditorEditFrameProps) => {
  const {
    pageEditing,
    languageContext: { cultureCode },
  } = useSitecoreContext()

  if (pageEditing && itemId && itemVersion && cultureCode) {
    return (
      <ChromeExperienceEditorEditFrame
        allowDelete={allowDelete ?? false}
        itemId={itemId}
        itemVersion={itemVersion}
        language={cultureCode}
        title={title ?? DEFAULT_EDIT_FRAME_TITLE}
        fieldNames={fieldNames ?? []}
      >
        {children}
      </ChromeExperienceEditorEditFrame>
    )
  }

  return <>{children}</>
}

export default ExperienceEditorEditFrame
