import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Flex, FlexProps } from 'theme-ui'

interface CardGridProps extends Omit<FlexProps, 'css'> {
  stripLastRowBorders?: boolean
}

const CardGrid: FC<CardGridProps> = ({
  stripLastRowBorders,
  children,
  sx,
  ...flexProps
}) => {
  const { space } = useTheme()

  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        mx: `-${space[4] / 2}px`,
        overflow: 'hidden',
        mb: stripLastRowBorders
          ? [`-${space[3] + 1}px`, `-${space[3] + 1}px`, `-${space[5] + 1}px`] // Hide border bottom of cards on search results grid
          : 0,
        ...sx,
      }}
      {...flexProps}
    >
      {children}
    </Flex>
  )
}

export default CardGrid
