import React from 'react'
import { Flex, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { ICONS } from '../../../constants/iconConstants'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { getDividerSx } from '../../atoms/Divider/Divider'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { COLORS } from '../../../constants/themeConstants'

interface SidePanelHeaderProps {
  title?: string
  onClose: () => void
}

const SidePanelHeader = ({ title = '', onClose }: SidePanelHeaderProps) => {
  const { space } = useTheme()

  return (
    <header>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'start',
          color: COLORS.DARK,
          gap: space[1],
          paddingInline: [space[3], null, space[6]],
          paddingBlock: [space[3], null, space[5]],
          ...getDividerSx('bottom'),
        }}
      >
        <Text as="h2" variant="heading2" data-t-id="sidepanel-title">
          {title}
        </Text>

        <ClickableWrapper
          data-t-id="side-panel-close-button"
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
          sx={{
            flexShrink: 0,
          }}
          padding={1}
        >
          <IconWrapper icon={ICONS.CROSS} size={4} />
        </ClickableWrapper>
      </Flex>
    </header>
  )
}

export default SidePanelHeader
