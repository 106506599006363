import { useTheme } from '@emotion/react'
import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'

interface GridItemProps {
  children: ReactNode
}

const GridItem = ({ children }: GridItemProps) => {
  const { space } = useTheme()

  return <Box sx={{ px: [2, 2, space[4] / 2], pb: [3, 0], flex: 1 }}>{children}</Box>
}

export default GridItem
