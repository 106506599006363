import i18next from 'i18next'
import React from 'react'
import ActionsDoughnutChartWrapper from '../../ChartWrappers/ActionsDoughnutChartWrapper'
import { DoughnutProps } from '../../../../types/DoughnutChartTypes'
import {
  MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE,
  SERVICEPLAN_CONTRACT_STATE_CANCELED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRING,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
} from '../../../../constants/dafCompanyVehicleConstants'
import { COLORS } from '../../../../constants/themeConstants'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'

interface CoverageStateChartProps {
  numberOfVehicles: number
  filteredActiveRMContracts: Vehicle[] | undefined
}
const CoverageStateChart = ({
  numberOfVehicles,
  filteredActiveRMContracts,
}: CoverageStateChartProps) => {
  const servicePlanExpiringData = filteredActiveRMContracts?.filter(
    (contract) =>
      contract.rmContract?.contractState === SERVICEPLAN_CONTRACT_STATE_EXPIRING
  ).length

  const servicePlanExpiredData = filteredActiveRMContracts?.filter(
    (contract) =>
      contract.rmContract?.contractState === SERVICEPLAN_CONTRACT_STATE_EXPIRED
  ).length

  const servicePlanCanceledData = filteredActiveRMContracts?.filter(
    (contract) =>
      contract.rmContract?.contractState === SERVICEPLAN_CONTRACT_STATE_CANCELED
  ).length

  const servicePlanOnHoldData = filteredActiveRMContracts?.filter(
    (contract) =>
      contract.rmContract?.contractState === SERVICEPLAN_CONTRACT_STATE_ON_HOLD
  ).length

  const expiredFilterValues = []

  if (servicePlanExpiredData && servicePlanExpiredData > 0)
    expiredFilterValues.push(SERVICEPLAN_CONTRACT_STATE_EXPIRED)

  if (servicePlanCanceledData && servicePlanCanceledData > 0)
    expiredFilterValues.push(SERVICEPLAN_CONTRACT_STATE_CANCELED)

  if (servicePlanOnHoldData && servicePlanOnHoldData > 0)
    expiredFilterValues.push(SERVICEPLAN_CONTRACT_STATE_ON_HOLD)

  const servicePlanStateData: DoughnutProps = {
    total:
      (servicePlanExpiringData || 0) +
      (servicePlanExpiredData || 0) +
      (servicePlanCanceledData || 0) +
      (servicePlanOnHoldData || 0),
    items: [
      {
        id: 'servicePlanExpiring',
        filterCategory: MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE,
        filterValue: SERVICEPLAN_CONTRACT_STATE_EXPIRING,
        itemTotal: servicePlanExpiringData ?? 0,
        color: COLORS.PROMOTION,
      },
      {
        id: 'servicePlanExpired',
        filterCategory: MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE,
        filterValue: expiredFilterValues,
        itemTotal:
          // || 1 is needed to render the pie
          (servicePlanExpiredData ?? 0) +
            (servicePlanCanceledData ?? 0) +
            (servicePlanOnHoldData ?? 0) || 1,
        color: COLORS.ALERT,
      },
    ],
    legenda: [
      {
        id: 'servicePlanExpiring',
        name: i18next.t('dafCompanyVehicles.expiring'),
        itemTotal: servicePlanExpiringData ?? 0,
        color: COLORS.PROMOTION,
      },
      {
        id: 'servicePlanExpired',
        name: i18next.t('dafCompanyVehicles.expired'),
        itemTotal:
          (servicePlanExpiredData ?? 0) +
          (servicePlanCanceledData ?? 0) +
          (servicePlanOnHoldData ?? 0),
        color: COLORS.ALERT,
      },
    ],
  }
  return (
    <ActionsDoughnutChartWrapper
      chartTitle={i18next.t('actionsStatisticsLabels.servicePlanStateTitle')}
      chartData={servicePlanStateData}
      numberOfVehicles={numberOfVehicles}
    />
  )
}

export default CoverageStateChart
