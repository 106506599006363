import React, { FC } from 'react'
import Button from '../../../../atoms/Button/Button'
import { CommerceWebshopPointsCampaign } from '../../../../../types/commerceApi'
import { LinkField } from '../../../../../types/layoutService'

interface WebshopPointsCampaignButtonProps {
  webshopPointsCampaign?: CommerceWebshopPointsCampaign
  activateAccountLink?: LinkField
  continueShoppingLink?: LinkField
  redeemPointsLink?: LinkField
}

const WebshopPointsCampaignButton: FC<WebshopPointsCampaignButtonProps> = ({
  webshopPointsCampaign,
  activateAccountLink,
  continueShoppingLink,
  redeemPointsLink,
}) => {
  if (!webshopPointsCampaign) return null

  if (webshopPointsCampaign.activationCode && activateAccountLink) {
    // Activate
    return (
      <Button
        as="a"
        href={`${activateAccountLink?.url}=${webshopPointsCampaign.activationCode}`}
        target="_blank"
        variant="primary"
        sx={{ marginBottom: 3, width: '100%' }}
      >
        {activateAccountLink?.text}
      </Button>
    )
  }

  if (
    webshopPointsCampaign.maxPointsReached &&
    redeemPointsLink &&
    !webshopPointsCampaign.pointsRedeemed
  ) {
    // Maximum points earned
    return (
      <Button
        as="a"
        href={redeemPointsLink?.url}
        variant="primary"
        sx={{ marginBottom: 3, width: '100%' }}
      >
        {redeemPointsLink?.text}
      </Button>
    )
  }

  // Maximum points redeemed
  // Activated
  return continueShoppingLink ? (
    <Button
      as="a"
      href={continueShoppingLink?.url}
      variant="primary"
      sx={{ marginBottom: 3, width: '100%' }}
    >
      {continueShoppingLink?.text}
    </Button>
  ) : null
}

export default WebshopPointsCampaignButton
