import { Cell, Customized, Pie, PieChart, ResponsiveContainer } from 'recharts'
import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { useHistory } from 'react-router-dom'
import { COLORS } from '../../../constants/themeConstants'
import { DoughnutItem, DoughnutProps } from '../../../types/DoughnutChartTypes'
import {
  getFilterStringByActiveSearchResultFilterObject,
  toggleFilter,
} from '../../../helpers/filterHelper'
import { MY_FLEET_LIST_URL } from '../../../constants/dafCompanyVehicleConstants'

interface DoughnutChartProps {
  chartData: DoughnutProps
  numberOfVisibleSections: number
  showTotal?: boolean
  numberOfVehicles: number
  onAnimationCompleted?: () => void
}
const DoughnutChart = ({
  chartData,
  numberOfVisibleSections,
  showTotal = true,
  numberOfVehicles,
  onAnimationCompleted,
}: DoughnutChartProps) => {
  const [filterCategory, setFilterCategory] = useState<string | null>(null)
  const [filterValue, setFilterValue] = useState<string | string[] | null>(null)
  const [activeItemId, setActiveItemId] = useState<string | null>(null)
  const [animationCompleted, setAnimationCompleted] = useState<boolean>(false)
  const [chartTotal, setChartTotal] = useState<number>(0)
  const history = useHistory()

  // const findLabelTextColor = (id: string) =>
  //   chartData.items.find((chart) => chart.id === id)?.color || COLORS.BLACK

  useEffect(() => {
    // check to determine if pie needs to be green with checkmark later on
    if (chartData.total > 0) {
      setChartTotal(chartData.total)
    }
  }, [chartData.total])

  const handleMouseEnter = (entry: DoughnutItem) => {
    setActiveItemId(entry.id)
    if (!entry.filterCategory || !entry.filterValue) return
    setFilterCategory(entry.filterCategory)
    setFilterValue(entry.filterValue)
  }

  useEffect(() => {
    if (onAnimationCompleted && animationCompleted) onAnimationCompleted()
  }, [animationCompleted, onAnimationCompleted])

  const setMyFleetFilter = () => {
    if (!filterCategory || !filterValue || chartTotal === 0) return

    const params = new URLSearchParams(history.location.search)

    if (Array.isArray(filterValue)) {
      history.push(
        `${MY_FLEET_LIST_URL}?${getFilterStringByActiveSearchResultFilterObject(
          { [filterCategory]: filterValue },
          params,
          false,
          false
        )}`
      )
    } else {
      const newFilterObject = toggleFilter({}, filterCategory, filterValue)

      history.push(
        `${MY_FLEET_LIST_URL}?${getFilterStringByActiveSearchResultFilterObject(
          newFilterObject,
          params,
          false,
          false
        )}`
      )
    }
  }

  const handleMouseLeave = () => {
    setActiveItemId(null)
  }
  const totalIndicator = () => (
    <>
      <text x="50%" y="47%" fill={COLORS.GRAY} fontSize={12} textAnchor="middle">
        {i18next.t('commonLabels.total')}
      </text>
      <text x="50%" y="57%" fill={COLORS.BLACK} fontSize={20} textAnchor="middle">
        {chartData.total}
      </text>
    </>
  )

  const checkMark = () => (
    <svg
      y="35%"
      width="100%"
      height="64px"
      viewBox="0 0 1024 1024"
      role="presentation"
    >
      <path d="M464 684.8l-166.4-166.4 44.8-44.8 121.6 121.6 233.6-233.6 44.8 44.8z" />
    </svg>
  )

  const pieStrokeColor = (entry: DoughnutItem) => {
    if (numberOfVehicles === 0) {
      return COLORS.LIGHT_GRAY
    }
    if (chartTotal === 0) {
      return COLORS.CONFIRMATION
    }
    return entry.color
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={180}>
        <PieChart>
          <Pie
            data={chartData.items}
            innerRadius={60}
            outerRadius={70}
            startAngle={180}
            endAngle={-180}
            animationDuration={1000}
            fill={COLORS.CONFIRMATION}
            cornerRadius={2}
            paddingAngle={
              numberOfVisibleSections && numberOfVisibleSections > 1 ? 5 : 0
            }
            dataKey="itemTotal"
            style={{ outline: 'none', cursor: 'pointer' }}
            onClick={() => {
              setMyFleetFilter()
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onAnimationEnd={() => setAnimationCompleted(true)}
          >
            {chartData.items.map((entry: DoughnutItem) => (
              <Cell
                key={`cell-${entry.id}`}
                fill={pieStrokeColor(entry)}
                stroke={activeItemId === entry.id ? pieStrokeColor(entry) : 'none'}
                strokeWidth={activeItemId === entry.id ? '2px' : '0'}
              />
            ))}
          </Pie>
          {animationCompleted && showTotal && (
            <>
              <Customized
                component={
                  chartData.total === 0 && numberOfVehicles !== 0
                    ? checkMark
                    : totalIndicator
                }
              />
            </>
          )}
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}
export default DoughnutChart
