import React, { FC } from 'react'
import { FlexProps, ThemeUIStyleObject } from 'theme-ui'
import { ImageWithFocalPointField, LogoPosition } from '../../../types/layoutService'
import SitecoreImage from '../SitecoreImage/SitecoreImage'

const LOGO_SIZE = ['100px', '200px', '200px']
const LOGO_SPACE = ['16px', '16px', '64px']
const LOGO_OFFSET = ['40px', '40px', '20px']
const LOGO_OVERLAP_OFFSET = ['-10px', '-10px', '-100px']

interface BannerLogoProps extends Omit<FlexProps, 'css'> {
  logoImageField?: ImageWithFocalPointField
  logoPosition?: LogoPosition | ''
}

const getSxForLogoPosition = (logoPosition?: LogoPosition): ThemeUIStyleObject => {
  // TODO: clean up method
  switch (logoPosition) {
    case 'topLeft':
      return { left: LOGO_SPACE, top: LOGO_OFFSET }
    case 'topLeftOverlap':
      return { left: LOGO_SPACE, top: LOGO_OVERLAP_OFFSET }
    case 'topRight':
      return { right: LOGO_SPACE, top: LOGO_OFFSET }
    case 'topRightOverlap':
      return { right: LOGO_SPACE, top: LOGO_OVERLAP_OFFSET }
    case 'bottomLeft':
      return { left: LOGO_SPACE, bottom: LOGO_OFFSET }
    case 'bottomLeftOverlap':
      return { left: LOGO_SPACE, bottom: LOGO_OVERLAP_OFFSET }
    case 'bottomRight':
      return { right: LOGO_SPACE, bottom: LOGO_OFFSET }
    case 'bottomRightOverlap':
      return { right: LOGO_SPACE, bottom: LOGO_OVERLAP_OFFSET }
    default:
      return {}
  }
}

const BannerLogo: FC<BannerLogoProps> = ({ logoImageField, logoPosition, sx }) => (
  <SitecoreImage
    imageField={logoImageField}
    sx={{
      position: 'absolute',
      zIndex: 4,
      width: LOGO_SIZE,
      height: LOGO_SIZE,
      objectFit: 'contain',
      ...getSxForLogoPosition(logoPosition || 'topLeft'),
      ...sx,
    }}
  />
)

export default BannerLogo
