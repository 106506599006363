import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { GraphServiceTuple } from '../../../types/graphServiceTypes'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'

const GET_MENU_ITEM_LIST = loader('./schemas/menuItemListQuery.graphql')

interface MenuItemListQueryVariables {
  [key: string]: any
  cultureCode: string
  isImpersonated?: boolean
}

interface MenuItemListQueryData {
  menu?: MenuItem[]
}

export interface MenuItem {
  description: string
  id: string
  url: string
  subMenuItems?: MenuItem[]
  priority: number
}

const useMenuItemListService = (): GraphServiceTuple<MenuItem[] | undefined> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService({
    requireAuthentication: true,
  })

  const [menuItemList, setMenuItemList] = useState<MenuItem[] | undefined>(undefined)

  const onData = useCallback((data?: MenuItemListQueryData) => {
    if (data?.menu?.length) {
      setMenuItemList(data.menu)
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    MenuItemListQueryData,
    MenuItemListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables) {
      fetch({
        document: GET_MENU_ITEM_LIST,
        variables: {
          ...baseVariables,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables])

  return [fetching, menuItemList]
}

export default useMenuItemListService
