import React, { FC } from 'react'
import { Flex, FlexProps } from 'theme-ui'

interface SidePanelFooterProps extends Omit<FlexProps, 'css'> {}

const SidePanelFooter: FC<SidePanelFooterProps> = ({
  children,
  sx,
  ...flexProps
}) => (
  <Flex
    as="footer"
    sx={{ bg: 'background', paddingX: [3, 3, 6], paddingY: [3, 3, 5], ...sx }}
    {...flexProps}
  >
    {children}
  </Flex>
)

export default SidePanelFooter
