import React, {
  FC,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Flex, FlexProps, Input } from 'theme-ui'
import {
  getBackgroundColor,
  getBorderColor,
  getTextColor,
} from '../../../../helpers/formInputHelper'

export interface FormInputTextListProps extends Omit<FlexProps, 'css' | 'onChange'> {
  error?: boolean
  focused?: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange: (inputValue: string) => void
  dataTId?: string
  type?: string
}

const FormInputTextList: FC<FormInputTextListProps> = ({
  sx,
  error = false,
  focused = false,
  disabled = false,
  readOnly = false,
  type,
  onChange,
  children,
  dataTId = 'form-input-text-list',
  ...flexProps
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [focus, setFocus] = useState(focused)

  const appendToList = useCallback(
    (eventTarget: HTMLInputElement) => {
      const valueClean = eventTarget.value.trim()

      if (!valueClean) return

      onChange(valueClean)

      // eslint-disable-next-line no-param-reassign
      eventTarget.value = ''
    },
    [onChange]
  )

  const focusOnInput = useCallback((e: MouseEvent<HTMLInputElement>) => {
    if (wrapperRef.current && inputRef.current && e.target === wrapperRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const eventListenerList = useMemo(
    () => ({
      onFocus: () => setFocus(true),
      onBlur: (e: FocusEvent<HTMLInputElement>) => {
        appendToList(e.currentTarget)
        setFocus(false)
      },
      onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') appendToList(e.currentTarget)
      },
    }),
    [appendToList]
  )

  return (
    <Flex
      ref={wrapperRef}
      onClick={focusOnInput}
      data-t-id={dataTId}
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        gridTemplateRows: '1fr',
        padding: 2,
        paddingBottom: 0,
        borderColor: getBorderColor(error, focus),
        backgroundColor: getBackgroundColor(disabled || readOnly),
        borderStyle: 'solid',
        borderRadius: 'default',
        borderWidth: 1,
        width: '100%',
        ...sx,
      }}
      {...flexProps}
    >
      {children}
      <Input
        ref={inputRef}
        data-t-id={dataTId ? `${dataTId}-input-new-recipient` : null}
        sx={{
          minWidth: '200px',
          flexGrow: 1,
          marginBottom: 2,
          border: 'none',
          color: getTextColor(error, disabled),
          backgroundColor: 'transparent',
          font: 'inherit',
          fontSize: 1,
          lineHeight: 'buttonText',
          outline: 'none',
        }}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        {...eventListenerList}
      />
    </Flex>
  )
}

export default FormInputTextList
