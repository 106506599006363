import React, { FC } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'
import { ICONS } from '../../../../constants/iconConstants'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import {
  FILTER_GROUP_TYPE_CHECKBOX,
  FILTER_GROUP_TYPE_RADIOBUTTON,
} from '../../../../constants/searchConstants'

interface PartFilterGroupHeadingProps extends BoxProps {
  selectionCount: number
  active: boolean
  onClick: () => void
  disabled?: boolean
  type?: typeof FILTER_GROUP_TYPE_CHECKBOX | typeof FILTER_GROUP_TYPE_RADIOBUTTON
}

const PartFilterGroupHeading: FC<PartFilterGroupHeadingProps> = ({
  selectionCount,
  active,
  onClick,
  children,
  disabled,
  type = FILTER_GROUP_TYPE_CHECKBOX,
  sx,
}) => (
  <ClickableWrapper
    onClick={() => !disabled && onClick()}
    disabled={disabled}
    sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      outline: 'none',
      ':hover, :active, :focus': {
        color: !disabled ? 'primary' : undefined,
      },
      cursor: disabled ? 'wait' : undefined,
      opacity: disabled ? 0.5 : undefined,
      ...sx,
    }}
  >
    <Text
      variant="heading4"
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}

      <Text
        variant="buttonText"
        sx={{
          display: 'flex',
          paddingLeft: 3,
          alignItems: 'center',
          fontSize: 1,
          textAlign: 'right',
        }}
      >
        {type === 'Checkbox' && (
          <Box data-t-id="filter-group-selection-count" mr={2} color="primary">
            {selectionCount >= 1 && selectionCount}
          </Box>
        )}
      </Text>
    </Text>

    <IconWrapper
      icon={ICONS.ARROW_DOWN}
      size={4}
      style={{
        transform: `rotate(${active ? 180 : 0}deg)`,
      }}
    />
  </ClickableWrapper>
)

export default PartFilterGroupHeading
