import React, { FC, useCallback, useContext } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { EVENT_CATEGORIES } from '../../../constants/analyticsConstants'
import { LinkField, TextField } from '../../../types/layoutService'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import { getCategoryById, getSubCategoryById } from '../../../helpers/categoryHelper'
import AddToBasketRequestAccountButton from './AddToBasketRequestAccountButton'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import AddToBasket, { DEFAULT_STEP_SIZE } from './AddToBasket'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'

interface PartAddToBasketProps extends Omit<BoxProps, 'css' | 'label'> {
  partNumber?: string
  partEnglishDescription?: string
  brandName?: string
  categoryId?: string
  subcategoryId?: string
  label?: TextField
  askQuotationLabel?: TextField
  requestAccountLink?: LinkField
  variant?: 'small'
  dataLayerComponentOrigin: string
  stepSize?: number
  initialQuantity?: number
}

const PartAddToBasket: FC<PartAddToBasketProps> = ({
  partNumber,
  partEnglishDescription,
  brandName,
  categoryId,
  subcategoryId,
  label,
  requestAccountLink,
  askQuotationLabel,
  variant,
  dataLayerComponentOrigin,
  stepSize = DEFAULT_STEP_SIZE,
  initialQuantity = DEFAULT_STEP_SIZE,
  ...boxProps
}) => {
  const { categories } = useContext(CategoriesContext)
  const { activeSupplier, actingCompanyId, actingSupplierId } = useContext(
    ActiveStoreProviderContext
  )
  const { priceInformation } = useContext(PartSalesInformationContext)

  const onOrder = useCallback(
    (quantity: number) => {
      if (quantity && partNumber && actingCompanyId && actingSupplierId) {
        const mainCategory = getCategoryById(categoryId, categories)
        const subCategory = getSubCategoryById(subcategoryId, mainCategory)

        if (activeSupplier?.userIsGuest) {
          pushToDataLayer({
            [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.ADD_TO_QUOTATION,
            product_name: partEnglishDescription,
            page_type: dataLayerComponentOrigin,
            product_id: partNumber,
          })
        } else {
          pushToDataLayer({
            event: DATA_LAYER.EVENT_NAME.ADD_TO_CART,
            ecommerce: {
              currency: priceInformation?.price?.currencyCode,
              value: priceInformation?.price?.netTotalPrice,
              items: [
                {
                  item_id: partNumber,
                  item_name: partEnglishDescription,
                  item_brand: brandName,
                  item_category:
                    subCategory?.englishDescription ||
                    mainCategory?.englishDescription ||
                    EVENT_CATEGORIES.UNCATEGORIZED,
                  item_list_name: dataLayerComponentOrigin,
                  doa_product: false, // 'dealer own assortment'
                  quantity,
                  price: priceInformation?.price?.netUnitPrice,
                },
              ],
            },
          })
        }
      }
    },
    [
      partNumber,
      actingCompanyId,
      actingSupplierId,
      categoryId,
      categories,
      subcategoryId,
      activeSupplier,
      partEnglishDescription,
      dataLayerComponentOrigin,
      priceInformation,
      brandName,
    ]
  )

  return (
    <Box {...boxProps}>
      <AddToBasketRequestAccountButton
        requestAccountLink={requestAccountLink}
        partNumber={partNumber}
        dataLayerComponentOrigin={dataLayerComponentOrigin}
      />

      <AddToBasket
        partNumber={partNumber}
        variant={variant}
        stepSize={stepSize}
        initialQuantity={initialQuantity}
        orderLabel={label}
        askQuotationLabel={askQuotationLabel}
        onOrder={onOrder}
      />
    </Box>
  )
}

export default PartAddToBasket
