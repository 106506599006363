import React, { FC } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Flex, Text } from 'theme-ui'
import Divider from '../../../../atoms/Divider/Divider'
import ClickableWrapper from '../../../../atoms/ClickableWrapper/ClickableWrapper'
import PartsNavigationPanelHeaderParentLink from './PartsNavigationPanelHeaderParentLink'
import { ICONS } from '../../../../../constants/iconConstants'
import { FILTER_PANEL_VARIANT } from '../../../../../constants/partsNavigationConstants'
import IconWrapper from '../../../../atoms/IconWrapper/IconWrapper'
import { Category } from '../../../../../hooks/services/graphql/useCategoryListService'

interface PartsNavigationPanelHeaderProps {
  title: string
  variant?: string
  parentCategory?: Category
  subCategory?: Category
  partsBaseUri?: string
  backToPreviousPanel?: () => void
}

const PartsNavigationPanelHeader: FC<PartsNavigationPanelHeaderProps> = ({
  partsBaseUri,
  title,
  parentCategory = undefined,
  subCategory = undefined,
  variant,
  backToPreviousPanel,
}) => {
  const { mainNavigation } = useTheme()

  return (
    <Box
      as="header"
      sx={{
        width: '100%',
      }}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex
          sx={{
            pr: 3,
            pl: [3, 3, 3, 5],
            alignItems: 'flex-start',
            width: '100%',
            color: mainNavigation.textColor,
          }}
        >
          <ClickableWrapper
            onClick={backToPreviousPanel}
            sx={{
              display: ['flex', 'flex', 'flex', 'none'],
              my: 2,
              py: 2,
              px: 0,
              width: '100%',
              svg: {
                display: 'inline-block',
              },
            }}
          >
            <IconWrapper size={4} icon={ICONS.ARROW_LEFT} />

            <Text
              as="h2"
              sx={{
                fontSize: 2,
                color: 'inherit',
                fontWeight: 'bold',
                lineHeight: 'heading3',
                px: 3,
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              {title}
            </Text>
          </ClickableWrapper>

          <Text
            as="h2"
            sx={{
              display: ['none', 'none', 'none', 'block'],
              my: 0,
              padding: 2,
              width: '100%',
              fontSize: 4,
              color: 'inherit',
              fontWeight: 'normal',
              lineHeight: 'heading2',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {title}
          </Text>
        </Flex>

        <Box
          pl={5}
          sx={{
            overflow: 'hidden',
            display: ['block', 'block', 'block', 'none'],
          }}
        >
          <Divider
            ml={3}
            color={mainNavigation.dividerColor}
            opacity={mainNavigation.dividerOpacity}
          />
        </Box>

        {parentCategory && (
          <PartsNavigationPanelHeaderParentLink
            partsBaseUri={partsBaseUri}
            title={title}
            variant={variant === FILTER_PANEL_VARIANT ? FILTER_PANEL_VARIANT : ''}
            categoryId={parentCategory.id}
            subCategoryId={subCategory?.id}
          />
        )}
      </Flex>
    </Box>
  )
}

export default PartsNavigationPanelHeader
