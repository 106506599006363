import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'

interface TileProps {
  children: ReactNode
}

const Tile = ({ children }: TileProps) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: COLORS.MEDIUM_GRAY,
      borderRadius: 4,
      padding: 5,
    }}
  >
    {children}
  </Box>
)

export default Tile
