import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import { CommerceOrderItem } from '../../../types/commerceApi'
import SuggestionInformationSidePanel from '../../organisms/SidePanel/ShoppingBasket/SuggestionInformationSidePanel'

interface PartLineSuggestionInformationProps {
  part?: CommerceOrderItem
}

const PartLineSuggestionInformation: FC<PartLineSuggestionInformationProps> = ({
  part,
}) => {
  if (!part?.suggestionInformation) return null

  return (
    <Flex
      sx={{
        marginBottom: 1,
      }}
    >
      <SuggestionInformationSidePanel part={part} />
    </Flex>
  )
}

export default PartLineSuggestionInformation
