import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useSelector } from 'react-redux'
import i18next from 'i18next'
import { COLORS } from '../../../constants/themeConstants'
import { RootState } from '../../../reducers'
import { validateString } from '../../../helpers/stringHelper'
import { displayValueOrConvertBooleanToHumanReadableString } from '../../../constants/partAttributeHelper'
import {
  AT_CONTRACT_ANNIVERSARY,
  START_DATE_TWO_INDEXATION_MONTHS,
} from '../../../constants/dafCompanyVehicleConstants'
import toCamelCase from '../../../helpers/camelCaseHelper'
import { getMonthName } from '../../../helpers/dateHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const MainServicePlanDetailTile = () => {
  const { vehicleData, isFetching } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )

  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const inflationProtected =
    vehicleData?.rmContract?.finance?.financialDetails?.customerPriceIndexation
      ?.inflationProtected

  const indexationTiming =
    vehicleData?.rmContract?.finance?.financialDetails?.customerPriceIndexation
      ?.timing

  const month1 = getMonthName(
    vehicleData?.rmContract?.finance?.financialDetails?.customerPriceIndexation
      ?.month1,
    cultureCode
  )

  const month2 = getMonthName(
    vehicleData?.rmContract?.finance?.financialDetails?.customerPriceIndexation
      ?.month2,
    cultureCode
  )

  return (
    <>
      {!isFetching && (
        <>
          <Flex
            sx={{
              flexDirection: 'row',
              width: '100%',
              alignContent: 'center',
              marginBottom: [4, 4, 5],
            }}
          >
            <Text variant="heading2">
              {i18next.t('finance.mainServiceDetailsTitle')}
            </Text>
          </Flex>
          <Box sx={{ flexGrow: 1 }}>
            <Flex>
              <Box sx={{ flexGrow: 1 }}>
                <Text
                  variant="bodySmall"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {i18next.t('finance.priceType')}
                </Text>
              </Box>
              <Box>
                <Text variant="bodySmall" color={COLORS.GRAY}>
                  {validateString(
                    vehicleData?.rmContract?.finance?.financialDetails
                      ?.installmentFrequency
                  )}
                </Text>
              </Box>
            </Flex>
            <Flex sx={{ marginTop: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Text
                  variant="bodySmall"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {i18next.t('finance.inflationProtected')}
                </Text>
              </Box>
              <Box>
                <Text variant="bodySmall" color={COLORS.GRAY}>
                  {displayValueOrConvertBooleanToHumanReadableString(
                    inflationProtected?.toString() || ''
                  )}
                </Text>
              </Box>
            </Flex>
            {!inflationProtected && (
              <>
                <Flex sx={{ marginTop: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Text
                      variant="bodySmall"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {i18next.t('finance.indexationTiming')}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="bodySmall" color={COLORS.GRAY}>
                      {indexationTiming
                        ? i18next.t(`finance.${toCamelCase(indexationTiming)}`)
                        : '-'}
                    </Text>
                  </Box>
                </Flex>
                {indexationTiming !== AT_CONTRACT_ANNIVERSARY && (
                  <Flex sx={{ marginTop: 1 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Text
                        variant="bodySmall"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {i18next.t('commonLabels.month')}
                      </Text>
                    </Box>
                    <Box>
                      <Text variant="bodySmall" color={COLORS.GRAY}>
                        {indexationTiming === START_DATE_TWO_INDEXATION_MONTHS
                          ? `${month1}, ${month2}`
                          : month1}
                      </Text>
                    </Box>
                  </Flex>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default MainServicePlanDetailTile
