import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { BannerVariant, Flag } from '../../../types/layoutService'

export interface BannerProps extends Omit<BoxProps, 'css'> {
  bottomSpacing?: Flag
}

export const DEFAULT_BANNER_VARIANT: BannerVariant = 'Large'

const Banner: FC<BannerProps> = ({ bottomSpacing, children, sx, ...boxProps }) => {
  const { banner } = useTheme()

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: banner.backgroundColor,
        marginBottom: bottomSpacing === '1' ? [4, 4, 7] : 0,
        zIndex: 0,
        width: '100%',
        height: 'auto',
        ...sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default Banner
