import { RequestOptions, Variables } from 'graphql-request'
import { useCallback, useState } from 'react'
import useIsMounted from '../../../useIsMounted'
import useAppInsightsErrorHandler from './useAppInsightsErrorHandler'
import useGraphQLClient from './useGraphQLClient'

export type UseLazyQueryDataFunc<TData> = (data?: TData) => void
export type UseLazyQueryErrorFunc = (error?: Error) => void

export type UseLazyQueryFetchFunc<TVariables extends Variables> = (
  requestOptions: RequestOptions<TVariables>
) => void

export type UseLazyQuery<TVariables extends Variables> = [
  UseLazyQueryFetchFunc<TVariables>,
  boolean
]

const useLazyQuery = <TData, TVariables extends Variables>(
  onData: UseLazyQueryDataFunc<TData>,
  onError?: UseLazyQueryErrorFunc
): UseLazyQuery<TVariables> => {
  const isMounted = useIsMounted()
  const client = useGraphQLClient()

  const [fetching, setFetching] = useState<boolean>(false)

  const appInsightsErrorHandler = useAppInsightsErrorHandler<TVariables>()

  const fetch = useCallback(
    async (requestOptions: RequestOptions<TVariables>) => {
      if (!isMounted()) {
        return
      }

      setFetching(true)

      try {
        const data = await client.request<TData, TVariables>(requestOptions)

        if (isMounted()) {
          onData(data)
        }
      } catch (err) {
        if (isMounted()) {
          appInsightsErrorHandler(requestOptions, err as Error)

          if (onError) {
            onError(err as Error)
          }
        }
      } finally {
        if (isMounted()) {
          setFetching(false)
        }
      }
    },

    [isMounted, client, onData, onError, appInsightsErrorHandler]
  )

  return [fetch, fetching]
}

export default useLazyQuery
