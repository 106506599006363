import React, { FC, useCallback } from 'react'
import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import QuickOrderByPartNumber from '../../ShoppingBasket/QuickOrder/QuickOrderByPartNumber'
import { tooltipRegister } from '../../../../actions/tooltipActions'
import {
  TOOLTIP_BASKET,
  TOOLTIP_ORDER_OVERVIEW,
} from '../../../../constants/tooltipConstants'
import { ICONS } from '../../../../constants/iconConstants'
import { PART_SUPERSEDED } from '../../../../constants/partConstants'
import { CommerceMessage, CommerceOrderItem } from '../../../../types/commerceApi'
import FormInputLabel from '../../../atoms/FormControls/FormInputLabel'
import { Box, Flex } from 'theme-ui'

interface OrderOverviewQuickOrderByPartNumberProps {}

const OrderOverviewQuickOrderByPartNumber: FC<
  OrderOverviewQuickOrderByPartNumberProps
> = () => {
  const dispatch = useDispatch()

  const triggerTooltip = useCallback(
    (orderItem: CommerceOrderItem, message?: CommerceMessage) => {
      if (message?.code === PART_SUPERSEDED) {
        dispatch(
          tooltipRegister({
            name: TOOLTIP_BASKET,
            data: {
              icon: ICONS.CHECK,
              label: i18next.t(
                'shoppingLabels.tooltips.superSededSuccessfullyAdded',
                {
                  superSededFrom: message?.argsObj?.superSededFrom,
                  superSededTo: message?.argsObj?.superSededTo,
                }
              ),
            },
          })
        )
      } else {
        dispatch(
          tooltipRegister({
            name: TOOLTIP_BASKET,
            data: {
              icon: ICONS.CHECK,
              label: i18next.t('shoppingLabels.tooltips.quickOrderSuccess', {
                partNumber: orderItem.partNumber,
              }),
            },
          })
        )
      }
    },
    [dispatch]
  )

  return (
    <Box
      sx={{
        padding: [null, null, 4],
        border: [null, null, '1px solid'],
        borderColor: [null, null, 'gray.1'],
      }}
    >
      <Flex
        sx={{
          flexWrap: 'wrap',
          gap: [3, 4],
          '> *': {
            flexGrow: 1,
            flexBasis: '320px',
          },
        }}
      >
        <div>
          <FormInputLabel htmlFor="orderOverviewQuickOrderByPartNumber">
            {i18next.t('shoppingLabels.addProduct')}
          </FormInputLabel>

          <QuickOrderByPartNumber
            id="orderOverviewQuickOrderByPartNumber"
            location={TOOLTIP_ORDER_OVERVIEW}
            onOrder={triggerTooltip}
          />
        </div>

        <span />
      </Flex>
    </Box>
  )
}

export default OrderOverviewQuickOrderByPartNumber
