import React from 'react'
import { Box, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import Avatar from '../../atoms/Avatar/Avatar'
import { DigitalMagazineTestimonalProps } from '../../../types/digitalMagazineTypes'
import renderHTML from '../../../helpers/renderHTMLHelper'
import { COLORS } from '../../../constants/themeConstants'

const DigitalMagazineTestimonial = ({
  authorImage,
  authorName,
  authorJobTitle,
  text,
}: DigitalMagazineTestimonalProps) => {
  const { space } = useTheme()

  return (
    <Box>
      {text && (
        <Text
          as="blockquote"
          sx={{
            lineHeight: 'heading2',
            color: COLORS.WHITE,
            display: 'flex',
            gap: 2,
            '&::before': {
              display: 'block',
              marginBlockStart: '12px',
              quotes: '"“" "”" "‘" "’"',
              content: 'open-quote',
              fontSize: 7,
              fontWeight: 'bold',
              lineHeight: 'heading2',
            },
          }}
        >
          <Text
            sx={{
              textWrap: 'balance',
              '&::after': {
                quotes: '"“" "”" "‘" "’"',
                content: 'close-quote',
              },
            }}
          >
            {renderHTML(text)}
          </Text>
        </Text>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBlockStart: space[6],
        }}
      >
        <Avatar
          image={authorImage.src}
          alt={authorImage.alt}
          sx={{
            marginInlineEnd: space[3],
            maxInlineSize: space[7],
            maxBlockSize: space[7],
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Text sx={{ color: COLORS.WHITE, fontWeight: 'bold' }}>{authorName}</Text>
          <Text sx={{ color: COLORS.WHITE, marginBlockStart: space[2] }}>
            {authorJobTitle}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default DigitalMagazineTestimonial
