import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { alphaColor } from '../../../helpers/themeHelper'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'

export interface TabProps extends Omit<TextProps, 'css' | 'onClick'> {
  panelId?: string
  active?: boolean
  onClick?: (id?: string) => void
}

const Tab: FC<TabProps> = ({
  panelId,
  active,
  onClick,
  sx: textSx,
  children,
  ...textProps
}) => {
  const { transitions } = useTheme()

  return (
    <ClickableWrapper
      onClick={() => !!onClick && onClick(panelId)}
      sx={{
        ':last-of-type span': {
          mr: 0,
        },
      }}
    >
      <Text
        as="span"
        variant="heading4"
        sx={{
          display: 'block',
          color: active ? 'text' : 'primary',
          backgroundColor: active ? 'background' : alphaColor(COLORS.BLACK, 0.04),
          py: 2,
          px: 3,
          mr: 1,
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
          transition: `ease all ${transitions[1]}`,
          textAlign: ['center', 'center', 'center', 'left'],
          ':hover': {
            backgroundColor: active ? 'background' : alphaColor(COLORS.BLACK, 0.08),
          },
          ...textSx,
        }}
        {...textProps}
      >
        {children}
      </Text>
    </ClickableWrapper>
  )
}

export default Tab
