import React, { ChangeEvent } from 'react'
import { Input, Label, Text } from 'theme-ui'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { COLORS } from '../../../../constants/themeConstants'

interface RadioProps {
  id?: string
  name?: string
  label?: string
  value: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  disabled?: boolean
  checked?: boolean
}

const Radio = ({
  id,
  name,
  value,
  required = false,
  disabled = false,
  checked = false,
  onChange,
  label = '',
}: RadioProps) => {
  const renderInputRadio = () => (
    <Input
      type="radio"
      id={id || `${name}-${value}`}
      name={name}
      value={value || name}
      required={required}
      checked={checked}
      onChange={onChange}
      {...(pageEditing ? { disabled: true } : { disabled })}
      sx={{
        marginBlockStart: 1,
        appearance: 'none',
        blockSize: 16,
        inlineSize: 16,
        border: '1px solid',
        borderColor: COLORS.DARK_GRAY,
        borderRadius: '50%',
        position: 'relative',
        cursor: 'pointer',
        ':after': {
          content: '""',
          position: 'absolute',
          inset: 1.2,
          display: 'block',
          size: 10,
          borderRadius: 5,
          opacity: 0,
          backgroundColor: COLORS.DAF_BLUE,
          margin: '2px',
        },
        ':checked': {
          borderColor: COLORS.DARK_GRAY,
          ':after': {
            opacity: 1,
          },
        },
        ':focus-visible': {
          outline: '1px solid',
          outlineColor: COLORS.DARK_GRAY,
          outlineOffset: 1,
        },
      }}
    />
  )

  const { pageEditing } = useSitecoreContext()
  return (
    <>
      {label !== '' && (
        <Label htmlFor={`${name}-${value}`} sx={{ gap: 2, paddingBlockEnd: 2 }}>
          {renderInputRadio()}
          <Text variant="bodySmall">{label}</Text>
        </Label>
      )}

      {label === '' && <>{renderInputRadio()}</>}
    </>
  )
}

export default Radio
