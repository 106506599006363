import i18next from 'i18next'
import React, { useMemo } from 'react'
import { Text } from 'theme-ui'
import { useSelector } from 'react-redux'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { RootState } from '../../../../reducers'
import {
  getCurrencyCodeFromPriceInformation,
  getFormattedPriceString,
} from '../../../../helpers/priceHelper'
import Price from '../../../atoms/Price/Price'
import { QUOTE_OPEN } from '../../../../constants/orderConstants'

const OrderStickySummaryTotalPrice = () => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { orderSummary } = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel
  )
  const formattedTotalOrderValue = useMemo(() => {
    if (!order?.items?.length) {
      return getFormattedPriceString(
        cultureCode,
        orderSummary?.totalPrice?.currencyCode,
        0
      )
    }

    const value =
      order?.items?.reduce<number>(
        (count, item) => count + (item?.price?.netTotalPrice || 0),
        0
      ) || 0
    const currency = order?.items?.[0].price

    return getFormattedPriceString(
      cultureCode,
      getCurrencyCodeFromPriceInformation(currency),
      value
    )
  }, [order])
  const orderPriceIncludingVat = order?.vat?.isIncludedInPrice

  return (
    <>
      <dt>
        <Text sx={{ fontWeight: 'bold' }}>
          {i18next.t('partSalesLabels.totalPrice')}
        </Text>

        <Text
          variant="smallText"
          sx={{
            color: 'gray.2',
          }}
        >
          &nbsp;(
          {i18next.t(
            `shoppingLabels.${
              orderPriceIncludingVat
                ? 'inclusiveVatAbbreviation'
                : 'excludingVatAbbreviation'
            }`
          )}
          )
        </Text>
      </dt>

      <dd>
        {order?.status === QUOTE_OPEN ? (
          <Text data-t-id="shopping-basket-total-price" variant="heading3">
            {i18next.t('shoppingLabels.onRequest')}
          </Text>
        ) : (
          <Price
            data-t-id="shopping-basket-total-price"
            price={formattedTotalOrderValue}
            sx={{
              color: 'text',
              fontSize: 2,
              lineHeight: 'heading3',
            }}
          />
        )}
      </dd>
    </>
  )
}

export default OrderStickySummaryTotalPrice
