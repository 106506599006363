import i18next from 'i18next'
import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import { StockInformation } from '../../../hooks/services/graphql/usePartSalesInformationService'
import { ImageProps } from '../../atoms/Image/Image'
import PartStockInformationList, {
  StockInformationVariant,
} from '../../organisms/PartStockInformationList/PartStockInformationList'
import PartStockInformationListAsync from '../../organisms/PartStockInformationList/PartStockInformationListAsync'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { TRP_THEME } from '../../../constants/themeConstants'

interface PartLineStockInformationProps extends ImageProps {
  partNumber?: string
  displayPartNumber?: string
  stockInformation?: StockInformation
  variant?: StockInformationVariant
  componentOrigin: string
  categoryId?: string
  subcategoryId?: string
  isDealer?: boolean
}

const PartLineStockInformation: FC<PartLineStockInformationProps> = ({
  partNumber,
  displayPartNumber,
  stockInformation,
  variant = 'minimal',
  componentOrigin,
  sx,
  isDealer = false,
  ...rest
}) => {
  const {
    site: { name },
  } = useSitecoreContext()
  if (stockInformation || partNumber) {
    return (
      <Flex sx={{ width: '100%', justifyContent: 'space-between', ...sx }}>
        {/* hide tekst in table */}
        {!(componentOrigin === 'search result table') && name !== TRP_THEME && (
          <Text
            as="span"
            variant="bodySmall"
            sx={{
              display: ['flex', 'flex', 'none'],
              justifyContent: 'space-between',
              mr: 2,
              lineHeight: 'heading3',
              color: 'gray.3',
            }}
          >
            {i18next.t('stockLabels.availability')}
          </Text>
        )}

        <div>
          {stockInformation ? (
            <PartStockInformationList
              partNumber={partNumber}
              displayPartNumber={displayPartNumber}
              variant={variant}
              stockInformation={stockInformation}
              componentOrigin={componentOrigin}
              {...rest}
            />
          ) : (
            <PartStockInformationListAsync
              isDealer={isDealer}
              partNumber={partNumber}
              displayPartNumber={displayPartNumber}
              variant={variant}
              componentOrigin={componentOrigin}
              {...rest}
            />
          )}
        </div>
      </Flex>
    )
  }
  return null
}

export default PartLineStockInformation
