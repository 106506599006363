import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { ICONS } from '../../../../constants/iconConstants'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { TextField } from '../../../../types/layoutService'
import HotjarSuppress from '../../../atoms/HotjarSuppress/HotjarSuppress'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'

interface StoreSwitcherMenuAnchorProps {
  label?: string
  title: TextField
  hasMenu: boolean
  open: boolean
}

const StoreSwitcherMenuAnchor: FC<StoreSwitcherMenuAnchorProps> = ({
  title,
  label,
  hasMenu,
  open,
}) => {
  const { storeSwitcher } = useTheme()

  return (
    <Flex
      sx={{
        flex: [1, 1, 'initial'],
        px: 3,
        alignItems: 'center',
        color: 'textLight',
        ...(hasMenu
          ? {
              ':hover, :active, :focus': {
                color: 'gray.1',
              },
            }
          : {}),
      }}
    >
      <HotjarSuppress>
        <Text
          as="span"
          sx={{
            flex: [1, 1, 'initial'],
            color: 'inherit',
            lineHeight: 'buttonText',
            fontSize: 1,
            py: 2,
            px: 2,
            position: 'relative',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            '::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              display: ['block', 'block', 'none'],
              height: '100%',
              width: '40px',
              background: `linear-gradient(270deg, ${storeSwitcher.background} 0%, transparent 100%)`,
            },
          }}
        >
          {label || getTextFieldValue(title)}
        </Text>
      </HotjarSuppress>

      {hasMenu && (
        <Box
          sx={{
            transform: `rotate(${open ? 180 : 0}deg)`,
          }}
        >
          <IconWrapper icon={ICONS.ARROW_DOWN} size={2} />
        </Box>
      )}
    </Flex>
  )
}

export default StoreSwitcherMenuAnchor
