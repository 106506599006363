export const FORM_VALIDATION_RULE_MAPPINGS: Record<string, string> = {
  MaxLength: 'maxLength',
  MinLength: 'minLength',
  MinMaxLength: 'between',
  Required: 'required',
  InvalidEmailAddress: 'email',
  UnsupportedCountry: 'unsupportedCountry',
  RequestAlreadyExists: 'requestAlreadyExists',
}

export const FORM_PROPERTY_MAPPINGS: Record<string, string> = {
  dealerCompanyName: 'selectedDealerCompany',
}

export const REQUEST_ACCOUNT_DEALER_COUNTRIES = [
  { CODE: 'DZ', LANGUAGE_CODE: '', NAME: 'Algeria' },
  { CODE: 'AW', LANGUAGE_CODE: '', NAME: 'Aruba' },
  { CODE: 'AT', LANGUAGE_CODE: 'de-at', NAME: 'Austria' },
  { CODE: 'BE', LANGUAGE_CODE: '', NAME: 'Belgium' },
  { CODE: 'BA', LANGUAGE_CODE: '', NAME: 'Bosnia-Herzegovina' },
  { CODE: 'BW', LANGUAGE_CODE: '', NAME: 'Botswana' },
  { CODE: 'BR', LANGUAGE_CODE: 'pt-br', NAME: 'Brasil' },
  { CODE: 'BG', LANGUAGE_CODE: '', NAME: 'Bulgaria' },
  { CODE: 'CL', LANGUAGE_CODE: '', NAME: 'Chile' },
  { CODE: 'HR', LANGUAGE_CODE: '', NAME: 'Croatia' },
  { CODE: 'CW', LANGUAGE_CODE: '', NAME: 'Curacao' },
  { CODE: 'CY', LANGUAGE_CODE: '', NAME: 'Cyprus' },
  { CODE: 'CZ', LANGUAGE_CODE: 'cs-cz', NAME: 'Czech Republic' },
  { CODE: 'DK', LANGUAGE_CODE: 'da', NAME: 'Denmark' },
  { CODE: 'EG', LANGUAGE_CODE: '', NAME: 'Egypt' },
  { CODE: 'EE', LANGUAGE_CODE: '', NAME: 'Estonia' },
  { CODE: 'FI', LANGUAGE_CODE: '', NAME: 'Finland' },
  { CODE: 'FR', LANGUAGE_CODE: 'fr-fr', NAME: 'France' },
  { CODE: 'GF', LANGUAGE_CODE: '', NAME: 'French Guiana' },
  { CODE: 'GE', LANGUAGE_CODE: '', NAME: 'Georgia' },
  { CODE: 'DE', LANGUAGE_CODE: 'de-de', NAME: 'Germany' },
  { CODE: 'GH', LANGUAGE_CODE: '', NAME: 'Ghana' },
  { CODE: 'GB', LANGUAGE_CODE: 'en-gb', NAME: 'Great Britain' },
  { CODE: 'GR', LANGUAGE_CODE: '', NAME: 'Greece' },
  { CODE: 'GP', LANGUAGE_CODE: '', NAME: 'Guadeloupe' },
  { CODE: 'HK', LANGUAGE_CODE: '', NAME: 'Hong Kong' },
  { CODE: 'HU', LANGUAGE_CODE: 'hu-hu', NAME: 'Hungary' },
  { CODE: 'ID', LANGUAGE_CODE: '', NAME: 'Indonesia' },
  { CODE: 'IQ', LANGUAGE_CODE: '', NAME: 'Iraq' },
  { CODE: 'IE', LANGUAGE_CODE: '', NAME: 'Ireland' },
  { CODE: 'IL', LANGUAGE_CODE: '', NAME: 'Israel' },
  { CODE: 'IT', LANGUAGE_CODE: 'it-it', NAME: 'Italy' },
  { CODE: 'CI', LANGUAGE_CODE: '', NAME: 'Ivory Coast' },
  { CODE: 'JO', LANGUAGE_CODE: '', NAME: 'Jordan' },
  { CODE: 'KZ', LANGUAGE_CODE: '', NAME: 'Kazakhstan' },
  { CODE: 'KW', LANGUAGE_CODE: '', NAME: 'Kuwait' },
  { CODE: 'LV', LANGUAGE_CODE: '', NAME: 'Latvia' },
  { CODE: 'LB', LANGUAGE_CODE: '', NAME: 'Lebanon' },
  { CODE: 'LY', LANGUAGE_CODE: '', NAME: 'Libya' },
  { CODE: 'LT', LANGUAGE_CODE: '', NAME: 'Lithuania' },
  { CODE: 'LU', LANGUAGE_CODE: '', NAME: 'Luxembourg' },
  { CODE: 'MK', LANGUAGE_CODE: '', NAME: 'Macedonia' },
  { CODE: 'MT', LANGUAGE_CODE: '', NAME: 'Malta' },
  { CODE: 'MQ', LANGUAGE_CODE: '', NAME: 'Martinique' },
  { CODE: 'MD', LANGUAGE_CODE: '', NAME: 'Moldavia' },
  { CODE: 'MA', LANGUAGE_CODE: '', NAME: 'Morocco' },
  { CODE: 'NL', LANGUAGE_CODE: 'nl-nl', NAME: 'Netherlands' },
  { CODE: 'AN', LANGUAGE_CODE: '', NAME: 'Netherlands Antilles' },
  { CODE: 'NC', LANGUAGE_CODE: '', NAME: 'New Caledonia' },
  { CODE: 'NZ', LANGUAGE_CODE: '', NAME: 'New Zealand' },
  { CODE: 'NO', LANGUAGE_CODE: '', NAME: 'Norway' },
  { CODE: 'PH', LANGUAGE_CODE: '', NAME: 'Philippines' },
  { CODE: 'PL', LANGUAGE_CODE: 'pl-pl', NAME: 'Poland' },
  { CODE: 'PT', LANGUAGE_CODE: '', NAME: 'Portugal' },
  { CODE: 'QA', LANGUAGE_CODE: '', NAME: 'Qatar' },
  { CODE: 'RE', LANGUAGE_CODE: '', NAME: 'Réunion' },
  { CODE: 'RO', LANGUAGE_CODE: '', NAME: 'Romania' },
  { CODE: 'SA', LANGUAGE_CODE: '', NAME: 'Saudi Arabia' },
  { CODE: 'RS', LANGUAGE_CODE: '', NAME: 'Serbia' },
  { CODE: 'SG', LANGUAGE_CODE: '', NAME: 'Singapore' },
  { CODE: 'SK', LANGUAGE_CODE: 'sk-sk', NAME: 'Slovakia' },
  { CODE: 'SI', LANGUAGE_CODE: '', NAME: 'Slovenia' },
  { CODE: 'ZA', LANGUAGE_CODE: '', NAME: 'South Africa' },
  { CODE: 'ES', LANGUAGE_CODE: 'es-es', NAME: 'Spain' },
  { CODE: 'SE', LANGUAGE_CODE: '', NAME: 'Sweden' },
  { CODE: 'CH', LANGUAGE_CODE: '', NAME: 'Switzerland' },
  { CODE: 'TW', LANGUAGE_CODE: '', NAME: 'Taiwan' },
  { CODE: 'TN', LANGUAGE_CODE: '', NAME: 'Tunisia' },
  { CODE: 'TR', LANGUAGE_CODE: 'tr-tr', NAME: 'Turkey' },
  { CODE: 'UA', LANGUAGE_CODE: '', NAME: 'Ukraine' },
  { CODE: 'AE', LANGUAGE_CODE: '', NAME: 'United Arab Emirates' },
  { CODE: 'ZW', LANGUAGE_CODE: '', NAME: 'Zimbabwe' },
]
