import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CULTURE_CODE,
} from '../../../constants/userConstants'

export interface FlatSuggestionText {
  query: string
  suggestionText: string
}

export interface SuggestionPerCatalogCategory {
  categoryId: string
  parentCategoryId: string
  partCount?: string
  query: string
  suggestionText: string
}
export interface CollectixSuggestion {
  query: string
  suggestionText: string
}

export interface SuggestionPerSac {
  partCount: string
  query: string
  sacCode: string
  suggestionText: string
}

export interface AutoSuggestionsData {
  flatSuggestionTexts?: FlatSuggestionText[]
  suggestionsPerCatalogCategory?: SuggestionPerCatalogCategory[]
  collectixSuggestions?: CollectixSuggestion[]
  suggestionsPerSac?: SuggestionPerSac[]
}

const executeAutoSuggestions = (
  client: AxiosInstance,
  query: string,
  cancelToken: CancelToken,
  cultureCode?: string,
  countryCode?: string,
  user?: UserProps
): Promise<AxiosResponse<AutoSuggestionsData>> =>
  client.get('/AutoSuggestions/', {
    params: {
      query,
      cultureCode: cultureCode ?? DEFAULT_CULTURE_CODE,
      countryCode: countryCode ?? DEFAULT_COUNTRY_CODE,
    },
    ...createRequestConfig(user),
    cancelToken,
  })

export default executeAutoSuggestions
