import { Company, UserProfile, UserProps } from '../types/userProps'

export const getBearerToken = (user?: UserProps): string | undefined =>
  user?.accessToken ? `Bearer ${user.accessToken}` : undefined

export const loggedIn = (user?: UserProps): boolean => !!user?.accessToken

export const hasRole = (user?: UserProps, role?: string): boolean =>
  user && role ? user.roles.includes(role) : false

export const userCultureCode = (userProfile?: UserProfile): string =>
  userProfile ? userProfile.cultureCode : 'en-gb'

export const orderUserCompaniesByPriority = (companies: Company[] | undefined) =>
  (companies &&
    companies.length &&
    companies
      .reduce<Company[]>(
        (a, b) => [
          ...a,
          {
            ...b,
            suppliers: b?.suppliers?.sort((c, d) =>
              c?.priority && d?.priority ? c.priority - d.priority : 0
            ),
          },
        ],
        []
      )
      .sort((a, b) => (a?.priority && b?.priority ? a.priority - b.priority : 0))) ||
  []

export const getUserFullName = (userProfile?: UserProfile) =>
  [userProfile?.firstName, userProfile?.lastName]
    .filter((e) => e) // remove item if empty
    .join(' ')
