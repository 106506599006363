import React, { FC } from 'react'
import { LinkField } from '../../../../../types/layoutService'
import CardGrid from '../../../../molecules/Card/CardGrid'
import GridCardContainer from '../../../../molecules/GridCardContainer/GridCardContainer'
import DealerPartCard from '../../../../molecules/Card/DealerPartCard/DealerPartCard'
import { adaptDealerPartFullFilledToPartCardProps } from '../../../../molecules/Card/PartCard/partCardAdapters'
import { DealerPartFulfilled } from '../../../../../hooks/services/rest/ecommerce/useDealerPartSearchService'
import { measureDealerPartClick } from '../../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'

interface DealerPartSearchGridViewProps {
  dealerParts?: DealerPartFulfilled[]
  dealerPartPage: LinkField
}

const DealerPartSearchGridView: FC<DealerPartSearchGridViewProps> = ({
  dealerParts,
  dealerPartPage,
}) => {
  if (!dealerParts?.length) return null

  return (
    <CardGrid>
      {dealerParts.map((dealerPart, i) => (
        <GridCardContainer key={i.toString()}>
          <DealerPartCard
            partPage={dealerPartPage}
            onClick={() =>
              measureDealerPartClick(
                dealerPart?.searchDealerPart.partNumber,
                dealerPart?.dealerPart?.partName,
                dealerPart?.dealerPart?.brandName,
                i,
                DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_GRID
              )
            }
            {...adaptDealerPartFullFilledToPartCardProps(dealerPart)}
          />
        </GridCardContainer>
      ))}
    </CardGrid>
  )
}

export default DealerPartSearchGridView
