import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import dayjs from 'dayjs'
import { COLORS } from '../../../constants/themeConstants'
import { validateString } from '../../../helpers/stringHelper'
import Image from '../Image/Image'
import { ValidateCompanyVehicleResult } from '../../../services/rest/ecommerce/validateCompanyVehicle'
import ClickableWrapper from '../ClickableWrapper/ClickableWrapper'
import IconWrapper from '../IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'

interface VehicleInformationTileProps {
  vehicle: ValidateCompanyVehicleResult
  onRemove?: (vin: string) => void
}

const VehicleInformationTile = ({
  vehicle,
  onRemove,
}: VehicleInformationTileProps) => (
  <Flex
    key={vehicle?.vin}
    sx={{
      position: 'relative',
      gap: 2,
      padding: 3,
      flexDirection: 'row',
      borderRadius: 3,
      border: '1px solid',
      borderColor: COLORS.MEDIUM_GRAY,
    }}
  >
    <Image
      src={vehicle.urlVehicleModelYearSeries}
      sx={{ maxWidth: '64px', maxHeight: '64px' }}
    />
    <Box
      sx={{
        flexGrow: 1,
        display: 'grid',
        gap: '4px 8px',
        gridTemplateColumns: 'auto 1fr',
        '> dd': { marginInlineStart: 0 },
        '> dl': { marginBlock: 0 },
      }}
    >
      <dl>
        <Text variant="smallText" color={COLORS.DARK}>
          Type:
        </Text>
      </dl>
      <dd>
        <Text variant="smallText" color={COLORS.GRAY}>
          {validateString(vehicle?.vehicleType)}
        </Text>
      </dd>
      <dl>
        <Text variant="smallText" color={COLORS.DARK}>
          VIN:
        </Text>
      </dl>
      <dd>
        <Text variant="smallText" color={COLORS.GRAY}>
          {validateString(vehicle?.vin)}
        </Text>
      </dd>
      {vehicle?.make === 'DAF' && (
        <>
          <dl>
            <Text variant="smallText" color={COLORS.DARK}>
              1st reg:
            </Text>
          </dl>
          <dd>
            <Text variant="smallText" color={COLORS.GRAY}>
              {dayjs(vehicle?.firstRegistrationDate).isValid()
                ? dayjs(vehicle?.firstRegistrationDate).format('DD-MM-YYYY')
                : '–'}
            </Text>
          </dd>
        </>
      )}
      {vehicle?.make !== 'DAF' && (
        <>
          <dl>
            <Text variant="smallText" color={COLORS.DARK}>
              Make:
            </Text>
          </dl>
          <dd>
            <Text variant="smallText" color={COLORS.GRAY}>
              {validateString(vehicle?.make)}
            </Text>
          </dd>
        </>
      )}
    </Box>
    {onRemove && (
      <Box sx={{ position: 'absolute', right: 2, top: 2 }}>
        <ClickableWrapper
          onClick={() => {
            if (vehicle && onRemove) {
              onRemove(vehicle.vin)
            }
          }}
        >
          <IconWrapper icon={ICONS.TRASH} color={COLORS.DARK} size={4} />
        </ClickableWrapper>
      </Box>
    )}
  </Flex>
)

export default VehicleInformationTile
