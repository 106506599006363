import React, { FC, useContext } from 'react'
import { Flex } from 'theme-ui'
import { loggedIn } from '../../../../helpers/userHelper'
import { UserContext } from '../../../../providers/UserProvider'
import { MatchMetaDataProps } from '../../../../types/dafResponseProps'
import PartPromotionsAsync from '../../PartPromotions/PartPromotionsAsync'
import PartAddToBasket from '../../AddToBasket/PartAddToBasket'
import PartPriceAsync from '../../PartPrice/PartPriceAsync'

export interface PartCardSalesInformationProps {
  partDescription?: string
  partEnglishDescription?: string
  partNumber: string
  displayPartNumber?: string
  matchMetaData?: MatchMetaDataProps
  brandImageUrl?: string
  brandName?: string
  categoryId?: string
  subcategoryId?: string
  packageUnitQuantity?: number
  dataLayerComponentOrigin?: string
  imageUrl?: string
  onClick?: () => void
}

const PartCardSalesInformation: FC<PartCardSalesInformationProps> = ({
  partNumber,
  partEnglishDescription,
  brandName,
  categoryId,
  subcategoryId,
  packageUnitQuantity,
  dataLayerComponentOrigin = '',
}) => {
  const { user } = useContext(UserContext)

  if (!loggedIn(user)) return null

  return (
    <>
      <Flex sx={{ flexBasis: '100%', marginTop: 3, alignItems: 'center' }}>
        <PartPriceAsync
          partNumber={partNumber}
          variant="small"
          sx={{
            mr: 5,
          }}
        />

        <PartAddToBasket
          variant="small"
          partNumber={partNumber}
          partEnglishDescription={partEnglishDescription}
          dataLayerComponentOrigin={dataLayerComponentOrigin}
          stepSize={packageUnitQuantity}
          initialQuantity={packageUnitQuantity}
          brandName={brandName}
          categoryId={categoryId}
          subcategoryId={subcategoryId}
        />
      </Flex>

      <PartPromotionsAsync
        partNumber={partNumber}
        sx={{ flexBasis: '100%', marginTop: 3 }}
      />
    </>
  )
}

export default PartCardSalesInformation
