import { AxiosResponse } from 'axios'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'

export interface OrderConfirmProps {
  orderNumber?: string
}

export const orderConfirm: RequestFunction<
  OrderConfirmProps,
  CommerceResponse<CommerceOrder>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrder>>> =>
  client.put(`/order-confirm/${props.orderNumber || ''}`, null, {
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
      }
    ),
    cancelToken: config.cancelToken,
  })
