import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react'
import { Box, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import SearchBarInput from '../../molecules/SearchBar/SearchBarInput'
import { URL_VEHICLE_SEARCH_QUERY_PARAM } from '../../../constants/urlConstants'
import SearchBarForm from '../../molecules/SearchBar/SearchBarForm'
import { ICONS } from '../../../constants/iconConstants'
import { resolveLocalizedUrl } from '../../../helpers/linkFieldHelper'
import { COLORS } from '../../../constants/themeConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { navigateClient } from '../../../helpers/dom'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Button from '../../atoms/Button/Button'
import withData from '../../../enhancers/withData'
import useDafVehicleReferenceService from '../../../hooks/services/rest/ecommerce/useDafVehicleReferenceService'
import { VehicleReference } from '../../../services/rest/ecommerce/dafVehicleReferences'

export interface VehicleSearchForHeroBannerDatasource {
  vehicleSearchPage: LinkField
  vehicleSearchLabel: TextField
  vehicleSearchPlaceholder: TextField
  vehicleSearchSubmitButtonLabelShort: TextField
  vehicleSearchSubmitButtonLabelLong: TextField
}

const getReferenceOptionLabel = (reference: VehicleReference) =>
  Object.values(reference)
    .map((value) => value)
    .filter((value) => value)
    .join(' | ')

const VehicleSearchForHeroBanner: FC<
  Datasource<VehicleSearchForHeroBannerDatasource>
> = ({ datasource }) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const [query, setQuery] = useState('')
  const trimmedSearchString = useMemo(() => query.trim(), [query])
  const [isFetching, dafVehicleReferences] = useDafVehicleReferenceService()

  const submitSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_FUNCTION,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.VEHICLE_SEARCH,
        search_query: trimmedSearchString,
        page_type: '',
      })

      navigateClient(
        `${resolveLocalizedUrl(
          datasource?.vehicleSearchPage,
          cultureCode
        )}?${URL_VEHICLE_SEARCH_QUERY_PARAM}=${trimmedSearchString}`
      )
    },
    [cultureCode, trimmedSearchString, datasource]
  )

  return (
    <>
      <label htmlFor="banner-call-to-action-search-input">
        <Text
          as="label"
          variant="heading5"
          sx={{
            display: 'block',
            marginBlockEnd: 2,
            color: 'textLight',
          }}
        >
          {datasource?.vehicleSearchLabel?.value}
        </Text>
      </label>

      <SearchBarForm
        onSubmit={submitSearch}
        sx={{
          width: ['100%', '100%', '100%', '50%'],
          display: 'flex',
          alignItems: 'center',
          paddingBlock: 1,
          paddingInline: 1,
          outline: `3px solid color-mix(in srgb, ${COLORS.BLACK} 24%, transparent)`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            insetInlineStart: 2,
            pointerEvents: 'none',
          }}
        >
          <IconWrapper icon={ICONS.SEARCH} />
        </Box>
        <SearchBarInput
          id="banner-call-to-action-search-input"
          list="chassisNumbers"
          name={URL_VEHICLE_SEARCH_QUERY_PARAM}
          placeholder={datasource?.vehicleSearchPlaceholder?.value}
          onChange={(ev) => setQuery(ev.currentTarget.value)}
        />

        <datalist id="chassisNumbers">
          {!isFetching &&
            dafVehicleReferences?.length &&
            dafVehicleReferences.map((reference) => (
              <option value={reference.chassisNumber} key={reference.chassisNumber}>
                {getReferenceOptionLabel(reference)}
              </option>
            ))}
        </datalist>

        <Box
          sx={{
            flexShrink: 0,
          }}
        >
          <Button type="submit">
            <Box
              sx={{
                display: ['block', 'none'],
              }}
            >
              {datasource?.vehicleSearchSubmitButtonLabelShort?.value}
            </Box>
            <Box
              sx={{
                display: ['none', 'block'],
              }}
            >
              {datasource?.vehicleSearchSubmitButtonLabelLong?.value}
            </Box>
          </Button>
        </Box>
      </SearchBarForm>
    </>
  )
}

export default withData(VehicleSearchForHeroBanner)
