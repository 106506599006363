import React, { FC, Fragment, useMemo } from 'react'
import { Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { CommerceAttributesOfAPart } from '../../../types/commerceApi'
import { displayValueOrConvertBooleanToHumanReadableString } from '../../../constants/partAttributeHelper'

interface PartLinePartAttributesProps {
  firstColumnWidth?: number
  attributes?: CommerceAttributesOfAPart[]
}

const PartLinePartAttributes: FC<PartLinePartAttributesProps> = ({
  firstColumnWidth = 200,
  attributes,
}) => {
  const { space } = useTheme()
  const attributesToDisplay = useMemo(() => attributes?.slice(0, 6), [attributes])

  if (!attributesToDisplay || !attributesToDisplay.length) return <div />

  return (
    <Text
      variant="bodySmall"
      sx={{
        display: 'grid',
        width: '100%',
        gap: `0 ${space[2]}px`,
        gridTemplateColumns: `${firstColumnWidth}px 1fr`,
        lineHeight: 'heading3',
        color: 'gray.3',
        'span:nth-of-type(even)': {
          display: 'flex',
          justifyContent: ['flex-end', 'flex-end', 'flex-start'],
          color: 'gray.2',
        },
      }}
    >
      {attributesToDisplay.map(({ description, value, id }) => (
        <Fragment key={`${description}-${value}`}>
          <span data-t-id={`attribute-${id}`}>{description}</span>

          <span data-t-id={`attribute-${id}-value`}>
            {displayValueOrConvertBooleanToHumanReadableString(value)}
          </span>
        </Fragment>
      ))}
    </Text>
  )
}

export default PartLinePartAttributes
