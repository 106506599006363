import React, { FC } from 'react'
import { Box } from 'theme-ui'
import usePartBillOfMaterialService from '../../../hooks/services/graphql/usePartBillOfMaterialService'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import { LinkField, TextField } from '../../../types/layoutService'
import { PartContext } from '../../../types/sitecoreContextProps'
import Spinner from '../../atoms/Spinner/Spinner'
import PartBillOfMaterialMasters from './PartBillOfMaterialMasters'
import PartBillOfMaterialParts from './PartBillOfMaterialParts'

interface PartBillOfMaterialMastersAndPartsProps {
  includedInTitle: TextField
  consistsOfTitle: TextField
  partsPageUri: LinkField
  id: string
}

const PartBillOfMaterialMastersAndParts: FC<
  PartBillOfMaterialMastersAndPartsProps
> = ({ consistsOfTitle, includedInTitle, id, partsPageUri }) => {
  const { part } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  const [fetching, billOfMaterial] = usePartBillOfMaterialService(part?.partNumber)

  if (fetching) {
    return (
      <Box mb={4}>
        <Spinner size={3} />
      </Box>
    )
  }

  if (!billOfMaterial) {
    return null
  }

  return (
    <>
      <PartBillOfMaterialMasters
        bomMasters={billOfMaterial?.bomMasters}
        partsPageUri={partsPageUri}
        includedInTitle={includedInTitle}
        id={`${id}-m`}
      />

      <PartBillOfMaterialParts
        bomParts={billOfMaterial?.bomParts}
        partsPageUri={partsPageUri}
        consistsOfTitle={consistsOfTitle}
        id={`${id}-p`}
      />
    </>
  )
}

export default PartBillOfMaterialMastersAndParts
