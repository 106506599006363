import React, { FC } from 'react'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import CallToActionContent from '../../molecules/CallToActionContent/CallToActionContent'
import {
  Datasource,
  DateField,
  LinkField,
  Params,
  TextField,
} from '../../../types/layoutService'

interface CallToActionDatasource {
  title: TextField
  text: TextField
  date: DateField
  button?: LinkField
}

const CallToAction: FC<Datasource<CallToActionDatasource> & Params> = ({
  datasourceId,
  datasource,
  params,
}) => (
  <Box mb={[4, 4, 7]} data-t-id={`call-to-action-${datasourceId}`}>
    <CallToActionContent {...datasource} {...params} />
  </Box>
)

export default withData(CallToAction)
