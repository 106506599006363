import { Action } from 'redux'
import { SHOPPING_BASKET_WIZARD_OVERWRITE_ORDER_RECIPIENTS } from '../../../constants/shoppingBasketConstants'
import { CommerceRecipient } from '../../../types/commerceApi'

export interface OverwriteOrderRecipients
  extends Action<typeof SHOPPING_BASKET_WIZARD_OVERWRITE_ORDER_RECIPIENTS> {
  payload: CommerceRecipient[]
}

export const overwriteOrderRecipients = (payload: CommerceRecipient[]) => ({
  type: SHOPPING_BASKET_WIZARD_OVERWRITE_ORDER_RECIPIENTS,
  payload,
})
