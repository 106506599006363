import TableHead from '../../molecules/Table/TableHead'
import TableColumnTitle from '../../molecules/Table/TableColumnTitle'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import TableBody from '../../molecules/Table/TableBody'
import TableRow from '../../molecules/Table/TableRow'
import TableCell from '../../molecules/Table/TableCell'
import { Text, Box } from 'theme-ui'
import i18next from 'i18next'
import dayjs from 'dayjs'
import Button from '../../atoms/Button/Button'
import { ICONS } from '../../../constants/iconConstants'
import Table from '../../molecules/Table/Table'
import React from 'react'
import { VoucherCodesOverviewDatasource } from './VoucherCodesOverview'
import { addToast } from '../../../actions/toastActions'
import { useDispatch } from 'react-redux'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Voucher } from '../../../services/rest/ecommerce/loyalty/vouchers'
import { formatNumber } from '../../../helpers/numberFormatHelper'
import VoucherCodeProductName from '../../molecules/VoucherCode/VoucherCodeProductName'
import VoucherCodeStatus from '../../molecules/VoucherCode/VoucherCodeStatus'
import NoResults from '../../molecules/NoResults/NoResults'

interface VoucherCodesOverviewTableProps {
  vouchers: Voucher[]
  datasource: VoucherCodesOverviewDatasource
}

const VoucherCodesOverviewTable = ({
  vouchers,
  datasource,
}: VoucherCodesOverviewTableProps) => {
  const dispatch = useDispatch()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const writeClipboardText = async (text: string) => {
    await navigator.clipboard.writeText(text)

    dispatch(
      addToast({
        id: text,
        message: i18next.t('commonLabels.copiedToClipboard'),
      })
    )
  }

  return (
    <>
      <Table columnSizes={[1.8, 0.7, 1, 1, 1, 0, 0]}>
        <TableHead sx={{ '.table-row': { borderTopWidth: '0' } }}>
          <TableColumnTitle
            sx={{
              display: 'table-cell',
            }}
          >
            {getTextFieldValue(datasource?.voucher)}
          </TableColumnTitle>
          <TableColumnTitle
            sx={{
              display: 'table-cell',
            }}
          >
            {getTextFieldValue(datasource?.points)}
          </TableColumnTitle>
          <TableColumnTitle
            sx={{
              display: 'table-cell',
            }}
          >
            {getTextFieldValue(datasource?.issued)}
          </TableColumnTitle>
          <TableColumnTitle
            sx={{
              display: 'table-cell',
            }}
          >
            {getTextFieldValue(datasource?.expires)}
          </TableColumnTitle>
          <TableColumnTitle
            sx={{
              display: 'table-cell',
            }}
          >
            {getTextFieldValue(datasource?.code)}
          </TableColumnTitle>
          <TableColumnTitle
            sx={{
              display: 'table-cell',
            }}
          >
            {getTextFieldValue(datasource?.status)}
          </TableColumnTitle>
          <TableColumnTitle
            sx={{
              display: 'table-cell',
            }}
          ></TableColumnTitle>
        </TableHead>
        {vouchers?.length > 0 && (
          <TableBody>
            {vouchers?.map((voucher) => (
              <TableRow
                key={voucher.uniqueCode}
                sx={{
                  '&:last-of-type': {
                    borderColor: 'gray.1',
                    borderStyle: 'solid',
                    borderBottomWidth: '1px',
                  },
                }}
              >
                <TableCell>
                  <VoucherCodeProductName voucher={voucher} />
                </TableCell>

                <TableCell sx={{ verticalAlign: 'middle' }}>
                  <Text variant="bodySmall" color="gray.3">
                    {formatNumber(voucher.costPriceInPoints, cultureCode)}
                  </Text>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'middle' }}>
                  <Text variant="bodySmall" color="gray.3">
                    {dayjs(voucher.issuedDate).format('DD-MM-YYYY')}
                  </Text>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'middle' }}>
                  <Text variant="bodySmall" color="gray.3">
                    {dayjs(voucher.expiresDate).format('DD-MM-YYYY')}
                  </Text>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'middle' }}>
                  <Text variant="bodySmall" color="gray.3">
                    <Button
                      onClick={() => writeClipboardText(voucher.uniqueCode)}
                      color="primary"
                      variant="infinite"
                      iconPosition="end"
                      icon={ICONS.COPY}
                      disabled={voucher.status !== 'Active'}
                    >
                      {voucher.uniqueCode}
                    </Button>
                  </Text>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'middle' }}>
                  <VoucherCodeStatus voucher={voucher} />
                </TableCell>
                <TableCell sx={{ verticalAlign: 'middle' }}>
                  {voucher.productDetailUrl && (
                    <Button
                      as="a"
                      href={voucher.productDetailUrl}
                      target="_blank"
                      icon={ICONS.EXIT}
                      iconSize={4}
                      iconPosition="end"
                      variant="infinite"
                      color="primary"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {vouchers?.length === 0 && (
        <Box sx={{ width: '100%', paddingBlock: 6 }}>
          <NoResults
            title={getTextFieldValue(datasource?.noResultsTitle)}
            description={getTextFieldValue(datasource?.noResultsDescription)}
          />
        </Box>
      )}
    </>
  )
}

export default VoucherCodesOverviewTable
