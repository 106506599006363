import React, { useContext, useMemo } from 'react'
import { Label } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import Checkbox from '../../../atoms/inputs/Checkbox/Checkbox'

import {
  getFilterStringByActiveSearchResultFilterObject,
  toggleFilter,
} from '../../../../helpers/filterHelper'
import { Option } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { MyFleetListContext } from '../../../../providers/MyFleetListContextProvider'

export interface MyFleetListFilterOptionProps {
  filterOption: Option
  contract: string
  onChange?: () => void
}

const MyFleetListFilterOption = ({
  filterOption,
  contract,
}: MyFleetListFilterOptionProps) => {
  const history = useHistory()
  const { activeFilters } = useContext(MyFleetListContext)

  const filterOptionValueToString = filterOption.value.toString()

  const checked = useMemo(
    () => activeFilters[0]?.[contract]?.includes(filterOptionValueToString) ?? false,
    [activeFilters, contract, filterOptionValueToString]
  )

  const checkboxId = `${contract}-${filterOptionValueToString}`

  const handleCheckClick = () => {
    const newFilterObject = toggleFilter(
      activeFilters[0] || {},
      contract,
      filterOptionValueToString
    )
    const params = new URLSearchParams(history.location.search)

    history.push(
      `?${getFilterStringByActiveSearchResultFilterObject(
        newFilterObject,
        params,
        false,
        false
      )}`
    )
  }

  return (
    <Label
      htmlFor={checkboxId}
      sx={{
        mb: 2,
        display: 'flex',
        cursor: 'pointer',
      }}
    >
      <Text as="span" variant="bodySmall" sx={{ color: 'gray.2', flex: 1, pr: 3 }}>
        {i18next.t(`myFleetFilters.${filterOption.id}`)}
      </Text>

      <Checkbox
        key={checkboxId}
        id={checkboxId}
        value={filterOptionValueToString}
        onChange={handleCheckClick}
        checked={checked}
        disabled={false}
      />
    </Label>
  )
}

export default MyFleetListFilterOption
