import { Action } from 'redux'
import { SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_REFERENCE } from '../../../constants/shoppingBasketConstants'

export interface SetCustomerOrderReference
  extends Action<typeof SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_REFERENCE> {
  payload: string
}

export const setCustomerOrderReference = (payload: string) => ({
  type: SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_REFERENCE,
  payload,
})
