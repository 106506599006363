import React, {
  Children,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import useResizeObserver from '@react-hook/resize-observer'
import { getWidthFractions } from '../../../helpers/gridHelper'
import {
  getBackgroundColor,
  getColumnLayoutDividerSettings,
} from '../../../helpers/layoutHelper'
import { Params } from '../../../types/layoutService'
import Container from '../Container/Container'
import { getDividerSx } from '../Divider/Divider'
import { ColumnLayoutParams } from '../../../types/columnLayoutProps'

const FRACTION_NAMES = ['FirstColumn', 'SecondColumn', 'ThirdColumn', 'FourthColumn']

export const COLUMN_LAYOUT_Y_SPACING = [4, 4, 7]

const getLayoutOptions = (params: ColumnLayoutParams) => ({
  topSpacing: params?.topSpacing !== '1' ? 0 : COLUMN_LAYOUT_Y_SPACING,
  bottomSpacing: params?.bottomSpacing !== '1' ? 0 : COLUMN_LAYOUT_Y_SPACING,
  bottomDivider: params?.bottomDivider === '1',
  topDivider: params?.topDivider === '1',
  removeHorizontalSpacing: params?.removeHorizontalSpacing === '1', // when undefined || '0' apply horizontal spacing
  reverseOnMobile: params?.ReverseOrderOnMobile,
  hasBackground: !params?.BackgroundColor || params?.BackgroundColor !== 'None',
  backgroundColor: getBackgroundColor(params?.BackgroundColor || 'None'),
  dividerSettings: getColumnLayoutDividerSettings(
    params?.BackgroundColor || 'None'
  ) as string[],
})

interface ColumnLayoutProps extends Params<ColumnLayoutParams> {
  children: ReactNode
}

const ColumnLayout = ({ params, children }: ColumnLayoutProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const [dynamicStyles, setDynamicStyles] = useState({})

  const options = useMemo(() => getLayoutOptions(params), [params])
  const columnCount = useMemo(() => Children.count(children) - 1, [children])
  const hasContent = useMemo(() => !!height, [height])
  const spacingType =
    options.hasBackground || options.topDivider || options.bottomDivider
      ? 'padding'
      : 'margin'

  useResizeObserver(ref, (entry) => setHeight(entry.contentRect.height))

  useEffect(() => {
    if (hasContent) {
      const styles = {
        ...(options.bottomDivider
          ? getDividerSx('bottom', ...options.dividerSettings)
          : {}),
        ...(options.topDivider
          ? getDividerSx('top', ...options.dividerSettings)
          : {}),
      }
      setDynamicStyles(styles)
    }
  }, [hasContent, options])

  return (
    <Box
      ref={ref}
      sx={{
        backgroundColor: options.backgroundColor,
        [`${spacingType}Top`]: hasContent ? options.topSpacing : null,
        [`${spacingType}Bottom`]: hasContent ? options.bottomSpacing : null,
        ...dynamicStyles,
      }}
    >
      <Container
        removeHorizontalSpacing={options.removeHorizontalSpacing}
        sx={{
          '> div': {
            display: 'flex',
            flexDirection:
              options.reverseOnMobile === '1'
                ? ['column-reverse', 'column-reverse', 'row']
                : ['column', 'column', 'row'],
            mx: [0, 0, -3],
          },
        }}
      >
        <div>
          {Children.map(children, (child, i) => {
            const widthFractions = getWidthFractions(FRACTION_NAMES[i], params, {
              left: i + 1,
              right: columnCount + 1,
            })

            return (
              <Box
                key={i.toString()}
                sx={{
                  px: [0, 0, 3],
                  flexBasis: [
                    '100%',
                    '100%',
                    `${(widthFractions.left / widthFractions.right) * 100}%`,
                  ],
                  '> *:last-child': {
                    mb: columnCount === i ? 0 : undefined,
                  },
                }}
              >
                {child}
              </Box>
            )
          })}
        </div>
      </Container>
    </Box>
  )
}

export default ColumnLayout
