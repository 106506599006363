import React, { useContext, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { Form } from 'react-final-form'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useTheme } from '@emotion/react'
import { ValidationErrors } from 'final-form'
import SidePanel from '../SidePanel'
import SidePanelFooter from '../SidePanelFooter'
import SidePanelBody from '../SidePanelBody'
import Button from '../../../atoms/Button/Button'
import { COLORS } from '../../../../constants/themeConstants'
import { TextField } from '../../../../types/layoutService'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../../helpers/layoutServiceHelper'
import { TextAreaField } from '../../../atoms/FormFields'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { UserContext } from '../../../../providers/UserProvider'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import companyChangeRequest, {
  CompanyChangeRequestProps,
} from '../../../../services/rest/company/companyChangeRequest'
import Spinner from '../../../atoms/Spinner/Spinner'
import renderHTML from '../../../../helpers/renderHTMLHelper'

interface RequestMyAccountChangesSidepanelProps {
  active: boolean
  onSidepanelClose: () => void
  labels: {
    title: TextField
    footer: TextField
    messageInputLabel: TextField
    messageInputPlaceholder: TextField
    thankYouMessage: TextField
  }
}

interface FormValues {
  message: string
}

const RequestMyAccountChangesSidepanel = ({
  active,
  onSidepanelClose,
  labels,
}: RequestMyAccountChangesSidepanelProps) => {
  const { space } = useTheme()
  const [sidePanelActive, setSidePanelActive] = useState(active)
  const [formSuccessfullySend, setFormSuccessfullySend] = useState(false)
  const { user } = useContext(UserContext)
  const commerceClient = useAxiosClient(AxiosClientType.CommerceApi)
  const { token } = axios.CancelToken.source()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  useEffect(() => {
    setSidePanelActive(active)
    if (active) {
      setFormSuccessfullySend(false)
    }
  }, [active])

  const sendChangeRequest = async (payload: CompanyChangeRequestProps) => {
    const response = await companyChangeRequest(
      payload,
      commerceClient,
      dafVehicleDetailQueryParams,
      token,
      user
    )

    return response.data
  }

  const { isPending: isMutating, mutate } = useMutation({
    mutationFn: (payload: CompanyChangeRequestProps) => sendChangeRequest(payload),

    onSuccess: () => {
      setFormSuccessfullySend(true)
    },
  })

  const onSubmit = (values: FormValues) => {
    mutate({ requestBody: values.message })
  }

  const handleCloseSidePanel = () => {
    setFormSuccessfullySend(false)
    onSidepanelClose()
  }

  return (
    <SidePanel
      position="right"
      active={sidePanelActive}
      onClose={() => {
        handleCloseSidePanel()
      }}
      disableOutsideClick
      title={getTextFieldValue(labels?.title)}
    >
      <SidePanelBody>
        <Flex sx={{ flexDirection: 'column' }}>
          {!formSuccessfullySend && !isMutating && (
            <Form
              onSubmit={onSubmit}
              validate={(values: FormValues) => {
                const errors: ValidationErrors = {}
                if (!values.message) {
                  errors.message = i18next.t('formLabels.required')
                }
                return errors
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="request-changes-form">
                  <Flex sx={{ flexDirection: 'column' }}>
                    <TextAreaField
                      name="message"
                      label={getTextFieldValue(labels?.messageInputLabel)}
                      placeholder={getTextFieldValue(
                        labels?.messageInputPlaceholder
                      )}
                    />
                  </Flex>
                </form>
              )}
            />
          )}
          {isMutating && (
            <Box sx={{ marginTop: 4 }}>
              <Spinner size={4} />
            </Box>
          )}
          {formSuccessfullySend && labels?.thankYouMessage && (
            <Box>
              <Text variant="bodySmall" color={COLORS.DARK}>
                {renderHTML(getEditableTextFieldValue(labels?.thankYouMessage))}
              </Text>
            </Box>
          )}
        </Flex>
      </SidePanelBody>
      <SidePanelFooter sx={{ flexDirection: 'column', marginTop: 'auto' }}>
        {!formSuccessfullySend ? (
          <Box>
            <Button
              sx={{ width: '100%' }}
              data-t-id="request-myaccount-changes-send"
              variant="primary"
              form="request-changes-form"
              type="submit"
            >
              {i18next.t('formLabels.sendRequest')}
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              sx={{ width: '100%' }}
              data-t-id="request-myaccount-changes-close"
              variant="primary"
              onClick={() => {
                handleCloseSidePanel()
              }}
            >
              {i18next.t('commonLabels.close')}
            </Button>
          </Box>
        )}
        {labels?.title && (
          <Text
            variant="smallText"
            sx={{
              marginBlockStart: space[3],
              textAlign: 'center',
              color: COLORS.GRAY,
            }}
          >
            {getTextFieldValue(labels.footer)}
          </Text>
        )}
      </SidePanelFooter>
    </SidePanel>
  )
}

export default RequestMyAccountChangesSidepanel
