import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { getUrlQueryValue } from '../../../helpers/urlHelper'

export interface TabContextProps {
  urlQueryParam?: string
  defaultActiveTab: number
  children: ReactNode
}
export interface TabContextSearchProps {
  urlQueryParam?: string
  activeTabId?: string | number
  setActiveTabId: (id: string) => void
}

const renderChildren = (
  children: ReactNode,
  { urlQueryParam, activeTabId, setActiveTabId }: TabContextSearchProps
): ReactNode =>
  Children.map(children, (child, i) => {
    if (!isValidElement(child)) return child

    if (i === 0) {
      // Tab bar
      return cloneElement(child as ReactElement, {
        urlQueryParam,
        activeTabId,
        setActiveTabId,
      })
    }

    return cloneElement(child as ReactElement, {
      // Tab panels
      active: child.props?.id === activeTabId || i - 1 === activeTabId,
    })
  })

const TabContext = ({
  urlQueryParam,
  defaultActiveTab,
  children,
}: TabContextProps) => {
  const { search } = useLocation()
  const [activeTabId, setActiveTabId] = useState<string | number | undefined>(
    undefined
  )

  useEffect(() => {
    if (!urlQueryParam) setActiveTabId(defaultActiveTab)

    if (urlQueryParam) {
      setActiveTabId(getUrlQueryValue(urlQueryParam, search) || defaultActiveTab)
    }
    // urlQueryParam will not change during lifecycle
  }, [search])

  return (
    <>{renderChildren(children, { urlQueryParam, activeTabId, setActiveTabId })}</>
  )
}

export default TabContext
