import i18next from 'i18next'
import React, { FC, useMemo, useState } from 'react'
import { Box } from 'theme-ui'
import { CommerceOrderItem } from '../../../../types/commerceApi'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import { getPartSuggestionHighlighColor } from '../../../../helpers/partSuggestionHelper'

interface SuggestionInformationSidePanelProps {
  part: CommerceOrderItem
}

const SuggestionInformationSidePanel: FC<SuggestionInformationSidePanelProps> = ({
  part,
}) => {
  const [open, setOpen] = useState(false)

  const color = useMemo(() => getPartSuggestionHighlighColor(part), [part])

  if (!part.suggestionInformation) return null

  return (
    <>
      <ClickableWrapper
        data-t-id="part-suggestion-information-button"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          setOpen(true)
        }}
      >
        <Box
          sx={{
            color: color || 'primary',
            padding: '4px 6px',
            borderRadius: '10px',
            border: '1px solid',
            borderColor: 'currentColor',
            fontSize: '8px',
            fontWeight: 'bold',
            lineHeight: '10px',
            backgroundColor: 'background',
          }}
        >
          {i18next.t('shoppingLabels.mdi')}
        </Box>
      </ClickableWrapper>

      <SidePanel active={open} onClose={() => setOpen(false)} title="MDI">
        <SidePanelBody
          sx={{
            h4: {
              fontWeight: 'bold',
              fontSize: 1,
              lineHeight: 'heading3',
              margin: 0,
            },
            p: {
              color: 'gray.2',
              marginTop: 0,
              marginBottom: 4,
              lineHeight: 'body',
              fontSize: 1,
            },
          }}
        >
          <h4>{i18next.t('partLabels.partNumber')}</h4>

          <p>{part.partNumber}</p>

          <h4>{i18next.t('specialPriceLabels.description')}</h4>

          <p>{part.description}</p>

          {part.suggestionInformation.sofMessage && (
            <>
              <h4>{i18next.t('shoppingLabels.mdiMessage')}</h4>

              <p>{part.suggestionInformation.sofMessage}</p>
            </>
          )}
        </SidePanelBody>
      </SidePanel>
    </>
  )
}

export default SuggestionInformationSidePanel
