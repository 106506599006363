import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Form } from 'react-final-form'
import { FormApi, ValidationErrors } from 'final-form'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import SidePanel from '../SidePanel'
import SidePanelFooter from '../SidePanelFooter'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import Spinner from '../../../atoms/Spinner/Spinner'
import EditServicePlanFormSubmitButton from '../../../molecules/Forms/EditServicePlanForm/EditServicePlanFormSubmitButton'
import { toggleMileageSettlementInputPanel } from '../../../../actions/dafCompanyVehicleActions'
import { COLORS } from '../../../../constants/themeConstants'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { UserContext } from '../../../../providers/UserProvider'
import Button from '../../../atoms/Button/Button'
import renderHTML from '../../../../helpers/renderHTMLHelper'
import { CheckboxField } from '../../../atoms/FormFields'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { getMileageValue, getVehicleLabel } from '../../../../helpers/vehicleHelper'
import sendRmMileageSettlementInputPayload, {
  SendRmMileageSettlementInputPayloadProps,
} from '../../../../services/rest/ecommerce/sendRmMileageSettlementInput'
import { InputMileageTextField } from '../../../atoms/FormFields/InputMileageTextField'
import { RootState } from '../../../../reducers'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'

interface MileageSettlementInputFormPanelProps {
  vehicle: VehicleDetailResponse | undefined
  messageOnSuccess?: string
  acceptedEstimation?: boolean
  onFormSuccess?: () => void
}

type FormData = {
  type?: string
  estimatedMileage: boolean
  mileageInput: number
  remarks?: string
}
const MileageSettlementInputFormPanel = ({
  vehicle,
  messageOnSuccess,
  onFormSuccess,
  acceptedEstimation = false,
}: MileageSettlementInputFormPanelProps) => {
  const dispatch = useDispatch()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { token } = axios.CancelToken.source()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const [confirmSendForm, setConfirmSendForm] = useState(false)
  const [hasFormErrors, setHasFormErrors] = useState(false)
  const [formSuccessfullySend, setFormSuccessfullySend] = useState(false)
  const formApiRef = useRef<FormApi<FormData> | null>(null)
  const { mileageSettlementInputFormPanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )
  const today = dayjs()
  let mileageRetrievalDate
  if (vehicle?.rmContract?.finance?.mileageSettlementStatus) {
    mileageRetrievalDate = dayjs(
      vehicle.rmContract.finance.mileageSettlementStatus.mileageRetrievalDate
    )
  }

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const apiQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const lastUpdatedInDays = today.diff(mileageRetrievalDate, 'day')

  useEffect(() => {
    setConfirmSendForm(acceptedEstimation)
  }, [acceptedEstimation])

  const postRmMileageSettlementInput = async (
    chassisNumber: string | undefined,
    payload: SendRmMileageSettlementInputPayloadProps
  ) => {
    const response = await sendRmMileageSettlementInputPayload(
      chassisNumber,
      payload,
      client,
      apiQueryParams,
      token,
      user
    )

    return response.data
  }
  const currentDay = dayjs().format('DD-MM-YYYY')

  const minMileageInputNumber = () => {
    const latestMileageSettlementMileage =
      vehicle?.rmContract?.finance?.mileageSettlementDetails
        ?.latestMileageSettlementMileage
    const startKilometers =
      vehicle?.rmContract?.servicePlan?.details?.startKilometers

    let baseMileage = 0

    if (latestMileageSettlementMileage) {
      baseMileage = latestMileageSettlementMileage
    } else if (startKilometers) {
      baseMileage = startKilometers
    }

    return baseMileage
  }

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (payload: SendRmMileageSettlementInputPayloadProps) =>
      postRmMileageSettlementInput(
        vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber,
        payload
      ),

    onSuccess: () => {
      if (onFormSuccess) onFormSuccess()
      setFormSuccessfullySend(true)
    },
  })
  const onSubmit = async (values: FormData | undefined) => {
    if (!values && !acceptedEstimation) return

    const hasEstimatedMileage = values?.estimatedMileage || acceptedEstimation
    const mileageSettlementUserSourceValue = hasEstimatedMileage ? 2 : 3

    const mileageInputValue: number = hasEstimatedMileage
      ? (vehicle?.rmContract?.finance?.mileageSettlementStatus
          ?.mileageEstimation as number)
      : values?.mileageInput || 0

    const payload: SendRmMileageSettlementInputPayloadProps = {
      mileageSettlementUserSource: mileageSettlementUserSourceValue,
      mileage: mileageInputValue,
    }

    mutate(payload)
  }

  return (
    <SidePanel
      position="right"
      active={mileageSettlementInputFormPanelState}
      onClose={() => {
        dispatch(toggleMileageSettlementInputPanel({ isActive: false }))
      }}
      disableOutsideClick
      title={i18next.t('formLabels.inputMileageTitle')}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          mileageInput: '',
          estimatedMileage: false,
        }}
        validate={(values: FormData) => {
          const errors: ValidationErrors = {}

          if (!values.mileageInput && !values.estimatedMileage) {
            errors.mileageInput = i18next.t('commonFormLabels.required')
          }

          if (
            values.mileageInput &&
            values.mileageInput < minMileageInputNumber() &&
            !values.estimatedMileage
          ) {
            errors.mileageInput = i18next.t('commonFormLabels.invalidMileage')
          }

          setTimeout(() => {
            // is needed to bypass an error. Still don't know why
            setHasFormErrors(Object.keys(errors).length > 0)
          }, 0)

          return errors
        }}
        render={({ handleSubmit, form, values, pristine }) => {
          formApiRef.current = form
          return (
            <form onSubmit={handleSubmit} style={{ display: 'flex', flex: 1 }}>
              <Flex
                sx={{
                  flexDirection: 'column',
                }}
              >
                <Flex
                  sx={{
                    paddingX: 6,
                    paddingY: 4,
                    flexDirection: 'column',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    flexGrow: 1,
                  }}
                >
                  {!isLoading &&
                    vehicle &&
                    !formSuccessfullySend &&
                    !confirmSendForm && (
                      <>
                        <Box data-t-id="input-mileage-header-text">
                          <Text
                            variant="bodySmall"
                            color={COLORS.DARK}
                            sx={{ display: 'inline' }}
                          >
                            {`${i18next.t('formLabels.inputMileageHeaderText')} `}
                          </Text>
                          <Text
                            variant="bodySmall"
                            color={COLORS.BLACK}
                            sx={{ display: 'inline', fontWeight: 'bold' }}
                          >
                            {getVehicleLabel(vehicle.vehicleDetail)}
                          </Text>
                        </Box>
                        <Box>
                          {vehicle?.rmContract?.finance?.mileageSettlementStatus
                            ?.mileageEstimation && (
                            <Box
                              sx={{ marginTop: 4 }}
                              data-t-id="input-mileage-estimated-mileage-checkbox"
                            >
                              <CheckboxField
                                name="estimatedMileage"
                                onChange={() => form.resetFieldState('mileageInput')}
                              >
                                {`${i18next.t(
                                  'formLabels.useEstimatedMileageLabel'
                                )}: `}
                                <Text
                                  color={COLORS.DARK}
                                  sx={{ display: 'inline', fontWeight: 'bold' }}
                                >
                                  {getMileageValue(
                                    vehicle?.rmContract?.finance
                                      ?.mileageSettlementStatus?.mileageEstimation,
                                    cultureCode
                                  )}
                                </Text>
                                <Text sx={{ marginTop: 2 }}>
                                  {i18next.t(
                                    'formLabels.inputMileageUpdatedInDays',
                                    {
                                      days: lastUpdatedInDays,
                                    }
                                  )}
                                </Text>
                              </CheckboxField>
                            </Box>
                          )}
                          <Box
                            sx={{ marginTop: 3 }}
                            data-t-id="input-mileage-input-field"
                          >
                            <InputMileageTextField
                              label={i18next.t(
                                'formLabels.inputVehicleMileageLabel'
                              )}
                              name="mileageInput"
                              disabled={values.estimatedMileage}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  {(!vehicle || isLoading) && (
                    <Box sx={{ marginTop: 4 }}>
                      <Spinner size={4} />
                    </Box>
                  )}
                  {confirmSendForm && !formSuccessfullySend && (
                    <>
                      <Box>
                        <Text
                          variant="bodySmall"
                          color={COLORS.DARK}
                          sx={{ display: 'inline' }}
                          data-t-id="mileage-confirmation-header-text"
                        >
                          {`${i18next.t('formLabels.inputMileageAreYouSureText')} `}
                        </Text>
                        <Text
                          variant="bodySmall"
                          color={COLORS.DARK}
                          sx={{ display: 'inline', fontWeight: 'bold' }}
                        >
                          {
                            vehicle?.vehicleDetail?.vehicleAttributes
                              ?.registrationNumber
                          }
                        </Text>
                      </Box>
                      <Box sx={{ marginTop: 4 }}>
                        <Box>
                          <Text
                            variant="bodySmall"
                            color={COLORS.BLACK}
                            sx={{
                              marginInlineEnd: 1,
                              fontWeight: 'bold',
                            }}
                          >
                            {i18next.t('formLabels.providedMileage')}
                          </Text>
                          <Text
                            variant="bodySmall"
                            color={COLORS.DARK}
                            data-t-id="input-mileage-provided-mileage"
                          >
                            {values.estimatedMileage || acceptedEstimation
                              ? getMileageValue(
                                  vehicle?.rmContract?.finance
                                    ?.mileageSettlementStatus?.mileageEstimation,
                                  cultureCode
                                )
                              : values.mileageInput}
                          </Text>
                        </Box>
                        <Box sx={{ marginTop: 1 }}>
                          <Text
                            variant="bodySmall"
                            color={COLORS.BLACK}
                            sx={{
                              fontWeight: 'bold',
                              marginInlineEnd: 1,
                            }}
                          >
                            {i18next.t('commonLabels.date')}
                          </Text>
                          <Text
                            variant="bodySmall"
                            color={COLORS.DARK}
                            data-t-id="input-mileage-date"
                          >
                            {currentDay}
                          </Text>
                        </Box>
                      </Box>
                    </>
                  )}
                  {formSuccessfullySend && messageOnSuccess && (
                    <Box>
                      <Text variant="bodySmall" color={COLORS.DARK}>
                        {renderHTML(messageOnSuccess)}
                      </Text>
                    </Box>
                  )}
                </Flex>
                <SidePanelFooter sx={{ flexDirection: 'column', marginTop: 'auto' }}>
                  {!formSuccessfullySend && !confirmSendForm && (
                    <>
                      <Box
                        sx={{ width: '100%' }}
                        data-t-id="input-mileage-send-button"
                      >
                        <EditServicePlanFormSubmitButton
                          label={i18next.t('commonFormLabels.send')}
                          disabled={isLoading || pristine || hasFormErrors}
                          onSubmit={() => setConfirmSendForm(true)}
                        />
                      </Box>
                    </>
                  )}
                  {confirmSendForm && !formSuccessfullySend && (
                    <Flex sx={{ gap: 3 }}>
                      <Box
                        sx={{ width: '50%' }}
                        data-t-id="input-mileage-close-button"
                      >
                        <Button
                          variant="outline"
                          sx={{ width: '100%' }}
                          data-t-id="input-mileage-close-button"
                          onClick={() => {
                            dispatch(
                              toggleMileageSettlementInputPanel({
                                isActive: false,
                              })
                            )
                            setFormSuccessfullySend(false)
                            setConfirmSendForm(false)
                            form.reset()
                          }}
                        >
                          {i18next.t('commonLabels.close')}
                        </Button>
                      </Box>
                      <Box
                        sx={{ width: '50%' }}
                        data-t-id="input-mileage-yes-button"
                      >
                        <Button
                          type="submit"
                          sx={{ width: '100%' }}
                          disabled={
                            !vehicle?.vehicleActions.inputMileage.confirmEnabled
                          }
                        >
                          {i18next.t('commonLabels.yes')}
                        </Button>
                      </Box>
                    </Flex>
                  )}
                  {formSuccessfullySend && confirmSendForm && (
                    <Button
                      onClick={() => {
                        setFormSuccessfullySend(false)
                        setConfirmSendForm(false)
                        form.reset()
                        dispatch(
                          toggleMileageSettlementInputPanel({ isActive: false })
                        )
                      }}
                      data-t-id="input-mileage-close-button"
                      sx={{ width: '100%' }}
                    >
                      {i18next.t('commonLabels.close')}
                    </Button>
                  )}
                  <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                    <Text
                      sx={{
                        fontSize: 0,
                        fontWeight: 'bold',
                      }}
                      color={COLORS.GRAY}
                    >
                      {i18next.t('formLabels.inputMileageFooterText')}
                    </Text>
                  </Box>
                </SidePanelFooter>
              </Flex>
            </form>
          )
        }}
      />
    </SidePanel>
  )
}

export default MileageSettlementInputFormPanel
