import React, { FC } from 'react'
import { useTheme } from '@emotion/react'
import { Box } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'

interface IconWrapperProps {
  icon?: string
  size?: number | 'fill'
  color?: string
  title?: string
  description?: string
  viewBox?: string
  style?: Record<string, unknown>
  showDot?: boolean
  rotate?: number
}

const IconWrapper: FC<IconWrapperProps> = ({
  icon,
  size = 5,
  viewBox = '0 0 1024 1024',
  style = {},
  color = 'currentColor',
  showDot = false,
  rotate = 0,
  ...props
}) => {
  const { fontSizes, colors } = useTheme()

  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      ...style,
    },
    path: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fill: colors?.[color] || color,
    },
  }

  if (!icon) return null

  return (
    <Box sx={{ position: showDot ? 'relative' : undefined, rotate: `${rotate}deg` }}>
      <svg
        style={styles.svg}
        width={size === 'fill' ? '100%' : fontSizes[size]}
        height={size === 'fill' ? '100%' : fontSizes[size]}
        viewBox={viewBox}
        // If no accessible title/description is given, assume the icon is for decoration purposes only, which means we must
        // add the `presentation` role.
        role={!props.title && !props.description ? 'presentation' : undefined}
      >
        {props.title && <title>{props.title}</title>}

        {props.description && <desc>{props.description}</desc>}

        <path style={styles.path} d={icon} />
      </svg>
      {showDot && (
        <span
          style={{
            position: 'absolute',
            top: 0,
            right: '-3px',
            width: '6px',
            height: '6px',
            backgroundColor: COLORS.PROMOTION,
            borderRadius: '50%',
          }}
        />
      )}
    </Box>
  )
}

export default IconWrapper
