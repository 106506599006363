import React, { FC } from 'react'
import { FormFieldSection } from '@sitecore-jss/sitecore-jss-forms'
import { FieldProps } from '@sitecore-jss/sitecore-jss-react-forms'
import { Box } from 'theme-ui'

export const Section: FC<FieldProps<FormFieldSection>> = ({
  field,
  fieldFactory,
}) => (
  <Box
    sx={{
      marginBottom: [5, 5, 7],
      '> :last-child': {
        marginBottom: 0,
      },
    }}
    className={`section${field.model.cssClass ? ` ${field.model.cssClass}` : ''}`}
  >
    {field.fields.map(fieldFactory)}
  </Box>
)
