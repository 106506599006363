export const SHOPPING_BASKET_SIDE_PANEL_REFRESH = 'ShoppingBasketSidePanel/REFRESH'
export const SHOPPING_BASKET_SIDE_PANEL_SET_ODER_SUMMARY =
  'ShoppingBasketSidePanel/SET_ORDER_SUMMARY'
export const SHOPPING_BASKET_SIDE_PANEL_SET_STATE =
  'ShoppingBasketSidePanel/SET_STATE'
export const SHOPPING_BASKET_WIZARD_REFETCH_ORDER =
  'ShoppingBasketWizard/REFETCH_ORDER'
export const SHOPPING_BASKET_WIZARD_SET_ORDER = 'ShoppingBasketWizard/SET_ORDER'
export const SHOPPING_BASKET_WIZARD_REMOVE_ORDER_ITEMS =
  'ShoppingBasketWizard/REMOVE_ORDER_ITEMS'
export const SHOPPING_BASKET_WIZARD_ADD_OR_REPLACE_ORDER_ITEM =
  'ShoppingBasketWizard/ADD_OR_REPLACE_ORDER_ITEM'
export const SHOPPING_BASKET_WIZARD_SET_ORDER_ITEM_REFERENCE =
  'ShoppingBasketWizard/SET_ORDER_ITEM_REFERENCE'
export const SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL =
  'ShoppingBasketWizard/SET_EDIT_ITEM_SIDEPANEL'
export const SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_REFERENCE =
  'ShoppingBasketWizard/SET_CUSTOMER_ORDER_REFERENCE'
export const SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_NUMBER =
  'ShoppingBasketWizard/SET_CUSTOMER_ORDER_NUMBER'
export const SHOPPING_BASKET_WIZARD_SET_ORDER_REMARKS =
  'ShoppingBasketWizard/SET_ORDER_NOTE'
export const SHOPPING_BASKET_WIZARD_SET_WAY_OF_TRANSPORT =
  'ShoppingBasketWizard/SET_WAY_OF_TRANSPORT'
export const SHOPPING_BASKET_WIZARD_ORDER_MUTATION =
  'ShoppingBasketWizard/CREATE_SHOPPING_BASKET_WIZARD_ORDER_MUTATION'
export const SHOPPING_BASKET_WIZARD_ORDER_MUTATION_FINISHED =
  'ShoppingBasketWizard/MARK_SHOPPING_BASKET_WIZARD_ORDER_MUTATION_FINISHED'
export const SHOPPING_BASKET_WIZARD_OVERWRITE_ORDER_RECIPIENTS =
  'ShoppingBasketWizard/SHOPPING_BASKET_WIZARD_OVERWRITE_ORDER_RECIPIENTS'
