import React, { FC, useContext } from 'react'
import PartStockInformationList, {
  StockInformationVariant,
} from './PartStockInformationList'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'
import { DealerPartSalesInformationContext } from '../../../providers/DealerPartSalesInformationProvider'
import { getStockInformation } from '../../../helpers/stockHelper'
import { BoxProps } from 'theme-ui'

interface PartStockInformationProps extends BoxProps {
  partNumber?: string
  displayPartNumber?: string
  variant?: StockInformationVariant
  componentOrigin: string
  isDealer?: boolean
}

const PartStockInformationListAsync: FC<PartStockInformationProps> = ({
  partNumber,
  displayPartNumber,
  variant,
  componentOrigin,
  isDealer,
  ...partStockInformationStatusProps
}) => {
  const { stockInformation } = useContext(PartSalesInformationContext)
  const { stockInformation: dealerStockInformation } = useContext(
    DealerPartSalesInformationContext
  )
  return (
    <PartStockInformationList
      stockInformation={
        isDealer
          ? getStockInformation(partNumber, dealerStockInformation)
          : stockInformation
      }
      partNumber={partNumber}
      displayPartNumber={displayPartNumber}
      variant={variant}
      componentOrigin={componentOrigin}
      {...partStockInformationStatusProps}
    />
  )
}

export default PartStockInformationListAsync
