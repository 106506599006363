import React from 'react'
import { Flex, FlexProps, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { COLORS } from '../../../constants/themeConstants'

interface SidePanelBodyProps
  extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {}

const SidePanelBody = ({ children, sx, ...flexProps }: SidePanelBodyProps) => {
  const { space } = useTheme()

  return (
    <Flex
      className="c-side-panel__body"
      sx={{
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingInline: [space[3], null, space[6]],
        paddingBlock: [space[3], null, space[5]],
        ...sx,
      }}
      {...flexProps}
    >
      <Text variant="bodySmall" color={COLORS.GRAY}>
        {children}
      </Text>
    </Flex>
  )
}

export default SidePanelBody
