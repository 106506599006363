import React, { FC } from 'react'
import DealerContactInformationBase from '../../molecules/DealerContactInformation/DealerContactInformationBase'

const DealerContactInformationBannerPageEditingComponent: FC = () => (
  <DealerContactInformationBase
    name="dealerName"
    address="dealerAddress"
    phone="dealerPhone"
    email="dealerEmail"
  />
)

export default DealerContactInformationBannerPageEditingComponent
