import React, { FC, useMemo } from 'react'
import { LinkField, TextField } from '../../../types/layoutService'
import PartBillOfMaterialMastersTable from './PartBillOfMaterialMastersTable'
import { CommerceBomMaster } from '../../../types/commerceApi'
import PartBillOfMaterialResponsiveTable from './PartBillOfMaterialResponsiveTable'

interface PartBillOfMaterialMastersProps {
  partsPageUri: LinkField
  bomMasters?: CommerceBomMaster[]
  includedInTitle: TextField
  id: string
}

const PartBillOfMaterialMasters: FC<PartBillOfMaterialMastersProps> = ({
  bomMasters,
  partsPageUri,
  includedInTitle,
  id,
}) => {
  const table = useMemo(
    () => (
      <PartBillOfMaterialMastersTable
        partsPageUri={partsPageUri}
        bomMasters={bomMasters}
      />
    ),
    [partsPageUri, bomMasters]
  )

  if (!bomMasters?.length) return null

  return (
    <PartBillOfMaterialResponsiveTable title={includedInTitle} id={id}>
      {table}
    </PartBillOfMaterialResponsiveTable>
  )
}

export default PartBillOfMaterialMasters
