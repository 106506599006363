import { ToastProps } from '../types/toastTypes'
import {
  ADD_TOAST,
  REMOVE_TOAST,
  RESET_TOASTS,
  ToastActions,
} from '../actions/toastActions'

export interface ToastsReducerState {
  toasts?: ToastProps[]
}

const toastsReducer = (
  state: ToastsReducerState = {
    toasts: [],
  },
  action: ToastActions
): ToastsReducerState => {
  switch (action.type) {
    case ADD_TOAST:
      return {
        ...state,
        toasts: [action.payload, ...(state?.toasts || [])],
      }
    case REMOVE_TOAST:
      return {
        ...state,
        toasts: state?.toasts?.filter((toast) => toast.id !== action.payload),
      }
    case RESET_TOASTS:
      return {
        ...state,
        toasts: [],
      }

    default:
      return state
  }
}

export default toastsReducer
