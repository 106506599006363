import i18next from 'i18next'
import React, { FC, useCallback, useEffect } from 'react'
import { Flex, FlexProps } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import { runOnDocument } from '../../../helpers/dom'
import { PaginationState } from '../../../hooks/usePagination'
import Button from '../../atoms/Button/Button'
import PaginationIndicator from '../../atoms/PaginationIndicator/PaginationIndicator'

export interface PaginationControlsProps extends Omit<FlexProps, 'css'> {
  handlePreviousClick: () => void
  handleNextClick: () => void
  handleIndicatorClick: (pageNumber: number) => void
  handleCloseClick: () => void
  state: PaginationState
}

const PaginationControls: FC<PaginationControlsProps> = ({
  handlePreviousClick,
  handleNextClick,
  handleIndicatorClick,
  handleCloseClick,
  state: { pageCount, currentPageIndex, isFirstPage, isLastPage },
  sx,
  ...flexProps
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handlePreviousClick()
      } else if (event.key === 'ArrowRight') {
        handleNextClick()
      } else if (event.key === 'Escape') {
        handleCloseClick()
      }
    },
    [handleCloseClick, handleNextClick, handlePreviousClick]
  )

  useEffect(() => {
    runOnDocument((doc) => doc.addEventListener('keydown', handleKeyDown))

    return () => {
      runOnDocument((doc) => doc.removeEventListener('keydown', handleKeyDown))
    }
  }, [handleKeyDown])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
      {...flexProps}
    >
      <Button
        variant="outline"
        onClick={handlePreviousClick}
        disabled={isFirstPage}
        icon={ICONS.ARROW_LEFT}
        sx={{
          visibility: isFirstPage ? 'hidden' : 'initial',
        }}
      >
        {i18next.t('paginationLabels.previous')}
      </Button>

      <PaginationIndicator
        pageCount={pageCount}
        currentPageIndex={currentPageIndex}
        onClick={handleIndicatorClick}
      />

      <Button
        variant="primary"
        onClick={isLastPage ? handleCloseClick : handleNextClick}
        icon={ICONS.ARROW_RIGHT}
        iconPosition="end"
      >
        {isLastPage
          ? i18next.t('paginationLabels.close')
          : i18next.t('paginationLabels.next')}
      </Button>
    </Flex>
  )
}

export default PaginationControls
