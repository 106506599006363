import React, { ComponentType } from 'react'
import { getDisplayName } from '../helpers/getDisplayName'
import { DatasourceBase, Params } from '../types/layoutService'
import ThemeProvider, { ComponentBackgroundColor } from '../providers/ThemeProvider'
import useSitecoreContext from '../hooks/useSitecoreContext'

export interface ThemingParams {
  BackgroundColor: ComponentBackgroundColor
}

const getSubThemeName = (backgroundColorSetting?: string) => {
  if (!backgroundColorSetting) return undefined

  switch (backgroundColorSetting) {
    case 'DafGray': {
      return 'dafGray'
    }
    case 'Primary': {
      return 'trpBlue'
    }
    default: {
      return 'default'
    }
  }
}

function withSubTheme<Props extends DatasourceBase>(
  WrappedComponent: ComponentType<Props>
): ComponentType<Props & Params<ThemingParams>> {
  const displayName = getDisplayName(WrappedComponent)

  const WithSubTheme = (props: Props & Params<ThemingParams>) => {
    const { params } = props
    const {
      site: { theme },
    } = useSitecoreContext()

    if (!params?.BackgroundColor || params?.BackgroundColor !== 'None') {
      const subThemeName = getSubThemeName(params.BackgroundColor)

      return (
        <ThemeProvider themeName={theme?.name} subThemeName={subThemeName}>
          <WrappedComponent {...props} />
        </ThemeProvider>
      )
    }

    return <WrappedComponent {...props} />
  }

  WithSubTheme.displayName = `withTheme(${displayName})`

  return WithSubTheme
}

export default withSubTheme
