import React, { createContext, ReactNode, useMemo } from 'react'
import { UserProfile, UserProps } from '../types/userProps'
import useSitecoreContext from '../hooks/useSitecoreContext'
import { orderUserCompaniesByPriority } from '../helpers/userHelper'
import { UserType } from '../types/sitecoreContextProps'

export interface UserContextProps {
  user?: UserProps
  userProfile?: UserProfile
  userType: UserType
}

export const UserContext = createContext<UserContextProps>({
  user: undefined,
  userProfile: undefined,
  userType: 'anonymous',
})

interface UserProviderProps {
  children: ReactNode
}

const UserProvider = ({ children }: UserProviderProps) => {
  const { user, userProfile, userType } = useSitecoreContext()

  const userProfileWithSortedCompanies = useMemo<UserProfile | undefined>(
    () =>
      userProfile
        ? {
            ...userProfile,
            companies: orderUserCompaniesByPriority(userProfile?.companies),
          }
        : undefined,
    [userProfile]
  )

  return (
    <UserContext.Provider
      value={{
        user,
        userProfile: userProfileWithSortedCompanies,
        userType,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
