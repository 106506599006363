import React, { useContext } from 'react'
import { ImageField } from '../../../types/layoutService'
import { LoyaltyContext } from '../../../providers/LoyaltyProvider'
import {
  LOYALTY_TYPE_NEXT,
  LOYALTY_TYPE_NEXT_PREMIUM,
  LOYALTY_TYPE_PPFS,
} from '../../../constants/customerLoyalty'
import Image from '../../atoms/Image/Image'

interface LoyaltyProgramLogoProps {
  logoNEXT: ImageField
  logoNEXTPremium: ImageField
  logoPPFS: ImageField
}
const LoyaltyProgramLogo = ({
  logoNEXT,
  logoNEXTPremium,
  logoPPFS,
}: LoyaltyProgramLogoProps) => {
  const { loyaltyData } = useContext(LoyaltyContext)

  let logo = null

  switch (loyaltyData?.customerLoyaltyTypeName) {
    case LOYALTY_TYPE_NEXT:
      logo = logoNEXT
      break
    case LOYALTY_TYPE_NEXT_PREMIUM:
      logo = logoNEXTPremium
      break
    case LOYALTY_TYPE_PPFS:
      logo = logoPPFS
      break
    default:
  }

  if (logo) {
    return (
      <Image
        {...logo}
        sx={{
          objectFit: 'initial',
        }}
      />
    )
  }

  return null
}

export default LoyaltyProgramLogo
