import React, { FC } from 'react'
import Button, { ButtonProps, ButtonVariants } from '../../atoms/Button/Button'
import {
  ButtonParams,
  LinkField,
  ReferenceField,
} from '../../../types/layoutService'
import {
  FONT_AWESOME_ICONS,
  FontAwesomeIcon,
} from '../../../constants/iconConstants'
import {
  resolveTarget,
  resolveText,
  resolveUrl,
} from '../../../helpers/linkFieldHelper'

interface SitecoreButtonProps extends Omit<ButtonProps, 'css'> {
  iconField?: ReferenceField
  linkField?: LinkField
  params?: ButtonParams
  variant?: ButtonVariants
}

export const resolveIcon = (field?: ReferenceField): string =>
  FONT_AWESOME_ICONS?.[field?.targetItem?.name as FontAwesomeIcon]

const resolveVariant = (
  variant?: ButtonVariants,
  params?: ButtonParams
): ButtonVariants =>
  variant || (params?.ButtonStyle?.toLowerCase() as ButtonVariants) || 'primary'

const SitecoreButton: FC<SitecoreButtonProps> = ({
  iconField,
  linkField,
  variant,
  params,
  ...rest
}) => {
  const url = resolveUrl(linkField)

  if (!url) return null

  return (
    <Button
      as="a"
      icon={resolveIcon(iconField)}
      href={url}
      target={resolveTarget(linkField)}
      variant={resolveVariant(variant, params)}
      {...rest}
    >
      {resolveText(linkField)}
    </Button>
  )
}

export default SitecoreButton
