import React, { FC } from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import withData from '../../../enhancers/withData'
import withSearchContext from '../../../enhancers/withSearchContext'
import {
  Datasource,
  Flag,
  LinkField,
  ReferenceField,
  TextField,
} from '../../../types/layoutService'
import AssemblySearchContextProvider from '../../../providers/AssemblySearchContextProvider'
import { PartFilterSidePanelDatasource } from '../SidePanel/PartFilterSidePanel/PartFilterSidePanel'
import SearchResultsLoadedResults from './SearchResultsLoadedResults'
import PartSearchContextProvider from '../../../providers/PartSearchContextProvider'
import DealerPartSearchContextProvider from '../../../providers/DealerPartSearchContextProvider'
import SearchResultsTabs from './SearchResultsTabs'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import ExperienceEditorComponentFrame from '../../molecules/ExperienceEditor/ExperienceEditorComponentFrame'

export interface SearchResultsDatasource {
  noSearchResultsDescription?: TextField
  noResultsLabel: TextField
  invalidFilterCombinationLabel: TextField
  removeFiltersLabel: TextField
  moreFiltersLabel: TextField
  supersessionText: TextField
  filtersLabel: TextField
  categoriesLabel: TextField
  subcategoriesLabel: TextField
  tableViewAnchorLabel: TextField
  viewLabel: TextField
  loadingLabel: TextField
  filterOverlay: ReferenceField<PartFilterSidePanelDatasource>
}

export interface SearchResultsProps {
  partPage: LinkField
  assemblyPage: LinkField
  dealerPartPage: LinkField
  params: {
    isPromotion: Flag
  }
}

const SearchResults: FC<
  Datasource<SearchResultsDatasource> & SearchResultsProps
> = ({ datasource, rendering, ...props }) => (
  <>
    {rendering?.params?.isPromotion === '1' ? (
      <PartSearchContextProvider isPromotion>
        <SearchResultsLoadedResults
          noSearchResultsDescription={datasource.noSearchResultsDescription}
          loadingLabel={datasource.loadingLabel}
          rendering={rendering}
        >
          <SearchResultsTabs
            searchResultsDatasource={datasource}
            searchResultProps={props}
          />
        </SearchResultsLoadedResults>
      </PartSearchContextProvider>
    ) : (
      <AssemblySearchContextProvider>
        <PartSearchContextProvider>
          <DealerPartSearchContextProvider>
            <SearchResultsLoadedResults
              noSearchResultsDescription={datasource.noSearchResultsDescription}
              loadingLabel={datasource.loadingLabel}
              rendering={rendering}
            >
              <SearchResultsTabs
                searchResultsDatasource={datasource}
                searchResultProps={props}
              />
            </SearchResultsLoadedResults>
          </DealerPartSearchContextProvider>
        </PartSearchContextProvider>
      </AssemblySearchContextProvider>
    )}
  </>
)

const SearchResultPageEditing: FC<
  Datasource<SearchResultsDatasource> & SearchResultsProps
> = ({ rendering }) => (
  <ExperienceEditorComponentFrame
    title="No search results"
    text="The components added below will be visible when there are no search results"
  >
    <Placeholder name="jss-no-search-results" rendering={rendering} />
  </ExperienceEditorComponentFrame>
)

export default withData(withClientSideRender(withSearchContext(SearchResults)), {
  showMessageWhenPageEditing: true,
  pageEditingComponent: SearchResultPageEditing,
  showPageEditingComponentWhenPreviewing: true,
})
