import React, { FC, useContext } from 'react'
import i18next from 'i18next'
import { Flex } from 'theme-ui'
import { loggedIn } from '../../../../helpers/userHelper'
import { UserContext } from '../../../../providers/UserProvider'
import PartPromotionsAsync from '../../PartPromotions/PartPromotionsAsync'
import CardBrandImage from '../CardBrandImage'
import CardExcerptText from '../CardExcerptText'
import CardImage from '../CardImage'
import CardTitle from '../CardTitle'
import { SkeletonComponent } from '../../../../types/layoutProps'
import CardFrame from '../CardFrame'
import PartPriceAsync from '../../PartPrice/PartPriceAsync'
import PartSalesInformationProvider from '../../../../providers/PartSalesInformationProvider'
import PromotionStickerAsync from '../../../atoms/PromotionSticker/PromotionStickerAsync'
import CardAction from '../CardAction'

export interface PartCardMinimalProps {
  partDescription?: string
  partNumber: string
  displayPartNumber?: string
  brandImageUrl?: string
  brandName?: string
  categoryId?: string
  subcategoryId?: string
  href: string
  imageUrl?: string
  onClick?: () => void
}

const PartCardMinimal: FC<PartCardMinimalProps> & SkeletonComponent = ({
  partDescription,
  partNumber,
  displayPartNumber,
  brandImageUrl,
  brandName,
  href,
  imageUrl,
  onClick,
}) => {
  const { user } = useContext(UserContext)

  return (
    <PartSalesInformationProvider partNumber={partNumber}>
      <CardFrame variant="minimal">
        <CardAction href={href} onClick={onClick}>
          <PromotionStickerAsync variant="m">
            <CardImage src={imageUrl} title={partDescription} />
          </PromotionStickerAsync>

          <CardTitle title={partDescription} />

          <CardExcerptText>
            {`${i18next.t('partLabels.partNumberAbbreviation')} ${
              displayPartNumber || partNumber
            }`}
          </CardExcerptText>
        </CardAction>

        <CardBrandImage imageUrl={brandImageUrl} name={brandName} />

        {loggedIn(user) && (
          <PartPriceAsync
            partNumber={partNumber}
            variant="small"
            sx={{
              marginTop: 3,
            }}
          />
        )}

        {loggedIn(user) && (
          <PartPromotionsAsync
            partNumber={partNumber}
            sx={{ flexBasis: '100%', marginTop: 3 }}
          />
        )}
      </CardFrame>
    </PartSalesInformationProvider>
  )
}

const PartCardMinimalSkeleton: FC<{ partNumber: string }> = ({ partNumber }) => (
  <Flex
    sx={{
      flexDirection: 'column',
    }}
  >
    <CardImage.Skeleton />

    <CardTitle.Skeleton />

    {partNumber && (
      <CardExcerptText>
        {`${i18next.t('partLabels.partNumberAbbreviation')} ${partNumber}`}
      </CardExcerptText>
    )}

    {!partNumber && <CardExcerptText.Skeleton />}

    <CardBrandImage.Skeleton />
  </Flex>
)

PartCardMinimal.Skeleton = PartCardMinimalSkeleton

export default PartCardMinimal
