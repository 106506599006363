import { useTheme } from '@emotion/react'
import React, { ReactNode } from 'react'
import { Flex } from 'theme-ui'

interface ContentModalProps {
  children: ReactNode
}

const ContentModal = ({ children }: ContentModalProps) => {
  const { breakpoints, space } = useTheme()

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: 'white',
        width: ['100%', '100%', breakpoints[1]],
        maxHeight: ['100%', '100%', `calc(100% - ${space[8]}px)`],
        overflow: 'hidden',
      }}
    >
      {children}
    </Flex>
  )
}

export default ContentModal
