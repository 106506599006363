import React, { FC, useCallback, useEffect, useState } from 'react'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import withData from '../../../enhancers/withData'
import useHasMounted from '../../../hooks/useHasMounted'
import { DialogPropsBase } from '../../../types/dialogTypes'
import { Datasource, Params, TextField } from '../../../types/layoutService'
import { NotificationSeverity } from '../../../types/notificationTypes'
import NotificationBarContent from '../NotificationBarContent/NotificationBarContent'
import toPascalCase from '../../../helpers/pascalCaseHelper'

interface NotificationBarDatasource {
  text: TextField
}

const NotificationBar: FC<
  Datasource<NotificationBarDatasource> & DialogPropsBase & Params
> = ({ datasource, hash, params: { Severity }, isEditingOrPreviewing }) => {
  const hasMounted = useHasMounted()
  const [isActive, setActive] = useState<boolean>(false)

  useEffect(() => {
    if (!hasMounted) return

    const hasPreviouslyInteractedWithNotificationBar =
      window.sessionStorage.getItem(hash)

    if (!hasPreviouslyInteractedWithNotificationBar) {
      setActive(true)
    }
  }, [hash, hasMounted])

  const onClose = useCallback(() => {
    window.sessionStorage.setItem(hash, new Date().toISOString())
  }, [hash])

  return (
    <NotificationBarContent
      isActive={isEditingOrPreviewing || isActive}
      onClose={isEditingOrPreviewing ? undefined : onClose}
      text={datasource.text}
      severity={toPascalCase(Severity) as NotificationSeverity}
    />
  )
}

export default withData(withClientSideRender(NotificationBar))
