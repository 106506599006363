import React, { FC } from 'react'
import { useDialogs } from '../../hooks/useDialogs'
import { DialogSelector } from '../../types/dialogTypes'
import DialogItemRenderer from './DialogItemRenderer'

interface DefaultDialogRendererProps {
  selector: DialogSelector
  limit?: number
}

export const DefaultDialogRenderer: FC<DefaultDialogRendererProps> = ({
  selector,
  limit,
}) => {
  const dialogs = useDialogs(selector, {
    limit,
  })

  return <DialogItemRenderer dialogs={dialogs} />
}
