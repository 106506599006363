import { useTheme } from '@emotion/react'
import React, { FC, forwardRef } from 'react'
import { Box, Link as RebassLink, LinkProps as RebassLinkProps } from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

export interface LinkProps extends Omit<RebassLinkProps, 'css'> {}

interface ConditionalLinkProps extends LinkProps {
  condition: boolean
  dataTId?: string
}

export const ConditionalLink: FC<ConditionalLinkProps> = ({
  condition,
  children,
  dataTId,
  ...linkProps
}) =>
  condition ? (
    <Link data-t-id={dataTId ?? null} {...linkProps}>
      {children}
    </Link>
  ) : (
    <Box data-t-id={dataTId ?? null} sx={linkProps.sx}>
      {children}
    </Box>
  )

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, sx, ...rest }, ref) => {
    const { transitions } = useTheme()
    const { pageEditing } = useSitecoreContext()

    return (
      <RebassLink
        ref={ref}
        sx={{
          transitionDuration: transitions[1],
          transitionProperty: 'opacity',
          ...sx,
        }}
        {...rest}
        {...(pageEditing
          ? { href: undefined, onClick: (e) => e.preventDefault() }
          : {})}
        rel={rest.target === '_blank' ? 'noopener' : undefined}
      >
        {children}
      </RebassLink>
    )
  }
)

export default Link
