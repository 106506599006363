import { Action } from 'redux'
import {
  PUSH_TO_ANCHOR_MENU,
  REMOVE_FROM_ANCHOR_MENU,
} from '../constants/anchorMenuConstants'

export interface AnchorMenuItemData {
  componentId: string
  componentTitle: string
}

interface PushToAnchorMenuAction extends Action<typeof PUSH_TO_ANCHOR_MENU> {
  payload: AnchorMenuItemData
}

interface RemoveFromAnchorMenuAction extends Action<typeof REMOVE_FROM_ANCHOR_MENU> {
  payload: AnchorMenuItemData
}

export type PushToAnchorMenuActions =
  | PushToAnchorMenuAction
  | RemoveFromAnchorMenuAction

export const pushToAnchorMenu = (
  payload: AnchorMenuItemData
): PushToAnchorMenuAction => ({
  type: PUSH_TO_ANCHOR_MENU,
  payload,
})

export const removeFromAnchorMenu = (
  payload: AnchorMenuItemData
): RemoveFromAnchorMenuAction => ({
  type: REMOVE_FROM_ANCHOR_MENU,
  payload,
})
