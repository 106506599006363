import React, { FC, useContext, useMemo } from 'react'
import withData from '../../../enhancers/withData'
import MainNavigationRight from './MainNavigationRight'
import MainNavigationMobileNavigationPanel from './MainNavigationMobileNavigationPanel'
import {
  Datasource,
  Flag,
  LinkField,
  NavigationItem,
  Params,
} from '../../../types/layoutService'
import MainNavigationContainer from '../../atoms/MainNavigationContainer/MainNavigationContainer'
import MainNavigationCenter from './MainNavigationCenter'
import MainNavigationLeft from './MainNavigationLeft'
import { UserContext } from '../../../providers/UserProvider'
import { loggedIn } from '../../../helpers/userHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { SiteThemeLogoImage } from '../../../types/sitecoreContextProps'
import AnchorMenu from '../AnchorMenu/AnchorMenu'
import PartsNavigation from '../PartsNavigation/PartsNavigation'

interface MainNavigationProps {
  navigationLeft: { items: NavigationItem[] }
  navigationRight: { items: NavigationItem[] }
  partsBaseUri: LinkField
}

interface MainNavigationDatasource {
  /* Intentionally empty, non of the props are exposed but a datasource item + check is required. */
}

interface MainNavigationParams {
  isTransparent?: Flag
}

const TEMPLATE_NAME_HOME_PAGE = 'Home Page'

const MainNavigation: FC<
  Datasource<MainNavigationDatasource> &
    Params<MainNavigationParams> &
    MainNavigationProps
> = ({ navigationLeft, navigationRight, rendering, params, partsBaseUri }) => {
  const { user } = useContext(UserContext)
  const { route, site } = useSitecoreContext()
  const { isTransparent: receivedTransparentSetting } = params
  const isTransparent =
    receivedTransparentSetting === '1' &&
    !loggedIn(user) &&
    route.templateName === TEMPLATE_NAME_HOME_PAGE
      ? '1'
      : undefined

  const logoImage = useMemo<SiteThemeLogoImage | undefined>(
    () =>
      isTransparent === '1' ? site?.theme?.logoImageWhite : site?.theme?.logoImage,
    [isTransparent]
  )

  return (
    <>
      <div>
        <MainNavigationContainer isTransparent={isTransparent}>
          <MainNavigationLeft logoImage={logoImage} navigation={navigationLeft} />

          <MainNavigationCenter rendering={rendering} />

          <MainNavigationRight
            navigation={navigationRight}
            rendering={rendering}
            isTransparent={isTransparent}
          />
        </MainNavigationContainer>

        <AnchorMenu />
      </div>

      <MainNavigationMobileNavigationPanel
        navigation={{
          items: [
            ...(navigationLeft?.items || []),
            ...(navigationRight?.items || []),
          ],
        }}
      />

      <PartsNavigation partsBaseUri={partsBaseUri} />
    </>
  )
}

export default withData(MainNavigation)
