import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { getUrlWithCurrentSearchParams } from '../../../helpers/urlHelper'
import { LinkField, TextField } from '../../../types/layoutService'
import SitecoreButton from '../SitecoreButton/SitecoreButton'

interface RequestAccountSelectDealerProps {
  selectDealerText: TextField
  selectDealerButton: LinkField
}

const RequestAccountSelectDealer: FC<RequestAccountSelectDealerProps> = ({
  selectDealerText,
  selectDealerButton,
}) => {
  const history = useHistory()
  const [link, setLink] = useState<LinkField | undefined>(undefined)

  const onClick = () => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
      action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.SELECT_DEALER,
    })
  }

  useEffect(() => {
    const targetUrl = getUrlWithCurrentSearchParams(selectDealerButton.url)

    setLink({
      ...selectDealerButton,
      url: targetUrl.toString(),
    })
  }, [history.location.search, selectDealerButton])

  return (
    <>
      <Text as="p" variant="bodyContent" sx={{ marginBottom: 3, color: 'gray.2' }}>
        {getTextFieldValue(selectDealerText)}
      </Text>

      {link && (
        <SitecoreButton linkField={link} variant="primary" onClick={onClick} />
      )}
    </>
  )
}

export default RequestAccountSelectDealer
