import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Box, Text } from 'theme-ui'
import { Form, FormSpy } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import { useMutation, useQueries } from '@tanstack/react-query'
import axios from 'axios'
import SidePanel from '../SidePanel'
import { toggleMaintenanceAppointmentPanel } from '../../../../actions/dafCompanyVehicleActions'
import { RootState } from '../../../../reducers'
import SidePanelClickableSection from '../SidePanelClickableSection/SidePanelClickableSection'
import SearchServicePartners from '../../../molecules/SearchServicePartners/SearchServicePartners'
import SidePanelMain from '../SidePanelMain'
import SidePanelPaddedBox from '../SidePanelPaddedBox'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import { Dealer } from '../../../../types/dealerServiceTypes'
import SidePanelFooter from '../SidePanelFooter'
import Button from '../../../atoms/Button/Button'
import { CheckboxField, SelectField, TextAreaField } from '../../../atoms/FormFields'
import { InputDateField } from '../../../atoms/FormFields/InputDateField'
import FormFieldText from '../../../atoms/FormControls/FormFieldText'
import sendMaintenanceAppointment, {
  SendMaintenanceAppointmentPayloadProps,
} from '../../../../services/rest/ecommerce/sendMaintenanceAppointment'
import { UserContext } from '../../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { fetchDealers } from '../../../../services/rest/dealer/dealerSearch'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { UpcomingJob } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { ValidationRules } from '../../../../helpers/formFieldHelper'
import { getTime } from '../../../../helpers/dateHelper'
import Spinner from '../../../atoms/Spinner/Spinner'
import FormGroup from '../../../atoms/FormControls/FormGroup'
import renderHTML from '../../../../helpers/renderHTMLHelper'
import InputRadioList from '../../../atoms/FormFields/InputRadioList'
import InputCheckboxList from '../../../atoms/FormFields/InputCheckboxList'
import { RequestMaintenanceAppointmentSidepanelDatasource } from '../../../../types/VehicleDetailGenericComponentDataProps'
import { getFormattedSidepanelVehicleIntroduction } from '../../../../helpers/sidepanelHelper'
import { VehicleReducerState } from '../../../../reducers/dafCompanyVehicleReducer'

const SHOW_OTHER_DAF_PARTNER_SEARCH = 'other'

interface MaintenanceAppointmentPanelProps {
  datasource: RequestMaintenanceAppointmentSidepanelDatasource
  vehicle: VehicleDetailResponse | undefined
}

type SupplierSelectOption = {
  label: string
  value: string
}

const RequestMaintenanceAppointmentPanel = ({
  datasource,
  vehicle,
}: MaintenanceAppointmentPanelProps) => {
  const dealerClient = useAxiosClient(AxiosClientType.DealerApi)
  const commerceClient = useAxiosClient(AxiosClientType.CommerceApi)
  const { token } = axios.CancelToken.source()
  const [supplierLocationCodes, setSupplierLocationCodes] = useState<string[]>([])
  const { userProfile } = useSitecoreContext()
  const { user } = useContext(UserContext)
  const dispatch = useDispatch()
  const [formSuccessfullySent, setFormSuccessfullySent] = useState(false)
  const [partner, setPartner] = useState<Dealer[]>([])
  const [otherSupplierIsDirty, setOtherSupplierIsDirty] = useState(false)
  const { selectedJobs } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const { maintenanceAppointmentPanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const getUpcomingJobs = (vehicleDetail: VehicleDetailResponse) => {
    if (selectedJobs?.length) {
      return selectedJobs
    }

    return vehicleDetail?.servicePlanner?.service?.upcomingJobs
      ?.filter((job) => !job.markedAsComplete)
      .sort((a, b) =>
        a.dueDate && b.dueDate ? getTime(a.dueDate) - getTime(b.dueDate) : 0
      )
  }

  const maintenanceReasons = [
    {
      value: 'plannedMaintenance',
      label: datasource.plannedMaintenanceOptionLabel?.value,
      checked: vehicle && !!getUpcomingJobs(vehicle)?.length,
    },
    {
      value: 'legalInspections',
      label: datasource.legalInspectionsOptionLabel?.value,
    },
    {
      value: 'repairAndVehicleUpgrade',
      label: datasource.repairAndVehicleUpgradeOptionLabel?.value,
    },
    {
      value: SHOW_OTHER_DAF_PARTNER_SEARCH,
      label: i18next.t('commonLabels.other'),
    },
  ]

  const momentPreferenceOptions = [
    {
      value: 'morning',
      label: datasource.optionMorningLabel?.value,
    },
    {
      value: 'afternoon',
      label: datasource.optionAfternoonLabel?.value,
    },
    {
      value: 'noPreference',
      label: datasource.optionNoPreferenceLabel?.value,
    },
  ]

  const getSuppliersData = async (locationCode: string) => {
    const response = await fetchDealers(
      dealerClient,
      {
        text: locationCode,
        pageSize: 20,
      },
      token
    )
    return response.data
  }

  const suppliersQueries = useQueries({
    queries: supplierLocationCodes.map((supplierLocationCode) => ({
      queryKey: [`supplier-${supplierLocationCode}`],
      queryFn: () => getSuppliersData(supplierLocationCode),
      enabled: !!maintenanceAppointmentPanelState,
    })),
  })

  const isLoadingSupplierData = suppliersQueries.some(
    (query) => query.status === 'pending'
  )

  const suppliersData = suppliersQueries.map((query) => {
    if (query.data && query.data.results && query.data.results.length > 0) {
      return query.data.results[0]
    }
    return {}
  })

  useEffect(() => {
    let locationCodes: string[] = []

    // Get data from userProfile
    if (userProfile?.companies && userProfile?.companies?.length >= 1) {
      const activeCompany = userProfile?.companies?.find(
        (company) => company.isActive
      )
      if (activeCompany?.suppliers) {
        locationCodes = activeCompany.suppliers.map(
          (supplier) => supplier.locationCode || ''
        )
      }
    }

    // Get data from mainServiceDealer
    const mainServiceDealer =
      vehicle?.rmContract?.servicePlan?.details?.mainServiceDealer
    if (mainServiceDealer) {
      if (!locationCodes.includes(mainServiceDealer.locationCode)) {
        locationCodes.push(mainServiceDealer.locationCode)
      }
    }

    setSupplierLocationCodes(locationCodes)
  }, [
    userProfile,
    vehicle,
    setSupplierLocationCodes,
    maintenanceAppointmentPanelState,
  ])

  const handleClickPartner = (selectedPartner: Dealer) => {
    setPartner([selectedPartner])
    setOtherSupplierIsDirty(true)
  }

  const handleRemovePartner = () => {
    setPartner([])
  }

  type FormData = {
    supplier: string
    otherSupplier: string
    appointmentReasons: string[]
    dateOptionA: string
    dateOptionB: string
    dateOptionC: string
    preferredSlot: string
    specifyMaintenanceJobs: string
    howContacted: string
  }

  const validate = (values: FormData) => {
    const errors: ValidationErrors = {}

    if (!values.supplier) {
      errors.supplier = i18next.t('commonFormLabels.required')
    }

    if (values.supplier === SHOW_OTHER_DAF_PARTNER_SEARCH && !partner.length) {
      errors.otherSupplier = i18next.t('commonFormLabels.required')
    }

    if (
      values.appointmentReasons?.includes(SHOW_OTHER_DAF_PARTNER_SEARCH) &&
      !values.specifyMaintenanceJobs
    ) {
      errors.specifyMaintenanceJobs = i18next.t('commonFormLabels.required')
    }

    if (new Date(values.dateOptionA) <= new Date()) {
      errors.dateOptionA = i18next.t('commonFormLabels.dateNotInPast')
    }

    if (new Date(values.dateOptionB) <= new Date()) {
      errors.dateOptionB = i18next.t('commonFormLabels.dateNotInPast')
    }

    if (new Date(values.dateOptionC) <= new Date()) {
      errors.dateOptionC = i18next.t('commonFormLabels.dateNotInPast')
    }

    return errors
  }

  const postMaintenanceAppointment = async (
    payload: SendMaintenanceAppointmentPayloadProps
  ) => {
    const response = await sendMaintenanceAppointment(
      payload,
      commerceClient,
      dafVehicleDetailQueryParams,
      token,
      user
    )

    return response.data
  }

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (payload: SendMaintenanceAppointmentPayloadProps) =>
      postMaintenanceAppointment(payload),

    onSuccess: () => {
      setFormSuccessfullySent(true)
    },
  })

  const onSubmit = async (values: FormData) => {
    const payload: SendMaintenanceAppointmentPayloadProps = {
      locationCode: partner[0]?.code || values.supplier,
      vin: vehicle?.vehicleDetail.vehicleAttributes.vin,
      reasonsForAppointment: maintenanceReasons
        .filter((reason) => values.appointmentReasons.includes(reason.value))
        .map((reason) => reason.label),
      maintenanceSpecification: values.specifyMaintenanceJobs,
      preferredTimeslot: {
        preferredDate1: values.dateOptionA,
        preferredDate2: values.dateOptionB,
        preferredDate3: values.dateOptionC,
      },
      preferredSlot: values.preferredSlot,
      contact: values.howContacted,
    }

    mutate(payload)
  }

  const suppliersSelectOptions = () => {
    const options: SupplierSelectOption[] = []
    if (!suppliersData) return options
    suppliersData.forEach((supplier) => {
      if (supplier?.name) {
        options.push({
          label: supplier?.name ?? '',
          value: supplier?.code ?? '',
        })
      }
    })

    return options
  }

  const handleClosePanel = () => {
    setFormSuccessfullySent(false)
    dispatch(toggleMaintenanceAppointmentPanel({ isActive: false }))
  }

  return (
    <SidePanel
      title={datasource.panelTitle?.value}
      active={maintenanceAppointmentPanelState}
      position="right"
      disableOutsideClick
      onClose={handleClosePanel}
    >
      {formSuccessfullySent ? (
        <>
          <SidePanelMain>
            <SidePanelPaddedBox>
              {renderHTML(datasource.confirmationText?.value)}
            </SidePanelPaddedBox>
          </SidePanelMain>
          <SidePanelFooter>
            <Button
              sx={{ width: '100%' }}
              variant="primary"
              onClick={() =>
                dispatch(
                  toggleMaintenanceAppointmentPanel({
                    isActive: false,
                  })
                )
              }
            >
              {i18next.t('commonLabels.close')}
            </Button>
          </SidePanelFooter>
        </>
      ) : (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            appointmentReasons: maintenanceReasons
              .filter((reason) => reason.checked)
              .map((reason) => reason.value),
            specifyMaintenanceJobs:
              vehicle &&
              getUpcomingJobs(vehicle)
                ?.map((job: UpcomingJob) => job.description)
                .join(', '),
            supplier: suppliersSelectOptions()[0]?.value,
          }}
          keepDirtyOnReinitialize
          render={({ handleSubmit, hasValidationErrors }) => (
            <>
              <SidePanelMain>
                <SidePanelPaddedBox>
                  {getFormattedSidepanelVehicleIntroduction(datasource, vehicle)}
                </SidePanelPaddedBox>
                <form onSubmit={handleSubmit} id="maintenanceAppointmentForm">
                  <SidePanelClickableSection
                    label={datasource.section1Title?.value}
                    initialActive
                    data-t-id="panel-maintenanceappointment-toggle-dafpartner"
                  >
                    {vehicle && !isLoadingSupplierData ? (
                      <>
                        {suppliersSelectOptions().length > 2 && (
                          <>
                            <FormSpy>
                              {(props) => (
                                <SelectField
                                  name="supplier"
                                  label={datasource.selectDAFPartnerLabel?.value}
                                  options={suppliersSelectOptions().map(
                                    (option) => ({
                                      value: option.value,
                                      text: option.label,
                                    })
                                  )}
                                  disabled={props.values.checkboxOtherSupplier}
                                />
                              )}
                            </FormSpy>

                            <CheckboxField
                              name="checkboxOtherSupplier"
                              label={datasource.searchDAFPartnerLabel?.value}
                            />
                          </>
                        )}

                        {(suppliersSelectOptions().length === 1 ||
                          suppliersSelectOptions().length === 2) && (
                          <InputRadioList
                            legend={
                              datasource.chooseDAFPartnerForMaintenanceLabel?.value
                            }
                            name="supplier"
                            options={[
                              ...suppliersSelectOptions(),
                              {
                                value: SHOW_OTHER_DAF_PARTNER_SEARCH,
                                label: i18next.t('commonLabels.other'),
                              },
                            ]}
                          />
                        )}
                        {!suppliersSelectOptions().length && (
                          <Text
                            variant="bodySmall"
                            sx={{
                              fontWeight: 'bold',
                              display: 'block',
                              marginBlockEnd: 8,
                            }}
                          >
                            {datasource.selectDAFPartnerLabel?.value}
                          </Text>
                        )}

                        <FormSpy>
                          {(props) => (
                            <>
                              {(!suppliersSelectOptions().length ||
                                props.values.supplier ===
                                  SHOW_OTHER_DAF_PARTNER_SEARCH ||
                                props.values.checkboxOtherSupplier) && (
                                <>
                                  <SearchServicePartners
                                    label={
                                      datasource.searchYourDAFPartnerLabel?.value
                                    }
                                    id="supplier-search"
                                    onSelectedPartners={handleClickPartner}
                                    onRemoveSelectedPartners={handleRemovePartner}
                                    infoMessage={false}
                                    partners={partner}
                                  />

                                  {otherSupplierIsDirty && (
                                    <FormFieldText type="error">
                                      {props.errors?.otherSupplier}
                                    </FormFieldText>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </FormSpy>
                      </>
                    ) : (
                      <>
                        <Box sx={{ marginTop: 4 }}>
                          <Spinner size={4} />
                        </Box>
                      </>
                    )}
                  </SidePanelClickableSection>

                  <SidePanelClickableSection
                    label={i18next.t(datasource.section2Title?.value, {
                      optionalLabel: i18next.t('formLabels.optional'),
                    })}
                  >
                    <InputCheckboxList
                      name="appointmentReasons"
                      label={datasource.selectMaintenanceLabel?.value}
                      options={maintenanceReasons}
                    />

                    <FormSpy>
                      {(props) => (
                        <TextAreaField
                          name="specifyMaintenanceJobs"
                          label={datasource.specifyMaintenanceJobsLabel?.value}
                          required={props.values.maintenanceReasons?.includes(
                            SHOW_OTHER_DAF_PARTNER_SEARCH
                          )}
                          placeholder={
                            datasource.specifyMaintenanceJobsPlaceholder?.value
                          }
                        />
                      )}
                    </FormSpy>
                  </SidePanelClickableSection>

                  <SidePanelClickableSection
                    label={i18next.t(datasource.section3Title?.value, {
                      optionalLabel: i18next.t('formLabels.optional'),
                    })}
                  >
                    <InputDateField
                      name="dateOptionA"
                      validationRules={[ValidationRules.ShouldBeInTheFuture]}
                      label={datasource.firstOptionLabel?.value}
                    />

                    <InputDateField
                      name="dateOptionB"
                      validationRules={[ValidationRules.ShouldBeInTheFuture]}
                      label={datasource.secondOptionLabel?.value}
                    />

                    <InputDateField
                      name="dateOptionC"
                      validationRules={[ValidationRules.ShouldBeInTheFuture]}
                      label={datasource.thirdOptionLabel?.value}
                    />

                    <InputRadioList
                      legend={datasource.partOfTheDayLabel?.value}
                      name="preferredSlot"
                      options={momentPreferenceOptions}
                    />

                    <Text>{datasource.dateNote?.value}</Text>
                  </SidePanelClickableSection>

                  <SidePanelClickableSection label={datasource.section4Title?.value}>
                    <FormGroup>
                      <TextAreaField
                        name="howContacted"
                        label={datasource.contactPreferenceLabel?.value}
                        placeholder={datasource.contactPreferencePlaceholder?.value}
                      />
                    </FormGroup>
                  </SidePanelClickableSection>
                </form>
              </SidePanelMain>

              <SidePanelFooter>
                <Button
                  type="submit"
                  disabled={
                    hasValidationErrors ||
                    isLoading ||
                    !vehicle?.vehicleActions.requestMaintenanceAppointment
                      .confirmEnabled
                  }
                  sx={{ width: '100%' }}
                  variant="primary"
                  form="maintenanceAppointmentForm"
                >
                  {i18next.t('commonFormLabels.confirm')}
                </Button>
              </SidePanelFooter>
            </>
          )}
        />
      )}
    </SidePanel>
  )
}

export default RequestMaintenanceAppointmentPanel
