import React, { FC } from 'react'
import {
  BannerVariant,
  ImageWithFocalPointField,
} from '../../../types/layoutService'
import SitecoreImage, { SitecoreImageProps } from '../SitecoreImage/SitecoreImage'

interface HeroBannerImageProps extends SitecoreImageProps {
  imageField?: ImageWithFocalPointField
  variant: BannerVariant
}

const BANNER_VARIANT_IMAGE_HEIGHT: Record<BannerVariant, string[]> = {
  Small: ['100px', '100px', '100px', '250px'],
  Medium: ['200px', '200px', '200px', '416px'],
  Large: ['375px', '375px', '375px', '620px'],
}

const BannerImage: FC<HeroBannerImageProps> = ({
  imageField,
  variant,
  sx,
  ...imageProps
}) => (
  <SitecoreImage
    imageField={imageField}
    sx={{
      height: BANNER_VARIANT_IMAGE_HEIGHT[variant],
      zIndex: -1,
      ...sx,
    }}
    {...imageProps}
  />
)

export default BannerImage
