import Axios from 'axios'
import { resolveEnvironment } from '../helpers/environment'
import { shouldUseLocalCommerceApiBaseUrl } from '../helpers/debugHelper'

const registerDefaultInterceptors = (instance) => {
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  )

  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
  )

  return instance
}

const createCommerceApiAxiosClient = (env) =>
  registerDefaultInterceptors(
    Axios.create({
      baseURL: shouldUseLocalCommerceApiBaseUrl()
        ? env.vars.localCommerceApiBaseUrl
        : env.vars.commerceApiBaseUrl,
      headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
    })
  )

const createDealerApiAxiosClient = (env) =>
  registerDefaultInterceptors(
    Axios.create({
      baseURL: env.vars.dealerApiBaseUrl,
      headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
    })
  )

const createSitecoreApiAxiosClient = (env) =>
  registerDefaultInterceptors(
    Axios.create({
      baseURL: env.vars.sitecoreApiBaseUrl,
      headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
    })
  )

const axiosClientFactory = (environmentName) => {
  const env = resolveEnvironment(environmentName)

  if (!env) {
    throw new Error('Unable to resolve environment.')
  }

  return {
    COMMERCE_API: createCommerceApiAxiosClient(env),
    DEALER_API: createDealerApiAxiosClient(env),
    SITECORE_API: createSitecoreApiAxiosClient(env),
  }
}

export default axiosClientFactory
