const YOUTUBE_EMBED_BASE_URL = 'https://www.youtube-nocookie.com'

export interface YouTubeEmbedOptions {
  videoId: string
  autoplay?: boolean
  controls?: boolean
  language?: string
  modestBranding?: boolean
  closedCaptions?: boolean
}

const convertToBitChar = (value?: boolean, defaultValue?: boolean) =>
  Number(value ?? defaultValue).toString()

export const buildEmbedUrl = ({
  videoId,
  autoplay,
  controls,
  language,
  modestBranding,
  closedCaptions,
}: YouTubeEmbedOptions): string => {
  const baseUrl = new URL(YOUTUBE_EMBED_BASE_URL)

  baseUrl.pathname = `embed/${videoId}`
  baseUrl.searchParams.append('autoplay', convertToBitChar(autoplay))
  baseUrl.searchParams.append('controls', convertToBitChar(controls, true))
  baseUrl.searchParams.append('modestbranding', convertToBitChar(modestBranding))
  baseUrl.searchParams.append('cc_load_policy', convertToBitChar(closedCaptions))

  if (language?.length) {
    baseUrl.searchParams.append('hl', language)
    baseUrl.searchParams.append('cc_lang_pref', language)
  }

  return baseUrl.toString()
}
