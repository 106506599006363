import { COLORS } from '../../constants/themeConstants'
import { alphaColor } from '../../helpers/themeHelper'
import { ThemeProps } from '../../types/themeProps'
import defaultTheme from '../defaultTheme'

const dafTheme: ThemeProps = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: COLORS.DAF_BLUE,
    gray: [...defaultTheme.colors.gray, COLORS.MEDIUM_DARK],
  },
  buttons: {
    ...defaultTheme.buttons,
    primary: {
      ...defaultTheme.buttons.primary,
      bg: COLORS.DAF_BLUE,
      border: 'none',
      ':hover, :focus, :active, &.active': {
        ...defaultTheme.buttons.primary[':hover, :focus, :active, &.active'],
        bg: COLORS.DAF_DARK_BLUE,
      },
    },
    outline: {
      ...defaultTheme.buttons.outline,
      color: COLORS.DAF_BLUE,
    },
    ghost: {
      ...defaultTheme.buttons.ghost,
      color: COLORS.MEDIUM_DARK,
      borderColor: COLORS.MEDIUM_DARK,
      ':hover, :focus, :active, &.active': {
        borderColor: COLORS.MEDIUM_GRAY,
        bg: COLORS.MEDIUM_GRAY,
        color: COLORS.DAF_BLUE,
      },
    },
  },
  mainNavigation: {
    ...defaultTheme.mainNavigation,
    background: COLORS.WHITE,
    textColor: COLORS.DARK,
    textActiveColor: COLORS.DAF_BLUE,
    parentLinkColor: COLORS.DAF_BLUE,
    parentLinkVariant: 'outline',
    searchBarBackground: COLORS.LIGHT_GRAY,
    dividerColor: COLORS.MEDIUM_GRAY,
    dividerOpacity: 1,
  },
  footer: {
    ...defaultTheme.footer,
    background: COLORS.MEDIUM_DARK,
  },
  storeSwitcher: {
    ...defaultTheme.storeSwitcher,
    background: COLORS.MEDIUM_DARK,
  },
  teaserImageBlock: {
    background: COLORS.MEDIUM_DARK,
  },
  banner: {
    backgroundColor: alphaColor(COLORS.BLACK, 0.72),
    backgroundColorMobile: COLORS.MEDIUM_DARK,
  },
  search: {
    tabBarBackgroundColor: COLORS.LIGHT_GRAY,
    titleBarBackgroundColor: COLORS.LIGHT_GRAY,
  },
}

export default dafTheme
