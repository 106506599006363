import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC } from 'react'
import { Box } from 'theme-ui'
import { RenderingProps } from '../../../types/layoutService'

interface MainNavigationCenterProps {
  rendering: RenderingProps
}

const MainNavigationCenter: FC<MainNavigationCenterProps> = ({ rendering }) => (
  <Box
    data-t-id="main-navigation-center"
    sx={{
      order: [3, 3, 3, 2],
      flexBasis: ['100%', '100%', '100%', '33.33%'],
      px: [0, 0, 0, 4],
    }}
  >
    <Placeholder name="jss-header-search" rendering={rendering} />
  </Box>
)

export default MainNavigationCenter
