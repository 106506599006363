import { ShoppingBasketWizardState } from '.'
import { CommerceOrderItem } from '../../../types/commerceApi'

const isItemInOrder = (items: CommerceOrderItem[], payload: CommerceOrderItem) => {
  if (!items) return false

  return items.find(({ partNumber }) => payload.partNumber === partNumber)
}

const overwriteOrderItem = (
  items: CommerceOrderItem[],
  payload: CommerceOrderItem
) => {
  if (!items) return []

  return items.map((item) => {
    if (item.partNumber === payload.partNumber) return payload

    return item
  })
}

const constructItemList = (
  state: ShoppingBasketWizardState,
  payload: CommerceOrderItem
) => {
  if (state.order?.items && isItemInOrder(state.order.items, payload)) {
    return overwriteOrderItem(state.order.items, payload)
  }

  return [...(state.order?.items || []), payload]
}

export default (state: ShoppingBasketWizardState, payload: CommerceOrderItem) => {
  if (!state?.order) return state

  return {
    ...state,
    order: {
      ...state.order,
      items: constructItemList(state, payload),
    },
  }
}
