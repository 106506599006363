import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import PartCrossReferencesTable from './PartCrossReferencesTable'
import { Datasource, TextField } from '../../../types/layoutService'
import { CrossReference } from '../../../types/partTypes'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'

interface PartCrossReferencesDatasource {
  title: TextField
}

interface PartCrossReferencesProps {
  crossReferences: CrossReference[]
}

const PartCrossReferences: FC<
  Datasource<PartCrossReferencesDatasource> & PartCrossReferencesProps
> = ({ crossReferences, datasource: { title }, rendering: { uid } }) => {
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)

  const Table = useMemo(
    () =>
      crossReferences &&
      !!crossReferences.length && (
        <PartCrossReferencesTable crossReferences={crossReferences} />
      ),
    [crossReferences]
  )

  if (!Table) return null

  if (!breakpointM) {
    return <PartMobileModalLink title={getTextFieldValue(title)} table={Table} />
  }

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid} mb={7}>
      {title && (
        <Text as="h2" variant="heading2" mb={4}>
          {getTextFieldValue(title)}
        </Text>
      )}

      {Table && Table}
    </AnchorMenuTarget>
  )
}

export default withData(PartCrossReferences, { showMessageWhenPageEditing: true })
