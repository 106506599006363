import React, { createContext, useContext } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import useAxiosClient from '../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from './AxiosClientProvider'
import { UserContext } from './UserProvider'
import { ActiveStoreProviderContext } from './ActiveStoreProvider'
import { DafDefaultQueryParams } from '../types/userProps'
import getLoyaltyInformation, {
  LoyaltyResult,
} from '../services/rest/ecommerce/loyalty/loyalty'

interface LoyaltyContextProps {
  loyaltyData: LoyaltyResult | undefined
  refetchLoyaltyData: () => void
}
const LoyaltyContext = createContext<LoyaltyContextProps>({
  loyaltyData: undefined,
  refetchLoyaltyData: () => null,
})

interface LoyaltyProviderProps {
  children: React.ReactNode
}

const LoyaltyProvider = ({ children }: LoyaltyProviderProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const queryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getLoyalty = async () => {
    const { token } = axios.CancelToken.source()
    const response = await getLoyaltyInformation(queryParams, client, token, user)
    return response.data
  }

  const { data: loyaltyData, refetch } = useQuery({
    queryKey: ['getLoyaltyInformation'],
    queryFn: getLoyalty,
    enabled: !!actingSupplierId,
  })

  return (
    <LoyaltyContext.Provider
      value={{ loyaltyData: loyaltyData?.result, refetchLoyaltyData: refetch }}
    >
      {children}
    </LoyaltyContext.Provider>
  )
}

export { LoyaltyContext, LoyaltyProvider }
