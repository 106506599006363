export const URL_IFRAME_SOURCE_PARAM = 'source'
export const URL_QUERY_STRING_PARAM = 'query'
export const URL_SPACE_DELIMITER = '+'
export const URL_FILTER_PARAMETER = 'filter'
export const URL_FILTER_NAME_VALUE_DELIMITER = ':'
export const URL_FILTER_VALUE_DELIMITER = ','
export const URL_FILTER_DELIMITER = ';'
export const URL_SORT = 'sort'
export const URL_SEARCH = 'search'
export const URL_SORT_DIRECTION = 'direction'
export const URL_SEARCH_TYPE = 'type'
export const URL_SEARCH_VIEW_TYPE = 'view-type'
export const URL_PAGE_PARAM = 'page'
export const URL_RETURN_URL_PARAM = 'return-url'
export const URL_DEALER_CODE_PARAM = 'dealer-code'
export const URL_VEHICLE_SEARCH_QUERY_PARAM = 'chassisNumber'
export const URL_SHOPPING_BASKET_WIZARD_STEP_PARAM = 'step'
export const URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM = 'overview'
export const URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM = 'checkout'
export const URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM = 'quotation'
export const URL_SHOPPING_BASKET_WIZARD_ORDER_NUMBER_FOR_CONFIRMATION_PAGE =
  'order-number'
export const URL_SHOPPING_BASKET_WIZARD_COMPANY_NAME_FOR_CONFIRMATION_PAGE =
  'company-name'
export const URL_SHOPPING_BASKET_WIZARD_ORDER_PARAM = 'order'
export const URL_WILDCARD_PLACEHOLDER = ',-w-,'

export const DAF_SEARCH_EMBEDDED_URL = 'embed?source=/en-GB/DafSearch?chassisNumber='

export const IS_DEALER_ADMIN_QUERY_PARAM = 'isDealerAdmin'

export const CUSTOM_URL_CHARACTER_REPLACE_MAP: Record<string, string> = {
  [URL_SPACE_DELIMITER]: '_p_', // plus +
  [URL_FILTER_NAME_VALUE_DELIMITER]: '_col_', // column :
  [URL_FILTER_VALUE_DELIMITER]: '_c_', // comma ,
  [URL_FILTER_DELIMITER]: '_s_', // semicolon ;
}
