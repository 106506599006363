import { Box, Link, Text } from 'theme-ui'
import React, { useCallback, useState } from 'react'
import i18next from 'i18next'
import { useSelector } from 'react-redux'
import { COLORS } from '../../../constants/themeConstants'
import RmDocumentList from '../../atoms/RmDocumentList/RmDocumentList'
import SidePanel from '../../organisms/SidePanel/SidePanel'
import SidePanelBody from '../../organisms/SidePanel/SidePanelBody'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { RootState } from '../../../reducers'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { FONT_AWESOME_ICONS } from '../../../constants/iconConstants'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const VehicleRmDocuments = () => {
  const { vehicleDocuments, vehicleData } = useSelector<
    RootState,
    VehicleReducerState
  >((state: RootState) => state.dafCompanyVehicle)

  const [sidePanel, setSidePanel] = useState(false)
  const onClick = useCallback(() => {
    setSidePanel(!sidePanel)
  }, [sidePanel])

  if (!vehicleData?.vehicleActions.rmDocuments.showOption) {
    return null
  }

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          paddingTop: 4,
          borderTop: '1px solid',
          borderColor: COLORS.MEDIUM_GRAY,
        }}
      >
        <Text
          sx={{
            marginTop: 0,
            fontSize: 1,
            color: COLORS.GRAY,
          }}
        >
          {i18next.t('servicePlan.downloadDocumentsDisclaimerMessage')}
        </Text>
        <ClickableWrapper
          onClick={onClick}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          <Link
            sx={{
              fontSize: 1,
              lineHeight: 2,
              float: 'left',
              color: COLORS.DAF_BLUE,
              fontWeight: 500,
              marginRight: 2,
            }}
          >
            {i18next.t('servicePlan.downloadLabel')}
          </Link>
          <IconWrapper
            icon={FONT_AWESOME_ICONS.FILE_DOCUMENT}
            color={COLORS.DAF_BLUE}
            size={2}
          />
        </ClickableWrapper>
      </Box>

      <SidePanel
        active={sidePanel}
        onClose={() => setSidePanel(false)}
        disableOutsideClick
        title={i18next.t('servicePlan.downloadsTitle')}
      >
        <SidePanelBody>
          <Text variant="bodyContent" color="gray.2">
            <RmDocumentList vehicleDocuments={vehicleDocuments} />
          </Text>
        </SidePanelBody>
      </SidePanel>
    </>
  )
}

export default VehicleRmDocuments
