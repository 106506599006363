import { DEALER_DEPARTMENT_CODE_GENERAL } from '../constants/dealerLocatorConstants'
import { Address, Dealer } from '../types/dealerServiceTypes'
import { joinStringsWithDelimiter } from './stringHelper'

export const getDisplayAddress = (address: Address | undefined) => {
  if (!address) return undefined

  return joinStringsWithDelimiter(
    [
      address?.addressLine1 || '',
      address?.addressLine2 || '',
      address?.addressLine3 || '',
      address?.city || '',
    ],
    ', '
  )
}

export const getDealerDepartmentCommunications = (data?: Dealer) =>
  data?.communications?.find(
    ({ departmentCode }) => departmentCode === DEALER_DEPARTMENT_CODE_GENERAL
  )
