import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react'
import { Flex, FlexProps } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import { History } from 'history'

export interface TabBarProps extends Omit<FlexProps, 'css'> {
  urlQueryParam?: string
  activeTabId?: string | number
  setActiveTabId?: (id: string | number) => void
}

interface TabBarContextProps extends TabBarProps {
  history: History
}

const persistActiveTabInUrl = (
  history: History,
  urlQueryParam: string,
  id: string
) => {
  const params = new URLSearchParams(window.location.search)

  params.set(urlQueryParam, id)

  return history.push(`?${params.toString()}`)
}

const renderTabs = (
  children: ReactNode,
  { urlQueryParam, history, activeTabId, setActiveTabId }: TabBarContextProps
): ReactNode =>
  Children.map(children, (child, i) => {
    if (!isValidElement(child)) return child

    return cloneElement(child as ReactElement, {
      onClick: (id?: string) => {
        if (id && urlQueryParam) persistActiveTabInUrl(history, urlQueryParam, id)

        if (child.props?.onClick) child.props.onClick()

        return setActiveTabId && setActiveTabId(id || i)
      },
      active: activeTabId === child.props.panelId || activeTabId === i,
    })
  })

const TabBar: FC<TabBarProps> = ({
  children,
  activeTabId,
  setActiveTabId,
  urlQueryParam,
  sx,
  ...flexProps
}) => {
  const history = useHistory()

  return (
    <Flex sx={sx} {...flexProps}>
      {renderTabs(children, { urlQueryParam, history, activeTabId, setActiveTabId })}
    </Flex>
  )
}

export default TabBar
