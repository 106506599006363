import React, { FC } from 'react'
import { Box, BoxProps, Flex, Text } from 'theme-ui'
import IconWrapper from '../IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import { Bullet } from '../../../types/dafResponseProps'

interface ListProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  items: Bullet[]
}

const CheckList: FC<ListProps> = ({ items, sx, ...boxProps }) => {
  if (!items?.length) return null

  return (
    <Box
      as="ul"
      sx={{
        margin: 0,
        marginLeft: -2,
        p: 0,
        ...sx,
      }}
      {...boxProps}
    >
      {items.map(({ description }, i) => (
        <Text
          key={i.toString()}
          as="li"
          variant="bodyContent"
          sx={{
            color: 'gray.2',
            listStyle: 'none',
            lineHeight: 'heading2',
            marginBottom: 1,
          }}
        >
          <Flex>
            <IconWrapper icon={ICONS.CHECK} color="primary" />

            <Box
              sx={{
                flex: 1,
                ml: [1, 1, 2],
              }}
            >
              {description}
            </Box>
          </Flex>
        </Text>
      ))}
    </Box>
  )
}
export default CheckList
