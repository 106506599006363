import React, { FC } from 'react'
import RichText from '../../atoms/RichText/RichText'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'

interface BasicTextDatasource {
  text: TextField
}

const BasicText: FC<Datasource<BasicTextDatasource>> = ({ datasource }) => (
  <RichText textField={datasource.text} />
)

export default withData(BasicText)
