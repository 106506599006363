import React, { FC, useContext } from 'react'
import { loggedIn } from '../../../helpers/userHelper'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../providers/UserProvider'
import { getDividerSx } from '../../atoms/Divider/Divider'
import DealerContactInformation from '../DealerContactInformation/DealerContactInformation'

const BasicInformationDealerContactInformation: FC = () => {
  const { activeCompany, activeSupplier } = useContext(ActiveStoreProviderContext)
  const { user } = useContext(UserContext)

  if (!loggedIn(user) || !activeCompany || !activeSupplier) return null

  return (
    <DealerContactInformation
      sx={{ position: 'relative', pt: 4, pb: [3, 3, 0], ...getDividerSx('top') }}
    />
  )
}

export default BasicInformationDealerContactInformation
