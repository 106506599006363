import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { LinkField, TextField } from '../../../types/layoutService'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Link from '../../atoms/Link/Link'

export interface NewsArticleHighlightHeadingProps {
  allLabel: string
  title: TextField
  newsOverviewPage: LinkField
}

const NewsArticleHighlightHeading: FC<NewsArticleHighlightHeadingProps> = ({
  title,
  allLabel,
  newsOverviewPage,
}) => (
  <Flex
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: [3, 3, 4],
    }}
  >
    <Text as="h2" variant="heading1" sx={{ color: 'text' }}>
      {getTextFieldValue(title)}
    </Text>

    <Flex sx={{ color: 'text', alignItems: 'center' }}>
      <Link
        href={newsOverviewPage.url}
        sx={{
          color: 'inherit',
          marginRight: 1,
          textDecoration: 'none',
          fontSize: 2,
          lineHeight: 'heading2',
          svg: { marginLeft: 1 },
        }}
      >
        {allLabel}

        <IconWrapper icon={ICONS.ARROW_RIGHT} size={4} />
      </Link>
    </Flex>
  </Flex>
)

export default NewsArticleHighlightHeading
