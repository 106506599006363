import React, { FC } from 'react'
import { useCookies } from 'react-cookie'
import { Box } from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { USER_COOKIE_CONSENT } from '../../../constants/cookieConstants'
import withData from '../../../enhancers/withData'
import CookieConsentBase, {
  CookieConsentBaseProps,
} from '../CookieConsentBase/CookieConsentBase'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import { Datasource } from '../../../types/layoutService'

const CookieConsentPopup: FC<Datasource<CookieConsentBaseProps>> = ({
  datasource,
}) => {
  const { pageEditing, pagePreviewing } = useSitecoreContext()

  const [cookie] = useCookies([USER_COOKIE_CONSENT])
  const consentCookieState = cookie?.[USER_COOKIE_CONSENT]

  if (consentCookieState || pageEditing || pagePreviewing) return null

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        bottom: 0,
        zIndex: 2,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: 'gray.1',
        backgroundColor: 'background',
        py: [4, 4, 7],
      }}
    >
      <CookieConsentBase {...datasource} />
    </Box>
  )
}

export default withClientSideRender(withData(CookieConsentPopup))
