import React, { ReactNode } from 'react'
import { Flex } from 'theme-ui'

interface SearchBarControlsWrapperProps {
  children: ReactNode
}

const SearchBarControlsWrapper = ({ children }: SearchBarControlsWrapperProps) => (
  <Flex
    sx={{
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      px: 2,
      color: 'text',
      '> *:not(:last-child)::after': {
        content: '""',
        width: '1px',
        height: '24px',
        backgroundColor: 'gray.1',
      },
    }}
  >
    {children}
  </Flex>
)

export default SearchBarControlsWrapper
