import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

const DEFAULT_IDLE_TIME = 6000 // ms

const useHoverIdle = (
  target: MutableRefObject<HTMLElement | null>,
  callback: () => void,
  idleTime: number = DEFAULT_IDLE_TIME
) => {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [idle, setIdle] = useState(true)

  const stopIdle = useCallback(() => setIdle(false), [])

  const startIdle = useCallback(() => setIdle(true), [])

  const resetTimeout = useCallback(() => {
    if (timeout.current) clearTimeout(timeout.current)
  }, [])

  useEffect(() => {
    const targetCurrent = target.current

    if (targetCurrent) {
      targetCurrent.addEventListener('mouseenter', stopIdle)
      targetCurrent.addEventListener('mouseleave', startIdle)
    }

    return () => {
      if (targetCurrent) {
        targetCurrent.removeEventListener('mouseenter', stopIdle)
        targetCurrent.removeEventListener('mouseleave', startIdle)
      }
    }
  }, [startIdle, stopIdle, target])

  useEffect(() => {
    if (idle) {
      timeout.current = setTimeout(callback, idleTime)
    } else {
      resetTimeout()
    }

    return () => resetTimeout()
  }, [callback, idle, idleTime, resetTimeout])

  return resetTimeout
}

export default useHoverIdle
