import { useTheme } from '@emotion/react'
import React, { FC, useContext } from 'react'
import { Box, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { alphaColor } from '../../../helpers/themeHelper'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { Datasource, LinkField } from '../../../types/layoutService'
import Link from '../../atoms/Link/Link'
import withData from '../../../enhancers/withData'

interface ImpersonatedProps {
  endImpersonatedMode: LinkField
}

const Impersonated: FC<Datasource<ImpersonatedProps>> = ({
  datasource: { endImpersonatedMode },
}) => {
  const { transitions } = useTheme()
  const { isImpersonated } = useContext(ActiveStoreProviderContext)

  if (!isImpersonated) return null

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
      }}
    >
      {endImpersonatedMode && (
        <Link
          data-t-id="end-impersonated-mode"
          href={endImpersonatedMode.url}
          sx={{
            paddingY: 2,
            pl: [2, 2, 2],
            pr: [3, 3, 2],
            alignItems: 'center',
            alignContent: 'right',
          }}
        >
          <Text
            as="span"
            variant="bodySmall"
            sx={{
              bg: alphaColor(COLORS.WHITE, 0.32),
              borderRadius: 16,
              color: 'textLight',
              px: 3,
              py: 1,
              textAlign: 'center',
              transition: `ease all ${transitions?.[1]}`,
              ':hover': {
                bg: alphaColor(COLORS.WHITE, 0.24),
              },
            }}
          >
            {endImpersonatedMode.text}
          </Text>
        </Link>
      )}
    </Box>
  )
}

export default withData(Impersonated)
