import React, { FC, useMemo } from 'react'
import { Flex } from 'theme-ui'
import { BREAKPOINT_L } from '../../../constants/themeConstants'
import useBreakpoints from '../../../hooks/useBreakpoints'
import PaginationPageNumber, {
  OnNavigate,
} from '../../atoms/PaginationPageNumber/PaginationPageNumber'
import PaginationSeparator from '../../atoms/PaginationSeparator/PaginationSeparator'

interface PaginationPageNumbersProps {
  totalCount: number
  currentIndex: number
  onNavigate: OnNavigate
}

interface PaginationFactoryProps {
  lastPage: number
  currentIndex: number
  onNavigate: OnNavigate
  amountOfPageNumbersToDisplay: number
}

const AMOUNT_OF_PAGE_NUMBERS_TO_DISPLAY_MOBILE = 5
const AMOUNT_OF_PAGE_NUMBERS_TO_DISPLAY_DESKTOP = 7

const PaginationFactory: FC<PaginationFactoryProps> = ({
  lastPage,
  currentIndex,
  onNavigate,
  amountOfPageNumbersToDisplay,
}) => {
  const currentPage = currentIndex + 1
  const tippingPointAmount = amountOfPageNumbersToDisplay - 2
  const tippingPointLeft = lastPage - (lastPage - currentPage)
  const tippingPointRight = lastPage - currentIndex

  if (lastPage <= amountOfPageNumbersToDisplay) {
    return (
      <>
        {[...Array(lastPage)].map((_, i) => (
          <PaginationPageNumber
            key={i.toString()}
            index={i}
            active={i === currentIndex}
            onNavigate={onNavigate}
          />
        ))}
      </>
    )
  }

  if (tippingPointLeft <= tippingPointAmount) {
    return (
      <>
        {[...Array(tippingPointAmount)].map((_, i) => (
          <PaginationPageNumber
            key={i.toString()}
            index={i}
            onNavigate={onNavigate}
            active={i === currentIndex}
          />
        ))}

        <PaginationSeparator />

        <PaginationPageNumber index={lastPage - 1} onNavigate={onNavigate} />
      </>
    )
  }

  if (tippingPointRight <= tippingPointAmount) {
    return (
      <>
        <PaginationPageNumber index={0} onNavigate={onNavigate} />

        <PaginationSeparator />

        {[...Array(tippingPointAmount)].map((_, i) => {
          const pageIndex = lastPage - (tippingPointAmount - i)

          return (
            <PaginationPageNumber
              key={pageIndex}
              index={pageIndex}
              onNavigate={onNavigate}
              active={pageIndex === currentIndex}
            />
          )
        })}
      </>
    )
  }

  if (amountOfPageNumbersToDisplay === AMOUNT_OF_PAGE_NUMBERS_TO_DISPLAY_MOBILE) {
    return (
      <>
        <PaginationPageNumber index={0} onNavigate={onNavigate} />

        <PaginationSeparator />

        <PaginationPageNumber index={currentIndex} onNavigate={onNavigate} active />

        <PaginationSeparator />

        <PaginationPageNumber index={lastPage - 1} onNavigate={onNavigate} />
      </>
    )
  }

  return (
    <>
      <PaginationPageNumber index={0} onNavigate={onNavigate} />

      <PaginationSeparator />

      <PaginationPageNumber index={currentIndex - 1} onNavigate={onNavigate} />

      <PaginationPageNumber index={currentIndex} onNavigate={onNavigate} active />

      <PaginationPageNumber index={currentIndex + 1} onNavigate={onNavigate} />

      <PaginationSeparator />

      <PaginationPageNumber index={lastPage - 1} onNavigate={onNavigate} />
    </>
  )
}

const PaginationPageNumbers: FC<PaginationPageNumbersProps> = ({
  totalCount,
  currentIndex,
  onNavigate,
}) => {
  const [breakpointL] = useBreakpoints(BREAKPOINT_L)

  const amountOfPageNumbersToDisplay = useMemo(() => {
    if (breakpointL) return AMOUNT_OF_PAGE_NUMBERS_TO_DISPLAY_DESKTOP
    if (!breakpointL) return AMOUNT_OF_PAGE_NUMBERS_TO_DISPLAY_MOBILE

    return undefined
  }, [breakpointL])

  if (typeof amountOfPageNumbersToDisplay === 'undefined') return null

  return (
    <Flex sx={{ justifyContent: 'center' }}>
      <PaginationFactory
        lastPage={totalCount}
        currentIndex={currentIndex}
        onNavigate={onNavigate}
        amountOfPageNumbersToDisplay={amountOfPageNumbersToDisplay}
      />
    </Flex>
  )
}

export default PaginationPageNumbers
