import React, { FC, useMemo } from 'react'
import { Box } from 'theme-ui'
import { LinkField, TextField } from '../../../types/layoutService'
import usePartSparePartsService, {
  SPARE_PART_DIRECTION_FROM,
} from '../../../hooks/services/graphql/usePartSparePartListService'
import PartBillOfMaterialMastersTable from './PartBillOfMaterialMastersTable'
import Spinner from '../../atoms/Spinner/Spinner'
import PartBillOfMaterialResponsiveTable from './PartBillOfMaterialResponsiveTable'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import { PartContext } from '../../../types/sitecoreContextProps'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

interface PartBillOfMaterialSparesProps {
  partsPageUri: LinkField

  spareOfTitle: TextField
  id: string
}

const PartBillOfMaterialSpares: FC<PartBillOfMaterialSparesProps> = ({
  partsPageUri,
  spareOfTitle,
  id,
}) => {
  const { part } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  const [fetching, spareParts] = usePartSparePartsService(
    part?.partNumber,
    SPARE_PART_DIRECTION_FROM
  )

  const table = useMemo(
    () => (
      <PartBillOfMaterialMastersTable
        partsPageUri={partsPageUri}
        bomMasters={spareParts}
        componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_SPARE_PARTS}
      />
    ),
    [partsPageUri, spareParts]
  )

  if (!spareParts?.length) return null

  if (fetching)
    return (
      <Box mb={4}>
        <Spinner size={3} />
      </Box>
    )

  return (
    <PartBillOfMaterialResponsiveTable title={spareOfTitle} id={id}>
      {table}
    </PartBillOfMaterialResponsiveTable>
  )
}

export default PartBillOfMaterialSpares
