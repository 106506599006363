import { Box, Text } from 'theme-ui'
import React, { useContext } from 'react'
import axios, { AxiosResponseHeaders } from 'axios'
import { useQueries, useQuery } from '@tanstack/react-query'
import i18next from 'i18next'
import SidePanelClickableSection from '../../SidePanelClickableSection/SidePanelClickableSection'
import { VehicleDetailResponse } from '../../../../../services/rest/ecommerce/dafVehicleDetail'
import { fetchDealers } from '../../../../../services/rest/dealer/dealerSearch'
import useAxiosClient from '../../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../../providers/AxiosClientProvider'
import PartnerTileList from '../../../../molecules/PartnerTileList/PartnerTileList'
import fetchDafVehicleRmDocuments, {
  DafVehicleRmDocumentsResponse,
} from '../../../../../services/rest/ecommerce/dafVehicleRmDocuments'
import fetchDafVehicleUrlRmDocument from '../../../../../services/rest/ecommerce/dafVehicleUrlRmDocument'
import {
  getFileExtension,
  getFilenameFromHeaders,
  getFiletypeFromHeaders,
  saveFile,
} from '../../../../../helpers/fileHelper'
import { UserContext } from '../../../../../providers/UserProvider'
import ClickableWrapper from '../../../../atoms/ClickableWrapper/ClickableWrapper'
import { COLORS } from '../../../../../constants/themeConstants'
import { TextAreaField } from '../../../../atoms/FormFields'
import { Dealer } from '../../../../../types/dealerServiceTypes'
import SearchServicePartners from '../../../../molecules/SearchServicePartners/SearchServicePartners'
import { MAX_NUMBER_OF_SERVICE_PARTNERS } from '../../../../../constants/multiSupportFormConstants'
import { ActiveStoreProviderContext } from '../../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../../types/userProps'

interface ExtendServicePlanFormProps {
  vehicle: VehicleDetailResponse | undefined
  onSelectedPartners: (partners: Dealer) => void
  onRemoveSelectedPartners: (partners: Dealer) => void
  partners: Dealer[]
}

const AddServicePartnersForm = ({
  vehicle,
  onSelectedPartners,
  onRemoveSelectedPartners,
  partners,
}: ExtendServicePlanFormProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const dealerClient = useAxiosClient(AxiosClientType.DealerApi)
  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const chassisNumber =
    vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber ?? ''
  const getDafVehicleRmDocuments = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafVehicleRmDocuments(
      dafVehicleDetailQueryParams,
      chassisNumber,
      client,
      token,
      user
    )
    return response.data
  }

  const { data: dafVehicleRmDocuments } = useQuery({
    queryKey: ['getDafVehicleRmDocuments'],
    queryFn: getDafVehicleRmDocuments,
    enabled: !!vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber,
  })

  const findContractDocument = (vehicleDocuments: DafVehicleRmDocumentsResponse) =>
    vehicleDocuments?.documents?.find(
      (document) => document.url.documentType === 'DealerList'
    )

  const vehicleServicePartnersDocument =
    dafVehicleRmDocuments && dafVehicleRmDocuments.result
      ? findContractDocument(dafVehicleRmDocuments.result)
      : undefined

  const getDafVehicleUrlRmDocument = async () => {
    const { token } = axios.CancelToken.source()

    if (vehicleServicePartnersDocument && vehicleServicePartnersDocument.url) {
      return fetchDafVehicleUrlRmDocument(
        vehicleServicePartnersDocument.url,
        client,
        token,
        user
      )
    }
    return undefined
  }

  const { data: dafVehicleUrlRmDocument } = useQuery({
    queryKey: ['getDafVehicleUrlRmDocument'],
    queryFn: getDafVehicleUrlRmDocument,
    enabled: !!vehicleServicePartnersDocument,
  })
  const handleDocumentClick = () => {
    if (dafVehicleUrlRmDocument) {
      if (dafVehicleUrlRmDocument && dafVehicleUrlRmDocument.status === 200) {
        const headers = dafVehicleUrlRmDocument.headers as AxiosResponseHeaders
        let fileName = 'service partners'
        fileName += `.${getFileExtension(getFilenameFromHeaders(headers, fileName))}`
        saveFile(
          dafVehicleUrlRmDocument,
          fileName,
          getFiletypeFromHeaders(headers, 'octet/stream')
        )
      }
    }
  }

  const mainServicePartner =
    vehicle?.rmContract?.servicePlan?.details?.mainServiceDealer?.locationCode
  const servicePartners =
    vehicle?.rmContract?.servicePlan?.details?.serviceDealers?.slice(0, 4) || []

  if (servicePartners && mainServicePartner)
    servicePartners.unshift({ locationCode: mainServicePartner })

  const getServicePartners = async (locationCode?: string) => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDealers(
      dealerClient,
      {
        text: locationCode ?? '',
      },
      token
    )
    return response.data
  }

  const servicePartnerQueries = useQueries({
    queries: (servicePartners ?? []).map((partner) => ({
      queryKey: [`servicePartner-${partner.locationCode}`],
      queryFn: () => getServicePartners(partner.locationCode),
    })),
  })

  const currentServicePartners = servicePartnerQueries.map((query) => {
    if (query.data && query.data.results && query.data.results.length > 0) {
      return query.data.results[0]
    }
    return null
  })

  return (
    <>
      <SidePanelClickableSection
        label={i18next.t('formLabels.currentServicePartners')}
      >
        <Box>
          <PartnerTileList partners={currentServicePartners} />
        </Box>

        {dafVehicleUrlRmDocument && dafVehicleRmDocuments?.result && (
          <Box sx={{ marginTop: 3 }}>
            <ClickableWrapper
              sx={{ fontSize: 2 }}
              onClick={() => handleDocumentClick()}
            >
              <Text
                variant="bodySmall"
                color={COLORS.DAF_BLUE}
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {i18next.t('formLabels.viewAllServicePartnersDocument')}
              </Text>
            </ClickableWrapper>
          </Box>
        )}
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={i18next.t('formLabels.selectAdditionalServicePartners')}
      >
        <Box>
          <SearchServicePartners
            id=""
            onSelectedPartners={onSelectedPartners}
            onRemoveSelectedPartners={onRemoveSelectedPartners}
            partners={partners}
            disabled={partners.length >= MAX_NUMBER_OF_SERVICE_PARTNERS}
          />
        </Box>
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={`${i18next.t('formLabels.addRemarks')} (${i18next.t(
          'formLabels.optional'
        )})`}
      >
        <TextAreaField
          required={false}
          name="addServicePartnersRemarks"
          label={i18next.t('formLabels.addRemarks')}
          placeholder={i18next.t('formLabels.typeYourRemark')}
        />
      </SidePanelClickableSection>
    </>
  )
}

export default AddServicePartnersForm
