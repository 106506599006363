import { Field } from '@sitecore-jss/sitecore-jss-react'
import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import { ICONS, VectorIcon } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'
import withData from '../../../enhancers/withData'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import { alphaColor } from '../../../helpers/themeHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Datasource, TextField } from '../../../types/layoutService'
import ColumnLayout from '../../atoms/ColumnLayout/ColumnLayout'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import RichText from '../../atoms/RichText/RichText'

export type BulletStyle = '' | 'default' | 'numbered'

interface FactSheetDatasource {
  title1: TextField
  text1: TextField
  icon1: Field<VectorIcon>
  title2: TextField
  text2: TextField
  icon2: Field<VectorIcon>
  title3: TextField
  text3: TextField
  icon3: Field<VectorIcon>
  bulletStyle: Field<BulletStyle>
}

const FactSheet: FC<Datasource<FactSheetDatasource>> = ({ datasource }) => {
  const {
    site: { theme },
  } = useSitecoreContext()
  const { space } = useTheme()
  const bulletStyle = getTextFieldValue(datasource.bulletStyle) || 'default'

  const factList = [
    {
      title: datasource.title1,
      text: datasource.text1,
      icon: datasource.icon1,
    },
    {
      title: datasource.title2,
      text: datasource.text2,
      icon: datasource.icon2,
    },
    {
      title: datasource.title3,
      text: datasource.text3,
      icon: datasource.icon3,
    },
  ]

  return (
    <ColumnLayout
      params={{
        topSpacing: '1',
        bottomSpacing: '1',
        BackgroundColor: theme.name === 'trp' ? 'Primary' : 'DafGray',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', 'repeat(3, 1fr)'],
          gap: [space[3], space[3], space[4]],
        }}
      >
        {factList.map(({ icon, title, text }, i) => (
          <Box
            key={i.toString()}
            sx={{
              backgroundColor: alphaColor(COLORS.WHITE, 0.04),
              color: 'textLight',
              padding: [3, 3, 5],
            }}
          >
            {bulletStyle !== 'numbered' ? (
              <IconWrapper
                icon={
                  ICONS?.[
                    (getTextFieldValue(icon).toUpperCase() || 'TRUCK') as VectorIcon
                  ]
                }
                size={5}
              />
            ) : (
              <Box
                sx={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                  backgroundColor: 'textLight',
                  color: theme.name === 'trp' ? 'primary' : 'gray.4',
                  textAlign: 'center',
                  lineHeight: '40px',
                  fontWeight: 'bold',
                  fontSize: 2,
                }}
              >
                {i + 1}
              </Box>
            )}

            <Text
              variant="heading1"
              sx={{ color: 'inherit', marginTop: '5', fontSize: [4, 4, 5] }}
            >
              {getTextFieldValue(title)}
            </Text>

            {getEditableTextFieldValue(text) && (
              <Text
                variant="bodyContent"
                sx={{ marginTop: 2, p: { color: 'inherit' } }}
              >
                <RichText textField={text} />
              </Text>
            )}
          </Box>
        ))}
      </Box>
    </ColumnLayout>
  )
}
export default withData(FactSheet, { checkDatasource: true })
