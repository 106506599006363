import React, { FC, useContext, useMemo } from 'react'
import i18next from 'i18next'
import PartListSalesInformationProvider from '../../../providers/PartListSalesInformationProvider'
import { loggedIn } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import { LinkField } from '../../../types/layoutService'
import { DEFAULT_PART_QUANTITY } from '../../../constants/partSalesInformationConstants'
import AssemblyBillOfMaterialPart from './AssemblyBillOfMaterialPart'
import { FulfilledBomPart } from './AssemblyBillOfMaterial'
import { PartNumberQuantityInput } from '../../../hooks/services/graphql/usePartSalesInformationService'
import PartLine from '../../molecules/PartLine/PartLine'
import PartLineTitle from '../../molecules/PartLine/PartLineTitle'

export interface AssemblyBillOfMaterialTableProps {
  fulfilledBomParts: FulfilledBomPart[]
  partsPageUri: LinkField
}

export const gridTemplate = {
  columnSizes: ['1fr', '1fr', '0.5fr 1fr 2fr 1fr'], // css prop: grid-template-columns
  columnSizesLoggedIn: ['1fr', '1fr', '0.5fr 1fr 2fr 1fr 1fr 2fr'], // css prop: grid-template-columns
}

const AssemblyBillOfMaterialTable: FC<AssemblyBillOfMaterialTableProps> = ({
  fulfilledBomParts,
  partsPageUri,
}) => {
  const { user } = useContext(UserContext)

  const partNumberQuantityInputs = useMemo<PartNumberQuantityInput[]>(
    () =>
      fulfilledBomParts
        .map(({ bomPart }) => ({
          partNumber: bomPart.partNumber,
          quantity: DEFAULT_PART_QUANTITY,
        }))
        .filter(({ partNumber }) => partNumber),
    [fulfilledBomParts]
  )

  if (!fulfilledBomParts?.length) return null

  return (
    <PartListSalesInformationProvider
      partNumbersQuantity={partNumberQuantityInputs}
      includePrice
      includeStock
    >
      <PartLine
        gridTemplate={gridTemplate}
        py={3}
        sx={{
          display: ['none', 'none', 'grid'],
        }}
      >
        <PartLineTitle>{i18next.t('commonLabels.numberAbbreviation')}</PartLineTitle>

        <PartLineTitle>{i18next.t('commonLabels.product')}</PartLineTitle>

        <PartLineTitle />

        <PartLineTitle>{i18next.t('commonLabels.brand')}</PartLineTitle>

        {loggedIn(user) && (
          <PartLineTitle>{i18next.t('stockLabels.availability')}</PartLineTitle>
        )}

        {loggedIn(user) && (
          <PartLineTitle>{i18next.t('partSalesLabels.price')}</PartLineTitle>
        )}
      </PartLine>

      {fulfilledBomParts.map((fulfilledBomPart, i) => (
        <AssemblyBillOfMaterialPart
          key={i.toString()}
          fulfilledBomPart={fulfilledBomPart}
          partsPageUri={partsPageUri}
          gridTemplate={gridTemplate}
        />
      ))}
    </PartListSalesInformationProvider>
  )
}

export default AssemblyBillOfMaterialTable
