import React, { FC } from 'react'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import Container from '../../atoms/Container/Container'
import Divider from '../../atoms/Divider/Divider'
import FindNearestDealerForm from '../../molecules/FindNearestDealerForm/FindNearestDealerForm'

interface FindNearestDealerDatasource {
  title: TextField
  dealerLocatorPage: LinkField
  inputPlaceholder: TextField
}

interface FindNearestDealerProps {
  dealerLocatorPage: LinkField
}

const FindNearestDealer: FC<
  FindNearestDealerProps & Datasource<FindNearestDealerDatasource>
> = ({ datasource: { title, inputPlaceholder }, dealerLocatorPage }) => (
  <Box bg="primary">
    <Container
      sx={{
        display: 'flex',
        bg: 'primary',
        flexDirection: 'column',
        alignItems: 'center',
        py: '5',
        color: 'textLight',
      }}
    >
      <FindNearestDealerForm
        title={title}
        dealerLocatorPage={dealerLocatorPage}
        inputPlaceholder={inputPlaceholder}
      />
    </Container>

    <Divider color="background" opacity="0.16" />
  </Box>
)

export default withData(FindNearestDealer)
