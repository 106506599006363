import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'

export interface DafVehicleUpcomingJobsRequest {
  markedAsComplete: boolean
  chassisNumber: string | undefined
  jobCode: string
  dueDate: string
}

interface DafVehicleUpcomingJobsResponse {}

const postDafVehicleUpcomingJobs = (
  props: Partial<DafVehicleUpcomingJobsRequest>,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<DafVehicleUpcomingJobsResponse>>> =>
  client.post('/daf-vehicle-upcoming-job', props, {
    cancelToken,
    params: queryParams,
    ...createRequestConfig(user),
  })

export default postDafVehicleUpcomingJobs
