import { ShoppingBasketWizardState } from '.'

const removeItemsFromList = (
  state: ShoppingBasketWizardState,
  payload: string[]
) => {
  if (state.order?.items) {
    return state.order.items.filter(
      ({ partNumber }) => !payload.includes(partNumber)
    )
  }

  return state.order?.items || []
}

export default (state: ShoppingBasketWizardState, payload: string[]) => {
  if (!state?.order) return state

  return {
    ...state,
    order: {
      ...state.order,
      items: removeItemsFromList(state, payload),
    },
  }
}
