import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../providers/UserProvider'
import executeAutoSuggestions, {
  AutoSuggestionsData,
} from '../../../../services/rest/ecommerce/autoSuggestions'
import usePrevious from '../../../usePrevious'
import useSitecoreContext from '../../../useSitecoreContext'
import useAxiosClient from '../core/useAxiosClient'

const DEBOUNCE_TIME = 200 // time im ms
const MINIMUM_QUERY_LENGTH = 3

const useAutoSuggestionService = (query: string) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const {
    languageContext: { countryCode, cultureCode },
  } = useSitecoreContext()
  const [autoSuggestions, setAutoSuggestions] = useState<
    AutoSuggestionsData | undefined
  >(undefined)

  const previousQuery = usePrevious(query)

  const getAutoSuggestions = useCallback(
    async (cancelToken: CancelToken) => {
      await executeAutoSuggestions(
        client,
        query,
        cancelToken,
        cultureCode,
        countryCode,
        user
      )
        .then((res) => {
          if (res?.data) {
            setAutoSuggestions(res?.data)
          }
        })
        .catch((error) => {
          const err = error as AxiosError<AutoSuggestionsData>

          if (!axios.isCancel(err)) {
            console.error('Unable to fetch auto suggestion', err)
          }
        })
    },

    [countryCode, cultureCode, query, user]
  )

  useEffect(() => {
    if (query === previousQuery) return
    if (query.length <= MINIMUM_QUERY_LENGTH) {
      setAutoSuggestions(undefined)

      return
    }

    const { token, cancel } = axios.CancelToken.source()

    const debounceAutosuggest = setTimeout(() => {
      getAutoSuggestions(token)
    }, DEBOUNCE_TIME)

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounceAutosuggest)
      cancel()
    }
  }, [getAutoSuggestions, previousQuery, query])

  return autoSuggestions
}

export default useAutoSuggestionService
