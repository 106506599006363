import { AxiosInstance, AxiosResponse } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { UserProps } from '../../../types/userProps'

export interface Recommendation {
  partNumber: string
  score: number
}

export const fetchRecommendations = (
  client: AxiosInstance,
  partNumbers: string[],
  recommendationCount: number,
  customerCompanyId: number,
  supplierCompanyId: number,
  user: UserProps,
  isImpersonated?: boolean
): Promise<AxiosResponse<Recommendation[]>> =>
  client.post(
    '/recommendations',
    {
      partNumbers,
      recommendationCount,
      customerCompanyId,
      supplierCompanyId,
      isImpersonated: !!isImpersonated,
    },
    {
      ...createRequestConfig(user),
    }
  )
