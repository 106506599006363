import React, { FC, useContext, useMemo } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import { loggedIn } from '../../../helpers/userHelper'
import { CommerceBillOfMaterialPart } from '../../../types/commerceApi'
import { LinkField } from '../../../types/layoutService'
import PartSalesInformation from '../../molecules/SalesInformation/PartSalesInformation'
import { UserContext } from '../../../providers/UserProvider'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartLineBasicInformation from '../../molecules/PartLine/PartLineBasicInformation'
import PartLine, { PartLineGridTemplate } from '../../molecules/PartLine/PartLine'
import PartLineImage from '../../molecules/PartLine/PartLineImage'
import PartLineProductImage from '../../molecules/PartLine/PartLineProductImage'
import { getCommercePartUri } from '../../../helpers/commerceApiHelpers'
import PartLineStockInformation from '../../molecules/PartLine/PartLineStockInformation'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'
import PartLineAnchor from '../../molecules/PartLine/PartLineAnchor'

interface PartBillOfMaterialPartProps {
  bomPart: CommerceBillOfMaterialPart
  partsPageUri: LinkField
  showDrawingReferenceColumn?: boolean
  englishDescription?: string
  onClick: () => void
  gridTemplate?: PartLineGridTemplate
}

const PartBillOfMaterialPart: FC<PartBillOfMaterialPartProps> = ({
  bomPart,
  partsPageUri,
  showDrawingReferenceColumn = false,
  englishDescription,
  gridTemplate,
  onClick,
}) => {
  const { user } = useContext(UserContext)

  const href = useMemo(
    () =>
      bomPart.partNumber
        ? `${partsPageUri.url}/${getCommercePartUri(
            bomPart.category?.mainCategoryId,
            bomPart.category?.subCategoryId,
            bomPart.partNumber
          )}`
        : undefined,
    [bomPart.category, bomPart.partNumber, partsPageUri.url]
  )

  return (
    <PartSalesInformationProvider partNumber={bomPart?.partNumber}>
      <PartLine gridTemplate={gridTemplate}>
        {showDrawingReferenceColumn && <div>{bomPart.drawingReference}</div>}

        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineProductImage
            partNumber={bomPart.partNumber || ''}
            src={bomPart?.images?.[0]?.thumbnailImageUrl}
            alt={bomPart.description}
          />
        </PartLineAnchor>

        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineBasicInformation
            description={bomPart.description}
            partNumber={bomPart.partNumber || ''}
            displayPartNumber={bomPart.displayPartNumber || bomPart.partNumber}
          />
        </PartLineAnchor>

        {bomPart.quantity && (
          <PartLineAnchor
            href={href}
            onClick={onClick}
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              mt: -3,
              mb: -3,
            }}
          >
            <Text
              as="span"
              variant="bodySmall"
              sx={{
                mr: 2,
                lineHeight: 'heading3',
                color: 'gray.3',
                display: ['block', 'block', 'none'],
              }}
            >
              {i18next.t('commonLabels.quantity')}
            </Text>

            <Text
              as="span"
              variant="bodySmall"
              sx={{
                lineHeight: 'menuList',
                display: 'inline-block',
                color: 'gray.2',
                width: ['auto', 'auto', '100%'],
              }}
            >
              {bomPart.quantity}
            </Text>
          </PartLineAnchor>
        )}

        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineImage
            src={bomPart?.brand?.image?.thumbnailImageUrl}
            alt={bomPart?.brand?.description}
          />
        </PartLineAnchor>

        {loggedIn(user) && (
          <PartLineStockInformation
            partNumber={bomPart.partNumber}
            displayPartNumber={bomPart.displayPartNumber}
            componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_BOM_PART}
            categoryId={bomPart?.category?.mainCategoryId}
            subcategoryId={bomPart?.category?.subCategoryId}
          />
        )}

        {loggedIn(user) && (
          <PartSalesInformation
            partNumber={bomPart.partNumber}
            partEnglishDescription={englishDescription}
            brandName={bomPart?.brand?.description}
            categoryId={bomPart?.category?.mainCategoryId}
            subcategoryId={bomPart?.category?.subCategoryId}
            dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_BOM_PART}
            packageUnitQuantity={
              bomPart.specification?.packaging?.packageUnitQuantity
            }
          />
        )}
      </PartLine>
    </PartSalesInformationProvider>
  )
}

export default PartBillOfMaterialPart
