import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { DEFAULT_SLIDER_PER_PAGE } from '../../../../constants/sliderConstants'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import {
  fetchRecommendations,
  Recommendation,
} from '../../../../services/rest/ecommerce/recommendations'
import useSitecoreContext from '../../../useSitecoreContext'
import useAxiosClient from '../core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'

export const useRecommendedPartService = (
  partNumbers?: string[],
  partsPerPage?: number
): [boolean, string[] | undefined] => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const {
    paginationSettings: { sliderPartsPerPage },
  } = useSitecoreContext()
  const [recommendedPartNumbers, setRecommendedPartNumbers] = useState<
    string[] | undefined
  >(undefined)
  const [fetching, setFetching] = useState<boolean>(false)

  useEffect(() => {
    if (
      partNumbers &&
      !!partNumbers.length &&
      user &&
      actingCompanyId &&
      actingSupplierId
    ) {
      const getParts = async () => {
        setFetching(true)
        try {
          const { data } = await fetchRecommendations(
            client,
            partNumbers,
            partsPerPage || sliderPartsPerPage || DEFAULT_SLIDER_PER_PAGE,
            actingCompanyId,
            actingSupplierId,
            user,
            isImpersonated
          )
          if (data && !!data.length) {
            setRecommendedPartNumbers(
              data
                .sort((a, b) => a.score - b.score)
                .flatMap(({ partNumber }) => partNumber)
            )
          }
        } catch (error) {
          const err = error as AxiosError<Recommendation[]>

          appInsights.trackException({
            exception: new Error(
              `Unable to fetch recommended parts: ${err.message} (${err.code})`
            ),
            severityLevel: SeverityLevel.Error,
            properties: {
              partNumbers,
              sliderPartsPerPage,
              actingCompanyId,
              actingSupplierId,
              isImpersonated,
            },
          })
        } finally {
          setFetching(false)
        }
      }
      getParts()
    }
  }, [
    user,
    actingCompanyId,
    actingSupplierId,
    partNumbers,
    partsPerPage,
    isImpersonated,
    sliderPartsPerPage,
    appInsights,
  ])

  return [fetching, recommendedPartNumbers]
}
