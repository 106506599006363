import React, { FC, useMemo } from 'react'
import { Flex, FlexProps } from 'theme-ui'
import Tag from '../../atoms/Tag/Tag'
import { getPromotionLabel, getPromotions } from '../../../helpers/promotionHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { PROMOTION_TYPE_MAX_COUPON } from '../../../constants/promotionConstants'
import { DEFAULT_CULTURE_CODE } from '../../../constants/userConstants'
import PromotionTagWithSidePanel from '../../atoms/PromotionTagWithSidePanel/PromotionTagWithSidePanel'
import SpecialPriceTagWithSidePanel from '../../atoms/SpecialPriceTagWithSidePanel/SpecialPriceTagWithSidePanel'
import { Price } from '../../../hooks/services/graphql/usePartSalesInformationService'

interface PartPromotionProps extends Omit<FlexProps, 'css'> {
  price?: Price
}

export const PartPromotions: FC<PartPromotionProps> = ({ price, sx }) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const promotions = useMemo(
    () => (price && getPromotions(cultureCode ?? DEFAULT_CULTURE_CODE, price)) || [],
    [cultureCode, price]
  )

  if (!price || (!promotions.length && !price?.specialPrice)) return null

  return (
    <Flex sx={{ flexWrap: 'wrap', ...sx }}>
      {price?.specialPrice && <SpecialPriceTagWithSidePanel price={price} />}
      {promotions.map((promotion) => {
        if (promotion.type === PROMOTION_TYPE_MAX_COUPON) {
          return (
            <Tag
              key={promotion.type}
              data-t-id={`part-${promotion.type}-tag`}
              label={getPromotionLabel(promotion)}
            />
          )
        }

        return (
          <PromotionTagWithSidePanel
            key={promotion.type}
            data-t-id={`part-${promotion.type}-tag`}
            price={price}
            promotion={promotion}
          />
        )
      })}
    </Flex>
  )
}

export default PartPromotions
