import React, { FC, useContext, useEffect, useState } from 'react'
import { BoxProps } from 'theme-ui'
import { COMPANY_TYPE_CODE_DEALER } from '../../../constants/userConstants'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import {
  getDealerDepartmentCommunications,
  getDisplayAddress,
} from '../../../helpers/dealerHelper'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { Communication } from '../../../types/dealerServiceTypes'
import DealerContactInformationBase, {
  DealerContactInformationVariant,
} from './DealerContactInformationBase'

interface DealerContactInformationProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  variant?: DealerContactInformationVariant
}

const DealerContactInformation: FC<DealerContactInformationProps> = ({
  variant = 'full',
  ...boxProps
}) => {
  const { activeSupplierInformation, activeSupplier } = useContext(
    ActiveStoreProviderContext
  )
  const [communications, setCommunications] = useState<Communication | undefined>(
    undefined
  )
  useEffect(() => {
    if (activeSupplierInformation) {
      setCommunications(getDealerDepartmentCommunications(activeSupplierInformation))
    }
  }, [activeSupplierInformation])

  if (
    activeSupplier?.companyTypeCode !== COMPANY_TYPE_CODE_DEALER ||
    !activeSupplierInformation
  )
    return null

  return (
    <DealerContactInformationBase
      variant={variant}
      name={activeSupplierInformation?.name || undefined}
      address={getDisplayAddress(activeSupplierInformation?.address)}
      phone={communications?.phone || undefined}
      email={communications?.email || undefined}
      {...boxProps}
    />
  )
}

export default withClientSideRender(DealerContactInformation)
