import { FormInputSelectOptionProps } from '../components/atoms/FormControls/FormInputSelect'
import { RequestAccountFormData } from '../components/organisms/RequestAccount/RequestAccount'
import {
  FORM_VALIDATION_RULE_MAPPINGS,
  FORM_PROPERTY_MAPPINGS,
  REQUEST_ACCOUNT_DEALER_COUNTRIES,
} from '../constants/requestAccountFormConstants'
import {
  RequestAccountRequestData,
  RequestAccountResponseError,
} from '../services/rest/sitecore/account'
import { FormErrorFormatter } from '../types/formProps'

export const mapProperty = (propertyName: string): string | undefined =>
  FORM_PROPERTY_MAPPINGS[propertyName]

export const mapValidationRule = (ruleName: string): string | undefined =>
  FORM_VALIDATION_RULE_MAPPINGS[ruleName]

/**
 * This method maps form field names and validation rule names from source to target.
 *
 * We need to map these because:
 * 1. The API returns unusual validation rule names like 'MinMaxLength', we prefer something like 'between'
 * 2. The API returns validation errors for hidden fields, we want to map these errors to a field that's visible for the user
 *
 * Reminder: This is NOT a generic mapper.
 */
export const mapResponseErrorsToFormErrors = (
  errors: RequestAccountResponseError[],
  errorFormatter: FormErrorFormatter
): Record<string, string> =>
  errors
    .map(({ args, name, rule }) => ({
      args,
      name: mapProperty(name) ?? name,
      rule: mapValidationRule(rule) ?? rule,
    }))
    // Convert the error array to a dictionary
    .reduce(
      (formErrors, { args, name, rule }) => ({
        ...formErrors,
        [name]: errorFormatter(rule, args), // Format the error message using the provided error formatter
      }),
      {}
    )

/**
 * Converts the flat form data to a nested data structure accepted by the request account endpoint.
 */
export const mapFormDataToRequestData = (
  formData: RequestAccountFormData,
  partDetailUrl: string
): RequestAccountRequestData => ({
  customerCompany: {
    name: formData.customerCompanyName ?? '',
    vatNumber: formData.vatNumber ?? '',
    chamberOfCommerceNumber: formData.chamberOfCommerceNumber ?? '',
    city: formData.customerCompanyCity ?? '',
    postalCode: formData.customerCompanyPostalCode ?? '',
    address: formData.customerCompanyAddress ?? '',
    countryCode: formData.customerCompanyCountryCode ?? '',
  },
  person: {
    firstName: formData.personFirstName ?? '',
    lastName: formData.personLastName ?? '',
    email: formData.personEmail ?? '',
    phoneNumber: formData.personPhoneNumber ?? '',
  },
  dealerCompany: {
    name: '', // NOTE: This property is intentionally left blank
    addressLatitude: '', // NOTE: This property is intentionally left blank
    addressLongitude: '', // NOTE: This property is intentionally left blank
    locationCode: formData.dealerCompanyLocationCode ?? '', // NOTE: This property is intentionally left blank
  },
  returnInformation: {
    partDetailUrl,
  },
})

export const getCountryOptions = (): FormInputSelectOptionProps[] =>
  REQUEST_ACCOUNT_DEALER_COUNTRIES.map(({ CODE, NAME }) => ({
    value: CODE,
    text: NAME,
  }))
