import { Box, Text } from 'theme-ui'
import React from 'react'
import { COLORS } from '../../../constants/themeConstants'
import Button, { ButtonProps } from '../Button/Button'

interface BannerProps {
  heading: string
  cta?: ButtonProps
  children: React.ReactNode
}

const Banner = ({ heading, cta, children }: BannerProps) => (
  <Box
    sx={{
      backgroundColor: COLORS.DAF_DARK_BLUE,
      paddingBlock: '1.5rem',
      paddingInline: ['1.5rem', '2.5rem'],
      color: 'white',
      borderRadius: '0.25rem',
      marginBlockEnd: '2.5rem',
      '> * + *': {
        marginBlockStart: '1rem',
      },
    }}
  >
    <Box
      sx={{
        display: [null, 'flex'],
        gap: 3,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Text variant="heading2">{heading}</Text>
        {children}
      </Box>
      {cta && (
        <Box sx={{ marginTop: [3, 3, 0] }}>
          <Button {...cta} variant="outline" />
        </Box>
      )}
    </Box>
  </Box>
)

export default Banner
