import React, { Children, ReactNode, useMemo } from 'react'
import { Box, Flex } from 'theme-ui'
import useSlidesPerPageResponsive from '../../../hooks/slider/useSlidesPerPageResponsive'
import useSlider, { SlidesPerPage } from '../../../hooks/slider/useSlider'
import Slider from '../Slider/Slider'
import ContentSliderHeader from './ContentSliderHeader'
import Spinner from '../../atoms/Spinner/Spinner'
import { COLUMN_LAYOUT_Y_SPACING } from '../../atoms/ColumnLayout/ColumnLayout'

interface ContentSliderProps {
  title?: string
  slidesPerPage: SlidesPerPage
  fetching?: boolean
  topSpacing?: boolean
  bottomSpacing?: boolean
  dataTId?: string
  children: ReactNode
}

const ContentSlider = ({
  title,
  slidesPerPage,
  fetching,
  children,
  topSpacing,
  bottomSpacing,
  dataTId,
}: ContentSliderProps) => {
  const slidesPerPageResponsive = useSlidesPerPageResponsive(slidesPerPage)

  const settings = useMemo(
    () => ({
      slidesPerPage: slidesPerPageResponsive,
      slideCount: Children.count(children),
      slideGap: 24,
    }),
    [children, slidesPerPageResponsive]
  )

  const slider = useSlider(settings)

  if (fetching) {
    return (
      <Flex py={[2, 2, 3]} sx={{ width: '100%', justifyContent: 'center' }}>
        <Spinner size={4} />
      </Flex>
    )
  }

  return (
    <Box
      data-t-id={dataTId ?? undefined}
      sx={{
        paddingTop: topSpacing ? COLUMN_LAYOUT_Y_SPACING : undefined,
        paddingBottom: bottomSpacing ? COLUMN_LAYOUT_Y_SPACING : undefined,
      }}
    >
      <ContentSliderHeader title={title} slider={slider} />

      <Slider slider={slider}>{children}</Slider>
    </Box>
  )
}

export default ContentSlider
