import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USER_ROLES } from '../../../constants/userConstants'
import { hasRole } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { registerModalNotification } from '../../../actions/notificationActions'
import { CommerceMessage, CommerceOrderItem } from '../../../types/commerceApi'
import { refetchShoppingBasketSidepanelSummary } from '../../../actions/shoppingBasket/sidePanelActions'
import { ActiveUserStateContext } from '../../../providers/ActiveUserStateProvider'
import useCommerceApiResponse from '../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import AddToBasketQuantitySpinner from './AddToBasketQuantitySpinner/AddToBasketQuantitySpinner'
import { addOrReplaceOrderItem } from '../../../actions/shoppingBasket/wizard/addOrReplaceOrderItem'
import usePrevious from '../../../hooks/usePrevious'
import { DEFAULT_STEP_SIZE } from './AddToBasket'
import useCommercePostOrderItemService from '../../../hooks/services/usePostOrderItemService'
import {
  createOrderMutation,
  markOrderMutationFinished,
} from '../../../actions/shoppingBasket/wizard'
import { getQuantityDelta } from '../../../helpers/priceHelper'
import { RootState } from '../../../reducers'

interface AddToBasketDeltaProps {
  partNumber?: string
  stepSize?: number
  nullable?: boolean
  onOrder?: (quantity: number) => void
  initialQuantity?: number
  pageType: string
  callback?: (orderItem: CommerceOrderItem) => void
}

const AddToBasketDelta: FC<AddToBasketDeltaProps> = ({
  partNumber,
  stepSize = DEFAULT_STEP_SIZE,
  nullable = false,
  onOrder,
  initialQuantity,
  pageType,
  callback,
}) => {
  const { user } = useContext(UserContext)
  const dispatch = useDispatch()
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { orderNumber } = useContext(ActiveUserStateContext)
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const [displayQuantity, setDisplayQuantity] = useState<number>(
    typeof initialQuantity === 'number' ? initialQuantity : stepSize
  )
  const previousDisplayQuantity =
    usePrevious(displayQuantity) || initialQuantity || stepSize

  const onResult = useCallback(
    (orderItem: CommerceOrderItem | null) => {
      if (!orderItem) return

      dispatch(addOrReplaceOrderItem(orderItem))
      dispatch(refetchShoppingBasketSidepanelSummary(true))
      dispatch(markOrderMutationFinished())

      if (callback) callback(orderItem)
    },
    [dispatch, callback]
  )

  const onMessage = useCallback(
    (commerceMessage: CommerceMessage) => {
      setDisplayQuantity(previousDisplayQuantity)

      if (
        commerceMessage.message &&
        (commerceMessage.severity === 'Error' || commerceMessage.code === 'noPrice')
      ) {
        dispatch(registerModalNotification('Error', commerceMessage.message))
      }
    },
    [dispatch, previousDisplayQuantity]
  )

  const [posting, response, postToShoppingBasket] = useCommercePostOrderItemService()

  const saveNewQuantity = useCallback(
    async (newQuantity: number) => {
      if (!partNumber) return

      const computedQuantity = getQuantityDelta(displayQuantity, newQuantity)

      setDisplayQuantity(newQuantity)

      dispatch(createOrderMutation())

      await postToShoppingBasket({
        orderNumber,
        items: [{ partNumber, quantity: computedQuantity }],
      })

      if (onOrder) onOrder(computedQuantity)
    },

    [
      displayQuantity,
      dispatch,
      onOrder,
      orderNumber,
      partNumber,
      postToShoppingBasket,
    ]
  )

  useCommerceApiResponse<CommerceOrderItem>({
    response,
    onResult,
    onMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => (results as CommerceOrderItem[])?.[0],
  })

  useEffect(() => {
    if (typeof initialQuantity === 'number') setDisplayQuantity(initialQuantity)
  }, [initialQuantity])

  if (
    !partNumber ||
    (!hasRole(user, USER_ROLES.commerceShoppingBasket) && !isImpersonated)
  )
    return null

  return (
    <AddToBasketQuantitySpinner
      posting={posting}
      variant="small"
      stepSize={stepSize}
      nullable={nullable}
      initialQuantity={displayQuantity}
      onChange={saveNewQuantity}
      pageType={pageType}
      readOnly={!order?.isEditAllowed}
    />
  )
}

export default AddToBasketDelta
