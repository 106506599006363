import React, { createContext, ReactNode, useCallback } from 'react'
import { DEFAULT_CULTURE_CODE } from '../constants/userConstants'
import { upperCaseFirst } from '../helpers/stringHelper'
import useSitecoreContext from '../hooks/useSitecoreContext'

export interface DateFormatter {
  (
    date?: Date,
    options?: Intl.DateTimeFormatOptions,
    forceCapitalization?: boolean
  ): string | undefined
}

export interface LocalizationContextProps {
  dateFormatter: DateFormatter
}

interface LocalizationProviderProps {
  children: ReactNode
}

export const LocalizationContext = createContext<LocalizationContextProps>({
  dateFormatter: () => undefined,
})

const LocalizationProvider = ({ children }: LocalizationProviderProps) => {
  const { languageContext } = useSitecoreContext() || {}

  const dateFormatter = useCallback(
    (
      date?: Date,
      options?: Intl.DateTimeFormatOptions,
      forceCapitalization?: boolean
    ): string | undefined => {
      const result = date?.toLocaleDateString(
        languageContext?.cultureCode ?? DEFAULT_CULTURE_CODE,
        options
      )

      return result && forceCapitalization ? upperCaseFirst(result) : result
    },
    [languageContext]
  )

  return (
    <LocalizationContext.Provider value={{ dateFormatter }}>
      {children}
    </LocalizationContext.Provider>
  )
}

export default LocalizationProvider
