import i18next from 'i18next'
import React, { FC, useMemo } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { DEALER_LOCATOR_FILTER_TYPES } from '../../../constants/dealerLocatorConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Dealer } from '../../../types/dealerServiceTypes'
import { StaticDealerFilter } from '../../../types/layoutService'
import Tag from '../../atoms/Tag/Tag'

interface DealerLocatorDealerServicesProps extends Omit<BoxProps, 'css'> {
  dealer: Dealer
  staticDealerFilters?: StaticDealerFilter[]
}

export const getServiceTranslation = (type: string, value: string) => {
  const dictionaryItem = `dealerLabels.serviceLabels.${
    type.charAt(0).toLowerCase() + type.slice(1)
  }s.${value.toLowerCase()}`

  if (i18next.exists(dictionaryItem)) {
    return i18next.t(dictionaryItem)
  }

  return undefined
}

const getDisplayServices = (dealer: Dealer, includeLoyalty = true) => {
  const serviceList = []

  if (dealer.businesses?.length) {
    dealer.businesses.forEach(({ businessCode }) => {
      if (businessCode) {
        const service = getServiceTranslation(
          DEALER_LOCATOR_FILTER_TYPES.BUSINESS_CODE,
          businessCode
        )

        if (service) serviceList.push(service)
      }
    })
  }

  if (dealer.categoryCode) {
    const service = getServiceTranslation(
      DEALER_LOCATOR_FILTER_TYPES.CATEGORY_CODE,
      dealer.categoryCode
    )

    if (service) serviceList.push(service)
  }

  if (includeLoyalty && dealer.participatesInLoyaltyProgram === true) {
    serviceList.push(i18next.t('dealerLabels.serviceLabels.nextProgram'))
  }

  return serviceList
}

const DealerLocatorDealerServices: FC<DealerLocatorDealerServicesProps> = ({
  dealer,
  staticDealerFilters,
  sx,
  ...props
}) => {
  const includeLoyaltyServiceChip = useMemo(
    () =>
      !!staticDealerFilters?.find(
        ({ filterType }) =>
          getTextFieldValue(filterType) ===
          'ExcludeDealerWithoutLoyaltyParticipation'
      ),
    [staticDealerFilters]
  )

  const displayServices = useMemo(
    () => getDisplayServices(dealer, includeLoyaltyServiceChip),
    [dealer, includeLoyaltyServiceChip]
  )

  if (!displayServices?.length) return null

  return (
    <Box sx={sx} {...props}>
      {displayServices.map((service, i) => (
        <Tag
          key={i.toString()}
          label={service}
          sx={{
            color: 'gray.3',
            backgroundColor: 'gray.0',
          }}
        />
      ))}
    </Box>
  )
}

export default DealerLocatorDealerServices
