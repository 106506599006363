import React, { ReactNode, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { DATA_LAYER } from '../../../../../../constants/dataLayerConstants'
import { CATEGORY_FILTER_ID } from '../../../../../../constants/searchConstants'
import { URL_PAGE_PARAM } from '../../../../../../constants/urlConstants'
import { pushToDataLayer } from '../../../../../../helpers/analyticsHelper'
import { getFilterStringByActiveSearchResultFilterObject } from '../../../../../../helpers/filterHelper'
import { Category } from '../../../../../../hooks/services/graphql/useCategoryListService'
import { PartSearchResultContext } from '../../../../../../providers/PartSearchResultContextProvider'
import ClickableWrapper from '../../../../../atoms/ClickableWrapper/ClickableWrapper'

interface PartSearchControlsSubcategoryListItemProps {
  categoryTree: Category[]
  callback?: () => void
  children: ReactNode
}

const PartSearchControlsSubcategoryListItem = ({
  categoryTree,
  callback,
  children,
}: PartSearchControlsSubcategoryListItemProps) => {
  const history = useHistory()

  const { activeFilters } = useContext(PartSearchResultContext)

  const onClick = useCallback(() => {
    const categoryFilter = categoryTree[categoryTree.length - 1].id
    const newFilterObject = {
      ...activeFilters,
      ...(categoryFilter ? { [CATEGORY_FILTER_ID]: [categoryFilter] } : {}),
    }

    const params = new URLSearchParams(history.location.search)

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
      navigate_type: DATA_LAYER.CUSTOM_DIMENSION.NAVIGATION_TYPE.IN_PAGE,
      main_category: categoryTree[0]?.englishDescription || '',
      sub_category: categoryTree[1]?.englishDescription || '',
      filter_name: '',
      filter_value: '',
      page_type: '',
    })

    params.delete(URL_PAGE_PARAM)

    history.push(
      `?${getFilterStringByActiveSearchResultFilterObject(
        newFilterObject,
        params,
        false,
        false
      )}`
    )

    if (callback) callback()
  }, [activeFilters, categoryTree, history, callback])

  return (
    <ClickableWrapper
      onClick={onClick}
      sx={{
        display: 'block',
        width: '100%',
        color: 'gray.2',
        outline: 'none',
        borderBottomWidth: ['1px', '1px', 0],
        borderBottomStyle: 'solid',
        borderBottomColor: 'gray.1',
        px: [3, 3, 0],
        ':hover, :active, :focus': {
          color: 'primary',
        },
      }}
    >
      {children}
    </ClickableWrapper>
  )
}

export default PartSearchControlsSubcategoryListItem
