import {
  CONSENT_TYPE_ANALYTICAL,
  CONSENT_TYPE_FUNCTIONAL,
  CONSENT_TYPE_FUNCTIONAL_SOCIAL,
  CONSENT_TYPE_MARKETING,
} from '../constants/cookieConstants'
import { ConsentCookieFormState } from '../types/cookieTypes'
import { isBrowser } from './dom'

export const updateConsentDataLayer = (
  consentCookieFormState: ConsentCookieFormState
) => {
  if (!isBrowser()) return
  if (consentCookieFormState && window.dataLayer) {
    window.dataLayer.push({
      event: 'consentUpdate',
      cookieConsent: {
        functional: consentCookieFormState?.[CONSENT_TYPE_FUNCTIONAL],
        statistics: consentCookieFormState?.[CONSENT_TYPE_ANALYTICAL],
        functionalSocial: consentCookieFormState?.[CONSENT_TYPE_FUNCTIONAL_SOCIAL],
        marketing: consentCookieFormState?.[CONSENT_TYPE_MARKETING],
      },
    })
  }
}
