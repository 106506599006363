import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { UserProps } from '../../../types/userProps'

export interface DafVehicleUrlRmDocumentResponse {
  document: BlobPart
}

interface DafVehicleUrlRmDocumentReference {
  messages: null
  result: DafVehicleUrlRmDocumentResponse
}

export interface DafVehicleUrlRmDocumentsRequest {
  keyField: string | null | undefined
  keyValue: string | null | undefined
  documentId: string | null | undefined
  documentType: string | null | undefined
}

export type DafVehicleUrlRmDocumentsData = DafVehicleUrlRmDocumentReference

const fetchDafVehicleUrlRmDocument = (
  props: Partial<DafVehicleUrlRmDocumentsRequest>,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<BlobPart>> =>
  client.get('/daf-vehicle-url-rmdocument', {
    params: {
      keyField: props.keyField,
      keyValue: props.keyValue,
      documentId: props.documentId,
      documentType: props.documentType,
    },
    responseType: 'arraybuffer',
    cancelToken,
    ...createRequestConfig(user),
  })

export default fetchDafVehicleUrlRmDocument
