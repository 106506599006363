import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import CarouselImage from '../../Carousel/CarouselImage'
import CarouselThumbnails from '../../Carousel/CarouselThumbnails/CarouselThumbnails'
import { Image } from '../../../../types/dafResponseProps'
import Modal, { ModalProps } from '../Modal'
import ModalBodyContent from '../ModalBodyContent'
import { getDividerSx } from '../../../atoms/Divider/Divider'
import { SliderTuple } from '../../../../hooks/slider/useSlider'
import Slider from '../../Slider/Slider'
import CarouselControls from '../../Carousel/CarouselControls'

export interface ImageCarouselModalProps extends ModalProps {
  images: Image[]
  slider: SliderTuple
}

const ImageCarouselModal: FC<ImageCarouselModalProps> = ({
  isActive,
  images,
  slider,
  onClose,
}) => (
  <Modal isActive={isActive} onClose={onClose} variant="fullscreen">
    <ModalBodyContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CarouselControls slider={slider}>
        <Slider slider={slider} enableKeyboard>
          {images.map((image, i) => (
            <CarouselImage
              data-t-id="carousel-enlarged-image"
              key={i.toString()}
              src={image.imageUrl}
              sx={{
                paddingTop: 0,
                height: '100%',
              }}
            />
          ))}
        </Slider>
      </CarouselControls>
    </ModalBodyContent>

    <Flex
      sx={{
        justifyContent: 'center',
        mb: 3,
        ...getDividerSx('top'),
      }}
    >
      <CarouselThumbnails images={images} slider={slider} />
    </Flex>
  </Modal>
)

export default ImageCarouselModal
