import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'

export interface DmscVehicleOptions {
  resultType: number
  faults: Fault[]
  exceptions: Exception[]
  result: DmscVehicleOptionsData
}

export interface DmscVehicleOptionsData {
  chassisNumber: string
  vehicleModelCode: string
  factorSetId: number
  vehicleOptions: VehicleOption[]
}

export interface VehicleOption {
  option: Option
  optionValue: OptionValue
  possibleValues: PossibleValue[]
  hide: boolean
  preSelectValue: boolean
}

export interface Option {
  optionId: number
  parentOptionId: number
  name: string
  optionCategory: string
  optionType: string
  sequenceNumber: number
  isAmendable: boolean
  isDefaultDefined: boolean
  description: string
}

export interface OptionValue {
  valueId: number
  name: string
  description: string
  valueText: string
  valueDouble: number
  valueInt: number
  valueBool: boolean
}

export interface PossibleValue {
  id: number
  name: string
  description: string
  isDefault: boolean
  sortPosition: number
}

export interface Exception {
  code: string
  type: string
  message: string
  stackTrace: string
}

export interface Fault {
  code: string
  message: string
  key: Key
  instance: Instance
}

export interface Instance {}

export interface Key {
  fieldName: string
  value: string
}

const fetchDmscVehicleOptions = (
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  chassisNumber: string | undefined,
  cultureCode: string,
  user?: UserProps
): Promise<AxiosResponse<DmscVehicleOptions>> =>
  client.get(`/dmsc/${chassisNumber}/${cultureCode}/vehicle-options`, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default fetchDmscVehicleOptions
