import React, { forwardRef } from 'react'
import { Box, Label } from 'theme-ui'
import { FormInputProps } from './FormInputBase'
import Checkbox from '../inputs/Checkbox/Checkbox'

export interface FormInputCheckboxProps extends FormInputProps {}

const FormInputCheckbox = forwardRef<HTMLInputElement, FormInputCheckboxProps>(
  ({ children, disabled, ...rest }, ref) => (
    <Label
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Box>
        <Checkbox disabled={disabled} {...rest} ref={ref} />
      </Box>

      <Box
        as="span"
        sx={{
          flex: 1,
          font: 'inherit',
          fontSize: 1,
          color: disabled ? 'disabled' : 'gray.2',
          userSelect: 'none',
          '&:hover': { cursor: 'pointer' },
        }}
      >
        {children}
      </Box>
    </Label>
  )
)

export default FormInputCheckbox
