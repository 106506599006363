import { REWARD_TYPE_PRODUCT_MERCHANDISE } from '../../../constants/loyaltyConstants'
import { Box, Flex, Image, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { getCurrencySymbol } from '../../../helpers/priceHelper'
import i18next from 'i18next'
import React from 'react'
import { Voucher } from '../../../services/rest/ecommerce/loyalty/vouchers'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

interface VoucherCodeProductNameProps {
  voucher: Voucher
}

const VoucherCodeProductName = ({ voucher }: VoucherCodeProductNameProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  return (
    <Flex sx={{ alignItems: 'center', gap: 3 }}>
      {voucher.type === REWARD_TYPE_PRODUCT_MERCHANDISE ? (
        <>
          <Box sx={{ aspectRatio: '1/1', maxWidth: '60px' }}>
            <Image
              src={voucher.productImageUrl}
              alt={voucher.productName}
              sx={{ objectFit: 'contain', inlineSize: '100%', height: '100%' }}
            />
          </Box>
          <Text
            as="h3"
            variant="bodySmall"
            color="gray.3"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {voucher?.productName}
          </Text>
        </>
      ) : (
        <>
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: 'large',
              backgroundColor: COLORS.CREDIT_DARK_BLUE,
              color: COLORS.WHITE,
              aspectRatio: '16 / 9',
              textAlign: 'center',
              maxWidth: '60px',
              width: '60px',
              paddingX: 2,
              paddingY: 1,
            }}
          >
            <Text
              sx={{
                fontWeight: '700',
                fontStyle: 'italic',
                fontSize: '18px',
              }}
            >
              {cultureCode &&
                getCurrencySymbol(cultureCode, voucher?.creditValue?.currencyCode)}
              {voucher?.creditValue?.value}
            </Text>
            <Text sx={{ fontSize: '8px' }}>
              {i18next.t('shoppingLabels.customerLoyalty.credit')}
            </Text>
          </Flex>
          <Text
            as="h3"
            variant="bodySmall"
            color="gray.3"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {cultureCode &&
              getCurrencySymbol(cultureCode, voucher?.creditValue?.currencyCode)}
            {voucher?.creditValue?.value}{' '}
            {i18next.t('shoppingLabels.customerLoyalty.credit')}
          </Text>
        </>
      )}
    </Flex>
  )
}

export default VoucherCodeProductName
