import React, { FC, useMemo } from 'react'
import { Box, Flex } from 'theme-ui'
import { Datasource } from '../../../types/layoutService'
import ExperienceEditorEditFrame from '../../molecules/ExperienceEditor/ExperienceEditorEditFrame'
import ExperienceEditorMessage from '../../molecules/ExperienceEditor/ExperienceEditorMessage'
import { Slideshow } from '../SlideshowHandout/SlideshowHandout'
import SlideshowSlide from './SlideshowSlide'

const EDIT_FRAME_FIELD_NAMES: string[] = ['Title', 'Body', 'Image']

const SlideshowModalExperienceEditor: FC<Datasource<Slideshow>> = ({
  datasource,
}) => {
  const slides = useMemo(() => datasource?.slides ?? [], [datasource])

  if (!slides?.results?.length) {
    return (
      <ExperienceEditorMessage
        title="No slides"
        text="Please use the Content Editor to add slides."
      />
    )
  }

  return (
    <>
      {slides?.results?.map((slide) => (
        <Flex
          sx={{
            justifyContent: 'center',
            width: '100%',
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              width: ['100%', '100%', '100%', '644px'],
            }}
            backgroundColor="background"
          >
            <ExperienceEditorEditFrame
              fieldNames={EDIT_FRAME_FIELD_NAMES}
              itemId={slide.id}
              itemVersion={slide.version}
              key={slide.id}
              title={slide.name}
            >
              <SlideshowSlide {...slide} isActive />
            </ExperienceEditorEditFrame>
          </Box>
        </Flex>
      ))}
    </>
  )
}

export default SlideshowModalExperienceEditor
