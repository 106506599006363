import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { getDividerSx } from '../Divider/Divider'

interface FormFieldSetProps extends BoxProps {
  legend: string
}

const FormFieldSet: FC<FormFieldSetProps> = ({ legend, children }) => (
  <Box
    as="fieldset"
    sx={{
      border: 'none',
      padding: 0,
      marginBottom: 5,
      legend: {
        width: '100%',
        fontWeight: 'bold',
        fontSize: 1,
        color: 'gray.3',
        marginBottom: 4,
        paddingBottom: 3,
        ...getDividerSx('bottom'),
      },
    }}
  >
    <legend>{legend}</legend>

    {children}
  </Box>
)

export default FormFieldSet
