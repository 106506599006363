import React, { FC, useMemo } from 'react'
import {
  DropdownListViewModel,
  ValueFormField,
} from '@sitecore-jss/sitecore-jss-forms'
import {
  FieldChangeCallback,
  ListFieldProps,
} from '@sitecore-jss/sitecore-jss-react-forms'
import { Box } from 'theme-ui'
import FormInputLabel from '../../../atoms/FormControls/FormInputLabel'
import FormInputSelect from '../../../atoms/FormControls/FormInputSelect'
import FormFieldText from '../../../atoms/FormControls/FormFieldText'

const handleOnChange = (
  field: ValueFormField,
  newValue: string,
  callback: FieldChangeCallback
) => {
  let valid = true
  const errorMessages = []

  // custom client validation logic here
  if (field.model.required && !newValue) {
    valid = false
    errorMessages.push(`${field.model.title} is required`)
  }

  callback(field.valueField.name, [newValue], valid, errorMessages)
}

export const DropdownList: FC<ListFieldProps<DropdownListViewModel>> = (props) => {
  const {
    field,
    field: {
      model: { items },
    },
    value,
    onChange,
    tracker,
    errors,
  } = props

  const options = useMemo(
    () => [
      ...(field.model.showEmptyItem ? [{ value: '', text: '' }] : []),
      ...items.map((item) => ({
        ...item,
      })),
    ],
    [field.model.showEmptyItem, items]
  )

  return (
    <Box sx={{ marginBottom: 4 }}>
      <FormInputLabel required={field.model.required}>
        {field.model.title}
      </FormInputLabel>

      <FormInputSelect
        id={field.valueField.id}
        name={field.valueField.name}
        value={value[0]}
        onChange={(e) => handleOnChange(field, e.target.value, onChange)}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
        options={options}
      />

      {errors
        ? errors.map((error) => <FormFieldText type="error">{error}</FormFieldText>)
        : null}
    </Box>
  )
}
