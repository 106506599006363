import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useSelector } from 'react-redux'
import i18next from 'i18next'
import { COLORS } from '../../../constants/themeConstants'
import { RootState } from '../../../reducers'
import {
  CURRENT_ACCOUNT,
  DIRECT_DEBIT,
  LUMPSUM,
  MONTHLY,
} from '../../../constants/dafCompanyVehicleConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { formatNumber } from '../../../helpers/numberFormatHelper'
import toCamelCase from '../../../helpers/camelCaseHelper'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const MainServicePlanPaymentTile = () => {
  const { vehicleData, isFetching } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )

  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const freeLumpsumOrMonthly =
    (vehicleData?.rmContract?.finance?.financialDetails?.installmentFrequency ===
      LUMPSUM ||
      vehicleData?.rmContract?.finance?.financialDetails?.installmentFrequency ===
        MONTHLY) &&
    vehicleData?.rmContract?.finance?.payment?.monthlyAmount === 0

  const paidLumpsum =
    vehicleData?.rmContract?.finance?.financialDetails?.installmentFrequency ===
      LUMPSUM && vehicleData?.rmContract?.finance?.payment?.monthlyAmount > 0

  return (
    <>
      {!isFetching && (
        <>
          <Flex
            sx={{
              flexDirection: 'row',
              width: '100%',
              alignContent: 'center',
              marginBottom: [4, 4, 5],
            }}
          >
            <Text variant="heading2">
              {i18next.t('finance.mainServicePaymentTitle')}
            </Text>
          </Flex>
          <Box sx={{ flexGrow: 1 }}>
            {!freeLumpsumOrMonthly && !paidLumpsum && (
              <Flex sx={{ marginTop: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Text
                    variant="bodySmall"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {i18next.t('finance.monthlyFee')}
                  </Text>
                </Box>
                <Box>
                  <Text variant="bodySmall" color={COLORS.GRAY}>
                    {vehicleData?.rmContract?.finance?.payment?.currency &&
                    vehicleData?.rmContract?.finance?.payment?.monthlyAmount
                      ? `${
                          vehicleData?.rmContract?.finance?.payment?.currency
                        } ${formatNumber(
                          vehicleData?.rmContract?.finance?.payment?.monthlyAmount,
                          cultureCode
                        )}`
                      : '-'}
                  </Text>
                </Box>
              </Flex>
            )}
            {paidLumpsum && !freeLumpsumOrMonthly && (
              <Flex sx={{ marginTop: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Text
                    variant="bodySmall"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {i18next.t('finance.totalAmount')}
                  </Text>
                </Box>
                <Box>
                  <Text variant="bodySmall" color={COLORS.GRAY}>
                    {`${
                      vehicleData?.rmContract?.finance?.payment?.currency
                    } ${formatNumber(
                      (vehicleData?.rmContract?.servicePlan?.details
                        ?.durationInMonthsEndDate || 0) *
                        vehicleData?.rmContract?.finance?.payment?.monthlyAmount,
                      cultureCode
                    )}`}
                  </Text>
                </Box>
              </Flex>
            )}
            {!freeLumpsumOrMonthly && !paidLumpsum && (
              <Flex sx={{ marginTop: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Text
                    variant="bodySmall"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {i18next.t('finance.paymentType')}
                  </Text>
                </Box>
                <Box>
                  <Text variant="bodySmall" color={COLORS.GRAY}>
                    {vehicleData?.rmContract?.finance?.payment?.paymentMethod
                      ? i18next.t(
                          `finance.${toCamelCase(
                            vehicleData?.rmContract?.finance?.payment?.paymentMethod
                          )}`
                        )
                      : '-'}
                  </Text>
                </Box>
              </Flex>
            )}
            {vehicleData?.rmContract?.finance?.payment?.paymentMethod ===
              DIRECT_DEBIT &&
              !freeLumpsumOrMonthly &&
              !paidLumpsum && (
                <Flex sx={{ marginTop: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Text
                      variant="bodySmall"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {i18next.t('finance.collectionDay')}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="bodySmall" color={COLORS.GRAY}>
                      {showDashWhenEmptyValue(
                        vehicleData?.rmContract?.finance?.payment?.paymentTermsDay
                      )}
                    </Text>
                  </Box>
                </Flex>
              )}
            {vehicleData?.rmContract?.finance?.payment?.paymentMethod ===
              CURRENT_ACCOUNT &&
              !freeLumpsumOrMonthly &&
              !paidLumpsum && (
                <Flex sx={{ marginTop: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Text
                      variant="bodySmall"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {i18next.t('finance.dueDay')}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="bodySmall" color={COLORS.GRAY}>
                      {vehicleData?.rmContract?.finance?.payment?.paymentTermsDay}
                    </Text>
                  </Box>
                </Flex>
              )}
            {freeLumpsumOrMonthly && !paidLumpsum && (
              <Box>
                <Text variant="bodySmall">
                  {i18next.t('finance.mainServicePaymentInformation')}
                </Text>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default MainServicePlanPaymentTile
