import i18next from 'i18next'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useSelector } from 'react-redux'
import { COLORS } from '../../../constants/themeConstants'
import { RootState } from '../../../reducers'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import toCamelCase from '../../../helpers/camelCaseHelper'
import { getMileageValue, validateAndGetDate } from '../../../helpers/vehicleHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import {
  MILAGE_SETTLEMENT_TYPE_NOSETTLEMENT,
  MILAGE_SETTLEMENT_TYPE_PAYASYOUDRIVE,
  MILEAGE_SETTLEMENT_TYPE_ATCONTRACTANNIVERSARY,
  MILEAGE_SETTLEMENT_TYPE_INCUSTOMERDEFINEDMONTH,
} from '../../../constants/dafCompanyVehicleConstants'
import { getMonthName } from '../../../helpers/dateHelper'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const MileageSettlementDetailsTile = () => {
  const { vehicleData, isFetching } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const getSettlementType = (vehicle: VehicleDetailResponse | undefined) => {
    if (!vehicle) return null

    if (
      vehicle?.rmContract?.finance?.mileageSettlementDetails
        ?.nextMileageSettlementInput?.trigger
    )
      return vehicle?.rmContract?.finance?.mileageSettlementDetails
        ?.nextMileageSettlementInput?.trigger

    if (vehicle?.rmContract?.finance?.mileageSettlementDetails?.type)
      return vehicle?.rmContract?.finance?.mileageSettlementDetails?.type

    return null
  }

  const getFinanceDictionaryItem = (value: string | null) => {
    if (value) return i18next.t(`finance.${toCamelCase(value)}`)
    return '-'
  }

  const settlementType = getSettlementType(vehicleData)

  return (
    <>
      {!isFetching && (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Text sx={{ fontSize: 4, mb: [4, 4, 5] }}>
              {i18next.t('finance.detailsTitle')}
            </Text>
            <Text sx={{ fontSize: 1, lineHeight: '24px' }}>
              <Flex>
                <Box sx={{ flexGrow: 1, fontWeight: 500 }}>
                  {i18next.t('finance.settlementType')}
                </Box>
                <Box
                  sx={{ color: COLORS.GRAY, textAlign: 'right' }}
                  data-t-id="settlement-type-value"
                >
                  {getFinanceDictionaryItem(settlementType)}
                </Box>
              </Flex>
              {settlementType !== MILAGE_SETTLEMENT_TYPE_NOSETTLEMENT &&
                settlementType !== MILAGE_SETTLEMENT_TYPE_PAYASYOUDRIVE && (
                  <>
                    {settlementType ===
                      MILEAGE_SETTLEMENT_TYPE_INCUSTOMERDEFINEDMONTH && (
                      <Flex>
                        <Box sx={{ flexGrow: 1, fontWeight: 500 }}>
                          {i18next.t('finance.month')}
                        </Box>
                        <Box
                          sx={{ color: COLORS.GRAY, textAlign: 'right' }}
                          data-t-id="mileage-settlement-details-month"
                        >
                          {getMonthName(
                            vehicleData?.rmContract?.finance
                              ?.mileageSettlementDetails?.month,
                            cultureCode
                          )}
                        </Box>
                      </Flex>
                    )}
                    {settlementType ===
                      MILEAGE_SETTLEMENT_TYPE_ATCONTRACTANNIVERSARY && (
                      <Flex>
                        <Box sx={{ flexGrow: 1, fontWeight: 500 }}>
                          {i18next.t('finance.anniversaryDate')}
                        </Box>
                        <Box sx={{ color: COLORS.GRAY, textAlign: 'right' }}>
                          {validateAndGetDate(
                            vehicleData?.rmContract?.finance
                              ?.mileageSettlementDetails?.birthDate
                          )}
                        </Box>
                      </Flex>
                    )}
                    <Flex>
                      <Box sx={{ flexGrow: 1, fontWeight: 500 }}>
                        {i18next.t('finance.nextMileageSettlementDate')}
                      </Box>
                      <Box
                        sx={{ color: COLORS.GRAY, textAlign: 'right' }}
                        data-t-id="next-mileage-settlement-date"
                      >
                        {validateAndGetDate(
                          vehicleData?.rmContract?.finance?.mileageSettlementDetails
                            ?.nextMileageSettlementInput?.date
                        )}
                      </Box>
                    </Flex>
                    <Flex>
                      <Box sx={{ flexGrow: 1, fontWeight: 500 }}>
                        {i18next.t('finance.yearlyMileage')}
                      </Box>
                      <Box
                        sx={{ color: COLORS.GRAY, textAlign: 'right' }}
                        data-t-id="mileage-settlement-yearly-mileage"
                      >
                        {getMileageValue(
                          vehicleData?.rmContract?.servicePlan?.details
                            ?.yearlyKilometers,
                          cultureCode
                        )}
                      </Box>
                    </Flex>
                  </>
                )}
            </Text>
          </Box>
          <Box
            sx={{
              marginTop: 4,
              paddingTop: 4,
              borderTop: '1px solid',
              borderColor: COLORS.MEDIUM_GRAY,
              alignContent: 'flex-end',
              color: COLORS.GRAY,
              fontSize: '12px',
            }}
          >
            {i18next.t('finance.settlementDetailsDescription')}
          </Box>
        </>
      )}
    </>
  )
}

export default MileageSettlementDetailsTile
