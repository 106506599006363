import React, { ElementType } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'

interface ActionButtonListButtonProps {
  label: string
  subLabel?: string
  as?: ElementType
  action?: () => void
  href?: string
  disabled?: boolean
}

const ActionButtonListButton = ({
  label,
  subLabel,
  as,
  action,
  href,
  disabled,
  ...rest
}: ActionButtonListButtonProps) => {
  return (
    <ClickableWrapper
      as={as}
      href={href}
      disabled={disabled}
      sx={{
        paddingBlock: 3,
        paddingInline: 0,
        background: 'transparent',
        border: 'none',
        display: 'block',
        inlineSize: '100%',
        textAlign: 'start',
        textDecoration: 'none',
      }}
      onClick={action}
      {...rest}
    >
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Text
            variant="bodySmall"
            sx={{
              display: 'block',
              fontWeight: 'bold',
              color: !disabled ? COLORS.DAF_BLUE : COLORS.GRAY,
              opacity: !disabled ? 1 : 0.5,
            }}
          >
            {label}
          </Text>
          {subLabel && (
            <Text
              className="sublabeText"
              variant="bodySmall"
              sx={{
                display: 'block',
                paddingBlockStart: 2,
                fontSize: 12,
              }}
            >
              {subLabel}
            </Text>
          )}
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <IconWrapper icon={ICONS.ARROW_RIGHT} size={4} />
        </Box>
      </Flex>
    </ClickableWrapper>
  )
}

export default ActionButtonListButton
