import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'
import StickyWrapper from '../../atoms/StickyWrapper/StickyWrapper'

interface BasicInformationProps {
  children: ReactNode
}

const BasicInformation = ({ children }: BasicInformationProps) => (
  <StickyWrapper>
    <Box mb={3}>{children}</Box>
  </StickyWrapper>
)

export default BasicInformation
