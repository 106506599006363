import React from 'react'
import { RewardsProvider } from '../../../providers/RewardsProvider'
import withData from '../../../enhancers/withData'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import RewardsFeatured from './RewardsFeatured'
import { LoyaltyProvider } from '../../../providers/LoyaltyProvider'
import { RewardsSidepanelDatasource } from '../../molecules/RewardsRedeemRewardSidepanel/RewardsRedeemRewardSidepanel'

export interface RewardsFeaturedDatasourceProps {
  title: TextField
  viewAllVouchers: LinkField
  redeemSidepanel: {
    targetItem: RewardsSidepanelDatasource
  }
}
const RewardsFeaturedWrapper = ({
  datasource,
}: Datasource<RewardsFeaturedDatasourceProps>) => {
  return (
    <RewardsProvider>
      <LoyaltyProvider>
        <RewardsFeatured datasource={datasource} />
      </LoyaltyProvider>
    </RewardsProvider>
  )
}

export default withData(RewardsFeaturedWrapper)
