import i18next from 'i18next'
import React, { FC } from 'react'
import { Box, Flex } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import Button from '../../atoms/Button/Button'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { OnNavigate } from '../../atoms/PaginationPageNumber/PaginationPageNumber'
import PaginationPageNumbers from '../../molecules/PaginationPageNumbers/PaginationPageNumbers'

interface PaginationProps {
  totalCount?: number
  previousIndex?: number | null
  currentIndex?: number
  nextIndex?: number | null

  onNavigate: OnNavigate
}

const Pagination: FC<PaginationProps> = ({
  totalCount,
  currentIndex,
  previousIndex,
  nextIndex,
  onNavigate,
}) => {
  if (
    typeof totalCount === 'undefined' ||
    typeof currentIndex === 'undefined' ||
    totalCount === null ||
    currentIndex === null ||
    totalCount === 1
  ) {
    return null
  }

  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Button
        disabled={previousIndex !== 0 && !previousIndex}
        opacity={previousIndex !== 0 && !previousIndex ? 0 : 1}
        variant="infinite"
        onClick={() =>
          (previousIndex || previousIndex === 0) && onNavigate(previousIndex)
        }
      >
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          <IconWrapper
            icon={ICONS.ARROW_LEFT}
            size={4}
            color="primary"
            style={{
              marginLeft: '-12px',
              marginRight: '-12px',
            }}
          />

          <Box
            sx={{
              display: ['none', 'none', 'block'],
              paddingInlineStart: 3,
              fontWeight: 'bold',
              color: 'primary',
              marginInlineEnd: 4,
            }}
          >
            {i18next.t('paginationLabels.previous')}
          </Box>
        </Flex>
      </Button>

      <PaginationPageNumbers
        totalCount={totalCount}
        currentIndex={currentIndex}
        onNavigate={onNavigate}
      />

      <Button
        disabled={!nextIndex}
        opacity={!nextIndex ? 0 : 1}
        variant="infinite"
        onClick={() => nextIndex && onNavigate(nextIndex)}
      >
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: ['none', 'none', 'block'],
              paddingInlineEnd: 3,
              color: 'primary',
              fontWeight: 'bold',
              marginInlineStart: 4,
            }}
          >
            {i18next.t('paginationLabels.next')}
          </Box>

          <IconWrapper
            icon={ICONS.ARROW_RIGHT}
            size={4}
            color="primary"
            style={{
              marginLeft: '-12px',
              marginRight: '-12px',
            }}
          />
        </Flex>
      </Button>
    </Flex>
  )
}

export default Pagination
