import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC, MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { Flex } from 'theme-ui'
import { openMobileNavigation } from '../../../actions/navigationActions'
import { ICONS } from '../../../constants/iconConstants'
import { Flag, NavigationItem, RenderingProps } from '../../../types/layoutService'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Navigation from '../../molecules/Navigation/Navigation'

interface MainNavigationRightProps {
  navigation: { items: NavigationItem[] }
  rendering: RenderingProps
  isTransparent?: Flag
}

const MainNavigationRight: FC<MainNavigationRightProps> = ({
  navigation,
  rendering,
  isTransparent,
  ...props
}) => {
  const dispatch = useDispatch()

  const handleBurgerClick = (event: MouseEvent) => {
    event.preventDefault()

    dispatch(openMobileNavigation())
  }

  return (
    <Flex
      data-t-id="main-navigation-right"
      sx={{
        order: [2, 2, 2, 3],
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexBasis: ['auto', 'auto', 'auto', '33.33%'],
        flexGrow: [0, 0, 1, 0],
        paddingLeft: [0, 0, 3, 0],
      }}
      {...props}
    >
      <Navigation sx={{ display: ['none', 'none', 'flex'] }} {...navigation} />

      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        <Placeholder name="jss-header-user" rendering={rendering} />

        {!isTransparent && (
          <ClickableWrapper
            onClick={handleBurgerClick}
            sx={{
              display: ['block', 'block', 'none'],
              paddingLeft: [2, 2, 0],
              marginRight: [-2, -2, 0],
            }}
          >
            <IconWrapper icon={ICONS.HAMBURGER} />
          </ClickableWrapper>
        )}
      </Flex>
    </Flex>
  )
}

export default MainNavigationRight
