import { TooltipActions } from '../actions/tooltipActions'
import { TOOLTIP_REGISTER, TOOLTIP_UNREGISTER } from '../constants/tooltipConstants'

export interface TooltipState {
  icon?: string
  label: string
  actionLabel?: string
  actionCallback?: () => void
  actionLink?: string
}

const initialTooltipState: Record<string, TooltipState> = {}

const tooltipReducer = (
  state: Record<string, TooltipState | undefined> = initialTooltipState,
  action: TooltipActions
): Record<string, TooltipState | undefined> => {
  switch (action.type) {
    case TOOLTIP_REGISTER: {
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      }
    }

    case TOOLTIP_UNREGISTER: {
      return { ...state, [action.payload]: undefined }
    }

    default: {
      return state
    }
  }
}

export default tooltipReducer
