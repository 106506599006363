import { ShoppingBasketSidePanelActions } from '../../actions/shoppingBasket/sidePanelActions'
import {
  SHOPPING_BASKET_SIDE_PANEL_SET_ODER_SUMMARY,
  SHOPPING_BASKET_SIDE_PANEL_SET_STATE,
  SHOPPING_BASKET_SIDE_PANEL_REFRESH,
} from '../../constants/shoppingBasketConstants'
import { CommerceOrderSummary } from '../../types/commerceApi'

export interface ShoppingBasketSidePanelState {
  refresh?: boolean
  orderSummary?: CommerceOrderSummary
  active: boolean
}

const initialState: ShoppingBasketSidePanelState = {
  refresh: undefined,
  orderSummary: undefined,
  active: false,
}

const shoppingBasketSidePanelReducer = (
  state: ShoppingBasketSidePanelState = initialState,
  action: ShoppingBasketSidePanelActions
): ShoppingBasketSidePanelState => {
  switch (action.type) {
    case SHOPPING_BASKET_SIDE_PANEL_REFRESH: {
      return {
        ...state,
        refresh: action.payload ? true : undefined,
      }
    }

    case SHOPPING_BASKET_SIDE_PANEL_SET_ODER_SUMMARY: {
      return {
        ...state,
        orderSummary: action.payload,
      }
    }

    case SHOPPING_BASKET_SIDE_PANEL_SET_STATE: {
      return {
        ...state,
        active: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default shoppingBasketSidePanelReducer
