import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react'
import { Box, BoxProps } from 'theme-ui'
import TableRow from './TableRow'

interface TableHeadProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {}

const TableHead: FC<TableHeadProps> = ({ children, sx, ...manipulatedProps }) => (
  <Box
    className="table-header"
    sx={{
      display: 'table-header-group',
      width: '100%',
      ...sx,
    }}
    {...manipulatedProps}
  >
    <TableRow sx={{ borderTopWidth: '1px' }}>
      {Children.map(children, (child: ReactNode, index) => {
        if (!isValidElement(child)) return child

        return cloneElement(child as ReactElement, {
          ...child.props,
          className: [child.props.className, `table-cell-${index}`]
            .filter((e) => e)
            .join(' '),
        })
      })}
    </TableRow>
  </Box>
)

export default TableHead
