import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { Dealer, SearchDealerRequest } from '../../../types/dealerServiceTypes'
import { PaginatedResponse } from '../../../types/layoutService'

const DEFAULT_DEALER_SEARCH_PAGE_SIZE = 12
const DEFAULT_DEALER_SERVICE_OPTIONS: SearchDealerRequest = {
  text: '',
  initialFilters: [],
  filters: [],
  pageIndex: 0,
  pageSize: DEFAULT_DEALER_SEARCH_PAGE_SIZE,
  geoPoint: undefined,
  excludeDealersWithoutRequestAccountSupport: false,
  excludeDealerWithoutLoyaltyParticipation: false,
}

export const fetchDealers = (
  client: AxiosInstance,
  receivedOptions: Partial<SearchDealerRequest>,
  cancelToken: CancelToken
): Promise<AxiosResponse<PaginatedResponse<Dealer>>> =>
  client.post(
    'search',
    { ...DEFAULT_DEALER_SERVICE_OPTIONS, ...receivedOptions },
    {
      cancelToken,
    }
  )

export const fetchDealer = (
  client: AxiosInstance,
  locationCode: string,
  cancelToken: CancelToken
): Promise<AxiosResponse<Dealer>> =>
  client.get(locationCode, {
    cancelToken,
  })
