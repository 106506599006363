import React, { useContext } from 'react'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import SelectVehicleGroup from './SelectVehicleGroup/SelectVehicleGroup'
import axios from 'axios'
import fetchDafCompanyVehicles from '../../../services/rest/ecommerce/dafCompanyVehicles'
import { useQuery } from '@tanstack/react-query'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { UserContext } from '../../../providers/UserProvider'
import { DafDefaultQueryParams } from '../../../types/userProps'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { Box, Flex } from 'theme-ui'
import SpinnerWithLabel from '../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import i18next from 'i18next'
import Button from '../../atoms/Button/Button'
import { ICONS } from '../../../constants/iconConstants'
import ProgressSteps from '../../atoms/ProgressSteps/ProgressSteps'
import { COLORS } from '../../../constants/themeConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'

export interface RequestMaintenancePlanWizardDatasourceProps {
  stepOneTitle: TextField
  stepOneSubtitle: TextField
  stepOneProgressBarTitle: TextField
  noOfVehiclesText: TextField
  enterInformationButtonText: TextField
  stepTwoTitle: TextField
  stepTwoSubtitle: TextField
  stepTwoProgressBarTitle: TextField
  stepThreeProgressBarTitle: TextField
  backToMyFleetButton: LinkField
  noResultsDescription: TextField
  noResultsTitle: TextField
}

const RequestMaintenancePlanWizard = ({
  datasource,
}: Datasource<RequestMaintenancePlanWizardDatasourceProps>) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDafCompanyVehicles = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafCompanyVehicles(
      client,
      dafVehicleDetailQueryParams,
      token,
      user
    )
    return response.data
  }

  const { isFetching, data: dafCompanyVehiclesData } = useQuery({
    queryKey: ['dafCompanyVehicles'],
    queryFn: getDafCompanyVehicles,
    enabled: !!actingCompanyId && !!actingSupplierId,
  })

  return isFetching ? (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        paddingY: 5,
      }}
    >
      <SpinnerWithLabel
        label={i18next.t('commonLabels.loading')}
        sx={{
          width: 'max-content',
          position: 'sticky',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </Flex>
  ) : (
    <>
      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: COLORS.MEDIUM_GRAY,
          paddingY: [3, 3, 3, 4],
          marginBottom: [4, 4, 4, 6],
        }}
      >
        <ProgressSteps
          activeStep={1}
          steps={[
            { label: getTextFieldValue(datasource.stepOneProgressBarTitle) },
            { label: getTextFieldValue(datasource.stepTwoProgressBarTitle) },
            { label: getTextFieldValue(datasource.stepThreeProgressBarTitle) },
          ]}
        />
      </Box>
      <Box>
        <Box>
          <SelectVehicleGroup
            datasource={datasource}
            vehicles={dafCompanyVehiclesData}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', marginBottom: 4 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <Button
            as="a"
            href={datasource.backToMyFleetButton.url}
            variant="outline"
            icon={ICONS.ARROW_LEFT}
            iconPosition="start"
          >
            {datasource.backToMyFleetButton.text}
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default withData(RequestMaintenancePlanWizard)
