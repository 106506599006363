import React, { FC, useContext, useMemo } from 'react'
import { Text } from 'theme-ui'
import { PersistedPageContext } from '../../../providers/PersistedPageProvider'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Link from '../../atoms/Link/Link'
import { ItemProps } from '../Breadcrumb/Breadcrumb'
import { Flag, TextField } from '../../../types/layoutService'

interface MobileBreadcrumbProps {
  items: ItemProps[]
  mobileBackText?: TextField
  hideOnMobile?: Flag
}

const MobileBreadcrumb: FC<MobileBreadcrumbProps> = ({
  items = [],
  mobileBackText,
  hideOnMobile,
}) => {
  const persistedPages = useContext(PersistedPageContext)

  const mobileDisplay = useMemo(() => {
    if (hideOnMobile === '1' && persistedPages?.previous) return 'none'

    return ['flex', 'flex', 'flex', 'none']
  }, [persistedPages, hideOnMobile])

  const mobileItem = useMemo(
    () => (items && items?.length > 1 ? items?.[items.length - 2] : undefined),
    [items]
  )

  if (!mobileBackText || (!mobileItem?.url && !mobileItem?.title)) return null

  return (
    <Link
      href={mobileItem.url}
      sx={{
        display: mobileDisplay,
        textDecoration: 'none',
        alignItems: 'center',
        fontSize: 1,
        py: [4, 4, 4],
      }}
    >
      <IconWrapper icon={ICONS.ARROW_LEFT} size={2} />

      <Text as="span" variant="smallText" ml="1">
        {`${getTextFieldValue(mobileBackText)} ${mobileItem.title}`}
      </Text>
    </Link>
  )
}

export default withClientSideRender(MobileBreadcrumb)
