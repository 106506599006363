import React, { ChangeEvent, FC } from 'react'
import { Field } from 'react-final-form'
import FormControl from '../FormControls/FormControl'
import FormFieldText from '../FormControls/FormFieldText'
import FormInputLabel from '../FormControls/FormInputLabel'
import FormInputSelect, {
  FormInputSelectOptionProps,
} from '../FormControls/FormInputSelect'
import { BaseFieldProps } from './BaseField'

export interface SelectFieldProps extends BaseFieldProps {
  label?: string
  placeholder?: string
  options: FormInputSelectOptionProps[]
  onChange?: (event: ChangeEvent) => void
  value?: string
  initialValue?: string
}

export const SelectField: FC<SelectFieldProps> = ({
  disabled,
  name,
  label,
  options,
  placeholder,
  validate,
  onChange,
  initialValue,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate} initialValue={initialValue}>
      {({ input, meta }) => (
        <FormControl
          id={name}
          error={meta.touched && (meta.error || meta.submitError)}
          disabled={disabled ?? meta.submitting}
        >
          <FormInputLabel>{label}</FormInputLabel>

          <FormInputSelect
            onBlur={input.onBlur}
            onChange={(e) => {
              if (onChange) onChange(e)
              input.onChange(e)
            }}
            onFocus={input.onFocus}
            options={options}
            placeholder={placeholder}
            value={input.value}
            {...rest}
          />

          <FormFieldText type="error">
            {meta.error || meta.submitError}
          </FormFieldText>
        </FormControl>
      )}
    </Field>
  )
}
