import { AxiosResponse } from 'axios'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import {
  CommerceOrderSummary,
  CommerceResponse,
} from '../../../../types/commerceApi'

export interface OrdersSummaryRequestProps {
  orderNumber?: string
}

export const getOrdersSummary: RequestFunction<
  OrdersSummaryRequestProps,
  CommerceResponse<CommerceOrderSummary>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrderSummary>>> =>
  client.get(`orders-summary/${props.orderNumber || 'latest'}`, {
    params: {
      customerCompanyId: context.customerCompanyId,
      supplierCompanyId: context.supplierCompanyId,
      isImpersonated: !!context.isImpersonated,
    },
    cancelToken: config.cancelToken,
    ...createRequestConfig(context.user),
  })
