import React, { ReactNode, useState } from 'react'
import SidePanelBody from '../../organisms/SidePanel/SidePanelBody'
import SidePanel from '../../organisms/SidePanel/SidePanel'
import ClickableWrapper from '../ClickableWrapper/ClickableWrapper'
import TagWithBeforeLabel from '../TagWithBeforeLabel/TagWithBeforeLabel'

interface TagWithSidePanelProps {
  tagLabelBeforeContent?: string
  tagLabel: string
  sidePanelTitle: string
  children: ReactNode
}

const TagWithSidePanel = ({
  tagLabelBeforeContent,
  tagLabel,
  sidePanelTitle,
  children,
  ...rest
}: TagWithSidePanelProps) => {
  const [displayDetails, setDisplayDetails] = useState<boolean>(false)

  return (
    <>
      <ClickableWrapper
        onClick={() => setDisplayDetails(true)}
        sx={{ marginBottom: 1, marginRight: 1 }}
        {...rest}
      >
        <TagWithBeforeLabel
          tagLabel={tagLabel}
          tagLabelBeforeContent={tagLabelBeforeContent}
          sx={{
            ':hover': {
              backgroundColor: 'promotion.1',
            },
          }}
        />
      </ClickableWrapper>

      <SidePanel
        active={displayDetails}
        onClose={() => setDisplayDetails(false)}
        title={sidePanelTitle}
      >
        <SidePanelBody>{children}</SidePanelBody>
      </SidePanel>
    </>
  )
}

export default TagWithSidePanel
