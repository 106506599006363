import i18next from 'i18next'
import { VehicleDetailResponse } from '../services/rest/ecommerce/dafVehicleDetail'
import { Sidepanel } from '../types/VehicleDetailGenericComponentDataProps'
import renderHTML from './renderHTMLHelper'
import { getVehicleLabel } from './vehicleHelper'
import { getTextFieldValue } from './layoutServiceHelper'

export const getFormattedSidepanelVehicleIntroduction = (
  sidePanelDatasource: Sidepanel,
  vehicle: VehicleDetailResponse | undefined
) => {
  return renderHTML(
    i18next.t(getTextFieldValue(sidePanelDatasource.introductionText), {
      registrationNumber: `<strong>${getVehicleLabel(
        vehicle?.vehicleDetail
      )}<strong>`,
    })
  )
}
