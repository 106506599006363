import dayjs from 'dayjs'
import { UpcomingJob, Vehicle } from '../services/rest/ecommerce/dafCompanyVehicles'
import {
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
} from '../constants/dafCompanyVehicleConstants'

export const sortByFirstRegistrationDate = (vehicles: Vehicle[]) =>
  vehicles.sort((a, b) => {
    const dateA = a.firstRegistrationDate ? new Date(a.firstRegistrationDate) : null
    const dateB = b.firstRegistrationDate ? new Date(b.firstRegistrationDate) : null

    if (dateA === null || dateB === null) {
      // Handle the case where the firstRegistrationDate is undefined or has an unexpected value
      return 0 // No sorting is performed in this case
    }

    return dateB.getTime() - dateA.getTime()
  })

export const sortByVehicleActionStatus = (vehicles: Vehicle[]) =>
  vehicles.sort((a, b) => {
    const vehicleActionStatusA = a.vehicleActionStatus
    const vehicleActionStatusB = b.vehicleActionStatus

    return vehicleActionStatusA - vehicleActionStatusB
  })

// if multiple upcomingJobs return the highest for use in sortByVehicleMaintenanceUrgencyStatus
const getHighestDueIn = (jobs: UpcomingJob[]) =>
  jobs.reduce((highest, job) => {
    const dueIn = job.markedAsComplete ? '' : job.dueIn || ''
    return dueInOrder.indexOf(dueIn) < dueInOrder.indexOf(highest) ? dueIn : highest
  }, '')

// Define an order for the 'dueIn' values in sortByVehicleMaintenanceUrgencyStatus
const dueInOrder = [
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS,
  '',
]

export const sortByVehicleMaintenanceUrgencyStatus = (vehicles: Vehicle[]) =>
  vehicles.sort((a, b) => {
    // Get the highest upcoming job dueIn for each vehicle
    const aDueIn = a.servicePlanner?.service?.upcomingJobs
      ? getHighestDueIn(a.servicePlanner.service.upcomingJobs)
      : ''
    const bDueIn = b.servicePlanner?.service?.upcomingJobs
      ? getHighestDueIn(b.servicePlanner.service.upcomingJobs)
      : ''

    // Find the index of 'dueIn' in the order for each vehicle
    const aIndex = dueInOrder.indexOf(aDueIn)
    const bIndex = dueInOrder.indexOf(bDueIn)

    // If the indices are the same, sort by registration date
    if (aIndex === bIndex) {
      // sort vehicles w/o registrationdate at the bottom
      if (!a.firstRegistrationDate || !b.firstRegistrationDate) {
        return -1
      }

      return (
        dayjs(b.firstRegistrationDate).unix() - dayjs(a.firstRegistrationDate).unix()
      )
    }

    // Otherwise, sort by the 'dueIn' order
    return aIndex - bIndex
  })

export const sortByMainServiceDealer = (vehicles: Vehicle[]) =>
  vehicles.sort((a, b) => {
    const dealerA = a?.rmContract?.mainServiceDealer?.name?.toUpperCase() || ''
    const dealerB = b?.rmContract?.mainServiceDealer?.name?.toUpperCase() || ''

    // Sort vehicles with non-empty mainServiceDealer?.name first
    if (dealerA && !dealerB) {
      return -1
    }
    if (!dealerA && dealerB) {
      return 1
    }

    // Sort vehicles with empty or null mainServiceDealer?.name next
    if (!dealerA && !dealerB) {
      return 0
    }

    // Sort remaining vehicles alphabetically by mainServiceDealer?.name
    if (dealerA < dealerB) {
      return -1
    }
    if (dealerA > dealerB) {
      return 1
    }
    return 0
  })

export const sortByMileage = (vehicles: Vehicle[]) =>
  vehicles.sort((a, b) => {
    const priorityA = a?.mileage?.value || 0
    const priorityB = b?.mileage?.value || 0

    return priorityB - priorityA
  })

export const sortByServiceCoveragePriority = (vehicles: Vehicle[]) =>
  vehicles.sort((a, b) => {
    const priorityA = a.serviceCoveragePriority || Number.MAX_SAFE_INTEGER
    const priorityB = b.serviceCoveragePriority || Number.MAX_SAFE_INTEGER

    return priorityA - priorityB
  })

export const findVehiclesByTextInput = (
  data: Vehicle[],
  searchString: string | null
) => {
  const matchedVehicles: Vehicle[] = []

  if (!searchString) return data

  data.forEach((vehicle) => {
    const lowerCaseSearchString = searchString.toLocaleLowerCase()
    const { vin, registrationNumber, rmContract, chassisNumber, customerVehicleId } =
      vehicle
    if (
      vin.toLocaleLowerCase().includes(lowerCaseSearchString) ||
      (registrationNumber &&
        registrationNumber.toLocaleLowerCase().includes(lowerCaseSearchString)) ||
      (rmContract?.mainServiceDealer?.name &&
        rmContract.mainServiceDealer.name
          .toLocaleLowerCase()
          .includes(lowerCaseSearchString)) ||
      chassisNumber.includes(lowerCaseSearchString) ||
      (customerVehicleId &&
        customerVehicleId.toLocaleLowerCase().includes(lowerCaseSearchString))
    ) {
      matchedVehicles.push(vehicle)
    }
  })

  return matchedVehicles
}

export const filterVehiclesByActiveFilters = (
  vehicles: Vehicle[],
  activeFilters: Record<string, string[]>
) => {
  if (Object.keys(activeFilters).length === 0) {
    return vehicles
  }

  return vehicles.filter((vehicle: Vehicle) =>
    Object.entries(activeFilters).every(([key, values]) => {
      // @ts-ignore
      const obj1Val = Array.isArray(vehicle.applicableFilters[key as never])
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          vehicle.applicableFilters[key as any]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [vehicle.applicableFilters[key as any]]
      return values.some((value) => obj1Val.includes(value))
    })
  )
}
