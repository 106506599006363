import { AxiosResponse } from 'axios'
import { useCallback, useContext, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { setShoppingBasketSidePanelOrderSummary } from '../../actions/shoppingBasket/sidePanelActions'
import { setOrder } from '../../actions/shoppingBasket/wizard'
import {
  ACTIVE_USER_STATE,
  ACTIVE_USER_STATE_COOKIE_OPTIONS,
} from '../../constants/cookieConstants'
import { encodeActiveUserStateCookie } from '../../helpers/cookieHelper'
import { ActiveUserStateContext } from '../../providers/ActiveUserStateProvider'
import {
  OrderItemsRequestItem,
  OrderItemsRequestProps,
  postOrderItems,
} from '../../services/rest/ecommerce/order/order-items'
import {
  createOrder,
  CreateOrderProps,
} from '../../services/rest/ecommerce/order/orders'
import {
  CommerceMessage,
  CommerceOrder,
  CommerceOrderItem,
  CommerceResponse,
} from '../../types/commerceApi'
import useSitecoreContext from '../useSitecoreContext'
import useCommerceApiPostService from './rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from './rest/ecommerce/useCommerceApiResponse'

type UseCommercePostOrderItemServiceTuple = [
  boolean,
  AxiosResponse<CommerceResponse<CommerceOrderItem[]>> | undefined,
  (requestProps: Partial<OrderItemsRequestProps>) => Promise<void>
]

const useCommercePostOrderItemService = (): UseCommercePostOrderItemServiceTuple => {
  const { activeUserState } = useContext(ActiveUserStateContext)
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const dispatch = useDispatch()

  const [, setCookie] = useCookies([ACTIVE_USER_STATE])

  const [orderItemList, setOrderItemList] = useState<
    OrderItemsRequestItem[] | undefined
  >(undefined)

  const [isCreatingOrder, createOrderResponse, createOrderExecutor] =
    useCommerceApiPostService<CreateOrderProps, CommerceResponse<CommerceOrder>>(
      createOrder
    )

  const [isPostingOrderItems, postOrderItemsResponse, postOrderItemsExecutor] =
    useCommerceApiPostService<
      OrderItemsRequestProps,
      CommerceResponse<CommerceOrderItem[]>
    >(postOrderItems)

  const onCreateOrderResult = useCallback(
    (order: CommerceOrder | null) => {
      if (
        !orderItemList ||
        !order?.number ||
        !activeUserState?.selectedCustomerCompanyId ||
        !activeUserState.selectedSupplierCompanyId
      )
        return

      dispatch(setOrder(order))

      postOrderItemsExecutor({ items: orderItemList, orderNumber: order.number })

      setCookie(
        ACTIVE_USER_STATE,
        encodeActiveUserStateCookie({
          ...activeUserState,
          selectedOrderNumber: order.number,
        }),
        ACTIVE_USER_STATE_COOKIE_OPTIONS
      )

      dispatch(
        setShoppingBasketSidePanelOrderSummary({
          orderNumber: order.number,
        })
      )
    },
    [activeUserState, dispatch, orderItemList, postOrderItemsExecutor, setCookie]
  )

  const onCreateOrderMessage = useCallback(
    (e: CommerceMessage) => console.error(e),
    []
  )

  useCommerceApiResponse<CommerceOrder>({
    response: createOrderResponse,
    onResult: onCreateOrderResult,
    onMessage: onCreateOrderMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrder,
  })

  const postOrderItem = useCallback(
    (props: Partial<OrderItemsRequestProps>) => {
      setOrderItemList(props.items)

      if (!props.orderNumber) {
        return createOrderExecutor({
          cultureCode,
        })
      }

      return postOrderItemsExecutor(props as OrderItemsRequestProps)
    },
    [createOrderExecutor, cultureCode, postOrderItemsExecutor]
  )

  return [
    isCreatingOrder || isPostingOrderItems,
    postOrderItemsResponse,
    postOrderItem,
  ]
}

export default useCommercePostOrderItemService
