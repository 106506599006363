import React, { FC, useLayoutEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'
import withData from '../../../enhancers/withData'
import {
  CheckboxField,
  Datasource,
  ImageWithFocalPointField,
  LinkField,
  TextField,
} from '../../../types/layoutService'
import ProductOrServiceTile from '../../molecules/ProductOrServiceTile/ProductOrServiceTile'
import useBreakpoints from '../../../hooks/useBreakpoints'

interface ProductsAndServicesDatasource {
  title: TextField
  highlightFirstProduct: CheckboxField
  tiles: {
    results: ProductOrServiceTileProps[]
  }
}

interface ProductOrServiceTileProps {
  id: string
  title: TextField
  link: LinkField
  image: ImageWithFocalPointField
}

const ProductsAndServices: FC<Datasource<ProductsAndServicesDatasource>> = ({
  datasource,
}) => {
  const [breakpointS] = useBreakpoints('s')
  const [isDesktopOrTablet, setIsDesktopOrTablet] = useState(false)

  useLayoutEffect(() => {
    setIsDesktopOrTablet(breakpointS)
  }, [breakpointS])

  if (datasource?.tiles?.results?.length === 0) return null

  return (
    <div>
      <Text
        variant="heading1"
        as="h2"
        sx={{
          color: 'text',
          paddingBottom: 5,
          fontSize: 24,
          maxWidth: ['100%', '100%', '100%', '50%'],
        }}
      >
        {datasource?.title?.value}
      </Text>
      {isDesktopOrTablet ? (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridAutoRows: 'auto',
            columnGap: 4,
            rowGap: 5,
          }}
        >
          {datasource.tiles?.results?.map((productOrServiceTile, index) => (
            <Box
              key={`product-or-service-tile-${productOrServiceTile.id}`}
              sx={{
                gridColumn:
                  index === 0 && datasource?.highlightFirstProduct?.boolValue
                    ? 'span 2'
                    : 'span 1',
              }}
            >
              <ProductOrServiceTile
                title={productOrServiceTile.title}
                link={productOrServiceTile.link}
                image={productOrServiceTile.image}
                imageSizes={
                  index === 0 && datasource?.highlightFirstProduct?.boolValue
                    ? '764px'
                    : '370px'
                }
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
            gap: 3,
            '& > div': {
              flex: '0 0 280px',
              scrollSnapAlign: 'start',
            },
          }}
        >
          {datasource.tiles?.results?.map((productOrServiceTile) => (
            <ProductOrServiceTile
              key={`product-or-service-tile-${productOrServiceTile.id}`}
              title={productOrServiceTile.title}
              link={productOrServiceTile.link}
              image={productOrServiceTile.image}
            />
          ))}
        </Box>
      )}
    </div>
  )
}

export default withData(ProductsAndServices)
