import { useTheme } from '@emotion/react'
import i18next from 'i18next'
import React, { FC, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import {
  SEARCH_TYPE_ASSEMBLIES_SEARCH,
  SEARCH_TYPE_DEALER_SEARCH,
  SEARCH_TYPE_PARTS_SEARCH,
} from '../../../constants/searchConstants'
import { URL_PAGE_PARAM } from '../../../constants/urlConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { AssemblySearchContext } from '../../../providers/AssemblySearchContextProvider'
import { DealerPartSearchContext } from '../../../providers/DealerPartSearchContextProvider'
import { PartSearchContext } from '../../../providers/PartSearchContextProvider'
import { SearchContext } from '../../../providers/SearchContextProvider'
import Container from '../../atoms/Container/Container'
import TabBar, { TabBarProps } from '../../molecules/Tab/TabBar'
import SearchResultsTab from './SearchResultsTab'

const SearchResultsTabBar: FC<TabBarProps> = ({ ...tabBarProps }) => {
  const { searchString } = useContext(SearchContext)

  const history = useHistory()
  const { search } = useTheme()
  const { statistics: partStatistics } = useContext(PartSearchContext)
  const { totalCount: totalAssemblyCount } = useContext(AssemblySearchContext)
  const { totalCount: totalDealerPartCount } = useContext(DealerPartSearchContext)

  const resetUrlPageNumber = useCallback(
    (searchType: string) => {
      const searchParams = new URLSearchParams(history.location.search)

      searchParams.delete(URL_PAGE_PARAM)

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CHANGE_SHOW_MODE,
        view_name: searchType,
        search_query: searchString,
      })

      history.push(`?${searchParams.toString()}`)
    },
    [history, searchString]
  )

  return (
    <Box sx={{ backgroundColor: search.tabBarBackgroundColor }}>
      <Container>
        <TabBar {...tabBarProps}>
          <SearchResultsTab
            panelId={SEARCH_TYPE_PARTS_SEARCH}
            count={partStatistics?.totalCount}
            onClick={() => resetUrlPageNumber(SEARCH_TYPE_PARTS_SEARCH)}
          >
            {i18next.t('commonLabels.products')}
          </SearchResultsTab>

          <SearchResultsTab
            panelId={SEARCH_TYPE_ASSEMBLIES_SEARCH}
            count={totalAssemblyCount}
            onClick={() => resetUrlPageNumber(SEARCH_TYPE_ASSEMBLIES_SEARCH)}
          >
            {i18next.t('commonLabels.assemblies')}
          </SearchResultsTab>

          <SearchResultsTab
            panelId={SEARCH_TYPE_DEALER_SEARCH}
            count={totalDealerPartCount}
            onClick={() => resetUrlPageNumber(SEARCH_TYPE_DEALER_SEARCH)}
          >
            {i18next.t('commonLabels.dealer')}
          </SearchResultsTab>
        </TabBar>
      </Container>
    </Box>
  )
}

export default SearchResultsTabBar
