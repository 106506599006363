import React, { useCallback, useEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from '../../../reducers'
import { runOnDocument, scrollTo } from '../../../helpers/dom'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

const DigitalMagazineMenu = () => {
  const menuItems = useSelector((state: RootState) => state.digitalMagazine)
  const [visibleSection, setVisibleSection] = useState<string | null>(null)
  const { pageEditing, pagePreviewing } = useSitecoreContext()
  const { pathname } = useLocation()
  const history = useHistory()

  const calculateOffset = useCallback(
    (hash: string) => (hash === menuItems[0]?.menuId ? 114 : 72),
    [menuItems]
  )

  const scrollHandler = useCallback((element: HTMLElement, offset: number) => {
    scrollTo({
      top:
        element.getBoundingClientRect().top +
        document.documentElement.scrollTop -
        offset,
      behavior: 'smooth',
    })
  }, [])

  const handleClickScroll = useCallback(
    (menuId: string) => {
      const element = document.getElementById(menuId)
      if (!element) return
      scrollHandler(element, calculateOffset(menuId))
    },
    [scrollHandler, calculateOffset]
  )

  useEffect(() => {
    const targetSections = runOnDocument((doc) => doc.querySelectorAll('section'))

    if (!targetSections) return

    const options = {
      root: null,
      rootMargin: '-50%',
      threshold: 0,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleSection(entry.target.getAttribute('id'))
        }
      })
    }, options)

    targetSections.forEach((section) => {
      observer.observe(section)
    })
  }, [])

  useEffect(() => {
    if (pageEditing || pagePreviewing || !visibleSection) return
    history.push(`${pathname}#${visibleSection}`)
    const menuItem = menuItems.find((item) => item.menuId === visibleSection)

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT.MAGAZINE_NAVIGATION,
      navigation_type: 'scroll',
      step_name: menuItem?.menuTitle,
    })
  }, [history, pathname, visibleSection, menuItems, pageEditing, pagePreviewing])

  useEffect(() => {
    // On pageload check if there is a hash in the url and scroll to that section
    const hash = history.location.hash.replace('#', '')
    if (hash) {
      setVisibleSection(hash)
      const element = document.getElementById(hash)
      if (element) {
        scrollHandler(element, calculateOffset(hash))
      }
    }
  }, [calculateOffset])

  return (
    <Box
      as="ul"
      sx={{
        position: 'fixed',
        zIndex: 2,
        top: '50%',
        right: '40px',
        transform: 'translateY(-50%)',
        listStyleType: 'none',
        display: ['none', 'none', 'none', 'none', 'block'],
      }}
    >
      {menuItems.map((item, index) => (
        <Box
          as="li"
          key={item.menuId}
          className={item.menuId === visibleSection ? 'active' : ''}
          sx={{ textAlign: 'right' }}
        >
          <ClickableWrapper
            onClick={() => {
              handleClickScroll(item.menuId)
              pushToDataLayer({
                [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT.MAGAZINE_NAVIGATION,
                navigation_type: 'index menu',
                step_number: index + 1,
                step_name: item.menuTitle,
              })
            }}
            sx={{
              color: 'rgba(255,255,255, 0.4)',
              '.menu-title': {
                opacity: item.menuId === visibleSection ? 1 : 0,
              },
              '&:hover': {
                color: 'white',
                '.menu-title': {
                  transition: 'opacity 0.2s ease-in-out',
                  opacity: 1,
                },
              },
            }}
          >
            <Box as="div">
              <Text
                sx={{
                  position: 'relative',
                  paddingRight: '10px',
                  color:
                    item.menuId === visibleSection
                      ? 'white'
                      : 'rgba(255,255,255, 0.4)',
                  fontSize: item.menuId === visibleSection ? '18px' : '16px',
                  '&:hover': {
                    color: 'white',
                    fontSize: '18px',
                    '&:after': {
                      backgroundColor: 'white',
                    },
                  },
                  '&:after': {
                    content: '""',
                    display: 'block',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor:
                      item.menuId === visibleSection
                        ? 'white'
                        : 'rgba(255,255,255, 0.4)',
                    position: 'absolute',
                    right: '-5px',
                    top: '50%',
                    marginBottom: '10px',
                    transform: 'translateY(-50%)',
                  },
                }}
              >
                <Box as="span" className="menu-title">{`${item.menuTitle} - `}</Box>
                <span className="menu-index">{`0${index + 1}`}</span>
              </Text>
            </Box>
          </ClickableWrapper>

          {index + 1 !== menuItems.length && (
            <Box
              sx={{
                width: '100%',
                display: 'block',
                height: '24px',
                marginRight: '-10px',
                borderRight: '1px dashed rgba(255,255,255, 0.4)',
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}
export default DigitalMagazineMenu
