import React, { FC, useContext } from 'react'
import i18next from 'i18next'
import { Box } from 'theme-ui'
import { LinkField } from '../../../../../types/layoutService'
import { AssemblySearchContext } from '../../../../../providers/AssemblySearchContextProvider'
import AssemblySearchLine from './AssemblySearchLine'
import PartLine from '../../../../molecules/PartLine/PartLine'
import { getDividerSx } from '../../../../atoms/Divider/Divider'
import PartLineTitle from '../../../../molecules/PartLine/PartLineTitle'

interface AssembliesSearchLineViewProps {
  assemblyPage: LinkField
}

export const gridTemplate = {
  columnSizes: ['1fr', '1fr', '1fr 4fr 2fr', '1fr 4fr 2fr', '1fr 5fr 6fr'], // css prop: grid-template-columns
}

const AssembliesSearchLineView: FC<AssembliesSearchLineViewProps> = ({
  assemblyPage,
}) => {
  const { assemblies } = useContext(AssemblySearchContext)

  if (!assemblies || !assemblies.length) return null

  return (
    <Box sx={getDividerSx('top')}>
      <PartLine
        gridTemplate={gridTemplate}
        py={3}
        sx={{
          display: ['none', 'none', 'grid'],
        }}
      >
        <PartLineTitle>{i18next.t('commonLabels.product')}</PartLineTitle>

        <PartLineTitle />

        <PartLineTitle>{i18next.t('commonLabels.brand')}</PartLineTitle>
      </PartLine>

      {assemblies.map((assembly, i) => (
        <AssemblySearchLine
          key={i.toString()}
          assemblyPage={assemblyPage}
          gridTemplate={gridTemplate}
          {...assembly}
        />
      ))}
    </Box>
  )
}

export default AssembliesSearchLineView
