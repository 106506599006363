import React, { FC, useContext, useEffect, useState } from 'react'
import i18next from 'i18next'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import PartAddToBasket from '../../molecules/AddToBasket/PartAddToBasket'
import PartPromotionsAsync from '../../molecules/PartPromotions/PartPromotionsAsync'
import { UserContext } from '../../../providers/UserProvider'
import { loggedIn } from '../../../helpers/userHelper'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { CommerceSpecification } from '../../../types/commerceApi'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { PartContext } from '../../../types/sitecoreContextProps'
import BasicInformation from '../../molecules/BasicInformation/BasicInformation'
import BasicInformationDescription from '../../molecules/BasicInformation/BasicInformationDescription'
import BasicInformationAttribute from '../../molecules/BasicInformation/BasicInformationAttribute'
import BasicInformationBrandImage from '../../molecules/BasicInformation/BasicInformationBrandImage'
import BasicInformationDealerContactInformation from '../../molecules/BasicInformation/BasicInformationDealerContactInformation'
import { Part } from '../../../types/dafResponseProps'
import PartPriceAsync from '../../molecules/PartPrice/PartPriceAsync'
import PartLineStockInformation from '../../molecules/PartLine/PartLineStockInformation'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'
import { EVENT_CATEGORIES } from '../../../constants/analyticsConstants'
import { PriceInformation } from '../../../hooks/services/graphql/usePartSalesInformationService'

interface PartBasicInformationDatasource {
  askQuotationLabel: TextField
  addToBasketLabel: TextField
  requestAccountLink: LinkField
}

interface PartBasicInformationProps {
  partBasicInformation: Part
  partSpecifications: CommerceSpecification
}

const PartBasicInformation: FC<
  Datasource<PartBasicInformationDatasource> & PartBasicInformationProps
> = ({ datasource, partBasicInformation, partSpecifications }) => {
  const {
    description,
    englishDescription,
    partNumber,
    displayPartNumber,
    supplierPartNumber,
    brand,
    category,
  } = partBasicInformation ?? false

  const partContext = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )
  const { requestAccountLink, askQuotationLabel, addToBasketLabel } = datasource
  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId } = useContext(
    ActiveStoreProviderContext
  )

  const [priceData, setPriceData] = useState<PriceInformation>()

  useEffect(() => {
    if (priceData) {
      pushToDataLayer({
        event: DATA_LAYER.EVENT.VIEW_ITEM,
        ecommerce: {
          currency: priceData.price?.currencyCode,
          value: priceData.price?.netTotalPrice,
          items: [
            {
              item_id: partNumber,
              item_name: englishDescription,
              item_brand: brand?.description,
              item_category:
                partContext.subcategory?.englishDescription ||
                partContext.category?.englishDescription ||
                EVENT_CATEGORIES.UNCATEGORIZED,
              item_list_name: DATA_LAYER.COMPONENT_ORIGIN.PART_BASIC_INFORMATION,
              doa_product: false,
              price: priceData.price?.netUnitPrice,
            },
          ],
        },
      })
    }
  }, [priceData, partNumber, englishDescription, brand, partContext])

  const handleFetchedPrice = (price: PriceInformation) => {
    setPriceData(price)
  }

  if (!partBasicInformation) return null

  return (
    <Box data-t-id="product-detail-page-information">
      <BasicInformation>
        <BasicInformationDescription
          description={description}
          data-t-id="part-description"
        />
        <BasicInformationAttribute
          label={i18next.t('partLabels.partNumberAbbreviation')}
          value={displayPartNumber || partNumber}
          mb={supplierPartNumber ? 1 : 3}
          data-t-id="part-number"
        />
        <BasicInformationAttribute
          label={i18next.t('partLabels.manufacturerPartNumberAbbreviation')}
          value={supplierPartNumber}
          data-t-id="part-supplier-number"
        />
        <BasicInformationBrandImage
          src={brand?.image?.thumbnailImageUrl}
          alt={brand?.description}
          data-t-id="part-brand"
        />
        {partNumber && (
          <PartSalesInformationProvider partNumber={partNumber}>
            <PartPriceAsync
              partNumber={partNumber}
              variant="normal"
              my={3}
              fetchedPrice={handleFetchedPrice}
            />

            <PartPromotionsAsync partNumber={partNumber} my={3} />

            <PartAddToBasket
              partNumber={partNumber}
              partEnglishDescription={englishDescription}
              brandName={brand?.description}
              categoryId={category?.mainCategoryId}
              subcategoryId={category?.subCategoryId}
              label={addToBasketLabel}
              askQuotationLabel={askQuotationLabel}
              requestAccountLink={requestAccountLink}
              dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PRODUCT_DETAIL}
              stepSize={partSpecifications?.packaging?.packageUnitQuantity}
              initialQuantity={partSpecifications?.packaging?.packageUnitQuantity}
              marginBottom={4}
            />

            {loggedIn(user) && actingCompanyId && actingSupplierId && (
              <PartLineStockInformation
                partNumber={partNumber}
                variant="full"
                componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PRODUCT_DETAIL}
                categoryId={category?.mainCategoryId}
                subcategoryId={category?.subCategoryId}
                sx={{
                  paddingBottom: 3,
                }}
              />
            )}
          </PartSalesInformationProvider>
        )}
        <BasicInformationDealerContactInformation />
      </BasicInformation>
    </Box>
  )
}

export default withData(PartBasicInformation, { showMessageWhenPageEditing: true })
