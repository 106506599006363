import React, { ReactNode, useEffect, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import { ICONS } from '../../../../constants/iconConstants'
import { COLORS } from '../../../../constants/themeConstants'
import { alphaColor } from '../../../../helpers/themeHelper'
import ClickableWrapper from '../../ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../IconWrapper/IconWrapper'
import Spinner from '../../Spinner/Spinner'
import FormFieldText from '../FormFieldText'

interface FormInputTextListItemProps {
  deletable?: boolean
  disabled?: boolean
  readOnly?: boolean
  onDelete?: () => void
  error?: string | null
  dataTId?: string
  children: ReactNode
}

const FormInputTextListItem = ({
  deletable = false,
  disabled = false,
  readOnly = false,
  onDelete,
  error,
  children,
  dataTId,
}: FormInputTextListItemProps) => {
  const [focus, setFocus] = useState<boolean>(false)
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    setDeleting(false)
  }, [error])

  return (
    <Flex sx={{ flexDirection: 'column', marginRight: 2, marginBottom: 2 }}>
      <Text
        variant="bodySmall"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingX: 2,
          paddingY: 1,
          borderWidth: 1,
          color: 'gray.2',
          borderColor: focus
            ? 'gray.2'
            : `${disabled ? 'gray.1' : alphaColor(COLORS.DAF_BLUE, 0.16)}`,
          borderStyle: 'solid',
          borderRadius: 'default',
          backgroundColor: disabled
            ? alphaColor(COLORS.LIGHT_GRAY, 0.4)
            : alphaColor(COLORS.DAF_BLUE, 0.08),
          lineHeight: 'buttonText',
          wordBreak: 'break-all',
          width: '100%',
        }}
        data-t-id={
          !deletable && !disabled
            ? 'order-field-primary-recipient'
            : `${dataTId}_${children}`
        }
      >
        {children}

        {deletable && !readOnly && (
          <ClickableWrapper
            data-test-id="form-input-text-list-item"
            data-t-id={`${dataTId}-delete_${children}`}
            onClick={() => {
              setDeleting(true)

              return onDelete?.()
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            sx={{
              marginLeft: 1,
              outline: 'none',
            }}
          >
            {deleting ? (
              <Flex sx={{ width: '24px', justifyContent: 'center' }}>
                <Spinner size={2} />
              </Flex>
            ) : (
              <IconWrapper icon={ICONS.CROSS} size={4} />
            )}
          </ClickableWrapper>
        )}
      </Text>

      {error && <FormFieldText type="error">{error}</FormFieldText>}
    </Flex>
  )
}

export default FormInputTextListItem
