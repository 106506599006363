import { TextProps } from 'theme-ui'
import {
  HEADING_VARIANT_TO_SPACING_MAPPING,
  HEADING_VARIANT_TO_TAG_MAPPING,
} from '../constants/textVariantConstants'
import { HeadingVariant, HeadingTag, HeadingParams } from '../types/layoutService'
import { getByKeyOrDefault } from './recordHelper'

const getHeadingSpacingByVariant = (variant: HeadingVariant) =>
  getByKeyOrDefault(HEADING_VARIANT_TO_SPACING_MAPPING, variant, 0)

const getHeadingTagByVariant = (
  variant: HeadingVariant,
  defaultValue?: HeadingTag
) => getByKeyOrDefault(HEADING_VARIANT_TO_TAG_MAPPING, variant, defaultValue)

export const createTextPropsForParams = (
  params: HeadingParams | undefined,
  defaultVariant: HeadingVariant
): Pick<TextProps, 'variant' | 'as' | 'sx'> => {
  const variant = params?.headingVariant || defaultVariant
  const spacing = getHeadingSpacingByVariant(variant)
  const tag = params?.headingTag || getHeadingTagByVariant(variant)

  return {
    variant,
    as: tag,
    sx: {
      marginBottom: spacing,
    },
    // marginBottom: spacing,
  }
}
