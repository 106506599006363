import { EVENT_CATEGORIES, EVENTS } from '../constants/analyticsConstants'
import { Category } from '../hooks/services/graphql/useCategoryListService'
import { getCategoryById, getSubCategoryById } from './categoryHelper'
import { runOnWindow } from './dom'
import { DATA_LAYER } from '../constants/dataLayerConstants'

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
    dataLayerEcommerce: Record<string, unknown>[]
  }
}

export const measurePartClick = (
  partNumber = '',
  partEnglishDescription = '',
  partBrandDescription = '',
  partMainCategoryId = '',
  partSubCategoryId = '',
  index: number | string = '',
  categories?: Category[],
  componentOrigin = '',
  price?: number
) => {
  const mainCategory = getCategoryById(partMainCategoryId, categories)
  const subCategory = getSubCategoryById(partSubCategoryId, mainCategory)

  pushToDataLayer({
    [DATA_LAYER.EVENT_KEYS.EVENT]: EVENTS.SELECT_ITEM,
    ecommerce: {
      item_list_id: componentOrigin,
      items: [
        {
          item_id: partNumber,
          item_name: partEnglishDescription,
          item_brand: partBrandDescription,
          item_category:
            subCategory?.englishDescription ||
            mainCategory?.englishDescription ||
            EVENT_CATEGORIES.UNCATEGORIZED,
          item_list_name: componentOrigin,
          doa_product: false,
          index,
          ...(price ? { price } : {}),
        },
      ],
    },
  })
}

export const measureDealerPartClick = (
  partNumber = '',
  partName = '',
  partBrandDescription = '',
  index: number | string = '',
  componentOrigin = ''
) => {
  pushToDataLayer({
    [DATA_LAYER.EVENT_KEYS.EVENT]: EVENTS.SELECT_ITEM,
    ecommerce: {
      item_list_id: componentOrigin,
      items: [
        {
          item_id: partNumber,
          item_name: partName,
          item_brand: partBrandDescription,
          item_category: EVENT_CATEGORIES.UNCATEGORIZED,
          item_list_name: componentOrigin,
          doa_product: true,
          index,
        },
      ],
    },
  })
}

export const pushEventToDataLayer = (
  event: string,
  eventValue?: string | number | boolean,
  ecommerce?: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
) =>
  pushToDataLayer({
    event,
    ...(eventValue && { eventValue }),
    ...(ecommerce && { ecommerce }),
  })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pushToDataLayer = (payload: Record<string, any>) =>
  runOnWindow((w) => {
    if (w.dataLayer) {
      if ('ecommerce' in payload) {
        window.dataLayer.push({ ecommerce: null })
      }

      window.dataLayer.push(payload)
    }
  })
