import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useOutsideClick from '../../../hooks/useOutsideClick'
import Animate from '../../atoms/Animate/Animate'
import Spinner, { VARIANT } from '../../atoms/Spinner/Spinner'
import Overlay from '../../molecules/Overlay/Overlay'
import SidePanelHeader from './SidePanelHeader'
import { COLORS } from '../../../constants/themeConstants'

export interface SidePanelProps {
  fetching?: boolean
  active?: boolean
  title?: string
  position?: 'left' | 'right'
  onClose: (action?: 'SIDE_CLICK' | 'CLOSE_BUTTON') => void
  disableOutsideClick?: boolean
  children: ReactNode
}

const PANEL_WIDTH = 370

const SidePanel = ({
  fetching = false,
  active = false,
  title,
  position = 'left',
  onClose,
  disableOutsideClick = false,
  children,
  ...sidePanelProps
}: SidePanelProps) => {
  const ref = useRef<HTMLElement | null>(null)
  const [isVisible, setIsVisible] = useState(active)
  const [BREAKPOINT_M] = useBreakpoints('m')
  const marginPosition = `margin${
    position.charAt(0).toUpperCase() + position.slice(1)
  }`

  useOutsideClick(
    ref,
    () => (disableOutsideClick ? null : onClose('SIDE_CLICK')),
    !active
  )

  useEffect(() => {
    if (active) {
      setIsVisible(true)
    } else {
      // to prevent sidebar being visible when
      // leave animation is finished the timeout
      // is a little less than the animation duration
      const timer = setTimeout(() => setIsVisible(false), 200)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [active])

  return isVisible ? (
    <Overlay isActive={active} hideScrollTrack={!BREAKPOINT_M}>
      <Animate
        from={{
          [position]: BREAKPOINT_M ? 0 : '-100%',
          [marginPosition]: BREAKPOINT_M ? `-${PANEL_WIDTH}px` : '0',
        }}
        enter={{
          [position]: 0,
          [marginPosition]: 0,
        }}
        leave={{
          [position]: BREAKPOINT_M ? 0 : '-100%',
          [marginPosition]: BREAKPOINT_M ? `-${PANEL_WIDTH}px` : '0',
        }}
        active={active}
      >
        <Flex
          as="aside"
          ref={ref}
          sx={{
            position: 'absolute',
            width: ['100%', '100%', `${PANEL_WIDTH}px`],
            bg: 'background',
            height: '100%',
          }}
          {...sidePanelProps}
        >
          <Text
            variant="bodySmall"
            sx={{
              inlineSize: '100%',
              display: 'flex',
              flexDirection: 'column',
              color: COLORS.DARK,
            }}
          >
            <SidePanelHeader onClose={() => onClose('CLOSE_BUTTON')} title={title} />

            {children}
          </Text>
        </Flex>
      </Animate>

      <Flex
        sx={{
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {fetching && <Spinner size={6} variant={VARIANT.LIGHT} />}
      </Flex>
    </Overlay>
  ) : null
}

export default SidePanel
