import React, { ReactNode } from 'react'
import { Link } from 'theme-ui'

interface CardActionProps {
  href?: string
  setInFocus?: (state: boolean) => void
  onClick?: () => void
  children: ReactNode
}

const CardAction = ({ href, onClick, setInFocus, children }: CardActionProps) => (
  <Link
    onMouseEnter={() => setInFocus?.(true)}
    onMouseLeave={() => setInFocus?.(false)}
    href={href}
    onClick={onClick}
  >
    {children}
  </Link>
)

export default CardAction
