import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface SendMaintenanceAppointment {}

export interface SendMaintenanceAppointmentPayloadProps {
  locationCode: string
  vin: string | undefined
  reasonsForAppointment: string[]
  maintenanceSpecification: string
  preferredTimeslot: {
    preferredDate1: string
    preferredDate2: string
    preferredDate3: string
  }
  preferredSlot: string
  contact: string
}

const sendMarkMaintenanceAsPerformedPayload = (
  payload: SendMaintenanceAppointmentPayloadProps,
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<SendMaintenanceAppointment>>> =>
  client.post(`/vehicles/${payload.vin}/plan-appointment`, payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default sendMarkMaintenanceAsPerformedPayload
