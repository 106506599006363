import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import {
  BannerParams,
  Datasource,
  ImageWithFocalPointField,
  LinkField,
  Params,
  ReferenceField,
  TextField,
  VideoField,
} from '../../../types/layoutService'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import Banner, { DEFAULT_BANNER_VARIANT } from '../../atoms/Banner/Banner'
import { BannerGradient } from '../../atoms/Banner/BannerGradient'
import BannerImage from '../../atoms/Banner/BannerImage'
import BannerLogo from '../../atoms/Banner/BannerLogo'
import BannerVideo from '../../atoms/Banner/BannerVideo'
import BannerText from '../../atoms/Banner/BannerText'

export interface LogoItem {
  image?: ImageWithFocalPointField
}

export interface HeroBannerDatasource {
  title: TextField
  subtitle?: TextField
  button?: LinkField
  image?: ImageWithFocalPointField
  video?: VideoField
  logo?: ReferenceField<LogoItem>
}

const HeroBanner: FC<Datasource<HeroBannerDatasource> & Params<BannerParams>> = ({
  datasource: { title, subtitle, image, video, logo },
  params: {
    bottomSpacing,
    variant = DEFAULT_BANNER_VARIANT,
    gradientType,
    logoPosition,
    hasNegativeOffset,
  },
}) => (
  <Banner bottomSpacing={bottomSpacing}>
    <BannerImage imageField={image} variant={variant} />

    <BannerVideo video={video} />

    <BannerText
      title={getTextFieldValue(title)}
      subtitle={subtitle?.value}
      variant={variant}
      hasNegativeOffset={hasNegativeOffset}
    />

    <BannerGradient gradientType={gradientType} visible={!!image?.src} />

    <BannerLogo
      logoImageField={logo?.targetItem?.image}
      logoPosition={logoPosition}
    />
  </Banner>
)

export default withData(HeroBanner)
