import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import ContractServicesIcon from '../../atoms/ContractServicesIcon/ContractServicesIcon'
import ServicePlansCoverageSummaryItem from './ServicePlansCoverageSummaryItem'
import { COLORS } from '../../../constants/themeConstants'
import { ICONS } from '../../../constants/iconConstants'
import ServicePlansCoverageSummaryBullets from '../ServicePlansCoverageSummaryBullets/ServicePlansCoverageSummaryBullets'
import VehicleRmDocuments from '../VehicleRmDocuments/VehicleRmDocuments'

interface ServicePlansCoverageSummaryProps {
  vehicle: VehicleDetailResponse
}

const ServicePlansCoverageSummary = ({
  vehicle,
}: ServicePlansCoverageSummaryProps) => (
  <Box>
    <Text variant="heading2" sx={{ marginBottom: [4, 4, 5] }}>
      {i18next.t('servicePlan.titleCoverageSummary')}
    </Text>
    {vehicle.rmContract.servicePlan.coverageSummary.contractPackageAbbreviation && (
      <Flex sx={{ alignItems: 'center' }}>
        <Box>
          <ContractServicesIcon
            iconSize="medium"
            contractPackageAbbreviation={
              vehicle.rmContract.servicePlan.coverageSummary
                .contractPackageAbbreviation
            }
          />
        </Box>
        <Box>
          <Text
            variant="bodySmall"
            color={COLORS.BLACK}
            sx={{ marginLeft: 2, fontWeight: 'bold' }}
          >
            {vehicle?.rmContract?.servicePlan?.packageName}
          </Text>
        </Box>
      </Flex>
    )}
    <Box
      sx={{
        marginTop: 2,
        display: 'grid',
        gridTemplateColumns: ['repeat(2, 1fr)'],
        gap: 2,
      }}
    >
      <Box>
        <ServicePlansCoverageSummaryItem
          icon={ICONS.MAINTENANCE}
          label={i18next.t('servicePlan.maintenanceLabel')}
          active={
            !!vehicle.rmContract.servicePlan.coverageSummary.coverage.maintenance
          }
        />
      </Box>
      <Box>
        <ServicePlansCoverageSummaryItem
          icon={ICONS.WEAR_TEAR}
          label={i18next.t('servicePlan.wearTearLabel')}
          active={!!vehicle.rmContract.servicePlan.coverageSummary.coverage.wearTear}
        />
      </Box>
      <Box>
        <ServicePlansCoverageSummaryItem
          icon={ICONS.DRIVELINE}
          label={i18next.t('servicePlan.driveLineLabel')}
          active={
            !!vehicle.rmContract.servicePlan.coverageSummary.coverage.driveline
          }
        />
      </Box>
      <Box>
        <ServicePlansCoverageSummaryItem
          icon={ICONS.NON_DRIVELINE}
          label={i18next.t('servicePlan.nonDriveLineLabel')}
          active={
            !!vehicle.rmContract.servicePlan.coverageSummary.coverage.nonDriveline
          }
        />
      </Box>
    </Box>
    <ServicePlansCoverageSummaryBullets vehicle={vehicle} />
    <VehicleRmDocuments />
  </Box>
)

export default ServicePlansCoverageSummary
