import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import usePartSparePartsService, {
  SPARE_PART_DIRECTION_TO,
} from '../../../hooks/services/graphql/usePartSparePartListService'
import {
  Datasource,
  Flag,
  Params,
  SliderVariant,
  TextField,
} from '../../../types/layoutService'
import { PardCardLayoutServiceData } from '../../molecules/Card/PartCard/PartCard'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { PartContext } from '../../../types/sitecoreContextProps'
import PartCardListResponsive from '../CardList/PartCardListResponsive'

interface PartSparePartsDatasource {
  title: TextField
}

interface PartSparePartsParams {
  variant: SliderVariant
  topSpacing: Flag
  bottomSpacing: Flag
}

const PartSpareParts: FC<
  Datasource<PartSparePartsDatasource> &
    PardCardLayoutServiceData &
    Params<PartSparePartsParams>
> = ({
  datasource: { title },
  rendering: { uid },
  params: { variant, topSpacing, bottomSpacing },
  partPage,
}) => {
  const { part: contextPart } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  const [fetching, spareParts] = usePartSparePartsService(
    contextPart?.partNumber,
    SPARE_PART_DIRECTION_TO
  )

  if (fetching || !spareParts?.length) return null

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid}>
      <PartCardListResponsive
        title={getTextFieldValue(title)}
        variant={variant}
        fetching={fetching}
        partList={spareParts}
        partPage={partPage}
        topSpacing={topSpacing === '1'}
        bottomSpacing={bottomSpacing === '1'}
        dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_SPARE_PARTS}
      />
    </AnchorMenuTarget>
  )
}

export default withData(PartSpareParts, { showMessageWhenPageEditing: true })
