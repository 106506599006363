import React, { useContext, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { useIsFetching } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../../reducers'
import {
  SERVICEPLAN_CONTRACT_STATE_CLOSED,
  VEHICLE_DETAIL_MAINTENANCE_TAB,
  VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB,
  VEHICLE_SERVICES_NON_MULTISUPPORT,
} from '../../../constants/dafCompanyVehicleConstants'
import { getQueryParam } from '../../../helpers/urlHelper'
import ActionButtonList from '../../molecules/ActionButtonList/ActionButtonList'
import ActionButtonListButton from '../../molecules/ActionButtonList/ActionButtonListButton'
import {
  toggleEditServicePlanPanel,
  toggleEndMaintenancePlanPanel,
  toggleMaintenanceAppointmentPanel,
  toggleMaintenanceInputPanel,
  toggleMarkMaintenanceAsPerformedPanel,
  toggleMileageSettlementInputPanel,
  toggleRequestQuotePanel,
} from '../../../actions/dafCompanyVehicleActions'
import Button from '../../atoms/Button/Button'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import {
  COMMERCE_SITE_CONTEXT,
  MULTISUPPORT_SITE_CONTEXT,
} from '../../../constants/environmentConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION } from '../../../constants/userConstants'
import { hasRole, userCultureCode } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import VehicleMaintenanceList from '../VehicleMaintenanceList/VehicleMaintenanceList'
import { NonMultiSupportServices } from '../../../services/rest/ecommerce/dafVehicleDetail'
import VehicleRecallList from '../VehicleRecallList/VehicleRecallList'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'
import VehicleRecallTypesSidePanel from '../SidePanel/VehicleRecallTypesSidepanel/VehicleRecallTypesSidepanel'
import { orderPartsFormMaintenanceLink } from '../../../helpers/vehicleHelper'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'
import { IS_DEALER_ADMIN_QUERY_PARAM } from '../../../constants/urlConstants'

export interface VehicleDetailMaintenanceDatasource {
  noRequestForMaintenancePlanText: TextField
  requestQuoteActionTitle: TextField
  requestMaintenanceAppointmentActionTitle: TextField
  noVehicleUpgradesAvailableText: TextField
  recallAdviceAttention: TextField
  recallAdviceWarning: TextField
  recallAdviceImportant: TextField
  recallExplanationWarning: TextField
  recallExplanationAttention: TextField
  recallExplanationImportant: TextField
}

const VehicleDetailMaintenance = ({
  datasource,
}: Datasource<VehicleDetailMaintenanceDatasource>) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { userProfile } = useContext(UserContext)
  const { vehicleData, selectedJobs } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const isFetchingVehicleData = useIsFetching({ queryKey: ['dafVehicleData'] })
  const [isActive, setIsActive] = useState<boolean>(false)
  const [recallTypesSidePanelOpen, setRecallTypesSidePanelOpen] =
    useState<boolean>(false)
  const currentActiveTab = getQueryParam(VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB)
  const {
    site,
    user,
    featureToggles,
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const handleRequestMaintenancePlanClick = (
    chassisNumber: string,
    isAdjustMaintenance?: boolean
  ) => {
    dispatch(
      toggleMaintenanceInputPanel({
        isActive: true,
        chassisNumber,
        isAdjustMaintenance,
      })
    )
  }

  const shouldShowMaintenanceList = () => {
    if (!vehicleData) return false

    return (
      (vehicleData?.rmContract &&
        vehicleData?.rmContract?.servicePlan?.status.contractState !==
          SERVICEPLAN_CONTRACT_STATE_CLOSED) ||
      vehicleData?.vehicleDetail?.nonMultiSupportServices.some(
        (service: NonMultiSupportServices) =>
          service.type === VEHICLE_SERVICES_NON_MULTISUPPORT
      )
    )
  }

  useEffect(() => {
    setIsActive(currentActiveTab === VEHICLE_DETAIL_MAINTENANCE_TAB)
  }, [isActive, currentActiveTab])

  const [isDealerAdmin, setIsDealerAdmin] = useState(false)

  useEffect(() => {
    const isDealerAdmin = getQueryParam(IS_DEALER_ADMIN_QUERY_PARAM)
    if (isDealerAdmin === 'true') setIsDealerAdmin(true)
  }, [location])

  return (
    <>
      <Box sx={{ display: !isActive ? 'none' : '' }}>
        {!isFetchingVehicleData && vehicleData && (
          <>
            <Flex
              sx={{
                marginBottom: 3,
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 16,
              }}
            >
              <Box sx={{ marginBottom: [3, 3, 0] }}>
                <Text
                  sx={{
                    fontSize: [3, 3, 2],
                    fontWeight: 'bold',
                  }}
                >
                  {i18next.t('maintenance.upcomingMaintenanceTitle')}
                </Text>
              </Box>
              <Box sx={{ alignSelf: 'flex-start', marginLeft: [0, 'auto', 'auto'] }}>
                <ActionButtonList variant="primary">
                  {site.name === COMMERCE_SITE_CONTEXT &&
                    vehicleData.vehicleActions?.orderParts?.showOption && (
                      <ActionButtonListButton
                        label={i18next.t('myFleet.orderPartsForMaintenance')}
                        as="a"
                        href={orderPartsFormMaintenanceLink(
                          cultureCode,
                          vehicleData.vehicleDetail.vehicleAttributes.chassisNumber,
                          selectedJobs ||
                            vehicleData.servicePlanner.service.upcomingJobs
                        )}
                      />
                    )}
                  {site.name === COMMERCE_SITE_CONTEXT &&
                    vehicleData.vehicleActions?.searchParts?.showOption && (
                      <ActionButtonListButton
                        label={i18next.t('myFleet.searchPartsButtonLabel')}
                        action={() => {
                          history.push(
                            `/${userCultureCode(
                              userProfile
                            )}/embed?source=/${userCultureCode(
                              userProfile
                            )}/DafSearch?chassisNumber=${
                              vehicleData?.vehicleDetail?.vehicleAttributes
                                ?.chassisNumber ||
                              vehicleData?.vehicleDetail?.vehicleAttributes?.vin ||
                              vehicleData?.vehicleDetail?.vehicleAttributes
                                ?.registrationNumber
                            }`
                          )
                        }}
                      />
                    )}

                  {(site.name === MULTISUPPORT_SITE_CONTEXT || isDealerAdmin) &&
                    vehicleData?.vehicleActions?.requestQuote?.showOption &&
                    datasource.requestQuoteActionTitle?.value && (
                      <ActionButtonListButton
                        label={datasource.requestQuoteActionTitle.value}
                        action={() =>
                          dispatch(
                            toggleRequestQuotePanel({
                              isActive: true,
                              chassisNumber:
                                vehicleData?.vehicleDetail.vehicleAttributes
                                  .chassisNumber,
                            })
                          )
                        }
                      />
                    )}

                  {site.name !== MULTISUPPORT_SITE_CONTEXT &&
                    vehicleData.vehicleActions.requestMaintenancePlan.showOption && (
                      <ActionButtonListButton
                        label={i18next.t('panels.requestMaintenancePlan')}
                        action={() =>
                          handleRequestMaintenancePlanClick(
                            vehicleData?.vehicleDetail?.vehicleAttributes
                              ?.chassisNumber
                          )
                        }
                        data-t-id="myfleet-actionbutton-requestmaintenance"
                      />
                    )}
                  {vehicleData.vehicleActions.editServicePlan.showOption && (
                    <ActionButtonListButton
                      label={i18next.t('formLabels.editServicePlan')}
                      action={() => {
                        dispatch(
                          toggleEditServicePlanPanel({
                            isActive: true,
                            chassisNumber:
                              vehicleData.vehicleDetail.vehicleAttributes
                                ?.chassisNumber,
                          })
                        )
                      }}
                    />
                  )}
                  {(site.name === MULTISUPPORT_SITE_CONTEXT || isDealerAdmin) &&
                    vehicleData.vehicleActions.inputMileage.showOption && (
                      <ActionButtonListButton
                        label={i18next.t('formLabels.inputMileageActionButtonLabel')}
                        action={() =>
                          dispatch(
                            toggleMileageSettlementInputPanel({
                              isActive: true,
                              chassisNumber:
                                vehicleData?.vehicleDetail.vehicleAttributes
                                  .chassisNumber,
                            })
                          )
                        }
                      />
                    )}

                  {vehicleData.vehicleActions.requestMaintenanceAppointment
                    .showOption && (
                    <ActionButtonListButton
                      label={
                        datasource.requestMaintenanceAppointmentActionTitle.value
                      }
                      action={() =>
                        dispatch(
                          toggleMaintenanceAppointmentPanel({
                            isActive: true,
                          })
                        )
                      }
                    />
                  )}

                  {vehicleData.vehicleActions.adjustMaintenancePlan.showOption && (
                    <ActionButtonListButton
                      label={i18next.t('maintenance.adjustMaintenancePlan')}
                      action={() =>
                        handleRequestMaintenancePlanClick(
                          vehicleData?.vehicleDetail?.vehicleAttributes
                            ?.chassisNumber,
                          true
                        )
                      }
                    />
                  )}

                  {vehicleData.vehicleActions.endMaintenancePlan.showOption && (
                    <ActionButtonListButton
                      label={i18next.t('maintenance.endMaintenancePlan')}
                      action={() => {
                        dispatch(
                          toggleEndMaintenancePlanPanel({
                            isActive: true,
                            chassisNumber:
                              vehicleData?.vehicleDetail?.vehicleAttributes
                                ?.chassisNumber,
                          })
                        )
                      }}
                    />
                  )}

                  {shouldShowMaintenanceList() && (
                    <ActionButtonListButton
                      label={i18next.t('maintenance.reportAsPerformed')}
                      subLabel={
                        !selectedJobs?.length
                          ? i18next.t('maintenance.firstSelectExplanation')
                          : undefined
                      }
                      disabled={!selectedJobs?.length}
                      action={() => {
                        dispatch(
                          toggleMarkMaintenanceAsPerformedPanel({
                            isActive: true,
                          })
                        )
                      }}
                    />
                  )}
                </ActionButtonList>
              </Box>
            </Flex>

            <VehicleMaintenanceList
              vehicleDetail={vehicleData}
              shouldShowMaintenanceList={shouldShowMaintenanceList()}
            />

            {(!vehicleData?.rmContract ||
              vehicleData?.rmContract?.servicePlan?.status.contractState ===
                SERVICEPLAN_CONTRACT_STATE_CLOSED) &&
              vehicleData?.vehicleDetail?.nonMultiSupportServices.length === 0 &&
              vehicleData?.hasDafConnect && (
                <Flex
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                    color: COLORS.GRAY,
                    paddingBlock: 32,
                    borderBlockEnd: '1px solid',
                    borderColor: 'gray.1',
                  }}
                >
                  <Text>{i18next.t('servicePlan.noServicePlanDescription')}</Text>

                  {site.name === MULTISUPPORT_SITE_CONTEXT &&
                    vehicleData?.vehicleActions?.requestQuote?.showOption && (
                      <Button
                        onClick={() => {
                          dispatch(
                            toggleRequestQuotePanel({
                              isActive: true,
                              chassisNumber:
                                vehicleData?.vehicleDetail.vehicleAttributes
                                  .chassisNumber,
                            })
                          )
                        }}
                      >
                        {datasource.requestQuoteActionTitle.value}
                      </Button>
                    )}

                  {site.name !== MULTISUPPORT_SITE_CONTEXT &&
                    !hasRole(
                      user,
                      USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
                    ) &&
                    featureToggles?.requestMaintenancePlan && (
                      <Button
                        variant="primary"
                        onClick={() =>
                          handleRequestMaintenancePlanClick(
                            vehicleData?.vehicleDetail?.vehicleAttributes
                              ?.chassisNumber
                          )
                        }
                        data-t-id="vehicledetail-togglepanel-requestmaintenanceplan"
                      >
                        {i18next.t('servicePlan.requestMaintenancePlanButtonLabel')}
                      </Button>
                    )}
                </Flex>
              )}

            {(!vehicleData?.rmContract ||
              vehicleData?.rmContract?.servicePlan?.status.contractState ===
                SERVICEPLAN_CONTRACT_STATE_CLOSED) &&
              !vehicleData?.vehicleDetail?.nonMultiSupportServices.some(
                (service: NonMultiSupportServices) =>
                  service.type === VEHICLE_SERVICES_NON_MULTISUPPORT
              ) &&
              !vehicleData?.hasDafConnect && (
                <Flex
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                    color: COLORS.GRAY,
                    paddingBlock: 32,
                    borderBlockEnd: '1px solid',
                    borderColor: 'gray.1',
                  }}
                >
                  <Text>{i18next.t('servicePlan.noServicePlanDescription')}</Text>

                  {!hasRole(
                    user,
                    USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
                  ) && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleRequestMaintenancePlanClick(
                          vehicleData?.vehicleDetail?.vehicleAttributes
                            ?.chassisNumber
                        )
                      }}
                      data-t-id="vehicledetail-togglepanel-requestmaintenanceplan"
                    >
                      {i18next.t('servicePlan.requestMaintenancePlanButtonLabel')}
                    </Button>
                  )}
                </Flex>
              )}

            <Flex
              sx={{
                marginBlockStart: 48,
                marginBlockEnd: 24,
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Text
                sx={{
                  fontSize: [3, 3, 2],
                  fontWeight: 'bold',
                }}
              >
                {i18next.t('urgentTasks.vehicleUpgrades')}
              </Text>

              <ClickableWrapper
                sx={{ padding: 2 }}
                onClick={() => setRecallTypesSidePanelOpen(true)}
              >
                <IconWrapper icon={ICONS.TOOLTIP} size={3} color={COLORS.DAF_BLUE} />
              </ClickableWrapper>
            </Flex>
            <VehicleRecallList vehicleDetail={vehicleData} datasource={datasource} />
          </>
        )}
      </Box>

      <VehicleRecallTypesSidePanel
        active={recallTypesSidePanelOpen}
        onClose={() => setRecallTypesSidePanelOpen(false)}
        datasource={datasource}
      />
    </>
  )
}

export default withData(VehicleDetailMaintenance)
