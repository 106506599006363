import { useEffect, useState } from 'react'
import {
  BREAKPOINT_L,
  BREAKPOINT_M,
  BREAKPOINT_S,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  BREAKPOINT_XXL,
} from '../constants/themeConstants'
import { isBrowser } from '../helpers/dom'

export type Breakpoints = Array<
  | typeof BREAKPOINT_XS
  | typeof BREAKPOINT_S
  | typeof BREAKPOINT_M
  | typeof BREAKPOINT_L
  | typeof BREAKPOINT_XL
  | typeof BREAKPOINT_XXL
>

const useMediaQuery = (minWidth: number, initialized = false): boolean => {
  const defaultState = !isBrowser() ? false : window.innerWidth > minWidth

  const [isMatch, setMatch] = useState<boolean>(defaultState)

  useEffect(() => {
    if (!isBrowser() || !initialized) return undefined

    const mediaQuery = window.matchMedia(`(min-width: ${minWidth}px)`)

    const onChange = (e: MediaQueryListEvent) => setMatch(e.matches)

    mediaQuery.addEventListener('change', onChange)

    return () => {
      mediaQuery.removeEventListener('change', onChange)
    }
  }, [initialized, minWidth])

  return isMatch
}

export default useMediaQuery
