import React, { FC } from 'react'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { LinkField, TextField } from '../../../types/layoutService'
import Container from '../../atoms/Container/Container'
import { FooterItems, SocialChannels } from './Footer'
import FooterColumn from './FooterColumn'
import FooterNavigationColumn from './FooterNavigationColumn'
import FooterSocialLinks from './FooterSocialLinks'

interface FooterColumnsProps {
  navigationItems: FooterItems
  socialChannelsTitle: TextField
  socialChannels: LinkField<SocialChannels>
}

const FooterColumns: FC<FooterColumnsProps> = ({
  navigationItems,
  socialChannelsTitle,
  socialChannels,
}) => (
  <Container
    sx={{
      pt: [3, 3, 7],
      pb: [3, 3, 5],
      width: '100%',
      color: 'textLight',
      display: 'flex',
      flexWrap: 'wrap',
    }}
  >
    {!!navigationItems?.items?.length &&
      navigationItems.items.map((navigationItem, i) => (
        <FooterNavigationColumn navigationItem={navigationItem} key={i.toString()} />
      ))}

    <FooterColumn title={getTextFieldValue(socialChannelsTitle)}>
      <FooterSocialLinks socialChannels={socialChannels} />
    </FooterColumn>
  </Container>
)

export default FooterColumns
