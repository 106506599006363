import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface ValidateCompanyVehicle {
  result: ValidateCompanyVehicleResult
  messages: ValidateCompanyVehicleResultMessage[]
}

export interface ValidateCompanyVehicleResultMessage {
  message: string
  severity: string
  code: string
  args: string[]
}

export interface ValidateCompanyVehicleResult {
  vin: string
  make: string
  vehicleType: string
  firstRegistrationDate: string
  urlVehicleModelYearSeries: string
}

export const validateCompanyVehicle = (
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  vin: string,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<ValidateCompanyVehicle>> =>
  client.get(`/validate-company-vehicle/${vin}`, {
    ...createRequestConfig(user),
    params: queryParams,
    cancelToken,
  })
