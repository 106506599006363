import React, { useEffect, useMemo, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Box, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  ImageWithFocalPointField,
  LinkField,
  TextField,
  VideoField,
} from '../../../types/layoutService'
import { FONT_AWESOME_ICONS, ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { COLORS } from '../../../constants/themeConstants'
import Button from '../../atoms/Button/Button'
import DigitalMagazineSlide from '../../molecules/DigitalMagazine/DigitalMagazineSlide'
import DigitalMagazineMenu from '../../molecules/DigitalMagazine/DigitalMagazineMenu'
import { runOnDocument, runOnWindow, scrollTo } from '../../../helpers/dom'
import { kebabCase } from '../../../helpers/kebabCaseHelper'
import { pushToDigitalMagazineMenu } from '../../../actions/digitalMagazine/digitalMagazineActions'
import { RootState } from '../../../reducers'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import Link from '../../atoms/Link/Link'

interface MagazineCoverDatasource {
  menutitle: TextField
  backgroundImage?: ImageWithFocalPointField
  backgroundVideo?: VideoField
  primaryButton?: LinkField
  title: TextField
  subject: TextField
}

const MagazineCover = ({ datasource }: Datasource<MagazineCoverDatasource>) => {
  const menuItems = useSelector((state: RootState) => state.digitalMagazine)
  const [nextSlide, setNextSlide] = useState<string>('')
  const dispatch = useDispatch()
  const ref = useRef(null)
  const sections = runOnDocument((doc) => doc.getElementsByTagName('section'))

  // add class to <html> needed for scroll snap css
  runOnWindow(() => document.documentElement.classList.add('digital-magazine'))

  useEffect(() => {
    setNextSlide(sections?.[1]?.getAttribute('id') || '')
  }, [sections])

  const handleClickScroll = () => {
    const element = document.getElementById(nextSlide)
    const nextElement = menuItems.find((item) => item.menuId === nextSlide)
    if (!element) return

    scrollTo({
      top:
        element.getBoundingClientRect().top +
        document.documentElement.scrollTop -
        72,
      behavior: 'smooth',
    })

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT.MAGAZINE_NAVIGATION,
      navigation_type: 'arrow click',
      step_number: '2',
      step_name: nextElement?.menuTitle,
    })
  }

  const slideMenuData = useMemo(
    () => ({
      menuTitle: datasource.menutitle.value,
      menuId: kebabCase(datasource.menutitle.value),
    }),
    [datasource.menutitle.value]
  )

  useEffect(() => {
    dispatch(pushToDigitalMagazineMenu(slideMenuData))
  }, [dispatch, slideMenuData])

  return (
    <>
      <DigitalMagazineSlide
        image={datasource.backgroundImage}
        video={datasource.backgroundVideo}
        id={kebabCase(datasource.menutitle.value)}
        ref={ref}
        isCover
      >
        {datasource.primaryButton && (
          <Box
            sx={{
              position: 'absolute',
              right: '40px',
              textAlign: 'center',
              marginTop: 5,
            }}
          >
            <Link
              href={datasource.primaryButton?.url}
              title={datasource.primaryButton?.targetItem?.name}
              target="_blank"
              onClick={() =>
                pushToDataLayer({
                  [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT.CALL_TO_ACTION,
                  call_to_action_name: datasource.primaryButton?.text,
                  call_to_action_type:
                    DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
                  destination_url: datasource.primaryButton?.url,
                })
              }
            >
              <Button variant="outline" icon={FONT_AWESOME_ICONS.FILE_PDF}>
                {datasource.primaryButton?.text}
              </Button>
            </Link>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <motion.div
            initial={{
              position: 'absolute',
              opacity: 0,
              y: 50,
            }}
            animate={{
              color: 'white',
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeOut',
              duration: 0.5,
              opacity: { duration: 1 },
            }}
          >
            <Text
              as="h1"
              sx={{
                fontSize: [5, 5, 6, 7],
                fontWeight: 'light',
                textAlign: 'center',
                textTransform: 'uppercase',
              }}
            >
              {datasource.title.value}
            </Text>
          </motion.div>
          <motion.div
            initial={{
              position: 'absolute',
              color: 'white',
              y: -80,
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            transition={{
              ease: 'easeOut',
              opacity: { duration: 1 },
            }}
          >
            <Text
              sx={{
                fontSize: [4],
                fontWeight: 'bold',
              }}
            >
              {datasource.subject.value}
            </Text>
          </motion.div>
          <motion.div
            initial={{ position: 'absolute', bottom: 100 }}
            transition={{ duration: 1, repeat: Infinity, repeatType: 'reverse' }}
            animate={{ bottom: 110 }}
          >
            <Button
              variant="infinite"
              onClick={handleClickScroll}
              sx={{ cursor: 'pointer' }}
            >
              <IconWrapper icon={ICONS.ARROW_DOWN} size={8} color={COLORS.WHITE} />
            </Button>
          </motion.div>
        </Box>
      </DigitalMagazineSlide>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.7 }}
      >
        <DigitalMagazineMenu />
      </motion.div>
    </>
  )
}

export default withData(MagazineCover)
