import React, { FC, Fragment, useCallback, useMemo } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import Link from '../../atoms/Link/Link'
import { getFilterStringByActiveSearchResultFilterObject } from '../../../helpers/filterHelper'
import CollapsibleList from '../../atoms/CollapsibleList/CollapsibleList'
import { FilterGroup } from '../../../types/filterProps'
import { displayValueOrConvertBooleanToHumanReadableString } from '../../../constants/partAttributeHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import {
  FILTER_GROUP_APPLICATION_IDS,
  FILTER_GROUP_APPLICATION_MAKES_IDS,
  FILTER_GROUP_APPLICATION_MODELS_IDS,
} from '../../../constants/searchConstants'
import { TRP_THEME } from '../../../constants/themeConstants'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { Category } from '../../../hooks/services/graphql/useCategoryListService'

interface PartsNavigationPanelFilterProps {
  filters: FilterGroup[]
  mainMenuCategories: Category[] | undefined
  categoryId?: string
  subCategoryId?: string
  partsBaseUri?: string
}

const PartsNavigationPanelFilter: FC<PartsNavigationPanelFilterProps> = ({
  filters,
  mainMenuCategories,
  categoryId,
  subCategoryId,
  partsBaseUri,
}) => {
  const {
    site: { theme },
  } = useSitecoreContext()
  const { mainNavigation } = useTheme()

  const parentCategory = useMemo(
    () => mainMenuCategories?.find((category) => category.id === categoryId || ''),
    [categoryId, mainMenuCategories]
  )
  const subCategory = useMemo(
    () =>
      parentCategory?.subCategories?.find(
        (category) => category.id === subCategoryId || ''
      ),
    [parentCategory, subCategoryId]
  )
  const visibleFilters = useMemo(() => filters.slice(0, 3), [filters])

  const customDescriptionMap: Record<string, string> = useMemo(
    () => ({
      [FILTER_GROUP_APPLICATION_IDS]: i18next.t('filteringLabels.type'),
      [FILTER_GROUP_APPLICATION_MAKES_IDS]: i18next.t('filteringLabels.make'),
      [FILTER_GROUP_APPLICATION_MODELS_IDS]: i18next.t('filteringLabels.model'),
    }),
    []
  )

  const onFilterClick = useCallback(
    (filterDescription: string, filterValueDescription: string) => {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
        navigate_type: DATA_LAYER.CUSTOM_DIMENSION.NAVIGATION_TYPE.MEGA_MENU,
        main_category: parentCategory?.englishDescription || '',
        sub_category: subCategory?.englishDescription || '',
        filter_name: filterDescription,
        filter_value: filterValueDescription,
        page_type: '',
      })
    },
    [parentCategory, subCategory]
  )

  return (
    <Box
      sx={{ flexGrow: 1, minHeight: 0, overflowY: 'auto', pl: 5, pr: 3, pb: 4 }}
      data-t-id="parts-navigation-filters"
    >
      <Text
        as="span"
        variant="bodySmall"
        sx={{
          display: ['none', 'none', 'none', 'block'],
          color: theme?.name === TRP_THEME ? mainNavigation.textColor : 'gray.3',
          pl: 3,
          pt: 4,
          lineHeight: 'menuList',
        }}
      >
        {i18next.t('filteringLabels.popularFilters')}
      </Text>

      {visibleFilters.map((category, i) => (
        <Fragment key={i.toString()}>
          <Text
            as="h4"
            key={i.toString()}
            variant="bodySmall"
            sx={{
              pt: 4,
              px: 3,
              pb: 1,
              color: mainNavigation.textColor,
              lineHeight: 'menuList',
              fontWeight: 'bold',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {customDescriptionMap?.[category.id] || category.description}
          </Text>

          <CollapsibleList
            as="menu"
            sx={{
              pl: 3,
              'li button': {
                color: mainNavigation.textActiveColor,
                textDecoration: 'underline',
              },
            }}
          >
            {category?.filterOptions?.map((filterOption, ii) => (
              <Text
                key={ii.toString()}
                variant="bodySmall"
                as="li"
                sx={{
                  py: 1,
                  listStyle: 'none',
                  lineHeight: 'menuList',
                  fontWeight: 'normal',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
              >
                <Link
                  data-t-id={`parts-navigation-filter-${filterOption.value}`}
                  onClick={() =>
                    onFilterClick(
                      category.englishDescription,
                      filterOption.description
                    )
                  }
                  href={`${partsBaseUri}/${
                    subCategory?.id
                  }?${getFilterStringByActiveSearchResultFilterObject(
                    {
                      [category.id]: [filterOption.value],
                    },
                    undefined,
                    true
                  )}`}
                  sx={{
                    outline: 'none',
                    color: mainNavigation.textColor,
                    ':hover, :active, :focus': {
                      color:
                        theme?.name === TRP_THEME
                          ? mainNavigation.textColor
                          : 'primary',
                      opacity: 1,
                    },
                  }}
                >
                  {displayValueOrConvertBooleanToHumanReadableString(
                    filterOption.description
                  )}
                </Link>
              </Text>
            ))}
          </CollapsibleList>
        </Fragment>
      ))}
    </Box>
  )
}

export default PartsNavigationPanelFilter
