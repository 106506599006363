import React, { FC, useMemo } from 'react'
import { Box } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import CheckList from '../../atoms/CheckList/CheckList'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  Flag,
  HeadingParams,
  Params,
  TextField,
} from '../../../types/layoutService'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import EmptyGridBox from '../../organisms/EmptyGridBox/EmptyGridBox'
import SitecoreText from '../SitecoreText/SitecoreText'

interface SummaryListDatasource {
  items: TextField
  heading: TextField
}

interface SummaryListParams {
  ySpacing: Flag
}

const getListItems = (text: string) => {
  // Split text by line separator (CR)LF
  let lines = text.split(/\r?\n/)

  // Remove empty lines
  lines = lines.filter((line) => !!line)

  // Map string array to list item
  return lines.map((line) => ({ description: line }))
}

const SummaryList: FC<
  Datasource<SummaryListDatasource> & Params<SummaryListParams & HeadingParams>
> = ({ datasource: { items, heading }, rendering: { componentName }, params }) => {
  const { pageEditing } = useSitecoreContext()
  const listItems = useMemo(() => getListItems(getTextFieldValue(items)), [items])

  if (!listItems?.length && pageEditing) {
    return <EmptyGridBox componentName={componentName} />
  }

  if (!listItems?.length) return null

  return (
    <Box my={params.ySpacing === '1' ? 7 : 3}>
      <SitecoreText field={heading} defaultVariant="heading3" params={params} />
      <CheckList items={listItems} />
    </Box>
  )
}

export default withData(SummaryList)
