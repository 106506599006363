import i18next from 'i18next'
import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { FONT_AWESOME_ICONS } from '../../../../../constants/iconConstants'
import IconWrapper from '../../../../atoms/IconWrapper/IconWrapper'
import PartLine, {
  PartLineGridTemplate,
} from '../../../../molecules/PartLine/PartLine'

interface PartSearchLineAlternativeProps {
  gridTemplate?: PartLineGridTemplate
}

const PartSearchLineAlternative: FC<PartSearchLineAlternativeProps> = ({
  gridTemplate,
}) => (
  <PartLine gridTemplate={gridTemplate} isAlternative>
    <Text
      variant="heading5"
      sx={{
        gridColumn: ['1 / 2', '1 / 2', '2 / -1'],
        alignItems: 'center',
        ml: -1,
        '> svg': {
          pr: 3,
        },
      }}
    >
      <IconWrapper icon={FONT_AWESOME_ICONS.EXCHANGE} size={2} />

      {i18next.t('partLabels.alternative')}
    </Text>
  </PartLine>
)

export default PartSearchLineAlternative
