import React from 'react'
import { Box, Text } from 'theme-ui'
import {
  ImageWithFocalPointField,
  LinkField,
  TextField,
} from '../../../types/layoutService'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'
import Button from '../../atoms/Button/Button'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { COLORS } from '../../../constants/themeConstants'

interface ProductOrServiceTileProps {
  title: TextField
  link: LinkField
  image: ImageWithFocalPointField
  imageSizes?: string
}

const ProductOrServiceTile = ({
  title,
  link,
  image,
  imageSizes,
}: ProductOrServiceTileProps) => (
  <Box sx={{ position: 'relative' }}>
    <Box
      sx={{
        '&::before': {
          content: '""',
          position: 'absolute',
          inset: 0,
          background: 'linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.60))',
        },
      }}
    >
      <SitecoreImage
        imageField={image}
        sx={{
          height: [280, 310, 310],
          width: '100%',
        }}
        sizes={imageSizes}
      />
    </Box>
    <Box
      sx={{
        zIndex: 1,
        position: 'absolute',
        bottom: 5,
        left: 5,
        right: 5,
      }}
    >
      <Text
        as="h3"
        sx={{
          color: COLORS.WHITE,
          fontSize: [3, 4, 4],
          fontWeight: 'normal',
          marginBottom: [3, 4, 4],
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title?.value}
      </Text>
      {link !== undefined && link.url !== undefined && link.text !== undefined && (
        <ClickableWrapper href={link?.url} as="a">
          <Button variant="ghost" sx={{ color: 'white', borderColor: 'white' }}>
            {link?.text}
          </Button>
        </ClickableWrapper>
      )}
    </Box>
  </Box>
)

export default ProductOrServiceTile
