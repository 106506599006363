import { ShoppingBasketWizardState } from '.'

export default (state: ShoppingBasketWizardState) => {
  if (!state?.order) return state

  return {
    ...state,
    mutation: {
      ...state.mutation,
      mutationCount: state.mutation.mutationCount + 1,
      mutationInProgress: true,
    },
  }
}
