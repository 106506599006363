import React, { useEffect, useState } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import {
  VEHICLE_SERVICES_NO_SERVICES,
  VEHICLE_SERVICES_NON_MULTISUPPORT,
} from '../../../constants/dafCompanyVehicleConstants'
import { alphaColor } from '../../../helpers/themeHelper'

interface ContractPackageAbbreviationProps extends Omit<BoxProps, 'css'> {
  contractPackageAbbreviation: string
  iconSize?: 'small' | 'medium' | 'large'
}

const ContractServicesIcon = ({
  contractPackageAbbreviation,
  iconSize = 'small',
  sx,
}: ContractPackageAbbreviationProps) => {
  const [iconBackgroundColor, setIconBackgroundColor] = useState<string>()
  const [iconTextColor, setIconTextColor] = useState<string>(COLORS.WHITE)
  const [iconBorder, setIconborder] = useState<string>('none')
  const [iconBorderColor, setIconBorderColor] = useState<string>('none')
  const [iconHoverBackgroundColor, setIconHoverBackgroundColor] = useState<string>(
    COLORS.DAF_DARK_BLUE
  )

  const size = () => {
    switch (iconSize) {
      case 'medium':
        return '40px'
      case 'large':
        return '64px'
      default:
        return ['24px', '24px', '32px']
    }
  }

  const fontSize = () => {
    switch (iconSize) {
      case 'medium':
        return '12px'
      case 'large':
        return '20px'
      default:
        return ['8px', '8px', '10px']
    }
  }

  useEffect(() => {
    switch (contractPackageAbbreviation) {
      case 'UP':
        setIconBackgroundColor('#80A8CD')
        break
      case 'EW':
        setIconBackgroundColor(COLORS.BLACK)
        break
      case VEHICLE_SERVICES_NON_MULTISUPPORT:
        setIconBackgroundColor(COLORS.WHITE)
        setIconTextColor(COLORS.BLACK)
        setIconborder('1px solid')
        setIconBorderColor(COLORS.BLACK)
        setIconHoverBackgroundColor(COLORS.BLACK)
        break
      case VEHICLE_SERVICES_NO_SERVICES:
        setIconBackgroundColor('transparent')
        setIconborder('1px dashed')
        setIconBorderColor(COLORS.DARK)
        setIconHoverBackgroundColor(alphaColor(COLORS.BLACK, 0.1))
        break
      default:
        setIconBackgroundColor(COLORS.DAF_BLUE)
        break
    }
  }, [contractPackageAbbreviation])
  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: iconSize === 'small' ? 4 : 8,
        color: iconTextColor,
        backgroundColor: iconBackgroundColor,
        border: iconBorder,
        borderColor: iconBorderColor,
        '&:hover': {
          backgroundColor: iconSize === 'large' ? iconHoverBackgroundColor : '',
          color: iconSize === 'large' ? COLORS.WHITE : '',
        },
        ...sx,
      }}
    >
      {contractPackageAbbreviation !== VEHICLE_SERVICES_NO_SERVICES && (
        <Text sx={{ fontSize: fontSize(), fontWeight: 'bold', lineHeight: 0 }}>
          {contractPackageAbbreviation}
        </Text>
      )}
    </Box>
  )
}

export default ContractServicesIcon
