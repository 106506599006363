import React from 'react'
import { Text, TextProps } from 'theme-ui'

interface TitleBarProps extends Omit<TextProps, 'css'> {
  title: string
  tag?: string
}

const TitleBar = ({ title, sx, ...textProps }: TitleBarProps) => (
  <Text
    as="h1"
    variant="heading1"
    sx={{
      fontSize: [4, 4, 5],
      pt: 2,
      pb: [3, 3, 4],
      ...sx,
    }}
    {...textProps}
  >
    {title}
  </Text>
)

export default TitleBar
