import { MultisupportReducerActions } from '../actions/dafCompanyVehicleActions'
import {
  PUSH_TO_DAF_COMPANY_VEHICLE,
  PUSH_TO_DAF_SERVICE_DEALER,
  PUSH_TO_DAF_VEHICLE_RM_DOCUMENTS,
  TOGGLE_ADD_VEHICLE_PANEL,
  TOGGLE_DELETE_VEHICLE_PANEL,
  TOGGLE_EDIT_SERVICE_PLAN_FORM_PANEL,
  TOGGLE_EDIT_VEHICLE_PANEL,
  TOGGLE_END_MAINTENANCE_PLAN_PANEL,
  TOGGLE_MAINTENANCE_APPOINTMENT_PANEL,
  TOGGLE_MAINTENANCE_INPUT_FORM_PANEL,
  TOGGLE_MARK_MAINTENANCE_AS_PERFORMED_PANEL,
  TOGGLE_MILEAGE_SETTLEMENT_INPUT_FORM_PANEL,
  TOGGLE_MY_FLEET_LIST_FILTER_PANEL,
  TOGGLE_REQUEST_QUOTE_PANEL,
  UPDATE_CHECKED_JOBS,
} from '../constants/dafCompanyVehicleConstants'
import { VehicleDetailResponse } from '../services/rest/ecommerce/dafVehicleDetail'
import { Dealer } from '../types/dealerServiceTypes'
import { DafVehicleRmDocumentsResponse } from '../services/rest/ecommerce/dafVehicleRmDocuments'
import { UpcomingJob } from '../services/rest/ecommerce/dafCompanyVehicles'

export interface VehicleReducerState {
  filterPanelState: boolean
  editServicePlanFormPanelState: boolean
  vehicleData?: VehicleDetailResponse
  mileageSettlementInputAcceptedEstimation?: boolean
  serviceDealer?: Dealer
  isFetchingServiceDealer?: boolean
  vehicleDocuments?: DafVehicleRmDocumentsResponse
  isFetching?: boolean
  chassisNumber?: string
  mileageSettlementInputFormPanelState?: boolean
  addVehiclePanelState?: {
    isActive?: boolean
  }
  maintenancePanelState?: boolean
  maintenanceIsAdjustMaintenance?: boolean
  endMaintenancePlanPanelState?: boolean
  selectedJobs?: UpcomingJob[]
  markMaintenanceAsPerformedPanelState?: boolean
  maintenanceAppointmentPanelState?: boolean
  editVehiclePanelState?: boolean
  deleteVehiclePanelState?: boolean
  requestQuotePanelState?: boolean
}

const multisupportReducer = (
  state = {
    filterPanelState: false,
    editServicePlanFormPanelState: false,
    vehicleData: undefined,
    mileageSettlementInputAcceptedEstimation: false,
  },
  action: MultisupportReducerActions
): VehicleReducerState => {
  switch (action.type) {
    case PUSH_TO_DAF_COMPANY_VEHICLE: {
      return {
        ...state,
        vehicleData: action.payload.data,
      }
    }
    case PUSH_TO_DAF_SERVICE_DEALER: {
      return {
        ...state,
        serviceDealer: action.payload.data,
        isFetchingServiceDealer: action.payload.isFetching,
      }
    }
    case PUSH_TO_DAF_VEHICLE_RM_DOCUMENTS: {
      return {
        ...state,
        vehicleDocuments: action.payload.data,
        isFetching: action.payload.isFetching,
      }
    }
    case TOGGLE_MY_FLEET_LIST_FILTER_PANEL: {
      return {
        ...state,
        filterPanelState: action.payload,
      }
    }
    case TOGGLE_EDIT_SERVICE_PLAN_FORM_PANEL: {
      return {
        ...state,
        editServicePlanFormPanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
      }
    }
    case TOGGLE_MILEAGE_SETTLEMENT_INPUT_FORM_PANEL: {
      return {
        ...state,
        mileageSettlementInputFormPanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
        mileageSettlementInputAcceptedEstimation: action.payload.acceptedEstimation,
      }
    }
    case TOGGLE_ADD_VEHICLE_PANEL: {
      return {
        ...state,
        addVehiclePanelState: action.payload,
      }
    }
    case TOGGLE_MAINTENANCE_INPUT_FORM_PANEL: {
      return {
        ...state,
        maintenancePanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
        maintenanceIsAdjustMaintenance: action.payload.isAdjustMaintenance,
      }
    }
    case TOGGLE_END_MAINTENANCE_PLAN_PANEL: {
      return {
        ...state,
        endMaintenancePlanPanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
      }
    }
    case UPDATE_CHECKED_JOBS: {
      return {
        ...state,
        selectedJobs: action.payload.selectedJobs,
      }
    }
    case TOGGLE_MARK_MAINTENANCE_AS_PERFORMED_PANEL: {
      return {
        ...state,
        markMaintenanceAsPerformedPanelState: action.payload.isActive,
      }
    }
    case TOGGLE_MAINTENANCE_APPOINTMENT_PANEL: {
      return {
        ...state,
        maintenanceAppointmentPanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
      }
    }
    case TOGGLE_EDIT_VEHICLE_PANEL: {
      return {
        ...state,
        editVehiclePanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
      }
    }
    case TOGGLE_DELETE_VEHICLE_PANEL: {
      return {
        ...state,
        deleteVehiclePanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
      }
    }
    case TOGGLE_REQUEST_QUOTE_PANEL: {
      return {
        ...state,
        requestQuotePanelState: action.payload.isActive,
        chassisNumber: action.payload.chassisNumber,
      }
    }
    default: {
      return state
    }
  }
}

export default multisupportReducer
