import React, { MouseEvent, ReactNode, useMemo } from 'react'
import { Box } from 'theme-ui'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'

interface TooltipActionProps {
  actionCallback?: () => void
  actionLink?: string
  children: ReactNode
}

const TooltipAction = ({
  actionCallback,
  actionLink,
  children,
}: TooltipActionProps) => {
  const action = useMemo(
    () =>
      actionCallback || actionLink
        ? (e: MouseEvent) => {
            e.preventDefault()
            if (actionCallback !== undefined) actionCallback()

            if (actionLink !== undefined) window.location.href = actionLink
          }
        : undefined,
    [actionCallback, actionLink]
  )

  if (!action && !actionLink) return null

  return (
    <ClickableWrapper
      onClick={action}
      sx={{
        marginTop: 2,
        color: 'textLight',
        display: 'block',
        ':hover span': {
          color: 'textLight',
        },
      }}
    >
      <Box as="span" sx={{ textDecoration: 'underline' }}>
        {children}
      </Box>
    </ClickableWrapper>
  )
}

export default TooltipAction
