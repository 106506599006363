import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../IconWrapper/IconWrapper'

interface BaseItemProps {
  includeArrow?: boolean
  active?: boolean
  title: string
}

export const BaseItem: FC<BaseItemProps> = ({
  title,
  includeArrow = false,
  active = false,
}) => (
  <Text
    sx={{
      fontSize: [4, 4, 1],
      fontWeight: ['normal', 'normal', 'bold'],
      lineHeight: ['heading2', 'heading2', 'buttonText'],
      paddingY: [3, 3, 4],
      paddingX: 3,
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
      svg: {
        flexShrink: 0,
        marginLeft: 1,
        transform: `rotate(${active ? 180 : 0}deg)`,
        visibility: ['hidden', 'hidden', 'visible'],
      },
    }}
  >
    {title}

    {includeArrow && <IconWrapper icon={ICONS.ARROW_DOWN} size={2} />}
  </Text>
)
