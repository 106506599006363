import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import { MAX_NUMBER_OF_PARTS } from '../../../constants/partSalesInformationConstants'
import {
  PartNumberQuantityInput,
  StockInformation,
} from './usePartSalesInformationService'

const GET_DEALER_PART_SALES_INFORMATION = loader(
  './schemas/dealerPartSalesInformationQuery.graphql'
)

interface DealerPartSalesInformationQueryData {
  priceInformations?: DealerPartPriceInformation[]
  stockInformationList?: StockInformation[]
}

interface DealerPartSalesInformationQueryVariables
  extends BaseGraphServiceVariables {
  [key: string]: any
  partNumbersQuantity: PartNumberQuantityInput[]
}

export interface DealerPartPrice {
  currencyCode?: string
  retailTotalPrice?: number
  retailUnitPrice?: number
  netTotalPrice?: number
  netTotalPriceEuro?: number
  netUnitPrice?: number
  netUnitPriceEuro?: number
}

export interface DealerPartPriceInformation {
  partNumber?: string
  price?: DealerPartPrice
  quantity: number
}

const useDealerPartSalesInformationService = (
  partNumbersQuantity?: PartNumberQuantityInput[]
): GraphServiceTuple<DealerPartSalesInformationQueryData> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [dealerPartSalesInformation, setDealerPartSalesInformation] =
    useState<DealerPartSalesInformationQueryData>({})

  const onData = useCallback((data?: DealerPartSalesInformationQueryData) => {
    if (data?.priceInformations?.length) {
      setDealerPartSalesInformation({
        priceInformations: data.priceInformations,
        stockInformationList: data.stockInformationList,
      })
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    DealerPartSalesInformationQueryData,
    DealerPartSalesInformationQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && partNumbersQuantity?.length) {
      const limitedPartNumbersQuantity = partNumbersQuantity.slice(
        0,
        MAX_NUMBER_OF_PARTS
      )

      fetch({
        document: GET_DEALER_PART_SALES_INFORMATION,
        variables: {
          ...baseVariables,
          partNumbersQuantity: limitedPartNumbersQuantity,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, partNumbersQuantity])

  return [fetching, dealerPartSalesInformation]
}

export default useDealerPartSalesInformationService
