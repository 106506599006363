import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import Modal, { ModalProps } from '../Modal'
import ModalBodyContent from '../ModalBodyContent'

export interface MenuListStoreSwitcherModalProps extends ModalProps {
  title: string
}

const MenuListStoreSwitcherModal: FC<MenuListStoreSwitcherModalProps> = ({
  title,
  isActive,
  onClose,
  children,
}) => (
  <Modal isActive={isActive} theme="dafGray" onClose={onClose} title={title}>
    <ModalBodyContent>
      <Flex
        sx={{
          flexDirection: 'column',
          li: {
            borderBottom: '1px solid',
            borderBottomColor: 'gray.2',
            ':last-of-type': {
              borderBottom: 'none',
            },
            button: {
              px: 0,
              bg: 'transparent',
              ':hover': {
                bg: 'transparent',
              },
            },
            div: {
              color: 'textLight',
            },
          },
        }}
      >
        {children}
      </Flex>
    </ModalBodyContent>
  </Modal>
)

export default MenuListStoreSwitcherModal
