import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'
import { PricingProps } from '../../../types/salesProps'
import { COLORS } from '../../../constants/themeConstants'

const Price: FC<PricingProps & Omit<TextProps, 'css'>> = ({
  price,
  variant,
  useTotalPrice = false,
  ...textProps
}) => (
  <Text
    as="span"
    data-t-id={useTotalPrice ? 'part-totalprice' : 'part-unitprice-actual'}
    sx={{
      color: COLORS.BLACK,
      fontFamily: 'numeric',
      fontSize: variant === 'normal' ? 4 : 1,
      fontWeight: variant === 'normal' ? 'normalNumeric' : 'boldNumeric',
      lineHeight: variant === 'normal' ? 'heading2' : 'heading3',
    }}
    {...textProps}
  >
    {price}
  </Text>
)

export default Price
