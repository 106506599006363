import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'

export interface TableCellProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {}

const TableCell: FC<TableCellProps> = ({ children, sx, ...manipulatedProps }) => (
  // in consultation with design only this component will
  // always have a padding of 12px and therefore not
  // present in the theme definition
  <Box
    sx={{
      display: 'table-cell',
      p: 0,
      py: '12px',
      px: '6px',
      color: 'gray.2',
      textAlign: 'left',
      verticalAlign: 'top',
      ':last-of-type': {
        pr: 0,
      },
      ':first-of-type': {
        pl: 0,
      },
      ...sx,
    }}
    {...manipulatedProps}
  >
    <Box>{children}</Box>
  </Box>
)

export default TableCell
