import React, { useCallback, useEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { COLORS } from '../../../constants/themeConstants'
import { RootState } from '../../../reducers'
import ProgressBar from '../ProgressBar/ProgressBar'
import {
  CURRENT_ACCOUNT,
  DIRECT_DEBIT,
  LUMPSUM,
  MONTHLY,
  SERVICEPLAN_CONTRACT_STATE_CANCELED,
  SERVICEPLAN_CONTRACT_STATE_CLOSED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRED,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
} from '../../../constants/dafCompanyVehicleConstants'
import StatePill from '../../atoms/StatePill/StatePill'
import VehicleGridTile from '../../molecules/VehicleGrid/VehicleGridTile'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const advancedFormat = require('dayjs/plugin/advancedFormat')
const duration = require('dayjs/plugin/duration')

dayjs.extend(advancedFormat)
dayjs.extend(duration)
const MainServicePlanStatusTile = () => {
  const [progressBarText, setProgressBarText] = useState('')
  const [progressBarPrecentage, setProgressBarPercentage] = useState(0)
  const { vehicleData, isFetching } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const today = dayjs()
  const endOfMonth = dayjs().endOf('month')
  const daysInMonth = dayjs().daysInMonth()

  // @ts-ignore
  const remainingDays = dayjs.duration(endOfMonth.diff(today)).days()

  const paymentTermsDay = vehicleData?.rmContract?.finance?.payment?.paymentTermsDay

  const freeLumpsumOrMonthly =
    (vehicleData?.rmContract?.finance?.financialDetails?.installmentFrequency ===
      LUMPSUM ||
      vehicleData?.rmContract?.finance?.financialDetails?.installmentFrequency ===
        MONTHLY) &&
    vehicleData?.rmContract?.finance?.payment?.monthlyAmount === 0

  const paidLumpsum =
    vehicleData?.rmContract?.finance?.financialDetails?.installmentFrequency ===
      LUMPSUM && vehicleData?.rmContract?.finance?.payment?.monthlyAmount > 0

  const isDirectDebit =
    vehicleData?.rmContract?.finance?.payment?.paymentMethod === DIRECT_DEBIT

  const hasPaymentTermsDay =
    !!vehicleData?.rmContract?.finance?.payment?.paymentTermsDay

  const isCurrentAccount =
    vehicleData?.rmContract?.finance?.payment?.paymentMethod === CURRENT_ACCOUNT

  const isCollectionDay =
    hasPaymentTermsDay && isDirectDebit && !freeLumpsumOrMonthly && !paidLumpsum

  const isDueDay =
    hasPaymentTermsDay && isCurrentAccount && !freeLumpsumOrMonthly && !paidLumpsum

  const isOnHold =
    vehicleData?.rmContract?.servicePlan?.status.contractState ===
    SERVICEPLAN_CONTRACT_STATE_ON_HOLD

  const shouldShowTile =
    vehicleData?.rmContract.finance.payment.monthlyAmount &&
    vehicleData?.rmContract.finance.payment.monthlyAmount > 0 &&
    vehicleData?.rmContract?.finance?.financialDetails?.installmentFrequency ===
      MONTHLY &&
    (isCollectionDay || isDueDay) &&
    vehicleData?.rmContract?.servicePlan.status.contractState !==
      SERVICEPLAN_CONTRACT_STATE_CLOSED &&
    vehicleData?.rmContract?.servicePlan.status.contractState !==
      SERVICEPLAN_CONTRACT_STATE_EXPIRED &&
    vehicleData?.rmContract?.servicePlan.status.contractState !==
      SERVICEPLAN_CONTRACT_STATE_CANCELED

  const progressText = useCallback(() => {
    if (paymentTermsDay && paymentTermsDay < today.date()) {
      const calculatedDays = remainingDays + paymentTermsDay
      const completedDays = daysInMonth - calculatedDays
      setProgressBarText(
        i18next.t('finance.inDays', {
          calculatedDays: Math.round(calculatedDays),
        })
      )
      setProgressBarPercentage((completedDays / daysInMonth) * 100)
    } else if (paymentTermsDay && paymentTermsDay > today.date()) {
      if (daysInMonth >= paymentTermsDay) {
        const calculatedDays = paymentTermsDay - today.date()
        const completedDays = daysInMonth - calculatedDays
        setProgressBarText(
          i18next.t('finance.inDays', {
            calculatedDays: Math.round(calculatedDays),
          })
        )
        setProgressBarPercentage((completedDays / daysInMonth) * 100)
      }

      if (daysInMonth < paymentTermsDay) {
        const calculatedDays = daysInMonth - today.date()
        const completedDays = daysInMonth - calculatedDays
        setProgressBarText(
          i18next.t('finance.inDays', {
            calculatedDays: Math.round(calculatedDays),
          })
        )
        setProgressBarPercentage((completedDays / daysInMonth) * 100)
      }
    }

    if (isOnHold) {
      setProgressBarText(i18next.t('finance.overdue'))
      setProgressBarPercentage(100)
    }

    if (today.date() === paymentTermsDay) {
      setProgressBarText(i18next.t('commonLabels.today'))
      setProgressBarPercentage(100)
    }
  }, [paymentTermsDay, remainingDays, today, daysInMonth, isOnHold])

  useEffect(() => {
    progressText()
  }, [progressText])

  if (!shouldShowTile) return null

  return (
    <VehicleGridTile>
      {!isFetching && (isCollectionDay || isDueDay) && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignContent: 'center',
            }}
          >
            <Text variant="heading2">{i18next.t('finance.statusTitle')}</Text>
            {isOnHold && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: ['flex-end'],
                }}
              >
                <StatePill
                  state={i18next.t('dafCompanyVehicles.paymentDue')}
                  color={COLORS.ALERT}
                />
              </Box>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <ProgressBar
              label={
                isDirectDebit
                  ? i18next.t('finance.collection')
                  : i18next.t('finance.payment')
              }
              color={isOnHold ? COLORS.ALERT : COLORS.BLACK}
              value={<Text>{progressBarText}</Text>}
              percentage={`${progressBarPrecentage}%`}
            />
          </Box>
          <Box
            sx={{
              marginTop: 4,
              paddingTop: 4,
              borderTop: '1px solid',
              borderColor: COLORS.MEDIUM_GRAY,
              alignContent: 'flex-end',
              color: COLORS.GRAY,
              fontSize: 0,
            }}
          >
            {i18next.t('finance.mainServicePlanFinanceNote')}
          </Box>
        </>
      )}
    </VehicleGridTile>
  )
}

export default MainServicePlanStatusTile
