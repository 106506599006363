import React, { FC } from 'react'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Link from '../../atoms/Link/Link'

interface FooterSocialLinkProps {
  href?: string
  icon: string
  size?: number
}

const FooterSocialLink: FC<FooterSocialLinkProps> = ({ href, icon, size }) => (
  <Link
    href={href}
    target="_BLANK"
    sx={{
      display: 'inline-block',
      pr: 3,
      textDecoration: 'none',
      opacity: 0.8,
      outline: 'none',
      ':hover, :focus, :active': {
        opacity: 1,
      },
    }}
  >
    <IconWrapper size={size ?? 4} icon={icon} color="textLight" />
  </Link>
)

export default FooterSocialLink
