import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface UserLanguageProps {
  cultureCode: string
}

const userLanguage = (
  payload: UserLanguageProps,
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse> =>
  client.put('/user/language', payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default userLanguage
