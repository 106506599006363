import React, { FC, useEffect, useMemo, useState } from 'react'
import { Text } from 'theme-ui'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import List from '../../atoms/List/List'
import PartSpecificationsTable from '../PartSpecificationsTable/PartSpecificationsTable'
import { Datasource, TextField } from '../../../types/layoutService'
import { CommerceSpecification } from '../../../types/commerceApi'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import useBreakpoints from '../../../hooks/useBreakpoints'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'

interface PartSpecificationsDatasource {
  title: TextField
}

interface PartSpecificationsProps {
  partSpecifications?: CommerceSpecification
}

const PartSpecifications: FC<
  Datasource<PartSpecificationsDatasource> & PartSpecificationsProps
> = ({ rendering: { uid }, partSpecifications, datasource }) => {
  const { title } = datasource
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const [breakpointM] = useBreakpoints(BREAKPOINT_M)

  const Table = useMemo(
    () => (
      <PartSpecificationsTable
        partAttributes={partSpecifications?.partAttributes}
        packaging={partSpecifications?.packaging}
      />
    ),
    [partSpecifications]
  )

  const sortedBullets = useMemo(
    () =>
      (!!partSpecifications?.bullets?.length &&
        partSpecifications.bullets.sort((a, b) =>
          a?.priority && b?.priority ? a.priority - b.priority : 0
        )) ||
      [],
    [partSpecifications]
  )

  const displaySpecifications =
    (!!partSpecifications?.packaging?.packageUnitQuantity &&
      partSpecifications.packaging.packageUnitQuantity > 1) ||
    !!partSpecifications?.partAttributes?.length ||
    !!sortedBullets?.length

  if (!displaySpecifications) return null

  if (!breakpointM && isClient) {
    return (
      <PartMobileModalLink
        title={getTextFieldValue(title)}
        bullets={sortedBullets}
        table={Table}
      />
    )
  }

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid} mb={7}>
      {title && (
        <Text as="h2" variant="heading2" mb={4}>
          {getTextFieldValue(title)}
        </Text>
      )}

      {!!sortedBullets?.length && (
        <List
          items={sortedBullets}
          sx={{
            paddingLeft: 3,
            marginBottom: 5,
            li: {
              color: 'gray.2',
            },
          }}
        />
      )}

      {Table}
    </AnchorMenuTarget>
  )
}

export default withData(PartSpecifications, { showMessageWhenPageEditing: true })
