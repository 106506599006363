import { Category } from '../hooks/services/graphql/useCategoryListService'
import { PartCategory } from '../types/dafResponseProps'
import { EVENT_CATEGORIES } from '../constants/analyticsConstants'

export const findCategoryByIdRecursive = (
  categoryId: string,
  categories: Category[] = [],
  visitedCategories: Category[] = []
): boolean =>
  categories.some((category) => {
    visitedCategories.push(category)

    if (
      category.id === categoryId ||
      findCategoryByIdRecursive(
        categoryId,
        category.subCategories ?? [],
        visitedCategories
      )
    )
      return true

    visitedCategories.pop()
    return false
  })

export const getFlattenedCategoryTree = (
  categoryId: string,
  categories: Category[] = []
): Category[] => {
  const visitedCategories: Category[] = []

  findCategoryByIdRecursive(categoryId, categories, visitedCategories)

  return visitedCategories
}

export const getFlattenedCategoriesTree = (
  categoryIds: string[],
  categories: Category[] = []
) =>
  categoryIds?.map((categoryId) =>
    getFlattenedCategoryTree(categoryId, categories)
  ) ?? []

export const getDescriptionFromFlattenedCategoryTree = (categoryTree: Category[]) =>
  categoryTree.slice(-1).map(({ description }) => description) ?? []

export const sortTreeCategoriesAlphabetically = (categoriesTree: Category[][]) =>
  categoriesTree.sort((a, b) =>
    getDescriptionFromFlattenedCategoryTree(a) >
    getDescriptionFromFlattenedCategoryTree(b)
      ? 1
      : -1
  )

export const restrictCategoriesTreeToParentCategory = (
  categories: Category[][],
  parent?: Category
): Category[][] => {
  const restricted =
    (!!parent && categories?.filter((item) => item[0].id === parent.id)) ||
    categories

  return restricted.filter((category) => category.length > 0)
}

export const getCategoryById = (
  categoryId?: string,
  categories?: Category[]
): Category | undefined => categories?.find(({ id }) => id === categoryId)

export const getSubCategoryById = (
  subCategoryId?: string,
  mainCategory?: Category
): Category | undefined =>
  mainCategory?.subCategories?.find((category) => category.id === subCategoryId)

export const getEnglishCategoryName = (
  partCategory: PartCategory | undefined,
  categories: Category[] | undefined
) => {
  if (!partCategory || !categories) return EVENT_CATEGORIES.UNCATEGORIZED
  const mainCategory = getCategoryById(partCategory.mainCategoryId, categories)
  const subCategory = getSubCategoryById(mainCategory?.id, mainCategory)
  return (
    subCategory?.englishDescription ??
    mainCategory?.englishDescription ??
    EVENT_CATEGORIES.UNCATEGORIZED
  )
}

export const getExistingSubcategoryIds = (
  categoryIds?: string[],
  categories?: Category[]
): string[] => {
  const subCategorieIds =
    categories?.flatMap(
      ({ subCategories }) =>
        subCategories?.map((subcategory) => subcategory.id) ?? []
    ) ?? []
  return categoryIds?.filter((id) => subCategorieIds.includes(id)) ?? []
}

export const filterUnPublished = (categories: Category[]): Category[] => {
  let publishedCategories: Category[] = []

  categories.forEach((category) => {
    if (category.publish) {
      let subCategories: Category[] = category.subCategories ?? []
      if (category.subCategories && category.subCategories.length) {
        subCategories = filterUnPublished(category.subCategories)
      }
      publishedCategories = [...publishedCategories, { ...category, subCategories }]
      return true
    }
    return false
  })

  return publishedCategories
}

export const sortCategoriesByPriority = (categories: Category[]) => {
  if (categories && !!categories.length) {
    return [...categories]
      .reduce<Category[]>(
        (a, b) => [
          ...a,
          {
            ...b,
            subCategories:
              [...(b?.subCategories ?? [])].sort(
                (c, d) => c.priority - d.priority
              ) || [],
          },
        ],
        []
      )
      .sort((a, b) => a.priority - b.priority)
  }

  return []
}
