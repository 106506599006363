import React, { forwardRef } from 'react'
import FormInputBase, { FormInputBaseProps } from './FormInputBase'

export interface FormInputTextareaProps extends FormInputBaseProps {}

const FormInputTextarea = forwardRef<HTMLInputElement, FormInputTextareaProps>(
  ({ children, sx, ...rest }, ref) => (
    <FormInputBase
      as="textarea"
      ref={ref}
      sx={{
        resize: 'none',
        height: '124px',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </FormInputBase>
  )
)

export default FormInputTextarea
