import i18next from 'i18next'
import { CommerceAttributesOfAPart, CommercePackaging } from '../types/commerceApi'

export const getPartAttributesWithPackagingQuantity = (
  attributes?: CommerceAttributesOfAPart[],
  packaging?: CommercePackaging
) => {
  const packagingQuantityAttribute =
    packaging?.packageUnitQuantity && packaging?.packageUnitQuantity > 1
      ? {
          id: 0,
          priority: 0,
          description: i18next.t('partLabels.packageQuantity'),
          value: packaging.packageUnitQuantity.toString(),
        }
      : undefined

  return [packagingQuantityAttribute, ...(attributes || [])].filter(
    (item) => item
  ) as CommerceAttributesOfAPart[]
}
