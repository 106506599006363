import React, { FC, SyntheticEvent, useCallback, useRef, useState } from 'react'
import { Box } from 'theme-ui'
import { SitecoreImageProps } from '../SitecoreImage/SitecoreImage'
import { VideoField } from '../../../types/layoutService'
import Button from '../Button/Button'
import IconWrapper from '../IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'

interface HeroBannerVideoProps extends SitecoreImageProps {
  video?: VideoField
}

const BannerVideo: FC<HeroBannerVideoProps> = ({ video }) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const [play, setPlay] = useState(false)
  const [mute, setMute] = useState(true)

  const togglePlay = useCallback(() => {
    setPlay((currentPlay) => {
      if (!currentPlay && videoRef?.current) {
        videoRef.current.play()
      }

      if (currentPlay && videoRef?.current) {
        videoRef.current.pause()
      }

      return !currentPlay
    })
  }, [])

  const toggleMute = useCallback(() => {
    setMute((currentMute) => {
      if (videoRef?.current) videoRef.current.muted = !currentMute

      return !currentMute
    })
  }, [])

  if (!video?.url) return null

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          overflow: 'hidden',
          'video::-webkit-media-controls': {
            display: 'none !important',
          },
          'video::-webkit-media-controls-start-playback-button': {
            display: 'none !important',
            WebkitAppearance: 'none',
          },
        }}
      >
        <video
          ref={videoRef}
          onPlay={() => setPlay(true)}
          onPause={() => setPlay(false)}
          onVolumeChange={(e: SyntheticEvent<HTMLVideoElement>) => {
            setMute((e.target as HTMLVideoElement).muted)
          }}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={video?.url} type="video/mp4" />
        </video>
      </Box>

      <Box
        sx={{
          gridTemplateColumns: '1fr 1fr',
          display: 'grid',
          gap: 2,
          flexDirection: 'row',
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginBottom: 3,
          marginRight: 4,
          zIndex: 2,
        }}
      >
        <Button
          round
          variant="outline"
          onClick={togglePlay}
          title={play ? 'play' : 'pause'}
        >
          <IconWrapper
            icon={ICONS[play ? 'PAUSE' : 'PLAY']}
            size={4}
            color="primary"
          />
        </Button>

        <Button
          round
          variant="outline"
          onClick={toggleMute}
          title={mute ? 'unmute' : 'mute'}
        >
          <IconWrapper
            icon={ICONS[mute ? 'UNMUTE' : 'MUTE']}
            size={4}
            color="primary"
          />
        </Button>
      </Box>
    </>
  )
}

export default BannerVideo
