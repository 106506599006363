import i18next from 'i18next'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { registerModalNotification } from '../../../../actions/notificationActions'
import {
  ACTIVE_USER_STATE,
  ACTIVE_USER_STATE_COOKIE_OPTIONS,
} from '../../../../constants/cookieConstants'
import {
  URL_SEARCH_TYPE,
  URL_SHOPPING_BASKET_WIZARD_ORDER_NUMBER_FOR_CONFIRMATION_PAGE,
  URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM,
} from '../../../../constants/urlConstants'
import { encodeActiveUserStateCookie } from '../../../../helpers/cookieHelper'
import useCommerceApiPostService from '../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import { RootState } from '../../../../reducers'
import {
  orderConfirm,
  OrderConfirmProps,
} from '../../../../services/rest/ecommerce/order/order-confirm'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'
import Button from '../../../atoms/Button/Button'
import Spinner from '../../../atoms/Spinner/Spinner'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import { navigateClient } from '../../../../helpers/dom'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { QUOTE } from '../../../../constants/orderConstants'
import { getEnglishCategoryName } from '../../../../helpers/categoryHelper'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import { ICONS } from '../../../../constants/iconConstants'
import { Text } from 'theme-ui'
import { COLORS } from '../../../../constants/themeConstants'
import renderHTML from '../../../../helpers/renderHTMLHelper'
import { getEditableTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { TextField } from '../../../../types/layoutService'
import toCamelCase from '../../../../helpers/camelCaseHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'

const ConfirmOrderButton = () => {
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const [additionalInfoText, setAdditionalInfoText] = useState<
    TextField | undefined
  >()

  const [, setCookie] = useCookies([ACTIVE_USER_STATE])
  const { activeUserState, orderNumber } = useContext(ActiveUserStateContext)
  const { categories } = useContext(CategoriesContext)
  const { step, datasource, orderConfirmedPage } = useShoppingBasket()
  const { order, mutation } = useSelector(
    (state: RootState) => state.shoppingBasketWizard
  )
  const dispatch = useDispatch()

  const buttonDisabled = useMemo(
    () =>
      mutation.mutationInProgress ||
      !order?.isConfirmAllowed ||
      !order?.items?.length ||
      !order?.isQuoteConfirmAllowed,
    [order]
  )

  const [posting, response, post] = useCommerceApiPostService<
    OrderConfirmProps,
    CommerceResponse<CommerceOrder>
  >(orderConfirm)

  const confirmOrder = useCallback(() => {
    if (buttonDisabled) return

    post({ orderNumber: order?.number })
  }, [buttonDisabled, post, order])

  const orderConfirmed = useCallback(() => {
    setCookie(
      ACTIVE_USER_STATE,
      encodeActiveUserStateCookie({
        ...activeUserState,
        selectedOrderNumber: undefined,
      }),
      ACTIVE_USER_STATE_COOKIE_OPTIONS
    )

    pushToDataLayer({
      event: DATA_LAYER.EVENT_NAME.PURCHASE_REGISTERED,
      ecommerce: {
        transaction_id: orderNumber,
        value:
          order?.items?.reduce<number>(
            (count, item) => count + (item?.price?.netTotalPrice || 0),
            0
          ) || 0,
        currency: order?.items?.[0].price?.currencyCode,
        items: order?.items?.map((item) => ({
          item_id: item.partNumber,
          item_name: item.englishDescription,
          item_brand: item.brand?.description,
          item_category: getEnglishCategoryName(item.category, categories),
          item_list_name: DATA_LAYER.EVENT_NAME.SHOPPING_BASKET,
          doa_product: item.type === 'DealerOwnAssortment',
          quantity: item.quantity,
          price: item.price?.netUnitPrice,
        })),
      },
    })
    navigateClient(
      `${
        orderConfirmedPage?.url || '/'
      }?${URL_SHOPPING_BASKET_WIZARD_ORDER_NUMBER_FOR_CONFIRMATION_PAGE}=${orderNumber}${
        order?.type === QUOTE ? `&${URL_SEARCH_TYPE}=${QUOTE}` : ''
      }`
    )
  }, [
    setCookie,
    activeUserState,
    order,
    orderConfirmedPage,
    orderNumber,
    categories,
  ])

  useCommerceApiResponse<CommerceOrder>({
    response,
    onResult: orderConfirmed,
    onMessage: (message) => {
      if (message.severity === 'Error' && !!message?.message?.length) {
        dispatch(registerModalNotification('Error', message.message))
      }
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrder,
  })

  useEffect(() => {
    if (
      step === URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM ||
      (step === URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM &&
        order?.isR2CEnabled &&
        isImpersonated)
    ) {
      setAdditionalInfoText(undefined)
      return
    }

    switch (toCamelCase(order?.confirmNotAllowedReason)) {
      case 'invalidOrderStatus': {
        setAdditionalInfoText(datasource.invalidOrderStatus)
        return
      }
      case 'noRecipients': {
        setAdditionalInfoText(datasource.noRecipients)
        return
      }
      case 'powerUserR2C': {
        setAdditionalInfoText(datasource.powerUserR2C)
        return
      }
      case 'powerUserRoyalMail': {
        setAdditionalInfoText(datasource.powerUserRoyalMail)
        return
      }
      case 'requestQuoteNotAllowed': {
        setAdditionalInfoText(datasource.requestQuoteNotAllowed)
        return
      }
      case 'royalMailExternalDirectoryIdEmpty': {
        setAdditionalInfoText(datasource.royalMailExternalDirectoryIdEmpty)
        return
      }
      case 'russianWholeSaleOrderingNotAllowed': {
        setAdditionalInfoText(datasource.russianWholeSaleOrderingNotAllowed)
        return
      }
      case 'unAuthorized': {
        setAdditionalInfoText(datasource.unAuthorized)
        break
      }
      case 'wholeSaleOrderWayOfTransportEmpty': {
        setAdditionalInfoText(datasource.wholeSaleOrderWayOfTransportEmpty)
        break
      }
      case 'orderNumberIsMandatoryButEmpty': {
        setAdditionalInfoText(datasource.orderNumberIsMandatoryButEmpty)
        break
      }
      case 'orderCustomerOrderReferenceIsMandatoryButEmpty': {
        setAdditionalInfoText(datasource.referenceNumberIsMandatoryButEmpty)
        break
      }
      case 'orderExternalReferenceEmptyNotAllowed': {
        setAdditionalInfoText(datasource.jobsheetReferenceRequiredToConfirmOrder)
        break
      }
      case 'orderItemsExternalLineReferenceEmptyNotAllowed': {
        setAdditionalInfoText(datasource.r2CTasksNotFilled)
        break
      }
      default: {
        setAdditionalInfoText(undefined)
      }
    }
  }, [order, datasource, step])

  return (
    <>
      <Button
        data-t-id="shopping-basket-confirm-order-button"
        disabled={buttonDisabled}
        variant="primary"
        onClick={confirmOrder}
        icon={ICONS.TRUCK}
        iconPosition="end"
        sx={{
          position: 'relative',
          width: [null, null, null, null, '100%'],
          '.spinner': {
            position: 'absolute',
            right: '4px',
            top: '12px',
            paddingRight: ['2px', '2px', '8px'],
          },
        }}
      >
        {i18next.t('shoppingLabels.order')}

        {posting && (
          <span className="spinner">
            <Spinner size={2} color="currentColor" />
          </span>
        )}
      </Button>

      {additionalInfoText && (
        <Text
          variant="bodySmall"
          color={COLORS.GRAY}
          sx={{ display: 'block', marginBlockStart: 3, textAlign: 'center' }}
        >
          {renderHTML(getEditableTextFieldValue(additionalInfoText))}
        </Text>
      )}
    </>
  )
}

export default ConfirmOrderButton
