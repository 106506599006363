import React, { FC, useCallback } from 'react'
import { Text, Flex } from 'theme-ui'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import {
  BannerVariant,
  Flag,
  LinkField,
  RenderingProps,
} from '../../../types/layoutService'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'
import Container from '../Container/Container'

export interface BannerCallToActionProps {
  title?: string
  subtitle?: string
  button?: LinkField
  variant: BannerVariant
  hasNegativeOffset?: Flag
  rendering: RenderingProps
}

const BannerCallToAction: FC<BannerCallToActionProps> = ({
  title,
  subtitle,
  button,
  hasNegativeOffset,
  rendering,
}) => {
  const measureClick = useCallback(() => {
    if (!button) return

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
      call_to_action_name: button.text,
      call_to_action_type: DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
      target_url: button.url,
    })
  }, [button])

  if (!title && !button) return null

  return (
    <Flex
      sx={{
        position: ['relative', 'relative', 'relative', 'absolute'],
        height: [
          'auto',
          'auto',
          'auto',
          hasNegativeOffset !== '1' ? 'calc(100% - 80px)' : '100%',
        ],
        width: '100%',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        paddingTop: [4, 4, 4, 0],
        paddingBottom: hasNegativeOffset !== '1' ? [4, 4, 4, 0] : [4, 4, 4, 9],
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 4,
      }}
    >
      <Container>
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          {title && (
            <Text
              variant="heading1"
              color="textLight"
              sx={{
                paddingBottom: [2, 2, 2, 3],
                fontSize: [4, 4, 4, 6],
                lineHeight: 'heading',
                maxWidth: ['100%', '100%', '100%', '50%'],
                textWrap: 'balance',
              }}
            >
              {title}
            </Text>
          )}
          {subtitle && (
            <Text
              variant="heading3"
              sx={{
                color: 'textLight',
                fontWeight: 'light',
                paddingBottom: [3, 3, 3, 3],
                fontSize: [3, 3, 3, 5],
                maxWidth: ['100%', '100%', '100%', '50%'],
              }}
            >
              {subtitle}
            </Text>
          )}

          <Placeholder name="jss-hero-banner-search" rendering={rendering} />

          <SitecoreButton
            variant="outline"
            linkField={button}
            onClick={measureClick}
          />
        </Flex>
      </Container>
    </Flex>
  )
}

export default BannerCallToAction
