import { Box, Label, Text } from 'theme-ui'
import { Field } from 'react-final-form'
import React from 'react'
import Checkbox from '../inputs/Checkbox/Checkbox'
import FormInputLabel from '../FormControls/FormInputLabel'

interface InputCheckboxListProps {
  name: string
  label: string
  ariaLabelledby?: string
  options: { value: string; label: string }[]
}
const InputCheckboxList = ({
  label,
  ariaLabelledby,
  name,
  options,
}: InputCheckboxListProps) => (
  <Box
    as="fieldset"
    sx={{ border: 0, padding: 0, marginBlockEnd: 2 }}
    aria-labelledby={ariaLabelledby}
  >
    {!ariaLabelledby && label && (
      <FormInputLabel as="legend">{label}</FormInputLabel>
    )}
    <Box as="ul" sx={{ listStyle: 'none', padding: 0 }}>
      {options.map((option) => (
        <li key={option.value}>
          <Field name={name} type="checkbox" value={option.value}>
            {({ input }) => (
              <Label
                htmlFor={`${name}-${option.value}`}
                sx={{
                  display: 'flex',
                  gap: 2,
                  paddingBlockEnd: 1,
                }}
              >
                <Box sx={{ flexShrink: 0 }}>
                  <Checkbox {...input} />
                </Box>
                <Text variant="bodySmall">{option.label}</Text>
              </Label>
            )}
          </Field>
        </li>
      ))}
    </Box>
  </Box>
)
export default InputCheckboxList
