import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import i18next from 'i18next'
import { MatchMetaDataProps } from '../../../../types/dafResponseProps'
import CardBrandImage from '../CardBrandImage'
import CardExcerpt from '../CardExcerpt'
import CardImage from '../CardImage'
import CardTitle from '../CardTitle'
import PartCardSalesInformation from './PartCardSalesInformation'
import { SkeletonComponent } from '../../../../types/layoutProps'
import CardExcerptText from '../CardExcerptText'
import CardFrame from '../CardFrame'
import PartStockInformationListAsync from '../../../organisms/PartStockInformationList/PartStockInformationListAsync'
import PartSalesInformationProvider from '../../../../providers/PartSalesInformationProvider'
import PromotionStickerAsync from '../../../atoms/PromotionSticker/PromotionStickerAsync'
import CardAction from '../CardAction'

export interface PartCardFullProps {
  partDescription?: string
  partEnglishDescription?: string
  partNumber: string
  displayPartNumber?: string
  matchMetaData?: MatchMetaDataProps
  brandImageUrl?: string
  brandName?: string
  categoryId?: string
  subcategoryId?: string
  packageUnitQuantity?: number
  dataLayerComponentOrigin?: string
  href: string
  imageUrl?: string
  onClick?: () => void
}

const PartCardFull: FC<PartCardFullProps> & SkeletonComponent = ({
  partDescription,
  partEnglishDescription,
  partNumber,
  displayPartNumber,
  matchMetaData,
  brandImageUrl,
  brandName,
  categoryId,
  subcategoryId,
  packageUnitQuantity,
  dataLayerComponentOrigin = '',
  href,
  imageUrl,
  onClick,
}) => (
  <PartSalesInformationProvider partNumber={partNumber}>
    <CardFrame>
      <CardAction href={href} onClick={onClick}>
        <PromotionStickerAsync variant="xl">
          <CardImage src={imageUrl} title={partDescription} />
        </PromotionStickerAsync>

        <CardTitle title={partDescription} />

        <CardExcerpt
          displayPartNumber={displayPartNumber}
          partNumber={partNumber}
          matchMetaData={matchMetaData}
        />
      </CardAction>

      <Flex sx={{ flexWrap: 'wrap', alignItems: 'center' }}>
        <CardBrandImage imageUrl={brandImageUrl} name={brandName} />

        <PartStockInformationListAsync
          variant="minimal"
          partNumber={partNumber}
          displayPartNumber={displayPartNumber}
          componentOrigin={dataLayerComponentOrigin}
          sx={{
            pl: 3,
          }}
        />
      </Flex>

      <PartCardSalesInformation
        partNumber={partNumber}
        partDescription={partDescription}
        partEnglishDescription={partEnglishDescription}
        dataLayerComponentOrigin={dataLayerComponentOrigin}
        packageUnitQuantity={packageUnitQuantity}
        brandName={brandName}
        categoryId={categoryId}
        subcategoryId={subcategoryId}
      />
    </CardFrame>
  </PartSalesInformationProvider>
)

const PartCardFullSkeleton: FC<{ partNumber: string }> = ({ partNumber }) => (
  <Flex
    sx={{
      flexDirection: 'column',
    }}
  >
    <CardImage.Skeleton />

    <CardTitle.Skeleton />

    {partNumber && (
      <CardExcerptText>
        {`${i18next.t('partLabels.partNumberAbbreviation')} ${partNumber}`}
      </CardExcerptText>
    )}

    {!partNumber && <CardExcerptText.Skeleton />}

    <CardBrandImage.Skeleton />
  </Flex>
)

PartCardFull.Skeleton = PartCardFullSkeleton

export default PartCardFull
