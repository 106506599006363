export const OPEN_PARTS_NAVIGATION = 'Navigation/OPEN_PARTS_NAVIGATION'
export const CLOSE_PARTS_NAVIGATION = 'Navigation/CLOSE_PARTS_NAVIGATION'
export const OPEN_MOBILE_NAVIGATION = 'Navigation/OPEN_MOBILE_NAVIGATION'
export const CLOSE_MOBILE_NAVIGATION = 'Navigation/CLOSE_MOBILE_NAVIGATION'

export const CATEGORY_PANEL_VARIANT = 'category-panel'
export const SUB_CATEGORY_PANEL_VARIANT = 'sub-category-panel'
export const FILTER_PANEL_VARIANT = 'filter-panel'

export const ICON_SIZE = 4
