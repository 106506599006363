import React, { useContext } from 'react'
import { ICONS } from '../../../../constants/iconConstants'
import { URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM } from '../../../../constants/urlConstants'
import Button from '../../../atoms/Button/Button'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'

const ContinueQuotationButton = () => {
  const { order, mutation } = useSelector(
    (state: RootState) => state.shoppingBasketWizard
  )
  const { setStep, datasource } = useShoppingBasket()
  const { isImpersonated } = useContext(ActiveStoreProviderContext)

  return (
    <Button
      variant="outline"
      icon={ICONS.ARROW_RIGHT}
      iconPosition="end"
      onClick={() => setStep(URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM)}
      sx={{
        inlineSize: '100%',
      }}
      disabled={
        (!order?.isConfirmAllowed && !order?.isR2CEnabled && !isImpersonated) ||
        !order?.items?.length ||
        mutation.mutationInProgress
      }
    >
      {getTextFieldValue(datasource.continueQuotationButtonLabel)}
    </Button>
  )
}

export default ContinueQuotationButton
