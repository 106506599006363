import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { useIsFetching } from '@tanstack/react-query'
import { RootState } from '../../../reducers'
import {
  VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB,
  VEHICLE_DETAIL_SERVICEPLAN_TAB,
} from '../../../constants/dafCompanyVehicleConstants'
import { getQueryParam } from '../../../helpers/urlHelper'
import VehicleGridTile from '../../molecules/VehicleGrid/VehicleGridTile'
import VehicleGrid from '../../molecules/VehicleGrid/VehicleGrid'
import NoResults from '../../molecules/NoResults/NoResults'
import Button from '../../atoms/Button/Button'
import ServicePlansCoverageSummary from '../../molecules/ServicePlansCoverageSummary/ServicePlansCoverageSummary'
import ServicePlansDetails from '../../molecules/ServicePlansDetails/ServicePlansDetails'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'
import ServicePlanStatus from '../../molecules/ServicePlansDetails/ServicePlanStatus'
import ActionButtonList from '../../molecules/ActionButtonList/ActionButtonList'
import ActionButtonListButton from '../../molecules/ActionButtonList/ActionButtonListButton'
import {
  toggleEditServicePlanPanel,
  toggleMileageSettlementInputPanel,
} from '../../../actions/dafCompanyVehicleActions'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION } from '../../../constants/userConstants'
import { hasRole } from '../../../helpers/userHelper'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const VehicleDetailServicePlans = () => {
  const { vehicleData } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const isFetchingVehicleData = useIsFetching({ queryKey: ['dafVehicleData'] })
  const [isActive, setIsActive] = useState<boolean>(false)
  const currentActiveTab = getQueryParam(VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB)
  const dispatch = useDispatch()
  const { user } = useSitecoreContext()

  const handleClick = () => {
    // eslint-disable-next-line no-console
    console.log('request new quote')
  }

  useEffect(() => {
    setIsActive(currentActiveTab === VEHICLE_DETAIL_SERVICEPLAN_TAB)
  }, [isActive, currentActiveTab])

  return (
    // Determine lateron if isActive has been set to true and if there's data present for this component
    <>
      <Box sx={{ display: !isActive ? 'none' : 'block' }}>
        {vehicleData?.rmContract?.servicePlan && (
          <>
            <Flex
              sx={{
                marginBottom: 4,
                alignItems: 'center',
                width: '100%',
                flexDirection: ['column', 'column', 'row'],
              }}
            >
              <Box sx={{ width: ['100%', '100%', '50%'], marginBottom: [3, 3, 0] }}>
                <Text
                  sx={{
                    fontSize: [4, 4, 2],
                    fontWeight: 'bold',
                  }}
                >
                  {`${i18next.t(
                    'servicePlan.packageNameLabel'
                  )}: ${showDashWhenEmptyValue(
                    vehicleData?.rmContract?.servicePlan?.packageName
                  )}`}
                </Text>
              </Box>
              <Box sx={{ alignSelf: 'flex-start', marginLeft: [0, 0, 'auto'] }}>
                <ActionButtonList variant="primary">
                  {vehicleData.vehicleActions.editServicePlan.showOption && (
                    <ActionButtonListButton
                      label={i18next.t('formLabels.editServicePlan')}
                      action={() =>
                        dispatch(
                          toggleEditServicePlanPanel({
                            isActive: true,
                            chassisNumber:
                              vehicleData?.vehicleDetail.vehicleAttributes
                                .chassisNumber,
                          })
                        )
                      }
                    />
                  )}
                  {vehicleData.vehicleActions.inputMileage.showOption && (
                    <ActionButtonListButton
                      label={i18next.t('formLabels.inputMileageActionButtonLabel')}
                      action={() =>
                        dispatch(
                          toggleMileageSettlementInputPanel({
                            isActive: true,
                            chassisNumber:
                              vehicleData?.vehicleDetail.vehicleAttributes
                                .chassisNumber,
                          })
                        )
                      }
                    />
                  )}
                </ActionButtonList>
              </Box>
            </Flex>

            <VehicleGrid>
              <VehicleGridTile>
                <ServicePlansCoverageSummary vehicle={vehicleData} />
              </VehicleGridTile>
              <VehicleGridTile>
                <ServicePlansDetails vehicle={vehicleData} />
              </VehicleGridTile>
              <VehicleGridTile>
                <ServicePlanStatus vehicle={vehicleData} />
              </VehicleGridTile>
            </VehicleGrid>
          </>
        )}
        {!isFetchingVehicleData && !vehicleData?.rmContract?.servicePlan ? (
          <NoResults
            title={i18next.t('servicePlan.noServicePlanTitle')}
            description={i18next.t('servicePlan.noServicePlanDescription')}
          >
            {!hasRole(
              user,
              USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
            ) && (
              <Button variant="primary" onClick={() => handleClick()}>
                {i18next.t('servicePlan.requestNewQuoteButtonLabel')}
              </Button>
            )}
          </NoResults>
        ) : null}
      </Box>
    </>
  )
}

export default VehicleDetailServicePlans
