import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CATEGORY_FILTER_ID } from '../../../../constants/searchConstants'
import { mapFiltersToSearchObject } from '../../../../helpers/searchResultsHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import executeAssemblySearch, {
  AssemblySearchData,
  SearchAssembly,
} from '../../../../services/rest/ecommerce/assemblySearch'
import { CommerceBomMasterWithoutPart } from '../../../../types/commerceApi'
import useAssembliesService from '../../graphql/useAssemblyListService'
import useSitecoreContext from '../../../useSitecoreContext'
import { loggedIn } from '../../../../helpers/userHelper'
import { getCommercePartUri } from '../../../../helpers/commerceApiHelpers'
import useAxiosClient from '../core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'

export interface AssemblyFulfilled {
  searchAssembly: SearchAssembly
  assembly?: CommerceBomMasterWithoutPart
  uri?: string
}

const combineSearchResultAssembliesWithGraphAssemblies = (
  searchAssemblies: SearchAssembly[],
  assemblies: CommerceBomMasterWithoutPart[]
): AssemblyFulfilled[] =>
  searchAssemblies.map((searchAssembly) => {
    const assembly = assemblies.find(({ bomId }) => bomId === searchAssembly.bomId)

    return {
      assembly: assembly ?? undefined,
      searchAssembly,
      uri: getCommercePartUri(
        assembly?.category?.mainCategoryId,
        assembly?.category?.subCategoryId,
        assembly?.bomId.toString()
      ),
    }
  })

const useAssemblySearchService = (
  searchString: string | undefined,
  categoryId: string | undefined,
  subcategoryId: string | undefined
): [boolean, AssemblyFulfilled[] | undefined, number] => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { user } = useContext(UserContext)
  const {
    languageContext: { countryCode, cultureCode },
    xSessionId,
  } = useSitecoreContext()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [fetching, setFetching] = useState<boolean>(false)
  const [searchAssemblies, setSearchAssemblies] = useState<
    SearchAssembly[] | undefined
  >(undefined)
  const [assembliesFulfilled, setAssembliesFulfilled] = useState<
    AssemblyFulfilled[] | undefined
  >(undefined)
  const [totalCount, setTotalCount] = useState<number>(0)

  const searchResultsAssemblyNumberList = useMemo(
    () => searchAssemblies?.map(({ bomId }) => bomId),
    [searchAssemblies]
  )

  const [fetchingGraphAssemblies, graphAssemblies] = useAssembliesService(
    searchResultsAssemblyNumberList
  )

  const searchFilterObject = useMemo(
    () => {
      if (!categoryId) return undefined

      return mapFiltersToSearchObject({
        [CATEGORY_FILTER_ID]: [subcategoryId || categoryId],
      })
    },
    // all dependencies are available on page load and will not change during component lifecycle

    []
  )

  const getAssemblies = useCallback(
    async (cancelToken: CancelToken) => {
      setFetching(true)

      await executeAssemblySearch(
        client,
        searchString || '',
        cancelToken,
        cultureCode,
        countryCode,
        searchFilterObject,
        user,
        xSessionId,
        isImpersonated,
        actingCompanyId,
        actingSupplierId
      )
        .then(({ data }) => {
          if (data) {
            setSearchAssemblies(data.bomMastersWithoutPart)
            setTotalCount(data.statistics?.totalCount || 0)
          }
        })
        .catch((error) => {
          const { code, message } = error as AxiosError<AssemblySearchData>

          if (!axios.isCancel(error)) {
            appInsights.trackException({
              exception: new Error(
                `Unable to fetch assembly search results: ${message} (${code})`
              ),
              severityLevel: SeverityLevel.Error,
              properties: {
                countryCode,
                cultureCode,
                isImpersonated,
                actingCompanyId,
                actingSupplierId,
              },
            })
          }
        })
        .finally(() => {
          setFetching(false)
        })
    },

    [
      actingCompanyId,
      actingSupplierId,
      appInsights,
      searchFilterObject,
      searchString,
    ]
  )

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()
    const allDataReceived = typeof searchString === 'string'
    const userInformationReady =
      !loggedIn(user) || (actingCompanyId && actingSupplierId)

    if (allDataReceived && userInformationReady) getAssemblies(token)

    return () => {
      if (allDataReceived && userInformationReady) cancel()
    }
  }, [getAssemblies, searchString, user, actingCompanyId, actingSupplierId])

  useEffect(() => {
    if (searchAssemblies && graphAssemblies && !fetchingGraphAssemblies) {
      setAssembliesFulfilled(
        combineSearchResultAssembliesWithGraphAssemblies(
          searchAssemblies,
          graphAssemblies || []
        )
      )
    }

    if (searchAssemblies && !graphAssemblies && !fetchingGraphAssemblies) {
      setAssembliesFulfilled([])
    }
  }, [fetchingGraphAssemblies, graphAssemblies, searchAssemblies])

  useEffect(() => {
    if (assembliesFulfilled) {
      setFetching(false)
    }
  }, [assembliesFulfilled])

  return [fetching, assembliesFulfilled, totalCount]
}

export default useAssemblySearchService
