import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { fetchDealers } from '../../../../services/rest/dealer/dealerSearch'
import { Dealer, SearchDealerRequest } from '../../../../types/dealerServiceTypes'
import { PaginatedResponse } from '../../../../types/layoutService'
import useAxiosClient from '../core/useAxiosClient'

export const useDealerSearchService = (
  options: Partial<SearchDealerRequest> | undefined
): [PaginatedResponse<Dealer> | undefined, boolean] => {
  const client = useAxiosClient(AxiosClientType.DealerApi)
  const appInsights = useAppInsightsContext()

  const [searchDealerResponse, setSearchDealerResponse] = useState<
    PaginatedResponse<Dealer> | undefined
  >(undefined)
  const [fetching, setFetching] = useState(false)

  const getDealers = useCallback(
    async (cancelToken: CancelToken) => {
      if (options) {
        setFetching(true)

        try {
          const { data } = await fetchDealers(client, options, cancelToken)

          if (data) {
            setSearchDealerResponse(data)
          }
        } catch (error) {
          const err = error as AxiosError<Dealer>

          appInsights.trackException({
            exception: new Error(
              `Unable to fetch dealers: ${err.message} (${err.code})`
            ),
            severityLevel: SeverityLevel.Error,
            properties: {
              ...options,
            },
          })
        } finally {
          setFetching(false)
        }
      }
    },

    [appInsights, options]
  )

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()

    if (options) {
      getDealers(token)
    }

    return () => {
      if (options) cancel()
    }
  }, [getDealers, options])

  return [searchDealerResponse, fetching]
}
