import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import { CommerceBillOfMaterial, CommercePart } from '../../../types/commerceApi'
import { sortBillOfMaterialByPriority } from '../../../helpers/commerceApiHelpers'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'

const GET_PART_BILL_OF_MATERIAL = loader('./schemas/partBillOfMaterialQuery.graphql')

interface PartBillOfMaterialQueryData {
  part: CommercePart
}

interface PartBillOfMaterialQueryVariables extends BaseGraphServiceVariables {
  [key: string]: any
  partNumber: string
}

const usePartBillOfMaterialService = (
  partNumber?: string
): GraphServiceTuple<CommerceBillOfMaterial | undefined> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [billOfMaterial, setBillOfMaterial] = useState<
    CommerceBillOfMaterial | undefined
  >(undefined)

  const onData = useCallback((data?: PartBillOfMaterialQueryData) => {
    if (data?.part?.billOfMaterial) {
      setBillOfMaterial(sortBillOfMaterialByPriority(data.part.billOfMaterial))
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    PartBillOfMaterialQueryData,
    PartBillOfMaterialQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && partNumber) {
      fetch({
        document: GET_PART_BILL_OF_MATERIAL,
        variables: {
          ...baseVariables,
          partNumber,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, partNumber])

  return [fetching, billOfMaterial]
}

export default usePartBillOfMaterialService
