import React from 'react'
import i18next from 'i18next'
import { Box, Flex, Text } from 'theme-ui'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import StatePill from '../../atoms/StatePill/StatePill'
import { BREAKPOINT_S, COLORS } from '../../../constants/themeConstants'
import useBreakpoints from '../../../hooks/useBreakpoints'
import Checkbox from '../../atoms/inputs/Checkbox/Checkbox'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import { VehicleDetailMaintenanceDatasource } from '../VehicleDetailMaintenance/VehicleDetailMaintenance'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { TextField } from '../../../types/layoutService'
import {
  RECALL_TYPE_PSFA,
  RECALL_TYPE_SFA,
  RECALL_TYPE_SR,
} from '../../../constants/dafCompanyVehicleConstants'

interface VehicleUpcomingMaintenancesProps {
  vehicleDetail: VehicleDetailResponse
  datasource: VehicleDetailMaintenanceDatasource
}

interface ChipProps {
  color: string
  advice: TextField
}

const VehicleRecallList = ({
  vehicleDetail,
  datasource,
}: VehicleUpcomingMaintenancesProps) => {
  const getRecallProps = (type: string) => {
    if (type === RECALL_TYPE_SR) {
      return {
        color: COLORS.ALERT,
        advice: datasource.recallAdviceWarning,
      } as ChipProps
    }
    if (type === RECALL_TYPE_PSFA) {
      return {
        color: COLORS.PROMOTION,
        advice: datasource.recallAdviceImportant,
      } as ChipProps
    }
    if (type === RECALL_TYPE_SFA) {
      return {
        color: COLORS.ORANGE_DARK,
        advice: datasource.recallAdviceAttention,
      } as ChipProps
    }

    return {} as ChipProps
  }

  const columnNames = [
    i18next.t('commonLabels.description'),
    i18next.t('servicePlan.referenceNumberLabel'),
    i18next.t('urgentTasks.advice'),
    i18next.t('maintenance.state'),
  ]

  const [breakpointS] = useBreakpoints(BREAKPOINT_S)

  return (
    <>
      {breakpointS ? (
        // === LARGE SCREEN VIEW ===
        <Box
          as="table"
          sx={{
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <Box
            as="thead"
            sx={{
              borderBlock: '1px solid',
              borderColor: 'gray.1',
              'th, td': {
                paddingBlock: 12,
                paddingInlineEnd: 24,
              },
            }}
          >
            <tr>
              <Box as="td">
                <Box sx={{ visibility: 'hidden' }}>
                  <Checkbox disabled />
                </Box>
              </Box>
              {columnNames.map((column) => (
                <Box
                  key={column}
                  as="th"
                  sx={{ textAlign: 'start', inlineSize: '25%' }}
                >
                  <Text
                    variant="smallText"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {column}
                  </Text>
                </Box>
              ))}
            </tr>
          </Box>
          {vehicleDetail.servicePlanner?.service.recalls.length > 0 && (
            <Box
              as="tbody"
              sx={{
                'th, td': {
                  paddingBlock: 24,
                  paddingInlineEnd: 24,
                },
              }}
            >
              {vehicleDetail.servicePlanner?.service.recalls?.map((recall) => (
                <Box
                  key={`${recall.id}`}
                  as="tr"
                  sx={{
                    borderBlockEnd: '1px solid',
                    borderColor: 'gray.1',
                    position: 'relative',
                  }}
                >
                  <td></td>
                  <Box as="th" sx={{ textAlign: 'start' }}>
                    <Text variant="heading4">{recall.description}</Text>
                  </Box>
                  <td>
                    <Text variant="bodySmall">{recall.id}</Text>
                  </td>

                  <td>
                    <Text variant="bodySmall">
                      {getTextFieldValue(getRecallProps(recall.type).advice)}
                    </Text>
                  </td>
                  <td>
                    <StatePill
                      state={i18next.t('myFleetFilters.recall')}
                      color={getRecallProps(recall.type).color}
                    />
                  </td>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ) : (
        // === SMALL SCREEN VIEW ===
        <Box
          as="ol"
          sx={{
            padding: 0,
            listStyle: 'none',
          }}
        >
          {vehicleDetail.servicePlanner?.service.recalls?.map((recall) => (
            <Box
              key={recall.id}
              as="li"
              sx={{
                paddingBlock: 24,
                borderBlockEnd: '1px solid',
                borderColor: 'gray.1',
                position: 'relative',
              }}
            >
              <Text
                as="h3"
                variant="heading4"
                sx={{
                  paddingBlockEnd: 4,
                }}
              >
                {recall.description}
              </Text>

              <Box
                as="dl"
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, auto)',
                  'dt, dd': {
                    paddingBlock: 4,
                  },
                  dd: {
                    margin: 0,
                    paddingInlineStart: 8,
                    marginInlineStart: 'auto',
                  },
                }}
              >
                <dt>
                  <Text variant="bodySmall">{columnNames[1]}</Text>
                </dt>
                <dd>
                  <Text variant="bodySmall">{recall.id}</Text>
                </dd>
                <dt>
                  <Text variant="bodySmall">{columnNames[2]}</Text>
                </dt>
                <dd>
                  <Text variant="bodySmall">
                    {getTextFieldValue(getRecallProps(recall.type).advice)}
                  </Text>
                </dd>
                <dt>
                  <Text variant="bodySmall">{columnNames[3]}</Text>
                </dt>
                <dd>
                  <StatePill
                    state={i18next.t('myFleetFilters.recall')}
                    color={getRecallProps(recall.type).color}
                  />
                </dd>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {!vehicleDetail.servicePlanner?.service.recalls.length && (
        <Flex
          sx={{
            justifyContent: 'center',
            color: COLORS.GRAY,
            paddingBlock: 32,
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
          }}
        >
          <Flex sx={{ gap: 2, alignItems: 'center' }}>
            <Box
              sx={{
                borderRadius: '50%',
                border: '2px solid',
                borderColor: COLORS.GREEN,
              }}
            >
              <IconWrapper icon={ICONS.CHECK} color={COLORS.GREEN} size={3} />
            </Box>
            <span>
              {getTextFieldValue(datasource.noVehicleUpgradesAvailableText)}
            </span>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default VehicleRecallList
