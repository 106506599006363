import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box, Flex } from 'theme-ui'

interface PaginationIndicatorProps {
  pageCount: number
  currentPageIndex: number
  onClick: (pageNumber: number) => void
}

const PaginationIndicator: FC<PaginationIndicatorProps> = ({
  pageCount,
  currentPageIndex,
  onClick,
}) => {
  const { transitions } = useTheme()

  return (
    <Flex sx={{ ':last-child': { mr: 0 } }}>
      {[...Array(pageCount)].map((_, index) => (
        <Box
          key={index.toString()}
          as="span"
          onClick={() => onClick(index)}
          sx={{
            p: 1,
            mr: 2,
            borderColor: index === currentPageIndex ? 'transparent' : 'gray.1',
            backgroundColor: index === currentPageIndex ? 'primary' : 'transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: `ease all ${transitions[1]}`,
          }}
        />
      ))}
    </Flex>
  )
}

export default PaginationIndicator
