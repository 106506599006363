import { ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import withClientSideRender from '../../../enhancers/withClientSideRender'

interface PortalProps {
  id: string
  children: ReactNode
}

const Portal = ({ children, id }: PortalProps) => {
  const elementRef = useRef(document.createElement('div'))

  useEffect(() => {
    const modalRoot = document.getElementById(id)
    const element = elementRef.current

    if (modalRoot && element) {
      modalRoot.appendChild(element)
    }

    return () => {
      if (modalRoot && element) {
        modalRoot?.removeChild(element)
      }
    }
  }, [elementRef, id])

  return ReactDOM.createPortal(children, elementRef.current)
}

export default withClientSideRender(Portal)
