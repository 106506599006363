import React from 'react'
import MyFleetListWrapperForContext from './MyFleetListWrapperForContext'
import MyFleetListContextProvider from '../../../providers/MyFleetListContextProvider'
import withData from '../../../enhancers/withData'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import {
  DeleteVehicleSidepanelDatasource,
  RequestMaintenanceAppointmentSidepanelDatasource,
  RequestNewMultisupportQuoteSidepanelDatasource,
  VehicleDescriptionProps,
} from '../../../types/VehicleDetailGenericComponentDataProps'
import { getEditableTextFieldValue } from '../../../helpers/layoutServiceHelper'

interface MyFleetListDatasourceProps {
  vehicleDetailLink: LinkField
  requestMaintenancePlanLink: LinkField
  requestMaintenancePlanBannerTitle: TextField
  requestMaintenancePlanBannerSubtitle: TextField
  descriptions: LinkField<VehicleDescriptionProps>
  requestNewMultisupportQuoteSidepanel: LinkField<RequestNewMultisupportQuoteSidepanelDatasource>
  requestMaintenanceAppointmentSidepanel: LinkField<RequestMaintenanceAppointmentSidepanelDatasource>
  deleteVehicleSidepanel: LinkField<DeleteVehicleSidepanelDatasource>
}

const MyFleetList = ({
  datasource: {
    vehicleDetailLink,
    requestMaintenancePlanLink,
    requestMaintenancePlanBannerTitle,
    requestMaintenancePlanBannerSubtitle,
    descriptions,
    requestNewMultisupportQuoteSidepanel,
    requestMaintenanceAppointmentSidepanel,
    deleteVehicleSidepanel,
  },
}: Datasource<MyFleetListDatasourceProps>) => (
  <>
    <MyFleetListContextProvider>
      <MyFleetListWrapperForContext
        requestMaintenancePlanLinkDatasource={requestMaintenancePlanLink}
        requestMaintenancePlanBannerTitleDatasource={
          requestMaintenancePlanBannerTitle
        }
        requestMaintenancePlanBannerSubtitleDatasource={
          requestMaintenancePlanBannerSubtitle
        }
        requestNewMultisupportQuoteSidepanelDatasource={
          requestNewMultisupportQuoteSidepanel?.targetItem
        }
        requestMaintenanceAppointmentSidepanelDatasource={
          requestMaintenanceAppointmentSidepanel?.targetItem
        }
        deleteVehicleSidepanelDatasource={deleteVehicleSidepanel?.targetItem}
        vehicleDetailLink={vehicleDetailLink}
        inputMileageThankYouMessageOnSuccess={getEditableTextFieldValue(
          descriptions?.targetItem?.inputMileageThankYou
        )}
        editServicePlanMessageOnSuccess={getEditableTextFieldValue(
          descriptions?.targetItem?.editServicePlanThankYouText
        )}
        noRequestForMaintenanceMessage={getEditableTextFieldValue(
          descriptions?.targetItem?.noRequestForMaintenancePlanText
        )}
      />
    </MyFleetListContextProvider>
  </>
)

export default withData(MyFleetList)
