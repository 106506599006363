import React, { FC } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'

interface BadgeProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css' | 'content'>> {
  amount?: number
}

const Badge: FC<BadgeProps> = ({
  amount = undefined,
  children,
  sx,
  ...boxProps
}) => (
  <Box
    sx={{
      position: 'relative',
      ...sx,
    }}
    {...boxProps}
  >
    {children}

    {!!amount && (
      <Text
        variant="smallText"
        sx={{
          display: 'flex',
          position: 'absolute',
          top: 0,
          right: [0, 0, 0, 'auto'],
          left: ['auto', 'auto', 'auto', '50%'],
          bg: 'primary',
          height: 16,
          minWidth: 16,
          padding: 1,
          borderRadius: 8,
          justifyContent: 'center',
          alignItems: 'center',
          color: 'textLight',
          fontWeight: 'bold',
        }}
      >
        {amount < 10000 ? amount : `${9999}+`}
      </Text>
    )}
  </Box>
)

export default Badge
