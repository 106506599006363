import React, { FC, useCallback } from 'react'
import { Flex, Text } from 'theme-ui'
import CallToActionImage from '../../molecules/CallToActionImage/CallToActionImage'
import SitecoreLink from '../../molecules/SitecoreLink/SitecoreLink'
import RichText from '../../atoms/RichText/RichText'
import { ConditionalLink } from '../../atoms/Link/Link'
import { resolveUrl } from '../../../helpers/linkFieldHelper'
import {
  CheckboxField,
  DateField,
  ImageWithFocalPointField,
  LinkField,
  TextField,
} from '../../../types/layoutService'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getFullTextDate } from '../../../helpers/dateHelper'
import { DEFAULT_CULTURE_CODE } from '../../../constants/userConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

export interface CallToActionWithImageBaseProps {
  title: TextField
  text?: TextField
  date?: DateField
  button?: LinkField
  imageField?: ImageWithFocalPointField
  wrapLink?: CheckboxField
  alignButtonToBottom?: boolean
}

const CallToActionWithImageBase: FC<CallToActionWithImageBaseProps> = ({
  imageField,
  title,
  text,
  date,
  button,
  wrapLink,
  alignButtonToBottom,
}) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const onClickHandler = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
      call_to_action_name: getTextFieldValue(title),
      call_to_action_type:
        DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE[
          wrapLink?.boolValue ? 'ARTICLE' : 'BUTTON'
        ],
      target_url: button?.url || '',
    })
  }, [title, button, wrapLink])

  return (
    <ConditionalLink
      condition={(wrapLink?.boolValue && !!button) || false}
      href={resolveUrl(button)}
      target={button?.target}
      onClick={onClickHandler}
      sx={{
        color: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        textDecoration: 'none',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          flexGrow: alignButtonToBottom ? 1 : 0,
        }}
      >
        <CallToActionImage imageField={imageField} />

        {date && date?.dateValue > 0 && (
          <Text
            color="gray.2"
            sx={{
              fontSize: 1,
              marginBlockEnd: 2,
            }}
          >
            {getFullTextDate(date?.dateValue, cultureCode ?? DEFAULT_CULTURE_CODE)}
          </Text>
        )}

        {!!title && (
          <Text as="h3" variant="heading3" pb={2}>
            {getTextFieldValue(title)}
          </Text>
        )}

        {!!text && (
          <Text
            sx={{
              p: { margin: 0 },
              color: 'gray.2',
              paddingBottom: 1,
              lineHeight: 'bodyContent',
            }}
          >
            <RichText textField={text} />
          </Text>
        )}
      </Flex>

      {!!button && (
        <Text
          sx={{
            lineHeight: 'heading2',
          }}
        >
          <SitecoreLink
            {...(wrapLink ? { as: 'span' } : {})}
            linkField={button}
            onClick={onClickHandler}
            sx={{
              outline: 'none',
              ':hover, :active, :focus': {
                textDecoration: 'underline',
              },
            }}
          />
        </Text>
      )}
    </ConditionalLink>
  )
}

export default CallToActionWithImageBase
