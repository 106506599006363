import { useContext, useMemo } from 'react'
import {
  WildcardPageContext,
  WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY,
  WILDCARD_PAGE_CONTEXT_TYPE_DEALER_PART,
  WILDCARD_PAGE_CONTEXT_TYPE_NEWS,
  WILDCARD_PAGE_CONTEXT_TYPE_PART,
  WILDCARD_PAGE_CONTEXT_TYPE_VEHICLE,
} from '../providers/WildcardPageContextProvider'

type ConfigurationName =
  | typeof WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY
  | typeof WILDCARD_PAGE_CONTEXT_TYPE_DEALER_PART
  | typeof WILDCARD_PAGE_CONTEXT_TYPE_NEWS
  | typeof WILDCARD_PAGE_CONTEXT_TYPE_PART
  | typeof WILDCARD_PAGE_CONTEXT_TYPE_VEHICLE

const useWildcardPageContext = <T>(
  configurationName: ConfigurationName
): Partial<T> => {
  const wildcardPageContext = useContext(WildcardPageContext)

  return useMemo<Partial<T>>(
    () => wildcardPageContext[configurationName] ?? {},
    [wildcardPageContext, configurationName]
  )
}

export default useWildcardPageContext
