import React, { ReactNode } from 'react'
import { Box, Text } from 'theme-ui'

interface FooterColumnProps {
  title: string
  children: ReactNode
}

const FooterColumn = ({ title, children }: FooterColumnProps) => (
  <Box sx={{ width: ['100%', '100%', '25%'], marginTop: [4, 4, 0] }}>
    <Text as="h3" variant="heading3" marginBottom={[1, 1, 3]}>
      {title}
    </Text>

    {children}
  </Box>
)

export default FooterColumn
