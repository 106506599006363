import React, { ReactNode } from 'react'
import PartStockInformationChip from './PartStockInformationChip'
import { ConditionalClickableWrapper } from '../../atoms/ClickableWrapper/ConditionalClickableWrapper'
import { STOCK_STATE } from '../../../constants/stockConstants'
import { StockInformationVariant } from './PartStockInformationList'

interface PartStockInformationStatusProps {
  stockState: string
  onClick?: () => void
  variant?: StockInformationVariant
  children: ReactNode
  dataTId?: string
}

const PartStockInformationStatus = ({
  stockState,
  children,
  onClick,
  variant,
  dataTId,
  ...rest
}: PartStockInformationStatusProps) => (
  <div>
    <ConditionalClickableWrapper
      condition={!!onClick}
      onClick={onClick}
      data-t-id={
        stockState === STOCK_STATE.AVAILABLE_AT_LOCATION ||
        stockState === STOCK_STATE.AVAILABLE_AT_YOUR_LOCATION ||
        stockState === STOCK_STATE.AVAILABLE ||
        stockState === STOCK_STATE.DIRECT_SHIP_DELIVERY
          ? `StockChip${children}-${stockState}`
          : `StockChip${children}`
      }
      sx={{ alignSelf: 'center', display: 'block' }}
      {...rest}
    >
      <PartStockInformationChip stockState={stockState}>
        {(stockState !== STOCK_STATE.DIRECT_SHIP_DELIVERY ||
          variant !== 'minimal') &&
          children}
      </PartStockInformationChip>
    </ConditionalClickableWrapper>
  </div>
)

export default PartStockInformationStatus
