import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, CancelToken } from 'axios'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import { isBrowser } from '../../../../helpers/dom'
import fetchPromotions from '../../../../services/rest/ecommerce/promotionService'
import useAxiosClient from '../core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { ApiVariant } from '../../../../types/layoutService'

const usePromotionService = (
  apiVariant?: ApiVariant
): [boolean, string[] | undefined] => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { user, userType } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [fetching, setFetching] = useState(false)
  const [promotionParts, setPromotionParts] = useState<string[] | undefined>(
    undefined
  )

  const getPromotions = useCallback(
    async (cancelToken: CancelToken) => {
      if (!actingCompanyId || !actingSupplierId || userType !== 'approved') return

      setFetching(true)

      try {
        const { data } = await fetchPromotions(
          apiVariant,
          client,
          cancelToken,
          actingCompanyId,
          actingSupplierId,
          user,
          isImpersonated
        )

        if (data) {
          setPromotionParts(data)
        } else {
          setPromotionParts([])
        }
      } catch (error) {
        const err = error as AxiosError<string[]>

        setPromotionParts([])

        appInsights.trackException({
          exception: new Error(
            `Unable to fetch promotions: ${err.message} (${err.code})`
          ),
          severityLevel: SeverityLevel.Error,
          properties: {
            actingCompanyId,
            actingSupplierId,
            user,
            isImpersonated,
          },
        })
      } finally {
        setFetching(false)
      }
    },

    [actingCompanyId, actingSupplierId, appInsights, isImpersonated, user, userType]
  )

  useEffect(() => {
    if (!isBrowser()) return

    const { token, cancel } = axios.CancelToken.source()

    const shouldFetch =
      actingCompanyId && actingSupplierId && typeof promotionParts === 'undefined'

    if (shouldFetch && !fetching) {
      getPromotions(token)
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (shouldFetch && fetching) cancel()
    }
  }, [actingCompanyId, actingSupplierId, fetching, getPromotions, promotionParts])

  return [fetching, promotionParts]
}

export default usePromotionService
