import React, { createContext, ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ActiveUserState } from '../types/layoutService'
import useSitecoreContext from '../hooks/useSitecoreContext'
import { decodeActiveUserStateCookie, getCookie } from '../helpers/cookieHelper'
import { ACTIVE_USER_STATE } from '../constants/cookieConstants'
import { RootState } from '../reducers'

export interface ActiveUserStateProviderContextProps {
  activeUserState?: ActiveUserState
  orderNumber?: string
}

export const ActiveUserStateContext =
  createContext<ActiveUserStateProviderContextProps>({
    activeUserState: undefined,
    orderNumber: undefined,
  })

interface ActiveUserStateProviderProps {
  children: ReactNode
}

const ActiveUserStateProvider = ({ children }: ActiveUserStateProviderProps) => {
  const { activeUserState } = useSitecoreContext()
  const orderSummary = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel.orderSummary
  )

  const refreshShoppingBasket = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel.refresh
  )

  const orderNumber = useMemo(() => {
    const orderNumberFromCookie = decodeActiveUserStateCookie(
      getCookie(ACTIVE_USER_STATE)
    )?.selectedOrderNumber

    return (
      orderNumberFromCookie ||
      activeUserState?.selectedOrderNumber ||
      orderSummary?.orderNumber
    )
  }, [activeUserState, orderSummary, refreshShoppingBasket])

  return (
    <ActiveUserStateContext.Provider
      value={{
        activeUserState,
        orderNumber,
      }}
    >
      {children}
    </ActiveUserStateContext.Provider>
  )
}

export default ActiveUserStateProvider
