import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'

interface PartsNavigationPanelBodyProps {
  children: ReactNode
}

const PartsNavigationPanelBody = ({ children }: PartsNavigationPanelBodyProps) => (
  <Box
    sx={{
      position: 'relative',
      width: ['100%', '100%', '100%', '360px'],
    }}
  >
    {children}
  </Box>
)

export default PartsNavigationPanelBody
