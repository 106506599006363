import React, { FC, useContext, useEffect, useState } from 'react'
import { Flex } from 'theme-ui'
import axios from 'axios'
import { useTheme } from '@emotion/react'
import { UserContext } from '../../../../providers/UserProvider'
import {
  CATEGORY_FILTER_ID,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PARTS_PER_PAGE_COUNT,
} from '../../../../constants/searchConstants'
import { filterItemsFromFilterService } from '../../../../helpers/filterHelper'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import Spinner from '../../../atoms/Spinner/Spinner'
import PartsNavigationFilterList from '../PartsNavigationFilterList'
import { FilterGroup } from '../../../../types/filterProps'
import { mapFiltersToSearchObject } from '../../../../helpers/searchResultsHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { executePartSearchFilter } from '../../../../services/rest/ecommerce/partSearchFilter'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'

interface PartsNavigationPanelSubcategoryFilterListProps {
  partsBaseUri: string
  categoryId?: string
  subCategoryId?: string
}

const PartsNavigationPanelSubcategoryFilterList: FC<
  PartsNavigationPanelSubcategoryFilterListProps
> = ({ partsBaseUri, categoryId, subCategoryId }) => {
  const {
    languageContext: { countryCode, cultureCode },
    xSessionId,
  } = useSitecoreContext()
  const { mainNavigation } = useTheme()
  const { user } = useContext(UserContext)
  const { categories } = useContext(CategoriesContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [filters, setFilters] = useState<FilterGroup[]>([])
  const [fetching, setFetching] = useState(false)

  const client = useAxiosClient(AxiosClientType.CommerceApi)

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()
    const allDataReceived = subCategoryId && subCategoryId !== ''

    if (allDataReceived) {
      setFetching(true)

      executePartSearchFilter(
        client,
        '',
        token,
        cultureCode,
        countryCode,
        DEFAULT_PAGE_INDEX,
        DEFAULT_PARTS_PER_PAGE_COUNT,
        mapFiltersToSearchObject({ [CATEGORY_FILTER_ID]: [subCategoryId] }),
        user,
        xSessionId,
        isImpersonated,
        actingCompanyId,
        actingSupplierId
      )
        .then(({ data }) => {
          setFilters(
            filterItemsFromFilterService([CATEGORY_FILTER_ID], data) as FilterGroup[]
          )
        })
        .finally(() => setFetching(false))
    }

    return () => {
      if (allDataReceived) cancel()
    }
  }, [
    categories,
    categoryId,
    subCategoryId,
    cultureCode,
    countryCode,
    user,
    xSessionId,
    isImpersonated,
    actingCompanyId,
    actingSupplierId,
  ])

  if (!categoryId && !subCategoryId) return null

  if (fetching)
    return (
      <Flex
        sx={{
          pt: 5,
          justifyContent: 'center',
          color: mainNavigation.textColor,
        }}
      >
        <Spinner
          data-t-id="navigation-panel-subcategory-loader"
          color="currentColor"
        />
      </Flex>
    )

  if (!filters?.length) return null

  return (
    <PartsNavigationFilterList
      mainMenuCategories={categories}
      categoryId={categoryId}
      subCategoryId={subCategoryId}
      filters={filters}
      partsBaseUri={partsBaseUri}
    />
  )
}

export default PartsNavigationPanelSubcategoryFilterList
