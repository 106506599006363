import React, { FC, Fragment, useContext } from 'react'
import { ALIGN } from '../../../constants/menuConstants'
import { MenuProviderContext } from '../../../providers/MenuProvider'
import MenuList from '../../molecules/MenuList/MenuList'
import MenuResponsive from '../../molecules/MenuResponsive/MenuResponsive'
import { NavigationItem as NavigationItemProps } from '../../../types/layoutService'
import { BaseItem } from './BaseItem'
import { LinkItem, SubNavigationItem } from '.'

export interface SubNavigationProps {
  title: string
  children?: NavigationItemProps[]
}

export const SubNavigation: FC<SubNavigationProps> = ({ children, title }) => {
  const { fetching } = useContext(MenuProviderContext)

  const SubMenuListElement = (
    <>
      {!!children?.length && (
        <MenuList>
          {!fetching &&
            children.map((child, i) => (
              <Fragment key={i.toString()}>
                {child.url && (
                  <SubNavigationItem href={child.url}>
                    {child.title}
                  </SubNavigationItem>
                )}
              </Fragment>
            ))}
        </MenuList>
      )}
    </>
  )

  if (!children?.length) return null

  if (children.length === 1) {
    return <LinkItem type="general" url={children[0].url} title={title} />
  }

  return (
    <MenuResponsive
      testId="menu-button-sub-navigation"
      align={ALIGN.RIGHT}
      title={title}
      anchorElement={(isMenuActive) => (
        <BaseItem includeArrow active={isMenuActive} title={title} />
      )}
      anchorElementMobile={<BaseItem includeArrow title={title} />}
      menuListElements={SubMenuListElement}
    />
  )
}
