import React, { FC } from 'react'
import ContentSlider from '../ContentSlider/ContentSlider'
import ContentCard from '../../molecules/ContentCard/ContentCard'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  LinkField,
  MultilistField,
  TextField,
} from '../../../types/layoutService'
import { Image } from '../../../types/dafResponseProps'
import {
  getMultiListFieldItems,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import GenericContentSliderSkeleton from './GenericContentSliderSkeleton'

export interface GenericContentSliderDatasource {
  title: TextField
  slides: MultilistField<Slide>
}

export interface Slide {
  id: string
  version: number
  name: string
  description: TextField
  englishDescription: TextField
  link: LinkField
  image: Image
}

const GenericContentSlider: FC<Datasource<GenericContentSliderDatasource>> = ({
  datasource: { title, slides: rawSlides },
}) => {
  const slides = getMultiListFieldItems(rawSlides)

  if (!slides?.length) return null

  return (
    <ContentSlider title={getTextFieldValue(title)} slidesPerPage={6}>
      {slides.map((item, i) => (
        <ContentCard
          key={i.toString()}
          description={item.description}
          image={item.image}
          link={item.link}
        />
      ))}
    </ContentSlider>
  )
}

export default withData(GenericContentSlider, {
  showMessageWhenPageEditing: true,
  pageEditingComponent: GenericContentSliderSkeleton,
})
