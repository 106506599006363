import React, { FC, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { refetchShoppingBasketSidepanelSummary } from '../../../actions/shoppingBasket/sidePanelActions'
import {
  addOrReplaceOrderItem,
  createOrderMutation,
  markOrderMutationFinished,
} from '../../../actions/shoppingBasket/wizard'
import { ICONS } from '../../../constants/iconConstants'
import useCommerceApiResponse from '../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveUserStateContext } from '../../../providers/ActiveUserStateProvider'
import { CommerceMessage, CommerceOrderItem } from '../../../types/commerceApi'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Spinner from '../../atoms/Spinner/Spinner'
import { registerModalNotification } from '../../../actions/notificationActions'
import useCommercePostOrderItemService from '../../../hooks/services/usePostOrderItemService'
import { getQuantityDelta } from '../../../helpers/priceHelper'

interface PartListLineRemoveProps {
  part: CommerceOrderItem
}

const PartListLineRemove: FC<PartListLineRemoveProps> = ({ part }) => {
  const { orderNumber } = useContext(ActiveUserStateContext)
  const dispatch = useDispatch()

  const [posting, response, postToShoppingBasket] = useCommercePostOrderItemService()

  const onResult = useCallback(
    (orderItem: CommerceOrderItem | null) => {
      if (!orderItem) return

      dispatch(addOrReplaceOrderItem(orderItem))
      dispatch(refetchShoppingBasketSidepanelSummary(true))
      dispatch(markOrderMutationFinished())
    },
    [dispatch]
  )

  const onMessage = useCallback(
    (commerceMessage: CommerceMessage) => {
      if (commerceMessage.message && commerceMessage.severity === 'Error') {
        dispatch(registerModalNotification('Error', commerceMessage.message))
      }
    },
    [dispatch]
  )

  const revertQuantity = useCallback(async () => {
    if (!part.suggestionInformation?.suggestedQuantity) return

    const computedQuantity = getQuantityDelta(
      part.quantity,
      part.suggestionInformation.suggestedQuantity
    )

    dispatch(createOrderMutation())

    await postToShoppingBasket({
      orderNumber,
      items: [
        {
          partNumber: part.partNumber,
          quantity: computedQuantity,
        },
      ],
    })
  }, [part, dispatch, orderNumber, postToShoppingBasket])

  useCommerceApiResponse<CommerceOrderItem>({
    response,
    onResult,
    onMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => (results as CommerceOrderItem[])?.[0],
  })

  if (posting) return <Spinner size={2} />

  return (
    <ClickableWrapper
      onClick={revertQuantity}
      data-t-id="shopping-basket-revert-order-item"
    >
      <IconWrapper icon={ICONS.REVERT} size={4} color="primary" />
    </ClickableWrapper>
  )
}

export default PartListLineRemove
