import React, { FC, useCallback } from 'react'
import { FlexProps, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { ICONS } from '../../../constants/iconConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { SkeletonComponent } from '../../../types/layoutProps'
import { NewsArticle, TextField } from '../../../types/layoutService'
import Button from '../../atoms/Button/Button'
import Link from '../../atoms/Link/Link'
import CallToActionImage from '../../molecules/CallToActionImage/CallToActionImage'
import GridCardContainer from '../../molecules/GridCardContainer/GridCardContainer'
import NewsArticleCardSkeleton from './NewsArticleCardSkeleton'

export interface NewsArticleCardProps extends Omit<FlexProps, 'css'> {
  href: string
  buttonText: TextField
}

const NewsArticleCard: FC<NewsArticle & NewsArticleCardProps> &
  SkeletonComponent = ({
  href,
  thumbnailImage,
  title,
  excerpt,
  buttonText,
  sx,
  ...flexProps
}) => {
  const onClickHandler = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
      call_to_action_name: title,
      call_to_action_type: DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.ARTICLE,
      target_url: href,
    })
  }, [title, href])

  return (
    <GridCardContainer sx={sx} {...flexProps}>
      <Link
        href={href}
        onClick={onClickHandler}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          textDecoration: 'none',
          ':hover span': {
            borderColor: 'primary',
          },
        }}
      >
        <CallToActionImage imageField={thumbnailImage || {}} />

        <Text variant="heading2" as="h3" sx={{ color: 'text', pb: 2 }}>
          {title}
        </Text>

        <Text
          variant="bodyContent"
          as="p"
          sx={{
            p: { margin: 0 },
            color: 'gray.2',
            marginBottom: 3,
            lineHeight: 'heading2',
            flexGrow: 1,
          }}
        >
          {excerpt}
        </Text>

        <Button
          variant="outline"
          as="span"
          iconPosition="end"
          icon={ICONS.ARROW_RIGHT}
          iconSize={4}
        >
          {getTextFieldValue(buttonText)}
        </Button>
      </Link>
    </GridCardContainer>
  )
}

NewsArticleCard.Skeleton = NewsArticleCardSkeleton

export default NewsArticleCard
