import i18next from 'i18next'
import React, { useContext } from 'react'
import { Box } from 'theme-ui'
import { getBasketMenuItems } from '../../../helpers/menuHelper'
import { MenuProviderContext } from '../../../providers/MenuProvider'
import ActionItemBase from '../../atoms/ActionItemBase/ActionItemBase'
import Link from '../../atoms/Link/Link'
import Spinner from '../../atoms/Spinner/Spinner'
import SidePanelSection from '../SidePanel/SidePanelSection'

const ShoppingBasketQuickLinks = () => {
  const { fetching, menu } = useContext(MenuProviderContext)

  const basketMenu = getBasketMenuItems(menu)

  if (fetching)
    return (
      <Box sx={{ paddingTop: 3 }}>
        <Spinner />
      </Box>
    )

  if (!fetching && !basketMenu) return null

  return (
    <SidePanelSection title={i18next.t('commonLabels.quickLinks')}>
      <nav>
        {basketMenu?.map(({ description, id, url }) => (
          <Link key={id} href={url}>
            <ActionItemBase>{description}</ActionItemBase>
          </Link>
        ))}
      </nav>
    </SidePanelSection>
  )
}

export default ShoppingBasketQuickLinks
