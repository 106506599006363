import { Action } from 'redux'
import { PUSH_TO_DIGITAL_MAGAZINE_MENU } from '../../constants/digitalMagazineConstants'

export interface DigitalMagazineMenuItemData {
  menuTitle: string
  menuId: string
}

export interface PushToDigitalMagazineMenuAction
  extends Action<typeof PUSH_TO_DIGITAL_MAGAZINE_MENU> {
  payload: DigitalMagazineMenuItemData
}

export type PushToDigitalMagazineActions = PushToDigitalMagazineMenuAction

export const pushToDigitalMagazineMenu = (
  payload: DigitalMagazineMenuItemData
): PushToDigitalMagazineMenuAction => ({
  type: PUSH_TO_DIGITAL_MAGAZINE_MENU,
  payload,
})
