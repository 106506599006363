import React, { useContext } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import Button from '../../atoms/Button/Button'
import { i18nextZeroPluralization } from '../../../helpers/translationHelper'
import { RewardsOverviewDatasourceProps } from '../../organisms/RewardsOverviewWrapper/RewardsOverviewWrapper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  getFilterStringByActiveSearchResultFilterObject,
  toggleFilter,
} from '../../../helpers/filterHelper'
import { useHistory } from 'react-router-dom'
import { RewardsContext } from '../../../providers/RewardsProvider'
import { URL_PAGE_PARAM } from '../../../constants/urlConstants'

interface RewardsOverviewFilterControlsProps {
  totalCount: number
  datasource: RewardsOverviewDatasourceProps
}

const RewardsOverviewFilterControls = ({
  totalCount,
  datasource,
}: RewardsOverviewFilterControlsProps) => {
  const history = useHistory()
  const { activeFilters } = useContext(RewardsContext)

  const updateFilters = (filterOption: string) => {
    const updatedFilterObject = toggleFilter(
      activeFilters?.[0] || {},
      'category',
      filterOption
    )
    const params = new URLSearchParams(history.location.search)
    params.delete(URL_PAGE_PARAM)

    history.push(
      `?${getFilterStringByActiveSearchResultFilterObject(
        updatedFilterObject,
        params,
        false,
        false
      )}`
    )
  }

  const isFilterActive = (filterOption: string) =>
    activeFilters?.[0]?.category?.includes(filterOption) || false

  const renderFilterButton = (filterOption: string, label: string) => (
    <Button
      variant={isFilterActive(filterOption) ? 'primary' : 'outline'}
      onClick={() => updateFilters(filterOption)}
    >
      {label}
    </Button>
  )

  return (
    <>
      <Box>
        <Text
          variant="bodySmall"
          sx={{
            fontWeight: 'bold',
          }}
        >
          {getTextFieldValue(datasource.filterTitle)}
        </Text>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr auto'],
          gap: 3,
          alignItems: 'center',
          marginBlockStart: '8px',
        }}
      >
        <Box>
          <Flex sx={{ gap: 2 }}>
            {renderFilterButton(
              'availableForMe',
              getTextFieldValue(datasource?.availableForMeLabel)
            )}
            {renderFilterButton(
              'productMerchandiseVoucher',
              getTextFieldValue(datasource?.productMerchandiseVoucherLabel)
            )}
            {renderFilterButton(
              'creditMerchandiseVoucher',
              getTextFieldValue(datasource?.creditMerchandiseVoucherLabel)
            )}
          </Flex>
        </Box>
        <Box>
          <Text variant="bodySmall" sx={{ px: [0, 0, 3], color: 'gray.2' }}>
            {`${totalCount || ''} ${i18nextZeroPluralization(
              'paginationLabels.results',
              totalCount
            )}`}
          </Text>
        </Box>
      </Box>
    </>
  )
}

export default RewardsOverviewFilterControls
