import React, { FC } from 'react'
import {
  resolveTarget,
  resolveText,
  resolveUrl,
} from '../../../helpers/linkFieldHelper'
import { LinkField } from '../../../types/layoutService'
import Link, { LinkProps } from '../../atoms/Link/Link'

interface SitecoreLinkProps
  extends Pick<LinkProps, Exclude<keyof LinkProps, 'css'>> {
  linkField?: LinkField
}

const SitecoreLink: FC<SitecoreLinkProps> = ({ linkField, children, ...rest }) => {
  const url = resolveUrl(linkField)

  if (!url) return null

  return (
    <Link href={url} target={resolveTarget(linkField)} {...rest}>
      {children || resolveText(linkField)}
    </Link>
  )
}

export default SitecoreLink
