import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import { Promotion } from '../../../../types/promotionProps'
import { getDividerSx } from '../../Divider/Divider'
import useCurrencyFormatter from '../../../../hooks/useCurrencyFormatter'
import { Price } from '../../../../hooks/services/graphql/usePartSalesInformationService'

interface DiscountPromotionProps {
  promotion: Promotion
  price: Price
}

const DiscountPromotion: FC<DiscountPromotionProps> = ({ promotion, price }) => {
  const currencyFormatter = useCurrencyFormatter(price)

  const rows = useMemo(
    () => [
      {
        label: i18next.t('discountPriceLabels.recommendedPrice'),
        value: currencyFormatter(price?.retailUnitPrice),
        dataTestId: 'Promotion-original-price',
      },
      {
        label: i18next.t('discountPriceLabels.newPrice'),
        value: promotion.label,
        dataTestId: 'Promotion-your-discount',
      },
      {
        label: i18next.t('discountPriceLabels.discountPrice'),
        value: currencyFormatter(price?.netUnitPrice),
        dataTestId: 'Promotion-your-retail-price',
      },
    ],
    [currencyFormatter, price, promotion.label]
  )

  return (
    <>
      {rows.map(
        ({ label, value, dataTestId }, i) => (
          <Text
            key={dataTestId}
            variant="smallText"
            sx={{
              display: 'flex',
              width: '100%',
              color: 'gray.2',
              justifyContent: 'space-between',
              fontSize: 0,
              lineHeight: '12px',
              paddingY: '12px',
              paddingX: '6px',
              ...(i > 0 ? getDividerSx('top') : { paddingTop: 0 }),
            }}
          >
            <Text sx={{ display: 'flex' }} variant="bodyContent">
              {label}
            </Text>

            <Text
              data-t-id={dataTestId}
              variant="bodyContent"
              sx={{
                display: 'flex',
                flexBasis: '50%',
                alignSelf: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {value}
            </Text>
          </Text>
        ),
        []
      )}
    </>
  )
}

export default DiscountPromotion
