import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { CommerceBomMasterWithoutPart } from '../../../types/commerceApi'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'

const GET_ASSEMBLY_LIST = loader('./schemas/assemblyListQuery.graphql')

export interface AssemblyListQueryData {
  bomMastersWithoutPart: CommerceBomMasterWithoutPart[]
}

export interface AssemblyListQueryVariables extends BaseGraphServiceVariables {
  [key: string]: any
  bomIds: number[]
  includeBomItems?: boolean
}

const sortBomMastersWithoutPart = (assemblies: CommerceBomMasterWithoutPart[]) =>
  assemblies?.map((assembly) => ({
    ...assembly,
    bomItem: assembly?.bomItem?.sort((a, b) => a.priority - b.priority),
  }))

const useAssemblyListService = (
  bomIds?: number[],
  includeBomItems?: boolean
): GraphServiceTuple<CommerceBomMasterWithoutPart[] | undefined> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [assemblyList, setAssemblyList] = useState<
    CommerceBomMasterWithoutPart[] | undefined
  >(undefined)

  const onData = useCallback((data?: AssemblyListQueryData) => {
    if (data?.bomMastersWithoutPart?.length) {
      setAssemblyList(sortBomMastersWithoutPart(data.bomMastersWithoutPart))
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    AssemblyListQueryData,
    AssemblyListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && bomIds?.length) {
      fetch({
        document: GET_ASSEMBLY_LIST,
        variables: {
          ...baseVariables,
          bomIds,
          includeBomItems,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, bomIds, includeBomItems])

  return [fetching, assemblyList]
}

export default useAssemblyListService
