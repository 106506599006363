import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { TextField } from '../../../types/layoutService'

interface DealerLocatorNoResultsProps {
  label: TextField
}

const DealerLocatorNoResults: FC<DealerLocatorNoResultsProps> = ({ label }) => (
  <Text
    as="p"
    variant="bodyCompact"
    sx={{ width: '100%', color: 'gray.2', paddingBottom: 5, textAlign: 'center' }}
  >
    {getTextFieldValue(label)}
  </Text>
)

export default DealerLocatorNoResults
