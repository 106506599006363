import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { CommerceResponse } from '../../../types/commerceApi'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { Mileage, ServicePlanner, VehicleActions } from './dafCompanyVehicles'

export interface VehicleDetailResponse {
  vehicleDetail: VehicleDetail
  rmContract: RmContract
  servicePlanner: ServicePlanner
  mileage: Mileage
  hasDafConnect: boolean
  vehicleActions: VehicleActions
}

export interface VehicleDetail {
  customerVehicleId: string
  vehicleAttributes: VehicleAttributes
  nonMultiSupportServices: NonMultiSupportServices[]
}

export interface NonMultiSupportServices {
  type: string
  description: string
}

interface VehicleAttributes {
  urlVehicleModelYearSeries: string
  registrationNumber: string
  vehicleType: string
  vin: string
  firstRegistrationDate: string
  enginePower: string
  engineType: string
  engineRetarder: string
  exhaustEmission: string
  gearboxMakeType: string
  gearboxType: string
  wheelPlanType: string
  rearAxleType: string
  chassisNumber: string
  make: string
}

interface RmContract {
  mostRecentContractForVehicle: boolean
  servicePlan: ServicePlan
  finance: Finance
}

interface ServicePlan {
  packageName: string
  details: Details
  coverageSummary: CoverageSummary
  status: Status
  otherServices: Record<string, unknown>
}

interface Status {
  currentMonth: number | null
  contractState: string | null
  deviation: number | null
}

interface CoverageSummary {
  coverage: Coverage
  contractLines: ContractLine[]
  contractPackageAbbreviation: string
}

interface Coverage {
  wearTear: boolean | null
  nonDriveline: boolean | null
  driveline: boolean | null
  maintenance: boolean | null
}

export interface ContractLine {
  contractLine: string
}

interface Details {
  number: string
  startDate: string | null
  endDate: string | null
  yearlyKilometers: number | null
  startKilometers: number | null
  endKilometers: number | null
  cancellationDate: string | null
  durationInMonthsEndDate: number | null
  durationInMonthsCancelDate: number | null
  endType: string
  sellingDealer: {
    name: string
    locationCode: string
  }
  serviceDealers: ServiceDealer[]
  mainServiceDealer: ServiceDealer
}

interface ServiceDealer {
  locationCode: string
  name?: string
}

interface Finance {
  invoicing: Invoicing
  financialDetails: FinancialDetails
  mileageSettlementDetails: MileageSettlementDetails
  mileageSettlementStatus: MileageSettlementStatus
  status: Status
  payment: Payment
  companyIsOnlyVehicleOperator: boolean
}

export interface Payment {
  paymentTermsDay: number
  monthlyAmount: number
  currency: string
  paymentMethod: string
}

interface MileageSettlementDetails {
  type: string
  month: number
  minimumInterval: number
  finalSettlementStatus: string
  latestMileageSettlementDate: string
  latestMileageSettlementMileage: number
  mileageSettlementInputState: string
  birthDate: string
  nextMileageSettlementInput: NextMileageSettlementInput
}

export interface NextMileageSettlementInput {
  date: string
  trigger: string
}

export interface MileageSettlementStatus {
  expectedCurrentMileage: number
  deviation: number
  nextMileageSettlementInput: NextMileageSettlementInput
  mileageEstimation: number
  mileageRetrievalDate: Date
}

interface Invoicing {
  paymentTermsDay: number | null
  monthlyAmount: number | null
  currency: string
}

interface FinancialDetails {
  paymentMethod: string
  installmentFrequency: string
  customerPriceIndexation: CustomerPriceIndexation
}

interface CustomerPriceIndexation {
  inflationProtected: boolean
  type: string
  timing: string
  month1: number | null
  month2: number | null
}

interface DafVehicleDetailReference {
  messages: null
  result: VehicleDetail
}

export interface DafVehicleDetailRequest {
  chassisNumber: string | null | undefined
  cultureCode: string | undefined
  isDealerAdmin?: boolean
}

export type DafVehicleDetailData = DafVehicleDetailReference

const fetchDafCompanyDetail = (
  props: Partial<DafVehicleDetailRequest>,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<VehicleDetailResponse>>> =>
  client.post('/daf-vehicle-detail', props, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default fetchDafCompanyDetail
