import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios'
import { RequestFunction } from './useCommerceApiFetchService'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import useAxiosClient from '../core/useAxiosClient'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import useSitecoreContext from '../../../useSitecoreContext'

type Executor<RequestProps> = (requestProps: RequestProps) => Promise<void>

type UseCommerceApiPostServiceTuple<RequestProps, ResponseType> = [
  boolean,
  AxiosResponse<ResponseType> | undefined,
  Executor<RequestProps>
]

const useCommerceApiPostService = <RequestProps, ResponseType>(
  requestFunction: RequestFunction<RequestProps, ResponseType>
): UseCommerceApiPostServiceTuple<RequestProps, ResponseType> => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { xSessionId } = useSitecoreContext()
  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [fetching, setFetching] = useState(false)
  const [response, setResponse] = useState<AxiosResponse<ResponseType> | undefined>(
    undefined
  )
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined)

  const executor = useCallback(
    async (requestProps: RequestProps) => {
      if (!actingCompanyId || !actingSupplierId) return

      const source = axios.CancelToken.source()

      setCancelTokenSource(source)

      const requestContext = {
        customerCompanyId: actingCompanyId,
        supplierCompanyId: actingSupplierId,
        user,
        isImpersonated,
        xSessionId,
      }

      setFetching(true)

      try {
        const requestResponse = await requestFunction(
          client,
          requestProps,
          { cancelToken: source.token },
          requestContext
        )

        setResponse(requestResponse)
      } catch (error) {
        const err = error as AxiosError<ResponseType>
        if (!err.code || err.code === 'ERR_CANCELED') return

        setResponse(err.response)

        appInsights.trackException({
          exception: new Error(
            `Unable to execute ${requestFunction.name}: ${err.message} (${err.code})`
          ),
          severityLevel: SeverityLevel.Error,
          properties: {
            ...requestProps,
            ...requestContext,
          },
        })
      } finally {
        setFetching(false)
      }
    },

    [
      actingCompanyId,
      actingSupplierId,
      requestFunction,
      user,
      isImpersonated,
      xSessionId,
      appInsights,
    ]
  )

  useEffect(
    () => () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
      }
    },
    [cancelTokenSource]
  )

  return [fetching, response, executor]
}

export default useCommerceApiPostService
