import { useTheme } from '@emotion/react'
import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import { isBrowser, runOnWindow } from '../../../helpers/dom'
import { loggedIn } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import { Flag } from '../../../types/layoutService'
import Container from '../Container/Container'

interface MainNavigationContainerProps {
  isTransparent?: Flag
  children: ReactNode
}

const SCROLL_THRESHOLD = 10 // 10px

const MainNavigationContainer: FC<MainNavigationContainerProps> = ({
  isTransparent,
  children,
}) => {
  const { user } = useContext(UserContext)
  const { mainNavigation, transitions, colors } = useTheme()
  const navigationRef = useRef<HTMLDivElement>(null)

  const [scrolled, setScrolled] = useState(
    runOnWindow((w) => w.scrollY > SCROLL_THRESHOLD)
  )

  const trackScroll = useCallback(() => {
    if (window.scrollY > SCROLL_THRESHOLD && !scrolled) {
      setScrolled(true)
    } else if (window.scrollY <= SCROLL_THRESHOLD && scrolled) {
      setScrolled(false)
    }
  }, [scrolled])

  useEffect(() => {
    if (!isBrowser() || !isTransparent) return

    window.addEventListener('scroll', trackScroll)

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', trackScroll)
    }
  }, [scrolled, isTransparent, trackScroll])

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 2,
        width: '100%',
        '> div': {
          position: isTransparent ? 'absolute' : 'relative',
          width: '100%',
          '--active-parent-link-color':
            isTransparent === '1'
              ? colors.textLight
              : mainNavigation.parentLinkColor,
          ...(isTransparent === '1'
            ? {
                bg: scrolled ? 'gray.4' : 'transparent',
                color: 'textLight',
                transition: `ease all ${transitions[1]}`,
                'div button + div': {
                  'button, a': {
                    opacity: 1,
                  },
                  'button:hover, a:hover, button:focus, a:focus': {
                    color: 'primary',
                  },
                },
                'button:hover, a:hover, button:focus, a:focus': {
                  opacity: 1,
                  color: 'textLight',
                },
                '::after': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  opacity: 0.16,
                  display: 'block',
                  position: 'absolute',
                  bg: scrolled ? 'transparent' : 'background',
                },
              }
            : {
                bg: mainNavigation.background,
                color: mainNavigation.textColor,
                'button:hover, a:hover, button:focus, a:focus': {
                  color: mainNavigation.textActiveColor,
                },
                '::after': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  opacity: mainNavigation.dividerOpacity,
                  display: 'block',
                  position: 'absolute',
                  bg: mainNavigation.dividerColor,
                },
              }),
        },
      }}
    >
      <div ref={navigationRef}>
        <Container
          sx={{
            display: 'flex',
            paddingBottom: !loggedIn(user) && isTransparent ? 0 : [3, 3, 3, 0],
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {children}
        </Container>
      </div>
    </Box>
  )
}

export default MainNavigationContainer
