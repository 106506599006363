import React, { useContext } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import WithData from '../../../enhancers/withData'
import { Datasource, TextField as TextFieldType } from '../../../types/layoutService'
import { COLORS } from '../../../constants/themeConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'

import { MyAccountPageProvider } from '../../../providers/MyAccountPageProvider'
import UserSettingsOverview from './UserSettingsOverview'
import { UserContext } from '../../../providers/UserProvider'

interface LanguageSwitcherProps {
  languageItems: LanguageItem[]
}

interface LanguageItem {
  name: string
  url: string
  isActive?: boolean
  displayName: string
  regionName: string
  cultureCode: string
}

interface UserSettingsDatasource {
  title: TextFieldType
  description: TextFieldType
  firstAndLastNameLabel: TextFieldType
  emailLabel: TextFieldType
  passwordLabel: TextFieldType
  passwordText: TextFieldType
  languageLabel: TextFieldType
}

const UserSettings = ({
  datasource: {
    title,
    description,
    firstAndLastNameLabel,
    emailLabel,
    passwordLabel,
    passwordText,
    languageLabel,
  },
  languageItems,
}: Datasource<UserSettingsDatasource> & LanguageSwitcherProps) => {
  const { user } = useContext(UserContext)

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <MyAccountPageProvider>
        {title && (
          <Text
            variant="heading1"
            sx={{
              color: COLORS.BLACK,
              fontSize: 5,
              lineHeight: 'heading',
            }}
          >
            {i18next.t(getTextFieldValue(title), { name: user?.profile?.fullName })}
          </Text>
        )}
        {description && (
          <Box sx={{ width: ['100%', '66%'] }}>
            <Text
              variant="heading2"
              sx={{
                marginTop: 3,
                color: COLORS.GRAY,
                lineHeight: 'body',
                fontSize: 2,
              }}
            >
              {getTextFieldValue(description)}
            </Text>
          </Box>
        )}
        <UserSettingsOverview
          languageItems={languageItems}
          labels={{
            firstAndLastNameLabel: getTextFieldValue(firstAndLastNameLabel),
            emailLabel: getTextFieldValue(emailLabel),
            passwordLabel: getTextFieldValue(passwordLabel),
            passwordText: getTextFieldValue(passwordText),
            languageLabel: getTextFieldValue(languageLabel),
          }}
        />
      </MyAccountPageProvider>
    </Flex>
  )
}
export default WithData(UserSettings)
