import {
  DEFAULT_PARTS_PER_PAGE_COUNT,
  VIEW_TYPE_TABLE,
} from '../constants/searchConstants'
import { SetFilters } from '../services/rest/ecommerce/partSearch'
import { CommerceCategory } from '../types/commerceApi'
import { Attribute } from '../types/dafResponseProps'
import { ActiveSearchResultFilters } from '../types/filterProps'
import { PaginationSettingsContext } from '../types/sitecoreContextProps'

export function getTotalSearchResultsString(totalCount: number) {
  return totalCount >= 200 ? '200+' : totalCount
}

export const getGroupedPartAttributes = (attributes?: Attribute[]) => {
  let groupedAttributes: { [key: string]: Attribute[] } = {}

  if (attributes) {
    attributes.forEach((item) => {
      const key = item.id
      groupedAttributes = {
        ...groupedAttributes,
        [key]: [...(groupedAttributes[key] || []), item],
      }
    })
  }
  return groupedAttributes
}

export const mapFiltersToSearchObject = (
  selectedFilters?: ActiveSearchResultFilters
): SetFilters[] => {
  if (!selectedFilters) return []

  const filterGroups = Object.keys(selectedFilters)

  if (!filterGroups.length) return []

  return Object.keys(selectedFilters).map((group) => ({
    id: group,
    values: selectedFilters[group].map((filterOption) => ({
      value: filterOption,
    })),
  }))
}

export const getPartsPerPageByViewType = (
  paginationSettings: PaginationSettingsContext,
  viewType?: CommerceCategory['viewType']
) => {
  if (!viewType) return undefined

  if (viewType === VIEW_TYPE_TABLE) {
    return paginationSettings?.tableViewPartsPerPage || DEFAULT_PARTS_PER_PAGE_COUNT
  }
  return paginationSettings?.lineViewPartsPerPage || DEFAULT_PARTS_PER_PAGE_COUNT
}
