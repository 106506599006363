import { useTheme } from '@emotion/react'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { scrollTo } from '../../../helpers/dom'
import useApplicationHeaderHeight from '../../../hooks/useApplicationHeaderHeight'
import ClickableWrapper from '../ClickableWrapper/ClickableWrapper'

interface AnchorMenuItemProps {
  targetId: string
  children: ReactNode
}

const AnchorMenuItem = ({
  targetId,
  children,
}: Omit<AnchorMenuItemProps, 'componentTitle'>) => {
  const { space } = useTheme()
  const spacerHeight = useApplicationHeaderHeight()
  const scrollOffsetY = useMemo(() => spacerHeight + space[3], [space, spacerHeight])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const targetElement = document.getElementById(targetId)
      if (!targetElement) return

      const y =
        targetElement.offsetTop -
        scrollOffsetY -
        event.currentTarget.getBoundingClientRect().height

      scrollTo({ top: y, behavior: 'smooth' })

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
        navigate_type: 'quick navigation',
        quick_navigate_name: children?.toString(),
      })
    },
    [targetId, scrollOffsetY, children]
  )

  return (
    <ClickableWrapper
      onClick={handleClick}
      sx={{
        display: 'inline-block',
        py: 4,
        px: 3,
        fontSize: 1,
        position: 'relative',
        textDecoration: 'none',
        outline: 'none',
        color: 'gray.2',
        lineHeight: 'menuList',
        ':last-child': {
          marginRight: 0,
        },
        '::after': {
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '2px',
          display: 'block',
          backgroundColor: 'transparent',
          content: '""',
        },
        ':hover, :focus, :active': {
          color: 'primary',
          '::after': {
            backgroundColor: 'primary',
          },
        },
      }}
    >
      {children}
    </ClickableWrapper>
  )
}

export default AnchorMenuItem
