import React, { FC, Fragment } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { DEFAULT_CULTURE_CODE } from '../../../constants/userConstants'
import withData from '../../../enhancers/withData'
import { getFullTextDate } from '../../../helpers/dateHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import {
  Datasource,
  DateField,
  ImageWithFocalPointField,
  TextField,
} from '../../../types/layoutService'
import Container from '../../atoms/Container/Container'
import Divider from '../../atoms/Divider/Divider'
import RichText from '../../atoms/RichText/RichText'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'
import EditFrame from '../../molecules/ExperienceEditor/ExperienceEditorEditFrame'

const EDIT_FRAME_FIELD_NAMES: string[] = ['Title', 'Body', 'Image']

export interface Slide {
  body: TextField
  id: string
  image: ImageWithFocalPointField
  name: string
  title: TextField
  version: number
}

export interface Slideshow {
  id: string
  name: string
  publishedAt: DateField
  slides: SlideShows
}

export interface SlideShows {
  results: Slide[]
}

export interface SlideshowHandoutProps extends Datasource<Slideshow> {}

const SlideshowHandoutSlide: FC<Slide> = ({ image, title, body }) => (
  <>
    <SitecoreImage imageField={image} pb={5} />

    <Text as="h2" variant="heading2">
      {getTextFieldValue(title)}
    </Text>

    <RichText textField={body} />
  </>
)

const SlideshowHandout: FC<SlideshowHandoutProps> = ({
  datasource: { publishedAt, slides },
}) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  return (
    <Container pb={5}>
      <Flex
        sx={{
          flexWrap: 'wrap',
          borderTop: '1px solid',
          borderTopColor: 'gray.1',
          paddingTop: [4, 4, 5],
        }}
      >
        <Box
          sx={{
            width: ['100%', '100%', '25%'],
          }}
          pb={[4, 4, 0]}
        >
          <Text as="span" variant="bodyCompact" color="gray.2">
            {getFullTextDate(
              publishedAt.dateValue,
              cultureCode ?? DEFAULT_CULTURE_CODE,
              {}
            )}
          </Text>
        </Box>

        <Box
          sx={{
            width: ['100%', '100%', '75%'],
          }}
        >
          {slides?.results.map((slide, index) => (
            <Fragment key={index.toString()}>
              <Box
                sx={{
                  maxWidth: ['100%', '100%', '66.67%'],
                }}
              >
                <EditFrame
                  allowDelete
                  fieldNames={EDIT_FRAME_FIELD_NAMES}
                  itemId={slide.id}
                  itemVersion={slide.version}
                  key={slide.id}
                  title={slide.name}
                >
                  <SlideshowHandoutSlide {...slide} />
                </EditFrame>
              </Box>

              {index < slides?.results?.length - 1 && <Divider mb={[4, 4, 5]} />}
            </Fragment>
          ))}
        </Box>
      </Flex>
    </Container>
  )
}

export default withData(SlideshowHandout)
