import React, { FC, useMemo } from 'react'
import { Box, FlexProps } from 'theme-ui'
import PartListLineReference from './PartListLineReference'
import { CommerceOrderItem } from '../../../types/commerceApi'
import PartListLineRemove from './PartListLineRemove'
import PartListLineRevert from './PartListLineRevert'

interface PartListLineModifiersProps extends Omit<FlexProps, 'css'> {
  part: CommerceOrderItem
  index: number
}

const PartListLineModifiers: FC<PartListLineModifiersProps> = ({
  part,
  index,
  sx,
}) => {
  const shouldRevert = useMemo(
    () =>
      part.suggestionInformation &&
      part.suggestionInformation?.suggestedQuantity !== part.quantity,
    [part]
  )

  return (
    <Box sx={{ ...sx }}>
      <PartListLineReference part={part} />

      {shouldRevert && <PartListLineRevert part={part} />}

      {!shouldRevert && <PartListLineRemove part={part} index={index} />}
    </Box>
  )
}

export default PartListLineModifiers
