import { loader } from 'graphql.macro'
import { useCallback, useContext } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { LazyGraphServiceTuple } from '../../../types/graphServiceTypes'
import { useBaseGraphQLServiceHeaders } from './core/useBaseGraphQLService'
import useLazyQuery, {
  UseLazyQueryDataFunc,
  UseLazyQueryErrorFunc,
} from './core/useLazyQuery'
import { UserContext } from '../../../providers/UserProvider'

const GET_COMPANY_DETAILS = loader('./schemas/companyDetailsQuery.graphql')

export interface CompanyDetailsQueryData {
  company: CompanyDetails
  additionalCompany: CompanyDetails
}

interface CompanyDetailsQueryVariables {
  [key: string]: any
  companyId: number
  isImpersonated?: boolean
  withAdditionalCompany?: boolean
  additionalCompanyId?: number
}

export interface CompanyDetails {
  id: number
  name: string
  isDealerOwnAssortmentParticipant: boolean
}

const useLazyCompanyDetailsService = (
  onData: UseLazyQueryDataFunc<CompanyDetailsQueryData>,
  onError?: UseLazyQueryErrorFunc
): LazyGraphServiceTuple<CompanyDetailsQueryVariables> => {
  const userContext = useContext(UserContext)

  const baseHeaders = useBaseGraphQLServiceHeaders(userContext?.user)

  const [fetch, fetching] = useLazyQuery<
    CompanyDetailsQueryData,
    CompanyDetailsQueryVariables
  >(onData, onError)

  const fetchWrapper = useCallback(
    (variables: CompanyDetailsQueryVariables, signal?: AbortSignal) => {
      fetch({
        document: GET_COMPANY_DETAILS,
        variables,
        requestHeaders: {
          ...baseHeaders,
        },
        signal: signal as NonNullable<RequestInit['signal']>,
      })
    },
    [baseHeaders, fetch]
  )

  return [fetchWrapper, fetching]
}

export default useLazyCompanyDetailsService
