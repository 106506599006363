import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import TooltipBalloon from './TooltipBalloon'

export interface TooltipProps {
  name: string
  edgeGap?: number
  fixedEdgeGap?: number
  dataTId?: string
  children?: ReactNode
}

const Tooltip = ({
  name,
  edgeGap,
  fixedEdgeGap,
  dataTId,
  children,
}: TooltipProps) => {
  const { [name]: tooltip } = useSelector((state: RootState) => state.tooltipList)

  if (!tooltip) return <>{children}</>

  return (
    <>
      {children}

      <TooltipBalloon
        dataTId={dataTId ?? undefined}
        name={name}
        edgeGap={edgeGap}
        fixedEdgeGap={fixedEdgeGap}
        {...tooltip}
      />
    </>
  )
}

export default Tooltip
