import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import IconWrapper from '../IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'

interface ProgressStepsProps {
  activeStep: number
  steps: {
    label: string
  }[]
}

const ProgressSteps = ({ activeStep, steps }: ProgressStepsProps) => {
  return (
    <Box
      as="ol"
      role="list"
      sx={{
        margin: 0,
        padding: 0,
        listStyle: 'none',
        display: 'flex',
        gap: [12, null, 50],
        justifyItems: 'stretch',
        justifyContent: ['stretch', null, 'start'],
        counterReset: 'progressSteps',
      }}
    >
      {steps.map((step, i) => (
        <Flex
          as="li"
          key={step.label}
          sx={{
            flexGrow: [i !== 0 && 1, null, 0],
            gap: 12,
            alignItems: 'center',
            '&:not(:first-of-type)::before': {
              content: '""',
              display: ['block', null, 'none'],
              inlineSize: 20,
              blockSize: 2,
              borderBlockEnd: '2px dashed',
              borderColor: COLORS.MEDIUM_GRAY,
              flexGrow: 1,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              inlineSize: 28,
              blockSize: 28,
              borderRadius: '50%',
              backgroundColor:
                i + 1 < activeStep
                  ? COLORS.CONFIRMATION
                  : i + 1 === activeStep
                  ? COLORS.DAF_BLUE
                  : COLORS.GRAY,
              color: COLORS.WHITE,
            }}
          >
            {i + 1 < activeStep ? (
              <Box
                sx={{
                  '&::after': {
                    counterIncrement: 'progressSteps',
                    content: 'counter(progressSteps)',
                    position: 'absolute',
                    opacity: 0,
                  },
                }}
              >
                <IconWrapper icon={ICONS.CHECK} size={4} />
              </Box>
            ) : (
              <Text
                variant="bodySmall"
                sx={{
                  '&::after': {
                    counterIncrement: 'progressSteps',
                    content: 'counter(progressSteps)',
                  },
                }}
              />
            )}
          </Box>
          <Text
            variant="bodySmall"
            sx={{
              display: ['none', null, 'block'],
            }}
          >
            {step.label}
          </Text>
        </Flex>
      ))}
    </Box>
  )
}

export default ProgressSteps
