import React, { forwardRef } from 'react'
import { Box, FlexProps } from 'theme-ui'

export interface TableRowProps
  extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {}

const TableRow = forwardRef<HTMLElement, TableRowProps>(
  ({ children, sx, ...manipulatedProps }, ref) => (
    <Box
      className="table-row"
      ref={ref}
      sx={{
        display: 'table-row',
        width: '100%',
        border: '0',
        borderColor: 'gray.1',
        borderStyle: 'solid',
        borderBottomWidth: '1px',
        ...sx,
      }}
      {...manipulatedProps}
    >
      {children}
    </Box>
  )
)

export default TableRow
