import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { alphaColor } from '../../../helpers/themeHelper'

interface DividerProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css' | 'opacity' | 'color'>> {
  color?: BoxProps['color']
  opacity?: string | number
}

export const getDividerSx = (
  position: 'top' | 'bottom',
  color?: BoxProps['color'],
  opacity?: string | number
) => {
  const capitalizedPosition = position.charAt(0).toUpperCase() + position.slice(1)

  return {
    [`border${capitalizedPosition}Style`]: 'solid',
    [`border${capitalizedPosition}Width`]: 1,
    [`border${capitalizedPosition}Color`]:
      opacity && typeof color === 'string'
        ? alphaColor(color, opacity)
        : color || 'gray.1',
  }
}

const Divider: FC<DividerProps> = ({ color, opacity, sx, ...manipulatedProps }) => (
  <Box
    as="hr"
    sx={{
      border: 0,
      height: 0,
      width: '100%',
      opacity,
      ...getDividerSx('bottom', color),
      ...sx,
    }}
    {...manipulatedProps}
  />
)

export default Divider
