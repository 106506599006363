import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Datasource, ImageField, TextField } from '../../../types/layoutService'
import { LoyaltyProvider } from '../../../providers/LoyaltyProvider'
import { COLORS } from '../../../constants/themeConstants'
import withData from '../../../enhancers/withData'
import LoyaltyProgramLogo from '../../molecules/LoyaltyProgramLogo/LoyaltyProgramLogo'
import LoyaltyPointBalanceBanner from '../../molecules/LoyaltyPointBalanceBanner/LoyaltyPointBalanceBanner'

export interface LoyaltyPointsBalanceDatasource {
  title: TextField
  logoNEXT: ImageField
  logoNEXTPremium: ImageField
  logoPPFS: ImageField
  pointsIntroductionLabel: TextField
  pointsBalanceLabel: TextField
  pointsLimitReachedLabel: TextField
  expiryLabel: TextField
}

const LoyaltyPointsBalance = ({
  datasource,
}: Datasource<LoyaltyPointsBalanceDatasource>) => {
  if (datasource === null) return

  return (
    <LoyaltyProvider>
      <Flex
        sx={{
          gap: 24,
          marginBlockEnd: 32,
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        {datasource.title?.value && (
          <Text
            as="h1"
            variant="heading1"
            sx={{
              color: COLORS.BLACK,
              fontSize: 5,
              lineHeight: 'heading',
            }}
          >
            {datasource.title?.value}
          </Text>
        )}

        <Box
          sx={{
            img: {
              blockSize: 40,
              inlineSize: 'auto',
            },
          }}
        >
          <LoyaltyProgramLogo
            logoNEXT={datasource.logoNEXT}
            logoNEXTPremium={datasource.logoNEXTPremium}
            logoPPFS={datasource.logoPPFS}
          />
        </Box>
      </Flex>
      <Box>
        <LoyaltyPointBalanceBanner
          introduction={datasource.pointsIntroductionLabel?.value}
          pointsBalanceText={datasource.pointsBalanceLabel?.value}
          pointsLimitReachedText={datasource.pointsLimitReachedLabel?.value}
          expiryText={datasource.expiryLabel?.value}
        />
      </Box>
    </LoyaltyProvider>
  )
}

export default withData(LoyaltyPointsBalance)
