import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import useAxiosClient from '../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from './AxiosClientProvider'
import { UserContext } from './UserProvider'
import { ActiveStoreProviderContext } from './ActiveStoreProvider'
import { DafDefaultQueryParams } from '../types/userProps'
import accountUserDetails, {
  AccountUserDetailsResponse,
  AccountUserDetailsResult,
} from '../services/rest/user/accountUserDetails'

interface MyAccountPageContextProps {
  accountUserDetailsData: AccountUserDetailsResult | undefined
  isLoading: boolean
}
const MyAccountPageContext = createContext<MyAccountPageContextProps>({
  accountUserDetailsData: undefined,
  isLoading: false,
})

interface MyAccountPageProviderProps {
  children: ReactNode
}

const MyAccountPageProvider = ({ children }: MyAccountPageProviderProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)

  const [accountUserDetailsData, setAccountUserDetailsData] =
    useState<AccountUserDetailsResult>()

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getAccountUserDetails = async (): Promise<AccountUserDetailsResponse> => {
    const { token } = axios.CancelToken.source()
    const response = await accountUserDetails(
      dafVehicleDetailQueryParams,
      client,
      token,
      user
    )
    return response.data
  }

  const { isLoading: isFetchingAccountUserDetails, data: accountUserDetailsResult } =
    useQuery({
      queryKey: ['AccountDetails'],
      queryFn: getAccountUserDetails,
      enabled: !!actingCompanyId && !!actingSupplierId,
    })

  useEffect(() => {
    if (accountUserDetailsResult) {
      setAccountUserDetailsData(accountUserDetailsResult?.result)
    }
  }, [accountUserDetailsResult])

  return (
    <MyAccountPageContext.Provider
      value={{
        accountUserDetailsData,
        isLoading: isFetchingAccountUserDetails,
      }}
    >
      {children}
    </MyAccountPageContext.Provider>
  )
}

export { MyAccountPageContext, MyAccountPageProvider }
