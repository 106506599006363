import React, { createContext, useContext } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import useAxiosClient from '../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from './AxiosClientProvider'
import { UserContext } from './UserProvider'
import { ActiveStoreProviderContext } from './ActiveStoreProvider'
import { DafDefaultQueryParams } from '../types/userProps'
import getVehicleRMServicePlanActions, {
  ServicesResult,
} from '../services/rest/ecommerce/dafVehicleServicePlan'

interface ServicesContextProps {
  servicesData: ServicesResult[] | undefined
}
const ServicesContext = createContext<ServicesContextProps>({
  servicesData: [],
})

interface ServicesProviderProps {
  chassisNumber?: string
  children: React.ReactNode
  enabled: boolean
}

const ServicesProvider = ({
  children,
  chassisNumber,
  enabled = false,
}: ServicesProviderProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getServices = async () => {
    const { token } = axios.CancelToken.source()
    const response = await getVehicleRMServicePlanActions(
      chassisNumber,
      dafVehicleDetailQueryParams,
      client,
      token,
      user
    )
    return response.data
  }

  const { data: servicesData } = useQuery({
    queryKey: [`getServicePartners-${chassisNumber}`],
    queryFn: getServices,
    enabled: !!chassisNumber && enabled,
  })

  return (
    <ServicesContext.Provider value={{ servicesData: servicesData?.result }}>
      {children}
    </ServicesContext.Provider>
  )
}

export { ServicesContext, ServicesProvider }
