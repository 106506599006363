import React, { FC, useCallback, useMemo } from 'react'
import { Box, FlexProps } from 'theme-ui'

type Matrix = number[][]
interface TableProps extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {
  columnSizes: number[] | Matrix
}

const createEmptyMatrix = (numberOfRows: number, numberOfColumns: number): Matrix =>
  new Array(numberOfRows).fill(0).map(() => new Array(numberOfColumns).fill(0))

const transposeMatrix = (matrix: Matrix) => {
  const numberOfColumns = matrix[0].length
  const numberOfRows = matrix.length

  const newMatrix = createEmptyMatrix(numberOfColumns, numberOfRows)

  for (let row = 0; row < numberOfRows; row += 1) {
    for (let column = 0; column < numberOfColumns; column += 1) {
      newMatrix[column][row] = matrix[row][column]
    }
  }

  return newMatrix
}

const calculateTotalWidth = (columnSizes: number[]) =>
  columnSizes.reduce<number>((a: number, b: number) => a + b, 0)

const calculateTotalResponsiveWidth = (columnSizeMatrix: Matrix) =>
  transposeMatrix(columnSizeMatrix).map((row) =>
    row.reduce((sum, value) => sum + value)
  )

const columnWidthCalculator = (columnWidth: number, totalColumnWidth: number) =>
  (columnWidth / totalColumnWidth) * 100

const responsiveColumnWidthCalculator = (
  columnWidthResponsive: number[],
  totalColumnWidthResponsive: number[]
) =>
  columnWidthResponsive.map(
    (columnWidth, i) => (columnWidth / totalColumnWidthResponsive[i]) * 100 || 0
  )

const Table: FC<TableProps> = ({
  children,
  columnSizes,
  sx,
  ...manipulatedProps
}) => {
  const isResponsive = useMemo(
    () => columnSizes?.length && typeof columnSizes[0] === 'object',
    [columnSizes]
  )

  const totalColumnWidth = useMemo(() => {
    if (columnSizes?.length) {
      if (!isResponsive) {
        return calculateTotalWidth(columnSizes as number[])
      }
      if (isResponsive) {
        return calculateTotalResponsiveWidth(columnSizes as Matrix)
      }
    }
    return 0
  }, [columnSizes, isResponsive])

  const getColumnWidth = useCallback(
    (columnWidth: number) =>
      columnWidthCalculator(columnWidth, totalColumnWidth as number),
    [totalColumnWidth]
  )

  const getResponsiveColumnWidth = useCallback(
    (columnWidth: number[]) =>
      responsiveColumnWidthCalculator(columnWidth, totalColumnWidth as number[]),
    [totalColumnWidth]
  )

  const tableCellSxObject = useMemo(
    () =>
      isResponsive
        ? (columnSizes as Matrix)
            .map((columnWidth: number[], i) => ({
              key: `.table-cell-${i}`,
              width: getResponsiveColumnWidth(columnWidth).map(
                (width) => `${width}%`
              ),
            }))
            .reduce(
              (sxObject, { key, width }) => ({ ...sxObject, [key]: { width } }),
              {}
            )
        : (columnSizes as number[])
            .map((columnWidth: number, i) => ({
              key: `.table-cell-${i}`,
              width: `${getColumnWidth(columnWidth)}%`,
            }))
            .reduce(
              (sxObject, { key, width }) => ({ ...sxObject, [key]: { width } }),
              {}
            ),
    [columnSizes, getColumnWidth, getResponsiveColumnWidth, isResponsive]
  )

  return (
    <Box
      className="table"
      sx={{
        display: 'table',
        width: '100%',
        borderCollapse: 'collapse',
        ...tableCellSxObject,
        ...sx,
      }}
      {...manipulatedProps}
    >
      {children}
    </Box>
  )
}

export default Table
