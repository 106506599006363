import { Environment } from '../types/environmentProps'

export const ENVIRONMENT_LOCAL = 'LOCAL'
export const ENVIRONMENT_DEVELOPMENT = 'DEVELOPMENT'
export const ENVIRONMENT_TEST = 'TEST'
export const ENVIRONMENT_CERT = 'CERT'
export const ENVIRONMENT_PROD = 'PROD'

export const COMMERCE_SITE_CONTEXT = 'commerce'

export const MULTISUPPORT_SITE_CONTEXT = 'multisupport'

export const ENVIRONMENTS: Environment[] = [
  {
    name: ENVIRONMENT_LOCAL,
    patterns: [
      /^daf\.cm$/i,
      /^daf\.cd$/i,
      /^multisupport\.cd$/i,
      /^trp\.cd$/i,
      /^localhost:3000$/i,
      /^localhost:4000$/i,
      /netlify\.app$/i,
    ],
    vars: {
      commerceApiBaseUrl: 'https://dev-parts-commerce-api.daf.com/api',
      commerceApiGraphQLBaseUrl:
        'https://dev-parts-commerce-api.daf.com/api/graphql',
      localCommerceApiBaseUrl: 'https://localhost:44372/api',
      localCommerceApiGraphQLBaseUrl: 'https://localhost:44372/api/graphql',
      dealerApiBaseUrl:
        'https://dafehv-parts-sitecore-dev-dealer-api.azurewebsites.net/api/v1/dealers',
      sitecoreApiBaseUrl: '/api/commerce',
      appInsightsInstrumentationKey: '1caaf1fc-f6b1-4da7-86d0-9cb327a72571',
      connectPortalUrl: 'https://acc-connect.daf.com',
    },
  },
  {
    name: ENVIRONMENT_DEVELOPMENT,
    patterns: [
      /^localhost:3000$/i,
      // AppService
      /^dafehv-parts-sc-dev-cm\.azurewebsites\.net$/i,
      /^dafehv-parts-sc-dev-cd\.azurewebsites\.net$/i,

      // WAF
      /^dev-parts\.daf\.com$/i,
      /^dev-parts-cms\.daf\.com$/i,
      /^dev\.trp\.eu$/i,
      /^dev-multisupport\.daf\.com$/i,
    ],
    vars: {
      commerceApiBaseUrl: 'https://dev-parts-commerce-api.daf.com/api',
      commerceApiGraphQLBaseUrl:
        'https://dev-parts-commerce-api.daf.com/api/graphql',
      localCommerceApiBaseUrl: 'https://localhost:44372/api',
      localCommerceApiGraphQLBaseUrl: 'https://localhost:44372/api/graphql',
      dealerApiBaseUrl:
        'https://dafehv-parts-sitecore-dev-dealer-api.azurewebsites.net/api/v1/dealers',
      sitecoreApiBaseUrl: '/api/commerce',
      appInsightsInstrumentationKey: '1caaf1fc-f6b1-4da7-86d0-9cb327a72571',
      connectPortalUrl: 'https://acc-connect.daf.com',
    },
  },
  {
    name: ENVIRONMENT_TEST,
    patterns: [
      // AppService
      /^dafehv-parts-sc-test-cm\.azurewebsites\.net$/i,
      /^dafehv-parts-sc-test-cd\.azurewebsites\.net$/i,

      // WAF
      /^test-parts\.daf\.com$/i,
      /^test-parts-cms\.daf\.com$/i,
      /^test\.trp\.eu$/i,
      /^test-multisupport\.daf\.com$/i,
    ],
    vars: {
      commerceApiBaseUrl: 'https://test-parts-commerce-api.daf.com/api',
      commerceApiGraphQLBaseUrl:
        'https://test-parts-commerce-api.daf.com/api/graphql',
      localCommerceApiBaseUrl: 'https://localhost:44372/api',
      localCommerceApiGraphQLBaseUrl: 'https://localhost:44372/api/graphql',
      dealerApiBaseUrl:
        'https://dafehv-parts-sitecore-test-dealer-api.azurewebsites.net/api/v1/dealers',
      sitecoreApiBaseUrl: '/api/commerce',
      appInsightsInstrumentationKey: 'daed15f9-f8a5-400b-b981-6153b7e19829',
      connectPortalUrl: 'https://acc-connect.daf.com',
    },
  },
  {
    name: ENVIRONMENT_CERT,
    patterns: [
      // AppService
      /^dafehv-parts-sc-cert-cm\.azurewebsites\.net$/i,
      /^dafehv-parts-sc-cert-cd\.azurewebsites\.net$/i,

      // WAF
      /^cert-parts\.daf\.com$/i,
      /^cert-parts-cms\.daf\.com$/i,
      /^cert\.trp\.eu$/i,
      /^cert-multisupport\.daf\.com$/i,
    ],
    vars: {
      commerceApiBaseUrl: 'https://cert-parts-commerce-api.daf.com/api',
      commerceApiGraphQLBaseUrl:
        'https://cert-parts-commerce-api.daf.com/api/graphql',
      localCommerceApiBaseUrl: 'https://localhost:44372/api',
      localCommerceApiGraphQLBaseUrl: 'https://localhost:44372/api/graphql',
      dealerApiBaseUrl:
        'https://dafehv-parts-sitecore-cert-dealer-api.azurewebsites.net/api/v1/dealers',
      sitecoreApiBaseUrl: '/api/commerce',
      appInsightsInstrumentationKey: 'e87a8904-eb3d-482f-9865-7d3bbaa3fb45',
      connectPortalUrl: 'https://acc-connect.daf.com',
    },
  },
  {
    name: ENVIRONMENT_PROD,
    patterns: [
      // AppService
      /^dafehv-parts-sc-prod-cm\.azurewebsites\.net$/i,
      /^dafehv-parts-sc-prod-cd\.azurewebsites\.net$/i,

      // WAF
      /^parts\.daf\.com$/i,
      /^parts-cms\.daf\.com$/i,
      /^trp\.eu$/i,
      /^multisupport\.daf\.com$/i,
    ],
    vars: {
      commerceApiBaseUrl: 'https://parts-commerce-api.daf.com/api',
      commerceApiGraphQLBaseUrl: 'https://parts-commerce-api.daf.com/api/graphql',
      dealerApiBaseUrl:
        'https://dafehv-parts-sitecore-prod-dealer-api.azurewebsites.net/api/v1/dealers',
      sitecoreApiBaseUrl: '/api/commerce',
      appInsightsInstrumentationKey: '83d8efc9-97a7-4cb3-93b6-ef8957599ae5',
      connectPortalUrl: 'https://connect.daf.com',
    },
  },
]
