import React, { FC, useContext } from 'react'
import withData from '../../../enhancers/withData'
import { loggedIn } from '../../../helpers/userHelper'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../providers/UserProvider'
import DealerContactInformation from '../../molecules/DealerContactInformation/DealerContactInformation'
import DealerContactInformationBannerPageEditingComponent from './DealerContactInformationBannerPageEditingComponent'

const DealerContactInformationBanner: FC = () => {
  const { user } = useContext(UserContext)
  const { activeCompany, activeSupplier } = useContext(ActiveStoreProviderContext)

  if (!loggedIn(user) || !activeCompany || !activeSupplier) return null

  return <DealerContactInformation p={5} bg="gray.0" mb={[4, 4, 7]} />
}

export default withData(DealerContactInformationBanner, {
  checkDatasource: false,
  pageEditingComponent: DealerContactInformationBannerPageEditingComponent,
  showPageEditingComponentWhenPreviewing: true,
})
