import i18next from 'i18next'
import React, { FC, useContext, useMemo } from 'react'
import { MATCH_TYPE_CROSS_REFERENCE } from '../../../constants/searchConstants'
import { SearchContext } from '../../../providers/SearchContextProvider'
import { MatchMetaDataProps } from '../../../types/dafResponseProps'
import CardExcerptText from './CardExcerptText'
import Highlight from '../../atoms/Highlight/Highlight'

interface CardExcerptProps {
  partNumber?: string
  displayPartNumber?: string
  supplierPartNumber?: string
  matchMetaData?: MatchMetaDataProps
}

const CardExcerpt: FC<CardExcerptProps> = ({
  displayPartNumber,
  partNumber,
  supplierPartNumber,
  matchMetaData,
}) => {
  const { searchString } = useContext(SearchContext)

  const partNumberString = `${i18next.t('partLabels.partNumberAbbreviation')} ${
    displayPartNumber || partNumber
  }`

  const supplierPartNumberString = useMemo(
    () =>
      supplierPartNumber &&
      `${i18next.t(
        'partLabels.manufacturerPartNumberAbbreviation'
      )} ${supplierPartNumber}`,
    [supplierPartNumber]
  )

  return (
    <CardExcerptText>
      {[partNumberString, supplierPartNumberString]
        .filter((string) => string)
        .join(' | ')}

      {matchMetaData?.type === MATCH_TYPE_CROSS_REFERENCE && (
        <>
          {' | '}

          {`${i18next.t('partLabels.crossReferencesAbbreviation')} `}

          <Highlight search={matchMetaData.basedOn || searchString}>
            {matchMetaData.matchedOn}
          </Highlight>
        </>
      )}
    </CardExcerptText>
  )
}

export default CardExcerpt
