import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import i18next from 'i18next'
import { URL_SORT } from '../../../../constants/urlConstants'
import {
  MY_FLEET_LIST_SORT_FIRST_REGISTRATION_DATE,
  MY_FLEET_LIST_SORT_MAIN_SERVICE_DEALER,
  MY_FLEET_LIST_SORT_MILEAGE,
  MY_FLEET_LIST_SORT_SERVICE_COVERAGE,
  MY_FLEET_LIST_SORT_VEHICLE_ACTION_STATUS,
  MY_FLEET_LIST_SORT_VEHICLE_MAINTENANCE_URGENCY_STATUS,
} from '../../../../constants/dafCompanyVehicleConstants'
import { MyFleetListContext } from '../../../../providers/MyFleetListContextProvider'
import Radio from '../../../atoms/inputs/Radio/Radio'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import {
  COMMERCE_SITE_CONTEXT,
  MULTISUPPORT_SITE_CONTEXT,
} from '../../../../constants/environmentConstants'

interface MyFleetListSortOptionsProps {
  name: string
  value: string
}

const MyFleetListSortingList = () => {
  const history = useHistory()
  const defaultRadioValue = MY_FLEET_LIST_SORT_FIRST_REGISTRATION_DATE
  const { sort } = useContext(MyFleetListContext)
  const [sortOptions, setSortOptions] = useState<MyFleetListSortOptionsProps[]>([])
  const [radioValue, setRadioValue] = useState(sort || defaultRadioValue)
  const params = new URLSearchParams(history.location.search)
  const {
    site: { name },
  } = useSitecoreContext()

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value)
    params.set(URL_SORT, event?.target.value || '')
    history.push(`?${params.toString()}`)
  }

  useEffect(() => {
    setSortOptions([
      {
        name: i18next.t('myFleetListSortAndFilterLabels.registrationDateLabel'),
        value: MY_FLEET_LIST_SORT_FIRST_REGISTRATION_DATE,
      },
      {
        name: i18next.t('myFleetListSortAndFilterLabels.mileageLabel'),
        value: MY_FLEET_LIST_SORT_MILEAGE,
      },
      {
        name: i18next.t('myFleetListSortAndFilterLabels.serviceCoverageLabel'),
        value: MY_FLEET_LIST_SORT_SERVICE_COVERAGE,
      },
    ])
    if (name === COMMERCE_SITE_CONTEXT) {
      setSortOptions((defaultVAlues) => [
        ...defaultVAlues,
        {
          name: i18next.t('myFleetListSortAndFilterLabels.maintenanceUrgencyLabel'),
          value: MY_FLEET_LIST_SORT_VEHICLE_MAINTENANCE_URGENCY_STATUS,
        },
      ])
    }
    if (name === MULTISUPPORT_SITE_CONTEXT) {
      setSortOptions((defaultVAlues) => [
        ...defaultVAlues,
        {
          name: i18next.t('myFleetListSortAndFilterLabels.overallUrgencyLabel'),
          value: MY_FLEET_LIST_SORT_VEHICLE_ACTION_STATUS,
        },
        {
          name: i18next.t('myFleetListSortAndFilterLabels.mainServiceDealerLabel'),
          value: MY_FLEET_LIST_SORT_MAIN_SERVICE_DEALER,
        },
      ])
    }
  }, [sort, name])

  return (
    <>
      <Box>
        <Text
          variant="bodyContent"
          sx={{
            fontWeight: 'bold',
          }}
        >
          {i18next.t('myFleetListSortAndFilterLabels.sortLabel')}
        </Text>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        {sortOptions.map((option: MyFleetListSortOptionsProps) => (
          <label key={option.value}>
            <Flex sx={{ marginBottom: 2, cursor: 'pointer' }}>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Text variant="bodySmall" color="gray.2">
                  {option.name}
                </Text>
              </Box>
              <Box sx={{ flexShrink: 0 }}>
                <Radio
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeHandler(event)
                  }
                  checked={option.value === radioValue}
                  value={option.value}
                  name="sortProp"
                />
              </Box>
            </Flex>
          </label>
        ))}
      </Box>
    </>
  )
}

export default MyFleetListSortingList
