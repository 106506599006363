import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { GraphServiceTuple } from '../../../types/graphServiceTypes'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'

export interface FooterLegalLinkListQueryData {
  footerLegalLinks: FooterLegalLink[]
}

interface FooterLegalLinkListQueryVariables {
  [key: string]: any
}

export interface FooterLegalLink {
  description: string
  id: string
  message: string
  priority: number
  url: string
}

const GET_LEGAL_LINK_LIST = loader('./schemas/footerLegalLinkListQuery.graphql')

const sortByPriority = (footerLegalLinks: FooterLegalLink[]) =>
  [...footerLegalLinks].sort((a, b) => a.priority - b.priority)

const useFooterLegalLinkListService = (): GraphServiceTuple<FooterLegalLink[]> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [footerLegalLinkList, setFooterLegalLinkList] = useState<FooterLegalLink[]>(
    []
  )

  const onData = useCallback((data?: FooterLegalLinkListQueryData) => {
    if (data?.footerLegalLinks) {
      setFooterLegalLinkList(sortByPriority(data.footerLegalLinks))
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    FooterLegalLinkListQueryData,
    FooterLegalLinkListQueryVariables
  >(onData)
  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables) {
      fetch({
        document: GET_LEGAL_LINK_LIST,
        variables: {
          ...baseVariables,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables])

  return [fetching, footerLegalLinkList]
}

export default useFooterLegalLinkListService
