import React, { FC, useContext, useMemo } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { getDividerSx } from '../../../../atoms/Divider/Divider'
import { FILTER_PANEL_VARIANT } from '../../../../../constants/partsNavigationConstants'
import Button from '../../../../atoms/Button/Button'
import useSitecoreContext from '../../../../../hooks/useSitecoreContext'
import { TRP_THEME } from '../../../../../constants/themeConstants'
import { pushToDataLayer } from '../../../../../helpers/analyticsHelper'
import { CategoriesContext } from '../../../../../providers/CategoriesProvider'
import {
  getCategoryById,
  getSubCategoryById,
} from '../../../../../helpers/categoryHelper'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'

interface PartsNavigationPanelHeaderParentLinkProps {
  partsBaseUri?: string
  variant?: string
  title: string
  categoryId?: string
  subCategoryId?: string
}

const PartsNavigationPanelHeaderParentLink: FC<
  PartsNavigationPanelHeaderParentLinkProps
> = ({ partsBaseUri, variant, title, categoryId, subCategoryId }) => {
  const {
    site: { theme },
  } = useSitecoreContext()
  const { categories } = useContext(CategoriesContext)
  const { mainNavigation, space } = useTheme()

  const mainCategory = useMemo(
    () => getCategoryById(categoryId, categories),
    [categories, categoryId]
  )
  const subCategory = useMemo(
    () => getSubCategoryById(subCategoryId, mainCategory),
    [mainCategory, subCategoryId]
  )
  const href = useMemo(
    () =>
      `${partsBaseUri}/${categoryId}${
        subCategoryId && variant === FILTER_PANEL_VARIANT ? `/${subCategoryId}` : ''
      }`,
    [categoryId, partsBaseUri, subCategoryId, variant]
  )

  const clickedCategoryLink = () => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
      navigate_type: DATA_LAYER.CUSTOM_DIMENSION.NAVIGATION_TYPE.MEGA_MENU,
      main_category: mainCategory?.englishDescription
        ? `${
            subCategory?.englishDescription
              ? ''
              : `${DATA_LAYER.CUSTOM_DIMENSION.MAIN_CATEGORY.ALL} `
          }${mainCategory.englishDescription || title}`
        : title || '',
      sub_category: subCategory?.englishDescription
        ? `${DATA_LAYER.CUSTOM_DIMENSION.SUB_CATEGORY.ALL} ${subCategory.englishDescription}`
        : '',
      filter_name: '',
      filter_value: '',
      page_type: '',
    })
  }

  return (
    <>
      <Box sx={{ pl: 5, pr: 3, pb: [2, 2, 2, 0], pt: 2 }}>
        <Button
          as="a"
          variant="outline"
          data-t-id={`parts-navigation-all-${
            variant !== FILTER_PANEL_VARIANT ? categoryId : subCategoryId
          }-link`}
          href={href}
          onClick={clickedCategoryLink}
          sx={{
            maxWidth: `calc(100% - ${space[3]}px)`,
            borderWidth: [0, 0, 0, '1px'],
            borderRadius: [0, 0, 0, '40px'],
            backgroundColor: 'transparent',
            ':hover': {
              borderWidth: [0, 0, 0, '1px'],
            },
            mx: 2,
            px: [0, 0, 0, '20px'],
            ...(theme?.name === TRP_THEME
              ? {
                  color: 'textLight',
                  ':hover': {
                    color: 'textLight',
                    borderColor: 'textLight',
                    borderWidth: [0, 0, 0, '1px'],
                  },
                }
              : {}),
          }}
        >
          <Text
            as="span"
            variant="bodySmall"
            sx={{
              lineHeight: 'menuList',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              fontWeight: ['normal', 'normal', 'normal', 'bold'],
            }}
          >
            {`${i18next.t('commonLabels.all')} ${title}`}
          </Text>
        </Button>
      </Box>

      <Box
        sx={{
          display: ['block', 'block', 'block', 'none'],
          overflow: 'hidden',
          pl: [5, 5, 5, 0],
          opacity: mainNavigation.dividerOpacity,
          marginLeft: '45px',
          ...getDividerSx('bottom', mainNavigation.dividerColor),
        }}
      />
    </>
  )
}

export default PartsNavigationPanelHeaderParentLink
