import { AxiosResponse } from 'axios'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceResponse } from '../../../../types/commerceApi'

export type OrderCustomerOrderRemarks = string

export interface OrderCustomerOrderRemarksRequestProps {
  orderNumber?: string
  remarkText?: string
}

export const postOrderCustomerOrderRemarks: RequestFunction<
  OrderCustomerOrderRemarksRequestProps,
  CommerceResponse<OrderCustomerOrderRemarks>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<OrderCustomerOrderRemarks>>> =>
  client.post(
    `/order-remarks/${props.orderNumber || ''}`,
    { remark: props.remarkText || '' },
    {
      ...createRequestConfig(
        context.user,
        (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
        {
          customerCompanyId: context.customerCompanyId,
          supplierCompanyId: context.supplierCompanyId,
          isImpersonated: !!context.isImpersonated,
        }
      ),
      cancelToken: config.cancelToken,
    }
  )
