import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Flex, Text } from 'theme-ui'
import { registerModalNotification } from '../../../actions/notificationActions'
import { DEALER_LOCATOR_USE_CLIENT_LOCATION } from '../../../constants/cookieConstants'
import { ICONS } from '../../../constants/iconConstants'
import {
  URL_PAGE_PARAM,
  URL_QUERY_STRING_PARAM,
} from '../../../constants/urlConstants'
import { getGeolocationCoordinates } from '../../../helpers/geoLocationHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { TextField } from '../../../types/layoutService'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Spinner from '../../atoms/Spinner/Spinner'

interface DealerLocatorCurrentLocationProps {
  locationUsageBlocked: boolean | undefined
  setClientLocation: (location?: GeolocationCoordinates) => void
  setDeterminingLocation: (state: boolean) => void
  setUseClientLocation: (state: boolean) => void
  useClientLocation: boolean
  currentLocationDisabled: TextField
  label: TextField
}

const DealerLocatorCurrentLocation: FC<DealerLocatorCurrentLocationProps> = ({
  locationUsageBlocked,
  setClientLocation,
  setDeterminingLocation,
  useClientLocation,
  setUseClientLocation,
  currentLocationDisabled,
  label,
}) => {
  const appInsights = useAppInsightsContext()
  const history = useHistory()
  const dispatch = useDispatch()

  const [fetching, setFetching] = useState(false)

  const disableCurrentLocationButton = locationUsageBlocked || useClientLocation

  const getClientLocation = useCallback(() => {
    setFetching(true)
    setDeterminingLocation(true)

    getGeolocationCoordinates()
      .then((coords) => {
        const params = new URLSearchParams(history.location.search)

        params.delete(URL_PAGE_PARAM)
        params.delete(URL_QUERY_STRING_PARAM)

        history.push(`?${params.toString()}`)

        setClientLocation(coords)
      })
      .catch((err) => {
        appInsights.trackException({
          exception: new Error(`Unable to determine current location: ${err}`),
          severityLevel: SeverityLevel.Error,
        })
      })
      .finally(() => {
        setFetching(false)
        setDeterminingLocation(false)
      })
  }, [setDeterminingLocation, history, setClientLocation, appInsights])

  const toggleClientLocationUsage = useCallback(() => {
    if (locationUsageBlocked) {
      dispatch(
        registerModalNotification(
          'Error',
          getTextFieldValue(currentLocationDisabled)
        )
      )
    } else if (useClientLocation) {
      setDeterminingLocation(false)
      setUseClientLocation(false)
      setClientLocation(undefined)

      window.localStorage.setItem(DEALER_LOCATOR_USE_CLIENT_LOCATION, 'false')
    } else {
      window.localStorage.setItem(DEALER_LOCATOR_USE_CLIENT_LOCATION, 'true')

      setUseClientLocation(true)
      getClientLocation()
    }
  }, [
    currentLocationDisabled,
    dispatch,
    getClientLocation,
    locationUsageBlocked,
    setClientLocation,
    setDeterminingLocation,
    setUseClientLocation,
    useClientLocation,
  ])

  if (typeof locationUsageBlocked === 'undefined') return null

  return (
    <ClickableWrapper
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,
        opacity: disableCurrentLocationButton ? 0.25 : 1,
      }}
      onClick={() => {
        if (!disableCurrentLocationButton || locationUsageBlocked) {
          toggleClientLocationUsage()
        }
      }}
      disabled={disableCurrentLocationButton && !locationUsageBlocked}
    >
      <Flex sx={{ alignItems: 'center', color: 'primary' }}>
        <IconWrapper icon={ICONS.TARGET} size={3} />

        <Text
          as="span"
          sx={{
            marginLeft: 2,
            fontSize: 1,
            lineHeight: 'buttonText',
            marginRight: 2,
          }}
        >
          {getTextFieldValue(label)}
        </Text>

        {fetching && <Spinner size={2} />}
      </Flex>
    </ClickableWrapper>
  )
}

export default DealerLocatorCurrentLocation
