import React, { FC } from 'react'
import { Box } from 'theme-ui'
import Image from '../../../atoms/Image/Image'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import { Image as ImageProps } from '../../../../types/dafResponseProps'

interface CarouselThumbnailProps {
  active: boolean
  image: ImageProps
  onClick: () => void
  index?: number
}

const CarouselThumbnail: FC<CarouselThumbnailProps> = ({
  active,
  image,
  onClick,
  index,
}) => (
  <Box
    sx={{
      display: 'inline-block',
      overflow: 'hidden',
      borderStyle: 'solid',
      borderColor: active ? 'highlight' : 'transparent',
      borderWidth: 1,
      borderRadius: 'default',
      mr: 1,
      mb: '-3px',
      height: '64px',
      width: '64px',
      'button:focus': {
        outline: 'none',
        borderColor: 'highlight',
      },
    }}
  >
    <ClickableWrapper
      onClick={onClick}
      data-t-id={`carousel-thumbnail-${index}`}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Image
        src={image?.thumbnailImageUrl || image.imageUrl}
        sx={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </ClickableWrapper>
  </Box>
)

export default CarouselThumbnail
