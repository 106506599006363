import { Action } from 'redux'
import { CommerceOrder } from '../../../types/commerceApi'

export interface SetOrderAction
  extends Action<typeof SHOPPING_BASKET_WIZARD_SET_ORDER> {
  payload: CommerceOrder
}

export const SHOPPING_BASKET_WIZARD_SET_ORDER = 'ShoppingBasketWizard/SET_ORDER'

export const setOrder = (payload: CommerceOrder) => ({
  type: SHOPPING_BASKET_WIZARD_SET_ORDER,
  payload,
})
