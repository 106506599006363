import { ShoppingBasketWizardActions } from '../../../actions/shoppingBasket/wizard'
import {
  SHOPPING_BASKET_WIZARD_ADD_OR_REPLACE_ORDER_ITEM,
  SHOPPING_BASKET_WIZARD_ORDER_MUTATION,
  SHOPPING_BASKET_WIZARD_ORDER_MUTATION_FINISHED,
  SHOPPING_BASKET_WIZARD_OVERWRITE_ORDER_RECIPIENTS,
  SHOPPING_BASKET_WIZARD_REFETCH_ORDER,
  SHOPPING_BASKET_WIZARD_REMOVE_ORDER_ITEMS,
  SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_NUMBER,
  SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_REFERENCE,
  SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL,
  SHOPPING_BASKET_WIZARD_SET_ORDER,
  SHOPPING_BASKET_WIZARD_SET_ORDER_ITEM_REFERENCE,
  SHOPPING_BASKET_WIZARD_SET_ORDER_REMARKS,
  SHOPPING_BASKET_WIZARD_SET_WAY_OF_TRANSPORT,
} from '../../../constants/shoppingBasketConstants'
import { CommerceOrder } from '../../../types/commerceApi'
import addOrReplaceOrderItem from './addOrReplaceOrderItem'
import removeOrderItems from './removeOrderItems'
import setCustomerOrderNumber from './setCustomerOrderNumber'
import setOrderItemReference from './setOrderItemReference'
import setCustomerOrderReference from './setCustomerOrderReference'
import setOrderRemarks from './setOrderRemarks'
import addOrCreateMutation from './addOrCreateMutation'
import removeMutation from './removeMutation'
import overwriteOrderRecipients from './overwriteOrderRecipients'
import refetchOrder from './refetchOrder'

export interface ShoppingBasketWizardState {
  mutation: MutationState
  order?: CommerceOrder
  editItemSidePanel: {
    active: boolean
    partNumber?: string
  }
  refetchOrder: boolean
}

export interface MutationState {
  mutationCount: number
  mutationInProgress: boolean
}

const initialMutationState: MutationState = {
  mutationCount: 0,
  mutationInProgress: false,
}

const initialState: ShoppingBasketWizardState = {
  mutation: initialMutationState,
  order: undefined,
  editItemSidePanel: {
    active: false,
    partNumber: undefined,
  },
  refetchOrder: false,
}

const shoppingBasketWizardReducer = (
  state: ShoppingBasketWizardState = initialState,
  action: ShoppingBasketWizardActions
): ShoppingBasketWizardState => {
  switch (action.type) {
    case SHOPPING_BASKET_WIZARD_ORDER_MUTATION: {
      return addOrCreateMutation(state)
    }

    case SHOPPING_BASKET_WIZARD_ORDER_MUTATION_FINISHED: {
      return removeMutation(state)
    }

    case SHOPPING_BASKET_WIZARD_SET_ORDER: {
      return {
        ...state,
        order: action.payload,
      }
    }

    case SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL: {
      return {
        ...state,
        editItemSidePanel: action.payload,
      }
    }

    case SHOPPING_BASKET_WIZARD_ADD_OR_REPLACE_ORDER_ITEM: {
      return addOrReplaceOrderItem(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_REMOVE_ORDER_ITEMS: {
      return removeOrderItems(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_SET_ORDER_ITEM_REFERENCE: {
      return setOrderItemReference(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_OVERWRITE_ORDER_RECIPIENTS: {
      return overwriteOrderRecipients(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_REFERENCE: {
      return setCustomerOrderReference(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_NUMBER: {
      return setCustomerOrderNumber(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_SET_ORDER_REMARKS: {
      return setOrderRemarks(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_REFETCH_ORDER: {
      return refetchOrder(state, action.payload)
    }

    case SHOPPING_BASKET_WIZARD_SET_WAY_OF_TRANSPORT: {
      return state
    }

    default: {
      return state
    }
  }
}

export default shoppingBasketWizardReducer
