import { COLORS } from '../../../constants/themeConstants'
import React from 'react'
import { Voucher } from '../../../services/rest/ecommerce/loyalty/vouchers'
import StatePill from '../../atoms/StatePill/StatePill'
import i18next from 'i18next'

interface VoucherCodeStatusProps {
  voucher: Voucher
}

const getVoucherStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return {
        color: COLORS.GREEN,
        text: i18next.t('loyalty.voucherCodeStatusActive'),
      }
    case 'Expired':
      return {
        color: COLORS.ALERT,
        text: i18next.t('loyalty.voucherCodeStatusExpired'),
      }
    case 'Used':
      return {
        color: COLORS.DAF_BLUE,
        text: i18next.t('loyalty.voucherCodeStatusUsed'),
      }
    default:
      return {
        color: COLORS.ORANGE_DARK,
        text: i18next.t('loyalty.voucherCodeStatusUnknown'),
      }
  }
}

const VoucherCodeStatus = ({ voucher }: VoucherCodeStatusProps) => {
  const status = getVoucherStatus(voucher.status)

  return <StatePill state={status.text} color={status.color} />
}

export default VoucherCodeStatus
