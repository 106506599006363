import React, { FC } from 'react'
import { MatchMetaDataProps } from '../../../../types/dafResponseProps'
import { LinkField, SliderVariant } from '../../../../types/layoutService'
import {
  PART_CARD_VARIANT_DEFAULT,
  PART_CARD_VARIANT_MINIMAL,
} from '../PartCard/PartCard'
import DealerPartCardFull from './DealerPartCardFull'
import DealerPartCardMinimal from './DealerPartCardMinimal'

export interface PardCardLayoutServiceData {
  partPage: LinkField
}

export interface PartCardProps extends PardCardLayoutServiceData {
  partDescription?: string
  partNumber: string
  imageUrl?: string
  matchMetaData?: MatchMetaDataProps
  brandName?: string
  uri?: string
  dataLayerComponentOrigin?: string
  variant?: SliderVariant
  onClick?: () => void
}

const DealerPartCard: FC<PartCardProps> = ({
  variant = PART_CARD_VARIANT_DEFAULT,
  uri,
  partPage,
  ...partCardProps
}) => {
  const partHref = `${partPage?.url}/${uri}`

  const sharedProps = {
    href: partHref,
    ...partCardProps,
  }

  if (variant === PART_CARD_VARIANT_MINIMAL) {
    return <DealerPartCardMinimal {...sharedProps} />
  }

  return <DealerPartCardFull {...sharedProps} />
}

export default DealerPartCard
