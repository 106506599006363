import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { PricingProps } from '../../../types/salesProps'

const OriginalPrice: FC<PricingProps> = ({ price, variant }) => (
  <Text
    variant={variant === 'normal' ? 'heading3' : 'heading5'}
    data-t-id="part-unitprice-original"
    as="span"
    sx={{
      fontWeight: 'normalNumeric',
      color: 'gray.2',
      textDecoration: 'line-through',
      fontFamily: 'numeric',
    }}
  >
    {price}
  </Text>
)

export default OriginalPrice
