import i18next from 'i18next'

export const displayValueOrConvertBooleanToHumanReadableString = (value: string) => {
  if (!value) return undefined

  switch (value.toLocaleLowerCase()) {
    case 'true':
      return i18next.t('commonLabels.yes')
    case 'false':
      return i18next.t('commonLabels.no')
    default:
      return value
  }
}
