import React, { FC, useCallback } from 'react'
import i18next from 'i18next'
import { Box, Flex } from 'theme-ui'
import { useDispatch } from 'react-redux'
import { CommerceOrderItem } from '../../../types/commerceApi'
import AddToBasketDelta from '../AddToBasket/AddToBasketDelta'
import {
  getPartSuggestionDeviationPercentage,
  getPartSuggestionHighlighColor,
} from '../../../helpers/partSuggestionHelper'
import Tooltip from '../Tooltip/Tooltip'
import { TOOLTIP_PART_QUANTITY_MUTATION } from '../../../constants/tooltipConstants'
import { tooltipRegister } from '../../../actions/tooltipActions'
import { ICONS } from '../../../constants/iconConstants'
import { alphaColor } from '../../../helpers/themeHelper'

interface PartLineAddToBasketProps {
  part: CommerceOrderItem
}

const PartLineAddToBasket: FC<PartLineAddToBasketProps> = ({ part }) => {
  const dispatch = useDispatch()

  const percentage = getPartSuggestionDeviationPercentage(part)
  const color = getPartSuggestionHighlighColor(part)

  const onQuantityAccepted = useCallback(
    (orderItem: CommerceOrderItem) => {
      if (!orderItem.suggestionInformation) return

      const newPercentage = getPartSuggestionDeviationPercentage(orderItem)

      if (newPercentage !== 100) {
        dispatch(
          tooltipRegister({
            name: `${TOOLTIP_PART_QUANTITY_MUTATION}-${part.partNumber}`,
            data: {
              icon: ICONS.EXCLAMATION_TRIANGLE,
              label: i18next.t('shoppingLabels.acceptanceDrop', {
                percentage: newPercentage,
              }),
            },
          })
        )
      }
    },
    [dispatch, part.partNumber]
  )

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        ...(color
          ? {
              '> div > div > div': {
                borderColor: alphaColor(color, 0.6),
                input: {
                  color,
                },
              },
              span: {
                color,
                fontSize: 0,
                lineHeight: 'smallText',
                fontWeight: 'normal',
                marginTop: 1,
              },
            }
          : {}),
      }}
    >
      <div>
        <Box sx={{ display: 'inline-block' }}>
          <Tooltip name={`${TOOLTIP_PART_QUANTITY_MUTATION}-${part.partNumber}`}>
            <AddToBasketDelta
              partNumber={part.partNumber}
              stepSize={part.specification?.packaging?.packageUnitQuantity}
              initialQuantity={part.quantity}
              pageType="Shopping basket"
              nullable={!!part?.suggestionInformation}
              callback={onQuantityAccepted}
            />
          </Tooltip>
        </Box>
      </div>

      {part?.suggestionInformation && percentage !== 100 && (
        <span data-t-id="part-suggestion-information-advice">
          {`${i18next.t('shoppingLabels.advice')}: ${
            part.suggestionInformation.suggestedQuantity
          }`}
        </span>
      )}
    </Flex>
  )
}

export default PartLineAddToBasket
