import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Flex, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { URL_DEALER_CODE_PARAM } from '../../../constants/urlConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getUrlWithCurrentSearchParams } from '../../../helpers/urlHelper'
import { useDealerService } from '../../../hooks/services/rest/dealer/useDealerService'
import { LinkField, StaticDealerFilter } from '../../../types/layoutService'
import Spinner from '../../atoms/Spinner/Spinner'
import DealerLocatorDealerAddress from '../../organisms/DealerLocator/DealerLocatorDealerAddress'
import DealerLocatorDealerContact from '../../organisms/DealerLocator/DealerLocatorDealerContact'
import DealerLocatorDealerServices from '../../organisms/DealerLocator/DealerLocatorDealerServices'
import SitecoreButton from '../SitecoreButton/SitecoreButton'

interface RequestAccountSelectedDealerProps {
  dealerLocationCode: string
  reselectDealerButton: LinkField
  staticFilters?: StaticDealerFilter[]
}

const RequestAccountSelectedDealer: FC<RequestAccountSelectedDealerProps> = ({
  dealerLocationCode,
  reselectDealerButton,
  staticFilters,
}) => {
  const history = useHistory()

  const [link, setLink] = useState<LinkField | undefined>(undefined)

  const [dealer, fetching] = useDealerService(dealerLocationCode)

  const onReselectClick = () => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
      action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.SELECT_DIFFERENT_DEALER,
    })
  }

  useEffect(() => {
    const targetUrl = getUrlWithCurrentSearchParams(reselectDealerButton.url)

    targetUrl.searchParams.delete(URL_DEALER_CODE_PARAM)

    setLink({
      ...reselectDealerButton,
      url: targetUrl.toString(),
    })
  }, [history.location, reselectDealerButton])

  return (
    <Box
      sx={{
        padding: 4,
        border: '1px solid',
        borderColor: 'gray.1',
        borderRadius: 'default',
        color: 'gray.3',
      }}
    >
      {fetching && <Spinner size={3} />}

      <Box
        mb={3}
        sx={{
          width: '100%',
        }}
      >
        {!fetching && dealer && (
          <>
            {dealer?.name && (
              <Text
                as="h4"
                variant="heading3"
                sx={{
                  lineHeight: 'menuList',
                }}
              >
                {dealer.name}
              </Text>
            )}

            <Flex
              sx={{
                flexDirection: ['column', 'column', 'row'],
              }}
            >
              {dealer.address && (
                <Box sx={{ marginRight: [0, 0, 8], marginBottom: [3, 3, 0] }}>
                  <DealerLocatorDealerAddress address={dealer.address} />
                </Box>
              )}

              {dealer.address && (
                <DealerLocatorDealerContact
                  dealer={dealer}
                  sx={{ marginRight: [0, 0, 8], marginBottom: [3, 3, 0] }}
                  dataLayerArguments={{
                    [DATA_LAYER.EVENT_KEYS.EVENT]:
                      DATA_LAYER.EVENT_NAME.DEALER_FINDER,
                    action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.CLICK_CONTACT_DETAILS,
                  }}
                />
              )}

              <DealerLocatorDealerServices
                dealer={dealer}
                staticDealerFilters={staticFilters}
              />
            </Flex>
          </>
        )}
      </Box>

      {link && (
        <SitecoreButton
          linkField={link}
          variant="infinite"
          onClick={onReselectClick}
          sx={{
            padding: 0,
            color: 'primary',
            minHeight: 'auto',
            '> div': {
              fontWeight: 'normal',
            },
          }}
        >
          {link?.text}
        </SitecoreButton>
      )}
    </Box>
  )
}

export default RequestAccountSelectedDealer
