// Discount types
export const DISCOUNT_TYPE_ABSOLUTE = 'Absolute'
export const DISCOUNT_TYPE_PERCENTAGE = 'Percentage'

export type DiscountType =
  | typeof DISCOUNT_TYPE_ABSOLUTE
  | typeof DISCOUNT_TYPE_PERCENTAGE

// Promotion types
export const PROMOTION_TYPE_DISCOUNT_ABSOLUTE = 'DISCOUNT_ABSOLUTE'
export const PROMOTION_TYPE_DISCOUNT_PERCENTAGE = 'DISCOUNT_PERCENTAGE'
export const PROMOTION_TYPE_QUANTUM_PRICE = 'QUANTUM_PRICE'
export const PROMOTION_TYPE_MAX_COUPON = 'MAX_COUPON'

export type PromotionType =
  | typeof PROMOTION_TYPE_DISCOUNT_ABSOLUTE
  | typeof PROMOTION_TYPE_DISCOUNT_PERCENTAGE
  | typeof PROMOTION_TYPE_MAX_COUPON
  | typeof PROMOTION_TYPE_QUANTUM_PRICE

export const PROMOTION_API_VALUE_DEAL = 'Deal'
export const PROMOTION_API_TYPE_DEALS = 'Deals'
export const PROMOTION_API_TYPE_PROMOTIONS = 'Promotions'
