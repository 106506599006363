import i18next from 'i18next'
import { Discount, Promotion } from '../types/promotionProps'
import {
  DISCOUNT_TYPE_ABSOLUTE,
  DISCOUNT_TYPE_PERCENTAGE,
  PROMOTION_TYPE_DISCOUNT_ABSOLUTE,
  PROMOTION_TYPE_DISCOUNT_PERCENTAGE,
  PROMOTION_TYPE_MAX_COUPON,
  PROMOTION_TYPE_QUANTUM_PRICE,
} from '../constants/promotionConstants'
import { getFormattedPriceString } from './priceHelper'
import { Price } from '../hooks/services/graphql/usePartSalesInformationService'

const getDiscount = (price: Price): Discount | undefined =>
  price.promotionInformation?.discountType &&
  price.promotionInformation?.discountValue
    ? {
        type: price.promotionInformation.discountType,
        value: price.promotionInformation.discountValue,
      }
    : undefined

interface PromotionFactory {
  (locale: string, price: Price): Promotion | undefined
}

const DISCOUNT_ABSOLUTE_PROMOTION_FACTORY: PromotionFactory = (
  locale: string,
  price: Price
) => {
  const discount = getDiscount(price)

  // Check if the discount is absolute
  if (discount?.type !== DISCOUNT_TYPE_ABSOLUTE) return undefined

  // Format the (negated) discount value
  const formattedDiscountValue = getFormattedPriceString(
    locale,
    price.currencyCode,
    -discount.value
  )

  return {
    type: PROMOTION_TYPE_DISCOUNT_ABSOLUTE,
    label: formattedDiscountValue || '',
  }
}

const DISCOUNT_PERCENTAGE_PROMOTION_FACTORY: PromotionFactory = (
  locale: string,
  price: Price
) => {
  const discount = getDiscount(price)

  // Check if the discount is a percentage
  if (discount?.type !== DISCOUNT_TYPE_PERCENTAGE) return undefined

  // Instantiate a new decimal formatter based on the current locale and currency code (e.g. 10,00)
  const decimalFormatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  // Format the (negated) discount value and append a '%' symbol
  const formattedDiscountValue = `${decimalFormatter.format(-discount.value)}%`

  return {
    type: PROMOTION_TYPE_DISCOUNT_PERCENTAGE,
    label: formattedDiscountValue,
  }
}

const MAX_COUPON_PROMOTION_FACTORY: PromotionFactory = (_, price: Price) => {
  // Check if there are coupons available
  if (!price.couponInformation?.maxProgram?.availableCoupon?.length) return undefined

  return {
    type: PROMOTION_TYPE_MAX_COUPON,
    label: PROMOTION_TYPE_MAX_COUPON,
  }
}

const QUANTUM_PRICE_PROMOTION_FACTORY: PromotionFactory = (_, price: Price) => {
  // Check if the quantum price information is available
  if (!price.quantumPrice) return undefined

  return {
    type: PROMOTION_TYPE_QUANTUM_PRICE,
    label: PROMOTION_TYPE_QUANTUM_PRICE,
  }
}

const PROMOTION_FACTORIES: PromotionFactory[] = [
  DISCOUNT_ABSOLUTE_PROMOTION_FACTORY,
  DISCOUNT_PERCENTAGE_PROMOTION_FACTORY,
  MAX_COUPON_PROMOTION_FACTORY,
  QUANTUM_PRICE_PROMOTION_FACTORY,
]

export const getPromotions = (locale: string, price: Price): Promotion[] =>
  PROMOTION_FACTORIES.map((promotionFactory) =>
    promotionFactory(locale, price)
  ).filter((promotion): promotion is Promotion => !!promotion)

export const getPromotionLabel = (promotion: Promotion) =>
  ({
    [PROMOTION_TYPE_MAX_COUPON]: i18next.t('promotionLabels.maxCoupon'),
    [PROMOTION_TYPE_QUANTUM_PRICE]: i18next.t('promotionLabels.quantumPrice'),
    [PROMOTION_TYPE_DISCOUNT_ABSOLUTE]: promotion.label,
    [PROMOTION_TYPE_DISCOUNT_PERCENTAGE]: promotion.label,
  }[promotion.type] ?? '')

export const getPromotionSidePanelTitle = (promotion: Promotion) =>
  ({
    [PROMOTION_TYPE_MAX_COUPON]: i18next.t('promotionLabels.maxCoupon'),
    [PROMOTION_TYPE_QUANTUM_PRICE]: i18next.t('promotionLabels.quantumPrice'),
    [PROMOTION_TYPE_DISCOUNT_ABSOLUTE]: i18next.t(
      'promotionLabels.absoluteDiscount'
    ),
    [PROMOTION_TYPE_DISCOUNT_PERCENTAGE]: i18next.t(
      'promotionLabels.percentageDiscount'
    ),
  }[promotion.type] ?? '')
