import React, { FC, useEffect, useMemo, useState } from 'react'
import { Text } from 'theme-ui'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import PartApplicationTable from '../PartApplicationTable/PartApplicationTable'
import { Datasource, TextField } from '../../../types/layoutService'
import { Application } from '../../../types/partTypes'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import useBreakpoints from '../../../hooks/useBreakpoints'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'

interface PartApplicationsDatasource {
  title: TextField
}

interface PartApplicationsProps {
  applications: Application[]
}

const PartApplications: FC<
  Datasource<PartApplicationsDatasource> & PartApplicationsProps
> = ({ datasource: { title }, rendering: { uid }, applications }) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)

  const ApplicationTable = useMemo(
    () =>
      applications &&
      !!applications.length && <PartApplicationTable applications={applications} />,
    [applications]
  )

  if (!applications?.length) return null

  if (!breakpointM && isClient) {
    return (
      <PartMobileModalLink
        title={getTextFieldValue(title)}
        table={ApplicationTable}
      />
    )
  }

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid} mb={7}>
      {title && (
        <Text as="h2" variant="heading2" mb={4}>
          {getTextFieldValue(title)}
        </Text>
      )}

      {ApplicationTable}
    </AnchorMenuTarget>
  )
}

export default withData(PartApplications, { showMessageWhenPageEditing: true })
