import React, { useContext, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { loyaltyPoints } from '../../../../helpers/commerceApiHelpers'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { COLORS } from '../../../../constants/themeConstants'
import SidePanel from '../../SidePanel/SidePanel'
import SidePanelMain from '../../SidePanel/SidePanelMain'
import SidePanelPaddedBox from '../../SidePanel/SidePanelPaddedBox'
import {
  LOYALTY_POINTS_LIMIT_REACHED,
  LOYALTY_POINTS_NO_LIMIT,
  LOYALTY_POINTS_REACHING_LIMIT,
} from '../../../../constants/loyaltyConstants'
import { getEditableTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { formatNumber } from '../../../../helpers/numberFormatHelper'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { UserContext } from '../../../../providers/UserProvider'

const OrderStickySummaryLoyaltyPoints = () => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const { datasource } = useShoppingBasket()
  const { userProfile } = useContext(UserContext)
  const { orderSummary } = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel
  )
  const [loyaltyTooltipSidePanelIsOpen, setLoyaltyTooltipSidePanelIsOpen] =
    useState(false)

  if (
    !orderSummary?.customerLoyalty ||
    orderSummary.customerLoyalty.loyaltyPoints === null
  )
    return null

  return (
    <>
      <dt>{i18next.t('shoppingLabels.customerLoyalty.loyaltyPoints')}</dt>
      <dd>
        <Flex
          sx={{
            alignItems: 'center',
            gap: 1,
          }}
        >
          {loyaltyPoints(orderSummary?.customerLoyalty?.loyaltyPoints, cultureCode)}

          {orderSummary?.customerLoyalty?.loyaltyPointsMessageCode && (
            <>
              <ClickableWrapper
                onClick={() => setLoyaltyTooltipSidePanelIsOpen(true)}
              >
                <IconWrapper icon={ICONS.TOOLTIP} color={COLORS.DAF_BLUE} size={2} />
              </ClickableWrapper>
            </>
          )}
        </Flex>
      </dd>
      <SidePanel
        onClose={() => setLoyaltyTooltipSidePanelIsOpen(false)}
        active={loyaltyTooltipSidePanelIsOpen}
        title={i18next.t('shoppingLabels.customerLoyalty.loyaltyPoints')}
        position="right"
      >
        <SidePanelMain>
          <SidePanelPaddedBox>
            <Text variant="bodySmall">
              {orderSummary?.customerLoyalty.loyaltyPointsMessageCode ===
                LOYALTY_POINTS_LIMIT_REACHED && (
                <>
                  {i18next.t(
                    getEditableTextFieldValue(datasource.loyaltyPointsLimitReached),
                    {
                      maxNumber: formatNumber(
                        userProfile?.companies?.find((company) => company.isActive)
                          ?.customerLoyaltyMaximumEarnedPointsLimit,
                        cultureCode
                      ),
                      expirationDate:
                        orderSummary?.customerLoyalty.loyaltyPointsExpiredDate,
                    }
                  )}
                </>
              )}
              {orderSummary?.customerLoyalty.loyaltyPointsMessageCode ===
                LOYALTY_POINTS_REACHING_LIMIT && (
                <>
                  {i18next.t(
                    getEditableTextFieldValue(datasource.loyaltyPointsLimitReaching),
                    {
                      maxNumber: formatNumber(
                        userProfile?.companies?.find((company) => company.isActive)
                          ?.customerLoyaltyMaximumEarnedPointsLimit,
                        cultureCode
                      ),
                      expirationDate:
                        orderSummary?.customerLoyalty.loyaltyPointsExpiredDate,
                    }
                  )}
                </>
              )}
              {orderSummary?.customerLoyalty.loyaltyPointsMessageCode ===
                LOYALTY_POINTS_NO_LIMIT && (
                <>
                  {i18next.t(
                    getEditableTextFieldValue(datasource.loyaltyPointsNoLimit)
                  )}
                </>
              )}
            </Text>
          </SidePanelPaddedBox>
        </SidePanelMain>
      </SidePanel>
    </>
  )
}

export default OrderStickySummaryLoyaltyPoints
