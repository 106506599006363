import { COLORS } from '../constants/themeConstants'
import { CommerceOrderItem } from '../types/commerceApi'

export const getPartSuggestionDeviationPercentage = (part?: CommerceOrderItem) => {
  const advice = part?.suggestionInformation?.suggestedQuantity
  const quantity = part?.quantity

  if (!advice || typeof quantity !== 'number') return null

  const deviation = Math.round((quantity / advice) * 100)

  if (deviation > 100) return Math.max(0, 200 - deviation)

  return deviation
}

export const getPartSuggestionHighlighColor = (part?: CommerceOrderItem) => {
  const deviationPercentage = getPartSuggestionDeviationPercentage(part)

  if (deviationPercentage === null || deviationPercentage === 100) return undefined

  if (deviationPercentage <= 0) return COLORS.ALERT

  return COLORS.ORANGE_DARK
}
