import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'

interface PartLineTitleProps extends Omit<TextProps, 'css'> {}

const PartLineTitle: FC<PartLineTitleProps> = ({ children, sx, ...props }) => (
  <Text
    as="h5"
    variant="smallText"
    sx={{ position: 'relative', fontWeight: 'bold', color: 'gray.3', ...sx }}
    {...props}
  >
    {children}
  </Text>
)

export default PartLineTitle
