import React, { useContext } from 'react'
import i18next from 'i18next'
import { FormSpy } from 'react-final-form'
import { VehicleDetailResponse } from '../../../../../services/rest/ecommerce/dafVehicleDetail'
import SidePanelClickableSection from '../../SidePanelClickableSection/SidePanelClickableSection'
import { TextAreaField } from '../../../../atoms/FormFields'
import FormFieldText from '../../../../atoms/FormControls/FormFieldText'
import { EDIT_SERVICE_PLAN_ADD_SERVICES } from '../../../../../constants/multiSupportFormConstants'
import { ServicesContext } from '../../../../../providers/ServicesProvider'
import InputCheckboxList from '../../../../atoms/FormFields/InputCheckboxList'

interface AddServicesFormProps {
  vehicle: VehicleDetailResponse | undefined
}

const AddServicesForm = ({ vehicle }: AddServicesFormProps) => {
  const { servicesData } = useContext(ServicesContext)

  const getPackageForVehicle = () => {
    const services = servicesData?.find(
      (item) =>
        item.packageAbbreviation ===
        vehicle?.rmContract.servicePlan.coverageSummary.contractPackageAbbreviation
    )

    if (!services) return null
    return services
  }

  if (!vehicle) return null

  return (
    <>
      <SidePanelClickableSection
        label={`1. ${i18next.t('formLabels.currentServices')}`}
      >
        {vehicle?.rmContract.servicePlan.coverageSummary.contractLines?.length ? (
          <ul>
            {vehicle?.rmContract.servicePlan.coverageSummary.contractLines?.map(
              (line) => (
                <li key={line.contractLine}>{line.contractLine}</li>
              )
            )}
          </ul>
        ) : (
          <div>–</div>
        )}
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={`2. ${i18next.t('formLabels.selectAdditionalServices')}`}
        labelId="selectAdditionalServices"
      >
        <InputCheckboxList
          name={EDIT_SERVICE_PLAN_ADD_SERVICES}
          label=""
          ariaLabelledby="selectAdditionalServices"
          options={
            getPackageForVehicle()?.availableOptions.map((option) => ({
              value: option.optionKey,
              label: i18next.exists(
                `servicePlan.packages.services.${option.optionKey}`
              )
                ? i18next.t(`servicePlan.packages.services.${option.optionKey}`)
                : option.title,
            })) || []
          }
        />
        <FormSpy>
          {(props) => (
            <FormFieldText type="error">
              {props.dirtyFields[EDIT_SERVICE_PLAN_ADD_SERVICES] &&
                props.errors?.addService}
            </FormFieldText>
          )}
        </FormSpy>
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={`${i18next.t('formLabels.addRemarks')} (${i18next.t(
          'formLabels.optional'
        )})`}
      >
        <TextAreaField
          required={false}
          name="remarks"
          label={i18next.t('formLabels.addRemarks')}
          placeholder={i18next.t('formLabels.typeYourRemark')}
        />
      </SidePanelClickableSection>
    </>
  )
}

export default AddServicesForm
