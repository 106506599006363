import React, { FC } from 'react'
import { Box, FlexProps } from 'theme-ui'
import { TextField } from '../../../../types/layoutService'
import SearchControlsContainer from './SearchControlsContainer'
import SearchControlsStatistics from './SearchControlsStatistics'
import SearchControlsViewTypeSwitcher from './SearchControlsViewTypeSwitcher'
import SearchControlsStatisticsContainer from './SearchControlsStatisticsContainer'

interface SearchResultsGenericInterfaceProps extends Omit<FlexProps, 'css'> {
  viewLabel: TextField
  count?: number
  viewType?: string
  defaultViewType: string
}

const SearchControls: FC<SearchResultsGenericInterfaceProps> = ({
  count,
  viewLabel,
  children,
  viewType,
  defaultViewType,
  ...searchControlsContainerProps
}) => (
  <>
    <SearchControlsContainer {...searchControlsContainerProps}>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>

      <SearchControlsStatisticsContainer>
        <SearchControlsStatistics
          count={count}
          data-t-id="search-results-statistics"
          sx={{
            position: 'relative',
            '::after': {
              content: '""',
              display: ['none', 'none', 'block'],
              position: 'absolute',
              height: '15px',
              width: '1px',
              backgroundColor: 'gray.1',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
            },
          }}
        />

        <SearchControlsViewTypeSwitcher
          label={viewLabel}
          viewType={viewType}
          defaultViewType={defaultViewType}
        />
      </SearchControlsStatisticsContainer>
    </SearchControlsContainer>
  </>
)

export default SearchControls
