import React, { FC, useMemo } from 'react'
import withData from '../../../enhancers/withData'
import { useRecommendedPartService } from '../../../hooks/services/rest/ecommerce/useRecommendedPartsService'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  Datasource,
  GenericPart,
  Params,
  SliderParams,
  TextField,
} from '../../../types/layoutService'
import { PardCardLayoutServiceData } from '../../molecules/Card/PartCard/PartCard'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartCardListResponsive from '../CardList/PartCardListResponsive'
import usePartsListService from '../../../hooks/services/graphql/usePartListService'

interface RecommendedPartSliderDatasource {
  title: TextField
}

export interface RecommendedPartSliderProps extends PardCardLayoutServiceData {
  part?: Pick<GenericPart, 'partNumber'>
}

const RecommendedPartSlider: FC<
  Datasource<RecommendedPartSliderDatasource> &
    RecommendedPartSliderProps &
    Params<SliderParams>
> = ({
  datasource: { title },
  rendering: { uid },
  params: { variant },
  part: basePart,
  partPage,
}) => {
  const partNumbers = useMemo(
    () => (basePart?.partNumber ? [basePart?.partNumber] : undefined),
    [basePart]
  )
  const [fetchingRecommendedPartNumber, recommendedPartNumbers] =
    useRecommendedPartService(partNumbers)

  const [fetchingPartList, partList] = usePartsListService(recommendedPartNumbers)

  if (!partList?.length) return null

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid}>
      <PartCardListResponsive
        title={getTextFieldValue(title)}
        variant={variant}
        fetching={fetchingRecommendedPartNumber || fetchingPartList}
        partList={partList}
        partPage={partPage}
        dataLayerComponentOrigin={
          DATA_LAYER.COMPONENT_ORIGIN.RECOMMENDED_PART_SLIDER
        }
      />
    </AnchorMenuTarget>
  )
}

export default withData(RecommendedPartSlider, { showMessageWhenPageEditing: true })
