import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Text } from 'theme-ui'
import { Form } from 'react-final-form'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Trans } from 'react-i18next'
import SidePanel from '../SidePanel'
import { toggleEndMaintenancePlanPanel } from '../../../../actions/dafCompanyVehicleActions'
import SidePanelBody from '../SidePanelBody'
import SidePanelFooter from '../SidePanelFooter'
import Button from '../../../atoms/Button/Button'
import sendEndMaintenancePlanPayload, {
  SendEndMaintenancePlanPayloadProps,
} from '../../../../services/rest/ecommerce/sendEndMaintenancePlan'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../providers/UserProvider'
import {
  getVehicleLabel,
  validateAndGetDate,
} from '../../../../helpers/vehicleHelper'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import { RootState } from '../../../../reducers'

interface EndMaintenancePlanPanelProps {
  vehicle: VehicleDetailResponse | undefined
  onFormSuccess?: () => void
}

const EndMaintenancePlanPanel = ({
  vehicle,
  onFormSuccess,
}: EndMaintenancePlanPanelProps) => {
  const dispatch = useDispatch()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { token } = axios.CancelToken.source()

  const { endMaintenancePlanPanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const postEndMaintenancePlanInput = async (
    payload: SendEndMaintenancePlanPayloadProps
  ) => {
    const response = await sendEndMaintenancePlanPayload(
      dafVehicleDetailQueryParams,
      payload,
      client,
      token,
      user
    )

    return response.data
  }

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (payload: SendEndMaintenancePlanPayloadProps) =>
      postEndMaintenancePlanInput(payload),

    onSuccess: () => {
      dispatch(toggleEndMaintenancePlanPanel({ isActive: false }))
      if (onFormSuccess) onFormSuccess()
    },
  })

  const onSubmit = () => {
    const payload: SendEndMaintenancePlanPayloadProps = {
      vin: vehicle?.vehicleDetail.vehicleAttributes.vin,
    }

    mutate(payload)
  }

  return (
    <SidePanel
      title={i18next.t('maintenance.endMaintenancePlan')}
      active={endMaintenancePlanPanelState}
      position="right"
      onClose={() => dispatch(toggleEndMaintenancePlanPanel({ isActive: false }))}
    >
      <SidePanelBody>
        <Text variant="bodySmall">
          <Trans
            i18nKey="maintenance.endMaintenancePlanIntro"
            values={{
              registrationNumber: `<strong>${getVehicleLabel(
                vehicle?.vehicleDetail
              )}</strong>`,
              date: validateAndGetDate(new Date().toString()),
            }}
          />
        </Text>
      </SidePanelBody>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <SidePanelFooter>
              <Button
                sx={{ width: '100%' }}
                type="submit"
                disabled={!vehicle || isLoading}
              >
                {i18next.t('commonFormLabels.confirm')}
              </Button>
            </SidePanelFooter>
          </form>
        )}
      />
    </SidePanel>
  )
}

export default EndMaintenancePlanPanel
