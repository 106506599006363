import React, { FC, ReactNode } from 'react'
import { keyframes } from '@emotion/react'
import { Box, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'

interface ProgressBarProps {
  label: string
  color: string
  value?: ReactNode
  percentage?: string
  description?: string
}

const ProgressBar: FC<ProgressBarProps> = ({
  label,
  color,
  value,
  percentage,
  description,
}) => {
  if (value === null || percentage === null) return <></>
  const animateBar = keyframes`
      0% {
          width: 0
      }
      100% {
          width: ${percentage};
      }`

  return (
    <Box sx={{ marginTop: [4, 4, 5] }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 1,
          marginBottom: 2,
        }}
      >
        <Box sx={{ width: '50%', fontWeight: '500' }}>{label}</Box>
        <Box
          sx={{
            width: '50%',
            textAlign: 'right',
            paddingRight: 1,
            color: COLORS.GRAY,
          }}
        >
          {value}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          width: '100%',
          height: '10px',
          borderRadius: '5px',
          backgroundColor: COLORS.MEDIUM_GRAY,
        }}
      >
        <Box
          sx={{
            backgroundColor: color,
            height: '10px',
            width: percentage,
            borderRadius: percentage === '100%' ? '5px' : '5px 0 0 5px',
            animation: `${animateBar} 1.5s ease-in-out`,
          }}
        />
      </Box>
      {description && (
        <Text
          sx={{
            fontSize: '12px',
            lineHeight: 2,
            marginTop: 2,
            color,
          }}
        >
          {description}
        </Text>
      )}
    </Box>
  )
}

export default ProgressBar
