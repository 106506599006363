import React, { ReactNode } from 'react'
import { Box } from 'theme-ui'
import Spinner from '../Spinner/Spinner'

interface FormAsyncInputSpinnerProps {
  posting?: boolean
  children: ReactNode
}

const FormAsyncInputSpinner = ({
  posting,
  children,
}: FormAsyncInputSpinnerProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {posting && (
        <Spinner
          size={1}
          sx={{
            position: 'absolute',
            right: 3,
            top: '14px',
          }}
        />
      )}

      {children}
    </Box>
  )
}

export default FormAsyncInputSpinner
