import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import { LinkField } from '../../../../../types/layoutService'
import { AssemblyFulfilled } from '../../../../../hooks/services/rest/ecommerce/useAssemblySearchService'
import { resolvePrimaryImage } from '../../../../../helpers/commerceApiHelpers'
import PartLineImage from '../../../../molecules/PartLine/PartLineImage'
import PartLine, {
  PartLineGridTemplate,
} from '../../../../molecules/PartLine/PartLine'
import PartLineAction from '../../../../molecules/PartLine/PartLineAnchor'
import PartLineLabelValue from '../../../../molecules/PartLine/PartLineLabelValue'

interface AssemblySearchLineProps {
  searchAssembly: AssemblyFulfilled['searchAssembly']
  assembly?: AssemblyFulfilled['assembly']
  uri?: AssemblyFulfilled['uri']
  assemblyPage: LinkField
  gridTemplate?: PartLineGridTemplate
}

const AssemblySearchLine: FC<AssemblySearchLineProps> = ({
  searchAssembly,
  assembly,
  uri,
  assemblyPage,
  gridTemplate,
}) => {
  const href = `${assemblyPage?.url}/${uri}`
  const assemblyBrand = assembly?.brand
  const assemblyPrimaryImage = useMemo(
    () => assembly && resolvePrimaryImage(assembly),
    [assembly]
  )

  return (
    <PartLineAction
      href={href}
      data-t-id={`assembly-search-line-assembly-link-id-${searchAssembly.bomId}`}
    >
      <PartLine
        gridTemplate={gridTemplate}
        data-t-id={`assembly-search-line-assembly-id-${searchAssembly.bomId}`}
      >
        <PartLineImage
          src={assemblyPrimaryImage?.thumbnailImageUrl}
          alt={searchAssembly.bomDescription}
        />

        <div>
          <Text as="h4" variant="heading4" color="gray.3">
            {searchAssembly.bomDescription}
          </Text>

          <PartLineLabelValue label={i18next.t('partLabels.referenceNr')}>
            {searchAssembly.bomId}
          </PartLineLabelValue>
        </div>

        <PartLineImage
          src={assemblyBrand?.image?.thumbnailImageUrl}
          alt={assemblyBrand?.description}
          title={assemblyBrand?.description}
        />
      </PartLine>
    </PartLineAction>
  )
}

export default AssemblySearchLine
