import React, { FC, useMemo } from 'react'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import Spinner from '../../atoms/Spinner/Spinner'
import AssemblyBillOfMaterialParts from './AssemblyBillOfMaterialParts'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY } from '../../../providers/WildcardPageContextProvider'
import useAssembliesService from '../../../hooks/services/graphql/useAssemblyListService'
import usePartsService from '../../../hooks/services/graphql/usePartListService'
import { CommerceBillOfMaterialItem } from '../../../types/commerceApi'
import { Part } from '../../../types/dafResponseProps'
import { AssemblyContext } from '../../../types/sitecoreContextProps'

interface AssemblyBillOfMaterialDatasource {
  includedInTitle: TextField
  assemblyIdentifierLabel: TextField
  addToBasketLabel: TextField
  askQuotationLabel: TextField
  consistsOfTitle: TextField
}

interface AssemblyBillOfMaterialProps {
  partsPageUri: LinkField
}

export interface FulfilledBomPart {
  bomPart: CommerceBillOfMaterialItem
  part: Part | undefined
}

const AssemblyBillOfMaterial: FC<
  Datasource<AssemblyBillOfMaterialDatasource> & AssemblyBillOfMaterialProps
> = ({ datasource, rendering: { uid }, partsPageUri }) => {
  const { assembly } = useWildcardPageContext<AssemblyContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY
  )

  const assemblyIdArray = useMemo(
    () => (assembly?.assemblyId ? [assembly.assemblyId] : []),
    [assembly]
  )

  const [fetching, billOfMaterial] = useAssembliesService(assemblyIdArray, true)

  const bomParts = billOfMaterial?.[0].bomItem

  const bomPartNumbers = useMemo(
    () =>
      bomParts
        ?.map(({ partNumber }) => partNumber as string)
        .filter((partNumber) => partNumber),
    [bomParts]
  )

  const [fetchingParts, parts] = usePartsService(bomPartNumbers)

  const fulfilledBomParts = useMemo<FulfilledBomPart[] | undefined>(
    () =>
      bomParts?.map((bomPart) => ({
        bomPart,
        part: parts?.find((part) => part.partNumber === bomPart.partNumber),
      })),
    [bomParts, parts]
  )

  if (fetching || fetchingParts) {
    return (
      <Box mb={4}>
        <Spinner size={3} />
      </Box>
    )
  }

  if (!fulfilledBomParts) return null

  return (
    <AssemblyBillOfMaterialParts
      fulfilledBomParts={fulfilledBomParts}
      {...datasource}
      partsPageUri={partsPageUri}
      id={`${uid}-p`}
    />
  )
}

export default withData(AssemblyBillOfMaterial, { showMessageWhenPageEditing: true })
