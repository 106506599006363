import React, { Fragment } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import {
  Filter,
  Vehicle,
} from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import Image from '../../../atoms/Image/Image'
import MyFleetListVehicleDetails from '../MyFleetListDetails/MyFleetVehicleDetails'
import { dafVehicleActionColorHelper } from '../../../../helpers/dafVehicleActionColorHelper'
import { COLORS } from '../../../../constants/themeConstants'

import ContractServicesIcon from '../../../atoms/ContractServicesIcon/ContractServicesIcon'
import {
  MILEAGE_INPUT_STATE_OPEN,
  SERVICEPLAN_CONTRACT_STATE_ACTIVE,
  SERVICEPLAN_CONTRACT_STATE_EXPIRING,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
  VEHICLE_SERVICES_NON_MULTISUPPORT,
} from '../../../../constants/dafCompanyVehicleConstants'
import StatePill from '../../../atoms/StatePill/StatePill'
import { getMaintenanceColor } from '../../../../helpers/multisupportHelpers'
import { findFilterIdByValue } from '../../../../helpers/findFilterIdByValue'
import { LinkField } from '../../../../types/layoutService'
import Button from '../../../atoms/Button/Button'
import { URL_WILDCARD_PLACEHOLDER } from '../../../../constants/urlConstants'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import toPascalCase from '../../../../helpers/pascalCaseHelper'
import MyFleetListActionButtonList from '../MyFleetListActionButtonList/MyFleetListActionButtonList'
import { getMileageValue } from '../../../../helpers/vehicleHelper'

interface MyFleetListTableProps {
  vehicleData: Vehicle[]
  filterGroups?: Filter[]
  vehicleDetailLink?: LinkField
  requestQuoteMenuOptionTitle: string | undefined
  requestMaintenanceAppointmentOptionTitle: string | undefined
  deleteVehicleMenuOptionTitle: string | undefined
}

const MyFleetListCards = ({
  vehicleData,
  filterGroups,
  vehicleDetailLink,
  requestQuoteMenuOptionTitle,
  requestMaintenanceAppointmentOptionTitle,
  deleteVehicleMenuOptionTitle,
}: MyFleetListTableProps) => {
  const {
    languageContext: { cultureCode },
    site: { name },
  } = useSitecoreContext()

  return (
    <>
      {vehicleData?.map((vehicle: Vehicle) => (
        <Fragment key={vehicle.chassisNumber}>
          <Flex
            sx={{
              position: 'relative',
              borderTop: '1px solid',
              borderColor: COLORS.MEDIUM_GRAY,
              paddingX: 3,
              paddingY: 4,
              flexDirection: 'column',
              '&::before': {
                content: '""',
                width: '5px',
                height: '100%',
                left: 0,
                top: 0,
                position: 'absolute',
                backgroundColor: dafVehicleActionColorHelper(
                  vehicle.vehicleActionStatus,
                  vehicle.rmContract?.mostRecentContractForVehicle &&
                    vehicle.rmContract?.mileageSettlementInputState ===
                      MILEAGE_INPUT_STATE_OPEN
                ),
              },
            }}
          >
            <Flex>
              <Box
                sx={{
                  paddingRight: 3,
                }}
              >
                <Image
                  alt={vehicle?.vehicleType}
                  sx={{
                    maxWidth: 100,
                  }}
                  src={vehicle?.urlVehicleImageModelYearSeries}
                />
              </Box>
              <Box>
                <MyFleetListVehicleDetails vehicle={vehicle} />
              </Box>
            </Flex>
            <Box sx={{ marginTop: 3 }}>
              {vehicle?.mileage?.value && (
                <Flex>
                  <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
                    <Text
                      color="gray.3"
                      sx={{
                        fontSize: 1,
                      }}
                    >
                      {i18next.t('myFleet.mileageTitle')}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="bodySmall" color={COLORS.DARK}>
                      {getMileageValue(vehicle?.mileage?.value, cultureCode)}
                    </Text>
                  </Box>
                </Flex>
              )}
              {vehicle?.rmContract?.contractPackageAbbreviation && (
                <Flex>
                  <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
                    <Text color="gray.3" sx={{ fontSize: 1 }}>
                      {i18next.t('myFleet.servicesTitle')}
                    </Text>
                  </Box>
                  <Box>
                    <Flex sx={{ gap: 1 }}>
                      <ContractServicesIcon
                        contractPackageAbbreviation={
                          vehicle.rmContract.contractPackageAbbreviation
                        }
                      />
                      {vehicle?.nonMultiSupportServices?.length > 0 && (
                        <ContractServicesIcon
                          contractPackageAbbreviation={
                            VEHICLE_SERVICES_NON_MULTISUPPORT
                          }
                        />
                      )}
                    </Flex>
                  </Box>
                </Flex>
              )}
              {vehicle?.applicableFilters?.maintenance?.length > 0 && (
                <Flex>
                  <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
                    <Text color="gray.3" sx={{ fontSize: 1 }}>
                      {i18next.t('myFleet.maintenanceTitle')}
                    </Text>
                  </Box>
                  <Box>
                    <Flex>
                      {filterGroups &&
                        vehicle?.applicableFilters?.maintenance?.map(
                          (item: string) => (
                            <Box sx={{ marginLeft: 1 }} key={item}>
                              <StatePill
                                state={i18next
                                  .t(
                                    `myFleetFilters.${findFilterIdByValue({
                                      filterGroups,
                                      value: item,
                                    })}`
                                  )
                                  .toLocaleLowerCase()}
                                color={getMaintenanceColor(item)}
                              />
                            </Box>
                          )
                        )}
                    </Flex>
                  </Box>
                </Flex>
              )}
              {name === MULTISUPPORT_SITE_CONTEXT &&
                vehicle.rmContract?.contractState &&
                vehicle.rmContract?.contractState !==
                  SERVICEPLAN_CONTRACT_STATE_ACTIVE && (
                  <Flex>
                    <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
                      <Text color="gray.3" sx={{ fontSize: 1 }}>
                        {i18next.t('myFleet.servicesTitle')}
                      </Text>
                    </Box>
                    <Box>
                      <Box>
                        <StatePill
                          state={i18next.t(
                            `dafCompanyVehicles.contract${toPascalCase(
                              vehicle?.rmContract?.contractState
                            )}`
                          )}
                          color={
                            vehicle?.rmContract?.contractState ===
                            SERVICEPLAN_CONTRACT_STATE_EXPIRING
                              ? COLORS.PROMOTION
                              : COLORS.ALERT
                          }
                        />
                      </Box>
                    </Box>
                  </Flex>
                )}
              {name === MULTISUPPORT_SITE_CONTEXT &&
                vehicle.rmContract?.invoiceState &&
                vehicle.rmContract?.invoiceState ===
                  SERVICEPLAN_CONTRACT_STATE_ON_HOLD && (
                  <Flex>
                    <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
                      <Text color="gray.3" sx={{ fontSize: 1 }}>
                        {i18next.t('myFleet.invoiceTitle')}
                      </Text>
                    </Box>
                    <Box>
                      <Box>
                        <StatePill
                          state={i18next.t(
                            `dafCompanyVehicles.invoice${toPascalCase(
                              vehicle.rmContract?.invoiceState
                            )}`
                          )}
                          color={COLORS.ALERT}
                        />
                      </Box>
                    </Box>
                  </Flex>
                )}
            </Box>
            <Box
              sx={{
                marginTop: 3,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 3,
              }}
            >
              <Box>
                <MyFleetListActionButtonList
                  vehicle={vehicle}
                  vehicleDetailLink={vehicleDetailLink}
                  requestQuoteMenuOptionTitle={requestQuoteMenuOptionTitle}
                  requestMaintenanceAppointmentOptionTitle={
                    requestMaintenanceAppointmentOptionTitle
                  }
                  deleteVehicleMenuOptionTitle={deleteVehicleMenuOptionTitle}
                />
              </Box>
              <Box>
                <Button
                  as="a"
                  href={vehicleDetailLink?.url.replace(
                    URL_WILDCARD_PLACEHOLDER,
                    vehicle.chassisNumber
                  )}
                  variant="primary"
                  data-t-id={`myfleet-vehicle-detail-${vehicle.chassisNumber}`}
                  sx={{
                    width: '100%',
                  }}
                >
                  {i18next.t('myFleet.details')}
                </Button>
              </Box>
            </Box>
          </Flex>
        </Fragment>
      ))}
    </>
  )
}

export default MyFleetListCards
