import {
  DEFAULT_CULTURE_CODE,
  DEFAULT_CURRENCY_CODE,
} from '../constants/userConstants'
import {
  Price,
  PriceInformation,
} from '../hooks/services/graphql/usePartSalesInformationService'
import { CurrencyCodeSourceType } from '../types/closureTypes'
import { CommerceOrder } from '../types/commerceApi'

export const getFormattedPriceString = (
  cultureCode: string = DEFAULT_CULTURE_CODE,
  currency: string = DEFAULT_CURRENCY_CODE,
  price?: number
): string | undefined => {
  if (cultureCode && currency && price !== undefined) {
    // as older browsers don't support the currencyDisplay prop, we do need to specify a fall back
    try {
      return new Intl.NumberFormat(cultureCode, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
      }).format(price)
    } catch {
      return new Intl.NumberFormat(cultureCode, {
        style: 'currency',
        currency,
      }).format(price)
    }
  }

  return undefined
}

export const getPriceInformation = (
  partNumber?: string,
  priceInformations?: PriceInformation[]
): PriceInformation | undefined =>
  partNumber && priceInformations
    ? priceInformations?.find((price) => price.partNumber === partNumber)
    : undefined

export const getCurrencyCodeFromPriceInformation = (
  price?: Price,
  sourceType: CurrencyCodeSourceType = 'DEFAULT'
): string | undefined => {
  let currencyCode: string | undefined

  if (sourceType === 'QUANTUM_PRICE') {
    currencyCode = price?.quantumPrice?.currencyCode
  } else if (sourceType === 'SPECIAL_PRICE') {
    currencyCode = price?.specialPrice?.currencyCode
  }

  return currencyCode ?? price?.currencyCode
}

export const getQuantityDelta = (quantity: number, newQuantity: number) => {
  const delta = Math.abs(newQuantity - quantity)
  const deltaDirection = newQuantity >= quantity ? 1 : -1

  return delta * deltaDirection
}

export const getTotalOrderAmount = (order?: CommerceOrder) => ({
  value:
    order?.items?.reduce<number>(
      (count, item) => count + (item?.price?.netTotalPrice || 0),
      0
    ) || 0,
  currency: order?.items?.[0]?.price?.currencyCode || DEFAULT_CURRENCY_CODE,
})

export const getCurrencySymbol = (
  cultureCode: string,
  currencyCode?: string
): string => {
  if (!currencyCode) return ''
  const parts = new Intl.NumberFormat(cultureCode, {
    style: 'currency',
    currency: currencyCode,
  }).formatToParts()
  return parts.find((part) => part.type === 'currency')?.value || ''
}
