import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { Flex } from 'theme-ui'
import i18next from 'i18next'
import { closePartsNavigation } from '../../../actions/navigationActions'
import PartsNavigationPanel from './Panel/PartsNavigationPanel'
import PartsNavigationPanelListCategory from './Panel/PartsNavigationPanelListCategory'
import PartsNavigationPanelListSubcategory from './Panel/PartsNavigationPanelListSubcategory'
import PartsNavigationPanelSubcategoryFilterList from './Panel/PartsNavigationPanelSubcategoryFilterList'
import {
  CATEGORY_PANEL_VARIANT,
  FILTER_PANEL_VARIANT,
  SUB_CATEGORY_PANEL_VARIANT,
} from '../../../constants/partsNavigationConstants'
import {
  filterUnPublished,
  getCategoryById,
  getSubCategoryById,
} from '../../../helpers/categoryHelper'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import { LinkField } from '../../../types/layoutService'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import PartsNavigationPanelHeader from './Panel/Header/PartsNavigationPanelHeader'
import PartsNavigationPanelAnimated from './Panel/PartsNavigationPanelAnimated'
import PartsNavigationHeader from './PartsNavigationHeader'
import PartsNavigationOverlay from './PartsNavigationOverlay'

interface PartsNavigationProps {
  partsBaseUri: LinkField
}

const PartsNavigation: FC<PartsNavigationProps> = ({ partsBaseUri }) => {
  const { categories } = useContext(CategoriesContext)
  const dispatch = useDispatch()

  const [categoryId, setCategoryId] = useState<string | undefined>(undefined)
  const [subCategoryId, setSubCategoryId] = useState<string | undefined>(undefined)

  const mainMenuCategories = useMemo(
    () => filterUnPublished(categories || []),
    [categories]
  )
  const parentCategory = useMemo(
    () => getCategoryById(categoryId, mainMenuCategories),
    [mainMenuCategories, categoryId]
  )
  const subCategory = useMemo(
    () => getSubCategoryById(subCategoryId, parentCategory),
    [parentCategory, subCategoryId]
  )

  const closePartsNavigationOverlay = useCallback(() => {
    setCategoryId(undefined)
    setSubCategoryId(undefined)

    dispatch(closePartsNavigation())
  }, [dispatch])

  useEffect(() => {
    if (categoryId) setSubCategoryId(undefined)
  }, [categoryId])

  return (
    <PartsNavigationOverlay onClose={closePartsNavigationOverlay}>
      <PartsNavigationHeader onClose={closePartsNavigationOverlay} />

      <Flex sx={{ flexGrow: 1, zIndex: 0, overflowY: 'auto' }}>
        <PartsNavigationPanel sx={{ zIndex: [1, 1, 1, 3] }}>
          <PartsNavigationPanelHeader
            title={i18next.t('commonLabels.categories')}
            variant={CATEGORY_PANEL_VARIANT}
            backToPreviousPanel={closePartsNavigationOverlay}
          />

          <PartsNavigationPanelListCategory
            categories={mainMenuCategories}
            activeCategory={parentCategory}
            setCategoryId={setCategoryId}
          />
        </PartsNavigationPanel>

        <PartsNavigationPanelAnimated active={!!categoryId} sx={{ zIndex: 2 }}>
          <PartsNavigationPanelHeader
            partsBaseUri={partsBaseUri?.url || ''}
            title={parentCategory?.description || ''}
            parentCategory={parentCategory}
            variant={SUB_CATEGORY_PANEL_VARIANT}
            backToPreviousPanel={() => {
              setCategoryId(undefined)
              setSubCategoryId(undefined)
            }}
          />

          <PartsNavigationPanelListSubcategory
            categories={parentCategory?.subCategories}
            parentCategory={parentCategory}
            activeCategory={subCategory}
            setSubCategoryId={setSubCategoryId}
          />
        </PartsNavigationPanelAnimated>

        <PartsNavigationPanelAnimated
          active={!!subCategoryId}
          sx={{ zIndex: [3, 3, 3, 1] }}
        >
          <PartsNavigationPanelHeader
            partsBaseUri={partsBaseUri?.url}
            title={subCategory?.description || ''}
            parentCategory={parentCategory}
            subCategory={subCategory}
            variant={FILTER_PANEL_VARIANT}
            backToPreviousPanel={() => {
              setSubCategoryId(undefined)
            }}
          />

          <PartsNavigationPanelSubcategoryFilterList
            partsBaseUri={`${partsBaseUri?.url}/${parentCategory?.id}`}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
          />
        </PartsNavigationPanelAnimated>
      </Flex>
    </PartsNavigationOverlay>
  )
}

export default withClientSideRender(PartsNavigation)
