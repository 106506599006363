import React, { FC } from 'react'
import { Box, BoxProps, Flex } from 'theme-ui'
import Image from '../../atoms/Image/Image'
import ActionContainer from '../../atoms/ActionContainer/ActionContainer'

interface CarouselImageProps extends Omit<BoxProps, 'css' | 'variant'> {
  src?: string
  onClick?: () => void
}

const CarouselImage: FC<CarouselImageProps> = ({
  src,
  onClick = undefined,
  sx,
  ...boxProps
}) => (
  <Box
    sx={{
      paddingTop: '56.25%', // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
      position: 'relative',

      ...sx,
    }}
    {...boxProps}
  >
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <ActionContainer
        data-t-id="carousel-enlarge-image"
        as="button"
        onClick={onClick}
        sx={{
          width: '100%',
          height: '100%',
          border: 'none',
          background: 'none',
          ':hover': {},
        }}
      >
        <Flex
          as="picture"
          sx={{
            height: '100%',
            width: '100%',
            backgroundColor: 'background',
          }}
        >
          <Image
            src={src}
            sx={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
            }}
          />
        </Flex>
      </ActionContainer>
    </Box>
  </Box>
)

export default CarouselImage
