/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useMemo } from 'react'
import { Box, BoxProps, Flex, Text } from 'theme-ui'
import { getDividerSx } from '../../atoms/Divider/Divider'
import {
  PartQuantumPriceTableColumnProps,
  PartQuantumPriceTableProps,
  PartQuantumPriceTableRowProps,
} from './PartQuantumPriceTable'
import { PART_QUANTITY } from '../../../constants/partConstants'

interface PartQuantumPricePropertiesTableProps {
  row: PartQuantumPriceTableRowProps
  columns: PartQuantumPriceTableColumnProps[]
}

interface PartQuantumPriceProperty {
  id: string
  label: string
  value: string
}

const PartQuantumPricePropertiesTable: FC<PartQuantumPricePropertiesTableProps> = ({
  row,
  columns,
}) => {
  const properties = useMemo<PartQuantumPriceProperty[]>(() => {
    // Get all property keys for the current row and remove keys that are equal to 'label' or keys that refer to a property with a falsy value
    const filteredKeys = Object.keys(row).filter(
      (key) => key !== 'label' && !!(row as any)[key]
    )

    return (
      filteredKeys
        // Map keys to column and row
        .map((key) => ({
          id: key,
          label: columns.find(({ id }) => id === key)?.label,
          value: (row as any)[key],
        }))
        // Remove properties that have invalid labels or values
        .filter(
          (property): property is PartQuantumPriceProperty =>
            property.label && property.value
        )
    )
  }, [row, columns])

  const quantumAmount = () => {
    const value = properties.find((property) => property.id === PART_QUANTITY)
    return value?.value
  }

  return (
    <Box mb={5}>
      {properties.map(({ id, label, value }) => (
        <Text
          key={id}
          sx={{
            display: 'flex',
            width: '100%',
            color: 'gray.2',
            justifyContent: 'space-between',
            lineHeight: 'menuList',
            py: 3,
            ...getDividerSx('top'),
          }}
        >
          {label}

          <Flex
            sx={{
              flexBasis: '50%',
              justifyContent: 'flex-end',
            }}
          >
            <Flex
              sx={{
                alignSelf: 'flex-end',
              }}
              as="span"
              data-t-id={`QuantumPrice-${id}-Group-${quantumAmount()}`}
            >
              {value}
            </Flex>
          </Flex>
        </Text>
      ))}
    </Box>
  )
}

interface PartQuantumPriceMobileTableProps
  extends PartQuantumPriceTableProps,
    Omit<BoxProps, 'css' | 'rows'> {}

const PartQuantumPriceMobileTable = ({
  rows,
  columns,
  sx,
  ...boxProps
}: PartQuantumPriceMobileTableProps) => {
  if (!rows?.length) return null
  return (
    <>
      {rows.map((row, rowIndex) => (
        <Box
          key={rowIndex.toString()}
          sx={{
            borderTopColor: 'gray.1',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            ...sx,
          }}
          {...boxProps}
        >
          <Text as="h3" variant="heading3" my={3} data-t-id={`${row.label}`}>
            {row.label}
          </Text>

          <PartQuantumPricePropertiesTable row={row} columns={columns} />
        </Box>
      ))}
    </>
  )
}

export default PartQuantumPriceMobileTable
