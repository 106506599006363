import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'

interface Address {
  addressType: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  postalCode: string
  state: string
  countryCode: string
}

interface Email {
  address: string
  type: string
}

interface User {
  firstName: string
  lastName: string
  email: string
  language: string
}

interface Company {
  name: string
  addresses: Address[]
  telephone: string
  vatNumber: string
  cocNumber: string
  email: Email[]
}

interface Finance {
  invoiceMethod: string
  paymentMethod: string
  paymentTermsDay: number
}

export interface AccountUserDetailsResult {
  user: User
  company: Company
  finance: Finance
}

interface Message {
  message: string
  severity: string
  code: string
  args: string[]
  argsObj: Record<string, unknown>
}

export interface AccountUserDetailsResponse {
  result: AccountUserDetailsResult
  messages: Message[]
}

const accountUserDetails = (
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<AccountUserDetailsResponse>> =>
  client.get('/account-user-details', {
    cancelToken,
    params: queryParams,
    ...createRequestConfig(user),
  })

export default accountUserDetails
