import React, { useContext } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { LoyaltyContext } from '../../../providers/LoyaltyProvider'
import i18next from 'i18next'
import { formatNumber } from '../../../helpers/numberFormatHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { validateAndGetDate } from '../../../helpers/dateHelper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import { alphaColor } from '../../../helpers/themeHelper'

interface PointBalanceBannerProps {
  introduction: string
  pointsBalanceText: string
  pointsLimitReachedText: string
  expiryText: string
}

const LoyaltyPointBalanceBanner = ({
  introduction,
  pointsBalanceText,
  pointsLimitReachedText,
  expiryText,
}: PointBalanceBannerProps) => {
  const { loyaltyData } = useContext(LoyaltyContext)
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  if (!loyaltyData) return null

  return (
    <Box
      sx={{
        marginBlock: 30,
      }}
    >
      <Box
        sx={{
          backgroundColor: loyaltyData.customerLoyaltyPoints
            .maximumEarnedPointsLimitReached
            ? COLORS.MEDIUM_DARK
            : COLORS.DAF_BLUE,
          padding: [16, 32],
          color: COLORS.WHITE,
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
            alignItems: [null, null, null, 'end'],
            gap: 24,
          }}
        >
          <Box
            sx={{
              flexGrow: 3,
              flexShrink: 0,
            }}
          >
            <Text as="p">
              {i18next.t(introduction, {
                companyName: loyaltyData.dealerGroupName,
              })}
            </Text>

            <Text
              as="h2"
              variant="heading1"
              sx={{
                marginBlockStart: [24, 32],
              }}
            >
              {i18next.t(pointsBalanceText, {
                amountOfPoints: formatNumber(
                  loyaltyData.customerLoyaltyPoints.currentPointsBalance,
                  cultureCode
                ),
              })}
            </Text>
            {!!loyaltyData.customerLoyaltyPoints.nextExpiryDate.points && (
              <Text variant="smallText">
                {i18next.t(expiryText, {
                  amountOfPoints: formatNumber(
                    loyaltyData.customerLoyaltyPoints.nextExpiryDate.points,
                    cultureCode
                  ),
                  expiryDate: validateAndGetDate(
                    loyaltyData.customerLoyaltyPoints.nextExpiryDate.date
                  ),
                })}
              </Text>
            )}
          </Box>
          {loyaltyData.customerLoyaltyPoints.maximumEarnedPointsLimitReached && (
            <Flex
              sx={{
                flexBasis: '33%',
                gap: 2,
                borderBlockStart: ['1px solid', null, null, 'none'],
                borderColor: alphaColor(COLORS.WHITE, 0.5),
                paddingBlockStart: 8,
              }}
            >
              <div>
                <IconWrapper icon={ICONS.TOOLTIP} size={2}></IconWrapper>
              </div>
              <Text as="p" variant="smallText">
                {i18next.t(pointsLimitReachedText, {
                  pointsLimit: formatNumber(
                    loyaltyData.customerLoyaltyPoints.maximumEarnedPointsLimit,
                    cultureCode
                  ),
                  nextExpiryDate: validateAndGetDate(
                    loyaltyData.customerLoyaltyPoints.nextExpiryDate.date
                  ),
                })}
              </Text>
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default LoyaltyPointBalanceBanner
