import i18next from 'i18next'
import { Box, Text } from 'theme-ui'
import React, { useContext } from 'react'
import axios, { AxiosResponseHeaders } from 'axios'
import { useQuery } from '@tanstack/react-query'
import SidePanelClickableSection from '../../SidePanelClickableSection/SidePanelClickableSection'
import { COLORS } from '../../../../../constants/themeConstants'
import {
  getMileageValue,
  validateAndGetDate,
} from '../../../../../helpers/vehicleHelper'
import ClickableWrapper from '../../../../atoms/ClickableWrapper/ClickableWrapper'
import { VehicleDetailResponse } from '../../../../../services/rest/ecommerce/dafVehicleDetail'
import useSitecoreContext from '../../../../../hooks/useSitecoreContext'
import { SelectField, TextAreaField } from '../../../../atoms/FormFields'
import {
  getFileExtension,
  getFilenameFromHeaders,
  getFiletypeFromHeaders,
  saveFile,
} from '../../../../../helpers/fileHelper'
import fetchDafVehicleRmDocuments, {
  DafVehicleRmDocumentsResponse,
} from '../../../../../services/rest/ecommerce/dafVehicleRmDocuments'
import fetchDafVehicleUrlRmDocument from '../../../../../services/rest/ecommerce/dafVehicleUrlRmDocument'
import useAxiosClient from '../../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../../types/userProps'

interface ExtendServicePlanFormProps {
  vehicle: VehicleDetailResponse | undefined
}

const ExtendServicePlanForm = ({ vehicle }: ExtendServicePlanFormProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const chassisNumber =
    vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber ?? ''

  const contractOptions = (statusCurrentMonth: number | null | undefined) => {
    if (!statusCurrentMonth) return []
    const maxMonths = 96
    const options = []

    for (let i = 12; i <= maxMonths - statusCurrentMonth; i += 12) {
      options.push({
        label: `${i} ${i18next.t('commonLabels.months')}`,
        value: i.toString(),
      })
    }

    return options
  }

  const getDafVehicleRmDocuments = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafVehicleRmDocuments(
      dafVehicleDetailQueryParams,
      chassisNumber,
      client,
      token,
      user
    )
    return response.data
  }

  const { data: dafVehicleRmDocuments } = useQuery({
    queryKey: ['getDafVehicleRmDocuments'],
    queryFn: getDafVehicleRmDocuments,
    enabled: !!vehicle?.vehicleDetail?.vehicleAttributes?.chassisNumber,
  })

  const findContractDocument = (vehicleDocuments: DafVehicleRmDocumentsResponse) =>
    vehicleDocuments?.documents?.find(
      (document) => document.url.documentType === 'Contract'
    )

  const vehicleContractDocument =
    dafVehicleRmDocuments && dafVehicleRmDocuments.result
      ? findContractDocument(dafVehicleRmDocuments.result)
      : undefined

  const getDafVehicleUrlRmDocument = async () => {
    const { token } = axios.CancelToken.source()

    if (vehicleContractDocument && vehicleContractDocument.url) {
      const response = await fetchDafVehicleUrlRmDocument(
        vehicleContractDocument.url,
        client,
        token,
        user
      )
      return response
    }
    return undefined
  }

  const { data: dafVehicleUrlRmDocument } = useQuery({
    queryKey: ['getDafVehicleUrlRmDocument'],
    queryFn: getDafVehicleUrlRmDocument,
    enabled: !!vehicleContractDocument,
  })
  const handleDocumentClick = () => {
    if (dafVehicleUrlRmDocument) {
      if (dafVehicleUrlRmDocument && dafVehicleUrlRmDocument.status === 200) {
        const headers = dafVehicleUrlRmDocument?.headers as AxiosResponseHeaders
        let fileName = 'service plan agreement'
        fileName += `.${getFileExtension(getFilenameFromHeaders(headers, fileName))}`
        saveFile(
          dafVehicleUrlRmDocument,
          fileName,
          getFiletypeFromHeaders(headers, 'octet/stream')
        )
      }
    }
  }

  return (
    <>
      <SidePanelClickableSection label={i18next.t('formLabels.currentServicePlan')}>
        <Box>
          <Box sx={{ marginBottom: 2 }}>
            <Text
              variant="bodySmall"
              color={COLORS.BLACK}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {i18next.t('formLabels.plan')}
            </Text>
            <Text variant="bodySmall" color={COLORS.GRAY}>
              {vehicle?.rmContract?.servicePlan?.packageName}
            </Text>
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <Text
              variant="bodySmall"
              color={COLORS.BLACK}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {i18next.t('formLabels.endDate')}
            </Text>
            <Text variant="bodySmall" color={COLORS.GRAY}>
              {validateAndGetDate(
                vehicle?.rmContract?.servicePlan?.details?.endDate
              )}
            </Text>
          </Box>
          {vehicle?.rmContract?.servicePlan?.details?.yearlyKilometers && (
            <Box>
              <Text
                variant="bodySmall"
                color={COLORS.BLACK}
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {i18next.t('formLabels.mileage')}
              </Text>
              <Text variant="bodySmall" color={COLORS.GRAY}>
                {getMileageValue(
                  vehicle?.rmContract?.servicePlan?.details?.yearlyKilometers,
                  cultureCode
                )}
              </Text>
            </Box>
          )}
          {vehicleContractDocument && (
            <Box sx={{ marginTop: 2 }}>
              <ClickableWrapper
                sx={{ fontSize: 2 }}
                onClick={() => handleDocumentClick()}
              >
                <Text
                  variant="bodySmall"
                  color={COLORS.DAF_BLUE}
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {i18next.t('formLabels.downloadServicePlanAgreement')}
                </Text>
              </ClickableWrapper>
            </Box>
          )}
        </Box>
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={i18next.t('formLabels.selectExtentionRange')}
      >
        <SelectField
          label={i18next.t('formLabels.chooseMonths')}
          options={contractOptions(
            vehicle?.rmContract?.servicePlan?.status?.currentMonth
          )}
          placeholder={i18next.t('formLabels.chooseMonths')}
          name="extendDuration"
        />

        <Text
          sx={{
            fontSize: 0,
            fontWeight: 'bold',
          }}
          color={COLORS.GRAY}
        >
          {i18next.t('formLabels.selectExtentionRangeNote')}
        </Text>
      </SidePanelClickableSection>
      <SidePanelClickableSection
        label={`${i18next.t('formLabels.addRemarks')} (${i18next.t(
          'formLabels.optional'
        )})`}
      >
        <TextAreaField
          required={false}
          name="remarks"
          label={i18next.t('formLabels.addRemarks')}
          placeholder={i18next.t('formLabels.typeYourRemark')}
        />
      </SidePanelClickableSection>
    </>
  )
}

export default ExtendServicePlanForm
