import React, { FC } from 'react'
import i18next from 'i18next'
import CardExcerptText from '../CardExcerptText'
import CardImage from '../CardImage'
import CardTitle from '../CardTitle'
import CardBrandName from '../CardBrandName'
import CardFrame from '../CardFrame'
import CardAction from '../CardAction'

export interface DealerPartCardMinimalProps {
  partDescription?: string
  partNumber: string
  displayPartNumber?: string
  brandImageUrl?: string
  brandName?: string
  categoryId?: string
  subcategoryId?: string
  href: string
  imageUrl?: string
  onClick?: () => void
}

const DealerPartCardMinimal: FC<DealerPartCardMinimalProps> = ({
  partDescription,
  partNumber,
  brandName,
  href,
  imageUrl,
  onClick,
}) => (
  <CardFrame variant="minimal">
    <CardAction href={href} onClick={onClick}>
      <CardImage src={imageUrl} title={partDescription} />

      <CardTitle title={partDescription} />

      <CardExcerptText>
        {`${i18next.t('partLabels.partNumberAbbreviation')} ${partNumber}`}
      </CardExcerptText>
    </CardAction>

    <CardBrandName name={brandName} />
  </CardFrame>
)

export default DealerPartCardMinimal
