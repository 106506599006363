import { AxiosResponse } from 'axios'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceOrderItem, CommerceResponse } from '../../../../types/commerceApi'

interface ItemReference {
  partNumber?: string
  lineReference?: string
  externalItemReference?: string
  vehicleReference?: string
  quotationUnitPrice?: number
  updateQuotationUnitPrice?: boolean
}

export type OrderItemReferenceResponse = CommerceOrderItem[]

export interface OrderItemReferenceRequestProps {
  orderNumber?: string
  items?: ItemReference[]
}

export const postOrderItemReference: RequestFunction<
  OrderItemReferenceRequestProps,
  CommerceResponse<OrderItemReferenceResponse>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<OrderItemReferenceResponse>>> =>
  client.post(
    `/orders/${props.orderNumber || ''}/additional-information`,
    { items: props.items || [] },
    {
      ...createRequestConfig(
        context.user,
        (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
        {
          customerCompanyId: context.customerCompanyId,
          supplierCompanyId: context.supplierCompanyId,
          isImpersonated: !!context.isImpersonated,
        }
      ),
      cancelToken: config.cancelToken,
    }
  )
