import i18next from 'i18next'
import React, {
  FC,
  FormEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'theme-ui'
import {
  addOrReplaceOrderItem,
  createOrderMutation,
  markOrderMutationFinished,
} from '../../../../actions/shoppingBasket/wizard'
import { tooltipUnregister } from '../../../../actions/tooltipActions'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { getBorderColor, getTextColor } from '../../../../helpers/formInputHelper'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import { CommerceMessage, CommerceOrderItem } from '../../../../types/commerceApi'
import FormFieldText from '../../../atoms/FormControls/FormFieldText'
import FormInputBase from '../../../atoms/FormControls/FormInputBase'
import AddToBasketConfirmButton from '../../../molecules/AddToBasket/AddToBasketConfirmButton'
import AddToBasketQuantitySpinner from '../../../molecules/AddToBasket/AddToBasketQuantitySpinner/AddToBasketQuantitySpinner'
import Tooltip from '../../../molecules/Tooltip/Tooltip'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { getEnglishCategoryName } from '../../../../helpers/categoryHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import { DEFAULT_STEP_SIZE } from '../../../molecules/AddToBasket/AddToBasket'
import useCommercePostOrderItemService from '../../../../hooks/services/usePostOrderItemService'
import { refetchShoppingBasketSidepanelSummary } from '../../../../actions/shoppingBasket/sidePanelActions'
import { registerModalNotification } from '../../../../actions/notificationActions'
import { ButtonProps } from '../../../atoms/Button/Button'
import { RootState } from '../../../../reducers'
import {
  toggleR2CJobsheetSidepanel,
  toggleR2CTaskSidepanel,
} from '../../../../actions/r2cActions'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'

interface QuickOrderByPartNumberProps {
  id: string
  onOrder: (orderItem: CommerceOrderItem, message?: CommerceMessage) => void
  buttonVariant?: ButtonProps['variant']
  location: string
}

const QuickOrderByPartNumber: FC<QuickOrderByPartNumberProps> = ({
  id,
  onOrder,
  buttonVariant = 'primary',
  location,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()
  const order = useSelector((state: RootState) => state.shoppingBasketWizard.order)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { orderNumber } = useContext(ActiveUserStateContext)
  const { categories } = useContext(CategoriesContext)

  const [focused, setFocus] = useState(false)
  const [error, setError] = useState(false)
  const [responseError, setResponseError] = useState<string | undefined>()
  const [quantity, setQuantity] = useState<number>(DEFAULT_STEP_SIZE)

  const [posting, response, postToShoppingBasket] = useCommercePostOrderItemService()

  const orderMutationInProgress = useSelector(
    (state: RootState) => state.shoppingBasketWizard.mutation.mutationInProgress
  )
  const isDisabled =
    orderMutationInProgress ||
    (typeof order?.isEditAllowed === 'boolean' && !order.isEditAllowed) ||
    posting

  const trackEvent = useCallback(
    (intention: keyof typeof DATA_LAYER.CUSTOM_DIMENSION.STATUS) => {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.ADD_PART_NUMBER,
        page_type: DATA_LAYER.COMPONENT_ORIGIN.SHOPPING_BASKET,
        status: DATA_LAYER.CUSTOM_DIMENSION.STATUS[intention],
        value: ref.current?.value,
      })
    },
    []
  )
  const onResult = useCallback(
    (orderItem: CommerceOrderItem | null, message: CommerceMessage | undefined) => {
      if (!ref.current?.value || !orderItem) return
      trackEvent('SUCCESS')

      ref.current.value = ''

      onOrder(orderItem, message)

      dispatch(addOrReplaceOrderItem(orderItem))
      dispatch(refetchShoppingBasketSidepanelSummary(true))
      dispatch(markOrderMutationFinished())

      if (
        order?.isR2CEnabled &&
        order?.externalReference?.value &&
        !orderItem?.externalOrderItemReference &&
        !isImpersonated
      ) {
        dispatch(toggleR2CTaskSidepanel(true, orderItem?.partNumber))
      } else {
        dispatch(toggleR2CJobsheetSidepanel(true, orderItem?.partNumber))
      }

      setQuantity(DEFAULT_STEP_SIZE)
      pushToDataLayer({
        event: DATA_LAYER.EVENT_NAME.ADD_TO_CART,
        ecommerce: {
          currency: orderItem.price?.currencyCode,
          value: orderItem.price?.netTotalPrice,
          items: [
            {
              item_id: orderItem.partNumber,
              item_name: orderItem.englishDescription,
              item_brand: orderItem.brand?.description,
              item_category: getEnglishCategoryName(orderItem.category, categories),
              item_list_name: DATA_LAYER.COMPONENT_ORIGIN.SHOPPING_BASKET,
              doa_product: false, // 'dealer own assortment'
              quantity,
              price: orderItem.price?.netUnitPrice,
            },
          ],
        },
      })
    },
    [dispatch, trackEvent, quantity, order]
  )

  const onMessage = useCallback(
    (commerceMessage: CommerceMessage) => {
      dispatch(markOrderMutationFinished())
      if (commerceMessage.severity === 'Error') {
        setResponseError(commerceMessage.message)
        setError(true)
        trackEvent('FAIL')
      }
    },
    [trackEvent]
  )

  const orderPart = useCallback(() => {
    if (posting || !ref.current) return

    trackEvent('INTENTION')

    setResponseError(undefined)
    setError(false)

    postToShoppingBasket({
      orderNumber,
      items: [{ partNumber: ref.current.value.trim(), quantity }],
    })
  }, [posting, trackEvent, postToShoppingBasket, orderNumber, quantity])

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      event.preventDefault()
      event.stopPropagation()

      orderPart()
      dispatch(createOrderMutation())
    },
    [orderPart]
  )

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()

      return orderPart()
    },
    [orderPart]
  )

  const resetResponse = useCallback(() => {
    setResponseError(undefined)
    setError(false)
  }, [])

  useCommerceApiResponse<CommerceOrderItem>({
    response,
    onResult,
    onMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => (results as CommerceOrderItem[])?.[0],
    onStatus: {
      '*': (data) => {
        if (data?.message && data.severity !== 'Success')
          dispatch(registerModalNotification(data.severity || 'Error', data.message))
      },
    },
  })

  useEffect(() => {
    dispatch(tooltipUnregister(location))
  }, [dispatch])

  useEffect(() => {
    resetResponse()
  }, [quantity, resetResponse])

  useEffect(() => {
    if (ref?.current) ref.current.focus()
  }, [ref])

  return (
    <>
      <Flex
        as="form"
        onSubmit={onSubmit}
        sx={{
          width: '100%',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: 'default',
          borderColor: getBorderColor(error, focused),
          color: getTextColor(error),
          lineHeight: 'buttonText',
          fontSize: 1,
          transition: 'ease all 200ms',
          '> *:not(:last-child)': {
            borderRight: '1px solid',
            borderRightColor: getBorderColor(error, focused),
            transition: 'ease all 200ms',
          },
        }}
      >
        <FormInputBase
          id={id}
          data-t-id="quick-order-by-part-number-input"
          ref={ref}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={() => {
            resetResponse()
          }}
          error={error}
          placeholder={i18next.t('shoppingLabels.typePartNumber')}
          onError={() => setError(true)}
          sx={{
            borderRadius: 0,
            border: 'none',
          }}
          disabled={isDisabled}
        />

        <Flex sx={{ flexShrink: 0, width: '20%', minWidth: '75px' }}>
          <AddToBasketQuantitySpinner
            variant="infinite"
            stepSize={DEFAULT_STEP_SIZE}
            initialQuantity={DEFAULT_STEP_SIZE}
            quantity={quantity}
            onChange={setQuantity}
            debounceInput={false}
            disabled={isDisabled}
          />
        </Flex>

        <div>
          <Tooltip name={location} fixedEdgeGap={119}>
            <AddToBasketConfirmButton
              data-t-id="add-to-basket"
              confirmButtonVariant="small"
              variant={buttonVariant}
              posting={posting}
              disabled={isDisabled}
              onClick={onClick}
              sx={{ borderRadius: 0, blockSize: '100%' }}
            />
          </Tooltip>
        </div>
      </Flex>

      {responseError && (
        <FormFieldText type="error" marginBottom="2" marginTop="2">
          {responseError}
        </FormFieldText>
      )}
    </>
  )
}

export default QuickOrderByPartNumber
