import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'

export interface TabPanelProps extends Omit<BoxProps, 'css' | 'id'> {
  id?: string
  active?: boolean
}

const TabPanel: FC<TabPanelProps> = ({ active, children, sx, ...boxProps }) => {
  if (!active) return null

  return (
    <Box sx={{ paddingTop: [3, 3, 3, 5], ...sx }} {...boxProps}>
      {children}
    </Box>
  )
}

export default TabPanel
