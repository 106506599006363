import { useTheme } from '@emotion/react'
import React, { ReactNode } from 'react'
import { DEFAULT_SORT_DIRECTION } from '../../../constants/searchConstants'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import SortDirectionIcons from '../../atoms/SortDirectionIcons/SortDirectionIcons'
import { SortDirection } from '../../organisms/SearchResults/PartSearch/Table/PartSearchTable'
import PartLineTitle from '../PartLine/PartLineTitle'

interface SortableTableColumnProps {
  columnId: string
  sortColumnId?: string
  sortDirection: SortDirection
  onClick: (id: string) => void
  children: ReactNode
}

const SortableTableColumn = ({
  columnId,
  sortColumnId,
  sortDirection,
  onClick,
  children,
}: SortableTableColumnProps) => {
  const { space } = useTheme()

  return (
    <PartLineTitle>
      <ClickableWrapper
        onClick={() => onClick(columnId)}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          color: columnId === sortColumnId ? 'primary' : 'gray.3',
          width: '100%',
          fontWeight: 'bold',
          '::after': {
            content: '""',
            position: 'absolute',
            top: `calc(100% + ${space[3]}px)`,
            left: 0,
            right: 0,
            width: '100%',
            height: '1px',
            backgroundColor: columnId === sortColumnId ? 'primary' : 'transparent',
          },
          ':hover': {
            color: 'primary',
            '::after': {
              backgroundColor: 'primary',
            },
          },
        }}
      >
        {children}

        <SortDirectionIcons
          sortDirection={
            columnId === sortColumnId ? sortDirection : DEFAULT_SORT_DIRECTION
          }
          sx={{
            mt: 1,
            ml: 2,
            flexShrink: 0,
          }}
        />
      </ClickableWrapper>
    </PartLineTitle>
  )
}

export default SortableTableColumn
