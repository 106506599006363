import React, { Children, cloneElement, FC, ReactElement, ReactNode } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { isErrorText, isInput, isLabel } from '../../../helpers/formControlHelper'
import { FormInputProps } from './FormInputBase'
import { FormInputLabelProps } from './FormInputLabel'

type FormInputLabelElement = ReactElement<
  FormInputLabelProps & React.LabelHTMLAttributes<HTMLLabelElement>
>
type FormInputElement = ReactElement<FormInputProps>

export interface FormControlProps extends Omit<BoxProps, 'css'> {
  disabled?: boolean
  error?: boolean
  id?: string
}

const renderChildren = (
  children: ReactNode,
  { disabled, error, id }: FormControlProps
): ReactNode =>
  Children.map(children, (child) => {
    if (isLabel(child)) {
      return cloneElement(child as FormInputLabelElement, {
        htmlFor: id,
      })
    }

    if (isErrorText(child)) {
      return error ? child : null
    }

    if (isInput(child)) {
      return cloneElement(child as FormInputElement, {
        disabled,
        error,
        id,
      })
    }

    return child
  })

const FormControl: FC<FormControlProps> = ({
  children,
  disabled,
  error,
  id,
  sx,
  ...rest
}) => (
  <Box sx={{ marginBottom: 4, ...sx }} {...rest}>
    {renderChildren(children, { disabled, error, id })}
  </Box>
)

export default FormControl
