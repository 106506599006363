import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC } from 'react'
import ContentColumnLayout from '../../atoms/ContentColumnLayout/ContentColumnLayout'
import { DatasourceBase, Params } from '../../../types/layoutService'

const ThreeContentColumnLayout: FC<DatasourceBase & Params> = ({ rendering }) => (
  <ContentColumnLayout>
    <Placeholder name="jss-content-first-column" rendering={rendering} />

    <Placeholder name="jss-content-second-column" rendering={rendering} />

    <Placeholder name="jss-content-third-column" rendering={rendering} />
  </ContentColumnLayout>
)

export default ThreeContentColumnLayout
