import React, { FC } from 'react'
import { Flex, FlexProps, Text } from 'theme-ui'
import Spinner, { DEFAULT, VARIANT } from '../Spinner/Spinner'

interface SpinnerWithLabelProps extends Omit<FlexProps, 'css'> {
  label?: string
  size?: number
  variant?: typeof VARIANT.DARK | typeof VARIANT.LIGHT
  fontSize?: number
}

const SpinnerWithLabel: FC<SpinnerWithLabelProps> = ({
  label,
  variant = DEFAULT.VARIANT,
  size = DEFAULT.SIZE,
  fontSize = DEFAULT.FONT_SIZE,
  children,
  sx,
  ...flexProps
}) => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      ...sx,
    }}
    {...flexProps}
  >
    <Spinner variant={variant} size={size} />

    <Text as="p" sx={{ fontSize, marginTop: 2, color: 'gray.2' }}>
      {label || children}
    </Text>
  </Flex>
)

export default SpinnerWithLabel
