import React, { FC, useContext } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import { loggedIn } from '../../../helpers/userHelper'
import { LinkField } from '../../../types/layoutService'
import PartSalesInformation from '../../molecules/SalesInformation/PartSalesInformation'
import { UserContext } from '../../../providers/UserProvider'
import { FulfilledBomPart } from './AssemblyBillOfMaterial'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartLine, { PartLineGridTemplate } from '../../molecules/PartLine/PartLine'
import PartLineImage from '../../molecules/PartLine/PartLineImage'
import PartLineBasicInformation from '../../molecules/PartLine/PartLineBasicInformation'
import { getCommercePartUri } from '../../../helpers/commerceApiHelpers'
import PartLineStockInformation from '../../molecules/PartLine/PartLineStockInformation'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'
import PartLineAnchor from '../../molecules/PartLine/PartLineAnchor'

interface AssemblyBillOfMaterialPartProps {
  fulfilledBomPart: FulfilledBomPart
  partsPageUri: LinkField
  gridTemplate?: PartLineGridTemplate
}

const AssemblyBillOfMaterialPart: FC<AssemblyBillOfMaterialPartProps> = ({
  fulfilledBomPart,
  partsPageUri,
  gridTemplate,
}) => {
  const { user } = useContext(UserContext)

  const { bomPart, part } = fulfilledBomPart
  const partPreviewImage = part?.images?.[0].thumbnailImageUrl
  const href = bomPart?.partNumber
    ? `${partsPageUri.url}/${getCommercePartUri(
        part?.category?.mainCategoryId,
        part?.category?.subCategoryId,
        bomPart.partNumber
      )}`
    : undefined

  return (
    <PartSalesInformationProvider partNumber={bomPart.partNumber}>
      <PartLine
        gridTemplate={gridTemplate}
        data-t-id={`consists-of-${bomPart.partNumber}`}
      >
        <PartLineAnchor
          href={href}
          sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
        >
          <Text
            as="span"
            variant="bodySmall"
            sx={{
              display: ['flex', 'flex', 'none'],
              justifyContent: 'space-between',
              mr: 2,
              lineHeight: 'heading3',
              color: 'gray.3',
            }}
          >
            {i18next.t('commonLabels.numberAbbreviation')}
          </Text>

          <Text as="span" variant="bodySmall" color="grey.2">
            {bomPart.drawingReference}
          </Text>
        </PartLineAnchor>
        <PartLineAnchor href={href}>
          <PartLineImage
            src={partPreviewImage}
            alt={part?.description || bomPart?.description}
          />
        </PartLineAnchor>
        <PartLineAnchor href={href}>
          <PartLineBasicInformation
            description={part?.description || bomPart?.description}
            partNumber={bomPart.partNumber || ''}
            displayPartNumber={part?.displayPartNumber || bomPart.partNumber}
          />
        </PartLineAnchor>
        <PartLineAnchor href={href}>
          <PartLineImage
            src={part?.brand?.image?.thumbnailImageUrl}
            alt={part?.brand?.description}
            sx={{
              maxWidth: 48,
              maxHeight: 48,
            }}
          />
        </PartLineAnchor>
        {loggedIn(user) && (
          <PartLineStockInformation
            partNumber={bomPart.partNumber}
            componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.ASSEMBLY_BOM}
            categoryId={part?.category?.mainCategoryId}
            subcategoryId={part?.category?.subCategoryId}
          />
        )}
        {loggedIn(user) && (
          <PartSalesInformation
            partNumber={bomPart.partNumber}
            partEnglishDescription={part?.englishDescription}
            brandName={part?.brand?.description}
            categoryId={part?.category?.mainCategoryId}
            subcategoryId={part?.category?.subCategoryId}
            dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.ASSEMBLY_BOM}
            packageUnitQuantity={part?.specification?.packaging?.packageUnitQuantity}
            initialQuantity={bomPart.quantity}
          />
        )}
      </PartLine>
    </PartSalesInformationProvider>
  )
}

export default AssemblyBillOfMaterialPart
