import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../../types/userProps'
import { createRequestConfig } from '../../../../helpers/axiosHelper'

export interface LoyaltyResult {
  dealerGroupName: string
  customerLoyaltyTypeName: string
  customerLoyaltyPoints: {
    maximumEarnedPointsLimit: number
    maximumEarnedPointsLimitReached: boolean
    currentPointsBalance: number
    nextExpiryDate: {
      date: string
      points: number
    }
  }
}

interface Message {
  message: string
  severity: string
  code: string
  args: string[]
  argsObj: Record<string, any>
}

interface LoyaltyData {
  result: LoyaltyResult
  messages: Message
}

const getLoyaltyInformation = (
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<LoyaltyData>> =>
  client.get('customer-loyalty-information', {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default getLoyaltyInformation
