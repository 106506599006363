import i18next from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Flex, Text } from 'theme-ui'
import { RootState } from '../../../reducers'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { getMileageValue, validateAndGetDate } from '../../../helpers/vehicleHelper'
import { validateString } from '../../../helpers/stringHelper'
import { COLORS } from '../../../constants/themeConstants'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const MileageSettlementHistoryTile = () => {
  const { vehicleData, isFetching } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const mileageValue = getMileageValue(
    vehicleData?.rmContract?.finance?.mileageSettlementDetails
      ?.latestMileageSettlementMileage,
    cultureCode
  )
  return (
    <>
      {!isFetching && (
        <>
          <Text sx={{ fontSize: 4, mb: [4, 4, 5] }}>
            {i18next.t('finance.historyTitle')}
          </Text>
          <Box sx={{ fontSize: 1, lineHeight: '24px' }}>
            <Flex>
              <Box sx={{ flexGrow: 1, fontWeight: 500 }}>
                {i18next.t('finance.lastMileageSettlement')}
              </Box>
              <Box
                sx={{ color: COLORS.GRAY }}
                data-t-id="last-mileage-settlement-value"
              >
                {validateString(mileageValue)}
              </Box>
            </Flex>
            <Flex>
              <Box sx={{ flexGrow: 1, fontWeight: 500 }}>
                {i18next.t('finance.lastMileageSettlementDate')}
              </Box>
              <Box
                sx={{ color: COLORS.GRAY }}
                data-t-id="last-mileage-settlement-date"
              >
                {validateAndGetDate(
                  vehicleData?.rmContract?.finance?.mileageSettlementDetails
                    ?.latestMileageSettlementDate
                )}
              </Box>
            </Flex>
          </Box>
        </>
      )}
    </>
  )
}

export default MileageSettlementHistoryTile
