import React, { FC } from 'react'
import { Box } from 'theme-ui'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'

interface QuantityIncrementButtonProps {
  disabled?: boolean
  onClick: () => void
}

const QuantityIncrementButton: FC<QuantityIncrementButtonProps> = ({
  disabled,
  onClick,
}) => (
  <ClickableWrapper
    data-t-id="quantity-increment"
    data-is-draggable="false"
    disabled={disabled}
    onClick={onClick}
    sx={{
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      outline: 'none',
      paddingRight: 3,
      paddingLeft: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        position: 'relative',
        width: '10px',
        height: '2px',
        bg: disabled ? 'gray.1' : 'gray.3',
        '::after': {
          content: '""',
          bg: 'inherit',
          height: '10px',
          width: '2px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
        },
      }}
    />
  </ClickableWrapper>
)

export default QuantityIncrementButton
