import React from 'react'
import { Box } from 'theme-ui'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'
import { ImageWithFocalPointField, VideoField } from '../../../types/layoutService'
import BannerVideo from '../../atoms/Banner/BannerVideo'

interface DigitalMagazineBackgroundImageProps {
  image?: ImageWithFocalPointField
  video?: VideoField
}

const DigitalMagazineBackground: React.FC<DigitalMagazineBackgroundImageProps> = ({
  image,
  video,
}) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        height: '100%',
        right: 0,
        left: 0,
        width: '100%',
        background: 'rgba(0,0,0,0.5)',
        opacity: 1,
        zIndex: 1,
      },
    }}
  >
    {video && <BannerVideo video={video} />}
    {image && <SitecoreImage imageField={image} />}
  </Box>
)

export default DigitalMagazineBackground
