import React, { FC, useMemo } from 'react'
import { Box } from 'theme-ui'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { CommerceOrderItem } from '../../../../../types/commerceApi'
import PromotionSticker from '../../../../atoms/PromotionSticker/PromotionSticker'
import PartLine from '../../../../molecules/PartLine/PartLine'
import PartLineAnchor from '../../../../molecules/PartLine/PartLineAnchor'
import PartLineBrand from '../../../../molecules/PartLine/PartLineBrand'
import PartLineImage from '../../../../molecules/PartLine/PartLineImage'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import PartPrice from '../../../../molecules/PartPrice/PartPrice'
import { getPartSuggestionHighlighColor } from '../../../../../helpers/partSuggestionHelper'
import PartLineInformation from '../../../../molecules/PartLine/PartLineInformation'
import PartLineAddToBasket from '../../../../molecules/PartLine/PartLineAddToBasket'
import PartListLineModifiers from '../../../../molecules/PartLine/PartListLineModifiers'
import PartPromotions from '../../../../molecules/PartPromotions/PartPromotions'

interface PartListItemProps {
  href: string
  part: CommerceOrderItem
  index: number
  onClick: () => void
}

const PartListItem: FC<PartListItemProps> = ({ href, part, index, onClick }) => {
  const color = useMemo(
    () => getPartSuggestionHighlighColor(part) || undefined,
    [part]
  )
  return (
    <PartLine
      data-t-id={`shopping-basket-part-line-${part?.partNumber}`}
      sx={{
        gridTemplateColumns: [
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
          '1fr 1.5fr 1fr 1fr 1fr 1.25fr 1fr 1fr',
        ],
        gridTemplateRows: ['repeat(5, auto)', 'repeat(5, auto)', 'auto'],
        gridTemplateAreas: [
          '"image image image" "info info info" "stock stock stock" " unit basket total" "brand . modifiers"',
          '"image image image" "info info info" "stock stock stock" " unit basket total" "brand . modifiers"',
          '"image info brand stock unit basket total modifiers"',
        ],
        position: 'relative',
        paddingX: [0, 0, 4],
        paddingY: 3,
        borderLeft: [0, 0, '1px solid'],
        borderRight: [0, 0, '1px solid'],
        borderColor: ['gray.1', 'gray.1', 'gray.1'],
        alignItems: 'center',
        '&::before': {
          position: 'absolute',
          content: '""',
          width: '4px',
          left: ['-16px', '-16px', 0],
          height: 'calc(100% + 1px)',
          backgroundColor: color,
        },
        '&:first-of-type': {
          borderTop: '1px solid',
          borderTopColor: 'gray.1',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: 'default',
          borderBottomRightRadius: 'default',
        },
      }}
    >
      <Box sx={{ gridArea: 'image' }}>
        <PartLineAnchor href={href} onClick={onClick}>
          <PromotionSticker
            price={part.price}
            variant="s"
            sx={{
              width: 64,
              display: 'inline-block',
            }}
          >
            <PartLineImage
              src={part?.images?.[0]?.thumbnailImageUrl}
              alt={part?.description}
            />
          </PromotionSticker>
        </PartLineAnchor>
      </Box>

      <Box sx={{ gridArea: 'info' }}>
        <PartLineInformation part={part} href={href} onClick={onClick} />
      </Box>

      <Box sx={{ gridArea: 'brand' }}>
        <PartLineAnchor href={href} onClick={onClick}>
          <PartLineBrand brand={part?.brand} />
        </PartLineAnchor>
      </Box>

      <Box sx={{ gridArea: 'stock' }}>
        <PartLineStockInformation
          partNumber={part.type === 'Paccar' ? part.partNumber : undefined}
          displayPartNumber={
            part.type === 'Paccar' ? part.displayPartNumber : undefined
          }
          stockInformation={part.stock}
          categoryId={part.category?.mainCategoryId}
          subcategoryId={part.category?.subCategoryId}
          componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SHOPPING_BASKET}
        />
      </Box>

      <Box sx={{ gridArea: 'unit' }}>
        <PartPrice price={part.price} />
        <PartPromotions price={part.price} sx={{ marginBlockStart: 1 }} />
      </Box>
      <Box sx={{ gridArea: 'basket' }}>
        <PartLineAddToBasket part={part} />
      </Box>
      <Box
        sx={{
          gridArea: 'total',
          justifySelf: 'end',
        }}
      >
        <PartPrice price={part.price} useTotalPrice />
      </Box>

      <Box sx={{ gridArea: 'modifiers', justifySelf: 'end' }}>
        <PartListLineModifiers
          part={part}
          index={index}
          sx={{
            display: 'flex',
            gap: 2,
          }}
        />
      </Box>
    </PartLine>
  )
}

export default PartListItem
