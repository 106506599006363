import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../IconWrapper/IconWrapper'

interface ActionItemBaseProps extends Omit<TextProps, 'css'> {}

const ActionItemBase: FC<ActionItemBaseProps> = ({ children, sx, ...props }) => (
  <Text
    variant="bodySmall"
    sx={{
      display: 'flex',
      marginBottom: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'gray.3',
      ':hover': {
        color: 'primary',
      },
      ...sx,
    }}
    {...props}
  >
    {children}

    <IconWrapper icon={ICONS.ARROW_RIGHT} size={4} />
  </Text>
)

export default ActionItemBase
