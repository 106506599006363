import React, { FC } from 'react'
import { TextField } from '../../../types/layoutService'
import { ButtonProps } from '../../atoms/Button/Button'
import AddToBasketConfirmButtonNormal from './AddToBasketConfirmButtonNormal'
import AddToBasketConfirmButtonSmall from './AddToBasketConfirmButtonSmall'

export type AddToBasketConfirmButtonVariant = 'normal' | 'small'

interface AddToBasketConfirmButtonProps extends Omit<ButtonProps, 'label'> {
  disabled?: boolean
  confirmButtonVariant?: AddToBasketConfirmButtonVariant
  posting?: boolean
  askQuotationLabel?: TextField
  orderLabel?: TextField
}

const AddToBasketConfirmButton: FC<AddToBasketConfirmButtonProps> = ({
  confirmButtonVariant = 'normal',
  disabled,
  askQuotationLabel,
  orderLabel,
  ...buttonProps
}) => {
  if (confirmButtonVariant !== 'normal') {
    return <AddToBasketConfirmButtonSmall disabled={disabled} {...buttonProps} />
  }

  return (
    <AddToBasketConfirmButtonNormal
      askQuotationLabel={askQuotationLabel}
      orderLabel={orderLabel}
      {...buttonProps}
    />
  )
}

export default AddToBasketConfirmButton
