import React, { useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import i18next from 'i18next'
import { Datasource, TextField } from '../../../types/layoutService'
import { COLORS } from '../../../constants/themeConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import WithData from '../../../enhancers/withData'
import { MyAccountPageProvider } from '../../../providers/MyAccountPageProvider'
import CompanyProfileOverview from './CompanyProfileOverview'
import RequestMyAccountChangesSidepanel from '../SidePanel/RequestMyAccountChangesSidepanel/RequestMyAccountChangesSidepanel'
import Button from '../../atoms/Button/Button'

interface CompanyProfileDatasource {
  title: TextField
  description: TextField
  companyName: TextField
  addressLabel: TextField
  phoneNumberLabel: TextField
  emailLabel: TextField
  vATNumberLabel: TextField
  chamberOfCommerce: TextField
  dAFMultiSupportTitle: TextField
  repairAndMaintenanceEmailLabel: TextField
  datasource: {
    targetItem: RequestChangesSidepanelDatasource
  }
}

interface RequestChangesSidepanelDatasource {
  title: TextField
  footer: TextField
  messageInputLabel: TextField
  messageInputPlaceholder: TextField
  thankYouMessage: TextField
}

const CompanyProfile = ({
  datasource: {
    title,
    description,
    companyName,
    addressLabel,
    phoneNumberLabel,
    emailLabel,
    vATNumberLabel,
    chamberOfCommerce,
    dAFMultiSupportTitle,
    repairAndMaintenanceEmailLabel,
    datasource: { targetItem },
  },
}: Datasource<CompanyProfileDatasource>) => {
  const { space } = useTheme()
  const [sidePanelActive, setSidePanelActive] = useState(false)
  return (
    <MyAccountPageProvider>
      <Flex sx={{ flexDirection: 'column' }}>
        {title && (
          <Text
            as="h1"
            variant="heading1"
            sx={{
              color: COLORS.BLACK,
              fontSize: 5,
              lineHeight: 'heading',
            }}
          >
            {getTextFieldValue(title)}
          </Text>
        )}
        {description && (
          <Box sx={{ width: ['100%', '66%'] }}>
            <Text
              variant="heading2"
              sx={{
                marginBlock: space[3],
                color: COLORS.GRAY,
                lineHeight: 'body',
                fontSize: 2,
              }}
            >
              {getTextFieldValue(description)}
            </Text>
          </Box>
        )}
        <CompanyProfileOverview
          labels={{
            companyName: getTextFieldValue(companyName),
            addressLabel: getTextFieldValue(addressLabel),
            phoneNumberLabel: getTextFieldValue(phoneNumberLabel),
            emailLabel: getTextFieldValue(emailLabel),
            vATNumberLabel: getTextFieldValue(vATNumberLabel),
            chamberOfCommerce: getTextFieldValue(chamberOfCommerce),
            dAFMultiSupportTitle: getTextFieldValue(dAFMultiSupportTitle),
            repairAndMaintenanceEmailLabel: getTextFieldValue(
              repairAndMaintenanceEmailLabel
            ),
          }}
        />
        <Button
          sx={{ marginBlockStart: space[4] }}
          data-t-id="company-profile-request-changes-sidepanel"
          variant="primary"
          onClick={() => {
            setSidePanelActive(true)
          }}
        >
          {i18next.t('myAccountOverview.requestAChange')}
        </Button>
      </Flex>
      <RequestMyAccountChangesSidepanel
        active={sidePanelActive}
        labels={targetItem}
        onSidepanelClose={() => {
          setSidePanelActive(false)
        }}
      />
    </MyAccountPageProvider>
  )
}

export default WithData(CompanyProfile)
