import React, { ReactNode } from 'react'
import { Box, Text } from 'theme-ui'
import ClickableWrapper from '../../../atoms/ClickableWrapper/ClickableWrapper'

interface StoreSwitcherMenuActionProps {
  onClick: () => void
  currentlySelected?: boolean
  children: ReactNode
}

const StoreSwitcherMenuAction = ({
  onClick,
  children,
  currentlySelected,
  ...boxProps
}: StoreSwitcherMenuActionProps) => (
  <Box as="li" sx={{ listStyle: 'none' }} {...boxProps}>
    <ClickableWrapper
      as="button"
      onClick={onClick}
      sx={{
        px: [3, 3, 5],
        py: [3, 3, 2],
        width: '100%',
        position: 'relative',
        outline: 'none',
        color: currentlySelected ? 'primary' : 'gray.3',
        bg: 'background',
        ':hover, :active, :focus': {
          color: 'primary',
        },
        '::before, ::after': {
          position: 'absolute',
          content: '""',
          display: ['block', 'block', 'none'],
          top: '50%',
        },
        '::before': {
          right: 0,
          borderRadius: '50%',
          border: '1px solid',
          borderColor: currentlySelected ? 'primary' : 'gray.2',
          transform: 'translateY(-50%)',
          bg: 'transparent',
          width: 20,
          height: 20,
        },
        '::after': {
          right: '7px',
          borderRight: '2px solid',
          borderBottom: '2px solid',
          borderColor: currentlySelected ? 'background' : 'transparent',
          transform: 'translateY(calc(-50% - 1px)) rotate(45deg)',
          width: 5,
          height: 8,
        },
      }}
    >
      <Text
        as="span"
        variant="bodySmall"
        sx={{ lineHeight: 'menuList', whiteSpace: 'nowrap' }}
      >
        {children}
      </Text>
    </ClickableWrapper>
  </Box>
)

export default StoreSwitcherMenuAction
