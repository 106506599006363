import i18next from 'i18next'
import React, { FC, useContext, useMemo } from 'react'
import { parseDate } from '../../../../../helpers/dateHelper'
import { getFormattedPriceString } from '../../../../../helpers/priceHelper'
import useSitecoreContext from '../../../../../hooks/useSitecoreContext'
import { LocalizationContext } from '../../../../../providers/LocalizationProvider'
import { CommerceWebshopPointsCampaign } from '../../../../../types/commerceApi'

const WebshopPointsCampaignInformationText: FC<{
  webshopPointsCampaign?: CommerceWebshopPointsCampaign
}> = ({ webshopPointsCampaign }) => {
  const { dateFormatter } = useContext(LocalizationContext)
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(cultureCode),
    [cultureCode]
  )

  if (!webshopPointsCampaign) return null

  if (webshopPointsCampaign.activationCode) {
    // Activate
    return (
      <p>
        {i18next.t('shoppingLabels.webshopPointsCampaign.activateMessage')}
        <br />
        <br />
        {i18next.t('shoppingLabels.webshopPointsCampaign.orderForPointsMessage', {
          orderForBonusPoints: getFormattedPriceString(
            cultureCode,
            webshopPointsCampaign.currencyCode,
            webshopPointsCampaign.orderForBonusPoints
          ),
          bonusPoints: numberFormatter.format(
            Number(webshopPointsCampaign.bonusPoints)
          ),
        })}
      </p>
    )
  }

  if (
    webshopPointsCampaign.maxPointsReached &&
    !webshopPointsCampaign.pointsRedeemed
  ) {
    // Maximum points earned
    return (
      <p>
        {i18next.t(
          'shoppingLabels.webshopPointsCampaign.maximumPointsReachedMessage',
          {
            maxPoints: numberFormatter.format(
              Number(webshopPointsCampaign.maxPoints)
            ),
            endDate: dateFormatter(parseDate(webshopPointsCampaign.endDate)),
          }
        )}
      </p>
    )
  }

  if (webshopPointsCampaign.pointsRedeemed) {
    // Maximum points redeemed
    return (
      <p>
        {i18next.t('shoppingLabels.webshopPointsCampaign.pointsRedeemed', {
          maxPoints: numberFormatter.format(Number(webshopPointsCampaign.maxPoints)),
        })}
      </p>
    )
  }

  // Activated
  return (
    <p>
      {i18next.t('shoppingLabels.webshopPointsCampaign.orderForPointsMessage', {
        orderForBonusPoints: getFormattedPriceString(
          cultureCode,
          webshopPointsCampaign.currencyCode,
          webshopPointsCampaign.orderForBonusPoints
        ),
        bonusPoints: numberFormatter.format(
          Number(webshopPointsCampaign.bonusPoints)
        ),
      })}
    </p>
  )
}

export default WebshopPointsCampaignInformationText
