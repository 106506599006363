import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'
import { AxiosResponse } from 'axios'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'

export interface OrderSendQuotationProps {
  orderNumber?: string
}

export const sendQuotation: RequestFunction<
  OrderSendQuotationProps,
  CommerceResponse<CommerceOrder>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrder>>> =>
  client.post(`/orders/${props.orderNumber}/quotation-confirmation`, null, {
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
      }
    ),
    cancelToken: config.cancelToken,
  })
