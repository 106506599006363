import React, { useEffect, useMemo, useRef } from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { Box, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { useDispatch } from 'react-redux'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  ImageWithFocalPointField,
  LinkField,
  TextField,
  VideoField,
} from '../../../types/layoutService'
import DigitalMagazineSlide from '../../molecules/DigitalMagazine/DigitalMagazineSlide'
import { kebabCase } from '../../../helpers/kebabCaseHelper'
import Button from '../../atoms/Button/Button'
import Link from '../../atoms/Link/Link'
import { pushToDigitalMagazineMenu } from '../../../actions/digitalMagazine/digitalMagazineActions'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import renderHTML from '../../../helpers/renderHTMLHelper'
import BannerLogo from '../../atoms/Banner/BannerLogo'
import { getEditableTextFieldValue } from '../../../helpers/layoutServiceHelper'

interface MagazineCallToActionProps {
  menutitle: TextField
  backgroundImage?: ImageWithFocalPointField
  backgroundVideo?: VideoField
  title: TextField
  subject: TextField
  text: TextField
  primaryButton?: LinkField
  logo?: {
    targetItem?: {
      image: ImageWithFocalPointField
    }
  }
}

// eslint-disable-next-line
const MagazineCallToAction = ({
  datasource,
}: Datasource<MagazineCallToActionProps>) => {
  const dispatch = useDispatch()
  const { space } = useTheme()
  const controls = useAnimation()
  const ref = useRef(null)
  const isInView = useInView(ref, { margin: '0px 0px -50% 0px', once: true })
  const titleVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: 'easeOut' },
    },
    hidden: { opacity: 0, y: 50 },
  }

  const logoVariants = {
    visible: {
      opacity: 1,
      transition: { delay: 1, duration: 0.7, ease: 'easeOut' },
    },
    hidden: { opacity: 0 },
  }

  useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [controls, isInView])

  const slideMenuData = useMemo(
    () => ({
      menuTitle: datasource.menutitle.value,
      menuId: kebabCase(datasource.menutitle.value),
    }),
    [datasource.menutitle.value]
  )

  useEffect(() => {
    dispatch(pushToDigitalMagazineMenu(slideMenuData))
  }, [dispatch, slideMenuData])

  return (
    <DigitalMagazineSlide
      image={datasource?.backgroundImage}
      video={datasource?.backgroundVideo}
      id={kebabCase(datasource.menutitle.value)}
      ref={ref}
    >
      <Box
        sx={{
          position: 'relative',
          paddingY: [
            `${space[7]}px`,
            `${space[7]}px`,
            `${space[8]}px`,
            `${space[8]}px`,
            `${space[8]}px`,
          ],
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: ['90%', '90%', '90%', '60%', '60%'],
          }}
        >
          {datasource.title.value && (
            <motion.div initial="hidden" variants={titleVariants} animate={controls}>
              <Text
                sx={{
                  fontSize: [4],
                  fontWeight: 'bold',
                  color: 'white',
                  textTransform: 'uppercase',
                }}
              >
                {datasource.subject.value}
              </Text>

              <Text
                as="h2"
                sx={{
                  marginTop: 3,
                  fontSize: [5, 5, 6, 6, 7],
                  fontWeight: 'light',
                  color: 'white',
                }}
              >
                {datasource.title.value}
              </Text>

              <Text
                color="white"
                sx={{ lineHeight: 'heading2', marginBlockStart: space[3] }}
              >
                {renderHTML(getEditableTextFieldValue(datasource.text))}
              </Text>

              {datasource.primaryButton && (
                <Button
                  variant="outline"
                  sx={{
                    marginTop: 3,
                  }}
                >
                  <Link
                    href={datasource.primaryButton.url}
                    onClick={() =>
                      pushToDataLayer({
                        [DATA_LAYER.EVENT_KEYS.EVENT]:
                          DATA_LAYER.EVENT.CALL_TO_ACTION,
                        call_to_action_name: datasource.primaryButton?.text,
                        call_to_action_type:
                          DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
                        destination_url: datasource.primaryButton?.url,
                      })
                    }
                  >
                    {datasource.primaryButton.text}
                  </Link>
                </Button>
              )}
              {datasource.logo && (
                <motion.div
                  initial="hidden"
                  variants={logoVariants}
                  animate={controls}
                >
                  <BannerLogo
                    sx={{
                      top: 0,
                      zIndex: 0,
                      display: ['none', 'none', 'none', 'block', 'block'],
                      marginTop: [`${space[8]}px`, `${space[9]}px`, `${space[9]}px`],
                    }}
                    logoImageField={datasource?.logo?.targetItem?.image}
                    logoPosition="topRight"
                  />
                </motion.div>
              )}
            </motion.div>
          )}
        </Box>
      </Box>
    </DigitalMagazineSlide>
  )
}
export default withData(MagazineCallToAction)
