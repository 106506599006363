import { useTheme } from '@emotion/react'
import i18next from 'i18next'
import React, { FC, ReactNode, useCallback, useContext, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { ICONS } from '../../../../../constants/iconConstants'
import { pushToDataLayer } from '../../../../../helpers/analyticsHelper'
import { parseDate } from '../../../../../helpers/dateHelper'
import { LocalizationContext } from '../../../../../providers/LocalizationProvider'
import { CommerceWayOfTransportOption } from '../../../../../types/commerceApi'
import ClickableWrapper from '../../../../atoms/ClickableWrapper/ClickableWrapper'
import { getDividerSx } from '../../../../atoms/Divider/Divider'
import IconWrapper from '../../../../atoms/IconWrapper/IconWrapper'
import { COLORS } from '../../../../../constants/themeConstants'

interface OrderFieldWayOfTransportProps extends CommerceWayOfTransportOption {
  onSelect: (id: number) => void
}

interface LabelValueProps {
  label: string
  method?: string
  deliveryTerm?: string
  children: ReactNode
}

const LabelValue = ({ label, method, deliveryTerm, children }: LabelValueProps) => (
  <>
    <Text
      variant="bodySmall"
      sx={{ color: 'gray.3', overflowWrap: 'break-word', lineHeight: 'menuList' }}
    >
      {label}
    </Text>

    <Text
      variant="bodySmall"
      data-t-id={`${label}-${method}-${deliveryTerm}`}
      sx={{ color: 'gray.2', lineHeight: 'menuList' }}
    >
      {children}
    </Text>
  </>
)

// todo: reuse existing input ratio component
const Radio: FC<{ active?: boolean }> = ({ active }) => (
  <Box
    as="span"
    sx={{
      height: '10px',
      width: '10px',
      position: 'relative',
      backgroundColor: active ? 'primary' : 'transparent',
      borderRadius: '50%',
      '::before': {
        content: '""',
        height: '16px',
        width: '16px',
        border: '1px solid',
        borderColor: active ? 'primary' : 'gray.1',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    }}
  />
)

const OrderFieldWayOfTransport: FC<OrderFieldWayOfTransportProps> = ({
  method,
  deliveryTerm,
  priority,
  hazardousMaterialsAllowed,
  transportCarrier,
  firstCutOff,
  firstTransport,
  nextCutOff,
  nextTransport,
  isSelectedOption,
  transportId,
  onSelect,
}) => {
  const { space } = useTheme()
  const { dateFormatter } = useContext(LocalizationContext)
  const [showMore, setShowMore] = useState(false)

  const formatDate = useCallback(
    (displayDate?: string) =>
      dateFormatter(parseDate(displayDate), {
        hour: '2-digit',
        minute: '2-digit',
      }),
    [dateFormatter]
  )

  const updateWayOfTransport = useCallback(() => {
    if (transportId) {
      onSelect(transportId)

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CHANGE_SHIPPING_INFO,
        shipping_tier: `${method} (${transportId})`,
      })
    }
  }, [onSelect, method, transportId])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        borderRadius: 'default',
        border: '1px solid',
        borderColor: 'gray.1',
        paddingX: 4,
        paddingY: 3,
      }}
      data-t-id={`transport-option-${method}-${deliveryTerm}`}
    >
      <ClickableWrapper
        onClick={updateWayOfTransport}
        data-t-id={`update-transport-${method}-${deliveryTerm}`}
        sx={{
          display: 'flex',
          paddingBottom: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          ':hover > span': {
            backgroundColor: isSelectedOption ? 'primary' : 'gray.1',
          },
          ...getDividerSx('bottom'),
        }}
      >
        <Text
          variant="heading3"
          sx={{
            color: 'gray.3',
          }}
        >
          {method}
        </Text>

        <Radio active={isSelectedOption} />
      </ClickableWrapper>

      <Box
        sx={{
          paddingTop: 3,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '3fr 4fr',
            gap: `0 ${space[3]}px`,
          }}
        >
          <LabelValue label={i18next.t('shoppingLabels.deliveryLabels.delivery')}>
            {deliveryTerm}
          </LabelValue>

          <LabelValue label={i18next.t('shoppingLabels.deliveryLabels.priority')}>
            {priority}
          </LabelValue>
        </Box>

        <Box
          sx={{
            display: showMore ? 'grid' : 'none',
            gridTemplateColumns: '3fr 4fr',
            gap: `0 ${space[3]}px`,
          }}
        >
          <LabelValue
            method={method}
            deliveryTerm={deliveryTerm}
            label={i18next.t('shoppingLabels.deliveryLabels.carrier')}
          >
            {transportCarrier}
          </LabelValue>

          <LabelValue
            method={method}
            deliveryTerm={deliveryTerm}
            label={i18next.t('shoppingLabels.deliveryLabels.firstCutoff')}
          >
            {formatDate(firstCutOff)}
          </LabelValue>

          <LabelValue
            method={method}
            deliveryTerm={deliveryTerm}
            label={i18next.t('shoppingLabels.deliveryLabels.firstTransport')}
          >
            {formatDate(firstTransport)}
          </LabelValue>

          <LabelValue
            method={method}
            deliveryTerm={deliveryTerm}
            label={i18next.t('shoppingLabels.deliveryLabels.nextCutoff')}
          >
            {formatDate(nextCutOff)}
          </LabelValue>

          <LabelValue
            label={i18next.t('shoppingLabels.deliveryLabels.nextTransport')}
          >
            {formatDate(nextTransport)}
          </LabelValue>

          <Text
            color={COLORS.GRAY}
            sx={{ fontSize: 1, marginBlockStart: space[3], gridColumn: '1 / -1' }}
            data-t-id={`HWA-${method}-${deliveryTerm}`}
          >
            {hazardousMaterialsAllowed
              ? i18next.t('shoppingLabels.deliveryLabels.hazardousMaterialsAllowed')
              : i18next.t(
                  'shoppingLabels.deliveryLabels.hazardousMaterialsNotAllowed'
                )}
          </Text>
        </Box>

        <Text
          variant="bodySmall"
          sx={{ color: 'primary', marginBlockStart: space[2] }}
        >
          <ClickableWrapper
            onClick={() => setShowMore((current) => !current)}
            data-t-id="transport-option-more-details"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {i18next.t(showMore ? 'commonLabels.showLess' : 'commonLabels.showMore')}

            <IconWrapper
              icon={showMore ? ICONS.ARROW_UP : ICONS.ARROW_DOWN}
              size={3}
              color="currentcolor"
            />
          </ClickableWrapper>
        </Text>
      </Box>
    </Flex>
  )
}

export default OrderFieldWayOfTransport
