import React, { forwardRef, PropsWithChildren, useMemo, useState } from 'react'
import { Box } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { FormInputBaseProps } from './FormInputBase'
import { getBorderColor, getTextColor } from '../../../helpers/formInputHelper'
import IconWrapper from '../IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'

export interface FormInputSelectOptionProps {
  value: string
  text?: string
  selected?: boolean
  disabled?: boolean
}

export interface FormInputSelectProps extends FormInputBaseProps {
  options: FormInputSelectOptionProps[]
  placeholder?: string
}

const renderOptions = (options: FormInputSelectOptionProps[]) =>
  options.map(({ value, disabled, text }) => (
    <option key={value} value={value} disabled={disabled}>
      {text || value}
    </option>
  ))

const FormInputSelect = forwardRef<
  HTMLSelectElement,
  PropsWithChildren<FormInputSelectProps & { initialValue?: string }>
>(
  (
    {
      disabled = false,
      error = false,
      options = [],
      placeholder = '',
      initialValue = '',
      ...rest
    },
    ref: React.Ref<HTMLSelectElement>
  ) => {
    const { colors } = useTheme()
    const [focused, setFocused] = useState(false)

    const selectedOption = useMemo(() => {
      if (initialValue) {
        return options.find((option) => option.value === initialValue)
      }
      return options.find((option) => option.selected)
    }, [options, initialValue])

    return (
      <Box sx={{ position: 'relative' }}>
        <Box
          as="select"
          ref={ref}
          sx={{
            width: '100%',
            appearance: 'none',
            backgroundColor: 'transparent',
            outline: 0,
            font: 'inherit',
            fontSize: 1,
            lineHeight: 'buttonText',
            color: getTextColor(error, disabled),
            borderWidth: 1,
            borderColor: getBorderColor(error, focused),
            borderStyle: 'solid',
            borderRadius: 'default',
            transition: 'ease all 200ms',
            paddingRight: 6,
            paddingLeft: 3,
            py: 2,
          }}
          {...rest}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          defaultValue={selectedOption?.value}
          disabled={disabled}
        >
          {placeholder && <option hidden>{placeholder}</option>}
          {renderOptions(options)}
        </Box>

        <Box
          as="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
            position: 'absolute',
            right: 3,
            top: 0,
            width: 'auto',
            height: '100%',
            color: disabled ? colors.disabled : colors.gray[3],
          }}
        >
          <IconWrapper icon={ICONS.ARROW_DOWN} size={3} />
        </Box>
      </Box>
    )
  }
)

export default FormInputSelect
