import { Action } from 'redux'
import { SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_NUMBER } from '../../../constants/shoppingBasketConstants'

export interface SetCustomerOrderNumber
  extends Action<typeof SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_NUMBER> {
  payload: string
}

export const setCustomerOrderNumber = (payload: string) => ({
  type: SHOPPING_BASKET_WIZARD_SET_CUSTOMER_ORDER_NUMBER,
  payload,
})
