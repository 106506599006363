import React, { FC, useContext, useEffect } from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { RenderingProps } from '../../../types/layoutService'
import { SearchContext } from '../../../providers/SearchContextProvider'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'

interface SearchResultsNoResultsProps {
  rendering: RenderingProps
}

const SearchResultsNoResults: FC<SearchResultsNoResultsProps> = ({ rendering }) => {
  const { searchString, category, subcategory } = useContext(SearchContext)

  useEffect(() => {
    const currentCategory = subcategory || category

    if (searchString || currentCategory) {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_FUNCTION,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.SEARCH_BAR,
        search_query: searchString || currentCategory?.description,
        page_type: 'no results',
      })
    }
    // searchString & subcategory & category ara available on page load and wont change during the page lifecycle
  }, [])

  return <Placeholder name="jss-no-search-results" rendering={rendering} />
}

export default SearchResultsNoResults
