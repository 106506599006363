import React, { FC, useContext } from 'react'
import { Box, Text } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { parseDate } from '../../../helpers/dateHelper'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { Datasource, NewsArticle } from '../../../types/layoutService'
import Banner, { DEFAULT_BANNER_VARIANT } from '../../atoms/Banner/Banner'
import BannerImage from '../../atoms/Banner/BannerImage'
import Container from '../../atoms/Container/Container'
import RichText from '../../atoms/RichText/RichText'

interface NewsArticleDetailProps {
  article?: NewsArticle
}

const NewsArticleDetail: FC<NewsArticleDetailProps & Datasource> = ({ article }) => {
  const { dateFormatter } = useContext(LocalizationContext)

  if (!article) {
    return null
  }

  const { body, title, image, displayDate } = article

  const formattedDisplayDate = dateFormatter(
    parseDate(displayDate),
    {
      dateStyle: 'full',
      timeStyle: undefined,
    },
    true
  )

  return (
    <>
      <Banner bg="primary" bottomSpacing="1">
        {!!image?.src && (
          <BannerImage variant={DEFAULT_BANNER_VARIANT} imageField={image} />
        )}
      </Banner>

      <Container>
        <Box
          sx={{
            pr: [0, 0, 3],
            width: ['100%', '100%', '100%', '50%'],
            mb: [4, 4, 7],
          }}
        >
          <Text
            color="gray.2"
            sx={{
              fontSize: 2,
            }}
            mb={4}
          >
            {formattedDisplayDate}
          </Text>

          <Text as="h1" variant="heading1" mb={5}>
            {title}
          </Text>

          <RichText
            textField={{
              value: '',
              jsonValue: {
                value: body,
              },
            }}
          />
        </Box>
      </Container>
    </>
  )
}

export default withData(NewsArticleDetail, {
  checkDatasource: false,
  showMessageWhenPageEditing: false,
})
