import React, { FC } from 'react'
import {
  ApiVariant,
  Datasource,
  Flag,
  LinkField,
  Params,
  SliderVariant,
  TextField,
} from '../../../types/layoutService'
import ContentSlider from '../../organisms/ContentSlider/ContentSlider'
import PartCard from '../../molecules/Card/PartCard/PartCard'
import { RecommendedPartSliderProps } from '../../organisms/RecommendedPartSlider/RecommendedPartSlider'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { SlidesPerPage } from '../../../hooks/slider/useSlider'
import { SLIDES_PER_PAGE } from '../../organisms/CardList/PartCardListResponsive'

interface PartSliderSkeletonDatasource {
  title: TextField
  link?: LinkField
}

interface PartSliderSkeletonParams {
  variant: SliderVariant
  apiVariant: ApiVariant
  topSpacing: Flag
  bottomSpacing: Flag
}

const PartSliderSkeleton: FC<
  Datasource<PartSliderSkeletonDatasource> &
    RecommendedPartSliderProps &
    Params<PartSliderSkeletonParams>
> = ({ params: { variant, topSpacing, bottomSpacing }, datasource: { title } }) => (
  <ContentSlider
    slidesPerPage={SLIDES_PER_PAGE[variant] as SlidesPerPage}
    title={getTextFieldValue(title)}
    topSpacing={topSpacing === '1'}
    bottomSpacing={bottomSpacing === '1'}
  >
    {[...Array(SLIDES_PER_PAGE[variant])].map((_, i) => (
      <PartCard.Skeleton key={i.toString()} />
    ))}
  </ContentSlider>
)

export default PartSliderSkeleton
