import { Action } from 'redux'
import {
  SHOPPING_BASKET_SIDE_PANEL_REFRESH,
  SHOPPING_BASKET_SIDE_PANEL_SET_ODER_SUMMARY,
  SHOPPING_BASKET_SIDE_PANEL_SET_STATE,
  SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL,
} from '../../constants/shoppingBasketConstants'
import { CommerceOrderSummary } from '../../types/commerceApi'

interface ShoppingBasketSidePanelBasketAction
  extends Action<typeof SHOPPING_BASKET_SIDE_PANEL_REFRESH> {
  payload?: boolean
}

interface ShoppingBasketSidePanelDataAction
  extends Action<typeof SHOPPING_BASKET_SIDE_PANEL_SET_ODER_SUMMARY> {
  payload?: CommerceOrderSummary
}

interface ShoppingBasketSidePanelState
  extends Action<typeof SHOPPING_BASKET_SIDE_PANEL_SET_STATE> {
  payload: boolean
}

interface ShoppingBasketEditItemSidePanelState
  extends Action<typeof SHOPPING_BASKET_WIZARD_SET_EDIT_ITEM_SIDE_PANEL> {
  payload: {
    active: boolean
    partNumber: string
  }
}

export type ShoppingBasketSidePanelActions =
  | ShoppingBasketSidePanelBasketAction
  | ShoppingBasketSidePanelDataAction
  | ShoppingBasketSidePanelState
  | ShoppingBasketEditItemSidePanelState

export const refetchShoppingBasketSidepanelSummary = (
  payload: ShoppingBasketSidePanelBasketAction['payload']
) => ({
  type: SHOPPING_BASKET_SIDE_PANEL_REFRESH,
  payload,
})

export const setShoppingBasketSidePanelOrderSummary = (
  payload: CommerceOrderSummary
) => ({
  type: SHOPPING_BASKET_SIDE_PANEL_SET_ODER_SUMMARY,
  payload,
})

export const setShoppingBasketSidePanelState = (payload = false) => ({
  type: SHOPPING_BASKET_SIDE_PANEL_SET_STATE,
  payload,
})
