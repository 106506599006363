import React, { FC } from 'react'
import { Flex, FlexProps } from 'theme-ui'

const SearchControlsContainer: FC<Omit<FlexProps, 'css'>> = ({
  sx,
  children,
  ...flexProps
}) => (
  <Flex
    sx={{
      alignItems: ['baseline', 'baseline', 'baseline', 'center'],
      mb: [3, 3, 5],
      flexDirection: ['column', 'column', 'row'],
      ...sx,
    }}
    {...flexProps}
  >
    {children}
  </Flex>
)

export default SearchControlsContainer
