import { combineReducers } from 'redux'
import anchorMenuReducer from './anchorMenuReducer'
import navigationReducer from './navigationReducer'
import shoppingBasketSidePanelReducer from './shoppingBasket/sidePanelReducer'
import notificationReducer from './notificationReducer'
import tooltipReducer from './tooltipReducer'
import shoppingBasketWizardReducer from './shoppingBasket/wizard/index'
import digitalMagazineReducer from './digitalMagazineReducer'
import dafCompanyVehicleReducer from './dafCompanyVehicleReducer'
import toastsReducer from './toastsReducer'
import r2cReducer from './r2cReducer'

const rootReducer = combineReducers({
  anchorMenu: anchorMenuReducer,
  digitalMagazine: digitalMagazineReducer,
  navigation: navigationReducer,
  shoppingBasketSidePanel: shoppingBasketSidePanelReducer,
  notifications: notificationReducer,
  tooltipList: tooltipReducer,
  shoppingBasketWizard: shoppingBasketWizardReducer,
  dafCompanyVehicle: dafCompanyVehicleReducer,
  toasts: toastsReducer,
  r2c: r2cReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
