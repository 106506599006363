import { useCallback, useMemo, useState } from 'react'

const FIRST_PAGE_INDEX = 0

type SetPageNumber = (pageNumber: number) => boolean
type DecrementPageNumber = () => boolean
type IncrementPageNumber = () => boolean

export interface PaginationState {
  pageCount: number
  currentPageIndex: number
  isFirstPage: boolean
  isLastPage: boolean
}

export interface PaginationOptions {
  pageCount: number
}

export type PaginationTuple = [
  SetPageNumber,
  DecrementPageNumber,
  IncrementPageNumber,
  PaginationState
]

const usePagination = ({ pageCount }: PaginationOptions): PaginationTuple => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(FIRST_PAGE_INDEX)

  const paginationState = useMemo<PaginationState>(
    () => ({
      pageCount,
      currentPageIndex,
      isFirstPage: currentPageIndex === FIRST_PAGE_INDEX,
      isLastPage: currentPageIndex === pageCount - 1,
    }),
    [currentPageIndex, pageCount]
  )

  const decrementPageNumber = useCallback((): boolean => {
    if (currentPageIndex > FIRST_PAGE_INDEX) {
      setCurrentPageIndex(currentPageIndex - 1)
      return true
    }
    return false
  }, [currentPageIndex])

  const incrementPageNumber = useCallback((): boolean => {
    if (currentPageIndex < pageCount - 1) {
      setCurrentPageIndex(currentPageIndex + 1)
      return true
    }
    return false
  }, [currentPageIndex, pageCount])

  const setPageNumber = useCallback(
    (pageNumber: number): boolean => {
      if (pageNumber >= FIRST_PAGE_INDEX && pageNumber <= pageCount - 1) {
        setCurrentPageIndex(pageNumber)
        return true
      }
      return false
    },
    [pageCount]
  )

  return [setPageNumber, decrementPageNumber, incrementPageNumber, paginationState]
}

export default usePagination
