import React, { useCallback } from 'react'
import i18next from 'i18next'
import { TextField } from './TextField'
import { formatNumber } from '../../../helpers/numberFormatHelper'
import toCamelCase from '../../../helpers/camelCaseHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { minimumInputValidation, required } from '../../../helpers/formFieldHelper'

interface InputMileageTextFieldProps {
  label: string
  name: string
  disabled?: boolean
  id?: string
  min?: number
  value?: string
}

type ValidatorFn = (value: string) => string | undefined

export const InputMileageTextField = ({
  label = '',
  name = '',
  disabled = false,
  min = 0,
  value,
  ...rest
}: InputMileageTextFieldProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const i18nFormErrorFormatter = useCallback(
    (
      ruleName: string,
      args?: Record<string, any> // eslint-disable-line
    ) => i18next.t(`commonFormLabels.${toCamelCase(ruleName)}`, args),
    []
  )

  const requiredValidation = useCallback(
    (val: string) => required(i18nFormErrorFormatter)(val),
    [i18nFormErrorFormatter]
  )

  const minimumInputValueValidation = useCallback(
    (val: string) => minimumInputValidation(min, cultureCode)(val),
    [cultureCode, min]
  )

  const composeValidators =
    (...validators: ValidatorFn[]): ValidatorFn =>
    (val) =>
      validators.reduce(
        (error: string | undefined, validator: ValidatorFn) =>
          error || validator(val),
        undefined
      )

  const parseValue = (val: string) => Number(val.replace(/\D/g, ''))

  const formatValue = (val: number) => {
    if (!val) return ''
    return formatNumber(Number(val), cultureCode) || val.toString()
  }

  return (
    <TextField
      value={value}
      name={name}
      parse={parseValue}
      format={formatValue}
      disabled={disabled}
      suffix={i18next.t('myFleet.kilometersAbbr')}
      validate={composeValidators(requiredValidation, minimumInputValueValidation)}
      type="text"
      label={label}
      placeholder={`${i18next.t('commonLabels.forExample')} ${formatNumber(
        10000,
        cultureCode
      )}`}
      {...rest}
    />
  )
}
