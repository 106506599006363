export const INVOICE_STATE_ON_HOLD = 'On Hold'
export const SERVICEPLAN_CONTRACT_STATE_EXPIRED = 'Expired'
export const SERVICEPLAN_CONTRACT_STATE_EXPIRING = 'Expiring'
export const SERVICEPLAN_CONTRACT_STATE_ON_HOLD = 'On Hold'
export const SERVICEPLAN_CONTRACT_STATE_CANCELED = 'Canceled'
export const SERVICEPLAN_CONTRACT_STATE_ACTIVE = 'Active'
export const SERVICEPLAN_CONTRACT_STATE_CLOSED = 'Closed'
export const MAINTENANCE_RECALL = 'Recall'
export const VEHICLE_UPGRADE_WARNING = 'Warning'
export const VEHICLE_UPGRADE_IMPORTANT = 'Important'
export const VEHICLE_UPGRADE_ATTENTION = 'Attention'
export const TASK_VARIANT_URGENT = 'urgent'
export const TASK_VARIANT_OK = 'ok'
export const TASK_VARIANT_URGENT_LARGE = 'urgentLarge'
export const TASK_VARIANT_WARNING = 'warning'
export const VEHICLES_PER_PAGE = 21
export const MY_FLEET_LIST_URL = 'my-fleet'
export const MY_FLEET_LIST_SORT_FIRST_REGISTRATION_DATE = 'firstRegistrationDate'
export const MY_FLEET_LIST_SORT_VEHICLE_ACTION_STATUS = 'vehicleActionStatus'
export const MY_FLEET_LIST_SORT_VEHICLE_MAINTENANCE_URGENCY_STATUS =
  'vehicleMaintenanceUrgencyStatus'
export const MY_FLEET_LIST_SORT_MILEAGE = 'mileage'
export const MY_FLEET_LIST_SORT_MAIN_SERVICE_DEALER = 'mainServiceDealer'
export const MY_FLEET_LIST_SORT_SERVICE_COVERAGE = 'serviceCoverage'
export const MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE = 'contractState'
export const MY_FLEET_LIST_FILTER_OPTION_MAINTENANCE = 'maintenance'
export const MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE = 'Overdue'
export const MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH = '0-1'
export const MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS = '1-2'
export const TOGGLE_ADD_VEHICLE_PANEL = 'Panels/TOGGLE_ADD_VEHICLE_PANEL'
export const TOGGLE_MY_FLEET_LIST_FILTER_PANEL =
  'Panels/TOGGLE_MY_FLEET_LIST_FILTER_PANEL'
export const TOGGLE_EDIT_SERVICE_PLAN_FORM_PANEL =
  'Panels/TOGGLE_EDIT_SERVICE_PLAN_FORM_PANEL'
export const TOGGLE_MILEAGE_SETTLEMENT_INPUT_FORM_PANEL =
  'Panels/TOGGLE_MILEAGE_SETTLEMENT_INPUT_FORM_PANEL'
export const TOGGLE_MAINTENANCE_INPUT_FORM_PANEL =
  'Panels/TOGGLE_MAINTENANCE_INPUT_FORM_PANEL'
export const TOGGLE_MARK_MAINTENANCE_AS_PERFORMED_PANEL =
  'Panels/TOGGLE_MARK_MAINTENANCE_AS_PERFORMED_PANEL'
export const TOGGLE_EDIT_VEHICLE_PANEL = 'Panels/TOGGLE_EDIT_VEHICLE_PANEL'
export const TOGGLE_DELETE_VEHICLE_PANEL = 'Panels/TOGGLE_DELETE_VEHICLE_PANEL'
export const TOGGLE_END_MAINTENANCE_PLAN_PANEL =
  'Multisupport/TOGGLE_END_MAINTENANCE_PLAN_PANEL'
export const TOGGLE_MAINTENANCE_APPOINTMENT_PANEL =
  'Maintenance/TOGGLE_MAINTENANCE_APPOINTMENT_PANEL'
export const TOGGLE_REQUEST_QUOTE_PANEL = 'Panels/TOGGLE_REQUEST_QUOTE_PANEL'
export const PUSH_TO_DAF_COMPANY_VEHICLE = 'Multisupport/PUSH_TO_DAF_COMPANY_VEHICLE'
export const PUSH_TO_DAF_SERVICE_DEALER = 'Multisupport/PUSH_TO_DAF_SERVICE_DEALER'
export const PUSH_TO_DAF_VEHICLE_RM_DOCUMENTS =
  'Panels/PUSH_TO_DAF_VEHICLE_RM_DOCUMENTS'
export const VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB = 'activeTab'
export const VEHICLE_DETAIL_SERVICEPLAN_TAB = 'servicePlan'
export const VEHICLE_DETAIL_MAINTENANCE_TAB = 'maintenance'
export const VEHICLE_DETAIL_FINANCE_TAB = 'finance'
export const VEHICLE_SERVICES_NO_SERVICES = 'No services'
export const VEHICLE_SERVICES_NON_MULTISUPPORT = 'MP'
export const VEHICLE_DETAIL_END_TYPE_DATE = 'EndDate'
export const FILTER_GROUP_SERVICE_PLANS = 'contractState'
export const FILTER_GROUP_FINANCE = 'finance'
export const FILTER_GROUP_VEHICLE_ACTION_STATUS = 'vehicleActionStatus'
export const FILTER_GROUP_MAINTENANCE = 'maintenance'
export const FILTER_GROUP_VEHICLE_UPGRADES = 'vehicleUpgrade'
export const VEHICLE_DETAIL_KM_ABBREVIATION = 'km'
export const DUE_IN_ONE_TO_TWO_MONTHS = '1-2'
export const DUE_IN_ZERO_TO_ONE_MONTH = '0-1'
export const OVERDUE = 'Overdue'
export const RECALL_TYPE_PSFA = 'PSFA'
export const RECALL_TYPE_SR = 'SR'
export const RECALL_TYPE_SFA = 'SFA'
export const VEHICLE_MILEAGE_SETTLEMENT_INPUT_STATE_OPEN = 'Open'
export const MILEAGE_INPUT_STATE_OPEN = 'Open'
export const MILEAGE_SETTLEMENT_TYPE_INCUSTOMERDEFINEDMONTH =
  'InCustomerDefinedMonth'
export const MILEAGE_SETTLEMENT_TYPE_ATCONTRACTANNIVERSARY = 'AtContractAnniversary'
export const MILAGE_SETTLEMENT_TYPE_NOSETTLEMENT = 'NoSettlement'
export const MILAGE_SETTLEMENT_TYPE_PAYASYOUDRIVE = 'PayAsYouDrive'
export const START_DATE_TWO_INDEXATION_MONTHS = 'StartDateTwoIndexationMonths'
export const AT_CONTRACT_ANNIVERSARY = 'AtContractAnniversary'
export const DIRECT_DEBIT = 'DirectDebit'
export const CURRENT_ACCOUNT = 'CurrentAccount'
export const LUMPSUM = 'LumpSum'
export const MONTHLY = 'Monthly'
export const UPDATE_CHECKED_JOBS = 'UpdateCheckedJobs'
export const CONNECTED_VEHICLE = 'ConnectedVehicle'
export const ACTIVE_CONTRACT = 'ActiveContract'
export const CONNECTED_VEHICLE_AND_ACTIVE_CONTRACT =
  'ConnectedVehicleAndActiveContract'
