import React, { FC, useCallback, useContext } from 'react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { CommerceOrderItem } from '../../../types/commerceApi'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { setEditPartListLineSidePanel } from '../../../actions/shoppingBasket/wizard'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleR2CJobsheetSidepanel,
  toggleR2CTaskSidepanel,
} from '../../../actions/r2cActions'
import { RootState } from '../../../reducers'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'

interface PartListLineReferenceProps {
  part: CommerceOrderItem
}

const PartListLineReference: FC<PartListLineReferenceProps> = ({ part }) => {
  const dispatch = useDispatch()
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)

  const addOrderItemReferenceClick = useCallback(() => {
    if (order?.isR2CEnabled && !isImpersonated && !order?.externalReference?.value) {
      dispatch(toggleR2CJobsheetSidepanel(true))
    } else if (
      order?.isR2CEnabled &&
      !isImpersonated &&
      order?.externalReference?.value
    ) {
      dispatch(toggleR2CTaskSidepanel(true, part?.partNumber))
    } else {
      dispatch(setEditPartListLineSidePanel(true, part.partNumber))
    }

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.ADD_NOTE,
      status: DATA_LAYER.CUSTOM_DIMENSION.STATUS.INTENTION,
      value: '',
    })
  }, [dispatch, isImpersonated, order, part])

  return (
    <>
      <ClickableWrapper
        onClick={addOrderItemReferenceClick}
        data-t-id="shopping-basket-add-order-item-reference"
        sx={{ color: 'primary' }}
      >
        <IconWrapper
          icon={ICONS.PENCIL}
          size={4}
          color="currentColor"
          showDot={
            !!part.lineReference ||
            !!part.externalOrderItemReference ||
            !!part.vehicleReference?.value ||
            !!part.price?.quotationUnitPrice
          }
        />
      </ClickableWrapper>
    </>
  )
}

export default PartListLineReference
