import React, { ReactNode, useContext, useMemo } from 'react'
import { DEFAULT_PART_QUANTITY } from '../../../constants/partSalesInformationConstants'
import PartListSalesInformationProvider from '../../../providers/PartListSalesInformationProvider'
import { PartSearchContext } from '../../../providers/PartSearchContextProvider'
import { PartSearchResultContext } from '../../../providers/PartSearchResultContextProvider'
import { TextField } from '../../../types/layoutService'
import PartSearchInvalidFilter from './PartSearch/PartSearchInvalidFilter'

interface SearchResultsLoadedPartResultsProps {
  noResultsLabel: TextField
  invalidFilterCombinationLabel: TextField
  removeFiltersLabel: TextField
  children: ReactNode
}

const SearchResultsLoadedPartResults = ({
  noResultsLabel,
  invalidFilterCombinationLabel,
  removeFiltersLabel,
  children,
}: SearchResultsLoadedPartResultsProps) => {
  const { statistics } = useContext(PartSearchContext)
  const { parts } = useContext(PartSearchResultContext)

  const invalidFilterSupplied =
    statistics?.totalCount && statistics.totalCount > 0 && parts?.length === 0

  const partNumbersQuantity = useMemo(
    () =>
      (!invalidFilterSupplied &&
        parts &&
        parts.map((part) => ({
          partNumber: part?.searchPart?.partNumber,
          quantity:
            part?.part?.specification?.packaging?.packageUnitQuantity ??
            DEFAULT_PART_QUANTITY,
        }))) ||
      [],
    [invalidFilterSupplied, parts]
  )

  if (invalidFilterSupplied)
    return (
      <PartSearchInvalidFilter
        noResultsLabel={noResultsLabel}
        invalidFilterCombinationLabel={invalidFilterCombinationLabel}
        removeFiltersLabel={removeFiltersLabel}
      />
    )

  return (
    <PartListSalesInformationProvider
      includePrice
      includeStock
      partNumbersQuantity={partNumbersQuantity}
    >
      {children}
    </PartListSalesInformationProvider>
  )
}

export default SearchResultsLoadedPartResults
