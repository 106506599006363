import React, { useCallback } from 'react'
import { DATA_LAYER } from '../../../../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../../../../helpers/analyticsHelper'
import Button from '../../../../../atoms/Button/Button'
import {
  FILTER_GROUP_TYPE_CHECKBOX,
  FILTER_GROUP_TYPE_RADIOBUTTON,
} from '../../../../../../constants/searchConstants'

interface PartSearchFilterChipProps {
  label: string
  englishDescription: string
  selectionCount?: number
  onClick: () => void
  filterType?:
    | typeof FILTER_GROUP_TYPE_CHECKBOX
    | typeof FILTER_GROUP_TYPE_RADIOBUTTON
}

const PartSearchFilterChip = ({
  label,
  englishDescription,
  selectionCount = 0,
  onClick,
  filterType = FILTER_GROUP_TYPE_CHECKBOX,
}: PartSearchFilterChipProps) => {
  const handleClick = useCallback(() => {
    onClick()

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FILTER,
      filter_action: DATA_LAYER.CUSTOM_DIMENSION.FILTER_ACTION.VIEW,
      filter_type: DATA_LAYER.CUSTOM_DIMENSION.FILTER_TYPE.TOP_THREE,
      filter_name: englishDescription,
      filter_value: '',
    })
    // dependencies (englishDescription & partContext) are available on page load and will not change during the lifecycle of the component
  }, [onClick])

  return (
    <Button
      variant={selectionCount >= 1 ? 'primary' : 'outline'}
      sx={{ mr: 2, mb: 2, display: 'inline-block', ':last-child': { mr: 0 } }}
      onClick={handleClick}
    >
      {selectionCount && filterType === FILTER_GROUP_TYPE_CHECKBOX
        ? `${label}: ${selectionCount}`
        : label}
    </Button>
  )
}

export default PartSearchFilterChip
