import { PartsNavigationActions } from '../actions/navigationActions'
import {
  CLOSE_MOBILE_NAVIGATION,
  CLOSE_PARTS_NAVIGATION,
  OPEN_MOBILE_NAVIGATION,
  OPEN_PARTS_NAVIGATION,
} from '../constants/partsNavigationConstants'

export interface NavigationState {
  partsNavigationOpen: boolean
  mobileNavigationOpen: boolean
}

const navigationReducer = (
  state: NavigationState = {
    partsNavigationOpen: false,
    mobileNavigationOpen: false,
  },
  action: PartsNavigationActions
): NavigationState => {
  switch (action.type) {
    case OPEN_PARTS_NAVIGATION: {
      return {
        ...state,
        partsNavigationOpen: true,
      }
    }

    case CLOSE_PARTS_NAVIGATION: {
      if (!state.partsNavigationOpen) return state

      return {
        ...state,
        partsNavigationOpen: false,
      }
    }

    case OPEN_MOBILE_NAVIGATION: {
      return {
        ...state,
        mobileNavigationOpen: true,
      }
    }

    case CLOSE_MOBILE_NAVIGATION: {
      if (!state.mobileNavigationOpen) return state

      return {
        ...state,
        mobileNavigationOpen: false,
      }
    }

    default: {
      return state
    }
  }
}

export default navigationReducer
