import React, { FC, useState } from 'react'
import { getTextFieldValue } from '../../../../../../helpers/layoutServiceHelper'
import { Category } from '../../../../../../hooks/services/graphql/useCategoryListService'
import { TextField } from '../../../../../../types/layoutService'
import ModalAnchor from '../../../../Modal/ModalAnchors/ModalAnchor'
import SearchCategoryListModal from '../../../../Modal/ModalVariants/SearchCategoryListModal'

interface PartSearchControlsSubcategoryListModalAnchorProps {
  title: TextField
  categoriesTree: Category[][]
  callback?: () => void
}

const PartSearchControlsSubcategoryListModalAnchor: FC<
  PartSearchControlsSubcategoryListModalAnchorProps
> = ({ title, categoriesTree, callback }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ModalAnchor
        label={getTextFieldValue(title)}
        onClick={() => setModalOpen(true)}
      />

      <SearchCategoryListModal
        isActive={modalOpen}
        onClose={() => setModalOpen(false)}
        title={getTextFieldValue(title)}
        categoriesTree={categoriesTree}
        callback={callback}
      />
    </>
  )
}

export default PartSearchControlsSubcategoryListModalAnchor
