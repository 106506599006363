import React, { FC, ReactNode } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { PADDING } from '../../../constants/containerConstants'

interface ConditionalContainerProps {
  removeHorizontalSpacing?: boolean
  condition: boolean
  children: ReactNode
}

interface ContainerProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  removeHorizontalSpacing?: boolean
}

export const ConditionalContainer: FC<ConditionalContainerProps> = ({
  condition,
  children,
  ...containerProps
}) => (
  <>{condition ? <Container {...containerProps}>{children}</Container> : children}</>
)

const Container: FC<ContainerProps> = ({
  children,
  sx,
  removeHorizontalSpacing = false,
  ...manipulatedProps
}) => {
  const { breakpoints } = useTheme()

  const paddingX = removeHorizontalSpacing
    ? 0
    : [PADDING.MOBILE, PADDING.MOBILE, PADDING.TABLET, PADDING.DESKTOP]

  return (
    <Box
      sx={{
        paddingX,
        maxWidth: breakpoints,
        margin: '0 auto',
        width: '100%',
        ...sx,
      }}
      {...manipulatedProps}
    >
      {children}
    </Box>
  )
}

export default Container
