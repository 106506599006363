import { useState, useLayoutEffect, useCallback } from 'react'
import { getWindow, runOnWindow } from '../helpers/dom'
import useDebounce from './useDebounce'

const getSizeObject = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
})

export default (threshold = 300) => {
  if (!getWindow()) return { width: 0, height: 0 }

  const [windowSize, setWindowSize] = useState(getSizeObject())
  const debouncedWindowSize = useDebounce(windowSize, threshold)

  const handleResize = useCallback(() => setWindowSize(getSizeObject()), [])

  useLayoutEffect(() => {
    handleResize()

    runOnWindow(() => window.addEventListener('resize', handleResize))

    return () => {
      runOnWindow(() => window.removeEventListener('resize', handleResize))
    }
  }, [handleResize])

  return debouncedWindowSize
}
