import React, { FC } from 'react'
import { Datasource, TextField } from '../../../types/layoutService'
import { Image } from '../../../types/dafResponseProps'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import withData from '../../../enhancers/withData'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { PartContext } from '../../../types/sitecoreContextProps'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'
import PromotionStickerAsync from '../../atoms/PromotionSticker/PromotionStickerAsync'

interface PartImagesCarouselDatasource {
  title: TextField
}

interface PartImagesCarouselProps {
  images: Image[]
  technicalDrawings: Image[]
}

const PartImagesCarousel: FC<
  Datasource<PartImagesCarouselDatasource> & PartImagesCarouselProps
> = ({
  rendering: { componentName, uid },
  datasource: { title },
  images,
  technicalDrawings,
}) => {
  const { part } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  return (
    <PartSalesInformationProvider partNumber={part?.partNumber}>
      <PromotionStickerAsync variant="xl">
        <ImageCarousel
          title={title}
          images={images}
          additionalImages={technicalDrawings}
          componentName={componentName}
          uid={uid}
        />
      </PromotionStickerAsync>
    </PartSalesInformationProvider>
  )
}

export default withData(PartImagesCarousel)
