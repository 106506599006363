import React, { FC, useContext } from 'react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import withData from '../../../enhancers/withData'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { UserContext } from '../../../providers/UserProvider'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import Button from '../../atoms/Button/Button'
import ButtonGroupWrapper from '../../atoms/ButtonGroupWrapper/ButtonGroupWarpper'
import SitecoreButton from '../SitecoreButton/SitecoreButton'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'

interface LoginOrRegisterDatasource {
  loginLabel: TextField
  createAccountButton: LinkField
  hybridLoginPage: LinkField
}

const LoginOrRegister: FC<Datasource<LoginOrRegisterDatasource>> = ({
  datasource: { loginLabel, createAccountButton, hybridLoginPage },
}) => {
  const { userProfile } = useContext(UserContext)
  const { pageEditing, classicUrl, absoluteUrl } = useSitecoreContext()

  if (userProfile || hybridLoginPage?.url === null) return null

  const isAbsoluteUri =
    hybridLoginPage.url.indexOf('http://') === 0 ||
    hybridLoginPage.url.indexOf('https://') === 0

  const hybridLoginUri = isAbsoluteUri
    ? `${classicUrl}/auth0/Prelogin?returnUrl=${hybridLoginPage.url}`
    : `${classicUrl}/auth0/Prelogin?returnUrl=${absoluteUrl}${hybridLoginPage.url}`

  return (
    <ButtonGroupWrapper>
      <ClickableWrapper href={hybridLoginUri} as="a" sx={{ marginRight: 2 }}>
        <Button
          variant="primary"
          disabled={pageEditing}
          onClick={() =>
            pushToDataLayer({
              [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.LOGIN_ACCOUNT,
            })
          }
        >
          {getTextFieldValue(loginLabel)}
        </Button>
      </ClickableWrapper>

      <SitecoreButton linkField={createAccountButton} variant="outline" />
    </ButtonGroupWrapper>
  )
}

export default withData(LoginOrRegister)
