import React, { FC } from 'react'
import Image, { ImageProps } from '../../atoms/Image/Image'

const BasicInformationBrandImage: FC<ImageProps> = ({ src, alt, ...props }) => {
  if (!src) return null

  return (
    <Image
      data-t-id="part-brand"
      src={src}
      alt={alt}
      sx={{
        maxWidth: ['65px'],
      }}
      {...props}
    />
  )
}

export default BasicInformationBrandImage
