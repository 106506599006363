import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { Application } from '../../../types/partTypes'
import TableCell from '../../molecules/Table/TableCell'
import TableBody from '../../molecules/Table/TableBody'
import TableRow from '../../molecules/Table/TableRow'
import Table from '../../molecules/Table/Table'

interface PartApplicationTableProps {
  applications: Application[]
}

export const PartApplicationTable: FC<PartApplicationTableProps> = ({
  applications,
}) => {
  let lastType = ''

  if (!applications?.length) return null

  return (
    <Table columnSizes={[2, 1, 1]}>
      <TableBody>
        {applications.map(({ make, model, type }, i) => {
          const hideTypeOnMobile = lastType === type ? ['none', 'none', 'block'] : []

          lastType = type

          return (
            <TableRow key={i.toString()}>
              <TableCell>
                <Text
                  as="strong"
                  variant="bodyContent"
                  sx={{
                    display: hideTypeOnMobile,
                    fontWeight: 'bold',
                  }}
                  color="gray.3"
                >
                  {type}
                </Text>
              </TableCell>

              <TableCell>
                <Text as="span" variant="bodyContent">
                  {make}
                </Text>
              </TableCell>

              <TableCell>
                <Text
                  as="span"
                  variant="bodyContent"
                  sx={{
                    textAlign: ['right', 'right', 'left'],
                  }}
                >
                  {model}
                </Text>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default PartApplicationTable
