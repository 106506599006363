import i18next from 'i18next'
import { StockInformationVariant } from '../components/organisms/PartStockInformationList/PartStockInformationList'
import { ICONS } from '../constants/iconConstants'
import {
  DELIVERY_PRESCRIPTION_CODES,
  STOCK_LOCATION_ID_CONSIGNMENT,
  STOCK_LOCATION_ID_NO_STOCK_FOR_DEALER,
  STOCK_STATE,
} from '../constants/stockConstants'
import { COLORS } from '../constants/themeConstants'
import {
  StockInformation,
  StockLocation,
} from '../hooks/services/graphql/usePartSalesInformationService'
import { alphaColor } from './themeHelper'

export const getStockInformation = (
  partNumber?: string,
  stockInformations?: StockInformation[]
): StockInformation | undefined =>
  partNumber && stockInformations
    ? stockInformations.find((stock) => stock.partNumber === partNumber)
    : undefined

export const getSingleStockLocation = (
  stockInformation: StockInformation | undefined
): StockLocation | undefined => {
  if (stockInformation?.stockLocations.length) {
    if (stockInformation.stockLocations.length > 1) {
      return getFastestShippingLocation(stockInformation.stockLocations)
    }
    return stockInformation.stockLocations[0]
  }
  return undefined
}

const getFastestShippingLocation = (
  stockLocations: StockLocation[]
): StockLocation =>
  stockLocations.reduce((a, b) => {
    if (
      !!a.availabilityTime &&
      a.availabilityTime !== null &&
      !!b.availabilityTime &&
      b.availabilityTime !== null
    ) {
      return a.availabilityTime < b.availabilityTime ? a : b
    }
    return b
  })

export const getStockLocationList = (
  stockInformation: StockInformation | undefined
): StockLocation[] | undefined => {
  if (stockInformation?.stockLocations?.length) {
    if (stockInformation.stockLocations.length > 1) {
      return sortByAvailabilityTime(stockInformation.stockLocations)
    }
  }

  return stockInformation?.stockLocations
}

const sortByAvailabilityTime = (stockLocations: StockLocation[]): StockLocation[] =>
  stockLocations.sort((a, b) => {
    if (!!a.availabilityTime && !!b.availabilityTime) {
      if (a.availabilityTime < b.availabilityTime) {
        return -1
      }

      if (a.availabilityTime > b.availabilityTime) {
        return 1
      }
    }

    return 0
  })

export const getStockState = (
  stockLocation: StockLocation | undefined
): string | undefined => {
  if (!stockLocation) return undefined

  const {
    availabilityTime,
    locationId,
    consignmentStockInformation,
    deliveryPrescriptionCode,
  } = stockLocation

  if (
    deliveryPrescriptionCode === DELIVERY_PRESCRIPTION_CODES.DIRECT_SHIP_DELIVERY
  ) {
    return STOCK_STATE.DIRECT_SHIP_DELIVERY
  }

  // Contact
  if (availabilityTime === undefined || availabilityTime === null) {
    return STOCK_STATE.CONTACT
  }

  // Available in x hours
  if (availabilityTime > 0 && availabilityTime / 24 <= 1) {
    return STOCK_STATE.AVAILABLE_IN_X_HOURS
  }

  // Available in x days
  if (availabilityTime > 0 && availabilityTime / 24 > 1) {
    return STOCK_STATE.AVAILABLE_IN_X_DAYS
  }

  // Available at your location
  if (locationId === STOCK_LOCATION_ID_CONSIGNMENT && availabilityTime === 0) {
    // Available at location
    if (
      consignmentStockInformation !== null &&
      consignmentStockInformation?.binLocation &&
      consignmentStockInformation.registeredLevel
    ) {
      return STOCK_STATE.AVAILABLE_AT_LOCATION
    }

    return STOCK_STATE.AVAILABLE_AT_YOUR_LOCATION
  }

  // Available
  return STOCK_STATE.AVAILABLE
}

export const getStockChipStyle = (stockState: string) => {
  switch (stockState) {
    case STOCK_STATE.CONTACT:
      return {
        color: 'primary',
        backgroundColor: alphaColor(COLORS.BLACK, 0.08),
        ':hover': {
          backgroundColor: alphaColor(COLORS.BLACK, 0.16),
        },
      }

    case STOCK_STATE.AVAILABLE_AT_LOCATION:
    case STOCK_STATE.AVAILABLE_AT_YOUR_LOCATION:
      return {
        color: 'textLight',
        backgroundColor: 'primary',
        ':hover': {
          backgroundColor: 'highlight',
        },
      }

    case STOCK_STATE.AVAILABLE:
      return {
        color: 'success',
        backgroundColor: alphaColor(COLORS.CONFIRMATION, 0.16),
        ':hover': {
          backgroundColor: alphaColor(COLORS.CONFIRMATION, 0.32),
        },
      }

    default:
      return {
        color: 'orange.1',
        backgroundColor: alphaColor(COLORS.ORANGE, 0.16),
        ':hover': {
          backgroundColor: alphaColor(COLORS.ORANGE, 0.32),
        },
      }
  }
}

export const getStockIcon = (stockState: string): string => {
  switch (stockState) {
    case STOCK_STATE.DIRECT_SHIP_DELIVERY:
      return ICONS.TRUCK

    case STOCK_STATE.AVAILABLE_IN_X_HOURS:
    case STOCK_STATE.AVAILABLE_IN_X_DAYS:
      return ICONS.CLOCK

    case STOCK_STATE.CONTACT:
      return ICONS.PHONE

    case STOCK_STATE.AVAILABLE_AT_LOCATION:
    case STOCK_STATE.AVAILABLE_AT_YOUR_LOCATION:
      return ICONS.MARKER

    default:
      return ICONS.CHECK
  }
}

export const getStockPhoneNumber = (dealerPhoneNumber?: string | null): string =>
  dealerPhoneNumber ?? i18next.t('stockLabels.customerServicePhoneNumber')

export const getStockLabel = (
  variant: StockInformationVariant,
  stockLocationInformation: StockLocation,
  stockState: string
): string | undefined => {
  const { availabilityTime, locationId } = stockLocationInformation

  switch (stockState) {
    case STOCK_STATE.AVAILABLE_IN_X_HOURS: {
      if (variant === 'minimal') {
        return `${availabilityTime}${i18next.t('stockLabels.hoursLetter')}`
      }

      return `${availabilityTime} ${i18next.t('stockLabels.hours')}`
    }

    case STOCK_STATE.AVAILABLE_IN_X_DAYS: {
      const amountOfDays = Math.ceil((availabilityTime ?? 0) / 24)

      if (variant === 'minimal') {
        return `${amountOfDays}${i18next.t('stockLabels.daysLetter')}`
      }

      return `${amountOfDays} ${i18next.t('stockLabels.days')}`
    }

    case STOCK_STATE.DIRECT_SHIP_DELIVERY: {
      return i18next.t('stockLabels.directDelivery')
    }

    case STOCK_STATE.CONTACT: {
      if (variant === 'minimal') {
        return i18next.t('commonLabels.call')
      }

      return locationId === STOCK_LOCATION_ID_NO_STOCK_FOR_DEALER
        ? i18next.t('stockLabels.contactCustomerService')
        : i18next.t('stockLabels.contactDealer')
    }

    default: {
      return i18next.t('stockLabels.now')
    }
  }
}

export const orderStockLocationsByPriority = (
  stockInformation: StockInformation
): StockInformation => ({
  ...stockInformation,
  stockLocations: stockInformation.stockLocations.length
    ? stockInformation.stockLocations.sort((a, b) => a.priority - b.priority)
    : [],
})
