import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import PartBillOfMaterialSpares from './PartBillOfMaterialSpares'
import PartBillOfMaterialMastersAndParts from './PartBillOfMaterialMastersAndParts'

interface PartBillOfMaterialDatasource {
  includedInTitle: TextField
  consistsOfTitle: TextField
  spareOfTitle: TextField
}

interface PartBillOfMaterialProps {
  partsPageUri: LinkField
}

const PartBillOfMaterial: FC<
  Datasource<PartBillOfMaterialDatasource> & PartBillOfMaterialProps
> = ({ datasource, rendering: { uid }, partsPageUri }) => {
  const { consistsOfTitle, includedInTitle, spareOfTitle } = datasource

  return (
    <>
      <PartBillOfMaterialMastersAndParts
        consistsOfTitle={consistsOfTitle}
        includedInTitle={includedInTitle}
        partsPageUri={partsPageUri}
        id={uid}
      />

      <PartBillOfMaterialSpares
        partsPageUri={partsPageUri}
        spareOfTitle={spareOfTitle}
        id={`${uid}-s`}
      />
    </>
  )
}

export default withData(PartBillOfMaterial, { showMessageWhenPageEditing: true })
