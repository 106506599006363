import React, { FC } from 'react'
import { SOCIAL_ICONS } from '../../../constants/iconConstants'
import { LinkField } from '../../../types/layoutService'
import { SocialChannels } from './Footer'
import FooterSocialLink from './FooterSocialLink'

interface FooterLegalLinksProps {
  socialChannels: LinkField<SocialChannels>
}

const FooterLegalLinks: FC<FooterLegalLinksProps> = ({ socialChannels }) => (
  <nav>
    {socialChannels?.targetItem?.linkedIn?.value && (
      <FooterSocialLink
        href={socialChannels?.targetItem?.linkedIn?.value}
        icon={SOCIAL_ICONS.LINKEDIN}
      />
    )}

    {socialChannels?.targetItem?.facebook?.value && (
      <FooterSocialLink
        href={socialChannels?.targetItem?.facebook?.value}
        icon={SOCIAL_ICONS.FACEBOOK}
      />
    )}

    {socialChannels?.targetItem?.x?.value && (
      <FooterSocialLink
        href={socialChannels?.targetItem?.x?.value}
        icon={SOCIAL_ICONS.X}
        size={5}
      />
    )}

    {socialChannels?.targetItem?.youtube?.value && (
      <FooterSocialLink
        href={socialChannels?.targetItem?.youtube?.value}
        icon={SOCIAL_ICONS.YOUTUBE}
        size={6}
      />
    )}
    {socialChannels?.targetItem?.instagram?.value && (
      <FooterSocialLink
        href={socialChannels?.targetItem?.instagram?.value}
        icon={SOCIAL_ICONS.INSTAGRAM}
        size={5}
      />
    )}
    {socialChannels?.targetItem?.flickr?.value && (
      <FooterSocialLink
        href={socialChannels?.targetItem?.flickr?.value}
        icon={SOCIAL_ICONS.FLICKR}
        size={5}
      />
    )}
  </nav>
)

export default FooterLegalLinks
