import {
  MouseEvent,
  RefObject,
  TouchEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

const LEFT = 'Left'
const RIGHT = 'Right'

export interface OnSwipingEvent {
  deltaX: number
}

type HandledEvents = MouseEvent | TouchEvent
type SwipeDirections = typeof LEFT | typeof RIGHT
type SwipeCallback = () => void
type OnSwipingCallback = (event: OnSwipingEvent) => void

interface SwipeCallbacks {
  onSwipeStart?: SwipeCallback
  onSwiping?: OnSwipingCallback
  onSwipeCancel?: SwipeCallback
  onSwiped?: SwipeCallback
  onSwipedLeft?: SwipeCallback
  onSwipedRight?: SwipeCallback
}

interface SwipeGestureHandlers {
  onTouchStart?(event: TouchEvent): void
  onTouchEnd?(event: TouchEvent): void
  onTouchCancel?(event: TouchEvent): void
  onTouchMove?(event: TouchEvent): void
  onMouseDown?(event: MouseEvent): void
  onMouseUp?(event: MouseEvent): void
  onMouseMove?(event: MouseEvent): void
  onMouseLeave?(event: MouseEvent): void
}

const minimalSwipeAmount = 10 // 10px

const getDirection = (clientX: number, deltaX: number): SwipeDirections =>
  clientX > deltaX ? LEFT : RIGHT

const getHandledEvent = (event: HandledEvents) => {
  const isTouch = 'touches' in event
  return isTouch ? event.touches[0] : event
}

const overwriteDefaultEvents = (event: HandledEvents) => {
  if (!('touches' in event)) event.preventDefault() // only overwrite default functions on desktop

  event.stopPropagation()
}

const isDraggableTarget = (event: HandledEvents): boolean => {
  if (!(event.target instanceof HTMLElement)) {
    return false
  }

  const targetElement = event.target as HTMLElement

  return targetElement.getAttribute('data-is-draggable') !== 'false'
}
const useSwipeGestures = (
  targetElement: RefObject<HTMLElement>,
  swipeCallbacks: SwipeCallbacks
) => {
  const [startClientX, setStartClientX] = useState(0)
  const [lastClientX, setLastClientX] = useState(0)
  const [interacting, setInteracting] = useState(false)

  const hasStarted = useMemo(
    () => interacting && startClientX > 0,
    [interacting, startClientX]
  )

  const isInteractingWithTargetElement = useCallback(
    (event: HandledEvents) =>
      targetElement.current &&
      targetElement.current.contains(event.target as Element),
    [targetElement]
  )

  const resetState = useCallback(() => {
    setInteracting(false)
    setStartClientX(0)
    setLastClientX(0)
  }, [])

  const onStart = useCallback(
    (event: HandledEvents) => {
      if (!isInteractingWithTargetElement(event) || !isDraggableTarget(event))
        return undefined

      overwriteDefaultEvents(event)

      const { clientX } = getHandledEvent(event)
      const swipeStartCallback = swipeCallbacks?.onSwipeStart

      setInteracting(true)
      setStartClientX(clientX)
      setLastClientX(clientX)

      return swipeStartCallback?.()
    },
    [isInteractingWithTargetElement, swipeCallbacks]
  )

  const onMove = useCallback(
    (event: HandledEvents) => {
      if (!isInteractingWithTargetElement(event)) return undefined

      overwriteDefaultEvents(event)

      if (hasStarted) {
        const { clientX } = getHandledEvent(event)
        const deltaX = startClientX - clientX

        const swipingCallback = swipeCallbacks?.onSwiping

        setLastClientX(clientX)

        return swipingCallback?.({ deltaX })
      }

      return undefined
    },
    [hasStarted, isInteractingWithTargetElement, startClientX, swipeCallbacks]
  )

  const onEnd = useCallback(
    (event: HandledEvents) => {
      if (isInteractingWithTargetElement(event)) overwriteDefaultEvents(event)

      if (!hasStarted || lastClientX <= 0) return undefined

      const swipeAmount = Math.abs(startClientX - lastClientX)

      if (swipeAmount <= minimalSwipeAmount) {
        const cancelCallback = swipeCallbacks?.onSwipeCancel

        resetState()

        return cancelCallback?.()
      }

      const direction = getDirection(startClientX, lastClientX)
      const onSwipedCallback = swipeCallbacks?.onSwiped
      const swipeDirectionCallback = swipeCallbacks?.[`onSwiped${direction}`]

      resetState()

      if (onSwipedCallback) onSwipedCallback()

      return swipeDirectionCallback?.()
    },
    [
      isInteractingWithTargetElement,
      hasStarted,
      lastClientX,
      startClientX,
      swipeCallbacks,
      resetState,
    ]
  )

  return useMemo<SwipeGestureHandlers>(
    () => ({
      onTouchStart: onStart,
      onTouchEnd: onEnd,
      onTouchCancel: onEnd,
      onTouchMove: onMove,
      onMouseDown: onStart,
      onMouseUp: onEnd,
      onMouseMove: onMove,
      onMouseLeave: onEnd,
    }),
    [onEnd, onMove, onStart]
  )
}

export default useSwipeGestures
