import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box, Flex } from 'theme-ui'
import PartSalesInformationProvider from '../../../providers/PartSalesInformationProvider'
import { Part } from '../../../types/dafResponseProps'
import { getDividerSx } from '../../atoms/Divider/Divider'
import PromotionStickerAsync from '../../atoms/PromotionSticker/PromotionStickerAsync'
import PartPriceAsync from '../PartPrice/PartPriceAsync'
import PartPromotionsAsync from '../PartPromotions/PartPromotionsAsync'
import PartLineBasicInformation from './PartLineBasicInformation'
import PartLineImage from './PartLineImage'
import PartLineAction from './PartLineAction'

interface PartLineMinimalProps {
  href?: string
  onClick?: () => void
  part: Part
}

const PartLineMinimal: FC<PartLineMinimalProps> = ({
  part,
  ...actionContainerProps
}) => {
  const { space } = useTheme()

  if (!part?.partNumber) return null

  return (
    <PartSalesInformationProvider partNumber={part.partNumber}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '51px 1fr 1fr',
          gap: `${space[2]}px`,
          paddingY: 3,
          ...getDividerSx('top'),
        }}
      >
        <PartLineAction {...actionContainerProps}>
          <PartLineImage
            src={part?.images?.[0]?.thumbnailImageUrl}
            sx={{ maxWidth: '51px' }}
          />

          <Box>
            <PromotionStickerAsync
              variant="s"
              position="relative"
              sx={{
                marginBottom: 2,
              }}
            />

            <PartLineBasicInformation
              description={part?.description}
              partNumber={part.partNumber}
              supplierPartNumber={part?.supplierPartNumber}
            />

            <PartLineImage
              src={part?.brand?.image?.imageUrl}
              sx={{ maxWidth: '48px', marginTop: 2, marginBottom: 0 }}
            />
          </Box>
        </PartLineAction>

        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <PartPriceAsync partNumber={part.partNumber} variant="small" />

          <Box mb={-1} mr={-1}>
            <PartPromotionsAsync partNumber={part.partNumber} mt={2} />
          </Box>
        </Flex>
      </Box>
    </PartSalesInformationProvider>
  )
}

export default PartLineMinimal
