import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ICONS } from '../../../constants/iconConstants'
import { TOOLTIP_BASKET } from '../../../constants/tooltipConstants'
import { RootState } from '../../../reducers'
import Badge from '../../atoms/Badge/Badge'
import ClickableMenuItemWrapper from '../../atoms/ClickableMenuItemWrapper/ClickableMenuItemWrapper'
import { ClickableWrapperProps } from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Tooltip from '../../molecules/Tooltip/Tooltip'
import { hasRole } from '../../../helpers/userHelper'
import { USER_ROLES_PARTS_ACCESS } from '../../../constants/userConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

interface ShoppingBasketAnchorProps extends ClickableWrapperProps {}

const ShoppingBasketAnchor: FC<ShoppingBasketAnchorProps> = ({
  title,
  onClick,
  ...rest
}) => {
  const orderSummary = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel.orderSummary
  )
  const { [TOOLTIP_BASKET]: tooltip } = useSelector(
    (state: RootState) => state.tooltipList
  )

  const { user } = useSitecoreContext()

  if (!hasRole(user, USER_ROLES_PARTS_ACCESS)) return null

  return (
    <div>
      <Tooltip name={TOOLTIP_BASKET} dataTId="popup_added_to_basket">
        <ClickableMenuItemWrapper
          onClick={onClick}
          active={!!tooltip}
          title={title}
          sx={{
            display: 'inline-block',
            position: 'relative',
            py: [3, 3, 3, '20px'],
            px: 1,
          }}
          {...rest}
        >
          <Badge
            amount={orderSummary?.totalNumberOfItems ?? undefined}
            data-t-id="shopping-basket-badge"
          >
            <IconWrapper icon={ICONS.BASKET} />
          </Badge>
        </ClickableMenuItemWrapper>
      </Tooltip>
    </div>
  )
}

export default ShoppingBasketAnchor
