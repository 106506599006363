import React, { ReactNode } from 'react'
import { Flex } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { getModalBackgroundColor } from '../../../helpers/modalHelper'
import { SubThemeName } from '../../../providers/ThemeProvider'

interface ModalBodyProps {
  theme: SubThemeName
  children: ReactNode
}

const ModalBody = ({ theme, children }: ModalBodyProps) => {
  const themeSettings = useTheme()

  const backgroundColor = getModalBackgroundColor(theme, themeSettings)

  return (
    <Flex
      sx={{
        position: 'relative',
        flexGrow: 1,
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor,
        zIndex: 1,
      }}
    >
      {children}
    </Flex>
  )
}

export default ModalBody
