import React, { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, FlexProps } from 'theme-ui'
import {
  URL_DEALER_CODE_PARAM,
  URL_RETURN_URL_PARAM,
} from '../../../constants/urlConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { runOnWindow } from '../../../helpers/dom'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { LinkField } from '../../../types/layoutService'
import { DealerLocatorAction } from './DealerLocator'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'

interface DealerLocatorDealerButtonProps extends Omit<FlexProps, 'css'> {
  action: DealerLocatorAction
  dealerName: string
  requestAccountLink: LinkField
  selectDealerLink: LinkField
  dealerCode: string
  stretchButton?: boolean
  dataLayerArguments: Record<string, unknown>
}

const DealerLocatorDealerButton: FC<DealerLocatorDealerButtonProps> = ({
  action,
  requestAccountLink,
  selectDealerLink,
  dealerCode,
  stretchButton,
  dataLayerArguments,
  sx,
  ...props
}) => {
  const { crossSiteUrl } = useSitecoreContext()
  const history = useHistory()
  const [link, setLink] = useState<LinkField | undefined>(undefined)

  const linkData = useMemo(
    () =>
      action === DealerLocatorAction.SelectDealer
        ? selectDealerLink
        : requestAccountLink,
    [action, requestAccountLink, selectDealerLink]
  )
  const returnUrl = useMemo(
    () => getUrlQueryValue(URL_RETURN_URL_PARAM, history.location.search),
    [history.location.search]
  )

  useEffect(() => {
    const newUrl = new URL(
      linkData.url,
      crossSiteUrl || runOnWindow((w) => w.location.origin)
    )

    newUrl.searchParams.set(URL_DEALER_CODE_PARAM, dealerCode)
    if (returnUrl) newUrl.searchParams.set(URL_RETURN_URL_PARAM, returnUrl)

    setLink({ ...linkData, url: newUrl.toString() })
  }, [crossSiteUrl, dealerCode, linkData, returnUrl])

  if (!dealerCode || !link) return null

  return (
    <Flex
      sx={{
        justifyContent: ['flex-start', 'flex-start', 'flex-end'],
        alignSelf: 'center',
        ...sx,
      }}
      {...props}
    >
      <SitecoreButton
        data-t-id="Request-account"
        linkField={link}
        variant="primary"
        onClick={() => pushToDataLayer(dataLayerArguments)}
        sx={{
          width: stretchButton ? '100%' : 'auto',
        }}
      />
    </Flex>
  )
}

export default DealerLocatorDealerButton
