import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { CommerceResponse } from '../../../types/commerceApi'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'

export interface DafVehicleRmDocumentsResponse {
  documents: VehicleRmDocument[]
}

export interface VehicleRmDocument {
  url: VehicleUrlRmDocument
  documentType: string
  signed: boolean
  name: string
}

export interface VehicleUrlRmDocument {
  keyField: string
  keyValue: string
  documentId: string
  documentType: string
}

const fetchDafVehicleRmDocuments = (
  queryParams: DafDefaultQueryParams,
  chassisNumber: string,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<DafVehicleRmDocumentsResponse>>> =>
  client.get(`/daf-vehicle-rmdocuments/${chassisNumber}`, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default fetchDafVehicleRmDocuments
