import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import {
  ActionItem,
  LinkItem,
  MenuServiceItem,
  SubNavigation,
} from '../../atoms/NavigationItems'
import { NavigationItem } from '../../../types/layoutService'

interface NavigationProps extends Omit<BoxProps, 'css'> {
  items: NavigationItem[]
}

const Navigation: FC<NavigationProps> = ({ items, sx, ...rest }) => {
  if (!items?.length) return null
  return (
    <Box
      as="nav"
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        ...sx,
      }}
      {...rest}
    >
      {items.map((item, i) => {
        if (item.children && item.children.length) {
          return <SubNavigation key={i.toString()} {...item} />
        }

        if (item.dispatchAction) {
          return <ActionItem key={i.toString()} {...item} />
        }

        if (item.type === 'api') {
          return <MenuServiceItem key={i.toString()} {...item} />
        }

        return <LinkItem key={i.toString()} {...item} />
      })}
    </Box>
  )
}

export default Navigation
