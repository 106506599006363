import { useCallback } from 'react'
import {
  getCurrencyCodeFromPriceInformation,
  getFormattedPriceString,
} from '../helpers/priceHelper'
import { CurrencyFormatFunc, CurrencyFormatOptions } from '../types/closureTypes'
import { Price } from './services/graphql/usePartSalesInformationService'
import useSitecoreContext from './useSitecoreContext'

export type CurrencyFormatter = (price?: Price) => CurrencyFormatFunc

const useCurrencyFormatter = (price?: Price) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const currencyFormatter = useCallback(
    (amount?: number, options?: CurrencyFormatOptions): string | undefined =>
      getFormattedPriceString(
        cultureCode,
        getCurrencyCodeFromPriceInformation(
          price,
          options?.currencyCodeSourceType ?? 'DEFAULT'
        ),
        amount
      ),
    [cultureCode, price]
  )

  return currencyFormatter
}

export default useCurrencyFormatter
