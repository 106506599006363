import React from 'react'
import { Box, Text } from 'theme-ui'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'

interface VehicleDetailLabelValueItemProps {
  label: string
  value?: string | undefined | null | number
  dataTId?: string
  hideOnMobile?: boolean
}

const VehicleDetailLabelValueItem = ({
  label,
  value,
  dataTId,
  hideOnMobile,
}: VehicleDetailLabelValueItemProps) => (
  <Box
    sx={{
      display: hideOnMobile ? ['none', 'grid'] : 'grid',
      gridTemplateColumns: 'subgrid',
      gridColumn: 'span 2',
      gap: 2,
    }}
  >
    <Text
      as="dt"
      variant="bodySmall"
      sx={{
        fontWeight: 'bold',
      }}
    >
      {label}
    </Text>
    <Text as="dd" variant="bodySmall" marginLeft={0} data-t-id={dataTId}>
      {showDashWhenEmptyValue(value)}
    </Text>
  </Box>
)

export default VehicleDetailLabelValueItem
