import React, { ChangeEvent, FC } from 'react'
import { Field } from 'react-final-form'
import { BaseFieldProps } from './BaseField'

export interface HiddenFieldProps extends BaseFieldProps {
  onChange?: (event: ChangeEvent) => void
}

export const HiddenField: FC<HiddenFieldProps> = ({ name, validate, onChange }) => (
  <Field name={name} validate={validate}>
    {({ input }) => (
      <input
        type="hidden"
        name={name}
        onBlur={input.onBlur}
        onChange={(e) => {
          if (onChange) onChange(e)
          input.onChange(e)
        }}
        onFocus={input.onFocus}
        value={input.value}
      />
    )}
  </Field>
)
