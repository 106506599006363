import { useEffect, useMemo, useState } from 'react'
import { DEFAULT_PAGE_INDEX } from '../../../../constants/searchConstants'
import { getCommercePartUri } from '../../../../helpers/commerceApiHelpers'
import { SearchPart } from '../../../../services/rest/ecommerce/partSearch'
import { Part } from '../../../../types/dafResponseProps'
import {
  ActiveSearchResultFilters,
  FilterGroup,
} from '../../../../types/filterProps'
import useHasMounted from '../../../useHasMounted'
import usePartSearchService from './usePartSearchService'
import usePartsService from '../../graphql/usePartListService'

export interface PartFulFilled {
  part?: Part
  searchPart: SearchPart
  uri?: string
}

const combineSearchResultPartsWithGraphParts = (
  searchParts: SearchPart[],
  parts: Part[]
): PartFulFilled[] =>
  searchParts.map((searchPart) => {
    const part = parts.find(({ partNumber }) => partNumber === searchPart.partNumber)

    return {
      part: part ?? undefined,
      searchPart,
      uri: getCommercePartUri(
        part?.category?.mainCategoryId,
        part?.category?.subCategoryId,
        part?.partNumber
      ),
    }
  })

const useFulfilledPartSearchService = (
  searchString: string | undefined,
  activeFilters: ActiveSearchResultFilters | undefined,
  pageIndex: number = DEFAULT_PAGE_INDEX,
  partsPerPage: number | undefined
): [
  boolean,
  PartFulFilled[] | undefined,
  FilterGroup[] | undefined,
  string[] | undefined,
  number
] => {
  const [fulfilledParts, setPartsFulfilled] = useState<PartFulFilled[] | undefined>(
    undefined
  )
  const hasMounted = useHasMounted()

  const [fetchingSearchParts, searchParts, partFilters, partCategories, totalCount] =
    usePartSearchService(searchString, activeFilters, pageIndex, partsPerPage)

  const graphPartNumbers = useMemo(
    () => searchParts?.map(({ partNumber }) => partNumber),
    [searchParts]
  )

  const [fetchingGraphParts, graphParts] = usePartsService(graphPartNumbers)

  useEffect(() => {
    if (!hasMounted) return

    if (searchParts && !fetchingGraphParts && graphParts) {
      setPartsFulfilled(
        combineSearchResultPartsWithGraphParts(searchParts, graphParts)
      )
    } else if (searchParts?.length === 0) {
      setPartsFulfilled([])
    }
  }, [hasMounted, fetchingGraphParts, graphParts, searchParts])

  return [
    fetchingSearchParts || fetchingGraphParts,
    fulfilledParts,
    partFilters,
    partCategories,
    totalCount,
  ]
}

export default useFulfilledPartSearchService
