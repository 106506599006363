import { useMemo } from 'react'
import usePagination, { PaginationTuple } from '../usePagination'

export type SlidesPerPage = 1 | 2 | 3 | 4 | 5 | 6

interface Settings {
  slidesPerPage: SlidesPerPage
  slideCount: number
  slideGap: number
}

export type SliderTuple = [...PaginationTuple, Settings]

const useSlider = (settings: Settings): SliderTuple => {
  const { slideCount, slidesPerPage } = settings

  const pageCount = useMemo(
    () => Math.ceil(slideCount / slidesPerPage),
    [slideCount, slidesPerPage]
  )

  const pagination = usePagination({ pageCount })

  return [...pagination, settings]
}

export default useSlider
