import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { Part } from '../../../types/dafResponseProps'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useIsMounted from '../../useIsMounted'
import useLazyQuery from './core/useLazyQuery'

const GET_PART_LIST = loader('./schemas/partListQuery.graphql')

interface PartListQueryData {
  parts: Part[]
}

interface PartListQueryVariables extends BaseGraphServiceVariables {
  [key: string]: any
  partNumbers: string[]
}

const usePartListService = (
  partNumbers?: string[]
): GraphServiceTuple<Part[] | undefined> => {
  const isMounted = useIsMounted()

  const [baseHeaders, baseVariables] = useBaseGraphQLService()
  const [partList, setPartList] = useState<Part[] | undefined>(undefined)

  const onData = useCallback((data?: PartListQueryData) => {
    if (data?.parts?.length) {
      setPartList(data.parts)
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<PartListQueryData, PartListQueryVariables>(
    onData
  )

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && partNumbers?.length) {
      fetch({
        document: GET_PART_LIST,
        variables: {
          ...baseVariables,
          partNumbers,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, partNumbers])

  return [fetching, partList]
}

export default usePartListService
