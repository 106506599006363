import React from 'react'
import i18next from 'i18next'
import TableColumnTitle from '../../../molecules/Table/TableColumnTitle'
import TableHead from '../../../molecules/Table/TableHead'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { COLORS } from '../../../../constants/themeConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'

const MyFleetListTableHead = () => {
  const {
    site: { name },
  } = useSitecoreContext()
  return (
    <TableHead>
      <TableColumnTitle
        sx={{
          display: 'table-cell',
        }}
      />
      <TableColumnTitle
        sx={{
          display: 'table-cell',
        }}
      />
      <TableColumnTitle
        sx={{
          display: 'table-cell',
        }}
      >
        {i18next.t('myFleet.vehicleTitle')}
      </TableColumnTitle>
      <TableColumnTitle
        sx={{
          display: 'table-cell',
        }}
      >
        {i18next.t('myFleet.mileageTitle')}
      </TableColumnTitle>
      <TableColumnTitle
        sx={{
          display: 'table-cell',
        }}
      >
        <IconWrapper icon={ICONS.CONNECTED} size={4} color={COLORS.BLACK} />
      </TableColumnTitle>
      <TableColumnTitle
        sx={{
          display: 'table-cell',
        }}
      >
        {i18next.t('myFleet.servicesTitle')}
      </TableColumnTitle>
      {name === MULTISUPPORT_SITE_CONTEXT && (
        <TableColumnTitle
          sx={{
            display: 'table-cell',
          }}
        >
          {i18next.t('myFleet.mainServicePartnerTitle')}
        </TableColumnTitle>
      )}
      <TableColumnTitle
        sx={{
          display: 'table-cell',
        }}
      >
        {i18next.t('myFleet.maintenanceTitle')}
      </TableColumnTitle>
      {name === MULTISUPPORT_SITE_CONTEXT && (
        <TableColumnTitle
          sx={{
            display: 'table-cell',
          }}
        >
          {i18next.t('myFleet.servicePlanTitle')}
        </TableColumnTitle>
      )}
      {name === MULTISUPPORT_SITE_CONTEXT && (
        <TableColumnTitle
          sx={{
            display: 'table-cell',
          }}
        >
          {i18next.t('myFleet.invoiceTitle')}
        </TableColumnTitle>
      )}
      <TableColumnTitle
        sx={{
          display: 'table-cell',
          textAlign: 'center',
        }}
      >
        {i18next.t('myFleet.actionTitle')}
      </TableColumnTitle>
    </TableHead>
  )
}

export default MyFleetListTableHead
