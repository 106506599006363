import i18next from 'i18next'
import React from 'react'
import ActionsDoughnutChartWrapper from '../../ChartWrappers/ActionsDoughnutChartWrapper'
import { DoughnutProps } from '../../../../types/DoughnutChartTypes'
import {
  INVOICE_STATE_ON_HOLD,
  MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE,
} from '../../../../constants/dafCompanyVehicleConstants'
import { COLORS } from '../../../../constants/themeConstants'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'

interface FinanceStateChartProps {
  numberOfVehicles: number
  filteredActiveRMContracts: Vehicle[] | undefined
}
const FinanceStateChart = ({
  numberOfVehicles,
  filteredActiveRMContracts,
}: FinanceStateChartProps) => {
  const countInvoiceStateOnHold = filteredActiveRMContracts?.filter(
    (contract) => contract.rmContract?.invoiceState === INVOICE_STATE_ON_HOLD
  ).length

  const numberOfInvoicesOnHold =
    countInvoiceStateOnHold === 0 ? 1 : countInvoiceStateOnHold || 0

  const invoiceStateData: DoughnutProps = {
    total: countInvoiceStateOnHold ?? 0,
    items: [
      {
        id: 'invoiceStateOnHold',
        filterCategory: MY_FLEET_LIST_FILTER_OPTION_CONTRACT_STATE,
        filterValue: INVOICE_STATE_ON_HOLD,
        itemTotal: numberOfInvoicesOnHold,
        color: COLORS.ALERT,
      },
    ],
    legenda: [
      {
        id: 'invoiceStateOnHold',
        name: i18next.t('dafCompanyVehicles.invoiceOnHold'),
        itemTotal: countInvoiceStateOnHold ?? 0,
        color: COLORS.ALERT,
      },
    ],
  }
  return (
    <ActionsDoughnutChartWrapper
      chartTitle={i18next.t('actionsStatisticsLabels.invoiceStateTitle')}
      chartData={invoiceStateData}
      numberOfVehicles={numberOfVehicles}
    />
  )
}

export default FinanceStateChart
