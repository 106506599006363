import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import CallToActionImage from '../../molecules/CallToActionImage/CallToActionImage'
import CallToActionContent, {
  CallToActionContentProps,
} from '../../molecules/CallToActionContent/CallToActionContent'
import { ConditionalLink } from '../../atoms/Link/Link'
import { resolveUrl } from '../../../helpers/linkFieldHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  Datasource,
  ImageWithFocalPointField,
  Params,
} from '../../../types/layoutService'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

export interface CallToActionWithImageDatasource extends CallToActionContentProps {
  image: ImageWithFocalPointField
}

const CallToActionWithImage: FC<
  Datasource<CallToActionWithImageDatasource> & Params
> = ({ params, datasource }) => {
  const buttonType = params?.ButtonStyle?.toLocaleLowerCase() ?? 'primary'
  const headingSize = params?.HeadingSize?.toLocaleLowerCase() ?? 'heading2'

  return (
    <ConditionalLink
      condition={(datasource.wrapLink?.boolValue && !!datasource.button) || false}
      href={resolveUrl(datasource.button)}
      target={datasource.button?.target}
      sx={{
        display: 'block',
        color: 'inherit',
        mb: [4, 4, 7],
        textDecoration: 'none',
      }}
      onClick={
        datasource.wrapLink?.boolValue
          ? () => {
              pushToDataLayer({
                [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
                call_to_action_name: getTextFieldValue(datasource.title),
                call_to_action_type:
                  DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.ARTICLE,
                target_url: datasource.button?.url || '',
              })
            }
          : undefined
      }
    >
      <CallToActionImage imageField={datasource.image} />

      <CallToActionContent
        {...{ ...datasource, buttonType, headingSize }}
        skipTagEvent
      />
    </ConditionalLink>
  )
}

export default withData(CallToActionWithImage)
