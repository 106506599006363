import React, { FC } from 'react'
import { Box, Flex, FlexProps } from 'theme-ui'
import { NewsArticle } from '../../../types/layoutService'
import Skeleton from '../../atoms/Skeleton/Skeleton'
import CallToActionImage from '../../molecules/CallToActionImage/CallToActionImage'
import GridCardContainer from '../../molecules/GridCardContainer/GridCardContainer'

const NewsArticleCardSkeleton: FC<NewsArticle & Omit<FlexProps, 'css'>> = ({
  sx,
  ...flexProps
}) => (
  <GridCardContainer sx={sx} {...flexProps}>
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Skeleton>
        <CallToActionImage />
      </Skeleton>

      <Skeleton>
        <Box sx={{ marginBottom: 2, height: '32px' }} />
      </Skeleton>

      <Skeleton>
        <Box
          sx={{
            marginBottom: 3,
            height: '16px',
          }}
        />
      </Skeleton>

      <Skeleton>
        <Box
          sx={{
            height: '40px',
            width: '110px',
            borderRadius: '40px',
          }}
        />
      </Skeleton>
    </Flex>
  </GridCardContainer>
)

export default NewsArticleCardSkeleton
