import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Flex } from 'theme-ui'
import i18next from 'i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  VEHICLE_DETAIL_FINANCE_TAB,
  VEHICLE_DETAIL_MAINTENANCE_TAB,
  VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB,
  VEHICLE_DETAIL_SERVICEPLAN_TAB,
} from '../../../constants/dafCompanyVehicleConstants'
import { getQueryParam } from '../../../helpers/urlHelper'
import VehicleDetailTab from './VehicleDetailTab'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import {
  COMMERCE_SITE_CONTEXT,
  MULTISUPPORT_SITE_CONTEXT,
} from '../../../constants/environmentConstants'
import { RootState } from '../../../reducers'
import { hasRole } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import {
  USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION,
  USER_ROLES_VEHICLE_OVERVIEW_ACCESS,
} from '../../../constants/userConstants'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import { vehicleDetailHasMaintenancePlan as vehicleDetailResponseHasMaintenancePlan } from '../../../helpers/vehicleHelper'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

interface TabsProps {
  key: string
  label: string
  dataTID: string
}

const VehicleDetailTabs = () => {
  const history = useHistory()
  const { site } = useSitecoreContext()
  const { user } = useContext(UserContext)
  const [applicableTabs, setApplicableTabs] = useState<TabsProps[]>([])
  const companyVehicle = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const vehicleDetailResponse = companyVehicle.vehicleData as VehicleDetailResponse

  const tabs = useMemo(() => {
    const coverageTab = {
      key: VEHICLE_DETAIL_SERVICEPLAN_TAB,
      label: i18next.t('myFleet.servicePlanTitle'),
      dataTID: 'vehicle-detail-coverage-button',
      isVisible:
        !vehicleDetailResponseHasMaintenancePlan(vehicleDetailResponse) &&
        (site.name !== COMMERCE_SITE_CONTEXT ||
          hasRole(
            user,
            USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
          )),
    }

    const maintenanceTab = {
      key: VEHICLE_DETAIL_MAINTENANCE_TAB,
      label: i18next.t('myFleet.maintenanceTitle'),
      dataTID: 'vehicle-detail-maintenance-button',
      isVisible:
        site.name === MULTISUPPORT_SITE_CONTEXT ||
        hasRole(
          user,
          USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
        ) ||
        hasRole(user, USER_ROLES_VEHICLE_OVERVIEW_ACCESS),
    }

    const financeTab = {
      key: VEHICLE_DETAIL_FINANCE_TAB,
      label: i18next.t('myFleet.financeTitle'),
      dataTID: 'vehicle-detail-finance-button',
      isVisible:
        !vehicleDetailResponseHasMaintenancePlan(vehicleDetailResponse) &&
        (site.name !== COMMERCE_SITE_CONTEXT ||
          hasRole(
            user,
            USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
          )),
    }

    return [coverageTab, maintenanceTab, financeTab]
  }, [user, site, companyVehicle])

  useEffect(
    () => setApplicableTabs(() => tabs.filter((tab) => tab.isVisible)),
    [tabs]
  )

  const queryParamValue = getQueryParam(VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB)

  useEffect(() => {
    if (
      !queryParamValue ||
      (queryParamValue && !applicableTabs.some((tab) => tab.key === queryParamValue))
    ) {
      if (applicableTabs.length) {
        const params = new URLSearchParams(window.location.search)
        params.set(VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB, applicableTabs[0]?.key)
        history.replace(`?${params.toString()}`)
      }
    }
  }, [history, queryParamValue, applicableTabs])

  const handleClickTab = (id: string) => {
    const params = new URLSearchParams(window.location.search)
    params.set(VEHICLE_DETAIL_QUERY_PARAM_ACTIVE_TAB, id || '')
    history.push(`?${params.toString()}`)
  }

  return (
    <Flex sx={{ marginTop: 5 }}>
      {applicableTabs.map((tab) => (
        <Fragment key={tab.key}>
          <VehicleDetailTab
            key={tab.key}
            data-t-id={tab.dataTID}
            active={queryParamValue === tab.key}
            value={tab.label}
            onClick={() => handleClickTab(tab.key)}
          />
        </Fragment>
      ))}
    </Flex>
  )
}

export default VehicleDetailTabs
