import React, { Children, cloneElement, ReactElement, ReactNode } from 'react'
import { SxProp } from 'theme-ui'

interface SkeletonProps {
  children: ReactNode
}

const renderChild = (child: ReactElement<SxProp>) =>
  cloneElement(child, {
    ...child.props,
    sx: { ...child.props.sx, backgroundColor: 'black', opacity: 0.16 },
  })

const Skeleton = ({ children }: SkeletonProps) => (
  <>
    {Children.map(children, (child) => renderChild(child as ReactElement<SxProp>))}
  </>
)

export default Skeleton
