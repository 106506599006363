import React, { useCallback, useContext, useMemo, useState } from 'react'
import i18next from 'i18next'
import PartSearchFilterChip from './PartSearchFilterChip'
import { ReferenceField, TextField } from '../../../../../../types/layoutService'
import Button from '../../../../../atoms/Button/Button'
import {
  CATEGORY_FILTER_ID,
  FILTER_MASTER_GROUP_APPLICATION,
  FILTER_MASTER_GROUP_APPLICATION_ID,
} from '../../../../../../constants/searchConstants'
import {
  getApplicationFilterGroupPriority,
  separateApplicationFiltersFromFilterGroups,
  stripInvalidFilterValues,
} from '../../../../../../helpers/filterHelper'
import PartSearchFilterChipContainer from './PartSearchFilterChipContainer'
import { pushToDataLayer } from '../../../../../../helpers/analyticsHelper'
import PartFiltersSidePanel, {
  PartFilterSidePanelDatasource,
} from '../../../../SidePanel/PartFilterSidePanel/PartFilterSidePanel'
import { PartSearchResultContext } from '../../../../../../providers/PartSearchResultContextProvider'
import {
  ActiveSearchResultFilters,
  FilterGroup,
} from '../../../../../../types/filterProps'
import { DATA_LAYER } from '../../../../../../constants/dataLayerConstants'
import { getTextFieldValue } from '../../../../../../helpers/layoutServiceHelper'

interface PartSearchFilterChipListProps {
  moreFiltersLabel: TextField
  filterOverlay: ReferenceField<PartFilterSidePanelDatasource>
}

const AMOUNT_OF_CHIPS_TO_DISPLAY = 3

const getAdditionalFilterChips = (
  initialFilterChipsA: FilterGroup[],
  initialFilterChipsB: FilterGroup[],
  applicationFilterPriority?: number,
  activeFilters?: ActiveSearchResultFilters,
  availableFilters?: FilterGroup[]
) => {
  const disallowedFilterIds = [
    CATEGORY_FILTER_ID,
    ...[...initialFilterChipsA, ...initialFilterChipsB].map(({ id }) => id),
    ...(applicationFilterPriority &&
    applicationFilterPriority <= AMOUNT_OF_CHIPS_TO_DISPLAY
      ? FILTER_MASTER_GROUP_APPLICATION
      : []),
  ]

  const selectedFilterIds = activeFilters
    ? Object.keys(activeFilters).filter(
        (filterId) => !disallowedFilterIds.includes(filterId)
      )
    : []

  const selectedApplicationFilters: string[] = []

  selectedFilterIds.forEach((filterId) => {
    if (FILTER_MASTER_GROUP_APPLICATION.includes(filterId)) {
      selectedApplicationFilters.push(filterId)
    }
  })

  selectedApplicationFilters.shift()

  return selectedFilterIds
    .filter((filterId) => !selectedApplicationFilters.includes(filterId)) // remove all application filters except one, application filters are grouped in one chip
    .map((filterId) =>
      availableFilters
        ? availableFilters.find(
            ({ id: selectedFilterId }) => selectedFilterId === filterId
          )
        : undefined
    )
}

const PartSearchFilterChipList = ({
  moreFiltersLabel,
  filterOverlay,
}: PartSearchFilterChipListProps) => {
  const { availableFilters, activeFilters } = useContext(PartSearchResultContext)

  const selectedFilterGroupIdState = useState<string | undefined>(undefined)
  const lastModifiedFilterGroupState = useState<FilterGroup | undefined>(undefined)

  const setSelectedFilterGroupId = selectedFilterGroupIdState[1]

  const [
    applicationFilterGroups,
    filterGroups,
    selectedApplicationFilterCount,
    initialFilterChipsA,
    initialFilterChipsB,
    additionalFilterChips,
  ] = useMemo(() => {
    const [computedApplicationFilterGroups, computedFilterGroups = []] =
      separateApplicationFiltersFromFilterGroups(availableFilters)
    const applicationFilterPriority = getApplicationFilterGroupPriority(
      computedApplicationFilterGroups
    )
    const computedSelectedApplicationFilterCount = (
      computedApplicationFilterGroups ?? []
    )
      .map(
        (group) =>
          stripInvalidFilterValues(
            group.filterOptions,
            activeFilters ? activeFilters[group.id] : undefined
          )?.length || 0
      )
      .reduce((a, b) => a + b, 0)
    const chipA =
      applicationFilterPriority &&
      applicationFilterPriority <= AMOUNT_OF_CHIPS_TO_DISPLAY
        ? computedFilterGroups.slice(0, applicationFilterPriority - 1)
        : computedFilterGroups.slice(0, AMOUNT_OF_CHIPS_TO_DISPLAY)
    const chipB =
      (applicationFilterPriority &&
        applicationFilterPriority <= AMOUNT_OF_CHIPS_TO_DISPLAY &&
        computedFilterGroups.slice(
          applicationFilterPriority - 1,
          AMOUNT_OF_CHIPS_TO_DISPLAY - (applicationFilterPriority ? 1 : 0)
        )) ||
      []

    const computedAdditionalFilterChips = getAdditionalFilterChips(
      chipA,
      chipB,
      applicationFilterPriority,
      activeFilters,
      availableFilters
    )

    return [
      computedApplicationFilterGroups,
      computedFilterGroups,
      computedSelectedApplicationFilterCount,
      chipA,
      chipB,
      computedAdditionalFilterChips,
    ]
  }, [availableFilters, activeFilters])

  const openFilterPanel = useCallback(() => {
    setSelectedFilterGroupId('')

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FILTER,
      filter_action: DATA_LAYER.CUSTOM_DIMENSION.FILTER_ACTION.VIEW,
      filter_type: DATA_LAYER.CUSTOM_DIMENSION.FILTER_TYPE.MORE_FILTERS,
      filter_name: '',
      filter_value: '',
    })
    // dependencies do not change during component lifecycle
  }, [])

  return (
    <PartSearchFilterChipContainer>
      {initialFilterChipsA.map((filter) => (
        <PartSearchFilterChip
          key={filter.id}
          label={filter.description}
          englishDescription={filter.englishDescription}
          filterType={filter.type}
          selectionCount={
            stripInvalidFilterValues(
              filter.filterOptions,
              activeFilters ? activeFilters[filter.id] : undefined
            )?.length
          }
          onClick={() => setSelectedFilterGroupId(filter.id)}
        />
      ))}

      {applicationFilterGroups &&
        !!applicationFilterGroups.length &&
        initialFilterChipsA.length < AMOUNT_OF_CHIPS_TO_DISPLAY && (
          <PartSearchFilterChip
            label={i18next.t('filteringLabels.application')}
            englishDescription={FILTER_MASTER_GROUP_APPLICATION_ID}
            selectionCount={selectedApplicationFilterCount}
            onClick={() =>
              setSelectedFilterGroupId(FILTER_MASTER_GROUP_APPLICATION_ID)
            }
          />
        )}

      {initialFilterChipsB.map((filter) => (
        <PartSearchFilterChip
          key={filter.id}
          label={filter.description}
          englishDescription={filter.englishDescription}
          filterType={filter.type}
          selectionCount={
            stripInvalidFilterValues(
              filter.filterOptions,
              activeFilters ? activeFilters[filter.id] : undefined
            )?.length
          }
          onClick={() => setSelectedFilterGroupId(filter.id)}
        />
      ))}

      {additionalFilterChips.map((filter) => {
        if (!filter) return <></>

        if (FILTER_MASTER_GROUP_APPLICATION.includes(filter.id)) {
          return (
            <PartSearchFilterChip
              key={filter.id}
              label={i18next.t('filteringLabels.application')}
              englishDescription={FILTER_MASTER_GROUP_APPLICATION_ID}
              selectionCount={selectedApplicationFilterCount}
              onClick={() =>
                setSelectedFilterGroupId(FILTER_MASTER_GROUP_APPLICATION_ID)
              }
            />
          )
        }

        return (
          <PartSearchFilterChip
            key={filter.id}
            label={filter.description}
            englishDescription={filter.englishDescription}
            selectionCount={
              stripInvalidFilterValues(
                filter.filterOptions,
                activeFilters ? activeFilters[filter.id] : undefined
              )?.length
            }
            onClick={() => setSelectedFilterGroupId(filter.id)}
          />
        )
      })}

      <Button
        data-t-id="parts-filter-chips-more-filters-button"
        variant="infinite"
        sx={{ color: 'primary', px: 2, div: { fontWeight: 'normal' }, mb: 2 }}
        onClick={openFilterPanel}
      >
        {getTextFieldValue(moreFiltersLabel)}
      </Button>

      <PartFiltersSidePanel
        selectedFilterGroupIdState={selectedFilterGroupIdState}
        lastModifiedFilterGroupState={lastModifiedFilterGroupState}
        applicationFilterGroups={applicationFilterGroups}
        filterGroups={filterGroups}
        onClose={() => setSelectedFilterGroupId(undefined)}
        {...(filterOverlay.targetItem as PartFilterSidePanelDatasource)}
        selectedApplicationFilterCount={selectedApplicationFilterCount}
      />
    </PartSearchFilterChipContainer>
  )
}

export default PartSearchFilterChipList
