import React, { FC, useContext, useMemo } from 'react'
import {
  getPartQuantumPriceTableColumns,
  getPartQuantumPriceTableRows,
} from '../../../../helpers/partQuantumPriceTableHelper'
import { Price } from '../../../../hooks/services/graphql/usePartSalesInformationService'
import useCurrencyFormatter from '../../../../hooks/useCurrencyFormatter'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import PartQuantumPriceMobileTable from '../../../organisms/PartQuantumPrice/PartQuantumPriceMobileTable'

interface QuantumPricePromotionSidePanelProps {
  price: Price
}

const QuantumPricePromotionSidePanel: FC<QuantumPricePromotionSidePanelProps> = ({
  price,
}) => {
  const { dateFormatter } = useContext(LocalizationContext)
  const currencyFormatter = useCurrencyFormatter(price)

  const rows = useMemo(
    () =>
      price
        ? getPartQuantumPriceTableRows(currencyFormatter, dateFormatter, price)
        : [],
    [currencyFormatter, dateFormatter, price]
  )

  const columns = useMemo(() => getPartQuantumPriceTableColumns(), [])

  return (
    <PartQuantumPriceMobileTable
      rows={rows}
      columns={columns}
      sx={{ borderTop: 0 }}
    />
  )
}

export default QuantumPricePromotionSidePanel
