import React, { FC } from 'react'
import { Text } from 'theme-ui'
import HotjarSuppress from '../../atoms/HotjarSuppress/HotjarSuppress'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ConditionalLink } from '../../atoms/Link/Link'

interface DealerContactInformationLineProps {
  icon?: string
  href?: string
  label?: string
}

const DealerContactInformationLine: FC<DealerContactInformationLineProps> = ({
  icon,
  href,
  label,
}) => {
  if (!label) return null

  return (
    <Text
      as="li"
      variant="bodyContent"
      sx={{
        listStyle: 'none',
        color: 'gray.3',
        alignItems: 'center',
        fontSize: 'inherit',
        svg: {
          marginRight: 2,
        },
      }}
    >
      {icon && <IconWrapper icon={icon} size={4} />}

      <HotjarSuppress>
        <ConditionalLink
          condition={!!href}
          href={href}
          color="inherit"
          sx={{ display: 'inline-block' }}
        >
          {label}
        </ConditionalLink>
      </HotjarSuppress>
    </Text>
  )
}

export default DealerContactInformationLine
