import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { getStockLabel, getStockState } from '../../../helpers/stockHelper'
import PartStockInformationStatus from './PartStockInformationStatus'
import { StockLocation } from '../../../hooks/services/graphql/usePartSalesInformationService'
import { StockInformationVariant } from './PartStockInformationList'

interface PartStockInformationLocationProps {
  stockLocation: StockLocation
  displayQuantity: boolean
  variant?: StockInformationVariant
  callback: () => void
  dataTId: string
}

const PartStockInformationLocation: FC<PartStockInformationLocationProps> = ({
  stockLocation,
  displayQuantity,
  variant = 'full',
  callback,
  dataTId,
  ...partStockInformationStatusProps
}) => {
  const stockState = getStockState(stockLocation)

  if (!stockLocation || !stockState) return null
  return (
    <>
      {displayQuantity && (
        <Text
          data-t-id={`${dataTId}-quantity`}
          variant="bodySmall"
          sx={{ color: 'gray.2', alignSelf: 'center' }}
        >
          {stockLocation.quantity}
        </Text>
      )}

      <PartStockInformationStatus
        variant={variant}
        stockState={stockState}
        onClick={callback}
        {...partStockInformationStatusProps}
      >
        {getStockLabel(variant, stockLocation, stockState)}
      </PartStockInformationStatus>
    </>
  )
}

export default PartStockInformationLocation
