import React, { FC, useCallback, useContext, useMemo } from 'react'
import { MATCH_TYPE_ALTERNATIVE } from '../../../../../constants/searchConstants'
import { loggedIn } from '../../../../../helpers/userHelper'
import { UserContext } from '../../../../../providers/UserProvider'
import { LinkField, TextField } from '../../../../../types/layoutService'
import PartSearchLineAlternative from './PartSearchLineAlternative'
import PartLineBasicInformation from '../../../../molecules/PartLine/PartLineBasicInformation'
import PartLinePartAttributes from '../../../../molecules/PartLine/PartLinePartAttributes'
import { getPartAttributesWithPackagingQuantity } from '../../../../../helpers/graphPartHelper'
import { CategoriesContext } from '../../../../../providers/CategoriesProvider'
import { SearchPart } from '../../../../../services/rest/ecommerce/partSearch'
import { Part } from '../../../../../types/dafResponseProps'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { measurePartClick } from '../../../../../helpers/analyticsHelper'
import PartLine, {
  PartLineGridTemplate,
} from '../../../../molecules/PartLine/PartLine'
import PartSalesInformation from '../../../../molecules/SalesInformation/PartSalesInformation'
import PartLineProductImage from '../../../../molecules/PartLine/PartLineProductImage'
import PartLineBrand from '../../../../molecules/PartLine/PartLineBrand'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import PartSalesInformationProvider from '../../../../../providers/PartSalesInformationProvider'
import PartLineAnchor from '../../../../molecules/PartLine/PartLineAnchor'

interface PartSearchLineProps {
  searchPart: SearchPart
  part?: Part
  uri?: string
  index: number
  partPage: LinkField
  supersessionText: TextField
  gridTemplate?: PartLineGridTemplate
}

const PartSearchLine: FC<PartSearchLineProps> = ({
  searchPart,
  part,
  uri,
  index,
  partPage,
  supersessionText,
  gridTemplate,
}) => {
  const { user } = useContext(UserContext)
  const { categories } = useContext(CategoriesContext)

  const href = `${partPage.url}/${uri}`
  const isAlternative =
    searchPart.matchMetaData?.type &&
    searchPart.matchMetaData.type === MATCH_TYPE_ALTERNATIVE

  const sortedPartAttributes = useMemo(
    () =>
      part?.specification?.partAttributes &&
      part?.specification?.partAttributes.length
        ? part?.specification?.partAttributes.sort((a, b) => a.priority - b.priority)
        : [],
    [part]
  )

  const attributes = useMemo(
    () =>
      getPartAttributesWithPackagingQuantity(
        sortedPartAttributes,
        part?.specification?.packaging
      ),
    [sortedPartAttributes, part]
  )

  const measureClick = useCallback(
    () =>
      measurePartClick(
        part?.partNumber,
        part?.englishDescription,
        part?.brand?.description,
        part?.category?.mainCategoryId,
        part?.category?.subCategoryId,
        index,
        categories,
        DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
      ),
    [part, index, categories]
  )

  return (
    <PartSalesInformationProvider partNumber={searchPart.partNumber}>
      {isAlternative && <PartSearchLineAlternative gridTemplate={gridTemplate} />}

      <PartLine
        data-t-id={`part-search-line-part-number-${part?.partNumber}`}
        gridTemplate={gridTemplate}
      >
        <PartLineAnchor href={href} onClick={measureClick}>
          <PartLineProductImage
            partNumber={searchPart.partNumber}
            src={part?.images?.[0].thumbnailImageUrl}
            alt={part?.description}
          />
        </PartLineAnchor>

        <PartLineAnchor href={href} onClick={measureClick}>
          <PartLineBasicInformation
            description={part?.description}
            partNumber={searchPart.partNumber}
            displayPartNumber={part?.displayPartNumber}
            matchMetaData={searchPart.matchMetaData}
            supplierPartNumber={part?.supplierPartNumber}
            supersessionText={supersessionText}
          />
        </PartLineAnchor>

        <PartLineAnchor href={href} onClick={measureClick}>
          <PartLinePartAttributes attributes={attributes} />
        </PartLineAnchor>

        <PartLineAnchor href={href} onClick={measureClick}>
          <PartLineBrand data-t-id="search-result-part-brand" brand={part?.brand} />
        </PartLineAnchor>

        <PartLineStockInformation
          partNumber={searchPart.partNumber}
          displayPartNumber={part?.displayPartNumber}
          componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE}
          categoryId={part?.category?.mainCategoryId}
          subcategoryId={part?.category?.subCategoryId}
        />

        {loggedIn(user) && (
          <PartSalesInformation
            partNumber={searchPart.partNumber}
            partEnglishDescription={part?.englishDescription}
            brandName={part?.brand?.description}
            categoryId={part?.category?.mainCategoryId}
            subcategoryId={part?.category?.subCategoryId}
            dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE}
            packageUnitQuantity={part?.specification?.packaging?.packageUnitQuantity}
            initialQuantity={part?.specification?.packaging?.packageUnitQuantity}
          />
        )}
      </PartLine>
    </PartSalesInformationProvider>
  )
}

export default PartSearchLine
