import React, { useContext, useMemo } from 'react'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { Text } from 'theme-ui'
import { Form } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import SidePanel from '../SidePanel'
import { toggleEditVehiclePanel } from '../../../../actions/dafCompanyVehicleActions'
import SidePanelMain from '../SidePanelMain'
import SidePanelPaddedBox from '../SidePanelPaddedBox'
import { TextField } from '../../../atoms/FormFields'
import { InputDateField } from '../../../atoms/FormFields/InputDateField'
import { ValidationRules } from '../../../../helpers/formFieldHelper'
import Button from '../../../atoms/Button/Button'
import SidePanelFooter from '../SidePanelFooter'
import sendEditVehicle, {
  SendEditVehiclePayloadProps,
} from '../../../../services/rest/ecommerce/sendEditVehicle'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import { addToast } from '../../../../actions/toastActions'
import getCompanyVehicle from '../../../../services/rest/ecommerce/getCompanyVehicle'
import Spinner from '../../../atoms/Spinner/Spinner'
import { SERVICEPLAN_CONTRACT_STATE_CLOSED } from '../../../../constants/dafCompanyVehicleConstants'
import { RootState } from '../../../../reducers'
import { getVehicleLabel } from '../../../../helpers/vehicleHelper'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import { NORMALIZED_DATE_FORMAT } from '../../../../constants/dateConstants'

interface EditVehiclePanelProps {
  vehicle: VehicleDetailResponse | undefined
  onFormSuccess?: () => void
}

type FormData = {
  registrationNumber: string
  customerVehicleLabel: string
  expirationDateLegalInspection: string
  expirationDateTacho: string
  type: string
  buildDate: string
}

const EditVehiclePanel = ({ onFormSuccess, vehicle }: EditVehiclePanelProps) => {
  const commerceClient = useAxiosClient(AxiosClientType.CommerceApi)
  const { token } = axios.CancelToken.source()
  const { user } = useContext(UserContext)
  const id = useMemo(() => uuidv4(), [])
  const dispatch = useDispatch()
  const client = useAxiosClient(AxiosClientType.CommerceApi)

  const { editVehiclePanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const shouldShowExpirationDates = () =>
    !vehicle?.rmContract ||
    vehicle?.rmContract?.servicePlan.status.contractState ===
      SERVICEPLAN_CONTRACT_STATE_CLOSED

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getEditVehicleData = async () => {
    const response = await getCompanyVehicle(
      vehicle?.vehicleDetail.vehicleAttributes.vin,
      dafVehicleDetailQueryParams,
      client,
      token,
      user
    )
    return response.data
  }

  const { isLoading: isLoadingPrefillData, data } = useQuery({
    queryKey: [`editVehicleData-${vehicle?.vehicleDetail.vehicleAttributes.vin}`],
    queryFn: () => getEditVehicleData(),
    enabled: !!editVehiclePanelState && !!vehicle,
  })

  const prefillData = data?.result

  const validate = (values: FormData) => {
    const errors: ValidationErrors = {}

    if (values.registrationNumber && values.registrationNumber.length > 20) {
      errors.registrationNumber = i18next.t(
        'errorMessages.valueCanNotBeLongerThan',
        {
          length: 20,
        }
      )
    }

    if (values.customerVehicleLabel && values.customerVehicleLabel.length > 50) {
      errors.customerVehicleLabel = i18next.t(
        'errorMessages.valueCanNotBeLongerThan',
        {
          length: 50,
        }
      )
    }

    if (values.type && values.type.length > 50) {
      errors.type = i18next.t('errorMessages.valueCanNotBeLongerThan', {
        length: 50,
      })
    }

    if (values.buildDate && new Date(values.buildDate) > new Date()) {
      errors.buildDate = i18next.t('commonFormLabels.dateNotInFuture')
    }

    return errors
  }

  const postEditVehicle = async (payload: SendEditVehiclePayloadProps) => {
    const response = await sendEditVehicle(
      payload,
      commerceClient,
      dafVehicleDetailQueryParams,
      token,
      user
    )

    return response.data
  }

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (payload: SendEditVehiclePayloadProps) => postEditVehicle(payload),

    onSuccess: (responseData) => {
      if (!responseData?.messages?.length) {
        if (onFormSuccess) onFormSuccess()
        dispatch(
          addToast({
            id,
            message: i18next.t('editVehicleLabels.vehicleSuccessfullyEdited'),
          })
        )
      } else {
        dispatch(
          addToast({
            id,
            message: i18next.t('editVehicleLabels.errorEditingVehicle'),
          })
        )
      }

      dispatch(toggleEditVehiclePanel({ isActive: false }))
    },

    onError: () => {
      dispatch(
        addToast({
          id,
          message: i18next.t('editVehicleLabels.errorEditingVehicle'),
        })
      )

      dispatch(toggleEditVehiclePanel({ isActive: false }))
    },
  })

  const onSubmit = async (values: FormData) => {
    const payload: SendEditVehiclePayloadProps = {
      vin: vehicle?.vehicleDetail.vehicleAttributes.vin,
      licensePlate: values.registrationNumber,
      customerVehicleId: values.customerVehicleLabel,
      expirationDateLegalInspection: values.expirationDateLegalInspection,
      expirationDateTacho: values.expirationDateTacho,
      ...(vehicle?.vehicleDetail.vehicleAttributes.make !== 'DAF' && {
        type: values.type,
        buildDate: values.buildDate,
      }),
    }

    mutate(payload)
  }

  return (
    <SidePanel
      position="right"
      active={editVehiclePanelState}
      onClose={() => {
        dispatch(toggleEditVehiclePanel({ isActive: false }))
      }}
      title={i18next.t('editVehicleLabels.panelTitle')}
    >
      {!vehicle || isLoadingPrefillData ? (
        <SidePanelPaddedBox>
          <Spinner size={4} />
        </SidePanelPaddedBox>
      ) : (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            registrationNumber: prefillData?.licensePlate,
            customerVehicleLabel: prefillData?.customerVehicleId,
            expirationDateLegalInspection: dayjs(
              prefillData?.expirationDateLegalInspection
            ).format(NORMALIZED_DATE_FORMAT),
            expirationDateTacho: dayjs(prefillData?.expirationDateTacho).format(
              NORMALIZED_DATE_FORMAT
            ),
            type: prefillData?.type,
            buildDate: dayjs(prefillData?.buildDate).format(NORMALIZED_DATE_FORMAT),
          }}
          render={({ handleSubmit, hasValidationErrors }) => (
            <>
              <SidePanelMain>
                <SidePanelPaddedBox>
                  <Text variant="bodySmall">
                    <p>
                      <Trans
                        i18nKey="editVehicleLabels.intro"
                        values={{
                          registrationNumber: `<strong>${getVehicleLabel(
                            vehicle?.vehicleDetail
                          )}</strong>`,
                        }}
                      />
                    </p>
                  </Text>

                  <form onSubmit={handleSubmit} id="editVehiclePanelForm">
                    <TextField
                      name="registrationNumber"
                      label={i18next.t('commonLabels.licensePlate')}
                    />

                    <TextField
                      name="customerVehicleLabel"
                      label={i18next.t('commonLabels.myVehicleId')}
                    />

                    {vehicle?.vehicleDetail.vehicleAttributes.make !== 'DAF' && (
                      <>
                        <TextField name="type" label="Type" />

                        <InputDateField
                          name="buildDate"
                          validationRules={[ValidationRules.ShouldBeInThePast]}
                          label={i18next.t('myFleet.buildDate')}
                        />
                      </>
                    )}

                    {shouldShowExpirationDates() && (
                      <>
                        <InputDateField
                          name="expirationDateLegalInspection"
                          validationRules={[]}
                          label={i18next.t('myFleet.expirationDateLegalInspections')}
                        />

                        <InputDateField
                          validationRules={[]}
                          name="expirationDateTacho"
                          label={i18next.t('myFleet.expirationDateTacho')}
                        />
                      </>
                    )}
                  </form>
                </SidePanelPaddedBox>
              </SidePanelMain>
              <SidePanelFooter>
                <Button
                  type="submit"
                  disabled={hasValidationErrors || isLoading}
                  sx={{ width: '100%' }}
                  variant="primary"
                  form="editVehiclePanelForm"
                >
                  {i18next.t('commonFormLabels.confirm')}
                </Button>
              </SidePanelFooter>
            </>
          )}
        />
      )}
    </SidePanel>
  )
}

export default EditVehiclePanel
