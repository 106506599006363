import React, { useContext } from 'react'
import OrderFieldCustomerOrderNumber from '../OrderInformation/OrderField/OrderFieldCustomerOrderNumber'
import OrderFieldCustomerOrderReference from '../OrderInformation/OrderField/OrderFieldCustomerOrderReference'
import { Flex } from 'theme-ui'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import OrderStickySummaryR2CJobsheet from '../OrderStickySummary/OrderStickySummaryR2CJobsheet'

const OrderOverviewOrderNumberAndReference = () => {
  const order = useSelector((state: RootState) => state.shoppingBasketWizard.order)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)

  return (
    <Flex
      sx={{
        border: '1px solid',
        borderColor: 'gray.1',
        padding: [3, 4],
        flexWrap: 'wrap',
        gap: [3, 4],
        '> *': {
          flexGrow: 1,
          flexBasis: '200px',
        },
      }}
    >
      {order?.isR2CEnabled && !isImpersonated ? (
        <OrderStickySummaryR2CJobsheet />
      ) : (
        <OrderFieldCustomerOrderNumber />
      )}

      <OrderFieldCustomerOrderReference />
    </Flex>
  )
}

export default OrderOverviewOrderNumberAndReference
