import React from 'react'
import { TextProps } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { alphaColor } from '../../../helpers/themeHelper'
import Tag from '../Tag/Tag'

interface TagWithBeforeLabelProps extends Omit<TextProps, 'css'> {
  tagLabelBeforeContent?: string
  tagLabel: string
}

const getTagLabelBeforeContent = (content = '') => ({
  '::before': {
    content: `'${content}'`,
    position: 'relative',
    display: 'block',
    float: 'left',
    pr: 1,
    mr: 1,
    borderRight: `1px solid ${alphaColor(COLORS.WHITE, 0.4)}`,
    lineHeight: '11px',
    top: '1px',
  },
})

const TagWithBeforeLabel = ({
  tagLabelBeforeContent,
  tagLabel,
  sx,
  ...textProps
}: TagWithBeforeLabelProps) => {
  const tagBeforeSx = tagLabelBeforeContent
    ? getTagLabelBeforeContent(tagLabelBeforeContent)
    : {}

  return (
    <Tag
      label={tagLabel}
      sx={{
        ...tagBeforeSx,
        ...sx,
      }}
      {...textProps}
    />
  )
}

export default TagWithBeforeLabel
