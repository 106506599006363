import React, { FC, useMemo } from 'react'
import { DEFAULT_PART_QUANTITY } from '../constants/partSalesInformationConstants'
import { PartNumberQuantityInput } from '../hooks/services/graphql/usePartSalesInformationService'
import useWildcardPageContext from '../hooks/useWildcardPageContext'
import PartListSalesInformationProvider from '../providers/PartListSalesInformationProvider'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../providers/WildcardPageContextProvider'
import { LayoutProps } from '../types/layoutProps'
import { PartContext } from '../types/sitecoreContextProps'
import DefaultLayout from './DefaultLayout'

const PartDetailLayout: FC<LayoutProps> = ({ context }) => {
  const { part } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  const partNumbersQuantity = useMemo<PartNumberQuantityInput[]>(
    () =>
      part?.partNumber
        ? [
            {
              partNumber: part.partNumber,
              quantity: part.packageUnitQuantity || DEFAULT_PART_QUANTITY,
            },
          ]
        : [],
    [part]
  )

  return (
    <PartListSalesInformationProvider
      partNumbersQuantity={partNumbersQuantity}
      includePrice
      includeStock
    >
      <DefaultLayout context={context} />
    </PartListSalesInformationProvider>
  )
}

export default PartDetailLayout
