import React from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { COLORS } from '../../../constants/themeConstants'
import { DafCompanyVehiclesData } from '../../../services/rest/ecommerce/dafCompanyVehicles'
import Spinner from '../../atoms/Spinner/Spinner'
import MaintenanceStateChart from './ActionsStatisticsCharts/MaintenanceStateChart'
import FinanceStateChart from './ActionsStatisticsCharts/FinanceStateChart'
import CoverageStateChart from './ActionsStatisticsCharts/CoverageStateChart'

interface ActionsStatisticsProps {
  isFetching: boolean
  dafCompanyVehicles?: null | DafCompanyVehiclesData
}
const ActionsStatistics = ({
  isFetching,
  dafCompanyVehicles,
}: ActionsStatisticsProps) => {
  const numberOfVehicles =
    dafCompanyVehicles?.result.vehicles.filter((result) => !result.noServices)
      .length || 0

  const filteredActiveRMContracts = dafCompanyVehicles?.result.vehicles.filter(
    (result) => result.rmContract?.mostRecentContractForVehicle === true
  )

  return (
    <>
      <Box
        sx={{
          backgroundColor: COLORS.WHITE,
          position: 'relative',
          borderRadius: 8,
          border: '1px solid',
          borderColor: COLORS.MEDIUM_GRAY,
          padding: [3, 3, 3, 5],
        }}
      >
        <Box>
          <Text as="h3" variant="heading2">
            {i18next.t('actionsStatisticsLabels.actionsLabel')}
          </Text>
          <Text as="span" variant="bodySmall" mb={2}>
            {i18next.t('actionsStatisticsLabels.actionsSubtitleLabel')}
          </Text>
        </Box>
        {isFetching && !dafCompanyVehicles && (
          <Box
            sx={{
              paddingY: 5,
              width: '100%',
              height: '100%',
            }}
          >
            <Spinner />
          </Box>
        )}
        {!isFetching && dafCompanyVehicles && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: ['column', 'column', 'row', 'row', 'row'],
            }}
          >
            <Box
              sx={{
                width: ['100%', '100%', '180px'],
              }}
            >
              <MaintenanceStateChart
                filteredActiveRMContracts={dafCompanyVehicles?.result.vehicles}
                numberOfVehicles={numberOfVehicles}
              />
            </Box>
            <Box
              sx={{
                width: ['100%', '100%', '180px'],
              }}
            >
              <CoverageStateChart
                filteredActiveRMContracts={filteredActiveRMContracts}
                numberOfVehicles={numberOfVehicles}
              />
            </Box>
            <Box
              sx={{
                width: ['100%', '100%', '180px'],
              }}
            >
              <FinanceStateChart
                filteredActiveRMContracts={filteredActiveRMContracts}
                numberOfVehicles={numberOfVehicles}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default ActionsStatistics
