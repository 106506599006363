import React, { createContext, ReactNode, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { runOnWindow } from '../helpers/dom'
import useHasMounted from '../hooks/useHasMounted'

export interface PersistedPageContextProps {
  current: string | undefined
  previous: string | undefined
}

export interface PersistedPageProviderProps {
  isSearchContext: boolean
  children: ReactNode
}

const defaultState = { current: undefined, previous: undefined }

export const PersistedPageContext =
  createContext<PersistedPageContextProps>(defaultState)

const PersistedPageProvider = ({
  children,
  isSearchContext,
}: PersistedPageProviderProps) => {
  const location = useLocation()
  const persistedPages = useMemo(
    () => ({
      ...defaultState,
      ...runOnWindow(() =>
        JSON.parse(sessionStorage.getItem('persistedPages') || '{}')
      ),
    }),
    []
  )
  const hasMounted = useHasMounted()

  useEffect(() => {
    if (!hasMounted || !isSearchContext) return

    const currentPage = `${location.pathname}${location.search}`

    if (persistedPages.current !== currentPage) {
      sessionStorage.setItem(
        'persistedPages',
        JSON.stringify({
          current: currentPage,
          previous: persistedPages.current || undefined,
        })
      )
    }
  }, [hasMounted, location, isSearchContext, persistedPages])

  return (
    <PersistedPageContext.Provider value={persistedPages}>
      {children}
    </PersistedPageContext.Provider>
  )
}

export default PersistedPageProvider
