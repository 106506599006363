import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Text } from 'theme-ui'
import { URL_DEALER_CODE_PARAM } from '../../../constants/urlConstants'
import withData from '../../../enhancers/withData'
import {
  getMultiListFieldItems,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import {
  Datasource,
  LinkField,
  MultilistField,
  StaticDealerFilter,
  TextField,
} from '../../../types/layoutService'
import RequestAccountSelectDealer from '../../molecules/RequestAccountSelectDealer/RequestAccountSelectDealer'
import RequestAccountSelectedDealer from '../../molecules/RequestAccountSelectedDealer/RequestAccountSelectedDealer'

interface RequestAccountDealerDatasource {
  title: TextField
  selectDealerText: TextField
  selectDealerButton: LinkField
  reselectDealerButton: LinkField
  staticFilters: MultilistField<StaticDealerFilter>
}

const RequestAccountDealer: FC<Datasource<RequestAccountDealerDatasource>> = ({
  datasource: {
    title,
    selectDealerText,
    selectDealerButton,
    reselectDealerButton,
    staticFilters,
  },
}) => {
  const { search } = useLocation()
  const [dealerLocationCode, setDealerLocationCode] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const urlDealerLocationCode = getUrlQueryValue(URL_DEALER_CODE_PARAM, search)

    if (urlDealerLocationCode && dealerLocationCode !== urlDealerLocationCode) {
      setDealerLocationCode(urlDealerLocationCode)
    }
  }, [dealerLocationCode, search])

  return (
    <Box
      sx={{
        borderRadius: 'default',
      }}
    >
      <Text
        as="h2"
        variant="heading2"
        sx={{
          marginBottom: 3,
        }}
      >
        {getTextFieldValue(title)}
      </Text>

      {!dealerLocationCode && (
        <RequestAccountSelectDealer
          selectDealerText={selectDealerText}
          selectDealerButton={selectDealerButton}
        />
      )}

      {dealerLocationCode && (
        <RequestAccountSelectedDealer
          dealerLocationCode={dealerLocationCode}
          reselectDealerButton={reselectDealerButton}
          staticFilters={getMultiListFieldItems(staticFilters)}
        />
      )}
    </Box>
  )
}

export default withData(RequestAccountDealer, {
  showMessageWhenPageEditing: true,
})
