import React, { Dispatch, FC, SetStateAction, useContext, useEffect } from 'react'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import SidePanel from '../SidePanel'
import PartFilterGroupList from './PartFilterGroupList'
import PartFilterSidePanelFooter from './PartFilterSidePanelFooter'
import SidePanelBody from '../SidePanelBody'
import { TextField } from '../../../../types/layoutService'
import { FilterGroup } from '../../../../types/filterProps'
import { PartSearchResultContext } from '../../../../providers/PartSearchResultContextProvider'
import {
  FILTER_MASTER_GROUP_APPLICATION,
  FILTER_MASTER_GROUP_APPLICATION_ID,
} from '../../../../constants/searchConstants'

export interface PartFilterSidePanelDatasource {
  confirmFiltersLabel: TextField
  filtersTitle: TextField
  removeFiltersLabel: TextField
  applicationFilterGroups?: FilterGroup[]
  selectedApplicationFilterCount: number
  filterGroups?: FilterGroup[]
}

interface PartFilterSidePanelProps {
  selectedFilterGroupIdState: [
    string | undefined,
    Dispatch<SetStateAction<string | undefined>>
  ]
  lastModifiedFilterGroupState: [
    FilterGroup | undefined,
    Dispatch<SetStateAction<FilterGroup | undefined>>
  ]
  onClose: () => void
}

const PartFilterSidePanel: FC<
  PartFilterSidePanelDatasource & PartFilterSidePanelProps
> = ({
  selectedFilterGroupIdState,
  lastModifiedFilterGroupState,
  onClose,
  confirmFiltersLabel,
  filtersTitle,
  removeFiltersLabel,
  applicationFilterGroups,
  selectedApplicationFilterCount,
  filterGroups,
}) => {
  const { activeFilters } = useContext(PartSearchResultContext)

  const selectedFilterGroupId = selectedFilterGroupIdState[0]
  const lastModifiedFilterGroup = lastModifiedFilterGroupState[0]
  const setLastModifiedFilterGroup = lastModifiedFilterGroupState[1]

  useEffect(() => {
    const isApplicationFilter =
      selectedFilterGroupId &&
      lastModifiedFilterGroup &&
      selectedFilterGroupId === FILTER_MASTER_GROUP_APPLICATION_ID &&
      FILTER_MASTER_GROUP_APPLICATION.includes(lastModifiedFilterGroup.id)

    const isFilter =
      selectedFilterGroupId &&
      lastModifiedFilterGroup &&
      lastModifiedFilterGroup.id !== selectedFilterGroupId

    if ((isFilter && !isApplicationFilter) || (isApplicationFilter && !isFilter)) {
      setLastModifiedFilterGroup(undefined)
    }
    // selectedFilterGroupId & lastModifiedFilterGroup should only be validated on component mount
  }, [activeFilters, setLastModifiedFilterGroup])

  return (
    <SidePanel
      data-t-id="part-filter-panel"
      active={selectedFilterGroupId !== undefined}
      onClose={onClose}
      title={getTextFieldValue(filtersTitle)}
    >
      <SidePanelBody data-t-id="part-filter-panel-body">
        <PartFilterGroupList
          selectedFilterGroupIdState={selectedFilterGroupIdState}
          lastModifiedFilterGroupState={lastModifiedFilterGroupState}
          applicationFilterGroups={applicationFilterGroups}
          selectedApplicationFilterCount={selectedApplicationFilterCount}
          filterGroups={filterGroups}
        />
      </SidePanelBody>

      <PartFilterSidePanelFooter
        removeFiltersLabel={removeFiltersLabel}
        confirmFiltersLabel={confirmFiltersLabel}
        onClose={onClose}
      />
    </SidePanel>
  )
}

export default PartFilterSidePanel
