import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'

const PartLineLabelValue: FC<
  { label: string; manPart?: boolean } & Omit<TextProps, 'css'>
> = ({ label, children, sx, manPart = false, ...textProps }) => (
  <Text
    variant="bodySmall"
    sx={{
      display: 'flex',
      lineHeight: 'menuList',
      width: '100%',
      justifyContent: ['space-between', 'space-between', 'flex-start'],
      flexWrap: ['nowrap', 'nowrap', 'wrap'],
      '> span': {
        ':nth-of-type(1)': {
          color: 'gray.3',
          pr: 2,
        },
        ':nth-of-type(2)': {
          display: 'flex',
          alignItems: 'center',
          textAlign: ['right', 'right', 'left'],
          color: 'gray.2',
          wordBreak: 'break-word',
          paddingRight: [0, 0, 2],
          strong: {
            fontWeight: 'bold',
          },
        },
      },
      ...sx,
    }}
    {...textProps}
  >
    <span>{label}</span>

    <span
      data-t-id={
        manPart ? 'search-result-line-man-part-number' : 'search-result-part-no'
      }
    >
      {children}
    </span>
  </Text>
)

export default PartLineLabelValue
