import i18n from 'i18next'
import fetchBackend from 'i18next-fetch-backend'
import { initReactI18next } from 'react-i18next'
import { sitecoreApiHost, sitecoreApiKey } from '../temp/config'

const DICTIONARY_ENDPOINT_COMMERCE = `${sitecoreApiHost}/api/commerce/dictionary/phrases?languageName={{lng}}&sc_apikey=${sitecoreApiKey}`

export async function initializeI18nClientSide(language) {
  const options = {
    debug: false,
    lng: language,
    fallbackLng: false,
    load: 'currentOnly',
    useCookie: false,
    interpolation: {
      escapeValue: false,
    },
  }

  // Configure i18next-fetch-backend
  options.backend = {
    loadPath: DICTIONARY_ENDPOINT_COMMERCE,
    parse: (data) => {
      const parsedData = JSON.parse(data)
      if (parsedData) {
        return parsedData
      }
      return parsedData
    },
  }

  // Inject middleware
  i18n.use(fetchBackend).use(initReactI18next)

  // Initialize
  await i18n.init(options)
}
