import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import {
  DEFAULT_SORT_DIRECTION,
  SORT_DIRECTION_ASCENDING,
  SORT_DIRECTION_DESCENDING,
} from '../../../constants/searchConstants'
import { SortDirection } from '../../organisms/SearchResults/PartSearch/Table/PartSearchTable'

interface SortPartsInDirectionProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  sortDirection: SortDirection
}

const getIconColor = (
  activeSortDirection: SortDirection,
  iconSortDirection: SortDirection
) => {
  if (activeSortDirection === iconSortDirection) {
    return 'primary'
  }
  if (activeSortDirection === DEFAULT_SORT_DIRECTION) {
    return 'gray.2'
  }
  return 'gray.1'
}

const SortDirectionIcons: FC<SortPartsInDirectionProps> = ({
  sortDirection,
  sx,
  ...boxProps
}) => (
  <Box
    data-t={sortDirection}
    sx={{
      height: '8px',
      width: '5px',
      position: 'relative',
      display: 'inline-block',
      '::before, ::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        width: 0,
        height: 0,
        borderLeft: '3px solid transparent',
        borderRight: '3px solid transparent',
      },
      '::before': {
        borderBottom: '3px solid',
        borderBottomColor: getIconColor(sortDirection, SORT_DIRECTION_ASCENDING),
        top: 0,
      },
      '::after': {
        borderTop: '3px solid',
        borderTopColor: getIconColor(sortDirection, SORT_DIRECTION_DESCENDING),
        bottom: 0,
      },
      ...sx,
    }}
    {...boxProps}
  />
)

export default SortDirectionIcons
