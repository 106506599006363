import React, { ChangeEvent, ReactNode } from 'react'
import { Field } from 'react-final-form'
import { Text } from 'theme-ui'
import FormControl from '../FormControls/FormControl'
import FormFieldText from '../FormControls/FormFieldText'
import FormInputCheckbox from '../FormControls/FormInputCheckbox'
import { BaseFieldProps } from './BaseField'

export interface CheckboxFieldProps extends BaseFieldProps {
  onChange?: (event: ChangeEvent) => void
  label?: string
  value?: string
  children?: ReactNode
}

export const CheckboxField = ({
  children,
  disabled,
  name,
  value,
  validate,
  onChange,
  label,
}: CheckboxFieldProps) => (
  <Field name={name} type="checkbox" validate={validate} value={value}>
    {({ input, meta }) => (
      <FormControl
        id={name}
        error={meta.touched && (meta.error ?? meta.submitError)}
        disabled={disabled ?? meta.submitting}
      >
        <FormInputCheckbox
          name={name}
          onBlur={input.onBlur}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(e)
            input.onChange(e)
          }}
          onFocus={input.onFocus}
          checked={input.checked}
        >
          {children}
        </FormInputCheckbox>

        {label && (
          <Text variant="bodySmall" sx={{ color: 'gray.2' }}>
            {label}
          </Text>
        )}

        <FormFieldText type="error">{meta.error ?? meta.submitError}</FormFieldText>
      </FormControl>
    )}
  </Field>
)
