export const formatNumber = (
  number: number | undefined | null,
  cultureCode: string | undefined
) => {
  if (number === null) return null
  if (number === undefined) return undefined
  if (cultureCode === undefined) return undefined
  const formattedMileage = new Intl.NumberFormat(cultureCode).format(number)
  return `${formattedMileage}`
}

export const reverseFormatNumber = (
  stringNumber: string | number,
  cultureCode: string | undefined
) => {
  if (typeof stringNumber === 'number') return stringNumber
  const thousandSeparator = Intl.NumberFormat(cultureCode)
    .format(11111)
    .replace(/\p{Number}/gu, '')
  const decimalSeparator = Intl.NumberFormat(cultureCode)
    .format(1.1)
    .replace(/\p{Number}/gu, '')

  return parseFloat(
    stringNumber
      .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator}`), '.')
  )
}
