import React from 'react'
import i18next from 'i18next'
import { Box } from 'theme-ui'
import OrderFieldOrderRemark from './OrderField/OrderFieldOrderRemark'
import OrderFieldRecipients from './OrderField/Recipients/OrderRecipients'
import { URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM } from '../../../../constants/urlConstants'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import FormFieldSet from '../../../atoms/FormControls/FormFieldSet'

const OrderInformation = () => {
  const { setStep } = useShoppingBasket()

  return (
    <Box sx={{ maxWidth: '85ch' }}>
      <FormFieldSet legend={i18next.t('shoppingLabels.recipients')}>
        <OrderFieldRecipients />

        <OrderFieldOrderRemark />
      </FormFieldSet>
      <Button
        icon={ICONS.ARROW_LEFT}
        variant="outline"
        onClick={() => {
          setStep(URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM)
        }}
        sx={{
          display: ['none', 'none', 'flex'],
        }}
        data-t-id="shopping-basket-previous"
      >
        {i18next.t('paginationLabels.previous')}
      </Button>
    </Box>
  )
}

export default OrderInformation
