import React from 'react'
import { css, Global } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'
import CyrillicItalicExt from './fonts/UcCm3FwrK3iLTcvnUwkT9mI1F55MKw.woff2'
import CyrillicItalic from './fonts/UcCo3FwrK3iLTcvmYwYZ8UA3J58.woff2'
import GreekItalicExt from './fonts/UcCm3FwrK3iLTcvnUwgT9mI1F55MKw.woff2'
import GreekItalic from './fonts/UcCm3FwrK3iLTcvnUwcT9mI1F55MKw.woff2'
import VietnameseItalic from './fonts/UcCm3FwrK3iLTcvnUwsT9mI1F55MKw.woff2'
import InterLatinExt from './fonts/UcCo3FwrK3iLTcvsYwYZ8UA3J58.woff2'
import InterLatin from './fonts/UcCo3FwrK3iLTcviYwYZ8UA3.woff2'
import InterItalicLatinExt from './fonts/UcCm3FwrK3iLTcvnUwoT9mI1F55MKw.woff2'
import InterItalicLatin from './fonts/UcCm3FwrK3iLTcvnUwQT9mI1F54.woff2'
import CyrillicExt from './fonts/UcCo3FwrK3iLTcvvYwYZ8UA3J58.woff2'
import Cyrillic from './fonts/UcCo3FwrK3iLTcvmYwYZ8UA3J58.woff2'
import Vietnamese from './fonts/UcCo3FwrK3iLTcvtYwYZ8UA3J58.woff2'
import {
  DAF_THEME,
  SCROLL_BAR_WIDTH,
  SCROLL_THUMB_COLOR,
  SCROLL_THUMB_HOVER_COLOR,
  SCROLL_TRACK_COLOR,
} from '../constants/themeConstants'
import useSitecoreContext from '../hooks/useSitecoreContext'
import { ThemeProps } from '../types/themeProps'

const GlobalStyle = () => {
  const { site } = useSitecoreContext()

  return (
    <Global
      styles={(theme: ThemeProps) => css`
        /* cyrillic-ext */
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 100 900;
          font-display: swap;
          src: url(${CyrillicItalicExt}) format('woff2');
          unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
            U+FE2E-FE2F;
        }
        /* cyrillic */
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 100 900;
          font-display: swap;
          src: url(${CyrillicItalic}) format('woff2');
          unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }
        /* greek-ext */
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 100 900;
          font-display: swap;
          src: url(${GreekItalicExt}) format('woff2');
          unicode-range: U+1F00-1FFF;
        }
        /* greek */
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 100 900;
          font-display: swap;
          src: url(${GreekItalic}) format('woff2');
          unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
            U+03A3-03FF;
        }
        /* vietnamese */
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 100 900;
          font-display: swap;
          src: url(${VietnameseItalic}) format('woff2');
          unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
            U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
            U+0329, U+1EA0-1EF9, U+20AB;
        }

        /* latin-ext italic */
        @font-face {
          font-family: Inter;
          font-style: italic;
          font-weight: 100 900;
          font-display: swap;
          src: url(${InterItalicLatinExt}) format('woff2');
          unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }
        /* latin italic */
        @font-face {
          font-family: Inter;
          font-style: italic;
          font-weight: 100 900;
          font-display: swap;
          src: url(${InterItalicLatin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* cyrillic-ext */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: url(${CyrillicExt}) format('woff2');
          unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
            U+FE2E-FE2F;
        }
        /* cyrillic */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: url(${Cyrillic}) format('woff2');
          unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }
        /* greek-ext */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: url(${GreekItalicExt}) format('woff2');
          unicode-range: U+1F00-1FFF;
        }
        /* greek */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: url(${GreekItalic}) format('woff2');
          unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
            U+03A3-03FF;
        }
        /* vietnamese */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: url(${Vietnamese}) format('woff2');
          unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
            U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
            U+0329, U+1EA0-1EF9, U+20AB;
        }
        /* latin-ext */
        @font-face {
          font-family: Inter;
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: url(${InterLatinExt}) format('woff2');
          unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
            U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: Inter;
          font-style: normal;
          font-weight: 100 900;
          font-display: swap;
          src: url(${InterLatin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* CSS Normalize https://github.com/infinum/emotion-normalize */
        ${emotionNormalize}

        ::-webkit-scrollbar {
          width: ${SCROLL_BAR_WIDTH};
          background: ${theme.colors.background};
        }

        ::-webkit-scrollbar-track {
          background: ${SCROLL_TRACK_COLOR};
        }

        ::-webkit-scrollbar-thumb {
          background: ${SCROLL_THUMB_COLOR};
        }

        ::-webkit-scrollbar-thumb:hover {
          background: ${SCROLL_THUMB_HOVER_COLOR};
        }

        html {
          font-family: ${theme.fonts.body};
          scroll-behavior: smooth;
          background-color: ${site.theme.name === DAF_THEME
            ? theme.colors.gray[4]
            : theme.colors.primary};

          &.digital-magazine {
            @media (max-width: 1279px) {
              // for digital magazine when the menu is hidden
              scroll-snap-type: y mandatory;
              footer {
                scroll-snap-align: end;
              }
            }
          }
        }

        body {
          margin: 0;
          padding: 0;
          background-color: ${theme.colors.background};
        }

        #overlay-root {
          z-index: 1;
        }

        .c-sidepanel {
          box-sizing: border-box;
          margin: 0;
          margin-inline-start: auto;
          block-size: 100%;
          max-block-size: 100%;
          inline-size: 370px;
          max-inline-size: 100%;
          border: none;
          padding: 0;
        }

        .c-sidepanel--left {
          margin-inline-end: auto;
          margin-inline-start: 0;
        }

        @keyframes slide-from-right {
          0% {
            translate: 100%;
          }
          100% {
            translate: 0;
          }
        }

        @keyframes slide-from-left {
          0% {
            translate: -100%;
          }
          100% {
            translate: 0;
          }
        }

        .c-sidepanel[open] {
          animation: slide-from-right 0.4s forwards;
        }

        .c-sidepanel--left[open] {
          animation: slide-from-left 0.4s forwards;
        }
        .toast-enter {
          transform: translateX(100%);
        }

        .toast-enter-active {
          transform: translateX(0);
          transition: transform 300ms;
        }

        .toast-exit {
          transform: translateX(0);
        }

        .toast-exit-active {
          transform: translateX(100%);
          transition: transform 300ms;
        }

        @media (width < 832px) {
          .c-sidepanel {
            inline-size: 100%;
            margin-inline: 0;
          }
        }
      `}
    />
  )
}

export default GlobalStyle
