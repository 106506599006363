import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import { GraphServiceTuple } from '../../../types/graphServiceTypes'
import { sortCategoriesByPriority } from '../../../helpers/categoryHelper'

const GET_CATEGORY_LIST_QUERY = loader('./schemas/categoryListQuery.graphql')

export interface Category {
  description: string
  englishDescription: string
  id: string
  filterId: string
  priority: number
  viewType?: string
  publish: boolean
  subCategories: Category[]
}

interface CategoryListQueryVariables {
  [key: string]: any
  cultureCode: string
  countryCode: string
}

interface CategoryListQueryData {
  categories: Category[]
}

const useCategoryListService = (): GraphServiceTuple<Category[] | undefined> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [categoryList, setCategoryList] = useState<Category[] | undefined>(undefined)

  const onData = useCallback((data?: CategoryListQueryData) => {
    if (data?.categories?.length) {
      setCategoryList(sortCategoriesByPriority(data.categories))
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    CategoryListQueryData,
    CategoryListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables) {
      fetch({
        document: GET_CATEGORY_LIST_QUERY,
        variables: {
          ...baseVariables,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables])

  return [fetching, categoryList]
}

export default useCategoryListService
