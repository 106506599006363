/* eslint-disable no-restricted-globals */
import { Part } from '../types/dafResponseProps'

const SORT_ORDER_ITEM_ID_NUMERIC = '{3d45faa6-15b0-4add-886b-522f1a3a56ad}'

export enum PartSliderSortOrder {
  Manual,
  Numeric,
}

export type PartSliderSortFunc = (partNumbers: string[], partList: Part[]) => Part[]

export const normalizePartNumber = (partNumber: string) =>
  /* Remove all non numeric characters */
  partNumber.replace(/\D/g, '')

export const sortPartListNumeric: PartSliderSortFunc = (_, partList) => {
  if (partList.length === 0) {
    return partList
  }

  // Sort by normalized part number
  return partList.sort((a, b) => {
    const aNumber = parseInt(normalizePartNumber(a.partNumber ?? '0'), 10)
    const bNumber = parseInt(normalizePartNumber(b.partNumber ?? '0'), 10)

    return aNumber - bNumber
  })
}

export const sortPartListManual: PartSliderSortFunc = (partNumbers, partList) => {
  if (partList.length === 0) {
    return partList
  }

  // Sort by original index in part number array
  return partList.sort((a, b) => {
    const aIndex = partNumbers.indexOf(a.partNumber ?? '')
    const bIndex = partNumbers.indexOf(b.partNumber ?? '')

    return aIndex - bIndex
  })
}

export const sortFuncFactory = (
  sortOrder: PartSliderSortOrder
): PartSliderSortFunc => {
  switch (sortOrder) {
    case PartSliderSortOrder.Numeric:
      return sortPartListNumeric
    default:
      return sortPartListManual
  }
}

export const getSortOrderById = (id: string): PartSliderSortOrder => {
  switch (id.toLocaleLowerCase()) {
    case SORT_ORDER_ITEM_ID_NUMERIC:
      return PartSliderSortOrder.Numeric
    default:
      return PartSliderSortOrder.Manual
  }
}

export const getSortFuncById = (id: string): PartSliderSortFunc =>
  sortFuncFactory(getSortOrderById(id))
