import i18next from 'i18next'
import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import {
  URL_FILTER_PARAMETER,
  URL_PAGE_PARAM,
} from '../../../constants/urlConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import {
  getFilterStringByActiveSearchResultFilterObject,
  mapFilterStringToActiveSearchResultFilterObject,
  toggleFilter,
} from '../../../helpers/filterHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import { DealerFilter, StaticDealerFilter } from '../../../types/layoutService'
import PartSearchFilterChipContainer from '../SearchResults/PartSearch/SearchControls/FilterChipList/PartSearchFilterChipContainer'
import { getServiceTranslation } from './DealerLocatorDealerServices'
import DealerLocatorFilterChip from './DealerLocatorFilterChip'

interface DealerLocatorFilterChipListProps {
  filters: DealerFilter[]
  staticFilters?: StaticDealerFilter[]
}

const STATIC_FILTER_VALUE = '1'

const DealerLocatorFilterChipList: FC<DealerLocatorFilterChipListProps> = ({
  filters,
  staticFilters,
}) => {
  const history = useHistory()

  const activeFilters = useMemo(
    () =>
      mapFilterStringToActiveSearchResultFilterObject(
        getUrlQueryValue(URL_FILTER_PARAMETER, history.location.search)
      ),
    [history.location.search]
  )

  const clickHandler = useCallback(
    (type: string, value: string) => {
      const newFilterObject = toggleFilter(activeFilters, type, value)
      const params = new URLSearchParams(history.location.search)

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
        action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.FILTER,
        filter_type: type,
        filter_value: value,
        eventValue: value,
      })

      params.delete(URL_PAGE_PARAM)

      history.push(
        `?${getFilterStringByActiveSearchResultFilterObject(
          newFilterObject,
          params
        )}`
      )
    },
    [activeFilters, history]
  )

  if (!filters || !filters.length) return null

  return (
    <PartSearchFilterChipContainer>
      {filters.map(({ filterType, filterValue }, i) => {
        const type = getTextFieldValue(filterType)
        const value = getTextFieldValue(filterValue)
        const active = activeFilters?.[type]?.includes(value)

        return (
          <DealerLocatorFilterChip
            key={i.toString()}
            active={active}
            onClick={() => clickHandler(type, value)}
          >
            {getServiceTranslation(type, value)}
          </DealerLocatorFilterChip>
        )
      })}

      {getTextFieldValue(staticFilters?.[0]?.filterType || { value: '' }) ===
        'ExcludeDealerWithoutLoyaltyParticipation' && (
        <DealerLocatorFilterChip
          active={activeFilters?.ExcludeDealerWithoutLoyaltyParticipation?.includes(
            STATIC_FILTER_VALUE
          )}
          onClick={() =>
            clickHandler(
              'ExcludeDealerWithoutLoyaltyParticipation',
              STATIC_FILTER_VALUE
            )
          }
        >
          {i18next.t('dealerLabels.serviceLabels.nextProgram')}
        </DealerLocatorFilterChip>
      )}
    </PartSearchFilterChipContainer>
  )
}

export default DealerLocatorFilterChipList
