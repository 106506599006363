import React from 'react'
import { Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { Dealer } from '../../../../types/dealerServiceTypes'
import { StaticDealerFilter } from '../../../../types/layoutService'
import DealerLocatorDealerContact from '../../DealerLocator/DealerLocatorDealerContact'
import DealerLocatorDealerInfo from '../../DealerLocator/DealerLocatorDealerInfo'
import DealerLocatorDealerServices from '../../DealerLocator/DealerLocatorDealerServices'
import SidePanelBody from '../SidePanelBody'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import DealerLocatorDealerWeekOpeningHours from '../../DealerLocator/DealerLocatorDealerWeekOpeningHours'

interface DealerInformationOverviewProps {
  dealer: Dealer
  staticDealerFilters: StaticDealerFilter[]
  children?: React.ReactNode
}

const DealerInformationOverview = ({
  dealer,
  staticDealerFilters,
  children,
}: DealerInformationOverviewProps) => {
  const [breakpointM] = useBreakpoints('m')

  if (!dealer) return null

  return (
    <SidePanelBody sx={{ color: 'gray.2' }}>
      <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
        {children}

        {breakpointM && (
          <Text as="h4" variant="heading4" color="text">
            {dealer.name}
          </Text>
        )}

        <DealerLocatorDealerInfo
          dealer={dealer}
          excludeDealerName
          marginBottom={4}
        />

        <Text as="h4" variant="heading4" color="text">
          {i18next.t('dealerLabels.contactDetails')}
        </Text>

        <DealerLocatorDealerContact
          dealer={dealer}
          dataLayerArguments={{
            [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
            action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.CLICK_CONTACT_DETAILS_POP_IN,
          }}
          marginBottom={4}
        />
        <Text as="h4" variant="heading4" color="text">
          {i18next.t('dealerLabels.openingHours')}
        </Text>

        <DealerLocatorDealerWeekOpeningHours dealer={dealer} marginBottom={4} />

        <Text as="h4" variant="heading4" color="text">
          {i18next.t('dealerLabels.services')}
        </Text>

        <DealerLocatorDealerServices
          dealer={dealer}
          staticDealerFilters={staticDealerFilters}
          marginTop={1}
          marginBottom={4}
        />
      </Flex>
    </SidePanelBody>
  )
}

export default DealerInformationOverview
