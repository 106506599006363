import React, { FC, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { URL_PAGE_PARAM } from '../../../constants/urlConstants'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import { useNewsArticleService } from '../../../hooks/services/rest/sitecore/useNewsArticleService'
import { Datasource, Item, LinkField, TextField } from '../../../types/layoutService'
import CardGrid from '../../molecules/Card/CardGrid'
import TitleBar from '../../molecules/TitleBar/TitleBar'
import NewsArticleCard from '../NewsArticleCard/NewsArticleCard'
import Paginated from '../Paginated/Paginated'

export interface NewsArticleOverviewDatasource {
  title: TextField
  readMoreLabel: TextField
  loadingLabel: TextField
  paginationPreviousLabel: TextField
  paginationNextLabel: TextField
}

export interface NewsArticleOverviewProps {
  contextItem: Item
  newsOverviewPage: LinkField
}

const NewsArticleOverview: FC<
  NewsArticleOverviewProps & Datasource<NewsArticleOverviewDatasource>
> = ({ datasource: { title, readMoreLabel, loadingLabel }, contextItem }) => {
  const { search } = useLocation()
  const [pageIndex, setPageIndex] = useState(0)

  const [fetching, newsArticles] = useNewsArticleService(
    useMemo(
      () => ({
        item: contextItem.id,
        pageIndex,
      }),
      [contextItem.id, pageIndex]
    )
  )

  useEffect(() => {
    const urlPageNumber = getUrlQueryValue(URL_PAGE_PARAM, search)

    if (urlPageNumber) {
      setPageIndex(
        parseInt(urlPageNumber, 10) - 1 // convert page number to page index
      )
    } else if (pageIndex !== 0) {
      setPageIndex(0)
    }
  }, [search, pageIndex])

  return (
    <>
      <TitleBar title={getTextFieldValue(title)} />

      {newsArticles && (
        <Paginated
          fetching={fetching}
          fetchingLabel={getTextFieldValue(loadingLabel)}
          onNavigate={(newPageIndex) => {
            setPageIndex(newPageIndex)
          }}
          {...newsArticles.pagination}
        >
          <CardGrid sx={{ mt: [-3, -3, -5] }}>
            {newsArticles?.results?.length &&
              newsArticles.results.map((article) => (
                <NewsArticleCard
                  key={article.id}
                  href={`${contextItem.url.url}/${article.wildcardPath}`}
                  buttonText={readMoreLabel}
                  {...article}
                />
              ))}
          </CardGrid>
        </Paginated>
      )}
    </>
  )
}

export default withData(NewsArticleOverview, {
  showMessageWhenPageEditing: true,
})
