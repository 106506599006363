import React, { FC, useMemo, useState } from 'react'
import CarouselImage from '../Carousel/CarouselImage'
import EmptyGridBox from '../EmptyGridBox/EmptyGridBox'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Image } from '../../../types/dafResponseProps'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import ImageCarouselModal from '../Modal/ModalVariants/ImageCarouselModal'
import { TextField } from '../../../types/layoutService'
import {
  combineImagesSources,
  filterPlaceholderImages,
} from '../../../helpers/carouselHelper'
import useSlider, { SlidesPerPage } from '../../../hooks/slider/useSlider'
import Slider from '../Slider/Slider'
import CarouselControls from '../Carousel/CarouselControls'
import CarouselThumbnails from '../Carousel/CarouselThumbnails/CarouselThumbnails'

interface ImageCarouselProps {
  title: TextField
  images: Image[]
  additionalImages?: Image[]
  uid: string
  componentName: string
}

const ImageCarousel: FC<ImageCarouselProps> = ({
  title,
  images: receivedImages,
  additionalImages,
  uid,
  componentName,
}) => {
  const { pageEditing } = useSitecoreContext()
  const [modalOpen, setModalOpen] = useState(false)

  const images = useMemo(
    () =>
      filterPlaceholderImages(
        combineImagesSources(receivedImages, additionalImages)
      ),
    [receivedImages, additionalImages]
  )

  const settings = useMemo(
    () => ({
      slidesPerPage: 1 as SlidesPerPage,
      slideCount: images.length,
      slideGap: 24,
    }),
    [images]
  )

  const slider = useSlider(settings)

  if (pageEditing) {
    return <EmptyGridBox componentName={componentName} />
  }

  if (!images.length) return null

  return (
    <>
      <AnchorMenuTarget title={getTextFieldValue(title)} id={uid} mb={[4, 4, 7]}>
        <CarouselControls slider={slider}>
          <Slider slider={slider}>
            {images.map((image, i) => (
              <CarouselImage
                key={i.toString()}
                src={image.imageUrl}
                onClick={() => setModalOpen(true)}
              />
            ))}
          </Slider>
        </CarouselControls>

        <CarouselThumbnails images={images} slider={slider} />
      </AnchorMenuTarget>

      <ImageCarouselModal
        isActive={modalOpen}
        onClose={() => setModalOpen(false)}
        slider={slider}
        images={images}
      />
    </>
  )
}

export default ImageCarousel
