import React, { FC } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { ImageWithFocalPointField } from '../../../types/layoutService'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'

interface TestimonialAuthorProps {
  name: string
  image: ImageWithFocalPointField
  jobTitle: string
}

const TestimonialAuthor: FC<TestimonialAuthorProps> = ({
  name,
  image,
  jobTitle,
}) => (
  <Flex sx={{ flexDirection: 'row', marginTop: '4', alignItems: 'center' }}>
    <Box
      as="picture"
      sx={{
        width: ['48px', '48px', '64px'],
        height: ['48px', '48px', '64px'],
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: '3',
      }}
    >
      <SitecoreImage imageField={image} />
    </Box>

    <Flex sx={{ flexDirection: 'column' }}>
      <Text
        variant="heading3"
        sx={{
          fontSize: [1, 1, 2],
          color: 'text',
        }}
      >
        {name}
      </Text>

      <Text
        variant="bodySmall"
        sx={{
          fontSize: [1, 1, 2],
          color: 'text',
        }}
      >
        {jobTitle}
      </Text>
    </Flex>
  </Flex>
)

export default TestimonialAuthor
