import React, { FC, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import Meta from './core/Meta'
import { getPageTitle } from './helpers/siteHelper'
import DefaultLayout from './layouts/DefaultLayout'
import SlideshowModalLayout from './layouts/SlideshowModalLayout'
import { LayoutProps } from './types/layoutProps'
import {
  SITECORE_LAYOUT_ID_MAGAZINE_PAGE,
  SITECORE_LAYOUT_ID_PART_DETAIL_PAGE,
  SITECORE_LAYOUT_ID_SLIDESHOW_MODAL,
} from './constants/sitecoreConstants'
import './assets/app.css'
import PartDetailLayout from './layouts/PartDetailLayout'
import DefaultMagazineLayout from './layouts/DefaultMagazineLayout'

const layoutFactory = (layoutId?: string): FC<LayoutProps> => {
  switch (layoutId?.toLowerCase()) {
    case SITECORE_LAYOUT_ID_SLIDESHOW_MODAL.toLowerCase():
      return SlideshowModalLayout
    case SITECORE_LAYOUT_ID_PART_DETAIL_PAGE.toLowerCase():
      return PartDetailLayout
    case SITECORE_LAYOUT_ID_MAGAZINE_PAGE.toLowerCase():
      return DefaultMagazineLayout
    default:
      return DefaultLayout
  }
}

const Layout: FC<LayoutProps> = ({ context }) => {
  const { language, seo, site, page, localizedPages, productSchema, route } = context
  const LayoutComponent = useMemo(() => layoutFactory(route.layoutId), [])

  const productSchemaJson = useMemo(
    () => productSchema && JSON.stringify(productSchema),
    []
  )

  return (
    <>
      <Helmet>
        <html lang={language} translate="no" />
        <title>{getPageTitle(site?.title, page?.title, site?.titleSeparator)}</title>

        {site?.theme?.iconUrl && <link rel="icon" href={site.theme.iconUrl} />}

        {seo?.canonicalUrl && <link rel="canonical" href={seo.canonicalUrl} />}

        {localizedPages?.length > 0 &&
          localizedPages.map(({ url, cultureCode }) => (
            <link
              key={cultureCode}
              rel="alternate"
              hrefLang={cultureCode}
              href={url}
            />
          ))}

        {productSchemaJson && (
          <script type="application/ld+json">{productSchemaJson}</script>
        )}
      </Helmet>
      <Meta context={context} />
      <LayoutComponent context={context} />
    </>
  )
}

export default Layout
