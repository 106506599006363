import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'

interface StickyWrapperProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {}

const TOP_OFFSET_MOBILE = 0
const TOP_OFFSET_TABLET = 257
const TOP_OFFSET_DESKTOP = 217

const StickyWrapper: FC<StickyWrapperProps> = ({ sx, children }) => (
  <Box
    sx={{
      position: ['relative', 'relative', 'sticky'],
      top: [
        TOP_OFFSET_MOBILE,
        TOP_OFFSET_MOBILE,
        TOP_OFFSET_TABLET,
        TOP_OFFSET_TABLET,
        TOP_OFFSET_DESKTOP,
      ],
      width: '100%',
      paddingLeft: [0, 0, '103px'],
      marginBottom: [7, 7, 0],
      ...sx,
    }}
  >
    {children}
  </Box>
)

export default StickyWrapper
