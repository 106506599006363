import React, { ChangeEvent, FC, useCallback } from 'react'
import { Flex } from 'theme-ui'
import Input from '../../../atoms/inputs/Input/Input'
import Spinner from '../../../atoms/Spinner/Spinner'
import {
  getBackgroundColor,
  getTextColor,
} from '../../../../helpers/formInputHelper'

interface QuantityInputProps {
  value: string
  posting?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
  disabled?: boolean
  readOnly?: boolean
}

const QuantityInput: FC<QuantityInputProps> = ({
  value,
  posting,
  onChange,
  onBlur,
  disabled = false,
  readOnly = false,
}) => {
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') onBlur()
    },
    [onBlur]
  )

  if (posting) {
    return (
      <Flex
        data-stop-propagation
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'default',
          appearance: 'none',
        }}
      >
        <Spinner size={1} />
      </Flex>
    )
  }

  return (
    <Input
      data-is-draggable="false"
      data-stop-propagation
      data-t-id="quantity-input"
      type="number"
      sx={{
        fontSize: 2,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        border: 'none',
        outline: 'none',
        appearance: 'none',
        MozAppearance: 'textfield',
        '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        backgroundColor: getBackgroundColor(disabled),
        color: getTextColor(false, disabled),
      }}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      disabled={disabled}
      readOnly={readOnly}
    />
  )
}

export default QuantityInput
