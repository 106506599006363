import React, { FC, useContext, useMemo } from 'react'
import { Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { parseDate } from '../../../helpers/dateHelper'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { getDividerSx } from '../Divider/Divider'
import TagWithSidePanel from '../TagWithSidePanel/TagWithSidePanel'
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter'
import { Price } from '../../../hooks/services/graphql/usePartSalesInformationService'

interface SpecialPriceTagWithSidePanelProps {
  price: Price
}

const SpecialPriceTagWithSidePanel: FC<SpecialPriceTagWithSidePanelProps> = ({
  price,
}) => {
  const { dateFormatter } = useContext(LocalizationContext)
  const currencyFormatter = useCurrencyFormatter(price)

  const rows = useMemo(
    () => [
      {
        label: i18next.t('specialPriceLabels.endDate'),
        value: dateFormatter(parseDate(price?.specialPrice?.endDate)),
      },
      {
        label: i18next.t('specialPriceLabels.priceType'),
        value: price?.specialPrice?.priceType,
      },
      {
        label: i18next.t('specialPriceLabels.description'),
        value: price?.specialPrice?.priceTypeDescription,
      },
      {
        label: i18next.t('specialPriceLabels.referenceNumber'),
        value: price?.specialPrice?.referenceNumber,
      },
      {
        label: i18next.t('specialPriceLabels.price'),
        value: currencyFormatter(price?.specialPrice?.stockOrderUnitPrice, {
          currencyCodeSourceType: 'SPECIAL_PRICE',
        }),
      },
      {
        label: i18next.t('specialPriceLabels.rushPrice'),
        value: currencyFormatter(price?.specialPrice?.rushOrderUnitPrice, {
          currencyCodeSourceType: 'SPECIAL_PRICE',
        }),
      },
    ],
    [currencyFormatter, dateFormatter, price]
  )

  return (
    <TagWithSidePanel
      tagLabelBeforeContent={
        Array.from(
          currencyFormatter(price?.specialPrice?.stockOrderUnitPrice) || ''
        )[0]
      }
      tagLabel={i18next.t('promotionLabels.specialPrice')}
      sidePanelTitle={i18next.t('promotionLabels.specialPrice')}
      data-t-id="part-special-price-tag"
    >
      {rows.map(({ label, value }, i) => (
        <Text
          key={i.toString()}
          sx={{
            display: 'flex',
            width: '100%',
            color: 'gray.2',
            justifyContent: 'space-between',
            lineHeight: 'heading2',
            py: 3,
            ...(i > 0 ? getDividerSx('top') : { paddingTop: 0 }),
          }}
        >
          <Flex
            as="strong"
            sx={{
              fontWeight: 'bold',
            }}
            color="text"
          >
            {label}
          </Flex>

          <Flex
            sx={{
              flexBasis: '50%',
              justifyContent: 'flex-end',
            }}
          >
            <Flex
              as="span"
              sx={{
                alignSelf: 'flex-end',
              }}
            >
              {value}
            </Flex>
          </Flex>
        </Text>
      ))}
    </TagWithSidePanel>
  )
}

export default SpecialPriceTagWithSidePanel
