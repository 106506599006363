import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface VehicleReference {
  chassisNumber?: string
  customerReference?: string
  licenseNumber?: string
}

export interface DafVehicleReferencesResult {
  message: string | null
  result: VehicleReference[]
}

export type DafVehicleReferencesData = VehicleReference[]

const fetchDafVehicleReferences = (
  client: AxiosInstance,
  cancelToken: CancelToken,
  customerCompanyId: number,
  supplierCompanyId: number,
  user?: UserProps,
  isImpersonated?: boolean
): Promise<AxiosResponse<DafVehicleReferencesResult>> =>
  client.get('/DafVehicleReferences/', {
    params: {
      customerCompanyId,
      supplierCompanyId,
      isImpersonated: !!isImpersonated,
    },
    ...createRequestConfig(user),
    cancelToken,
  })

export default fetchDafVehicleReferences
