import React, { FC } from 'react'
import { Flex, FlexProps } from 'theme-ui'
import OriginalPrice from '../../atoms/OriginalPrice/OriginalPrice'
import Price from '../../atoms/Price/Price'
import { PriceVariant } from '../../../types/salesProps'
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter'
import { Price as PriceProps } from '../../../hooks/services/graphql/usePartSalesInformationService'
import Spinner from '../../atoms/Spinner/Spinner'

interface PartPriceProps extends Omit<FlexProps, 'css'> {
  price?: PriceProps
  variant?: PriceVariant
  useTotalPrice?: boolean
  fetching?: boolean
}

const PartPrice: FC<PartPriceProps> = ({
  price,
  useTotalPrice = false,
  variant,
  fetching,
  sx,
}) => {
  const currencyFormatter = useCurrencyFormatter(price)

  const unitPriceSelector = useTotalPrice ? 'netTotalPrice' : 'netUnitPrice'
  const retailPriceSelector = useTotalPrice ? 'retailTotalPrice' : 'retailUnitPrice'

  if (variant === 'small' && fetching) {
    return <Spinner size={3} mr={3} />
  }

  return (
    <Flex sx={{ flexDirection: 'column', ...sx }}>
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {price ? (
          <Price
            variant={variant}
            price={currencyFormatter(price[unitPriceSelector])}
            useTotalPrice={useTotalPrice}
          />
        ) : null}
        {fetching ? <Spinner size={3} ml={3} /> : null}
      </Flex>

      {price &&
      !useTotalPrice &&
      price[unitPriceSelector] !== price[retailPriceSelector] ? (
        <OriginalPrice
          variant={variant}
          price={currencyFormatter(price[retailPriceSelector])}
        />
      ) : null}
    </Flex>
  )
}

export default PartPrice
