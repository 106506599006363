import React, { FC } from 'react'
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { Box, Text } from 'theme-ui'
import { getEditableTextFieldValue } from '../../../helpers/layoutServiceHelper'
import Link from '../Link/Link'
import SitecoreImage from '../SitecoreImage/SitecoreImage'
import { RichTextField } from '../../../types/layoutService'

interface RichTextProps {
  textField: RichTextField
}

export const RICH_TEXT_PARSE_OPTIONS: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    if (domNode.type === 'tag') {
      const { name, children, attribs } = domNode as Element
      if (!children) return undefined

      if (name === 'h1') {
        return (
          <Text as="h1" variant="heading1" mb={[2]} color="text">
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Text>
        )
      }

      if (name === 'h2') {
        return (
          <Text as="h2" variant="heading2" mb={[1]} color="text">
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Text>
        )
      }

      if (name === 'h3') {
        return (
          <Text as="h3" variant="heading3" mb={[1]} color="text">
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Text>
        )
      }

      if (name === 'h4') {
        return (
          <Text as="h4" variant="heading4" mb={[1]}>
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Text>
        )
      }

      if (name === 'h5') {
        return (
          <Text as="h5" variant="heading5" mb={[1]} color="text">
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Text>
        )
      }

      if (name === 'p') {
        return (
          <Text
            as="p"
            variant="bodyContent"
            sx={{
              mb: 2,
              color: 'gray.2',
            }}
          >
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Text>
        )
      }

      if (name === 'a') {
        return (
          <Link {...attribs}>
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Link>
        )
      }

      // Sitecore injects <span /> elements in weird places
      // We remove the outer element to prevent DOM pollution
      if (name === 'span') {
        return domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)
      }

      if (name === 'li') {
        return (
          <Box as="li" variant="bodyContent" sx={{ color: 'gray.2' }}>
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Box>
        )
      }

      if (name === 'ul') {
        return (
          <Box as="ul" mb={[5]} sx={{ ul: { mb: 0 } }}>
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Box>
        )
      }

      if (name === 'ol') {
        return (
          <Box as="ol" mb={[5]}>
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Box>
        )
      }

      if (name === 'img') {
        const { alt, src } = attribs ?? {}

        return (
          <SitecoreImage
            imageField={{ alt, src }}
            sx={{ maxWidth: '100%', width: ['100%', '100%', 'auto'] }}
          />
        )
      }

      if (name === 'strong' || name === 'b') {
        return (
          <Text as="strong" sx={{ fontWeight: 'bold' }}>
            {domToReact(children as DOMNode[], RICH_TEXT_PARSE_OPTIONS)}
          </Text>
        )
      }
    }
    return undefined
  },
}

const RichText: FC<RichTextProps> = ({ textField }) => (
  <Box
    className="rich-text"
    sx={{
      mb: 5,
      ':last-child, > *:last-child': { mb: 0 },
      '*:is(ol, ul) :where(ol, ul)': { mb: 0 },
    }}
  >
    {parse(getEditableTextFieldValue(textField), RICH_TEXT_PARSE_OPTIONS)}
  </Box>
)

export default RichText
