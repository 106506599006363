import React, { FC, useState } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'
import i18next from 'i18next'
import { Datasource, TextField } from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import FormInputText from '../../atoms/FormControls/FormInputText'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { AuthenticationInfoProps } from '../../../types/userProps'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { COLORS } from '../../../constants/themeConstants'

interface HybridLoginDatasource {
  usernameLabelText: TextField
  loginButtonLabelText: TextField
  loginPlaceholderText: TextField
}

interface MyAccountProps {
  authenticationInfo: AuthenticationInfoProps
}

const HybridLogin: FC<Datasource<HybridLoginDatasource> & MyAccountProps> = ({
  datasource: { usernameLabelText, loginButtonLabelText, loginPlaceholderText },
  authenticationInfo,
}) => {
  const {
    site: {
      theme: { logoImage },
    },
  } = useSitecoreContext()
  const allowedSsoDomains = ['paccar.com', 'iodigital.com', 'fcbamsterdam.nl']

  const [postLoginUri, setPostLoginUri] = useState(authenticationInfo.loginUri)
  const handleOnChange = (fieldValue: string) => {
    const isSso = allowedSsoDomains.some((domain) =>
      fieldValue.toLowerCase().trim().endsWith(domain.toLowerCase())
    )
    if (isSso) {
      setPostLoginUri(authenticationInfo.loginUriAuth0)
    } else {
      setPostLoginUri(addIdentityServerQueryParam(fieldValue))
    }
  }

  const addIdentityServerQueryParam = (userName: string) =>
    authenticationInfo.loginUri.includes('?')
      ? `${authenticationInfo.loginUri}&username=${encodeURIComponent(userName)}`
      : `${authenticationInfo.loginUri}?username=${encodeURIComponent(userName)}`

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        '@media screen and (max-width: 480px)': {
          height: 'auto',
        },
      }}
    >
      <Flex
        sx={{
          justifyContent: 'center',
          maxWidth: '432px',
          width: ['100%', '432px', '432px'],
          margin: ['24px', '24px', 'auto'],
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            maxWidth: '480px',
            width: ['100%', '480px', '480px'],
            alignSelf: 'center',
            paddingBottom: '1.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            sx={{
              width: '50%',
              alignItems: 'center',
            }}
            src={logoImage?.src}
          />
        </Box>
        <Box sx={{ height: '238px' }}>
          <Box
            sx={{
              maxWidth: '432px',
              width: ['100%', '432px', '432px'],
              background: '#f8f8f8',
              border: '1px solid #090a0b20',
              borderRadius: '.25rem',
              padding: '1.25rem',
              '> * + *': {
                marginBlockStart: '1.5rem',
              },
            }}
          >
            <form action={postLoginUri} method="post">
              <Flex
                id="inputWrapper"
                sx={{
                  flexDirection: 'row',
                  width: ['auto', 'auto', '390px'],
                  height: '55px',
                  border: '1px solid #e2e4e5',
                  borderRadius: '.25rem',
                  background: '#FFF',
                  alignItems: 'center',
                  '&:has(input:focus)': {
                    border: '1px solid #266caa',
                  },
                }}
              >
                <Box
                  as="label"
                  sx={{
                    paddingLeft: '24px',
                    fontSize: '14px',
                    color: '#606060',
                  }}
                >
                  {usernameLabelText.value}
                </Box>
                <FormInputText
                  autoFocus
                  autoComplete=""
                  id="hybrid-login-username"
                  type="text"
                  name="Username"
                  required
                  placeholder={loginPlaceholderText.value}
                  sx={{
                    width: '100%',
                    padding: '0.9rem 1.5rem 0.9rem 0.5rem',
                    ml: '0.5rem',
                    border: 0,
                  }}
                  onChange={(e) => handleOnChange(e.target.value)}
                />
              </Flex>
              <Flex
                sx={{
                  marginTop: '24px',
                }}
              >
                <Box
                  id="hybrid-login-button"
                  as="button"
                  sx={{
                    inlineSize: '100%',
                    background:
                      '#00529b linear-gradient(180deg,#266caa,#00529b) repeat-x',
                    color: COLORS.WHITE,
                    border: COLORS.DAF_BLUE,
                    borderWidth: '1px solid transparent',
                    borderRadius: '.25rem',
                    padding: '14px 24px',
                    fontSize: '.875rem',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                >
                  <IconWrapper icon={ICONS.LOGIN} size={2} />
                  <Text as="span" sx={{ ml: 1 }}>
                    {loginButtonLabelText.value}
                  </Text>
                </Box>
              </Flex>
            </form>
            <div>
              <Flex
                sx={{
                  alignItems: 'center',
                  gap: '0.5rem',
                  '::before, ::after': {
                    content: '""',
                    blockSize: '1px',
                    backgroundColor: COLORS.GRAY,
                    flexGrow: 1,
                  },
                }}
              >
                <span>{i18next.t('commonLabels.or')}</span>
              </Flex>
            </div>
            <div>
              <form action={authenticationInfo.loginUriAuth0Pass} method="post">
                <Box
                  id="dealer-pass-button"
                  as="button"
                  sx={{
                    display: 'block',
                    backgroundColor: 'white',
                    color: COLORS.DAF_BLUE,
                    border: '1px solid',
                    borderColor: COLORS.DARK_GRAY,
                    borderRadius: '.25rem',
                    padding: '14px 24px',
                    fontSize: '.875rem',
                    fontWeight: '700',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  {i18next.t('commonLabels.loginForDealers')}
                </Box>
              </form>
            </div>
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default withData(HybridLogin)
