import { ShoppingBasketWizardState } from '.'

export default (state: ShoppingBasketWizardState, payload: boolean) => {
  if (!state?.order) return state

  return {
    ...state,
    refetchOrder: payload,
  }
}
