import React, { FC } from 'react'
import ActionItemBase from '../../../atoms/ActionItemBase/ActionItemBase'
import ClickableWrapper, {
  ClickableWrapperProps,
} from '../../../atoms/ClickableWrapper/ClickableWrapper'
import { getDividerSx } from '../../../atoms/Divider/Divider'

interface ModalAnchorProps extends ClickableWrapperProps {
  label: string
  onClick: () => void
}

const ModalAnchor: FC<ModalAnchorProps> = ({
  label,
  onClick,
  sx,
  ...clickableWrapperProps
}) => (
  <ClickableWrapper
    onClick={onClick}
    sx={{ width: '100%', mt: -3, mb: 4, ...sx }}
    {...clickableWrapperProps}
  >
    <ActionItemBase
      variant="buttonText"
      sx={{
        paddingY: 3,
        color: 'primary',
        ...getDividerSx('bottom'),
      }}
    >
      {label}
    </ActionItemBase>
  </ClickableWrapper>
)

export default ModalAnchor
