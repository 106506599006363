import React, { FC } from 'react'
import { useTheme } from '@emotion/react'
import { BoxProps, Flex } from 'theme-ui'

interface SpinnerProps extends Omit<BoxProps, 'css'> {
  size?: number
  color?: string
  variant?: typeof VARIANT.DARK | typeof VARIANT.LIGHT
}

export const SPINNER_VIEW_BOX = '0 0 40 40'
export const VARIANT = {
  LIGHT: 'light',
  DARK: 'dark',
}
export const COLOR = {
  LIGHT: 'textLight',
  DARK: 'text',
}
export const DEFAULT = {
  SIZE: 5,
  VARIANT: VARIANT.DARK,
  DEBOUNCE_RATE: 0,
  FONT_SIZE: 1,
}

const offset = 187
const duration = '1.5s'

const Spinner: FC<SpinnerProps> = ({
  variant = DEFAULT.VARIANT,
  size = DEFAULT.SIZE,
  color,
  sx,
  ...props
}) => {
  const { fontSizes } = useTheme()
  const actualColor = color || (variant === VARIANT.LIGHT ? COLOR.LIGHT : COLOR.DARK)

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        color: actualColor,
        '@keyframes rotator': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(270deg)',
          },
        },
        '@keyframes dash': {
          '0%': {
            strokeDashoffset: offset,
          },
          '50%': {
            strokeDashoffset: offset / 4,
            transform: 'rotate(135deg)',
          },
          '100%': {
            strokeDashoffset: offset,
            transform: 'rotate(450deg)',
          },
        },
        svg: {
          animation: `rotator ${duration} linear infinite`,
          circle: {
            strokeDasharray: offset,
            strokeDashoffset: 0,
            stroke: 'currentColor',
            transformOrigin: 'center',
            animation: `dash ${duration} ease-in-out infinite`,
          },
        },
        ...sx,
      }}
      {...props}
    >
      <svg
        width={fontSizes[size]}
        height={fontSizes[size]}
        viewBox="0 0 66 66"
        aria-hidden="true"
      >
        <circle
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </Flex>
  )
}

export default Spinner
