import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18next from 'i18next'
import SearchBarControls from '../../../molecules/SearchBar/SearchBarControls'
import SearchBarForm from '../../../molecules/SearchBar/SearchBarForm'
import SearchBarInput from '../../../molecules/SearchBar/SearchBarInput'
import { COLORS } from '../../../../constants/themeConstants'
import { URL_SEARCH } from '../../../../constants/urlConstants'
import useDebounce from '../../../../hooks/useDebounce'
import { MyFleetListContext } from '../../../../providers/MyFleetListContextProvider'
import { COMMERCE_SITE_CONTEXT } from '../../../../constants/environmentConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'

const MyFleetListTextInputFilter = () => {
  const {
    site: { name },
  } = useSitecoreContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const { search } = useContext(MyFleetListContext)
  const [searchString, setSearchString] = useState(search || '')
  const params = new URLSearchParams(history.location.search)

  useDebounce(searchString, 300)

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event?.target.value)
    params.set(URL_SEARCH, event?.target.value || '')
    history.push(`?${params.toString()}`)
  }

  const resetTextInputFilter = () => {
    setSearchString('')
    params.delete(URL_SEARCH)
    history.push(`?${params.toString()}`)
  }

  useEffect(() => {
    setSearchString(search || '')
  }, [search])

  return (
    <SearchBarForm sx={{ width: ['100%', '100%', '300px'] }}>
      <SearchBarInput
        required={false}
        data-t-id="multisupport-vehicle-search-text"
        ref={inputRef}
        name="search"
        placeholder={
          name === COMMERCE_SITE_CONTEXT
            ? i18next.t(
                'myFleetListSortAndFilterLabels.textInputFilterLabelCommerce'
              )
            : i18next.t('myFleetListSortAndFilterLabels.textInputFilterLabel')
        }
        autoComplete="off"
        minLength={3}
        value={searchString}
        onChange={changeHandler}
        sx={{
          backgroundColor: COLORS.LIGHT_GRAY,
        }}
      />

      <SearchBarControls
        submitButtonTestId="multisupport-vehicle-search-button"
        currentInput={searchString}
        inputRef={inputRef}
        onReset={() => resetTextInputFilter()}
      />
    </SearchBarForm>
  )
}

export default MyFleetListTextInputFilter
