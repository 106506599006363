import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC, useContext } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Flex } from 'theme-ui'
import { DatasourceBase } from '../../../types/layoutService'
import Container from '../../../components/atoms/Container/Container'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import withSubTheme from '../../../enhancers/withTheme'

const GrayTopNavigationBar: FC<DatasourceBase> = ({ rendering }) => {
  const { storeSwitcher } = useTheme()
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  return (
    <Box
      bg={
        isImpersonated
          ? storeSwitcher.backgroundImpersonated
          : storeSwitcher.background
      }
      sx={{ minHeight: 42 }}
    >
      <Container>
        <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <Box
            sx={{
              flexBasis: ['100%', '100%', '50%'],
            }}
          >
            <Placeholder
              name="jss-grid-top-navigation-first-column"
              rendering={rendering}
            />
          </Box>

          <Box
            sx={{
              flexBasis: ['100%', '100%', '50%'],
              flexDirection: ['column', 'column', 'row'],
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Placeholder
              name="jss-grid-top-navigation-second-column"
              rendering={rendering}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}
export default withSubTheme(GrayTopNavigationBar)
