import { Action } from 'redux'
import {
  OPEN_PARTS_NAVIGATION,
  CLOSE_PARTS_NAVIGATION,
  OPEN_MOBILE_NAVIGATION,
  CLOSE_MOBILE_NAVIGATION,
} from '../constants/partsNavigationConstants'

export interface OpenPartsNavigationAction
  extends Action<typeof OPEN_PARTS_NAVIGATION> {}

interface ClosePartsNavigationAction extends Action<typeof CLOSE_PARTS_NAVIGATION> {}

interface OpenMobileNavigationAction extends Action<typeof OPEN_MOBILE_NAVIGATION> {}

interface CloseMobileNavigationAction
  extends Action<typeof CLOSE_MOBILE_NAVIGATION> {}

export type PartsNavigationActions =
  | OpenPartsNavigationAction
  | ClosePartsNavigationAction
  | OpenMobileNavigationAction
  | CloseMobileNavigationAction

export const openPartsNavigation = (): OpenPartsNavigationAction => ({
  type: OPEN_PARTS_NAVIGATION,
})

export const closePartsNavigation = (): ClosePartsNavigationAction => ({
  type: CLOSE_PARTS_NAVIGATION,
})

export const openMobileNavigation = (): OpenMobileNavigationAction => ({
  type: OPEN_MOBILE_NAVIGATION,
})

export const closeMobileNavigation = (): CloseMobileNavigationAction => ({
  type: CLOSE_MOBILE_NAVIGATION,
})
