import React, { FC } from 'react'
import { Flex } from 'theme-ui'

const PaginationSeparator: FC = () => (
  <Flex
    sx={{
      height: '40px',
      width: '40px',
      marginLeft: 1,
      marginRight: 1,
      lineHeight: 'buttonText',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    ...
  </Flex>
)

export default PaginationSeparator
