import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface DMSCServiceJobsProps {
  cultureCode: string
  chassisNumber: string | undefined
  vehicleModelCode: string
  factorSetId: number
  annualDistance: number
  vehicleOptions?: VehicleOptionsEntity[] | null
}
export interface VehicleOptionsEntity {
  optionId: number
  valueId: number
}

export interface ServiceJobsResponse {
  result: ServiceJobsResponseValue
  errorMessage: string
  errorCode: string
  succeeded: boolean
}
export interface ServiceJobsResponseValue {
  resultType: number
  faults?: FaultsEntity[] | null
  exceptions?: ExceptionsEntity[] | null
  data: ServiceJobsResponseData
}
export interface FaultsEntity {
  code: string
  message: string
  key: Key
  instance: Instance
}
export interface Key {
  fieldName: string
  value: string
}
export interface Instance {}
export interface ExceptionsEntity {
  code: string
  type: string
  message: string
  stackTrace: string
}
export interface ServiceJobsResponseData {
  serviceJobs?: ServiceJobsEntity[] | null
}
export interface ServiceJobsEntity {
  code: string
  name: string
  description: string
  weekFirst: number
  kmFirst: number
  weekInterval: number
  kmInterval: number
  requestToUserDate: boolean
  requestToUserMileage: boolean
  lastKnownExecutedDate: string
  lastKnownExecutedMileage: number | null
}

const DMSCServiceJobs = (
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  payload: DMSCServiceJobsProps,
  user?: UserProps
): Promise<AxiosResponse<ServiceJobsResponse>> =>
  client.post('/dmsc/service-jobs/vehicle-options', payload, {
    cancelToken,
    params: queryParams,
    ...createRequestConfig(user),
  })

export default DMSCServiceJobs
