import React, { Fragment, useCallback, useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Box, Text } from 'theme-ui'
import { Form } from 'react-final-form'
import { FormApi, ValidationErrors } from 'final-form'
import axios from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Trans } from 'react-i18next'
import SidePanel from '../SidePanel'
import { toggleMaintenanceInputPanel } from '../../../../actions/dafCompanyVehicleActions'
import SidePanelClickableSection from '../SidePanelClickableSection/SidePanelClickableSection'
import SidePanelFooter from '../SidePanelFooter'
import {
  formatNumber,
  reverseFormatNumber,
} from '../../../../helpers/numberFormatHelper'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { COLORS } from '../../../../constants/themeConstants'
import Spinner from '../../../atoms/Spinner/Spinner'
import { InputMileageTextField } from '../../../atoms/FormFields/InputMileageTextField'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../providers/UserProvider'
import fetchDmscVehicleOptions, {
  VehicleOption,
} from '../../../../services/rest/dmsc/dmscVehicleOptions'
import { CheckboxField, SelectField, TextField } from '../../../atoms/FormFields'
import { required, ValidationRules } from '../../../../helpers/formFieldHelper'
import {
  DMSC_OPTION_DOUBLE,
  DMSC_OPTION_LIST,
  INSPECTION_INTERVAL_UK,
  MAINTENANCE_PACKAGE_UK,
  MAINTENANCE_PLAN_REQUEST_EVENT,
} from '../../../../constants/dmscConstants'
import DMSCServiceJobs, {
  DMSCServiceJobsProps,
  ServiceJobsEntity,
  ServiceJobsResponse,
  ServiceJobsResponseValue,
} from '../../../../services/rest/dmsc/DMSCServiceJobs'
import Button from '../../../atoms/Button/Button'
import VehicleServiceJobs, {
  VehicleServiceJobsProps,
} from '../../../../services/rest/serviceJobs/vehicleServiceJobs'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { InputDateField } from '../../../atoms/FormFields/InputDateField'
import { getVehicleLabel } from '../../../../helpers/vehicleHelper'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import { RootState } from '../../../../reducers'
import SidePanelPaddedBox from '../SidePanelPaddedBox'
import renderHTML from '../../../../helpers/renderHTMLHelper'

interface MaintenanceInputFormPanelProps {
  onFormSuccess?: () => void
  vehicle: VehicleDetailResponse | undefined
  noRequestForMaintenanceMessage?: string
}

interface ServiceJobsData extends ServiceJobsEntity {
  estimatedDate: string | null
  estimatedMileage: number | null
  executedDate?: string | number
  executedDistance?: string | number
}

interface CustomFormData extends FormData {
  [key: string]: any
  estimatedAnnualDistance: number
}

const MaintenanceInputFormPanel = ({
  onFormSuccess,
  vehicle,
  noRequestForMaintenanceMessage,
}: MaintenanceInputFormPanelProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const dispatch = useDispatch()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const [hasVehicleOptionsFormErrors, setHasVehicleOptionsFormErrors] =
    useState(true)
  const [hasServiceJobsFormErrors, setHasServiceJobsFormErrors] = useState(true)
  const [formSuccessfullySend, setFormSuccessfullySend] = useState(false)
  const [serviceJobsResponseData, setServiceJobsResponseData] =
    useState<ServiceJobsResponse | null>(null)
  const [filteredServiceJobs, setFilteredServiceJobs] = useState<
    ServiceJobsEntity[] | undefined
  >(undefined)
  const formApiRef = useRef<FormApi<CustomFormData> | null>(null)
  const [showServiceJobsForm, setShowServiceJobsForm] = useState(false)
  const [errorSubmitServiceJobs, setErrorSubmitServiceJobs] = useState('')

  const { maintenancePanelState, maintenanceIsAdjustMaintenance } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDMSCVehicleOptions = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDmscVehicleOptions(
      dafVehicleDetailQueryParams,
      client,
      token,
      vehicle?.vehicleDetail.vehicleAttributes.chassisNumber,
      cultureCode ?? '',
      user
    )
    return response.data
  }

  const { isLoading: isFetchingVehicleOptions, data: dmscVehicleOptionsData } =
    useQuery({
      queryKey: [
        `dmscVehicleOptionsData-${vehicle?.vehicleDetail.vehicleAttributes.chassisNumber}`,
        vehicle?.vehicleDetail.vehicleAttributes.chassisNumber,
      ],

      queryFn: () => getDMSCVehicleOptions(),
      enabled: !!actingCompanyId && !!actingSupplierId && !!vehicle,
    })

  const vehicleOptions = dmscVehicleOptionsData?.result?.vehicleOptions

  const amendableVehicleOptions = vehicleOptions
    ?.filter(
      (vehicleOption) =>
        vehicleOption.option.isAmendable &&
        vehicleOption.option.description !== MAINTENANCE_PACKAGE_UK &&
        vehicleOption.option.description !== INSPECTION_INTERVAL_UK &&
        vehicleOption.option.name !== MAINTENANCE_PACKAGE_UK &&
        vehicleOption.option.name !== INSPECTION_INTERVAL_UK &&
        ((vehicleOption.option.optionType === DMSC_OPTION_LIST &&
          vehicleOption.possibleValues.length > 1) ||
          vehicleOption.option.optionType === DMSC_OPTION_DOUBLE)
    )
    .sort((a, b) => a.option.sequenceNumber - b.option.sequenceNumber)

  const vehicleOptionsSpecification = () => {
    const filteredVehicleOptionsSpecification = amendableVehicleOptions
      ? amendableVehicleOptions.filter(
          (vehicleOption) =>
            vehicleOption.option.optionCategory === 'VehicleSpecification'
        )
      : []

    filteredVehicleOptionsSpecification.forEach((item) => {
      if (item.possibleValues && item.possibleValues.length > 0) {
        item.possibleValues.sort((a, b) => a.sortPosition - b.sortPosition)
      }
    })
    return filteredVehicleOptionsSpecification
  }

  const vehicleOptionsUsage = () => {
    const filteredVehicleUseValues = amendableVehicleOptions
      ? amendableVehicleOptions.filter(
          (vehicleOption) => vehicleOption.option.optionCategory === 'VehicleUse'
        )
      : []

    filteredVehicleUseValues.forEach((item) => {
      if (item.possibleValues && item.possibleValues.length > 0) {
        item.possibleValues.sort((a, b) => a.sortPosition - b.sortPosition)
      }
    })

    return filteredVehicleUseValues
  }

  const mergeServiceJobValues = (
    data: ServiceJobsData[] | undefined,
    values: CustomFormData
  ) => {
    if (!data && !values) return []
    // iterate over the keys in the values object
    Object.keys(values).forEach((key) => {
      // extract the 'Y' value and property from the key
      const yValue = key.split('-')[0]
      const property = key.split('-')[1]

      // find the corresponding 'Y' value in the data array
      const foundObject = data?.find((item: ServiceJobsData) => item.code === yValue)
      // if found, add the corresponding value
      if (foundObject) {
        if (property === 'executedDate') {
          foundObject[property] = values[key]
        } else if (property === 'executedDistance') {
          foundObject[property] = values[key]
            ? reverseFormatNumber(values[key], cultureCode)
            : 0
        }
      }
    })

    return data
  }

  const createPayloadToMutate = (data: ServiceJobsResponseValue) => {
    const formValues = formApiRef?.current?.getState().values as unknown as {
      [key: string]: string
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = mergeServiceJobValues(data, formValues)

    const payload = {
      vin: vehicle?.vehicleDetail.vehicleAttributes.vin,
      eventType: MAINTENANCE_PLAN_REQUEST_EVENT,
      vehicleModelCode: dmscVehicleOptionsData?.result.vehicleModelCode ?? '',
      factorSetId: dmscVehicleOptionsData?.result.factorSetId ?? 0,
      annualDistance: formValues.estimatedAnnualDistance
        ? reverseFormatNumber(formValues.estimatedAnnualDistance, cultureCode)
        : 0,
      serviceJobs: result,
    }

    return payload
  }

  const handleServiceJobsSubmit = () => {
    if (serviceJobsResponseData) {
      mutateVehicleServiceJobs(
        createPayloadToMutate(serviceJobsResponseData?.result)
      )
    }
  }

  const handleVehicleOptionsSubmit = () => {
    const formValues = formApiRef?.current?.getState().values as unknown as {
      [key: string]: string
    }
    if (!formValues || !vehicleOptions) {
      return
    }

    const formResults = Object.keys(formValues)
      .filter((key) => key !== 'estimatedAnnualDistance')
      .map((key) => ({
        optionId: +key.replace('s-', ''), // remove s- we put before optionId in the Select Field name
        valueId: +formValues[key],
      }))

    // we want to add default values for options that are not filled in by the user
    // eslint-disable-next-line no-unused-expressions
    vehicleOptions?.forEach((vehicleOption) => {
      const exists = formResults.some(
        (formResult) => formResult.optionId === vehicleOption.option.optionId
      )
      if (!exists && vehicleOption.optionValue.valueId) {
        formResults.push({
          optionId: vehicleOption.option.optionId,
          valueId: vehicleOption.optionValue.valueId,
        })
      }
    })

    const payload = {
      cultureCode: cultureCode ?? '',
      chassisNumber: vehicle?.vehicleDetail.vehicleAttributes.chassisNumber,
      vehicleModelCode: dmscVehicleOptionsData?.result.vehicleModelCode ?? '',
      factorSetId: dmscVehicleOptionsData?.result.factorSetId ?? 0,
      annualDistance: formValues.estimatedAnnualDistance
        ? reverseFormatNumber(formValues.estimatedAnnualDistance, cultureCode)
        : 0,
      vehicleOptions: formResults,
    }

    mutate(payload)

    setShowServiceJobsForm(true)
  }

  const validateForm = (values: CustomFormData) => {
    const vehicleOptionserrors: ValidationErrors = {}
    const serviceJobsErrors: ValidationErrors = {}

    if (!values.estimatedAnnualDistance) {
      vehicleOptionserrors.estimatedAnnualDistance = i18next.t(
        'commonFormLabels.required'
      )
    }

    if (values.estimatedAnnualDistance && values.estimatedAnnualDistance < 1000) {
      vehicleOptionserrors.estimatedAnnualDistance = i18next.t(
        'errorMessages.estimatedAnnualDistanceAtLeast',
        { distance: formatNumber(1000, cultureCode) }
      )
    }

    if (amendableVehicleOptions) {
      amendableVehicleOptions.forEach((vehicleOption) => {
        const key = `s-${vehicleOption.option.optionId}`
        const value = (values as unknown as { [key: string]: string })[key]
        if (!vehicleOption.hide && !value) {
          vehicleOptionserrors[key] = i18next.t('commonFormLabels.required')
          // check for step 4
          vehicleOptionserrors.amendableVehicleOptions = i18next.t(
            'commonFormLabels.required'
          )
        }
      })
    }

    if (filteredServiceJobs) {
      filteredServiceJobs.forEach((serviceJob) => {
        const dateKey = `${serviceJob.code}-executedDate`
        const mileageKey = `${serviceJob.code}-executedDistance`
        const dateValue = (values as unknown as { [key: string]: string })[dateKey]
        const mileageValue = (values as unknown as { [key: string]: number })[
          mileageKey
        ]

        if (!dateValue && !values[`checkbox-${serviceJob.code}`]) {
          serviceJobsErrors[dateKey] = i18next.t('commonFormLabels.required')
          // check for step 4
          serviceJobsErrors.serviceJobs = i18next.t('commonFormLabels.required')
        }

        if (
          dateValue &&
          !values[`checkbox-${serviceJob.code}`] &&
          new Date(dateValue) >= new Date()
        ) {
          serviceJobsErrors[dateKey] = i18next.t(
            'commonFormLabels.shouldBeInTheFutureMessage'
          )
          // check for step 4
          serviceJobsErrors.serviceJobs = i18next.t(
            'commonFormLabels.shouldBeInTheFutureMessage'
          )
        }

        if (!mileageValue && !values[`checkbox-${serviceJob.code}`]) {
          serviceJobsErrors[mileageKey] = i18next.t('commonFormLabels.required')
          // check for step 4
          serviceJobsErrors.serviceJobs = i18next.t('commonFormLabels.required')
        }

        if (mileageValue < 1000 && !values[`checkbox-${serviceJob.code}`]) {
          serviceJobsErrors[dateKey] = i18next.t('commonFormLabels.minimumValue')
          serviceJobsErrors.serviceJobs = i18next.t('commonFormLabels.minimumValue')
        }
      })
    }

    setTimeout(() => {
      setHasVehicleOptionsFormErrors(Object.keys(vehicleOptionserrors).length > 0)
      setHasServiceJobsFormErrors(Object.keys(serviceJobsErrors).length > 0)
    }, 0)

    return vehicleOptionserrors
  }

  const postDMSCVehicleOptions = async (payload: DMSCServiceJobsProps) => {
    const { token } = axios.CancelToken.source()
    const response = await DMSCServiceJobs(
      dafVehicleDetailQueryParams,
      client,
      token,
      payload,
      user
    )

    return response.data
  }

  const {
    isPending: isLoadingServiceJobs,
    mutate,
    reset: resetMutateDMSCServiceJobs,
  } = useMutation({
    mutationFn: (payload: DMSCServiceJobsProps) => postDMSCVehicleOptions(payload),
    onSuccess: (data) => {
      setServiceJobsResponseData(data)

      if (Array.isArray(data?.result)) {
        const filteredServices = data?.result.filter(
          (serviceJob: ServiceJobsEntity) =>
            serviceJob.requestToUserDate || serviceJob.requestToUserMileage
        )

        if (filteredServices.length > 0) {
          // results in a form to add values to service jobs
          setFilteredServiceJobs(filteredServices)
        } else {
          // no input needed, continue to post response
          mutateVehicleServiceJobs(createPayloadToMutate(data?.result))
        }
      }
    },
  })

  const postVehicleServiceJobs = async (payload: VehicleServiceJobsProps) => {
    const { token } = axios.CancelToken.source()
    const response = await VehicleServiceJobs(
      dafVehicleDetailQueryParams,
      client,
      token,
      payload,
      user
    )

    return response.data
  }

  const {
    isPending: isPostingServiceJobs,
    isError: hasServiceJobsError,
    mutate: mutateVehicleServiceJobs,
    reset: resetMutateVehicleServiceJobs,
  } = useMutation({
    mutationFn: (payload: VehicleServiceJobsProps) =>
      postVehicleServiceJobs(payload),
    onSuccess: (data) => {
      if (!data.messages.length) {
        return setFormSuccessfullySend(true)
      }

      return setErrorSubmitServiceJobs(data.messages[0].code)
    },
  })

  const i18nFormErrorFormatter = useCallback(
    (ruleName: string, args: string[]) =>
      i18next.t(`formValidation.${ruleName}`, { args }),
    []
  )

  const requiredValidation = useCallback(
    (value: string) => {
      required(i18nFormErrorFormatter)(value)
    },
    [i18nFormErrorFormatter]
  )

  const handleClearForm = () => {
    if (formApiRef.current) {
      formApiRef.current.reset()
    }
    setFormSuccessfullySend(false)
  }

  const handleClosePanel = () => {
    if (onFormSuccess && formSuccessfullySend) onFormSuccess()
    handleClearForm()
    resetMutateVehicleServiceJobs()
    resetMutateDMSCServiceJobs()
    setShowServiceJobsForm(false)
    setServiceJobsResponseData(null)
    dispatch(toggleMaintenanceInputPanel({ isActive: false }))
  }

  const resetFieldsState = (field: string) => {
    if (formApiRef.current) {
      formApiRef.current.resetFieldState(field)
    }
  }

  const renderListOrTextFieldOrNothing = (option: VehicleOption) => {
    return (
      <>
        {option.hide ? null : option.option.optionType === DMSC_OPTION_LIST ? (
          <SelectField
            name={`s-${option.option.optionId}`}
            placeholder={i18next.t('commonFormLabels.selectAValue')}
            options={option.possibleValues.map((value) => ({
              value: value.id.toString(),
              text: value.description,
              selected: value.isDefault,
            }))}
            initialValue={
              option.preSelectValue
                ? option.possibleValues
                    .find((value) => value.id === option.optionValue.valueId)
                    ?.id.toString()
                : undefined
            }
            label={option.option.description}
            validate={requiredValidation}
            data-t-id={`panel-requestmaintenance-input-${option.option.optionId}`}
          />
        ) : (
          <TextField
            name={`s-${option.option.optionId}`}
            label={option.option.description}
            validate={requiredValidation}
            data-t-id={`panel-requestmaintenance-input-${option.option.optionId}`}
          />
        )}
      </>
    )
  }

  const renderServiceJobFormItem = (serviceJob: ServiceJobsEntity) => (
    <Box
      sx={{
        marginBottom: 6,
        paddingBottom: 3,
        borderBottom: '1px solid',
        borderColor: COLORS.MEDIUM_GRAY,
        ':last-child': {
          borderBottom: 0,
          paddingBottom: 0,
          marginBottom: 0,
        },
      }}
    >
      <InputDateField
        name={`${serviceJob.code}-executedDate`}
        value={
          maintenanceIsAdjustMaintenance
            ? serviceJob.lastKnownExecutedDate
            : undefined
        }
        validationRules={[
          ValidationRules.Required,
          ValidationRules.ShouldBeInThePast,
        ]}
        label={i18next.t('panels.requestMaintenanceServiceDate')}
        data-t-id={`panel-requestmaintenance-input-mileage-${serviceJob.code}`}
        disabled={
          formApiRef.current?.getFieldState(`checkbox-${serviceJob.code}`)?.value
        }
      />
      <InputMileageTextField
        value={
          maintenanceIsAdjustMaintenance
            ? serviceJob.lastKnownExecutedMileage?.toString()
            : undefined
        }
        label={i18next.t('panels.requestMaintenanceServiceMileage')}
        name={`${serviceJob.code}-executedDistance`}
        data-t-id={`panel-requestmaintenance-input-mileage-${serviceJob.code}`}
        min={1000}
        disabled={
          formApiRef.current?.getFieldState(`checkbox-${serviceJob.code}`)?.value
        }
      />
      <CheckboxField
        name={`checkbox-${serviceJob.code}`}
        onChange={() => formApiRef.current?.mutators.resetValues(serviceJob.code)}
      >
        {i18next.t('panels.requestMaintenanceServiceNotPerformed')}
      </CheckboxField>
    </Box>
  )

  return (
    <SidePanel
      title={
        maintenanceIsAdjustMaintenance
          ? i18next.t('maintenance.adjustMaintenancePlan')
          : i18next.t('panels.requestMaintenancePlan')
      }
      position="right"
      active={maintenancePanelState}
      disableOutsideClick
      onClose={() => {
        handleClosePanel()
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        {(vehicle &&
          !vehicle?.vehicleActions.requestMaintenancePlan.allowed &&
          !maintenanceIsAdjustMaintenance) ||
        (vehicle &&
          !vehicle?.vehicleActions.adjustMaintenancePlan.allowed &&
          maintenanceIsAdjustMaintenance) ? (
          <SidePanelPaddedBox>
            <Text>{renderHTML(noRequestForMaintenanceMessage || '')}</Text>
          </SidePanelPaddedBox>
        ) : (
          <>
            {!showServiceJobsForm &&
              dmscVehicleOptionsData?.result &&
              !isFetchingVehicleOptions && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingY: 4,
                    paddingX: 6,
                  }}
                >
                  <Text variant="bodySmall">
                    {maintenanceIsAdjustMaintenance ? (
                      <Trans
                        i18nKey="maintenance.adjustMaintenancePlanIntro"
                        values={{
                          registrationNumber: `<strong>${getVehicleLabel(
                            vehicle?.vehicleDetail
                          )}</strong>`,
                        }}
                      />
                    ) : (
                      <>
                        {`${i18next.t(
                          'panels.requestMaintenanceServiceProvideDetails'
                        )} `}
                        <Text
                          as="span"
                          sx={{
                            fontWeight: 'bold',
                          }}
                          data-t-id="panel-requestmaintenance-registrationnumber"
                        >
                          {getVehicleLabel(vehicle?.vehicleDetail)}
                        </Text>
                      </>
                    )}
                  </Text>
                </Box>
              )}
            {showServiceJobsForm &&
              !isLoadingServiceJobs &&
              !formSuccessfullySend &&
              !hasServiceJobsError &&
              !isPostingServiceJobs &&
              !errorSubmitServiceJobs && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: COLORS.MEDIUM_GRAY,
                    marginBottom: 4,
                  }}
                >
                  <Text variant="bodySmall" sx={{ paddingY: 4, paddingX: 6 }}>
                    {`${i18next.t('panels.requestMaintenanceServiceFinalize')} `}
                    <Text
                      as="span"
                      sx={{
                        fontWeight: 'bold',
                      }}
                      data-t-id="panel-requestmaintenance-registrationnumber"
                    >
                      {getVehicleLabel(vehicle?.vehicleDetail)}
                    </Text>
                  </Text>
                </Box>
              )}
            {!vehicle || isFetchingVehicleOptions ? (
              <Box sx={{ marginTop: 4 }}>
                <Spinner size={4} />
              </Box>
            ) : null}
            {vehicle && !vehicleOptions && !isFetchingVehicleOptions && (
              <Box sx={{ paddingX: 6 }}>
                <Text color={COLORS.ALERT} sx={{ marginTop: 4, fontSize: 1 }}>
                  {`${i18next.t('errorMessages.canNotShowInformationForVehicle')} `}
                  <Text
                    as="span"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {getVehicleLabel(vehicle?.vehicleDetail)}
                  </Text>
                </Text>
              </Box>
            )}
            {!isFetchingVehicleOptions &&
              vehicleOptions &&
              !formSuccessfullySend &&
              !errorSubmitServiceJobs && (
                <>
                  <Form
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onSubmit={() => {}}
                    validate={validateForm}
                    mutators={{
                      resetValues: (args, state, utils) => {
                        utils.changeValue(state, `${args}-executedDate`, () => null)
                        utils.changeValue(state, `${args}-executedDistance`, () => 0)
                        resetFieldsState(`${args}-executedDate`)
                        resetFieldsState(`${args}-executedDistance`)
                      },
                    }}
                    render={({ handleSubmit, form }) => {
                      formApiRef.current = form
                      return (
                        <form onSubmit={handleSubmit}>
                          <Box>
                            {!showServiceJobsForm && dmscVehicleOptionsData && (
                              <>
                                <SidePanelClickableSection
                                  label={`1. ${i18next.t(
                                    'panels.requestMaintenanceAnnualDistance'
                                  )}`}
                                  initialActive
                                  data-t-id="panel-requestmaintenance-toggle-annualdistance"
                                >
                                  <InputMileageTextField
                                    min={1000}
                                    label={i18next.t(
                                      'panels.requestMaintenanceEstimatedAnnualDistance'
                                    )}
                                    name="estimatedAnnualDistance"
                                    data-t-id="panel-requestmaintenance-input-annualdistance"
                                  />
                                </SidePanelClickableSection>
                                <SidePanelClickableSection
                                  label={`2. ${i18next.t(
                                    'panels.requestMaintenanceVehicleSpecifications'
                                  )}`}
                                  data-t-id="panel-requestmaintenance-toggle-vehiclespecifications"
                                >
                                  {vehicleOptionsSpecification()?.map((option) => (
                                    <Fragment key={option.option.optionId}>
                                      {renderListOrTextFieldOrNothing(option)}
                                    </Fragment>
                                  ))}
                                </SidePanelClickableSection>
                                <SidePanelClickableSection
                                  label={`3. ${i18next.t(
                                    'panels.requestMaintenanceVehicleUsage'
                                  )}`}
                                  data-t-id="panel-requestmaintenance-toggle-vehicleusage"
                                >
                                  {vehicleOptionsUsage()?.map((option) => (
                                    <Fragment key={option.option.optionId}>
                                      {renderListOrTextFieldOrNothing(option)}
                                    </Fragment>
                                  ))}
                                </SidePanelClickableSection>
                              </>
                            )}

                            {isPostingServiceJobs ? (
                              <Box sx={{ marginTop: 4 }}>
                                <Spinner size={4} />
                              </Box>
                            ) : null}

                            {showServiceJobsForm &&
                              !hasServiceJobsError &&
                              !isPostingServiceJobs && (
                                <Box sx={{ paddingX: 6 }}>
                                  {isLoadingServiceJobs && (
                                    <Box sx={{ marginTop: 4 }}>
                                      <Spinner size={4} />
                                    </Box>
                                  )}

                                  {serviceJobsResponseData &&
                                    !formSuccessfullySend &&
                                    filteredServiceJobs?.map((serviceJob) => (
                                      <Fragment key={serviceJob.code}>
                                        <Text
                                          color={COLORS.BLACK}
                                          sx={{
                                            marginBottom: 4,
                                            fontSize: 1,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {serviceJob.description}
                                        </Text>
                                        {renderServiceJobFormItem(serviceJob)}
                                      </Fragment>
                                    ))}
                                </Box>
                              )}
                          </Box>
                          {showServiceJobsForm && hasServiceJobsError && (
                            <Box sx={{ paddingX: 6 }}>
                              <Text
                                color={COLORS.ALERT}
                                sx={{ marginTop: 4, fontSize: 1 }}
                              >
                                {i18next.t('errorMessages.pleaseTryAgainLater')}
                              </Text>
                            </Box>
                          )}
                        </form>
                      )
                    }}
                  />
                </>
              )}
            {errorSubmitServiceJobs && (
              <Box sx={{ paddingX: 6 }}>
                <Text color={COLORS.ALERT} sx={{ marginTop: 4, fontSize: 1 }}>
                  {i18next.t(
                    `panels.requestMaintenanceError${errorSubmitServiceJobs}`
                  )}
                </Text>
              </Box>
            )}
            {formSuccessfullySend && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingY: 4,
                  paddingX: 6,
                }}
              >
                <Text
                  sx={{
                    fontSize: 1,
                  }}
                  color={COLORS.GRAY}
                  data-t-id="panel-requestmaintenance-text-thankyoumessage"
                >
                  {i18next.t('panels.requestMaintenanceThankYouMessage')}
                </Text>
              </Box>
            )}
          </>
        )}
      </Box>

      <SidePanelFooter sx={{ flexDirection: 'column' }}>
        <Box>
          {!showServiceJobsForm && vehicleOptions && (
            <Button
              sx={{ width: '100%' }}
              variant="primary"
              disabled={hasVehicleOptionsFormErrors || isLoadingServiceJobs}
              onClick={handleVehicleOptionsSubmit}
              data-t-id="panel-requestmaintenance-button-next"
            >
              {i18next.t('commonFormLabels.next')}
            </Button>
          )}

          {showServiceJobsForm &&
            !hasServiceJobsError &&
            !formSuccessfullySend &&
            !errorSubmitServiceJobs && (
              <Button
                type="submit"
                disabled={
                  hasServiceJobsFormErrors ||
                  isLoadingServiceJobs ||
                  isPostingServiceJobs
                }
                sx={{ width: '100%' }}
                variant="primary"
                onClick={handleServiceJobsSubmit}
                data-t-id="panel-requestmaintenance-button-confirm"
              >
                {i18next.t('commonFormLabels.confirm')}
              </Button>
            )}

          {(hasServiceJobsError ||
            formSuccessfullySend ||
            !vehicleOptions ||
            errorSubmitServiceJobs) &&
            !isLoadingServiceJobs &&
            !isFetchingVehicleOptions && (
              <Button
                sx={{ width: '100%' }}
                variant="primary"
                onClick={() => {
                  handleClosePanel()
                }}
                data-t-id="panel-requestmaintenance-button-close"
              >
                {i18next.t('commonLabels.close')}
              </Button>
            )}
        </Box>

        {dmscVehicleOptionsData?.result &&
          !hasServiceJobsError &&
          !formSuccessfullySend && (
            <Box sx={{ textAlign: 'center', marginTop: 3 }}>
              <Text
                sx={{
                  fontSize: 0,
                }}
                color={COLORS.GRAY}
              >
                {i18next.t('panels.requestMaintenanceFooterText')}
              </Text>
            </Box>
          )}
      </SidePanelFooter>
    </SidePanel>
  )
}

export default MaintenanceInputFormPanel
