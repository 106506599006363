import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Label } from 'theme-ui'
import i18next from 'i18next'
import SidePanel from '../SidePanel'
import SidePanelFooter from '../SidePanelFooter'
import SidePanelBody from '../SidePanelBody'
import SearchBarInput from '../../../molecules/SearchBar/SearchBarInput'
import SearchBarControls from '../../../molecules/SearchBar/SearchBarControls'
import { COLORS } from '../../../../constants/themeConstants'
import {
  getJobSheetReferences,
  JobSheetsMessagesProps,
  JobSheetsReferenceData,
  JobSheetsReferenceProps,
} from '../../../../services/rest/ecommerce/r2cJobsheets'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import Spinner from '../../../atoms/Spinner/Spinner'
import Radio from '../../../atoms/inputs/Radio/Radio'
import Button from '../../../atoms/Button/Button'
import {
  OrderCustomerOrderNumber,
  OrderCustomerOrderNumberRequestProps,
  postOrderCustomerOrderNumber,
} from '../../../../services/rest/ecommerce/order/order-customer-order-number'
import useCommerceApiPostService from '../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import { CommerceResponse } from '../../../../types/commerceApi'
import toCamelCase from '../../../../helpers/camelCaseHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { useDispatch } from 'react-redux'
import {
  refetchShoppingBasketOrder,
  setCustomerOrderNumber,
} from '../../../../actions/shoppingBasket/wizard'
import {
  URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_PARAM,
} from '../../../../constants/urlConstants'
import { useHistory, useLocation } from 'react-router-dom'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import { hasRole } from '../../../../helpers/userHelper'
import { USER_ROLES_SITECORE_SHOPPING_BASKET } from '../../../../constants/userConstants'
import { OPEN_R2C_TASK_SIDEPANEL } from '../../../../constants/r2cConstants'
import { toggleR2CTaskSidepanel } from '../../../../actions/r2cActions'

interface R2CSidePanelProps {
  active: boolean
  onSidepanelClose: () => void
  partNumber?: string
  jobsheetReference?: string
}

const R2CJobsheetReferenceSidePanel = ({
  active,
  onSidepanelClose,
  partNumber,
  jobsheetReference,
}: R2CSidePanelProps) => {
  const { user } = useSitecoreContext()
  const { orderNumber } = useContext(ActiveUserStateContext)
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchString, setSearchString] = useState('')
  const [jobSheetReference, setJobSheetReference] =
    useState<JobSheetsReferenceProps | null>(null)
  const [sidePanelActive, setSidePanelActive] = useState(active)
  const [jobsheets, setJobsheets] = useState<JobSheetsReferenceData | undefined>(
    undefined
  )
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const apiQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const hasSitecoreBasketRole = hasRole(user, USER_ROLES_SITECORE_SHOPPING_BASKET)

  const changeSearchInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event?.target.value)
  }
  const resetSidePanel = () => {
    setSearchString('')
    setJobSheetReference(null)
    setJobsheets(undefined)
  }

  const getJobsheets = async () => {
    const { token } = axios.CancelToken.source()
    const response = await getJobSheetReferences(
      client,
      apiQueryParams,
      searchString,
      token,
      user
    )
    return response.data
  }

  const { isFetching, data: jobsheetReferenceData } = useQuery({
    queryKey: ['FindJobSheetsReferences', searchString],
    queryFn: () => getJobsheets(),
    enabled: searchString.length === 12,
  })

  const [posting, response, post] = useCommerceApiPostService<
    OrderCustomerOrderNumberRequestProps,
    CommerceResponse<OrderCustomerOrderNumber>
  >(postOrderCustomerOrderNumber)

  useEffect(() => {
    setJobsheets(jobsheetReferenceData)
    if (jobsheetReferenceData?.result)
      setJobSheetReference(jobsheetReferenceData?.result[0])
  }, [jobsheetReferenceData, active])

  const radioChangeHandler = (jobSheetId: JobSheetsReferenceProps) => {
    setJobSheetReference(jobSheetId)
  }

  const submitSelectedJobsheet = () => {
    post({
      orderNumber: orderNumber,
      customerOrderNumber: jobSheetReference?.reference || '',
      externalReference: jobSheetReference?.id || '',
    })
  }

  const changeStep = useCallback(
    (newStep: string) => {
      const params = new URLSearchParams(search)
      params.set(URL_SHOPPING_BASKET_WIZARD_STEP_PARAM, newStep)
      history.push(`?${params.toString()}`)
    },
    [history, search]
  )

  useEffect(() => {
    if (response?.status === 201) {
      if (response?.data?.result) {
        dispatch(setCustomerOrderNumber(response?.data?.result))
        if (hasSitecoreBasketRole) {
          changeStep(URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM)
          dispatch(refetchShoppingBasketOrder(true))
        }
      }
    }

    if (response?.status === 201 && !partNumber) {
      if (response?.data?.result) {
        dispatch(refetchShoppingBasketOrder(true))
        onSidepanelClose()
      }
    }

    if (response?.status === 201 && partNumber && jobSheetReference?.reference) {
      setSidePanelActive(false)
      if (hasSitecoreBasketRole) {
        dispatch(toggleR2CTaskSidepanel(true, partNumber))
      }
      window.parent.postMessage(
        {
          event: OPEN_R2C_TASK_SIDEPANEL,
          value: {
            partNumber,
            referenceNumber: `${jobSheetReference?.reference}|${jobSheetReference?.id}`,
          },
        },
        '*'
      )
    }
  }, [response, onSidepanelClose, partNumber, jobSheetReference])

  useEffect(() => {
    if (!active) resetSidePanel()
    setSidePanelActive(active && !isImpersonated)
  }, [active, inputRef, isImpersonated])

  useEffect(() => {
    setSearchString(jobsheetReference || '')
  }, [jobsheetReference, active])

  useEffect(() => {
    // setTimeout needed to set focus after the sidepanel is rendered
    setTimeout(() => {
      if (inputRef?.current && active) inputRef.current.focus()
    }, 1)
  }, [inputRef, active])

  return (
    <SidePanel
      position="right"
      active={sidePanelActive}
      onClose={() => {
        onSidepanelClose()
      }}
      disableOutsideClick
      title={i18next.t('r2cLabels.editR2cjobsheet')}
    >
      <SidePanelBody>
        <Flex sx={{ flexDirection: 'column' }}>
          <Box sx={{ marginBottom: 2 }}>
            <Text
              color={COLORS.DARK}
              sx={{
                fontWeight: 'bold',
                fontSize: 1,
              }}
            >
              {i18next.t('r2cLabels.findJobsheet')}
            </Text>
          </Box>
          <Box
            sx={{ position: 'relative', marginBottom: jobsheets?.result ? 4 : 0 }}
          >
            <SearchBarInput
              data-t-id="R2C-Searchtext"
              ref={inputRef}
              name="search"
              placeholder={i18next.t('r2cLabels.typeJobsheetNo')}
              autoComplete="off"
              minLength={12}
              value={searchString}
              maxLength={12}
              onChange={changeSearchInputHandler}
              sx={{
                backgroundColor: COLORS.WHITE,
                border: '1px solid',
                borderColor: COLORS.MEDIUM_GRAY,
              }}
            />

            <SearchBarControls
              submitButtonTestId="R2C-SearchButton"
              currentInput={searchString}
              inputRef={inputRef}
              onReset={() => resetSidePanel()}
            />
          </Box>
        </Flex>
        {isFetching && !jobsheets?.result && (
          <Box
            sx={{
              paddingY: 5,
              width: '100%',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </Box>
        )}
        {!isFetching &&
          jobsheets?.result?.map((jobsheet) => (
            <Label
              key={jobsheet.id}
              data-t-id={`R2C-Result-${jobsheet.reference}`}
              sx={{
                position: 'relative',
                display: 'block !important', // need to override the default display: flex
                marginBottom: 2,
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  borderRadius: 4,
                  padding: 3,
                  border: '1px solid',
                  borderColor: COLORS.MEDIUM_GRAY,
                }}
              >
                <Box sx={{ position: 'absolute', right: 2, top: 2 }}>
                  <Radio
                    onChange={() => {
                      radioChangeHandler(jobsheet)
                    }}
                    checked={jobSheetReference?.id === jobsheet.id}
                    value={jobsheet.id}
                  />
                </Box>
                <Box>
                  <Text
                    sx={{ fontSize: 2, color: COLORS.BLACK, fontWeight: 'bold' }}
                  >
                    {jobsheet.reference}
                  </Text>
                </Box>
                <Flex
                  sx={{
                    marginTop: 2,
                    justifyContent: ['flex-start', 'flex-start', 'space-between'],
                  }}
                >
                  <Box>
                    <Text sx={{ fontSize: 0, color: COLORS.GRAY }}>
                      {`${i18next.t('commonLabels.id')}: ${jobsheet.id}`}
                    </Text>
                  </Box>
                  <Box sx={{ marginLeft: [3, 3, 0] }}>
                    <Text sx={{ fontSize: 0, color: COLORS.GRAY }}>
                      {`${i18next.t('commonLabels.status')}: ${jobsheet.status}`}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Label>
          ))}
        {!isFetching &&
          jobsheets?.messages?.map((message: JobSheetsMessagesProps) => (
            <Box sx={{ marginTop: 2 }} key={message.code}>
              <Text
                sx={{
                  fontSize: 0,
                }}
                color={COLORS.ALERT}
                data-t-id="R2C-Jobsheet-ValidationText"
              >
                {i18next.t(`r2cLabels.${toCamelCase(message.code)}`, {
                  referenceNumber: searchString,
                })}
              </Text>
            </Box>
          ))}
      </SidePanelBody>
      <SidePanelFooter sx={{ flexDirection: 'column', marginTop: 'auto' }}>
        <Button
          sx={{ width: '100%', marginBottom: 3 }}
          data-t-id="R2C-SaveJobsheetNo"
          variant="primary"
          disabled={!jobSheetReference || posting}
          onClick={submitSelectedJobsheet}
        >
          {i18next.t('commonLabels.save')}
        </Button>
        <Text
          variant="smallText"
          sx={{
            textAlign: 'center',
            color: COLORS.GRAY,
          }}
        >
          {i18next.t('r2cLabels.disclaimerCancelledOrInvoicedR2cJobsheets')}
        </Text>
      </SidePanelFooter>
    </SidePanel>
  )
}

export default R2CJobsheetReferenceSidePanel
