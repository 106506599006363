import React, { FC, useMemo } from 'react'
import { Flex, Text } from 'theme-ui'
import { useLocation } from 'react-router-dom'
import { ALIGN } from '../../../constants/menuConstants'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Datasource, TextField } from '../../../types/layoutService'
import { BaseItem } from '../../atoms/NavigationItems/BaseItem'
import MenuList from '../MenuList/MenuList'
import MenuListAction from '../MenuListAction/MenuListAction'
import MenuResponsive from '../MenuResponsive/MenuResponsive'

interface LanguageSwitcherDatasource {
  title: TextField
}
interface LanguageSwitcherProps {
  languageItems: LanguageItem[]
}
interface LanguageItem {
  name: string
  url: string
  isActive?: boolean
  displayName: string
  regionName: string
}

const LanguageSwitcher: FC<
  Datasource<LanguageSwitcherDatasource> & LanguageSwitcherProps
> = ({ datasource: { title }, languageItems }) => {
  const anchorTitle = useMemo<string>(() => {
    const activeLanguageItem = languageItems.find(({ isActive }) => isActive)

    if (!activeLanguageItem) return getTextFieldValue(title)

    return `${activeLanguageItem.displayName} (${activeLanguageItem.regionName})`
  }, [languageItems, title])

  const AnchorElementMobile = useMemo(
    () => (
      <Text
        as="span"
        variant="heading4"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'inherit',
          pr: [0, 0, 0, 3],
          pl: [2, 2, 2, 0],
        }}
      >
        {anchorTitle}
      </Text>
    ),
    [anchorTitle]
  )

  const currentQuerystring = useLocation().search
  const currentHash = useLocation().hash

  const MenuListElements = useMemo(
    () => (
      <MenuList>
        {languageItems.map(({ displayName, url, isActive, regionName }) => (
          <MenuListAction
            key={url}
            href={`${url}${currentQuerystring}${currentHash}`}
            currentlySelected={isActive || false}
          >
            {`${displayName} (${regionName})`}
          </MenuListAction>
        ))}
      </MenuList>
    ),
    [languageItems, currentQuerystring, currentHash]
  )

  return (
    <Flex sx={{ position: 'relative' }}>
      {languageItems && !!languageItems.length && (
        <MenuResponsive
          testId="menu-button-language-switcher"
          align={ALIGN.RIGHT}
          title={getTextFieldValue(title)}
          anchorElement={(isMenuActive) => (
            <BaseItem includeArrow active={isMenuActive} title={anchorTitle} />
          )}
          anchorElementMobile={AnchorElementMobile}
          menuListElements={MenuListElements}
        />
      )}
    </Flex>
  )
}

export default withData(LanguageSwitcher, { checkDatasource: false })
