import React, { FC, useContext, useMemo } from 'react'
import withData from '../../../enhancers/withData'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import { Datasource, TextField } from '../../../types/layoutService'
import { UserContext } from '../../../providers/UserProvider'
import { getUserFullName, loggedIn } from '../../../helpers/userHelper'
import {
  GREETING_TYPE_USER_COMPANY_NAME,
  GREETING_TYPE_USER_FULL_NAME,
} from '../../../constants/userConstants'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import PersonalizedGreetingBannerBase from './PersonalizedGreetingBannerBase'
import PersonalizedGreetingBannerPageEditingComponent from './PersonalizedGreetingBannerPageEditingComponent'

export interface PersonalizedGreetingBannerDatasource {
  headingPrefix: TextField
  headingSuffixType: TextField
  body: TextField
}

const PersonalizedGreetingBanner: FC<
  Datasource<PersonalizedGreetingBannerDatasource>
> = ({ datasource: { headingPrefix, headingSuffixType, body } }) => {
  const { user } = useContext(UserContext)
  const { userProfile } = useContext(UserContext)
  const { activeCompany, impersonatedCompany } = useContext(
    ActiveStoreProviderContext
  )
  const suffixType = getTextFieldValue(headingSuffixType)

  const suffix = useMemo(() => {
    if (suffixType === GREETING_TYPE_USER_FULL_NAME) {
      return getUserFullName(userProfile)
    }

    if (suffixType === GREETING_TYPE_USER_COMPANY_NAME) {
      return impersonatedCompany?.name || activeCompany?.companyName
    }

    return ''
  }, [suffixType, userProfile, impersonatedCompany, activeCompany])

  if (!loggedIn(user)) return null

  return (
    <PersonalizedGreetingBannerBase
      headingPrefix={getTextFieldValue(headingPrefix)}
      suffix={suffix}
      body={getEditableTextFieldValue(body)}
    />
  )
}

export default withData(PersonalizedGreetingBanner, {
  showMessageWhenPageEditing: true,
  pageEditingComponent: PersonalizedGreetingBannerPageEditingComponent,
  showPageEditingComponentWhenPreviewing: true,
})
