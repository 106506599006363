import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import {
  getMileageValue,
  showDashWhenEmptyValue,
  validateAndGetDate,
} from '../../../helpers/vehicleHelper'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { fetchDealer } from '../../../services/rest/dealer/dealerSearch'
import ServicePlansDetailItem from './ServicePlanDetailItem'
import ServiceDetailMainServiceDealer from './ServiceDetailMainServiceDealer'
import { COLORS } from '../../../constants/themeConstants'
import { VEHICLE_DETAIL_END_TYPE_DATE } from '../../../constants/dafCompanyVehicleConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import toCamelCase from '../../../helpers/camelCaseHelper'
import { pushServiceDealer } from '../../../actions/dafCompanyVehicleActions'

interface ServicePlansDetailsProps {
  vehicle: VehicleDetailResponse
}

const ServicePlansDetails = ({ vehicle }: ServicePlansDetailsProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const client = useAxiosClient(AxiosClientType.DealerApi)
  const [isFetchingMainServiceDealer, setIsFetchingMainServiceDealer] =
    useState(false)
  const dispatch = useDispatch()
  const locationCode =
    vehicle?.rmContract?.servicePlan?.details?.mainServiceDealer?.locationCode
  const getMainServiceDealer = async () => {
    const { token } = axios.CancelToken.source()

    return fetchDealer(client, locationCode, token).then((response) => response.data)
  }
  const { isFetching, data: mainServiceDealer } = useQuery({
    queryKey: ['getMainServiceDealer'],
    queryFn: getMainServiceDealer,

    // The query will not execute until vehicle data exists
    enabled: !!locationCode,
  })

  useEffect(() => {
    setIsFetchingMainServiceDealer(isFetching)
  }, [isFetching])

  useEffect(() => {
    dispatch(
      pushServiceDealer({
        data: mainServiceDealer,
        isFetching,
      })
    )
  }, [mainServiceDealer, isFetching, dispatch])

  return (
    <>
      <Text variant="heading2" sx={{ marginBottom: [4, 4, 5] }}>
        {i18next.t('servicePlan.detailsTitle')}
      </Text>
      <Box sx={{ marginTop: 4 }}>
        <Flex>
          <ServicePlansDetailItem
            label={i18next.t('servicePlan.referenceNumberLabel')}
            value={vehicle.rmContract?.servicePlan?.details?.number}
          />
        </Flex>
        <Flex sx={{ marginTop: 1 }}>
          <ServicePlansDetailItem
            label={i18next.t('servicePlan.startDateLabel')}
            value={validateAndGetDate(
              vehicle.rmContract.servicePlan.details.startDate
            )}
          />
        </Flex>
        <Flex sx={{ marginTop: 1 }}>
          <ServicePlansDetailItem
            label={i18next.t('servicePlan.endDateLabel')}
            value={validateAndGetDate(
              vehicle.rmContract.servicePlan.details.endDate
            )}
          />
        </Flex>
        {vehicle.rmContract.servicePlan.details.cancellationDate && (
          <Flex sx={{ marginTop: 1 }}>
            <ServicePlansDetailItem
              label={i18next.t('servicePlan.canceledPerLabel')}
              value={validateAndGetDate(
                vehicle.rmContract.servicePlan.details.cancellationDate
              )}
            />
          </Flex>
        )}
        <Flex sx={{ marginTop: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Text variant="bodySmall" sx={{ fontWeight: 'bold' }}>
              {i18next.t('servicePlan.durationInMonthsLabel')}
            </Text>
          </Box>
          <Box>
            <Text variant="bodySmall" color={COLORS.GRAY}>
              {vehicle.rmContract.servicePlan.details.cancellationDate ? (
                <>
                  <Box as="span">
                    {`${vehicle.rmContract.servicePlan.details.durationInMonthsCancelDate} `}
                  </Box>
                  <Box as="span">
                    (
                    <Box as="span" sx={{ textDecoration: 'line-through' }}>
                      {`${vehicle?.rmContract?.servicePlan?.details?.durationInMonthsEndDate}`}
                    </Box>
                    )
                  </Box>
                </>
              ) : (
                showDashWhenEmptyValue(
                  vehicle?.rmContract?.servicePlan?.details?.durationInMonthsEndDate
                )
              )}
            </Text>
          </Box>
        </Flex>
        <Flex sx={{ marginTop: 1 }}>
          <ServicePlansDetailItem
            label={i18next.t('servicePlan.endTypeLabel')}
            value={
              vehicle.rmContract?.servicePlan?.details?.endType
                ? i18next.t(
                    `servicePlan.${toCamelCase(
                      vehicle.rmContract?.servicePlan?.details?.endType
                    )}`
                  )
                : '-'
            }
          />
        </Flex>
        <Flex sx={{ marginTop: 1 }}>
          <ServicePlansDetailItem
            label={i18next.t('servicePlan.yearlyMileageLabel')}
            value={getMileageValue(
              vehicle?.rmContract?.servicePlan?.details?.yearlyKilometers,
              cultureCode
            )}
          />
        </Flex>
        <Flex sx={{ marginTop: 1 }}>
          <ServicePlansDetailItem
            label={i18next.t('servicePlan.startMileageLabel')}
            value={getMileageValue(
              vehicle?.rmContract?.servicePlan?.details?.startKilometers,
              cultureCode
            )}
          />
        </Flex>
        {vehicle.rmContract?.servicePlan?.details?.endType !==
          VEHICLE_DETAIL_END_TYPE_DATE && (
          <Flex sx={{ marginTop: 1 }}>
            <ServicePlansDetailItem
              label={i18next.t('servicePlan.endMileageLabel')}
              value={getMileageValue(
                vehicle.rmContract?.servicePlan?.details?.endKilometers,
                cultureCode
              )}
            />
          </Flex>
        )}
      </Box>
      <Box
        sx={{
          marginTop: 4,
          paddingTop: 4,
          borderTop: '1px solid',
          borderColor: COLORS.MEDIUM_GRAY,
        }}
      >
        <Text variant="bodySmall" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
          {i18next.t('servicePlan.mainServicePartnerTitle')}
        </Text>

        <ServiceDetailMainServiceDealer
          serviceDealer={mainServiceDealer}
          isFetching={isFetchingMainServiceDealer}
        />
      </Box>
    </>
  )
}
export default ServicePlansDetails
