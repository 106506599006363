import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import { GraphServiceTuple } from '../../../types/graphServiceTypes'

const GET_CLASS_INFORMATION_LIST = loader(
  './schemas/classInformationListQuery.graphql'
)

export interface Property {
  description: string
  englishDescription: string
  id: number
  priority: number
  type: 'List' | 'Number' | 'Decimal' | 'Boolean' | 'String'
}

export interface Class {
  code: string
  description: string
  englishDescription: string
  properties: Property[]
}

interface ClassListQueryVariables {
  [key: string]: any
  classCodes: string[]
  cultureCode: string
}

interface ClassListQueryData {
  classes: Class[]
}

const useClassInformationListService = (
  classCodes: string[]
): GraphServiceTuple<Class[] | undefined> => {
  const isMounted = useIsMounted()

  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [classInformationList, setClassInformationList] = useState<
    Class[] | undefined
  >(undefined)

  const onData = useCallback((data?: ClassListQueryData) => {
    if (data?.classes?.length) {
      setClassInformationList(data.classes)
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    ClassListQueryData,
    ClassListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && classCodes.length) {
      fetch({
        document: GET_CLASS_INFORMATION_LIST,
        variables: {
          ...baseVariables,
          classCodes,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, classCodes])

  return [fetching, classInformationList]
}

export default useClassInformationListService
