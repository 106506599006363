import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, CancelToken } from 'axios'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import fetchDafVehicleReferences, {
  DafVehicleReferencesData,
} from '../../../../services/rest/ecommerce/dafVehicleReferences'
import { isBrowser, runOnWindow } from '../../../../helpers/dom'
import useAxiosClient from '../core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'

const DAF_VEHICLE_REFERENCES_SESSION_ID = 'dafVehicleReferences'

const useDafVehicleReferenceService = (): [
  boolean,
  DafVehicleReferencesData | undefined
] => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const persistedData = runOnWindow(
    () =>
      JSON.parse(
        decodeURIComponent(
          atob(sessionStorage.getItem(DAF_VEHICLE_REFERENCES_SESSION_ID) || '') ||
            '[]'
        )
      ) as DafVehicleReferencesData
  )

  const [fetching, setFetching] = useState(false)
  const [dafVehicleReferences, setDafVehicleReferences] = useState<
    DafVehicleReferencesData | undefined
  >(persistedData?.length ? persistedData : undefined)

  const getDafVehicleReferences = useCallback(
    async (cancelToken: CancelToken) => {
      if (!actingCompanyId || !actingSupplierId) return

      setFetching(true)

      try {
        const { data } = await fetchDafVehicleReferences(
          client,
          cancelToken,
          actingCompanyId,
          actingSupplierId,
          user,
          isImpersonated
        )

        if (data) {
          setDafVehicleReferences(data.result)

          sessionStorage.setItem(
            DAF_VEHICLE_REFERENCES_SESSION_ID,
            btoa(encodeURIComponent(JSON.stringify(data)))
          )
        } else {
          setDafVehicleReferences([])
        }
      } catch (error) {
        const err = error as AxiosError<DafVehicleReferencesData>

        setDafVehicleReferences([])

        appInsights.trackException({
          exception: new Error(
            `Unable to fetch DAF vehicle references: ${err.message} (${err.code})`
          ),
          severityLevel: SeverityLevel.Error,
          properties: {
            actingCompanyId,
            actingSupplierId,
            user,
            isImpersonated,
          },
        })
      } finally {
        setFetching(false)
      }
    },

    [actingCompanyId, actingSupplierId, appInsights, isImpersonated, user]
  )

  useEffect(() => {
    if (!isBrowser()) return

    const { token, cancel } = axios.CancelToken.source()

    const shouldFetch =
      actingCompanyId &&
      actingSupplierId &&
      typeof dafVehicleReferences === 'undefined'

    if (shouldFetch && !fetching) {
      getDafVehicleReferences(token)
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (shouldFetch && fetching) cancel()
    }
  }, [
    actingCompanyId,
    actingSupplierId,
    dafVehicleReferences,
    fetching,
    getDafVehicleReferences,
  ])

  return [fetching, dafVehicleReferences]
}

export default useDafVehicleReferenceService
