import { useTheme } from '@emotion/react'
import React from 'react'
import { useField, useFormState } from 'react-final-form'
import { Flex, Text } from 'theme-ui'
import { FONT_AWESOME_ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../IconWrapper/IconWrapper'
import RichText from '../RichText/RichText'

export const SubmitError = () => {
  const { colors } = useTheme()
  const { submitError } = useFormState({ subscription: { submitError: true } })
  const genericField = useField('generic')

  const errorMessage =
    genericField?.meta?.error || genericField?.meta?.submitError || submitError

  if (!errorMessage) return null

  return (
    <Flex mb={4}>
      <Text variant="bodyContent" mr={2}>
        <IconWrapper
          color={colors.primary}
          size={2}
          icon={FONT_AWESOME_ICONS.EXCLAMATION_TRIANGLE}
        />
      </Text>

      <Text as="div" variant="bodyContent" color="gray.2">
        {typeof errorMessage === 'string' ? (
          <Text as="p">{errorMessage}</Text>
        ) : (
          <RichText textField={errorMessage} />
        )}
      </Text>
    </Flex>
  )
}
