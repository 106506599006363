import React, { useContext } from 'react'
import { Box, Flex, Link, Text } from 'theme-ui'
import i18next from 'i18next'
import { Field } from '@sitecore-jss/sitecore-jss/layout'
import WithData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import { COLORS } from '../../../constants/themeConstants'
import Tile from '../../atoms/Tile/Tile'
import { ICONS, VectorIcon } from '../../../constants/iconConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { UserContext } from '../../../providers/UserProvider'
import { alphaColor } from '../../../helpers/themeHelper'

interface AccountOverviewDatasource {
  title: TextField
  description: TextField
  tiles: {
    results: TileProps[]
  }
}

interface TileProps {
  id: string
  title: TextField
  description: TextField
  link: LinkField
  icon: Field<VectorIcon>
}

const AccountOverview = ({
  datasource: { title, description, tiles },
}: Datasource<AccountOverviewDatasource>) => {
  const { user } = useContext(UserContext)
  return (
    <Flex sx={{ flexDirection: 'column' }}>
      {title && (
        <Text
          as="h1"
          variant="heading1"
          sx={{
            color: COLORS.BLACK,
            fontSize: 5,
            lineHeight: 'heading',
          }}
        >
          {i18next.t(getTextFieldValue(title), { name: user?.profile?.fullName })}
        </Text>
      )}
      {description && (
        <Box sx={{ width: ['100%', '66%'] }}>
          <Text
            as="p"
            variant="heading2"
            sx={{
              marginBlock: 3,
              color: COLORS.GRAY,
              lineHeight: 'body',
              fontSize: 2,
            }}
          >
            {getTextFieldValue(description)}
          </Text>
        </Box>
      )}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          gap: 3,
        }}
      >
        {tiles?.results?.map((tile: TileProps) => (
          <Tile key={tile.id}>
            {tile?.icon && (
              <Flex
                sx={{
                  background: alphaColor(COLORS.DAF_BLUE, '0.1'),
                  borderRadius: '50%',
                  height: '44px',
                  width: '44px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBlockEnd: 5,
                }}
              >
                <IconWrapper
                  icon={
                    ICONS?.[
                      (getTextFieldValue(tile?.icon).toUpperCase() ||
                        'PROFILE') as VectorIcon
                    ]
                  }
                  color={COLORS.DAF_BLUE}
                />
              </Flex>
            )}
            <Link key={tile.id} href={tile?.link?.url}>
              <Flex sx={{ alignItems: 'center' }}>
                <Text sx={{ color: COLORS.BLACK, fontWeight: '500', fontSize: 2 }}>
                  {tile?.link?.text}
                </Text>
                <IconWrapper
                  icon={ICONS.ARROW_RIGHT}
                  size={2}
                  color={COLORS.BLACK}
                />
              </Flex>
            </Link>
            <Text
              as="p"
              sx={{
                color: COLORS.GRAY,
                fontSize: 2,
                marginBlockStart: 3,
              }}
            >
              {getTextFieldValue(tile.description)}
            </Text>
          </Tile>
        ))}
      </Box>
    </Flex>
  )
}

export default WithData(AccountOverview)
