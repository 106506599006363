import React, { ReactNode, useCallback } from 'react'
import SearchBarButton from './SearchBarButton'
import SearchBarControlsWrapper from './SearchBarControlsWrapper'
import SearchBarReset from './SearchBarReset'

interface SearchBarControlsProps {
  currentInput?: string
  submitButtonTestId?: string
  resetButtonTestId?: string
  inputRef?: React.RefObject<HTMLInputElement>
  onReset?: () => void
  disabled?: boolean
  children?: ReactNode
}

const SearchBarControls = ({
  currentInput,
  inputRef,
  onReset,
  children,
  submitButtonTestId,
  resetButtonTestId,
  disabled,
}: SearchBarControlsProps) => {
  const resetInput = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.focus()

      if (onReset) onReset()
    }
  }, [inputRef, onReset])

  return (
    <>
      <SearchBarButton data-t-id={submitButtonTestId} disabled={disabled} />

      <SearchBarControlsWrapper>
        <SearchBarReset
          currentInput={currentInput}
          onClick={resetInput}
          resetButtonTestId={resetButtonTestId}
        />

        {children ?? children}
      </SearchBarControlsWrapper>
    </>
  )
}

export default SearchBarControls
