import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import parse from 'html-react-parser'
import { ICONS } from '../../../constants/iconConstants'
import { getEditableTextFieldValue } from '../../../helpers/layoutServiceHelper'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import Container from '../../atoms/Container/Container'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { TextField } from '../../../types/layoutService'
import { NotificationSeverity } from '../../../types/notificationTypes'

interface NotificationBarContentProps {
  isActive: boolean
  text: TextField
  onClose?: () => void
  severity?: NotificationSeverity
}

const severityStyles = {
  Error: {
    bg: 'error',
    color: 'textLight',
  },
  Warning: {
    bg: '#f7d1d5', // custom colors
    color: '#D50017', // custom colors
  },
  Info: {
    bg: 'primary',
    color: 'textLight',
  },
  Success: {
    bg: 'success',
    color: 'textLight',
  },
}

const NotificationBarContent: FC<NotificationBarContentProps> = ({
  isActive,
  severity = 'Info',
  onClose,
  text,
}) => {
  const [active, setActive] = useState(isActive)

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  const close = useCallback(() => {
    setActive(false)
    if (onClose) onClose()
  }, [onClose])

  if (!active) return null

  return (
    <Box sx={{ ...severityStyles[severity] }}>
      <Container
        data-container="true"
        sx={{
          minHeight: '40px',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-between',
          }}
          py={2}
        >
          {getEditableTextFieldValue(text) ? (
            <Text
              variant="bodySmall"
              sx={{
                lineHeight: 'menuList',
                p: {
                  margin: 0,
                },
                a: {
                  color: 'inherit',
                  textDecoration: 'underline',
                },
              }}
            >
              {parse(getEditableTextFieldValue(text))}
            </Text>
          ) : null}

          <ClickableWrapper onClick={close}>
            <IconWrapper icon={ICONS.CROSS} size={4} />
          </ClickableWrapper>
        </Flex>
      </Container>
    </Box>
  )
}

export default NotificationBarContent
