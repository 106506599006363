export const SEARCH_TYPE_PARTS_SEARCH = 'parts'
export const SEARCH_TYPE_ASSEMBLIES_SEARCH = 'assemblies'
export const SEARCH_TYPE_DEALER_SEARCH = 'dealer'

export const VIEW_TYPE_LINE = 'line'
export const VIEW_TYPE_TABLE = 'table'
export const VIEW_TYPE_GRID = 'grid'
export const VIEW_TYPE_DEFAULT = VIEW_TYPE_LINE
export const CATEGORY_FILTER_ID = 'CategoryId'
export const PERSISTED_SEARCH_RESULTS_VIEW_TYPE = 'persistedSearchResultsViewType'

export const DEFAULT_PARTS_PER_PAGE_COUNT = 21
export const DEFAULT_PART_SEARCH_FILTERS_COUNT = 200
export const DEFAULT_PAGE_INDEX = 0
export const MAXIMUM_AMOUNT_OF_SEARCH_RESULTS = 200
export const DEFAULT_ACTIVE_PART_SEARCH_FILTERS = null

export const MATCH_TYPE_CROSS_REFERENCE = 'CrossReference'
export const MATCH_TYPE_SUPERSESSION = 'Supersession'
export const MATCH_TYPE_ALTERNATIVE = 'Alternative'

export const CLASS_PROPERTY_TYPE_STRING = 'String'
export const CLASS_PROPERTY_TYPE_NUMBER = 'Number'
export const CLASS_PROPERTY_TYPE_DECIMAL = 'Decimal'
export const CLASS_PROPERTY_TYPE_BOOLEAN = 'Boolean'
export const CLASS_PROPERTY_TYPE_LIST = 'List'

export const CLASS_PROPERTY_TYPE_BOOLEAN_TRUE = 'True'
export const CLASS_PROPERTY_TYPE_BOOLEAN_FALSE = 'False'

export const DEFAULT_SORT_DIRECTION = 'UNSET'
export const SORT_DIRECTION_ASCENDING = 'ASC'
export const SORT_DIRECTION_DESCENDING = 'DESC'
export const DEFAULT_PROPERTIES_TO_DISPLAY_COUNT = 4

export const COLUMN_ID_PART_NUMBER = 'partNumber'
export const COLUMN_ID_SUPPLIER_PART_NUMBER = 'supplierPartNumber'
export const COLUMN_ID_BRAND = 'brand'

export const FILTER_GROUP_APPLICATION_IDS = 'ApplicationIds'
export const FILTER_GROUP_APPLICATION_MAKES_IDS = 'ApplicationMakesIds'
export const FILTER_GROUP_APPLICATION_MODELS_IDS = 'ApplicationModelsIds'
export const FILTER_MASTER_GROUP_APPLICATION = [
  FILTER_GROUP_APPLICATION_IDS,
  FILTER_GROUP_APPLICATION_MAKES_IDS,
  FILTER_GROUP_APPLICATION_MODELS_IDS,
]
export const FILTER_MASTER_GROUP_APPLICATION_ID = 'ParentFilterGroup-Application'

export const FILTER_GROUP_TYPE_CHECKBOX = 'Checkbox'
export const FILTER_GROUP_TYPE_RADIOBUTTON = 'Radiobutton'
