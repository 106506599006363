import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { useTheme } from '@emotion/react'
import { getStockLocationList } from '../../../helpers/stockHelper'
import { UserContext } from '../../../providers/UserProvider'
import { loggedIn } from '../../../helpers/userHelper'
import {
  StockInformation,
  StockLocation,
} from '../../../hooks/services/graphql/usePartSalesInformationService'
import PartStockInformationLocation from './PartStockInformationLocation'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import PartStockInformationSidePanel from '../SidePanel/PartStockInformationSidePanel/PartStockInformationSidePanel'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import { getCategoryById, getSubCategoryById } from '../../../helpers/categoryHelper'
import { EVENT_CATEGORIES } from '../../../constants/analyticsConstants'

export type StockInformationVariant = 'minimal' | 'full'

interface PartStockInformationListProps extends BoxProps {
  stockInformation?: StockInformation
  partNumber?: string
  displayPartNumber?: string
  variant?: StockInformationVariant
  componentOrigin: string
  categoryId?: string
  subcategoryId?: string
}

const PartStockInformationList: FC<PartStockInformationListProps> = ({
  stockInformation,
  partNumber,
  displayPartNumber,
  variant = 'full',
  categoryId,
  subcategoryId,
  componentOrigin,
  sx,
  ...partStockInformationListProps
}) => {
  const { categories } = useContext(CategoriesContext)
  const { space } = useTheme()
  const { user } = useContext(UserContext)
  const [sidePanelStockLocation, setSidePanelStockLocation] = useState<
    StockLocation | undefined
  >(undefined)

  const stockLocationList = getStockLocationList(stockInformation)

  const displayQuantity = useMemo(
    () => (stockLocationList?.length || 0) > 1,
    [stockLocationList]
  )

  const openSidePanel = useCallback(
    (stockLocationInformation: StockLocation) => {
      const mainCategory = getCategoryById(categoryId, categories)
      const subCategory = getSubCategoryById(subcategoryId, mainCategory)

      setSidePanelStockLocation(stockLocationInformation)

      const buttonTypeValue = () => {
        const { availabilityTime } = stockLocationInformation

        if (availabilityTime === 0) {
          return 'now'
        }

        if (availabilityTime) {
          if (availabilityTime >= 0) {
            return availabilityTime
          }
        }

        return 'call'
      }

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.STOCK_AVAILABILITY,
        button_type: buttonTypeValue(), // now | 4h | 12h | ... | call
        action: DATA_LAYER.EVENT_ACTION.CLICK,
        page_type: componentOrigin,
        part_number: partNumber,
        main_category:
          mainCategory?.englishDescription || EVENT_CATEGORIES.UNCATEGORIZED,
        sub_category:
          subCategory?.englishDescription || EVENT_CATEGORIES.UNCATEGORIZED,
      })
    },
    [categoryId, categories, subcategoryId, componentOrigin, partNumber]
  )

  if (!loggedIn(user) || !stockLocationList) return <div />

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `${displayQuantity ? 'auto ' : ''}minmax(0, 1fr)`,
          gap: displayQuantity ? `${space[1]}px ${space[2]}px` : '',
          ...sx,
        }}
      >
        {stockLocationList.map((stockLocation) => (
          <PartStockInformationLocation
            key={stockLocation.locationId}
            stockLocation={stockLocation}
            variant={variant}
            callback={() => openSidePanel(stockLocation)}
            displayQuantity={displayQuantity}
            dataTId={`stock-state-${stockLocation.locationId}`}
            {...partStockInformationListProps}
          />
        ))}
      </Box>

      <PartStockInformationSidePanel
        partNumber={partNumber}
        displayPartNumber={displayPartNumber}
        stockLocation={sidePanelStockLocation}
        active={!!sidePanelStockLocation}
        onClose={() => setSidePanelStockLocation(undefined)}
      />
    </>
  )
}

export default PartStockInformationList
