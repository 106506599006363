import React from 'react'

interface HighlightProps {
  search?: string
  children: React.ReactNode
}
const Highlight = ({ search, children }: HighlightProps) => {
  if (typeof children !== 'string') {
    return children
  }

  const regex = new RegExp(`(${search})`, 'gi')
  const parts = children.split(regex)

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === search?.toLowerCase() ? (
          <b key={index}>{part}</b>
        ) : (
          part
        )
      )}
    </>
  )
}

export default Highlight
