import { ICONS } from '../constants/iconConstants'
import { BREAKPOINTS, BREAKPOINT_XS, COLORS } from '../constants/themeConstants'
import { alphaColor } from '../helpers/themeHelper'
import { ThemeProps } from '../types/themeProps'

const defaultTheme: ThemeProps = {
  breakpoints: BREAKPOINTS.filter(({ label }) => label !== BREAKPOINT_XS) // filter xs for rebase usage
    .map(({ value }) => `${value}px`),
  transitions: ['100ms', '200ms', '300ms', '400ms'],
  colors: {
    text: COLORS.BLACK,
    textLight: COLORS.WHITE,
    background: COLORS.WHITE,
    primary: COLORS.TRP_BLUE,
    primaryDisabled: alphaColor(COLORS.TRP_BLUE, 0.2),
    gray: [COLORS.LIGHT_GRAY, COLORS.MEDIUM_GRAY, COLORS.GRAY, COLORS.DARK],
    darkGray: COLORS.DARK_GRAY,
    dark: COLORS.DARK,
    highlight: COLORS.DAF_DARK_BLUE,
    success: COLORS.CONFIRMATION,
    overlay: alphaColor(COLORS.BLACK, 0.16),
    orange: [COLORS.ORANGE, COLORS.ORANGE_DARK],
    promotion: [COLORS.PROMOTION, COLORS.PROMOTION_DARK],
    error: COLORS.ALERT,
    disabled: COLORS.MEDIUM_GRAY,
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    numeric: 'Arial, sans-serif',
  },
  fontSizes: [
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '3rem',
    '4rem',
    '6rem',
  ],
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 500,
    normalNumeric: 400,
    boldNumeric: 600,
  },
  lineHeights: {
    heading1: '40px',
    heading2: '32px',
    heading3: '24px',
    bodyContent: 2,
    body: 1.5,
    bodySmall: 1.7142857143,
    smallText: '16px',
    heading: 1.25,
    buttonText: '24px',
    menuList: '24px',
  },
  space: [0, 4, 8, 17, 24, 32, 40, 64, 80, 128],
  radii: {
    default: 4,
    large: 8,
    circle: 99999,
  },
  // rebass variants
  text: {
    heading1: {
      fontWeight: 'normal',
      fontSize: 5,
      lineHeight: 'heading1',
    },
    heading2: {
      fontWeight: 'normal',
      fontSize: 4,
      lineHeight: 'heading2',
    },
    heading3: {
      fontWeight: 'bold',
      fontSize: 2,
      lineHeight: 'heading3',
    },
    heading4: {
      fontWeight: 'bold',
      fontSize: 1,
      lineHeight: 'heading3',
    },
    heading5: {
      fontWeight: 'bold',
      fontSize: 0,
      lineHeight: 'smallText',
    },
    bodyContent: {
      fontWeight: 'normal',
      fontSize: 2,
      lineHeight: 'bodyContent',
    },
    bodyCompact: {
      fontWeight: 'normal',
      fontSize: 2,
      lineHeight: 'body',
    },
    bodySmall: {
      fontWeight: 'normal',
      fontSize: 1,
      lineHeight: 'bodySmall',
    },
    smallText: {
      fontWeight: 'normal',
      fontSize: 0,
      lineHeight: 'bodySmall',
    },
    buttonText: {
      fontWeight: 'bold',
      fontSize: 1,
      lineHeight: 'buttonText',
    },
  },
  variants: {
    visuallyHidden: {
      border: '0 !important',
      clip: 'rect(0 0 0 0) !important',
      clipPath: 'inset(50%) !important',
      blockSize: '1px !important',
      margin: '-1px !important',
      overflow: 'hidden !important',
      padding: '0 !important',
      position: 'absolute !important',
      whiteSpace: 'nowrap !important',
      inlineSize: '1px !important',
    },
  },
  urgentTasks: {
    urgent: {
      backgroundColor: alphaColor(COLORS.ALERT, 0.04),
      padding: 3,
      borderLeft: '5px solid',
      borderColor: COLORS.ALERT,
      borderRadius: 'default',
      marginBottom: '1px',
      hoverColor: COLORS.ALERT_DARK,
    },
    urgentLarge: {
      backgroundColor: alphaColor(COLORS.ALERT, 0.04),
      padding: 3,
      borderLeft: '5px solid',
      borderColor: COLORS.ALERT,
      borderRadius: 'default',
      marginBottom: '1px',
      hoverColor: COLORS.ALERT_DARK,
    },
    warning: {
      backgroundColor: alphaColor(COLORS.PROMOTION, 0.16),
      padding: 3,
      borderLeft: '5px solid',
      borderColor: COLORS.PROMOTION,
      borderRadius: 'default',
      marginBottom: '1px',
      hoverColor: COLORS.PROMOTION_DARK,
    },
    ok: {
      backgroundColor: COLORS.GREEN_LIGHT,
      padding: 3,
      borderLeft: '5px solid',
      borderColor: COLORS.GREEN,
      borderRadius: 'default',
      marginBottom: '1px',
    },
  },
  urgentTaskIcons: {
    urgent: ICONS.ARROW_RIGHT,
    urgentLarge: ICONS.ALERT,
    ok: ICONS.CHECK,
  },
  buttons: {
    primary: {
      color: COLORS.WHITE,
      bg: COLORS.TRP_BLUE,
      borderRadius: '40px',
      border: 'none',
      ':hover, :focus, :active, &.active': {
        bg: COLORS.TRP_DARK_BLUE,
        border: 'none',
        color: COLORS.WHITE,
      },
    },
    outline: {
      variant: 'buttons.primary',
      color: COLORS.TRP_BLUE,
      bg: COLORS.WHITE,
      boxShadow: 'none',
      border: '1px solid',
      borderColor: COLORS.DARK_GRAY,
      ':hover, :focus, :active, &.active': {
        border: '1px solid',
        borderColor: COLORS.TRP_BLUE,
        color: COLORS.TRP_BLUE,
      },
    },
    ghost: {
      variant: 'buttons.outline',
      color: COLORS.WHITE,
      borderColor: COLORS.WHITE,
      bg: 'transparent',
      ':hover, :focus, :active, &.active': {
        borderColor: COLORS.WHITE,
        bg: COLORS.WHITE,
        color: COLORS.TRP_BLUE,
      },
    },
    infinite: {
      color: COLORS.DAF_BLUE,
      border: 'none',
      bg: 'transparent',
    },
  },
  mainNavigation: {
    background: COLORS.TRP_BLUE,
    textColor: COLORS.WHITE,
    textActiveColor: COLORS.WHITE,
    parentLinkColor: COLORS.WHITE,
    parentLinkVariant: 'ghost',
    searchBarBackground: COLORS.WHITE,
    dividerColor: COLORS.WHITE,
    dividerOpacity: 0.16,
  },
  footer: {
    background: COLORS.TRP_BLUE,
  },
  storeSwitcher: {
    background: COLORS.DARK,
    backgroundImpersonated: COLORS.ALERT,
  },
  teaserImageBlock: {
    background: COLORS.TRP_BLUE,
  },
  banner: {
    backgroundColor: COLORS.TRP_BLUE,
    backgroundColorMobile: COLORS.TRP_BLUE,
  },
  forms: {
    checkbox: {
      color: COLORS.MEDIUM_GRAY,
    },
  },
  partFilterPanel: {
    width: '360px',
  },
  search: {
    tabBarBackgroundColor: COLORS.TRP_BLUE,
    titleBarBackgroundColor: COLORS.TRP_BLUE,
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'normal',
      lineHeight: 'body',
      fontSize: 2,
    },
    a: {
      textDecoration: 'none',
      color: COLORS.TRP_BLUE,
    },
  },
}

export default defaultTheme
