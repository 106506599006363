import React, { FC } from 'react'
import { Box, Text, TextProps } from 'theme-ui'
import { FormField, TextViewModel } from '@sitecore-jss/sitecore-jss-forms'
import { FieldWithValueProps } from '@sitecore-jss/sitecore-jss-react-forms'

const getTextPropsForHtmlTag = (htmlTag?: string): Omit<TextProps, 'css'> => {
  switch (htmlTag) {
    case 'h1':
      return { variant: 'heading1', as: 'h1' }
    case 'h2':
      return { variant: 'heading2', as: 'h2' }
    case 'h3':
      return { variant: 'heading3', as: 'h3' }
    case 'h4':
      return { variant: 'heading4', as: 'h4' }
    case 'h5':
      return { variant: 'heading5', as: 'h5' }
    case 'h6':
      return { variant: 'heading6', as: 'h6' }
    default:
      return { variant: 'bodyContent', as: 'p', color: 'gray.2' }
  }
}

export const TextField: FC<
  FieldWithValueProps<FormField<TextViewModel>, string>
> = ({ field }) => (
  <Box sx={{ gridColumn: 'span 2' }}>
    <Text {...getTextPropsForHtmlTag(field.model.htmlTag)}>{field.model.text}</Text>
  </Box>
)
