import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchContext } from './SearchContextProvider'
import { getSearchViewTypeFromUrl } from '../helpers/partContextHelper'
import { VIEW_TYPE_DEFAULT } from '../constants/searchConstants'
import { ActiveStoreProviderContext } from './ActiveStoreProvider'
import useDealerPartSearchService, {
  DealerPartFulfilled,
} from '../hooks/services/rest/ecommerce/useDealerPartSearchService'

export interface DealerPartSearchContextProps {
  fetching: boolean
  dealerParts?: DealerPartFulfilled[]
  totalCount?: number
  viewType?: string
}

export const DealerPartSearchContext = createContext<DealerPartSearchContextProps>({
  fetching: false,
  dealerParts: undefined,
  totalCount: undefined,
  viewType: undefined,
})

interface DealerPartSearchContextProviderProps {
  children: ReactNode
}

const ContextProvider = ({ children }: DealerPartSearchContextProviderProps) => {
  const { search } = useLocation()
  const {
    searchString,
    viewType: contextViewType,
    category,
    subcategory,
  } = useContext(SearchContext)

  const [fetchingDealerParts, dealerParts, totalCount] = useDealerPartSearchService(
    searchString,
    category?.id,
    subcategory?.id
  )

  const viewType = useMemo(
    () =>
      getSearchViewTypeFromUrl(new URLSearchParams(search)) ||
      contextViewType ||
      VIEW_TYPE_DEFAULT,
    // contextViewType are constant and available on page load

    [search]
  )

  return (
    <DealerPartSearchContext.Provider
      value={{
        fetching: fetchingDealerParts,
        dealerParts,
        totalCount,
        viewType,
      }}
    >
      {children}
    </DealerPartSearchContext.Provider>
  )
}

const DealerPartSearchContextProvider = ({
  children,
}: DealerPartSearchContextProviderProps) => {
  const { activeSupplier, impersonatedSupplier } = useContext(
    ActiveStoreProviderContext
  )

  if (
    activeSupplier?.isDealerOwnAssortmentParticipant ||
    impersonatedSupplier?.isDealerOwnAssortmentParticipant
  )
    return <ContextProvider>{children}</ContextProvider>

  return <>{children}</>
}

export default DealerPartSearchContextProvider
