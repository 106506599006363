import React, { FC, useMemo } from 'react'
import { LinkField } from '../../../../../types/layoutService'
import { AssemblyFulfilled } from '../../../../../hooks/services/rest/ecommerce/useAssemblySearchService'
import { resolvePrimaryImage } from '../../../../../helpers/commerceApiHelpers'
import GridCardContainer from '../../../../molecules/GridCardContainer/GridCardContainer'
import AssemblyCard from '../../../../molecules/Card/AssemblyCard/AssemblyCard'

interface AssemblySearchCardProps {
  searchAssembly: AssemblyFulfilled['searchAssembly']
  assembly?: AssemblyFulfilled['assembly']
  uri?: AssemblyFulfilled['uri']
  assemblyPage: LinkField
}

const AssemblySearchCard: FC<AssemblySearchCardProps> = ({
  searchAssembly,
  assembly,
  uri,
  assemblyPage,
}) => {
  const assemblyPrimaryImage = useMemo(
    () => assembly && resolvePrimaryImage(assembly),
    [assembly]
  )

  const assemblyBrand = assembly?.brand

  return (
    <GridCardContainer>
      <AssemblyCard
        uri={uri}
        assemblyPage={assemblyPage}
        partNumber={searchAssembly.bomId}
        imageUrl={assemblyPrimaryImage?.imageUrl}
        partDescription={searchAssembly.bomDescription}
        brandName={assemblyBrand?.description}
        brandImage={assemblyBrand?.image?.thumbnailImageUrl}
      />
    </GridCardContainer>
  )
}

export default AssemblySearchCard
