import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CULTURE_CODE,
} from '../../../constants/userConstants'
import { HEADER_SESSION_ID } from '../../../constants/httpConstants'
import { SearchStatistics, SetFilters } from './partSearch'
import { MatchMetaDataProps } from '../../../types/dafResponseProps'

export interface SearchDealerPart {
  matchMetaData?: MatchMetaDataProps
  partNumber: string
}

export interface DealerPartSearchData {
  parts: SearchDealerPart[]
  statistics: SearchStatistics
}

const DEALER_PARTS_PER_PAGE = 200

export const executeDealerPartSearch = (
  client: AxiosInstance,
  payload: string,
  cancelToken: CancelToken,
  cultureCode?: string,
  countryCode?: string,
  setFilters?: SetFilters[],
  user?: UserProps,
  xSessionId?: string,
  isImpersonated?: boolean,
  customerCompanyId?: number,
  supplierCompanyId?: number
): Promise<AxiosResponse<DealerPartSearchData>> =>
  client.post(
    '/dealerpartsearch/',
    {
      keyword: payload,
      countryCode: countryCode ?? DEFAULT_COUNTRY_CODE,
      cultureCode: cultureCode ?? DEFAULT_CULTURE_CODE,
      paging: {
        pageNumber: 0,
        partsPerPage: DEALER_PARTS_PER_PAGE,
      },
      filters: setFilters || [],
    },
    {
      ...createRequestConfig(
        user,
        (xSessionId && { [HEADER_SESSION_ID]: xSessionId }) || {},
        {
          isImpersonated: isImpersonated || undefined,
          customerCompanyId,
          supplierCompanyId,
        }
      ),
      cancelToken,
    }
  )

export default executeDealerPartSearch
