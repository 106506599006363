export const LOYALTY_POINTS_LIMIT_REACHED = 'loyaltyPointsLimitReached'
export const LOYALTY_POINTS_REACHING_LIMIT = 'loyaltyPointsReachingLimit'
export const LOYALTY_POINTS_NO_LIMIT = 'loyaltyPointsLessThanThreshold'
export const REWARDS_PER_PAGE = 12
export const REWARD_TYPE_CREDIT_MERCHANDISE = 'CreditMerchandise'
export const REWARD_TYPE_PRODUCT_MERCHANDISE = 'ProductMerchandise'
export const REWARD_ERROR_REDEEM_NOT_ALLOWED = 'RedeemNotAllowed'
export const REWARD_ORDER_MIN_AMOUNT_BENELUX_EUR = 50
export const REWARD_ORDER_MIN_AMOUNT_NON_BENELUX_EUR = 95
export const REWARD_ORDER_COSTS_MINIMUM_EUR = 8.5
export const REWARD_ORDER_COSTS_MAXIMUM_EUR = 60
