import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Class } from '../../../../../../hooks/services/graphql/useClassInformationListService'
import { TextField } from '../../../../../../types/layoutService'
import { getTextFieldValue } from '../../../../../../helpers/layoutServiceHelper'
import { ITEMS_PER_COLUMN } from '../../../../../../constants/searchCategoryListConstants'
import { VIEW_TYPE_TABLE } from '../../../../../../constants/searchConstants'
import { PartSearchResultContext } from '../../../../../../providers/PartSearchResultContextProvider'
import { ICONS } from '../../../../../../constants/iconConstants'
import IconWrapper from '../../../../../atoms/IconWrapper/IconWrapper'
import ClickableWrapper from '../../../../../atoms/ClickableWrapper/ClickableWrapper'
import { scrollTo } from '../../../../../../helpers/dom'
import useBreakpoints from '../../../../../../hooks/useBreakpoints'
import PartSearchTableAnchorsModalAnchor from './PartSearchTableAnchorsModalAnchor'
import { pushToDataLayer } from '../../../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../../../constants/dataLayerConstants'
import { CategoriesContext } from '../../../../../../providers/CategoriesProvider'
import useWildcardPageContext from '../../../../../../hooks/useWildcardPageContext'
import { PartContext } from '../../../../../../types/sitecoreContextProps'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../../../../providers/WildcardPageContextProvider'
import useApplicationHeaderHeight from '../../../../../../hooks/useApplicationHeaderHeight'

export interface PartSearchTableAnchorsProps {
  label: TextField
}

export const TABLE_ANCHOR_ADDITIONAL_TARGET_TOP_SPACE = 24

const PartSearchTableAnchors: FC<PartSearchTableAnchorsProps> = ({ label }) => {
  const partContext = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )

  const { categories } = useContext(CategoriesContext)
  const { viewType, availableClassInformation } = useContext(PartSearchResultContext)
  const navigationSpacerHeight = useApplicationHeaderHeight()

  const columnCount = Math.ceil(
    (availableClassInformation?.length || 0) / ITEMS_PER_COLUMN
  )

  const mainCategory = useMemo(
    () =>
      partContext.category?.id
        ? categories?.find((category) => category.id === partContext.category?.id)
        : undefined,
    [partContext, categories]
  )
  const subCategory = useMemo(
    () =>
      mainCategory?.subCategories?.find(
        (category) => category.id === partContext.subcategory?.id
      ),
    [mainCategory, partContext]
  )

  const [breakpointM] = useBreakpoints('m')

  const onAnchorClick = useCallback(
    (classInformation: Class) => {
      const element = document.getElementById(classInformation.code)

      if (element) {
        const elementTop = element.getBoundingClientRect().y
        const newTopPosition =
          elementTop -
          navigationSpacerHeight -
          TABLE_ANCHOR_ADDITIONAL_TARGET_TOP_SPACE

        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
          navigate_type:
            DATA_LAYER.CUSTOM_DIMENSION.NAVIGATION_TYPE.JUMP_TO_TABLE_IN_PAGE,
          main_category: mainCategory?.englishDescription || '',
          sub_category: subCategory?.englishDescription || '',
          filter_name: '',
          filter_value: '',
          page_type: '',
          table_name: classInformation.englishDescription,
        })

        scrollTo({ top: newTopPosition, behavior: 'smooth' })
      }
    },
    [mainCategory, navigationSpacerHeight, subCategory]
  )

  if (
    viewType !== VIEW_TYPE_TABLE ||
    !availableClassInformation ||
    availableClassInformation?.length <= 1
  )
    return null

  if (!breakpointM) {
    return (
      <PartSearchTableAnchorsModalAnchor
        title={label}
        availableClassInformation={availableClassInformation}
      />
    )
  }

  return (
    <Box pb={5} mb={-3}>
      <Text as="h4" variant="heading4" mb={1}>
        {getTextFieldValue(label)}
      </Text>

      <Flex sx={{ overflowX: 'hidden', width: '100%', flexWrap: 'wrap' }}>
        {[...Array(columnCount)].map((_x, i) => {
          const end = (i + 1) * ITEMS_PER_COLUMN
          const start = end - ITEMS_PER_COLUMN

          return (
            <Box
              as="ul"
              key={i.toString()}
              sx={{
                flexShrink: 0,
                listStyle: 'none',
                p: 0,
                mr: 8,
                mb: 3,
                width: '230px',
              }}
            >
              {availableClassInformation
                .slice(start, end)
                .map((classInformation) => (
                  <Text as="li" variant="bodySmall" key={classInformation.code}>
                    <ClickableWrapper
                      onClick={() => onAnchorClick(classInformation)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'gray.2',
                        outline: 'none',
                        borderBottomWidth: ['1px', '1px', 0],
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'gray.1',
                        px: [3, 3, 0],
                        lineHeight: 'menuList',
                        ':hover, :active, :focus': {
                          color: 'primary',
                        },
                        svg: {
                          display: 'inline',
                          marginLeft: '-4px',
                          marginRight: 2,
                          color: 'primary',
                          fill: 'currentColor',
                        },
                      }}
                    >
                      <IconWrapper icon={ICONS.ARROW_RIGHT} size={1} />

                      {classInformation.description}
                    </ClickableWrapper>
                  </Text>
                ))}
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}

export default PartSearchTableAnchors
