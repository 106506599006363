import React, { FC } from 'react'
import { Box } from 'theme-ui'

const TooltipTingle: FC = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      marginTop: 2,
      width: 0,
      height: 0,
      borderLeft: ' 8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: '8px solid',
      borderBottomColor: 'text',
    }}
  />
)

export default TooltipTingle
