import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RewardsContext } from '../../../providers/RewardsProvider'
import { Box, Flex } from 'theme-ui'
import RewardsOverviewFilterControls from '../../molecules/RewardsOverviewFilterControls/RewardsOverviewFilterControls'
import RewardCard from '../../molecules/Card/RewardCard/RewardCard'
import { RewardsOverviewDatasourceProps } from '../RewardsOverviewWrapper/RewardsOverviewWrapper'
import i18next from 'i18next'
import Paginated from '../Paginated/Paginated'
import SpinnerWithLabel from '../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import { PaginationInfo } from '../../../types/layoutService'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import {
  URL_FILTER_PARAMETER,
  URL_PAGE_PARAM,
} from '../../../constants/urlConstants'
import { useHistory } from 'react-router-dom'
import { REWARDS_PER_PAGE } from '../../../constants/loyaltyConstants'
import NoResults from '../../molecules/NoResults/NoResults'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import Button from '../../atoms/Button/Button'

interface RewardsOverviewProps {
  datasource: RewardsOverviewDatasourceProps
}

const RewardsOverview = ({ datasource }: RewardsOverviewProps) => {
  const { rewardsData, isPending } = useContext(RewardsContext)
  const [pagination, setPagination] = useState<PaginationInfo | undefined>(undefined)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const history = useHistory()
  const urlPageNumber = getUrlQueryValue(URL_PAGE_PARAM, history.location.search)

  const handleResetButtonClick = useCallback(() => {
    const params = new URLSearchParams(history.location.search)
    params.delete(URL_FILTER_PARAMETER)
    params.delete(URL_PAGE_PARAM)
    history.push({
      search: params.toString(),
    })
  }, [history])

  useEffect(() => {
    if (urlPageNumber) {
      setPageIndex(parseInt(urlPageNumber, 10) - 1)
    } else {
      setPageIndex(0)
    }
  }, [urlPageNumber, history])

  useEffect(() => {
    if (rewardsData) {
      const totalPageCount = Math.ceil(
        rewardsData?.statistics?.totalCount / REWARDS_PER_PAGE
      )

      setPagination({
        totalCount: totalPageCount,
        previousIndex: pageIndex && pageIndex > 0 ? pageIndex - 1 : null,
        currentIndex: pageIndex,
        nextIndex: pageIndex < totalPageCount - 1 ? pageIndex + 1 : null,
      })
    }
  }, [pageIndex, rewardsData])

  return (
    <Box>
      <RewardsOverviewFilterControls
        totalCount={rewardsData?.statistics?.totalCount || 0}
        datasource={datasource}
      />
      {rewardsData?.rewards.length === 0 && !isPending ? (
        <Box sx={{ marginBlock: '40px' }}>
          <NoResults
            description={getTextFieldValue(datasource.noResultsMessage)}
            title={getTextFieldValue(datasource.noResultsTitle)}
          >
            <Button variant="primary" onClick={() => handleResetButtonClick()}>
              {i18next.t('filteringLabels.resetFilters')}
            </Button>
          </NoResults>
        </Box>
      ) : (
        <Paginated
          fetching={false}
          fetchingLabel={i18next.t('commonLabels.total')}
          totalCount={pagination?.totalCount}
          previousIndex={pagination?.previousIndex}
          currentIndex={pagination?.currentIndex}
          nextIndex={pagination?.nextIndex}
        >
          {isPending ? (
            <Flex
              sx={{
                width: '100%',
                height: '100%',
                paddingY: 5,
              }}
            >
              <SpinnerWithLabel
                label={i18next.t('commonLabels.loading')}
                sx={{
                  width: 'max-content',
                  position: 'sticky',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              />
            </Flex>
          ) : (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                gap: 3,
                marginBlock: '40px',
              }}
            >
              {rewardsData?.rewards.map((reward) => (
                <RewardCard {...reward} key={reward?.rewardId} type={reward?.type} />
              ))}
            </Box>
          )}
        </Paginated>
      )}
    </Box>
  )
}

export default RewardsOverview
