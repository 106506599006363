import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import { CommercePart, CommerceSparePart } from '../../../types/commerceApi'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'

export const SPARE_PART_DIRECTION_TO = 'TO'
export const SPARE_PART_DIRECTION_FROM = 'FROM'

export type SparePartDirection =
  | typeof SPARE_PART_DIRECTION_TO
  | typeof SPARE_PART_DIRECTION_FROM

const GET_PART_SPARE_PART_LIST = loader('./schemas/partSparePartListQuery.graphql')

interface PartSparePartListQueryData {
  part: CommercePart
}

interface PartSparePartListQueryVariables extends BaseGraphServiceVariables {
  [key: string]: any
  partNumber: string
  direction?: SparePartDirection
}

const sortSparePartsByPriority = (spareParts: CommerceSparePart[]) =>
  spareParts.sort((a, b) =>
    a?.priority && b?.priority ? a.priority - b.priority : 0
  )

const usePartSparePartListService = (
  partNumber?: string,
  direction?: SparePartDirection
): GraphServiceTuple<CommerceSparePart[]> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [sparePartList, setSparePartList] = useState<CommerceSparePart[]>([])

  const onData = useCallback((data?: PartSparePartListQueryData) => {
    if (data?.part?.spares?.length) {
      setSparePartList(sortSparePartsByPriority(data.part.spares))
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    PartSparePartListQueryData,
    PartSparePartListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && partNumber) {
      fetch({
        document: GET_PART_SPARE_PART_LIST,
        variables: {
          ...baseVariables,
          partNumber,
          direction,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, partNumber, direction])

  return [fetching, sparePartList]
}

export default usePartSparePartListService
