import { COLORS } from '../../../constants/themeConstants'
import { ThemeProps } from '../../../types/themeProps'
import dafTheme from '../dafTheme'

const dafGrayTheme: ThemeProps = {
  ...dafTheme,
  colors: {
    ...dafTheme.colors,
    text: COLORS.WHITE,
    primary: COLORS.WHITE,
    gray: [
      COLORS.LIGHT_GRAY,
      COLORS.LIGHT_GRAY,
      COLORS.WHITE,
      COLORS.WHITE,
      COLORS.MEDIUM_DARK,
    ],
  },
  buttons: {
    ...dafTheme.buttons,
    primary: {
      ...dafTheme.buttons.primary,
      color: COLORS.DAF_BLUE,
      bg: COLORS.WHITE,
      ':hover, :focus, :active, &.active': {
        ...dafTheme.buttons.primary[':hover, :focus, :active, &.active'],
        bg: COLORS.LIGHT_GRAY,
        color: COLORS.DAF_BLUE,
      },
    },
    outline: {
      ...dafTheme.buttons.outline,
      color: COLORS.WHITE,
      bg: COLORS.MEDIUM_DARK,
      borderColor: COLORS.MEDIUM_GRAY,
      ':hover, :focus, :active, &.active': {
        ...dafTheme.buttons.outline[':hover, :focus, :active, &.active'],
        borderColor: COLORS.WHITE,
        color: COLORS.WHITE,
      },
    },
    ghost: {
      ...dafTheme.buttons.ghost,
      color: COLORS.WHITE,
      borderColor: COLORS.WHITE,
      ':hover, :focus, :active, &.active': {
        ...dafTheme.buttons.ghost[':hover, :focus, :active, &.active'],
        color: COLORS.DAF_BLUE,
      },
    },
  },
  variants: {
    ...dafTheme.variants,
  },
}

export default dafGrayTheme
