import React, { FC } from 'react'
import Button from '../Button/Button'

export type OnNavigate = (newPageIndex: number) => void

interface PaginationPageNumberProps {
  index: number
  onNavigate: OnNavigate
  active?: boolean
}

const PaginationPageNumber: FC<PaginationPageNumberProps> = ({
  index,
  onNavigate,
  active,
}) => {
  const pageNumber = index + 1

  return (
    <Button
      className={active ? 'active' : ''}
      onClick={() => !active && onNavigate(index)}
      variant="outline"
      sx={{
        marginLeft: 1,
        marginRight: 1,
        width: '40px',
        padding: 0,
        ...(active ? { pointerEvents: 'none' } : {}),
      }}
    >
      {pageNumber}
    </Button>
  )
}

export default PaginationPageNumber
