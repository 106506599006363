import React from 'react'
import { motion } from 'framer-motion'
import { Box } from 'theme-ui'
import { DigitalMagazineLinkItemsProps } from '../../../types/digitalMagazineTypes'
import Link from '../../atoms/Link/Link'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

const DigitalMagazineLinkItems: React.FC<DigitalMagazineLinkItemsProps> = ({
  items,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {items.map((item, index) => (
      <Box
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        sx={{
          position: 'relative',
          marginRight: ['16px', '50px'],
          '&:last-child': {
            marginRight: 0,
          },
        }}
      >
        <Link
          onClick={() => {
            pushToDataLayer({
              [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT.CALL_TO_ACTION,
              type: 'visual',
              destination_url: item.url,
            })
          }}
          href={item.url}
          title={item.alt}
          sx={{
            maxWidth: ['110px', '160px', '180px'],
            maxHeight: ['110px', '160px', '180px'],
            width: '100%',
            height: '100%',
            display: 'block',
            borderRadius: '50%',
            border: '4px solid white',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              height: ['40px', '50px'],
              width: ['40px', '50px'],
              backgroundColor: 'white',
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
          >
            <IconWrapper icon={ICONS.ARROW_RIGHT} size={4} />
          </Box>

          <motion.img
            key={item.src}
            src={item.src}
            initial={{
              width: '100%',
              height: '100%',
            }}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          />
        </Link>
      </Box>
    ))}
  </Box>
)

export default DigitalMagazineLinkItems
