import { ShoppingBasketWizardState } from '.'

export default (state: ShoppingBasketWizardState) => {
  if (!state?.order) return state

  const newCount = Math.max(0, state.mutation.mutationCount - 1) // prevent decrementing below 0

  return {
    ...state,
    mutation: {
      ...state.mutation,
      mutationCount: newCount,
      mutationInProgress: newCount > 0,
    },
  }
}
