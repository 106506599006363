import { GraphQLClient } from 'graphql-request'
import { resolveEnvironment } from '../helpers/environment'
import { shouldUseLocalCommerceApiBaseUrl } from '../helpers/debugHelper'

const graphQLClientFactory = (environmentName) => {
  const env = resolveEnvironment(environmentName)

  if (!env) {
    throw new Error('Unable to resolve environment.')
  }

  return new GraphQLClient(
    shouldUseLocalCommerceApiBaseUrl()
      ? env.vars.localCommerceApiGraphQLBaseUrl
      : env.vars.commerceApiGraphQLBaseUrl,
    {
      errorPolicy: 'all',
    }
  )
}

export default graphQLClientFactory
