import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import { KNOWN_DISPATCH_ACTIONS } from '../../../constants/navigationConstants'
import ClickableMenuItemWrapper from '../ClickableMenuItemWrapper/ClickableMenuItemWrapper'
import { NavigationItem as NavigationItemProps } from '../../../types/layoutService'
import { BaseItem } from './BaseItem'

export const ActionItem: FC<NavigationItemProps> = ({ title, dispatchAction }) => {
  const dispatch = useDispatch()
  const [clicked, setClicked] = useState<boolean>(false)
  const knownState =
    dispatchAction === 'openPartsNavigation' ? 'partsNavigationOpen' : dispatchAction
  const { [knownState!]: dispatched } = useSelector(
    (state: RootState) => state.navigation
  )

  const action = KNOWN_DISPATCH_ACTIONS?.[dispatchAction!]
  const elementType = useMemo<React.ElementType>(
    () => (dispatchAction ? 'button' : 'a'),
    [dispatchAction]
  )

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()

      if (!dispatchAction) return

      if (action) {
        dispatch(action())
      } else {
        setClicked(true)
      }
    },
    [dispatchAction, setClicked, dispatch, action]
  )

  useEffect(() => {
    setClicked(!!dispatched)
  }, [dispatched])

  return (
    <ClickableMenuItemWrapper
      data-t-id={`navigation-action-item-${dispatchAction}`}
      onClick={handleClick}
      active={clicked}
      as={elementType}
    >
      <BaseItem includeArrow={!!action} active={clicked} title={title} />
    </ClickableMenuItemWrapper>
  )
}
