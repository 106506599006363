import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import Link from '../../atoms/Link/Link'
import { FooterItem } from './Footer'

interface StaticFooterItem {
  linkItem: FooterItem
}

const StaticFooterLegalLink: FC<StaticFooterItem> = ({
  linkItem: { title, url, target },
}) => {
  const { transitions } = useTheme()
  return (
    <>
      <Link
        href={url}
        target={target}
        sx={{
          display: 'inline-block',
          fontSize: 0,
          color: 'textLight',
          fontWeight: 'normal',
          textDecoration: 'none',
          outline: 'none',
          lineHeight: 'body',
          marginBottom: [1, 1, 0],
          transitionDuration: transitions[1],
          transitionProperty: 'opacity',
          opacity: 0.64,
          ':hover, :focus, :active': {
            opacity: 1,
          },
          '::after': {
            position: 'relative',
            content: '"|"',
            display: 'inline',
            fontSize: '1',
            color: 'textLight',
            fontWeight: 'normal',
            mx: 2,
          },
          '&:last-child': {
            '::after': { display: 'none' },
          },
        }}
      >
        {title}
      </Link>
    </>
  )
}

export default StaticFooterLegalLink
