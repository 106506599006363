import React, { FC } from 'react'
import { Datasource } from '../../../types/layoutService'
import CardGrid from '../../molecules/Card/CardGrid'
import NewsArticleCard from '../NewsArticleCard/NewsArticleCard'
import NewsArticleHighlightHeading from './NewsArticleHighlightHeading'
import {
  NewsArticleHighlightsDatasource,
  NewsArticleHighlightsProps,
} from './NewsArticleHighlights'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'

const NewsArticleHighlightsSkeleton: FC<
  NewsArticleHighlightsProps & Datasource<NewsArticleHighlightsDatasource>
> = ({
  datasource: { title, allLabel, customNewsOverviewPage },
  generalNewsOverviewPage,
}) => {
  const allLabelTextValue = getTextFieldValue(allLabel)

  const actualAllLabel = customNewsOverviewPage
    ? customNewsOverviewPage.text ?? allLabelTextValue
    : allLabelTextValue

  const newsOverviewPage = customNewsOverviewPage?.url
    ? customNewsOverviewPage
    : generalNewsOverviewPage

  return (
    <>
      <NewsArticleHighlightHeading
        title={title}
        allLabel={actualAllLabel}
        newsOverviewPage={newsOverviewPage}
      />

      <CardGrid>
        {[...Array(3)].map((_, i) => (
          <NewsArticleCard.Skeleton key={i.toString()} />
        ))}
      </CardGrid>
    </>
  )
}

export default NewsArticleHighlightsSkeleton
