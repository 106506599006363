import React, { FC, useEffect } from 'react'
import i18next from 'i18next'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import BasicInformation from '../../molecules/BasicInformation/BasicInformation'
import BasicInformationDescription from '../../molecules/BasicInformation/BasicInformationDescription'
import BasicInformationAttribute from '../../molecules/BasicInformation/BasicInformationAttribute'
import BasicInformationBrandName from '../../molecules/BasicInformation/BasicInformationBrandName'
import BasicInformationDealerContactInformation from '../../molecules/BasicInformation/BasicInformationDealerContactInformation'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { EVENT_CATEGORIES } from '../../../constants/analyticsConstants'
import DealerPartAddToBasket from '../../molecules/AddToBasket/DealerPartAddToBasket'
import DealerPartSalesInformationProvider from '../../../providers/DealerPartSalesInformationProvider'
import { DEFAULT_PART_QUANTITY } from '../../../constants/partSalesInformationConstants'
import DealerPartPrice from '../../molecules/PartPrice/DealerPartPrice'
import PartLineStockInformation from '../../molecules/PartLine/PartLineStockInformation'
import { DealerPart } from '../../../hooks/services/graphql/useDealerPartListService'

interface PartBasicInformationDatasource {
  askQuotationLabel: TextField
  addToBasketLabel: TextField
}

interface PartBasicInformationProps {
  dealerPart?: DealerPart
}

const PartBasicInformation: FC<
  Datasource<PartBasicInformationDatasource> & PartBasicInformationProps
> = ({ dealerPart, datasource: { askQuotationLabel, addToBasketLabel } }) => {
  const { partName, partNumber, manufacturerPartNumber, brandName } =
    dealerPart ?? {}

  useEffect(() => {
    pushToDataLayer({
      ecommerce: {
        detail: {
          actionField: {
            list: DATA_LAYER.COMPONENT_ORIGIN.PART_BASIC_INFORMATION,
          },
          products: [
            {
              id: partNumber,
              name: partName,
              price: '',
              brand: brandName,
              category: EVENT_CATEGORIES.UNCATEGORIZED,
              dimension9: true, // Define that part is not a dealer part (doa_product)
              variant: '',
            },
          ],
        },
      },
    })
  }, [brandName, partName, partNumber])

  return (
    <BasicInformation>
      <BasicInformationDescription description={partName} data-t-id="description" />
      <BasicInformationAttribute
        label={i18next.t('partLabels.partNumberAbbreviation')}
        value={partNumber}
        mb={manufacturerPartNumber ? 1 : 3}
        data-t-id="part-number"
      />
      <BasicInformationAttribute
        label={i18next.t('partLabels.manufacturerPartNumberAbbreviation')}
        value={manufacturerPartNumber}
        data-t-id="manufacturer-part-number"
      />
      <BasicInformationBrandName name={brandName} />
      <DealerPartSalesInformationProvider
        partNumbersQuantity={[{ partNumber, quantity: DEFAULT_PART_QUANTITY }]}
      >
        <DealerPartPrice partNumber={partNumber} variant="normal" my={3} />

        <DealerPartAddToBasket
          partNumber={partNumber}
          partDescription={partName}
          brandName={brandName}
          label={addToBasketLabel}
          askQuotationLabel={askQuotationLabel}
          dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PRODUCT_DETAIL}
          marginBottom={4}
        />

        <PartLineStockInformation
          partNumber={partNumber}
          componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PRODUCT_DETAIL}
          variant="full"
          isDealer
          sx={{
            marginBottom: 3,
          }}
        />
      </DealerPartSalesInformationProvider>

      <BasicInformationDealerContactInformation />
    </BasicInformation>
  )
}

export default withData(PartBasicInformation, { showMessageWhenPageEditing: true })
