export const STOCK_LOCATION_ID_CONSIGNMENT = 'Consignment'
export const STOCK_LOCATION_ID_NO_STOCK_FOR_DEALER = 'NoStockForDealer'

export const STOCK_STATE: Record<string, string> = {
  // note: values must be unique with no substring match, otherwise no distinction can be make by Tosca
  AVAILABLE: 'availableDealer',
  AVAILABLE_IN_X_HOURS: 'availableInXHours',
  AVAILABLE_IN_X_DAYS: 'availableInXDays',
  AVAILABLE_AT_LOCATION: 'availableAtLocation',
  AVAILABLE_AT_YOUR_LOCATION: 'availableAtYourLocation',
  CONTACT: 'contact',
  DIRECT_SHIP_DELIVERY: 'directShipDelivery',
}

export const DELIVERY_PRESCRIPTION_CODES: Record<string, string> = {
  DIRECT_SHIP_DELIVERY: 'DirectShipDelivery',
}
