import { COLORS } from '../constants/themeConstants'
import { Vehicle } from '../services/rest/ecommerce/dafCompanyVehicles'
import {
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
} from '../constants/dafCompanyVehicleConstants'

export const dafVehicleActionColorHelper = (
  vehicleActionStatus: number,
  inputMileage: boolean
) => {
  if (vehicleActionStatus === 1 || inputMileage) return COLORS.ALERT
  if (vehicleActionStatus === 2) return COLORS.PROMOTION
  if (vehicleActionStatus === 3) return COLORS.ORANGE
  return 'transparent'
}

export const dafVehicleMaintenanceColorHelper = (vehicle: Vehicle) => {
  const openJobs = vehicle.servicePlanner?.service.upcomingJobs?.filter(
    (job) => !job.markedAsComplete
  )

  if (!openJobs?.length) {
    return 'transparent'
  }

  const earliestJob = openJobs.sort(
    (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
  )[0]

  switch (earliestJob.dueIn) {
    case MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE:
      return COLORS.ALERT
    case MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH:
      return COLORS.PROMOTION
    case MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS:
      return COLORS.ORANGE
    default:
      return 'transparent'
  }
}
