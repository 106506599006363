import React, { FC, useCallback } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import withData from '../../../enhancers/withData'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'
import {
  Datasource,
  ImageWithFocalPointField,
  LinkField,
  TextField,
} from '../../../types/layoutService'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import RichText from '../../atoms/RichText/RichText'

interface TeaserImageTextBlockDatasource {
  image: ImageWithFocalPointField
  title: TextField
  text: TextField
  button: LinkField
}

const TeaserImageTextBlock: FC<Datasource<TeaserImageTextBlockDatasource>> = ({
  datasource: { image, text, title, button },
}) => {
  const { teaserImageBlock, colors } = useTheme()

  const measureButtonClick = useCallback(
    () =>
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
        call_to_action_name: button.text,
        call_to_action_type: DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
        target_url: button.url,
      }),
    [button]
  )

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'column', 'row'],
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: ['100%', '100%', '66.67%'],
          minHeight: ['60vw', '60vw', '560px'],
        }}
      >
        <Box
          as="figure"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <SitecoreImage
            imageField={image}
            sx={{
              display: 'block',
            }}
          />
        </Box>
      </Box>

      <Text
        sx={{
          width: ['100%', '100%', '33.33%'],
          backgroundColor: teaserImageBlock.background,
          p: [3, 3, 5],
          color: 'textLight',
          overflow: 'hidden',
          'h2, p, strong, li': {
            color: `${colors.textLight}!important`,
          },
        }}
      >
        {getTextFieldValue(title) ? (
          <Text as="h2" variant="heading1" pb={2}>
            {getTextFieldValue(title)}
          </Text>
        ) : null}

        {getEditableTextFieldValue(text) ? (
          <Text as="div" variant="bodyContent" sx={{ pb: 3, p: { margin: 0 } }}>
            <RichText textField={text} />
          </Text>
        ) : null}

        <SitecoreButton
          linkField={button}
          variant="outline"
          color="primary"
          onClick={measureButtonClick}
        />
      </Text>
    </Flex>
  )
}

export default withData(TeaserImageTextBlock)
