import { Box, Flex, Text } from 'theme-ui'
import React from 'react'
import i18next from 'i18next'
import TableCell from '../../../molecules/Table/TableCell'
import Image from '../../../atoms/Image/Image'
import TableRow from '../../../molecules/Table/TableRow'
import { COLORS } from '../../../../constants/themeConstants'
import ContractServicesIcon from '../../../atoms/ContractServicesIcon/ContractServicesIcon'
import StatePill from '../../../atoms/StatePill/StatePill'
import {
  MILEAGE_INPUT_STATE_OPEN,
  SERVICEPLAN_CONTRACT_STATE_ACTIVE,
  SERVICEPLAN_CONTRACT_STATE_CLOSED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRING,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
  VEHICLE_SERVICES_NON_MULTISUPPORT,
} from '../../../../constants/dafCompanyVehicleConstants'
import {
  Filter,
  Vehicle,
} from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import MyFleetListVehicleDetails from '../MyFleetListDetails/MyFleetVehicleDetails'
import {
  dafVehicleActionColorHelper,
  dafVehicleMaintenanceColorHelper,
} from '../../../../helpers/dafVehicleActionColorHelper'
import Button from '../../../atoms/Button/Button'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { findFilterIdByValue } from '../../../../helpers/findFilterIdByValue'
import {
  getMaintenanceColor,
  getVehicleUpgradeColor,
} from '../../../../helpers/multisupportHelpers'
import { getMileageValue } from '../../../../helpers/vehicleHelper'
import { LinkField } from '../../../../types/layoutService'
import { URL_WILDCARD_PLACEHOLDER } from '../../../../constants/urlConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import toPascalCase from '../../../../helpers/pascalCaseHelper'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'
import MyFleetListActionButtonList from '../MyFleetListActionButtonList/MyFleetListActionButtonList'

interface MyFleetListTableRowProps {
  vehicle: Vehicle
  filterGroups?: Filter[]
  vehicleDetailLink?: LinkField
  requestQuoteMenuOptionTitle: string | undefined
  requestMaintenanceAppointmentOptionTitle: string | undefined
  deleteVehicleOptionTitle: string | undefined
}
const MyFleetListTableRow = ({
  vehicle,
  filterGroups,
  vehicleDetailLink,
  requestQuoteMenuOptionTitle,
  requestMaintenanceAppointmentOptionTitle,
  deleteVehicleOptionTitle,
}: MyFleetListTableRowProps) => {
  const {
    languageContext: { cultureCode },
    site: { name },
  } = useSitecoreContext()

  const nonMultiSupportServicesCount = vehicle?.nonMultiSupportServices?.length > 0

  return (
    <TableRow
      key={vehicle.chassisNumber}
      data-t-id={`myfleet-vehicle-${vehicle.vin}`}
      sx={{
        position: 'relative',
        '& > div': {
          paddingY: 4,
        },
        '&::before': {
          content: '""',
          width: '5px',
          height: '100%',
          left: 0,
          top: 0,
          position: 'absolute',
          backgroundColor:
            name !== MULTISUPPORT_SITE_CONTEXT
              ? dafVehicleMaintenanceColorHelper(vehicle)
              : dafVehicleActionColorHelper(
                  vehicle.vehicleActionStatus,
                  vehicle.rmContract?.mostRecentContractForVehicle &&
                    vehicle.rmContract?.mileageSettlementInputState ===
                      MILEAGE_INPUT_STATE_OPEN
                ),
        },
      }}
    >
      <TableCell>
        <Image
          alt={vehicle.vehicleType}
          sx={{
            maxWidth: 100,
          }}
          src={vehicle.urlVehicleImageModelYearSeries}
        />
      </TableCell>
      <TableCell>
        <Box>
          <MyFleetListVehicleDetails vehicle={vehicle} />
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <Text variant="bodySmall" color={COLORS.DARK}>
            {getMileageValue(vehicle?.mileage?.value, cultureCode)}
          </Text>
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <IconWrapper
            icon={ICONS.CONNECTED}
            size={4}
            color={vehicle?.hasDafConnect ? COLORS.BLACK : COLORS.MEDIUM_GRAY}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {vehicle.rmContract?.contractPackageAbbreviation && (
            <ContractServicesIcon
              contractPackageAbbreviation={
                vehicle.rmContract?.contractPackageAbbreviation
              }
            />
          )}
          {nonMultiSupportServicesCount && (
            <ContractServicesIcon
              contractPackageAbbreviation={VEHICLE_SERVICES_NON_MULTISUPPORT}
            />
          )}
        </Box>
      </TableCell>
      {name === MULTISUPPORT_SITE_CONTEXT && (
        <TableCell>
          <Text as="span" variant="bodySmall" color="gray.3">
            {vehicle.rmContract?.mainServiceDealer?.name &&
              vehicle.rmContract?.mainServiceDealer?.name}
          </Text>
        </TableCell>
      )}
      <TableCell>
        {filterGroups &&
          vehicle.applicableFilters?.maintenance?.map((item: string) => (
            <Box sx={{ marginBottom: 1 }} key={item}>
              <StatePill
                state={i18next
                  .t(
                    `myFleetFilters.${findFilterIdByValue({
                      filterGroups,
                      value: item,
                    })}`
                  )
                  .toLocaleLowerCase()}
                color={getMaintenanceColor(item)}
              />
            </Box>
          ))}
        {filterGroups &&
          vehicle.applicableFilters?.vehicleUpgrade?.map((item: string) => (
            <Box sx={{ marginBottom: 1 }} key={item}>
              <StatePill
                state={i18next.t('myFleetFilters.recall').toLocaleLowerCase()}
                color={getVehicleUpgradeColor(item)}
              />
            </Box>
          ))}
      </TableCell>
      {name === MULTISUPPORT_SITE_CONTEXT && (
        <TableCell>
          {vehicle.rmContract?.contractState &&
            vehicle.rmContract?.contractState !==
              SERVICEPLAN_CONTRACT_STATE_ACTIVE &&
            vehicle.rmContract?.contractState !==
              SERVICEPLAN_CONTRACT_STATE_CLOSED && (
              <Box>
                <StatePill
                  state={i18next.t(
                    `dafCompanyVehicles.contract${toPascalCase(
                      vehicle.rmContract?.contractState
                    )}`
                  )}
                  color={
                    vehicle.rmContract?.contractState ===
                    SERVICEPLAN_CONTRACT_STATE_EXPIRING
                      ? COLORS.PROMOTION
                      : COLORS.ALERT
                  }
                />
              </Box>
            )}
        </TableCell>
      )}
      {name === MULTISUPPORT_SITE_CONTEXT && (
        <TableCell>
          {vehicle.rmContract?.invoiceState &&
            vehicle.rmContract?.invoiceState ===
              SERVICEPLAN_CONTRACT_STATE_ON_HOLD && (
              <Box sx={{ marginBottom: 1 }}>
                <StatePill
                  state={i18next.t(
                    `dafCompanyVehicles.invoice${toPascalCase(
                      vehicle.rmContract?.invoiceState
                    )}`
                  )}
                  color={COLORS.ALERT}
                />
              </Box>
            )}
          {vehicle.rmContract?.mostRecentContractForVehicle &&
            vehicle.rmContract?.mileageSettlementInputState ===
              MILEAGE_INPUT_STATE_OPEN && (
              <Box sx={{ marginBottom: 1 }}>
                <StatePill
                  state={i18next.t('myFleet.inputMileage')}
                  color={COLORS.ALERT}
                />
              </Box>
            )}
        </TableCell>
      )}

      <TableCell>
        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
          <Box>
            <MyFleetListActionButtonList
              vehicle={vehicle}
              vehicleDetailLink={vehicleDetailLink}
              requestQuoteMenuOptionTitle={requestQuoteMenuOptionTitle}
              requestMaintenanceAppointmentOptionTitle={
                requestMaintenanceAppointmentOptionTitle
              }
              deleteVehicleMenuOptionTitle={deleteVehicleOptionTitle}
            />
          </Box>
          <Box>
            <Button
              as="a"
              href={vehicleDetailLink?.url.replace(
                URL_WILDCARD_PLACEHOLDER,
                vehicle.chassisNumber
              )}
              variant="primary"
              data-t-id={`myfleet-detail-${vehicle.vin}`}
              sx={{
                width: '100%',
                display: ['none', 'none', 'flex'],
              }}
            >
              {i18next.t('myFleet.details')}
            </Button>
          </Box>
        </Flex>
      </TableCell>
    </TableRow>
  )
}

export default MyFleetListTableRow
