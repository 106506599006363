/* eslint-disable no-restricted-globals */
import { COLORS } from '../constants/themeConstants'
import { BackgroundColor, FocalPoint } from '../types/layoutService'

export const getObjectPosition = (focalPoint?: FocalPoint) =>
  `${focalPoint?.x.toFixed() || 50}% ${focalPoint?.y.toFixed() || 50}%`

export const getBackgroundColor = (backgroundColor: BackgroundColor) => {
  switch (backgroundColor) {
    case 'Gray':
      return 'gray.0'
    case 'Primary':
      return 'primary'
    case 'DafGray':
      return 'gray.4'
    default:
      return 'background'
  }
}

export const getColumnLayoutDividerSettings = (
  backgroundColor?: BackgroundColor
) => {
  switch (backgroundColor) {
    case 'Gray':
      return [COLORS.MEDIUM_GRAY, 1]
    case 'Primary':
      return [COLORS.WHITE, 0.16]
    case 'DafGray':
      return [COLORS.WHITE, 0.16]
    default:
      return [COLORS.LIGHT_GRAY, 1]
  }
}

export const getApplicationHeaderHeight = () => {
  const headerElement = document.querySelector(
    '#root div:first-of-type header:first-of-type'
  ) as HTMLElement

  return headerElement?.offsetHeight || 0
}
