import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  LinkField,
  NewsArticle,
  TextField,
} from '../../../types/layoutService'
import CardGrid from '../../molecules/Card/CardGrid'
import NewsArticleCard from '../NewsArticleCard/NewsArticleCard'
import NewsArticleHighlightHeading from './NewsArticleHighlightHeading'
import NewsArticleHighlightsSkeleton from './NewsArticleHighlightsSkeleton'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'

export interface NewsArticleHighlightsDatasource {
  allLabel: TextField
  readMoreLabel: TextField
  title: TextField
  customNewsOverviewPage: LinkField | null
}

export interface NewsArticleHighlightsProps {
  newsArticles: NewsArticle[]
  generalNewsOverviewPage: LinkField
}

const NewsArticleHighlights: FC<
  NewsArticleHighlightsProps & Datasource<NewsArticleHighlightsDatasource>
> = ({
  datasource: { readMoreLabel, title, allLabel, customNewsOverviewPage },
  newsArticles,
  generalNewsOverviewPage,
}) => {
  if (!newsArticles?.length || !generalNewsOverviewPage?.url) return null

  const allLabelTextValue = getTextFieldValue(allLabel)

  const actualAllLabel = customNewsOverviewPage
    ? customNewsOverviewPage.text ?? allLabelTextValue
    : allLabelTextValue

  const newsOverviewPage = customNewsOverviewPage?.url
    ? customNewsOverviewPage
    : generalNewsOverviewPage

  return (
    <>
      <NewsArticleHighlightHeading
        title={title}
        allLabel={actualAllLabel}
        newsOverviewPage={newsOverviewPage}
      />

      <CardGrid>
        {newsArticles.map((article, i) => (
          <NewsArticleCard
            key={i.toString()}
            href={`${newsOverviewPage.url}/${article.wildcardPath}`}
            buttonText={readMoreLabel}
            {...article}
          />
        ))}
      </CardGrid>
    </>
  )
}

export default withData(NewsArticleHighlights, {
  checkDatasource: true,
  pageEditingComponent: NewsArticleHighlightsSkeleton,
  showPageEditingComponentWhenPreviewing: true,
})
