import React, { ReactNode } from 'react'

interface SlideProps {
  children: ReactNode
}

const Slide = ({ children }: SlideProps) => (
  <div
    style={{
      scrollSnapAlign: 'start',
      userSelect: 'none',
      overflow: 'hidden',
    }}
  >
    {children}
  </div>
)

export default Slide
