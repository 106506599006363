import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'

interface SendRequestQuote {}

export interface RequestQuotePayloadProps {
  chassisNumber?: string
  locationCode: string
  cultureCode: string
  servicePlan: string
  servicePlanOptions: string[]
  remarks: string
  durationAndMileage: {
    duration: number
    expectedYearlyMileage: number
    startDate: string
  }
  vehicleUsage: {
    roadType: string
    staticPowerHours: number
    numberOfDropsPerDay: string
  }
}

const sendRequestQuotePayload = (
  queryParams: DafDefaultQueryParams,
  payload: RequestQuotePayloadProps,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<SendRequestQuote>>> =>
  client.post(
    `/send-requestRmServicePlanQuote-actions/${payload.chassisNumber}`,
    payload,
    {
      params: queryParams,
      cancelToken,
      ...createRequestConfig(user),
    }
  )

export default sendRequestQuotePayload
