import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface CompanyChangeRequestProps {
  requestBody: string
}

const userLanguage = (
  payload: CompanyChangeRequestProps,
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse> =>
  client.post('/company-changerequest', payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default userLanguage
