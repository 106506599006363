import React, { FC, useCallback } from 'react'
import R2CJobsheetReferenceSidePanel from '../../SidePanel/R2CJobsheetReferenceSidePanel/R2CJobsheetReferenceSidePanel'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import R2CJobsheetTaskSidePanel from '../../SidePanel/R2CJobsheetTaskSidePanel/R2CJobsheetTaskSidePanel'
import {
  toggleR2CJobsheetSidepanel,
  toggleR2CTaskSidepanel,
} from '../../../../actions/r2cActions'
import i18next from 'i18next'
import { ICONS } from '../../../../constants/iconConstants'
import Button from '../../../atoms/Button/Button'
import FormInputLabel from '../../../atoms/FormControls/FormInputLabel'
import { Flex, Text } from 'theme-ui'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import { refetchShoppingBasketOrder } from '../../../../actions/shoppingBasket/wizard'

interface OrderStickySummaryR2CJobsheetProps {}

const OrderStickySummaryR2CJobsheet: FC<OrderStickySummaryR2CJobsheetProps> = () => {
  const { datasource } = useShoppingBasket()
  const dispatch = useDispatch()
  const order = useSelector((state: RootState) => state.shoppingBasketWizard.order)
  const r2c = useSelector((state: RootState) => state.r2c)

  const openReferenceSidePanel = useCallback(() => {
    dispatch(toggleR2CJobsheetSidepanel(true))
  }, [])

  const closeReferenceSidePanel = useCallback(() => {
    dispatch(refetchShoppingBasketOrder(true))
    dispatch(toggleR2CJobsheetSidepanel(false))
  }, [])

  const closeTaskSidePanel = useCallback(() => {
    dispatch(refetchShoppingBasketOrder(true))
    dispatch(toggleR2CTaskSidepanel(false))
  }, [])

  return (
    <>
      <div>
        <FormInputLabel htmlFor="jobSheetReference">
          {i18next.t('r2cLabels.r2cJobsheetNumber')}
        </FormInputLabel>

        <Flex
          sx={{
            alignItems: 'center',
            gap: 4,
          }}
        >
          {order?.customerOrderNumber?.value && (
            <Text variant="bodySmall">{order?.customerOrderNumber?.value}</Text>
          )}

          <Button
            data-t-id="R2C-add-jobsheetnumber"
            onClick={openReferenceSidePanel}
            variant="outline"
            icon={order?.customerOrderNumber?.value ? ICONS.PENCIL : ICONS.PLUS}
          >
            {order?.customerOrderNumber?.value
              ? getTextFieldValue(datasource.editJobsheetButtonLabel)
              : i18next.t('r2cLabels.addJobsheet')}
          </Button>
        </Flex>
      </div>

      {r2c.showR2CJobsheetSidepanel?.showSidepanel && (
        <R2CJobsheetReferenceSidePanel
          active={r2c.showR2CJobsheetSidepanel?.showSidepanel}
          onSidepanelClose={closeReferenceSidePanel}
          jobsheetReference={order?.customerOrderNumber?.value}
          partNumber={r2c.showR2CJobsheetSidepanel?.partNumber}
        />
      )}
      <R2CJobsheetTaskSidePanel
        active={r2c.showR2CTaskSidepanel?.showSidepanel}
        onSidepanelClose={closeTaskSidePanel}
        jobsheetReference={order?.customerOrderNumber?.value}
        customerOrderNumber={order?.externalReference?.value}
        partNumber={r2c.showR2CTaskSidepanel?.partNumber}
      />
    </>
  )
}

export default OrderStickySummaryR2CJobsheet
