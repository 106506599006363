import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTheme } from '@emotion/react'
import { RTL_READING_LANGUAGES, TRP_THEME } from '../constants/themeConstants'
import { SeoContext, SitecoreContext } from '../types/sitecoreContextProps'

const getSearchIndexingOptions = (seo: SeoContext) => {
  const searchIndexingOptions = []

  if (seo.noFollow) searchIndexingOptions.push('nofollow')
  if (seo.noIndex) searchIndexingOptions.push('noindex')

  return searchIndexingOptions
}

interface MetaProps {
  context: SitecoreContext
}

const Meta = ({ context }: MetaProps) => {
  const { language, seo, site } = context
  // @ts-ignore
  const { colors } = useTheme()

  const readingDirection = RTL_READING_LANGUAGES.includes(language.toLowerCase())
    ? 'rtl'
    : 'ltr'

  const openGraphUrl = seo.canonicalUrl ?? seo.url
  const searchIndexingOptions = getSearchIndexingOptions(seo)

  return (
    <Helmet htmlAttributes={{ dir: readingDirection }}>
      {seo.imageUrl && <meta name="image" content={seo.imageUrl} />}

      <meta property="og:title" content={site.title} />
      <meta name="description" content={seo.metaDescription} />
      <meta property="og:description" content={seo.metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={openGraphUrl} />
      {seo.imageUrl && <meta property="og:image" content={seo.imageUrl} />}
      <meta property="og:locale" content={language} />
      <meta property="og:site_name" content={site.title} />

      <meta property="twitter:title" content={site.title} />
      <meta property="twitter:description" content={seo.metaDescription} />
      {seo.imageUrl && <meta property="twitter:image" content={seo.imageUrl} />}
      {seo.metaKeywords && <meta name="keywords" content={seo.metaKeywords} />}

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="theme-color"
        content={site.name === TRP_THEME ? colors.primary : colors.gray[4]}
      />
      {searchIndexingOptions.length > 0 && (
        <meta name="robots" content={searchIndexingOptions.join(', ')} />
      )}

      <meta name="google" content="notranslate" />
    </Helmet>
  )
}

export default Meta
