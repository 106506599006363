import React, { FC } from 'react'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Datasource } from '../../../types/layoutService'
import { PersonalizedGreetingBannerDatasource } from './PersonalizedGreetingBanner'
import PersonalizedGreetingBannerBase from './PersonalizedGreetingBannerBase'

const PersonalizedGreetingBannerPageEditingComponent: FC<
  Datasource<PersonalizedGreetingBannerDatasource>
> = ({ datasource: { headingPrefix, headingSuffixType, body } }) => {
  const suffixType = getTextFieldValue(headingSuffixType)

  return (
    <PersonalizedGreetingBannerBase
      headingPrefix={getTextFieldValue(headingPrefix)}
      suffix={suffixType}
      body={getTextFieldValue(body)}
    />
  )
}

export default PersonalizedGreetingBannerPageEditingComponent
