import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  Datasource,
  Params,
  SliderParams,
  TextField,
} from '../../../types/layoutService'
import { PardCardLayoutServiceData } from '../../molecules/Card/PartCard/PartCard'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartCardListResponsive from '../CardList/PartCardListResponsive'
import usePartsListService from '../../../hooks/services/graphql/usePartListService'
import { RootState } from '../../../reducers'
import { useRecommendedPartService } from '../../../hooks/services/rest/ecommerce/useRecommendedPartsService'
import { DEFAULT_SLIDER_PER_PAGE } from '../../../constants/sliderConstants'

interface ShoppingBasketRecommendedPartSliderDatasource {
  title: TextField
}

const ShoppingBasketRecommendedPartSlider: FC<
  Datasource<ShoppingBasketRecommendedPartSliderDatasource> &
    PardCardLayoutServiceData &
    Params<SliderParams>
> = ({ datasource: { title }, params: { variant }, partPage }) => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)

  const [partNumberList, setPartNumberList] = useState<string[]>([])

  const [fetchingRecommendedPartNumber, recommendedPartNumbers] =
    useRecommendedPartService(partNumberList, DEFAULT_SLIDER_PER_PAGE)

  const [fetchingPartList, partList] = usePartsListService(recommendedPartNumbers)

  useEffect(() => {
    setPartNumberList(order?.items?.map(({ partNumber }) => partNumber) || [])
  }, [order])

  if (!order?.items?.length || !partList?.length) return null

  return (
    <PartCardListResponsive
      title={getTextFieldValue(title)}
      variant={variant}
      fetching={fetchingRecommendedPartNumber || fetchingPartList}
      partList={partList}
      partPage={partPage}
      dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.RECOMMENDED_PART_SLIDER}
    />
  )
}

export default withData(ShoppingBasketRecommendedPartSlider, {
  showMessageWhenPageEditing: true,
})
