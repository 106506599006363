import React, { FC, useContext } from 'react'
import {
  VIEW_TYPE_DEFAULT,
  VIEW_TYPE_GRID,
} from '../../../../constants/searchConstants'
import { AssemblySearchContext } from '../../../../providers/AssemblySearchContextProvider'
import { LinkField, TextField } from '../../../../types/layoutService'
import Container from '../../../atoms/Container/Container'
import SearchControls from '../SearchControls/SearchControls'
import AssembliesSearchGridView from './Grid/AssembliesSearchGridView'
import AssemblySearchLineView from './Line/AssemblySearchLineView'

interface AssemblySearchTabPanelProps {
  viewLabel: TextField
  assemblyPage: LinkField
}

const AssemblySearchTabPanel: FC<AssemblySearchTabPanelProps> = ({
  viewLabel,
  assemblyPage,
}) => {
  const { totalCount, viewType } = useContext(AssemblySearchContext)

  return (
    <Container>
      <SearchControls
        viewLabel={viewLabel}
        count={totalCount}
        viewType={viewType}
        defaultViewType={VIEW_TYPE_DEFAULT}
        sx={{ mb: 3, mt: -3 }}
      />
      {viewType === VIEW_TYPE_GRID ? (
        <AssembliesSearchGridView assemblyPage={assemblyPage} />
      ) : (
        <AssemblySearchLineView assemblyPage={assemblyPage} />
      )}
    </Container>
  )
}
export default AssemblySearchTabPanel
