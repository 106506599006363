import React, { ChangeEvent, FC } from 'react'
import { ValueFormField } from '@sitecore-jss/sitecore-jss-forms'
import {
  FieldChangeCallback,
  ValueFieldProps,
} from '@sitecore-jss/sitecore-jss-react-forms'
import { Box } from 'theme-ui'
import FormInputCheckbox from '../../../atoms/FormControls/FormInputCheckbox'

const handleOnChange = (
  field: ValueFormField,
  fieldValue: boolean,
  callback: FieldChangeCallback
) => {
  let valid = true
  const errorMessages = []

  if (field.model.required && !fieldValue) {
    valid = false
    errorMessages.push(`${field.model.title} is required`)
  }

  callback(field.valueField.name, String(fieldValue), valid, errorMessages)
}

export const Checkbox: FC<ValueFieldProps> = (props) => {
  const { field, value, onChange, tracker, errors } = props

  return (
    <Box sx={{ marginBottom: 4 }}>
      <FormInputCheckbox
        type="checkbox"
        className={field.model.cssClass}
        id={field.valueField.id}
        name={field.valueField.name}
        checked={Boolean(value)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleOnChange(field, e.target.checked, onChange)
        }
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
      >
        {field.model.title}
      </FormInputCheckbox>
    </Box>
  )
}
