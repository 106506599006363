import i18next from 'i18next'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import {
  FILTER_GROUP_APPLICATION_IDS,
  FILTER_GROUP_APPLICATION_MAKES_IDS,
  FILTER_GROUP_APPLICATION_MODELS_IDS,
  FILTER_MASTER_GROUP_APPLICATION_ID,
} from '../../../../constants/searchConstants'
import { getFilterOrPersistedFilterOptions } from '../../../../helpers/filterHelper'
import { PartSearchResultContext } from '../../../../providers/PartSearchResultContextProvider'
import { FilterGroup, FilterOption } from '../../../../types/filterProps'
import CollapsibleList from '../../../atoms/CollapsibleList/CollapsibleList'
import PartFilterGroupHeading from './PartFilterGroupHeading'
import PartFilterOption from './PartFilterOption'

interface PartFilterGroupApplicationProps {
  active: boolean
  toggleFilterGroupId: () => void
  lastModifiedFilterGroup?: FilterGroup
  applicationFilterGroups?: FilterGroup[]
  selectedFilterCount: number
  onChange: (filter: FilterGroup) => void
}

const PartFilterGroupApplication: FC<PartFilterGroupApplicationProps> = ({
  active,
  toggleFilterGroupId,
  lastModifiedFilterGroup,
  applicationFilterGroups,
  selectedFilterCount,
  onChange,
}) => {
  const { fetching } = useContext(PartSearchResultContext)

  const applicationFilterGroupsRenamed = useMemo(() => {
    const groups = applicationFilterGroups || []
    const typeGroup = groups.find(
      (group) => group.id === FILTER_GROUP_APPLICATION_IDS
    )

    return [
      typeGroup
        ? { ...typeGroup, description: i18next.t('filteringLabels.type') }
        : undefined,
      groups.find((group) => group.id === FILTER_GROUP_APPLICATION_MAKES_IDS),
      groups.find((group) => group.id === FILTER_GROUP_APPLICATION_MODELS_IDS),
    ].filter((group) => group) as FilterGroup[]
  }, [applicationFilterGroups])

  const getPersistedFilterOptions = useCallback(
    (filterId: string, filterOptions: FilterOption[]) =>
      getFilterOrPersistedFilterOptions(
        lastModifiedFilterGroup,
        filterId,
        filterOptions
      ),
    [lastModifiedFilterGroup]
  )

  if (!applicationFilterGroupsRenamed?.length) return null

  return (
    <Box
      data-t-id={`filter-group-${FILTER_MASTER_GROUP_APPLICATION_ID}`}
      sx={{
        mb: 4,
        ':last-of-type': {
          mb: 0,
        },
      }}
    >
      <PartFilterGroupHeading
        onClick={toggleFilterGroupId}
        active={active}
        selectionCount={selectedFilterCount}
      >
        {i18next.t('filteringLabels.application')}
      </PartFilterGroupHeading>

      <Box
        sx={{
          height: active ? '100%' : 0,
          overflow: 'hidden',
        }}
      >
        <Box mt={4} pl={3}>
          {active &&
            !!applicationFilterGroupsRenamed?.length &&
            applicationFilterGroupsRenamed.map((filterGroup) => (
              <Box
                key={filterGroup.id}
                sx={{
                  mb: 4,
                  ':last-of-type': {
                    mb: 0,
                  },
                  opacity:
                    fetching && lastModifiedFilterGroup?.id !== filterGroup.id
                      ? 0.5
                      : undefined,
                  '*': {
                    cursor:
                      fetching && lastModifiedFilterGroup?.id !== filterGroup.id
                        ? 'wait !important'
                        : undefined,
                  },
                }}
              >
                <Text variant="buttonText" mb={2}>
                  {filterGroup.description}
                </Text>

                <CollapsibleList animate={false} itemsVisible={4}>
                  {getPersistedFilterOptions(
                    filterGroup.id,
                    filterGroup.filterOptions
                  ).map((option) => (
                    <PartFilterOption
                      key={option.value}
                      id={filterGroup.id}
                      groupDescription={`${FILTER_MASTER_GROUP_APPLICATION_ID} ${filterGroup.description}`}
                      onChange={() => onChange(filterGroup)}
                      type={filterGroup.type}
                      disabled={
                        fetching && lastModifiedFilterGroup?.id !== filterGroup.id
                      }
                      {...option}
                    />
                  ))}
                </CollapsibleList>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  )
}

export default PartFilterGroupApplication
