import React, { FC } from 'react'
import { getDescriptionFromFlattenedCategoryTree } from '../../../../../../helpers/categoryHelper'
import { Category } from '../../../../../../hooks/services/graphql/useCategoryListService'
import ActionItemBase from '../../../../../atoms/ActionItemBase/ActionItemBase'
import PartSearchControlsSubcategoryListItem from './PartSearchControlsSubcategoryListItem'

interface PartSearchControlsSubcategoryListModalItemProps {
  categoryTree: Category[]
  callback?: () => void
}

const PartSearchControlsSubcategoryListModalItem: FC<
  PartSearchControlsSubcategoryListModalItemProps
> = ({ categoryTree, callback }) => {
  if (!categoryTree?.length) return null

  return (
    <PartSearchControlsSubcategoryListItem
      categoryTree={categoryTree}
      callback={callback}
    >
      <ActionItemBase
        variant="buttonText"
        sx={{
          py: 3,
          color: 'primary',
          fontSize: 'inherit',
          fontWeight: 'normal',
        }}
      >
        {getDescriptionFromFlattenedCategoryTree(categoryTree)}
      </ActionItemBase>
    </PartSearchControlsSubcategoryListItem>
  )
}

export default PartSearchControlsSubcategoryListModalItem
