import React, { FC, useContext, useEffect, useState } from 'react'
import { Box } from 'theme-ui'
import { Company, Supplier } from '../../../../types/userProps'
import StoreSwitcherMenu from './StoreSwitcherMenu'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import { UserContext } from '../../../../providers/UserProvider'
import { TextField } from '../../../../types/layoutService'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'

interface StoreSwitcherMenuListProps {
  companySelectTitle: TextField
  supplierSelectTitle: TextField
}

const StoreSwitcherMenuList: FC<StoreSwitcherMenuListProps> = ({
  companySelectTitle,
  supplierSelectTitle,
}) => {
  const { activeUserState } = useContext(ActiveUserStateContext)
  const {
    activeCompany,
    activeSupplier,
    isImpersonated,
    impersonatedCompany,
    impersonatedSupplier,
  } = useContext(ActiveStoreProviderContext)
  const [companies, setCompanies] = useState<Company[]>()
  const [suppliers, setSuppliers] = useState<Supplier[]>()
  const { userProfile } = useContext(UserContext)

  const {
    site: { name },
  } = useSitecoreContext()

  useEffect(() => {
    if (userProfile && userProfile.companies && !isImpersonated) {
      setCompanies(userProfile.companies)
    }
  }, [activeUserState, isImpersonated, userProfile])

  useEffect(() => {
    if (activeCompany) {
      setSuppliers(activeCompany.suppliers)
    }
  }, [activeCompany])

  // Render an empty div to prevent layout shifting
  if (!activeSupplier && !activeCompany) return <div>&nbsp;</div>

  return (
    <Box
      sx={{
        display: isImpersonated ? ['none', 'none', 'flex'] : 'flex',
        width: !isImpersonated ? ['100%', '100%', 'auto'] : 'auto',
      }}
    >
      {!isImpersonated && (
        <>
          {name !== MULTISUPPORT_SITE_CONTEXT && activeSupplier && (
            <StoreSwitcherMenu
              data-type="supplier"
              title={supplierSelectTitle}
              label={activeSupplier?.companyName}
              items={suppliers || []}
              activeItem={activeSupplier}
              dataLayerEvent={DATA_LAYER.EVENT.SELECT_SUPPLIER}
            />
          )}
          {activeCompany && (
            <StoreSwitcherMenu
              data-type="company"
              title={companySelectTitle}
              label={activeCompany?.companyName}
              items={companies || []}
              activeItem={activeCompany}
              prependLocationCode
            />
          )}
        </>
      )}

      {isImpersonated && (
        <>
          {impersonatedSupplier && (
            <StoreSwitcherMenu
              data-type="supplier"
              title={supplierSelectTitle}
              label={impersonatedSupplier.name}
            />
          )}

          {impersonatedCompany && (
            <StoreSwitcherMenu
              data-type="company"
              title={companySelectTitle}
              label={impersonatedCompany.name}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default StoreSwitcherMenuList
