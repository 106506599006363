import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { MAX_NUMBER_OF_PARTS } from '../../../constants/partSalesInformationConstants'
import { GraphServiceTuple } from '../../../types/graphServiceTypes'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'

const GET_DEALER_PART_LIST = loader('./schemas/dealerPartListQuery.graphql')

interface DealerPartListQueryVariables {
  [key: string]: any
  partNumbers: string[]
  cultureCode: string
  customerCompanyId?: number
  supplierCompanyId?: number
  isImpersonated?: boolean
}

interface DealerPartListQueryData {
  dealerParts: DealerPart[]
}

export interface DealerPartImage {
  partImageId: number
  partId: number
  imageUrl: string
  thumbnailImageUrl: string
  priority: number
}

export interface DealerPartCrossReference {
  crossReferenceId: number
  partId: number
  partNumber: string
  crossReferencePartNumber: string
  vendorName: string
  brandName: string
}

export interface DealerPart {
  partId: number
  partNumber: string
  partName: string
  partProperties: string
  brandName: string
  manufacturerPartNumber: string
  partImages?: DealerPartImage[]
  partCrossReferences?: DealerPartCrossReference[]
}

const useDealerPartListService = (
  dealerPartNumbers?: string[]
): GraphServiceTuple<DealerPart[] | undefined> => {
  const isMounted = useIsMounted()

  const [baseHeaders, baseVariables] = useBaseGraphQLService()
  const [dealerPartList, setDealerPartList] = useState<DealerPart[] | undefined>(
    undefined
  )

  const onData = useCallback((data?: DealerPartListQueryData) => {
    if (data?.dealerParts?.length) {
      setDealerPartList(data.dealerParts)
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    DealerPartListQueryData,
    DealerPartListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && dealerPartNumbers?.length) {
      const limitedDealerPartNumbers = dealerPartNumbers.slice(
        0,
        MAX_NUMBER_OF_PARTS
      )

      fetch({
        document: GET_DEALER_PART_LIST,
        variables: {
          ...baseVariables,
          partNumbers: limitedDealerPartNumbers,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, dealerPartNumbers])

  return [fetching, dealerPartList]
}

export default useDealerPartListService
