import { GraphQLClient } from 'graphql-request'
import React, { createContext, FC, ReactNode } from 'react'

export const GraphQLClientContext = createContext<GraphQLClient | undefined>(
  undefined
)

interface GraphQLClientProviderProps {
  client: GraphQLClient
  children: ReactNode
}

const GraphQLClientProvider: FC<GraphQLClientProviderProps> = ({
  children,
  client,
}) => (
  <GraphQLClientContext.Provider value={client}>
    {children}
  </GraphQLClientContext.Provider>
)

export default GraphQLClientProvider
