import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { UserProps } from '../../../types/userProps'
import { DEFAULT_PARTS_PER_PAGE_COUNT } from '../../../constants/searchConstants'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CULTURE_CODE,
} from '../../../constants/userConstants'
import { FilterGroup } from '../../../types/filterProps'
import { HEADER_SESSION_ID } from '../../../constants/httpConstants'

export interface SetFilters {
  id: string
  values: { value: string }[]
}

export const executePartSearchFilter = (
  client: AxiosInstance,
  payload: string,
  cancelToken: CancelToken,
  cultureCode?: string,
  countryCode?: string,
  pageIndex?: number,
  partsPerPage?: number,
  setFilters?: SetFilters[],
  user?: UserProps,
  xSessionId?: string,
  isImpersonated?: boolean,
  customerCompanyId?: number,
  supplierCompanyId?: number
): Promise<AxiosResponse<FilterGroup[]>> =>
  client.post(
    '/partsearchfilter/',
    {
      keyword: payload,
      countryCode: countryCode ?? DEFAULT_COUNTRY_CODE,
      cultureCode: cultureCode ?? DEFAULT_CULTURE_CODE,
      paging: {
        pageNumber: pageIndex || 0,
        partsPerPage: partsPerPage || DEFAULT_PARTS_PER_PAGE_COUNT,
      },
      filters: setFilters || [],
    },
    {
      ...createRequestConfig(
        user,
        (xSessionId && { [HEADER_SESSION_ID]: xSessionId }) || {},
        {
          isImpersonated: isImpersonated || undefined,
          customerCompanyId,
          supplierCompanyId,
        }
      ),
      cancelToken,
    }
  )
