import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface SendEditVehicle {}

export interface SendEditVehiclePayloadProps {
  vin: string | undefined
  licensePlate: string
  customerVehicleId: string
  expirationDateLegalInspection: string
  expirationDateTacho: string
  type?: string
  buildDate?: string
}

const sendEditVehiclePayload = (
  payload: SendEditVehiclePayloadProps,
  client: AxiosInstance,
  queryParams: DafDefaultQueryParams,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<SendEditVehicle>>> =>
  client.put(`/companyvehicle/${payload.vin}`, payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default sendEditVehiclePayload
