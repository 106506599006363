import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  getUrlQueryValue,
  getUrlWithCurrentSearchParams,
} from '../../../helpers/urlHelper'
import {
  URL_PAGE_PARAM,
  URL_QUERY_STRING_PARAM,
} from '../../../constants/urlConstants'
import { TextField } from '../../../types/layoutService'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import SearchBarForm from '../../molecules/SearchBar/SearchBarForm'
import SearchBarInput from '../../molecules/SearchBar/SearchBarInput'
import { DEALER_LOCATOR_USE_CLIENT_LOCATION } from '../../../constants/cookieConstants'
import SearchBarControls from '../../molecules/SearchBar/SearchBarControls'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

interface DealerLocatorSearchBarProps {
  placeholderLabel: TextField
  useClientLocation?: boolean
  currentLocationActiveLabel: string
  resetCurrentLocationUsage: () => void
}

const DealerLocatorSearchBar: FC<DealerLocatorSearchBarProps> = ({
  placeholderLabel,
  useClientLocation,
  currentLocationActiveLabel,
  resetCurrentLocationUsage,
}) => {
  const history = useHistory()
  const inputRef = useRef<HTMLInputElement>(null)

  const initialValue = useMemo(
    () => getUrlQueryValue(URL_QUERY_STRING_PARAM, history.location.search),
    [history.location.search]
  )
  const [searchString, setSearchString] = useState(initialValue || '')

  const setUrlQueryValue = useCallback(
    (queryValue: string) => {
      const targetUrl = getUrlWithCurrentSearchParams()

      targetUrl.searchParams.delete(URL_PAGE_PARAM)

      if (queryValue === '') {
        targetUrl.searchParams.delete(URL_QUERY_STRING_PARAM)
      } else {
        targetUrl.searchParams.set(URL_QUERY_STRING_PARAM, queryValue)
      }

      history.push({
        pathname: targetUrl.pathname,
        search: targetUrl.searchParams.toString(),
      })
    },
    [history]
  )

  const submit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      if (searchString === currentLocationActiveLabel) return

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_FUNCTION,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.DEALER_FINDER,
        search_query: searchString,
        page_type: '',
      })

      setUrlQueryValue(searchString)
    },
    [currentLocationActiveLabel, searchString, setUrlQueryValue]
  )

  useEffect(() => {
    if (useClientLocation) {
      setSearchString(currentLocationActiveLabel)
    } else {
      const searchValue =
        getUrlQueryValue(URL_QUERY_STRING_PARAM, history.location.search) || ''

      setSearchString(searchValue)
    }
  }, [currentLocationActiveLabel, history.location.search, useClientLocation])

  return (
    <SearchBarForm
      onSubmit={submit}
      sx={{
        marginTop: 3,
        marginBottom: 2,
      }}
    >
      <SearchBarInput
        ref={inputRef}
        data-t-id="dealer-locator-search-input"
        required={false}
        name={URL_QUERY_STRING_PARAM}
        value={searchString}
        placeholder={getTextFieldValue(placeholderLabel)}
        autoComplete="off"
        onChange={(event) => {
          if (event?.target.value !== currentLocationActiveLabel) {
            setSearchString(event?.target.value)
          }
        }}
        onKeyDown={(event) => {
          if (
            event.currentTarget.value === currentLocationActiveLabel &&
            event.key !== 'Enter'
          ) {
            setSearchString('')

            resetCurrentLocationUsage()

            window.localStorage.setItem(DEALER_LOCATOR_USE_CLIENT_LOCATION, 'false')
          }
        }}
        sx={{ border: '1px solid', borderColor: 'gray.1' }}
      />

      <SearchBarControls
        submitButtonTestId="storelocator-submit-button"
        inputRef={inputRef}
        currentInput={searchString}
        resetButtonTestId="Search-reset-button"
        onReset={() => {
          setSearchString('')
          setUrlQueryValue('')
        }}
      />
    </SearchBarForm>
  )
}

export default DealerLocatorSearchBar
