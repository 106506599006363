import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { ICONS } from '../../../constants/iconConstants'
import Button, { ButtonProps } from '../../atoms/Button/Button'

interface SearchBarButtonProps extends Omit<ButtonProps, 'css' | 'variant'> {}

const SearchBarButton: FC<SearchBarButtonProps> = ({ sx, ...boxProps }) => {
  const { colors } = useTheme()

  return (
    <Button
      variant="infinite"
      icon={ICONS.SEARCH}
      iconSize={5}
      type="submit"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        px: 2,
        color: 'text',
        ':hover, :focus, :active': {
          color: `${colors.primary} !important`,
        },
        'svg path': {
          fill: 'currentColor!important',
        },
        ...sx,
      }}
      {...boxProps}
    />
  )
}

export default SearchBarButton
