import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

interface CompanyVehicleResult {
  vin: string
  make: string
  licensePlate: string
  customerVehicleId: string
  expirationDateLegalInspection: string
  expirationDateTacho: string
  type: string
  buildDate: string
}

interface CompanyVehicleData {
  result: CompanyVehicleResult
  messages: null
}

const getCompanyVehicle = (
  vin: string | undefined,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CompanyVehicleData>> =>
  client.get(`companyvehicle/${vin}`, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default getCompanyVehicle
