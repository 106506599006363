import React, { FC, useContext, useMemo } from 'react'
import i18next from 'i18next'
import { Box, Text } from 'theme-ui'
import SidePanelBody from '../SidePanelBody'
import SidePanel from '../SidePanel'
import usePaccarDistributionCenterStockListService from '../../../../hooks/services/graphql/usePaccarDistributionCenterStockListService'
import Spinner from '../../../atoms/Spinner/Spinner'
import { getDividerSx } from '../../../atoms/Divider/Divider'
import Button from '../../../atoms/Button/Button'
import { navigateClient } from '../../../../helpers/dom'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { getDealerDepartmentCommunications } from '../../../../helpers/dealerHelper'
import { getStockPhoneNumber } from '../../../../helpers/stockHelper'
import { StockLocation } from '../../../../hooks/services/graphql/usePartSalesInformationService'
import { DELIVERY_PRESCRIPTION_CODES } from '../../../../constants/stockConstants'

interface PaccarDistributionCenterStockInformationSidePanelProps {
  partNumber: string
  displayPartNumber?: string
  active?: boolean
  dealerContactInformationVisible?: boolean
  onClose: () => void
  stockLocation?: StockLocation
}

const PaccarDistributionCenterStockInformationSidePanel: FC<
  PaccarDistributionCenterStockInformationSidePanelProps
> = ({
  partNumber,
  displayPartNumber,
  dealerContactInformationVisible,
  active,
  onClose,
  stockLocation,
}) => {
  const { activeSupplierInformation } = useContext(ActiveStoreProviderContext)

  const dealerPhoneNumberFromApi = useMemo(
    () => getDealerDepartmentCommunications(activeSupplierInformation)?.phone,
    [activeSupplierInformation]
  )

  const dealerPhoneNumber = useMemo(
    () => getStockPhoneNumber(dealerPhoneNumberFromApi),
    [dealerPhoneNumberFromApi]
  )

  const [fetching, stockList] = usePaccarDistributionCenterStockListService(
    useMemo(() => (active && [partNumber]) || [], [partNumber, active])
  )

  const stockLocations = useMemo(
    () =>
      stockList?.find(
        ({ partNumber: stockBreakdownPartNumber }) =>
          stockBreakdownPartNumber === partNumber
      )?.allocationLocations,
    [stockList, partNumber]
  )

  return (
    <SidePanel
      active={!!active}
      onClose={onClose}
      title={i18next.t('stockLabels.stockAvailability')}
    >
      <SidePanelBody>
        <Text variant="heading3" paddingBottom="3">
          {`${i18next.t('partLabels.partNumber')}: ${
            displayPartNumber || partNumber
          }`}
        </Text>

        {stockLocation?.deliveryPrescriptionCode ===
        DELIVERY_PRESCRIPTION_CODES.DIRECT_SHIP_DELIVERY ? (
          <Text
            sx={{
              ...getDividerSx('top'),
            }}
          >
            <Text variant="bodyContent">
              <p>{i18next.t('stockLabels.directShipDeliveryInfo')}</p>
            </Text>
          </Text>
        ) : (
          <>
            {fetching ? (
              <Box paddingY={3}>
                <Spinner size={3} color="gray.2" />
              </Box>
            ) : (
              <>
                {stockLocations?.map(({ location, availableStock }) => (
                  <Text
                    key={location}
                    variant="bodyContent"
                    sx={{
                      color: 'gray.2',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingY: 3,
                      ...getDividerSx('top'),
                    }}
                  >
                    <span>{location}</span>

                    <span data-t-id={`StockAvailability-${location}`}>
                      {availableStock}
                    </span>
                  </Text>
                ))}
              </>
            )}
          </>
        )}

        {dealerContactInformationVisible ? (
          <Box
            sx={{
              marginTop: 6,
            }}
          >
            <Text variant="heading3" mb={1}>
              {i18next.t('stockLabels.questions')}
            </Text>

            <Text variant="bodyCompact" mb={3} color="gray.4">
              {i18next.t('stockLabels.contactCustomerService')}
            </Text>

            <Button
              variant="outline"
              onClick={(event) => navigateClient(`tel:${dealerPhoneNumber}`, event)}
            >
              {`${i18next.t('commonLabels.call')} ${dealerPhoneNumber}`}
            </Button>
          </Box>
        ) : null}
      </SidePanelBody>
    </SidePanel>
  )
}

export default PaccarDistributionCenterStockInformationSidePanel
