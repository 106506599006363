import React, { forwardRef } from 'react'
import FormInputBase, { FormInputBaseProps } from './FormInputBase'

export interface FormInputTextProps extends FormInputBaseProps {
  maxLength?: number
}

const FormInputText = forwardRef<HTMLInputElement, FormInputTextProps>(
  ({ children, disabled, ...rest }, ref) => (
    <FormInputBase ref={ref} disabled={disabled} {...rest}>
      {children}
    </FormInputBase>
  )
)

export default FormInputText
