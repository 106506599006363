import i18next from 'i18next'
import React, { FC, useMemo } from 'react'
import { Text, TextProps } from 'theme-ui'
import { DEALER_DEPARTMENT_CODE_GENERAL } from '../../../constants/dealerLocatorConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Dealer, OpeningHour } from '../../../types/dealerServiceTypes'

interface DealerLocatorDealerDayOpeningHoursProps extends Omit<TextProps, 'css'> {
  dayNumber: number
  dealer: Dealer
}

const convertMilitaryTimeToStandardTime = (
  militaryTime?: number,
  cultureCode?: string
) => {
  if (militaryTime) {
    const time = militaryTime?.toString()
    const hour = time.substring(0, time.length > 3 ? 2 : 1)
    const minutes = time.substring(time.length > 3 ? 2 : 1, time.length)

    const date = new Date()

    date.setHours(parseInt(hour, 10))
    date.setMinutes(parseInt(minutes, 10))

    return date.toLocaleTimeString(cultureCode || [], {
      hour: '2-digit',
      minute: '2-digit',
    })
  }
  return undefined
}

const getOpeningHours = (dayNumber: number, openingHours?: OpeningHour[] | null) =>
  openingHours?.find(
    (entry) =>
      entry &&
      entry?.fromDay &&
      entry?.toDay &&
      entry.departmentCode === DEALER_DEPARTMENT_CODE_GENERAL &&
      dayNumber >= entry.fromDay &&
      dayNumber <= entry.toDay
  )

const createTimeString = (
  timeFrom?: number,
  timeTo?: number,
  cultureCode?: string
) =>
  !!timeFrom && !!timeTo
    ? `${convertMilitaryTimeToStandardTime(
        timeFrom,
        cultureCode
      )} - ${convertMilitaryTimeToStandardTime(timeTo, cultureCode)}`
    : undefined

const getOpeningHoursList = (
  dayNumber: number,
  openingHours?: OpeningHour[] | null,
  cultureCode?: string
) => {
  const OpeningHours = getOpeningHours(dayNumber, openingHours)

  if (OpeningHours) {
    const mainOpeningHours = createTimeString(
      OpeningHours?.fromTime,
      OpeningHours.toTime,
      cultureCode
    )

    const additionalOpeningHours = createTimeString(
      OpeningHours?.additionalFromTime,
      OpeningHours.additionalToTime,
      cultureCode
    )

    return [mainOpeningHours, additionalOpeningHours].filter(
      (item) => item
    ) as string[]
  }

  return undefined
}

const DealerLocatorDealerDayOpeningHours: FC<
  DealerLocatorDealerDayOpeningHoursProps
> = ({ dayNumber, dealer, sx, ...props }) => {
  const { languageContext } = useSitecoreContext()

  const openingHoursList = useMemo(
    () =>
      getOpeningHoursList(
        dayNumber,
        dealer.openingHours,
        languageContext?.cultureCode
      ),
    [dayNumber, dealer.openingHours, languageContext]
  )

  if (!dealer.openingHours || !dealer.openingHours.length) return null

  return (
    <Text
      variant="bodySmall"
      sx={{
        lineHeight: 'menuList',
        ...sx,
      }}
      {...props}
    >
      {openingHoursList &&
        openingHoursList.map((timeFrame, i) => (
          <div key={i.toString()}>{timeFrame}</div>
        ))}

      {!openingHoursList && i18next.t('dealerLabels.closed')}
    </Text>
  )
}

export default DealerLocatorDealerDayOpeningHours
