import React, { FC, useCallback, useEffect, useState } from 'react'
import withData from '../../../enhancers/withData'
import useHasMounted from '../../../hooks/useHasMounted'
import { DialogPropsBase } from '../../../types/dialogTypes'
import { Datasource } from '../../../types/layoutService'
import PaginatedSlideshowModal from '../Modal/ModalVariants/PaginatedSlideshowModal'
import { Slideshow } from '../SlideshowHandout/SlideshowHandout'
import SlideshowModalExperienceEditor from './SlideshowModalExperienceEditor'

interface SlideshowModalProps extends Datasource<Slideshow>, DialogPropsBase {}

const SlideshowModal: FC<SlideshowModalProps> = ({
  datasource,
  hash,
  isEditingOrPreviewing,
}) => {
  const hasMounted = useHasMounted()
  const [isActive, setActive] = useState<boolean>(false)

  useEffect(() => {
    if (!hasMounted) return

    const hasPreviouslyInteractedWithModal = window.localStorage.getItem(hash)

    if (!hasPreviouslyInteractedWithModal) {
      setActive(true)
    }
  }, [hash, hasMounted])

  const handleCloseClick = useCallback(() => {
    setActive(false)
    window.localStorage.setItem(hash, new Date().toISOString())
  }, [hash])

  return (
    <PaginatedSlideshowModal
      isActive={isEditingOrPreviewing || isActive}
      slideshow={datasource}
      handleCloseClick={isEditingOrPreviewing ? undefined : handleCloseClick}
    />
  )
}

export default withData(SlideshowModal, {
  pageEditingComponent: SlideshowModalExperienceEditor,
})
