import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { ICONS } from '../../../constants/iconConstants'
import {
  getModalActiveTextColor,
  getModalBackgroundColor,
  getModalTextColor,
} from '../../../helpers/modalHelper'
import { SubThemeName } from '../../../providers/ThemeProvider'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ModalVariant } from './Modal'

export interface ModalCloseButtonProps {
  onClick: () => void
  theme: SubThemeName
  variant: ModalVariant
}

const ModalCloseButton: FC<ModalCloseButtonProps> = ({
  theme,
  onClick,
  variant,
}) => {
  const themeSettings = useTheme()
  const textColor =
    variant === 'minimal' ? 'primary' : getModalTextColor(theme, themeSettings)
  const borderColor = variant === 'minimal' ? 'gray.1' : 'transparent'
  const textActiveColor = getModalActiveTextColor(theme, themeSettings)
  const backgroundColor = getModalBackgroundColor(theme, themeSettings)

  return (
    <ClickableWrapper
      data-t-id="modal-close-button"
      onClick={onClick}
      aria-label="Close"
      sx={{
        verticalAlign: 'center',
        textAlign: 'center',
        width: '40px',
        height: '40px',
        backgroundColor: [
          backgroundColor,
          backgroundColor,
          backgroundColor,
          'white',
        ],
        borderRadius: '50%',
        border: '1px solid',
        borderColor:
          variant === 'default'
            ? [borderColor, borderColor, borderColor, 'gray.1']
            : borderColor,
        right: 0,
        color:
          variant === 'default'
            ? [textColor, textColor, textColor, 'primary']
            : textColor,
        ':hover, :active, :focus': {
          color: textActiveColor,
        },
      }}
    >
      <IconWrapper icon={ICONS.CROSS} size={5} />
    </ClickableWrapper>
  )
}

export default ModalCloseButton
