import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Box, Flex, Text } from 'theme-ui'
import { Field, Form, FormSpy } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import dayjs from 'dayjs'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { RootState } from '../../../../reducers'
import { VehicleDetailResponse } from '../../../../services/rest/ecommerce/dafVehicleDetail'
import SidePanel from '../SidePanel'
import { toggleRequestQuotePanel } from '../../../../actions/dafCompanyVehicleActions'
import SidePanelMain from '../SidePanelMain'
import SidePanelPaddedBox from '../SidePanelPaddedBox'
import SidePanelClickableSection from '../SidePanelClickableSection/SidePanelClickableSection'
import SearchServicePartners from '../../../molecules/SearchServicePartners/SearchServicePartners'
import { Dealer } from '../../../../types/dealerServiceTypes'
import Radio from '../../../atoms/inputs/Radio/Radio'
import SidePanelFooter from '../SidePanelFooter'
import { ServicesContext } from '../../../../providers/ServicesProvider'
import TiledRadioSet from '../../../atoms/FormFields/TiledRadioSet'
import { SelectField, TextAreaField, TextField } from '../../../atoms/FormFields'
import { InputMileageTextField } from '../../../atoms/FormFields/InputMileageTextField'
import { InputDateField } from '../../../atoms/FormFields/InputDateField'
import { ValidationRules } from '../../../../helpers/formFieldHelper'
import Button from '../../../atoms/Button/Button'
import { COLORS } from '../../../../constants/themeConstants'
import sendRequestQuotePayload, {
  RequestQuotePayloadProps,
} from '../../../../services/rest/ecommerce/sendRequestRmServicePlanQuote'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { UserContext } from '../../../../providers/UserProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import Spinner from '../../../atoms/Spinner/Spinner'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { RequestNewMultisupportQuoteSidepanelDatasource } from '../../../../types/VehicleDetailGenericComponentDataProps'
import renderHTML from '../../../../helpers/renderHTMLHelper'
import InputCheckboxList from '../../../atoms/FormFields/InputCheckboxList'
import { getFormattedSidepanelVehicleIntroduction } from '../../../../helpers/sidepanelHelper'

const SHOW_OTHER_DAF_PARTNER_SEARCH = 'other'

interface RequestQuotePanelProps {
  datasource: RequestNewMultisupportQuoteSidepanelDatasource
  vehicle?: VehicleDetailResponse
}

type FormData = {
  servicePlan: string
  servicePlanOptions: string[]
  remarks: string
  duration: number
  mileage: number
  startDate: string
  roadType: string
  staticPowerHours: number
  numberOfDropsPerDay: string
  // not sent to api
  supplier: string
}

const RequestQuotePanel = ({ datasource, vehicle }: RequestQuotePanelProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { token } = axios.CancelToken.source()
  const [selectedPartner, setSelectedPartner] = useState<Dealer | null>()
  const [successfullySent, setSuccessFullySent] = useState(false)
  const dispatch = useDispatch()
  const { requestQuotePanelState } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const { servicesData } = useContext(ServicesContext)

  const handleSelectPartner = (partner: Dealer) => {
    setSelectedPartner(partner)
  }

  const handleRemovePartner = () => {
    setSelectedPartner(null)
  }

  const durationOptions = Array.from(
    { length: 96 / 12 },
    (_, index) => 12 + index * 12
  ).map((value) => ({
    value: `${value}`,
    text: `${value} ${i18next.t('commonLabels.months')}`,
  }))

  const roadTypes = [
    {
      value: datasource?.onroadOnlyLabel?.value,
      text: datasource?.onroadOnlyLabel?.value,
    },
    {
      value: datasource?.offRoadLessThan10Label?.value,
      text: datasource?.offRoadLessThan10Label?.value,
    },
    {
      value: datasource?.offRoadBetween11And30Label?.value,
      text: datasource?.offRoadBetween11And30Label?.value,
    },
    {
      value: datasource?.offRoadBetween31And50Label?.value,
      text: datasource?.offRoadBetween31And50Label?.value,
    },
    {
      value: datasource?.offRoadOver50Label?.value,
      text: datasource?.offRoadOver50Label?.value,
    },
  ]

  const numberOfDropsPerDayOptions = [
    {
      value: datasource?.nrOfDropsOption1?.value,
      text: datasource?.nrOfDropsOption1?.value,
    },
    {
      value: datasource?.nrOfDropsOption2?.value,
      text: datasource?.nrOfDropsOption2?.value,
    },
    {
      value: datasource?.nrOfDropsOption3?.value,
      text: datasource?.nrOfDropsOption3?.value,
    },
  ]

  const validate = (values: FormData) => {
    const errors: ValidationErrors = {}

    if (
      vehicle?.rmContract?.servicePlan.details.sellingDealer.locationCode &&
      !values.supplier
    ) {
      errors.supplier = i18next.t('commonFormLabels.required')
    }

    if (
      (!vehicle?.rmContract?.servicePlan.details.sellingDealer.locationCode &&
        !selectedPartner) ||
      (values.supplier === SHOW_OTHER_DAF_PARTNER_SEARCH && !selectedPartner)
    ) {
      errors.otherSupplier = i18next.t('commonFormLabels.required')
    }

    if (!values.servicePlan) {
      errors.servicePlan = i18next.t('commonFormLabels.required')
    }

    if (!values.duration) {
      errors.duration = i18next.t('commonFormLabels.required')
    }

    if (!values.mileage) {
      errors.mileage = i18next.t('commonFormLabels.required')
    }

    if (!values.startDate) {
      errors.startDate = i18next.t('commonFormLabels.required')
    }

    if (values.staticPowerHours && Number.isNaN(+values.staticPowerHours)) {
      errors.staticPowerHours = i18next.t('commonFormLabels.number')
    }

    if (
      values.staticPowerHours &&
      +values.staticPowerHours &&
      +values.staticPowerHours > 24
    ) {
      errors.staticPowerHours = i18next.t('commonFormLabels.maxValue', {
        number: 24,
      })
    }

    return errors
  }

  const onSubmit = (values: FormData) => {
    let locationCode =
      vehicle?.rmContract?.servicePlan?.details.sellingDealer.locationCode || ''

    if (
      !vehicle?.rmContract?.servicePlan.details.sellingDealer.locationCode ||
      values.supplier === SHOW_OTHER_DAF_PARTNER_SEARCH
    ) {
      locationCode = selectedPartner?.code || ''
    }
    const payload: RequestQuotePayloadProps = {
      locationCode,
      cultureCode: cultureCode || '',
      chassisNumber: vehicle?.vehicleDetail.vehicleAttributes.chassisNumber,
      servicePlan: values.servicePlan,
      servicePlanOptions: values.servicePlanOptions,
      remarks: values.remarks,
      durationAndMileage: {
        duration: values.duration,
        expectedYearlyMileage: values.mileage,
        startDate: values.startDate,
      },
      vehicleUsage: {
        roadType: values.roadType,
        staticPowerHours: values.staticPowerHours,
        numberOfDropsPerDay: values.numberOfDropsPerDay,
      },
    }

    mutate(payload)
  }

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (payload: RequestQuotePayloadProps) => postRequestQuote(payload),

    onSuccess: () => {
      setSuccessFullySent(true)
    },
  })

  const postRequestQuote = async (payload: RequestQuotePayloadProps) => {
    const response = await sendRequestQuotePayload(
      dafVehicleDetailQueryParams,
      payload,
      client,
      token,
      user
    )

    return response.data
  }

  const renderSupplierSearch = () => (
    <FormSpy>
      {(props) => (
        <>
          {(!vehicle?.rmContract ||
            !vehicle?.rmContract.servicePlan.details.sellingDealer.locationCode ||
            props.values.supplier === SHOW_OTHER_DAF_PARTNER_SEARCH) && (
            <>
              <SearchServicePartners
                label={datasource?.searchDAFPartnerLabel?.value}
                id="supplier-search"
                onSelectedPartners={handleSelectPartner}
                onRemoveSelectedPartners={handleRemovePartner}
                partners={selectedPartner ? [selectedPartner] : []}
                infoMessage={false}
                filters={[
                  {
                    id: 'BusinessCode',
                    values: ['TS'],
                  },
                ]}
              />
            </>
          )}
        </>
      )}
    </FormSpy>
  )

  const handleClose = () => {
    dispatch(
      toggleRequestQuotePanel({
        isActive: false,
      })
    )
    setSuccessFullySent(false)
  }

  if (!datasource) return null

  return (
    <SidePanel
      position="right"
      disableOutsideClick
      active={requestQuotePanelState}
      onClose={handleClose}
      title={datasource.panelTitle?.value}
    >
      {!successfullySent ? (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            servicePlan: 'nonUkFullCare',
            duration: 12,
            roadType: datasource?.onroadOnlyLabel?.value,
            numberOfDropsPerDay: datasource?.nrOfDropsOption1?.value,
          }}
          render={({ handleSubmit, hasValidationErrors, values, errors }) => (
            <>
              <SidePanelMain>
                <form onSubmit={handleSubmit} id="requestQuoteForm">
                  <SidePanelPaddedBox>
                    <Text variant="bodySmall">
                      {getFormattedSidepanelVehicleIntroduction(datasource, vehicle)}
                    </Text>
                  </SidePanelPaddedBox>
                  {!vehicle ? (
                    <Box sx={{ marginTop: 4 }}>
                      <Spinner size={4} />
                    </Box>
                  ) : (
                    <>
                      <SidePanelClickableSection
                        initialActive
                        label={datasource.section1Title?.value}
                      >
                        {vehicle?.rmContract &&
                          vehicle.rmContract.servicePlan.details.sellingDealer
                            .locationCode && (
                            <Box as="fieldset" sx={{ border: 0, padding: 0 }}>
                              <Text
                                as="legend"
                                variant="bodySmall"
                                sx={{ marginBlockEnd: 2, fontWeight: 'bold' }}
                              >
                                {datasource.selectDAFPartnerLabel?.value}
                              </Text>
                              <Field
                                name="supplier"
                                type="radio"
                                value={
                                  vehicle.rmContract.servicePlan.details
                                    .sellingDealer.locationCode
                                }
                              >
                                {({ input }) => (
                                  <Flex sx={{ gap: 2, marginBlockEnd: 2 }}>
                                    <Radio {...input} id="supplier-main" />
                                    <label htmlFor="supplier-main">
                                      <Text
                                        variant="bodySmall"
                                        sx={{
                                          display: 'block',
                                        }}
                                      >
                                        {
                                          vehicle?.rmContract.servicePlan.details
                                            .sellingDealer.name
                                        }
                                      </Text>
                                    </label>
                                  </Flex>
                                )}
                              </Field>
                              <Flex sx={{ gap: 2, marginBlockEnd: 4 }}>
                                <Field
                                  name="supplier"
                                  type="radio"
                                  value={SHOW_OTHER_DAF_PARTNER_SEARCH}
                                >
                                  {({ input }) => (
                                    <Radio
                                      {...input}
                                      id={`supplier-${SHOW_OTHER_DAF_PARTNER_SEARCH}`}
                                    />
                                  )}
                                </Field>
                                <label
                                  htmlFor={`supplier-${SHOW_OTHER_DAF_PARTNER_SEARCH}`}
                                >
                                  <Text variant="bodySmall">
                                    {i18next.t('commonLabels.other')}
                                  </Text>
                                </label>
                              </Flex>
                            </Box>
                          )}

                        {renderSupplierSearch()}
                      </SidePanelClickableSection>

                      <SidePanelClickableSection
                        label={datasource.section2Title?.value}
                        labelId="servicePlanPackage"
                      >
                        <TiledRadioSet
                          name="servicePlan"
                          label=""
                          ariaLabelledby="servicePlanPackage"
                          items={servicesData?.map((service) => ({
                            title: service.title,
                            description: i18next.exists(
                              `servicePlan.packages.${service.descriptionKey}`
                            )
                              ? i18next.t(
                                  `servicePlan.packages.${service.descriptionKey}`
                                )
                              : service.description,
                            key: service.descriptionKey,
                          }))}
                        />
                      </SidePanelClickableSection>

                      <SidePanelClickableSection
                        label={datasource.section3Title?.value}
                        labelId="availableOptions"
                      >
                        <InputCheckboxList
                          label=""
                          ariaLabelledby="availableOptions"
                          name="servicePlanOptions"
                          options={
                            servicesData
                              ?.find(
                                (service) =>
                                  service.descriptionKey === values.servicePlan
                              )
                              ?.availableOptions?.map((option) => ({
                                value: option.optionKey,
                                label: i18next.exists(
                                  `servicePlan.packages.services.${option.optionKey}`
                                )
                                  ? i18next.t(
                                      `servicePlan.packages.services.${option.optionKey}`
                                    )
                                  : option.title,
                              })) || []
                          }
                        />
                      </SidePanelClickableSection>

                      <SidePanelClickableSection
                        label={datasource.section4Title?.value}
                      >
                        <SelectField
                          name="duration"
                          label={i18next.t('servicePlan.duration')}
                          options={durationOptions}
                        />

                        <InputMileageTextField
                          label={datasource.yearlyMileageLabel?.value}
                          name="mileage"
                          min={1000}
                        />

                        <InputDateField
                          label={i18next.t('servicePlan.startDateLabel')}
                          validationRules={[
                            ValidationRules.ShouldBeTodayOrInTheFuture,
                          ]}
                          name="startDate"
                        />

                        {values.startDate &&
                          values.duration &&
                          !errors?.startDate && (
                            <Text
                              variant="smallText"
                              sx={{
                                fontStyle: 'italic',
                              }}
                            >
                              {`${i18next.t('formLabels.endDate')}: `}
                              {dayjs(values.startDate)
                                .add(values.duration, 'month')
                                .format('DD-MM-YYYY')}
                            </Text>
                          )}
                      </SidePanelClickableSection>

                      <SidePanelClickableSection
                        label={datasource.section5Title?.value}
                      >
                        <SelectField
                          label={datasource.roadTypeLabel?.value}
                          options={roadTypes}
                          name="roadType"
                        />

                        <TextField
                          label={datasource.staticPowerTakeoffLabel?.value}
                          name="staticPowerHours"
                        />

                        <SelectField
                          label={datasource.dropsPerDayLabel?.value}
                          options={numberOfDropsPerDayOptions}
                          name="numberOfDropsPerDay"
                        />
                      </SidePanelClickableSection>

                      <SidePanelClickableSection
                        label={i18next.t(datasource.section6Title?.value, {
                          optionalLabel: i18next.t('formLabels.optional'),
                        })}
                      >
                        <TextAreaField
                          required={false}
                          name="remarks"
                          label={i18next.t('formLabels.addRemarks')}
                          placeholder={i18next.t('formLabels.typeYourRemark')}
                        />
                      </SidePanelClickableSection>
                    </>
                  )}
                </form>
              </SidePanelMain>

              <SidePanelFooter>
                <Box sx={{ width: '100%' }}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="primary"
                    type="submit"
                    // button is outside <form>, but this makes it part of the form again
                    form="requestQuoteForm"
                    disabled={
                      hasValidationErrors ||
                      isLoading ||
                      !vehicle ||
                      !vehicle.vehicleActions.requestQuote.confirmEnabled
                    }
                  >
                    {i18next.t('formLabels.sendRequest')}
                  </Button>
                  <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                    <Text
                      sx={{
                        fontSize: 0,
                      }}
                      color={COLORS.GRAY}
                    >
                      {datasource.footerText?.value}
                    </Text>
                  </Box>
                </Box>
              </SidePanelFooter>
            </>
          )}
        />
      ) : (
        <>
          <SidePanelMain>
            <SidePanelPaddedBox>
              <Text variant="bodySmall">
                {renderHTML(
                  i18next.t(datasource.confirmationText?.value, {
                    selectedDealerName: selectedPartner?.name,
                  })
                )}
              </Text>
            </SidePanelPaddedBox>
          </SidePanelMain>
          <SidePanelFooter>
            <Button sx={{ width: '100%' }} variant="primary" onClick={handleClose}>
              {i18next.t('commonLabels.close')}
            </Button>
          </SidePanelFooter>
        </>
      )}
    </SidePanel>
  )
}

export default RequestQuotePanel
