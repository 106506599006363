import React, { FC, useCallback } from 'react'
import { Flex, FlexProps, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { LinkField, TextField } from '../../../types/layoutService'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'

interface BannerContentProps extends Omit<FlexProps, 'css' | 'title'> {
  title?: TextField
  button?: LinkField
}

export const BannerContent: FC<BannerContentProps> = ({
  title,
  button,
  children,
  sx,
  ...flexProps
}) => {
  const buttonClick = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
      call_to_action_name: button?.text || '',
      call_to_action_type:
        DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.HERO_HEADER,
      target_url: button?.url || '',
    })
  }, [button])

  if (!title && !button) return null

  return (
    <Flex
      sx={{
        position: 'relative',
        width: ['100%', '100%', '50%'],
        height: ['auto', 'auto', '100%'],
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: [4, 4, 0],
        paddingBottom: [4, 4, 0],
        ...sx,
      }}
      {...flexProps}
    >
      {title && (
        <Text
          as="h1"
          sx={{
            color: 'textLight',
            fontSize: [4, 4, '40px'], // 40px hardcoded as is custom value
            fontWeight: 'normal',
            lineHeight: ['heading2', 'heading2', '48px'], // 48px hardcoded as is custom value
            mb: [3, 3, 4],
            '&:last-child': {
              mb: 0,
            },
          }}
        >
          {getTextFieldValue(title)}
        </Text>
      )}

      <SitecoreButton variant="outline" linkField={button} onClick={buttonClick} />

      {children}
    </Flex>
  )
}
