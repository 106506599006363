import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { MAX_ORDER_QUANTITY } from '../../../../constants/partSalesInformationConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import QuantityDecrementButton from './QuantityDecrementButton'
import QuantityIncrementButton from './QuantityIncrementButton'
import QuantityInput from './QuantityInput'

export type QuantitySpinnerVariant = 'small' | 'normal' | 'infinite'

interface AddToBasketQuantitySpinnerProps {
  variant?: QuantitySpinnerVariant
  stepSize: number
  nullable?: boolean
  quantity?: number
  initialQuantity: number
  posting?: boolean
  onChange: (newQuantity: number) => void
  debounceInput?: boolean
  pageType?: string
  disabled?: boolean
  readOnly?: boolean
  maxQuantity?: number
}

const variantSx: Partial<Record<QuantitySpinnerVariant, ThemeUIStyleObject>> = {
  small: {
    display: 'inline-block',
    width: '100px',
  },
  normal: {
    marginBottom: 2,
  },
  infinite: {
    borderRadius: 0,
    border: undefined,
    'button:first-of-type': {
      paddingLeft: 2,
    },
    'button:last-of-type': {
      paddingRight: 2,
    },
  },
}

const dataLayerChangeQuantityEvent = (
  label: keyof typeof DATA_LAYER.CUSTOM_DIMENSION.QUANTITY_CHANGE,
  pageType?: string
) => {
  pushToDataLayer({
    [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CHANGE_QUANTITY,
    method: DATA_LAYER.CUSTOM_DIMENSION.QUANTITY_CHANGE[label],
    ...(pageType ? { page_type: pageType } : {}),
  })
}

export const AddToBasketQuantitySpinner = ({
  variant = 'normal',
  stepSize,
  nullable = false,
  quantity: overwriteQuantity,
  initialQuantity,
  posting = false,
  debounceInput = true,
  pageType,
  onChange,
  disabled = false,
  readOnly = false,
  maxQuantity = MAX_ORDER_QUANTITY,
}: AddToBasketQuantitySpinnerProps) => {
  const [quantity, setQuantity] = useState<number>(
    overwriteQuantity || initialQuantity
  )
  const [debounceTimeout, setDebounceTimeout] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    if (overwriteQuantity) setQuantity(overwriteQuantity)
  }, [overwriteQuantity])

  const debounceChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (newQuantity: number, changeType: any, debounceRate: number) => {
      if (debounceTimeout !== undefined) {
        window.clearTimeout(debounceTimeout)
      }

      const timeout = window.setTimeout(() => {
        onChange(newQuantity)
        dataLayerChangeQuantityEvent(changeType, pageType)
        setDebounceTimeout(undefined)
      }, debounceRate)

      setDebounceTimeout(timeout)
    },
    [onChange, pageType, debounceTimeout]
  )

  const triggerChange = useCallback(
    (newQuantity: number, changeType: string, debounceRate = 500) => {
      setQuantity(newQuantity)

      if (
        (newQuantity === 0 && nullable) ||
        (newQuantity >= stepSize && newQuantity <= maxQuantity)
      ) {
        setQuantity(newQuantity)
        debounceChange(newQuantity, changeType, !debounceInput ? 0 : debounceRate)
      }
    },
    [nullable, stepSize, debounceChange]
  )

  const decrementQuantity = useCallback(() => {
    triggerChange(quantity - stepSize, 'REMOVE')
  }, [quantity, triggerChange, stepSize])

  const incrementQuantity = useCallback(() => {
    triggerChange(quantity + stepSize, 'ADD')
  }, [quantity, triggerChange, stepSize])

  const onQuantityInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newQuantity = Number(event.target.value)

      if (newQuantity <= maxQuantity) {
        triggerChange(newQuantity, 'INPUT')
      }
    },
    [triggerChange, debounceInput]
  )

  const onQuantityInputBlur = useCallback(() => {
    if (quantity < stepSize) {
      triggerChange(stepSize, 'INPUT', 0)
    } else if (quantity % stepSize !== 0) {
      triggerChange(quantity + (stepSize - (quantity % stepSize)), 'INPUT', 0)
    } else {
      triggerChange(quantity, 'INPUT', 0)
    }
  }, [quantity, triggerChange, stepSize])

  useEffect(() => {
    setQuantity(initialQuantity)
  }, [initialQuantity])

  return (
    <Box
      sx={{
        height: '40px',
        borderRadius: '40px',
        border: '1px solid',
        borderColor: 'darkGray',
        position: 'relative',
        display: 'block',
        overflow: 'hidden',
        ...variantSx[variant],
      }}
    >
      <QuantityDecrementButton
        disabled={
          disabled ||
          readOnly ||
          posting ||
          (!nullable && quantity <= stepSize) ||
          (nullable && quantity === 0)
        }
        onClick={decrementQuantity}
      />

      <QuantityInput
        posting={posting}
        value={quantity.toString()}
        onChange={onQuantityInputChange}
        onBlur={onQuantityInputBlur}
        disabled={disabled}
        readOnly={readOnly}
      />

      <QuantityIncrementButton
        disabled={disabled || readOnly || posting || quantity >= maxQuantity}
        onClick={incrementQuantity}
      />
    </Box>
  )
}

export default AddToBasketQuantitySpinner
