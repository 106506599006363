import React, { FC } from 'react'
import { MatchMetaDataProps } from '../../../../types/dafResponseProps'
import { SkeletonComponent } from '../../../../types/layoutProps'
import { LinkField, SliderVariant } from '../../../../types/layoutService'
import PartCardFull from './PartCardFull'
import PartCardMinimal from './PartCardMinimal'

export const PART_CARD_VARIANT_MINIMAL = 'minimal'
export const PART_CARD_VARIANT_FULL = 'full'
export const PART_CARD_VARIANT_DEFAULT = 'full'

export interface PardCardLayoutServiceData {
  partPage: LinkField
}

export interface PartCardProps extends PardCardLayoutServiceData {
  partDescription?: string
  partEnglishDescription?: string
  partNumber: string
  displayPartNumber?: string
  imageUrl?: string
  matchMetaData?: MatchMetaDataProps
  brandImageUrl?: string
  brandName?: string
  categoryId?: string
  subcategoryId?: string
  packageUnitQuantity?: number
  uri?: string
  dataLayerComponentOrigin?: string
  variant?: SliderVariant
  onClick?: () => void
}

const PartCard: FC<PartCardProps> & SkeletonComponent = ({
  variant = PART_CARD_VARIANT_DEFAULT,
  uri,
  partPage,
  ...partCardProps
}) => {
  const partHref = `${partPage?.url}/${uri}`

  const sharedProps = {
    href: partHref,
    ...partCardProps,
  }

  if (variant === PART_CARD_VARIANT_MINIMAL) {
    return <PartCardMinimal {...sharedProps} />
  }

  return <PartCardFull {...sharedProps} />
}

const PartCardSkeleton: FC<{ partNumber: string; variant?: SliderVariant }> = ({
  partNumber,
  variant = PART_CARD_VARIANT_FULL,
}: {
  partNumber: string
  variant?: SliderVariant
}) => {
  if (variant === PART_CARD_VARIANT_MINIMAL) {
    return <PartCardMinimal.Skeleton partNumber={partNumber} />
  }

  return <PartCardFull.Skeleton partNumber={partNumber} />
}

PartCard.Skeleton = PartCardSkeleton

export default PartCard
