import React from 'react'
import { Text } from 'theme-ui'
import TableRow from '../Table/TableRow'
import TableCell from '../Table/TableCell'
import { COLORS } from '../../../constants/themeConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'

interface ServiceDetailMainServiceDealerItemProps {
  icon: string
  value: string | null | undefined
}

const ServiceDetailMainServiceDealerItem = ({
  icon,
  value,
}: ServiceDetailMainServiceDealerItemProps) => (
  <TableRow sx={{ borderBottom: 0 }}>
    <TableCell sx={{ paddingY: [0, 0, 1, 0], paddingRight: 0 }}>
      <IconWrapper icon={icon} color={COLORS.GRAY} />
    </TableCell>
    <TableCell sx={{ paddingY: [0, 0, 1, 0], verticalAlign: 'middle' }}>
      <Text variant="bodySmall" color={COLORS.GRAY}>
        {value && value}
      </Text>
    </TableCell>
  </TableRow>
)

export default ServiceDetailMainServiceDealerItem
