import { useMemo } from 'react'
import { URL_RETURN_URL_PARAM } from '../constants/urlConstants'
import useHasMounted from './useHasMounted'

const useReturnUrl = (paramName: string = URL_RETURN_URL_PARAM) => {
  const hasMounted = useHasMounted()

  return useMemo(() => {
    if (!hasMounted) return undefined

    const params = new URLSearchParams(window.location.search)

    return params.get(paramName) ?? '/'
  }, [hasMounted, paramName])
}

export default useReturnUrl
