import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { mapFiltersToSearchObject } from '../../../../helpers/searchResultsHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import {
  executePartSearch,
  PartSearchData,
  SearchStatistics,
} from '../../../../services/rest/ecommerce/partSearch'
import { ActiveSearchResultFilters } from '../../../../types/filterProps'
import useSitecoreContext from '../../../useSitecoreContext'
import { loggedIn } from '../../../../helpers/userHelper'
import useAxiosClient from '../core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { PartSearchContext } from '../../../../providers/PartSearchContextProvider'

const usePartSearchStatisticsService = (
  searchString?: string,
  activeSearchFilters?: ActiveSearchResultFilters
): [boolean, SearchStatistics | undefined] => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()
  const { isPromotion } = useContext(PartSearchContext)

  const { user } = useContext(UserContext)
  const {
    languageContext: { countryCode, cultureCode },
    xSessionId,
  } = useSitecoreContext()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [fetching, setFetching] = useState(false)
  const [statistics, setStatistics] = useState<SearchStatistics | undefined>(
    undefined
  )

  const searchFilterObject = useMemo(
    () => mapFiltersToSearchObject(activeSearchFilters),
    [activeSearchFilters]
  )

  const getSearchStatistics = useCallback(
    async (cancelToken: CancelToken) => {
      setFetching(true)

      await executePartSearch(
        client,
        searchString || '',
        isPromotion || false,
        cancelToken,
        cultureCode,
        countryCode,
        0, // TODO: remove when statistics end point is available
        1, // TODO: remove when statistics end point is available
        searchFilterObject,
        user,
        xSessionId,
        isImpersonated,
        actingCompanyId,
        actingSupplierId
      )
        .then(({ data }) => {
          if (data) {
            if (data?.statistics) {
              setStatistics(data.statistics)
            }
          }
        })
        .catch((error) => {
          const { code, message } = error as AxiosError<PartSearchData>

          if (!axios.isCancel(error)) {
            appInsights.trackException({
              exception: new Error(
                `Unable to fetch part search results: ${message} (${code})`
              ),
              severityLevel: SeverityLevel.Error,
              properties: {
                searchString,
                cultureCode,
                countryCode,
                pageIndex: 0,
                partsPerPage: 1,
                searchFilterObject,
                actingCompanyId,
                actingSupplierId,
                isImpersonated,
              },
            })
          }
        })
        .finally(() => setFetching(false))
    },

    [
      searchString,
      searchFilterObject,
      appInsights,
      actingCompanyId,
      actingSupplierId,
    ]
  )

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()
    const allDataReceived =
      typeof searchString === 'string' && activeSearchFilters !== undefined
    const userInformationReady =
      !loggedIn(user) || (actingCompanyId && actingSupplierId)

    if (allDataReceived && userInformationReady) getSearchStatistics(token)

    return () => {
      if (allDataReceived && userInformationReady) cancel()
    }
  }, [
    actingCompanyId,
    actingSupplierId,
    activeSearchFilters,
    getSearchStatistics,
    searchString,
  ])

  return [fetching, statistics]
}

export default usePartSearchStatisticsService
