import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getPriceInformation } from '../helpers/priceHelper'
import { getStockInformation } from '../helpers/stockHelper'
import usePartSalesInformationService, {
  PartSalesInformation,
  PriceInformation,
  StockInformation,
} from '../hooks/services/graphql/usePartSalesInformationService'
import { PartListSalesInformationContext } from './PartListSalesInformationProvider'

export interface PartSalesInformationContextProps {
  fetching: boolean
  fetchPartSalesInformation?: (quantity: number) => void
  priceInformation?: PriceInformation
  stockInformation?: StockInformation
}

export const PartSalesInformationContext =
  createContext<PartSalesInformationContextProps>({
    fetching: false,
    fetchPartSalesInformation: undefined,
    priceInformation: undefined,
    stockInformation: undefined,
  })

export interface PartSalesInformationProviderProps {
  partNumber?: string
  children: ReactNode
}

const PartSalesInformationProvider = ({
  children,
  partNumber,
}: PartSalesInformationProviderProps) => {
  const {
    priceInformationList,
    stockInformationList,
    fetching: baseFetch,
  } = useContext(PartListSalesInformationContext)

  const initialPriceInformation = useMemo(
    () => getPriceInformation(partNumber, priceInformationList),
    [partNumber, priceInformationList]
  )
  const initialStockInformation = useMemo(
    () => getStockInformation(partNumber, stockInformationList),
    [partNumber, stockInformationList]
  )

  const [priceInformation, setPriceInformation] = useState<
    PriceInformation | undefined
  >(undefined)
  const [stockInformation, setStockInformation] = useState<
    StockInformation | undefined
  >(undefined)

  const includePrice = useMemo(
    () => typeof initialPriceInformation !== 'undefined',
    [initialPriceInformation]
  )
  const includeStock = useMemo(
    () => typeof initialStockInformation !== 'undefined',
    [initialStockInformation]
  )

  const onResult = useCallback((data: PartSalesInformation) => {
    setPriceInformation(data?.priceInformationList?.[0])
    setStockInformation(data?.stockInformationList?.[0])
  }, [])

  const [fetchPartSalesInformationList, fetching] = usePartSalesInformationService(
    includePrice,
    includeStock,
    onResult
  )

  useEffect(() => {
    setPriceInformation(initialPriceInformation)
  }, [initialPriceInformation])

  useEffect(() => {
    setStockInformation(initialStockInformation)
  }, [initialStockInformation])

  const fetchPartSalesInformation = useCallback(
    (quantity: number) => {
      if (!partNumber) return

      fetchPartSalesInformationList([
        {
          partNumber,
          quantity,
        },
      ])
    },
    [fetchPartSalesInformationList, partNumber]
  )

  return (
    <PartSalesInformationContext.Provider
      value={{
        fetching: baseFetch || fetching,
        fetchPartSalesInformation,
        priceInformation,
        stockInformation,
      }}
    >
      {children}
    </PartSalesInformationContext.Provider>
  )
}

export default PartSalesInformationProvider
