import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isBrowser } from '../../../../helpers/dom'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { fetchDealer } from '../../../../services/rest/dealer/dealerSearch'
import { Dealer } from '../../../../types/dealerServiceTypes'
import useAxiosClient from '../core/useAxiosClient'

const SESSION_STORAGE_ACTIVE_DEALER_INFORMATION_KEY = 'activeDealerInformation'

export const useDealerService = (
  dealerLocationCode?: string
): [Dealer | undefined, boolean] => {
  const client = useAxiosClient(AxiosClientType.DealerApi)
  const appInsights = useAppInsightsContext()

  const persistedDealer = useMemo(() => {
    if (!isBrowser()) return undefined

    if (dealerLocationCode) {
      const sessionStorageDealer = sessionStorage.getItem(
        SESSION_STORAGE_ACTIVE_DEALER_INFORMATION_KEY
      )

      if (sessionStorageDealer) {
        return JSON.parse(decodeURIComponent(atob(sessionStorageDealer))) as Dealer
      }
    }

    return undefined
  }, [dealerLocationCode])

  const [dealer, setDealer] = useState<Dealer | undefined>(persistedDealer)
  const [fetching, setFetching] = useState(false)

  const getDealer = useCallback(
    async (locationCode: string, cancel: CancelToken) => {
      setFetching(true)

      try {
        const { data } = await fetchDealer(client, locationCode, cancel)

        if (data) {
          setDealer(data)

          sessionStorage.setItem(
            SESSION_STORAGE_ACTIVE_DEALER_INFORMATION_KEY,
            btoa(encodeURIComponent(JSON.stringify(data)))
          )
        }
      } catch (error) {
        const err = error as AxiosError<Dealer>

        appInsights.trackException({
          exception: new Error(
            `Unable to fetch dealer: ${err.message} (${err.code})`
          ),
          severityLevel: SeverityLevel.Error,
          properties: {
            dealerLocationCode,
          },
        })
      } finally {
        setFetching(false)
      }
    },

    []
  )

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()

    // Compare the provided dealer location code with the persisted dealer code
    const isPersistedDealer = dealer && dealer.code === dealerLocationCode

    if (!fetching && !isPersistedDealer && dealerLocationCode) {
      getDealer(dealerLocationCode, token)
    }

    return () => {
      if (fetching) {
        cancel()
      }
    }
  }, [dealerLocationCode])

  return [dealer, fetching]
}
