import React, { FC, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { URL_DEALER_CODE_PARAM } from '../../../../constants/urlConstants'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { getUrlQueryValue } from '../../../../helpers/urlHelper'
import { Dealer } from '../../../../types/dealerServiceTypes'
import {
  LinkField,
  StaticDealerFilter,
  TextField,
} from '../../../../types/layoutService'
import DealerLocatorDealerButton from '../../DealerLocator/DealerLocatorDealerButton'
import SidePanelFooter from '../SidePanelFooter'
import { DealerLocatorAction } from '../../DealerLocator/DealerLocator'
import SidePanel from '../SidePanel'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import DealerInformationOverview from './DealerInformationOverview'

interface DealerInformationDealerLocatorSidePanelProps {
  dealers: Dealer[]
  staticDealerFilters: StaticDealerFilter[]
  action: DealerLocatorAction
  requestAccountLink: LinkField
  selectDealerLink: LinkField
  sidePanelTitle: TextField
}

const DealerInformationDealerLocatorSidePanel: FC<
  DealerInformationDealerLocatorSidePanelProps
> = ({
  dealers,
  staticDealerFilters,
  action,
  requestAccountLink,
  selectDealerLink,
  sidePanelTitle,
}) => {
  const history = useHistory()
  const [breakpointM] = useBreakpoints('m')

  const dealerCode = useMemo(
    () =>
      getUrlQueryValue(URL_DEALER_CODE_PARAM, history.location.search) || undefined,
    [history.location.search]
  )
  const dealer = useMemo(
    () => dealers.find((d) => d.code === dealerCode) || {},
    [dealerCode, dealers]
  )

  useEffect(() => {
    if (dealerCode) {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
        action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.CLICK_SHOW_DEALER_DETAILS,
      })
    }
  }, [dealerCode])

  return (
    <SidePanel
      active={!!dealerCode}
      onClose={() => {
        const params = new URLSearchParams(history.location.search)

        params.delete(URL_DEALER_CODE_PARAM)

        history.push(`?${params.toString()}`)
      }}
      title={breakpointM ? getTextFieldValue(sidePanelTitle) : dealer.name || ''}
    >
      <DealerInformationOverview
        dealer={dealer}
        staticDealerFilters={staticDealerFilters}
      />

      {dealer.requestAccountSupported && (
        <SidePanelFooter>
          <DealerLocatorDealerButton
            action={action}
            requestAccountLink={requestAccountLink}
            selectDealerLink={selectDealerLink}
            dealerName={dealer.name || ''}
            dealerCode={dealer.code || ''}
            sx={{ flexGrow: 1 }}
            stretchButton
            dataLayerArguments={{
              [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.REQUEST_ACCOUNT,
              action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.REQUEST_ACCOUNT_POP_IN,
              dealer_name: dealer.name || '',
              page_type: DATA_LAYER.COMPONENT_ORIGIN.DEALER_FINDER,
            }}
          />
        </SidePanelFooter>
      )}
    </SidePanel>
  )
}

export default DealerInformationDealerLocatorSidePanel
