import React, { FC, useCallback, useRef, useState } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { ALIGN, MENU_BORDER_WIDTH } from '../../../constants/menuConstants'
import Animate from '../../atoms/Animate/Animate'

interface MenuProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  open: boolean
  align?: typeof ALIGN.LEFT | typeof ALIGN.RIGHT
}

const Menu: FC<MenuProps> = ({
  open,
  align = ALIGN.LEFT,
  sx,
  children,
  ...additionalProps
}) => {
  const menuRef = useRef<HTMLElement>(null)
  const menuInnerRef = useRef<HTMLElement>(null)
  const [displayScrollBar, setDisplayScrollBar] = useState(false)

  const onAnimationEnd = useCallback(() => {
    if (menuRef?.current && menuInnerRef?.current) {
      const menuHeight = menuRef.current.getBoundingClientRect().height
      const innerMenuHeight =
        menuInnerRef.current.getBoundingClientRect().height + MENU_BORDER_WIDTH * 2

      if (menuHeight < innerMenuHeight) setDisplayScrollBar(true)
    }
  }, [])

  return (
    <Animate
      from={{ maxHeight: 0, borderColor: 'transparent' }}
      enter={{
        maxHeight: '80vh',
        borderColor: 'gray.1',
      }}
      leave={{ maxHeight: 0, borderColor: 'transparent' }}
      active={open}
      onAnimationEnd={onAnimationEnd}
    >
      <Box
        ref={menuRef}
        sx={{
          position: 'absolute',
          ...(align === ALIGN.LEFT ? { left: 0 } : { right: 0 }),
          height: 'auto',
          bg: 'background',
          zIndex: 999,
          paddingRight: displayScrollBar ? 0 : '10px',
          overflowY: displayScrollBar ? 'scroll' : 'hidden',
          overflowX: 'hidden',
          overscrollBehavior: 'contain',
          border: `${MENU_BORDER_WIDTH}px solid`,
          ...sx,
        }}
        {...additionalProps}
      >
        <Box ref={menuInnerRef} py={3}>
          {children}
        </Box>
      </Box>
    </Animate>
  )
}

export default Menu
