import React, { FC } from 'react'
import { SOCIAL_ICONS, SocialIcon } from '../../../constants/iconConstants'
import { SHARE_URI } from '../../../constants/socialShareButtonConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Link from '../../atoms/Link/Link'

interface SocialShareButtonProps {
  variant: SocialIcon
}

const SocialShareButton: FC<SocialShareButtonProps> = ({ variant }) => {
  const { seo } = useSitecoreContext()

  return (
    <Link px={[2, 2, 2]} href={`${SHARE_URI[variant]}${seo?.url}`} target="_BLANK">
      <IconWrapper
        size={4}
        icon={SOCIAL_ICONS?.[variant as SocialIcon]}
        color="primary"
      />
    </Link>
  )
}

export default SocialShareButton
