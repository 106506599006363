import React from 'react'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'
import i18next from 'i18next'
import StatePill from '../../../atoms/StatePill/StatePill'
import { COLORS } from '../../../../constants/themeConstants'
import { Flex } from 'theme-ui'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { VehicleDetailMaintenanceDatasource } from '../../VehicleDetailMaintenance/VehicleDetailMaintenance'
import SidePanelFooter from '../SidePanelFooter'
import Button from '../../../atoms/Button/Button'

interface VehicleRecallTypesSidepanelProps {
  active: boolean
  onClose: () => void
  datasource: VehicleDetailMaintenanceDatasource
}
const VehicleRecallTypesSidePanel = ({
  active,
  onClose,
  datasource,
}: VehicleRecallTypesSidepanelProps) => {
  return (
    <SidePanel
      position="right"
      active={active}
      onClose={onClose}
      title={i18next.t('urgentTasks.vehicleUpgrades')}
    >
      <SidePanelBody>
        <Flex sx={{ gap: 4, flexDirection: 'column' }}>
          <div>
            <StatePill
              state={i18next.t('myFleetFilters.recall')}
              color={COLORS.ALERT}
            />
            <p>{getTextFieldValue(datasource.recallExplanationWarning)}</p>
          </div>
          <div>
            <StatePill
              state={i18next.t('myFleetFilters.recall')}
              color={COLORS.PROMOTION}
            />
            <p>{getTextFieldValue(datasource.recallExplanationImportant)}</p>
          </div>
          <div>
            <StatePill
              state={i18next.t('myFleetFilters.recall')}
              color={COLORS.ORANGE_DARK}
            />
            <p>{getTextFieldValue(datasource.recallExplanationAttention)}</p>
          </div>
        </Flex>
      </SidePanelBody>
      <SidePanelFooter>
        <Button sx={{ width: '100%' }} variant="primary" onClick={onClose}>
          {i18next.t('commonLabels.close')}
        </Button>
      </SidePanelFooter>
    </SidePanel>
  )
}

export default VehicleRecallTypesSidePanel
