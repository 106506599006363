import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'
import { LinkField, TextField } from '../../../types/layoutService'
import { FulfilledBomPart } from './AssemblyBillOfMaterial'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import AssemblyBillOfMaterialTable from './AssemblyBillOfMaterialTable'

interface AssemblyBillOfMaterialPartsProps {
  fulfilledBomParts: FulfilledBomPart[]
  id: string
  includedInTitle: TextField
  assemblyIdentifierLabel: TextField
  addToBasketLabel: TextField
  askQuotationLabel: TextField
  consistsOfTitle: TextField
  partsPageUri: LinkField
}

const AssemblyBillOfMaterialParts: FC<AssemblyBillOfMaterialPartsProps> = ({
  fulfilledBomParts,
  id,
  consistsOfTitle,
  ...tableProps
}) => {
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)

  const table = useMemo(
    () => (
      <AssemblyBillOfMaterialTable
        {...tableProps}
        fulfilledBomParts={fulfilledBomParts}
      />
    ),
    [tableProps, fulfilledBomParts]
  )

  if (!fulfilledBomParts?.length) return null

  if (!breakpointM) {
    return (
      <PartMobileModalLink
        title={getTextFieldValue(consistsOfTitle)}
        table={table}
      />
    )
  }

  return (
    <AnchorMenuTarget
      title={getTextFieldValue(consistsOfTitle)}
      id={id}
      sx={{
        maxWidth: '100%',
        overflow: 'auto',
      }}
      mb={7}
    >
      <Text variant="heading2" as="h3" pb={5}>
        {getTextFieldValue(consistsOfTitle)}
      </Text>

      {table}
    </AnchorMenuTarget>
  )
}

export default AssemblyBillOfMaterialParts
