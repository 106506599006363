import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'theme-ui'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { ICONS } from '../../../../constants/iconConstants'
import {
  VIEW_TYPE_GRID,
  VIEW_TYPE_LINE,
  VIEW_TYPE_TABLE,
} from '../../../../constants/searchConstants'
import { URL_SEARCH_VIEW_TYPE } from '../../../../constants/urlConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { getSearchViewTypeFromUrl } from '../../../../helpers/partContextHelper'
import { TextField } from '../../../../types/layoutService'
import SearchControlsViewTypeSwitcherButton from './SearchControlsViewTypeSwitcherButton'

interface SearchControlsViewTypeSwitcherProps {
  label: TextField
  viewType?: string
  defaultViewType: string
}

export const DEFAULT_VIEW_TYPES = [VIEW_TYPE_LINE, VIEW_TYPE_TABLE]

const SearchControlsViewTypeSwitcher: FC<SearchControlsViewTypeSwitcherProps> = ({
  label,
  viewType,
  defaultViewType,
}) => {
  const history = useHistory()

  useEffect(() => {
    const urlViewType = getSearchViewTypeFromUrl(history.location.search)

    if (viewType && (!urlViewType || viewType !== urlViewType)) {
      // only update url if view type is not present in url or url is not matching persisted view type
      if (!DEFAULT_VIEW_TYPES.includes(viewType)) {
        // no need to update url as we are using the default view type from the category
        const params = new URLSearchParams(history.location.search)

        params.set(URL_SEARCH_VIEW_TYPE, viewType)
        history.push(`?${params.toString()}`)
      }
    }
  }, [history, viewType])

  useEffect(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.INITIAL_VIEW_MODE,
      view_name: defaultViewType,
    })
  }, [defaultViewType])

  useEffect(() => {
    pushToDataLayer({ view_mode: viewType })
  }, [viewType])

  return (
    <Text
      variant="bodySmall"
      sx={{
        color: 'gray.2',
        pr: 1,
        lineHeight: 'buttonText',
        display: 'flex',
        alignItems: 'center',
        ml: 3,
        mr: -2,
      }}
    >
      {getTextFieldValue(label)}

      <SearchControlsViewTypeSwitcherButton
        data-t-id="search-results-view-type-switcher-button-default"
        data-active={viewType === defaultViewType}
        icon={ICONS.GRID_RECTANGLE}
        buttonViewType={defaultViewType}
        active={viewType === defaultViewType}
        ml={1}
      />

      <SearchControlsViewTypeSwitcherButton
        data-t-id="search-results-view-type-switcher-button-grid"
        data-active={viewType === VIEW_TYPE_GRID}
        icon={ICONS.GRID}
        buttonViewType={VIEW_TYPE_GRID}
        active={viewType === VIEW_TYPE_GRID}
      />
    </Text>
  )
}

export default SearchControlsViewTypeSwitcher
