import React from 'react'
import { Box, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { showDashWhenEmptyValue } from '../../../helpers/vehicleHelper'

interface ServicePlansDetailItemProps {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

const ServicePlansDetailItem = ({ label, value }: ServicePlansDetailItemProps) => (
  <>
    <Box sx={{ flexGrow: 1 }}>
      <Text
        variant="bodySmall"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {label}
      </Text>
    </Box>
    <Box>
      <Text variant="bodySmall" color={COLORS.GRAY}>
        {showDashWhenEmptyValue(value)}
      </Text>
    </Box>
  </>
)

export default ServicePlansDetailItem
