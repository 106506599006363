import i18next from 'i18next'
import React, { FC } from 'react'
import { LinkField } from '../../../../types/layoutService'
import CardAction from '../CardAction'
import CardBrandImage from '../CardBrandImage'
import CardExcerptText from '../CardExcerptText'
import CardFrame from '../CardFrame'
import CardImage from '../CardImage'
import CardTitle from '../CardTitle'

export interface AssemblyCardProps {
  partDescription?: string
  partNumber: string | number
  imageUrl?: string
  brandName?: string
  brandImage?: string
  uri?: string
  assemblyPage: LinkField
  onClick?: () => void
}

const AssemblyCard: FC<AssemblyCardProps> = ({
  assemblyPage,
  imageUrl,
  partNumber,
  brandImage,
  brandName,
  partDescription,
  uri,
}) => {
  const assemblyHref = `${assemblyPage?.url}/${uri}`
  const partNumberString = `${i18next.t('partLabels.referenceNr')} ${partNumber}`

  return (
    <CardFrame>
      <CardAction href={assemblyHref}>
        <CardImage src={imageUrl} title={partDescription} />

        <CardTitle title={partDescription} />

        <CardExcerptText>{partNumberString}</CardExcerptText>
      </CardAction>

      <CardBrandImage imageUrl={brandImage} name={brandName} />
    </CardFrame>
  )
}

export default AssemblyCard
