import React, { FC, useContext, useMemo } from 'react'
import {
  SEARCH_TYPE_ASSEMBLIES_SEARCH,
  SEARCH_TYPE_DEALER_SEARCH,
  SEARCH_TYPE_PARTS_SEARCH,
} from '../../../constants/searchConstants'
import { URL_SEARCH_TYPE } from '../../../constants/urlConstants'
import { AssemblySearchContext } from '../../../providers/AssemblySearchContextProvider'
import { DealerPartSearchContext } from '../../../providers/DealerPartSearchContextProvider'
import { PartSearchContext } from '../../../providers/PartSearchContextProvider'
import PartSearchResultContextProvider from '../../../providers/PartSearchResultContextProvider'
import { SearchContext } from '../../../providers/SearchContextProvider'
import TabContext from '../../molecules/Tab/TabContext'
import TabPanel from '../../molecules/Tab/TabPanel'
import { SearchResultsDatasource, SearchResultsProps } from './SearchResults'
import SearchResultsTabBar from './SearchResultsTabBar'
import AssemblySearchTabPanel from './AssemblySearch/AssemblySearchTabPanel'
import DealerPartSearchTabPanel from './DealerPartSearch/DealerPartSearchTabPanel'
import PartSearchTabPanel from './PartSearch/PartSearchTabPanel'

interface SearchResultsTabsProps {
  searchResultsDatasource: SearchResultsDatasource
  searchResultProps: SearchResultsProps
}

const SearchResultsTabs: FC<SearchResultsTabsProps> = ({
  searchResultsDatasource,
  searchResultProps,
}) => {
  const { searchType } = useContext(SearchContext)
  const { fetching: fetchingParts, statistics: partStatistics } =
    useContext(PartSearchContext)
  const { totalCount: totalDealerPartCount } = useContext(DealerPartSearchContext)
  const { fetching: fetchingAssemblies, totalCount: assembliesTotalCount } =
    useContext(AssemblySearchContext)
  const isPromotion = searchResultProps?.params.isPromotion === '1'
  const searchTabMap = useMemo(
    () => ({
      [SEARCH_TYPE_PARTS_SEARCH]: {
        resultCount: partStatistics?.totalCount || 0,
        tabIndex: 0,
      },
      [SEARCH_TYPE_ASSEMBLIES_SEARCH]: {
        resultCount: assembliesTotalCount || 0,
        tabIndex: 1,
      },
      [SEARCH_TYPE_DEALER_SEARCH]: {
        resultCount: totalDealerPartCount || 0,
        tabIndex: 2,
      },
    }),
    [assembliesTotalCount, totalDealerPartCount, partStatistics]
  )

  const defaultActiveTab = useMemo(() => {
    if (searchType && searchTabMap[searchType].resultCount > 0) {
      return searchTabMap[searchType].tabIndex
    }

    if (searchTabMap[SEARCH_TYPE_PARTS_SEARCH].resultCount > 0) {
      return searchTabMap[SEARCH_TYPE_PARTS_SEARCH].tabIndex
    }

    if (searchTabMap[SEARCH_TYPE_ASSEMBLIES_SEARCH].resultCount > 0) {
      return searchTabMap[SEARCH_TYPE_ASSEMBLIES_SEARCH].tabIndex
    }

    return searchTabMap[SEARCH_TYPE_DEALER_SEARCH].tabIndex
  }, [searchTabMap, searchType])

  if (fetchingParts || fetchingAssemblies) return null

  return (
    <TabContext urlQueryParam={URL_SEARCH_TYPE} defaultActiveTab={defaultActiveTab}>
      <SearchResultsTabBar />

      <TabPanel id={SEARCH_TYPE_PARTS_SEARCH}>
        <PartSearchResultContextProvider isPromotion={isPromotion}>
          <PartSearchTabPanel
            partPage={searchResultProps.partPage}
            filtersLabel={searchResultsDatasource.filtersLabel}
            categoriesLabel={searchResultsDatasource.categoriesLabel}
            tableViewAnchorLabel={searchResultsDatasource.tableViewAnchorLabel}
            viewLabel={searchResultsDatasource.viewLabel}
            filterOverlay={searchResultsDatasource.filterOverlay}
            supersessionText={searchResultsDatasource.supersessionText}
            loadingLabel={searchResultsDatasource.loadingLabel}
            noResultsLabel={searchResultsDatasource.noResultsLabel}
            invalidFilterCombinationLabel={
              searchResultsDatasource.invalidFilterCombinationLabel
            }
            removeFiltersLabel={searchResultsDatasource.removeFiltersLabel}
            moreFiltersLabel={searchResultsDatasource.moreFiltersLabel}
          />
        </PartSearchResultContextProvider>
      </TabPanel>

      <TabPanel id={SEARCH_TYPE_ASSEMBLIES_SEARCH}>
        <AssemblySearchTabPanel
          assemblyPage={searchResultProps.assemblyPage}
          viewLabel={searchResultsDatasource.viewLabel}
        />
      </TabPanel>

      <TabPanel id={SEARCH_TYPE_DEALER_SEARCH}>
        <DealerPartSearchTabPanel
          dealerPartPage={searchResultProps.dealerPartPage || { url: '' }}
          viewLabel={searchResultsDatasource.viewLabel}
        />
      </TabPanel>
    </TabContext>
  )
}

export default SearchResultsTabs
