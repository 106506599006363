import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'
import axios, { AxiosResponseHeaders } from 'axios'
import i18next from 'i18next'
import ListBase from '../ListBase/ListBase'
import {
  DafVehicleRmDocumentsResponse,
  VehicleRmDocument,
} from '../../../services/rest/ecommerce/dafVehicleRmDocuments'
import ClickableWrapper from '../ClickableWrapper/ClickableWrapper'
import fetchDafVehicleUrlRmDocument from '../../../services/rest/ecommerce/dafVehicleUrlRmDocument'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { UserContext } from '../../../providers/UserProvider'
import IconWrapper from '../IconWrapper/IconWrapper'
import { FONT_AWESOME_ICONS, ICONS } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'
import {
  getFileExtension,
  getFilenameFromHeaders,
  getFiletypeFromHeaders,
  saveFile,
} from '../../../helpers/fileHelper'
import Spinner from '../Spinner/Spinner'

interface RmDocumentListProps {
  vehicleDocuments?: DafVehicleRmDocumentsResponse
}

const RmDocumentList = ({ vehicleDocuments }: RmDocumentListProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const [waitedOnLoad, setWaited] = useState(false)
  const [counter, setCounter] = useState(0)
  const [clickedDocument, setClickedDocument] = useState<VehicleRmDocument | null>(
    null
  )

  const handleClick = useCallback(
    (document: VehicleRmDocument) => {
      setClickedDocument(document)
    },
    [setClickedDocument]
  )
  useEffect(() => {
    const interval = setInterval(() => {
      if (vehicleDocuments || counter >= 5) setWaited(() => true)
      setCounter((prevCounter) => prevCounter + 1)
    }, 500)

    return () => clearInterval(interval)
  }, [counter, vehicleDocuments])

  useEffect(() => {
    const getDafVehicleUrlRmDocument = async () => {
      const { token } = axios.CancelToken.source()
      if (clickedDocument && clickedDocument.url) {
        return await fetchDafVehicleUrlRmDocument(
          clickedDocument.url,
          client,
          token,
          user
        )
      }
      return undefined
    }

    getDafVehicleUrlRmDocument().then((response) => {
      if (clickedDocument) {
        let fileName = 'multisupport.download'
        let fileType = 'octet/stream'
        if (response && response.status === 200) {
          const headers = response.headers as AxiosResponseHeaders
          fileName = getFilenameFromHeaders(headers, fileName)
          fileType = getFiletypeFromHeaders(headers, fileType)
          const extension = getFileExtension(fileName)
          fileName = `${clickedDocument.name}.${extension}`
          saveFile(response, fileName, fileType)
        }
      }
    })
  }, [clickedDocument, client, user])
  return (
    <ListBase>
      {!waitedOnLoad && (
        <Box
          sx={{
            paddingY: 5,
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      )}
      {waitedOnLoad &&
        vehicleDocuments &&
        vehicleDocuments.documents.map((document, index) => (
          <Box
            key={index.toString()}
            sx={{
              marginTop: 1,
              paddingTop: 1,
              borderBottom: '1px solid',
              borderColor: COLORS.MEDIUM_GRAY,
              fontSize: 1,
            }}
          >
            <Text
              sx={{
                as: 'li',
                listStyle: 'none',
              }}
            >
              <ClickableWrapper
                onClick={() => handleClick(document)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  lineHeight: 1.5,
                  alignItems: 'center',
                  marginTop: 1,
                  marginBottom: 3,
                }}
              >
                <Box sx={{ width: '40px' }}>
                  <IconWrapper
                    icon={FONT_AWESOME_ICONS.FILE_DOCUMENT}
                    size={4}
                    color={COLORS.BLACK}
                  />
                </Box>
                <Text color={COLORS.DAF_BLUE}>{document.name}</Text>
              </ClickableWrapper>
            </Text>
          </Box>
        ))}
      {waitedOnLoad && !vehicleDocuments && (
        <Box
          sx={{
            paddingY: 5,
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconWrapper icon={ICONS.EXCLAMATION_TRIANGLE} size={6} />
          {i18next.t('servicePlan.noDocumentsAvailableLabel')}
        </Box>
      )}
    </ListBase>
  )
}

export default RmDocumentList
