import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { DocumentNode, OperationDefinitionNode } from 'graphql'
import { RequestOptions, Variables } from 'graphql-request'
import { useCallback } from 'react'

export type AppInsightsErrorHandlerFunc<TVariables extends Variables> = (
  requestOptions: RequestOptions<TVariables>,
  error: Error
) => void

const getOperationNames = <T extends Variables>(
  requestOptions: RequestOptions<T>
): string[] => {
  const documentNode = requestOptions.document as DocumentNode

  const operationDefinitionsNodes = documentNode.definitions
    .filter((d) => d.kind === 'OperationDefinition')
    .map((d) => d as OperationDefinitionNode)

  const nameNodes = operationDefinitionsNodes.map((o) => o.name).filter((n) => n)

  const names = nameNodes.map((n) => n?.value ?? 'Unknown')

  return names
}

const useAppInsightsErrorHandler = <
  TVariables extends Variables
>(): AppInsightsErrorHandlerFunc<TVariables> => {
  const appInsights = useAppInsightsContext()

  return useCallback<AppInsightsErrorHandlerFunc<TVariables>>(
    (requestOptions: RequestOptions<TVariables>, error: Error) => {
      const operationName = getOperationNames(requestOptions)[0] ?? 'Unknown'

      appInsights.trackException({
        exception: new Error(
          `GraphQL operation "${operationName}}" failed: ${error}`
        ),
        severityLevel: SeverityLevel.Error,
        properties: {
          ...(requestOptions.variables ?? {}),
          operationName,
        },
      })
    },
    [appInsights]
  )
}

export default useAppInsightsErrorHandler
