import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import { MatchMetaDataProps } from '../../../../types/dafResponseProps'
import CardExcerpt from '../CardExcerpt'
import CardImage from '../CardImage'
import CardTitle from '../CardTitle'
import CardBrandName from '../CardBrandName'
import DealerPartCardSalesInformation from './DealerPartCardSalesInformation'
import CardFrame from '../CardFrame'
import PartLineStockInformation from '../../PartLine/PartLineStockInformation'
import CardAction from '../CardAction'

export interface DealerPartCardFullProps {
  partDescription?: string
  partNumber: string
  matchMetaData?: MatchMetaDataProps
  brandName?: string
  dataLayerComponentOrigin?: string
  href: string
  imageUrl?: string
  onClick?: () => void
}

const DealerPartCardFull: FC<DealerPartCardFullProps> = ({
  partDescription,
  partNumber,
  matchMetaData,
  brandName,
  href,
  imageUrl,
  dataLayerComponentOrigin = '',
  onClick,
}) => (
  <CardFrame>
    <CardAction href={href} onClick={onClick}>
      <CardImage src={imageUrl} title={partDescription} />

      <CardTitle title={partDescription} />

      <CardExcerpt partNumber={partNumber} matchMetaData={matchMetaData} />
    </CardAction>

    <Flex sx={{ flexWrap: 'wrap', alignItems: 'center' }}>
      <CardBrandName name={brandName} />

      <PartLineStockInformation
        partNumber={partNumber}
        componentOrigin={dataLayerComponentOrigin}
        isDealer
      />
    </Flex>

    <DealerPartCardSalesInformation
      partDescription={partDescription}
      partNumber={partNumber}
      brandName={brandName}
      dataLayerComponentOrigin={dataLayerComponentOrigin}
    />
  </CardFrame>
)

export default DealerPartCardFull
