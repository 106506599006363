import { Image } from '../types/dafResponseProps'

const PLACEHOLDER_SOURCE_SUFFIX = '/no-thumb.png'

export const combineImagesSources = (
  images: Image[],
  additionalImages?: Image[]
) => [
  ...((images &&
    images.length &&
    images.sort((a, b) => (a?.priority || 0) - (b?.priority || 0))) ||
    []),
  ...((additionalImages &&
    additionalImages.length &&
    additionalImages.sort((a, b) => (a.priority || 0) - (b.priority || 0))) ||
    []),
]

const isPlaceholderImage = ({ imageUrl, thumbnailImageUrl }: Image): boolean =>
  ((imageUrl?.toLocaleLowerCase().endsWith(PLACEHOLDER_SOURCE_SUFFIX) ?? false) ||
    thumbnailImageUrl?.toLowerCase().endsWith(PLACEHOLDER_SOURCE_SUFFIX)) ??
  false

export const filterPlaceholderImages = (images: Image[]): Image[] => {
  // Performance optimization: directly return when we encounter a single image
  if (images.length === 1) return images

  // Create a collection of all regular images (images that do not pass the isPlaceholderImage test)
  const filteredImages = images.filter((image) => !isPlaceholderImage(image))

  // When our remaining collection is empty, find the first image that does classify as a placeholder and return it (if found)
  if (filteredImages.length === 0) {
    const placeholderImage = images.find(isPlaceholderImage)
    return placeholderImage ? [placeholderImage] : []
  }

  // Return the collection containing only regular images
  return filteredImages
}

export const resolvePrimaryImage = (
  images: Image[],
  additionalImages: Image[]
): Image | undefined => {
  const combinedImages = combineImagesSources(images, additionalImages)
  const filteredImages = filterPlaceholderImages(combinedImages)
  return filteredImages[0]
}
