import { Box, Flex, Text } from 'theme-ui'
import React from 'react'
import { VoucherCodesOverviewDatasource } from './VoucherCodesOverview'
import { Voucher } from '../../../services/rest/ecommerce/loyalty/vouchers'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { formatNumber } from '../../../helpers/numberFormatHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import dayjs from 'dayjs'
import { ICONS } from '../../../constants/iconConstants'
import Button from '../../atoms/Button/Button'
import { addToast } from '../../../actions/toastActions'
import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import { COLORS } from '../../../constants/themeConstants'
import VoucherCodeStatus from '../../molecules/VoucherCode/VoucherCodeStatus'
import VoucherCodeProductName from '../../molecules/VoucherCode/VoucherCodeProductName'
import NoResults from '../../molecules/NoResults/NoResults'

interface VoucherCodesOverviewTableProps {
  vouchers: Voucher[]
  datasource: VoucherCodesOverviewDatasource
}

const VoucherCodesOverviewCard = ({
  vouchers,
  datasource,
}: VoucherCodesOverviewTableProps) => {
  const dispatch = useDispatch()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const writeClipboardText = async (text: string) => {
    await navigator.clipboard.writeText(text)

    dispatch(
      addToast({
        id: text,
        message: i18next.t('commonLabels.copiedToClipboard'),
      })
    )
  }
  return (
    <>
      {vouchers?.length === 0 ? (
        <Box
          sx={{
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: COLORS.MEDIUM_GRAY,
            paddingBlock: 4,
          }}
        >
          <NoResults
            title={getTextFieldValue(datasource?.noResultsTitle)}
            description={getTextFieldValue(datasource?.noResultsDescription)}
          />
        </Box>
      ) : (
        <Box as="ul" sx={{ padding: 0 }}>
          {vouchers?.map((voucher) => (
            <Box
              as="li"
              key={voucher.uniqueCode}
              sx={{
                listStyle: 'none',
                borderBottom: '1px solid',
                borderColor: COLORS.MEDIUM_GRAY,
                paddingBlock: 4,
                '&:first-of-type': {
                  borderTop: '1px solid',
                  borderColor: COLORS.MEDIUM_GRAY,
                },
              }}
            >
              <Flex
                as="article"
                sx={{
                  gap: 1,
                  flexDirection: 'column',
                }}
              >
                <Box as="dl">
                  <Flex sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ flexGrow: 1, marginBlockEnd: 2 }}>
                      <VoucherCodeProductName voucher={voucher} />
                    </Box>
                    {voucher.productDetailUrl && (
                      <Box>
                        <Button
                          as="a"
                          href={voucher.productDetailUrl}
                          target="_blank"
                          icon={ICONS.EXIT}
                          iconSize={4}
                          iconPosition="end"
                          variant="infinite"
                          color="primary"
                          sx={{
                            position: 'relative',
                            zIndex: 2,
                          }}
                        />
                      </Box>
                    )}
                  </Flex>
                  <Flex>
                    <Box as="dt" sx={{ flexGrow: 1 }}>
                      <Text variant="bodySmall" color="gray.3">
                        {getTextFieldValue(datasource.points)}
                      </Text>
                    </Box>
                    <Box as="dd">
                      <Text variant="bodySmall" color="gray.3">
                        {formatNumber(voucher?.costPriceInPoints, cultureCode)}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box as="dt" sx={{ flexGrow: 1 }}>
                      <Text variant="bodySmall" color="gray.3">
                        {getTextFieldValue(datasource.issued)}
                      </Text>
                    </Box>
                    <Box as="dd">
                      <Text variant="bodySmall" color="gray.3">
                        {dayjs(voucher.issuedDate).format('DD-MM-YYYY')}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box as="dt" sx={{ flexGrow: 1 }}>
                      <Text variant="bodySmall" color="gray.3">
                        {getTextFieldValue(datasource.expires)}
                      </Text>
                    </Box>
                    <Box as="dd">
                      <Text variant="bodySmall" color="gray.3">
                        {dayjs(voucher.expiresDate).format('DD-MM-YYYY')}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box as="dt" sx={{ flexGrow: 1 }}>
                      <Text variant="bodySmall" color="gray.3">
                        {getTextFieldValue(datasource.status)}
                      </Text>
                    </Box>
                    <Box as="dd">
                      <VoucherCodeStatus voucher={voucher} />
                    </Box>
                  </Flex>
                  <Flex sx={{ marginBlockStart: 3 }}>
                    <Box as="dt" sx={{ flexGrow: 1 }}>
                      <Text as="strong" color={COLORS.DARK} sx={{ fontSize: 1 }}>
                        {getTextFieldValue(datasource.code)}
                      </Text>
                    </Box>
                    <Box as="dd">
                      <Button
                        onClick={() => writeClipboardText(voucher.uniqueCode)}
                        color="primary"
                        variant="infinite"
                        iconPosition="end"
                        icon={ICONS.COPY}
                        disabled={voucher.status !== 'Active'}
                      >
                        {voucher.uniqueCode}
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

export default VoucherCodesOverviewCard
