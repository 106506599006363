import React from 'react'
import { COLORS } from '../../../../constants/themeConstants'
import { Box, Flex, Text } from 'theme-ui'
import { getCurrencySymbol } from '../../../../helpers/priceHelper'
import i18next from 'i18next'
import {
  PART_CARD_VARIANT_FULL,
  PART_CARD_VARIANT_MINIMAL,
} from '../PartCard/PartCard'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'

interface Props {
  variant?: typeof PART_CARD_VARIANT_MINIMAL | typeof PART_CARD_VARIANT_FULL
  creditValue?: {
    currencyCode?: string
    value?: number
  }
}

const RewardCardCreditVoucherCard = ({ variant, creditValue }: Props) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  return (
    <Flex
      padding={variant === PART_CARD_VARIANT_MINIMAL ? 2 : 3}
      sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBlockEnd: 2,
        borderRadius: 'large',
        backgroundColor: COLORS.CREDIT_DARK_BLUE,
        color: COLORS.WHITE,
        aspectRatio: '16 / 9',
        textAlign: 'center',
      }}
    >
      <Box sx={{ marginBlock: 'auto' }}>
        <Text
          as="div"
          sx={{
            fontSize: variant === PART_CARD_VARIANT_MINIMAL ? '2.5rem' : 6,
            fontWeight: '700',
            fontStyle: 'italic',
          }}
        >
          {cultureCode && getCurrencySymbol(cultureCode, creditValue?.currencyCode)}
          {creditValue?.value}
        </Text>
        <Text
          as="div"
          sx={{
            fontWeight: 300,
          }}
        >
          {i18next.t('shoppingLabels.customerLoyalty.credit')}
        </Text>
      </Box>

      <Text
        as="div"
        opacity={0.6}
        sx={{
          fontSize: 0,
        }}
      >
        {i18next.t('shoppingLabels.customerLoyalty.dafMerchandiseStore')}
      </Text>
    </Flex>
  )
}

export default RewardCardCreditVoucherCard
