import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { Box } from 'theme-ui'
import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import { USER_ROLES } from '../../../constants/userConstants'
import { hasRole } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import AddToBasketQuantitySpinner from './AddToBasketQuantitySpinner/AddToBasketQuantitySpinner'
import AddToBasketConfirmButton from './AddToBasketConfirmButton'
import { TextField } from '../../../types/layoutService'
import { registerModalNotification } from '../../../actions/notificationActions'
import { tooltipRegister } from '../../../actions/tooltipActions'
import { ICONS } from '../../../constants/iconConstants'
import { TOOLTIP_BASKET } from '../../../constants/tooltipConstants'
import { MenuProviderContext } from '../../../providers/MenuProvider'
import { getBasketOpenOrderItem } from '../../../helpers/menuHelper'
import { CommerceMessage, CommerceOrderItem } from '../../../types/commerceApi'
import { refetchShoppingBasketSidepanelSummary } from '../../../actions/shoppingBasket/sidePanelActions'
import { ActiveUserStateContext } from '../../../providers/ActiveUserStateProvider'
import useCommercePostOrderItemService from '../../../hooks/services/usePostOrderItemService'
import useCommerceApiResponse from '../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'
import { addOrReplaceOrderItem } from '../../../actions/shoppingBasket/wizard'

interface AddToBasketProps {
  partNumber?: string
  variant?: 'small'
  stepSize?: number
  initialQuantity?: number
  orderLabel?: TextField
  askQuotationLabel?: TextField
  onOrder?: (quantity: number) => void
}

export const DEFAULT_STEP_SIZE = 1

const AddToBasket: FC<AddToBasketProps> = ({
  partNumber,
  variant,
  stepSize = DEFAULT_STEP_SIZE,
  initialQuantity = DEFAULT_STEP_SIZE,
  orderLabel,
  askQuotationLabel,
  onOrder,
}) => {
  const { user } = useContext(UserContext)
  const dispatch = useDispatch()
  const { menu } = useContext(MenuProviderContext)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { orderNumber } = useContext(ActiveUserStateContext)
  const { fetchPartSalesInformation } = useContext(PartSalesInformationContext)

  const openOrder = useMemo(() => getBasketOpenOrderItem(menu), [menu])

  const onResult = useCallback(
    (orderItem: CommerceOrderItem | null) => {
      if (!orderItem) return

      dispatch(
        tooltipRegister({
          name: TOOLTIP_BASKET,
          data: {
            icon: ICONS.CHECK,
            label: i18next.t('shoppingLabels.tooltips.partOrderConfirmation', {
              partName: orderItem.englishDescription,
              partNumber: orderItem.partNumber,
            }),
            actionLabel: i18next.t('shoppingLabels.tooltips.goToShoppingBasket'),
            actionLink: openOrder?.url,
          },
        })
      )

      dispatch(addOrReplaceOrderItem(orderItem))
      dispatch(refetchShoppingBasketSidepanelSummary(true))
    },
    [dispatch, openOrder]
  )

  const onMessage = useCallback(
    (commerceMessage: CommerceMessage) => {
      if (
        commerceMessage.message &&
        (commerceMessage.severity === 'Error' || commerceMessage.code === 'noPrice')
      ) {
        dispatch(registerModalNotification('Error', commerceMessage.message))
      }
    },
    [dispatch]
  )

  const [posting, response, postToShoppingBasket] = useCommercePostOrderItemService()

  const [quantity, setQuantity] = useState<number>(stepSize)

  const orderButtonHandler = useCallback(async () => {
    if (posting || !partNumber) return

    await postToShoppingBasket({ orderNumber, items: [{ partNumber, quantity }] })

    if (onOrder) onOrder(quantity)
  }, [posting, partNumber, postToShoppingBasket, orderNumber, quantity, onOrder])

  const onChange = useCallback(
    (newQuantity: number) => {
      setQuantity((currentQuantity) => {
        if (fetchPartSalesInformation && currentQuantity !== newQuantity) {
          fetchPartSalesInformation(newQuantity)
        }

        return newQuantity
      })
    },
    [fetchPartSalesInformation]
  )

  useCommerceApiResponse<CommerceOrderItem>({
    response,
    onResult,
    onMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => (results as CommerceOrderItem[])?.[0],
    onStatus: {
      '*': (data) => {
        if (data?.message && data.severity !== 'Success')
          dispatch(registerModalNotification(data.severity || 'Error', data.message))
      },
    },
  })

  if (!partNumber) return null

  if (!hasRole(user, USER_ROLES.commerceShoppingBasket) && !isImpersonated)
    return null

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: variant === 'small' ? 'row' : 'column',
      }}
    >
      <AddToBasketQuantitySpinner
        variant={variant}
        stepSize={stepSize}
        initialQuantity={initialQuantity}
        onChange={onChange}
      />

      <AddToBasketConfirmButton
        data-t-id={`part-add-to-basket-${partNumber}`}
        confirmButtonVariant={variant}
        variant="primary"
        disabled={quantity < 1}
        onClick={orderButtonHandler}
        orderLabel={orderLabel}
        askQuotationLabel={askQuotationLabel}
        posting={posting}
      />
    </Box>
  )
}

export default AddToBasket
