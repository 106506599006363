import React from 'react'
import { Box, Text } from 'theme-ui'
import { ICONS } from '../../../../constants/iconConstants'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'

interface MyFleetListFilterGroupHeadingProps {
  selectionCount: number
  active: boolean
  disabled?: boolean
  onClick: () => void
  children: React.ReactNode
}

const MyFleetListFilterGroupHeading = ({
  selectionCount,
  active,
  onClick,
  children,
  disabled,
}: MyFleetListFilterGroupHeadingProps) => (
  <Box
    sx={{
      button: {
        border: 'none',
        background: 'none',
        padding: 0,
        color: 'inherit',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        ':hover, :active, :focus': {
          color: !disabled ? 'primary' : undefined,
        },
        cursor: disabled ? 'wait' : undefined,
        opacity: disabled ? 0.5 : undefined,
      },
    }}
  >
    <button
      type="button"
      aria-expanded={active}
      onClick={() => !disabled && onClick()}
      disabled={disabled}
    >
      <Text
        variant="heading4"
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {children}

        <Text
          variant="buttonText"
          sx={{
            display: 'flex',
            paddingLeft: 3,
            alignItems: 'center',
            fontSize: 1,
            textAlign: 'right',
          }}
        >
          <Box mr={2} color="primary">
            {selectionCount >= 1 && selectionCount}
          </Box>

          <IconWrapper
            icon={ICONS.ARROW_DOWN}
            size={4}
            style={{
              transform: `rotate(${active ? 180 : 0}deg)`,
            }}
          />
        </Text>
      </Text>
    </button>
  </Box>
)

export default MyFleetListFilterGroupHeading
