import React, { FC } from 'react'
import { FooterItem } from './Footer'
import FooterColumn from './FooterColumn'
import FooterNavigationLink from './FooterNavigationLink'

interface FooterNavigationColumnProps {
  navigationItem: FooterItem
}

const FooterNavigationColumn: FC<FooterNavigationColumnProps> = ({
  navigationItem,
}) => (
  <FooterColumn title={navigationItem.title}>
    <nav>
      {!!navigationItem?.children?.length &&
        navigationItem.children.map((link, i) => (
          <FooterNavigationLink link={link} key={i.toString()} />
        ))}
    </nav>
  </FooterColumn>
)

export default FooterNavigationColumn
