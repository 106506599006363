import { useTheme } from '@emotion/react'
import React, {
  Children,
  cloneElement,
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, BoxProps } from 'theme-ui'
import { navigateClient } from '../../../helpers/dom'

interface ClientXY {
  clientX: number
  clientY: number
}

export interface ActionContainerProps extends Omit<BoxProps, 'css'> {
  href?: string
  onClick?: () => void
}

const disallowedElements = ['input', 'button', '[data-stop-propagation]']

const allowTargetClick = (target: HTMLElement) =>
  !disallowedElements
    .map((disallowedElement) => !!target.closest(disallowedElement))
    .includes(true)

const clientDragged = (clientStart?: ClientXY, clientEnd?: ClientXY) =>
  clientStart &&
  clientEnd &&
  (clientStart.clientX !== clientEnd.clientX ||
    clientStart.clientY !== clientEnd.clientY)

const ActionContainer: FC<ActionContainerProps> = ({
  href,
  as = 'div',
  onClick,
  children,
  sx,
  ...props
}) => {
  const { transitions } = useTheme()
  const ref = useRef<HTMLElement>(null)

  const [clickStartPosition, setClickStartPosition] = useState<ClientXY | undefined>(
    undefined
  )
  const [clickEndPosition, setClickEndPosition] = useState<ClientXY | undefined>(
    undefined
  )

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault()

      if (!clientDragged(clickStartPosition, clickEndPosition)) {
        if (as !== 'button') {
          const target = event.target as HTMLElement

          if (allowTargetClick(target)) {
            const newLocation = target.closest('a')?.getAttribute('href')

            if ((href && newLocation === href) || (!href && !newLocation)) {
              // make sure we are clicking on wrapper element
              if (onClick) onClick()
            }

            if (newLocation) navigateClient(newLocation, event)
          }
        } else if (onClick) onClick()
      }
    },
    [as, clickEndPosition, clickStartPosition, href, onClick]
  )

  const onMouseDown = useCallback(
    ({ clientX, clientY }: MouseEvent<HTMLElement>) => {
      setClickStartPosition({ clientX, clientY })
    },
    []
  )

  const onMouseUp = useCallback(({ clientX, clientY }: MouseEvent<HTMLElement>) => {
    setClickEndPosition({ clientX, clientY })
  }, [])

  const childProps = useMemo(
    () => ({
      ref,
      as: href ? 'a' : as,
      href,
      onClick: handleClick,
      onMouseDown,
      onMouseUp,
      draggable: false,
      sx: {
        cursor: 'pointer',
        transition: 'ease background-color',
        transitionDuration: transitions[1],
        textDecoration: 'none',
        ':hover': {
          backgroundColor: '#fbfbfb',
        },
        ':select, :active, :focus': {
          backgroundColor: '#f5f5f5',
        },
        ...sx,
      },
      ...props,
    }),
    [as, handleClick, href, onMouseDown, onMouseUp, props, sx, transitions]
  )

  if (!href && !onClick) return <>{children}</>

  if (typeof children === 'function') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return children(childProps)
  }

  if (Children.count(children) > 1) return <Box {...childProps}>{children}</Box>

  return cloneElement(Children.only(children) as ReactElement, childProps)
}

export default ActionContainer
