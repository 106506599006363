import { AxiosResponse } from 'axios'
import { useEffect } from 'react'
import { CommerceMessage, CommerceResponse } from '../../../../types/commerceApi'

interface CommerceApiResponseProps<ResponseType> {
  response?: AxiosResponse<CommerceResponse<ResponseType[] | ResponseType>> | null
  messageSelector: (messages: CommerceMessage[]) => CommerceMessage
  resultSelector: (results: ResponseType[] | ResponseType) => ResponseType
  onMessage: (commerceMessage: CommerceMessage) => void
  onResult: (
    commerceResponse: ResponseType | null,
    message?: CommerceMessage
  ) => void
  onStatus?: Partial<
    Record<
      '*' | string,
      (
        data: CommerceResponse<ResponseType[] | ResponseType> | undefined,
        status: string
      ) => void
    >
  >
}

const useCommerceApiResponse = <ResponseType,>({
  response,
  onMessage,
  onResult,
  messageSelector,
  resultSelector,
  onStatus,
}: CommerceApiResponseProps<ResponseType>) => {
  useEffect(() => {
    if (!response) return

    if (response?.data) {
      const responseData = response.data

      const message = responseData?.messages
        ? messageSelector(responseData.messages)
        : undefined
      const result =
        typeof responseData?.result !== 'undefined'
          ? resultSelector(responseData.result)
          : undefined

      if (message !== undefined) onMessage(message)
      if (result !== undefined) onResult(result, message)
    }

    if (onStatus) {
      Object.keys(onStatus).forEach((statusCode) => {
        if (statusCode === '*' || response.data.code === statusCode) {
          return onStatus[statusCode]?.(response.data, response.data.code || '')
        }

        return undefined
      })
    }
  }, [response])
}

export default useCommerceApiResponse
