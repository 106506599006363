import React, { FC } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { LinkField, TextField } from '../../../types/layoutService'
import Image from '../../atoms/Image/Image'
import SitecoreLink from '../SitecoreLink/SitecoreLink'
import { Image as ImageType } from '../../../types/dafResponseProps'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { SkeletonComponent } from '../../../types/layoutProps'
import ContentCardSkeleton from './ContentCardSkeleton'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

interface ContentCardProps {
  description: TextField
  link: LinkField
  image: ImageType
}

const ContentCard: FC<ContentCardProps> & SkeletonComponent = ({
  image,
  description,
  link,
}) => {
  const { pageEditing } = useSitecoreContext()

  return (
    <SitecoreLink
      linkField={link}
      sx={{ display: 'block', userDrag: 'none', userSelect: 'none' }}
      onClick={() =>
        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
          call_to_action_name: link.text,
          call_to_action_type:
            DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
          target_url: link.url,
        })
      }
    >
      <Flex
        pr={[2, 2, 4]}
        sx={{
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            paddingTop: '56.25%', // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
            position: 'relative',
            mb: [2, 2, 3],
            ...(pageEditing && !image?.imageUrl
              ? { backgroundColor: 'black', opacity: 0.16 }
              : {}),
          }}
        >
          {image?.imageUrl && (
            <Box
              as="picture"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
            >
              <Image
                {...image}
                src={image.imageUrl}
                alt={getTextFieldValue(description)}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%,-50%)',
                  userDrag: 'none',
                  userSelect: 'none',
                }}
              />
            </Box>
          )}
        </Box>

        <Text as="h3" variant="heading3" color="text">
          {getTextFieldValue(description)}
        </Text>
      </Flex>
    </SitecoreLink>
  )
}

ContentCard.Skeleton = ContentCardSkeleton

export default ContentCard
