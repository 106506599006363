import React, { ReactNode, useCallback, useEffect } from 'react'
import ModalHeader from './ModalHeader'
import Overlay from '../../molecules/Overlay/Overlay'
import ModalContainer from './ModalContainer'
import ModalContent from './ModalContent'
import ModalBody from './ModalBody'
import ThemeProvider, { SubThemeName } from '../../../providers/ThemeProvider'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

export type ModalVariant = 'minimal' | 'default' | 'fullscreen'

export interface ModalProps {
  isActive: boolean
  disableOutsideClick?: boolean
  onClose: () => void
  theme?: SubThemeName
  title?: string
  variant?: ModalVariant
  children?: ReactNode
}

const Modal = ({
  isActive,
  disableOutsideClick = false,
  theme = 'default',
  variant = 'default',
  title,
  onClose,
  children,
}: ModalProps) => {
  const {
    site: { theme: siteTheme },
  } = useSitecoreContext()

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (disableOutsideClick) {
      return undefined
    }

    document.addEventListener('keydown', escFunction)
    return () => {
      document.removeEventListener('keydown', escFunction)
    }
  }, [escFunction, disableOutsideClick])

  return (
    <ThemeProvider themeName={siteTheme.name} subThemeName={theme}>
      <Overlay
        isActive={isActive}
        hideScrollTrack
        blurBackground
        sx={{ zIndex: [9999999992, 9999999992, 4] }}
      >
        <ModalContainer>
          <ModalContent
            variant={variant}
            onClose={onClose}
            disableOutsideClick={disableOutsideClick}
          >
            <ModalHeader
              variant={variant}
              theme={theme}
              onClose={onClose}
              title={title}
            />

            <ModalBody theme={theme}>{children}</ModalBody>
          </ModalContent>
        </ModalContainer>
      </Overlay>
    </ThemeProvider>
  )
}

export default Modal
