import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  CATEGORY_FILTER_ID,
  DEFAULT_PAGE_INDEX,
} from '../../../../constants/searchConstants'
import { mapFiltersToSearchObject } from '../../../../helpers/searchResultsHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import {
  executePartSearch,
  PartSearchData,
  SearchPart,
} from '../../../../services/rest/ecommerce/partSearch'
import {
  ActiveSearchResultFilters,
  FilterGroup,
} from '../../../../types/filterProps'
import useSitecoreContext from '../../../useSitecoreContext'
import { loggedIn } from '../../../../helpers/userHelper'
import {
  filterItemsFromFilterService,
  getFilterFromFilterService,
  orderFiltersByPriority,
} from '../../../../helpers/filterHelper'
import { MAX_ITEMS } from '../../../../constants/searchCategoryListConstants'
import useHasMounted from '../../../useHasMounted'
import useAxiosClient from '../core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import { PartSearchContext } from '../../../../providers/PartSearchContextProvider'

const usePartSearchService = (
  searchString?: string,
  activeSearchFilters?: ActiveSearchResultFilters,
  pageIndex: number = DEFAULT_PAGE_INDEX,
  partsPerPage?: number
): [
  boolean,
  SearchPart[] | undefined,
  FilterGroup[] | undefined,
  string[] | undefined,
  number
] => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { user } = useContext(UserContext)
  const {
    languageContext: { countryCode, cultureCode },
    xSessionId,
  } = useSitecoreContext()
  const { isPromotion } = useContext(PartSearchContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [fetching, setFetching] = useState(false)
  const [searchParts, setSearchParts] = useState<SearchPart[] | undefined>(undefined)
  const [partFilters, setPartFilters] = useState<FilterGroup[] | undefined>(
    undefined
  )
  const [partCategories, setPartCategories] = useState<string[] | undefined>(
    undefined
  )
  const [totalCount, setTotalCount] = useState<number>(0)

  const hasMounted = useHasMounted()

  const searchFilterObject = useMemo(
    () => mapFiltersToSearchObject(activeSearchFilters),
    [activeSearchFilters]
  )

  const getSearchResults = useCallback(
    async (cancelToken: CancelToken) => {
      setFetching(true)

      await executePartSearch(
        client,
        searchString || '',
        isPromotion || false,
        cancelToken,
        cultureCode,
        countryCode,
        pageIndex,
        partsPerPage,
        searchFilterObject,
        user,
        xSessionId,
        isImpersonated,
        actingCompanyId,
        actingSupplierId
      )
        .then(({ data }) => {
          if (hasMounted && data) {
            if (data?.parts) setSearchParts(data?.parts)

            if (data?.filters) {
              setPartFilters(data?.filters)

              setPartFilters(
                orderFiltersByPriority(
                  filterItemsFromFilterService([CATEGORY_FILTER_ID], data.filters)
                )
              )

              setPartCategories(
                getFilterFromFilterService(CATEGORY_FILTER_ID, data.filters)
                  ?.filterOptions?.map(({ value }) => value)
                  ?.slice(0, MAX_ITEMS) || []
              )
            }

            if (typeof data?.statistics?.totalCount === 'number') {
              setTotalCount(data.statistics.totalCount)
            }
          }
        })
        .catch((error) => {
          const { code, message } = error as AxiosError<PartSearchData>

          if (!axios.isCancel(error)) {
            if (hasMounted) setSearchParts([]) // searchResults with empty array as call failed

            appInsights.trackException({
              exception: new Error(
                `Unable to fetch part search results: ${message} (${code})`
              ),
              severityLevel: SeverityLevel.Error,
              properties: {
                searchString,
                cultureCode,
                countryCode,
                pageIndex,
                partsPerPage,
                searchFilterObject,
                actingCompanyId,
                actingSupplierId,
                isImpersonated,
              },
            })
          }
        })
        .finally(() => {
          if (hasMounted) setFetching(false)
        })
    },

    [
      hasMounted,
      searchString,
      pageIndex,
      partsPerPage,
      searchFilterObject,
      actingCompanyId,
      actingSupplierId,
      appInsights,
    ]
  )

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()
    const allDataReceived =
      typeof searchString === 'string' &&
      activeSearchFilters !== undefined &&
      partsPerPage !== undefined
    const userInformationReady =
      !loggedIn(user) || (actingCompanyId && actingSupplierId)

    if (allDataReceived && userInformationReady) getSearchResults(token)

    return () => {
      if (allDataReceived && userInformationReady) cancel()
    }
  }, [
    actingCompanyId,
    actingSupplierId,
    activeSearchFilters,
    getSearchResults,
    pageIndex,
    partsPerPage,
    searchString,
  ])

  return [fetching, searchParts, partFilters, partCategories, totalCount]
}

export default usePartSearchService
