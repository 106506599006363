import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Flex } from 'theme-ui'
import { closeMobileNavigation } from '../../../actions/navigationActions'
import { ICONS } from '../../../constants/iconConstants'
import { ICON_SIZE } from '../../../constants/partsNavigationConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import MainNavigationLogo from '../MainNavigation/MainNavigationLogo'

interface PartsNavigationHeaderProps {
  onClose?: () => void
}

const PartsNavigationHeader: FC<PartsNavigationHeaderProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { lineHeights, mainNavigation } = useTheme()
  const { site } = useSitecoreContext()

  return (
    <Flex
      as="header"
      sx={{
        padding: [2, 2, 2, 6],
        px: [3, 3, 3, 6],
        height: ['56px', '56px', '56px', '104px'],
        alignItems: 'center',
        backgroundColor: mainNavigation.background,
      }}
    >
      {site?.theme?.logoImage && (
        <MainNavigationLogo logoImage={site.theme.logoImage} />
      )}

      <ClickableWrapper
        onClick={() => {
          dispatch(closeMobileNavigation())

          if (onClose) onClose()
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          outline: 'none',
          height: lineHeights.heading2,
          color: mainNavigation.textColor,
          marginLeft: 'auto',
          marginRight: [-1, -1, -1, 0],
          ':hover, :active, :focus': {
            color: mainNavigation.textActiveColor,
          },
        }}
      >
        <IconWrapper size={ICON_SIZE} icon={ICONS.CROSS} />
      </ClickableWrapper>
    </Flex>
  )
}

export default PartsNavigationHeader
