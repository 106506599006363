import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import { BannerVariant, Flag } from '../../../types/layoutService'
import Container from '../Container/Container'

export interface BannerTextProps {
  title?: string
  subtitle?: string
  variant: BannerVariant
  hasNegativeOffset?: Flag
}

const DESKTOP_BOTTOM_SPACE: Record<BannerVariant, number> = {
  Small: 5,
  Medium: 7,
  Large: 9,
}

const BannerText: FC<BannerTextProps> = ({
  title,
  subtitle,
  variant,
  hasNegativeOffset,
}) => {
  if (!title && !subtitle) return null

  return (
    <Flex
      sx={{
        position: ['relative', 'relative', 'relative', 'absolute'],
        height: ['auto', 'auto', 'auto', '100%'],
        width: '100%',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        paddingTop: [4, 4, 4, 0],
        paddingBottom:
          hasNegativeOffset !== '1'
            ? [4, 4, 4, DESKTOP_BOTTOM_SPACE[variant]]
            : [4, 4, 4, '240px'],
        flexDirection: 'column',
        justifyContent: 'flex-end',
        zIndex: 4,
      }}
    >
      <Container>
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          {title && (
            <Text
              variant="heading1"
              sx={{
                color: 'textLight',
                paddingBottom: [2, 2, 2, 3],
                fontSize: [4, 4, 4, 7],
                lineHeight: 'heading',
                maxWidth: ['100%', '100%', '100%', '50%'],
              }}
            >
              {title}
            </Text>
          )}

          {subtitle && (
            <Text
              variant="heading3"
              sx={{
                color: 'textLight',
                fontWeight: 'light',
                paddingBottom: [3, 3, 3, 3],
                fontSize: [3, 3, 3, 5],
                maxWidth: ['100%', '100%', '100%', '50%'],
              }}
            >
              {subtitle}
            </Text>
          )}
        </Flex>
      </Container>
    </Flex>
  )
}

export default BannerText
