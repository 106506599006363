import { Box, Flex, Label, Text } from 'theme-ui'
import React from 'react'
import { Field } from 'react-final-form'
import { COLORS } from '../../../constants/themeConstants'
import Radio from '../inputs/Radio/Radio'

interface TiledRadioSetProps {
  name: string
  label: string
  ariaLabelledby?: string
  items:
    | {
        title: string
        description: string
        key: string
      }[]
    | undefined
}

const TiledRadioSet = ({
  name,
  label,
  ariaLabelledby,
  items,
}: TiledRadioSetProps) => (
  <>
    <Box
      as="fieldset"
      sx={{
        border: 'none',
        padding: 0,
      }}
      aria-labelledby={ariaLabelledby}
    >
      {!ariaLabelledby && label && (
        <Text as="legend" variant="label">
          {label}
        </Text>
      )}

      <Box
        as="ul"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          listStyle: 'none',
          gap: 2,
        }}
      >
        {items?.map((item) => (
          <li key={item.key}>
            <Label
              htmlFor={`${name}-${item.key}`}
              sx={{
                display: 'block',
                border: '1px solid',
                borderColor: COLORS.MEDIUM_GRAY,
                borderRadius: 4,
                padding: 3,
              }}
            >
              <Flex>
                <Box sx={{ flexGrow: 1 }}>
                  <Text as="div" variant="heading4">
                    {item.title}
                  </Text>
                  <Text as="div" variant="smallText">
                    {item.description}
                  </Text>
                </Box>
                <Box sx={{ flexShrink: 0 }}>
                  <Field type="radio" name={name} value={item.key}>
                    {({ input }) => <Radio {...input} id={`${name}-${item.key}`} />}
                  </Field>
                </Box>
              </Flex>
            </Label>
          </li>
        ))}
      </Box>
    </Box>
  </>
)

export default TiledRadioSet
