import { Action } from 'redux'
import { SHOPPING_BASKET_WIZARD_REMOVE_ORDER_ITEMS } from '../../../constants/shoppingBasketConstants'

export interface RemoveOrderItems
  extends Action<typeof SHOPPING_BASKET_WIZARD_REMOVE_ORDER_ITEMS> {
  payload: string[]
}

export const removeOrderItems = (payload: string[]) => ({
  type: SHOPPING_BASKET_WIZARD_REMOVE_ORDER_ITEMS,
  payload,
})
