import i18next, { TOptions } from 'i18next'

export const i18nextZeroPluralization = (
  key: string,
  count: number,
  options?: TOptions
) => {
  if (count === 0) {
    return i18next.t(`${key}_zero`, options)
  }

  if (count > 1) {
    return i18next.t(`${key}_plural`, options)
  }

  return i18next.t(key, { ...options, count })
}
