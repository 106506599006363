import React, { FC, useEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { useHistory } from 'react-router-dom'
import { COLORS } from '../../../constants/themeConstants'
import MultisupportUrgentTask from '../MultisupportUrgentTask/MultisupportUrgentTask'
import {
  DafCompanyVehicles,
  DafCompanyVehiclesData,
  Vehicle,
} from '../../../services/rest/ecommerce/dafCompanyVehicles'
import {
  FILTER_GROUP_FINANCE,
  FILTER_GROUP_MAINTENANCE,
  FILTER_GROUP_SERVICE_PLANS,
  FILTER_GROUP_VEHICLE_UPGRADES,
  MILEAGE_INPUT_STATE_OPEN,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
  MY_FLEET_LIST_URL,
  RECALL_TYPE_PSFA,
  RECALL_TYPE_SR,
  SERVICEPLAN_CONTRACT_STATE_CANCELED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRED,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
  VEHICLE_UPGRADE_IMPORTANT,
  VEHICLE_UPGRADE_WARNING,
} from '../../../constants/dafCompanyVehicleConstants'
import {
  getExistingFilterValues,
  getFilterStringByActiveSearchResultFilterObject,
} from '../../../helpers/filterHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { COMMERCE_SITE_CONTEXT } from '../../../constants/environmentConstants'

interface MultisupportUrgentTasksProps {
  isFetching: boolean
  vehicleData?: DafCompanyVehiclesData
}

const MultisupportUrgentTasks: FC<MultisupportUrgentTasksProps> = ({
  vehicleData,
  isFetching,
}) => {
  const [notCoveredPlansVehicles, setNotCoveredPlansVehicles] = useState<Vehicle[]>(
    []
  )
  const [mileageSettlementInput, setMileageSettlementInput] = useState<Vehicle[]>([])
  const [recallVehicles, setRecallVehicles] = useState<Vehicle[]>([])
  const [maintenanceOverdueTasks, setMaintenanceOverdueTasks] = useState<Vehicle[]>(
    []
  )
  const history = useHistory()
  const {
    site: { name },
  } = useSitecoreContext()

  const getNotCoveredPlans = (dafCompanyVehicles: DafCompanyVehicles) => {
    const notCoveredVehicles: Vehicle[] = []
    dafCompanyVehicles.vehicles.forEach((vehicle) => {
      if (
        vehicle?.rmContract?.mostRecentContractForVehicle &&
        (vehicle?.rmContract?.contractState === SERVICEPLAN_CONTRACT_STATE_EXPIRED ||
          vehicle?.rmContract?.contractState ===
            SERVICEPLAN_CONTRACT_STATE_CANCELED ||
          vehicle?.rmContract?.contractState === SERVICEPLAN_CONTRACT_STATE_ON_HOLD)
      ) {
        notCoveredVehicles.push(vehicle)
      }
    })
    return notCoveredVehicles
  }
  const getRecalVehicles = (dafCompanyVehicles: DafCompanyVehicles) => {
    const recalls: Vehicle[] = []
    dafCompanyVehicles.vehicles.forEach((vehicle) => {
      if (
        vehicle?.servicePlanner?.service?.recalls?.length > 0 &&
        vehicle.servicePlanner.service.recalls.find(
          (recall) =>
            recall.type === RECALL_TYPE_PSFA || recall.type === RECALL_TYPE_SR
        )
      ) {
        recalls.push(vehicle)
      }
    })

    return recalls
  }

  const getMileageSettlementInput = (dafCompanyVehicles: DafCompanyVehicles) => {
    const mileageSettlements: Vehicle[] = []
    dafCompanyVehicles.vehicles.forEach((vehicle) => {
      if (
        vehicle?.rmContract?.mostRecentContractForVehicle &&
        vehicle.rmContract.mileageSettlementInputState === MILEAGE_INPUT_STATE_OPEN
      ) {
        mileageSettlements.push(vehicle)
      }
    })
    return mileageSettlements
  }

  const getMaintenanceOverdueTasks = (dafCompanyVehicles: DafCompanyVehicles) => {
    const maintenanceOverdueVehicles: Vehicle[] = []
    dafCompanyVehicles.vehicles.forEach((vehicle) => {
      if (
        vehicle?.applicableFilters?.maintenance?.includes(
          MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE
        )
      ) {
        maintenanceOverdueVehicles.push(vehicle)
      }
    })
    return maintenanceOverdueVehicles
  }

  const setUrgentTaskFilter = () => {
    const filterCategory = FILTER_GROUP_SERVICE_PLANS
    const params = new URLSearchParams(history.location.search)
    const filterValues = [
      SERVICEPLAN_CONTRACT_STATE_EXPIRED,
      SERVICEPLAN_CONTRACT_STATE_CANCELED,
      SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
    ]
    return `${MY_FLEET_LIST_URL}?${getFilterStringByActiveSearchResultFilterObject(
      {
        [filterCategory]: getExistingFilterValues(
          filterValues,
          vehicleData?.result.filters
        ),
      },
      params,
      false,
      false
    )}`
  }

  const setFinanceFilter = () => {
    const filterCategory = FILTER_GROUP_FINANCE
    const params = new URLSearchParams(history.location.search)
    const filterValues = [MILEAGE_INPUT_STATE_OPEN]
    return `${MY_FLEET_LIST_URL}?${getFilterStringByActiveSearchResultFilterObject(
      {
        [filterCategory]: getExistingFilterValues(
          filterValues,
          vehicleData?.result.filters
        ),
      },
      params,
      false,
      false
    )}`
  }

  const setRecallFilter = () => {
    const filterCategory = FILTER_GROUP_VEHICLE_UPGRADES
    const params = new URLSearchParams(history.location.search)
    const filterValues = [VEHICLE_UPGRADE_WARNING, VEHICLE_UPGRADE_IMPORTANT]
    return `${MY_FLEET_LIST_URL}?${getFilterStringByActiveSearchResultFilterObject(
      {
        [filterCategory]: getExistingFilterValues(
          filterValues,
          vehicleData?.result.filters
        ),
      },
      params,
      false,
      false
    )}`
  }

  const setMaintenanceOverdueFilter = () => {
    const filterCategory = FILTER_GROUP_MAINTENANCE
    const params = new URLSearchParams(history.location.search)
    const filterValues = [MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE]
    return `${MY_FLEET_LIST_URL}?${getFilterStringByActiveSearchResultFilterObject(
      {
        [filterCategory]: getExistingFilterValues(
          filterValues,
          vehicleData?.result.filters
        ),
      },
      params,
      false,
      false
    )}`
  }

  useEffect(() => {
    if (vehicleData) {
      setNotCoveredPlansVehicles(getNotCoveredPlans(vehicleData.result))
      setRecallVehicles(getRecalVehicles(vehicleData.result))
      setMileageSettlementInput(getMileageSettlementInput(vehicleData.result))
      setMaintenanceOverdueTasks(getMaintenanceOverdueTasks(vehicleData.result))
    }
  }, [vehicleData])

  const hasNoUrgentTasks = () => {
    const isCommerceSite = name === COMMERCE_SITE_CONTEXT

    if (isCommerceSite) {
      if (recallVehicles.length === 0 && maintenanceOverdueTasks.length === 0)
        return true
    }

    if (!isCommerceSite) {
      if (
        recallVehicles.length === 0 &&
        maintenanceOverdueTasks.length === 0 &&
        notCoveredPlansVehicles.length === 0 &&
        mileageSettlementInput.length === 0
      )
        return true
    }
    return false
  }

  return (
    <Box
      data-t-id="Homepage-UrgentTaksWidget"
      sx={{
        backgroundColor: COLORS.WHITE,
        position: 'relative',
        borderRadius: 8,
        border: '1px solid',
        borderColor: COLORS.MEDIUM_GRAY,
        padding: [3, 3, 3, 5],
      }}
    >
      {!isFetching && (
        <>
          <Box>
            <Text as="h3" variant="heading2" mb={6}>
              {i18next.t('urgentTasks.urgentTaskLabel')}
            </Text>
          </Box>
          {recallVehicles.length > 0 && (
            <MultisupportUrgentTask
              variant="urgent"
              dataTId="urgent-task-vehicle-upgrades"
              value={i18next.t('urgentTasks.vehicleUpgrades')}
              href={setRecallFilter()}
              amount={recallVehicles.length}
            />
          )}
          {notCoveredPlansVehicles.length > 0 && name !== COMMERCE_SITE_CONTEXT && (
            <MultisupportUrgentTask
              variant="urgent"
              dataTId="urgent-task-not-covered-plans"
              value={i18next.t('urgentTasks.notCoveredPlans')}
              href={setUrgentTaskFilter()}
              amount={notCoveredPlansVehicles.length}
            />
          )}
          {mileageSettlementInput.length > 0 && name !== COMMERCE_SITE_CONTEXT && (
            <MultisupportUrgentTask
              variant="urgent"
              dataTId="urgent-task-input-mileage"
              value={i18next.t('urgentTasks.inputMileage')}
              href={setFinanceFilter()}
              amount={mileageSettlementInput.length}
            />
          )}

          {maintenanceOverdueTasks.length > 0 && (
            <MultisupportUrgentTask
              variant="urgent"
              dataTId="urgent-task-maintenance-overdue"
              href={setMaintenanceOverdueFilter()}
              value={i18next.t('urgentTasks.maintenanceOverdue')}
              amount={maintenanceOverdueTasks.length}
            />
          )}

          {hasNoUrgentTasks() && (
            <MultisupportUrgentTask
              variant="ok"
              value={i18next.t('urgentTasks.noUrgentTasks')}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default MultisupportUrgentTasks
