import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import React from 'react'
import { Box, Image, Text } from 'theme-ui'
import { COLORS } from '../../../../constants/themeConstants'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'

interface VehicleGroupItemProps {
  group: Vehicle[]
  noOfVehicleText: string
  enterInformationText: string
}

const VehicleGroupItem = ({
  group,
  noOfVehicleText,
  enterInformationText,
}: VehicleGroupItemProps) => {
  const { vehicleType, urlVehicleImageModelYearSeries: imageUrl } = group[0]
  const count = group.length

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: COLORS.MEDIUM_GRAY,
        borderRadius: 8,
        display: 'flex',
        flexDirection: ['column', 'row', 'row'],
        alignItems: ['left', 'center', 'center'],
        paddingBlock: '10px',
        paddingInline: '10px',
        marginBlockEnd: '20px',
        gap: 4,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Box>
          <Image
            alt={vehicleType}
            sx={{
              maxWidth: 60,
            }}
            src={imageUrl}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Text
              as="span"
              color={COLORS.BLACK}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {vehicleType}
            </Text>
          </Box>
          <Box>
            <Text
              as="span"
              variant="bodySmall"
              color={COLORS.BLACK}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {`${noOfVehicleText} `}
              <Text as="span" sx={{ color: COLORS.GRAY }}>
                {count}
              </Text>
            </Text>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginLeft: ['0', 'auto', 'auto'] }}>
        <Button
          variant="primary"
          icon={ICONS.ARROW_RIGHT}
          iconPosition="end"
          sx={{ width: '100%' }}
        >
          {enterInformationText}
        </Button>
      </Box>
    </Box>
  )
}

export default VehicleGroupItem
