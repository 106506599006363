/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs'
import i18next from 'i18next'
import { FormErrorFormatter } from '../types/formProps'
import { formatNumber } from './numberFormatHelper'

export enum ValidationRules {
  Required = 'required',
  ShouldBeInThePast = 'shouldBeInThePast',
  ShouldBeInTheFuture = 'shouldBeInTheFuture',
  ShouldBeTodayOrInTheFuture = 'shouldBeTodayOrInTheFuture',
}

export const required = (errorFormatter: FormErrorFormatter) => (value: any) =>
  value ? undefined : errorFormatter('required', [])

export const shouldBeInThePast =
  (errorFormatter: FormErrorFormatter) => (value: string) => {
    if (dayjs().isBefore(dayjs(value))) {
      return errorFormatter('isFutureDateMessage', [])
    }
    return undefined
  }

export const shouldBeInTheFuture =
  (errorFormatter: FormErrorFormatter) => (value: string) => {
    if (dayjs().isAfter(dayjs(value))) {
      return errorFormatter('shouldBeInTheFutureMessage', [])
    }
    return undefined
  }

export const dateShouldNotBeInThePast =
  (errorFormatter: FormErrorFormatter) => (value: string) => {
    const isSameOrAfterToday = dayjs(value).startOf('day')! >= dayjs().startOf('day')
    if (!isSameOrAfterToday) {
      return errorFormatter('dateShouldNotBeInThePastMessage', [])
    }
    return undefined
  }

export const minimumInputValidation =
  (minimumInputValue: number, cultureCode: string | undefined) => (value: any) => {
    // const formattedInput = reverseFormatNumber(value, cultureCode)
    if (value < minimumInputValue) {
      return i18next.t('errorMessages.valueShouldBeAtLeast', {
        minValue: `${formatNumber(minimumInputValue, cultureCode)} km`,
      })
    }
    return undefined
  }
