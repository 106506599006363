import React from 'react'
import PartListHeading from './PartListHeading'
import PartListItems from './PartListItems'

const PartList = () => (
  <>
    <PartListHeading />

    <PartListItems />
  </>
)
export default PartList
