import React, { useContext, useEffect } from 'react'
import { useShoppingBasket } from '../../../providers/ShoppingBasketProvider'
import {
  URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM,
} from '../../../constants/urlConstants'
import ColumnLayout from '../../atoms/ColumnLayout/ColumnLayout'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import OrderHeading from './OrderPage/OrderHeading'
import { Box, Flex } from 'theme-ui'
import Container from '../../atoms/Container/Container'
import OrderOverviewQuickOrderByPartNumber from './OrderOverview/OrderOverviewQuickOrderByPartNumber'
import OrderSummary from './OrderPage/OrderSummary'
import OrderOverviewOrderNumberAndReference from './OrderOverview/OrderOverviewOrderNumberAndReference'
import Spinner from '../../atoms/Spinner/Spinner'
import OrderOverview from './OrderOverview/OrderOverview'
import OrderInformation from './OrderInformation/OrderInformation'
import { getApplicationHeaderHeight } from '../../../helpers/layoutHelper'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { getTotalOrderAmount } from '../../../helpers/priceHelper'
import { getEnglishCategoryName } from '../../../helpers/categoryHelper'
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers'
import WebshopPointsCampaignSidePanel from '../SidePanel/ShoppingBasket/WebshopPointsCampaignSidePanel'

const ShoppingBasketConsumer = () => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { step, rendering, initialFetch } = useShoppingBasket()

  const { categories } = useContext(CategoriesContext)

  useEffect(() => {
    pushToDataLayer({
      event: DATA_LAYER.EVENT_NAME.VIEW_CART,
      ecommerce: {
        value: getTotalOrderAmount(order).value,
        currency: getTotalOrderAmount(order).currency,
        items: order?.items?.map((item) => ({
          item_id: item.partNumber,
          item_name: item.englishDescription,
          item_brand: item.brand?.description,
          item_category: getEnglishCategoryName(item.category, categories),
          item_list_name: DATA_LAYER.EVENT_NAME.SHOPPING_BASKET,
          doa_product: item.type === 'DealerOwnAssortment',
          quantity: item.quantity,
          price: item.price?.netUnitPrice,
        })),
      },
    })
  }, [categories, order])

  if (initialFetch) {
    return (
      <Container
        sx={{
          marginY: [6, 6, 6, 7],
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <Container>
        <OrderHeading />
      </Container>
      <Box
        sx={{
          display: 'grid',
        }}
      >
        <Container
          sx={{
            gridRow: '1 / 2',
            marginBlockEnd: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 4,
            }}
          >
            <Box
              sx={{
                flex: '99999 1 700px',
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                {step === URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM && (
                  <>
                    <OrderOverviewOrderNumberAndReference />

                    <OrderOverviewQuickOrderByPartNumber />

                    <OrderOverview />
                  </>
                )}

                {(step === URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM ||
                  step === URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM) && (
                  <>
                    <OrderInformation />
                  </>
                )}
              </Flex>
            </Box>
            <Box
              sx={{
                display: ['none', null, null, null, 'block'],
                flex: '1 1 420px',
              }}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: getApplicationHeaderHeight(),
                }}
              >
                <OrderSummary />
              </Box>
            </Box>
          </Box>
        </Container>

        <Box
          sx={{
            gridRow: '2/3',
            display: ['block', null, null, null, 'none'],
            zIndex: 1,
            alignSelf: 'end',
            position: 'sticky',
            insetBlockEnd: 0,
          }}
        >
          <OrderSummary />

          {order?.webshopPointsCampaign &&
            step === URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM && (
              <Box
                sx={{
                  marginBlockStart: 4,
                }}
              >
                <WebshopPointsCampaignSidePanel />
              </Box>
            )}
        </Box>
      </Box>

      {step === URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM && (
        <ColumnLayout
          params={{
            topSpacing: '1',
            bottomSpacing: '1',
            topDivider: '1',
          }}
        >
          {rendering && (
            <Placeholder
              name="jss-shopping-basket-product-overview-content"
              rendering={rendering}
            />
          )}
        </ColumnLayout>
      )}
    </>
  )
}

export default ShoppingBasketConsumer
