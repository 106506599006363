import React from 'react'
import { Box } from 'theme-ui'
import IconWrapper from '../IconWrapper/IconWrapper'

interface ButtonIconProps {
  icon?: string
  size?: number
  removeMargin?: boolean
}

const ButtonIcon = ({ icon, size = 5, removeMargin = false }: ButtonIconProps) => {
  if (!icon) return null

  return (
    <Box
      sx={{
        '&:first-of-type': {
          marginLeft: !removeMargin ? -2 : null,
          marginRight: !removeMargin ? 1 : null,
        },
        '&:last-of-type': {
          marginLeft: !removeMargin ? 1 : null,
        },
        '&:first-of-type:last-of-type': {
          marginLeft: 0,
          marginRight: 0,
        },
      }}
    >
      <IconWrapper size={size} icon={icon} />
    </Box>
  )
}

export default ButtonIcon
