import React from 'react'
import { Box, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { alphaColor } from '../../../helpers/themeHelper'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'

interface ServicePlansCoverageSummaryItemProps {
  icon: string
  label: string
  active: boolean
}

function ServicePlansCoverageSummaryItem({
  icon,
  label,
  active,
}: ServicePlansCoverageSummaryItemProps) {
  return (
    <Box
      sx={{
        backgroundColor: !active ? COLORS.WHITE : alphaColor(COLORS.DAF_BLUE, 0.08),
        borderRadius: 8,
        border: !active ? `1px solid ${COLORS.MEDIUM_GRAY}` : 'none',
        paddingY: 2,
        paddingX: 3,
      }}
    >
      <Box>
        <IconWrapper
          icon={icon}
          color={!active ? COLORS.MEDIUM_GRAY : COLORS.DAF_BLUE}
        />
        <Text
          variant="bodySmall"
          color={!active ? COLORS.MEDIUM_GRAY : COLORS.DAF_BLUE}
        >
          {label}
        </Text>
      </Box>
    </Box>
  )
}

export default ServicePlansCoverageSummaryItem
