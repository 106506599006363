import React from 'react'
import PartnerTile from '../../atoms/PartnerTile/PartnerTile'
import { Dealer } from '../../../types/dealerServiceTypes'

interface PartnerTileListProps {
  partners?: Array<Dealer | null>
  removePartner?: (partner: Dealer) => void
}

const PartnerTileList = ({ partners, removePartner }: PartnerTileListProps) => {
  if (!partners) {
    return null
  }

  return (
    <>
      {partners.map(
        (partner: Dealer | null) =>
          partner && (
            <PartnerTile
              key={partner.code}
              partner={partner}
              removePartner={removePartner || undefined}
            />
          )
      )}
    </>
  )
}

export default PartnerTileList
