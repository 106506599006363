import React, { FC, useCallback, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'theme-ui'
import {
  refetchShoppingBasketSidepanelSummary,
  setShoppingBasketSidePanelOrderSummary,
  setShoppingBasketSidePanelState,
} from '../../../actions/shoppingBasket/sidePanelActions'
import {
  ACTIVE_USER_STATE,
  ACTIVE_USER_STATE_COOKIE_OPTIONS,
} from '../../../constants/cookieConstants'
import { USER_ROLES } from '../../../constants/userConstants'
import withData from '../../../enhancers/withData'
import { encodeActiveUserStateCookie } from '../../../helpers/cookieHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { hasRole } from '../../../helpers/userHelper'
import useCommerceApiPostService from '../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { ActiveUserStateContext } from '../../../providers/ActiveUserStateProvider'
import { UserContext } from '../../../providers/UserProvider'
import { RootState } from '../../../reducers'
import {
  getOrdersSummary,
  OrdersSummaryRequestProps,
} from '../../../services/rest/ecommerce/order/orders-summary'
import { CommerceOrderSummary, CommerceResponse } from '../../../types/commerceApi'
import { ActiveUserState, Datasource, TextField } from '../../../types/layoutService'
import ShippingBasketSidePanel from '../SidePanel/ShoppingBasket/ShoppingBasketSidePanel'
import ShoppingBasketAnchor from './ShoppingBasketAnchor'
import { URL_SHOPPING_BASKET_WIZARD_ORDER_PARAM } from '../../../constants/urlConstants'
import { useLocation } from 'react-router-dom'

export interface ShoppingBasketDatasource {
  title: TextField
  basketButtonLabel: TextField
}

const ShoppingBasket: FC<Datasource<ShoppingBasketDatasource>> = ({
  datasource: { title, basketButtonLabel },
}) => {
  const { user } = useContext(UserContext)
  const activeUserState = useContext(ActiveUserStateContext)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const [, setCookie] = useCookies([ACTIVE_USER_STATE])
  const refreshSummary = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel.refresh
  )
  const location = useLocation()
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const orderParamValue = queryParams.get(URL_SHOPPING_BASKET_WIZARD_ORDER_PARAM)

  const [, response, fetchOrderSummary] = useCommerceApiPostService<
    OrdersSummaryRequestProps,
    CommerceResponse<CommerceOrderSummary>
  >(getOrdersSummary)

  const updateActiveUserStateSelectedOrderNumber = useCallback(
    (state?: ActiveUserState, orderNumber?: string) => {
      setCookie(
        ACTIVE_USER_STATE,
        encodeActiveUserStateCookie({
          ...state,
          selectedOrderNumber: orderNumber,
        }),
        ACTIVE_USER_STATE_COOKIE_OPTIONS
      )
    },
    [setCookie]
  )

  const onResult = useCallback(
    (orderSummary: CommerceOrderSummary | null) => {
      if (orderSummary) {
        dispatch(setShoppingBasketSidePanelOrderSummary(orderSummary))

        if (!activeUserState.orderNumber) {
          updateActiveUserStateSelectedOrderNumber(
            activeUserState.activeUserState,
            orderSummary.orderNumber
          )
        }
      } else {
        updateActiveUserStateSelectedOrderNumber(
          activeUserState.activeUserState,
          undefined
        )
      }
    },
    [
      dispatch,
      activeUserState.orderNumber,
      activeUserState.activeUserState,
      updateActiveUserStateSelectedOrderNumber,
    ]
  )

  useEffect(() => {
    if (orderParamValue) {
      updateActiveUserStateSelectedOrderNumber(
        activeUserState.activeUserState,
        orderParamValue
      )
    }
  }, [location.search])

  useEffect(() => {
    const orderNumber = orderParamValue
      ? orderParamValue
      : activeUserState.orderNumber
    fetchOrderSummary({ orderNumber })

    if (refreshSummary === true) {
      fetchOrderSummary({ orderNumber })
      dispatch(refetchShoppingBasketSidepanelSummary(false))
    }
  }, [
    dispatch,
    fetchOrderSummary,
    orderParamValue,
    activeUserState.orderNumber,
    refreshSummary,
  ])

  useCommerceApiResponse<CommerceOrderSummary>({
    response,
    onResult,
    onMessage: () => undefined,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrderSummary,
    onStatus: {
      orderNotFound: () => {
        updateActiveUserStateSelectedOrderNumber(
          activeUserState.activeUserState,
          undefined
        )
      },
    },
  })

  if (!hasRole(user, USER_ROLES.commerceShoppingBasket) && !isImpersonated) {
    return null
  }

  return (
    <>
      <Flex sx={{ position: 'relative' }}>
        <ShoppingBasketAnchor
          title={getTextFieldValue(title)}
          onClick={() => dispatch(setShoppingBasketSidePanelState(true))}
          data-t-id="menu-button-shopping-basket"
        />
      </Flex>

      <ShippingBasketSidePanel
        title={getTextFieldValue(title)}
        basketButtonLabel={basketButtonLabel}
      />
    </>
  )
}

export default withData(ShoppingBasket)
