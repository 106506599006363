import { useCallback, useReducer } from 'react'
import { PageEvent } from '../types/pageEvents'
import { isBrowser } from '../helpers/dom'

const LOCALSTORAGE_KEY = 'PAGE_EVENTS'

interface PageEventFilter {
  category: string
  minCount: number
  limit?: number
}

interface PageEventData {
  count: number
  modifiedAt: string
}

interface PageEventCategory {
  data: Record<string, PageEventData>
}

interface PageEventState {
  categories: Record<string, PageEventCategory>
}

enum PageEventActionType {
  ADD = 'ADD',
  SAVE = 'SAVE',
}

interface PageEventActionBase {
  type: PageEventActionType
}

interface PageEventAddAction extends PageEventActionBase {
  type: PageEventActionType.ADD
  category: string
  value: string
}

interface PageEventSaveAction extends PageEventActionBase {
  type: PageEventActionType.SAVE
}

type PageEventAction = PageEventAddAction | PageEventSaveAction

const loadState = (): PageEventState => {
  const defaultState: PageEventState = { categories: {} }

  if (!isBrowser()) {
    return defaultState
  }

  try {
    const json = localStorage.getItem(LOCALSTORAGE_KEY)
    return json ? JSON.parse(json) : defaultState
  } catch {
    return defaultState
  }
}

const saveState = (state: PageEventState): void => {
  if (!isBrowser()) {
    return
  }

  try {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(state))
  } catch {
    // IGNORE
  }
}

const pageEventReducer = (state: PageEventState, action: PageEventAction) => {
  switch (action.type) {
    case PageEventActionType.ADD: {
      const categoryKey = action.category
      const category = state.categories[categoryKey] ?? { data: {} }

      const dataKey = action.value
      const data = category.data[dataKey] ?? { count: 0 }

      data.count += 1
      data.modifiedAt = new Date().toISOString()

      category.data[dataKey] = data

      return {
        categories: { ...state.categories, [action.category]: category },
      }
    }
    case PageEventActionType.SAVE: {
      saveState(state)
      return state
    }
    default: {
      return state
    }
  }
}

export type PageEventWriteFunc = (pageEvents: PageEvent[]) => void
export type PageEventReadFunc = (filter: PageEventFilter) => string[]

export type PageEventsTuple = [PageEventReadFunc, PageEventWriteFunc]

const usePageEvents = (): PageEventsTuple => {
  const [state, dispatch] = useReducer(pageEventReducer, loadState())

  const read = useCallback<PageEventReadFunc>(
    (filter: PageEventFilter) => {
      const category = state.categories[filter.category]

      if (!category) {
        return []
      }

      let values = Object.entries(category.data)
        .filter(([, entry]) => entry.count >= filter.minCount)
        .sort(([, a], [, b]) => b.count - a.count)
        .map(([categoryKey]) => categoryKey)

      if (filter.limit) {
        values = values.slice(0, filter.limit)
      }

      return values
    },
    [state]
  )

  const write = useCallback<PageEventWriteFunc>((pageEvents: PageEvent[]) => {
    pageEvents.forEach(({ category, value }) =>
      dispatch({ type: PageEventActionType.ADD, category, value })
    )

    dispatch({ type: PageEventActionType.SAVE })
  }, [])

  return [read, write]
}

export default usePageEvents
