import { COLORS } from '../../../constants/themeConstants'
import { ThemeProps } from '../../../types/themeProps'
import trpTheme from '../trpTheme'

const trpBlueTheme: ThemeProps = {
  ...trpTheme,
  colors: {
    ...trpTheme.colors,
    text: COLORS.WHITE,
    gray: [
      COLORS.LIGHT_GRAY,
      COLORS.LIGHT_GRAY,
      COLORS.LIGHT_GRAY,
      COLORS.DARK,
      COLORS.MEDIUM_DARK,
    ],
  },
  buttons: {
    ...trpTheme.buttons,
    primary: {
      ...trpTheme.buttons.primary,
      color: COLORS.DAF_BLUE,
      bg: COLORS.WHITE,
      ':hover, :focus, :active, &.active': {
        ...trpTheme.buttons.primary[':hover, :focus, :active, &.active'],
        bg: COLORS.LIGHT_GRAY,
        color: COLORS.DAF_BLUE,
      },
    },
    outline: {
      ...trpTheme.buttons.outline,
      color: COLORS.WHITE,
      borderColor: COLORS.WHITE,
      bg: 'transparent',
      ':hover, :focus, :active, &.active': {
        ...trpTheme.buttons.outline[':hover, :focus, :active, &.active'],
        borderColor: COLORS.WHITE,
        color: COLORS.WHITE,
      },
    },
  },

  variants: {
    ...trpTheme.variants,
  },
}

export default trpBlueTheme
