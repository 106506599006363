import React, { FC, useMemo } from 'react'
import { LinkField, TextField } from '../../../types/layoutService'
import { CommerceBillOfMaterialPart } from '../../../types/commerceApi'
import PartBillOfMaterialTable from './PartBillOfMaterialTable'
import PartBillOfMaterialResponsiveTable from './PartBillOfMaterialResponsiveTable'

interface PartBillOfMaterialPartsProps {
  partsPageUri: LinkField
  bomParts?: CommerceBillOfMaterialPart[]
  consistsOfTitle: TextField
  id: string
}

const PartBillOfMaterialParts: FC<PartBillOfMaterialPartsProps> = ({
  bomParts,
  partsPageUri,
  consistsOfTitle,
  id,
}) => {
  const table = useMemo(
    () => (
      <PartBillOfMaterialTable partsPageUri={partsPageUri} bomParts={bomParts} />
    ),
    [partsPageUri, bomParts]
  )

  if (!bomParts?.length) return null

  return (
    <PartBillOfMaterialResponsiveTable title={consistsOfTitle} id={id}>
      {table}
    </PartBillOfMaterialResponsiveTable>
  )
}

export default PartBillOfMaterialParts
