import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import axios, { AxiosError, CancelToken } from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CATEGORY_FILTER_ID } from '../../../../constants/searchConstants'
import { mapFiltersToSearchObject } from '../../../../helpers/searchResultsHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../../providers/UserProvider'
import useSitecoreContext from '../../../useSitecoreContext'
import { loggedIn } from '../../../../helpers/userHelper'
import executeDealerPartSearch, {
  DealerPartSearchData,
  SearchDealerPart,
} from '../../../../services/rest/ecommerce/dealerPartSearch'
import useDealerPartsService, {
  DealerPart,
} from '../../graphql/useDealerPartListService'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import useAxiosClient from '../core/useAxiosClient'

export interface DealerPartFulfilled {
  dealerPart?: DealerPart
  searchDealerPart: SearchDealerPart
  uri?: string
}

const combineRestDealerPartsWithGraphDealerParts = (
  searchDealerParts: SearchDealerPart[],
  dealerParts: DealerPart[]
): DealerPartFulfilled[] =>
  searchDealerParts.map((searchDealerPart) => {
    const dealerPart = dealerParts.find(
      ({ partNumber }) => partNumber === searchDealerPart.partNumber
    )

    return {
      dealerPart: dealerPart ?? undefined,
      searchDealerPart,
      uri: dealerPart?.partNumber.toString(),
    }
  })

const useDealerPartSearchService = (
  searchString: string | undefined,
  categoryId: string | undefined,
  subcategoryId: string | undefined
): [boolean, DealerPartFulfilled[] | undefined, number] => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const appInsights = useAppInsightsContext()

  const { user } = useContext(UserContext)
  const {
    languageContext: { countryCode, cultureCode },
    xSessionId,
  } = useSitecoreContext()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const [fetching, setFetching] = useState<boolean>(false)
  const [searchDealerParts, setSearchDealerParts] = useState<
    SearchDealerPart[] | undefined
  >(undefined)
  const [dealerPartsFulfilled, setDealerPartsFulfilled] = useState<
    DealerPartFulfilled[] | undefined
  >(undefined)
  const [totalCount, setTotalCount] = useState<number>(0)

  const searchDealerPartNumberList = useMemo(
    () => searchDealerParts?.map(({ partNumber }) => partNumber),
    [searchDealerParts]
  )

  const [fetchingGraphDealerParts, graphDealerParts] = useDealerPartsService(
    searchDealerPartNumberList
  )

  const searchFilterObject = useMemo(
    () => {
      if (!categoryId) return undefined

      return mapFiltersToSearchObject({
        [CATEGORY_FILTER_ID]: [subcategoryId || categoryId],
      })
    },
    // all dependencies are available on page load and will not change during component lifecycle

    []
  )

  const getDealerParts = useCallback(
    async (cancelToken: CancelToken) => {
      setFetching(true)

      await executeDealerPartSearch(
        client,
        searchString || '',
        cancelToken,
        cultureCode,
        countryCode,
        searchFilterObject,
        user,
        xSessionId,
        isImpersonated,
        actingCompanyId,
        actingSupplierId
      )
        .then(({ data }) => {
          if (data) {
            setSearchDealerParts(data.parts)
            setTotalCount(data.statistics?.totalCount || 0)
          }
        })
        .catch((error) => {
          const err = error as AxiosError<DealerPartSearchData>

          if (!axios.isCancel(err)) {
            appInsights.trackException({
              exception: new Error(
                `Unable to fetch dealer part search results: ${err}`
              ),
              severityLevel: SeverityLevel.Error,
              properties: {
                countryCode,
                cultureCode,
                isImpersonated,
                actingCompanyId,
                actingSupplierId,
              },
            })
          }
        })
        .finally(() => {
          setFetching(false)
        })
    },

    [
      actingCompanyId,
      actingSupplierId,
      appInsights,
      searchFilterObject,
      searchString,
    ]
  )

  useEffect(() => {
    const { token, cancel } = axios.CancelToken.source()
    const allDataReceived = typeof searchString === 'string'
    const userInformationReady =
      !loggedIn(user) || (actingCompanyId && actingSupplierId)

    if (allDataReceived && userInformationReady) getDealerParts(token)

    return () => {
      if (allDataReceived && userInformationReady) cancel()
    }
  }, [getDealerParts, searchString, user, actingCompanyId, actingSupplierId])

  useEffect(() => {
    if (searchDealerParts && graphDealerParts && !fetchingGraphDealerParts) {
      setDealerPartsFulfilled(
        combineRestDealerPartsWithGraphDealerParts(
          searchDealerParts,
          graphDealerParts || []
        )
      )
    }

    if (searchDealerParts && !graphDealerParts && !fetchingGraphDealerParts) {
      setDealerPartsFulfilled([])
    }
  }, [fetchingGraphDealerParts, graphDealerParts, searchDealerParts])

  useEffect(() => {
    if (dealerPartsFulfilled) {
      setFetching(false)
    }
  }, [dealerPartsFulfilled])

  return [fetching, dealerPartsFulfilled, totalCount]
}

export default useDealerPartSearchService
