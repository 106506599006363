import React, { forwardRef } from 'react'
import { Input as ThemeUIInput, InputProps as ThemeUIInputProps } from 'theme-ui'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'

export interface InputProps
  extends Pick<ThemeUIInputProps, Exclude<keyof ThemeUIInputProps, 'css'>> {
  autoComplete?: 'on' | 'off'
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { pageEditing } = useSitecoreContext()

  return (
    <ThemeUIInput
      ref={ref}
      {...props}
      {...(pageEditing ? { disabled: true } : {})}
    />
  )
})

export default Input
