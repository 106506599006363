import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import Modal, { ModalProps } from '../Modal'
import ModalBodyContent from '../ModalBodyContent'

export interface MenuListModalProps extends ModalProps {
  title: string
}

const MenuListModal: FC<MenuListModalProps> = ({
  title,
  isActive,
  onClose,
  children,
}) => {
  const {
    site: { theme },
  } = useSitecoreContext()
  const { mainNavigation } = useTheme()

  return (
    <Modal
      isActive={isActive}
      theme={theme.name === 'trp' ? 'trpBlue' : 'default'}
      onClose={onClose}
      title={title}
    >
      <ModalBodyContent>
        <Flex
          sx={{
            flexDirection: 'column',
            li: {
              button: {
                px: 0,
                bg: 'transparent',
              },
              div: {
                color: mainNavigation.textColor,
              },
            },
          }}
        >
          {children}
        </Flex>
      </ModalBodyContent>
    </Modal>
  )
}

export default MenuListModal
