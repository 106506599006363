import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { Box, Flex, Text } from 'theme-ui'
import { useDispatch } from 'react-redux'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import { calculateDueInDays, getTime } from '../../../helpers/dateHelper'
import StatePill from '../../atoms/StatePill/StatePill'
import {
  DUE_IN_ONE_TO_TWO_MONTHS,
  DUE_IN_ZERO_TO_ONE_MONTH,
  OVERDUE,
} from '../../../constants/dafCompanyVehicleConstants'
import { BREAKPOINT_S, COLORS } from '../../../constants/themeConstants'
import { validateAndGetDate } from '../../../helpers/vehicleHelper'
import useBreakpoints from '../../../hooks/useBreakpoints'
import Checkbox from '../../atoms/inputs/Checkbox/Checkbox'
import { UpcomingJob } from '../../../services/rest/ecommerce/dafCompanyVehicles'
import { updateCheckedJobs } from '../../../actions/dafCompanyVehicleActions'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'

interface VehicleUpcomingMaintenancesProps {
  vehicleDetail: VehicleDetailResponse
  shouldShowMaintenanceList: boolean
}

interface ChipProps {
  color: string
  text: string
}

const VehicleMaintenanceList = ({
  vehicleDetail,
  shouldShowMaintenanceList,
}: VehicleUpcomingMaintenancesProps) => {
  const dispatch = useDispatch()
  const [checkAllChecked, setCheckAllChecked] = useState(false)
  const getChipProps = (dueIn: string, dueInKm: number, complete: boolean) => {
    if (complete) {
      return {
        color: COLORS.GREEN,
        text: i18next.t('maintenance.reportedAsPerformed').toLocaleLowerCase(),
      } as ChipProps
    }

    if (dueIn && dueIn === DUE_IN_ONE_TO_TWO_MONTHS) {
      return {
        color: COLORS.ORANGE_DARK,
        text: i18next.t('myFleetFilters.dueInOneAndTwoMonths').toLocaleLowerCase(),
      } as ChipProps
    }

    if (dueIn && dueIn === DUE_IN_ZERO_TO_ONE_MONTH) {
      return {
        color: COLORS.PROMOTION,
        text: i18next.t('myFleetFilters.dueInOneMonth').toLocaleLowerCase(),
      } as ChipProps
    }
    if ((dueIn && dueIn === OVERDUE) || (dueInKm && dueInKm <= 0)) {
      return {
        color: COLORS.ALERT,
        text: i18next.t('myFleetFilters.overdue').toLocaleLowerCase(),
      } as ChipProps
    }
    return {} as ChipProps
  }

  const columnNames = [
    i18next.t('commonLabels.description'),
    i18next.t('maintenance.dueIn'),
    i18next.t('maintenance.dueDate'),
    i18next.t('maintenance.state'),
  ]

  const [breakpointS] = useBreakpoints(BREAKPOINT_S)

  const upcomingJobs = vehicleDetail?.servicePlanner?.service?.upcomingJobs
    ?.filter((job) => !job.markedAsComplete)
    .sort((a, b) =>
      a.dueDate && b.dueDate ? getTime(a.dueDate) - getTime(b.dueDate) : 0
    )

  const completedJobs = vehicleDetail?.servicePlanner?.service?.upcomingJobs
    ?.filter((job) => job.markedAsComplete)
    .sort((a, b) =>
      a.dueDate && b.dueDate ? getTime(a.dueDate) - getTime(b.dueDate) : 0
    )

  let allJobs = upcomingJobs

  if (
    vehicleDetail?.rmContract &&
    !vehicleDetail?.vehicleDetail.nonMultiSupportServices.some(
      (service) => service.type === 'MP'
    )
  ) {
    allJobs = upcomingJobs?.concat(completedJobs)
  }

  const [checkedJobs, setCheckedJobs] = useState<UpcomingJob[]>([])

  const handleCheck = (upcomingJob: UpcomingJob) => {
    if (checkedJobs.includes(upcomingJob)) {
      setCheckedJobs(checkedJobs.filter((job) => job !== upcomingJob))
    } else {
      setCheckedJobs(checkedJobs.concat(upcomingJob))
    }
  }

  useEffect(() => {
    dispatch(updateCheckedJobs({ selectedJobs: checkedJobs }))
  }, [checkedJobs, dispatch])

  useEffect(() => {
    if (checkAllChecked) {
      setCheckedJobs(upcomingJobs)
    }

    if (!checkAllChecked) {
      setCheckedJobs([])
    }
  }, [checkAllChecked])

  return (
    <>
      {breakpointS ? (
        // === LARGE SCREEN VIEW ===
        <Box
          as="table"
          sx={{
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <Box
            as="thead"
            sx={{
              borderBlock: '1px solid',
              borderColor: 'gray.1',
              'th, td': {
                paddingBlock: 12,
                paddingInlineEnd: 24,
              },
            }}
          >
            <tr>
              <td>
                <label aria-label={i18next.t('maintenance.selectAllJobs')}>
                  <Checkbox
                    onChange={() => setCheckAllChecked(!checkAllChecked)}
                    checked={checkAllChecked}
                  />
                </label>
              </td>
              {columnNames.map((column) => (
                <Box
                  key={column}
                  as="th"
                  sx={{ textAlign: 'start', inlineSize: '25%' }}
                >
                  <Text
                    variant="smallText"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {column}
                  </Text>
                </Box>
              ))}
            </tr>
          </Box>
          {shouldShowMaintenanceList && (
            <Box
              as="tbody"
              sx={{
                'th, td': {
                  paddingBlock: 24,
                  paddingInlineEnd: 24,
                },
              }}
            >
              {allJobs?.map((upcomingJob) => (
                <Box
                  key={`${upcomingJob.code}-${upcomingJob.dueDate}`}
                  as="tr"
                  sx={{
                    borderBlockEnd: '1px solid',
                    borderColor: 'gray.1',
                    position: 'relative',
                  }}
                >
                  <Box as="td" sx={{ inlineSize: 1 }}>
                    {!upcomingJob.markedAsComplete && (
                      <>
                        <Checkbox
                          id={`${upcomingJob.code}-${upcomingJob.dueDate}-large`}
                          onChange={() => handleCheck(upcomingJob)}
                          checked={checkedJobs.includes(upcomingJob)}
                        />
                        <label
                          aria-label={`${upcomingJob.code}–${upcomingJob.description}`}
                          htmlFor={`${upcomingJob.code}-${upcomingJob.dueDate}-large`}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              inset: 0,
                            }}
                          />
                        </label>
                      </>
                    )}
                  </Box>
                  <Box as="th" sx={{ textAlign: 'start' }}>
                    {upcomingJob.code && (
                      <Text variant="heading4">
                        {upcomingJob.code}
                        <span> – </span>
                        {upcomingJob.description}
                      </Text>
                    )}
                  </Box>
                  <td>
                    <Text variant="bodySmall">
                      {Math.abs(calculateDueInDays(upcomingJob.dueDate))}
                      &nbsp;
                      {calculateDueInDays(upcomingJob.dueDate) < 0
                        ? i18next.t('commonLabels.daysAgo')
                        : i18next.t('commonLabels.days')}
                    </Text>
                  </td>

                  <td>
                    <Text variant="bodySmall">
                      <time dateTime={upcomingJob.dueDate}>
                        {validateAndGetDate(upcomingJob.dueDate)}
                      </time>
                    </Text>
                  </td>
                  <td>
                    {getChipProps(
                      upcomingJob?.dueIn,
                      upcomingJob?.dueInKms,
                      upcomingJob?.markedAsComplete
                    ).color && (
                      <StatePill
                        state={
                          getChipProps(
                            upcomingJob?.dueIn,
                            upcomingJob?.dueInKms,
                            upcomingJob?.markedAsComplete
                          ).text
                        }
                        color={
                          getChipProps(
                            upcomingJob?.dueIn,
                            upcomingJob?.dueInKms,
                            upcomingJob?.markedAsComplete
                          ).color
                        }
                      />
                    )}
                  </td>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ) : (
        <>
          {/* === SMALL SCREEN VIEW ===*/}
          {shouldShowMaintenanceList && (
            <Box
              as="ol"
              sx={{
                padding: 0,
                listStyle: 'none',
                borderBlockStart: '1px solid',
                borderColor: 'gray.1',
              }}
            >
              {allJobs?.map((upcomingJob) => (
                <Box
                  key={`${upcomingJob.code}-${upcomingJob.dueDate}`}
                  as="li"
                  sx={{
                    paddingBlock: 24,
                    borderBlockEnd: '1px solid',
                    borderColor: 'gray.1',
                    position: 'relative',
                  }}
                >
                  {upcomingJob.code && (
                    <>
                      <Flex
                        sx={{
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text
                          as="h3"
                          variant="heading4"
                          sx={{
                            paddingBlockEnd: 4,
                          }}
                        >
                          {upcomingJob.code}
                          <span> – </span>
                          {upcomingJob.description}
                        </Text>

                        <div>
                          {!upcomingJob.markedAsComplete && (
                            <>
                              <Checkbox
                                id={`${upcomingJob.code}-${upcomingJob.dueDate}-large`}
                                onChange={() => handleCheck(upcomingJob)}
                                checked={checkedJobs.includes(upcomingJob)}
                              />
                              <label
                                htmlFor={`${upcomingJob.code}-${upcomingJob.dueDate}-large`}
                                aria-label={`${upcomingJob.code}–${upcomingJob.description}`}
                              >
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    inset: 0,
                                  }}
                                />
                              </label>
                            </>
                          )}
                        </div>
                      </Flex>

                      <Box
                        as="dl"
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, auto)',
                          'dt, dd': {
                            paddingBlock: 4,
                          },
                          dd: {
                            margin: 0,
                            paddingInlineStart: 8,
                            marginInlineStart: 'auto',
                          },
                        }}
                      >
                        <dt>
                          <Text variant="bodySmall">{columnNames[1]}</Text>
                        </dt>
                        <dd>
                          <Text variant="bodySmall">
                            {Math.abs(calculateDueInDays(upcomingJob.dueDate))}
                            &nbsp;
                            {calculateDueInDays(upcomingJob.dueDate) < 0
                              ? i18next.t('commonLabels.daysAgo')
                              : i18next.t('commonLabels.days')}
                          </Text>
                        </dd>
                        <dt>
                          <Text variant="bodySmall">{columnNames[2]}</Text>
                        </dt>
                        <dd>
                          <Text variant="bodySmall">
                            <time dateTime={upcomingJob.dueDate}>
                              {validateAndGetDate(upcomingJob.dueDate)}
                            </time>
                          </Text>
                        </dd>
                        <dt>
                          <Text variant="bodySmall">{columnNames[3]}</Text>
                        </dt>
                        <dd>
                          {getChipProps(
                            upcomingJob?.dueIn,
                            upcomingJob?.dueInKms,
                            upcomingJob?.markedAsComplete
                          ).color && (
                            <StatePill
                              state={
                                getChipProps(
                                  upcomingJob?.dueIn,
                                  upcomingJob?.dueInKms,
                                  upcomingJob?.markedAsComplete
                                ).text
                              }
                              color={
                                getChipProps(
                                  upcomingJob?.dueIn,
                                  upcomingJob?.dueInKms,
                                  upcomingJob?.markedAsComplete
                                ).color
                              }
                            />
                          )}
                        </dd>
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </>
      )}

      {shouldShowMaintenanceList && (!allJobs || allJobs?.length === 0) && (
        <Flex
          sx={{
            justifyContent: 'center',
            color: COLORS.GRAY,
            paddingBlock: 32,
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
          }}
        >
          <Flex sx={{ gap: 2, alignItems: 'center' }}>
            <Box
              sx={{
                borderRadius: '50%',
                border: '2px solid',
                borderColor: COLORS.GREEN,
              }}
            >
              <IconWrapper icon={ICONS.CHECK} color={COLORS.GREEN} size={3} />
            </Box>
            <span>{i18next.t('maintenance.noMaintenanceNeeded')}</span>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default VehicleMaintenanceList
