import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Box, Flex } from 'theme-ui'
import Header from '../components/organisms/Header/Header'
import NotificationModal from '../components/organisms/Modal/ModalVariants/NotificationModal'
import { DefaultDialogRenderer, QueueDialogRenderer } from '../core/DialogRenderer'
import CategoriesProvider from '../providers/CategoriesProvider'
import MenuProvider from '../providers/MenuProvider'
import PersistedPageProvider from '../providers/PersistedPageProvider'
import { LayoutProps } from '../types/layoutProps'
import usePageEventRegistration from '../hooks/usePageEventRegistration'

const DefaultMagazineLayout: FC<LayoutProps> = ({ context }) => {
  const { isSearchContext, reCaptcha, route } = context
  usePageEventRegistration()

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={reCaptcha?.siteKey ?? ''}>
        <MenuProvider>
          <CategoriesProvider>
            <PersistedPageProvider isSearchContext={isSearchContext}>
              <Flex
                sx={{
                  flexDirection: 'column',
                  minHeight: '100vh',
                }}
              >
                <Header>
                  <DefaultDialogRenderer selector="notifications" limit={3} />
                  <Placeholder name="jss-header" rendering={route} />
                </Header>

                <Box
                  as="main"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <QueueDialogRenderer selector="modals" limit={3} />
                  <Placeholder name="jss-main-magazine" rendering={route} />
                </Box>

                <Placeholder name="jss-footer" rendering={route} />
              </Flex>

              <NotificationModal />
            </PersistedPageProvider>
          </CategoriesProvider>
        </MenuProvider>
      </GoogleReCaptchaProvider>
    </>
  )
}

export default DefaultMagazineLayout
