import { useEffect } from 'react'
import usePageEvents from './usePageEvents'
import useSitecoreContext from './useSitecoreContext'
import { PageEvent } from '../types/pageEvents'
import { PartContext, SitecoreContext } from '../types/sitecoreContextProps'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../providers/WildcardPageContextProvider'

type ValueResolverFunc = (context: SitecoreContext) => string | undefined

const VALUE_RESOLVERS: Record<string, ValueResolverFunc> = {
  PART_CATEGORY_PAGE_VISIT: (context) => {
    const partContext = context?.wildcardPageContext?.[
      WILDCARD_PAGE_CONTEXT_TYPE_PART
    ] as PartContext | undefined

    return partContext?.subcategory?.id ?? partContext?.category?.id
  },
  PART_DETAIL_PAGE_VISIT: (context) => {
    const partContext = context?.wildcardPageContext?.[
      WILDCARD_PAGE_CONTEXT_TYPE_PART
    ] as PartContext | undefined

    return partContext?.part?.partNumber
  },
}

const createPageEvents = (context: SitecoreContext): PageEvent[] => {
  if (!context?.analytics?.pageEvents) return []

  return context.analytics.pageEvents
    .map(({ category }) => {
      if (!category) {
        return undefined
      }

      const valueResolver = VALUE_RESOLVERS[category]
      const value = valueResolver ? valueResolver(context) : undefined

      if (!value) {
        return undefined
      }

      return {
        category,
        value,
      }
    })
    .filter((pageEvent): pageEvent is PageEvent => !!pageEvent)
}

const usePageEventRegistration = (): void => {
  const context = useSitecoreContext()
  const [, write] = usePageEvents()

  useEffect(() => {
    if (context.pageEditing || context.pagePreviewing) {
      return
    }

    write(createPageEvents(context))
  }, [])
}

export default usePageEventRegistration
