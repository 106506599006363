import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { DEFAULT_PART_QUANTITY } from '../../../constants/partSalesInformationConstants'
import { measurePartClick } from '../../../helpers/analyticsHelper'
import { getCommercePartUri } from '../../../helpers/commerceApiHelpers'
import { SlidesPerPage } from '../../../hooks/slider/useSlider'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import PartListSalesInformationProvider from '../../../providers/PartListSalesInformationProvider'
import { Part } from '../../../types/dafResponseProps'
import { LinkField, SliderVariant } from '../../../types/layoutService'
import CollapsibleList from '../../atoms/CollapsibleList/CollapsibleList'
import PartCard, {
  PART_CARD_VARIANT_DEFAULT,
  PART_CARD_VARIANT_FULL,
  PART_CARD_VARIANT_MINIMAL,
} from '../../molecules/Card/PartCard/PartCard'
import { adaptPartToPartCardProps } from '../../molecules/Card/PartCard/partCardAdapters'
import PartLineMinimal from '../../molecules/PartLine/PartLineMinimal'
import ContentSlider from '../ContentSlider/ContentSlider'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'
import { ICONS } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'

interface CardListResponsiveProps {
  dataLayerComponentOrigin?: string
  title?: string
  link?: LinkField
  variant?: SliderVariant
  partList: Part[]
  partPage: LinkField
  fetching?: boolean
  topSpacing?: boolean
  bottomSpacing?: boolean
  onCardClick?(part?: Part, index?: number): void
}

export const SLIDES_PER_PAGE = {
  [PART_CARD_VARIANT_MINIMAL]: 6,
  [PART_CARD_VARIANT_FULL]: 4,
}

const PartCardListResponsive: FC<CardListResponsiveProps> = ({
  title,
  link,
  variant = PART_CARD_VARIANT_DEFAULT,
  partList,
  partPage,
  fetching,
  topSpacing,
  bottomSpacing,
  onCardClick: additionalOnCardClickCallback,
  dataLayerComponentOrigin,
}) => {
  const { categories } = useContext(CategoriesContext)
  const [breakpointS] = useBreakpoints('s')

  const partNumbersQuantity = useMemo(
    () =>
      partList.map((part) => ({
        partNumber: part.partNumber,
        quantity:
          part.specification?.packaging?.packageUnitQuantity ||
          DEFAULT_PART_QUANTITY,
      })),
    [partList]
  )

  const onCardClick = useCallback(
    (part: Part, i: number) => {
      measurePartClick(
        part?.partNumber,
        part?.englishDescription,
        part?.brand?.description,
        part?.category?.mainCategoryId,
        part?.category?.subCategoryId,
        i,
        categories,
        dataLayerComponentOrigin
      )

      if (additionalOnCardClickCallback) additionalOnCardClickCallback(part)
    },
    [additionalOnCardClickCallback, categories, dataLayerComponentOrigin]
  )

  return (
    <PartListSalesInformationProvider
      partNumbersQuantity={partNumbersQuantity}
      includePrice
      includeStock={variant === 'full'}
    >
      {breakpointS && (
        <ContentSlider
          title={title}
          slidesPerPage={SLIDES_PER_PAGE[variant] as SlidesPerPage}
          fetching={fetching}
          topSpacing={topSpacing}
          bottomSpacing={bottomSpacing}
          dataTId={`promotionslider-homepage-${title}`}
        >
          {partList.map((part, i) => (
            <PartCard
              data-t-id={`PromotionPart-${part.partNumber}`}
              variant={variant}
              key={i.toString()}
              onClick={() => onCardClick(part, i)}
              partPage={partPage}
              dataLayerComponentOrigin={dataLayerComponentOrigin}
              {...adaptPartToPartCardProps(part)}
            />
          ))}
        </ContentSlider>
      )}
      {!breakpointS && (
        <>
          <Text as="h2" variant="heading2" mb={3}>
            {title}
          </Text>

          <CollapsibleList itemsVisible={5} buttonAlignment="end">
            {partList.map((part, i) => (
              <PartLineMinimal
                key={i.toString()}
                href={`${partPage.url}/${getCommercePartUri(
                  part.category?.mainCategoryId,
                  part.category?.subCategoryId,
                  part.partNumber
                )}`}
                onClick={() => onCardClick(part, i)}
                part={part}
              />
            ))}
          </CollapsibleList>
        </>
      )}

      {link ? (
        <Box sx={{ marginTop: 3 }}>
          <SitecoreButton
            linkField={link}
            variant="infinite"
            color={COLORS.DAF_BLUE}
            icon={ICONS.ARROW_RIGHT}
            iconPosition="end"
          />
        </Box>
      ) : null}
    </PartListSalesInformationProvider>
  )
}

export default PartCardListResponsive
