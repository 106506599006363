import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { ICONS } from '../../../constants/iconConstants'
import Button from '../../atoms/Button/Button'

interface SearchBarResetProps {
  currentInput?: string
  onClick: () => void
  resetButtonTestId?: string
}

const SearchBarReset: FC<SearchBarResetProps> = ({
  currentInput,
  onClick,
  resetButtonTestId,
}) => {
  const { colors } = useTheme()

  if (!currentInput || currentInput === '') return null

  return (
    <Button
      data-t-id={resetButtonTestId}
      variant="infinite"
      round
      icon={ICONS.CROSS}
      type="reset"
      onClick={onClick}
      sx={{
        ':hover, :focus, :active': {
          color: `${colors.primary} !important`,
        },
        'svg path': {
          fill: 'currentColor!important',
        },
      }}
    />
  )
}

export default SearchBarReset
