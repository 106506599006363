import { CookieSetOptions } from 'universal-cookie'
import { runOnWindow } from '../helpers/dom'

export const ACTIVE_USER_STATE = 'ActiveUserState'
export const ACTIVE_USER_STATE_COOKIE_OPTIONS: CookieSetOptions = {
  path: '/',
  encode: (s) => s,
  domain: runOnWindow((w) => `.${w.location.hostname}`),
  sameSite: 'none',
  secure: true,
}
export const USER_COOKIE_CONSENT = 'daf-gdpr'
export const DEALER_LOCATOR_USE_CLIENT_LOCATION = 'DealerLocatorUseClientLocation'

export const CONSENT_EXPIRATION_IN_YEARS = 1
export const CONSENT_TYPE_ANALYTICAL = 'analytical'
export const CONSENT_TYPE_FUNCTIONAL = 'functional'
export const CONSENT_TYPE_FUNCTIONAL_SOCIAL = 'functionalsocial'
export const CONSENT_TYPE_MARKETING = 'marketing'
