import { isBrowser } from './dom'

export const getGeolocationCoordinates = (): Promise<GeolocationCoordinates> =>
  new Promise((resolve, reject) => {
    if (isBrowser()) {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (geolocation) => resolve(geolocation.coords),
          (error) => reject(new Error(error.message))
        )
      } else {
        reject(new Error('geolocation api is not supported in this browser'))
      }
    }
  })
