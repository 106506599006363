import React, { FC } from 'react'
import { Flex } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import Button, { ButtonProps } from '../../atoms/Button/Button'
import Spinner from '../../atoms/Spinner/Spinner'

interface AddToBasketConfirmButtonSmallProps extends ButtonProps {
  disabled?: boolean
  posting?: boolean
}

const AddToBasketConfirmButtonSmall: FC<AddToBasketConfirmButtonSmallProps> = ({
  disabled,
  posting,
  variant,
  sx,
  ...buttonProps
}) => (
  <Button
    type="submit"
    disabled={disabled}
    variant={variant || 'primary'}
    icon={!posting ? ICONS.BASKET : undefined}
    sx={{
      paddingLeft: 0,
      paddingRight: 0,
      ...sx,
    }}
    {...buttonProps}
  >
    {posting && (
      <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Spinner size={2} color="currentColor" />
      </Flex>
    )}
  </Button>
)

export default AddToBasketConfirmButtonSmall
