import React, { FC } from 'react'
import { TextField } from '../../../../types/layoutService'
import PartFilterConfirmButton from './PartFilterConfirmButton'
import SidePanelFooter from '../SidePanelFooter'
import PartFilterResetButton from './PartFilterResetButton'

interface PartFilterSidePanelFooterProps {
  removeFiltersLabel: TextField
  confirmFiltersLabel: TextField
  onClose: () => void
}

const PartFilterSidePanelFooter: FC<PartFilterSidePanelFooterProps> = ({
  removeFiltersLabel,
  confirmFiltersLabel,
  onClose,
}) => (
  <SidePanelFooter>
    <PartFilterResetButton label={removeFiltersLabel} />

    <PartFilterConfirmButton onClose={onClose} label={confirmFiltersLabel} />
  </SidePanelFooter>
)

export default PartFilterSidePanelFooter
