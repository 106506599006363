import React, { FC, useContext, useMemo } from 'react'
import { CATEGORY_FILTER_ID } from '../../../../../../constants/searchConstants'
import { BREAKPOINT_M } from '../../../../../../constants/themeConstants'
import {
  filterUnPublished,
  getCategoryById,
  getExistingSubcategoryIds,
  getFlattenedCategoriesTree,
  restrictCategoriesTreeToParentCategory,
  sortTreeCategoriesAlphabetically,
} from '../../../../../../helpers/categoryHelper'
import useBreakpoints from '../../../../../../hooks/useBreakpoints'
import { CategoriesContext } from '../../../../../../providers/CategoriesProvider'
import { PartSearchResultContext } from '../../../../../../providers/PartSearchResultContextProvider'
import { SearchContext } from '../../../../../../providers/SearchContextProvider'
import { TextField } from '../../../../../../types/layoutService'
import RemoveCategoryFilterButton from '../../../../../molecules/RemoveCategoryFilterButton/RemoveCategoryFilterButton'
import PartSearchControlsSubcategoryListColumns from './PartSearchControlsSubcategoryListColumns'
import PartSearchControlsSubcategoryListModalAnchor from './PartSearchControlsSubcategoryListModalAnchor'

export interface PartSearchControlsSubcategoryListProps {
  availableCategories: string[] | undefined
  categoriesLabel: TextField
  callback?: () => void
}

const PartSearchControlsSubcategoryList: FC<
  PartSearchControlsSubcategoryListProps
> = ({ categoriesLabel, availableCategories, callback }) => {
  const { category, subcategory } = useContext(SearchContext)
  const { categories } = useContext(CategoriesContext)
  const { activeFilters } = useContext(PartSearchResultContext)
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)

  const parentCategory = useMemo(
    () => getCategoryById(category?.id, categories),
    [categories, category]
  )

  const categoryIds = useMemo(
    () =>
      getExistingSubcategoryIds(
        availableCategories,
        filterUnPublished(categories || [])
      ),
    [availableCategories, categories]
  )

  const categoriesTree = useMemo(
    () =>
      sortTreeCategoriesAlphabetically(
        restrictCategoriesTreeToParentCategory(
          getFlattenedCategoriesTree(categoryIds, categories),
          parentCategory
        )
      ),
    [categories, categoryIds, parentCategory]
  )

  const selectedCategoryFilter = useMemo(
    () => activeFilters?.[CATEGORY_FILTER_ID]?.[0],
    [activeFilters]
  )

  if (
    !subcategory?.id &&
    !!selectedCategoryFilter &&
    category?.id !== selectedCategoryFilter
  ) {
    return <RemoveCategoryFilterButton label={categoriesLabel} callback={callback} />
  }

  if (!breakpointM) {
    return (
      <PartSearchControlsSubcategoryListModalAnchor
        title={categoriesLabel}
        categoriesTree={categoriesTree}
        callback={callback}
      />
    )
  }

  return (
    <PartSearchControlsSubcategoryListColumns
      title={categoriesLabel}
      categoriesTree={categoriesTree}
      callback={callback}
    />
  )
}

export default PartSearchControlsSubcategoryList
