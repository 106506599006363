import React, { useMemo } from 'react'
import Button from '../../../atoms/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import useCommerceApiPostService from '../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { registerModalNotification } from '../../../../actions/notificationActions'
import {
  OciResponse,
  orderGetPunchoutOci,
  orderGetPunchoutXml,
  orderPunchout,
  OrderPunchoutProps,
} from '../../../../services/rest/ecommerce/order/order-punchout'
import useCommerceApiFetchService from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import i18next from 'i18next'

interface PunchoutButtonProps {
  disabled?: boolean
}
const PunchoutButton = ({ disabled = false }: PunchoutButtonProps) => {
  const dispatch = useDispatch()
  const { order, mutation } = useSelector(
    (state: RootState) => state.shoppingBasketWizard
  )
  const buttonDisabled = useMemo(
    () =>
      mutation.mutationInProgress ||
      !order?.isConfirmAllowed ||
      !order?.items?.length ||
      !order?.isQuoteConfirmAllowed ||
      disabled,
    [disabled]
  )

  const [postingOrderPunchout, orderPunchoutResponse, postOrderPunchout] =
    useCommerceApiPostService<OrderPunchoutProps, CommerceResponse<CommerceOrder>>(
      orderPunchout
    )

  const [gettingPunchoutXml, orderPunchoutXmlResponse, getXml] =
    useCommerceApiFetchService<OrderPunchoutProps, CommerceResponse<string>>(
      orderGetPunchoutXml
    )

  const [gettingPunchoutOci, orderPunchoutOciResponse, getOci] =
    useCommerceApiFetchService<OrderPunchoutProps, CommerceResponse<OciResponse>>(
      orderGetPunchoutOci
    )

  const orderConfirmed = () => {
    if (order?.punchOutRequest.punchOutType === 'CXML') {
      getXml({ orderNumber: order?.number })
    } else if (order?.punchOutRequest.punchOutType === 'OCI') {
      getOci({ orderNumber: order?.number })
    }
  }

  const appendHiddenInput = (name: string, value: string, form: HTMLFormElement) => {
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.name = name
    hiddenInput.value = value
    form.appendChild(hiddenInput)
  }

  const onPunchoutXmlResult = (res: string | null) => {
    if (!res) return
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', order?.punchOutRequest.formPostUrl || '')
    appendHiddenInput('cxml-urlencoded', res, form)
    document.body.appendChild(form)
    form.submit()
  }

  const onPunchoutOciResult = (res: OciResponse | null) => {
    if (!res) return
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', order?.punchOutRequest.formPostUrl || '')
    res?.orderLines.forEach((orderLine, i) => {
      appendHiddenInput(
        `NEW_ITEM-DESCRIPTION[${i + 1}]`,
        orderLine.description,
        form
      )
      appendHiddenInput(`NEW_ITEM-QUANTITY[${i + 1}]`, orderLine.quantity, form)
      appendHiddenInput(`NEW_ITEM-UNIT[${i + 1}]`, orderLine.unit, form)
      appendHiddenInput(`NEW_ITEM-PRICE[${i + 1}]`, orderLine.price, form)
      appendHiddenInput(`NEW_ITEM-CURRENCY[${i + 1}]`, orderLine.currency, form)
      appendHiddenInput(`NEW_ITEM-MATGROUP[${i + 1}]`, 'SG050228', form)
      appendHiddenInput(
        `NEW_ITEM-VENDORMAT[${i + 1}]`,
        orderLine.productNumber,
        form
      )
      appendHiddenInput(
        `NEW_ITEM-CUST_FIELD1[${i + 1}]`,
        orderLine.customValue1,
        form
      )
      appendHiddenInput(`NEW_ITEM-CUST_FIELD2[${i + 1}]`, orderLine.sacCode, form)
      appendHiddenInput(
        `NEW_ITEM-CUST_FIELD3[${i + 1}]`,
        orderLine.autoConfirmPunchOut,
        form
      )
    })

    document.body.appendChild(form)
    form.submit()
  }

  const punchoutOrder = () => {
    if (buttonDisabled) return

    postOrderPunchout({ orderNumber: order?.number })
  }

  useCommerceApiResponse<CommerceOrder>({
    response: orderPunchoutResponse,
    onResult: orderConfirmed,
    onMessage: (message) => {
      if (message.severity === 'Error' && !!message?.message?.length) {
        dispatch(registerModalNotification('Error', message.message))
      }
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrder,
  })

  useCommerceApiResponse<string>({
    response: orderPunchoutXmlResponse,
    onResult: onPunchoutXmlResult,
    onMessage: (message) => {
      if (message.severity === 'Error' && !!message?.message?.length) {
        dispatch(registerModalNotification('Error', message.message))
      }
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as string,
  })

  useCommerceApiResponse<OciResponse>({
    response: orderPunchoutOciResponse,
    onResult: onPunchoutOciResult,
    onMessage: (message) => {
      if (message.severity === 'Error' && !!message?.message?.length) {
        dispatch(registerModalNotification('Error', message.message))
      }
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as OciResponse,
  })

  return (
    <Button
      data-t-id="shopping-basket-confirm-order-button"
      disabled={
        buttonDisabled ||
        postingOrderPunchout ||
        gettingPunchoutXml ||
        gettingPunchoutOci
      }
      onClick={punchoutOrder}
      sx={{
        inlineSize: '100%',
      }}
    >
      {order?.punchOutRequest.autoConfirmPunchOut
        ? i18next.t('shoppingLabels.punchoutAndConfirm')
        : i18next.t('shoppingLabels.punchout')}
    </Button>
  )
}

export default PunchoutButton
