import React from 'react'
import { Flex } from 'theme-ui'

const InformationIcon = () => (
  <Flex
    as="span"
    sx={{
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'primary',
      borderRadius: '50%',
      height: '14px',
      width: '14px',
      '::before, ::after': {
        content: '""',
        bg: 'background',
        borderRadius: 2,
        width: 2,
      },
      '::before': {
        height: '2px',
        marginBottom: '1px',
      },
      '::after': {
        height: '5px',
      },
    }}
  />
)

export default InformationIcon
