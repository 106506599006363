import { ShoppingBasketWizardState } from '.'
import { CommerceRecipient } from '../../../types/commerceApi'

export default (state: ShoppingBasketWizardState, payload: CommerceRecipient[]) => {
  if (!state?.order) return state

  return {
    ...state,
    order: {
      ...state.order,
      recipients: payload,
    },
  }
}
