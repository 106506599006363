import React, { FC } from 'react'
import { ImageProps } from '../../atoms/Image/Image'
import PromotionStickerAsync from '../../atoms/PromotionSticker/PromotionStickerAsync'
import PartLineImage from './PartLineImage'

interface PartLineImageProps extends ImageProps {
  partNumber?: string
}

const PartLineProductImage: FC<PartLineImageProps> = ({
  partNumber,
  ...imageProps
}) => {
  if (!partNumber) return <></>

  return (
    <PromotionStickerAsync
      variant="s"
      sx={{
        width: 64,
        display: 'inline-block',
      }}
    >
      <PartLineImage {...imageProps} />
    </PromotionStickerAsync>
  )
}

export default PartLineProductImage
