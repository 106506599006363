import React, { FC, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { loggedIn } from '../../../helpers/userHelper'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { UserContext } from '../../../providers/UserProvider'
import { Datasource, TextField } from '../../../types/layoutService'
import StoreSwitcherMenuList from './Menu/StoreSwitcherMenuList'

interface StoreSwitcherDatasource {
  companySelectTitle: TextField
  supplierSelectTitle: TextField
}

const StoreSwitcher: FC<Datasource<StoreSwitcherDatasource>> = ({ datasource }) => {
  const dispatch = useDispatch()
  const { user } = useContext(UserContext)
  const { isImpersonated, activeSupplier } = useContext(ActiveStoreProviderContext)

  useEffect(() => {
    pushToDataLayer({ power_user: isImpersonated })
  }, [dispatch, isImpersonated])

  useEffect(() => {
    if (typeof activeSupplier?.userIsLoyaltyMember !== 'undefined') {
      pushToDataLayer({ loyaltyMember: activeSupplier.userIsLoyaltyMember })
      pushToDataLayer({ loyalty_member: activeSupplier.userIsLoyaltyMember })
    }
  }, [dispatch, activeSupplier])

  if (!loggedIn(user)) return null

  return (
    <Box sx={{ ml: [-2, -2, -4] }}>
      <StoreSwitcherMenuList
        companySelectTitle={datasource.companySelectTitle}
        supplierSelectTitle={datasource.supplierSelectTitle}
      />
    </Box>
  )
}

export default withData(StoreSwitcher, { showHeaderMessageWhenPageEditting: true })
