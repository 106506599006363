import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { isMobile } from 'react-device-detect'
import { SCROLL_BAR_WIDTH, SCROLL_THUMB_COLOR } from '../constants/themeConstants'

const DisableBodyScroll: FC = () => (
  <Helmet>
    <style type="text/css">
      {`
        body {
          overflow: hidden;
          padding-right: ${SCROLL_BAR_WIDTH};
        }

        body::after {
          content: '';
          position: fixed;
          top: 0;
          right: 0;
          width: ${SCROLL_BAR_WIDTH};
          height: 100vh;
          background-color: ${SCROLL_THUMB_COLOR};
        }

        
        ${
          isMobile &&
          `
          body {
            padding-right: 0;
          }
          
          body::after {
            display: none;
          }
          `
        }
      `}
    </style>
  </Helmet>
)

export default DisableBodyScroll
