import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { useCookies } from 'react-cookie'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { Company, CompanyBase, Supplier } from '../../../../types/userProps'
import Menu from '../../../molecules/Menu/Menu'
import MenuList from '../../../molecules/MenuList/MenuList'
import StoreSwitcherMenuListAction from './StoreSwitcherMenuAction'
import StoreSwitcherMenuAnchor from './StoreSwitcherMenuAnchor'
import { ActiveUserState, TextField } from '../../../../types/layoutService'
import HotjarSuppress from '../../../atoms/HotjarSuppress/HotjarSuppress'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { encodeActiveUserStateCookie } from '../../../../helpers/cookieHelper'
import {
  ACTIVE_USER_STATE,
  ACTIVE_USER_STATE_COOKIE_OPTIONS,
} from '../../../../constants/cookieConstants'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import { getCompanyDisplayName } from '../../../../helpers/companyHelper'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import MenuListStoreSwitcherModal from '../../Modal/ModalVariants/MenuListStoreSwitcherModal'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { ConditionalClickableWrapper } from '../../../atoms/ClickableWrapper/ConditionalClickableWrapper'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import useOutsideClick from '../../../../hooks/useOutsideClick'

interface StoreSwitcherMenuProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css' | 'title'>> {
  dataLayerEvent?:
    | typeof DATA_LAYER.EVENT.SELECT_COMPANY
    | typeof DATA_LAYER.EVENT.SELECT_SUPPLIER
  items?: CompanyBase[]
  activeItem?: CompanyBase
  label?: string
  title: TextField
  prependLocationCode?: boolean
}

const createActiveUserState = (
  activeUserState: ActiveUserState | undefined,
  dataLayerEvent:
    | typeof DATA_LAYER.EVENT.SELECT_COMPANY
    | typeof DATA_LAYER.EVENT.SELECT_SUPPLIER,
  item: Company | Supplier
): ActiveUserState => {
  if (dataLayerEvent === DATA_LAYER.EVENT.SELECT_COMPANY) {
    return {
      ...activeUserState,
      selectedCustomerCompanyId: item.companyId,
      selectedSupplierCompanyId: (item as Company).suppliers?.[0]?.companyId,
      selectedOrderNumber: undefined,
    }
  }

  return {
    ...activeUserState,
    selectedSupplierCompanyId: item.companyId,
    selectedOrderNumber: undefined,
  }
}

const StoreSwitcherMenu: FC<StoreSwitcherMenuProps> = ({
  title,
  label,
  items = [],
  activeItem,
  dataLayerEvent = DATA_LAYER.EVENT.SELECT_COMPANY,
  sx,
  prependLocationCode = false,
  ...boxProps
}) => {
  const [breakpointM] = useBreakpoints('m')
  const wrapperRef = useRef<HTMLElement>(null)

  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { activeUserState } = useContext(ActiveUserStateContext)
  const [, setCookie] = useCookies([ACTIVE_USER_STATE])

  const [open, setOpen] = useState(false)

  useOutsideClick(wrapperRef, () => setOpen(false), !open)

  const close = useCallback(() => {
    setOpen(false)

    if (dataLayerEvent === DATA_LAYER.EVENT.SELECT_COMPANY) {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.HEADER_DROPDOWN,
        action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.CLOSE,
        company_name: label,
      })
    } else {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.HEADER_DROPDOWN,
        action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.CLOSE,
        supplier_name: label,
      })
    }
  }, [dataLayerEvent, label])

  const setActiveUserStateCookie = useCallback(
    (item: Company | Supplier) => {
      setCookie(
        ACTIVE_USER_STATE,
        encodeActiveUserStateCookie(
          createActiveUserState(activeUserState, dataLayerEvent, item)
        ),
        ACTIVE_USER_STATE_COOKIE_OPTIONS
      )
    },
    [activeUserState, dataLayerEvent, setCookie]
  )

  const menuItemOnClick = useCallback(
    (item: Company | Supplier) => {
      setActiveUserStateCookie(item)

      if (dataLayerEvent === DATA_LAYER.EVENT.SELECT_COMPANY) {
        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.HEADER_DROPDOWN,
          action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.SELECT,
          company_name: item.companyName,
        })
      } else {
        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.HEADER_DROPDOWN,
          action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.SELECT,
          supplier_name: item.companyName,
        })
      }

      close()

      window.location.reload()
    },
    [close, dataLayerEvent, setActiveUserStateCookie]
  )

  const listItems = useMemo(
    () =>
      !isImpersonated && // not impersonating
      items &&
      items.length >= 2 && (
        <MenuList>
          <HotjarSuppress>
            {items.map((item) => (
              <StoreSwitcherMenuListAction
                key={item.companyId}
                onClick={() => menuItemOnClick(item)}
                currentlySelected={item === activeItem}
              >
                {getCompanyDisplayName(item, prependLocationCode)}
              </StoreSwitcherMenuListAction>
            ))}
          </HotjarSuppress>
        </MenuList>
      ),
    [activeItem, isImpersonated, items, menuItemOnClick, prependLocationCode]
  )

  const openMenuList = useCallback(() => {
    if (listItems) {
      setOpen((currentOpen) => {
        if (!currentOpen) {
          if (dataLayerEvent === DATA_LAYER.EVENT.SELECT_COMPANY) {
            pushToDataLayer({
              [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.HEADER_DROPDOWN,
              action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.DROPDOWN,
              company_name: label,
            })
          } else {
            pushToDataLayer({
              [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.HEADER_DROPDOWN,
              action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.DROPDOWN,
              supplier_name: label,
            })
          }
        }

        return !currentOpen
      })
    }
  }, [dataLayerEvent, label, listItems])

  return (
    <Box
      ref={wrapperRef}
      sx={{
        flex: [1, 1, 'initial'],
        position: 'relative',
        '::after': {
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'block',
          content: '""',
          height: '100%',
          width: '1px',
          bg: 'background',
          opacity: 0.24,
        },
        ':last-child::after': {
          display: ['none', 'none', 'block'],
        },
        ...sx,
      }}
      {...boxProps}
    >
      <Box
        sx={{
          width: '100%',
          display: ['none', 'none', 'block'],
        }}
      >
        <ConditionalClickableWrapper
          onClick={openMenuList}
          condition={!!listItems}
          sx={{
            width: '100%',
            outline: 'none',
            borderBottom: '2px solid',
            borderBottomColor: open ? 'primary' : 'transparent',
          }}
        >
          <StoreSwitcherMenuAnchor
            title={title}
            label={label}
            hasMenu={!!listItems}
            open={open}
          />
        </ConditionalClickableWrapper>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: ['block', 'block', 'none'],
        }}
      >
        <ConditionalClickableWrapper
          onClick={openMenuList}
          condition={!!listItems}
          sx={{ outline: 'none', width: '100%' }}
        >
          <StoreSwitcherMenuAnchor
            title={title}
            label={label}
            hasMenu={!!listItems}
            open={open}
          />
        </ConditionalClickableWrapper>
      </Box>

      {listItems && (
        <>
          {breakpointM && <Menu open={open}>{listItems}</Menu>}

          {!breakpointM && (
            <MenuListStoreSwitcherModal
              isActive={open}
              onClose={close}
              title={getTextFieldValue(title)}
            >
              {listItems}
            </MenuListStoreSwitcherModal>
          )}
        </>
      )}
    </Box>
  )
}

export default StoreSwitcherMenu
