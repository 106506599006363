import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import { ITEMS_PER_COLUMN } from '../../../../../../constants/searchCategoryListConstants'
import { getTextFieldValue } from '../../../../../../helpers/layoutServiceHelper'
import { Category } from '../../../../../../hooks/services/graphql/useCategoryListService'
import { TextField } from '../../../../../../types/layoutService'
import PartSearchControlsSubcategoryListColumn from './PartSearchControlsSubcategoryListColumn'

interface PartSearchControlsSubcategoryListColumnsProps {
  title: TextField
  categoriesTree: Category[][]
  callback?: () => void
}

const PartSearchControlsSubcategoryListColumns: FC<
  PartSearchControlsSubcategoryListColumnsProps
> = ({ title, categoriesTree, callback }) => {
  const columnCount = Math.ceil(categoriesTree.length / ITEMS_PER_COLUMN)

  return (
    <Box
      sx={{
        paddingBottom: 5,
        marginBottom: -3,
        '> div': {
          display: 'flex',
          overflowX: 'hidden',
          width: '100%',
          flexWrap: 'wrap',
          '> div': {
            flexShrink: 0,
            listStyle: 'none',
            padding: 0,
            marginRight: 8,
            marginBottom: 3,
            width: '230px',
          },
        },
      }}
    >
      <Text as="h4" variant="heading4" marginBottom={1}>
        {getTextFieldValue(title)}
      </Text>

      {columnCount > 0 && (
        <div>
          {[...Array(columnCount)].map((_x, i) => {
            const end = (i + 1) * ITEMS_PER_COLUMN
            const start = end - ITEMS_PER_COLUMN

            return (
              <div key={i.toString()}>
                <PartSearchControlsSubcategoryListColumn
                  key={`${start}-${end}`}
                  categoriesTree={categoriesTree.slice(start, end)}
                  callback={callback}
                />
              </div>
            )
          })}
        </div>
      )}
    </Box>
  )
}

export default PartSearchControlsSubcategoryListColumns
