import useOnWindowScroll from '@rooks/use-on-window-scroll'
import { useTheme } from '@emotion/react'
import React, { FC, useCallback, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { PADDING } from '../../../../constants/containerConstants'
import { ICONS } from '../../../../constants/iconConstants'
import Button from '../../../atoms/Button/Button'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { scrollTo } from '../../../../helpers/dom'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import useApplicationHeaderHeight from '../../../../hooks/useApplicationHeaderHeight'

interface SearchResultsBackToTopButtonProps {
  wrapperTop: number
  label: string
}

const SearchResultsBackToTopButton: FC<SearchResultsBackToTopButtonProps> = ({
  wrapperTop,
  label,
}) => {
  const { space, breakpoints } = useTheme()
  const navigationSpacer = useApplicationHeaderHeight()
  const [visible, setVisible] = useState(false)
  const topSpace = navigationSpacer + space[3]

  const [breakpointM] = useBreakpoints('m')

  const scrollToTop = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.BACK_TO_TOP,
    })

    scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useOnWindowScroll(() => {
    setVisible(window.pageYOffset > wrapperTop - topSpace)
  })

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: [PADDING.MOBILE, PADDING.MOBILE, PADDING.DESKTOP],
        float: 'right',
        width: 'auto', // prevents container from flowing over content and makes content un-clickable
        height: 0,
        display: visible ? 'block' : 'none',
      }}
    >
      {breakpointM && ( // Desktop button
        <Button
          data-t-id="search-results-back-to-top-button"
          variant="primary"
          onClick={scrollToTop}
          sx={{
            transform: 'translateY(-100%)',
            marginRight: [
              PADDING.MOBILE,
              PADDING.MOBILE,
              PADDING.DESKTOP,
              PADDING.DESKTOP,
              PADDING.DESKTOP,
              `calc((100vw - ${breakpoints[4]}) / 2 + (${
                space[PADDING.DESKTOP]
              }px - 4px))`,
            ],
            ':active': {
              transform: 'translateY(-100%) scale(0.95)',
            },
          }}
        >
          <Text
            variant="buttonText"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '> svg': {
                ml: 2,
              },
            }}
            mr={-1}
          >
            {label}

            <IconWrapper icon={ICONS.ARROW_UP} size={4} />
          </Text>
        </Button>
      )}
      {!breakpointM && ( // Mobile button
        <Box sx={{ transform: 'translateY(-100%)', paddingRight: PADDING.MOBILE }}>
          <Flex
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="primary"
              onClick={scrollToTop}
              round
              icon={ICONS.ARROW_UP}
            />
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default SearchResultsBackToTopButton
