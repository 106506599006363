import React, { FC } from 'react'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import FindNearestDealerForm from '../../molecules/FindNearestDealerForm/FindNearestDealerForm'

interface FindNearestDealerBlockDatasource {
  title: TextField
  dealerLocatorPage: LinkField
  inputPlaceholder: TextField
}

interface FindNearestDealerBlockProps {
  dealerLocatorPage: LinkField
}

const FindNearestDealerBlock: FC<
  FindNearestDealerBlockProps & Datasource<FindNearestDealerBlockDatasource>
> = ({ datasource: { title, inputPlaceholder }, dealerLocatorPage }) => (
  <Box
    sx={{
      backgroundColor: 'gray.0',
      padding: [4, 4, 4, 5],
    }}
  >
    <FindNearestDealerForm
      title={title}
      dealerLocatorPage={dealerLocatorPage}
      inputPlaceholder={inputPlaceholder}
    />
  </Box>
)

export default withData(FindNearestDealerBlock)
