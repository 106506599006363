import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { FILTER_MASTER_GROUP_APPLICATION_ID } from '../../../../constants/searchConstants'
import {
  getApplicationFilterGroupPriority,
  getFilterOrPersistedFilterOptions,
} from '../../../../helpers/filterHelper'
import { FilterGroup, FilterOption } from '../../../../types/filterProps'
import PartFilterGroupApplication from './PartFilterGroupApplication'
import PartFilterGroup from './PartFilterGroup'

interface PartFilterGroupListProps {
  selectedApplicationFilterCount: number
  applicationFilterGroups?: FilterGroup[]
  filterGroups?: FilterGroup[]
  selectedFilterGroupIdState: [
    string | undefined,
    Dispatch<SetStateAction<string | undefined>>
  ]
  lastModifiedFilterGroupState: [
    FilterGroup | undefined,
    Dispatch<SetStateAction<FilterGroup | undefined>>
  ]
}

const PartFilterGroupList: FC<PartFilterGroupListProps> = ({
  selectedApplicationFilterCount,
  selectedFilterGroupIdState,
  lastModifiedFilterGroupState,
  applicationFilterGroups,
  filterGroups,
}) => {
  const selectedFilterGroupId = selectedFilterGroupIdState[0]
  const setSelectedFilterGroupId = selectedFilterGroupIdState[1]
  const lastModifiedFilterGroup = lastModifiedFilterGroupState[0]
  const setLastModifiedFilterGroup = lastModifiedFilterGroupState[1]

  const [filterGroupsA, filterGroupsB] = useMemo(() => {
    const applicationFilterPriority = getApplicationFilterGroupPriority(
      applicationFilterGroups
    )

    if (applicationFilterPriority) {
      return [
        filterGroups?.slice(0, applicationFilterPriority - 1),
        filterGroups?.slice(applicationFilterPriority - 1),
      ]
    }

    return [filterGroups, undefined]
  }, [applicationFilterGroups, filterGroups])

  const getPersistedFilterOptions = useCallback(
    (filterId: string, filterOptions: FilterOption[]) =>
      getFilterOrPersistedFilterOptions(
        lastModifiedFilterGroup,
        filterId,
        filterOptions
      ),
    [lastModifiedFilterGroup]
  )

  return (
    <>
      {filterGroupsA &&
        filterGroupsA.map((filter) => (
          <PartFilterGroup
            key={filter.id}
            active={filter.id === selectedFilterGroupId}
            toggleFilterGroupId={() =>
              setSelectedFilterGroupId(
                filter.id !== selectedFilterGroupId ? filter.id : ''
              )
            }
            onChange={() =>
              lastModifiedFilterGroup?.id !== filter.id &&
              setLastModifiedFilterGroup(filter)
            }
            {...filter}
            filterOptions={getPersistedFilterOptions(
              filter.id,
              filter.filterOptions
            )}
          />
        ))}

      <PartFilterGroupApplication
        active={FILTER_MASTER_GROUP_APPLICATION_ID === selectedFilterGroupId}
        applicationFilterGroups={applicationFilterGroups}
        selectedFilterCount={selectedApplicationFilterCount}
        lastModifiedFilterGroup={lastModifiedFilterGroup}
        toggleFilterGroupId={() =>
          setSelectedFilterGroupId(
            FILTER_MASTER_GROUP_APPLICATION_ID !== selectedFilterGroupId
              ? FILTER_MASTER_GROUP_APPLICATION_ID
              : ''
          )
        }
        onChange={(filter: FilterGroup) => {
          if (lastModifiedFilterGroup?.id !== filter.id) {
            setLastModifiedFilterGroup(filter)
          }
        }}
      />

      {filterGroupsB &&
        filterGroupsB.map((filter) => (
          <PartFilterGroup
            key={filter.id}
            active={filter.id === selectedFilterGroupId}
            toggleFilterGroupId={() =>
              setSelectedFilterGroupId(
                filter.id !== selectedFilterGroupId ? filter.id : ''
              )
            }
            onChange={() =>
              lastModifiedFilterGroup?.id !== filter.id &&
              setLastModifiedFilterGroup(filter)
            }
            {...filter}
            filterOptions={getPersistedFilterOptions(
              filter.id,
              filter.filterOptions
            )}
          />
        ))}
    </>
  )
}

export default PartFilterGroupList
