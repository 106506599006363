import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from 'theme-ui'
import { RootState } from '../../../reducers'
import Container from '../../atoms/Container/Container'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import { AnchorMenuItemData } from '../../../actions/anchorMenuActions'
import useWindowSize from '../../../hooks/useWindowSize'
import AnchorMenuItem from '../../atoms/AnchorMenuItem/AnchorMenuItem'
import { getDividerSx } from '../../atoms/Divider/Divider'

const sortTargetsByDomPosition = (
  targets?: AnchorMenuItemData[]
): AnchorMenuItemData[] | undefined => {
  if (targets) {
    return targets.sort((a, b) => {
      const elementA = document.getElementById(a.componentId) as HTMLElement | null
      const elementB = document.getElementById(b.componentId) as HTMLElement | null

      if (!elementA || !elementB) {
        return 0
      }

      if (elementA.offsetTop === elementB.offsetTop) {
        return elementA.offsetLeft < elementB.offsetLeft ? -1 : 1
      }

      return elementA.offsetTop < elementB.offsetTop ? -1 : 1
    })
  }

  return undefined
}

const AnchorMenu: FC = () => {
  const windowSize = useWindowSize()
  const targets = useSelector((state: RootState) => state.anchorMenu)

  const [anchors, setAnchors] = useState<AnchorMenuItemData[]>()

  useEffect(() => {
    if (targets?.length) setAnchors(sortTargetsByDomPosition(targets))
  }, [targets, windowSize])

  if (!anchors?.length) return null

  return (
    <Box
      sx={{
        display: ['none', 'none', 'block'],
        zIndex: '1',
        backgroundColor: 'white',
        ...getDividerSx('bottom', 'gray.1'),
      }}
    >
      <Container>
        <Box
          as="nav"
          sx={{
            ml: -3,
            mr: -3,
          }}
        >
          {anchors.map(({ componentTitle, ...anchor }) => (
            <AnchorMenuItem
              data-t-id={`anchor-menu-item-${anchor.componentId}`}
              key={anchor.componentId}
              targetId={anchor.componentId}
            >
              {componentTitle}
            </AnchorMenuItem>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default withClientSideRender(AnchorMenu)
