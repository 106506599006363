import { ComponentParams, Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC, ReactNode } from 'react'
import { Box } from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { RenderingProps } from '../../../types/layoutService'

interface ResponsiveWrapperProps {
  params: ComponentParams
  rendering: RenderingProps
}

interface DisplayOptions {
  showOnMobile: boolean
  showOnTablet: boolean
  showOnDesktop: boolean
}

interface PageEditorBoxProps {
  children: ReactNode
}
interface ResponsiveBoxProps {
  children: ReactNode
  displayOptions: DisplayOptions
}

const PageEditorBox: FC<PageEditorBoxProps> = ({ children }) => (
  <Box
    sx={{
      p: 2,
      background:
        'repeating-linear-gradient(45deg, #ffffff, #fff 10px, #fafafa 10px, #fafafa 20px)',
      border: '1px #ddd dotted',
    }}
  >
    {children}
  </Box>
)

const ResponsiveBox: FC<ResponsiveBoxProps> = ({ children, displayOptions }) => (
  <Box
    sx={{
      display: [
        displayOptions.showOnMobile ? 'block' : 'none',
        displayOptions.showOnTablet ? 'block' : 'none',
        displayOptions.showOnDesktop ? 'block' : 'none',
      ],
    }}
  >
    {children}
  </Box>
)

const ResponsiveWrapper: FC<ResponsiveWrapperProps> = ({ params, rendering }) => {
  const { pageEditing } = useSitecoreContext()

  const displayOptions: DisplayOptions = {
    showOnMobile: params?.showOnMobile === '1',
    showOnTablet: params?.showOnTablet === '1',
    showOnDesktop: params?.showOnDesktop === '1',
  }

  const BoxComponent = pageEditing ? PageEditorBox : ResponsiveBox

  return (
    <BoxComponent displayOptions={displayOptions}>
      <Placeholder name="jss-responsive-wrapper" rendering={rendering} />
    </BoxComponent>
  )
}

export default ResponsiveWrapper
