import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import { BannerParams, Datasource, Params } from '../../../types/layoutService'
import Banner, { DEFAULT_BANNER_VARIANT } from '../../atoms/Banner/Banner'
import { BannerContent } from '../../atoms/Banner/BannerContent'
import { BannerGradient } from '../../atoms/Banner/BannerGradient'
import BannerImage from '../../atoms/Banner/BannerImage'
import Container from '../../atoms/Container/Container'
import { HeroBannerDatasource } from '../../molecules/HeroBanner/HeroBanner'

const HeroBannerWithPlaceholder: FC<
  Datasource<HeroBannerDatasource> & Params<BannerParams>
> = ({
  datasource: { title, image, button },
  params: { bottomSpacing, variant = DEFAULT_BANNER_VARIANT, gradientType },
  rendering,
}) => (
  <>
    <Banner bottomSpacing={bottomSpacing}>
      <BannerImage
        variant={variant}
        imageField={image}
        sx={{
          position: ['absolute', 'absolute'],
          height: ['350px', '350px', '100%'],
          opacity: 0.6,
        }}
      />

      <BannerGradient
        gradientType={gradientType}
        visible
        sx={{ display: ['block', 'block'], width: '50%', zIndex: '-1' }}
      />

      <Container
        sx={{
          paddingTop: ['100px', '100px', '275px'],
          paddingBottom: [5, 5, 8],
          '.rich-text': {
            marginBottom: 3,
            h2: {
              fontSize: 2,
              lineHeight: 1.5,
              fontWeight: 'bold',
            },
          },
        }}
      >
        <BannerContent
          title={title}
          button={button}
          sx={{ paddingBottom: [5, 5, 8], width: ['80%', '80%', '50%'] }}
        />

        <Placeholder name="jss-hero-banner" rendering={rendering} />
      </Container>
    </Banner>
  </>
)

export default withData(HeroBannerWithPlaceholder)
