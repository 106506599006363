import React, { FC } from 'react'
import i18next from 'i18next'
import withData from '../../../enhancers/withData'
import { Datasource, LayoutServiceAssembly } from '../../../types/layoutService'
import BasicInformation from '../../molecules/BasicInformation/BasicInformation'
import BasicInformationDescription from '../../molecules/BasicInformation/BasicInformationDescription'
import BasicInformationAttribute from '../../molecules/BasicInformation/BasicInformationAttribute'
import BasicInformationBrandImage from '../../molecules/BasicInformation/BasicInformationBrandImage'

interface AssemblyBasicInformationProps {
  assemblyBasicInformation: LayoutServiceAssembly
}

const AssemblyBasicInformation: FC<Datasource & AssemblyBasicInformationProps> = ({
  assemblyBasicInformation,
}) => {
  const { description, assemblyId, brand } = assemblyBasicInformation ?? {}

  if (!assemblyBasicInformation) return null

  return (
    <BasicInformation>
      <BasicInformationDescription
        data-t-id="assembly-description"
        description={description}
      />

      <BasicInformationAttribute
        label={i18next.t('partLabels.referenceNr')}
        value={assemblyId}
        data-t-id="assembly-id"
      />

      <BasicInformationBrandImage
        src={brand?.image?.thumbnailImageUrl}
        alt={brand?.description}
        data-t-id="assembly-brand"
      />
    </BasicInformation>
  )
}

export default withData(AssemblyBasicInformation, {
  showMessageWhenPageEditing: true,
  checkDatasource: false,
})
