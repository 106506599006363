import React, { FC, useContext, useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import TitleBar from '../../molecules/TitleBar/TitleBar'
import { TextField } from '../../../types/layoutService'
import { SearchContext } from '../../../providers/SearchContextProvider'
import { AssemblySearchContext } from '../../../providers/AssemblySearchContextProvider'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import Container from '../../atoms/Container/Container'
import { PartSearchContext } from '../../../providers/PartSearchContextProvider'
import { DealerPartSearchContext } from '../../../providers/DealerPartSearchContextProvider'
import { i18nextZeroPluralization } from '../../../helpers/translationHelper'

interface SearchResultsTitleBarProps {
  noSearchResultsDescription?: TextField
}

const SearchResultsTitleBar: FC<SearchResultsTitleBarProps> = ({
  noSearchResultsDescription,
}) => {
  const { mainNavigation, search } = useTheme()

  const { searchString, category, subcategory } = useContext(SearchContext)
  const { fetching: fetchingParts, statistics: partStatistics } =
    useContext(PartSearchContext)
  const {
    fetching: fetchingAssemblies,
    totalCount: assemblyTotalCount,
    assemblies,
  } = useContext(AssemblySearchContext)
  const { fetching: fetchingDealerParts, totalCount: dealerPartTotalCount } =
    useContext(DealerPartSearchContext)

  const resultCount =
    (partStatistics?.totalCount || 0) +
    (assemblyTotalCount || 0) +
    (dealerPartTotalCount || 0)

  const searchTitle = useMemo(
    () =>
      i18nextZeroPluralization('searchLabels.results', resultCount, {
        searchString,
      }),
    // all missing dependencies are available on page load and will not change during lifecycle
    [resultCount]
  )

  if (
    (fetchingParts || fetchingAssemblies || fetchingDealerParts) &&
    !assemblies?.length
  ) {
    return null
  }

  if (partStatistics?.totalCount === undefined || assemblyTotalCount === undefined)
    return null

  return (
    <>
      <Box
        sx={{
          backgroundColor: search.titleBarBackgroundColor,
        }}
      >
        <Container>
          <TitleBar
            title={subcategory?.description || category?.description || searchTitle}
            data-t-id="search-results-title-bar"
            sx={{
              ...(noSearchResultsDescription && resultCount === 0
                ? { pb: [3, 3, 3, 5] }
                : {}),
              pt: 0,
              color: mainNavigation.textColor,
            }}
          />
        </Container>
      </Box>

      <Container>
        {noSearchResultsDescription && resultCount === 0 && (
          <Text
            as="p"
            variant="bodyContent"
            sx={{
              color: 'gray.2',
              lineHeight: 'heading2',
              paddingTop: [3, 3, 3, 5],
              mb: [-2, -2, -2, -5],
            }}
          >
            {getTextFieldValue(noSearchResultsDescription)}
          </Text>
        )}
      </Container>
    </>
  )
}

export default SearchResultsTitleBar
