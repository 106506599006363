import React, { FC } from 'react'
import { FlexProps } from 'theme-ui'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import Animate from '../../../atoms/Animate/Animate'
import PartsNavigationPanel from './PartsNavigationPanel'

interface PartsNavigationPanelAnimatedProps extends Omit<FlexProps, 'css'> {
  active?: boolean
}

const PartsNavigationPanelAnimated: FC<PartsNavigationPanelAnimatedProps> = ({
  active,
  children,
  ...props
}) => {
  const [breakpointL] = useBreakpoints('l')

  return (
    <Animate
      from={{
        width: 0,
        left: 0,
      }}
      enter={{
        width: breakpointL ? '360px' : '100%',
        left: 0,
      }}
      active={active}
    >
      <PartsNavigationPanel {...props}>{children}</PartsNavigationPanel>
    </Animate>
  )
}

export default PartsNavigationPanelAnimated
