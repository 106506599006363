import { DEFAULT_CULTURE_CODE } from '../constants/userConstants'
import { LinkField } from '../types/layoutService'

const formatAnchor = (anchor: string): string =>
  anchor.startsWith('#') ? anchor : `#${anchor}`

export const resolveLocalizedUrl = (field?: LinkField, cultureCode?: string) =>
  resolveUrl(field)?.replace('{cultureCode}', cultureCode ?? DEFAULT_CULTURE_CODE)

export const resolveUrl = (field?: LinkField): string | undefined => {
  if (!field) return undefined

  switch (field?.linkType) {
    case 'anchor':
      return field.anchor ? formatAnchor(field.anchor) : undefined
    default:
      return field.url || undefined
  }
}

export const resolveTarget = (field?: LinkField): string | undefined =>
  field?.target || undefined

export const resolveText = (field?: LinkField): string | undefined => {
  if (!field) return undefined

  switch (field.linkType) {
    case 'internal':
      return field.text || field.targetItem?.name || undefined
    default:
      return field.text || undefined
  }
}
