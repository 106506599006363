import React, { FC, useCallback } from 'react'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../../../helpers/layoutServiceHelper'
import { TextField } from '../../../../../types/layoutService'
import Button from '../../../../atoms/Button/Button'

interface SidePanelProps {
  label: TextField
  numberOfResults: number
  onClose: () => void
}

const MyFleetListSidePanelFooterConfirmButton: FC<SidePanelProps> = ({
  label,
  numberOfResults,
  onClose,
}) => {
  const handleClick = useCallback(() => {
    onClose()

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.FILTER,
      filter_action: DATA_LAYER.CUSTOM_DIMENSION.FILTER_ACTION.ACTIVATE,
      filter_type: '',
      filter_name: '',
      filter_value: '',
    })
  }, [onClose])

  return (
    <Button
      variant="primary"
      sx={{
        flexGrow: 1,
        '> div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      onClick={handleClick}
    >
      {`${getTextFieldValue(label)}${
        numberOfResults > 0 ? ` (${numberOfResults})` : ''
      }`}
    </Button>
  )
}

export default MyFleetListSidePanelFooterConfirmButton
