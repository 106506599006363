import { MutableRefObject, useCallback, useEffect, useState } from 'react'
import { runOnWindow } from '../helpers/dom'

const useOutsideClick = (
  ref: MutableRefObject<HTMLElement | null | undefined>,
  onOutsideClick: () => void,
  disabled = false
) => {
  const [mouseDownTarget, setMouseDownTarget] = useState<EventTarget | null>(null)

  const handleMouseDown = useCallback((event: MouseEvent) => {
    event.stopPropagation()

    setMouseDownTarget(event.target)
  }, [])

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()

      if (
        ref?.current &&
        !ref.current.contains(mouseDownTarget as Node) &&
        !ref.current.contains(event.target as Node)
      ) {
        onOutsideClick()
      }

      setMouseDownTarget(null)
    },
    [onOutsideClick, ref, mouseDownTarget]
  )

  useEffect(() => {
    if (!disabled) {
      runOnWindow(() => {
        window.addEventListener('mousedown', handleMouseDown)
        window.addEventListener('mouseup', handleMouseUp)
      })
    }

    return () => {
      if (!disabled) {
        runOnWindow(() => {
          window.removeEventListener('mousedown', handleMouseDown)
          window.removeEventListener('mouseup', handleMouseUp)
        })
      }
    }
  }, [disabled, handleMouseDown, handleMouseUp])
}

export default useOutsideClick
