import i18next from 'i18next'
import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import QuickOrderByFile from './QuickOrder/QuickOrderByFile'
import QuickOrderByPartNumber from './QuickOrder/QuickOrderByPartNumber'
import { CommerceMessage, CommerceOrderItem } from '../../../types/commerceApi'
import { tooltipRegister } from '../../../actions/tooltipActions'
import { TOOLTIP_QUICK_ORDER } from '../../../constants/tooltipConstants'
import { ICONS } from '../../../constants/iconConstants'
import { PART_SUPERSEDED } from '../../../constants/partConstants'
import FormInputLabel from '../../atoms/FormControls/FormInputLabel'

interface ShoppingBasketQuickOrderProps {
  fileUploadUrl?: string
  onOrder: (orderItem: CommerceOrderItem) => void
}

const ShoppingBasketQuickOrder: FC<ShoppingBasketQuickOrderProps> = ({
  onOrder,
  fileUploadUrl,
}) => {
  const dispatch = useDispatch()

  const triggerTooltip = useCallback(
    (orderItem: CommerceOrderItem, message?: CommerceMessage) => {
      if (message?.code === PART_SUPERSEDED) {
        dispatch(
          tooltipRegister({
            name: TOOLTIP_QUICK_ORDER,
            data: {
              icon: ICONS.CHECK,
              label: i18next.t(
                'shoppingLabels.tooltips.superSededSuccessfullyAdded',
                {
                  superSededFrom: message?.argsObj?.superSededFrom,
                  superSededTo: message?.argsObj?.superSededTo,
                }
              ),
            },
          })
        )
      } else {
        dispatch(
          tooltipRegister({
            name: TOOLTIP_QUICK_ORDER,
            data: {
              icon: ICONS.CHECK,
              label: i18next.t('shoppingLabels.tooltips.quickOrderSuccess', {
                partNumber: orderItem.partNumber,
              }),
            },
          })
        )
      }

      return onOrder?.(orderItem)
    },
    [dispatch, onOrder]
  )

  return (
    <>
      <FormInputLabel htmlFor="ShoppingBasketQuickOrder">
        {i18next.t('shoppingLabels.addProduct')}
      </FormInputLabel>

      <QuickOrderByPartNumber
        id="ShoppingBasketQuickOrder"
        location={TOOLTIP_QUICK_ORDER}
        onOrder={triggerTooltip}
        buttonVariant="infinite"
      />

      <QuickOrderByFile fileUploadUrl={fileUploadUrl} />
    </>
  )
}

export default ShoppingBasketQuickOrder
