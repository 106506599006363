import { useTheme } from '@emotion/react'
import React, { FC, useState } from 'react'
import { Text } from 'theme-ui'
import { FooterLegalLink as FooterLegalLinkProps } from '../../../hooks/services/graphql/useFooterLegalLinkListService'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import SidePanel from '../SidePanel/SidePanel'
import SidePanelBody from '../SidePanel/SidePanelBody'

const FooterLegalLinkMessage: FC<FooterLegalLinkProps> = ({
  description,
  message,
}) => {
  const { transitions } = useTheme()
  const [sidePanel, setSidePanel] = useState(false)

  return (
    <>
      <ClickableWrapper
        onClick={() => {
          setSidePanel(true)
        }}
        sx={{
          fontSize: '0',
          color: 'textLight',
          fontWeight: 'normal',
          textDecoration: 'none',
          opacity: 0.64,
          outline: 'none',
          transitionDuration: transitions[1],
          transitionProperty: 'opacity',
          ':hover, :focus, :active': {
            opacity: 1,
          },
          '::after': {
            position: 'relative',
            content: '"|"',
            display: 'inline',
            fontSize: '1',
            color: 'textLight',
            fontWeight: 'normal',
            mx: 2,
          },
          '&:last-child': {
            '::after': { display: 'none' },
          },
        }}
      >
        {description}
      </ClickableWrapper>

      <SidePanel
        active={sidePanel}
        onClose={() => setSidePanel(false)}
        title={description}
      >
        <SidePanelBody>
          <Text as="p" variant="bodyContent" color="gray.2">
            {message}
          </Text>
        </SidePanelBody>
      </SidePanel>
    </>
  )
}

export default FooterLegalLinkMessage
