import {
  AnchorMenuItemData,
  PushToAnchorMenuActions,
} from '../actions/anchorMenuActions'
import {
  PUSH_TO_ANCHOR_MENU,
  REMOVE_FROM_ANCHOR_MENU,
} from '../constants/anchorMenuConstants'

const anchorMenuReducer = (
  state: AnchorMenuItemData[] = [],
  action: PushToAnchorMenuActions
): AnchorMenuItemData[] => {
  switch (action.type) {
    case PUSH_TO_ANCHOR_MENU: {
      const componentIndex = state.findIndex(
        (component) => component.componentId === action.payload.componentId
      )

      if (componentIndex !== -1) return state

      return [...state, { ...action.payload }]
    }
    case REMOVE_FROM_ANCHOR_MENU: {
      return [
        ...state.filter(
          ({ componentId }) => componentId !== action.payload.componentId
        ),
      ]
    }
    default: {
      return state
    }
  }
}

export default anchorMenuReducer
