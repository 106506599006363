import React from 'react'
import { Box, Text } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { ICONS } from '../../../constants/iconConstants'
import IconWrapper from '../IconWrapper/IconWrapper'
import { Dealer } from '../../../types/dealerServiceTypes'
import { joinStringsWithDelimiter } from '../../../helpers/stringHelper'
import TableCell from '../../molecules/Table/TableCell'
import TableRow from '../../molecules/Table/TableRow'
import Table from '../../molecules/Table/Table'
import ClickableWrapper from '../ClickableWrapper/ClickableWrapper'

interface PartnerTileProps {
  partner?: Dealer
  removePartner?: (partner: Dealer) => void
}

const PartnerTile: React.FC<PartnerTileProps> = ({ partner, removePartner }) => {
  const getAddress = () => {
    const addressLine = joinStringsWithDelimiter(
      [
        partner?.address?.addressLine1,
        partner?.address?.addressLine2,
        partner?.address?.addressLine3,
      ],
      ', '
    )

    const postalCodeAndCity = joinStringsWithDelimiter([
      partner?.address?.postalCode,
      partner?.address?.city,
    ])

    return `${addressLine}, ${postalCodeAndCity}`
  }

  return (
    <Box
      sx={{
        padding: 3,
        paddingRight: removePartner ? 4 : 3,
        border: '1px solid',
        borderColor: COLORS.MEDIUM_GRAY,
        borderRadius: 4,
        marginBottom: 3,
        '&:last-child': {
          marginBottom: 0,
        },
        position: 'relative',
      }}
    >
      {removePartner && (
        <Box sx={{ position: 'absolute', right: 2, top: 2 }}>
          <ClickableWrapper
            onClick={() => {
              if (partner && removePartner) {
                removePartner(partner)
              }
            }}
          >
            <IconWrapper icon={ICONS.TRASH} color={COLORS.DARK} size={4} />
          </ClickableWrapper>
        </Box>
      )}

      <Table columnSizes={[0.1, 0.9]}>
        <TableRow sx={{ borderBottom: 0, paddingRight: 4 }}>
          <TableCell sx={{ paddingY: [0, 0, 1, 0], paddingRight: 0 }}>
            <IconWrapper icon={ICONS.TRUCK} color={COLORS.GRAY} />
          </TableCell>
          <TableCell sx={{ paddingY: [0, 0, 1, 0], verticalAlign: 'middle' }}>
            <Text
              variant="smallText"
              color={COLORS.DARK}
              sx={{ fontWeight: 'bold' }}
            >
              {partner?.name}
            </Text>
          </TableCell>
        </TableRow>
        <TableRow sx={{ borderBottom: 0 }}>
          <TableCell sx={{ paddingY: [0, 0, 1, 0], paddingRight: 0 }}>
            <IconWrapper icon={ICONS.MARKER} color={COLORS.GRAY} />
          </TableCell>
          <TableCell sx={{ paddingY: [0, 0, 1, 0], verticalAlign: 'middle' }}>
            <Text variant="smallText" color={COLORS.GRAY}>
              {getAddress()}
            </Text>
          </TableCell>
        </TableRow>
      </Table>
    </Box>
  )
}

export default PartnerTile
