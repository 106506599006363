import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Flex } from 'theme-ui'
import { useTheme } from '@emotion/react'
import withData from '../../../enhancers/withData'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'
import { Datasource, LinkField, ReferenceField } from '../../../types/layoutService'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'

interface QuickLinksDatasource {
  firstButtonIcon: ReferenceField
  firstButtonLink: LinkField
  secondButtonIcon: ReferenceField
  secondButtonLink: LinkField
  thirdButtonIcon: ReferenceField
  thirdButtonLink: LinkField
}

const QuickLinks: FC<Datasource<QuickLinksDatasource>> = ({
  datasource: {
    firstButtonIcon,
    firstButtonLink,
    secondButtonIcon,
    secondButtonLink,
    thirdButtonIcon,
    thirdButtonLink,
  },
}) => {
  const parentRef = useRef<HTMLElement>(null)
  const { space } = useTheme()
  const spacing = space[4] / 2

  const buttonList = [
    { icon: firstButtonIcon, link: firstButtonLink },
    { icon: secondButtonIcon, link: secondButtonLink },
    { icon: thirdButtonIcon, link: thirdButtonLink },
  ]

  const measureClick = useCallback(
    (link: LinkField) =>
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
        call_to_action_name: link.text,
        call_to_action_type: DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
        target_url: link.url,
      }),
    []
  )

  useEffect(() => {
    if (!parentRef.current) return

    const parentElement = parentRef.current
    const childElements = [...parentElement.children]
    const childWidths = childElements.map((child) => child.clientWidth)
    const maxChildWidth = Math.max(...childWidths)

    childElements.forEach((childElement) =>
      childElement.setAttribute('style', `min-width: ${maxChildWidth}px`)
    )
  }, [parentRef])

  return (
    <Flex
      sx={{
        justifyContent: ['start', 'center'],
      }}
    >
      <Flex
        ref={parentRef}
        sx={{
          justifyContent: ['start', 'center'],
          flexDirection: ['column', 'row'],
          flexWrap: ['nowrap', 'wrap'],
          marginBottom: -4,
          marginX: [0, -spacing],
        }}
      >
        {buttonList.map(({ icon, link }) => (
          <SitecoreButton
            key={link.url}
            variant="primary"
            iconField={icon}
            linkField={link}
            marginBottom={4}
            marginX={[0, spacing]}
            onClick={() => measureClick(link)}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default withData(QuickLinks)
