import React, { FC, useContext } from 'react'
import { Box, Flex } from 'theme-ui'
import { loggedIn } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import { SkeletonComponent } from '../../../types/layoutProps'
import Image from '../../atoms/Image/Image'
import Skeleton from '../../atoms/Skeleton/Skeleton'

interface CardBrandImageProps {
  imageUrl?: string
  name?: string
}

const CardBrandImage: FC<CardBrandImageProps> & SkeletonComponent = ({
  imageUrl,
  name,
}) => {
  const { user } = useContext(UserContext)

  if (!imageUrl) return null

  return (
    <Flex
      sx={{
        flexShrink: 1,
        alignItems: 'center',
        height: loggedIn(user) ? '32px' : 'auto',
      }}
    >
      <Image
        src={imageUrl}
        alt={name}
        title={name}
        sx={{
          maxWidth: 64,
          maxHeight: 32,
        }}
      />
    </Flex>
  )
}

const CardBrandImageSkeleton: FC = () => (
  <Skeleton>
    <Box
      sx={{
        height: '32px',
        width: '64px',
      }}
    />
  </Skeleton>
)

CardBrandImage.Skeleton = CardBrandImageSkeleton

export default CardBrandImage
