import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import {
  Datasource,
  Flag,
  Params,
  SliderVariant,
  TextField,
} from '../../../types/layoutService'
import usePartAlternativePartsService from '../../../hooks/services/graphql/usePartAlternativePartsService'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { PardCardLayoutServiceData } from '../../molecules/Card/PartCard/PartCard'
import { pushEventToDataLayer } from '../../../helpers/analyticsHelper'
import { EVENT_CATEGORIES, EVENTS } from '../../../constants/analyticsConstants'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../providers/WildcardPageContextProvider'
import { PartContext } from '../../../types/sitecoreContextProps'
import PartCardListResponsive from '../CardList/PartCardListResponsive'

interface PartAlternativesDatasource {
  title: TextField
}

interface PartAlternativePartsParams {
  variant: SliderVariant
  topSpacing: Flag
  bottomSpacing: Flag
}

const clickHandler = () => {
  pushEventToDataLayer(EVENTS.LINK_CLICK, EVENT_CATEGORIES.LINK_CLICK)
}

const PartAlternativeParts: FC<
  Datasource<PartAlternativesDatasource> &
    PardCardLayoutServiceData &
    Params<PartAlternativePartsParams>
> = ({
  datasource: { title },
  rendering: { uid },
  params: { variant, topSpacing, bottomSpacing },
  partPage,
}) => {
  const { part: contextPart } = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )
  const [fetching, alternativeParts] = usePartAlternativePartsService(
    contextPart?.partNumber
  )

  if (fetching || !alternativeParts?.length) return null

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid}>
      <PartCardListResponsive
        title={getTextFieldValue(title)}
        variant={variant}
        partList={alternativeParts}
        partPage={partPage}
        topSpacing={topSpacing === '1'}
        bottomSpacing={bottomSpacing === '1'}
        onCardClick={() => clickHandler()}
        dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_ALTERNATIVE_PARTS}
      />
    </AnchorMenuTarget>
  )
}

export default withData(PartAlternativeParts, { showMessageWhenPageEditing: true })
