import { useTheme } from '@emotion/react'
import i18next from 'i18next'
import React, { Children, FC, useEffect, useRef, useState } from 'react'
import { Box, BoxProps, Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import ClickableWrapper from '../ClickableWrapper/ClickableWrapper'
import IconWrapper from '../IconWrapper/IconWrapper'
import ListBase from '../ListBase/ListBase'

interface CollapsibleListProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  animate?: boolean
  itemsVisible?: number
  buttonAlignment?: 'start' | 'end'
}

const CollapsibleList: FC<CollapsibleListProps> = ({
  animate = true,
  itemsVisible = 4,
  buttonAlignment = 'start',
  children,
  ...boxProps
}) => {
  const { transitions } = useTheme()
  const [showHidden, setShowHidden] = useState(false)
  const [expandHeight, setExpandHeight] = useState(0)
  const hiddenItemsListRef = useRef<HTMLElement>(null)

  const itemsList = Children.toArray(children)
  const calculatedItemsVisible =
    itemsList.length <= itemsVisible ? itemsVisible : itemsVisible - 1
  const visibleItems = itemsList.slice(0, calculatedItemsVisible)
  const hiddenItems = itemsList.slice(calculatedItemsVisible, itemsList.length)

  useEffect(() => {
    if (hiddenItemsListRef?.current) {
      setExpandHeight(hiddenItemsListRef.current?.getBoundingClientRect().height)
    }
  }, [hiddenItemsListRef, hiddenItems])

  if (!itemsList.length) return null

  if (itemsList.length <= itemsVisible) {
    return <ListBase {...boxProps}>{visibleItems}</ListBase>
  }

  return (
    <ListBase {...boxProps}>
      <li data-type="visible">
        <ListBase>{visibleItems}</ListBase>
      </li>

      <Box
        as="li"
        data-type="hidden"
        sx={{
          height: showHidden ? expandHeight : 0,
          overflow: 'hidden',
          transitionProperty: animate ? 'height' : 'none',
          transitionDuration: transitions[1],
        }}
      >
        <ListBase ref={hiddenItemsListRef}>{hiddenItems}</ListBase>
      </Box>

      <Text
        as="li"
        variant="bodySmall"
        sx={{
          display: showHidden ? 'none' : 'flex',
          color: 'primary',
          lineHeight: 'menuList',
          textAlign: 'left',
          width: '100%',
          justifyContent: `flex-${buttonAlignment}`,
        }}
      >
        <ClickableWrapper onClick={() => setShowHidden(true)}>
          {i18next.t('commonLabels.showMore')}

          <IconWrapper icon={ICONS.ARROW_DOWN} size={3} />
        </ClickableWrapper>
      </Text>
    </ListBase>
  )
}

export default CollapsibleList
