import i18next from 'i18next'
import React, { FC } from 'react'
import PartLine from '../../../../molecules/PartLine/PartLine'
import PartLineTitle from '../../../../molecules/PartLine/PartLineTitle'

interface PartListHeadingProps {}

const PartListHeading: FC<PartListHeadingProps> = () => (
  <PartLine
    sx={{
      display: ['none', 'none', 'grid'],
      gridTemplateColumns: '1fr 1.5fr 1fr 1fr 1fr 1.25fr 1fr 1fr',
      paddingBlock: '12px',
      paddingInline: 4,
      border: '1px solid',
      borderBlockEnd: 0,
      borderColor: 'gray.1',
    }}
  >
    <PartLineTitle>{i18next.t('commonLabels.product')}</PartLineTitle>

    <PartLineTitle />

    <PartLineTitle>{i18next.t('commonLabels.brand')}</PartLineTitle>

    <PartLineTitle>{i18next.t('stockLabels.availability')}</PartLineTitle>

    <PartLineTitle>{i18next.t('partSalesLabels.unitPrice')}</PartLineTitle>

    <PartLineTitle>{i18next.t('commonLabels.quantity')}</PartLineTitle>

    <PartLineTitle sx={{ justifySelf: 'end' }}>
      {i18next.t('partSalesLabels.totalPrice')}
    </PartLineTitle>
  </PartLine>
)

export default PartListHeading
