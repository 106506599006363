import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import { getPartAttributesWithPackagingQuantity } from '../../../helpers/graphPartHelper'
import { CommerceSpecification } from '../../../types/commerceApi'
import TableCell from '../../molecules/Table/TableCell'
import TableBody from '../../molecules/Table/TableBody'
import TableRow from '../../molecules/Table/TableRow'
import Table from '../../molecules/Table/Table'
import {
  CLASS_PROPERTY_TYPE_BOOLEAN_FALSE,
  CLASS_PROPERTY_TYPE_BOOLEAN_TRUE,
} from '../../../constants/searchConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'

interface PartSpecificationsTableProps {
  partAttributes?: CommerceSpecification['partAttributes']
  packaging?: CommerceSpecification['packaging']
}

export const PartSpecificationsTable: FC<PartSpecificationsTableProps> = ({
  partAttributes,
  packaging,
}) => {
  const sortedPartAttributes = useMemo(
    () =>
      partAttributes && partAttributes.length
        ? partAttributes.sort((a, b) => a.priority - b.priority)
        : [],
    [partAttributes]
  )

  const attributes = useMemo(
    () => getPartAttributesWithPackagingQuantity(sortedPartAttributes, packaging),
    [sortedPartAttributes, packaging]
  )

  if (!attributes?.length) return null

  return (
    <Table columnSizes={[1, 1]}>
      <TableBody>
        {attributes.map(({ description, value }, i) => {
          const displayIcon =
            value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE ||
            value === CLASS_PROPERTY_TYPE_BOOLEAN_FALSE
          const icon =
            ICONS[value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE ? 'CHECK' : 'CROSS']
          const iconStyles =
            value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE
              ? { marginLeft: '-9px' }
              : { marginLeft: '-6px' }

          return (
            <TableRow key={i.toString()}>
              <TableCell>
                <Text
                  as="strong"
                  variant="bodyContent"
                  color="gray.3"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {description}
                </Text>
              </TableCell>

              <TableCell
                color="gray.2"
                sx={{
                  textAlign: ['right', 'right', 'left'],
                }}
              >
                {displayIcon && (
                  <IconWrapper
                    style={iconStyles}
                    icon={icon}
                    color="text"
                    size={value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE ? 5 : 4}
                  />
                )}

                {!displayIcon && (
                  <Text as="span" variant="bodyContent">
                    {value}
                  </Text>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default PartSpecificationsTable
