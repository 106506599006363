import { ComponentParams } from '@sitecore-jss/sitecore-jss-react'
import { ColumnFractions } from '../types/columnLayoutProps'

export const getWidthFractions = (
  columnName: string,
  renderingParameters: ComponentParams,
  defaultColumnFractions: { left: number; right: number }
): ColumnFractions => {
  const columnFractions: ColumnFractions = defaultColumnFractions

  Object.keys(renderingParameters).forEach((key) => {
    if (key === columnName) {
      const columnWidth = renderingParameters[key]

      if (columnWidth !== '') {
        const fractions = columnWidth.split('-')

        if (fractions.length === 2) {
          columnFractions.left = Number(fractions[0])
          columnFractions.right = Number(fractions[1])

          return columnFractions
        }
      }
    }

    return columnFractions
  })

  return columnFractions
}
