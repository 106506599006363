import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Box, Flex, Text } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import i18next from 'i18next'
import { useQuery } from '@tanstack/react-query'
import fetchDafCompanyVehicles, {
  DafCompanyVehiclesData,
  Vehicle,
} from '../../../services/rest/ecommerce/dafCompanyVehicles'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { UserContext } from '../../../providers/UserProvider'
import SpinnerWithLabel from '../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import Paginated from '../Paginated/Paginated'
import {
  toggleAddVehiclePanel,
  toggleMyFleetFilterPanel,
} from '../../../actions/dafCompanyVehicleActions'
import { LinkField, PaginationInfo, TextField } from '../../../types/layoutService'
import {
  MY_FLEET_LIST_SORT_FIRST_REGISTRATION_DATE,
  MY_FLEET_LIST_SORT_MAIN_SERVICE_DEALER,
  MY_FLEET_LIST_SORT_MILEAGE,
  MY_FLEET_LIST_SORT_SERVICE_COVERAGE,
  MY_FLEET_LIST_SORT_VEHICLE_ACTION_STATUS,
  MY_FLEET_LIST_SORT_VEHICLE_MAINTENANCE_URGENCY_STATUS,
  VEHICLES_PER_PAGE,
} from '../../../constants/dafCompanyVehicleConstants'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import { URL_PAGE_PARAM } from '../../../constants/urlConstants'
import { scrollTo } from '../../../helpers/dom'
import MyFleetListCards from './MyFleetListCards/MyFleetListCards'
import { BREAKPOINT_S } from '../../../constants/themeConstants'
import useBreakpoints from '../../../hooks/useBreakpoints'
import MyFleetListTable from './MyFleetListTable/MyFleetListTable'
import SidePanelBody from '../SidePanel/SidePanelBody'
import SidePanel from '../SidePanel/SidePanel'
import { RootState } from '../../../reducers'
import MyFleetListSortingList from './MyFleetListSorting/MyFleetListSortingList'
import {
  filterVehiclesByActiveFilters,
  findVehiclesByTextInput,
  sortByFirstRegistrationDate,
  sortByMainServiceDealer,
  sortByMileage,
  sortByServiceCoveragePriority,
  sortByVehicleActionStatus,
  sortByVehicleMaintenanceUrgencyStatus,
} from '../../../helpers/MyFleetListSortHelper'
import { MyFleetListContext } from '../../../providers/MyFleetListContextProvider'
import MyFleetListSidePanelFooter from './MyFleetListSidePanels/MyFleetListSidePanelFooter/MyFleetListSidePanelFooter'
import MyFleetListFiltersWrapper from './MyFleetListFilters/MyFleetListFiltersWrapper'
import MyFleetListFiltersList from './MyFleetListFilters/MyFleetListFiltersList'
import EditServicePlanFormPanel from '../SidePanel/EditServicePlanFormPanel/EditServicePlanFormPanel'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import fetchDafCompanyDetail, {
  DafVehicleDetailRequest,
} from '../../../services/rest/ecommerce/dafVehicleDetail'
import MileageSettlementInputFormPanel from '../SidePanel/MileageSettlementInputFormPanel/MileageSettlementInputFormPanel'
import RequestMaintenanceFormPanel from '../SidePanel/RequestMaintenanceFormPanel/RequestMaintenanceFormPanel'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../types/userProps'
import EndMaintenancePlanPanel from '../SidePanel/EndMaintenancePlanPanel/EndMaintenancePlanPanel'
import BannerGeneric from '../../atoms/Banner/BannerGeneric'
import AddVehicleSidePanel from '../SidePanel/AddVehicle/AddVehicle'
import RequestMaintenanceAppointmentPanel from '../SidePanel/RequestMaintenanceAppointmentPanel/RequestMaintenanceAppointmentPanel'
import DeleteVehiclePanel from '../SidePanel/DeleteVehiclePanel/DeleteVehiclePanel'
import EditVehiclePanel from '../SidePanel/EditVehiclePanel/EditVehiclePanel'
import RequestQuotePanel from '../SidePanel/RequestQuotePanel/RequestQuotePanel'
import { ServicesProvider } from '../../../providers/ServicesProvider'
import {
  DeleteVehicleSidepanelDatasource,
  RequestMaintenanceAppointmentSidepanelDatasource,
  RequestNewMultisupportQuoteSidepanelDatasource,
} from '../../../types/VehicleDetailGenericComponentDataProps'
import { hasRole } from '../../../helpers/userHelper'
import { USER_ROLES_VEHICLE_MANAGEMENT } from '../../../constants/userConstants'
import MyFleetAddVehicleButton from './MyFleetAddVehicleButton/MyFleetAddVehicleButton'
import { useTheme } from '@emotion/react'
import { vehicleHasMaintenancePlan } from '../../../helpers/vehicleHelper'
import { COMMERCE_SITE_CONTEXT } from '../../../constants/environmentConstants'
import { ICONS } from '../../../constants/iconConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'

interface MyFleetListWrapperForContextProps {
  editServicePlanMessageOnSuccess: string | undefined
  inputMileageThankYouMessageOnSuccess: string | undefined
  noRequestForMaintenanceMessage: string | undefined
  vehicleDetailLink: LinkField
  requestMaintenancePlanLinkDatasource: LinkField
  requestMaintenancePlanBannerTitleDatasource: TextField
  requestMaintenancePlanBannerSubtitleDatasource: TextField
  requestNewMultisupportQuoteSidepanelDatasource:
    | RequestNewMultisupportQuoteSidepanelDatasource
    | undefined
  requestMaintenanceAppointmentSidepanelDatasource:
    | RequestMaintenanceAppointmentSidepanelDatasource
    | undefined
  deleteVehicleSidepanelDatasource: DeleteVehicleSidepanelDatasource | undefined
}

const MyFleetListWrapperForContext = ({
  vehicleDetailLink,
  editServicePlanMessageOnSuccess,
  inputMileageThankYouMessageOnSuccess,
  noRequestForMaintenanceMessage,
  requestNewMultisupportQuoteSidepanelDatasource,
  requestMaintenanceAppointmentSidepanelDatasource,
  deleteVehicleSidepanelDatasource,
  requestMaintenancePlanLinkDatasource,
  requestMaintenancePlanBannerTitleDatasource,
  requestMaintenancePlanBannerSubtitleDatasource,
}: MyFleetListWrapperForContextProps) => {
  const [dafCompanyVehicles, setDafCompanyVehicles] =
    useState<null | DafCompanyVehiclesData>(null)
  const [filteredDafCompanyVehicles, setFilteredDafCompanyVehicles] = useState<
    Vehicle[]
  >([])
  const [paginatedDafCompanyVehicles, setPaginatedDafCompanyVehicles] = useState<
    Vehicle[]
  >([])
  const {
    requestQuotePanelState,
    editServicePlanFormPanelState,
    filterPanelState,
    chassisNumber,
  } = useSelector((state: RootState) => state.dafCompanyVehicle)
  const { space } = useTheme()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const [pagination, setPagination] = useState<PaginationInfo | undefined>(undefined)
  const [pageIndex, setpageIndex] = useState<number>(0)
  const dispatch = useDispatch()
  const history = useHistory()
  const [breakpointS] = useBreakpoints(BREAKPOINT_S)
  const urlPageNumber = getUrlQueryValue(URL_PAGE_PARAM, history.location.search)

  const { sort, search, activeFilters } = useContext(MyFleetListContext)
  const {
    site: { name },
    featureToggles,
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const dafVehicleDetailRequest: DafVehicleDetailRequest = {
    chassisNumber,
    cultureCode,
  }
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDafVehicleDetail = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafCompanyDetail(
      dafVehicleDetailRequest,
      dafVehicleDetailQueryParams,
      client,
      token,
      user
    )
    return response.data
  }

  const { isPending: dafVehicleDetailPending, data: dafVehicleDetailData } =
    useQuery({
      queryKey: [`dafVehicleData-${chassisNumber}`],
      queryFn: getDafVehicleDetail,
      enabled: !!chassisNumber && !!actingCompanyId && !!actingSupplierId,
    })

  const closeSideBar = () => {
    dispatch(toggleMyFleetFilterPanel(false))
  }

  useEffect(() => {
    if (urlPageNumber) {
      setpageIndex(parseInt(urlPageNumber, 10) - 1)
    }
  }, [urlPageNumber])

  const scrollToTop = useCallback(() => {
    scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  const sortFilteredDafCompanyVehicles = useCallback(
    (current: Vehicle[]) => {
      switch (sort) {
        case MY_FLEET_LIST_SORT_FIRST_REGISTRATION_DATE:
          return sortByFirstRegistrationDate(current)
        case MY_FLEET_LIST_SORT_MILEAGE:
          return sortByMileage(current)
        case MY_FLEET_LIST_SORT_VEHICLE_ACTION_STATUS:
          return sortByVehicleActionStatus(current)
        case MY_FLEET_LIST_SORT_VEHICLE_MAINTENANCE_URGENCY_STATUS:
          return sortByVehicleMaintenanceUrgencyStatus(current)
        case MY_FLEET_LIST_SORT_MAIN_SERVICE_DEALER:
          return sortByMainServiceDealer(current)
        case MY_FLEET_LIST_SORT_SERVICE_COVERAGE:
          return sortByServiceCoveragePriority(current)
        default:
          return sortByFirstRegistrationDate(current)
      }
    },
    [sort]
  )

  const getDafCompanyVehicles = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafCompanyVehicles(
      client,
      dafVehicleDetailQueryParams,
      token,
      user
    )
    return response.data
  }

  const {
    isFetching,
    data: dafCompanyVehiclesData,
    refetch: refetchDafCompanyVehiclesData,
  } = useQuery({
    queryKey: ['dafCompanyVehicles'],
    queryFn: getDafCompanyVehicles,
    enabled: !!actingCompanyId && !!actingSupplierId,
  })

  useEffect(() => {
    if (dafCompanyVehiclesData) {
      setDafCompanyVehicles(dafCompanyVehiclesData)
    }
  }, [dafCompanyVehiclesData, dispatch])

  useEffect(() => {
    if (dafCompanyVehicles) {
      const vehicles = dafCompanyVehicles?.result.vehicles

      setFilteredDafCompanyVehicles(sortFilteredDafCompanyVehicles(vehicles))

      if (search) {
        setFilteredDafCompanyVehicles((current) => [
          ...findVehiclesByTextInput(current, search),
        ])
      }

      if (activeFilters) {
        setFilteredDafCompanyVehicles((current) => [
          ...filterVehiclesByActiveFilters(current, activeFilters[0]),
        ])
      }
    }
  }, [
    dispatch,
    dafCompanyVehicles,
    sort,
    search,
    activeFilters,
    sortFilteredDafCompanyVehicles,
  ])

  useEffect(() => {
    setpageIndex(0)
    setFilteredDafCompanyVehicles((current) => [
      ...sortFilteredDafCompanyVehicles(current),
    ])
  }, [sortFilteredDafCompanyVehicles])

  useEffect(() => {
    if (filteredDafCompanyVehicles) {
      setPaginatedDafCompanyVehicles(
        filteredDafCompanyVehicles.slice(
          pageIndex * VEHICLES_PER_PAGE,
          (pageIndex + 1) * VEHICLES_PER_PAGE
        )
      )
    }
    scrollToTop()
  }, [pageIndex, filteredDafCompanyVehicles, scrollToTop])

  useEffect(() => {
    if (filteredDafCompanyVehicles) {
      const totalPageCount = Math.ceil(
        filteredDafCompanyVehicles?.length / VEHICLES_PER_PAGE
      )

      setPagination({
        totalCount: totalPageCount,
        previousIndex: pageIndex && pageIndex > 0 ? pageIndex - 1 : null,
        currentIndex: pageIndex,
        nextIndex: pageIndex < totalPageCount - 1 ? pageIndex + 1 : null,
      })
    }
  }, [pageIndex, filteredDafCompanyVehicles])

  useEffect(() => {
    setpageIndex(0)
    setFilteredDafCompanyVehicles((current) => [
      ...findVehiclesByTextInput(current, search),
    ])
  }, [search])

  useEffect(() => {
    if (activeFilters) {
      setpageIndex(0)
      setFilteredDafCompanyVehicles((current) => [
        ...filterVehiclesByActiveFilters(current, activeFilters[0]),
      ])
    }
  }, [activeFilters, filterPanelState])

  const showMaintenancePlanSuggestion = () => {
    const vehicles = dafCompanyVehicles?.result.vehicles
    return (
      vehicles?.filter(
        (vehicle) =>
          !vehicle.rmContract &&
          vehicle.hasDafConnect &&
          !vehicleHasMaintenancePlan(vehicle)
      ).length ?? 0
    )
  }

  return (
    <>
      <Box>
        {isFetching && (
          <Flex
            sx={{
              width: '100%',
              height: '100%',
              paddingY: 5,
            }}
          >
            <SpinnerWithLabel
              label={i18next.t('commonLabels.loading')}
              sx={{
                width: 'max-content',
                position: 'sticky',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          </Flex>
        )}
        {!isFetching && dafCompanyVehicles && (
          <>
            {showMaintenancePlanSuggestion() > 0 &&
              name === COMMERCE_SITE_CONTEXT &&
              !!featureToggles?.requestMaintenancePlan && (
                <BannerGeneric
                  heading={i18next.t(
                    getTextFieldValue(requestMaintenancePlanBannerTitleDatasource),
                    {
                      numberOfVehicles: showMaintenancePlanSuggestion(),
                    }
                  )}
                  cta={{
                    icon: ICONS.ARROW_RIGHT,
                    iconPosition: 'end',
                    children: requestMaintenancePlanLinkDatasource?.text,
                    as: 'a',
                    href: requestMaintenancePlanLinkDatasource?.url,
                  }}
                >
                  <Text as="p">
                    {getTextFieldValue(
                      requestMaintenancePlanBannerSubtitleDatasource
                    )}
                  </Text>
                </BannerGeneric>
              )}

            <Box sx={{ marginBottom: 4 }}>
              <MyFleetListFiltersWrapper
                filterGroups={dafCompanyVehicles?.result.filters}
              />
            </Box>

            {!!dafCompanyVehicles.result.vehicles.length && (
              <Paginated
                fetching={isFetching}
                fetchingLabel={i18next.t('commonLabels.total')}
                totalCount={pagination?.totalCount}
                previousIndex={pagination?.previousIndex}
                currentIndex={pagination?.currentIndex}
                nextIndex={pagination?.nextIndex}
              >
                {!breakpointS && (
                  <MyFleetListCards
                    vehicleData={paginatedDafCompanyVehicles}
                    filterGroups={dafCompanyVehicles?.result.filters}
                    vehicleDetailLink={vehicleDetailLink}
                    requestQuoteMenuOptionTitle={
                      requestNewMultisupportQuoteSidepanelDatasource?.panelTitle
                        ?.value
                    }
                    requestMaintenanceAppointmentOptionTitle={
                      requestMaintenanceAppointmentSidepanelDatasource?.panelTitle
                        ?.value
                    }
                    deleteVehicleMenuOptionTitle={
                      deleteVehicleSidepanelDatasource?.panelTitle?.value
                    }
                  />
                )}
                {breakpointS && (
                  <MyFleetListTable
                    vehicleData={paginatedDafCompanyVehicles}
                    unfilteredVehicleData={dafCompanyVehicles.result.vehicles}
                    filterGroups={dafCompanyVehicles?.result.filters}
                    vehicleDetailLink={vehicleDetailLink}
                    requestQuoteMenuOptionTitle={
                      requestNewMultisupportQuoteSidepanelDatasource?.panelTitle
                        ?.value
                    }
                    requestMaintenanceAppointmentOptionTitle={
                      requestMaintenanceAppointmentSidepanelDatasource?.panelTitle
                        ?.value
                    }
                    deleteVehicleOptionTitle={
                      deleteVehicleSidepanelDatasource?.panelTitle?.value
                    }
                  />
                )}
              </Paginated>
            )}

            {!dafCompanyVehicles.result.vehicles.length && (
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Text as="h2" variant="heading2">
                  {i18next.t('commonLabels.noVehiclesAdded')}
                </Text>
                <Text
                  as="p"
                  variant="bodyContent"
                  color="gray.2"
                  sx={{ marginBlock: space[3] }}
                >
                  {i18next.t('commonLabels.noVehiclesAddedDescription')}
                </Text>

                {hasRole(user, USER_ROLES_VEHICLE_MANAGEMENT) && (
                  <Flex
                    sx={{
                      justifyContent: 'center',
                    }}
                  >
                    <MyFleetAddVehicleButton
                      handleAddVehicleClick={() =>
                        dispatch(toggleAddVehiclePanel(true))
                      }
                      variant="primary"
                    />
                  </Flex>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      <SidePanel
        active={filterPanelState}
        onClose={() => closeSideBar()}
        title={i18next.t('myFleetListSortAndFilterLabels.sortAndFilterLabel')}
      >
        <SidePanelBody>
          <MyFleetListSortingList />
          <MyFleetListFiltersList
            filterGroups={dafCompanyVehicles?.result.filters}
          />
        </SidePanelBody>
        <MyFleetListSidePanelFooter
          removeFiltersLabel={{
            value: i18next.t('myFleetListSortAndFilterLabels.resetButtonValue'),
          }}
          confirmFiltersLabel={{
            value: i18next.t('myFleetListSortAndFilterLabels.confirmButtonLabel'),
          }}
          numberOfResults={filteredDafCompanyVehicles?.length}
          onClose={() => closeSideBar()}
        />
      </SidePanel>

      <ServicesProvider
        chassisNumber={
          dafVehicleDetailData?.result?.vehicleDetail.vehicleAttributes.chassisNumber
        }
        enabled={editServicePlanFormPanelState}
      >
        <EditServicePlanFormPanel
          vehicle={dafVehicleDetailData?.result}
          messageOnSuccess={editServicePlanMessageOnSuccess}
        />
      </ServicesProvider>

      <MileageSettlementInputFormPanel
        vehicle={dafVehicleDetailData?.result}
        messageOnSuccess={inputMileageThankYouMessageOnSuccess}
      />

      <RequestMaintenanceFormPanel
        vehicle={dafVehicleDetailData?.result}
        onFormSuccess={refetchDafCompanyVehiclesData}
        noRequestForMaintenanceMessage={noRequestForMaintenanceMessage}
      />

      <EndMaintenancePlanPanel
        vehicle={dafVehicleDetailData?.result}
        onFormSuccess={refetchDafCompanyVehiclesData}
      />

      <AddVehicleSidePanel onFormSuccess={refetchDafCompanyVehiclesData} />

      {requestMaintenanceAppointmentSidepanelDatasource && (
        <RequestMaintenanceAppointmentPanel
          datasource={requestMaintenanceAppointmentSidepanelDatasource}
          vehicle={dafVehicleDetailData?.result}
        />
      )}

      <EditVehiclePanel
        vehicle={dafVehicleDetailData?.result}
        onFormSuccess={refetchDafCompanyVehiclesData}
      />

      {deleteVehicleSidepanelDatasource && (
        <DeleteVehiclePanel
          datasource={deleteVehicleSidepanelDatasource}
          vehicle={dafVehicleDetailData?.result}
          onFormSuccess={refetchDafCompanyVehiclesData}
          isPending={dafVehicleDetailPending}
        />
      )}

      <ServicesProvider
        chassisNumber={
          dafVehicleDetailData?.result?.vehicleDetail.vehicleAttributes.chassisNumber
        }
        enabled={requestQuotePanelState}
      >
        {requestNewMultisupportQuoteSidepanelDatasource && (
          <RequestQuotePanel
            datasource={requestNewMultisupportQuoteSidepanelDatasource}
            vehicle={dafVehicleDetailData?.result}
          />
        )}
      </ServicesProvider>
    </>
  )
}

export default MyFleetListWrapperForContext
