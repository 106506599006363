import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import { CommerceCrossSellPart, CommercePart } from '../../../types/commerceApi'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'

const GET_PART_CROSS_SELL_PART_LIST = loader(
  './schemas/partCrossSellPartListQuery.graphql'
)

interface PartCrossSellPartListQueryData {
  part: CommercePart
}

interface PartCrossSellPartListQueryVariables extends BaseGraphServiceVariables {
  [key: string]: any
  partNumber: string
}

const usePartCrossSellPartList = (
  partNumber?: string
): GraphServiceTuple<CommerceCrossSellPart[]> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [crossSellPartList, setCrossSellPartList] = useState<
    CommerceCrossSellPart[]
  >([])

  const onData = useCallback((data?: PartCrossSellPartListQueryData) => {
    if (data?.part?.crossSell?.length) {
      setCrossSellPartList(data.part.crossSell)
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    PartCrossSellPartListQueryData,
    PartCrossSellPartListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && partNumber) {
      fetch({
        document: GET_PART_CROSS_SELL_PART_LIST,
        variables: {
          ...baseVariables,
          partNumber,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, partNumber])

  return [fetching, crossSellPartList]
}

export default usePartCrossSellPartList
