import { useTheme } from '@emotion/react'
import i18next from 'i18next'
import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import { CommerceWayOfTransportOption } from '../../../../types/commerceApi'
import OrderFieldWayOfTransport from './OrderField/OrderFieldWayOfTransport'

interface OrderWayOfTransportListProps {
  selectedTransportId?: number
  wayOfTransportOptions?: CommerceWayOfTransportOption[]
  onSelect: (id: number) => void
}

const OrderWayOfTransportList: FC<OrderWayOfTransportListProps> = ({
  selectedTransportId,
  wayOfTransportOptions,
  onSelect,
}) => {
  const { space } = useTheme()

  if (!wayOfTransportOptions?.length) return null

  return (
    <>
      <Text
        variant="bodySmall"
        sx={{
          display: 'block',
          color: 'gray.2',
          marginBottom: 4,
        }}
      >
        {i18next.t('shoppingLabels.deliveryLabels.transportOptionsDescription')}
      </Text>

      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: `${space[4]}px`,
        }}
      >
        {wayOfTransportOptions.map((transportOption) => (
          <OrderFieldWayOfTransport
            key={transportOption.transportId}
            onSelect={onSelect}
            {...transportOption}
            isSelectedOption={selectedTransportId === transportOption.transportId}
          />
        ))}
      </Box>
    </>
  )
}

export default OrderWayOfTransportList
