import React, { FC } from 'react'
import withData from '../../../enhancers/withData'
import {
  BannerParams,
  Datasource,
  ImageWithFocalPointField,
  Params,
} from '../../../types/layoutService'
import Banner, { DEFAULT_BANNER_VARIANT } from '../../atoms/Banner/Banner'
import BannerImage from '../../atoms/Banner/BannerImage'

interface HeroImageDatasource {
  image: ImageWithFocalPointField
}

/**
 * OBSOLETE, marked for removal.
 *
 * Replaced by HeroBanner
 */

const HeroImage: FC<Datasource<HeroImageDatasource> & Params<BannerParams>> = ({
  datasource: { image },
  params: { bottomSpacing, variant = DEFAULT_BANNER_VARIANT },
}) => {
  if (!image?.src) return null

  return (
    <Banner bottomSpacing={bottomSpacing}>
      <BannerImage imageField={image} variant={variant} />
    </Banner>
  )
}

export default withData(HeroImage)
