import useOnWindowScroll from '@rooks/use-on-window-scroll'
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { refetchShoppingBasketSidepanelSummary } from '../../../actions/shoppingBasket/sidePanelActions'
import { URL_IFRAME_SOURCE_PARAM } from '../../../constants/urlConstants'
import withClientSideRender from '../../../enhancers/withClientSideRender'
import {
  addQueryParameterToUrl,
  getUrlHash,
  getUrlQueryValue,
} from '../../../helpers/urlHelper'
import { scrollTo } from '../../../helpers/dom'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getApplicationHeaderHeight } from '../../../helpers/layoutHelper'
import useApplicationHeaderHeight from '../../../hooks/useApplicationHeaderHeight'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import {
  BASKET_OPEN,
  BASKET_UPDATE,
  UPDATE_IFRAME_EMBED_URL,
} from '../../../constants/iframeConstants'
import {
  CLOSE_R2C_JOBSHEET_REFERENCE_SIDEPANEL,
  OPEN_R2C_ADD_TO_BASKET_PANEL,
  OPEN_R2C_JOBSHEET_REFERENCE_SIDEPANEL,
  OPEN_R2C_TASK_SIDEPANEL,
} from '../../../constants/r2cConstants'
import { userCultureCode } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import R2CJobsheetReferenceSidePanel from '../../organisms/SidePanel/R2CJobsheetReferenceSidePanel/R2CJobsheetReferenceSidePanel'
import R2CJobsheetTaskSidePanel from '../../organisms/SidePanel/R2CJobsheetTaskSidePanel/R2CJobsheetTaskSidePanel'
import Spinner from '../../atoms/Spinner/Spinner'
import { Box } from 'theme-ui'

export interface IframeDatasource {
  source: TextField
}

const Iframe: FC<Datasource<IframeDatasource>> = ({ datasource }) => {
  const hasFixedSource = useMemo(() => !!datasource?.source?.value, [datasource])
  const { userProfile } = useContext(UserContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const spacerHeight = useApplicationHeaderHeight()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [src, setSrc] = useState<string | undefined>(datasource?.source?.value)
  const [height, setHeight] = useState<string>('0px')
  const [iframeContentHeight, setIframeContentHeight] = useState<number | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(true)
  const { classicUrl } = useSitecoreContext()
  const [r2cReferenceSidePanelActive, setR2CReferenceSidePanelActive] =
    useState<boolean>(false)
  const [r2cTaskSidePanelActive, setR2CTaskSidePanelActive] =
    useState<boolean>(false)
  const [selectedPartNumber, setSelectedPartNumber] = useState<string>('')
  const [customerOrderNumber, setCustomerOrderNumber] = useState<string>('')

  const reloadIframe = () => window.location.reload()

  const setIframeHeightToContentHeight = useCallback(
    (newHeight: number) => {
      if (newHeight && iframeContentHeight !== newHeight) {
        setIframeContentHeight(newHeight)
      }
    },
    [iframeContentHeight]
  )

  const messageHandler = useCallback(
    (event: MessageEvent) => {
      if (typeof event.data === 'object') {
        if (event.data.event === 'contentHeight') {
          setIframeHeightToContentHeight(event.data.value)
          setIsLoading(false)
        } else if (event.data.event === 'dataLayer') {
          pushToDataLayer(event.data.value)
        } else if (event.data.event === 'scrollTo') {
          scrollTo({ top: event.data.value, left: 0, behavior: 'smooth' })
        } else if (event.data.event === OPEN_R2C_TASK_SIDEPANEL) {
          setSelectedPartNumber(event.data.value.partNumber)
          if (!event.data.value.referenceNumber) {
            setR2CReferenceSidePanelActive(true)
          } else {
            setCustomerOrderNumber(event.data.value.referenceNumber)
            setR2CTaskSidePanelActive(true)
          }
        } else if (event.data.event === UPDATE_IFRAME_EMBED_URL) {
          history.replace(`/embed?source=${event.data.value}`)
        } else if (event.data.event === OPEN_R2C_ADD_TO_BASKET_PANEL) {
          if (!event.data.value.referenceNumber) {
            setR2CReferenceSidePanelActive(true)
            setSelectedPartNumber(event.data.value.partNumber)
          } else {
            setR2CTaskSidePanelActive(true)
            setCustomerOrderNumber(event.data.value.referenceNumber)
            setSelectedPartNumber(event.data.value.partNumber)
            setCustomerOrderNumber(event.data.value.referenceNumber)
          }
        }
      } else if (event.data === BASKET_UPDATE) {
        dispatch(refetchShoppingBasketSidepanelSummary(true))
      } else if (event.data === BASKET_OPEN) {
        window.location.href = `/${userCultureCode(userProfile)}/shopping-basket`
      } else if (event.data === OPEN_R2C_JOBSHEET_REFERENCE_SIDEPANEL) {
        setR2CReferenceSidePanelActive(true)
      } else if (event.data === CLOSE_R2C_JOBSHEET_REFERENCE_SIDEPANEL) {
        reloadIframe()
      }
    },
    [dispatch, setIframeHeightToContentHeight, userProfile, history]
  )

  useEffect(() => {
    if (hasFixedSource) {
      return
    }

    const uriString = getUrlQueryValue(URL_IFRAME_SOURCE_PARAM)
    const uriHashString = getUrlHash()

    if (uriString) {
      setSrc(
        `${addQueryParameterToUrl(
          `${classicUrl}${uriString}`,
          'forceAuthentication',
          'true'
        )}${uriHashString}`
      )
    }
  }, [hasFixedSource, classicUrl])

  useEffect(() => {
    if (iframeContentHeight) {
      setHeight(`${iframeContentHeight}px`)
    } else if (spacerHeight) {
      setHeight(`calc(100vh - ${getApplicationHeaderHeight()}px)`)
    } else {
      setHeight('0px')
    }
  }, [spacerHeight, iframeContentHeight])

  useEffect(() => {
    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [messageHandler])

  useEffect(() => {
    if (iframeRef.current?.contentWindow?.postMessage) {
      iframeRef.current.contentWindow.postMessage(
        { event: 'setScrollPaddingTop', value: spacerHeight },
        '*'
      )
    }
  }, [spacerHeight])

  useOnWindowScroll(() => {
    if (iframeRef.current?.contentWindow?.postMessage) {
      iframeRef.current.contentWindow.postMessage(
        { event: 'setScrollPosition', value: window.scrollY },
        '*'
      )
    }
  })

  const handleR2CReferenceSidePanelClose = () => {
    setR2CReferenceSidePanelActive(false)
    setTimeout(() => {
      reloadIframe()
    }, 300)
  }

  const handleR2CTaskSidePanelClose = () => {
    setR2CTaskSidePanelActive(false)
    setTimeout(() => {
      reloadIframe()
    }, 300)
  }

  if (!src) return null

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            inset: 0,
            backgroundColor: 'white',
            padding: 8,
            opacity: 0.8,
          }}
        >
          <Spinner />
        </Box>
      )}
      <iframe
        ref={iframeRef}
        src={src}
        frameBorder="0"
        scrolling="no"
        allowFullScreen
        seamless
        data-hj-allow-iframe
        width="100%"
        height={height}
      ></iframe>
      <R2CJobsheetReferenceSidePanel
        active={r2cReferenceSidePanelActive}
        onSidepanelClose={handleR2CReferenceSidePanelClose}
        partNumber={selectedPartNumber}
      />
      <R2CJobsheetTaskSidePanel
        active={r2cTaskSidePanelActive}
        onSidepanelClose={handleR2CTaskSidePanelClose}
        partNumber={selectedPartNumber}
        customerOrderNumber={customerOrderNumber}
      />
    </Box>
  )
}

export default withClientSideRender(
  withData(Iframe, { checkDatasource: false, showMessageWhenPageEditing: true })
)
