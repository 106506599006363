import React, { FC, useContext } from 'react'
import { LinkField } from '../../../../../types/layoutService'
import AssemblySearchCard from './AssemblySearchCard'
import { AssemblySearchContext } from '../../../../../providers/AssemblySearchContextProvider'
import CardGrid from '../../../../molecules/Card/CardGrid'

interface AssembliesSearchGridViewProps {
  assemblyPage: LinkField
}

const AssembliesSearchGridView: FC<AssembliesSearchGridViewProps> = ({
  assemblyPage,
}) => {
  const { assemblies } = useContext(AssemblySearchContext)

  if (!assemblies?.length) return null

  return (
    <CardGrid>
      {assemblies.map((assembly, i) => (
        <AssemblySearchCard
          key={i.toString()}
          {...assembly}
          assemblyPage={assemblyPage}
        />
      ))}
    </CardGrid>
  )
}

export default AssembliesSearchGridView
