import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { Class } from '../../../../hooks/services/graphql/useClassInformationListService'
import { scrollTo } from '../../../../helpers/dom'
import Modal, { ModalProps } from '../Modal'
import ModalBodyContent from '../ModalBodyContent'
import ModalAnchor from '../ModalAnchors/ModalAnchor'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import useWildcardPageContext from '../../../../hooks/useWildcardPageContext'
import { PartContext } from '../../../../types/sitecoreContextProps'
import { WILDCARD_PAGE_CONTEXT_TYPE_PART } from '../../../../providers/WildcardPageContextProvider'
import { TABLE_ANCHOR_ADDITIONAL_TARGET_TOP_SPACE } from '../../SearchResults/PartSearch/SearchControls/TableAnchorList/PartSearchTableAnchors'

export interface SearchCategoryListModalProps extends ModalProps {
  title: string
  availableClassInformation: Class[]
}

const SearchCategoryListModal: FC<SearchCategoryListModalProps> = ({
  title,
  availableClassInformation,
  isActive,
  onClose,
}) => {
  const partContext = useWildcardPageContext<PartContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_PART
  )
  const { categories } = useContext(CategoriesContext)

  const mainCategory = useMemo(
    () =>
      partContext.category?.id
        ? categories?.find((category) => category.id === partContext.category?.id)
        : undefined,
    [partContext, categories]
  )
  const subCategory = useMemo(
    () =>
      mainCategory?.subCategories?.find(
        (category) => category.id === partContext.subcategory?.id
      ),
    [mainCategory, partContext]
  )

  const onAnchorClick = useCallback(
    (classInformation: Class) => {
      const element = document.getElementById(classInformation.code)

      if (element) {
        const elementTop = element.getBoundingClientRect().y
        const newTopPosition = elementTop - TABLE_ANCHOR_ADDITIONAL_TARGET_TOP_SPACE

        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
          navigate_type:
            DATA_LAYER.CUSTOM_DIMENSION.NAVIGATION_TYPE.JUMP_TO_TABLE_IN_PAGE,
          main_category: mainCategory?.englishDescription || '',
          sub_category: subCategory?.englishDescription || '',
          filter_name: '',
          filter_value: '',
          page_type: '',
          table_name:
            classInformation.englishDescription || classInformation.description,
        })

        scrollTo({ top: newTopPosition })
      }

      onClose()
    },
    [mainCategory, onClose, subCategory]
  )

  return (
    <Modal isActive={isActive} onClose={onClose} title={title}>
      <ModalBodyContent>
        {availableClassInformation && !!availableClassInformation.length && (
          <Box as="ul" sx={{ listStyle: 'none', p: 0 }}>
            {availableClassInformation.map((classInformation) => (
              <Text as="li" variant="bodySmall" key={classInformation.code}>
                <ModalAnchor
                  label={classInformation.description}
                  onClick={() => onAnchorClick(classInformation)}
                  sx={{
                    '> div': {
                      px: 3,
                    },
                  }}
                />
              </Text>
            ))}
          </Box>
        )}
      </ModalBodyContent>
    </Modal>
  )
}

export default SearchCategoryListModal
