import React, { ReactNode, useEffect, useRef } from 'react'
import { Box } from 'theme-ui'
import SearchResultsBackToTopButton from './SearchResultsBackToTopButton'
import { isServer } from '@sitecore-jss/sitecore-jss/utils'

interface SearchResultsBackToTopWrapperProps {
  backToTopLabel: string
  children: ReactNode
}

const SearchResultsBackToTopWrapper = ({
  backToTopLabel,
  children,
}: SearchResultsBackToTopWrapperProps) => {
  if (isServer()) return
  const [offset, setOffset] = React.useState(0)
  const wrapperRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (wrapperRef.current) {
      setOffset(wrapperRef?.current?.offsetTop)
    }
  })
  return (
    <Box
      ref={wrapperRef}
      sx={{
        position: 'relative',
        marginBottom: [4, 4, 4, 5],
      }}
    >
      {children}

      <SearchResultsBackToTopButton wrapperTop={offset} label={backToTopLabel} />
    </Box>
  )
}

export default SearchResultsBackToTopWrapper
