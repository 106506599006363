import React, { FC, useMemo } from 'react'
import withData from '../../../enhancers/withData'
import usePartsListService from '../../../hooks/services/graphql/usePartListService'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  ApiVariant,
  Datasource,
  Flag,
  Params,
  ReferenceField,
  SliderVariant,
  TextField,
} from '../../../types/layoutService'
import { PardCardLayoutServiceData } from '../../molecules/Card/PartCard/PartCard'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartSliderSkeleton from '../../atoms/Skeleton/PartSliderSkeleton'
import PartCardListResponsive from '../CardList/PartCardListResponsive'
import {
  getSortFuncById,
  PartSliderSortFunc,
} from '../../../helpers/partSliderSortHelper'
import { Part } from '../../../types/dafResponseProps'

export interface PartSliderDatasource {
  title: TextField
  parts: TextField
  sortOrder: ReferenceField
}

export interface PartSliderParams {
  variant: SliderVariant
  apiVariant: ApiVariant
  topSpacing: Flag
  bottomSpacing: Flag
}

type PartSliderType = FC<
  Datasource<PartSliderDatasource> &
    PardCardLayoutServiceData &
    Params<PartSliderParams>
>

const PartSlider: FC<
  Datasource<PartSliderDatasource> &
    PardCardLayoutServiceData &
    Params<PartSliderParams>
> = ({
  params: { variant, topSpacing, bottomSpacing },
  datasource: { title, parts, sortOrder },
  partPage,
}) => {
  const partNumbers = useMemo(
    () =>
      getTextFieldValue(parts)
        .split(',')
        .filter((e) => e)
        .map((e) => e.trim()),
    [parts]
  )

  const sortFunc = useMemo<PartSliderSortFunc>(
    () => getSortFuncById(sortOrder?.targetItem?.id ?? ''),
    [sortOrder]
  )

  const [fetchingPartList, partList] = usePartsListService(partNumbers)

  const sortedPartList = useMemo<Part[]>(
    () => sortFunc(partNumbers, partList ?? []),
    [partNumbers, partList, sortFunc]
  )

  if (!sortedPartList?.length) return null

  return (
    <PartCardListResponsive
      title={getTextFieldValue(title)}
      variant={variant}
      fetching={fetchingPartList}
      partList={sortedPartList}
      partPage={partPage}
      topSpacing={topSpacing === '1'}
      bottomSpacing={bottomSpacing === '1'}
      dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_SLIDER}
    />
  )
}

export default withData(PartSlider, {
  pageEditingComponent: PartSliderSkeleton as PartSliderType,
  showPageEditingComponentWhenPreviewing: true,
})
