const toPascalCase = (sentence: string | null | undefined) => {
  if (sentence)
    return sentence
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .trim()
      .split(' ')
      .map((word) => word[0].toUpperCase().concat(word.slice(1)))
      .join('')
  return sentence
}

export default toPascalCase
