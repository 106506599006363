import React, { FC } from 'react'
import i18next from 'i18next'
import { Text } from 'theme-ui'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'

interface DealerInformationEmptySidePanelProps {
  onClose: () => void
  title: string
  active?: boolean
}

const DealerInformationEmptySidePanel: FC<DealerInformationEmptySidePanelProps> = ({
  active,
  onClose,
  title,
}) => (
  <SidePanel active={active} onClose={onClose} title={title}>
    <SidePanelBody sx={{ color: 'gray.2' }}>
      <Text variant="bodySmall">{i18next.t('commonLabels.noDealerInfo')}</Text>
    </SidePanelBody>
  </SidePanel>
)

export default DealerInformationEmptySidePanel
