import {
  DigitalMagazineMenuItemData,
  PushToDigitalMagazineActions,
} from '../actions/digitalMagazine/digitalMagazineActions'
import { PUSH_TO_DIGITAL_MAGAZINE_MENU } from '../constants/digitalMagazineConstants'

const digitalMagazineReducer = (
  state: DigitalMagazineMenuItemData[] = [],
  action: PushToDigitalMagazineActions
): DigitalMagazineMenuItemData[] => {
  switch (action.type) {
    case PUSH_TO_DIGITAL_MAGAZINE_MENU: {
      const slideExists = state.some(
        (slide) => slide.menuId === action.payload.menuId
      )

      return slideExists ? state : [...state, { ...action.payload }]
    }
    default: {
      return state
    }
  }
}

export default digitalMagazineReducer
