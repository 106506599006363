import React, { FC } from 'react'
import { Box } from 'theme-ui'
import withData from '../../../enhancers/withData'
import {
  BackgroundParams,
  ButtonParams,
  Datasource,
  HeadingParams,
  LinkField,
  Params,
  SpacingParams,
  TextField,
} from '../../../types/layoutService'
import ColumnLayout from '../../atoms/ColumnLayout/ColumnLayout'
import CallToActionContent from '../../molecules/CallToActionContent/CallToActionContent'
import withSubTheme from '../../../enhancers/withTheme'

interface CallToActionCenteredParams
  extends BackgroundParams,
    SpacingParams,
    HeadingParams,
    ButtonParams {}

interface CallToActionCenteredDatasource {
  title: TextField
  text: TextField
  button?: LinkField
}

const CallToActionCentered: FC<
  Datasource<CallToActionCenteredDatasource> & Params<CallToActionCenteredParams>
> = ({ datasource, params }) => (
  <ColumnLayout
    params={{
      FirstColumn: '1-4',
      SecondColumn: '2-4',
      ThirdColumn: '1-4',
      ...params,
    }}
  >
    <div />

    <Box mb={[4, 4, 7]}>
      <CallToActionContent {...datasource} params={params} />
    </Box>

    <div />
  </ColumnLayout>
)

export default withData(withSubTheme(CallToActionCentered), {
  checkDatasource: true,
})
