import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  URL_FILTER_PARAMETER,
  URL_SEARCH,
  URL_SORT,
} from '../../../../../constants/urlConstants'
import { getTextFieldValue } from '../../../../../helpers/layoutServiceHelper'
import { TextField } from '../../../../../types/layoutService'
import Button from '../../../../atoms/Button/Button'
import { MyFleetListContext } from '../../../../../providers/MyFleetListContextProvider'

interface PartFilterResetButtonProps {
  label: TextField
}

const MyFleetListSidePanelFooterResetButton = ({
  label,
}: PartFilterResetButtonProps) => {
  const history = useHistory()
  const { sort, search, activeFilters } = useContext(MyFleetListContext)
  const [buttonState, setButtonState] = useState(true)

  const handleClick = useCallback(() => {
    const params = new URLSearchParams(history.location.search)

    params.delete(URL_SORT)
    params.delete(URL_SEARCH)
    params.delete(URL_FILTER_PARAMETER)

    history.push({
      search: params.toString(),
    })
  }, [history])

  useEffect(() => {
    if (sort || search || (activeFilters && Object.keys(activeFilters[0]).length)) {
      setButtonState(false)
    } else {
      setButtonState(true)
    }
  }, [sort, search, activeFilters, setButtonState])

  return (
    <Button
      disabled={buttonState}
      variant="outline"
      sx={{ flexGrow: 1, mr: 2 }}
      onClick={() => handleClick()}
    >
      {getTextFieldValue(label)}
    </Button>
  )
}

export default MyFleetListSidePanelFooterResetButton
