import React, { FC, useContext } from 'react'
import { FlexProps } from 'theme-ui'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'
import PartPromotions from './PartPromotions'

interface PartPromotionProps extends Omit<FlexProps, 'css'> {
  partNumber: string
}

export const PartPromotionsAsync: FC<PartPromotionProps> = ({ ...flexProps }) => {
  const { priceInformation } = useContext(PartSalesInformationContext)

  if (!priceInformation?.price) return null

  return <PartPromotions price={priceInformation.price} {...flexProps} />
}

export default PartPromotionsAsync
