import i18next from 'i18next'
import { DateFormatter } from '../providers/LocalizationProvider'
import { parseDate } from './dateHelper'
import {
  PartQuantumPriceTableColumnProps,
  PartQuantumPriceTableRowProps,
} from '../components/organisms/PartQuantumPrice/PartQuantumPriceTable'
import { CurrencyFormatFunc } from '../types/closureTypes'
import { Price } from '../hooks/services/graphql/usePartSalesInformationService'
import {
  PART_ENDDATE,
  PART_PRICETYPE,
  PART_QUANTITY,
  PART_REFERENCE,
  PART_RUSHPRICE,
  PART_STOCKPRICE,
} from '../constants/partConstants'

/**
 * Creates an array of columns based on all label properties. The first column is intentionally blank.
 */
export const getPartQuantumPriceTableColumns =
  (): PartQuantumPriceTableColumnProps[] => [
    { id: 'label', label: undefined },
    {
      id: PART_QUANTITY,
      label: i18next.t('quantumPriceLabels.quantity'),
    },
    {
      id: PART_STOCKPRICE,
      label: i18next.t('quantumPriceLabels.stockPrice'),
    },
    {
      id: PART_RUSHPRICE,
      label: i18next.t('quantumPriceLabels.rushPrice'),
    },
    {
      id: PART_ENDDATE,
      label: i18next.t('quantumPriceLabels.endDate'),
    },
    {
      id: PART_PRICETYPE,
      label: i18next.t('quantumPriceLabels.priceType'),
    },
    {
      id: PART_REFERENCE,
      label: i18next.t('quantumPriceLabels.reference'),
    },
  ]

/**
 * Dynamically creates an array of rows based on special price or quantum break availability.
 */
export const getPartQuantumPriceTableRows = (
  currencyFormatter: CurrencyFormatFunc,
  dateFormatter: DateFormatter,
  price?: Price,
  withSpecialPrice = false
): PartQuantumPriceTableRowProps[] => {
  const rows: PartQuantumPriceTableRowProps[] = []

  // Create row when the price object contains a special price
  if (withSpecialPrice && price?.specialPrice) {
    const {
      rushOrderUnitPrice,
      stockOrderUnitPrice,
      priceType,
      referenceNumber,
      endDate,
    } = price.specialPrice

    rows.push({
      quantity: i18next.t('quantumPriceLabels.notApplicable'),
      label: i18next.t('promotionLabels.specialPrice'),
      endDate: dateFormatter(parseDate(endDate)),
      rushPrice: currencyFormatter(rushOrderUnitPrice, {
        currencyCodeSourceType: 'SPECIAL_PRICE',
      }),
      stockPrice: currencyFormatter(stockOrderUnitPrice, {
        currencyCodeSourceType: 'SPECIAL_PRICE',
      }),
      priceType,
      reference: referenceNumber,
    })
  }

  // Convert quantity breaks to rows when the price object contains any
  if (price?.quantumPrice?.quantityBreaks?.length) {
    rows.push(
      ...price.quantumPrice.quantityBreaks.map(
        ({
          minQuantityInclusive,
          rushOrderUnitPrice,
          stockOrderUnitPrice,
        }): PartQuantumPriceTableRowProps => ({
          label: `${i18next.t(
            'quantumPriceLabels.quantumGroup'
          )} ${minQuantityInclusive}`,
          quantity: minQuantityInclusive?.toString(),
          endDate: dateFormatter(parseDate(price.quantumPrice?.endDate)),
          rushPrice: currencyFormatter(rushOrderUnitPrice, {
            currencyCodeSourceType: 'QUANTUM_PRICE',
          }),
          stockPrice: currencyFormatter(stockOrderUnitPrice, {
            currencyCodeSourceType: 'QUANTUM_PRICE',
          }),
          priceType: i18next.t('quantumPriceLabels.notApplicable'),
          reference: i18next.t('quantumPriceLabels.notApplicable'),
        })
      )
    )
  }

  return rows
}
