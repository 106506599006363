import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { CrossReference } from '../../../types/partTypes'
import TableCell from '../../molecules/Table/TableCell'
import TableBody from '../../molecules/Table/TableBody'
import TableRow from '../../molecules/Table/TableRow'
import Table from '../../molecules/Table/Table'

interface PartCrossReferencesTableProps {
  crossReferences: CrossReference[]
}

export const PartCrossReferencesTable: FC<PartCrossReferencesTableProps> = ({
  crossReferences,
}) => {
  let lastVendor = ''

  if (!crossReferences?.length) return null

  return (
    <Table columnSizes={[1, 1]}>
      <TableBody>
        {crossReferences.map(({ vendor, vendorPartNumber }, i) => {
          const hideVendorOnMobile =
            lastVendor === vendor ? ['none', 'none', 'block'] : []

          lastVendor = vendor

          return (
            <TableRow key={i.toString()}>
              <TableCell>
                <Text
                  as="strong"
                  variant="bodyContent"
                  color="gray.3"
                  sx={{
                    fontWeight: 'bold',
                    display: hideVendorOnMobile,
                  }}
                >
                  {vendor}
                </Text>
              </TableCell>

              <TableCell>
                <Text
                  as="span"
                  variant="bodyContent"
                  sx={{
                    textAlign: ['right', 'right', 'left'],
                  }}
                >
                  {vendorPartNumber}
                </Text>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default PartCrossReferencesTable
