import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { GradientType } from '../../../types/layoutService'

interface BannerGradientProps extends Omit<BoxProps, 'css'> {
  visible?: boolean
  gradientType?: GradientType | ''
}

const BannerGradientHorizontal: FC<Omit<BoxProps, 'css'>> = ({ sx, ...rest }) => {
  const { banner } = useTheme()

  return (
    <Box
      sx={{
        position: 'absolute',
        display: ['none', 'none', 'block'],
        top: 0,
        left: 0,
        height: '100%',
        width: '50%',
        zIndex: 2,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          height: '100%',
          right: 0,
          left: 0,
          width: '100%',
          background: `linear-gradient(90deg, ${banner.backgroundColor} 0%, transparent 100%)`,
          zIndex: -1,
        },
        ...sx,
      }}
      {...rest}
    />
  )
}

const BannerGradientVertical: FC<Omit<BoxProps, 'css'>> = ({ sx, ...rest }) => {
  const { banner } = useTheme()
  return (
    <Box
      sx={{
        position: 'absolute',
        display: ['none', 'none', 'none', 'block'],
        bottom: 0,
        left: 0,
        height: ['100%'],
        width: '100%',
        zIndex: 2,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          height: '100%',
          right: 0,
          left: 0,
          width: '100%',
          background: `linear-gradient(transparent, ${banner.backgroundColor})`,
          zIndex: -1,
        },
        ...sx,
      }}
      {...rest}
    />
  )
}

export const BannerGradient: FC<BannerGradientProps> = ({
  visible,
  gradientType,
  ...rest
}) => {
  if (!visible || gradientType === 'none') {
    return null
  }

  if (gradientType === 'vertical') {
    return <BannerGradientVertical {...rest} />
  }

  return <BannerGradientHorizontal {...rest} />
}
