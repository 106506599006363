import React, { FC, useCallback } from 'react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import withData from '../../../enhancers/withData'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { Datasource, LinkField, Params } from '../../../types/layoutService'
import { ButtonVariants } from '../../atoms/Button/Button'
import ButtonGroupWrapper from '../../atoms/ButtonGroupWrapper/ButtonGroupWarpper'
import SitecoreButton from '../SitecoreButton/SitecoreButton'

interface ButtonGroupDatasource {
  primaryButton?: LinkField
  secondaryButton?: LinkField
}

interface ButtonGroupParams {
  primaryButtonVariant?: ButtonVariants
  secondaryButtonVariant?: ButtonVariants
}

const ButtonGroup: FC<
  Datasource<ButtonGroupDatasource> & Params<ButtonGroupParams>
> = ({ datasource: { primaryButton, secondaryButton }, params }) => {
  const measureClick = useCallback((link?: LinkField) => {
    if (!link) return

    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
      call_to_action_name: link.text,
      call_to_action_type: DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
      target_url: link.url,
    })
  }, [])

  return (
    <ButtonGroupWrapper>
      <SitecoreButton
        variant={params.primaryButtonVariant?.toLowerCase() as ButtonVariants}
        onClick={() => measureClick(primaryButton)}
        linkField={primaryButton}
      />

      <SitecoreButton
        variant={params.secondaryButtonVariant?.toLowerCase() as ButtonVariants}
        onClick={() => measureClick(secondaryButton)}
        linkField={secondaryButton}
      />
    </ButtonGroupWrapper>
  )
}

export default withData(ButtonGroup)
