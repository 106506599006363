import useResizeObserver from '@react-hook/resize-observer'
import React, {
  Children,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import { SliderTuple } from '../../../hooks/slider/useSlider'
import useSwipeGestures, { OnSwipingEvent } from '../../../hooks/useSwipeGestures'
import Slide from './Slide'

interface SliderProps {
  slider: SliderTuple
  enableKeyboard?: boolean
  children: ReactNode
}

const Slider = ({ slider, enableKeyboard, children }: SliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const [
    ,
    decrementPageNumber,
    incrementPageNumber,
    { currentPageIndex, isFirstPage, isLastPage },
    { slidesPerPage, slideGap },
  ] = slider
  const [pageSize, setPageSize] = useState(0)
  const [swiping, setSwiping] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [startScrollLeft, setStartScrollLeft] = useState(0)

  const setSliderScrollLeft = useCallback((newScrollLeft: number) => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft = newScrollLeft
    }
  }, [])

  const updateSliderScrollPosition = useCallback(
    (offset: number) => setSliderScrollLeft(pageSize * currentPageIndex + offset),
    [setSliderScrollLeft, pageSize, currentPageIndex]
  )

  const keyboardNavigation = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowRight':
          return incrementPageNumber()

        case 'ArrowLeft':
          return decrementPageNumber()

        default:
          return undefined
      }
    },
    [decrementPageNumber, incrementPageNumber]
  )

  const swipeHandlers = useSwipeGestures(sliderRef, {
    onSwipeStart() {
      setStartScrollLeft(sliderRef.current?.scrollLeft || 0)
    },
    onSwiping({ deltaX }: OnSwipingEvent) {
      if (!swiping && !(isFirstPage && isLastPage)) setSwiping(true)

      setSliderScrollLeft(startScrollLeft + deltaX)
    },
    onSwipeCancel: () => {
      setSwiping(false)
      setSliderScrollLeft(startScrollLeft)
    },
    onSwiped: () => setSwiping(false),
    onSwipedLeft: incrementPageNumber,
    onSwipedRight: decrementPageNumber,
  })

  useResizeObserver(sliderRef, (element) => setPageSize(element.contentRect.width))

  useEffect(() => {
    // change slider scroll position whenever pageIndex changes
    if (sliderRef.current) {
      const additionalGapOffset = currentPageIndex * slideGap

      updateSliderScrollPosition(additionalGapOffset)

      setTimeout(() => setInitialized(true), 50) // prevents scroll animation from being triggered when start index is larger than 0
    }
  }, [currentPageIndex, slideGap, updateSliderScrollPosition])

  useEffect(() => {
    if (enableKeyboard) {
      document.addEventListener('keydown', keyboardNavigation)
    }

    return () => {
      if (enableKeyboard) {
        document.removeEventListener('keydown', keyboardNavigation)
      }
    }
  }, [enableKeyboard, keyboardNavigation])

  return (
    <Box
      ref={sliderRef}
      {...swipeHandlers}
      sx={{
        display: 'grid',
        gridGap: `${slideGap}px`,
        gridAutoFlow: 'column',
        gridAutoColumns: `calc(${100 / slidesPerPage}% - ${
          ((slidesPerPage - 1) * slideGap) / slidesPerPage
        }px)`,
        overflowX: 'hidden',
        scrollBehavior: !initialized || swiping ? 'initial' : 'smooth',
        cursor: swiping ? 'grabbing' : 'grab',
        a: {
          cursor: swiping ? 'grabbing' : 'pointer',
        },
      }}
    >
      {Children.map(children, (child, i) => (
        <Slide key={i.toString()}>{child}</Slide>
      ))}
    </Box>
  )
}
export default Slider
