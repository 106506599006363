import { useContext, useEffect, useState } from 'react'
import { getPriceInformation } from '../helpers/priceHelper'
import { DealerPartSalesInformationContext } from '../providers/DealerPartSalesInformationProvider'
import { CurrencyFormatFunc } from '../types/closureTypes'
import { PriceInformation } from './services/graphql/usePartSalesInformationService'

export interface PriceInformationWithFormatter extends PriceInformation {
  currencyFormatter: CurrencyFormatFunc
}

const useDealerPartSalesInformation = (
  partNumber?: string
): [PriceInformation?, boolean?] => {
  const { priceInformations, fetching } = useContext(
    DealerPartSalesInformationContext
  )

  const [priceInformation, setPriceInformation] = useState<
    PriceInformation | undefined
  >(undefined)
  useEffect(() => {
    setPriceInformation(getPriceInformation(partNumber, priceInformations))
  }, [partNumber, priceInformations])

  return [priceInformation, fetching]
}

export default useDealerPartSalesInformation
