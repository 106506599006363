import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { COLORS } from '../../../constants/themeConstants'
import { RootState } from '../../../reducers'
import { getMileageValue } from '../../../helpers/vehicleHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import StatePill from '../../atoms/StatePill/StatePill'
import { MILEAGE_INPUT_STATE_OPEN } from '../../../constants/dafCompanyVehicleConstants'
import Button from '../../atoms/Button/Button'
import { toggleMileageSettlementInputPanel } from '../../../actions/dafCompanyVehicleActions'
import { validateString } from '../../../helpers/stringHelper'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'

const FinanceDetailTile = () => {
  const { vehicleData, isFetching } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const dispatch = useDispatch()

  const handleMileageSettlementInputClick = (
    chassisNumber: string,
    acceptedEstimation: boolean
  ) => {
    dispatch(
      toggleMileageSettlementInputPanel({
        isActive: true,
        chassisNumber,
        acceptedEstimation,
      })
    )
  }

  const deviationValue = vehicleData?.rmContract?.finance?.mileageSettlementStatus
    ?.deviation
    ? `${vehicleData?.rmContract?.finance?.mileageSettlementStatus?.deviation}%`
    : '-'

  return (
    <>
      {!isFetching && (
        <>
          <Flex
            sx={{
              flexDirection: 'row',
              width: '100%',
              alignContent: 'center',
              marginBottom: [4, 4, 5],
            }}
          >
            <Text variant="heading2">{i18next.t('finance.statusTitle')}</Text>
            {vehicleData?.rmContract?.finance?.mileageSettlementDetails
              ?.mileageSettlementInputState === MILEAGE_INPUT_STATE_OPEN && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: ['flex-end'],
                }}
              >
                <StatePill
                  dataTId="input-mileage-chip"
                  state={i18next.t('myFleet.inputMileage')}
                  color={COLORS.ALERT}
                />
              </Box>
            )}
          </Flex>
          <Box sx={{ flexGrow: 1 }}>
            <Flex sx={{ marginTop: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Text
                  variant="bodySmall"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {i18next.t('finance.deviationIndication')}
                </Text>
              </Box>
              <Box>
                <Text
                  variant="bodySmall"
                  color={COLORS.GRAY}
                  data-t-id="deviation-value"
                >
                  {deviationValue}
                </Text>
              </Box>
            </Flex>
            {vehicleData?.rmContract?.finance?.mileageSettlementDetails
              ?.mileageSettlementInputState === MILEAGE_INPUT_STATE_OPEN && (
              <Flex sx={{ marginTop: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Text
                    variant="bodySmall"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {`${i18next.t('commonLabels.mileage')} `}
                    {vehicleData?.mileage?.type
                      ? `(${vehicleData?.mileage?.type})`
                      : '-'}
                  </Text>
                </Box>
                <Box>
                  <Text variant="bodySmall" color={COLORS.GRAY}>
                    {validateString(
                      getMileageValue(
                        vehicleData?.rmContract?.finance?.mileageSettlementStatus
                          ?.mileageEstimation,
                        cultureCode
                      )
                    )}
                  </Text>
                </Box>
              </Flex>
            )}

            {vehicleData?.rmContract?.finance?.mileageSettlementStatus
              ?.mileageEstimation &&
              vehicleData?.rmContract?.finance?.mileageSettlementDetails
                ?.mileageSettlementInputState === MILEAGE_INPUT_STATE_OPEN && (
                <Flex sx={{ gap: 3, marginTop: 4, flexDirection: 'column' }}>
                  <Box>
                    <Button
                      data-t-id="provide-mileage-button"
                      variant="outline"
                      sx={{ width: '100%' }}
                      onClick={() =>
                        handleMileageSettlementInputClick(
                          vehicleData?.vehicleDetail.vehicleAttributes.chassisNumber,
                          false
                        )
                      }
                    >
                      {i18next.t('finance.provideMileageLabel')}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      data-t-id="accept-mileage-button"
                      variant="primary"
                      sx={{ width: '100%' }}
                      onClick={() =>
                        handleMileageSettlementInputClick(
                          vehicleData?.vehicleDetail.vehicleAttributes.chassisNumber,
                          true
                        )
                      }
                    >
                      {i18next.t('finance.acceptEstimationLabel')}
                    </Button>
                  </Box>
                </Flex>
              )}
            {!vehicleData?.rmContract?.finance?.mileageSettlementStatus
              ?.mileageEstimation &&
              vehicleData?.rmContract?.finance?.mileageSettlementDetails
                ?.mileageSettlementInputState === MILEAGE_INPUT_STATE_OPEN && (
                <Box sx={{ marginTop: 4 }}>
                  <Box>
                    <Button
                      data-t-id="provide-mileage-button"
                      variant="primary"
                      sx={{ width: '100%' }}
                      onClick={() =>
                        handleMileageSettlementInputClick(
                          vehicleData?.vehicleDetail.vehicleAttributes.chassisNumber,
                          false
                        )
                      }
                    >
                      {i18next.t('finance.provideMileageLabel')}
                    </Button>
                  </Box>
                </Box>
              )}
          </Box>
          <Box
            sx={{
              marginTop: 4,
              paddingTop: 4,
              borderTop: '1px solid',
              borderColor: COLORS.MEDIUM_GRAY,
              alignContent: 'flex-end',
              color: COLORS.GRAY,
              fontSize: 0,
            }}
          >
            {i18next.t('finance.settlementStatusDescription')}
          </Box>
        </>
      )}
    </>
  )
}

export default FinanceDetailTile
