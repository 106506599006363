import React, { FC, useCallback, useContext } from 'react'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { URL_RETURN_URL_PARAM } from '../../../constants/urlConstants'
import { USER_ROLES } from '../../../constants/userConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getReturnUrl } from '../../../helpers/urlHelper'
import { hasRole } from '../../../helpers/userHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { UserContext } from '../../../providers/UserProvider'
import { LinkField } from '../../../types/layoutService'
import Button from '../../atoms/Button/Button'
import { navigateClient } from '../../../helpers/dom'

interface AddToBasketRequestAccountButtonProps {
  partNumber?: string
  requestAccountLink?: LinkField
  dataLayerComponentOrigin: string
}

const AddToBasketRequestAccountButton: FC<AddToBasketRequestAccountButtonProps> = ({
  partNumber,
  requestAccountLink,
  dataLayerComponentOrigin,
}) => {
  const { crossSiteUrl } = useSitecoreContext()
  const { user } = useContext(UserContext)

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.REQUEST_ACCOUNT,
        part_number: partNumber,
        page_type: dataLayerComponentOrigin || '',
      })

      const url = new URL(requestAccountLink?.url || '', crossSiteUrl)

      url.searchParams.set(URL_RETURN_URL_PARAM, getReturnUrl())

      navigateClient(url.toString(), event)
    },
    [crossSiteUrl, partNumber, dataLayerComponentOrigin, requestAccountLink]
  )

  if (
    hasRole(user, USER_ROLES.commerceShoppingBasket) ||
    hasRole(user, USER_ROLES.commerceBasic)
  ) {
    return null
  }

  if (!partNumber || !requestAccountLink?.url) return null

  return (
    <Button
      variant="primary"
      onClick={onClick}
      sx={{
        width: '100%',
      }}
    >
      {requestAccountLink?.text}
    </Button>
  )
}

export default AddToBasketRequestAccountButton
