import { SubThemeName } from '../providers/ThemeProvider'
import { ThemeProps } from '../types/themeProps'

export const getModalBackgroundColor = (
  theme: SubThemeName,
  { colors, mainNavigation }: ThemeProps
) => {
  switch (theme) {
    case 'dafGray':
      return colors.gray[4]
    case 'trpBlue': {
      return mainNavigation.background
    }
    default:
      // 'light'
      return colors.background
  }
}

export const getModalDividerColor = (
  theme: SubThemeName,
  { colors, mainNavigation }: ThemeProps
) => {
  switch (theme) {
    case 'dafGray':
      return colors.gray[2]
    case 'trpBlue':
      return mainNavigation.dividerColor
    default:
      // 'light'
      return colors.gray[1]
  }
}

export const getModalDividerOpacity = (
  theme: SubThemeName,
  { mainNavigation }: ThemeProps
) => {
  switch (theme) {
    case 'trpBlue':
      return mainNavigation.dividerOpacity
    default:
      // 'light' | 'dafGray':
      return '1'
  }
}

export const getModalTextColor = (
  theme: SubThemeName,
  { mainNavigation }: ThemeProps
) => {
  switch (theme) {
    case 'dafGray':
      return 'textLight'
    case 'trpBlue':
      return mainNavigation.textColor
    default:
      // 'light'
      return 'text'
  }
}

export const getModalActiveTextColor = (
  theme: SubThemeName,
  { mainNavigation }: ThemeProps
) => {
  switch (theme) {
    case 'dafGray':
      return 'highlight'
    case 'trpBlue':
      return mainNavigation.textActiveColor
    default:
      // 'light'
      return mainNavigation.textActiveColor
  }
}
