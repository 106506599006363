import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import componentFactory from './temp/componentFactory' // eslint-disable-line
import ActiveUserStateProvider from './providers/ActiveUserStateProvider'
import UserProvider from './providers/UserProvider'
import ActiveStoreProvider from './providers/ActiveStoreProvider'
import RouteHandler from './RouteHandler'
import LocalizationProvider from './providers/LocalizationProvider'
import WildcardPageContextProvider from './providers/WildcardPageContextProvider'
import GraphQLClientProvider from './providers/GraphQLClientProvider'
import AxiosClientProvider from './providers/AxiosClientProvider'
import { HelmetProvider } from 'react-helmet-async'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  render() {
    const {
      appInsightsReactPlugin,
      axiosClientMap,
      path,
      graphQLClient,
      Router,
      reduxStore,
      helmetContext,
    } = this.props

    const routeRenderFunction = (props) => (
      <RouteHandler route={props} isSSR={!!this.props.ssrState} />
    )

    return (
      <HelmetProvider context={helmetContext}>
        <ReduxProvider store={reduxStore}>
          <AppInsightsContext.Provider value={appInsightsReactPlugin}>
            <GraphQLClientProvider client={graphQLClient}>
              <AxiosClientProvider clientMap={axiosClientMap}>
                <QueryClientProvider client={queryClient}>
                  <SitecoreContext
                    componentFactory={componentFactory}
                    layoutData={this.props.ssrState}
                  >
                    <WildcardPageContextProvider>
                      <LocalizationProvider>
                        <ActiveUserStateProvider>
                          <UserProvider>
                            <ActiveStoreProvider>
                              <Router location={path} context={{}}>
                                <Switch>
                                  {routePatterns.map((routePattern) => (
                                    <Route
                                      key={routePattern}
                                      path={routePattern}
                                      render={routeRenderFunction}
                                    />
                                  ))}
                                </Switch>
                              </Router>
                              <ReactQueryDevtools initialIsOpen={false} />
                            </ActiveStoreProvider>
                          </UserProvider>
                        </ActiveUserStateProvider>
                      </LocalizationProvider>
                    </WildcardPageContextProvider>
                  </SitecoreContext>
                </QueryClientProvider>
              </AxiosClientProvider>
            </GraphQLClientProvider>
          </AppInsightsContext.Provider>
        </ReduxProvider>
      </HelmetProvider>
    )
  }
}

export default AppRoot
