import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { CommerceResponse } from '../../../types/commerceApi'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface DeleteCompanyVehicleResponse {
  result: string[]
  messages: null
}

const deleteCompanyVehicle = (
  vin: string | undefined,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<DeleteCompanyVehicleResponse>>> =>
  client.delete(`/companyvehicle/${vin}`, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default deleteCompanyVehicle
