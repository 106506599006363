import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { CommerceBrand } from '../../../types/commerceApi'
import { ImageProps } from '../../atoms/Image/Image'
import PartLineImage from './PartLineImage'

interface PartLineBrandProps extends ImageProps {
  brand?: CommerceBrand
}

const PartLineBrand: FC<PartLineBrandProps> = ({ brand, ...imageProps }) => {
  if (!brand) return <></>

  if (!brand?.image) {
    return (
      <Text
        as="span"
        variant="bodySmall"
        sx={{
          lineHeight: 'menuList',
          display: 'inline-block',
          color: 'gray.2',
          width: ['auto', 'auto', '100%'],
        }}
      >
        {brand?.description}
      </Text>
    )
  }

  return (
    <PartLineImage
      src={brand?.image?.thumbnailImageUrl}
      alt={brand?.description}
      title={brand?.description}
      sx={{
        maxWidth: [48, 48, 48, 64],
        maxHeight: [48, 48, 48, 64],
      }}
      {...imageProps}
    />
  )
}

export default PartLineBrand
