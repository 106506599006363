import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { createRequestConfig } from '../../../helpers/axiosHelper'
import { CommerceResponse } from '../../../types/commerceApi'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'

export interface DafVehicleRmDocumentsRequest {
  documents?: DocumentsEntity[] | null
}
export interface DocumentsEntity {
  url: Url
  documentType: string
  signed: boolean
  name: string
}
export interface Url {
  keyField: string
  keyValue: string
  documentId: string
  documentType: string
}

export interface SendEditRmServicePlanActionsPayload {
  type: number | null
  endDate: string | null
  mileage: number | null
  extendDuration: number | null
  remarks: string | null
  earlyTerminationReason: string | null
  specificReason: string | null
  terminationDate: string | null
  selectedAdditionalServices: string[] | null
  additionalServicePartners: (string | null | undefined)[] | null
}

const sendEditRmServicePlanActions = (
  queryParams: DafDefaultQueryParams,
  chassisNumber: string | null | undefined,
  payload: SendEditRmServicePlanActionsPayload,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<CommerceResponse<DafVehicleRmDocumentsRequest>>> =>
  client.post(`/send-editRmServicePlan-actions/${chassisNumber}`, payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default sendEditRmServicePlanActions
