import React, { FC } from 'react'
import {
  LinkField,
  ReferenceField,
  TextField,
} from '../../../../types/layoutService'
import { PartFilterSidePanelDatasource } from '../../SidePanel/PartFilterSidePanel/PartFilterSidePanel'
import SearchResultsLoadedPartResults from '../SearchResultsLoadedPartResults'
import PartSearchControls from './SearchControls/PartSearchControls'
import PartSearchResults from './PartSearchResults'

interface PartSearchTabPanelProps {
  partPage: LinkField
  supersessionText: TextField
  loadingLabel: TextField
  filtersLabel: TextField
  categoriesLabel: TextField
  tableViewAnchorLabel: TextField
  viewLabel: TextField
  filterOverlay: ReferenceField<PartFilterSidePanelDatasource>
  noResultsLabel: TextField
  invalidFilterCombinationLabel: TextField
  removeFiltersLabel: TextField
  moreFiltersLabel: TextField
}

const PartSearchTabPanel: FC<PartSearchTabPanelProps> = ({
  partPage,
  supersessionText,
  loadingLabel,
  filtersLabel,
  categoriesLabel,
  tableViewAnchorLabel,
  viewLabel,
  filterOverlay,
  noResultsLabel,
  invalidFilterCombinationLabel,
  removeFiltersLabel,
  moreFiltersLabel,
}) => (
  <PartSearchControls
    filtersLabel={filtersLabel}
    categoriesLabel={categoriesLabel}
    tableViewAnchorLabel={tableViewAnchorLabel}
    viewLabel={viewLabel}
    filterOverlay={filterOverlay}
    loadingLabel={loadingLabel}
    moreFiltersLabel={moreFiltersLabel}
  >
    <SearchResultsLoadedPartResults
      noResultsLabel={noResultsLabel}
      invalidFilterCombinationLabel={invalidFilterCombinationLabel}
      removeFiltersLabel={removeFiltersLabel}
    >
      <PartSearchResults
        partPage={partPage}
        supersessionText={supersessionText}
        loadingLabel={loadingLabel}
      />
    </SearchResultsLoadedPartResults>
  </PartSearchControls>
)
export default PartSearchTabPanel
