import React, { ReactNode } from 'react'
import { useFormState } from 'react-final-form'
import Button from '../Button/Button'
import FormControl from '../FormControls/FormControl'
import Spinner from '../Spinner/Spinner'

export interface SubmitButtonProps {
  disabled?: boolean
  children: ReactNode
}

export const SubmitButton = ({ disabled, children }: SubmitButtonProps) => {
  const { submitting } = useFormState({ subscription: { submitting: true } })

  return (
    <FormControl>
      <Button
        type="submit"
        variant="primary"
        disabled={submitting || disabled}
        sx={{ display: 'flex', flexDirection: 'row' }}
      >
        {submitting && <Spinner variant="light" size={2} />}

        {!submitting && children}
      </Button>
    </FormControl>
  )
}

export default SubmitButton
