import React, { useContext } from 'react'
import { RewardsFeaturedDatasourceProps } from './RewardsFeaturedWrapper'
import ContentSlider from '../ContentSlider/ContentSlider'
import { RewardsContext } from '../../../providers/RewardsProvider'
import RewardCard from '../../molecules/Card/RewardCard/RewardCard'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'
import { ICONS } from '../../../constants/iconConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Box } from 'theme-ui'
import RewardsRedeemRewardSidepanel from '../../molecules/RewardsRedeemRewardSidepanel/RewardsRedeemRewardSidepanel'

interface RewardsFeaturedProps {
  datasource: RewardsFeaturedDatasourceProps
}
const RewardsFeatured = ({ datasource }: RewardsFeaturedProps) => {
  const { rewardsData } = useContext(RewardsContext)

  if (!rewardsData?.rewards.length) {
    return null
  }

  return (
    <>
      <ContentSlider
        slidesPerPage={4}
        title={getTextFieldValue(datasource.title)}
        topSpacing
      >
        {rewardsData?.rewards.map((reward) => (
          <RewardCard key={reward.rewardId} {...reward} variant="minimal" />
        ))}
      </ContentSlider>

      <Box
        sx={{
          paddingBlockStart: 16,
          paddingBlockEnd: [40, 64],
        }}
      >
        <SitecoreButton
          linkField={datasource.viewAllVouchers}
          variant="infinite"
          color="primary"
          icon={ICONS.ARROW_RIGHT}
          iconPosition="end"
        />
      </Box>

      <RewardsRedeemRewardSidepanel
        datasource={datasource.redeemSidepanel.targetItem}
      />
    </>
  )
}

export default RewardsFeatured
