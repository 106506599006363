import React, { FC } from 'react'
import { Box } from 'theme-ui'
import { SliderTuple } from '../../../../hooks/slider/useSlider'
import { Image as ImageProps } from '../../../../types/dafResponseProps'
import CarouselThumbnail from './CarouselThumbnail'

export interface CarouselThumbnailsProps {
  images: ImageProps[]
  slider: SliderTuple
}

const CarouselThumbnails: FC<CarouselThumbnailsProps> = ({ images, slider }) => {
  const [setPageNumber, , , { currentPageIndex }] = slider

  return (
    <Box
      sx={{
        pt: 3,
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      {images.map((image, i) => (
        <CarouselThumbnail
          index={i}
          key={i.toString()}
          image={image}
          active={currentPageIndex === i}
          onClick={() => setPageNumber(i)}
        />
      ))}
    </Box>
  )
}

export default CarouselThumbnails
