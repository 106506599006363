import {
  VIEW_TYPE_DEFAULT,
  VIEW_TYPE_GRID,
  VIEW_TYPE_LINE,
  VIEW_TYPE_TABLE,
} from '../constants/searchConstants'
import { URL_SEARCH_VIEW_TYPE } from '../constants/urlConstants'
import { Category } from '../hooks/services/graphql/useCategoryListService'
import { getUrlQueryValue } from './urlHelper'

export const getSearchViewTypeFromUrl = (
  search?: string | URLSearchParams
): string | undefined => {
  let urlValue

  if (typeof search === 'string') {
    urlValue = getUrlQueryValue(URL_SEARCH_VIEW_TYPE, search)
  } else if (search instanceof URLSearchParams) {
    urlValue = search.get(URL_SEARCH_VIEW_TYPE)
  }

  if (
    urlValue &&
    [VIEW_TYPE_LINE, VIEW_TYPE_TABLE, VIEW_TYPE_GRID].includes(urlValue)
  ) {
    return urlValue
  }

  return undefined
}

export const getSearchResultViewType = (
  category?: Category,
  searchString?: string
): string | undefined => {
  if (category === undefined && searchString === undefined) return undefined

  if (category?.viewType === undefined && searchString) return VIEW_TYPE_DEFAULT

  if (category?.viewType === undefined) return VIEW_TYPE_DEFAULT

  if (category.viewType === null) return VIEW_TYPE_DEFAULT

  return category.viewType
}
