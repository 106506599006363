import React, { Children, FC } from 'react'
import { Link, LinkProps } from 'theme-ui'

export interface PartLineActionProps extends Omit<LinkProps, 'css'> {
  href?: string
  onClick?: () => void
}

const PartLineAction: FC<PartLineActionProps> = ({
  href,
  onClick,
  children,
  ...linkProps
}) => (
  <>
    {Children.map(children, (child) => (
      <Link
        href={href}
        onClick={onClick}
        sx={{
          py: 4,
          textDecoration: 'none',
        }}
        {...linkProps}
      >
        {child}
      </Link>
    ))}
  </>
)

export default PartLineAction
