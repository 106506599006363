import React, { FC, useContext, useEffect, useState } from 'react'
import { NavigationItem as NavigationItemProps } from '../../../types/layoutService'
import { MenuProviderContext } from '../../../providers/MenuProvider'
import { LinkItem, SubNavigation, SubNavigationItem } from '.'
import {
  findMenuItemByPath,
  mapMenuServiceItemToNavigationItem,
} from '../../../helpers/menuHelper'

interface MenuServiceItemProps {
  isSubNavigationItem?: boolean
}

export const MenuServiceItem: FC<NavigationItemProps & MenuServiceItemProps> = ({
  url = '',
  isSubNavigationItem = false,
}) => {
  const { menu } = useContext(MenuProviderContext)
  const [item, setItem] = useState<NavigationItemProps | undefined>(undefined)

  useEffect(() => {
    if (menu) {
      const menuItem = findMenuItemByPath(menu, url)

      if (menuItem) {
        setItem(mapMenuServiceItemToNavigationItem(menuItem))
      }
    }
  }, [menu, url])

  if (!item) return null

  if (!item.children?.length) {
    if (isSubNavigationItem) {
      return item.url ? (
        <SubNavigationItem href={item.url}>{item.title}</SubNavigationItem>
      ) : null
    }

    return <LinkItem {...item} />
  }

  return <SubNavigation {...item} />
}
