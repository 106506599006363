import React, { createContext, ReactNode } from 'react'
import { AxiosInstance } from 'axios'

export enum AxiosClientType {
  CommerceApi = 'COMMERCE_API',
  DealerApi = 'DEALER_API',
  SitecoreApi = 'SITECORE_API',
}

export type AxiosClientMap = Record<AxiosClientType, AxiosInstance | undefined>

export interface AxiosClientContextProps {
  clientMap?: AxiosClientMap
}

export interface AxiosClientProviderProps {
  clientMap: AxiosClientMap
  children: ReactNode
}

export const AxiosClientContext = createContext<AxiosClientContextProps>({
  clientMap: undefined,
})

const AxiosClientProvider = ({ children, clientMap }: AxiosClientProviderProps) => (
  <AxiosClientContext.Provider value={{ clientMap }}>
    {children}
  </AxiosClientContext.Provider>
)

export default AxiosClientProvider
