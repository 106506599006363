import { useTheme } from '@emotion/react'
import React, { FC, useCallback } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { ImageWithFocalPointField, TextField } from '../../../types/layoutService'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import YouTubeVideoPlayIcon from './YouTubeVideoPlayIcon'
import SitecoreImage from '../../atoms/SitecoreImage/SitecoreImage'

interface YouTubeVideoPosterProps {
  onClick: () => void
  title: TextField
  imageField: ImageWithFocalPointField
}

const YouTubeVideoPoster: FC<YouTubeVideoPosterProps> = ({
  imageField,
  title,
  onClick,
}) => {
  const { transitions } = useTheme()

  const dispatchPushToDataLayer = useCallback(() => {
    pushToDataLayer({
      [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.VIDEO,
      action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.START,
      title: getTextFieldValue(title),
    })
  }, [title])

  return (
    <ClickableWrapper
      onClick={() => {
        dispatchPushToDataLayer()
        if (onClick) onClick()
      }}
      sx={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover > div:first-of-type': {
          opacity: 0.16,
        },
      }}
    >
      <SitecoreImage
        imageField={imageField}
        sx={{
          position: 'absolute',
          zIndex: -2,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: 'text',
          transition: `ease opacity ${transitions[1]}`,
          opacity: '.2',
          zIndex: -1,
        }}
      />

      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
        }}
      >
        <YouTubeVideoPlayIcon mb={[1, 2, 4]} />

        <Text
          variant="heading1"
          sx={{
            color: 'textLight',
            fontWeight: 'normal',
            fontSize: [3, 4, 5],
            textAlign: 'center',
          }}
        >
          {getTextFieldValue(title)}
        </Text>
      </Flex>
    </ClickableWrapper>
  )
}

export default YouTubeVideoPoster
