import i18next from 'i18next'
import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { EVENTS } from '../../../../constants/analyticsConstants'
import { ICONS } from '../../../../constants/iconConstants'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { getEnglishCategoryName } from '../../../../helpers/categoryHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'
import { RootState } from '../../../../reducers'
import Button from '../../../atoms/Button/Button'
import { QUOTE } from '../../../../constants/orderConstants'
import { URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM } from '../../../../constants/urlConstants'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'

const OrderGoToCheckoutButton = () => {
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { setStep, datasource } = useShoppingBasket()
  const { order, mutation } = useSelector(
    (state: RootState) => state.shoppingBasketWizard
  )
  const orderSummary = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel.orderSummary
  )

  const { categories } = useContext(CategoriesContext)

  const handleClick = useCallback(() => {
    pushToDataLayer({
      event: EVENTS.BEGIN_CHECKOUT,
      ecommerce: {
        currency: orderSummary?.totalPrice?.currencyCode,
        value: orderSummary?.totalPrice?.value,
        items: order?.items?.map(
          (
            { englishDescription, partNumber, price, brand, quantity, category },
            index
          ) => ({
            item_id: partNumber,
            item_name: englishDescription,
            index,
            item_brand: brand?.description,
            item_category: getEnglishCategoryName(category, categories),
            item_list_id: '',
            item_list_name: 'ShoppingBasketWizardOrderGoToCheckoutButton',
            item_variant: '',
            doa_product: false,
            price: price?.netUnitPrice,
            quantity,
          })
        ),
      },
    })

    setStep(URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM)
  }, [categories, order, orderSummary])

  return (
    <>
      <Button
        data-t-id="shopping-basket-next"
        disabled={
          !(order?.isR2CEnabled && isImpersonated) &&
          // this check works for r2c too, because the jobsheet reference is in order.customerOrderNumber
          (order?.confirmNotAllowedReason ===
            'OrderItemsExternalLineReferenceEmptyNotAllowed' ||
            (order?.customerOrderNumber?.isMandatory &&
              !order?.customerOrderNumber?.value) ||
            (order?.customerOrderReference?.isMandatory &&
              !order.customerOrderReference.value) ||
            !order?.items?.length ||
            mutation.mutationInProgress)
        }
        icon={ICONS.ARROW_RIGHT}
        iconPosition="end"
        onClick={handleClick}
        sx={{
          inlineSize: '100%',
        }}
      >
        {order?.type === QUOTE
          ? i18next.t('shoppingLabels.toQuoteRequestDetails')
          : getTextFieldValue(datasource.continueOrderButtonLabel)}
      </Button>
    </>
  )
}

export default OrderGoToCheckoutButton
