import React, { createContext, ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  PERSISTED_SEARCH_RESULTS_VIEW_TYPE,
  SEARCH_TYPE_ASSEMBLIES_SEARCH,
  SEARCH_TYPE_PARTS_SEARCH,
} from '../constants/searchConstants'
import { URL_QUERY_STRING_PARAM } from '../constants/urlConstants'
import withClientSideRender from '../enhancers/withClientSideRender'
import { resolveUrl } from '../helpers/linkFieldHelper'
import { getSearchViewTypeFromUrl } from '../helpers/partContextHelper'
import useWildcardPageContext from '../hooks/useWildcardPageContext'
import { CommerceCategory } from '../types/commerceApi'
import { LinkField } from '../types/layoutService'
import {
  AssemblyContext,
  ContextCategory,
  PartContext,
} from '../types/sitecoreContextProps'
import {
  WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY,
  WILDCARD_PAGE_CONTEXT_TYPE_PART,
} from './WildcardPageContextProvider'

type SearchTypeParts = typeof SEARCH_TYPE_PARTS_SEARCH
type SearchTypeAssemblies = typeof SEARCH_TYPE_ASSEMBLIES_SEARCH
type SearchType = SearchTypeParts | SearchTypeAssemblies

export interface SearchContextProps {
  searchType?: SearchType
  viewType?: CommerceCategory['viewType'] | null
  searchString?: string
  category?: ContextCategory
  subcategory?: ContextCategory
}

export const SearchContext = createContext<SearchContextProps>({
  searchType: undefined,
  viewType: undefined,
  searchString: undefined,
  category: undefined,
  subcategory: undefined,
})

interface SearchContextProviderProps {
  partPage: LinkField
  assemblyPage: LinkField
  children: ReactNode
}

const SearchContextProvider = ({
  partPage,
  assemblyPage,
  children,
}: SearchContextProviderProps) => {
  const { search, pathname } = useLocation()
  const { category: partContextCategory, subcategory: partContextSubcategory } =
    useWildcardPageContext<PartContext>(WILDCARD_PAGE_CONTEXT_TYPE_PART)
  const {
    category: assemblyContextCategory,
    subcategory: assemblyContextSubcategory,
  } = useWildcardPageContext<AssemblyContext>(WILDCARD_PAGE_CONTEXT_TYPE_ASSEMBLY)
  const category = partContextCategory || assemblyContextCategory || undefined
  const subcategory =
    partContextSubcategory || assemblyContextSubcategory || undefined

  const searchTypeMap = [
    { type: SEARCH_TYPE_PARTS_SEARCH, uriSegment: resolveUrl(partPage) },
    { type: SEARCH_TYPE_ASSEMBLIES_SEARCH, uriSegment: resolveUrl(assemblyPage) },
  ]

  const [searchString, viewType, searchType] = useMemo<
    [string, string | null, SearchType | undefined]
  >(() => {
    const newSearchType = searchTypeMap.find(({ uriSegment }) =>
      pathname.includes(uriSegment || '')
    )?.type as SearchType | undefined
    const urlQueryString = new URLSearchParams(search)
    const newSearchString = urlQueryString.get(URL_QUERY_STRING_PARAM) || ''
    const newViewType =
      getSearchViewTypeFromUrl(urlQueryString) ||
      sessionStorage.getItem(PERSISTED_SEARCH_RESULTS_VIEW_TYPE) ||
      null

    return [newSearchString, newViewType, newSearchType]
    // search is constant and available on page
  }, [])

  return (
    <SearchContext.Provider
      value={{
        searchType,
        viewType,
        searchString,
        category,
        subcategory,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export default withClientSideRender(SearchContextProvider)
