import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import Container from '../Container/Container'

interface BannerAboveTitleProps {
  title?: string
}

const BannerAboveTitle: FC<BannerAboveTitleProps> = ({ title }) => {
  if (!title) return null

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <Container>
        <Text
          variant="heading1"
          sx={{
            paddingY: [4, 4, 4, 8],
            fontSize: [5, 5, 5, 7],
            lineHeight: 'heading',
            maxWidth: ['100%', '100%', '100%', '50%'],
          }}
        >
          {title}
        </Text>
      </Container>
    </Box>
  )
}

export default BannerAboveTitle
