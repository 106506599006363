import React, { FC } from 'react'
import { Text } from 'theme-ui'

const ModalTitle: FC<{ title?: string }> = ({ title }) => {
  if (!title) return <div />

  return (
    <Text
      as="h2"
      variant="heading2"
      sx={{
        fontWeight: 'normal',
        fontSize: 3,
        color: 'text',
        ml: 2,
      }}
    >
      {title}
    </Text>
  )
}

export default ModalTitle
