import { Action } from 'redux'
import {
  PUSH_TO_DAF_COMPANY_VEHICLE,
  PUSH_TO_DAF_SERVICE_DEALER,
  PUSH_TO_DAF_VEHICLE_RM_DOCUMENTS,
  TOGGLE_ADD_VEHICLE_PANEL,
  TOGGLE_DELETE_VEHICLE_PANEL,
  TOGGLE_EDIT_SERVICE_PLAN_FORM_PANEL,
  TOGGLE_EDIT_VEHICLE_PANEL,
  TOGGLE_END_MAINTENANCE_PLAN_PANEL,
  TOGGLE_MAINTENANCE_APPOINTMENT_PANEL,
  TOGGLE_MAINTENANCE_INPUT_FORM_PANEL,
  TOGGLE_MARK_MAINTENANCE_AS_PERFORMED_PANEL,
  TOGGLE_MILEAGE_SETTLEMENT_INPUT_FORM_PANEL,
  TOGGLE_MY_FLEET_LIST_FILTER_PANEL,
  TOGGLE_REQUEST_QUOTE_PANEL,
  UPDATE_CHECKED_JOBS,
} from '../constants/dafCompanyVehicleConstants'
import { VehicleDetailResponse } from '../services/rest/ecommerce/dafVehicleDetail'
import { DafVehicleRmDocumentsResponse } from '../services/rest/ecommerce/dafVehicleRmDocuments'
import { Dealer } from '../types/dealerServiceTypes'
import { UpcomingJob } from '../services/rest/ecommerce/dafCompanyVehicles'

interface PushToDafCompanyVehiclePayloadProps {
  data: VehicleDetailResponse | undefined
}

interface PushToServiceDealerProps {
  isFetching: boolean
  data: Dealer | undefined
}

interface PushToDafVehicleRmDocumentsPayloadProps {
  isFetching: boolean
  data: DafVehicleRmDocumentsResponse | undefined
}

interface ToggleEditServicePlanPanelPayloadProps {
  chassisNumber?: string
  isActive: boolean
}

interface ToggleMileageSettlementInputPanelProps {
  chassisNumber?: string
  isActive: boolean
  acceptedEstimation?: boolean
}

interface ToggleAddVehiclePanelProps {
  isActive: boolean
}

interface ToggleMaintenanceInputPanelProps {
  isActive: boolean
  chassisNumber?: string
  isAdjustMaintenance?: boolean
}

interface ToggleEndMaintenancePlanPanelProps {
  isActive: boolean
  chassisNumber?: string
}

interface SelectedJobsProps {
  selectedJobs: UpcomingJob[]
}

interface ToggleMarkMaintenanceAsPerformedPanelProps {
  isActive: boolean
}

interface ToggleMaintenanceAppointmentPanelProps {
  isActive: boolean
  chassisNumber?: string
}

interface ToggleEditVehiclePanelProps {
  isActive: boolean
  chassisNumber?: string
}

interface ToggleDeleteVehiclePanelProps {
  isActive: boolean
  chassisNumber?: string
}

interface ToggleRequestQuotePanelProps {
  isActive: boolean
  chassisNumber?: string
}

interface ToggleMyFleetFilterPanelAction
  extends Action<typeof TOGGLE_MY_FLEET_LIST_FILTER_PANEL> {
  payload: boolean
}

interface ToggleEditServicePlanPanelAction
  extends Action<typeof TOGGLE_EDIT_SERVICE_PLAN_FORM_PANEL> {
  payload: ToggleEditServicePlanPanelPayloadProps
}

interface ToggleToggleMileageSettlementInputPanelAction
  extends Action<typeof TOGGLE_MILEAGE_SETTLEMENT_INPUT_FORM_PANEL> {
  payload: ToggleMileageSettlementInputPanelProps
}

interface ToggleAddVehiclePanelAction
  extends Action<typeof TOGGLE_ADD_VEHICLE_PANEL> {
  payload: ToggleAddVehiclePanelProps
}

interface PushToDafCompanyVehicleAction
  extends Action<typeof PUSH_TO_DAF_COMPANY_VEHICLE> {
  payload: PushToDafCompanyVehiclePayloadProps
}

interface PushToDafServiceDealerAction
  extends Action<typeof PUSH_TO_DAF_SERVICE_DEALER> {
  payload: PushToServiceDealerProps
}

interface PushToDafVehicleRmDocumentsAction
  extends Action<typeof PUSH_TO_DAF_VEHICLE_RM_DOCUMENTS> {
  payload: PushToDafVehicleRmDocumentsPayloadProps
}

interface ToggleMaintenanceInputPanelAction
  extends Action<typeof TOGGLE_MAINTENANCE_INPUT_FORM_PANEL> {
  payload: ToggleMaintenanceInputPanelProps
}

interface ToggleEndMaintenancePlanPanelAction
  extends Action<typeof TOGGLE_END_MAINTENANCE_PLAN_PANEL> {
  payload: ToggleEndMaintenancePlanPanelProps
}

interface UpdateCheckedJobsAction extends Action<typeof UPDATE_CHECKED_JOBS> {
  payload: SelectedJobsProps
}

interface ToggleMarkMaintenanceAsPerformedPanelAction
  extends Action<typeof TOGGLE_MARK_MAINTENANCE_AS_PERFORMED_PANEL> {
  payload: ToggleMarkMaintenanceAsPerformedPanelProps
}

interface ToggleMaintenanceAppointmentPanelAction
  extends Action<typeof TOGGLE_MAINTENANCE_APPOINTMENT_PANEL> {
  payload: ToggleMaintenanceAppointmentPanelProps
}

interface ToggleEditVehiclePanelAction
  extends Action<typeof TOGGLE_EDIT_VEHICLE_PANEL> {
  payload: ToggleEditVehiclePanelProps
}

interface ToggleDeleteVehiclePanelAction
  extends Action<typeof TOGGLE_DELETE_VEHICLE_PANEL> {
  payload: ToggleDeleteVehiclePanelProps
}

interface ToggleRequestQuotePanelAction
  extends Action<typeof TOGGLE_REQUEST_QUOTE_PANEL> {
  payload: ToggleRequestQuotePanelProps
}

export type MultisupportReducerActions =
  | PushToDafCompanyVehicleAction
  | ToggleMyFleetFilterPanelAction
  | ToggleEditServicePlanPanelAction
  | PushToDafVehicleRmDocumentsAction
  | PushToDafServiceDealerAction
  | ToggleToggleMileageSettlementInputPanelAction
  | ToggleMaintenanceInputPanelAction
  | UpdateCheckedJobsAction
  | ToggleMarkMaintenanceAsPerformedPanelAction
  | ToggleEndMaintenancePlanPanelAction
  | ToggleMaintenanceAppointmentPanelAction
  | ToggleAddVehiclePanelAction
  | ToggleEditVehiclePanelAction
  | ToggleDeleteVehiclePanelAction
  | ToggleRequestQuotePanelAction

export const pushToDafCompanyVehicle = (
  payload: PushToDafCompanyVehiclePayloadProps
): PushToDafCompanyVehicleAction => ({
  type: PUSH_TO_DAF_COMPANY_VEHICLE,
  payload,
})

export const pushServiceDealer = (
  payload: PushToServiceDealerProps
): PushToDafServiceDealerAction => ({
  type: PUSH_TO_DAF_SERVICE_DEALER,
  payload,
})

export const pushToDafVehicleRmDocuments = (
  payload: PushToDafVehicleRmDocumentsPayloadProps
): PushToDafVehicleRmDocumentsAction => ({
  type: PUSH_TO_DAF_VEHICLE_RM_DOCUMENTS,
  payload,
})

export const toggleMyFleetFilterPanel = (
  payload: boolean
): ToggleMyFleetFilterPanelAction => ({
  type: TOGGLE_MY_FLEET_LIST_FILTER_PANEL,
  payload,
})

export const toggleEditServicePlanPanel = (
  payload: ToggleEditServicePlanPanelPayloadProps
): ToggleEditServicePlanPanelAction => ({
  type: TOGGLE_EDIT_SERVICE_PLAN_FORM_PANEL,
  payload,
})

export const toggleMileageSettlementInputPanel = (
  payload: ToggleMileageSettlementInputPanelProps
): ToggleToggleMileageSettlementInputPanelAction => ({
  type: TOGGLE_MILEAGE_SETTLEMENT_INPUT_FORM_PANEL,
  payload,
})

export const toggleAddVehiclePanel = (payload: boolean) => ({
  type: TOGGLE_ADD_VEHICLE_PANEL,
  payload,
})

export const toggleMaintenanceInputPanel = (
  payload: ToggleMaintenanceInputPanelProps
): ToggleMaintenanceInputPanelAction => ({
  type: TOGGLE_MAINTENANCE_INPUT_FORM_PANEL,
  payload,
})

export const toggleEndMaintenancePlanPanel = (
  payload: ToggleEndMaintenancePlanPanelProps
): ToggleEndMaintenancePlanPanelAction => ({
  type: TOGGLE_END_MAINTENANCE_PLAN_PANEL,
  payload,
})

export const updateCheckedJobs = (
  payload: SelectedJobsProps
): UpdateCheckedJobsAction => ({
  type: UPDATE_CHECKED_JOBS,
  payload,
})

export const toggleMarkMaintenanceAsPerformedPanel = (
  payload: ToggleMarkMaintenanceAsPerformedPanelProps
): ToggleMarkMaintenanceAsPerformedPanelAction => ({
  type: TOGGLE_MARK_MAINTENANCE_AS_PERFORMED_PANEL,
  payload,
})

export const toggleMaintenanceAppointmentPanel = (
  payload: ToggleMaintenanceAppointmentPanelProps
): ToggleMaintenanceAppointmentPanelAction => ({
  type: TOGGLE_MAINTENANCE_APPOINTMENT_PANEL,
  payload,
})

export const toggleEditVehiclePanel = (
  payload: ToggleEditVehiclePanelProps
): ToggleEditVehiclePanelAction => ({
  type: TOGGLE_EDIT_VEHICLE_PANEL,
  payload,
})

export const toggleDeleteVehiclePanel = (
  payload: ToggleDeleteVehiclePanelProps
): ToggleDeleteVehiclePanelAction => ({
  type: TOGGLE_DELETE_VEHICLE_PANEL,
  payload,
})

export const toggleRequestQuotePanel = (
  payload: ToggleRequestQuotePanelProps
): ToggleRequestQuotePanelAction => ({
  type: TOGGLE_REQUEST_QUOTE_PANEL,
  payload,
})
