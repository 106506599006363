import React, { FC } from 'react'
import PartsNavigationPanelList from './PartsNavigationPanelList'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { Category } from '../../../../hooks/services/graphql/useCategoryListService'

interface PartsNavigationPanelListCategoryProps {
  categories?: Category[]
  activeCategory?: Category | undefined
  setCategoryId: (itemId: string | undefined) => void
}

const PartsNavigationPanelListCategory: FC<
  PartsNavigationPanelListCategoryProps
> = ({ categories, activeCategory, setCategoryId }) => {
  if (!categories?.length) return null

  return (
    <PartsNavigationPanelList
      data-t-id="parts-navigation-categories"
      categories={categories}
      activeCategory={activeCategory}
      clickAction={(itemId, englishDescription) => {
        setCategoryId(itemId)

        pushToDataLayer({
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.NAVIGATE,
          navigate_type: DATA_LAYER.CUSTOM_DIMENSION.NAVIGATION_TYPE.MEGA_MENU,
          main_category: englishDescription,
          sub_category: '',
          filter_name: '',
          filter_value: '',
          page_type: '',
        })
      }}
    />
  )
}

export default PartsNavigationPanelListCategory
