import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'

import { RequestInit } from 'graphql-request/build/esm/types.dom'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import {
  CommerceAlternative,
  CommerceBrand,
  CommercePart,
} from '../../../types/commerceApi'
import { Part } from '../../../types/dafResponseProps'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'

const GET_PART_ALTERNATIVE_PART_LIST = loader(
  './schemas/partAlternativePartListQuery.graphql'
)

interface PartAlternativePartListQueryData {
  part: CommercePart
}

interface PartAlternativePartListQueryVariables extends BaseGraphServiceVariables {
  [key: string]: any
  partNumber: string
}

const adaptCommerceBrandToBrand = (brand?: CommerceBrand): CommerceBrand => ({
  ...brand,
  image: brand?.image
    ? {
        imageUrl: brand?.image?.imageUrl,
        thumbnailImageUrl: brand?.image?.thumbnailImageUrl,
      }
    : undefined,
})

const adaptCommerceAlternativePartToPart = (
  alternative: CommerceAlternative
): Part => ({
  ...alternative,
  brand: adaptCommerceBrandToBrand(alternative.brand),
  specification: undefined,
})

const usePartAlternativePartListService = (
  partNumber?: string
): GraphServiceTuple<Part[]> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [partList, setPartList] = useState<Part[]>([])

  const onData = useCallback((data?: PartAlternativePartListQueryData) => {
    const alternatives = data?.part?.alternatives
    if (alternatives?.length) {
      setPartList(alternatives.map(adaptCommerceAlternativePartToPart))
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    PartAlternativePartListQueryData,
    PartAlternativePartListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && partNumber) {
      fetch({
        document: GET_PART_ALTERNATIVE_PART_LIST,
        variables: {
          ...baseVariables,
          partNumber,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, partNumber])

  return [fetching, partList]
}

export default usePartAlternativePartListService
