import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18next from 'i18next'
import { Text } from 'theme-ui'
import { Dealer } from '../../../types/dealerServiceTypes'
import { LinkField, StaticDealerFilter } from '../../../types/layoutService'
import DealerLocatorDealerInfo from './DealerLocatorDealerInfo'
import { DealerLocatorAction } from './DealerLocator'
import { URL_DEALER_CODE_PARAM } from '../../../constants/urlConstants'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import DealerLocatorDealerButton from './DealerLocatorDealerButton'
import DealerLocatorDealerContact from './DealerLocatorDealerContact'
import DealerLocatorDealerDayOpeningHours from './DealerLocatorDealerDayOpeningHours'
import DealerLocatorDealerDistance from './DealerLocatorDealerDistance'
import DealerLocatorDealerServices from './DealerLocatorDealerServices'
import PartLine, { PartLineGridTemplate } from '../../molecules/PartLine/PartLine'
import PartLineAnchor from '../../molecules/PartLine/PartLineAnchor'

interface DealerLocatorTableRowProps {
  action: DealerLocatorAction
  requestAccountLink: LinkField
  selectDealerLink: LinkField
  dealer: Dealer
  staticDealerFilters: StaticDealerFilter[]
  useClientLocation?: boolean
  gridTemplate?: PartLineGridTemplate
}

const DealerLocatorTableRow: FC<DealerLocatorTableRowProps> = ({
  action,
  requestAccountLink,
  selectDealerLink,
  dealer,
  staticDealerFilters,
  useClientLocation,
  gridTemplate,
}) => {
  const [columnSpace, setColumnSpace] = useState(0)
  const history = useHistory()

  const onClick = useCallback(() => {
    const params = new URLSearchParams(history.location.search)

    params.set(URL_DEALER_CODE_PARAM, dealer.code || '')
    history.push(`?${params.toString()}`)
  }, [dealer.code, history])

  return (
    <PartLine
      gridTemplate={gridTemplate}
      sx={{ color: 'gray.2' }}
      data-t-id={`Search-result-location-${dealer.code}`}
    >
      <PartLineAnchor as="button" onClick={onClick}>
        <DealerLocatorDealerInfo dealer={dealer} setColumnSpace={setColumnSpace} />
      </PartLineAnchor>

      <DealerLocatorDealerContact
        dealer={dealer}
        paddingTop={[0, 0, `${columnSpace}px`]}
        dataLayerArguments={{
          [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.DEALER_FINDER,
          action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.CLICK_CONTACT_DETAILS,
        }}
      />

      <PartLineAnchor as="button" onClick={onClick}>
        <DealerLocatorDealerDayOpeningHours
          dayNumber={new Date().getDay()}
          dealer={dealer}
          sx={{
            display: 'block',
            paddingTop: [0, 0, `${columnSpace}px`],
          }}
        />

        <Text
          variant="bodySmall"
          sx={{
            color: 'primary',
          }}
          data-t-id="Show-more-dealer-info"
        >
          {i18next.t('commonLabels.showMore')}
        </Text>
      </PartLineAnchor>

      <DealerLocatorDealerServices
        dealer={dealer}
        staticDealerFilters={staticDealerFilters}
        paddingTop={[0, 0, `${columnSpace}px`]}
      />

      {useClientLocation && (
        <DealerLocatorDealerDistance
          distance={dealer.address?.distance}
          paddingTop={[0, 0, `${columnSpace}px`]}
        />
      )}

      {dealer.requestAccountSupported && (
        <DealerLocatorDealerButton
          action={action}
          requestAccountLink={requestAccountLink}
          selectDealerLink={selectDealerLink}
          dealerName={dealer.name || ''}
          dealerCode={dealer.code || ''}
          dataLayerArguments={{
            [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.REQUEST_ACCOUNT,
            action: DATA_LAYER.CUSTOM_DIMENSION.ACTION.REQUEST_ACCOUNT,
            dealer_name: dealer.name || '',
            page_type: DATA_LAYER.COMPONENT_ORIGIN.DEALER_FINDER,
          }}
        />
      )}
    </PartLine>
  )
}

export default DealerLocatorTableRow
