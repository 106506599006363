import { HeadingTag, HeadingVariant } from '../types/layoutService'

// Used when mapping the default HTML tag for a rebass theme variant
export const HEADING_VARIANT_TO_TAG_MAPPING: Record<HeadingVariant, HeadingTag> = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  heading5: 'h5',
}

export const HEADING_VARIANT_TO_SPACING_MAPPING: Record<HeadingVariant, number> = {
  heading1: 4,
  heading2: 3,
  heading3: 2,
  heading4: 2,
  heading5: 2,
}
