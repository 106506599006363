import React, { FC, useCallback, useEffect } from 'react'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import usePagination from '../../../../hooks/usePagination'
import PaginationControls from '../../../molecules/PaginationControls/PaginationControls'
import Modal from '../Modal'
import { Slideshow } from '../../SlideshowHandout/SlideshowHandout'
import { getDividerSx } from '../../../atoms/Divider/Divider'
import SlideshowSlide from '../../SlideshowModal/SlideshowSlide'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'

export interface PaginatedSlideshowModalProps {
  isActive?: boolean
  slideshow: Slideshow
  handleCloseClick?: () => void
}

const PaginatedSlideshowModal: FC<PaginatedSlideshowModalProps> = ({
  isActive,
  slideshow,
  handleCloseClick,
}) => {
  const slides = slideshow.slides

  const [setPageNumber, decrementPageNumber, incrementPageNumber, state] =
    usePagination({
      pageCount: slides?.results?.length,
    })

  const handlePreviousClick = useCallback(() => {
    if (decrementPageNumber()) {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.MODAL,
        action: DATA_LAYER.CUSTOM_DIMENSION.MODAL_ACTION.PREVIOUS,
        modal_title: slideshow.name,
      })
    }
  }, [decrementPageNumber, slideshow.name])

  const handleNextClick = useCallback(() => {
    if (incrementPageNumber()) {
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.MODAL,
        action: DATA_LAYER.CUSTOM_DIMENSION.MODAL_ACTION.NEXT,
        modal_title: slideshow.name,
      })
    }
  }, [incrementPageNumber, slideshow.name])

  const handleCloseButtonClick = useCallback(
    (eventAction: string) => {
      if (!handleCloseClick) return

      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.MODAL,
        action: eventAction,
        modal_title: slideshow.name,
      })

      handleCloseClick()
    },
    [handleCloseClick, slideshow.name]
  )

  useEffect(() => {
    slides.results.forEach(({ image }) => {
      if (!image?.src) return
      const img = new Image()
      img.src = image.src
    })
  }, [slides])

  return (
    <Modal
      isActive={!!isActive}
      onClose={() =>
        handleCloseButtonClick(DATA_LAYER.CUSTOM_DIMENSION.MODAL_ACTION.CLOSE)
      }
    >
      {slides?.results.map((slide, i) => (
        <SlideshowSlide
          key={i.toString()}
          {...slide}
          isActive={state.currentPageIndex === i}
        />
      ))}

      <PaginationControls
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        handleIndicatorClick={setPageNumber}
        handleCloseClick={() =>
          handleCloseButtonClick(DATA_LAYER.CUSTOM_DIMENSION.MODAL_ACTION.DONE)
        }
        state={state}
        sx={{
          backgroundColor: 'inherit',
          position: 'sticky',
          bottom: 0,
          padding: 3,
          ...getDividerSx('top'),
        }}
      />
    </Modal>
  )
}

export default PaginatedSlideshowModal
