import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import { ALIGN } from '../../../constants/menuConstants'
import useBreakpoints from '../../../hooks/useBreakpoints'
import useOutsideClick from '../../../hooks/useOutsideClick'
import ClickableMenuItemWrapper from '../../atoms/ClickableMenuItemWrapper/ClickableMenuItemWrapper'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import MenuListModal from '../../organisms/Modal/ModalVariants/MenuListModal'
import Menu from '../Menu/Menu'

interface MenuResponsiveProps {
  title: string
  anchorElement: ReactNode | AnchorElementFunction
  anchorElementMobile?: ReactNode
  menuListElements: ReactNode
  open?: boolean
  align?: typeof ALIGN.LEFT | typeof ALIGN.RIGHT
  testId?: string
}

type AnchorElementFunction = (isMenuActive: boolean) => ReactNode

const MenuResponsive: FC<MenuResponsiveProps> = ({
  title,
  anchorElement,
  anchorElementMobile = false,
  menuListElements,
  testId,
  ...menuProps
}) => {
  const [breakpointM] = useBreakpoints('m')
  const wrapperRef = useRef<HTMLElement>(null)

  const [open, setOpen] = useState(false)

  useOutsideClick(wrapperRef, () => setOpen(false), !open)

  const menuToggle = useCallback(() => {
    if (menuListElements) setOpen((state) => !state)
  }, [menuListElements])

  useEffect(() => () => setOpen(false), []) // automatic close on unmount (screen gos back to desktop)

  const AnchorElement =
    typeof anchorElement === 'function' ? anchorElement(open) : anchorElement
  const AnchorElementMobile = anchorElementMobile

  return (
    <Box sx={{ position: 'relative' }} ref={wrapperRef}>
      <ClickableMenuItemWrapper // desktop button
        data-t-id={`${testId}-desktop`}
        onClick={menuToggle}
        active={open}
        sx={{
          display: ['none', 'none', 'none', 'block'],
        }}
      >
        {AnchorElement}
      </ClickableMenuItemWrapper>

      <ClickableWrapper // mobile button
        data-t-id={`${testId}-mobile`}
        onClick={menuToggle}
        sx={{
          display: ['block', 'block', 'block', 'none'],
          outline: 'none',
          width: '100%',
        }}
      >
        {AnchorElementMobile || AnchorElement}
      </ClickableWrapper>

      {breakpointM && menuListElements && (
        <Menu open={open} {...menuProps}>
          {menuListElements}
        </Menu>
      )}

      {!breakpointM && menuListElements && (
        <MenuListModal isActive={open} onClose={() => setOpen(false)} title={title}>
          {menuListElements}
        </MenuListModal>
      )}
    </Box>
  )
}

export default MenuResponsive
