import React, { FC, useContext } from 'react'
import { Flex } from 'theme-ui'
import { loggedIn } from '../../../../helpers/userHelper'
import { UserContext } from '../../../../providers/UserProvider'
import DealerPartAddToBasket from '../../AddToBasket/DealerPartAddToBasket'

export interface PartCardSalesInformationProps {
  partDescription?: string
  partNumber: string
  brandName?: string
  dataLayerComponentOrigin?: string
}

const PartCardSalesInformation: FC<PartCardSalesInformationProps> = ({
  partDescription,
  partNumber,
  brandName,
  dataLayerComponentOrigin = '',
}) => {
  const { user } = useContext(UserContext)

  if (!loggedIn(user)) return null

  return (
    <Flex sx={{ flexBasis: '100%', marginTop: 3, alignItems: 'center' }}>
      {/*  TODO: add DealerPartPrice */}
      <div />

      <DealerPartAddToBasket
        variant="small"
        partNumber={partNumber}
        partDescription={partDescription}
        brandName={brandName}
        dataLayerComponentOrigin={dataLayerComponentOrigin}
      />
    </Flex>
  )
}

export default PartCardSalesInformation
