import React, { ElementType, ReactNode } from 'react'
import { Label, Text } from 'theme-ui'
import i18next from 'i18next'
import { COLORS } from '../../../constants/themeConstants'

export interface FormInputLabelProps {
  required?: boolean
  htmlFor?: string
  as?: ElementType
  children: ReactNode
}

const FormInputLabel = ({
  children,
  required,
  htmlFor,
  as = 'label',
  ...rest
}: FormInputLabelProps) => {
  const Component = as === 'label' ? Label : 'legend'

  return (
    <Component
      htmlFor={htmlFor}
      sx={{
        display: 'flex',
        alignItems: 'end',
        marginBlockEnd: 1,
        justifyContent: 'space-between',
      }}
      {...rest}
    >
      <Text variant="bodySmall" color={COLORS.DARK} sx={{ fontWeight: 'bold' }}>
        {children}
      </Text>

      {required === false && (
        <Text variant="smallText" color="gray.2">
          {i18next.t('commonFormLabels.optional')}
        </Text>
      )}
    </Component>
  )
}

export default FormInputLabel
