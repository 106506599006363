import { mediaApi } from '@sitecore-jss/sitecore-jss/media'
import React, { forwardRef, PropsWithChildren } from 'react'
import { ImageWithFocalPointField } from '../../../types/layoutService'
import Image, { ImageProps } from '../../atoms/Image/Image'
import { getObjectPosition } from '../../../helpers/layoutHelper'

export interface SitecoreImageProps
  extends Pick<ImageProps, Exclude<keyof ImageProps, 'css'>> {
  imageField?: ImageWithFocalPointField
  variant?: string
}

const sourceSetSizes: Record<string, string>[] = [
  { w: '320' },
  { w: '460' },
  { w: '640' },
  { w: '720' },
  { w: '960' },
  { w: '1024' },
  { w: '1280' },
  { w: '1366' },
  { w: '1680' },
  { w: '1920' },
]

const SitecoreImage = forwardRef<
  HTMLImageElement,
  PropsWithChildren<SitecoreImageProps>
>(({ imageField, sx, ...rebassImageProps }, ref) => {
  const { focalPoint, ...imageProps } = imageField || {}

  if (!imageField || !imageField.src) return null

  return (
    <Image
      ref={ref}
      srcSet={mediaApi.getSrcSet(imageField.src, sourceSetSizes)}
      sx={{
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: getObjectPosition(focalPoint),
        ...sx,
      }}
      {...imageProps}
      {...rebassImageProps}
    />
  )
})

export default SitecoreImage
