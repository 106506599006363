import React, { FC } from 'react'
import { Flex, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { FONT_AWESOME_ICONS } from '../../../constants/iconConstants'

interface SitecoreFormErrorProps {
  message: string
}

const SitecoreFormError: FC<SitecoreFormErrorProps> = ({ message }) => {
  const { colors } = useTheme()

  return (
    <Flex mb={4}>
      <Text variant="bodyContent" mr={2}>
        <IconWrapper
          color={colors.primary}
          size={2}
          icon={FONT_AWESOME_ICONS.EXCLAMATION_TRIANGLE}
        />
      </Text>

      <Text as="div" variant="bodyContent" color="gray.2">
        <Text as="p">{message}</Text>
      </Text>
    </Flex>
  )
}

export default SitecoreFormError
