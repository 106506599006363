import React, { forwardRef } from 'react'
import { Box, Flex, FlexProps } from 'theme-ui'
import DisableBodyScroll from '../../../core/DisableBodyScroll'
import { SCROLL_BAR_WIDTH } from '../../../constants/themeConstants'
import Portal from '../../atoms/Portal/Portal'
import Animate from '../../atoms/Animate/Animate'

interface OverlayProps extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {
  isActive?: boolean
  hideScrollTrack?: boolean
  blurBackground?: boolean
}

const Overlay = forwardRef<HTMLElement, OverlayProps>(
  (
    { isActive, children, sx, hideScrollTrack, blurBackground, ...manipulatedProps },
    ref
  ) => (
    <Portal id="overlay-root">
      {isActive && <DisableBodyScroll />}

      <Animate
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
        active={isActive}
      >
        <Flex
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            zIndex: [9999999991, 9999999991, 3], // make sure modals and overlays are above hotjar widgets
            width: hideScrollTrack ? '100%' : `calc(100% - ${SCROLL_BAR_WIDTH})`,
            alignItems: 'stretch',
            backgroundColor: 'overlay',
            backdropFilter: blurBackground ? 'blur(2px)' : undefined,
            '@media (pointer: coarse)': {
              width: '100%',
            },
            ...sx,
          }}
          {...manipulatedProps}
        >
          <Box
            ref={ref}
            sx={{
              width: '100%',
              display: ['block', 'block', 'flex'],
              zIndex: 0,
            }}
          >
            {children}
          </Box>
        </Flex>
      </Animate>
    </Portal>
  )
)

export default Overlay
