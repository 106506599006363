import React, { FC } from 'react'
import { BoxProps, Text } from 'theme-ui'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { TextField } from '../../../types/layoutService'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'

interface PartBillOfMaterialResponsiveTableProps
  extends Omit<BoxProps, 'css' | 'title'> {
  id: string
  title: TextField
}

const PartBillOfMaterialResponsiveTable: FC<
  PartBillOfMaterialResponsiveTableProps
> = ({ title, children, sx, id, ...boxProps }) => {
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)

  if (!breakpointM) {
    return <PartMobileModalLink title={getTextFieldValue(title)} table={children} />
  }

  return (
    <AnchorMenuTarget
      title={getTextFieldValue(title)}
      id={id}
      sx={{
        maxWidth: '100%',
        overflow: 'auto',
        mb: 7,
        ...sx,
      }}
      {...boxProps}
    >
      <Text variant="heading2" as="h3" pb={5}>
        {getTextFieldValue(title)}
      </Text>

      {children}
    </AnchorMenuTarget>
  )
}

export default PartBillOfMaterialResponsiveTable
