import React, { FC } from 'react'
import { Box, Button as RebassButton } from 'theme-ui'
import { ButtonFormField } from '@sitecore-jss/sitecore-jss-forms'
import { FieldProps } from '@sitecore-jss/sitecore-jss-react-forms'

export const Button: FC<FieldProps<ButtonFormField>> = ({
  field,
  onButtonClick,
}) => (
  <Box sx={{ marginBottom: 4 }}>
    <RebassButton
      type="submit"
      variant="primary"
      value={field.model.title}
      name={field.buttonField.name}
      id={field.buttonField.id}
      onClick={() => onButtonClick(field.buttonField.name)}
    >
      {field.model.title}
    </RebassButton>
  </Box>
)
