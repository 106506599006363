import React, { FC } from 'react'
import { Datasource, TextField } from '../../../types/layoutService'
import { Image } from '../../../types/dafResponseProps'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import withData from '../../../enhancers/withData'

interface AssemblyImagesDatasource {
  title: TextField
}

interface AssemblyImagesProps {
  assemblyImages: Image[]
  assemblyTechnicalDrawings: Image[]
}

const AssemblyImages: FC<
  Datasource<AssemblyImagesDatasource> & AssemblyImagesProps
> = ({
  rendering: { componentName, uid },
  datasource: { title },
  assemblyImages,
  assemblyTechnicalDrawings,
}) => (
  <ImageCarousel
    title={title}
    images={assemblyTechnicalDrawings}
    additionalImages={assemblyImages}
    componentName={componentName}
    uid={uid}
  />
)

export default withData(AssemblyImages)
