import React from 'react'

export const getDocument = (): Document | undefined =>
  typeof document !== 'undefined' ? document : undefined

export const getWindow = (): Window | undefined =>
  typeof window !== 'undefined' ? window : undefined

export const isBrowser = (): boolean => !!getWindow()

export const runOnDocument = <T>(
  callback: (document: Document) => T
): T | undefined => (getDocument() ? callback(document) : undefined)

export const runOnWindow = <T>(callback: (window: Window) => T): T | undefined =>
  getWindow() ? callback(window) : undefined

export const isVisible = (element: HTMLElement | undefined | null) =>
  element && element.offsetHeight > 0 && element.offsetWidth > 0

export const scrollTo = (options?: ScrollToOptions) =>
  runOnWindow((w) => {
    const supportsNativeSmoothScroll =
      'scrollBehavior' in document.documentElement.style // determines if we should use a fallback

    if (supportsNativeSmoothScroll) {
      w.scrollTo(options)
    } else {
      // fallback for IE
      w.scrollTo(options?.left || 0, options?.top || 0)
    }
  })

export const navigatorApiPermissions = (
  name: PermissionName
): Promise<PermissionStatus> =>
  new Promise((resolve, reject) => {
    if (isBrowser()) {
      if (navigator?.permissions) {
        navigator.permissions
          .query({ name })
          .then((permission) => {
            resolve(permission)
          })
          .catch((error) => {
            reject(new Error(error.message))
          })
      } else {
        reject(new Error('Permission API is not supported'))
      }
    }
  })

export const navigateClient = (
  newLocation: string,
  event?: MouseEvent | React.MouseEvent<HTMLElement>
) => {
  if (event && (event.metaKey || event.ctrlKey)) {
    window.open(newLocation, '_blank')
  } else {
    window.location.href = newLocation
  }
}
