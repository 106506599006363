import { loader } from 'graphql.macro'
import { useCallback, useEffect, useState } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { MAX_NUMBER_OF_PARTS } from '../../../constants/partSalesInformationConstants'
import {
  BaseGraphServiceVariables,
  GraphServiceTuple,
} from '../../../types/graphServiceTypes'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'

const GET_PACCAR_DISTRIBUTION_CENTER_STOCK_LIST = loader(
  './schemas/paccarDistributionCenterStockListQuery.graphql'
)

interface PaccarDistributionCenterStockListQueryData {
  pdcStocks: PaccarDistributionCenterStock[]
}

interface PaccarDistributionCenterStockListQueryVariables
  extends BaseGraphServiceVariables {
  [key: string]: any
  partNumbers: string[]
}

export interface StockLocation {
  location: string
  pdc: string // Paccar Distribution Center
  priority: number
  availableStock: number
}

export interface PaccarDistributionCenterStock {
  partNumber: string
  allocationLocations: StockLocation[]
}

const usePaccarDistributionCenterStockListService = (
  partNumbers?: string[]
): GraphServiceTuple<PaccarDistributionCenterStock[] | undefined> => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService()

  const [paccarDistributionCenterStockList, setPaccarDistributionCenterStockList] =
    useState<PaccarDistributionCenterStock[] | undefined>(undefined)

  const onData = useCallback((data?: PaccarDistributionCenterStockListQueryData) => {
    if (data?.pdcStocks?.length) {
      setPaccarDistributionCenterStockList(data.pdcStocks)
    }
  }, [])

  const [fetch, fetching] = useLazyQuery<
    PaccarDistributionCenterStockListQueryData,
    PaccarDistributionCenterStockListQueryVariables
  >(onData)

  useEffect(() => {
    const abortController = new AbortController()

    if (isMounted() && baseVariables && partNumbers?.length) {
      const limitedPartNumbers = partNumbers.slice(0, MAX_NUMBER_OF_PARTS)

      fetch({
        document: GET_PACCAR_DISTRIBUTION_CENTER_STOCK_LIST,
        variables: {
          ...baseVariables,
          partNumbers: limitedPartNumbers,
        },
        requestHeaders: {
          ...baseHeaders,
        },
        signal: abortController.signal as NonNullable<RequestInit['signal']>,
      })
    }

    return () => {
      abortController.abort()
    }
  }, [isMounted, baseHeaders, baseVariables, partNumbers])

  return [fetching, paccarDistributionCenterStockList]
}

export default usePaccarDistributionCenterStockListService
