import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { getDescriptionFromFlattenedCategoryTree } from '../../../../../../helpers/categoryHelper'
import { Category } from '../../../../../../hooks/services/graphql/useCategoryListService'
import PartSearchControlsSubcategoryListItem from './PartSearchControlsSubcategoryListItem'

interface PartSearchControlsSubcategoryListColumnItemProps {
  categoryTree: Category[]
  callback?: () => void
}

const PartSearchControlsSubcategoryListColumnItem: FC<
  PartSearchControlsSubcategoryListColumnItemProps
> = ({ categoryTree, callback }) => {
  if (!categoryTree?.length) return null

  return (
    <PartSearchControlsSubcategoryListItem
      categoryTree={categoryTree}
      callback={callback}
    >
      <Text as="span" variant="bodySmall" sx={{ lineHeight: 'menuList' }}>
        {getDescriptionFromFlattenedCategoryTree(categoryTree)}
      </Text>
    </PartSearchControlsSubcategoryListItem>
  )
}

export default PartSearchControlsSubcategoryListColumnItem
