import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { MENU_ITEM_ID_SUBMITTED_ORDER } from '../../../constants/menuServiceConstants'
import {
  URL_SEARCH_TYPE,
  URL_SHOPPING_BASKET_WIZARD_COMPANY_NAME_FOR_CONFIRMATION_PAGE,
  URL_SHOPPING_BASKET_WIZARD_ORDER_NUMBER_FOR_CONFIRMATION_PAGE,
} from '../../../constants/urlConstants'
import withData from '../../../enhancers/withData'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import { getBasketMenuItem } from '../../../helpers/menuHelper'
import { getUrlQueryValue } from '../../../helpers/urlHelper'
import { MenuProviderContext } from '../../../providers/MenuProvider'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import ButtonGroupWrapper from '../../atoms/ButtonGroupWrapper/ButtonGroupWarpper'
import SitecoreButton from '../../molecules/SitecoreButton/SitecoreButton'
import { QUOTATION_SENT, QUOTE } from '../../../constants/orderConstants'
import renderHTML from '../../../helpers/renderHTMLHelper'

interface ShoppingBasketOrderConfirmedDatasource {
  title: TextField
  text: TextField
  quoteTitle: TextField
  quoteText: TextField
  goToOrderHistoryLabel: TextField
  continueShoppingLink: LinkField
}

const ShoppingBasketOrderConfirmed: FC<
  Datasource<ShoppingBasketOrderConfirmedDatasource>
> = ({
  datasource: {
    title,
    text,
    quoteTitle,
    quoteText,
    goToOrderHistoryLabel,
    continueShoppingLink,
  },
}) => {
  const { menu } = useContext(MenuProviderContext)
  const { search } = useLocation()

  const orderNumber =
    getUrlQueryValue(
      URL_SHOPPING_BASKET_WIZARD_ORDER_NUMBER_FOR_CONFIRMATION_PAGE,
      search
    ) || ''

  const companyName =
    getUrlQueryValue(
      URL_SHOPPING_BASKET_WIZARD_COMPANY_NAME_FOR_CONFIRMATION_PAGE,
      search
    ) || ''

  const orderType = getUrlQueryValue(URL_SEARCH_TYPE, search) || ''

  let heading = `${getTextFieldValue(title)} ${orderNumber}`
  let subText = renderHTML(
    i18next.t(getEditableTextFieldValue(text), {
      orderNumber,
    })
  )

  switch (orderType) {
    case QUOTE:
      heading = getTextFieldValue(quoteTitle)
      subText = renderHTML(
        i18next.t(getEditableTextFieldValue(quoteText), {
          orderNumber,
        })
      )
      break
    case QUOTATION_SENT:
      heading = i18next.t(getTextFieldValue(title), {
        orderNumber,
      })
      subText = renderHTML(
        i18next.t(getEditableTextFieldValue(text), {
          orderNumber,
          companyName,
        })
      )
      break
    default:
  }

  const submittedOrder = useMemo(
    () => getBasketMenuItem(MENU_ITEM_ID_SUBMITTED_ORDER, menu),
    [menu]
  )

  const measureClick = useCallback(
    (buttonText: string, targetUrl?: string) =>
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.CALL_TO_ACTION,
        call_to_action_name: buttonText,
        call_to_action_type: DATA_LAYER.CUSTOM_DIMENSION.CALL_TO_ACTION_TYPE.BUTTON,
        target_url: targetUrl,
      }),
    []
  )

  return (
    <>
      <Text variant="heading1" marginBottom={{ marginBottom: 3 }}>
        {heading}
      </Text>

      <Box sx={{ marginBottom: 5, color: 'gray.2' }}>{subText}</Box>

      <ButtonGroupWrapper>
        {goToOrderHistoryLabel && submittedOrder?.url && orderType !== QUOTE && (
          <SitecoreButton
            data-t-id="order-confirmed-order-history"
            variant="outline"
            linkField={{
              text: getTextFieldValue(goToOrderHistoryLabel),
              url: submittedOrder.url,
            }}
            onClick={() =>
              measureClick(
                getTextFieldValue(goToOrderHistoryLabel),
                submittedOrder.url
              )
            }
          />
        )}

        <SitecoreButton
          data-t-id="order-confirmed-continue-shopping"
          variant="primary"
          linkField={continueShoppingLink}
          onClick={() => measureClick(continueShoppingLink?.text || '', '/')}
        />
      </ButtonGroupWrapper>
    </>
  )
}

export default withData(ShoppingBasketOrderConfirmed)
