import React, { FC } from 'react'
import { Text } from 'theme-ui'
import { BREAKPOINT_M } from '../../../constants/themeConstants'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { DealerPart } from '../../../hooks/services/graphql/useDealerPartListService'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { Datasource, TextField } from '../../../types/layoutService'
import { getDividerSx } from '../../atoms/Divider/Divider'
import AnchorMenuTarget from '../AnchorMenu/AnchorMenuTarget'
import PartMobileModalLink from '../PartMobileModalLink/PartMobileModalLink'

interface PartSpecificationsDatasource {
  title: TextField
}

interface PartSpecificationsProps {
  dealerPart?: DealerPart
}

const PartSpecifications: FC<
  Datasource<PartSpecificationsDatasource> & PartSpecificationsProps
> = ({ rendering: { uid }, dealerPart, datasource }) => {
  const [breakpointM] = useBreakpoints(BREAKPOINT_M)
  const { title } = datasource
  const { partProperties } = dealerPart ?? {}

  if (!partProperties) return null

  if (!breakpointM) {
    return (
      <PartMobileModalLink title={getTextFieldValue(title)} table={partProperties} />
    )
  }

  return (
    <AnchorMenuTarget title={getTextFieldValue(title)} id={uid} mb={7}>
      {title && (
        <Text as="h2" variant="heading2" mb={4}>
          {getTextFieldValue(title)}
        </Text>
      )}

      <Text
        variant="bodySmall"
        sx={{
          width: '100%',
          paddingY: 4,
          color: 'gray.2',
          display: 'block',
          ...getDividerSx('top'),
        }}
      >
        {partProperties}
      </Text>
    </AnchorMenuTarget>
  )
}

export default withData(PartSpecifications, { showMessageWhenPageEditing: true })
