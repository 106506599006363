import React, { FC } from 'react'
import { Datasource, TextField } from '../../../types/layoutService'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import withData from '../../../enhancers/withData'
import { DealerPart } from '../../../hooks/services/graphql/useDealerPartListService'

interface DealerPartImagesCarouselDatasource {
  title: TextField
}

interface DealerPartImagesCarouselProps {
  dealerPart?: DealerPart
}

const DealerPartImagesCarousel: FC<
  Datasource<DealerPartImagesCarouselDatasource> & DealerPartImagesCarouselProps
> = ({ rendering: { componentName, uid }, datasource: { title }, dealerPart }) => (
  <ImageCarousel
    title={title}
    images={dealerPart?.partImages ?? []}
    componentName={componentName}
    uid={uid}
  />
)

export default withData(DealerPartImagesCarousel)
