import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { measurePartClick } from '../../../../../helpers/analyticsHelper'
import { getCommercePartUri } from '../../../../../helpers/commerceApiHelpers'
import { CategoriesContext } from '../../../../../providers/CategoriesProvider'
import { RootState } from '../../../../../reducers'
import { CommerceOrderItem } from '../../../../../types/commerceApi'
import { LinkField } from '../../../../../types/layoutService'
import PartListItem from './PartListItem'
import PartListSalesInformationProvider from '../../../../../providers/PartListSalesInformationProvider'
import ShoppingBasketEditItemSidePanel from '../../../SidePanel/ShoppingBasket/ShoppingBasketEditItemSidePanel'
import { useShoppingBasket } from '../../../../../providers/ShoppingBasketProvider'
import i18next from 'i18next'
import { Box, Text } from 'theme-ui'
import { getEditableTextFieldValue } from '../../../../../helpers/layoutServiceHelper'

export const getPartHref = (
  partPage: LinkField,
  dealerPartPage: LinkField,
  part: CommerceOrderItem
) => {
  if (part.type === 'DealerOwnAssortment') {
    return `${dealerPartPage?.url}/${part.partNumber}`
  }

  return `${partPage?.url}/${getCommercePartUri(
    part?.category?.mainCategoryId,
    part?.category?.subCategoryId,
    part.partNumber
  )}`
}

const PartListItems = () => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { categories } = useContext(CategoriesContext)
  const { partPage, dealerPartPage, datasource } = useShoppingBasket()

  const getHref = useCallback(
    (part: CommerceOrderItem) => getPartHref(partPage, dealerPartPage, part),
    [partPage, dealerPartPage]
  )

  const measureClick = useCallback(
    (part: CommerceOrderItem, index: number) =>
      measurePartClick(
        part.partNumber,
        part.englishDescription,
        part.brand?.description,
        part.category?.mainCategoryId,
        part.category?.subCategoryId,
        index,
        categories,
        DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE,
        part.price?.netUnitPrice
      ),
    [categories]
  )

  if (!order?.items?.length) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          padding: 4,
          border: '1px solid',
          borderColor: 'gray.1',
        }}
      >
        <Text
          as="h2"
          variant="heading3"
          sx={{
            marginBlockEnd: 2,
          }}
        >
          {i18next.t('shoppingLabels.noProducts')}
        </Text>

        <Text
          as="p"
          variant="bodySmall"
          sx={{
            margin: 'auto',
            maxWidth: '85ch',
          }}
        >
          {getEditableTextFieldValue(datasource.emptyOverviewMessage)}
        </Text>
      </Box>
    )
  }

  return (
    <PartListSalesInformationProvider
      includePrice
      partNumbersQuantity={order.items.map((item) => ({
        partNumber: item.partNumber,
        quantity: 1,
      }))}
    >
      <div>
        {order.items.map((part, index) => (
          <PartListItem
            href={getHref(part)}
            part={part}
            key={part.partNumber}
            index={index}
            onClick={() => measureClick(part, index)}
          />
        ))}
      </div>

      <ShoppingBasketEditItemSidePanel />
    </PartListSalesInformationProvider>
  )
}

export default PartListItems
