import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import React, { useRef, useState } from 'react'
import SearchServicePartnersInput from './SearchServicePartnersInput'
import { COLORS } from '../../../constants/themeConstants'
import { Dealer, SearchDealerRequestFilter } from '../../../types/dealerServiceTypes'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import PartnerTileList from '../PartnerTileList/PartnerTileList'
import FormInputLabel from '../../atoms/FormControls/FormInputLabel'

interface SearchServicePartnersProps {
  label?: string
  id: string
  onSelectedPartners: (partners: Dealer) => void
  onRemoveSelectedPartners: (partners: Dealer) => void
  infoMessage?: boolean
  partners: Dealer[]
  disabled?: boolean
  filters?: SearchDealerRequestFilter[]
}

interface SearchServicePartnersRef {
  resetTextInputFilter?: () => void
}

const SearchServicePartners = ({
  label,
  id,
  onSelectedPartners,
  onRemoveSelectedPartners,
  infoMessage = true,
  partners,
  disabled,
  filters,
}: SearchServicePartnersProps) => {
  const searchServicePartnersRef = useRef<SearchServicePartnersRef>()
  const [servicePartnersDataResults, setServicePartnersDataResults] = useState<
    Dealer[] | null | undefined
  >([])
  const [servicePartnerSearchInput, setServicePartnerSearchInput] = useState('')

  const handleAddPartnerClick = (partner: Dealer) => {
    onSelectedPartners(partner)
    // Reset the text input filter
    // eslint-disable-next-line no-unused-expressions
    searchServicePartnersRef.current?.resetTextInputFilter?.()
  }

  const handleRemovePartnerClick = (partnerToRemove: Dealer) => {
    onRemoveSelectedPartners(partnerToRemove)
  }

  return (
    <>
      {label && <FormInputLabel htmlFor={id}>{label}</FormInputLabel>}
      <SearchServicePartnersInput
        id={id}
        ref={searchServicePartnersRef}
        disabled={disabled}
        onDataFetch={(servicePartnersData, searchString) => {
          setServicePartnersDataResults(servicePartnersData.results)
          setServicePartnerSearchInput(searchString)
        }}
        filters={filters}
      />

      {servicePartnerSearchInput.length > 2 && (
        <Box
          sx={{
            maxHeight: '144px',
            overflowX: 'auto',
            border: '1px solid',
            borderColor: COLORS.MEDIUM_GRAY,
            padding: 3,
            marginTop: 3,
          }}
        >
          {servicePartnersDataResults?.map((partner: Dealer) => (
            <Box
              key={partner.code}
              sx={{ marginBottom: 2, '&:last-child': { marginBottom: 0 } }}
            >
              <ClickableWrapper onClick={() => handleAddPartnerClick(partner)}>
                <Text
                  variant="smallText"
                  color={COLORS.DARK}
                  sx={{ '&:hover': { color: COLORS.DAF_BLUE } }}
                >
                  {partner.name}
                </Text>
              </ClickableWrapper>
            </Box>
          ))}
          {servicePartnersDataResults?.length === 0 && (
            <Text variant="smallText" color={COLORS.DARK}>
              {i18next.t('commonLabels.noResultsFoundPleaseTryAgain')}
            </Text>
          )}
        </Box>
      )}

      {infoMessage && (
        <Box sx={{ marginTop: 3 }}>
          <Text variant="smallText" color={COLORS.GRAY} sx={{}}>
            {i18next.t('formLabels.numberOfServicePartnersToAdd')}
          </Text>
        </Box>
      )}

      {!!partners.length && (
        <Box sx={{ marginTop: 3 }}>
          <PartnerTileList
            partners={partners}
            removePartner={handleRemovePartnerClick}
          />
        </Box>
      )}
    </>
  )
}

export default SearchServicePartners
