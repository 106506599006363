import React, { FC } from 'react'
import { useDialogs } from '../../hooks/useDialogs'
import { FilterFunc } from '../../types/closureTypes'
import { DialogRenderedItem, DialogSelector } from '../../types/dialogTypes'
import DialogItemRenderer from './DialogItemRenderer'

interface QueueDialogRendererProps {
  selector: DialogSelector
  limit?: number
}

const filterByHash: FilterFunc<DialogRenderedItem> = ({
  hash,
}: DialogRenderedItem): boolean => {
  if (typeof window === 'undefined') return false
  return !window.localStorage.getItem(hash)
}

export const QueueDialogRenderer: FC<QueueDialogRendererProps> = ({
  selector,
  limit,
}) => {
  const [activeDialog] = useDialogs(selector, {
    limit,
    filter: filterByHash,
  })

  if (!activeDialog) {
    return null
  }

  return <DialogItemRenderer dialogs={[activeDialog]} />
}
