import React, { FC } from 'react'
import { BoxProps } from 'theme-ui'
import PartLineTitle from '../PartLine/PartLineTitle'

const TableColumnTitle: FC<Omit<BoxProps, 'css'>> = ({ children, sx, ...props }) => (
  <PartLineTitle
    sx={{ display: 'table-cell', paddingY: '12px', px: '6px', ...sx }}
    {...props}
  >
    {children}
  </PartLineTitle>
)

export default TableColumnTitle
