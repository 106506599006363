import React, { FC } from 'react'
import { Box, FlexProps } from 'theme-ui'

interface YouTubeVideoPlayIconProps
  extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {}

export const YouTubeVideoPlayIcon: FC<YouTubeVideoPlayIconProps> = ({
  sx,
  ...boxProps
}) => (
  <Box
    sx={{
      color: 'textLight',
      ...sx,
    }}
    {...boxProps}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 140 140"
      height="80"
      width="80"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M70 140c38.7 0 70-31.3 70-70S108.7 0 70 0 0 31.3 0 70s31.3 70 70 70zM55.7 40.4l43.2 27.1c.4.2.6.5.9.9.2.4.3.8.3 1.2 0 .4-.1.8-.3 1.2s-.5.7-.9.9l-43.2 27c-.4.2-.8.4-1.2.4-.4 0-.8-.1-1.2-.3-.4-.2-.7-.5-.9-.9-.3-.5-.4-.9-.4-1.3V42.5c0-.4.1-.9.3-1.2.2-.4.5-.7.9-.9.4-.2.8-.3 1.2-.3s.9 0 1.3.3z"
        clipRule="evenodd"
        opacity=".9"
      />
    </svg>
  </Box>
)

export default YouTubeVideoPlayIcon
