import i18next from 'i18next'
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'theme-ui'
import { registerModalNotification } from '../../../../actions/notificationActions'
import { setOrder } from '../../../../actions/shoppingBasket/wizard'
import useCommerceApiFetchService from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import useCommerceApiPostService from '../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { RootState } from '../../../../reducers'
import {
  OrderWayOfTransportId,
  OrderWayOfTransportProps,
  putOrderWayOfTransport,
} from '../../../../services/rest/ecommerce/order/order-way-of-transport'
import {
  getOrder,
  OrderRequestProps,
} from '../../../../services/rest/ecommerce/order/orders'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'
import Button from '../../../atoms/Button/Button'
import Spinner from '../../../atoms/Spinner/Spinner'
import OrderWayOfTransportList from '../../ShoppingBasketWizard/OrderInformation/OrderWayOfTransportList'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'
import SidePanelFooter from '../SidePanelFooter'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { getEnglishCategoryName } from '../../../../helpers/categoryHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'

export interface ShoppingBasketWayOfTransportSidePaneProps {
  active: boolean
  onClose: () => void
}

const ShoppingBasketWayOfTransportSidePanel: FC<
  ShoppingBasketWayOfTransportSidePaneProps
> = ({ active, onClose }) => {
  const dispatch = useDispatch()
  const { categories } = useContext(CategoriesContext)
  const order = useSelector((state: RootState) => state.shoppingBasketWizard.order)
  const [selectedTransportId, setSelectedTransportId] = useState<number | undefined>(
    undefined
  )

  const transportOptions = useMemo(() => order?.wayOfTransportOptions || [], [order])

  const [posting, response, post] = useCommerceApiPostService<
    OrderWayOfTransportProps,
    CommerceResponse<OrderWayOfTransportId>
  >(putOrderWayOfTransport)

  const [fetching, orderResponse, fetchOrder] = useCommerceApiFetchService<
    OrderRequestProps,
    CommerceResponse<CommerceOrder>
  >(getOrder)

  const saveWayOfTransport = useCallback(() => {
    if (order) {
      const transportData = order.wayOfTransportOptions?.find(
        (option) => option.transportId === selectedTransportId
      )
      pushToDataLayer({
        event: DATA_LAYER.EVENT_NAME.ADD_SHIPPING_INFO,
        ecommerce: {
          shipping_tier: `${transportData?.method} (${transportData?.transportId})`,
          items: order.items?.map((item) => ({
            item_id: item.partNumber,
            item_name: item.englishDescription,
            item_brand: item.brand?.description,
            item_category: getEnglishCategoryName(item.category, categories),
            item_list_name: DATA_LAYER.EVENT_NAME.SHOPPING_BASKET,
            doa_product: item.type === 'DealerOwnAssortment',
            quantity: item.quantity,
          })),
        },
      })
      post({ transportId: selectedTransportId, orderNumber: order.number })
    }
  }, [order, post, selectedTransportId])

  const onOrderResult = useCallback(
    (newOrder: CommerceOrder | null) => {
      if (newOrder) dispatch(setOrder(newOrder))

      onClose()
    },
    [dispatch, onClose]
  )

  const setInitialSelectedTransportId = useCallback(() => {
    if (order) {
      const selectedTransportOption = order.wayOfTransportOptions?.find(
        (option) => option.isSelectedOption
      )

      setSelectedTransportId(selectedTransportOption?.transportId)
    }
  }, [order])

  const closeAndReset = useCallback(() => {
    setInitialSelectedTransportId()

    onClose()
  }, [onClose, setInitialSelectedTransportId])

  useCommerceApiResponse<OrderWayOfTransportId>({
    response,
    onResult: () => {
      fetchOrder({ orderNumber: order?.number })
    },
    onMessage: (message) => {
      if (message.severity === 'Error' && !!message?.message?.length) {
        dispatch(registerModalNotification('Error', message.message))
      }
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as OrderWayOfTransportId,
  })

  useCommerceApiResponse<CommerceOrder>({
    response: orderResponse,
    onResult: onOrderResult,
    onMessage: () => undefined,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrder,
  })

  useEffect(() => {
    setInitialSelectedTransportId()
  }, [setInitialSelectedTransportId])

  return (
    <SidePanel
      active={active}
      onClose={closeAndReset}
      position="right"
      title={i18next.t('shoppingLabels.deliveryLabels.transportOptions')}
    >
      <SidePanelBody>
        <OrderWayOfTransportList
          onSelect={(id) => setSelectedTransportId(id)}
          wayOfTransportOptions={transportOptions}
          selectedTransportId={selectedTransportId}
        />
      </SidePanelBody>

      <SidePanelFooter>
        <Button
          variant="primary"
          onClick={saveWayOfTransport}
          disabled={posting || fetching}
          sx={{ width: '100%' }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              span: { marginLeft: 1 },
            }}
          >
            {i18next.t('commonLabels.apply')}

            {(posting || fetching) && (
              <span>
                <Spinner size={2} color="currentColor" />
              </span>
            )}
          </Flex>
        </Button>
      </SidePanelFooter>
    </SidePanel>
  )
}

export default ShoppingBasketWayOfTransportSidePanel
