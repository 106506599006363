import React from 'react'
import { Text } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'

interface TitleDatasource {
  text?: TextField
}

const Title = ({ datasource: { text } }: Datasource<TitleDatasource>) => {
  return (
    <Text
      as="h2" // extend in sitecore when needed
      color="text"
      sx={{
        fontSize: [4, 4, 5],
        fontWeight: 'normal',
        marginBlockEnd: 3,
      }}
    >
      {text?.value}
    </Text>
  )
}

export default withData(Title)
