import { ShoppingBasketWizardState } from '.'
import { CommerceOrderItem } from '../../../types/commerceApi'

const replaceOrderItemReference = (
  state: ShoppingBasketWizardState,
  payload: CommerceOrderItem
) => {
  if (state.order?.items) {
    return state.order.items.map((item) => {
      if (item.partNumber === payload.partNumber) {
        return payload
      }

      return item
    })
  }

  return state.order?.items || []
}

export default (state: ShoppingBasketWizardState, payload: CommerceOrderItem) => {
  if (!state?.order) return state

  return {
    ...state,
    order: {
      ...state.order,
      items: replaceOrderItemReference(state, payload),
    },
  }
}
