import React, { FC } from 'react'
import usePromotionService from '../../../hooks/services/rest/ecommerce/usePromotionService'
import { PardCardLayoutServiceData } from '../../molecules/Card/PartCard/PartCard'
import {
  ApiVariant,
  Datasource,
  Flag,
  LinkField,
  Params,
  SliderVariant,
  TextField,
} from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import usePartsListService from '../../../hooks/services/graphql/usePartListService'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import PartSliderSkeleton from '../../atoms/Skeleton/PartSliderSkeleton'
import PartCardListResponsive from '../CardList/PartCardListResponsive'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'

interface PartSliderDatasource {
  title: TextField
  link?: LinkField
}

interface PartSliderParams {
  variant: SliderVariant
  apiVariant: ApiVariant
  topSpacing: Flag
  bottomSpacing: Flag
}

const PartPromotionsSlider: FC<
  Datasource<PartSliderDatasource> &
    PardCardLayoutServiceData &
    Params<PartSliderParams>
> = ({
  datasource: { title, link },
  params: { variant, topSpacing, bottomSpacing, apiVariant },
  partPage,
}) => {
  const [fetchingPromotionParts, promotionPartNumbers] =
    usePromotionService(apiVariant)
  const [fetchingPartList, partList] = usePartsListService(promotionPartNumbers)

  if (!partList?.length) return null

  return (
    <PartCardListResponsive
      title={getTextFieldValue(title)}
      link={link}
      variant={variant}
      partList={partList}
      partPage={partPage}
      fetching={fetchingPromotionParts || fetchingPartList}
      topSpacing={topSpacing === '1'}
      bottomSpacing={bottomSpacing === '1'}
      dataLayerComponentOrigin={DATA_LAYER.COMPONENT_ORIGIN.PART_PROMOTIONS_SLIDER}
    />
  )
}

export default withData(PartPromotionsSlider, {
  pageEditingComponent: PartSliderSkeleton,
  showPageEditingComponentWhenPreviewing: true,
})
