import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'

const BasicInformationDescription: FC<
  { description?: string } & Omit<TextProps, 'css'>
> = ({ description, ...props }) => {
  if (!description) return null

  return (
    <Text as="h1" variant="heading1" mb={3} {...props}>
      {description}
    </Text>
  )
}

export default BasicInformationDescription
