import { useTheme } from '@emotion/react'
import React, { FC, useContext, useMemo } from 'react'
import { Box, BoxProps } from 'theme-ui'
import { loggedIn } from '../../../helpers/userHelper'
import { UserContext } from '../../../providers/UserProvider'
import { UserProps } from '../../../types/userProps'
import { getDividerSx } from '../../atoms/Divider/Divider'

export interface PartLineGridTemplate {
  columnSizes?: string | string[]
  columnSizesLoggedIn?: string | string[]
}

interface PartLineProps extends Omit<BoxProps, 'css'> {
  isAlternative?: boolean
  gridTemplate?: PartLineGridTemplate
}

export const getGridTemplateColumns = (
  gridTemplate?: PartLineGridTemplate,
  user?: UserProps
) =>
  (loggedIn(user) ? gridTemplate?.columnSizesLoggedIn : gridTemplate?.columnSizes) ??
  gridTemplate?.columnSizes

const PartLine: FC<PartLineProps> = ({
  isAlternative,
  gridTemplate,
  children,
  sx,
  ...boxProps
}) => {
  const { user } = useContext(UserContext)
  const { space } = useTheme()

  const gridTemplateColumns = useMemo(
    () => getGridTemplateColumns(gridTemplate, user),
    [gridTemplate, user]
  )

  return (
    <Box
      sx={{
        display: 'grid',
        py: 4,
        gridTemplateColumns,
        alignItems: 'start',
        gap: [`${space[3]}px`, `${space[3]}px`, `0 ${space[2]}px`],
        ...(isAlternative
          ? {
              paddingBottom: 0,
            }
          : getDividerSx('bottom')),
        ...sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default PartLine
