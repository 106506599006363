import React, { useEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { Filter } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import MyFleetListFilterGroup from './MyFleetListFilterGroup'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import {
  FILTER_GROUP_FINANCE,
  FILTER_GROUP_SERVICE_PLANS,
  FILTER_GROUP_VEHICLE_ACTION_STATUS,
} from '../../../../constants/dafCompanyVehicleConstants'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'

interface MyFleetListFiltersListProps {
  filterGroups?: Filter[]
}
const MyFleetListFiltersList = ({ filterGroups }: MyFleetListFiltersListProps) => {
  const {
    site: { name },
  } = useSitecoreContext()

  const [filteredFilterGroups, setFilteredFilterGroups] = useState(filterGroups)

  useEffect(() => {
    if (name !== MULTISUPPORT_SITE_CONTEXT) {
      setFilteredFilterGroups(
        filterGroups?.filter(
          (fg) =>
            fg.id !== FILTER_GROUP_FINANCE &&
            fg.id !== FILTER_GROUP_SERVICE_PLANS &&
            fg.id !== FILTER_GROUP_VEHICLE_ACTION_STATUS
        )
      )
    }
  }, [filterGroups, name])

  return (
    <Box sx={{ marginTop: 6 }}>
      <Box>
        <Text
          variant="bodyContent"
          sx={{
            fontWeight: 'bold',
          }}
        >
          {i18next.t('myFleetListSortAndFilterLabels.filtersLabel')}
        </Text>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        {filteredFilterGroups?.map((filter) => (
          <MyFleetListFilterGroup key={filter.id} filterOptions={filter} />
        ))}
      </Box>
    </Box>
  )
}

export default MyFleetListFiltersList
