import React, { useCallback } from 'react'
import i18next from 'i18next'
import { Field } from 'react-final-form'
import { TextField } from './TextField'
import { BaseFieldProps } from './BaseField'
import toCamelCase from '../../../helpers/camelCaseHelper'
import {
  dateShouldNotBeInThePast,
  required,
  shouldBeInTheFuture,
  shouldBeInThePast,
  ValidationRules,
} from '../../../helpers/formFieldHelper'

interface InputDateFieldProps extends BaseFieldProps {
  value?: string
  label?: string
  disabled?: boolean
  validationRules: ValidationRules[]
}

type ValidatorFn = (value: string) => string | undefined

export const InputDateField = ({
  name,
  value,
  label,
  disabled,
  validationRules,
}: InputDateFieldProps) => {
  const i18nFormErrorFormatter = useCallback(
    (ruleName: string, args: string[]) =>
      i18next.t(`commonFormLabels.${toCamelCase(ruleName)}`, { ...args }),
    []
  )

  const validationRuleNames: { [key: string]: ValidatorFn } = {
    [ValidationRules.Required]: useCallback(
      (val: string) => required(i18nFormErrorFormatter)(val),
      [i18nFormErrorFormatter]
    ),
    [ValidationRules.ShouldBeInThePast]: useCallback(
      (val: string) => shouldBeInThePast(i18nFormErrorFormatter)(val),
      [i18nFormErrorFormatter]
    ),
    [ValidationRules.ShouldBeInTheFuture]: useCallback(
      (val: string) => shouldBeInTheFuture(i18nFormErrorFormatter)(val),
      [i18nFormErrorFormatter]
    ),
    [ValidationRules.ShouldBeTodayOrInTheFuture]: useCallback(
      (val: string) => dateShouldNotBeInThePast(i18nFormErrorFormatter)(val),
      [i18nFormErrorFormatter]
    ),
  }

  const composeValidators =
    (...validators: ValidatorFn[]): ValidatorFn =>
    (val) =>
      validators.reduce(
        (error: string | undefined, validator: ValidatorFn) =>
          error || validator(val),
        undefined
      )

  const validatorFunctions = validationRules.map((rule) => validationRuleNames[rule])

  return (
    <Field name={name} initialValue={value}>
      {({ input }) => (
        <TextField
          {...input}
          disabled={disabled}
          validate={composeValidators(...validatorFunctions)}
          type="date"
          label={label}
        />
      )}
    </Field>
  )
}
