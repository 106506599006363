import { useTheme } from '@emotion/react'
import React, { FC } from 'react'
import Link, { LinkProps } from '../../../atoms/Link/Link'
import Highlight from '../../../atoms/Highlight/Highlight'

interface AutoSuggestionItemProps extends LinkProps {
  searchString: string
  suggestionText: string
}

const AutoSuggestionItem: FC<AutoSuggestionItemProps> = ({
  searchString,
  suggestionText,
  sx,
  ...rest
}) => {
  const { colors } = useTheme()

  return (
    <Link
      sx={{
        py: 2,
        fontSize: '14px',
        display: 'block',
        width: '100%',
        outline: 'none',
        color: 'text',
        ':hover, :active, :focus': {
          color: `${colors.primary} !important`,
        },
        ...sx,
      }}
      {...rest}
    >
      <Highlight search={searchString}>{suggestionText}</Highlight>
    </Link>
  )
}

export default AutoSuggestionItem
