import React from 'react'
import { Box } from 'theme-ui'
import { COLORS } from '../../../constants/themeConstants'
import { alphaColor } from '../../../helpers/themeHelper'

interface Props {
  children: React.ReactNode
}

export const SidePanelMain = ({ children }: Props) => (
  <Box
    sx={{
      flexGrow: 1,
      overflow: 'auto',
      background: `
        linear-gradient(
            ${COLORS.WHITE} 30%,
            ${alphaColor(COLORS.WHITE, 0)}
        ) center top,
        /* Shadow Cover BOTTOM */
        linear-gradient(
            ${alphaColor(COLORS.WHITE, 0)},
            ${COLORS.WHITE} 70%
        ) center bottom,
    
        /* Shadow TOP */
        radial-gradient(
            farthest-side at 50% 0,
            ${alphaColor(COLORS.BLACK, 0.2)},
            ${alphaColor(COLORS.BLACK, 0.001)}
        ) center top,
    
        /* Shadow BOTTOM */
        radial-gradient(
            farthest-side at 50% 100%,
            ${alphaColor(COLORS.BLACK, 0.2)},
            ${alphaColor(COLORS.BLACK, 0.001)}
        ) center bottom;
    
        background-repeat: no-repeat;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        background-attachment: local, local, scroll, scroll;`,
    }}
  >
    {children}
  </Box>
)

export default SidePanelMain
