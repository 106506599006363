import {
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS,
  VEHICLE_UPGRADE_ATTENTION,
  VEHICLE_UPGRADE_IMPORTANT,
  VEHICLE_UPGRADE_WARNING,
} from '../constants/dafCompanyVehicleConstants'
import { COLORS } from '../constants/themeConstants'

export const getMaintenanceColor = (item: string) => {
  if (item === MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH) {
    return COLORS.PROMOTION
  }

  if (item === MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS) {
    return COLORS.ORANGE_DARK
  }
  return COLORS.ALERT
}

export const getVehicleUpgradeColor = (item: string) => {
  if (item === VEHICLE_UPGRADE_WARNING) {
    return COLORS.ALERT
  }

  if (item === VEHICLE_UPGRADE_IMPORTANT) {
    return COLORS.PROMOTION
  }

  if (item === VEHICLE_UPGRADE_ATTENTION) {
    return COLORS.ORANGE_DARK
  }

  return COLORS.GRAY
}
