import { loader } from 'graphql.macro'
import { useCallback } from 'react'
import { RequestInit } from 'graphql-request/build/esm/types.dom'
import { DiscountType } from '../../../constants/promotionConstants'
import useBaseGraphQLService from './core/useBaseGraphQLService'
import useLazyQuery from './core/useLazyQuery'
import useIsMounted from '../../useIsMounted'
import { MAX_NUMBER_OF_PARTS } from '../../../constants/partSalesInformationConstants'
import { orderStockLocationsByPriority } from '../../../helpers/stockHelper'

const GET_PART_SALES_INFORMATION = loader(
  './schemas/partListSalesInformationQuery.graphql'
)

export interface AvailableCoupon {
  couponNumber?: string
  currencyCode?: string
  discountValue?: number
  endDate?: string
  numberOfCoupons: number
}

export type Program = 'SalesPromotion' | 'NextPromotion'

export type Applicability = 'Online' | 'Offline' | 'OnlineAndOffline'

interface MaxProgram {
  availableCoupon?: AvailableCoupon[]
}

interface CouponInformation {
  maxProgram?: MaxProgram
}

export interface PromotionInformation {
  discountType?: DiscountType
  discountValue?: number
  endDate?: string
  isPromoted: boolean
  program: Program
  applicability: Applicability
}

interface QuantityBreak {
  minQuantityInclusive?: number
  rushOrderUnitPrice?: number
  stockOrderUnitPrice?: number
}

interface Quantum {
  currencyCode?: string
  endDate?: string
  quantityBreaks?: QuantityBreak[]
}

interface SpecialPrice {
  currencyCode?: string
  endDate?: string
  priceType?: string
  priceTypeDescription?: string
  referenceNumber?: string
  rushOrderUnitPrice?: number
  stockOrderUnitPrice?: number
}

export interface Price {
  couponInformation?: CouponInformation
  currencyCode?: string
  netTotalPrice?: number
  netUnitPrice?: number
  promotionInformation?: PromotionInformation
  quantumPrice?: Quantum
  retailTotalPrice?: number
  retailUnitPrice?: number
  specialPrice?: SpecialPrice
  quotationUnitPrice?: number
}

export interface PriceInformation {
  partNumber?: string
  price?: Price
  quantity: number
}

export interface PartNumberQuantityInput {
  partNumber?: string
  quantity?: number
}

interface ConsignmentStockInformation {
  binLocation?: string
  registeredLevel?: number
}

export interface StockLocation {
  availabilityTime?: number | null
  consignmentStockInformation?: ConsignmentStockInformation | null
  locationId?: string
  quantity: number
  priority: number
  // todo: change to one of constants
  deliveryPrescriptionCode?: string
}

export interface StockInformation {
  partNumber?: string
  quantity: number
  stockLocations: StockLocation[]
}

interface PartSalesInformationQueryVariables {
  [key: string]: any
  partNumbersQuantity: PartNumberQuantityInput[]
  cultureCode: string
  customerCompanyId?: number
  supplierCompanyId?: number
  isImpersonated?: boolean
  includePrice?: boolean
  includeStock?: boolean
}

export interface PartSalesInformation {
  priceInformationList?: PriceInformation[]
  stockInformationList?: StockInformation[]
}

const orderStockLocationList = (stockInformationList?: StockInformation[]) => {
  if (!stockInformationList?.length) return undefined

  return stockInformationList.reduce<StockInformation[]>(
    (a, b) => [
      ...a,
      {
        ...orderStockLocationsByPriority(b),
      },
    ],
    []
  )
}

const usePartSalesInformationService = (
  includePrice = false,
  includeStock = false,
  onDataCallback: (data: PartSalesInformation) => void
): [(partNumbersQuantity?: PartNumberQuantityInput[]) => void, boolean] => {
  const isMounted = useIsMounted()
  const [baseHeaders, baseVariables] = useBaseGraphQLService({
    requireAuthentication: true,
  })

  const onData = useCallback(
    (data?: PartSalesInformation) => {
      let priceInformationList

      const stockInformationList = orderStockLocationList(data?.stockInformationList)

      if (data?.priceInformationList?.length) {
        priceInformationList = data.priceInformationList
      }

      onDataCallback({ priceInformationList, stockInformationList })
    },
    [onDataCallback]
  )

  const [fetch, fetching] = useLazyQuery<
    PartSalesInformation,
    PartSalesInformationQueryVariables
  >(onData)

  const fetchLazy = useCallback(
    (partNumbersQuantity?: PartNumberQuantityInput[]) => {
      const abortController = new AbortController()

      if (
        isMounted() &&
        baseVariables &&
        partNumbersQuantity?.length &&
        (includePrice || includeStock)
      ) {
        const limitedPartNumbersQuantity = partNumbersQuantity.slice(
          0,
          MAX_NUMBER_OF_PARTS
        )

        fetch({
          document: GET_PART_SALES_INFORMATION,
          variables: {
            ...baseVariables,
            partNumbersQuantity: limitedPartNumbersQuantity,
            includePrice,
            includeStock,
          },
          requestHeaders: {
            ...baseHeaders,
          },
          signal: abortController.signal as NonNullable<RequestInit['signal']>,
        })
      }

      return () => {
        abortController.abort()
      }
    },
    [isMounted, baseVariables, includePrice, includeStock, fetch, baseHeaders]
  )

  return [fetchLazy, fetching]
}

export default usePartSalesInformationService
