import { Filter, Option } from '../services/rest/ecommerce/dafCompanyVehicles'
import toCamelCase from './camelCaseHelper'

interface FindFilterByValueProps {
  filterGroups: Filter[]
  value: string
}

export const findFilterIdByValue = ({
  filterGroups,
  value,
}: FindFilterByValueProps) => {
  const filterGroupItem = filterGroups.find((item) =>
    item.options.some((option) => option.value === value)
  )

  if (!filterGroupItem) return null

  const filterOption = filterGroupItem.options.find(
    (option: Option) => option.value === value
  )

  if (!filterOption) return null

  const filterOptionId = toCamelCase(filterOption.id)

  return filterOptionId
}
