import { COLORS } from '../constants/themeConstants'
import { alphaColor } from './themeHelper'

export const getBorderColor = (error: boolean, focused: boolean): string => {
  if (error) return 'error'
  return focused ? 'gray.2' : COLORS.DARK_GRAY
}

export const getTextColor = (error: boolean, disabled = false): string => {
  if (error) return 'error'
  return disabled ? 'gray.1' : 'dark.2'
}

export const getBackgroundColor = (disabled = false): string =>
  disabled ? alphaColor(COLORS.LIGHT_GRAY, 0.4) : 'transparent'
