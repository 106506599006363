import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'

interface BasicInformationBrandNameProps extends Omit<TextProps, 'css'> {
  name?: string
}

const BasicInformationBrandName: FC<BasicInformationBrandNameProps> = ({
  name,
  ...props
}) => {
  if (!name) return null

  return (
    <Text
      variant="bodyCompact"
      sx={{ display: 'block', marginBottom: 3 }}
      {...props}
    >
      {name}
    </Text>
  )
}

export default BasicInformationBrandName
