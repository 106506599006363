import React, { FC, useCallback } from 'react'
import Button from '../../../atoms/Button/Button'

interface MyFleetListFilterChipProps {
  label: string
  selectionCount?: number
  showSelectionCount?: boolean
  onClick: () => void
}

const MyFleetListFilterChip: FC<MyFleetListFilterChipProps> = ({
  label,
  selectionCount = 0,
  showSelectionCount = true,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick()
    // dependencies (englishDescription & partContext) are available on page load and will not change during the lifecycle of the component
  }, [onClick])

  return (
    <Button
      variant={selectionCount >= 1 ? 'primary' : 'outline'}
      sx={{ mr: 2, mb: 2, display: 'inline-block', ':last-child': { mr: 0 } }}
      onClick={handleClick}
    >
      {selectionCount && showSelectionCount ? `${label}: ${selectionCount}` : label}
    </Button>
  )
}

export default MyFleetListFilterChip
