import { MultilistField, RichTextField, TextField } from '../types/layoutService'

export const getEditableTextFieldValue = (textField?: RichTextField): string =>
  textField?.jsonValue?.value || ''

export const getTextFieldValue = (textField: TextField): string =>
  textField?.value || ''

export const getMultiListFieldItems = <T>(multiListField?: MultilistField<T>): T[] =>
  multiListField?.targetItems || []
