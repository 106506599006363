import React, { FC } from 'react'
import { Text, TextProps } from 'theme-ui'

interface TagProps extends Omit<TextProps, 'css'> {
  label: string
}

const Tag: FC<TagProps> = ({ label, sx, ...textProps }) => (
  <Text
    as="span"
    variant="textSmall"
    sx={{
      bg: 'promotion.0',
      display: 'inline-block',
      paddingY: 1,
      paddingX: 2,
      fontSize: 0,
      color: 'textLight',
      borderRadius: '16px',
      lineHeight: '12px',
      ...sx,
    }}
    {...textProps}
  >
    {label}
  </Text>
)

export default Tag
