import { Action } from 'redux'
import { TOOLTIP_REGISTER, TOOLTIP_UNREGISTER } from '../constants/tooltipConstants'
import { TooltipState } from '../reducers/tooltipReducer'

interface TooltipRegisterActionPayload {
  name: string
  data: TooltipState
}

interface TooltipRegisterAction extends Action<typeof TOOLTIP_REGISTER> {
  payload: TooltipRegisterActionPayload
}

interface TooltipUnregisterAction extends Action<typeof TOOLTIP_UNREGISTER> {
  payload: string
}

export type TooltipActions = TooltipRegisterAction | TooltipUnregisterAction

export const tooltipRegister = (
  payload: TooltipRegisterActionPayload
): TooltipRegisterAction => ({
  type: TOOLTIP_REGISTER,
  payload,
})

export const tooltipUnregister = (payload: string): TooltipUnregisterAction => ({
  type: TOOLTIP_UNREGISTER,
  payload,
})
