import React, { ReactNode } from 'react'
import { useTheme } from '@emotion/react'
import { Flex } from 'theme-ui'

interface GridRowProps {
  children: ReactNode
}

const GridRow = ({ children }: GridRowProps) => {
  const { space } = useTheme()

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row', 'row'],
        mx: [-2, -2, -(space[4] / 2)],
        pb: [0, 3, 4],
        alignItems: 'stretch',
        ':last-child': {
          pb: 0,
        },
      }}
    >
      {children}
    </Flex>
  )
}
export default GridRow
