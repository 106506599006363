import i18next from 'i18next'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShoppingBasketSidePanelState } from '../../../../actions/shoppingBasket/sidePanelActions'
import { tooltipRegister } from '../../../../actions/tooltipActions'
import { ICONS } from '../../../../constants/iconConstants'
import { MENU_ITEM_ID_UPLOAD_PART_FILE } from '../../../../constants/menuServiceConstants'
import { TOOLTIP_BASKET_SIDEPANEL } from '../../../../constants/tooltipConstants'
import { USER_ROLES } from '../../../../constants/userConstants'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import {
  getBasketMenuItem,
  getBasketOpenOrderItem,
} from '../../../../helpers/menuHelper'
import { hasRole } from '../../../../helpers/userHelper'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { MenuProviderContext } from '../../../../providers/MenuProvider'
import { UserContext } from '../../../../providers/UserProvider'
import { RootState } from '../../../../reducers'
import { TextField } from '../../../../types/layoutService'
import SitecoreButton from '../../../molecules/SitecoreButton/SitecoreButton'
import ShoppingBasketDetails from '../../ShoppingBasket/ShoppingBasketDetails'
import ShoppingBasketQuickLinks from '../../ShoppingBasket/ShoppingBasketQuickLinks'
import ShoppingBasketQuickOrder from '../../ShoppingBasket/ShoppingBasketQuickOrder'
import SidePanel from '../SidePanel'
import SidePanelBody from '../SidePanelBody'
import SidePanelSection from '../SidePanelSection'
import { CommerceOrderItem } from '../../../../types/commerceApi'

export interface ShippingBasketSidePanelProps {
  title: string
  basketButtonLabel: TextField
}

const ShippingBasketSidePanel: FC<ShippingBasketSidePanelProps> = ({
  title,
  basketButtonLabel,
}) => {
  const { user } = useContext(UserContext)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { menu } = useContext(MenuProviderContext)
  const [totalPartsAdded, setTotalPartsAdded] = useState(0)
  const { active: shoppingBasketActive, orderSummary } = useSelector(
    (state: RootState) => state.shoppingBasketSidePanel
  )

  const dispatch = useDispatch()

  const uploadFile = useMemo(
    () => getBasketMenuItem(MENU_ITEM_ID_UPLOAD_PART_FILE, menu),
    [menu]
  )
  const openOrder = useMemo(() => getBasketOpenOrderItem(menu), [menu])

  const onSidePanelClose = useCallback(() => {
    dispatch(setShoppingBasketSidePanelState(false))

    if (totalPartsAdded > 0) {
      dispatch(
        tooltipRegister({
          name: TOOLTIP_BASKET_SIDEPANEL,
          data: {
            icon: ICONS.CHECK,
            label: i18next.t('shoppingLabels.tooltips.quickOrderSummary', {
              count: totalPartsAdded,
              number: totalPartsAdded,
            }),
            actionLabel: i18next.t('shoppingLabels.tooltips.goToShoppingBasket'),
            actionLink: openOrder?.url,
          },
        })
      )
      setTotalPartsAdded(0)
    }
  }, [dispatch, openOrder, totalPartsAdded])

  if (!hasRole(user, USER_ROLES.commerceShoppingBasket) && !isImpersonated) {
    return null
  }

  return (
    <SidePanel
      active={shoppingBasketActive}
      onClose={onSidePanelClose}
      title={title}
      position="right"
    >
      <SidePanelBody>
        <SidePanelSection
          title={
            orderSummary && orderSummary?.totalNumberOfItems
              ? i18next.t('commonLabels.details')
              : ''
          }
        >
          <ShoppingBasketDetails orderSummary={orderSummary} />

          <ShoppingBasketQuickOrder
            fileUploadUrl={uploadFile?.url}
            onOrder={(orderItem: CommerceOrderItem) =>
              setTotalPartsAdded(totalPartsAdded + orderItem.quantity)
            }
          />

          {openOrder && (
            <SitecoreButton
              data-t-id="shopping-basket-open-basket"
              variant="primary"
              linkField={{
                url: openOrder.url,
                text: getTextFieldValue(basketButtonLabel),
              }}
              sx={{
                marginTop: 5,
                width: '100%',
                ':hover, :focus': { color: '#ffffff !important' }, // override color for buttons / anchors in MainNavigationContainer
              }}
            />
          )}
        </SidePanelSection>

        <ShoppingBasketQuickLinks />
      </SidePanelBody>
    </SidePanel>
  )
}

export default ShippingBasketSidePanel
