import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchContext } from './SearchContextProvider'
import useAssemblySearchService, {
  AssemblyFulfilled,
} from '../hooks/services/rest/ecommerce/useAssemblySearchService'
import { getSearchViewTypeFromUrl } from '../helpers/partContextHelper'
import { VIEW_TYPE_DEFAULT } from '../constants/searchConstants'

export interface AssemblySearchContextProps {
  fetching: boolean
  assemblies?: AssemblyFulfilled[]
  totalCount?: number
  viewType?: string
}

export const AssemblySearchContext = createContext<AssemblySearchContextProps>({
  fetching: false,
  assemblies: undefined,
  totalCount: undefined,
  viewType: undefined,
})

interface AssemblySearchContextProviderProps {
  children: ReactNode
}

const AssemblySearchContextProvider = ({
  children,
}: AssemblySearchContextProviderProps) => {
  const { search } = useLocation()
  const {
    searchString,
    viewType: contextViewType,
    category,
    subcategory,
  } = useContext(SearchContext)

  const [fetchingAssemblies, assemblies, totalCount] = useAssemblySearchService(
    searchString,
    category?.id,
    subcategory?.id
  )

  const viewType = useMemo(
    () =>
      getSearchViewTypeFromUrl(new URLSearchParams(search)) ||
      contextViewType ||
      VIEW_TYPE_DEFAULT,
    // contextViewType are constant and available on page load

    [search]
  )

  return (
    <AssemblySearchContext.Provider
      value={{
        fetching: fetchingAssemblies,
        assemblies,
        totalCount,
        viewType,
      }}
    >
      {children}
    </AssemblySearchContext.Provider>
  )
}
export default AssemblySearchContextProvider
