import React, { FormEvent, forwardRef, ReactNode, useCallback } from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import useSitecoreContext from '../../../hooks/useSitecoreContext'

interface SearchBarFormProps {
  action?: string
  children?: ReactNode
  onSubmit?: (e: FormEvent) => void
  sx: ThemeUIStyleObject
}

const SearchBarForm = forwardRef<HTMLFormElement, SearchBarFormProps>(
  ({ children, onSubmit, sx, ...boxProps }, ref) => {
    const { pageEditing } = useSitecoreContext()

    const formSubmit = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        if (pageEditing) {
          e.preventDefault()
          return
        }

        if (onSubmit) onSubmit(e)
      },
      [onSubmit]
    )

    return (
      <form method="get" ref={ref} onSubmit={formSubmit}>
        <Box
          sx={{
            bg: 'background',
            position: 'relative',
            borderRadius: '40px',
            ...sx,
          }}
          {...boxProps}
        >
          {children}
        </Box>
      </form>
    )
  }
)

export default SearchBarForm
