import { AxiosResponse } from 'axios'
import { HEADER_SESSION_ID } from '../../../../constants/httpConstants'
import { DEFAULT_CULTURE_CODE } from '../../../../constants/userConstants'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import { RequestFunction } from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceResponse, CommerceOrder } from '../../../../types/commerceApi'

export interface CreateOrderProps {}

export interface OrderRequestProps {
  orderNumber?: string
}

export const getOrder: RequestFunction<
  OrderRequestProps,
  CommerceResponse<CommerceOrder>
> = async (
  client,
  props,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrder>>> =>
  client.get(`orders/${props.orderNumber || 'latest'}`, {
    params: {
      customerCompanyId: context.customerCompanyId,
      supplierCompanyId: context.supplierCompanyId,
      isImpersonated: !!context.isImpersonated,
      cultureCode: context.cultureCode || DEFAULT_CULTURE_CODE,
    },
    cancelToken: config.cancelToken,
    ...createRequestConfig(context.user),
  })

export const createOrder: RequestFunction<
  CreateOrderProps,
  CommerceResponse<CommerceOrder>
> = async (
  client,
  _,
  config,
  context
): Promise<AxiosResponse<CommerceResponse<CommerceOrder>>> =>
  client.post('orders', undefined, {
    ...createRequestConfig(
      context.user,
      (context.xSessionId && { [HEADER_SESSION_ID]: context.xSessionId }) || {},
      {
        customerCompanyId: context.customerCompanyId,
        supplierCompanyId: context.supplierCompanyId,
        isImpersonated: !!context.isImpersonated,
        cultureCode: context.cultureCode || DEFAULT_CULTURE_CODE,
      }
    ),
    cancelToken: config.cancelToken,
  })
