import React, { FC } from 'react'
import { PROMOTION_TYPE_QUANTUM_PRICE } from '../../../constants/promotionConstants'
import {
  getPromotionLabel,
  getPromotionSidePanelTitle,
} from '../../../helpers/promotionHelper'
import { Price } from '../../../hooks/services/graphql/usePartSalesInformationService'
import { Promotion } from '../../../types/promotionProps'
import TagWithSidePanel from '../TagWithSidePanel/TagWithSidePanel'
import DiscountPromotion from './PromotionSidePanels/DiscountPromotion'
import QuantumPricePromotionSidePanel from './PromotionSidePanels/QuantumPricePromotion'

interface PromotionTagWithSidePanelDetailsProps {
  price: Price
  promotion: Promotion
}

const PromotionTagWithSidePanelDetails: FC<
  PromotionTagWithSidePanelDetailsProps
> = ({ price, promotion, ...rest }) => {
  const tagLabel = getPromotionLabel(promotion)
  const sidePanelTitle = getPromotionSidePanelTitle(promotion)

  return (
    <TagWithSidePanel tagLabel={tagLabel} sidePanelTitle={sidePanelTitle} {...rest}>
      {promotion.type === PROMOTION_TYPE_QUANTUM_PRICE ? (
        <QuantumPricePromotionSidePanel price={price} />
      ) : (
        <DiscountPromotion promotion={promotion} price={price} />
      )}
    </TagWithSidePanel>
  )
}

export default PromotionTagWithSidePanelDetails
