import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import axios from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import SidePanel from '../SidePanel'
import SidePanelFooter from '../SidePanelFooter'
import SidePanelBody from '../SidePanelBody'
import Spinner from '../../../atoms/Spinner/Spinner'
import Button from '../../../atoms/Button/Button'
import {
  getOrder,
  OrderRequestProps,
} from '../../../../services/rest/ecommerce/order/orders'
import useCommerceApiFetchService from '../../../../hooks/services/rest/ecommerce/useCommerceApiFetchService'
import { CommerceOrder, CommerceResponse } from '../../../../types/commerceApi'
import useCommerceApiResponse from '../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import Image from '../../../atoms/Image/Image'
import { COLORS } from '../../../../constants/themeConstants'
import { getJobSheetTasks } from '../../../../services/rest/ecommerce/r2cJobsheets'
import useAxiosClient from '../../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../../providers/AxiosClientProvider'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import {
  OrderItemReferenceRequestProps,
  postOrderItemReference,
} from '../../../../services/rest/ecommerce/order/order-item-reference'
import { ActiveUserStateContext } from '../../../../providers/ActiveUserStateProvider'
import FormControl from '../../../atoms/FormControls/FormControl'
import FormInputSelect from '../../../atoms/FormControls/FormInputSelect'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../../types/userProps'
import { addOrReplaceOrderItem } from '../../../../actions/shoppingBasket/wizard'
import { useDispatch } from 'react-redux'
import { hasRole } from '../../../../helpers/userHelper'
import { USER_ROLES_SITECORE_SHOPPING_BASKET } from '../../../../constants/userConstants'

interface R2CSidePanelProps {
  active: boolean
  onSidepanelClose: () => void
  partNumber?: string
  jobsheetReference?: string
  customerOrderNumber?: string
}

interface TaskOptionProps {
  text: string
  value: string
}

const R2CJobsheetTaskSidePanel = ({
  active,
  onSidepanelClose,
  partNumber,
  jobsheetReference,
  customerOrderNumber,
}: R2CSidePanelProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useSitecoreContext()
  const dispatch = useDispatch()
  const { activeUserState, orderNumber } = useContext(ActiveUserStateContext)
  const { xSessionId } = useSitecoreContext()
  const inputRef = useRef<HTMLSelectElement>(null)
  const [selectedOrder, setSelectedOrder] = useState<CommerceOrder | null>(null)
  const [currentOrderlineIndex, setCurrentOrderlineIndex] = useState(0)
  const [sidePanelActive, setSidePanelActive] = useState(active)
  const [selectedTaskInput, setSelectedTaskInput] = useState('')
  const [selectedSubmitEvent, setSelectedSubmitEvent] = useState('')

  const numberOfSelectedOrderlines = selectedOrder?.items?.length ?? 0
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const apiQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const hasSitecoreBasketRole = hasRole(user, USER_ROLES_SITECORE_SHOPPING_BASKET)

  // old shopping basket logic to get jobsheet reference and id
  const jobSheetReferenceAndID = customerOrderNumber
    ? customerOrderNumber.split('|')
    : selectedOrder?.customerOrderNumber?.value?.split('|')

  // select jobsheetID for correct enviorment
  const jobSheetID = hasSitecoreBasketRole
    ? customerOrderNumber ?? ''
    : jobSheetReferenceAndID?.[1] ?? ''

  useEffect(() => {
    if (partNumber && selectedOrder?.items) {
      const orderIndex = selectedOrder.items.findIndex(
        (item) => item.partNumber === partNumber
      )
      if (orderIndex !== -1) {
        setCurrentOrderlineIndex(orderIndex)
      }
    }
  }, [active, partNumber, selectedOrder])

  useEffect(() => {
    setSidePanelActive(active && !isImpersonated)
  }, [active, isImpersonated])

  const [isFetching, response, fetchOrder] = useCommerceApiFetchService<
    OrderRequestProps,
    CommerceResponse<CommerceOrder>
  >(getOrder)

  const onResult = useCallback((newOrder: CommerceOrder | null) => {
    if (newOrder) {
      setSelectedOrder(newOrder)
    }
  }, [])

  useCommerceApiResponse<CommerceOrder>({
    response,
    onResult,
    onMessage: (message) => {
      if (message.code === 'orderNotFound') setSelectedOrder(null)
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as CommerceOrder,
  })

  useEffect(() => {
    if (active) fetchOrder({ orderNumber })
  }, [fetchOrder, active, orderNumber])

  const getJobsheetTasks = async () => {
    const { token } = axios.CancelToken.source()

    const getJobsheetTasksResponse = await getJobSheetTasks(
      client,
      apiQueryParams,
      jobSheetID,
      token,
      user
    )
    return getJobsheetTasksResponse.data
  }
  const { isFetching: isFetchingTasks, data: jobsheetTasksData } = useQuery({
    queryKey: ['GetTasksFromJobsheetID'],
    queryFn: () => getJobsheetTasks(),
    enabled: !!customerOrderNumber,
  })
  const currentOrderline =
    selectedOrder && selectedOrder.items
      ? selectedOrder.items[currentOrderlineIndex]
      : null

  useEffect(() => {
    setSelectedTaskInput(currentOrderline?.externalOrderItemReference ?? '')
  }, [currentOrderline])

  const {
    isPending: isLoading,
    mutate,
    data: additionalInformationData,
  } = useMutation({
    mutationKey: ['postOrderItemReference'],
    mutationFn: (payload: OrderItemReferenceRequestProps) =>
      postOrderItemReference(
        client,
        payload,
        { cancelToken: axios.CancelToken.source().token },
        {
          customerCompanyId: activeUserState?.selectedCustomerCompanyId ?? 0,
          supplierCompanyId: activeUserState?.selectedSupplierCompanyId ?? 0,
          user,
          isImpersonated: activeUserState?.isImpersonated ?? false,
          xSessionId,
        }
      ),
    onSuccess: () => {
      if (selectedSubmitEvent === 'save') {
        onSidepanelClose()
        return
      }
      if (currentOrderline) {
        currentOrderline.externalOrderItemReference = selectedTaskInput
      }
      const nextOrderline =
        currentOrderlineIndex === numberOfSelectedOrderlines - 1
          ? 0
          : currentOrderlineIndex + 1

      setCurrentOrderlineIndex(nextOrderline)
    },
  })

  const submitOrderItemReference = useCallback(() => {
    const selectedTask = jobsheetTasksData?.result?.tasks.find(
      (task) => task.id === selectedTaskInput
    )

    mutate({
      orderNumber,
      items: [
        {
          partNumber: currentOrderline?.partNumber,
          externalItemReference: selectedTask?.id,
          lineReference: currentOrderline?.lineReference,
          vehicleReference: currentOrderline?.vehicleReference?.value,
        },
      ],
    })
  }, [currentOrderline, orderNumber, mutate, selectedTaskInput])

  useEffect(() => {
    if (additionalInformationData?.data?.result?.[0]) {
      dispatch(addOrReplaceOrderItem(additionalInformationData.data.result[0]))
    }
  }, [additionalInformationData])

  const formatTaskSelectOption = () =>
    jobsheetTasksData?.result?.tasks
      ?.filter((item) => item.status !== 'Cancelled')
      .map((item) => ({
        text: `${item.reasonForWork}: ${item.description}`,
        value: item.id,
      }))

  return (
    <SidePanel
      position="right"
      active={sidePanelActive}
      onClose={() => {
        onSidepanelClose()
      }}
      disableOutsideClick
      title={i18next.t('r2cLabels.selectR2cJobsheetTask')}
    >
      <SidePanelBody>
        {currentOrderline && !isLoading && (
          <Flex sx={{ flexDirection: 'column' }}>
            <Fragment key={currentOrderline?.displayPartNumber}>
              <Flex sx={{ flexDirection: 'row' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Box>
                    <Text
                      sx={{
                        fontWeight: 'bold',
                      }}
                      color={COLORS.BLACK}
                      data-t-id="R2C-PartDescription"
                    >
                      {currentOrderline?.description}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      sx={{
                        fontSize: 1,
                      }}
                      color={COLORS.GRAY}
                      data-t-id="R2C-PartNumber"
                    >
                      {currentOrderline?.partNumber}
                    </Text>
                  </Box>
                  <Box sx={{ marginTop: 4 }}>
                    <Text
                      sx={{
                        fontweight: 'bold',
                      }}
                      color={COLORS.BLACK}
                    >
                      {i18next.t('r2cLabels.r2cJobsheetNumber')}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      sx={{
                        fontSize: 1,
                      }}
                      color={COLORS.GRAY}
                      data-t-id="R2C-JobsheetNumber"
                    >
                      {hasSitecoreBasketRole
                        ? jobsheetReference
                        : jobSheetReferenceAndID?.[0]}
                    </Text>
                  </Box>
                </Box>

                {currentOrderline?.images?.[0]?.thumbnailImageUrl && (
                  <Box>
                    <Image
                      alt={currentOrderline?.englishDescription}
                      src={currentOrderline?.images[0]?.thumbnailImageUrl}
                      sx={{
                        width: '100%',
                        maxWidth: 64,
                        maxHeight: 64,
                      }}
                    />
                  </Box>
                )}
              </Flex>
              {!isFetchingTasks && jobsheetTasksData && (
                <Box sx={{ marginTop: 4 }}>
                  <Text
                    color={COLORS.BLACK}
                    sx={{ marginBottom: 1, fontWeight: 'bold' }}
                  >
                    {i18next.t('r2cLabels.r2cJobsheetTask')}
                  </Text>
                  <FormControl>
                    <FormInputSelect
                      ref={inputRef}
                      data-t-id="R2C-JobsheetTaskSelector"
                      placeholder={i18next.t('r2cLabels.selectATask')}
                      value={selectedTaskInput}
                      initialValue={selectedTaskInput}
                      onChange={(event) =>
                        setSelectedTaskInput(event?.target?.value)
                      }
                      options={formatTaskSelectOption() as TaskOptionProps[]}
                    />
                  </FormControl>
                </Box>
              )}
            </Fragment>
          </Flex>
        )}
        {(isFetching && !currentOrderline) || isFetchingTasks || isLoading ? (
          <Box
            sx={{
              paddingY: 5,
              width: '100%',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </Box>
        ) : null}
      </SidePanelBody>
      <SidePanelFooter sx={{ flexDirection: 'column', marginTop: 'auto' }}>
        <Flex sx={{ gap: 2, marginBottom: 3 }}>
          <Button
            sx={{ width: '100%' }}
            data-t-id="R2C-SaveJobsheetTask"
            variant="primary"
            disabled={!selectedOrder || isLoading || !selectedTaskInput}
            onClick={() => {
              setSelectedSubmitEvent('save')
              submitOrderItemReference()
            }}
          >
            {i18next.t('commonLabels.save')}
          </Button>

          {(selectedOrder?.items?.length ?? 0) > 1 && (
            <Button
              sx={{ width: '100%' }}
              data-t-id="R2C-Save&NextJobsheetTask"
              variant="outline"
              disabled={!selectedOrder || isLoading || !selectedTaskInput}
              onClick={() => {
                setSelectedSubmitEvent('saveAndNext')
                submitOrderItemReference()
              }}
            >
              {i18next.t('commonLabels.saveAndNext')}
            </Button>
          )}
        </Flex>
        <Text
          variant="smallText"
          sx={{
            textAlign: 'center',
            color: COLORS.GRAY,
          }}
        >
          {i18next.t('r2cLabels.disclaimerCancelledOrInvoicedR2cJobsheets')}
        </Text>
      </SidePanelFooter>
    </SidePanel>
  )
}

export default R2CJobsheetTaskSidePanel
