import React, { useContext, useEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'
import {
  URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM,
  URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM,
} from '../../../../constants/urlConstants'
import ConfirmOrderButton from './ConfirmOrderButton'
import OrderGoToCheckoutButton from './OrderGoToCheckoutButton'
import { COLORS } from '../../../../constants/themeConstants'
// import renderHTML from '../../../../helpers/renderHTMLHelper'
import { getEditableTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import PunchoutButton from './PunchoutButton'
import SendQuotationButton from './SendQuotationButton'
import { useShoppingBasket } from '../../../../providers/ShoppingBasketProvider'
import { hasRole } from '../../../../helpers/userHelper'
import { USER_ROLES_DEALER_ORDER_QUOTATION_PRICE } from '../../../../constants/userConstants'
import { QUOTE } from '../../../../constants/orderConstants'
import { UserContext } from '../../../../providers/UserProvider'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import ContinueQuotationButton from './ContinueQuotationButton'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { TextField } from '../../../../types/layoutService'
import renderHTML from '../../../../helpers/renderHTMLHelper'

const OrderStickySummaryNavigationButtons = () => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { user } = useContext(UserContext)
  const { isImpersonated } = useContext(ActiveStoreProviderContext)
  const { step, datasource } = useShoppingBasket()
  const [additionalInfoText, setAdditionalInfoText] = useState<
    TextField | undefined
  >()

  useEffect(() => {
    if (!order?.items?.length) {
      setAdditionalInfoText(datasource.noItemsInBasket)
      return
    }

    // if power usering for r2c customer, you can always continue
    if (order.isR2CEnabled && isImpersonated) {
      setAdditionalInfoText(undefined)
      return
    }

    // if r2c customer, jobsheet reference is required
    if (order.confirmNotAllowedReason === 'OrderExternalReferenceEmptyNotAllowed') {
      setAdditionalInfoText(datasource.jobsheetReferenceRequiredToConfirmOrder)
      return
    }

    // if r2c customer, jobsheet task is required
    if (
      order.confirmNotAllowedReason ===
      'OrderItemsExternalLineReferenceEmptyNotAllowed'
    ) {
      setAdditionalInfoText(datasource.r2CTasksNotFilled)
      return
    }

    if (
      order?.customerOrderNumber?.isMandatory &&
      !order.customerOrderNumber.value
    ) {
      setAdditionalInfoText(datasource.orderNumberIsMandatoryButEmpty)
      return
    }

    if (
      order?.customerOrderReference?.isMandatory &&
      !order.customerOrderReference.value
    ) {
      setAdditionalInfoText(datasource.referenceNumberIsMandatoryButEmpty)
      return
    }

    setAdditionalInfoText(undefined)
  }, [order])

  return (
    <>
      {step === URL_SHOPPING_BASKET_WIZARD_STEP_OVERVIEW_PARAM && (
        <>
          <Box
            sx={{
              display: 'grid',
              gap: 4,
              gridTemplateColumns: '1fr',
              ':has(> :first-of-type:nth-last-of-type(2))': {
                gridTemplateColumns: ['1fr 1fr', null, null, null, '1fr'],
              },
              '> *:not(:first-of-type)': {
                borderBlockStart: [null, null, null, '1px solid'],
                borderColor: [null, null, null, 'gray.1'],
                paddingBlockStart: [null, null, null, 4],
              },
            }}
          >
            {hasRole(user, USER_ROLES_DEALER_ORDER_QUOTATION_PRICE) &&
              isImpersonated &&
              order?.type !== QUOTE && (
                <div>
                  <ContinueQuotationButton />
                </div>
              )}

            <div>
              <OrderGoToCheckoutButton />
            </div>
          </Box>

          {additionalInfoText && (
            <Text
              variant="bodySmall"
              color={COLORS.GRAY}
              sx={{ display: 'block', marginBlockStart: 3, textAlign: 'center' }}
            >
              {renderHTML(getEditableTextFieldValue(additionalInfoText))}
            </Text>
          )}
        </>
      )}

      {step === URL_SHOPPING_BASKET_WIZARD_STEP_QUOTATION_PARAM && (
        <SendQuotationButton />
      )}

      {step === URL_SHOPPING_BASKET_WIZARD_STEP_CHECKOUT_PARAM && (
        <>
          {order?.status !== 'PunchedOut' && order?.isPunchOut ? (
            <PunchoutButton />
          ) : (
            <ConfirmOrderButton />
          )}
        </>
      )}
    </>
  )
}

export default OrderStickySummaryNavigationButtons
