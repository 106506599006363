import React, { FC, useContext, useMemo } from 'react'
import { Text } from 'theme-ui'
import i18next from 'i18next'
import { CommerceWebshopPointsCampaign } from '../../../../../types/commerceApi'
import { LocalizationContext } from '../../../../../providers/LocalizationProvider'
import { parseDate } from '../../../../../helpers/dateHelper'
import useSitecoreContext from '../../../../../hooks/useSitecoreContext'
import WebshopPointsCampaignInformationInformationText from './WebshopPointsCampaignInformationInformationText'
import { getDividerSx } from '../../../../atoms/Divider/Divider'

interface WebshopPointsCampaignInformationProps {
  webshopPointsCampaign?: CommerceWebshopPointsCampaign
}

const WebshopPointsCampaignInformation: FC<
  WebshopPointsCampaignInformationProps
> = ({ webshopPointsCampaign }) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const { dateFormatter } = useContext(LocalizationContext)

  const lastUpdateDate = useMemo(
    () =>
      webshopPointsCampaign
        ? dateFormatter(parseDate(webshopPointsCampaign.lastUpdated))
        : '',
    [dateFormatter, webshopPointsCampaign]
  )

  if (!webshopPointsCampaign) return null

  return (
    <Text
      variant="heading4"
      sx={{
        p: {
          color: 'gray.3',
          fontSize: 1,
        },
        '> p': {
          margin: 0,
          paddingBottom: 3,
          marginBottom: 3,
          lineHeight: 'menuList',
          ...getDividerSx('bottom'),
        },
        '> div': {
          color: 'gray.3',
          display: 'flex',
          flexDirection: 'column',
          marginTop: 3,
          marginBottom: 3,
          p: {
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 0,
            marginBottom: 0,
          },
          '> span': {
            fontWeight: 'normal',
            fontSize: 0,
            lineHeight: 'smallText',
          },
        },
      }}
    >
      <WebshopPointsCampaignInformationInformationText
        webshopPointsCampaign={webshopPointsCampaign}
      />

      <div>
        <p>
          {i18next.t('shoppingLabels.webshopPointsCampaign.currentBalance')}

          <span>
            {i18next.t('shoppingLabels.webshopPointsCampaign.points', {
              pointCount: new Intl.NumberFormat(cultureCode).format(
                Number(webshopPointsCampaign.pointsBalance)
              ),
            })}
          </span>
        </p>

        <span>
          {i18next.t('commonLabels.updated', {
            lastUpdateDate,
          })}
        </span>
      </div>
    </Text>
  )
}

export default WebshopPointsCampaignInformation
