import React, { FC, useContext } from 'react'
import i18next from 'i18next'
import { UserContext } from '../../../providers/UserProvider'
import { loggedIn } from '../../../helpers/userHelper'
import PartLineTitle from '../../molecules/PartLine/PartLineTitle'
import PartLine, { PartLineGridTemplate } from '../../molecules/PartLine/PartLine'

interface SearchResultsLineViewColumnTitlesProps {
  gridTemplate?: PartLineGridTemplate
}

const SearchResultsLineViewColumnTitles: FC<
  SearchResultsLineViewColumnTitlesProps
> = ({ gridTemplate }) => {
  const { user } = useContext(UserContext)

  return (
    <PartLine
      gridTemplate={gridTemplate}
      py={3}
      sx={{
        display: ['none', 'none', 'grid'],
      }}
    >
      <PartLineTitle>{i18next.t('commonLabels.product')}</PartLineTitle>

      <PartLineTitle />

      <PartLineTitle>{i18next.t('commonLabels.specifications')}</PartLineTitle>

      <PartLineTitle>{i18next.t('commonLabels.brand')}</PartLineTitle>

      {loggedIn(user) && (
        <PartLineTitle>{i18next.t('stockLabels.availability')}</PartLineTitle>
      )}

      {loggedIn(user) && (
        <PartLineTitle>{i18next.t('partSalesLabels.price')}</PartLineTitle>
      )}
    </PartLine>
  )
}

export default SearchResultsLineViewColumnTitles
