import { TextProps } from '@sitecore-jss/sitecore-jss-react/types/components/Text'
import React, { FC, useMemo } from 'react'
import { Text } from 'theme-ui'
import { getTotalSearchResultsString } from '../../../../helpers/searchResultsHelper'
import { i18nextZeroPluralization } from '../../../../helpers/translationHelper'

interface SearchControlsStatisticsProps extends Omit<TextProps, 'css' | 'label'> {
  count?: number
  sx?: Record<string, unknown>
}

const SearchControlsStatistics: FC<SearchControlsStatisticsProps> = ({
  count,
  sx = {},
  ...props
}) => {
  const totalSearchResults = useMemo(
    () =>
      typeof count !== 'undefined' ? getTotalSearchResultsString(count) : undefined,
    [count]
  )

  if (!totalSearchResults || !count) return null

  return (
    <Text
      variant="bodySmall"
      sx={{
        px: [0, 0, 3],
        color: 'gray.2',
        ...sx,
      }}
      {...props}
    >
      {`${totalSearchResults} ${i18nextZeroPluralization(
        'paginationLabels.results',
        count
      )}`}
    </Text>
  )
}

export default SearchControlsStatistics
