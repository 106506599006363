import React, { ReactNode } from 'react'
import { Flex } from 'theme-ui'

interface SearchControlsStatisticsContainerProps {
  children: ReactNode
}

const SearchControlsStatisticsContainer = ({
  children,
}: SearchControlsStatisticsContainerProps) => (
  <Flex
    sx={{
      flexShrink: 0,
      mt: [4, 4, 0],
      width: ['100%', '100%', 'auto'],
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </Flex>
)

export default SearchControlsStatisticsContainer
